import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as checkinUtils from 'utils/checkinUtils';
import * as membershipUtils from 'utils/membershipUtils';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// API
import * as remoteApi from 'api/remote';

import CurrentGrowth from 'components/CurrentGrowth';
import CommentButtonLink from 'components/CommentButtonLink';
import DropdownMenu from 'components/DropdownMenu';
import MarkdownContent from 'components/MarkdownContent';
import KoalaIconButton from 'koala/components/IconButton';
import Reactions from 'components/Reactions';
import { CachedReaction, Checkin, Outcome } from 'types';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaTextBadge from 'koala/components/TextBadge';
import CheckinViews from './CheckinViews';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { formatDistanceToNowLocale } from 'utils/dateUtils';
import ShareIconButton from 'components/ShareIconButton';
import * as routes from 'routes';
import { useParams } from 'react-router-dom';

const TabbyAvatar = styled.img<{ size: number }>`
  width: ${(props) => props.size}rem;
  position: relative;
  bottom: 4px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    'content content'
    'footer footer';
  column-gap: ${theme.spacing.x2};

  padding: 0;

  .actions {
    opacity: 1;
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }

  @media ${theme.devices.mobile} {
    padding: ${theme.spacing.x2};
    column-gap: ${theme.spacing.x2};
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing.half};
  height: 3.8rem;
`;

const Content = styled.div`
  grid-area: content;
  .subtle {
    padding: ${theme.spacing.x1} 0;
  }
`;

const Footer = styled.div`
  grid-area: footer;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: ${theme.spacing.x1};
  align-items: start;
  justify-content: space-between;
  color: ${theme.colors.subtleText};

  .time-block {
    height: 3.2rem;
  }
  .actions-block {
    justify-content: end;
  }
  > div {
    display: flex;
    align-items: center;
  }

  @media ${theme.devices.mobile} {
    margin-right: 1.6rem;
  }
`;

interface Props {
  className?: string;
  outcome: Outcome;
  checkin: Checkin;
  showProgressPrct?: boolean;
  compact?: boolean;
  hideActions?: boolean;
}

function CheckinBlock(props: Props) {
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t, i18n } = useTranslation();
  const { checkin, outcome, className, showProgressPrct, hideActions } = props;
  const user = checkin.membership ? checkin.membership.user : null;
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  // Checkin mutations
  const [deleteCheckinMutation] = useMutation(remoteApi.deleteCheckin, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.checkins);
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcome.nano_slug]);
    },
  });

  const handleMenuSelection = (value: ReactElement) => {
    const action = value.props['data-action'];
    const checkinId = value.props['data-id'];
    const blockId = `checkin:${checkinId}`;
    switch (action) {
      case 'edit':
        dispatch(setGlobalModalContent(`${blockId}:edit`));
        break;
      case 'delete':
        if (window.confirm('Delete this checkin?')) {
          deleteCheckinMutation(checkinId);
        }
        break;
    }
  };

  // get reactions
  const [reactions, setReactions] = useState<CachedReaction[]>([]);
  useEffect(() => {
    if (checkin.cached_reactions && typeof checkin.cached_reactions !== 'string') {
      setReactions(checkin.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [checkin]);

  const [addReactionMutation] = useMutation(remoteApi.createCheckinReaction, {
    onSuccess: (response) => {
      setReactions(response.data);
      queryCache.invalidateQueries(queryKeys.checkins);
      queryCache.invalidateQueries(queryKeys.currentCheckin);
    },
  });
  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: (response) => {
      setReactions(response.data);
      queryCache.invalidateQueries(queryKeys.checkins);
      queryCache.invalidateQueries(queryKeys.currentCheckin);
    },
  });

  const addReaction = (emotion: string) => {
    addReactionMutation({ checkinId: checkin.id, emotion: emotion });
  };
  const removeReaction = (reactionId: string) => {
    deleteReactionMutation(reactionId);
  };

  const formattedScore = checkin ? checkinUtils.checkinScore(checkin, outcome, t) : 'Pending';
  const sourceLink = `#checkin:${checkin.nano_slug}:show`;
  let checkinLink = checkin.plan_nano_slug
    ? `https://${process.env.REACT_APP_DOMAIN}${routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(
        ':workspaceSlug',
        workspaceSlug,
      ).replace(':planId', checkin.plan_nano_slug)}${sourceLink}`
    : '';

  const commentClass = checkin.checkin_comments_count > 0 ? '' : 'actions';

  let menu_items: ReactElement[] = [];

  menu_items = [
    <span key="edit" data-action="edit" data-id={checkin.id}>
      {t('shared.edit')}
    </span>,
    <span key="delete" data-action="delete" data-id={checkin.id}>
      {t('shared.delete')}
    </span>,
  ];

  const avatarSize = props.compact ? 2.4 : 3.2;

  // Remote layout for the checkin
  if (checkin.is_remote) {
    return (
      <Grid className={`checkin ${props.className} remote`}>
        <Gutter>
          <TabbyAvatar
            src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png"
            alt="Tabby"
            size={avatarSize}
          />
          <KoalaTextBadge
            isLowercase={true}
            variant={checkinUtils.confidenceToVariant(checkin.confidence)}
            edge="circle"
            style={{ border: 'none' }}
            dataFor={`progress-checkin-${checkin.id}`}
          >
            {formattedScore}
          </KoalaTextBadge>
          <ReactTooltip
            place="bottom"
            type="dark"
            className="tooltip"
            effect="solid"
            id={`progress-checkin-${checkin.id}`}
          >
            {formattedScore}
          </ReactTooltip>
          {showProgressPrct && <CurrentGrowth outcome={outcome} />}
        </Gutter>
        <Meta>
          <KoalaTextBadge
            variant="neutral-light"
            edge="circle"
            maxWidth="20rem"
            size="small"
            style={{ border: 'none' }}
          >
            {t('shared.checkins.synced')}
          </KoalaTextBadge>
          {!hideActions && !isReadOnly && (
            <>
              {menu_items.length > 0 && (
                <DropdownMenu
                  trigger={<KoalaIconButton iconName="ellipsis" className="actions" />}
                  onSelection={handleMenuSelection}
                  items={menu_items}
                />
              )}
            </>
          )}
        </Meta>
        <Footer className="checkin-block-footer">
          <div className="time-block">{formatDistanceToNowLocale(checkin.checkin_date, i18n, true, true)}</div>
          {!hideActions && (
            <div>
              <Reactions reactions={reactions} addCallback={addReaction} removeCallback={removeReaction} />
            </div>
          )}
          {!hideActions && (
            <div className="actions-block">
              {className === 'primary' && <CheckinViews checkin={checkin} />}
              {className !== 'primary' && (
                <CommentButtonLink to={sourceLink} className={commentClass}>
                  <KoalaIconButton iconName="comment" />
                  {checkin.checkin_comments_count > 0 && <div className="value">{checkin.checkin_comments_count}</div>}
                </CommentButtonLink>
              )}
            </div>
          )}
        </Footer>
      </Grid>
    );
  }

  return (
    <Grid className={`checkin ${props.className}`}>
      <Gutter>
        <KoalaAvatar user={user} size={avatarSize} />
        <KoalaTextBadge
          isLowercase={true}
          variant={checkinUtils.confidenceToVariant(checkin.confidence)}
          edge="circle"
          style={{ border: 'none' }}
          dataFor={`progress-checkin-${checkin.id}`}
        >
          {formattedScore}
        </KoalaTextBadge>
        <ReactTooltip
          place="bottom"
          type="dark"
          className="tooltip"
          effect="solid"
          id={`progress-checkin-${checkin.id}`}
        >
          {formattedScore}
        </ReactTooltip>
        {showProgressPrct && <CurrentGrowth outcome={outcome} />}
      </Gutter>
      <Meta>
        <ShareIconButton id={checkin.id} link={checkinLink} />
        {!hideActions && !isReadOnly && (
          <>
            {menu_items.length > 0 && (
              <DropdownMenu
                trigger={<KoalaIconButton iconName="ellipsis" className="actions" />}
                onSelection={handleMenuSelection}
                items={menu_items}
              />
            )}
          </>
        )}
      </Meta>
      <Content className="checkin-block-content">
        {checkin.body && <MarkdownContent source={checkin.body} />}
        {!checkin.body && <p className="subtle">{t('publicPlan.noComment')}</p>}
        {checkin.is_remote && (
          <p className="subtle">
            --
            <br />
            <em>{t('publicPlan.remote')}</em>
          </p>
        )}
      </Content>
      <Footer className="checkin-block-footer">
        <div className="time-block">{formatDistanceToNowLocale(checkin.checkin_date, i18n, true, true)}</div>
        {!hideActions && (
          <>
            <div>
              <Reactions reactions={reactions} addCallback={addReaction} removeCallback={removeReaction} />
            </div>
            <div className="actions-block">
              {className === 'primary' && <CheckinViews checkin={checkin} />}
              {className !== 'primary' && (
                <CommentButtonLink to={sourceLink} className={commentClass}>
                  <KoalaIconButton iconName="comment" />
                  {checkin.checkin_comments_count > 0 && <div className="value">{checkin.checkin_comments_count}</div>}
                </CommentButtonLink>
              )}
            </div>
          </>
        )}
      </Footer>
    </Grid>
  );
}

export default React.memo(CheckinBlock);
