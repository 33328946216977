import React, { useEffect } from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';
import theme from 'theme';

import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';

import FormField from 'components/FormField';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useTranslation } from 'react-i18next';

const InlineItems = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
  setScoreChanges: (value: string) => void;
}

function CurrentValue(props: CurrentValueProps) {
  const { outcome, setScoreChanges } = props;
  const { t } = useTranslation();
  const translationKey = 'modals.dataConnectors';

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcome.id, `data_source:monday:${outcome.data_source_type}`, 'current'];
  const staleTime = 300;

  const { data, isFetching } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  const currentValue = data?.data.result;

  useEffect(() => {
    if (currentValue) {
      setScoreChanges(currentValue.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const mondayKey = 'modals.dataConnectors.mondayData';
  const dataTypeToLabel: any = {
    tickets_in_group: t(`${mondayKey}.tickets_in_group`),
    completed_high_priority: t(`${mondayKey}.completed_high_priority`),
    total_tickets: t(`${mondayKey}.total_tickets`),
  };

  if (!outcome.data_source_type) {
    return null;
  }

  return (
    <FormField>
      <label>{t(`${translationKey}.title`)}</label>
      <InlineItems>
        <KoalaTextBadge edge="circle" size="small" maxWidth="30rem" variant="neutral-light">
          {t(`${translationKey}.dataSource`, {
            dataSource: 'Monday',
            label: dataTypeToLabel[outcome.data_source_type],
          })}
        </KoalaTextBadge>
        {isFetching && <KoalaLoader size="small" />}
      </InlineItems>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  setScoreChanges: (value: string) => void;
}

function MondaySource(props: Props) {
  const { outcome, setScoreChanges } = props;

  const availableDataSourceTypes = ['tickets_in_group', 'completed_high_priority', 'total_tickets'];

  if (!outcome.data_source_type || outcome.data_source_origin !== 'monday') {
    return null;
  }

  if (availableDataSourceTypes.includes(outcome.data_source_type)) {
    return <CurrentValue outcome={outcome} setScoreChanges={setScoreChanges} />;
  }

  return null;
}

export default React.memo(MondaySource);
