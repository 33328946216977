import { CachedReaction, Membership, Workspace } from 'types';
import { CustomTermKey, translate } from './customTermUtils';
import { TFunction } from 'i18next';

export interface UserReaction {
  reactionId: string;
  membershipId: string;
  fullname: string;
}
interface ReactionGroup {
  [emoji: string]: {
    count: number;
    reactions: UserReaction[];
  };
}

export const reactionHelper = (reactions: CachedReaction[]) => {
  if (!reactions || Object.keys(reactions).length === 0 || reactions.values === undefined) {
    return {};
  }
  const groupedReactions = reactions.reduce((previous: ReactionGroup, currentReaction: CachedReaction) => {
    const emoji = currentReaction.emotion;

    if (previous[emoji]) {
      // if emoji already in list, add user
      const count = previous[emoji].reactions.push({
        reactionId: currentReaction.id,
        membershipId: currentReaction.membership_id,
        fullname: currentReaction.membership_name,
      });
      previous[emoji].count = count;
    } else {
      // if emoji isn't in list, add key and add user
      previous[emoji] = {
        count: 1,
        reactions: [
          {
            reactionId: currentReaction.id,
            membershipId: currentReaction.membership_id,
            fullname: currentReaction.membership_name,
          },
        ],
      };
    }
    return previous;
  }, {});

  return groupedReactions;
};

export const reactionNames = (
  numberUsers: number,
  isSelected: boolean,
  reactions: UserReaction[],
  t: TFunction,
  currentMembership?: Membership,
) => {
  let names: string;

  if (!currentMembership) {
    names = reactions
      .slice(0, 5)
      .map((reaction) => reaction.fullname)
      .join(', ');
  } else if (isSelected && numberUsers === 1) {
    names = t('reactions.you');
  } else {
    names = reactions
      .slice(0, 5)
      .map((reaction) => (reaction.membershipId !== currentMembership.id ? reaction.fullname : 'you'))
      .join(', ');
  }
  names += numberUsers > 5 ? `, ${t('reactions.andMore', { number: numberUsers - 5 })}` : '';
  names += isSelected ? ` ${t('reactions.remove')}` : '';
  return names;
};

export const reactableTypeToString = (type: string, workspace: Workspace, t: TFunction) => {
  switch (type) {
    case 'Checkin':
      return t('reactions.checkin');
    case 'CheckinComment':
      return t('reactions.checkinComment');
    case 'InitiativeComment':
      return t('reactions.initiativeComment', { initiative: translate(workspace, CustomTermKey.INITIATIVE, 1) });
    case 'ReviewComment':
      return t('reactions.reviewComment');
    case 'ReviewReply':
      return t('reactions.reviewReply');
    default:
      break;
  }
};
