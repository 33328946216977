/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import theme from 'theme';
import { Helmet } from 'react-helmet';

// Actions
import { sessionActions, uiActions } from 'state/actions/';

// Components
import Loader from 'components/Loader';
import Wordmark from 'components/Wordmark';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { Trans, useTranslation } from 'react-i18next';

// Components
const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  padding: 0 12rem 8rem 12rem;
  width: 80rem;
  height: 40rem;
  p {
    font-size: 2.4rem;
    line-height: 1.4;
    margin-bottom: 1.6rem;
  }

  span {
    border-top: 1px solid #d1d1d1;

    display: block;
    padding-top: 1.6rem;
    margin-top: 1.6rem;
    line-height: 1;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 1.6rem;
  }
`;

const ReconfirmError = styled.div`
  color: #d0402e;
  margin-bottom: ${theme.spacing.x2};
`;

const Message = styled.div<{ bgColor: string }>`
  background: ${(props) => props.bgColor};
  border-radius: 8px;
  padding: ${theme.spacing.x2};
  margin: ${theme.spacing.x3} 0;
  p {
    font-size: 2rem;
    line-height: 1.4;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.x2};
  input {
    margin-right: ${theme.spacing.x2};
  }
`;

function SessionConfirm() {
  const dispatch = useDispatch();
  const { t } = useTranslation(undefined, { useSuspense: false });
  // Reset Redux state for the UI
  useEffect(() => {
    dispatch(uiActions.resetPageState('Confirm'));
  }, [dispatch]);

  // Get the UI state from redux store
  const uiState = useSelector((state: any) => state.pageConfirm);

  // Local state
  const [email, setEmail] = useState('');

  // Extract the confirmation token from the query string
  const location = useLocation();
  const parsedLocation = queryString.parse(location.search);
  const { token } = parsedLocation;

  // Try to confirm the token after loading the page
  useEffect(() => {
    if (token) {
      dispatch(sessionActions.confirm(String(token)));
    }
  }, [token, dispatch]);

  // UI State for confirmation
  const { hasErrors, hasSentNewConfirm, isRequestingConfirm, errors } = uiState;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
  };

  // Translate error keys to string value
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'email':
        return t('errors.email');
      case 'confirmation_token':
        return t('errors.confirmEmail');
      default:
        return '';
    }
  };

  // Check if the error is due to confirmation being too late.
  // (we look for "needs to be confirmed" in the error message as it's the error returned
  // by Devise)
  let tokenIsExpired = false;
  if (errors['email'] && errors['email'][0].includes('needs to be confirmed')) {
    tokenIsExpired = true;
  }

  // Also render reconfirmation form if token is invalid
  if (errors['confirmation_token'] && errors['confirmation_token'][0].includes('is invalid')) {
    tokenIsExpired = true;
  }

  const renderReconfirmForm = () => {
    // UI State for resending confirmation
    const { hasReconfirmErrors, isResendingConfirm, reconfirmErrors } = uiState;

    const handleNewConfirmation = (e: React.FormEvent) => {
      e.preventDefault();
      dispatch(sessionActions.resendConfirmation(email));
    };

    return (
      <Form onSubmit={handleNewConfirmation}>
        <InputLine>
          <input
            type="email"
            name="email"
            placeholder={t('sessionConfirm.emailPlaceholder') ?? 'Work email'}
            required={true}
            onChange={handleEmailChange}
            autoComplete="off"
          />
          {isResendingConfirm && <Loader />}
        </InputLine>
        {hasReconfirmErrors && (
          <ReconfirmError>
            {Object.keys(reconfirmErrors).map((errorKey: string) => {
              // Get the first error for the key
              // NOTE: there might be more! I'm just keeping the UI simpler right now.
              const error = reconfirmErrors[errorKey][0];
              return (
                <div>
                  {errorKeyToString(errorKey)} {error.toLowerCase()}.
                </div>
              );
            })}
          </ReconfirmError>
        )}
        <Actions>
          <KoalaButton submit disabled={isResendingConfirm}>
            {t('sessionConfirm.sendNew')}
          </KoalaButton>

          <KoalaTextButton isLink to="/login" appearance="primary">
            {t('sessionConfirm.back')}
          </KoalaTextButton>
        </Actions>
      </Form>
    );
  };

  return (
    <Container>
      <Helmet>
        <title>{t('sessionConfirm.title')} | Tability</title>
      </Helmet>
      <ContentContainer>
        <Content>
          <p>
            <Wordmark type="dark" />
          </p>
          {isRequestingConfirm && (
            <p style={{ textAlign: 'center' }}>
              <Loader size="big" />
            </p>
          )}
          {hasErrors && (
            <Message bgColor={theme.colors.warningBg}>
              <p>
                <b>{t('sessionConfirm.error')}</b>
              </p>
              <p>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error = errors[errorKey][0];
                  return (
                    <p>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}.
                    </p>
                  );
                })}
              </p>
              {tokenIsExpired && <Fragment>{renderReconfirmForm()}</Fragment>}
              {tokenIsExpired === false && (
                <Actions>
                  <KoalaTextButton isLink to='="/login'>
                    {t('sessionConfirm.back')}
                  </KoalaTextButton>
                </Actions>
              )}
            </Message>
          )}
          {hasSentNewConfirm && (
            <Message bgColor={theme.colors.successBg}>
              <p>
                <Trans i18nKey="sessionConfirm.newConfirmation" values={{ email }} components={{ b: <b /> }} />
              </p>
              <p>{t('sessionConfirm.checkInbox')}</p>
            </Message>
          )}
        </Content>
      </ContentContainer>
    </Container>
  );
}

export default SessionConfirm;
