import styled from 'styled-components'; // Fixing the build
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryKeys from 'config/queryKeys';

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

interface Props {
  dashboardId: string;
}

function RenameDashboard(props: Props) {
  const { dashboardId } = props;
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const { t } = useTranslation();

  const [title, setTitle] = useState('');

  const [updateDashboard, { isLoading }] = useMutation(remoteApi.updateDashboard, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.dashboards, workspaceSlug]);
      queryCache.invalidateQueries([queryKeys.currentDashboard, dashboardId]);
      dispatch(setGlobalModalContent(''));
    },
  });

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    const dashboard = {
      title,
    };
    const params = {
      dashboardId,
      dashboard,
    };
    updateDashboard(params);
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t('modals.renameDashboard.title')}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleUpdate}>
          <FormField>
            <label>{t('modals.renameDashboard.name')}</label>
            <input
              placeholder={t('modals.renameDashboard.name') ?? 'Dashboard name'}
              className="segment-input"
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleUpdate} loading={isLoading}>
          {t('shared.update')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default RenameDashboard;
