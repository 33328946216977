import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import { DashboardWidget, Outcome } from 'types';
import { DragHandle, PathSection, SourceTitle, WidgetContainer, WidgetContent, WidgetHeader } from '..';
import { useTranslation } from 'react-i18next';
import KoalaLoader from 'koala/components/Loader';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import KoalaIcon from 'koala/components/Icons';
import PlanIconLabel from 'components/PlanIconLabel';
import { Link, useHistory } from 'react-router-dom';
import { WORKSPACE_PLAN_TEMPLATE_ROUTE, WORKSPACE_PLAN_TRACK_ROUTE, WORKSPACE_PLAN_WRITE_ROUTE } from 'routes';
import SummaryCard from './SummaryCard';

const OutcomeMetrics = styled.div`
  display: flex;
  flex: 1;
  height: 100%;

  .outcome-metrics-card {
    width: 100%;
    height: fit-content;
  }
`;

interface Props {
  widget: DashboardWidget;
}

function OutcomeSummaryWidget(props: Props) {
  const { widget } = props;
  const outcomeId = widget.source_id;
  const { t } = useTranslation();
  const history = useHistory();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const widgetTitle: string = widget.title
    ? widget.title
    : t('workspaceDashboards.outcomeSummary', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      });

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = 0;
  const [outcome, setoutcome] = useState<Outcome | null>(null);
  const [error, setError] = useState('');
  // Get the outcome details
  const { isFetching } = useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
    onSuccess: (response) => {
      setoutcome(response.data);
    },
    onError: (error: AxiosError) => {
      const errorText = error.response?.data.error;
      if (errorText.includes('not allowed') && error.response?.status === 401) {
        setError(
          t('workspaceDashboards.permissionError') ?? 'You do not have permission to view the content in this widget',
        );
      } else {
        setError(t('workspaceDashboards.widgetError') ?? 'Unable to fetch widget');
      }
    },
  });

  if (isFetching) {
    return (
      <WidgetContainer>
        <KoalaLoader />
      </WidgetContainer>
    );
  }

  if (!outcome) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <WidgetContent>
          <p>{error ?? t('workspaceDashboards.widgetError')}</p>
        </WidgetContent>
      </WidgetContainer>
    );
  }

  const handleClick = () => {
    history.push(`#outcome:${outcome.nano_slug}:show`);
  };

  const handleClickObjective = () => {
    history.push(`#objective:${outcome.objective.nano_slug}:show`);
  };

  const stateToRouteMapping: { [key: string]: string } = {
    draft: WORKSPACE_PLAN_WRITE_ROUTE,
    published: WORKSPACE_PLAN_TRACK_ROUTE,
    template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
  };
  let routeMappingState = outcome.plan.state || 'draft';
  const routeTarget = stateToRouteMapping[routeMappingState];
  const planRoute = routeTarget
    .replace(':workspaceSlug', currentWorkspace.slug)
    .replace(':planId', outcome.plan.nano_slug);

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>

      <WidgetContent>
        <SourceTitle>
          <span className="widget-source" onClick={handleClick}>
            {outcome.title}
          </span>
        </SourceTitle>
        <OutcomeMetrics>
          <SummaryCard outcome={outcome} />
        </OutcomeMetrics>
        <PathSection>
          <Link to={planRoute}>
            <PlanIconLabel plan={outcome.plan} />
          </Link>
          <span>/</span>
          <span onClick={handleClickObjective} className="widget-source">
            {outcome.objective.title}
          </span>
        </PathSection>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default OutcomeSummaryWidget;
