import React from 'react';

const SparklesIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.01267 1.00006C7.44713 1.00471 7.82887 1.28938 7.95728 1.70446L9.696 7.32479L12.5452 9.15919C12.8276 9.34102 12.9999 9.65248 13.0038 9.98835C13.0077 10.3242 12.8428 10.6396 12.5647 10.828L9.8445 12.6703L7.95004 18.318C7.81334 18.7255 7.4315 19.0001 7.00166 19C6.57182 18.9999 6.19015 18.7251 6.05368 18.3175L4.16313 12.6704L1.43973 10.8283C1.16368 10.6416 0.998788 10.3296 1.00001 9.99634C1.00123 9.66308 1.16839 9.35232 1.4458 9.16762L4.16369 7.35804L6.05318 1.68405C6.19045 1.27182 6.57821 0.995401 7.01267 1.00006ZM6.9669 5.2703L5.95267 8.31595C5.88232 8.52723 5.74346 8.70897 5.5581 8.83238L3.79454 10.0066L5.56417 11.2036C5.74609 11.3266 5.88244 11.5061 5.95217 11.7144L7.00288 14.8528L8.05582 11.7138C8.12553 11.506 8.26162 11.3268 8.44312 11.2039L10.1894 10.0211L8.30701 8.80918C8.10915 8.6818 7.96256 8.48872 7.89301 8.26392L6.9669 5.2703Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5014 1C15.8358 1.00015 16.1479 1.16739 16.3333 1.44567L17.2213 2.77902L18.5547 3.66795C18.8329 3.85342 19 4.16565 19 4.5C19 4.83435 18.8329 5.14658 18.5547 5.33205L17.2213 6.22098L16.3333 7.55433C16.1479 7.83261 15.8358 7.99985 15.5014 8C15.1671 8.00015 14.8548 7.83319 14.6692 7.55507L13.7791 6.22124L12.4453 5.33205C12.1671 5.14658 12 4.83435 12 4.5C12 4.16565 12.1671 3.85341 12.4453 3.66795L13.7791 2.77876L14.6692 1.44493C14.8548 1.16681 15.1671 0.99985 15.5014 1ZM15.5002 3.80278L15.3318 4.05507C15.2586 4.16477 15.1644 4.2589 15.0547 4.33205L14.8028 4.5L15.0547 4.66795C15.1644 4.7411 15.2586 4.83523 15.3318 4.94493L15.5002 5.19722L15.6677 4.94567C15.741 4.83566 15.8353 4.74127 15.9453 4.66795L16.1972 4.5L15.9453 4.33205C15.8353 4.25873 15.741 4.16434 15.6677 4.05433L15.5002 3.80278Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5014 12C15.8358 12.0002 16.1479 12.1674 16.3333 12.4457L17.2213 13.779L18.5547 14.668C18.8329 14.8534 19 15.1656 19 15.5C19 15.8344 18.8329 16.1466 18.5547 16.332L17.2213 17.221L16.3333 18.5543C16.1479 18.8326 15.8358 18.9998 15.5014 19C15.1671 19.0001 14.8548 18.8332 14.6692 18.5551L13.7791 17.2212L12.4453 16.3321C12.1671 16.1466 12 15.8344 12 15.5C12 15.1656 12.1671 14.8534 12.4453 14.6679L13.7791 13.7788L14.6692 12.4449C14.8548 12.1668 15.1671 11.9999 15.5014 12ZM15.5002 14.8028L15.3318 15.0551C15.2586 15.1648 15.1644 15.2589 15.0547 15.3321L14.8028 15.5L15.0547 15.6679C15.1644 15.7411 15.2586 15.8352 15.3318 15.9449L15.5002 16.1972L15.6677 15.9457C15.741 15.8357 15.8353 15.7413 15.9453 15.668L16.1972 15.5L15.9453 15.332C15.8353 15.2587 15.741 15.1643 15.6677 15.0543L15.5002 14.8028Z"
      fill="#737373"
    />
  </svg>
);

export default SparklesIcon;
