import React from 'react';

const AlignIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1111 12.6667C17.602 12.6667 18 13.0646 18 13.5556L18 17.1111C18 17.602 17.602 18 17.1111 18H11.7778C11.2869 18 10.8889 17.602 10.8889 17.1111V13.5556C10.8889 13.0646 11.2869 12.6667 11.7778 12.6667H13.5556V10.8889H6.44444V12.6667H8.22222C8.71314 12.6667 9.11111 13.0646 9.11111 13.5556L9.11111 17.1111C9.11111 17.602 8.71314 18 8.22222 18H2.88889C2.39797 18 2 17.602 2 17.1111L2 13.5556C2 13.0646 2.39797 12.6667 2.88889 12.6667L4.66667 12.6667V10C4.66667 9.50908 5.06464 9.11111 5.55556 9.11111H9.11111V7.33333H7.33333C6.84241 7.33333 6.44444 6.93536 6.44444 6.44444V2.88889C6.44444 2.39797 6.84241 2 7.33333 2L12.6667 2C13.1576 2 13.5556 2.39797 13.5556 2.88889V6.44444C13.5556 6.93536 13.1576 7.33333 12.6667 7.33333H10.8889V9.11111H14.4444C14.9354 9.11111 15.3333 9.50908 15.3333 10V12.6667H17.1111ZM12.6667 14.4444V16.2222H16.2222V14.4444H12.6667ZM3.77778 16.2222V14.4444H7.33333V16.2222H3.77778ZM11.7778 3.77778L8.22222 3.77778V5.55556H11.7778V3.77778Z"
      fill="#737373"
    />
  </svg>
);

export default AlignIcon;
