import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import SlackIcon from '../_assets/IconSlack.png';
import KoalaButton from 'koala/components/Button';
import Loader from 'components/Loader';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
  state: string;
}

function SlackIntegration(props: Props) {
  const { workspace, integrations, state } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallSlackMutation, { isLoading: isUninstalling }] = useMutation(remoteApi.uninstallSlack, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const handleUninstallSlack = (e: React.MouseEvent) => {
    e.preventDefault();
    uninstallSlackMutation(workspace.slug);
  };

  const slackIntegration = integrations.filter((i) => i.integration_type === 'slack')[0];

  const slackRedirectURL = `https://${process.env.REACT_APP_DOMAIN}/install/slack`;
  const slackOAuthURL =
    'https://slack.com/oauth/v2/authorize' +
    '?scope=app_mentions:read,chat:write,chat:write.public,im:history,links:read,links:write,users:read,users:read.email,channels:join,channels:read,groups:read,im:read' +
    `&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}` +
    `&state=${state}` +
    `&redirect_uri=${encodeURIComponent(slackRedirectURL)}`;
  const uninstallSlackLabel = isUninstalling ? <Loader /> : t('workspaceSettingsIntegrations.remove');

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={SlackIcon} alt="Slack" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.slackHeader')}</h3>
        <p>{t('workspaceSettingsIntegrations.slackInfo')}</p>
        <p>
          <a href="https://guides.tability.app/docs/integrations/slack" target="_blank" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {slackIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: slackIntegration.connected_to }}
              />
            </div>
            <KoalaButton href={slackOAuthURL} appearance="secondary" size="small">
              {t('workspaceSettingsIntegrations.reinstall')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={handleUninstallSlack}>
              {uninstallSlackLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!slackIntegration && (
          <KoalaButton href={slackOAuthURL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Slack' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default SlackIntegration;
