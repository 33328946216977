import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative } from 'types';

const BlockGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 10rem 1fr 20rem;
  grid-template-rows: auto;
  gap: ${theme.spacing.x2};

  > div {
    display: flex;
    align-items: flex-start;
    align-items: center;
  }

  .label {
    justify-content: flex-end;
  }
  .text {
    font-family: monospace, 'Courier New';
    font-size: 1.3rem;
  }

  .meta {
  }

  &.closed {
    opacity: 0.5;
  }

  &:hover,
  &.selected,
  &:focus {
    opacity: 1;
    .block-actions {
      opacity: 1;
    }
  }

  .block-actions {
    opacity: 0;
  }
`;

const CheckBox = styled.div`
  line-height: 1;
  margin-right: ${theme.spacing.x1};
  box-sizing: border-box;
  [type='checkbox'],
  [type='checkbox'] {
    position: absolute;
    visibility: hidden;
    display: none;
  }
  [type='checkbox'] + label {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    border: 2px solid #7e98e6;
    background: #fff;
    color: #a9a9a9;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 50%;
  }

  [type='checkbox']:not(:checked) + label {
    color: #fff;
    &:hover {
      border: 2px solid #293c9d;
    }
  }

  [type='checkbox']:checked + label {
    color: #293c9d;
    background: #7e98e6;
    border: 2px solid #7e98e6;
    &:hover {
      border: 2px solid #293c9d;
    }
  }
`;

interface Props {
  initiative: Initiative;
}
function PresentationInitiative(props: Props) {
  const { initiative } = props;
  const [checked, setChecked] = useState(false);

  return (
    <BlockGrid>
      <div className="label"></div>
      <div>
        <CheckBox>
          <input
            type="checkbox"
            id={`initiative-${initiative.id}`}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <label htmlFor={`initiative-${initiative.id}`}>&#10003;</label>
        </CheckBox>
        <span className="text">{initiative.title}</span>
      </div>
      <div />
    </BlockGrid>
  );
}
export default PresentationInitiative;
