import FormField from 'components/FormField';
import React, { useEffect, useState } from 'react';
import * as remoteApi from 'api/remote';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { Initiative } from 'types';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';

interface Props {
  workspaceSlug: string;
  planId: string;
  setInitiativeId: (value: string) => void;
  initiative?: Initiative;
}

function InitiativeSelect(props: Props) {
  const { setInitiativeId, planId, initiative } = props;
  const { t } = useTranslation();
  const [selectedInitiativeOption, setSelectedInitiativeOption] = useState<KoalaSelectOption | null>(null);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const handleSelection = (selected: any) => {
    setSelectedInitiativeOption(selected);
    setInitiativeId(selected.value);
  };

  useEffect(() => {
    setSelectedInitiativeOption(null);
  }, [planId]);

  useEffect(() => {
    if (initiative) {
      setSelectedInitiativeOption({ value: initiative.id, label: initiative.title });
    }
  }, [initiative]);

  // Get the objectives
  const { isLoading: initiativesAreLoading, data: initiativesResponse } = useQuery(
    [queryKeys.initiatives, planId],
    remoteApi.fetchPlanInitiatives,
    {
      staleTime: 0,
    },
  );

  const initiativeOptions: KoalaSelectOption[] = initiativesResponse?.data.map((init: Initiative) => ({
    label: init.title,
    value: init.id,
  }));

  if (initiativesAreLoading) {
    return (
      <FormField>
        <label>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 1)}</label>
        <KoalaLoader />
      </FormField>
    );
  }

  const label = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();
  const placeholder = t(`workspaceDashboards.placeholder`, { item: label });

  return (
    <FormField>
      <label>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 1)}</label>
      <KoalaSelect
        handleChange={handleSelection}
        placeholder={placeholder ?? `Select ${label} or type to search`}
        selectedOption={selectedInitiativeOption}
        isClearable
        options={initiativeOptions}
        size="small"
        id="initiative"
      />
    </FormField>
  );
}
export default InitiativeSelect;
