/**
 * Outcome component
 */
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
//import * as TabilityTypes from '../../../types';
import styled from 'styled-components';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

// API
import * as remoteApi from 'api/remote';

// Actions
import {
  editorSetDisplayNewBlockForm,
  editorSelectBlock,
  editorDeleteInitiative,
} from 'state/actions/workspaceEditorActions';

// UI Components
import BlockGrid, { BlockGutter, BlockContent, BlockMeta } from './BlockGrid';
import { Draggable } from 'react-beautiful-dnd';
import DropdownMenu from '../shared/DropdownMenu';
import InitiativeBlockMeta from './InitiativeBlockMeta';
import InitiativeLabel from './InitiativeLabel';
import InitiativeTitle from './InitiativeTitle';
import InitiativeForm from './InitiativeForm';
import IconJiraPng from 'components/_assets/IconJira.png';
import IconClickupPng from 'components/_assets/IconClickup.png';
import IconAsanaPng from 'components/_assets/IconAsana.png';

import Loader from 'components/Loader';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

interface Props {
  initiativeId: string;
  initiativeIndex: number;
  outcomeIndex: number;
  objectiveIndex: number;
  outcomeId: string;
  objectiveId: string;
}

const IntegrationIcon = styled.img`
  height: 2rem;
  margin-right: 0.8rem;
  position: absolute;
  left: 0;
  top: 2px;
`;

const IntegrationTitle = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: #6a6a6a;
  position: relative;
  span {
    margin-left: 3.2rem;
  }
`;

const InitiativeWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  font-size: 1.8rem;

  border: ${(props: any) => (props.isDragging ? '2px solid rgba(244, 205, 201, 1)' : '0px solid #fff')};
  position: relative;
`;

const InitiativeContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;

  .block-title {
    flex: 1;
  }
`;

function Initiative(props: Props) {
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const history = useHistory();
  const { initiativeId, objectiveIndex, outcomeIndex, initiativeIndex, outcomeId, objectiveId } = props;
  const { t } = useTranslation();
  // Get the data needed to build the component
  const initiative = useSelector((state: any) => state.editorEntities.initiatives[initiativeId]);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [archiveInitiativeMutation, { isLoading: isArchiving }]: [any, any] = useMutation(remoteApi.archiveInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
    },
  });

  const archiveInitiative = () => {
    const params = {
      archived: true,
    };
    const mutationParams = {
      initiativeId,
      initiative: params,
    };
    archiveInitiativeMutation(mutationParams);
  };

  // Get the data needed to build the component
  const hasInitiative = useSelector((state: any) => {
    return state.editorEntities.initiatives[initiativeId];
  });

  const blockId = `initiative:${initiativeId}`;
  const isSelected = useSelector((state: any) => state.editorUI.selectedBlockId === blockId);
  // Block form if (1) the block is selected AND (2) the displayBlockForm flag is true
  const displayForm = useSelector((state: any) => {
    return isSelected && state.editorUI.displayNewBlockForm;
  });

  // Extract the review comments
  const reviewCommentsCount = useSelector((state: any) => {
    if (state.editorEntities.reviewCommentsByBlockId[blockId]) {
      const reviewComments = state.editorEntities.reviewCommentsByBlockId[blockId];
      let count = 0;
      reviewComments.forEach((reviewComment: any) => {
        count = count + 1 + reviewComment.review_replies_count; // count the initial comment and all replies;
      });
      return count;
    }
    return 0;
  });

  if (!hasInitiative || !initiative) {
    return null;
  }

  // Creates the block ID

  const selectedClass = isSelected ? 'selected' : '';

  const handleClick = (e: any) => {
    dispatch(editorSelectBlock(blockId));
  };

  const handleEdit = () => {
    const hashPath = `#initiative:${initiative.nano_slug}:edit`;
    history.push(hashPath);
  };

  const handleCreateComment = () => {
    const hashPath = `#editor:${blockId}:comment`;
    history.push(hashPath);
  };

  const handleDelete = () => {
    const initLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();
    const deletePlaceholder =
      t('workspacePlan.write.deleteConfirm', {
        label: initLabel,
      }) ?? `Are you sure that you want to delete this ${initLabel}?`;
    if (window.confirm(deletePlaceholder)) {
      dispatch(editorDeleteInitiative(initiativeId));
    }
  };

  const handleArchive = () => {
    const initLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();
    const archivePlaceholder =
      t('workspacePlan.write.archiveConfirm', {
        label: initLabel,
      }) ?? `Are you sure that you want to archive this ${initLabel}?`;
    if (window.confirm(archivePlaceholder)) {
      archiveInitiative();
    }
  };

  const handlePlusSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'show-initiative-form':
        dispatch(editorSelectBlock(blockId));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
      case 'show-outcome-form':
        dispatch(editorSelectBlock(`outcome:${outcomeId}`));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
      case 'show-objective-form':
        dispatch(editorSelectBlock(`objective:${objectiveId}`));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
    }
  };

  const handleDragSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'edit':
        handleEdit();
        break;
      case 'comment':
        handleCreateComment();
        break;
      case 'archive':
        handleArchive();
        break;
      case 'delete':
        handleDelete();
        break;
    }
  };

  const plusMenuItems = [
    <span data-action="show-objective-form">
      {t('workspacePlan.write.addObjective', {
        label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
      })}
    </span>,
    <span data-action="show-outcome-form">
      {t('workspacePlan.write.addObjective', {
        label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase(),
      })}
    </span>,
    <span data-action="show-initiative-form">
      {t('workspacePlan.write.addObjective', {
        label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
      })}
    </span>,
  ];

  const dragMenuItems = [
    <span data-action="edit">{t('shared.edit')}</span>,
    <span data-action="comment">{t('workspacePlan.write.addComment')}</span>,
    <span data-action="archive">{t('shared.archive')}</span>,
    <span data-action="delete">{t('shared.delete')}</span>,
  ];

  const commentedClass = reviewCommentsCount > 0 ? 'commented' : '';

  const isJiraIssue = initiative.integration_type === 'jira_cloud' && initiative.integration_remote_id;
  const isClickupTask =
    initiative.integration_type === 'clickup' && initiative.integration_remote_id && initiative.integration_meta.status;
  const isAsanaTask = initiative.integration_type === 'asana' && initiative.integration_remote_id;

  const joyrideSelector = `initiative-${objectiveIndex}-${outcomeIndex}-${initiativeIndex}`;

  return (
    <Draggable draggableId={blockId} index={props.initiativeIndex} key={blockId}>
      {(provided, snapshot) => {
        return (
          <InitiativeWrapper ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <BlockGrid
              className={`initiative editor-block  ${selectedClass} ${commentedClass} ${joyrideSelector}`}
              onClick={handleClick}
            >
              <BlockGutter>
                {isArchiving && <Loader size="small" />}
                <DropdownMenu
                  trigger={
                    <KoalaIconButton
                      iconName="plus"
                      edge="square"
                      size="small"
                      className="icon-button"
                      dataFor={`${blockId}-plus-menu`}
                      iconColor={theme.colors.N40}
                      onClick={handleClick}
                    />
                  }
                  onSelection={handlePlusSelection}
                  items={plusMenuItems}
                />
                <DropdownMenu
                  trigger={
                    <KoalaIconButton
                      iconName="grab"
                      edge="square"
                      size="small"
                      className="icon-button"
                      dataFor={`${blockId}-drag-menu`}
                      onClick={handleClick}
                    />
                  }
                  onSelection={handleDragSelection}
                  items={dragMenuItems}
                />
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  id={`${blockId}-plus-menu`}
                  className="tooltip"
                  effect="solid"
                  delayShow={200}
                >
                  {t('workspacePlan.write.addTooltip')}
                </ReactTooltip>
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  id={`${blockId}-drag-menu`}
                  className="tooltip"
                  effect="solid"
                  html={true}
                  delayShow={200}
                >
                  {t('workspacePlan.write.dragTooltip')}
                </ReactTooltip>
              </BlockGutter>
              <BlockContent className="initiative">
                <InitiativeContentWrapper>
                  <InitiativeLabel>&#10003;</InitiativeLabel>
                  {isJiraIssue && (
                    <IntegrationTitle>
                      <IntegrationIcon src={IconJiraPng} />
                      <span>{initiative.integration_title}</span>
                    </IntegrationTitle>
                  )}
                  {isClickupTask && (
                    <IntegrationTitle>
                      <IntegrationIcon src={IconClickupPng} />
                      <span>{initiative.integration_title}</span>
                    </IntegrationTitle>
                  )}
                  {isAsanaTask && (
                    <IntegrationTitle>
                      <IntegrationIcon src={IconAsanaPng} />
                      <span>{initiative.integration_title}</span>
                    </IntegrationTitle>
                  )}
                  {!isJiraIssue && !isClickupTask && !isAsanaTask && <InitiativeTitle initiativeId={initiativeId} />}
                </InitiativeContentWrapper>
              </BlockContent>
              <BlockMeta>
                <InitiativeBlockMeta initiativeId={initiativeId} reviewCommentsCount={reviewCommentsCount} />
              </BlockMeta>
            </BlockGrid>
            {displayForm && <InitiativeForm selectedInitiativeId={initiativeId} outcomeId={outcomeId} />}
          </InitiativeWrapper>
        );
      }}
    </Draggable>
  );
}

const MemoInitiative = React.memo(Initiative);

export default MemoInitiative;
