import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import theme from 'theme';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory, Link, Redirect } from 'react-router-dom';
import * as amplitudeUtils from 'utils/amplitude';

// API
import { Helmet } from 'react-helmet';
import { fetchDiscoverableWorkspaces } from 'api/remote';
// Actions
import { sessionActions } from 'state/actions/';

import KoalaButton from 'koala/components/Button';

// Components
import Loader from 'components/Loader';

import Wordmark from 'components/Wordmark';
import KoalaTextButton from 'koala/components/TextButton';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: ${theme.spacing.x8};

  min-width: 60rem;
  width: 60rem;

  @media (max-width: 800px) {
    width: 100%;
    min-width: initial;
    margin-top: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};

    .hide-mobile {
      display: none;
    }

    .special-mobile {
      position: relative;
      bottom: ${theme.spacing.x3};
    }
  }
`;

const Instruction = styled.p`
  padding: ${theme.spacing.x2} 0;

  font-size: 2.2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${theme.spacing.x4} 0 ${theme.spacing.x2} 0;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Footer = styled.div`
  margin-bottom: ${theme.spacing.x3};

  button.secondary {
    color: ${theme.colors.subtleText};
    text-decoration: none;
    font-weight: normal;
  }
`;

const WorkspacesList = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  flex-wrap: wrap;
  flex-direction: column;
`;

const WorkspaceCard = styled(Link)`
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  color: ${theme.colors.black};

  padding: ${theme.spacing.x1};
  border-radius: 8px;

  &:hover {
    background: #e7e7e7;
  }
`;

const workspaceQueryKey = queryKeys.workspaces;
const staleTime = 5 * 60 * 1000;

function Workspaces() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.onboarding.discoverable', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const handleLogout = (e: any) => {
    dispatch(sessionActions.logout());
  };

  const {
    isLoading,
    isError,
    data: workspacesResponse,
  }: any = useQuery(workspaceQueryKey, fetchDiscoverableWorkspaces, {
    staleTime,
    onSuccess: (response) => {
      if (response.data.length === 0) {
        history.push(`/onboarding/workspaces/new`);
      }
    },
  });

  if (isLoading) {
    return (
      <Container>
        <Loader size="medium" />
      </Container>
    );
  }

  if (isError) {
    return <Redirect to={`/onboarding/workspaces/new`} />;
  }

  let workspaces = [];

  if (workspacesResponse && workspacesResponse.data) {
    workspaces = workspacesResponse.data;
  }

  if (isError) {
    return <Redirect to={`/onboarding/workspaces/new`} />;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{t('dashboardWorkspaces.title')} | Tability</title>
      </Helmet>
      <Container>
        <Wordmark type="dark" />
        <Header>
          <h1>{t('dashboardWorkspaces.found')}</h1>
        </Header>
        <p>{t('dashboardWorkspaces.line1')}</p>
        <p>{t('dashboardWorkspaces.line2')}</p>
        <Instruction>{t('dashboardWorkspaces.instruction')}</Instruction>
        <WorkspacesList>
          {workspaces.map((w: any) => {
            const userLabel = w.memberships_count !== 1 ? 'users' : 'user';

            return (
              <WorkspaceCard to={`/${w.slug}`}>
                <h3>{w.name}</h3>
                <span className="subtle">{w.url}</span>
                <span>
                  {w.memberships_count} {userLabel}
                </span>
              </WorkspaceCard>
            );
          })}
        </WorkspacesList>
        <Instruction>{t('dashboardWorkspaces.newWorkspace')}</Instruction>
        <div>
          <KoalaButton isLink to={`/onboarding/workspaces/new`}>
            {t('dashboardWorkspaces.newButton')}
          </KoalaButton>
        </div>
      </Container>
      <Footer>
        <KoalaTextButton onClick={handleLogout} appearance="subtle">
          {t('shared.logout')}
        </KoalaTextButton>
      </Footer>
    </Wrapper>
  );
}

export default Workspaces;
