import styled from 'styled-components';
import theme from 'theme';
import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import queryKeys from 'config/queryKeys';
import * as routes from 'routes';

// API
import * as remoteApi from 'api/remote';
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';
import OutcomesIcon from 'components/_assets/OutcomesIcon';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import PlanIconLabel from 'components/PlanIconLabel';
import { useTranslation } from 'react-i18next';

const PlanOption = styled(Link)`
  display: flex;
  padding: ${theme.spacing.x1} ${theme.spacing.x1};
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.4rem;
  &.active {
    cursor: initial;
  }
  &:hover,
  &.active {
    background: #f3f3f3;
  }

  &.outcome {
    svg {
      height: 1.4rem;
      min-height: 1.4rem;
      margin-right: ${theme.spacing.x1};
      path {
        stroke: ${theme.colors.blue};
      }
    }
  }

  span {
    max-width: 100%;
    text-overflow: ellipsis;
  }
`;

const PlanOptionLoader = styled.div`
  display: flex;
  padding: ${theme.spacing.x1} ${theme.spacing.x1};
  align-items: center;
  border-radius: 4px;
`;

interface Props {
  handleClosePanel: any;
  filterText: string;
}
function SearchResults(props: Props) {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [filteredPlans, setPlanOptions] = useState([]);
  const [filteredOutcomes, setOutcomeOptions] = useState([]);
  const { handleClosePanel, filterText } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const filter = {
    title: filterText,
    archived: false,
  };

  const params = {
    filter,
    order_direction: 'asc',
    order_attribute: 'title',
    limit: 7,
  };

  // Get the plan details
  const { isLoading: isSearchingPlans } = useQuery([queryKeys.plans, workspaceSlug, params], remoteApi.fetchPlans, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const plans = response.data;
      setPlanOptions(plans);
    },
  });

  // Get the outcomes
  const { isLoading: isSearchingOutcomes } = useQuery(
    [queryKeys.outcomes, 'search', { workspaceSlug, filter, limit: 7 }],
    remoteApi.searchOutcomes,
    {
      staleTime: 0,
      onSuccess: (response: any) => {
        const outcomes = response.data;
        setOutcomeOptions(outcomes);
      },
    },
  );

  return (
    <>
      {!isSearchingOutcomes && !isSearchingPlans && filteredPlans.length === 0 && filteredOutcomes.length === 0 && (
        <p>{t('panels.search.noResults')}</p>
      )}

      {isSearchingPlans && (
        <>
          <label>{t('shared.plans')}</label>
          <PlanOptionLoader>
            <Loader />
          </PlanOptionLoader>
        </>
      )}
      {!isSearchingPlans && filteredPlans.length > 0 && (
        <>
          <label>{t('shared.plans')}</label>
          <Fragment>
            {filteredPlans.map((plan: any) => {
              const planRoute = routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
                ':planId',
                plan.nano_slug,
              );
              return (
                <PlanOption key={plan.id} to={planRoute} onClick={handleClosePanel} className="plan">
                  <PlanIconLabel plan={plan} size="medium" crop={40} />
                </PlanOption>
              );
            })}
          </Fragment>
        </>
      )}

      {isSearchingOutcomes && (
        <>
          <label>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</label>
          <PlanOptionLoader>
            <Loader />
          </PlanOptionLoader>
        </>
      )}
      {!isSearchingOutcomes && filteredOutcomes.length > 0 && (
        <>
          <label>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</label>
          <Fragment>
            {filteredOutcomes.map((outcome: any) => {
              const outcomePath = `#outcome:${outcome.nano_slug}:show`;
              return (
                <PlanOption key={outcome.id} to={outcomePath} onClick={handleClosePanel} className="outcome">
                  <OutcomesIcon /> <span>{outcome.title}</span>
                </PlanOption>
              );
            })}
          </Fragment>
        </>
      )}
    </>
  );
}

export default SearchResults;
