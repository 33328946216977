import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  label {
    color: ${theme.colors.N70};
    margin-bottom: ${theme.spacing.x1};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    display: block;
  }
  margin-bottom: ${theme.spacing.x3};

  small {
    margin-top: ${theme.spacing.x1};
    display: block;
  }
`;
