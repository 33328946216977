import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
//import * as TabilityTypes from 'types';
import { useMutation, useQueryCache, useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import BlockActions from 'components/BlockActions';
import DropdownMenu from 'components/DropdownMenu';
import Loader from 'components/Loader';

import IconAsanaPng from 'components/_assets/IconAsana.png';
import IconGitlabPng from 'components/_assets/IconGitlab.png';
import IconBitbucketPng from 'components/_assets/IconBitbucket.png';
import IconConfluencePng from 'components/_assets/IconConfluence.png';
import IconDropboxPng from 'components/_assets/IconDropbox.png';
import IconGithubPng from 'components/_assets/IconGithub.png';
import IconGoogleDrivePng from 'components/_assets/IconGoogleDrive.png';
import IconJiraPng from 'components/_assets/IconJira.png';
import IconLinkPng from 'components/_assets/IconLink.png';
import IconNotionPng from 'components/_assets/IconNotion.png';
import IconSlackPng from 'components/_assets/IconSlack.png';
import IconTrelloPng from 'components/_assets/IconTrello.png';
import IconTwitterPng from 'components/_assets/IconTwitter.png';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import KoalaTextBadge from 'koala/components/TextBadge';

const BlockIcon = styled.div`
  grid-area: icon;
  display: flex;
  align-items: center;

  .image {
    display: block;
    background-color: #fff;
    border-radius: 2px;
    min-width: 6rem;
    min-height: 6rem;
    width: 6rem;
    height: 6rem;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .icon {
    display: block;
    background-color: #fff;
    border-radius: 2px;
    min-width: 6rem;
    min-height: 6rem;
    width: 6rem;
    height: 6rem;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 2.4rem;
    }
  }
`;

const BlockContent = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 1rem;
  gap: ${theme.spacing.half};

  .hide {
    display: none;
  }
  position: relative;
  a,
  small {
    max-width: 55rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`;

const BlockGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 6rem 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'icon content';
  align-items: start;
  column-gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  padding: ${theme.spacing.x1};
  background: ${theme.colors.N3};
  border-radius: 4px;

  &:hover,
  &.selected,
  &:focus {
    opacity: 1;
    background: #e7e7e7;
    .block-actions {
      opacity: 1;
    }
  }

  .block-actions {
    opacity: 0;
  }

  @media ${theme.devices.tablet} {
    grid-template-columns: 4rem 1fr 3rem;
    grid-template-areas: 'icon content action';

    .block-actions {
      opacity: 1;
      box-shadow: none;
      padding: ${theme.spacing.x1} ${theme.spacing.x1} 0rem 0rem;
      background: unset;
    }
  }
`;

const URLFields = styled.div`
  width: 100%;
  div {
    display: flex;
    align-items: center;
    width: 100%;
    label {
      width: 4rem;
      color: ${theme.colors.subtleText};
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.2rem;
      display: block;
    }
  }
  input {
    height: 3.2rem;
    margin: 0 0 ${theme.spacing.x1} ${theme.spacing.x1};
  }
`;

const BlockFormActions = styled.div`
  button {
    margin-right: ${theme.spacing.x1};
  }
`;

interface Props {
  outcomeLink: any;
}

function OutcomeLinkBlock(props: Props) {
  const queryCache = useQueryCache();
  const { outcomeLink } = props;
  const buttonEl: any = useRef();
  const { t } = useTranslation();
  const translationKey = 'panels.shared';
  const [displayForm, setDisplayForm] = useState(false);
  const [title, setTitle] = useState(outcomeLink.title);
  const [url, setUrl] = useState(outcomeLink.url);

  useEffect(() => {
    if (buttonEl && buttonEl.current && displayForm) {
      buttonEl.current.scrollIntoView();
    }
  }, [buttonEl, displayForm]);

  // Construct the query key using the plan Id
  const queryKeyLink = [queryKeys.outcomeLinks, outcomeLink.id];

  // Get the plan details
  const { data: metaResponse, isLoading: isLoadingMeta }: any = useQuery(queryKeyLink, remoteApi.fetchOutcomeLinkMeta, {
    staleTime: 60 * 1000 * 5,
  });

  const meta = metaResponse ? metaResponse.data : {};

  // OutcomeLink mutations
  const [deleteOutcomeLinkMutation, { isLoading: isDeleting }]: [any, any] = useMutation(remoteApi.deleteOutcomeLink, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomeLinks);
    },
  });

  const [updateOutcomeLinkMutation, { isLoading: isUpdating }]: [any, any] = useMutation(remoteApi.updateOutcomeLink, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomeLinks);
      setDisplayForm(false);
    },
  });

  const handleTitleChange = (e: any) => {
    e.preventDefault();
    const title = e.target.value;
    setTitle(title);
  };

  const handleUrlChange = (e: any) => {
    e.preventDefault();
    const url = e.target.value;
    setUrl(url);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      title,
      url,
    };

    updateOutcomeLinkMutation({
      outcomeLinkId: outcomeLink.id,
      outcomeLink: params,
    });

    //scrollToFormInput();
  };

  const hideForm = (e: any) => {
    e.preventDefault();
    setDisplayForm(false);
  };

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    const outcomeLinkId = value.props['data-id'];
    switch (action) {
      case 'edit':
        setDisplayForm(true);
        break;
      case 'delete':
        if (window.confirm('Delete this link?')) {
          deleteOutcomeLinkMutation(outcomeLinkId);
        }
        break;
    }
  };

  const getLinkIcon = (link: any) => {
    const url = link.url;
    let icon = IconLinkPng;

    if (meta.og_image) {
      return meta.og_image;
    }

    switch (true) {
      case /gitlab.com/.test(url):
        icon = IconGitlabPng;
        break;
      case /asana.com/.test(url):
        icon = IconAsanaPng;
        break;
      case /bitbucket.org/.test(url):
        icon = IconBitbucketPng;
        break;
      case /atlassian.net\/wiki/.test(url):
        icon = IconConfluencePng;
        break;
      case /dropbox.com/.test(url):
        icon = IconDropboxPng;
        break;
      case /github.com/.test(url):
        icon = IconGithubPng;
        break;
      case /docs.google.com/.test(url):
        icon = IconGoogleDrivePng;
        break;
      case /atlassian.net/.test(url):
        icon = IconJiraPng;
        break;
      case /notion.so/.test(url):
        icon = IconNotionPng;
        break;
      case /slack.com/.test(url):
        icon = IconSlackPng;
        break;
      case /trello.com/.test(url):
        icon = IconTrelloPng;
        break;
      case /twitter.com/.test(url):
        icon = IconTwitterPng;
        break;
      default:
        break;
    }

    return icon;
  };

  const isFetching = isDeleting || isUpdating;

  const menuItems = [
    <span key="edit" data-action="edit" data-id={outcomeLink.id}>
      {t('shared.edit')}
    </span>,
    <span key="delete" data-action="delete" data-id={outcomeLink.id}>
      {t('shared.delete')}
    </span>,
  ];

  const imageClass = meta.og_image ? 'image' : 'icon';
  return (
    <BlockGrid>
      <BlockIcon>
        {(isFetching || isLoadingMeta) && <Loader size="small" />}
        {!isFetching && (
          <a className={imageClass} href={outcomeLink.url} target="_blank" rel="noopener noreferrer">
            <img src={getLinkIcon(outcomeLink)} alt="" />
          </a>
        )}
      </BlockIcon>
      {!displayForm && (
        <BlockContent>
          <a href={outcomeLink.url} target="_blank" rel="noopener noreferrer" className="header">
            {meta.title || outcomeLink.title}
          </a>
          <small className="subtle">
            <a href={outcomeLink.url} target="_blank" rel="noopener noreferrer" className="subtle">
              {outcomeLink.url}
            </a>
          </small>
          {meta.label && (
            <KoalaTextBadge variant="neutral-light" size="small">
              {meta.label}
            </KoalaTextBadge>
          )}
        </BlockContent>
      )}
      {displayForm && (
        <BlockContent>
          <URLFields>
            <div>
              <label>{t(`${translationKey}.url`)}</label>
              <input
                value={url}
                onChange={handleUrlChange}
                placeholder={`URL`}
                type={t(`${translationKey}.url`) ?? 'url'}
              />
            </div>
            <div>
              <label>{t(`${translationKey}.title`)}</label>
              <input
                value={title}
                onChange={handleTitleChange}
                placeholder={t(`${translationKey}.urlTitle`) ?? `Title (optional)`}
              />
            </div>
          </URLFields>
          <BlockFormActions>
            <KoalaButton
              size="small"
              disabled={isFetching}
              loading={isFetching}
              onClick={handleSubmit}
              ref={buttonEl}
              submit
            >
              {t('shared.add')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={hideForm}>
              {t('shared.cancel')}
            </KoalaTextButton>
          </BlockFormActions>
        </BlockContent>
      )}
      <BlockActions className="block-actions">
        <DropdownMenu
          trigger={<KoalaIconButton iconName="ellipsis" edge="square" size="small" />}
          onSelection={handleMenuSelection}
          items={menuItems}
        />
      </BlockActions>
    </BlockGrid>
  );
}

export default React.memo(OutcomeLinkBlock);
