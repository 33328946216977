import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import fr from './locales/fr.json';
import es from './locales/es.json';
import pt from './locales/pt.json';
import de from './locales/de.json';
import vi from './locales/vi.json';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const DEFAULT_LOCALE = 'en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      es: { translation: es },
      pt: { translation: pt },
      de: { translation: de },
      vi: { translation: vi },
    },
    fallbackLng: DEFAULT_LOCALE,
    debug: true,
    supportedLngs: ['en', 'fr', 'de', 'pt', 'es', 'vi'],
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export default i18n;
