// Check if plan has a timeline
import * as TabilityTypes from 'types';

const starterEnabledSubscriptions = [
  'tability20-starter',
  'tability20-essentials',
  'tability20-automation',
  'tability20-unlimited',
];
const essentialsEnabledSubscriptions = ['tability20-essentials', 'tability20-automation', 'tability20-unlimited'];
const automationEnabledSubscriptions = ['tability20-automation', 'tability20-unlimited'];
const unlimitedEnabledSubscriptions = ['tability20-unlimited'];

// pricing v2
const essentialsPerUserEnabledSubscriptions = [
  'tability2-essentials-peruser-monthly',
  'tability2-premium-peruser-monthly',
];
const premiumPerUserEnabledSubscriptions = ['tability2-premium-peruser-monthly'];

const v4PremiumSubscriptions = ['tability-v4-premium'];
const v4PlusSubscriptions = ['tability-v4-plus', 'tability-v4-premium'];
const v4BasicSubscriptions = ['tability-v4-basic', 'tability-v4-plus', 'tability-v4-premium'];
const v4FreeSubscriptions = ['tability-v4-free', 'tability-v4-basic', 'tability-v4-plus', 'tability-v4-premium'];

const v5PremiumSubscriptions = ['tability-v5-premium-monthly', 'tability-v5-premium-annual'];
const v5BasicSubscriptions = [
  'tability-v5-basic-monthly',
  'tability-v5-basic-annual',
  'tability-v5-premium-monthly',
  'tability-v5-premium-annual',
];

export const BasicSubscriptions = [...v4BasicSubscriptions, ...v5BasicSubscriptions];
export const PremiumSubscriptions = [...v4PremiumSubscriptions, ...v5PremiumSubscriptions];

// Returns true if the workspace has a subscription
export const hasSubscription = (workspace: TabilityTypes.Workspace): boolean => {
  return workspace.billing_has_subscription;
};

// Checks if we have exceeded the number of user
export const hasExceededUserLimit = (workspace: TabilityTypes.Workspace): boolean => {
  // By convention we have unlimited users if billing_user_limit is at 0.
  if (workspace.billing_user_limit === 0) {
    return false;
  }

  return workspace.memberships_count > workspace.billing_user_limit;
};

// Checks if we have exceeded the number of checkins
export const hasExceededCheckinsLimit = (workspace: TabilityTypes.Workspace): boolean => {
  // By convention we have unlimited checkins if billing_checkins_limit is at 0.
  if (workspace.billing_checkins_limit === 0) {
    return false;
  }

  return workspace.checkins_count >= workspace.billing_checkins_limit;
};

export const hasStarterSubscription = (workspace: TabilityTypes.Workspace) => {
  // Allow Starter features if the workspace has the flag billing_ignore_restrictions set to true
  if (workspace && workspace.billing_ignore_restrictions) {
    return true;
  }
  return (
    workspace && hasSubscription(workspace) && starterEnabledSubscriptions.includes(workspace.billing_subscription_plan)
  );
};

export const hasEssentialsSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && essentialsEnabledSubscriptions.includes(workspace.billing_subscription_plan);
};

export const hasAutomationSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && automationEnabledSubscriptions.includes(workspace.billing_subscription_plan);
};

export const hasUnlimitedSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && unlimitedEnabledSubscriptions.includes(workspace.billing_subscription_plan);
};

// v2 pricing
export const hasEssentialsPerUserSubscription = (workspace: TabilityTypes.Workspace) => {
  return (
    hasSubscription(workspace) && essentialsPerUserEnabledSubscriptions.includes(workspace.billing_subscription_plan)
  );
};

export const hasPremiumPerUserSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && premiumPerUserEnabledSubscriptions.includes(workspace.billing_subscription_plan);
};

export const hasV4FreeSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && v4FreeSubscriptions.includes(workspace.billing_subscription_plan);
};

export const hasV4BasicSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && v4BasicSubscriptions.includes(workspace.billing_subscription_plan);
};

export const hasV4PlusSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && v4PlusSubscriptions.includes(workspace.billing_subscription_plan);
};

export const hasV4PremiumSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && v4PremiumSubscriptions.includes(workspace.billing_subscription_plan);
};

export const hasV5BasicSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && v5BasicSubscriptions.includes(workspace.billing_subscription_plan);
};

export const hasV5PremiumSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && v5PremiumSubscriptions.includes(workspace.billing_subscription_plan);
};

export const hasPremiumSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && PremiumSubscriptions.includes(workspace.billing_subscription_plan);
};

export const hasBasicSubscription = (workspace: TabilityTypes.Workspace) => {
  return hasSubscription(workspace) && BasicSubscriptions.includes(workspace.billing_subscription_plan);
};

export const hasV4Trial = (workspace: TabilityTypes.Workspace) => {
  return !hasSubscription(workspace) && workspace.pricing_version === 4;
};

export const hasV5Trial = (workspace: TabilityTypes.Workspace) => {
  return !hasSubscription(workspace) && workspace.pricing_version === 5;
};

export const hasTrial = (workspace: TabilityTypes.Workspace) => {
  return hasV4Trial(workspace) || hasV5Trial(workspace);
};

export const planIdToString = (planId: string) => {
  switch (planId) {
    case 'tability20-starter':
      return 'Tability Starter';
    case 'tability20-essentials':
      return 'Tability Essentials';
    case 'tability20-automation':
      return 'Tability Automation';
    case 'tability20-unlimited':
      return 'Tability Unlimited';
    case 'tability2-essentials-peruser-monthly':
      return 'Tability Essentials';
    case 'tability2-premium-peruser-monthly':
      return 'Tability Premium';
    case 'tability-v4-free':
      return 'Free';
    case 'tability-v4-basic':
      return 'Basic';
    case 'tability-v4-plus':
      return 'Plus';
    case 'tability-v4-premium':
      return 'Premium';
    case 'tability-v5-basic-monthly':
      return 'Basic Monthly';
    case 'tability-v5-basic-annual':
      return 'Basic Annual';
    case 'tability-v5-premium-monthly':
      return 'Premium Monthly';
    case 'tability-v5-premium-annual':
      return 'Premium Annual';
    default:
      return "Couldn't find your plan – please contact Tability";
  }
};

export const billingSubscriptionToString = (workspace: TabilityTypes.Workspace) => {
  if (!hasSubscription(workspace)) {
    if (workspace.pricing_version === 4 || workspace.pricing_version === 5) {
      return 'Trial';
    }
    return 'Free';
  }

  switch (workspace.billing_subscription_plan) {
    case 'tability20-starter':
      return 'Tability Starter';
    case 'tability20-essentials':
      return 'Tability Essentials';
    case 'tability20-automation':
      return 'Tability Automation';
    case 'tability20-unlimited':
      return 'Tability Unlimited';
    case 'tability2-essentials-peruser-monthly':
      return 'Tability Essentials';
    case 'tability2-premium-peruser-monthly':
      return 'Tability Premium';
    case 'tability2-10seats':
      return 'Tability 10 Seats';
    case 'tability2-50seats':
      return 'Tability 50 Seats';
    case 'tability2-200seats':
      return 'Tability 200 Seats';
    case 'tability2-500seats':
      return 'Tability 500 Seats';
    case 'tability-v4-free':
      return 'Free';
    case 'tability-v4-basic':
      return 'Basic';
    case 'tability-v4-plus':
      return 'Plus';
    case 'tability-v4-premium':
      return 'Premium';
    case 'tability-v5-basic-monthly':
      return 'Basic Monthly';
    case 'tability-v5-basic-annual':
      return 'Basic Annual';
    case 'tability-v5-premium-monthly':
      return 'Premium Monthly';
    case 'tability-v5-premium-annual':
      return 'Premium Annual';
    default:
      return workspace.billing_subscription_plan;
  }
};

export const billingSbuscriptionToCost = (workspace: TabilityTypes.Workspace) => {
  if (!hasSubscription(workspace)) {
    return 0;
  }

  switch (workspace.billing_subscription_plan) {
    case 'tability20-starter':
      return 39;
    case 'tability20-essentials':
      return 99;
    case 'tability20-automation':
      return 299;
    case 'tability20-unlimited':
      return 599;
    default:
      return 0;
  }
};
