import styled from 'styled-components'; // Fixing the build
import theme from 'theme';

import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useParams, Link } from 'react-router-dom';

import KoalaProgressBar from 'koala/components/ProgressBar';
import { Trans, useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  max-width: 60rem;
  margin: 0 auto;
`;

const OnboardingItems = styled.div`
  width: 100%;
  padding: ${theme.spacing.x2};
  margin-top: ${theme.spacing.x1};
  background: ${theme.colors.N3};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};

  ul {
    list-style-type: disc;

    margin-left: ${theme.spacing.x2};
    margin-top: ${theme.spacing.half};
    margin-bottom: ${theme.spacing.half};

    li {
      padding-bottom: ${theme.spacing.x2};
      color: ${theme.colors.N90};

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  h3 {
    margin: ${theme.spacing.x3} 0 ${theme.spacing.x1};

    &:first-of-type {
      margin-top: 0;
    }
  }
  p {
    b {
      font-weight: 800;
    }
    a {
      color: ${theme.colors.B50};
      &:hover {
        text-decoration: underline;
      }
    }
    &.completed {
      text-decoration: line-through;
    }
  }
`;

function OnboardingChecklist() {
  const dispatch = useDispatch();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const { t } = useTranslation();
  const [stats, setStats]: any = useState(null);

  const statsQueryKey = [queryKeys.currentWorkspace, workspaceSlug, 'stats'];

  useQuery(statsQueryKey, remoteApi.fetchWorkspaceStats, {
    staleTime: 0,
    onSuccess: (response) => {
      setStats(response.data);
    },
  });

  let planClass = '';
  let userClass = '';
  let integrationClass = '';
  let checkinClass = '';

  // Return true if one of the following actions have not been done:
  // - There are no published plans
  // - There's only one user
  // - There are no closed tasks
  // - There are no checkins
  const calculateStepsCompleted = (stats: any) => {
    let completedSteps = 1; // counting the workspace creation

    if (stats.published_plans_count > 0) {
      completedSteps++;
      planClass = 'completed';
    }

    if (stats.users_count > 1) {
      completedSteps++;
      userClass = 'completed';
    }

    if (stats.integrations_count > 0) {
      completedSteps++;
      integrationClass = 'completed';
    }

    if (stats.checkins_count > 0) {
      completedSteps++;
      checkinClass = 'completed';
    }

    if (currentWorkspace.billing_subscription_plan) {
      completedSteps++;
    }
    return completedSteps;
  };

  if (!stats) {
    return null;
  }

  const stepsCompleted = calculateStepsCompleted(stats);
  const stepsTotal = 6;

  // Don't display if we have completed the 5 steps.
  if (stepsCompleted >= stepsTotal) {
    return null;
  }

  const prct = (stepsCompleted / stepsTotal) * 100;

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t('workspaceInbox.onboarding.modalTitle')}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Container>
          <KoalaProgressBar value={prct} confidence="blue" appearance="secondary" />
          <OnboardingItems>
            <h3>{t('workspaceInbox.onboarding.checkistTitle')}</h3>
            <p className="completed">
              <b>1.</b> {t('workspaceInbox.onboarding.step1')}
            </p>
            <p className={planClass}>
              <b>2.</b>{' '}
              <Link to={`/${workspaceSlug}/plans`} className="primary">
                {t('workspaceInbox.onboarding.step3')}
              </Link>
            </p>
            <p className={userClass}>
              <b>3.</b>{' '}
              <Link to={`/${workspaceSlug}/people`} className="primary">
                {t('workspaceInbox.onboarding.step2')}
              </Link>
            </p>
            <p className={integrationClass}>
              <b>4.</b>{' '}
              <Link to={`/${workspaceSlug}/settings/integrations`} className="primary">
                {t('workspaceInbox.onboarding.step4')}
              </Link>
            </p>
            <p className={checkinClass}>
              <b>5.</b>{' '}
              <a
                href="https://guides.tability.app/docs/features/check-ins/how-to-create-check-ins"
                target="_blank"
                rel="noopener noreferrer"
                className="primary"
              >
                {t('workspaceInbox.onboarding.step5')}
              </a>
            </p>
            <p className={integrationClass}>
              <b>6.</b>{' '}
              <Link to={`/${workspaceSlug}/settings/billing`} className="primary">
                {t('workspaceInbox.onboarding.step6')}
              </Link>
            </p>
            <h3>{t('workspaceInbox.onboarding.boostTitle')}</h3>
            <p>{t('workspaceInbox.onboarding.boostContent')}</p>
            <p>
              <Trans
                i18nKey="workspaceInbox.onboarding.boostScheduleCall"
                components={{
                  a: (
                    <a
                      href="https://calendly.com/tability/concierge"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button"
                    >
                      Book a call with our team
                    </a>
                  ),
                }}
              />
            </p>

            <h3>{t('workspaceInbox.onboarding.resourcesTitle')}</h3>
            <p>
              <Trans
                i18nKey="workspaceInbox.onboarding.guides"
                components={{
                  a: (
                    <a href="https://guides.tability.app" target="_blank" rel="noopener noreferrer" className="primary">
                      guides
                    </a>
                  ),
                }}
              />
            </p>
          </OnboardingItems>
          <small className="subtle">{t('workspaceInbox.onboarding.checklistDismiss')}</small>
        </Container>
      </ModalContent>
    </ModalGrid>
  );
}

export default OnboardingChecklist;
