import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import HelpScoutIcon from 'components/_assets/IconHelpScout.png';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
  state: string;
}

function HelpScoutIntegration(props: Props) {
  const { workspace, integrations, state } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallHelpScoutMutation, { isLoading: isUninstallingHelpScout }] = useMutation(
    remoteApi.uninstallHelpScout,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
      },
    },
  );

  const helpscoutIntegration = integrations.filter((i: Integration) => i.integration_type === 'helpscout')[0];
  const uninstallHelpScoutLabel = isUninstallingHelpScout ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove');

  const helpScoutOAuthURL =
    `https://secure.helpscout.net/authentication/authorizeClientApplication?` +
    `client_id=${process.env.REACT_APP_HELPSCOUT_CLIENT_ID}&state=${state}
  `;

  const handleUninstallHelpScout = (e: React.MouseEvent) => {
    e.preventDefault();
    uninstallHelpScoutMutation(workspace.slug);
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={HelpScoutIcon} alt="HelpScout" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'Help Scout' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Help Scout' })}</p>
      </IntegrationContent>
      <IntegrationAction>
        {helpscoutIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: helpscoutIntegration.connected_to }}
              />
            </div>
            <KoalaButton href={helpScoutOAuthURL} appearance="secondary" size="small">
              {t('workspaceSettingsIntegrations.reinstall')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={handleUninstallHelpScout}>
              {uninstallHelpScoutLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!helpscoutIntegration && (
          <KoalaButton href={helpScoutOAuthURL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Help Scout' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default HelpScoutIntegration;
