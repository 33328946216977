import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Workspace } from 'types';
import SessionIdleTimer from './SessionIdleTimer';

// const SessionTimeout = ({ isAuthenticated, logOut }) => {
const SessionTimeout = () => {
  const isAuthenticated = useSelector((state: any) => state.session.isAuthenticated);

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  if (isAuthenticated && currentWorkspace && currentWorkspace.session_timeout_enabled) {
    return <SessionIdleTimer timeout={currentWorkspace.session_duration} />;
  }

  return null;
};
export default SessionTimeout;
