import styled from 'styled-components';
import theme from 'theme';
import React, { Fragment } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import queryKeys from 'config/queryKeys';
import parse from 'parse-link-header';
import * as routes from 'routes';
import { useDispatch } from 'react-redux';

// API
import * as remoteApi from 'api/remote';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';
import PlanIconLabel from 'components/PlanIconLabel';

const PlanOptionLoader = styled.div`
  display: flex;
  padding: ${theme.spacing.x1} ${theme.spacing.x1};
  align-items: center;
  border-radius: 4px;
`;
const PlanOption = styled(Link)<{ isfocus: boolean }>`
  display: flex;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.4rem;
  background-color: ${({ isfocus }) => (isfocus ? theme.colors.N5 : 'none')};
  &.active {
    cursor: initial;
    background-color: ${theme.colors.N5};
  }

  &.outcome {
    svg {
      height: 1.4rem;
      min-height: 1.4rem;
      margin-right: ${theme.spacing.x1};
      path {
        stroke: ${theme.colors.blue};
      }
    }
  }

  span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ResultsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface Props {
  focusElement: HTMLElement | null;
  setFocusElement: (e: HTMLElement) => void;
}
function RecentlyViewed(props: Props) {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { focusElement, setFocusElement } = props;
  const dispatch = useDispatch();
  const recentVisitsQueryKey = [queryKeys.recentVisits, workspaceSlug];

  const handleClose = () => {
    dispatch(setGlobalModalContent(''));
  };

  // recently visited
  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  const { isLoadingRecentVisits, data: visitsResponse }: any = useInfiniteQuery(
    recentVisitsQueryKey,
    remoteApi.fetchRecentVisits,
    {
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
    },
  );
  let recentVisits = [];

  if (visitsResponse) {
    recentVisits = visitsResponse;
  }

  return (
    <ResultsContainer>
      {isLoadingRecentVisits && (
        <PlanOptionLoader>
          <Loader />
        </PlanOptionLoader>
      )}
      {recentVisits.length > 0 &&
        recentVisits.map((group: any, i: number) => {
          return (
            <Fragment key={i}>
              {group.data.map((visit: any, index: number) => {
                const plan = visit.visited;
                if (!plan) {
                  return null;
                }
                const planRoute = routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
                  ':planId',
                  plan.nano_slug,
                );
                return (
                  <PlanOption
                    key={plan.id}
                    to={planRoute}
                    onClick={handleClose}
                    className="result-item plan"
                    id={plan.id}
                    isfocus={focusElement?.id === plan.id}
                    onMouseOverCapture={(e) => setFocusElement(e.target as HTMLElement)}
                  >
                    <PlanIconLabel plan={plan} size="medium" crop={78} />
                  </PlanOption>
                );
              })}
            </Fragment>
          );
        })}
    </ResultsContainer>
  );
}

export default RecentlyViewed;
