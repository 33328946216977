import React, { useState } from 'react';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
// API
import * as remoteApi from 'api/remote';

import DisplayCommentBlock from './_DisplayCommentBlock';
import EditCommentBlock from './_EditCommentBlock';
import Loader from 'components/Loader';
import NewReplyBlock from './NewReplyBlock';
import ReplyBlock from './ReplyBlock';

const RepliesContainer = styled.div`
  margin-left: 6.2rem;
`;

const staleTime = 0;

interface Props {
  reviewComment: any;
}

function CommentBlock(props: Props) {
  const { reviewComment } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [reviewReplies, setReviewReplies] = useState([]);

  const { isFetching } = useQuery([queryKeys.reviewReplies, reviewComment.id], remoteApi.fetchReviewReplies, {
    staleTime,
    onSuccess: (response) => {
      setReviewReplies(response.data);
    },
  });

  return (
    <div>
      {isEditing && <EditCommentBlock reviewComment={reviewComment} setIsEditing={setIsEditing} />}
      {!isEditing && <DisplayCommentBlock reviewComment={reviewComment} setIsEditing={setIsEditing} />}
      <RepliesContainer>
        {reviewReplies.map((reviewReply: any) => (
          <ReplyBlock reviewReply={reviewReply} key={reviewReply.id} />
        ))}
        {isFetching && (
          <div style={{ marginBottom: theme.spacing.x1 }}>
            <Loader size="medium" />
          </div>
        )}
        {!reviewComment.resolved && <NewReplyBlock reviewComment={reviewComment} />}
      </RepliesContainer>
    </div>
  );
}

export default React.memo(CommentBlock);
