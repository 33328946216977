import React, { Fragment, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import * as routes from 'routes';
import * as amplitudeUtils from 'utils/amplitude';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';

// Components
import PlanPrezNav from 'components/PlanPrezNav';

import AccessDenied from 'components/AccessDenied';
import { ChromeContent } from 'components/Chrome';
import Loader from 'components/Loader';
import NotFound from 'components/NotFound';

// Tracker component
import PlanOverviewNav from 'components/PlanOverviewNav';
import { Plan } from 'types';
import PresentationModeContent from './PresentationModeContent';
import PresentationModeOverview from './PresentationModeOverview';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import { generateSlug } from 'utils/naming';
import notoSansJP from 'fonts/notoSansJP';
import notoSans from 'fonts/notoSans';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const GridLayout = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: 'header header' 'nav content';
  height: 100%;
  grid-template-rows: auto 1fr;
  grid-template-columns: 25rem 1fr;
  width: 100%;
  height: 100%;

  @media ${theme.devices.laptop} {
    grid-template-columns: 25rem 1fr;
  }
  @media ${theme.devices.tablet} {
    grid-template-columns: 20rem 1fr;
  }
  @media ${theme.devices.mobile} {
    grid-template-columns: 10rem 1fr;
  }
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupBanner = styled.div`
  width: 50rem;
  max-width: 50rem;
  background: #fff;
  padding: ${theme.spacing.x4};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};

  img {
    width: 15rem;
  }

  p {
    b {
      font-size: 2.2rem;
    }
    text-align: center;

    &.tinyimg {
      img {
        width: 8rem;
      }
    }

    &.actions {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        margin: ${theme.spacing.x1};
      }
    }
  }
`;

const ContentContainer = styled.div`
  position: relative;
  grid-area: content;
  padding: ${theme.spacing.x2};
  overflow-y: auto;
  height: 100%;
  background: ${theme.colors.N0};
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &::-webkit-scrollbar-track {
    border-left: solid 1px ${theme.colors.N40};
    border-right: solid 1px ${theme.colors.N40};
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-color: ${theme.colors.N40};
    background-clip: content-box;
  }

  @media ${theme.devices.tablet} {
    padding: 0;
  }
`;

function WorkspacePlanPrezTrack() {
  const { planId } = useParams<{ planId: string }>();
  const match: any = useRouteMatch(routes.WORKSPACE_PLAN_PREZ_OUTCOMES_ROUTE);
  const outcomeId = match ? match.params.outcomeId : null;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plan.present.outcomes.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, planId, 'track'];
  const staleTime = 0;

  // Get the plan details
  const {
    isError,
    error,
    data: planResponse,
    isLoading: planIsLoading,
  }: any = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
  });

  const modifyClone = async (doc: Document) => {
    const chartNodes = doc.querySelectorAll('.svg-chart');
    chartNodes.forEach((chartNode) => {
      // get parent and remove svg chart
      const parent = chartNode.parentNode;
      parent?.removeChild(chartNode);
      //show pdf text
      const textElem: any = parent?.querySelector('.pdf-only-text');
      if (textElem) {
        textElem.style.display = 'block';
      }
    });
  };

  const handleDownload = () => {
    const content: HTMLElement | null = document.querySelector('#pdf-container');
    if (!content || !plan) {
      return null;
    }
    const doc = new jsPDF({
      format: 'a4',
      unit: 'px',
    });

    // Add Noto Sans fonts here
    doc.addFileToVFS('NotoSans.ttf', notoSans);
    doc.addFont('NotoSans.ttf', 'NotoSans', 'normal');
    doc.addFileToVFS('NotoSansJP.ttf', notoSansJP);
    doc.addFont('NotoSansJP.ttf', 'NotoSansJP', 'normal');

    doc.setFont('Inter-Regular', 'normal');
    const filename = `${generateSlug(plan.title)}.pdf`;
    doc.html(content, {
      margin: [40, 50, 40, 50],
      autoPaging: 'text',
      html2canvas: {
        scale: 0.4,
        allowTaint: true,
        useCORS: true,
        taintTest: true,
        ignoreElements: (e) => {
          const list = e.classList;
          return (
            list.contains('avatar') ||
            list.contains('koala-hidden-checkbox') ||
            list.contains('icon--custom') ||
            list.contains('collapse-toggle')
          );
        },
        onclone: modifyClone,
      },
      callback: function (doc) {
        doc.save(filename);
      },
    });
  };

  const plan: Plan = planResponse ? planResponse.data : null;
  const dataIsLoading = planIsLoading;

  // We display the loader until we have loaded the plan and objectives.
  // The reason why we wait for objectives to be loaded here is to check in the Track components
  // if we should display the empty state, or the tracking content
  if (!plan || dataIsLoading) {
    return (
      <Fragment>
        <ChromeContent>
          <LoadingContainer>
            <Loader size="big" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  // Display empty state if plan is still in draft mode
  if (plan.state === 'draft') {
    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | {plan.title} | Tability
          </title>
        </Helmet>
        <ChromeContent>
          <GridLayout>
            <PlanOverviewNav />
            <PlanPrezNav plan={plan} />
            <ContentContainer>
              <Mask>
                <PopupBanner>
                  <p>
                    <b>{t('publicPlan.draft')}</b>
                  </p>
                  <p className="subtle">{t('publicPlan.draftInfo')}</p>
                </PopupBanner>
              </Mask>
            </ContentContainer>
          </GridLayout>
        </ChromeContent>
      </Fragment>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {currentWorkspace.name} | {plan.title} | Tability
        </title>
      </Helmet>
      <ChromeContent>
        <GridLayout>
          <PlanPrezNav plan={plan} handleDownload={handleDownload} />
          <PlanOverviewNav />
          <ContentContainer>
            {outcomeId && <PresentationModeContent outcomeId={outcomeId} plan={plan} />}
            {!outcomeId && <PresentationModeOverview plan={plan} />}
          </ContentContainer>
        </GridLayout>
      </ChromeContent>
    </>
  );
}

export default WorkspacePlanPrezTrack;
