import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import { colors } from 'koala/shared/styles';
import React from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div<{ bgColor?: string; barColor?: string }>`
  width: 100%;
  position: relative;
  background: ${colors.N20};
  height: 8px;
  border-radius: 2px;
  overflow: hidden;

  .progress-inner {
    background: ${colors.B50};
  }

  &.progress-bar--large {
    height: 16px;
    border-radius: 4px;
  }

  &.progress--secondary {
    background: ${colors.N10};
    height: 8px;
    border-radius: 2px;
  }

  &.progress--green {
    background: ${colors.G10};
    .progress-inner {
      background: ${colors.G50};
    }
  }
  &.progress--orange {
    background: ${colors.O20};
    .progress-inner {
      background: ${colors.O50};
    }
  }
  &.progress--yellow {
    background: ${colors.Y20};
    .progress-inner {
      background: ${colors.Y50};
    }
  }
  &.progress--red {
    background: ${colors.P30};
    .progress-inner {
      background: ${colors.R50};
    }
  }
  &.progress--blue {
    background: ${colors.B10};
    .progress-inner {
      background: ${colors.B50};
    }
  }
  &.progress--grey {
    background: ${colors.N10};
    .progress-inner {
      background: ${colors.N60};
    }
  }
  &.progress--custom {
    background: ${(props) => props.bgColor};
    .progress-inner {
      background: ${(props) => props.barColor};
    }
  }
`;

const ProgressBarInner = styled.div<{ value: number; confidence: string }>`
  height: 100%;
  border-radius: 4px;
  width: ${(props) => props.value}%;
  border-radius: 2px 0 0 2px;

  &.progress-inner--secondary {
    border-radius: 2px 0 0 2px;
  }
`;

interface ProgressBarProps {
  /* progress display in the progress bar from 0 to 100 */
  value: number;
  appearance?: (typeof KoalaAppearances)[number];
  dataFor?: string;
  /* if set, will change color of bar based on confidence */
  confidence?: string;
  className?: string;
  style?: React.CSSProperties;
  bgColor?: string;
  barColor?: string;
  /** Optional size from KoalaSizes */
  size?: (typeof KoalaSizes)[number];
}

const KoalaProgressBar: React.FunctionComponent<ProgressBarProps> = (props: ProgressBarProps) => {
  const { value, confidence, dataFor, className, style, appearance, bgColor, barColor, size } = props;

  const progressClass = `progress-bar progress-bar--${size ? size : 'medium'} progress--${appearance ?? 'primary'} ${
    className ?? ''
  } progress--${confidence ?? 'grey'}`;
  const progressInner = `progress-inner progress-inner--${appearance ?? 'primary'} progress-inner--${
    confidence ?? 'grey'
  }`;

  return (
    <ProgressBarContainer
      className={progressClass}
      style={style}
      data-tip={dataFor ? true : false}
      data-for={dataFor}
      bgColor={bgColor}
      barColor={barColor}
    >
      <ProgressBarInner value={value} confidence={confidence ?? ''} className={progressInner} />
    </ProgressBarContainer>
  );
};

KoalaProgressBar.defaultProps = {
  appearance: 'primary',
};

export default KoalaProgressBar;
