import queryKeys from 'config/queryKeys';
import React, { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { User, Membership, Workspace } from 'types';
import * as remoteApi from 'api/remote';
import KoalaIcon from 'koala/components/Icons';
import { shallowEqual, useSelector } from 'react-redux';

import { Menu, MenuItem } from 'react-aria-menubutton';
import Loader from 'components/Loader';
import _ from 'lodash';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';

const UserSelection = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 3.2rem 1fr;
  grid-template-areas: 'icon text';
  align-items: center;
`;

const UserSelectionIcon = styled.div`
  grid-area: icon;
  align-items: center;
  display: flex;
  justify-content: center;
`;
const UserSelectionText = styled.div`
  margin-left: ${theme.spacing.x1};
  grid-area: text;
  span {
    word-break: break-word;
  }
`;

interface Props {
  ignoredMemberships?: string[];
  canUnassign?: boolean;
}

function UserItems(props: Props) {
  const { ignoredMemberships, canUnassign } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const currentUser: User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const isAdmin = ['admin', 'owner'].includes(currentMembership.role);

  const workspaceMemberships: Membership[] = useSelector((state: any) => state.session.workspaceMemberships);
  const [nameToSearch, setNameToSearch] = useState<string | null>(null);
  const [searchedMemberships, setSearchedMemberships] = useState(workspaceMemberships);
  const searchMembershipsQueryKey = [
    queryKeys.memberships,
    workspaceSlug,
    {
      name: nameToSearch,
      per_page: 10,
    },
  ];

  const staleTime = 0;

  const { isFetching: isFetchingMemberships } = useQuery(
    searchMembershipsQueryKey,
    remoteApi.fetchWorkspaceMemberships,
    {
      staleTime,
      onSuccess: (response) => {
        setSearchedMemberships(response.data);
      },
      enabled: nameToSearch !== null,
    },
  );

  const performSearch = (newName: string) => {
    setNameToSearch(newName);
  };

  let users = [];

  const currentName = currentUser.fullname || currentUser.email;

  if (!ignoredMemberships || (ignoredMemberships && !ignoredMemberships.includes(currentMembership.id))) {
    // Add the current user at the top of the list
    users.push(
      <UserSelection data-action={currentMembership.id}>
        <UserSelectionIcon>
          <KoalaAvatar user={currentUser} size={2.4} hideTooltip={true} />
        </UserSelectionIcon>
        <UserSelectionText>
          <span className="owner-option-name">{t('shared.userSelect.self', { user: currentName })}</span>
        </UserSelectionText>
      </UserSelection>,
    );
  }

  // Adds all the users returned by search
  searchedMemberships.forEach((membership: Membership) => {
    const { user } = membership;
    if (ignoredMemberships && ignoredMemberships.includes(membership.id)) {
      return; // Ignore the ignoredMemberships
    }

    if (membership.id === currentMembership.id) {
      return; // Ignore the current user as it was set previously
    }

    const name = user.fullname || user.email;
    users.push(
      <UserSelection data-action={membership.id}>
        <UserSelectionIcon>
          <KoalaAvatar user={user} size={2.4} hideTooltip={true} />
        </UserSelectionIcon>
        <UserSelectionText>
          <span className="owner-option-name">{name}</span>
        </UserSelectionText>
      </UserSelection>,
    );
  });

  if (canUnassign) {
    users.push(
      <UserSelection data-action={'unassign'}>
        <UserSelectionIcon>
          <KoalaIcon iconName="close" />
        </UserSelectionIcon>
        <UserSelectionText>
          <span className="owner-option-name">{t('shared.userSelect.unassign')}</span>
        </UserSelectionText>
      </UserSelection>,
    );
  }

  // only show add user option if correct permissions
  if (isAdmin || !currentWorkspace.invites_restricted) {
    users.push(
      <UserSelection data-action={'invite user'}>
        <UserSelectionIcon>
          <KoalaIcon iconName="plus" />
        </UserSelectionIcon>
        <UserSelectionText>
          <span className="owner-option-name">{t('shared.userSelect.invite')}</span>
        </UserSelectionText>
      </UserSelection>,
    );
  }

  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );
  const handleSearch = (e: any) => {
    const newName = e.target.value;
    debouncePerformSearch.current(newName);
  };

  return (
    <>
      <input type="text" onChange={handleSearch} placeholder={t('shared.userSelect.search') ?? 'Search user'} />
      <ul>
        {isFetchingMemberships && (
          <li className="AriaMenuButton-menuItemWrapper">
            <MenuItem className="AriaMenuButton-menuItem">
              <Loader />
            </MenuItem>
          </li>
        )}
        {!isFetchingMemberships &&
          users.map((item, index) => (
            <li className="AriaMenuButton-menuItemWrapper" key={index}>
              <MenuItem className="AriaMenuButton-menuItem">{item}</MenuItem>
            </li>
          ))}
      </ul>
    </>
  );
}

// We need this function to make sure that we don't trigger any useless membership search.
// We'll only display the menu content (and execute related code) once it's opened.
function MenuItems(props: Props) {
  const menuContent = (menuState: any) => {
    if (menuState.isOpen) {
      // @ts-ignore
      return <UserItems {...props} />;
    } else {
      return null;
    }
  };

  // @ts-ignore
  return <Menu className="AriaMenuButton-menu">{menuContent}</Menu>;
}

export default React.memo(MenuItems);
