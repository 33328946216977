import React, { useState } from 'react';

import { useMutation, useQuery, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import Loader from 'components/Loader';
import UserSelectButton from 'components/UserSelectButton';

interface Props {
  initiativeId: string;
  isReadOnly: boolean;
}

function InitiativeContributors(props: Props) {
  const { initiativeId, isReadOnly } = props;

  const [initiativeContributors, setInitiativeContributors]: any = useState([]);
  const queryCache = useQueryCache();

  const initiativeContributorsQueryKey = [queryKeys.initiativeContributors, initiativeId];
  const { isFetching: isFetchingInitiativeContributors } = useQuery(
    initiativeContributorsQueryKey,
    remoteApi.fetchInitiativeContributors,
    {
      staleTime: 0,
      onSuccess: (response) => {
        setInitiativeContributors(response.data);
      },
    },
  );

  const [addInitiativeContributorMutation]: [any, any] = useMutation(remoteApi.createInitiativeContributor, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiativeContributors);
    },
  });
  const [deleteInitiativeContributorMutation]: [any, any] = useMutation(remoteApi.deleteInitiativeContributor, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiativeContributors);
    },
  });

  const handleAssignUser = (value: string) => {
    const membership_id = value;
    if (membership_id) {
      const params = {
        membership_id,
      };
      addInitiativeContributorMutation({
        initiativeId,
        initiative_contributor: params,
      });
    }
  };

  const handleUnassignUser = (value?: string) => {
    const initiativeContributorId = value;
    deleteInitiativeContributorMutation(initiativeContributorId);
  };

  if (isFetchingInitiativeContributors) {
    return <Loader size="small" />;
  }

  return (
    <>
      {initiativeContributors.map((initiativeContributor: any) => {
        const { membership } = initiativeContributor;
        const { user } = membership;

        const unassignCallback = () => {
          handleUnassignUser(initiativeContributor.id);
        };
        return (
          <UserSelectButton
            canEdit={!isReadOnly}
            handleAssignUser={handleAssignUser}
            selectedUser={user}
            handleUnassignUser={unassignCallback}
            size="small"
          />
        );
      })}
      <UserSelectButton
        canEdit={!isReadOnly}
        handleAssignUser={handleAssignUser}
        selectedUser={undefined}
        size="small"
      />
    </>
  );
}

export default React.memo(InitiativeContributors);
