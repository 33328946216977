import React, { useState } from 'react';
import { useParams, useRouteMatch, Link } from 'react-router-dom';
import * as routes from 'routes';
import styled from 'styled-components';
import theme from 'theme';
import { Objective, Outcome } from 'types';

import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import Loader from 'components/Loader';

// API
import * as remoteApi from 'api/remote';
import * as outcomeUtils from 'utils/outcomeUtils';
import KoalaConfidenceText from 'koala/components/ConfidenceText';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  border-bottom: solid 1px ${theme.colors.N40};
  background: ${theme.colors.N5};
  grid-area: nav;
  overflow-y: auto;
  border-right: 1px solid ${theme.colors.N40};

  hr {
    border: none;
    border-top: 1px solid ${theme.colors.N40};
    margin: ${theme.spacing.half} 0;
  }
  &::-webkit-scrollbar-track {
    border-left: solid 1px ${theme.colors.N40};
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-color: ${theme.colors.N40};
    background-clip: content-box;
  }

  @media ${theme.devices.laptop} {
    width: 25rem;
  }
  @media ${theme.devices.tablet} {
    width: 20rem;
  }
  @media ${theme.devices.mobile} {
    width: 10rem;
  }
`;

const LoadingContainer = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

const PlanContainer = styled(Link)<{ selected: boolean }>`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
  padding: ${theme.spacing.x2};
  cursor: pointer;
  border-bottom: solid 1px ${theme.colors.N40};

  ${(props) =>
    props.selected
      ? `background-color: ${theme.colors.B10};`
      : `
      background:color: #ffffff;
      :hover {
        background-color: ${theme.colors.N10};
      }
  `}

  .plan-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
  .plan-icon {
    display: center;
    align-items: center;
  }

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.x1};
  }
  @media ${theme.devices.mobile} {
    .plan-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
    .plan-icon {
      display: none;
    }
  }
`;

const ObjectiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px ${theme.colors.N40};
`;

const ObjectiveLabelContainer = styled.div`
  padding: ${theme.spacing.x2};
  label {
    font-weight: 800;
    font-size: 1rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    line-height: 120%;
    color: #000000;
  }

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.x1};
  }
`;

const OutcomeContainer = styled(Link)<{ selected: boolean }>`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${theme.spacing.x1};
  align-items: flex-start;
  cursor: pointer;
  font-weight: 400;
  font-size: 1.4rem;
  ${(props) =>
    props.selected
      ? `background-color: ${theme.colors.B10};`
      : `
      background:color: #ffffff;
      :hover {
        background-color: ${theme.colors.N10};
      }
  `}

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.x1};
  }
  @media ${theme.devices.mobile} {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
  }
`;

function PlanOverviewNav() {
  const { workspaceSlug, planId } = useParams<{ workspaceSlug: string; planId: string }>();
  const match = useRouteMatch(routes.WORKSPACE_PLAN_PREZ_OUTCOMES_ROUTE);
  // @ts-ignore
  const selectedOutcomeId = match ? match.params.outcomeId : null;
  const { t } = useTranslation();
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [outcomesByObjective, setOutcomesByObjective] = useState<{ [key: string]: Outcome[] }>({});

  // Load the data for the nav
  // Get the objectives
  const { isLoading: objectivesAreLoading } = useQuery([queryKeys.objectives, planId], remoteApi.fetchPlanObjectives, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const objectives: Objective[] = response.data;
      setObjectives(objectives);
    },
  });

  // Get the outcomes
  const { isLoading: outcomesAreLoading } = useQuery([queryKeys.outcomes, planId], remoteApi.fetchPlanOutcomes, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const outcomes: Outcome[] = response.data;
      const outcomesByObjective: { [key: string]: Outcome[] } = {};
      // Iterate on each outcome and group items by objective_id in an array
      outcomes.forEach((outcome) => {
        if (outcomesByObjective[outcome.objective_id]) {
          outcomesByObjective[outcome.objective_id].push(outcome);
        } else {
          outcomesByObjective[outcome.objective_id] = [outcome];
        }
      });
      setOutcomesByObjective(outcomesByObjective);
    },
  });

  const objectivePath = routes.WORKSPACE_PLAN_PREZ_ROOT_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    planId,
  );
  const overviewIsSelected = !selectedOutcomeId;

  // Display a loading container while we wait
  if (objectivesAreLoading || outcomesAreLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  // Past this point, we have objectives and outcomes.

  return (
    <Container>
      <PlanContainer selected={overviewIsSelected} to={objectivePath}>
        <span className="plan-title">{t('publicPlan.planOverview')}</span>
      </PlanContainer>
      {objectives.map((objective) => {
        const objectiveOutcomes = outcomesByObjective[objective.id];
        return (
          <ObjectiveContainer key={objective.id}>
            <ObjectiveLabelContainer>
              <label>{objective.title}</label>
            </ObjectiveLabelContainer>
            {objectiveOutcomes &&
              objectiveOutcomes.map((outcome) => {
                const isSelected: boolean = outcome.nano_slug === selectedOutcomeId;
                const currentCheckin = outcome.current_checkin;
                const path = routes.WORKSPACE_PLAN_PREZ_OUTCOMES_ROUTE.replace(':workspaceSlug', workspaceSlug)
                  .replace(':planId', planId)
                  .replace(':outcomeId', outcome.nano_slug);
                const hasTarget = outcomeUtils.hasTarget(outcome);
                return (
                  <OutcomeContainer selected={isSelected} to={path} key={outcome.id}>
                    <KoalaConfidenceText
                      isCompleted={outcome.completed}
                      value={hasTarget ? outcome.outcome_progress_prct * 100 : null}
                      confidence={currentCheckin ? currentCheckin.confidence : 'grey'}
                    />
                    {outcome.title}
                  </OutcomeContainer>
                );
              })}
          </ObjectiveContainer>
        );
      })}
    </Container>
  );
}
export default PlanOverviewNav;
