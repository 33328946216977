import React from 'react';

const LikeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 69">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40172 0C7.05045 0 6.73212 0.206874 6.58945 0.527877L3.97963 6.39996H2.42396C1.14757 6.39996 0.112854 7.43467 0.112854 8.71106V13.6888C0.112854 14.9652 1.14757 15.9999 2.42396 15.9999H4.55502C4.55578 15.9999 4.55654 15.9999 4.5573 15.9999H12.5742C13.7239 16.0101 14.7068 15.1726 14.8796 14.0354L15.8609 7.63557C15.9627 6.96518 15.7649 6.28393 15.32 5.77226C14.8763 5.26201 14.2315 4.97174 13.5555 4.97775H10.4239V3.02221C10.4239 1.35309 9.07084 0 7.40172 0ZM5.44619 7.47748L7.9277 1.89407C8.35209 2.09228 8.64615 2.5229 8.64615 3.02221V5.86663C8.64615 6.35755 9.04412 6.75552 9.53504 6.75552L13.5599 6.75558L13.57 6.75547C13.7264 6.75369 13.8758 6.82072 13.9785 6.9388C14.081 7.0567 14.1265 7.21431 14.1033 7.36879L13.122 13.7689C13.0821 14.0318 12.8546 14.2251 12.5886 14.2221H5.44619V7.47748ZM1.89063 8.71106C1.89063 8.41651 2.12941 8.17773 2.42396 8.17773H3.66839V14.2221H2.42396C2.12941 14.2221 1.89063 13.9833 1.89063 13.6888V8.71106Z"
        fill="#737373"
      />
    </g>
  </svg>
);

export default LikeIcon;
