import React from 'react';
export default () => (
  <svg viewBox="0 0 77 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_710_28888)">
      <path
        d="M16.1418 20.7113H4.92414C2.49863 20.7113 0.532959 18.7447 0.532959 16.3201V5.10248C0.532959 2.67697 2.49863 0.711304 4.92414 0.711304H16.1409C18.5664 0.711304 20.5321 2.67697 20.5321 5.10248V16.3192C20.533 18.7447 18.5664 20.7113 16.1418 20.7113Z"
        fill="#0A5055"
        className="tability-icon"
      />
      <path
        d="M11.602 8.57841C11.1911 8.1666 10.9376 7.6018 10.9376 6.97386V5.27591H10.1282V6.97386V13.0683C10.1282 13.9195 10.4733 14.6889 11.031 15.2466C11.5877 15.8025 12.3571 16.1467 13.2065 16.1467H14.9045V15.3373H13.2065C12.5786 15.3373 12.0138 15.0838 11.602 14.6729C11.1911 14.2611 10.9376 13.6963 10.9376 13.0683V9.03914C11.151 9.27395 11.3992 9.47052 11.6758 9.63062V13.0034C11.6758 13.8875 12.3927 14.6044 13.2768 14.6044H14.9054V13.795H13.2768C13.0571 13.795 12.8614 13.7069 12.7173 13.5628C12.5733 13.4179 12.4852 13.2231 12.4852 13.0025V9.95793C12.7182 10.014 12.9575 10.0522 13.2074 10.0522H14.9054V9.24282H13.2074C12.5786 9.24282 12.0138 8.99022 11.602 8.57841Z"
        fill="white"
      />
      <path
        d="M13.276 7.69608C13.0564 7.69608 12.8607 7.60803 12.7166 7.46394C12.5725 7.31896 12.4844 7.12417 12.4844 6.90359V5.27591H11.675V6.90448C11.675 7.78858 12.3919 8.50547 13.276 8.50547H14.9046V7.69608H13.276Z"
        fill="white"
      />
      <path
        d="M9.40953 13.3565C9.39797 13.2622 9.38996 13.1662 9.38996 13.0683V6.90448V5.27591H8.58057V6.90448C8.58057 7.12417 8.49252 7.31985 8.34843 7.46483C8.20345 7.60892 8.00866 7.69697 7.78808 7.69697H6.1604V8.50636H7.78897C8.07893 8.50636 8.34665 8.42365 8.58057 8.28845V9.11296C8.35288 9.19034 8.11362 9.24371 7.85835 9.24371H6.1604V10.0531H7.85835C8.1065 10.0531 8.34843 10.0211 8.58057 9.96505V13.0683C8.58057 14.3046 9.30991 15.372 10.3612 15.8603C10.4991 15.9243 10.645 15.975 10.7935 16.0186C10.6957 15.9385 10.6014 15.854 10.5124 15.7651C9.88716 15.1407 9.4798 14.2931 9.40953 13.3565Z"
        fill="white"
      />
      <path
        d="M27.4609 9.76316V14.7262H29.6427V16.2419H27.9777C26.6453 16.2419 25.763 15.3595 25.763 14.0271V9.76405H24.0811V8.24755H25.763V5.7998H27.4618V8.24755H29.8936V9.76316H27.4609V9.76316Z"
        fill="#151515"
        className="tability-text"
      />
      <path
        d="M39.1687 8.24753V16.2418H37.4699V14.5928C36.9202 15.742 35.9213 16.4082 34.589 16.4082C32.2906 16.4082 30.8586 14.7431 30.8586 12.2447C30.8586 9.74624 32.2746 8.08121 34.5223 8.08121C35.8876 8.08121 36.9202 8.7474 37.4699 9.92947V8.24753H39.1687ZM37.5526 12.2447C37.5526 10.4622 36.753 9.62973 35.0541 9.62973C33.3553 9.62973 32.5726 10.4622 32.5726 12.2447C32.5726 14.0262 33.3553 14.8596 35.0541 14.8596C36.7539 14.8596 37.5526 14.0271 37.5526 12.2447Z"
        fill="#151515"
        className="tability-text"
      />
      <path
        d="M49.6258 12.2447C49.6258 14.7431 48.1938 16.4082 45.8955 16.4082C44.5462 16.4082 43.5473 15.7251 42.9977 14.5599V16.2418H41.2988V4.58392H42.9977V9.94637C43.5473 8.7643 44.58 8.08121 45.9453 8.08121C48.2107 8.08121 49.6258 9.74624 49.6258 12.2447ZM47.9109 12.2447C47.9109 10.4622 47.1113 9.62973 45.4294 9.62973C43.7306 9.62973 42.931 10.4622 42.931 12.2447C42.931 14.0262 43.7306 14.8596 45.4294 14.8596C47.1113 14.8596 47.9109 14.0271 47.9109 12.2447Z"
        fill="#151515"
        className="tability-text"
      />
      <path
        d="M50.7742 5.45024C50.7573 4.73424 51.1904 4.35089 51.9402 4.35089C52.6731 4.35089 53.0894 4.73424 53.1063 5.45024C53.1232 6.18314 52.7069 6.5994 51.9402 6.5994C51.2073 6.5994 50.7911 6.18225 50.7742 5.45024ZM51.0739 8.24754H52.7727V16.2418H51.0739V8.24754Z"
        fill="#151515"
        className="tability-text"
      />
      <path d="M54.8887 4.67288H56.5875V16.3308H54.8887V4.67288Z" fill="#151515" className="tability-text" />
      <path
        d="M58.4038 5.45024C58.3869 4.73424 58.8201 4.35089 59.5699 4.35089C60.3028 4.35089 60.719 4.73424 60.7359 5.45024C60.7528 6.18314 60.3366 6.5994 59.5699 6.5994C58.837 6.5994 58.4207 6.18225 58.4038 5.45024ZM58.7035 8.24754H60.4024V16.2418H58.7035V8.24754Z"
        fill="#151515"
        className="tability-text"
      />
      <path
        d="M65.3654 9.76316V14.7262H67.5472V16.2419H65.8822C64.5498 16.2419 63.6674 15.3595 63.6674 14.0271V9.76405H61.9846V8.24755H63.6666V5.7998H65.3654V8.24755H67.7971V9.76316H65.3654V9.76316Z"
        fill="#151515"
        className="tability-text"
      />
      <path
        d="M77.0002 8.24753L73.4033 17.3741C72.9203 18.59 72.2879 19.0729 70.8221 19.0729H69.1064V17.5573H72.0042L72.1038 17.2247L72.07 17.2576L68.5896 8.24753H70.4717L72.2034 13.2933L72.77 15.1585L73.3695 13.2764L75.1181 8.24664H77.0002V8.24753Z"
        fill="#151515"
        className="tability-text"
      />
    </g>
    <defs>
      <clipPath id="clip0_710_28888">
        <rect width="76.4671" height="20" fill="white" transform="translate(0.532959 0.711304)" />
      </clipPath>
    </defs>
  </svg>
);
