import React, { useState } from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { addMonths, parseISO, startOfQuarter, endOfQuarter, addDays } from 'date-fns';

import * as remoteApi from 'api/remote';
import ReactTooltip from 'react-tooltip';
import * as routes from 'routes';

import 'theme/DatePicker.css';
import DatePicker from 'react-datepicker';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
const Container = styled.div`
  label {
    display: block;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 700;
    margin: ${theme.spacing.x1} 0;
    color: ${theme.colors.N70};
  }
  input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.6rem;
    line-height: 1.4;
    display: block;
    padding: 0.8rem;
    width: 100%;
    outline: 0;
    transition: border 0.2s ease;
  }
`;

const Header = styled.div`
  margin-bottom: 5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputBlock = styled.div`
  margin-bottom: 3.2rem;
`;

const ActionContainer = styled.div`
  margin-top: ${theme.spacing.x3};
  > button {
    margin-left: ${theme.spacing.x2};
  }
`;

const PresetsBlock = styled.div`
  margin-bottom: 3.2rem;
  button {
    margin-right: 1.6rem;
  }
`;

const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  margin: ${theme.spacing.x1} 0;

  .interval {
    width: 6rem;
  }
`;

interface Props {
  planId: string;
}

function EditPlanTimeline(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const translationKey = 'workspacePlan.write.timeline';

  const queryCache = useQueryCache();

  const { planId } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Get the data needed to build the component
  const plan: TabilityTypes.Plan = useSelector((state: any) => {
    return state.editorEntities.plans[planId];
  });

  const _start = plan.start_at ? parseISO(plan.start_at) : null;
  const _finish = plan.finish_at ? parseISO(plan.finish_at) : null;
  const _reminders_start_at = plan.reminders_start_at ? parseISO(plan.reminders_start_at) : null;
  const [startAt, setStartAt] = useState(_start);
  const [finishAt, setFinishAt] = useState(_finish);
  const [remindersStartAt, setRemindersStartAt] = useState(_reminders_start_at);
  const [reminders_hour, setRemindersHour] = useState(plan.reminders_hour);
  const [reminders_period, setRemidersPeriod] = useState(plan.reminders_period);
  const [reminders_interval, setRemindersInterval] = useState(plan.reminders_interval);

  const firstOutcome = useSelector((state: any) => {
    const objectiveIds = state.editorEntities.plansToObjectivesMapping[plan.id] || [];
    if (objectiveIds.length > 0) {
      const firstObjectiveId = objectiveIds[0];
      const outcomeIds = state.editorEntities.objectivesToOutcomesMapping[firstObjectiveId] || [];
      if (outcomeIds.length > 0) {
        const firstOutcomeId = outcomeIds[0];
        return state.editorEntities.outcomes[firstOutcomeId];
      }
    }
    return null;
  });

  const outcomeHashPath = firstOutcome ? `#outcome:${firstOutcome.nano_slug}:show` : '';
  const trackRoute = routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    plan.nano_slug,
  );

  // Mutation that will update the plan in the backend
  const [publishPlanMutation, { isLoading: isPublishing }]: [any, any] = useMutation(remoteApi.updatePlan, {
    onSuccess: (planResponse) => {
      queryCache.invalidateQueries(queryKeys.currentWorkspace);
      history.push(`${trackRoute}${outcomeHashPath}`);
    },
  });

  // Mutation that will update the plan in the backend
  const [updatePlanMutation, { isLoading: isUpdating }]: [any, any] = useMutation(remoteApi.updatePlan, {
    onSuccess: (planResponse) => {
      queryCache.invalidateQueries(queryKeys.currentWorkspace);
      closePanel();
    },
  });

  // Publish the plan and navigate to track view
  const handlePublish = (e: any) => {
    e.preventDefault();
    const start_at = startAt ? startAt.toISOString() : null;
    const finish_at = finishAt ? finishAt.toISOString() : null;
    const reminders_start_at = remindersStartAt ? remindersStartAt.toISOString() : null;
    const planParams = {
      start_at,
      finish_at,
      state: 'published',
      reminders_hour,
      reminders_interval,
      reminders_period,
      reminders_start_at,
    };
    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    publishPlanMutation(mutationParams);
  };

  if (!plan) {
    return <div>{t(`${translationKey}.noPlan`)}</div>;
  }

  const isPublished = plan.state === 'published';

  const handleChangeStart = (date: any) => {
    setStartAt(date);
  };
  const handleChangeFinish = (date: any) => {
    setFinishAt(date);
  };

  const handleChangeRemindersStartAt = (date: any) => {
    setRemindersStartAt(date);
  };

  const closePanel = () => {
    history.push(location.pathname);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    const start_at = startAt ? startAt.toISOString() : null;
    const finish_at = finishAt ? finishAt.toISOString() : null;
    const reminders_start_at = remindersStartAt ? remindersStartAt.toISOString() : null;
    const planParams = {
      start_at,
      finish_at,
      reminders_hour,
      reminders_interval,
      reminders_period,
      reminders_start_at,
    };
    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
    closePanel();
  };

  // Get the starting date from the plan start_at and reminderConfig setting
  const getRemindersStartAt = (start_at: Date) => {
    if (!start_at) {
      return null;
    }

    // Clone the starting date
    const date = new Date(start_at.getTime());

    // The date offset is the default weekly_reminder_day set at the workspace level.
    const dateOffset = currentWorkspace.weekly_reminder_day;

    // Calculate difference between current date and default reminder day.
    const daysUntilOffset = (dateOffset - date.getDay()) % 7;

    // Return the first "weekly_reminder_day" after the start_at date.
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + daysUntilOffset + 7);
  };

  const setTimelineToCurrentQuarter = (e: any) => {
    const refDate = Date.now();
    const startAt = startOfQuarter(refDate);
    const finishAt = endOfQuarter(refDate);
    const remindersStartAt = getRemindersStartAt(startAt);
    setStartAt(startAt);
    setFinishAt(finishAt);
    setRemindersStartAt(remindersStartAt);
  };

  const setTimelineToNextQuarter = (e: any) => {
    const refDate = addMonths(Date.now(), 3); // Add 3 months to get the next quarter
    const startAt = startOfQuarter(refDate);
    const finishAt = endOfQuarter(refDate);
    const remindersStartAt = getRemindersStartAt(startAt);
    setStartAt(startAt);
    setFinishAt(finishAt);
    setRemindersStartAt(remindersStartAt);
  };

  const publishDisabled = !(startAt && finishAt) || isPublishing;

  const minDate = startAt ? addDays(startAt, -8) : null;
  const maxDate = finishAt ? addDays(finishAt, 8) : null;

  return (
    <Container>
      <Header>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={closePanel} iconName="rightArrow" />
      </Header>
      <h3>{t(`${translationKey}.setTimeline`)}</h3>
      <PresetsBlock>
        <label>{t(`${translationKey}.presets`)}</label>
        <KoalaButton onClick={setTimelineToCurrentQuarter} appearance="subtle">
          {t(`shared.timelineThisQuarter`)}
        </KoalaButton>
        <KoalaButton onClick={setTimelineToNextQuarter} appearance="subtle">
          {t(`shared.timelineNextQuarter`)}
        </KoalaButton>
      </PresetsBlock>
      <InputBlock>
        <label>{t(`shared.planStart`)}</label>
        <DatePicker selected={startAt} onChange={handleChangeStart} dateFormat="d MMM yyyy" />
      </InputBlock>
      <InputBlock>
        <label>{t(`shared.planFinish`)}</label>
        <DatePicker selected={finishAt} onChange={handleChangeFinish} dateFormat="d MMM yyyy" />
      </InputBlock>
      {!(startAt && finishAt) && (
        <ReactTooltip type="dark" id={`save-button-help`} className="tooltip" effect="solid" delayShow={100}>
          {t(`${translationKey}.timelineRequired`)}
        </ReactTooltip>
      )}
      <h3>{t(`${translationKey}.config`)}</h3>
      <label>{t(`${translationKey}.frequency`)}</label>
      <InlineContainer>
        <span>{t(`${translationKey}.sendReminders`)}</span>
        <input
          type="number"
          required
          className="interval"
          value={reminders_interval}
          onChange={(e) => setRemindersInterval(parseInt(e.currentTarget.value))}
        />
        <select value={reminders_period} onChange={(e) => setRemidersPeriod(e.currentTarget.value)}>
          <option value="week">{t(`shared.time.week`, { count: reminders_interval })}</option>
          <option value="month">{t(`shared.time.month`, { count: reminders_interval })}</option>
        </select>
        <span>{t(`shared.at`)}</span>
        <div>
          <select value={reminders_hour} onChange={(e) => setRemindersHour(parseInt(e.currentTarget.value))} required>
            {Array(24)
              .fill(0)
              .map((_, i) => (
                <option value={i} key={i}>
                  {i}
                </option>
              ))}
          </select>
          <span>:00</span>
        </div>
      </InlineContainer>
      <InlineContainer>
        <span>{t(`${translationKey}.startingOn`)}</span>
        <DatePicker
          selected={remindersStartAt}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleChangeRemindersStartAt}
          dateFormat="d MMM yyyy"
        />
      </InlineContainer>

      <ActionContainer>
        {!isPublished && false && (
          <div style={{ display: 'inline-block' }} data-tip data-for="save-button-help">
            <KoalaButton onClick={handlePublish} disabled={publishDisabled}>
              {t(`shared.save`)}
            </KoalaButton>
          </div>
        )}
        <div style={{ display: 'inline-block' }} data-tip data-for="save-button-help">
          <KoalaButton onClick={handleSave} disabled={isUpdating} appearance={isPublished ? 'primary' : 'subtle'}>
            {t(`shared.update`)}
          </KoalaButton>
        </div>
        <KoalaTextButton onClick={closePanel}>{t(`shared.cancel`)}</KoalaTextButton>
      </ActionContainer>
    </Container>
  );
}

export default EditPlanTimeline;
