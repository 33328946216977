import React from 'react';

const LinkIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4876 2.41139C12.4585 0.505907 15.5917 0.533178 17.5292 2.47263C19.4662 4.41156 19.4934 7.54624 17.5904 9.51853L17.5782 9.53093L15.1647 11.9469C14.1345 12.9785 12.7072 13.5116 11.2533 13.4073C9.79934 13.3031 8.46261 12.5719 7.58986 11.4039C7.25927 10.9615 7.34992 10.3349 7.79233 10.0043C8.23474 9.6737 8.86138 9.76435 9.19197 10.2068C9.71745 10.91 10.5219 11.3498 11.3963 11.4124C12.2707 11.4751 13.1294 11.1546 13.7496 10.5336L13.7497 10.5335L16.1567 8.12401C17.2971 6.93593 17.2788 5.05183 16.1143 3.88613C14.95 2.72066 13.0688 2.70274 11.8826 3.8446L10.5043 5.2163C10.1128 5.60588 9.47964 5.60435 9.09005 5.21289C8.70047 4.82142 8.702 4.18826 9.09346 3.79868L10.4773 2.42152L10.4876 2.41139Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.83538 8.05303C5.86559 7.02149 7.29281 6.48847 8.74671 6.5927C10.2007 6.69693 11.5374 7.42814 12.4101 8.59608C12.7407 9.03849 12.6501 9.66513 12.2077 9.99573C11.7653 10.3263 11.1386 10.2357 10.808 9.79326C10.2826 9.09005 9.47813 8.65027 8.6037 8.58758C7.72928 8.52489 6.87056 8.84543 6.25044 9.4664L3.84326 11.876C2.70292 13.0641 2.72118 14.9482 3.88569 16.1139C5.04968 17.279 6.93015 17.2973 8.11643 16.1564L9.48563 14.7858C9.87595 14.3951 10.5091 14.3947 10.8998 14.7851C11.2906 15.1754 11.2909 15.8086 10.9006 16.1993L9.52479 17.5764L9.5124 17.5886C7.5415 19.4941 4.40826 19.4668 2.47077 17.5274C0.533801 15.5885 0.506607 12.4538 2.40959 10.4815L2.42176 10.4691L4.83538 8.05303C4.83543 8.05299 4.83534 8.05307 4.83538 8.05303Z"
      fill="#737373"
    />
  </svg>
);

export default LinkIcon;
