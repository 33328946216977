import React, { useState } from 'react';
import { Segment } from 'types';
import * as routes from 'routes';
import { NavLink, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { fetchOutcomeSegments } from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .loader {
    margin-left: ${theme.spacing.x2};
  }

  span {
    margin: ${theme.spacing.x1} ${theme.spacing.x2};
    font-weight: 300;
  }
`;
interface Props {
  searchValue?: string;
}

function SegmentList(props: Props) {
  const { searchValue } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [segments, setSegments] = useState<Segment[] | null>(null);

  const filter = searchValue ? { title: searchValue } : null;
  const { isLoading } = useQuery([queryKeys.segments, workspaceSlug, filter], fetchOutcomeSegments, {
    onSuccess: (response) => {
      setSegments(response.data);
    },
  });

  return (
    <Container>
      {isLoading && <KoalaLoader className="loader" />}
      {segments?.map((segment, i) => {
        const link = routes.WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug)
          .replace(':segmentId', segment.nano_slug)
          .replace(':filter', segment.filter_hash);
        return (
          <NavLink to={link} key={i}>
            {segment.title}
          </NavLink>
        );
      })}
      {searchValue && segments?.length === 0 && (
        <span>{t('workspaceFilters.segments.emptyState') ?? 'No segments found'}</span>
      )}
    </Container>
  );
}

export default SegmentList;
