import React from 'react';

const CardsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 3.5C1.5 2.94772 1.94772 2.5 2.5 2.5H8.5C9.05228 2.5 9.5 2.94772 9.5 3.5V8.5C9.5 9.05228 9.05228 9.5 8.5 9.5H2.5C1.94772 9.5 1.5 9.05228 1.5 8.5V3.5ZM3.5 4.5V7.5H7.5V4.5H3.5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 11.5C1.5 10.9477 1.94772 10.5 2.5 10.5H8.5C9.05228 10.5 9.5 10.9477 9.5 11.5V16.5C9.5 17.0523 9.05228 17.5 8.5 17.5H2.5C1.94772 17.5 1.5 17.0523 1.5 16.5V11.5ZM3.5 12.5V15.5H7.5V12.5H3.5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 3.5C10.5 2.94772 10.9477 2.5 11.5 2.5H17.5C18.0523 2.5 18.5 2.94772 18.5 3.5V8.5C18.5 9.05228 18.0523 9.5 17.5 9.5H11.5C10.9477 9.5 10.5 9.05228 10.5 8.5V3.5ZM12.5 4.5V7.5H16.5V4.5H12.5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 11.5C10.5 10.9477 10.9477 10.5 11.5 10.5H17.5C18.0523 10.5 18.5 10.9477 18.5 11.5V16.5C18.5 17.0523 18.0523 17.5 17.5 17.5H11.5C10.9477 17.5 10.5 17.0523 10.5 16.5V11.5ZM12.5 12.5V15.5H16.5V12.5H12.5Z"
      fill="#737373"
    />
  </svg>
);
export default CardsIcon;
