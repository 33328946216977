import React, { useState, useEffect } from 'react';
import * as TabilityTypes from 'types';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as amplitudeUtils from 'utils/amplitude';
import { useSelector, shallowEqual } from 'react-redux';
// import { REFERENCE_TEMPLATE_STORAGE_KEY } from 'config/constants';

// Components
import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';

// API
import * as remoteApi from 'api/remote';

// Actions
import { sessionActions } from 'state/actions/';
import { useTranslation } from 'react-i18next';

import FlagIcon from './_assets/Flag.png';
import MetricIcon from './_assets/Metric.png';
import TasksIcon from './_assets/Tasks.png';
import PersonIcon from './_assets/Person.png';

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: ${theme.spacing.x2};
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${theme.spacing.x8};

  width: 100%;
  gap: ${theme.spacing.x8};

  a {
    color: ${theme.colors.blue};
  }

  @media ${theme.devices.laptop} {
    display: flex;
    width: 100%;
    gap: ${theme.spacing.x1};
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: initial;
    margin-top: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};

    .hide-mobile {
      display: none;
    }

    .special-mobile {
      position: relative;
      bottom: ${theme.spacing.x3};
    }
  }
`;

const About = styled.div`
  width: 50rem;
`;
const Content = styled.div`
  justify-self: flex-end;
  width: 45rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${theme.devices.laptop} {
    margin-top: 0;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x4};

  @media (max-width: 800px) {
    input,
    select {
      &.small {
        width: 100%;
      }

      &.tiny {
        width: 50%;
      }
    }
  }
`;

const ErrorList = styled.ul`
  margin-bottom: 1.6rem;
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 100%;
    justify-content: center;
  }
`;

const Footer = styled.div`
  margin-bottom: ${theme.spacing.x3};

  button.secondary {
    color: ${theme.colors.subtleText};
    text-decoration: none;
    font-weight: normal;
  }
`;

const RadioField = styled.div`
  display: flex;
  flex-direction: column;

  .form-label {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${theme.colors.subtleText};
    margin-bottom: ${theme.spacing.x1};
  }

  .radio-group {
    display: flex;
    gap: ${theme.spacing.x1};
    position: relative;
    // hide radio button and put it behind label so not clickable
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    input[type='radio']:focus + label {
      border: 1px solid ${theme.colors.blue};
    }

    label {
      color: ${theme.colors.black};
      background-color: #fff;
      cursor: pointer;
      flex: 1;
      padding: ${theme.spacing.x2};
      transition: all 0.2s ease;
      border: solid 1px ${theme.colors.blockBorder};
      border-radius: 4px;
      &:hover {
        background: ${theme.colors.N3};
        cursor: pointer;
      }

      span {
        display: inline-block;
        padding: ${theme.spacing.half} ${theme.spacing.x1};
        border-radius: 4px;
        background: ${theme.colors.N5};
        font-size: 1.1rem;
        font-weight: 500;
        color: ${theme.colors.N70};
        margin-bottom: ${theme.spacing.x2};
      }

      &.disabled {
        cursor: not-allowed;
        filter: grayscale(0.7);
        opacity: 0.7;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        g,
        path,
        circle {
          stroke: ${theme.colors.subtleText};
        }
      }
    }

    input[type='radio']:checked + label {
      color: #fff;
      background: ${theme.colors.B5};
      border: 1px solid ${theme.colors.B30};
      color: ${theme.colors.N100};

      span {
        background: ${theme.colors.B50};
        font-size: 1.1rem;
        font-weight: 500;
        color: ${theme.colors.N0};
      }
    }

    &.compact {
      label {
        font-weight: 800;
        font-size: 1rem;
        padding: ${theme.spacing.x1} ${theme.spacing.x2};
      }

      input[type='radio']:checked + label {
        color: ${theme.colors.B50};
      }
    }
  }

  &.icons-only {
    .radio-group {
      display: flex;
      align-items: center;
    }
    label {
      display: flex;
      align-items: center;
    }
  }
`;

const TermBox = styled.div`
  background: ${theme.colors.T10};
  border-radius: 16px;
  padding: ${theme.spacing.x2};
  height: 50rem;
  width: 45rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${theme.devices.laptop} {
    display: none;
  }
`;
const TermContainer = styled.div`
  background: ${theme.colors.N0};
  padding: ${theme.spacing.x3};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgba(21, 21, 21, 0.15);
  width: 30rem;
`;

const TermRow = styled.div`
  display: grid;
  gap: ${theme.spacing.x1};
  grid-template-columns: 2.4rem 1fr 2.4rem;
  align-items: center;

  &.flag {
    grid-template-columns: 2rem 1fr auto;
    grid-template-rows: 2.2rem;
  }
  &.metric {
    margin-left: ${theme.spacing.x4};
  }
  &.task {
    margin-left: ${theme.spacing.x8};
  }
  img {
    width: 100%;
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceOnboardingWorkflow(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { workspace } = props;
  const { t } = useTranslation();

  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const [weekly_reminder_day, setWeeklyReminderDay] = useState(workspace.weekly_reminder_day);
  const [weekly_report_day, setWeeklyReportDay] = useState(workspace.weekly_report_day);
  const [framework, setFramework] = useState('');

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.onboarding.workflow', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  useEffect(() => {
    window.Intercom('update', {
      has_created_workspace: true,
      workspace_slug: workspace.slug,
    });
  }, [workspace]);

  /** 
    This is a bit of a hack for now, to see if we get better onboarding conversions.
    The code checks for the presence of a reference template in local storage.
    If there's one, it means that the user got to the app via a Community Template (https://tability.app?tpl=<template.nano_slug>)

    From here we decide whether to send the user to the onboarding template screen, or to the user invite screen.

    TODO: If onboarding converts better with a template, we should permanently replace the user invite screen
    with the template onboarding.

    Why not both? Because we want to keep the number of onboarding screen manageable.
  */

  // Check if we have a template in local storage
  //const storedReferenceTemplateValue = localStorage.getItem(REFERENCE_TEMPLATE_STORAGE_KEY);
  //const storedReferenceTemplate = storedReferenceTemplateValue ? JSON.parse(storedReferenceTemplateValue) : null;

  // Workspace mutations
  const [updateWorkspaceMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.updateWorkspace, {
    onSuccess: (response: any) => {
      const workspace = response.data;
      dispatch(sessionActions.setCurrentWorkspace(workspace));
      history.push(`/${workspace.slug}/onboarding/team`);
      /*
      // Send to the Invite Team onboarding screen if we don't have a template
      if (!storedReferenceTemplate) {
        history.push(`/${workspace.slug}/onboarding/team`);
      } else {
        // Send to the Template onboarding screen if we have a reference template in localStorage
        history.push(`/${workspace.slug}/onboarding/template`);
      }
      */
    },
  });

  // Workspace mutations
  const [updateMembershipMutation]: [any, any] = useMutation(remoteApi.updateMembership);

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: ['Sorry, an error occurred on the remote server.'],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'weekly_reminder_day':
        setWeeklyReminderDay(value);
        break;
      case 'framework':
        setFramework(value);
        break;
      case 'weekly_report_day':
        setWeeklyReportDay(value);
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const default_standup_frequency = 'none';
    const reminder_params = {
      weekly_reminder_day,
      weekly_report_day,
      default_standup_frequency,
    };

    let params;

    if (framework === 'okrs') {
      params = {
        ...reminder_params,
        custom_term_outcome: 'Key Result',
        custom_term_outcomes: 'Key Results',
        custom_term_initiative: 'Task',
        custom_term_initiatives: 'Tasks',
      };
    } else {
      params = reminder_params;
    }

    const membershipParams = {
      standup_frequency: default_standup_frequency,
    };

    updateMembershipMutation({
      membershipId: currentMembership.id,
      membership: membershipParams,
    });

    updateWorkspaceMutation({
      workspaceId: workspace.id,
      workspace: params,
    });
  };

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'name':
        return t('errors.workspaceName');
      case 'slug':
        return t('errors.workspaceID');
      default:
        return '';
    }
  };

  const handleLogout = (e: any) => {
    dispatch(sessionActions.logout());
  };

  const isPerformingAction = isLoading;

  return (
    <Wrapper>
      <Helmet>
        <title>{workspace.name} | Set up your workflow | Tability</title>
      </Helmet>
      <Container>
        <Content>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <h2>How do you write goals?</h2>
              <small className="subtle">(You can also customise these terms later)</small>
              <RadioField>
                <div className="radio-group">
                  <>
                    <input
                      type="radio"
                      id="default"
                      required
                      checked={framework === 'default'}
                      name="framework"
                      value={'default'}
                      onChange={handleChange}
                    />
                    <label htmlFor="default">
                      <span>Default</span>
                      <br />
                      Use Tability's terms: <b>Objectives</b>, <b>Outcomes</b>, and <b>Initiatives</b>
                    </label>
                  </>
                  <>
                    <input
                      type="radio"
                      id="okrs"
                      checked={framework === 'okrs'}
                      name="framework"
                      value={'okrs'}
                      onChange={handleChange}
                    />
                    <label htmlFor="okrs">
                      <span>OKRs</span>
                      <br />
                      Use traditional OKRs terms: <b>Objectives</b>, <b>Key Results</b>, and <b>Tasks</b>
                    </label>
                  </>
                </div>
              </RadioField>
            </FormGroup>
            <FormGroup>
              <h2>What is your preferred day for check-ins?</h2>
              <small className="subtle" style={{ marginBottom: theme.spacing.x2 }}>
                We will send reminders to update progress on this day.
              </small>
              <FormField>
                <RadioField>
                  <div className="radio-group compact">
                    <>
                      <input
                        type="radio"
                        id="mon"
                        required
                        checked={weekly_reminder_day.toString() === '1'}
                        name="weekly_reminder_day"
                        value={'1'}
                        onChange={handleChange}
                      />
                      <label htmlFor="mon">MON</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="tue"
                        required
                        checked={weekly_reminder_day.toString() === '2'}
                        name="weekly_reminder_day"
                        value={'2'}
                        onChange={handleChange}
                      />
                      <label htmlFor="tue">TUE</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="wed"
                        required
                        checked={weekly_reminder_day.toString() === '3'}
                        name="weekly_reminder_day"
                        value={'3'}
                        onChange={handleChange}
                      />
                      <label htmlFor="wed">WED</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="thu"
                        required
                        checked={weekly_reminder_day.toString() === '4'}
                        name="weekly_reminder_day"
                        value={'4'}
                        onChange={handleChange}
                      />
                      <label htmlFor="thu">THU</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="fri"
                        required
                        checked={weekly_reminder_day.toString() === '5'}
                        name="weekly_reminder_day"
                        value={'5'}
                        onChange={handleChange}
                      />
                      <label htmlFor="fri">FRI</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="sat"
                        required
                        checked={weekly_reminder_day.toString() === '6'}
                        name="weekly_reminder_day"
                        value={'6'}
                        onChange={handleChange}
                      />
                      <label htmlFor="sat">SAT</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="sun"
                        required
                        checked={weekly_reminder_day.toString() === '0'}
                        name="weekly_reminder_day"
                        value={'0'}
                        onChange={handleChange}
                      />
                      <label htmlFor="sun">SUN</label>
                    </>
                  </div>
                </RadioField>
              </FormField>
              {false && (
                <FormField>
                  <label>Get a weekly progress digest every:</label>
                  <RadioField>
                    <div className="radio-group compact">
                      <>
                        <input
                          type="radio"
                          id="report_mon"
                          required
                          checked={weekly_report_day.toString() === '1'}
                          name="weekly_report_day"
                          value={'1'}
                          onChange={handleChange}
                        />
                        <label htmlFor="report_mon">MON</label>
                      </>
                      <>
                        <input
                          type="radio"
                          id="report_tue"
                          required
                          checked={weekly_report_day.toString() === '2'}
                          name="weekly_report_day"
                          value={'2'}
                          onChange={handleChange}
                        />
                        <label htmlFor="report_tue">TUE</label>
                      </>
                      <>
                        <input
                          type="radio"
                          id="report_wed"
                          required
                          checked={weekly_report_day.toString() === '3'}
                          name="weekly_report_day"
                          value={'3'}
                          onChange={handleChange}
                        />
                        <label htmlFor="report_wed">WED</label>
                      </>
                      <>
                        <input
                          type="radio"
                          id="report_thu"
                          required
                          checked={weekly_report_day.toString() === '4'}
                          name="weekly_report_day"
                          value={'4'}
                          onChange={handleChange}
                        />
                        <label htmlFor="report_thu">THU</label>
                      </>
                      <>
                        <input
                          type="radio"
                          id="report_fri"
                          required
                          checked={weekly_report_day.toString() === '5'}
                          name="weekly_report_day"
                          value={'5'}
                          onChange={handleChange}
                        />
                        <label htmlFor="report_fri">FRI</label>
                      </>
                      <>
                        <input
                          type="radio"
                          id="report_sat"
                          required
                          checked={weekly_report_day.toString() === '6'}
                          name="weekly_report_day"
                          value={'6'}
                          onChange={handleChange}
                        />
                        <label htmlFor="report_sat">SAT</label>
                      </>
                      <>
                        <input
                          type="radio"
                          id="report_sun"
                          required
                          checked={weekly_report_day.toString() === '0'}
                          name="weekly_report_day"
                          value={'0'}
                          onChange={handleChange}
                        />
                        <label htmlFor="report_sun">SUN</label>
                      </>
                    </div>
                  </RadioField>
                </FormField>
              )}
            </FormGroup>
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isPerformingAction} loading={isPerformingAction}>
                Continue
              </KoalaButton>
            </Actions>
          </Form>
        </Content>
        <About>
          <TermBox>
            <TermContainer>
              <TermRow className="flag">
                <img src={FlagIcon} alt="Flag" />
                <h4>Objective</h4>
              </TermRow>
              <TermRow className="metric">
                <img src={MetricIcon} alt="Metric" />
                <p>{framework !== 'okrs' ? 'Outcome' : 'Key Result'}</p>
                <img src={PersonIcon} alt="Person" />
              </TermRow>
              <TermRow className="task">
                <img src={TasksIcon} alt="Task" />
                <p>{framework !== 'okrs' ? 'Initiative' : 'Task'}</p>
                <img src={PersonIcon} alt="Person" />
              </TermRow>
              <TermRow className="task">
                <img src={TasksIcon} alt="Task" />
                <p>{framework !== 'okrs' ? 'Initiative' : 'Task'}</p>
                <img src={PersonIcon} alt="Person" />
              </TermRow>
              <TermRow className="metric">
                <img src={MetricIcon} alt="Metric" />
                <p>{framework !== 'okrs' ? 'Outcome' : 'Key Result'}</p>
                <img src={PersonIcon} alt="Person" />
              </TermRow>
              <TermRow className="task">
                <img src={TasksIcon} alt="Task" />
                <p>{framework !== 'okrs' ? 'Initiative' : 'Task'}</p>
                <img src={PersonIcon} alt="Person" />
              </TermRow>
              <TermRow className="task">
                <img src={TasksIcon} alt="Task" />
                <p>{framework !== 'okrs' ? 'Initiative' : 'Task'}</p>
                <img src={PersonIcon} alt="Person" />
              </TermRow>
            </TermContainer>
          </TermBox>
        </About>
      </Container>
      <Footer>
        <KoalaTextButton onClick={handleLogout} appearance="subtle">
          Logout
        </KoalaTextButton>
      </Footer>
    </Wrapper>
  );
}

export default WorkspaceOnboardingWorkflow;
