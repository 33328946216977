/**
  YML Editor

  This component is used to create and edit templates managed as YML file. Here's how it works:
  1. First, it loads the yml data and create the proper data structures
  2. Second it presents all the elemets as a editable UI
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import * as ymlEditorActions from 'state/actions/ymlEditorActions';

// Components
import { DragDropContext } from 'react-beautiful-dnd';
import Plan from './Plan';
import YMLSync from './YMLSync';

interface YMLEditorProps {
  template: any;
  handleChange(ymlChanged: string): void;
}

function YMLEditor(props: YMLEditorProps) {
  const dispatch = useDispatch();
  const { template, handleChange } = props;

  useEffect(() => {
    dispatch(ymlEditorActions.resetTemplate());
    dispatch(ymlEditorActions.loadTemplate(template));
  }, [dispatch, template]);

  const plan = useSelector((state: any) => state.ymlEditor.data.plan);

  if (!plan) {
    return null;
  }

  // ----------------------------
  // Handle re-ordering of items
  // ----------------------------
  const onDragEnd = (result: any) => {
    dispatch(ymlEditorActions.reorderItems(result));
  };

  //-------------------------
  // End of re-ordering code
  //-------------------------

  const onDragStart = () => {
    //console.log('drag start');
  };

  return (
    <div>
      <YMLSync handleChange={handleChange} />
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Plan />
      </DragDropContext>
    </div>
  );
}

export default React.memo(YMLEditor);
