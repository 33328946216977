import InitiativeWorkStatus from 'components/InitiativeWorkStatus';
import { TFunction } from 'i18next';
import { KoalaTextBadgeVariants } from 'koala/shared/global';
import React from 'react';
import { Initiative, Workspace, WorkspaceSettings } from 'types';

// Turn confidence into color
export const roadmapStateToText = (roadmap_state: string) => {
  switch (roadmap_state) {
    case 'now':
      return 'This week';
    case 'next':
      return 'Next week';
    case 'later':
      return 'Later';
  }
};

export const getInitiativeRoadmapState = (initiative: Initiative, t: TFunction) => {
  if (initiative.state === 'closed') {
    return t('shared.initiatives.closed');
  } else if (initiative.roadmap_state) {
    return roadmapStateToText(initiative.roadmap_state);
  } else {
    return;
  }
};

export enum InitiativeStates {
  BACKLOG = 'backlog',
  PLANNED = 'planned',
  DESIGN = 'design',
  IN_PROGRESS = 'in_progress',
  IN_REVIEW = 'in_review',
  READY = 'ready',
  BLOCKED = 'blocked',
  DONE = 'done',
  ABANDONED = 'abandoned',
  COMMENT = 'comment',
}

export const isInitiativeStateActive = (workspaceSettings: WorkspaceSettings, state: string) => {
  switch (state) {
    case 'backlog':
      return true;
    case 'planned':
      return workspaceSettings.planned_state_active;
    case 'design':
      return workspaceSettings.design_state_active;
    case 'in_progress':
      return workspaceSettings.in_progress_state_active;
    case 'in_review':
      return workspaceSettings.in_review_state_active;
    case 'ready':
      return workspaceSettings.ready_state_active;
    case 'blocked':
      return workspaceSettings.blocked_state_active;
    case 'done':
      return true;
    case 'abandoned':
      return workspaceSettings.abandoned_state_active;
    default:
      return true;
  }
};

export const getWorkStateColor = (state: string): (typeof KoalaTextBadgeVariants)[number] => {
  switch (state) {
    case InitiativeStates.BACKLOG:
      return 'neutral-light';
    case InitiativeStates.PLANNED:
      return 'yellow-light';
    case InitiativeStates.DESIGN:
      return 'blue-light';
    case InitiativeStates.IN_PROGRESS:
      return 'blue-light';
    case InitiativeStates.IN_REVIEW:
      return 'blue-light';
    case InitiativeStates.READY:
      return 'teal-light';
    case InitiativeStates.BLOCKED:
      return 'red-light';
    case InitiativeStates.DONE:
      return 'green-light';
    case InitiativeStates.ABANDONED:
      return 'neutral-outlined';
    default:
      return 'neutral-light';
  }
};

export const getWorkState = (state: InitiativeStates | string, workspace: Workspace, t: TFunction): string => {
  switch (state) {
    case InitiativeStates.BACKLOG:
      return workspace?.custom_term_backlog ?? t('shared.initiatives.backlog');
    case InitiativeStates.PLANNED:
      return workspace?.custom_term_planned ?? t('shared.initiatives.planned');
    case InitiativeStates.DESIGN:
      return workspace?.custom_term_design ?? t('shared.initiatives.design');
    case InitiativeStates.IN_PROGRESS:
      return workspace?.custom_term_in_progress ?? t('shared.initiatives.in_progress');
    case InitiativeStates.IN_REVIEW:
      return workspace?.custom_term_in_review ?? t('shared.initiatives.in_review');
    case InitiativeStates.READY:
      return workspace?.custom_term_ready ?? t('shared.initiatives.ready');
    case InitiativeStates.BLOCKED:
      return workspace?.custom_term_blocked ?? t('shared.initiatives.blocked');
    case InitiativeStates.DONE:
      return workspace?.custom_term_done ?? t('shared.initiatives.done');
    case InitiativeStates.ABANDONED:
      return workspace?.custom_term_abandoned ?? t('shared.initiatives.abandoned');
    default:
      return workspace?.custom_term_backlog ?? t('shared.initiatives.backlog');
  }
};

export const getWorkStateDropdownData = (
  t: TFunction,
  workspaceSettings: WorkspaceSettings,
  showCommentOption?: boolean,
) => {
  let statusItems = [
    <span key={InitiativeStates.BACKLOG} data-action={InitiativeStates.BACKLOG}>
      <InitiativeWorkStatus showArrow={false} providedState={InitiativeStates.BACKLOG} />
    </span>,
  ];

  if (workspaceSettings.planned_state_active) {
    statusItems.push(
      <span key={InitiativeStates.PLANNED} data-action={InitiativeStates.PLANNED}>
        <InitiativeWorkStatus showArrow={false} providedState={InitiativeStates.PLANNED} />
      </span>,
    );
  }

  if (workspaceSettings.design_state_active) {
    statusItems.push(
      <span key={InitiativeStates.DESIGN} data-action={InitiativeStates.DESIGN}>
        <InitiativeWorkStatus showArrow={false} providedState={InitiativeStates.DESIGN} />
      </span>,
    );
  }
  if (workspaceSettings.in_progress_state_active) {
    statusItems.push(
      <span key={InitiativeStates.IN_PROGRESS} data-action={InitiativeStates.IN_PROGRESS}>
        <InitiativeWorkStatus showArrow={false} providedState={InitiativeStates.IN_PROGRESS} />
      </span>,
    );
  }
  if (workspaceSettings.in_review_state_active) {
    statusItems.push(
      <span key={InitiativeStates.IN_REVIEW} data-action={InitiativeStates.IN_REVIEW}>
        <InitiativeWorkStatus showArrow={false} providedState={InitiativeStates.IN_REVIEW} />
      </span>,
    );
  }
  if (workspaceSettings.ready_state_active) {
    statusItems.push(
      <span key={InitiativeStates.READY} data-action={InitiativeStates.READY}>
        <InitiativeWorkStatus showArrow={false} providedState={InitiativeStates.READY} />
      </span>,
    );
  }
  if (workspaceSettings.blocked_state_active) {
    statusItems.push(
      <span key={InitiativeStates.BLOCKED} data-action={InitiativeStates.BLOCKED}>
        <InitiativeWorkStatus showArrow={false} providedState={InitiativeStates.BLOCKED} />
      </span>,
    );
  }
  statusItems.push(
    <span key={InitiativeStates.DONE} data-action={InitiativeStates.DONE}>
      <InitiativeWorkStatus showArrow={false} providedState={InitiativeStates.DONE} />
    </span>,
  );
  if (workspaceSettings.abandoned_state_active) {
    statusItems.push(
      <span key={InitiativeStates.ABANDONED} data-action={InitiativeStates.ABANDONED}>
        <InitiativeWorkStatus showArrow={false} providedState={InitiativeStates.ABANDONED} />
      </span>,
    );
  }

  if (showCommentOption) {
    statusItems.push(
      <span key={InitiativeStates.COMMENT} data-action={InitiativeStates.COMMENT}>
        {t('shared.updateComment')}
      </span>,
    );
  }
  return statusItems;
};

export const getWorkStateParams = (state: string) => {
  switch (state) {
    case InitiativeStates.BACKLOG:
      return { work_state: InitiativeStates.BACKLOG, state: 'open' };
    case InitiativeStates.PLANNED:
      return { work_state: InitiativeStates.PLANNED, state: 'open' };
    case InitiativeStates.DESIGN:
      return { work_state: InitiativeStates.DESIGN, state: 'open' };
    case InitiativeStates.IN_PROGRESS:
      return { work_state: InitiativeStates.IN_PROGRESS, state: 'open' };
    case InitiativeStates.IN_REVIEW:
      return { work_state: InitiativeStates.IN_REVIEW, state: 'open' };
    case InitiativeStates.READY:
      return { work_state: InitiativeStates.READY, state: 'open' };
    case InitiativeStates.BLOCKED:
      return { work_state: InitiativeStates.BLOCKED, state: 'open' };
    case InitiativeStates.DONE:
      return { work_state: InitiativeStates.DONE, state: 'closed' };
    case InitiativeStates.ABANDONED:
      return { work_state: InitiativeStates.ABANDONED, state: 'closed' };
    default:
      return { work_state: InitiativeStates.BACKLOG, state: 'open' };
  }
};

export const getStateFromWorkState = (state: string) => {
  if (state === 'done' || state === 'done') {
    return 'closed';
  } else {
    return 'open';
  }
};
