import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import AsanaIcon from '../_assets/IconAsana.png';
import KoalaButton from 'koala/components/Button';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
}

function AsanaDataConnectorIntegration(props: Props) {
  const { workspace, integrations } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallAsanaMutation, { isLoading: isUninstallingAsana }] = useMutation(remoteApi.uninstallAsana, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const asanaIntegration = integrations.filter((i: Integration) => i.integration_type === 'asana')[0];

  const asanaRedirectURL = `https://${process.env.REACT_APP_DOMAIN}/install/asana`;
  const asanaOAuthURL = `https://app.asana.com/-/oauth_authorize?client_id=${
    process.env.REACT_APP_ASANA_CLIENT_ID
  }&response_type=code&redirect_uri=${encodeURIComponent(asanaRedirectURL)}`;
  const uninstallAsanaLabel = isUninstallingAsana ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove');

  const handleUninstallAsana = (e: React.MouseEvent) => {
    e.preventDefault();
    uninstallAsanaMutation(workspace.slug);
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={AsanaIcon} alt="Asana" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>
          {t('workspaceSettingsIntegrations.dataConnectorHeader', {
            integration: 'Asana',
          })}
        </h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Asana' })}</p>
        <p>
          <a
            href="https://guides.tability.app/docs/integrations/data-sources"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {asanaIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: asanaIntegration.connected_to }}
              />
            </div>
            <KoalaButton href={asanaOAuthURL} appearance="secondary" size="small">
              {t('workspaceSettingsIntegrations.reinstall')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={handleUninstallAsana}>
              {uninstallAsanaLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!asanaIntegration && (
          <KoalaButton href={asanaOAuthURL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Asana' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default AsanaDataConnectorIntegration;
