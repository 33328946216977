import React from 'react';
import { Outcome } from 'types';

function nFormatter(num: number, digits: number) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

interface Props {
  outcome: Outcome;
}

function OutcomeTarget(props: Props) {
  const { outcome } = props;

  const { outcome_type, score_format, from, to } = outcome;

  let formattedOutcomeFrom = null;
  let formattedOutcomeTo = null;

  if (score_format) {
    formattedOutcomeFrom = from || from === 0 ? nFormatter(from, 2) : null;
    formattedOutcomeTo = to || to === 0 ? score_format.replace('_number_', nFormatter(to, 2)) : null;
  }

  if (!outcome) {
    return null;
  }

  let content = '—';

  if (outcome_type === 'improve_metric') {
    content = `${formattedOutcomeFrom} ➞ ${formattedOutcomeTo}`;
  } else if (outcome_type === 'stay_above') {
    content = `x > ${formattedOutcomeTo}`;
  } else if (outcome_type === 'stay_below') {
    content = `x < ${formattedOutcomeTo}`;
  } else if (outcome_type === 'kpi') {
    if (score_format) {
      formattedOutcomeFrom = formattedOutcomeFrom ? score_format.replace('_number_', formattedOutcomeFrom) : null;
    }
    content = formattedOutcomeFrom ? `KPI ${formattedOutcomeFrom}` : '—';
  }

  return <>{content}</>;
}

export default OutcomeTarget;
