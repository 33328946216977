import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import theme from 'theme';
import { useTranslation } from 'react-i18next';

// Components
import KoalaButton from 'koala/components/Button';
import KoalaTextBadge from 'koala/components/TextBadge';
import OutcomeExpandable from 'components/OutcomeExpandable';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaIcon from 'koala/components/Icons';
import { AxiosResponse } from 'axios';
import { CheckinsListHeader } from './CheckinsList';

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

const OutcomeList = styled.div`
  .outcome-expanded {
    border-bottom: 1px solid ${theme.colors.blockBorder};
  }
  .outcome-expanded:last-of-type {
    border-bottom: 0;
  }
`;

const Banner = styled.div<{ color: string }>`
  padding: 1.2rem ${theme.spacing.x2};
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.x1};
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const StreakContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  .text-badge-label {
    gap: ${theme.spacing.half};
  }
`;

interface Props {
  pendingCheckins: AxiosResponse<TabilityTypes.Outcome[]>[];
  noPendingCheckins: boolean;
  canFetchMore: boolean;
  isFetchingMore: boolean;
  fetchMore: () => void;
  streakCount: number;
}

function PendingCheckinsList(props: Props) {
  const { pendingCheckins, noPendingCheckins, canFetchMore, isFetchingMore, fetchMore, streakCount } = props;

  // Query that fetches all the outcomes with pending checkins
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentPing = useSelector((state: any) => state.session.currentPing, shallowEqual);
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );
  const handleBulkUpdate = () => {
    dispatch(setGlobalModalContent(`workspace::bulk.checkin`));
  };

  const handleShowStreakLadder = () => {
    dispatch(setGlobalModalContent(`workspace:${currentWorkspace.slug}:streak.ladder`));
  };

  let pending_checkins_count = 0;

  if (currentPing && currentPing.inbox) {
    pending_checkins_count = currentPing.inbox.pending_checkins;
  }

  // show streak banner if no pending checkins
  if (noPendingCheckins) {
    return (
      <>
        <CheckinsListHeader>
          <h4>{t('workspaceInbox.pendingCheckins')}</h4>
        </CheckinsListHeader>
        <Banner color={theme.colors.G5} className="item">
          <LeftContainer>
            <KoalaIcon iconName="flag" iconAppearance={theme.colors.G50} />
            <p>{t('workspaceInbox.goalsUpToDate')}</p>
          </LeftContainer>
          {streakCount > 0 && (
            <StreakContainer>
              {t('workspaceInbox.streakCount', { count: streakCount })}
              <KoalaTextBadge variant="orange-outlined" isLowercase onClick={handleShowStreakLadder}>
                <KoalaIcon iconName="fire" iconSize="small" iconAppearance={theme.colors.O50} />
                {streakCount}x
              </KoalaTextBadge>
            </StreakContainer>
          )}
        </Banner>
      </>
    );
  }

  return (
    <>
      <CheckinsListHeader>
        <h4>{t('workspaceInbox.pendingCheckins')}</h4>
        <KoalaTextBadge edge="circle" size="small" variant="urgent">
          {pending_checkins_count}
        </KoalaTextBadge>
        <KoalaButton size="xsmall" appearance="subtle" onClick={handleBulkUpdate}>
          {t('workspaceInbox.bulkCheckin')}
        </KoalaButton>
      </CheckinsListHeader>
      <Banner color={theme.colors.Y5} className="item">
        <LeftContainer>
          <KoalaIcon iconName="flag" iconAppearance={theme.colors.Y60} />
          <p>{t('workspaceInbox.pendingCheckinsCount', { count: pending_checkins_count })}</p>
        </LeftContainer>

        <StreakContainer>
          {streakCount === 0 && <p>{t('workspaceInbox.startStreak')}</p>}
          {streakCount > 0 && <p>{t('workspaceInbox.extendStreak')}</p>}
          <KoalaTextBadge variant="orange-outlined" isLowercase onClick={handleShowStreakLadder}>
            <KoalaIcon iconName="fire" iconSize="small" iconAppearance={theme.colors.O50} />
            {streakCount}x
          </KoalaTextBadge>
        </StreakContainer>
      </Banner>
      <OutcomeList>
        {pendingCheckins.map((group, i: number) => {
          return (
            <Fragment key={i}>
              {group.data.map((outcome: TabilityTypes.Outcome, index: number) => {
                return <OutcomeExpandable outcome={outcome} hideExpand={true} hideTags={true} />;
              })}
            </Fragment>
          );
        })}
      </OutcomeList>
      {canFetchMore && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMore()}
            loading={isFetchingMore}
            disabled={isFetchingMore}
            appearance="secondary"
          >
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
    </>
  );
}

export default PendingCheckinsList;
