import React from 'react';

const PersonIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.89474C8.43034 2.89474 7.15789 4.16719 7.15789 5.73684C7.15789 7.30649 8.43034 8.57895 10 8.57895C11.5696 8.57895 12.8421 7.30649 12.8421 5.73684C12.8421 4.16719 11.5696 2.89474 10 2.89474ZM5.26315 5.73684C5.26315 3.12076 7.38391 1 10 1C12.6161 1 14.7368 3.12076 14.7368 5.73684C14.7368 8.35293 12.6161 10.4737 10 10.4737C7.38391 10.4737 5.26315 8.35293 5.26315 5.73684Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.36842 17.2L3.00316 18.0741C2.6506 17.9268 2.42105 17.5821 2.42105 17.2C2.42105 14.0777 3.17971 11.8676 4.62094 10.4497C6.06065 9.03322 8.00757 8.57895 10 8.57895C12.1769 8.57895 14.1478 9.02303 15.5493 10.4646C16.9413 11.8964 17.5789 14.1151 17.5789 17.2C17.5789 17.5821 17.3494 17.9268 16.9968 18.0741L16.6316 17.2C16.9968 18.0741 16.9972 18.074 16.9968 18.0741L16.9937 18.0754L16.9899 18.077L16.9799 18.0811L16.9508 18.0926C16.9273 18.1017 16.8957 18.1137 16.8558 18.128C16.7761 18.1567 16.6633 18.195 16.5165 18.2397C16.2228 18.3289 15.7934 18.4432 15.2202 18.5559C14.0737 18.7813 12.3545 19 10 19C7.64547 19 5.92627 18.7813 4.77976 18.5559C4.20664 18.4432 3.77717 18.3289 3.48352 18.2397C3.33671 18.195 3.22392 18.1567 3.14417 18.128C3.1043 18.1137 3.07269 18.1017 3.04923 18.0926L3.02013 18.0811L3.01013 18.077L3.00627 18.0754L3.00462 18.0747C3.00426 18.0746 3.00316 18.0741 3.36842 17.2ZM15.672 16.5101C15.5821 14.0536 15.005 12.6229 14.1908 11.7854C13.3127 10.8822 11.9678 10.4737 10 10.4737C8.26217 10.4737 6.89329 10.872 5.94977 11.8003C5.08663 12.6495 4.43339 14.0864 4.33007 16.5106C4.54306 16.5671 4.81408 16.6316 5.14534 16.6968C6.16684 16.8976 7.76343 17.1053 10 17.1053C12.2366 17.1053 13.8332 16.8976 14.8547 16.6968C15.187 16.6314 15.4587 16.5667 15.672 16.5101ZM3.7287 16.3238C3.72864 16.3238 3.72864 16.3238 3.7287 16.3238Z"
      fill="#737373"
    />
  </svg>
);

export default PersonIcon;
