import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import styled from 'styled-components';
import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import { ReactElement } from 'react-markdown';
import { spacing, colors, typography } from 'koala/shared/styles';

export interface ProgressData {
  progressPercentage: number;
  colorType: string;
  centerLabel?: string | string[];
  getCenterIcon?: () => JSX.Element;
}

interface CircularProgressProps {
  /** data for ProgressPie */
  data: ProgressData;
  /** Optional element for the legend */
  legendElement?: ReactElement;
  /** Optional size from KoalaSizes */
  size?: (typeof KoalaSizes)[number];
  /** Optional appearance from KoalaAppearances */
  appearance?: (typeof KoalaAppearances)[number];
  dataFor?: string;
  /** Optional class name for Progress Pie */
  className?: string;
  /** Optional custom styling for Progress Pie */
  style?: React.CSSProperties;
  labelColor?: string | null;
  useIcon?: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing.x4};

  &.circular-progress--xsmall {
    .circular-progress-chart {
      width: 2rem;
      height: 2rem;
    }
  }
  &.circular-progress--small {
    .circular-progress-chart {
      width: 4.5rem;
      height: 4.5rem;
    }
  }
  &.circular-progress--medium {
    .circular-progress-chart {
      width: 6rem;
      height: 6rem;
    }
  }
  &.circular-progress--large {
    .circular-progress-chart {
      width: 10rem;
      height: 10rem;
    }
  }
  &.circular-progress--xlarge {
    .circular-progress-chart {
      width: 14rem;
      height: 14rem;
    }
  }
`;
const ChartContainer = styled.div`
  margin: 0;
`;

function KoalaCircularProgress(props: CircularProgressProps) {
  const { data, legendElement, size, dataFor, useIcon, className, style } = props;

  const pieClass = `circular-progress ${className ?? ''} circular-progress--${size ? size : 'medium'}`;

  const fillColor = props.labelColor || colors.N100;

  let pieArguments: any = {};
  let labelStyleArguments: any = [];
  let dyArgument = null;
  const font = typography.fontFamily;
  switch (size) {
    case 'xsmall':
      pieArguments = { width: 20, height: 20, innerRadius: 6 };
      break;
    case 'small':
      pieArguments = { width: 45, height: 45, innerRadius: 18 };
      labelStyleArguments.push({ fontSize: 10, fontWeight: 500, fill: fillColor, fontFamily: font });
      if (Array.isArray(data.centerLabel)) {
        labelStyleArguments.push({ fontSize: 9, fontWeight: 400, fill: fillColor, fontFamily: font });
        dyArgument = 1;
      }
      break;
    case 'medium':
      pieArguments = { width: 60, height: 60, innerRadius: 24 };
      labelStyleArguments.push({ fontSize: 16, fontWeight: 500, fill: fillColor, fontFamily: font });
      if (Array.isArray(data.centerLabel)) {
        labelStyleArguments.push({ fontSize: 10, fontWeight: 400, fill: fillColor, fontFamily: font });
        dyArgument = 2;
      }
      break;
    case 'large':
      pieArguments = { width: 100, height: 100, innerRadius: 42 };
      labelStyleArguments.push({ fontSize: 24, fontWeight: 500, fill: fillColor, fontFamily: font });
      if (Array.isArray(data.centerLabel)) {
        labelStyleArguments.push({ fontSize: 16, fontWeight: 400, fill: fillColor, fontFamily: font });
        dyArgument = 6;
      }
      break;
    case 'xlarge':
      pieArguments = { width: 140, height: 140, innerRadius: 58, cornerRadius: 8 };
      labelStyleArguments.push({ fontSize: 38, fontWeight: 500, fill: fillColor, fontFamily: font });
      if (Array.isArray(data.centerLabel)) {
        labelStyleArguments.push({ fontSize: 20, fontWeight: 400, fill: fillColor, fontFamily: font });
        dyArgument = 6;
      }
      break;
    default:
      pieArguments = { width: 100, height: 100, innerRadius: 42 };
      labelStyleArguments.push({ fontSize: 24, fontWeight: 500, fill: fillColor, fontFamily: font });
      break;
  }

  const chartData = () => {
    const primaryColor = colors[`${data.colorType}60`];
    return [
      { x: 1, y: data.progressPercentage, color: primaryColor },
      { x: 2, y: 100 - data.progressPercentage, color: 'transparent' },
    ];
  };

  const backgroundData = () => {
    const backgroundColor = colors[`${data.colorType}10`];
    return [{ x: 1, y: 100 - data.progressPercentage, color: backgroundColor }];
  };

  return (
    <Container style={style} className={pieClass} data-tip={dataFor ? true : false} data-for={dataFor}>
      <ChartContainer className="circular-progress-chart">
        <svg viewBox={`0 0 ${pieArguments.width} ${pieArguments.height}`} width="100%" height="100%">
          <VictoryPie
            standalone={false}
            style={{
              data: {
                fill: (data: any) => {
                  return data.datum.color;
                },
              },
            }}
            padding={0}
            data={backgroundData()}
            labels={() => null}
            {...pieArguments}
          />
          <VictoryPie
            standalone={false}
            cornerRadius={4}
            style={{
              data: {
                fill: (data: any) => {
                  return data.datum.color;
                },
              },
            }}
            padding={0}
            data={chartData()}
            labels={() => null}
            {...pieArguments}
          />
          {useIcon && data.getCenterIcon && (
            <g transform={`translate(${pieArguments.width / 2 - 10},${pieArguments.height / 2 - 10})`}>
              {data.getCenterIcon()}
            </g>
          )}
          {size !== 'xsmall' && !useIcon && (
            <>
              {dyArgument && (
                <VictoryLabel
                  textAnchor="middle"
                  verticalAnchor="middle"
                  x={pieArguments.width / 2}
                  y={pieArguments.height / 2}
                  text={data.centerLabel}
                  style={labelStyleArguments}
                  dy={dyArgument}
                />
              )}
              {!dyArgument && (
                <VictoryLabel
                  textAnchor="middle"
                  verticalAnchor="middle"
                  x={pieArguments.width / 2}
                  y={pieArguments.height / 2}
                  text={data.centerLabel}
                  style={labelStyleArguments}
                />
              )}
            </>
          )}
        </svg>
      </ChartContainer>
      {legendElement}
    </Container>
  );
}

KoalaCircularProgress.defaultProps = {
  size: 'medium',
  appearance: 'primary',
};

export default KoalaCircularProgress;
