import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { Helmet } from 'react-helmet';
// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
//import ContentHeader from 'components/ContentHeader';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import { capitaliseFirst, getLocaleDaysOfWeek } from 'utils/dateUtils';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const SelectLine = styled.div`
  display: flex;
  align-items: center;
  span {
    margin: 0 ${theme.spacing.x2};
  }
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const Content = styled.div`
  p {
    margin: ${theme.spacing.x1} 0;
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingsDetails(props: Props) {
  const queryCache = useQueryCache();
  const { t, i18n } = useTranslation();
  const { workspace } = props;

  const [weekly_reminder_day, setWeeklyReminderDay] = useState(workspace.weekly_reminder_day);
  const [weekly_reminder_hour, setWeeklyReminderHour] = useState(workspace.weekly_reminder_hour);

  const [weekly_report_day, setWeeklyReportDay] = useState(workspace.weekly_report_day);
  const [weekly_report_hour, setWeeklyReportHour] = useState(workspace.weekly_report_hour);

  const [monthly_report_day, setMonthlyReportDay] = useState(workspace.monthly_report_day);
  const [monthly_reminder_day_difference, setMonthlyReminderDayDifference] = useState(
    workspace.monthly_reminder_day_difference,
  );

  const [default_standup_frequency, setDefaultStandupFrequency] = useState<string>(workspace.default_standup_frequency);
  const [default_standup_hour, setDefaultStandupHour] = useState<number>(workspace.default_standup_hour);
  const [default_weekly_standup_day, setDefaultWeeklyStandupDay] = useState<number>(
    workspace.default_weekly_standup_day,
  );

  // Workspace mutations
  const [updateWorkspaceMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.updateWorkspace, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentWorkspace);
    },
  });

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'weekly_reminder_day':
        setWeeklyReminderDay(value);
        break;
      case 'weekly_reminder_hour':
        setWeeklyReminderHour(value);
        break;
      case 'weekly_report_day':
        setWeeklyReportDay(value);
        break;
      case 'weekly_report_hour':
        setWeeklyReportHour(value);
        break;
      case 'monthly_report_day':
        setMonthlyReportDay(value);
        break;
      case 'monthly_reminder_day_difference':
        setMonthlyReminderDayDifference(value);
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      weekly_reminder_day,
      weekly_reminder_hour,
      weekly_report_day,
      weekly_report_hour,
      monthly_report_day,
      monthly_reminder_day_difference,
      default_weekly_standup_day,
      default_standup_frequency,
      default_standup_hour,
    };
    updateWorkspaceMutation({
      workspaceId: workspace.id,
      workspace: params,
    });
  };

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'name':
        return t('errors.workspaceName');
      case 'slug':
        return t('errors.workspaceID');
      default:
        return '';
    }
  };

  const days = getLocaleDaysOfWeek('EEEE', i18n);
  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsWorkflow.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsWorkflow.title')}</h1>
          </ContentTitle>
          <Content>
            <h3>{t('workspaceSettingsWorkflow.defaultCheckinsSettings')}</h3>
            <p>{t('workspaceSettingsWorkflow.defaultCheckinsInfo')}</p>
          </Content>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <label>{t('workspaceSettingsWorkflow.reminders')}</label>
              <SelectLine>
                <select value={weekly_reminder_day} onChange={handleChange} name="weekly_reminder_day">
                  <option value="0">{capitaliseFirst(days[0])}</option>
                  <option value="1">{capitaliseFirst(days[1])}</option>
                  <option value="2">{capitaliseFirst(days[2])}</option>
                  <option value="3">{capitaliseFirst(days[3])}</option>
                  <option value="4">{capitaliseFirst(days[4])}</option>
                  <option value="5">{capitaliseFirst(days[5])}</option>
                  <option value="6">{capitaliseFirst(days[6])}</option>
                </select>
                <span>{t('shared.at')}</span>
                <select value={weekly_reminder_hour} onChange={handleChange} name="weekly_reminder_hour">
                  {Array(24)
                    .fill(0)
                    .map((_, i) => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    ))}
                </select>
                <div>:00</div>
              </SelectLine>
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsWorkflow.reports')}</label>
              <SelectLine>
                <select value={weekly_report_day} onChange={handleChange} name="weekly_report_day">
                  <option value="0">{capitaliseFirst(days[0])}</option>
                  <option value="1">{capitaliseFirst(days[1])}</option>
                  <option value="2">{capitaliseFirst(days[2])}</option>
                  <option value="3">{capitaliseFirst(days[3])}</option>
                  <option value="4">{capitaliseFirst(days[4])}</option>
                  <option value="5">{capitaliseFirst(days[5])}</option>
                  <option value="6">{capitaliseFirst(days[6])}</option>
                </select>
                <span>{t('shared.at')}</span>
                <select value={weekly_report_hour} onChange={handleChange} name="weekly_report_hour">
                  {Array(24)
                    .fill(0)
                    .map((_, i) => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    ))}
                </select>
                <div>:00</div>
              </SelectLine>
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsWorkflow.monthlyReminders')}</label>
              <SelectLine>
                <select
                  value={monthly_reminder_day_difference}
                  onChange={handleChange}
                  name="monthly_reminder_day_difference"
                >
                  <option value="-7">{t('workspaceSettingsWorkflow.before', { count: 7 })}</option>
                  <option value="-6">{t('workspaceSettingsWorkflow.before', { count: 6 })}</option>
                  <option value="-5">{t('workspaceSettingsWorkflow.before', { count: 5 })}</option>
                  <option value="-4">{t('workspaceSettingsWorkflow.before', { count: 4 })}</option>
                  <option value="-3">{t('workspaceSettingsWorkflow.before', { count: 3 })}</option>
                  <option value="-2">{t('workspaceSettingsWorkflow.before', { count: 2 })}</option>
                  <option value="-1">{t('workspaceSettingsWorkflow.before', { count: 1 })}</option>
                  <option value="0">{t('workspaceSettingsWorkflow.reminderOn')}</option>
                  <option value="1">{t('workspaceSettingsWorkflow.after', { count: 1 })}</option>
                  <option value="2">{t('workspaceSettingsWorkflow.after', { count: 2 })}</option>
                  <option value="3">{t('workspaceSettingsWorkflow.after', { count: 3 })}</option>
                  <option value="4">{t('workspaceSettingsWorkflow.after', { count: 4 })}</option>
                  <option value="5">{t('workspaceSettingsWorkflow.after', { count: 5 })}</option>
                  <option value="6">{t('workspaceSettingsWorkflow.after', { count: 6 })}</option>
                  <option value="7">{t('workspaceSettingsWorkflow.after', { count: 7 })}</option>
                </select>
                <span>{t('workspaceSettingsWorkflow.firstDay')}</span>
              </SelectLine>
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsWorkflow.monthlyReports')}</label>
              <SelectLine>
                <select value={monthly_report_day} onChange={handleChange} name="monthly_report_day">
                  <option value="1">{t('workspaceSettingsWorkflow.first')}</option>
                  <option value="2">{t('workspaceSettingsWorkflow.second')}</option>
                  <option value="3">{t('workspaceSettingsWorkflow.third')}</option>
                  <option value="4">{t('workspaceSettingsWorkflow.fourth')}</option>
                  <option value="5">{t('workspaceSettingsWorkflow.fifth')}</option>
                </select>
                <span>{t('workspaceSettingsWorkflow.dayOfMonth')}</span>
              </SelectLine>
            </FormField>
            <Content>
              <h3>{t('workspaceSettingsWorkflow.defaultStandupsSettings')}</h3>
              <p>{t('workspaceSettingsWorkflow.defaultStandupsInfo')}</p>
            </Content>
            <FormField>
              <label>{t('workspaceSettingsWorkflow.defaultStandupFrequencyHeader')}</label>
              <SelectLine>
                <select
                  value={default_standup_frequency}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setDefaultStandupFrequency(e.currentTarget.value)
                  }
                >
                  <option value="daily">{t('workspaceSettingsWorkflow.daily')}</option>
                  <option value="weekly">{t('workspaceSettingsWorkflow.weekly')}</option>
                  <option value="none">{t('workspaceSettingsWorkflow.none')}</option>
                </select>
              </SelectLine>
            </FormField>
            {default_standup_frequency !== 'none' && (
              <>
                {default_standup_frequency === 'weekly' && (
                  <FormField>
                    <label>{t('workspaceSettingsWorkflow.defaultStandupDayHeader')}</label>
                    <SelectLine>
                      <select
                        value={default_weekly_standup_day}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          setDefaultWeeklyStandupDay(parseInt(e.currentTarget.value))
                        }
                      >
                        <option value="0">{days[0]}</option>
                        <option value="1">{days[1]}</option>
                        <option value="2">{days[2]}</option>
                        <option value="3">{days[3]}</option>
                        <option value="4">{days[4]}</option>
                        <option value="5">{days[5]}</option>
                        <option value="6">{days[6]}</option>
                      </select>
                    </SelectLine>
                  </FormField>
                )}
                <FormField>
                  <label>{t('workspaceSettingsWorkflow.defaultStandupHourHeader')}</label>
                  <SelectLine>
                    <select
                      value={default_standup_hour}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setDefaultStandupHour(parseInt(e.currentTarget.value))
                      }
                    >
                      {Array(24)
                        .fill(0)
                        .map((_, i) => (
                          <option value={i} key={i}>
                            {i}
                          </option>
                        ))}
                    </select>
                    <div>:00</div>
                  </SelectLine>
                </FormField>
              </>
            )}
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading}>
                {t('shared.save')}
              </KoalaButton>
            </Actions>
          </Form>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
