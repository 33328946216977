import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQuery, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import Loader from 'components/Loader';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import KoalaButton from 'koala/components/Button';
import StandupBilling from './StandupBilling';
import { Trans, useTranslation } from 'react-i18next';

const Block = styled.div`
  margin-bottom: ${theme.spacing.x4};

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const PlansWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  column-gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x4};
  margin-top: ${theme.spacing.x2};
`;

const PlanCard = styled.div`
  padding: ${theme.spacing.x2};
  min-width: 18rem;
  border-radius: 8px;
  background: #f3f3f3;

  &.popular {
    background: #fbf1f0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  b {
    font-size: 2rem;
    margin-bottom: ${theme.spacing.x1};
  }
  big {
    font-size: 4rem;
    font-weight: 600;
  }
  span {
    color: ${theme.colors.subtleText};
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 800;
    margin-bottom: ${theme.spacing.x2};
  }
  p {
    margin-bottom: ${theme.spacing.x2};
  }

  label {
    height: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    margin-bottom: ${theme.spacing.x2};
  }

  small {
    text-align: center;
  }
`;

const Usage = styled.div`
  white-space: nowrap;
  margin-right: ${theme.spacing.x2};
`;

const Quota = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressBarContainer = styled.div`
  height: 1.2rem;
  flex: 1;
  background: ${theme.colors.blockBorder};
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ prct: number }>`
  height: 100%;
  background-color: ${theme.colors.blue};
  border-radius: 4px;
  width: ${(props) => props.prct}%;
`;

declare global {
  interface Window {
    Stripe: any;
    Rewardful: any;
  }
}

interface Props {
  workspace: TabilityTypes.Workspace;
}

function PricingVersion(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();
  const [usersCount, setUsersCount] = useState(0);
  const statsQueryKey = [queryKeys.currentWorkspace, workspace.slug, 'stats'];

  const { isFetching: isLoadingStats } = useQuery(statsQueryKey, remoteApi.fetchWorkspaceStats, {
    staleTime: 0,
    onSuccess: (response) => {
      const stats = response.data;
      const { users_count } = stats;
      setUsersCount(users_count);
    },
  });

  // Workspace mutations
  const [createWorkspaceCheckoutSessionMutation, { isLoading: isLoadingCheckout }]: [any, any] = useMutation(
    remoteApi.createWorkspaceCheckoutSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_id } = data;

        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        stripe
          .redirectToCheckout({
            sessionId: session_id,
          })
          .then((result: any) => {
            if (result.error) {
              alert(result.error.message);
            }
          });
      },
    },
  );

  // Workspace mutations
  const [createWorkspacePortalSessionMutation, { isLoading: isLoadingPortal }]: [any, any] = useMutation(
    remoteApi.createWorkspacePortalSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_url } = data;

        window.location.href = session_url;
      },
    },
  );

  const handleSubscribe = (price_id: string) => {
    const params = {
      workspaceSlug: workspace.slug,
      body: {
        price_id,
      },
    };
    createWorkspaceCheckoutSessionMutation(params);
  };

  const handleShowPortal = () => {
    const params = {
      workspaceSlug: workspace.slug,
    };
    createWorkspacePortalSessionMutation(params);
  };

  if (isLoadingStats) {
    return (
      <Fragment>
        <ChromeSideNav>
          <WorkspaceSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <ContentTitle>
              <h1>{t('workspaceSettingsBilling.header')}&nbsp;</h1>
            </ContentTitle>
            <Loader />
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  const hasPlan = workspaceUtils.hasSubscription(workspace);

  let quotaProgress = 0;
  if (workspace.billing_user_limit) {
    quotaProgress = Math.round((usersCount / workspace.billing_user_limit) * 100);
  }

  return (
    <Fragment>
      <Helmet>
        <script src="https://js.stripe.com/v3/" />
        <title>
          {workspace.name} | {t('workspaceSettingsBilling.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsBilling.header')}&nbsp;</h1>
          </ContentTitle>
          {hasPlan && (
            <Block>
              <h3>{t('workspaceSettingsBilling.yourPlan')}</h3>
              <p>
                <Trans
                  i18nKey="workspaceSettingsBilling.currentPlan"
                  values={{ plan: workspaceUtils.billingSubscriptionToString(workspace) }}
                  components={{ b: <b /> }}
                />
              </p>
              {workspace.billing_user_limit !== undefined && workspace.billing_user_limit > 0 && (
                <p>
                  <Trans
                    i18nKey="workspaceSettingsBilling.v1.userLimit"
                    values={{ count: workspace.billing_user_limit }}
                    components={{ b: <b /> }}
                  />
                </p>
              )}
              {workspace.billing_user_limit === 0 && (
                <p>
                  <Trans i18nKey="workspaceSettingsBilling.v1.unlimited" components={{ b: <b /> }} />
                </p>
              )}
            </Block>
          )}

          {!hasPlan && (
            <Block>
              <h3>{t('workspaceSettingsBilling.yourPlan')}</h3>
              <p>
                <Trans
                  i18nKey="workspaceSettingsBilling.currentPlan"
                  values={{ plan: 'Free' }}
                  components={{ b: <b /> }}
                />
              </p>
              {workspace.billing_user_limit > 0 && (
                <p>
                  <Trans
                    i18nKey="workspaceSettingsBilling.v1.userLimit"
                    components={{ b: <b /> }}
                    values={{ count: workspace.billing_user_limit }}
                  />
                </p>
              )}
            </Block>
          )}
          {workspace.billing_user_limit !== undefined && workspace.billing_user_limit > 0 && (
            <Block>
              <h3>{t('workspaceSettingsBilling.usage')}</h3>
              <Quota>
                <Usage>
                  <b>{usersCount}</b> / {t('workspaceSettingsBilling.users', { count: workspace.billing_user_limit })}
                </Usage>
                <ProgressBarContainer>
                  <ProgressBar prct={quotaProgress} />
                </ProgressBarContainer>
              </Quota>
            </Block>
          )}

          {!hasPlan && (
            <Block>
              <h3>{t('shared.upgrade.startTrial')}</h3>
              <p>{t('workspaceSettingsBilling.v1.trial')}</p>
              <PlansWrapper>
                <PlanCard>
                  <Trans
                    i18nKey="workspaceSettingsBilling.v1.starter"
                    components={{ b: <b />, big: <big />, span: <span />, p: <p /> }}
                  />
                  <KoalaButton
                    onClick={() => handleSubscribe('tability20-starter')}
                    disabled={isLoadingCheckout}
                    loading={isLoadingCheckout}
                  >
                    {t('workspaceSettingsBilling.v1.startTrial')}
                  </KoalaButton>
                  <small>{t('workspaceSettingsBilling.v1.starterInfo')}</small>
                </PlanCard>
                <PlanCard className="popular">
                  <Trans
                    i18nKey="workspaceSettingsBilling.v1.essentials"
                    components={{ b: <b />, big: <big />, span: <span />, p: <p /> }}
                  />
                  <KoalaButton
                    onClick={() => handleSubscribe('tability20-essentials')}
                    disabled={isLoadingCheckout}
                    loading={isLoadingCheckout}
                  >
                    {t('workspaceSettingsBilling.v1.startTrial')}
                  </KoalaButton>
                  <small>{t('workspaceSettingsBilling.v1.starterInfo')}</small>
                </PlanCard>
                <PlanCard>
                  <Trans
                    i18nKey="workspaceSettingsBilling.v1.automation"
                    components={{ b: <b />, big: <big />, span: <span />, p: <p /> }}
                  />
                  <KoalaButton
                    onClick={() => handleSubscribe('tability20-automation')}
                    disabled={isLoadingCheckout}
                    loading={isLoadingCheckout}
                  >
                    {t('workspaceSettingsBilling.v1.startTrial')}
                  </KoalaButton>
                  <small>{t('workspaceSettingsBilling.v1.automationInfo')}</small>
                </PlanCard>
                <PlanCard>
                  <Trans
                    i18nKey="workspaceSettingsBilling.v1.unlimited"
                    components={{ b: <b />, big: <big />, span: <span />, p: <p /> }}
                  />

                  <KoalaButton
                    onClick={() => handleSubscribe('tability20-unlimitedPlan')}
                    disabled={isLoadingCheckout}
                    loading={isLoadingCheckout}
                  >
                    {t('workspaceSettingsBilling.v1.startTrial')}
                  </KoalaButton>
                  <small>{t('workspaceSettingsBilling.v1.unlimitedInfo')}</small>
                </PlanCard>
              </PlansWrapper>
              <h3>{t('workspaceSettingsBilling.v1.compare')}</h3>
              <KoalaButton
                href="https://tability.io/pricing"
                target="_blank"
                rel="noopened noreferrer"
                appearance="subtle"
              >
                {t('workspaceSettingsBilling.compareButton')}
              </KoalaButton>
            </Block>
          )}
          <StandupBilling workspace={workspace} />

          {hasPlan && (
            <Block>
              <h3>{t('workspaceSettingsBilling.manageSubscription')}</h3>
              <KoalaButton
                onClick={() => handleShowPortal()}
                disabled={isLoadingPortal}
                loading={isLoadingPortal}
                appearance="subtle"
              >
                {t('workspaceSettingsBilling.manageSubscription')}
              </KoalaButton>
            </Block>
          )}
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default PricingVersion;
