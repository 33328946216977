import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import AddPersonIcon from 'components/_assets/AddPersonIcon';
import FormField from 'components/FormField';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import { useTranslation } from 'react-i18next';

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;
  background: #f3f3f3;

  &:hover {
    cursor: pointer;
    background: #e7e7e7;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }
`;

interface Props {
  membershipsToIgnore: string[];
}

function NewFollower(props: Props) {
  const { membershipsToIgnore } = props;
  const queryCache = useQueryCache();
  const { t } = useTranslation();

  const followingQueryKey = [queryKeys.following];

  const [createFollowerMutation]: [any, any] = useMutation(remoteApi.createFollower, {
    onSuccess: () => {
      queryCache.invalidateQueries(followingQueryKey);
    },
  });

  const handleAddFollower = (membership_id: any) => {
    if (membership_id) {
      createFollowerMutation(membership_id);
    }
  };

  return (
    <FormField>
      <UserSearchableDropdown
        ignoredMemberships={membershipsToIgnore}
        trigger={
          <OwnerOption className="active option-button">
            <AddPersonIcon /> <span className="owner-option-name"> {t('workspaceStandups.followUser')}</span>
          </OwnerOption>
        }
        callback={handleAddFollower}
      />
    </FormField>
  );
}

export default React.memo(NewFollower);
