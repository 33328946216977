import KoalaIconButton from 'koala/components/IconButton';
import KoalaSelect from 'koala/components/Select';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import FormField from 'components/FormField';
import PlanSummaryWidgetDetails from './WidgetDetails/PlanSummaryWidgetDetails';
import ObjectiveSummaryWidgetDetails from './WidgetDetails/ObjectiveSummaryWidgetDetails';
import OutcomeSummaryWidgetDetails from './WidgetDetails/OutcomeSummaryWidgetDetails';
import UserProfileWidgetDetails from './WidgetDetails/UserProfileDetails';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import InitiativeSummaryWidgetDetails from './WidgetDetails/InitiativeSummaryWidgetDetails';
import ObjectiveListWidgetDetails from './WidgetDetails/ObjectiveListWidgetDetails';
import OutcomeListWidgetDetails from './WidgetDetails/OutcomeListWidgetDetails';
import InitiativeListWidgetDetails from './WidgetDetails/InitiativeListWidgetDetails';
import OutcomeTaskGraphWidgetDetails from './WidgetDetails/OutcomeTaskGraphWidgetDetails';
import PlanConfidenceGraphWidgetDetails from './WidgetDetails/PlanConfidenceGraphWidgetDetails';
import PlanNCSGraphWidgetDetails from './WidgetDetails/PlanNCSGraphWidgetDetails';
import OutcomeGraphWidgetDetails from './WidgetDetails/OutcomeGraphWidgetDetails';
import TextBlockWidgetDetails from './WidgetDetails/TextBlockWidgetDetails';
import OutcomeStatsWidgetDetails from './WidgetDetails/OutcomeStatsWidgetDetails';
import OutcomeDistributionWidgetDetails from './WidgetDetails/OutcomeDistributionWidgetDetails';

const Mask = styled.div<{ showSearchPanel: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${(props) => (props.showSearchPanel ? '50' : '-1')};
  opacity: ${(props) => (props.showSearchPanel ? '1' : '0')};
  transition: 0.2s ease-out;
`;

const ContentWrapper = styled.div<{ showSearchPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 60rem;
  z-index: 60;
  transform: translateX(${(props) => (props.showSearchPanel ? '0%' : '100%')});
  transition: 0.2s ease-out;

  @media ${theme.devices.mobile} {
    max-width: 40rem;
    width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 3.2rem;
  background: #fff;
`;

const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #151515;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: ${theme.spacing.x3};
`;

interface Props {
  showPanel: boolean;
  handleClosePanel: () => void;
  resetTimer: number;
  addWidgetCallback: (dashboardParams: {
    widget_type: string;
    source_id?: string;
    source_type: string;
    title: string;
    widget_meta?: string;
  }) => void;
  isAddingWidget: boolean;
}

function WidgetAddPanel(props: Props) {
  const { handleClosePanel, showPanel, addWidgetCallback, isAddingWidget, resetTimer } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // useEffect to reset the widget type and title when resetTimer changes
  useEffect(() => {
    setWidgetType('');
    setWidgetTitle('');
  }, [resetTimer]);

  const [widgetType, setWidgetType] = useState('');
  const [widgetTitle, setWidgetTitle] = useState('');

  const handleSingleOptionSelect = (option: any) => {
    const value = option ? option.value : '';
    setWidgetType(value);
  };

  const insightOptions = [
    { label: t('workspaceDashboards.planSummary'), value: 'plan-summary' },
    {
      label: t('workspaceDashboards.objectiveSummary', {
        objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1),
      }),
      value: 'objective-summary',
    },
    {
      label: t('workspaceDashboards.outcomeSummary', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      }),
      value: 'outcome-summary',
    },
    {
      label: t('workspaceDashboards.initiativeSummary', {
        initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
      }),
      value: 'initiative-summary',
    },
    { label: t('workspaceDashboards.userProfile'), value: 'user-profile' },
    {
      label: t('workspaceDashboards.objectiveList', {
        objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1),
      }),
      value: 'objective-list',
    },
    {
      label: t('workspaceDashboards.outcomeList', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      }),
      value: 'outcome-list',
    },
    {
      label: t('workspaceDashboards.outcomeStats', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      }),
      value: 'outcome-stats',
    },
    {
      label: t('workspaceDashboards.outcomeDistribution', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      }),
      value: 'outcome-distribution',
    },
    {
      label: t('workspaceDashboards.initiativeList', {
        initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
      }),
      value: 'initiative-list',
    },
    {
      label: t('workspaceDashboards.textBlock'),
      value: 'text-block',
    },
    {
      label: t('workspaceDashboards.OutcomeTaskGraph', {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
        tasks: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
      }),
      value: 'outcome-task-graph',
    },
    {
      label: t('workspaceDashboards.PlanConfidenceGraph'),
      value: 'plan-confidence-graph',
    },
    {
      label: t('workspaceDashboards.PlanNCSGraph'),
      value: 'plan-ncs-graph',
    },
    {
      label: t('workspaceDashboards.OutcomeGraph', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      }),
      value: 'outcome-graph',
    },
  ];

  const getSelectedInsightOptionsFromFilter = () => {
    return insightOptions.filter((o: any) => {
      return o.value === widgetType;
    });
  };

  const handleAddWidget = (type: string, source: string, source_id?: string, widgetID?: string, widgetMeta?: any) => {
    const dashboard_widget = {
      widget_type: type,
      source_id: source_id,
      source_type: source,
      title: widgetTitle,
      widget_meta: widgetMeta,
    };
    addWidgetCallback(dashboard_widget);
  };

  const widgetDetails = () => {
    switch (widgetType) {
      case 'plan-summary':
        return <PlanSummaryWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'objective-summary':
        return <ObjectiveSummaryWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-summary':
        return <OutcomeSummaryWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'initiative-summary':
        return <InitiativeSummaryWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'user-profile':
        return <UserProfileWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'objective-list':
        return <ObjectiveListWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-list':
        return <OutcomeListWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-stats':
        return <OutcomeStatsWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-distribution':
        return <OutcomeDistributionWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'initiative-list':
        return <InitiativeListWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-task-graph':
        return <OutcomeTaskGraphWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'plan-confidence-graph':
        return <PlanConfidenceGraphWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'plan-ncs-graph':
        return <PlanNCSGraphWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-graph':
        return <OutcomeGraphWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'text-block':
        return <TextBlockWidgetDetails handleSaveWidget={handleAddWidget} isLoading={isAddingWidget} />;
      default:
        break;
    }
  };

  const titlePlaceholder = t('workspaceDashboards.widgetTitlePlaceholder') ?? 'Enter title for widget';

  return (
    <Fragment>
      <Mask onClick={handleClosePanel} showSearchPanel={showPanel} />
      <ContentWrapper showSearchPanel={showPanel}>
        <Content>
          <SearchHeader>
            <p>{t('workspaceDashboards.add')}</p>
            <KoalaIconButton onClick={handleClosePanel} iconName="close" />
          </SearchHeader>

          <FormField>
            <label>{t('workspaceDashboards.widgetType')}</label>
            <KoalaSelect
              placeholder={t('workspaceDashboards.widgetTypePlaceholder')}
              options={insightOptions}
              handleChange={handleSingleOptionSelect}
              selectedOption={getSelectedInsightOptionsFromFilter()}
            />
          </FormField>

          {widgetType && (
            <FormField>
              <label>{t('workspaceDashboards.widgetTitle')}</label>
              <input
                value={widgetTitle}
                onChange={(e) => setWidgetTitle(e.target.value)}
                placeholder={titlePlaceholder}
              />
            </FormField>
          )}

          {widgetDetails()}
        </Content>
      </ContentWrapper>
    </Fragment>
  );
}

export default WidgetAddPanel;
