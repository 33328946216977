import React from 'react';
import { useTranslation } from 'react-i18next';

import { IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Workspace } from 'types';
import GoogleSheetIcon from '../_assets/IconGoogleSheet.png';
import KoalaButton from 'koala/components/Button';

interface Props {
  workspace: Workspace;
}

function GoogleSheetIntegration(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={GoogleSheetIcon} alt="GoogleSheet" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.googleSheetHeader')}</h3>
        <p>{t('workspaceSettingsIntegrations.googleSheetInfo')}</p>
        <p>
          <a
            href="https://guides.tability.app/docs/integrations/google-sheet"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
        <p>
          <KoalaButton isLink size="small" appearance="secondary" to={`/${workspace.slug}/account/integrations`}>
            {t('workspaceSettingsIntegrations.connectGoogleAccount')}
          </KoalaButton>
        </p>
      </IntegrationContent>
    </IntegrationBlock>
  );
}

export default GoogleSheetIntegration;
