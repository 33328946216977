import React, { useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import KoalaInputValidation from 'koala/components/InputValidation';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();
  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcome.id, `data_source:monday:${outcome.data_source_type}`, 'current'];
  const staleTime = 300;

  const { data: sourceData, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const result = sourceData?.data.result;
  if (result?.error) {
    return (
      <FormField>
        <p>{t(`panels.editOutcome.queryError`)}</p>
        <p>{result.error}</p>
      </FormField>
    );
  }
  const currentValue = toNumber(result);

  if (currentValue === null || currentValue === undefined) {
    return <FormField>{t(`panels.editOutcome.errorFetching`)}</FormField>;
  }

  return (
    <FormField>
      <label>{t('modals.dataConnectors.preview')}</label>
      <p>{currentValue}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  workspaceSlug: string;
}

function MondaySource(props: Props) {
  const { outcome, workspaceSlug } = props;
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [board, setBoard] = useState<string>(outcome.data_source_meta ? outcome.data_source_meta.board_id : '');
  const [group, setGroup] = useState<string>(outcome.data_source_meta ? outcome.data_source_meta.group_id : '');
  const [boardOptions, setBoardOptions] = useState<KoalaSelectOption[]>([]);
  const [groupOptions, setGroupOptions] = useState<KoalaSelectOption[]>([]);
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');
  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const staleTime = 0;
  const queryKeyBoards = ['monday_boards', workspaceSlug];
  const { isLoading: isLoadingBoards } = useQuery(queryKeyBoards, remoteApi.listMondayBoards, {
    staleTime,
    onSuccess: (response) => {
      setError('');

      if (!response.data?.boards) {
        setBoardOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.boards.map((e: any) => ({
        label: e.name,
        value: e.id,
      }));
      setBoardOptions(options);
    },
    onError: (error: any) => {
      setError(error.response.data.error);
    },
  });

  const queryKeyGroups = ['monday_groups', workspaceSlug, board];
  const { isLoading: isLoadingGroups } = useQuery(queryKeyGroups, remoteApi.listMondayGroups, {
    staleTime,
    onSuccess: (response) => {
      setError('');
      if (!response.data?.groups) {
        setGroupOptions([]);
        return;
      }

      const options: KoalaSelectOption[] = response.data.groups.map((e: any) => ({
        label: e.title,
        value: e.id,
      }));
      setGroupOptions(options);
    },
    onError: (error: any) => {
      setError(error.response.data.error);
    },
    enabled: !!board,
  });

  const handleUpdate = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'monday',
      data_source_type,
      data_source_meta: { board_id: board, group_id: group },
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const onBoardChange = (option: any) => {
    const value = option ? option.value : null;
    setBoard(value);
  };

  const onGroupChange = (option: any) => {
    const value = option ? option.value : null;
    setGroup(value);
  };

  const selectedBoardOption = boardOptions.find((option) => option.value === board);
  const selectedGroupOption = groupOptions.find((option) => option.value === group);

  const mondayKey = `modals.dataConnectors.mondayData`;
  return (
    <>
      <FormField>
        <label>{t(`modals.dataConnectors.changeMetric`)}</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">{t(`modals.dataConnectors.selectMetric`)}</option>
          <option value="tickets_in_group">{t(`${mondayKey}.tickets_in_group`)}</option>
          <option value="completed_high_priority">{t(`${mondayKey}.completed_high_priority`)}</option>
          <option value="total_tickets">{t(`${mondayKey}.total_tickets`)}</option>
        </select>
      </FormField>
      <FormField>
        <label>
          {t(`${mondayKey}.board`)}
          {isLoadingBoards && <KoalaLoader />}
        </label>
        {!isLoadingBoards && (
          <div style={{ width: '50%' }}>
            <KoalaSelect
              handleChange={(options) => onBoardChange(options)}
              placeholder={t(`${mondayKey}.boardPlaceholder`) ?? 'Select Board'}
              selectedOption={selectedBoardOption}
              options={boardOptions}
              className="small"
            />
          </div>
        )}
      </FormField>

      {data_source_type === 'tickets_in_group' && (
        <FormField>
          <label>
            {t(`${mondayKey}.group`)}

            {isLoadingGroups && <KoalaLoader />}
          </label>
          {!isLoadingGroups && (
            <div style={{ width: '50%' }}>
              <KoalaSelect
                handleChange={(options) => onGroupChange(options)}
                placeholder={t(`${mondayKey}.groupPlaceholder`) ?? 'Select Group'}
                selectedOption={selectedGroupOption}
                options={groupOptions}
                className="small"
              />
            </div>
          )}
        </FormField>
      )}

      {error && <KoalaInputValidation state="error" text={error} />}
      <FormField>
        <KoalaButton
          onClick={handleUpdate}
          loading={isUpdating}
          disabled={isUpdating || !!error}
          appearance="subtle"
          type="button"
        >
          {t(`panels.editOutcome.savePreview`)}
        </KoalaButton>
      </FormField>
      {outcome.data_source_origin === 'monday' && <CurrentValue outcome={outcome} />}
    </>
  );
}

export default React.memo(MondaySource);
