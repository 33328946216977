import React from 'react';

const WatchInactiveIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.0138 11.7251C8.14951 11.6049 7.36355 11.3338 6.65998 10.9788L5.37717 12.9579C5.07588 13.4228 4.46378 13.5468 4.01001 13.2349C3.55623 12.923 3.43262 12.2934 3.73391 11.8285L4.99162 9.88806C4.67255 9.62874 4.38191 9.36125 4.12047 9.09833C3.47088 8.44505 2.97561 7.79492 2.64234 7.30839C2.4751 7.06425 2.34706 6.85896 2.25931 6.71188C2.21539 6.63827 2.18146 6.57905 2.15764 6.53662C2.14573 6.51539 2.13634 6.49835 2.12948 6.48579L2.12111 6.47033L2.11835 6.46518L2.11655 6.46179L2.99865 5.99399L2.2273 6.40289C2.11794 6.46083 2.11647 6.46161 2.11654 6.46178L2.11655 6.46179C1.85779 5.97387 2.04356 5.36857 2.53148 5.10982C3.01889 4.85133 3.6237 5.03694 3.8829 5.52372L3.88345 5.52475L3.88539 5.5283L3.90171 5.55774C3.91736 5.58561 3.94246 5.62952 3.97685 5.68716C4.04571 5.80257 4.15133 5.97228 4.29234 6.17813C4.57556 6.59158 4.99509 7.14144 5.53869 7.68813C6.64014 8.79585 8.14968 9.79319 10 9.79319C11.8504 9.79319 13.3599 8.79584 14.4613 7.68812C15.0049 7.14142 15.4245 6.59156 15.7077 6.17811C15.8487 5.97225 15.9543 5.80255 16.0231 5.68713C16.0575 5.62949 16.0826 5.58558 16.0983 5.55771L16.1146 5.52827L16.1165 5.52472C16.3755 5.03722 16.9808 4.85112 17.4685 5.10976C17.9564 5.36851 18.1422 5.9738 17.8835 6.46173L17 5.99322C17.8835 6.46173 17.8829 6.46285 17.8827 6.4632L17.8817 6.46511L17.8789 6.47026L17.8705 6.48572C17.8637 6.49829 17.8543 6.51533 17.8424 6.53655C17.8186 6.57899 17.7846 6.63821 17.7407 6.71181C17.653 6.8589 17.5249 7.06419 17.3577 7.30833C17.0244 7.79487 16.5292 8.445 15.8796 9.0983C15.6181 9.36126 15.3274 9.62879 15.0083 9.88814L16.266 11.8285C16.5673 12.2934 16.4437 12.923 15.9899 13.2349C15.5361 13.5468 14.924 13.4228 14.6227 12.9579L13.34 10.9788C12.6364 11.3338 11.8505 11.6049 10.9863 11.7251L10.9863 13.9982C10.9863 14.5579 10.5447 15.0095 10 15.0068C9.45536 15.0041 9.0138 14.5482 9.0138 13.9884L9.0138 11.7251Z"
      fill="#737373"
    />
  </svg>
);

export default WatchInactiveIcon;
