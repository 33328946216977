import React, { useRef, useEffect, useMemo } from 'react';

import ReactQuill, { Quill } from 'react-quill';
import request from 'state/utils/request';
// @ts-ignore
import QuillMention from 'quill-mention';
// @ts-ignore
import LoadingImage from './LoadingImage';

import { store } from 'App';

// API
import * as remoteApi from 'api/remote';

// CSS
import './quill.css';
import './quill.mention.css';
import './LoadingImage.css';

Quill.register('modules/mentions', QuillMention);

// Function that fetches the memberships.
async function fetchMentions(inputValue: string) {
  // Get the workspaceSlug from the store.
  const workspaceSlug = store.getState().session.currentWorkspace.slug;

  const membershipsApiURL = remoteApi.WORKSPACE_MEMBERSHIPS_API.replace(':workspaceSlug', workspaceSlug);

  let membershipsParams = {
    per_page: 10,
  };

  // Add the searched name if it's provided.
  if (inputValue) {
    // @ts-ignore
    membershipsParams.name = inputValue;
  }

  const results = await request.get(membershipsApiURL, { params: membershipsParams }).then((response) => {
    const memberships = response.data;
    if (memberships && memberships.length > 0) {
      const options = memberships.map((membership: any) => {
        const user = membership.user;
        return {
          id: membership.id,
          value: user.name,
        };
      });
      return options;
    } else {
      return [];
    }
  });
  return results;
}

// Create the mention module outside of the component otherwise the re-renders kill the app.
const mentionModule = {
  allowedChars: /^[A-Za-z\s]*$/,
  mentionDenotationChars: ['@', '#'],
  source: async function (inputValue: string, renderList: any) {
    const matchedPeople = await fetchMentions(inputValue);
    renderList(matchedPeople);
  },
};

interface SimpleQuillProp {
  value: string;
  quillClassName?: string;
  onChange: any;
  placeholder?: string;
  disableMentions?: boolean;
  allowHeaders?: boolean;
  cmdEnterCallback?: any;
  disableAutoFocus?: boolean;
}

function SimpleQuill(props: SimpleQuillProp) {
  const { value, onChange, allowHeaders, placeholder, disableAutoFocus } = props;
  const editorRef: any = useRef(null);

  // Function that handles image upload
  async function imageHandler() {
    // Get the workspace slug to create the upload request later on.
    const workspaceSlug = store.getState().session.currentWorkspace.slug;
    const input = document.createElement('input');

    // Trigger the file upload
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/gif, image/jpeg, image/png');
    input.click();
    input.onchange = async () => {
      if (!input.files || !editorRef.current) {
        return;
      }

      const image: any = input.files[0];
      // @ts-ignore
      const quillEditor = editorRef.current.getEditor();

      const fileReader = new FileReader();
      let isUploadReject = false;

      // This function will upload a placeholder while we wait for the image to be uploaded.
      fileReader.addEventListener(
        'load',
        () => {
          if (!isUploadReject) {
            let base64ImageSrc = fileReader.result;
            // Insert image placeholder with loading spinner.
            quillEditor.insertEmbed(range.index, LoadingImage.blotName, base64ImageSrc, 'user');
          }
        },
        false,
      );

      if (image) {
        fileReader.readAsDataURL(image);
      }

      const params = {
        image,
        workspaceSlug,
      };

      // Save current cursor state
      const range = quillEditor.getSelection(true);

      remoteApi.createAttachment(params).then((response: any) => {
        // Replace the placeholder image with the secure_url
        quillEditor.deleteText(range.index, 3, 'user');
        quillEditor.insertEmbed(range.index, 'image', response.data.secure_url, 'user');
        range.index++;
        quillEditor.setSelection(range, 'user');
      });
    };
  }

  useEffect(() => {
    if (editorRef.current && !disableAutoFocus) {
      // @ts-ignore
      editorRef.current.getEditor().focus();
    }
  }, [editorRef, disableAutoFocus]);

  useEffect(() => {
    const quill = editorRef.current.getEditor();
    const handleEnter = (event: any) => {
      if (event.key === 'Enter' || event.key === ' ') {
        const range = quill.getSelection();
        if (range && range.index > 0) {
          // Get the text before the cursor position
          const textBeforeCursor = quill.getText(0, range.index);
          const urlRegex = /(https?:\/\/[^\s]+)/g;

          const match = textBeforeCursor.match(urlRegex);
          if (match) {
            const url = match[match.length - 1]; // Get the last URL found
            const startIndex = textBeforeCursor.lastIndexOf(url);
            const endIndex = startIndex + url.length;

            // Format the text as a link
            quill.formatText(startIndex, endIndex - startIndex, 'link', url);
          }
        }
      }
    };

    quill.root.addEventListener('keydown', handleEnter);

    return () => {
      quill.root.removeEventListener('keydown', handleEnter);
    };
  }, []);

  let bindings = {};

  const memoModules = useMemo(
    () => ({
      mention: {
        ...mentionModule,
      },
      toolbar: {
        container: [
          ['bold', 'italic'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image'],
          allowHeaders ? [{ header: [1, 2, 3, 4, 5, false] }] : [],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [allowHeaders],
  );

  const modules = {
    ...memoModules,
    keyboard: {
      bindings: bindings,
    },
  };

  // Remove the mention module if we have disabled the mentions
  if (props.disableMentions) {
    // @ts-ignore
    delete modules['mention'];
  }

  return (
    <div data-text-editor="body-editor">
      <ReactQuill
        ref={editorRef}
        value={value}
        className={props.quillClassName}
        theme={'snow'}
        modules={modules}
        onChange={onChange}
        bounds={`[data-text-editor="body-editor"]`}
        placeholder={placeholder}
      />
    </div>
  );
}

export default SimpleQuill;
