import styled from 'styled-components';
import theme from 'theme';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import queryKeys from 'config/queryKeys';
import * as routes from 'routes';

// API
import * as remoteApi from 'api/remote';
import { Link } from 'react-router-dom';
import OutcomesIcon from 'components/_assets/OutcomesIcon';
import { useDispatch } from 'react-redux';
import PlanIconLabel from 'components/PlanIconLabel';
import { useTranslation } from 'react-i18next';
import KoalaLoader from 'koala/components/Loader';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { Initiative, Outcome, Plan } from 'types';
import KoalaCheckbox from 'koala/components/Checkbox';

const Block = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
`;

const PlanOption = styled(Link)<{ isfocus: boolean }>`
  display: flex;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  align-items: center;
  border-radius: 4px;
  background-color: ${({ isfocus }) => (isfocus ? theme.colors.N5 : 'none')};
  cursor: pointer;
  font-size: 1.4rem;
  &.active {
    cursor: initial;
    background-color: ${theme.colors.N5};
  }

  &.outcome {
    svg {
      height: 1.4rem;
      min-height: 1.4rem;
      margin-right: ${theme.spacing.x1};
      path {
        stroke: ${theme.colors.blue};
      }
    }
  }
  &.initiative {
    gap: 1.2rem;
  }

  span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ResultsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface Props {
  searchText: string;
  focusElement: HTMLElement | null;
  setFocusElement: (e: HTMLElement) => void;
}
function SearchResults(props: Props) {
  const { searchText, focusElement, setFocusElement } = props;

  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [filteredPlans, setPlanOptions] = useState<Plan[]>([]);
  const [filteredOutcomes, setOutcomeOptions] = useState<Outcome[]>([]);
  const [filteredInitiatives, setInitiativeOptions] = useState<Initiative[]>([]);
  const { t } = useTranslation();
  const translationKey = 'modals.commandCenter';
  const dispatch = useDispatch();

  const limit = 7;
  const filter = {
    title: searchText,
    archived: false,
  };

  const params = {
    filter,
    order_direction: 'asc',
    order_attribute: 'title',
    limit,
  };

  // Get the plan details
  const { isLoading: isSearchingPlans } = useQuery([queryKeys.plans, workspaceSlug, params], remoteApi.fetchPlans, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const plans = response.data;
      setPlanOptions(plans);
    },
  });

  // Get the outcomes
  const { isLoading: isSearchingOutcomes } = useQuery(
    [queryKeys.outcomes, 'search', { workspaceSlug, filter, limit }],
    remoteApi.searchOutcomes,
    {
      staleTime: 0,
      onSuccess: (response: any) => {
        const outcomes = response.data;
        setOutcomeOptions(outcomes);
      },
    },
  );

  // Get the initiatives
  const { isLoading: isSearchingInitiatives } = useQuery(
    [queryKeys.initiatives, 'search', { workspaceSlug, filter, limit }],
    remoteApi.searchInitiatives,
    {
      staleTime: 0,
      onSuccess: (response: any) => {
        const initiatives = response.data;
        setInitiativeOptions(initiatives);
      },
    },
  );

  const handleClose = () => {
    dispatch(setGlobalModalContent(''));
  };

  // still searching
  const isSearching = isSearchingOutcomes || isSearchingPlans || isSearchingInitiatives;
  if (isSearching) {
    return (
      <Block>
        <KoalaLoader />
      </Block>
    );
  }
  // no results
  if (!isSearching && filteredPlans.length === 0 && filteredOutcomes.length === 0 && filteredInitiatives.length === 0) {
    return (
      <Block>
        <p className="subtle">{t(`${translationKey}.noResult`)}</p>
      </Block>
    );
  }

  return (
    <ResultsContainer>
      {filteredPlans.map((plan) => {
        const planRoute = routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
          ':planId',
          plan.nano_slug,
        );
        return (
          <PlanOption
            key={plan.id}
            to={planRoute}
            onClick={handleClose}
            className="result-item plan"
            id={plan.id}
            isfocus={focusElement?.id === plan.id}
            onMouseOverCapture={(e) => setFocusElement(e.target as HTMLElement)}
          >
            <PlanIconLabel plan={plan} size="medium" crop={78} />
          </PlanOption>
        );
      })}

      {filteredOutcomes.map((outcome) => {
        const outcomePath = `#outcome:${outcome.nano_slug}:show`;
        return (
          <PlanOption
            key={outcome.id}
            to={outcomePath}
            onClick={handleClose}
            className="result-item outcome"
            id={outcome.id}
            isfocus={focusElement?.id === outcome.id}
            onMouseOverCapture={(e) => setFocusElement(e.target as HTMLElement)}
          >
            <OutcomesIcon /> <span>{outcome.title}</span>
          </PlanOption>
        );
      })}

      {filteredInitiatives.map((initiative) => {
        const outcomePath = `#initiative:${initiative.nano_slug}:show`;
        return (
          <PlanOption
            key={initiative.id}
            to={outcomePath}
            onClick={handleClose}
            className="result-item initiative"
            id={initiative.id}
            isfocus={focusElement?.id === initiative.id}
            onMouseOverCapture={(e) => setFocusElement(e.target as HTMLElement)}
          >
            <KoalaCheckbox checked={initiative.state === 'closed'} size="small" isClickable={false} />
            <span>{initiative.title}</span>
          </PlanOption>
        );
      })}
    </ResultsContainer>
  );
}

export default SearchResults;
