import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Wordmark from 'components/Wordmark';

// Components
const Container = styled.div`
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x8};
`;

interface Props {
  workspaceSlug: string;
}

function JoinWorkspaceByDomain(props: Props) {
  const { workspaceSlug } = props;
  const authAPI = `https://${process.env.REACT_APP_TABILITY_API_DOMAIN}/auth/saml`;
  const saml_init_url = `${authAPI}/init/${workspaceSlug}`;

  return (
    <Container>
      <Helmet>
        <title>Single Sign-On</title>
      </Helmet>
      <WordmarkWrapper>
        <Wordmark type="dark" />
      </WordmarkWrapper>
      <h2>Single Sign-On access for {workspaceSlug}</h2>
      <p>
        <a href={saml_init_url} className="button primary">
          Log in via Single Sign-On
        </a>
      </p>
      <p>
        <Link to="/account" className="button secondary">
          Manage your account
        </Link>
      </p>
    </Container>
  );
}

export default JoinWorkspaceByDomain;
