import React from 'react';

import UserSelectButton from 'components/UserSelectButton';
import { Outcome } from 'types';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';

interface Props {
  outcome: Outcome;
  canEdit: boolean;
}

function OutcomeContributors(props: Props) {
  const { outcome, canEdit } = props;
  const queryCache = useQueryCache();

  const [addOutcomeContributorMutation]: [any, any] = useMutation(remoteApi.createOutcomeContributor, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomeContributors);
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const [deleteOutcomeContributorMutation]: [any, any] = useMutation(remoteApi.deleteOutcomeContributor, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomeContributors);
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const handleAssignUser = (value: string) => {
    const membership_id = value;
    if (membership_id) {
      const params = {
        membership_id,
      };

      addOutcomeContributorMutation({
        outcomeId: outcome.id,
        outcome_contributor: params,
      });
    }
  };

  return (
    <>
      {outcome.contributors &&
        outcome.contributors.map((outcomeContributor: any) => {
          const { membership } = outcomeContributor;
          const { user } = membership;
          const handleUnassignUser = () => {
            const outcomeContributorId = outcomeContributor.id;
            deleteOutcomeContributorMutation(outcomeContributorId);
          };

          return (
            <UserSelectButton
              canEdit={canEdit}
              handleAssignUser={handleAssignUser}
              handleUnassignUser={handleUnassignUser}
              selectedUser={user}
            />
          );
        })}
      <UserSelectButton canEdit={canEdit} handleAssignUser={handleAssignUser} selectedUser={undefined} />
    </>
  );
}

export default React.memo(OutcomeContributors);
