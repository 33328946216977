/**
  This is the playground editor. It loads the state from local storage and saves there too
 */

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useParams } from 'react-router-dom';

// Routes
import * as routes from 'routes';

// Components
import { NavLink } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x4} ${theme.spacing.x4};
  align-items: flex-start;
  width: 30rem;
  justify-content: flex-start;
  background: #0d383b;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);

  height: 100%;

  a {
    width: 100%;
    color: #fff;
    margin: 0;
    margin-bottom: ${theme.spacing.x2};
    border-bottom: 2px solid transparent;
    padding-bottom: 0.4rem;
    font-weight: 400;

    &:hover {
      border-bottom: 2px solid #fff;
      text-decoration: none;
      font-weight: 400;
    }
    &.active {
      border-bottom: 2px solid #fff;
      text-decoration: none;
      font-weight: 400;
    }
  }
`;

const Header = styled.div`
  margin-bottom: ${theme.spacing.x2};
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #6ecad6;
  font-size: 1rem;
  font-weight: 800;
`;

function InboxNav() {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  return (
    <Container>
      <Header>Account</Header>
      <NavLink
        to={routes.WORKSPACE_MEMBERSHIP_NOTIFICATIONS_ROUTE.replace(':workspaceSlug', workspaceSlug)}
        exact={true}
      >
        Manage your notifications
      </NavLink>
      <NavLink to={routes.WORKSPACE_MEMBERSHIP_STANDUPS_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
        Manage your standups
      </NavLink>

      <NavLink
        to={routes.WORKSPACE_MEMBERSHIP_INTEGRATIONS_ROUTE.replace(':workspaceSlug', workspaceSlug)}
        exact={true}
      >
        Integrations
      </NavLink>
      <NavLink to={routes.WORKSPACE_MEMBERSHIP_DELETE_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
        Leave workspace
      </NavLink>
    </Container>
  );
}

export default InboxNav;
