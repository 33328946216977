import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import KoalaSelect from 'koala/components/Select';
import { useTranslation } from 'react-i18next';

interface Props {
  value: any;
  handleChange: any;
}
function InsightFilter(props: Props) {
  const { value, handleChange } = props;
  const { t } = useTranslation();

  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const handleSingleOptionSelect = (selectId: string, option: any) => {
    const value = option ? option.value : '';
    handleChange(value);
  };

  const insightOptions = [
    {
      label: t('workspacePeople.insightFilter.tooManyOutcomes', {
        label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLocaleLowerCase(),
      }),
      value: 'too_many_outcomes',
    },
  ];

  const getSelectedInsightOptionsFromFilter = () => {
    return insightOptions.filter((o: any) => {
      return o.value === value;
    });
  };

  return (
    <KoalaSelect
      isClearable
      placeholder={t('workspacePeople.insightFilterPlaceholder')}
      options={insightOptions}
      handleChange={(options) => handleSingleOptionSelect('plan_status', options)}
      selectedOption={getSelectedInsightOptionsFromFilter()}
    />
  );
}

export default React.memo(InsightFilter);
