import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import PlanObjective from './PlanObjective';
import PlanNotes from './PlanNotes';
import TagPickerPlan from 'components/TagPickerPlan';

const PlanWrapper = styled.div`
  padding: ${theme.spacing.x4};
  margin: 0 auto;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  gap: ${theme.spacing.x2};
`;

const PlanContent = styled.div``;

interface Props {
  plan: TabilityTypes.Plan;
  objectives: TabilityTypes.Objective[];
  outcomes: TabilityTypes.Outcome[];
  outcomesByObjective: { [objectiveId: string]: TabilityTypes.Outcome[] };
  outcomesLoading: boolean;
}

function Tracker(props: Props) {
  const { plan, objectives, outcomesByObjective, outcomesLoading } = props;

  return (
    <PlanWrapper>
      <TagPickerPlan plan={plan} />
      <PlanNotes />
      <PlanContent>
        {objectives.map((objective, index) => (
          <PlanObjective
            objective={objective}
            outcomes={outcomesByObjective[objective.id] || []}
            isLoading={outcomesLoading}
            key={index}
          />
        ))}
      </PlanContent>
    </PlanWrapper>
  );
}

export default React.memo(Tracker);
