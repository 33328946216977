import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import { colors } from 'koala/shared/styles';
import React, { FC } from 'react';
import styled from 'styled-components';
import KoalaIcon from '../Icons';

interface Props {
  iconName: string;
  iconSize?: number;
  value: string;
  selected: boolean;
  expandable?: boolean;
  handleClick?: (e: any) => void;
  appearance?: (typeof KoalaAppearances)[number];
  size?: (typeof KoalaSizes)[number];
  dataFor?: string;
  className?: string;
  style?: React.CSSProperties;
}

const CounterContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  gap: 4px;
  color: ${colors.N60};
  border: 1px solid ${colors.N20};
  border-radius: 4px;
  height: 2rem;
  width: fit-content;
`;

const CounterText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: currentColor;
  height: 1.6rem;
  flex: 1;
`;

const CounterButton = styled(CounterContainer).attrs({ as: 'button' })`
  cursor: pointer;
  .chevron {
    transform: rotate(-90deg);
    transition: all ease 0.2s;
  }
  :hover {
    background: ${colors.N10};
    border: 1px solid ${colors.N20};
    color: ${colors.N60};
  }
  :active,
  :hover,
  &.selected {
    background: ${colors.B5};
    border: 1px solid ${colors.B20};
    color: ${colors.B50};
    path {
      fill: currentColor;
    }
  }
  &.selected {
    .chevron {
      transform: rotate(0deg);
    }
  }
`;

const KoalaCounterButton: FC<Props> = ({ ...props }) => {
  const { iconName, iconSize, value, handleClick, dataFor, selected, className, style, expandable } = props;

  const displayIconSize = iconSize || 1.4;
  // static
  if (!handleClick) {
    return (
      <CounterContainer
        onClick={handleClick}
        className={`counter-button ${className ?? ''}`}
        style={style}
        data-tip={dataFor ? true : false}
        data-for={dataFor}
      >
        <KoalaIcon
          iconName={iconName}
          iconSize={displayIconSize}
          iconAppearance="currentColor"
          className="koala-counter-icon"
        />
        <CounterText className="koala-counter-text">{value}</CounterText>
      </CounterContainer>
    );
  } else {
    const buttonClass = `${selected ? 'selected' : ''} ${className ?? ''}`;
    return (
      <CounterButton
        onClick={handleClick}
        className={buttonClass}
        style={style}
        data-tip={dataFor ? true : false}
        data-for={dataFor}
      >
        {expandable && <KoalaIcon iconName="downChevron" iconSize={1.4} className="chevron" />}
        <KoalaIcon
          iconName={iconName}
          iconSize={displayIconSize}
          iconAppearance="currentColor"
          className="koala-counter-icon"
        />
        <CounterText className="koala-counter-text">{value}</CounterText>
      </CounterButton>
    );
  }
};

KoalaCounterButton.defaultProps = {
  appearance: 'primary',
  size: 'medium',
  selected: false,
  expandable: false,
};

export default KoalaCounterButton;
