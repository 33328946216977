import React, { useState } from 'react';
import { InitiativeComment, InitiativeReply } from 'types';
import EditInitiativeComment from './_EditInitiativeComment';
import DisplayInitiativeComment from './_DisplayInitiativeComment';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';

import * as remoteApi from 'api/remote';
import InitiativeReplyBlock from './InitiativeReplyBlock';
import NewInitiativeReply from './NewInitiativeReply';
import styled from 'styled-components';

const Container = styled.div`
  min-height: 4rem;
`;

interface Props {
  comment: InitiativeComment;
  isSetNewState: boolean;
}

function InitiativeCommentBlock(props: Props) {
  const { comment, isSetNewState } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [showNewReply, setShowNewReply] = useState(false);

  const queryKeyInitiativeReplies = [queryKeys.initiativeReplies, comment.id];
  const staleTime = queryKeys.staleTime;
  const { data: initiativeRepliesResponse } = useQuery(queryKeyInitiativeReplies, remoteApi.fetchInitiativeReplies, {
    staleTime,
  });

  let replies: InitiativeReply[] = [];
  if (initiativeRepliesResponse) {
    replies = initiativeRepliesResponse.data;
  }

  return (
    <Container>
      {!isEditing && (
        <DisplayInitiativeComment
          comment={comment}
          setIsEditing={setIsEditing}
          setShowNewReply={setShowNewReply}
          isSetNewState={isSetNewState}
        />
      )}
      {isEditing && <EditInitiativeComment comment={comment} setIsEditing={setIsEditing} />}

      {replies.map((reply, i) => (
        <InitiativeReplyBlock reply={reply} setShowNewReply={setShowNewReply} key={i} comment={comment} />
      ))}
      {showNewReply && <NewInitiativeReply comment={comment} setShowNewReply={setShowNewReply} />}
    </Container>
  );
}

export default React.memo(InitiativeCommentBlock);
