import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  background: transparent;
  padding: ${theme.spacing.half} ${theme.spacing.x1};
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: ${theme.colors.N60};
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;

  border: 1px solid ${theme.colors.N30};

  &:hover {
    background: rgba(50, 77, 211, 0.15);
  }
`;
