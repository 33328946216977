import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import ShareLinkComponent from './ShareLinkComponent';
import { useTranslation } from 'react-i18next';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  &.btn--medium {
    height: 3.2rem;
  }
  &.btn--small {
    height: 2.4rem;
    button {
      font-size: 1.1rem;
    }
  }

  &.primary-btn > button {
    background-color: ${theme.colors.B50};
    color: ${theme.colors.N0};

    :hover {
      background: ${theme.colors.B60};
    }

    :active {
      background: ${theme.colors.B80};
    }
  }

  &.secondary-btn > button {
    background: ${theme.colors.N5};
    color: ${theme.colors.N70};
    :hover {
      background: ${theme.colors.N20};
    }
    :active {
      background: ${theme.colors.N40};
    }
  }

  button {
    padding: 0px ${theme.spacing.x1};
    align-items: center;
    justify-content: center;
    border: unset;
  }
`;
const Share = styled.button`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const Divider = styled.div<{ isSecondary: boolean }>`
  background: ${({ isSecondary }) => (isSecondary ? theme.colors.N40 : theme.colors.N0)};
  width: 1px;
`;

interface Props {
  id: string;
  link: string;
  shareCallback: () => void;
  isSecondary?: boolean;
  size?: 'small' | 'medium';
  className?: string;
}

function ShareButton(props: Props) {
  const { id, link, shareCallback, isSecondary, size, className } = props;
  const { t } = useTranslation();
  const handleShare = () => {
    shareCallback();
  };

  const appearanceClass = isSecondary ? 'secondary-btn' : 'primary-btn';
  const sizeClass = `btn--${size ?? 'medium'}`;

  return (
    <ButtonContainer className={`${className} ${appearanceClass} ${sizeClass}`}>
      <Share onClick={handleShare}>{t('shared.share')}</Share>
      <Divider isSecondary={!!isSecondary} />
      <ShareLinkComponent id={id} link={link} />
    </ButtonContainer>
  );
}

export default ShareButton;
