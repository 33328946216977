import React, { useState } from 'react';

import DisplayReplyBlock from './_DisplayReplyBlock';
import EditReplyBlock from './_EditReplyBlock';

interface Props {
  reviewReply: any;
}

function CommentBlock(props: Props) {
  const { reviewReply } = props;

  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return <EditReplyBlock reviewReply={reviewReply} setIsEditing={setIsEditing} />;
  }

  return <DisplayReplyBlock reviewReply={reviewReply} setIsEditing={setIsEditing} />;
}

export default React.memo(CommentBlock);
