import PlanIconLabel from 'components/PlanIconLabel';
import PlanStatus from 'components/PlanStatus';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { memo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Handle, NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';
import { Objective, Outcome } from 'types';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import OutcomeBlockCompact from './OutcomeBlockCompact';
import { Link } from 'react-router-dom';
import * as routes from 'routes';
import * as objectiveUtils from 'utils/objectiveUtils';
import * as planUtils from 'utils/planUtils';

import KoalaCircularProgress from 'koala/components/CircularProgress';
import KoalaNCSPie from 'koala/components/NCSPie';
import { useTranslation } from 'react-i18next';
import KoalaProgressBar from 'koala/components/ProgressBar';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 50rem;
`;
const PlanContainer = styled.div`
  width: 48rem;

  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  background-color: ${theme.colors.N0};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${theme.spacing.x1};
  justify-content: space-between;
  padding: ${theme.spacing.x1};
  a {
    width: 100%;
  }
  .plan-title {
    font-weight: 600;
  }
`;
const ExpandContainer = styled.div`
  .expand-button {
    transition: all ease 0.2s;
  }
  .show-outcomes {
    transform: rotate(-90deg);
  }
`;
const TitleDetails = styled.div`
  display: flex;
  gap: ${theme.spacing.half};
`;
const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  border-top: 1px solid ${theme.colors.N10};
  padding: ${theme.spacing.x1};
  max-height: 60rem;
  overflow: auto;

  &.show-outcomes {
    display: none;
  }
`;

const PlanMetrics = styled.div`
  display: flex;
  gap: ${theme.spacing.x3};
  padding: 0 ${theme.spacing.x1} ${theme.spacing.x1} ${theme.spacing.x1};
  justify-content: space-between;
  width: 100%;

  > div {
    &:first-of-type {
      width: 25%;
    }
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x1};
  }
`;

const ObjectiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
  align-items: center;
  width: 100%;
`;
const ObjectiveTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: ${theme.spacing.half};
  gap: ${theme.spacing.half};

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 10rem;
    width: inherit;
    font-weight: 600;
  }
`;

const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;
function PlanNode(node: NodeProps) {
  const { data } = node;
  const { t } = useTranslation();
  const { plan, expanded, handleExpand, expandable, handleFitView, outcomesExpanded, handleExpandOutcomes } = data;
  const { workspace } = plan;
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [outcomesByObjective, setOutcomesByObjective] = useState<{ [key: string]: Outcome[] }>({});
  // const [showOutcomes, setShowOutcomes] = useState(true);

  // When the outcomes are toggled, resize view to fit
  useEffect(() => {
    const timer = setTimeout(() => {
      handleFitView();
    }, 200); // Adjust the delay (in milliseconds) as needed

    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts or updates
  }, [outcomesExpanded, handleFitView]);

  const routeTarget =
    plan.state === 'published' ? routes.WORKSPACE_PLAN_TRACK_ROUTE : routes.WORKSPACE_PLAN_WRITE_ROUTE;
  const planRoute = routeTarget.replace(':workspaceSlug', workspace.slug).replace(':planId', plan.nano_slug);

  // Get the objectives
  const { isLoading: objectivesAreLoading } = useQuery([queryKeys.objectives, plan.id], remoteApi.fetchPlanObjectives, {
    staleTime: 0,
    onSuccess: (response) => {
      const objectives: Objective[] = response.data;
      setObjectives(objectives);
    },
  });

  // Get the outcomes
  const { isLoading: outcomesAreLoading } = useQuery([queryKeys.outcomes, plan.id], remoteApi.fetchPlanOutcomes, {
    staleTime: 0,
    onSuccess: (response) => {
      const outcomes: Outcome[] = response.data;
      const outcomesByObjective: { [key: string]: Outcome[] } = {};
      // Iterate on each outcome and group items by objective_id in an array
      outcomes.forEach((outcome) => {
        if (outcomesByObjective[outcome.objective_id]) {
          outcomesByObjective[outcome.objective_id].push(outcome);
        } else {
          outcomesByObjective[outcome.objective_id] = [outcome];
        }
      });
      setOutcomesByObjective(outcomesByObjective);
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    handleExpand(event, node);
  };

  const handleExpandOutcomesClick = (event: React.MouseEvent<HTMLDivElement>) => {
    handleExpandOutcomes(event, node);
  };

  const confidenceData = planUtils.getConfidenceData(plan);
  const outcomeProgressData = planUtils.getOutcomeProgressData(plan);

  const expandIcon = expanded ? 'rightChevron' : 'leftChevron';
  const isLoading = outcomesAreLoading || objectivesAreLoading;
  const expandedClassName = outcomesExpanded ? 'show-outcomes' : '';

  const outcomeCenterLabel = plan.total_outcomes_count === 0 ? '-' : `${outcomeProgressData.centerLabel}`;

  let ncsColor = theme.colors.G50;
  if (plan.ncs <= 30) {
    ncsColor = theme.colors.Y70;
  }
  if (plan.ncs < 0) {
    ncsColor = theme.colors.R50;
  }

  return (
    <Container>
      <CustomHandle position={Position.Left} type="target" id="in" key="in" isConnectableStart={false} />
      <PlanContainer>
        <Title>
          <ExpandContainer>
            <KoalaIconButton
              className={`${expandedClassName} expand-button`}
              iconName="downChevron"
              edge="square"
              onClick={handleExpandOutcomesClick}
            />
          </ExpandContainer>
          <Link to={planRoute}>
            <PlanIconLabel plan={plan} />
          </Link>
          <TitleDetails>
            <PlanStatus plan={plan} />
          </TitleDetails>
        </Title>
        {isLoading && <KoalaLoader />}
        <PlanMetrics>
          <div>
            <KoalaProgressBar value={plan.outcome_progress_prct * 100} confidence="blue" />
            <b>{outcomeCenterLabel}</b>
          </div>
          <div>
            <KoalaNCSPie centerLabel={[]} data={confidenceData} size="xsmall" />
            <b style={{ color: ncsColor }}>
              {Math.round(plan.ncs)} {t('shared.ncs.acronym')}
            </b>
          </div>
        </PlanMetrics>
        {objectives.length > 0 && (
          <Content className={`nowheel ${expandedClassName}`}>
            {objectives.map((objective) => {
              const outcomeProgressData = objectiveUtils.getOutcomeProgressData(objective);
              const outcomesToDisplay = outcomesByObjective[objective.id] || [];

              return (
                <ObjectiveContainer key={objective.id}>
                  <ObjectiveTitle>
                    <p>{objective.title}</p>
                    <KoalaCircularProgress data={outcomeProgressData} size="xsmall" />
                  </ObjectiveTitle>
                  {outcomesToDisplay.map((outcome) => (
                    <OutcomeBlockCompact isSelected={false} outcome={outcome} key={outcome.id} />
                  ))}
                </ObjectiveContainer>
              );
            })}
          </Content>
        )}
      </PlanContainer>
      {expandable && <KoalaIconButton size="small" onClick={handleClick} iconName={expandIcon} />}

      <CustomHandle type="source" position={Position.Right} key="out" id="out" isConnectableStart={false} />
    </Container>
  );
}

export default memo(PlanNode);
