import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import _ from 'lodash';
import { useQueryCache, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import { updatePlan } from 'api/remote';

// Components

import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  .hide {
    display: none;
  }
`;

const NavTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80rem;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 1;
  height: 3rem;
  border-radius: 4px;
  cursor: pointer;
  padding: 0.4rem 0;
  &:hover {
    background: #e7e7e7;
  }
  &.untitled {
    color: ${theme.colors.subtleText};
  }
`;

const TitleInputForm = styled.textarea`
  border: none;
  width: 100%;
  overflow: hidden;
  overflow-wrap: break-word;
  outline: none;
  box-shadow: none;
  resize: none;
  padding: 0;
  margin: 0;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 2.4rem;
  line-height: 1;
  height: 3rem;
  padding: 0.4rem 0;
  font-weight: 700;
  background: transparent;

  &:focus {
    outline: 0;
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
}

const PlanTitle = (props: Props) => {
  const { plan } = props;
  const queryCache = useQueryCache();
  const inputEl: any = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(plan.title);
  const { t } = useTranslation();
  // Used to avoid saving objective title on first render
  const isInitialRender = useRef(true);

  const queryKeyPlan = [queryKeys.currentPlan, plan.nano_slug];

  // Mutation that will update the plan in the backend
  const [updatePlanMutation, { isLoading }]: [any, any] = useMutation(updatePlan, {
    onSuccess: (planResponse) => {
      queryCache.setQueryData(queryKeyPlan, planResponse);
    },
  });

  const updatePlanTitle = (newTitle: string) => {
    const planParams = {
      title: newTitle,
    };
    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  const debounceUpdatePlanTitle = useRef(
    _.debounce((newTitle: string) => updatePlanTitle(newTitle), 500, {
      maxWait: 2000,
    }),
  );

  useEffect(() => {
    if (!isInitialRender.current) {
      debounceUpdatePlanTitle.current(title);
    }
  }, [title]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
  }, []);

  useEffect(() => {
    if (inputEl && inputEl.current && isEditing) {
      const textarea = inputEl.current;
      textarea.focus();

      const value = textarea.value;
      textarea.value = '';
      textarea.value = value;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
      textarea.select();
    }
  }, [isEditing]);

  const handlePaste = (e: any) => {
    e.preventDefault();
    let text = e.clipboardData.getData('text/plain');
    text = text.replace(/(\r\n|\n|\r)/gm, ' '); // remove the line breaks
    document.execCommand('insertHTML', false, text);
  };

  const resize = (e: any) => {
    const textarea = e.currentTarget;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  const handleBlur = (e: any) => {
    setIsEditing(false);
  };

  const handlePress = (e: any) => {
    if (e.keyCode === 27) {
      // Esc key
      e.preventDefault();
      e.currentTarget.blur();
    }

    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  const handleEdit = (e: any) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    let title = e.target.value || '';
    title = title.replace(/(\r\n|\n|\r)/gm, ''); // Remove new lines
    setTitle(title);
  };

  const titleClassname = isEditing ? 'hide' : '';
  const inputClassname = isEditing ? '' : 'hide';

  const untitledClass = plan.title === 'Untitled plan' ? 'untitled' : '';
  const placeholder = t('workspacePlan.namePlaceholder') ?? 'Name your plan';
  return (
    <Container>
      <NavTitle className={`${titleClassname} ${untitledClass}`} onClick={handleEdit}>
        {title}
      </NavTitle>
      <TitleInputForm
        className={inputClassname}
        rows={1}
        ref={inputEl}
        placeholder={placeholder}
        onPaste={handlePaste}
        onKeyDown={handlePress}
        onChange={handleChange}
        onBlur={handleBlur}
        value={title}
        onInput={resize}
      />
      {isLoading && <Loader />}
    </Container>
  );
};

export default PlanTitle;
