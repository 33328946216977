import React from 'react';

// Redux
import { shallowEqual, useSelector } from 'react-redux';

import { Draggable, Droppable } from 'react-beautiful-dnd';
import Outcome from './Outcome';
import ItemTitle from './ItemTitle';
import LineGrid, { Label } from './LineGrid';
import { CustomTermKey, translate } from 'utils/customTermUtils';

interface ObjectiveProps {
  objectiveId: string;
  index: string;
}

export default function Objective(props: ObjectiveProps) {
  const { index, objectiveId } = props;

  const items = useSelector((state: any) => state.ymlEditor.data.objectiveItems[objectiveId]);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const blockId = `objectives:${objectiveId}`;
  const selected = useSelector((state: any) => state.ymlEditor.cursor === blockId);
  const className = selected ? 'selected' : '';

  return (
    // @ts-ignore
    <Draggable draggableId={objectiveId} index={index}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <LineGrid className={className}>
              <div>
                <Label className="objective">{translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}</Label>
              </div>
              <div>
                <ItemTitle itemId={objectiveId} itemType="objectives" />
              </div>
            </LineGrid>
            <Droppable droppableId={objectiveId} type="objectiveItems">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((outcomeId: string, index: number) => {
                    // @ts-ignore
                    return <Outcome outcomeId={outcomeId} key={outcomeId} index={index} />;
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        );
      }}
    </Draggable>
  );
}
