import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import { DashboardWidget, Membership, StreakData } from 'types';
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import { Trans, useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';
import ReactTooltip from 'react-tooltip';
import KoalaNCSPie from 'koala/components/NCSPie';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import KoalaAvatar from 'koala/components/Avatar';
import { getConfidenceData, getInitiativeProgressData, getOutcomeProgressData } from 'utils/membershipUtils';
import SmallFire from 'pages/WorkspaceInbox/SmallFire';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { DragHandle, WidgetContainer, WidgetHeader } from '.';
import { WORKSPACE_PROFILE_ROUTE } from 'routes';

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  padding: ${theme.spacing.x2};
`;

const StyledSmallFire = styled(SmallFire)`
  &.off {
    path {
      fill: ${theme.colors.N10};
    }
  }
  &.mid {
    .outerFlame {
      fill: ${theme.colors.Y30};
    }

    .innerFlame {
      fill: ${theme.colors.Y30};
    }
  }
  &.warm {
    .outerFlame {
      fill: ${theme.colors.O30};
    }

    .innerFlame {
      fill: ${theme.colors.O30};
    }
  }
  &.waiting {
    .outerFlame {
      fill: ${theme.colors.Y30};
    }

    .innerFlame {
      fill: ${theme.colors.Y30};
    }
  }
`;

const UserRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};
`;
const DataSection = styled.div`
  margin: ${theme.spacing.x1} 0px;
  display: flex;
  flex-wrap: wrap;
  column-gap: ${theme.spacing.x2};
  row-gap: ${theme.spacing.x2};
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  min-width: 4rem;
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  widget: DashboardWidget;
}

function UserProfileWidget(props: Props) {
  const { widget } = props;
  const membershipId = widget.source_id;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const [streakData, setStreakData] = useState<StreakData>();
  const dispatch = useDispatch();
  const widgetTitle: string = widget.title ? widget.title : t('workspaceDashboards.userProfile');
  // Query keys and query params
  const queryKey = [queryKeys.currentMembership, membershipId];
  const staleTime = 0;
  // Get the outcome details
  const { data: membershipData, isLoading } = useQuery(queryKey, remoteApi.fetchMembershipDetails, {
    staleTime,
  });

  const queryKeyStreak = [queryKeys.streak, membershipId];

  const { isLoading: streakIsLoading } = useQuery(queryKeyStreak, remoteApi.fetchMembershipCurrentStreak, {
    staleTime: 0,
    onSuccess: (response) => {
      const streakData = response.data;
      setStreakData(streakData);
    },
  });

  if (streakIsLoading || isLoading) {
    return (
      <WidgetContainer>
        <KoalaLoader />
      </WidgetContainer>
    );
  }

  const membership: Membership = membershipData?.data ?? null;
  if (!membership) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <ContentContainer>
          <p>{t('workspaceDashboards.widgetError')}</p>
        </ContentContainer>
      </WidgetContainer>
    );
  }

  const confidenceData = getConfidenceData(membership);
  const outcomeProgressData = getOutcomeProgressData(membership);
  const initiativeProgressData = getInitiativeProgressData(membership);

  const streakCount = streakData?.streak_count ?? 0;
  let streakClass = ''; // neutrail

  // Set fire warmth to mid if streak has 1+ item
  if (streakCount >= 0) {
    streakClass = 'mid';
  }

  // Set fire warmth to warm if streak has 5+ items
  if (streakCount >= 5) {
    streakClass = 'warm';
  }

  // Set fire warmth to hot if streak has 10+ items
  if (streakCount >= 10) {
    streakClass = 'hot';
  }

  const streakComponent = () => {
    if (streakCount === 0) {
      return <StyledSmallFire className={`off`} />;
    }
    if (streakCount > 0) {
      return <StyledSmallFire className={streakClass} />;
    }
  };

  const handleShowStreakLadder = () => {
    dispatch(setGlobalModalContent(`workspace:${currentWorkspace.slug}:streak.ladder`));
  };

  const userPath = WORKSPACE_PROFILE_ROUTE.replace(':workspaceSlug', currentWorkspace.slug).replace(
    ':membershipId',
    membership.id,
  );

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <ContentContainer>
        {isLoading && <KoalaLoader />}
        <UserRow>
          <KoalaAvatar user={membership.user} size="large" />
          <div>{membership.cached_name}</div>
        </UserRow>

        <DataSection>
          <Row data-tip data-for={`${membership.id}-explain-outcomes-count`}>
            <IconContainer>
              <KoalaIcon iconName="flag" iconSize="small" />
            </IconContainer>
            <b>{membership.total_outcomes_count}</b>
            <ReactTooltip
              place="bottom"
              type="dark"
              className="tooltip"
              effect="solid"
              id={`${membership.id}-explain-outcomes-count`}
              delayShow={200}
            >
              {membership.total_outcomes_count} {translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}
            </ReactTooltip>
          </Row>

          {membership.total_outcomes_count > 0 && (
            <>
              <Row>
                <div data-tip data-for={`${membership.id}-explain-ncs`}>
                  <KoalaNCSPie centerLabel={''} data={confidenceData} size="xsmall" />
                </div>
                <b>{Math.round(membership.ncs).toString()} NCS</b>
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  className="tooltip"
                  effect="solid"
                  id={`${membership.id}-explain-ncs`}
                  delayShow={200}
                  clickable={true}
                >
                  <Trans i18nKey="shared.ncs.tooltip" />{' '}
                  <a
                    href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('shared.learnMore')}
                  </a>
                </ReactTooltip>
              </Row>
              <Row data-tip data-for={`${membership.id}-explain-outcomes`}>
                <KoalaCircularProgress data={outcomeProgressData} size="xsmall" />
                <b>{`${Math.round(membership.outcome_progress_prct * 100)}%`}</b>
              </Row>
              <ReactTooltip
                place="bottom"
                type="dark"
                className="tooltip"
                effect="solid"
                id={`${membership.id}-explain-outcomes`}
                delayShow={200}
              >
                {t('workspacePlan.insights.outcomeProgress', {
                  outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
                })}
              </ReactTooltip>
            </>
          )}
          {membership.total_initiatives_count > 0 && (
            <>
              <Row data-tip data-for={`${membership.id}-explain-initiatives`}>
                <KoalaCircularProgress data={initiativeProgressData} size="xsmall" />
                <b>{`${membership.closed_initiatives_count}/${membership.total_initiatives_count}`}</b>
              </Row>
              <ReactTooltip
                place="bottom"
                type="dark"
                className="tooltip"
                effect="solid"
                id={`${membership.id}-explain-initiatives`}
                delayShow={200}
              >
                {t('workspacePlan.insights.initiativeProgress', {
                  initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                })}
              </ReactTooltip>
            </>
          )}
          <Row
            data-tip
            data-for={`${membership.id}-explain-streak`}
            className="clickable"
            onClick={handleShowStreakLadder}
          >
            {streakComponent()}
            <b>{streakCount}</b>
            <ReactTooltip
              place="bottom"
              type="dark"
              className="tooltip"
              effect="solid"
              id={`${membership.id}-explain-streak`}
              delayShow={200}
            >
              <Trans i18nKey="workspaceInbox.streaksTooltip" />
            </ReactTooltip>
          </Row>
          <Row>
            <KoalaButton isLink to={userPath} appearance="secondary" size="small">
              {t('workspaceDashboards.viewProfile')}
            </KoalaButton>
          </Row>
        </DataSection>
      </ContentContainer>
    </WidgetContainer>
  );
}

export default UserProfileWidget;
