import * as actionTypes from './actionTypes';
import { store } from 'App';

// UI Actions

export const setPanelContent = (panelContent: string) => ({
  type: actionTypes.WORKSPACE_EDITOR_SET_PANEL_CONTENT,
  panelContent,
});

export const setHasSyncError = (hasSyncError: boolean) => ({
  type: actionTypes.WORKSPACE_EDITOR_SET_SYNC_ERROR,
  hasSyncError,
});

export const setSyncErrorText = (syncErrorText: string) => ({
  type: actionTypes.WORKSPACE_EDITOR_SET_SYNC_ERROR_TEXT,
  syncErrorText,
});

export const setLoadingOutcomeMetric = (outcomeId: string, isLoading: boolean) => ({
  type: actionTypes.WORKSPACE_EDITOR_SET_LOADING_OUTCOME_METRIC,
  outcomeId,
  isLoading,
});

export const resetLoadingMetrics = () => ({
  type: actionTypes.WORKSPACE_EDITOR_RESET_LOADING_METRIC,
});

export const editorSelectBlock = (blockId: string) => ({
  type: actionTypes.WORKSPACE_EDITOR_SELECT_BLOCK,
  blockId,
});

export const editorSelectBlockAbove = () => ({
  type: actionTypes.WORKSPACE_EDITOR_SELECT_BLOCK_ABOVE,
  editorEntities: store.getState().editorEntities,
});

export const editorSelectBlockBelow = () => ({
  type: actionTypes.WORKSPACE_EDITOR_SELECT_BLOCK_BELOW,
  editorEntities: store.getState().editorEntities,
});

export const editorUnselectBlock = () => ({
  type: actionTypes.WORKSPACE_EDITOR_UNSELECT_BLOCK,
});

// Plan write UI actions
export const editorSetDisplayNewBlockForm = (displayNewBlockForm: boolean) => ({
  type: actionTypes.WORKSPACE_EDITOR_SET_DISPLAY_NEW_BLOCK_FORM,
  displayNewBlockForm,
});

// ============================
// Entities loading actions
// ============================

export const resetPlan = () => ({
  type: actionTypes.WORKSPACE_EDITOR_RESET,
});

export const loadPlan = (plan: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_LOAD_PLAN,
  plan,
});

export const loadObjectives = (objectives: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_LOAD_OBJECTIVES,
  objectives,
});

export const loadOutcomes = (outcomes: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_LOAD_OUTCOMES,
  outcomes,
});

export const loadInitiatives = (initiatives: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_LOAD_INITIATIVES,
  initiatives,
});

export const loadReviewComments = (reviewComments: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_LOAD_REVIEW_COMMENTS,
  reviewComments,
});

export const loadResolvedComments = (resolvedComments: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_LOAD_RESOLVED_COMMENTS,
  resolvedComments,
});

// ============================
// Entities (content) actions
// ============================

export const editorUpdatePlan = (planId: string, planParams: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_UPDATE_PLAN,
  planParams,
  planId,
});

export const editorCreateObjective = (objective: any, afterObjective: any = null) => ({
  type: actionTypes.WORKSPACE_EDITOR_CREATE_OBJECTIVE,
  objective,
  afterObjective,
});

export const editorUpdateObjective = (objectiveId: string, objectiveParams: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_UPDATE_OBJECTIVE,
  objectiveParams,
  objectiveId,
});

export const editorDeleteObjective = (objectiveId: string) => ({
  type: actionTypes.WORKSPACE_EDITOR_DELETE_OBJECTIVE,
  objectiveId,
});

export const editorReorderObjective = (orderData: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_REORDER_OBJECTIVE,
  orderData,
});

// Outcome functions
export const editorCreateOutcome = (outcome: any, afterOutcome: any = null) => ({
  type: actionTypes.WORKSPACE_EDITOR_CREATE_OUTCOME,
  outcome,
  afterOutcome,
});

export const editorUpdateOutcome = (outcomeId: string, outcomeParams: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_UPDATE_OUTCOME,
  outcomeId,
  outcomeParams,
});

export const editorDeleteOutcome = (outcomeId: string) => ({
  type: actionTypes.WORKSPACE_EDITOR_DELETE_OUTCOME,
  outcomeId,
});

export const editorReorderOutcome = (orderData: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_REORDER_OUTCOME,
  orderData,
});

export const editorMoveOutcome = (orderData: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_MOVE_OUTCOME,
  orderData,
});

// Initiatives functions
export const editorCreateInitiative = (initiative: any, afterInitiative: any = null) => ({
  type: actionTypes.WORKSPACE_EDITOR_CREATE_TASK,
  initiative,
  afterInitiative,
});

export const editorUpdateInitiative = (initiativeId: string, initiativeParams: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_UPDATE_TASK,
  initiativeId,
  initiativeParams,
});

export const editorDeleteInitiative = (initiativeId: string) => ({
  type: actionTypes.WORKSPACE_EDITOR_DELETE_TASK,
  initiativeId,
});

export const editorReorderInitiative = (orderData: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_REORDER_TASK,
  orderData,
});

export const editorMoveInitiative = (orderData: any) => ({
  type: actionTypes.WORKSPACE_EDITOR_MOVE_TASK,
  orderData,
});

// ============================
// Get scorecard
// ============================

export const getScorecard = () => ({
  type: actionTypes.WORKSPACE_EDITOR_GET_SCORECARD,
  editorEntities: store.getState().editorEntities,
});
