import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { ModalContent, ModalGrid } from 'components/GlobalModal';

const IframeWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 100%;
  width: 100%;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  gap: ${theme.spacing.x4};
  align-items: center;
  width: 100%;
  height: 100%;

  h3 {
    font-size: 1.6rem;
    font-weight: 700;
  }

  > div.content {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2};
  }
`;

interface Props {
  videoId: string;
}

function LoomVideo(props: Props) {
  const { videoId } = props;

  return (
    <ModalGrid>
      <ModalContent>
        <Container>
          <IframeWrapper>
            <Iframe
              title="loom"
              src={`https://www.loom.com/embed/${videoId}?autoplay=1`}
              frameBorder={0}
              allowFullScreen
            />
          </IframeWrapper>
        </Container>
      </ModalContent>
    </ModalGrid>
  );
}

export default LoomVideo;
