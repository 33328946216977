import React from 'react';

const InsightIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 10C7.55228 10 8 10.4477 8 11V17C8 17.5523 7.55228 18 7 18C6.44772 18 6 17.5523 6 17V11C6 10.4477 6.44772 10 7 10Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5C10.5523 5 11 5.44772 11 6V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V6C9 5.44772 9.44772 5 10 5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 7C13.5523 7 14 7.44772 14 8V17C14 17.5523 13.5523 18 13 18C12.4477 18 12 17.5523 12 17V8C12 7.44772 12.4477 7 13 7Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 13C4.55228 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V14C3 13.4477 3.44772 13 4 13Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2C16.5523 2 17 2.44772 17 3V17C17 17.5523 16.5523 18 16 18C15.4477 18 15 17.5523 15 17V3C15 2.44772 15.4477 2 16 2Z"
      fill="#737373"
    />
  </svg>
);

export default InsightIcon;
