import MarkdownContent from 'components/MarkdownContent';
import KoalaColorBlock from 'koala/components/ColorBlock';
import KoalaTextBadge from 'koala/components/TextBadge';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import theme from 'theme';
import { OutcomeRelationship } from 'types';
import * as checkinUtils from 'utils/checkinUtils';

const Content = styled.div`
  grid-area: content;
  .subtle {
    padding: ${theme.spacing.x1} 0;
  }
`;
const OutcomeChildContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'icon left right' 'icon comment comment';
  align-items: start;
  column-gap: ${theme.spacing.x1};
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const IconBlock = styled.div`
  grid-area: icon;
`;

const CommentBlock = styled.div`
  grid-area: comment;
`;

const BlockLeft = styled.div`
  grid-area: left;
  display: flex;
`;

const OutcomeTitle = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};

  .plan-title {
    color: ${theme.colors.subtleText};
    font-size: 1.2rem;
  }
`;

const BlockRight = styled.div`
  grid-area: right;
  align-items: start;
  justify-content: right;
  gap: ${theme.spacing.x1};
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-areas: 'tags progress owners';

  @media ${theme.devices.smallDesktop} {
    grid-template-columns: auto auto;
    grid-template-areas: 'progress owners';
  }
  @media ${theme.devices.tablet} {
    display: none;
  }
`;

interface Props {
  outcomeRelationship: OutcomeRelationship;
}

function ContributingOutcome(props: Props) {
  const { outcomeRelationship } = props;
  const { t } = useTranslation();
  const outcome = outcomeRelationship.outcome_source;
  const checkin = outcome.current_checkin ?? null;
  const currentConfidence = outcome.current_checkin ? outcome.current_checkin.confidence : 'grey';
  const confidenceColor = checkinUtils.confidenceToColor(currentConfidence);
  const formattedScore = outcome.current_checkin
    ? checkinUtils.checkinScore(outcome.current_checkin, outcome, t)
    : 'Pending';
  const path = `#outcome:${outcome.nano_slug}:show`;

  return (
    <OutcomeChildContainer>
      <IconBlock>
        <KoalaColorBlock
          color={confidenceColor}
          className="color-block"
          isPending={outcome.is_pending_checkin}
          isCompleted={outcome.completed}
        />
      </IconBlock>
      <BlockLeft className="outcome-row--left">
        <OutcomeTitle>
          <Link to={path}>
            <span className="plan-title">{outcome.plan.title} / </span>
            {outcome.title}
          </Link>
        </OutcomeTitle>
      </BlockLeft>
      <BlockRight className="outcome-row--right">
        <KoalaTextBadge
          isLowercase={true}
          variant={checkinUtils.confidenceToVariant(currentConfidence)}
          edge="circle"
          size="small"
          style={{ border: 'none' }}
          maxWidth="10rem"
          dataFor={`progress-${outcome.nano_slug}`}
        >
          {formattedScore}
        </KoalaTextBadge>
        <ReactTooltip
          place="bottom"
          type="dark"
          className="tooltip"
          effect="solid"
          id={`progress-${outcome.nano_slug}`}
        >
          {formattedScore}
        </ReactTooltip>
      </BlockRight>
      <CommentBlock>
        <Content className="checkin-block-content">
          {checkin && (
            <>
              {checkin.body && <MarkdownContent source={checkin.body} />}
              {!checkin.body && <p className="subtle">{t('publicPlan.noComment')}</p>}
              {checkin.is_remote && (
                <p className="subtle">
                  --
                  <br />
                  <em>{t('publicPlan.remote')}</em>
                </p>
              )}
            </>
          )}
          {!checkin && <p className="subtle">{t('publicPlan.noCheckin')}</p>}
        </Content>
      </CommentBlock>
    </OutcomeChildContainer>
  );
}

export default React.memo(ContributingOutcome);
