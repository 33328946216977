import React from 'react';

const PresentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4.5C1 3.94772 1.44772 3.5 2 3.5H18C18.5523 3.5 19 3.94772 19 4.5V15.5C19 16.0523 18.5523 16.5 18 16.5H2C1.44772 16.5 1 16.0523 1 15.5V4.5ZM3 5.5V14.5H17V5.5H3Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.47427 6.64935C7.76909 6.46714 8.13723 6.45058 8.44721 6.60557L13.4472 9.10557C13.786 9.27497 14 9.62123 14 10C14 10.3788 13.786 10.725 13.4472 10.8944L8.44721 13.3944C8.13723 13.5494 7.76909 13.5329 7.47427 13.3507C7.17945 13.1684 7 12.8466 7 12.5V7.5C7 7.15342 7.17945 6.83156 7.47427 6.64935ZM9 9.11804V10.882L10.7639 10L9 9.11804Z"
      fill="#737373"
    />
  </svg>
);

export default PresentIcon;
