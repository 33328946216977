import React from 'react';

const PublicIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.35492 3.51699C8.17098 3.18369 9.06404 3 10 3C11.9073 3 13.6364 3.76281 14.899 5H14.5C13.1193 5 12 6.11929 12 7.5C12 8.88071 13.1193 10 14.5 10H17C17 11.3758 16.6031 12.6589 15.9175 13.7411C15.5708 12.173 14.1724 11 12.5 11C10.7368 11 9.27806 12.3039 9.03544 14H8C6.95072 14 6.02725 14.5387 5.49108 15.3546C4.83607 14.8025 4.28378 14.1324 3.86698 13.377C5.38801 12.9694 6.51601 11.5946 6.54491 9.94649L6.55399 9.42857H7.03565C8.67278 9.42857 9.99993 8.10142 9.99993 6.46429C9.99993 4.93502 8.8419 3.67623 7.35492 3.51699ZM7.19016 16.4132C8.0502 16.7906 9.00064 17 10 17C11.4872 17 12.8662 16.5362 14 15.7453V14.5C14 13.6716 13.3284 13 12.5 13C11.6716 13 11 13.6716 11 14.5V14.9032C11 15.509 10.509 16 9.90323 16H8C7.66693 16 7.3719 16.1628 7.19016 16.4132ZM16 7C16.1059 7 16.208 6.98353 16.3038 6.95301C16.466 7.28799 16.6023 7.63785 16.7101 8H14.5C14.2239 8 14 7.77614 14 7.5C14 7.22386 14.2239 7 14.5 7H16ZM4.63789 5.5C3.61568 6.71677 3 8.28648 3 10C3 10.5092 3.05436 11.0056 3.15759 11.4839C3.93182 11.3741 4.53112 10.7155 4.54521 9.91144L4.56165 8.97409C4.57669 8.11618 5.27645 7.42857 6.13449 7.42857H7.03565C7.56821 7.42857 7.99993 6.99685 7.99993 6.46429C7.99993 5.93173 7.56821 5.5 7.03565 5.5H4.63789ZM10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1Z"
      fill="#737373"
    />
  </svg>
);

export default PublicIcon;
