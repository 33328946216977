import FormField from 'components/FormField';
import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextButton from 'koala/components/TextButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

import * as remoteApi from 'api/remote';
import * as routes from 'routes';
import queryKeys from 'config/queryKeys';
import { useHistory, useParams } from 'react-router-dom';
import KoalaLoader from 'koala/components/Loader';
import { formatLocale } from 'utils/dateUtils';
import { AxiosResponse } from 'axios';
import { Retrospective } from 'types';

interface Props {
  planId: string;
}

function CreateRetrospective(props: Props) {
  const { planId } = props;
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const translationKey = `modals.createRetrospective`;
  const [name, setName] = useState('');
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, planId, 'track'];
  const staleTime = 0;

  // Get the plan details
  const { isLoading: isPlanLoading } = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
    onSuccess: (response) => {
      // const defaultName = `${plan.title} Retrospective (${formatLocale(new Date(), 'd MMM', i18n)})`;
      const defaultName = `${formatLocale(new Date(), 'MMM yyyy', i18n)} Retrospective`;
      setName(defaultName);
    },
  });

  const [createRetrospectiveMutation, { isLoading }] = useMutation(remoteApi.createRetrospective, {
    onSuccess: (response: AxiosResponse<Retrospective>) => {
      const retrospective = response.data;
      queryCache.invalidateQueries([queryKeys.retrospectives, planId]);
      const path = routes.WORKSPACE_RETROSPECTIVE_EDIT_ROUTE.replace(':workspaceSlug', workspaceSlug)
        .replace(':planId', planId)
        .replace(':retrospectiveId', retrospective.nano_slug);
      history.push(path);
      dispatch(setGlobalModalContent(''));
    },
  });

  const handleCancel = () => {
    dispatch(setGlobalModalContent(''));
  };
  const handleSave = () => {
    const params = {
      planId,
      retrospective: { title: name },
    };
    createRetrospectiveMutation(params);
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setName(value);
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        {isPlanLoading && <KoalaLoader />}
        {!isPlanLoading && (
          <form onSubmit={handleSave}>
            <FormField>
              <label>{t(`${translationKey}.name`)}</label>
              <input
                placeholder={t(`${translationKey}.namePlaceholder`) ?? 'Enter retrospective name'}
                value={name}
                onChange={handleNameChange}
              />
            </FormField>
          </form>
        )}
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!name} loading={isLoading}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default CreateRetrospective;
