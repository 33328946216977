import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import CheckBoxCheckIcon from 'koala/shared/images/icons/CheckboxCheckIcon';
import { colors, spacing } from 'koala/shared/styles';
import React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import KoalaLoader from '../Loader';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.label<CheckboxProps>`
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.6rem;
  border-radius: 50%;
  transition: all ease 0.2s;

  svg {
    width: 100%;
    height: 100%;
  }
  path {
    stroke: currentColor;
  }

  &:focus {
    border: 2px solid ${colors.B50};
    border-radius: 50%;
  }

  ${HiddenCheckbox}:focus + & {
    border: 2px solid ${colors.B50};
    border-radius: 50%;
  }

  &.checkbox--primary {
    border: 2px solid ${colors.B20};
    color: ${(props) => (props.checked ? colors.B50 : colors.N0)};
    background: ${(props) => (props.checked ? colors.B20 : colors.N0)};
  }
  &.checkbox--secondary {
    border: 2px solid ${colors.T50};
    color: ${(props) => (props.checked ? colors.T90 : colors.N0)};
    background: ${(props) => (props.checked ? colors.T50 : colors.N0)};
  }
  &.checkbox--disabled {
    cursor: not-allowed !important;
    border: 2px solid ${colors.N20};
    color: ${(props) => (props.checked ? colors.N60 : colors.N0)};
    background: ${(props) => (props.checked ? colors.N20 : colors.N0)};
  }

  // 20px
  &.checkbox--small {
    width: 2rem;
    height: 2rem;
    padding: 0.3rem;
  }
  // 24px
  &.checkbox--medium {
    width: ${spacing.x3};
    height: ${spacing.x3};
    padding: ${spacing.half};
  }
  // 32px
  &.checkbox--large {
    width: ${spacing.x4};
    height: ${spacing.x4};
    padding: 0.6rem;
  }

  &.checkbox--square {
    border-radius: 20%;
  }
`;

interface CheckboxProps {
  appearance?: (typeof KoalaAppearances)[number];
  size?: (typeof KoalaSizes)[number];
  checked: boolean;
  isClickable?: boolean;
  handleChange?: (e: any) => void;
  disabled?: boolean;
  loading?: boolean;
  edge?: 'square' | 'circle';
  id?: string;
  className?: string;
  style?: React.CSSProperties;
}

const KoalaCheckbox: FC<CheckboxProps> = (props) => {
  const { appearance, size, checked, isClickable, handleChange, disabled, loading, id, edge, className, style } = props;

  const onChange = (e: any) => {
    if (!disabled && isClickable && handleChange !== undefined) {
      handleChange(e);
    }
  };

  let checkboxClass = className ? className : '';
  checkboxClass += ` checkbox--${disabled ? 'disabled' : appearance} checkbox--${size}`;
  checkboxClass += ` checkbox--${edge ?? 'circle'}`;
  if (loading) {
    return <KoalaLoader size={size} />;
  }

  return (
    <CheckboxContainer className={className} style={style}>
      <HiddenCheckbox
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        id={id}
        className="koala-hidden-checkbox"
      />
      <StyledCheckbox
        {...props}
        onClick={onChange}
        className={checkboxClass}
        disabled={disabled}
        htmlFor={id}
        isClickable={isClickable}
        loading={undefined}
      >
        <CheckBoxCheckIcon />
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

KoalaCheckbox.defaultProps = {
  size: 'medium',
  appearance: 'primary',
  disabled: false,
  isClickable: true,
};

export default KoalaCheckbox;
