export const REFERENCE_TEMPLATE_STORAGE_KEY = `__tbty_reference_template`;
export const USER_REFERRAL_TOKEN = `__tbty_user_referral_token`;
export const LAST_VISITED_FILTER = '__tbty_last_visited_filter';
export const DEFAULT_PLAN_EXPAND = '__tbty_default_plan_expand';
export const DEFAULT_SUBPLANS_EXPAND = '__tbty_default_plan_expand';
export const DEFAULT_PLAN_HIDE_COMPLETED = '__tbty_default_plan_hide_completed';
export const DEFAULT_HIDE_DONE_PLANS = '__tbty_default_hide_done_plans';
export const DEFAULT_TEAM_PLAN_CARDS = '__tbty_default_team_plan_cards';
export const PREFERRED_KANBAN_VIEW = '__tbty_preferred_kanban_view';
export const CONTEXT_STORAGE_KEY = '__tbty_context';
export const SELECTED_PLANS_KEY = '__tbty_selected_plans';
export const SELECTED_MEMBERSHIPS_KEY = '__tbty_selected_memberships';
export const UXPREFERENCES = '__tbty_uxpref';
export const AI_PLAN_FEEDBACK_HIDE_RESOLVED = '__tbty_ai_plan_feedback_hide_resolved';
