import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useMutation } from 'react-query';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import KoalaButton from 'koala/components/Button';
import StripeLogo from './powered_stripe.svg';
import { Trans, useTranslation } from 'react-i18next';

const PreviewGrid = styled.div`
  display: grid;
  grid-template-columns: 15rem 1fr auto;
  width: 100%;
  gap: ${theme.spacing.x1};
`;

const StripeLogoContainer = styled.div`
  img {
    height: 3.2rem;
  }
`;

const PlanOptions = styled.div`
  display: flex;
  gap: ${theme.spacing.x3};
  // hide radio button and put it behind label so not clickable
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  label {
    cursor: pointer;
    display: grid;
    grid-template-rows: auto auto auto 1fr;

    flex: 1;
    gap: ${theme.spacing.x1};
    border: 1px solid ${theme.colors.blockBorder};
    border-radius: 4px;
    padding: ${theme.spacing.x2};
    transition: all 0.2s ease;

    &.disabled {
      cursor: not-allowed;
      filter: grayscale(0.7);
      opacity: 0.7;
    }

    &:hover {
      border: 1px solid ${theme.colors.B50};
    }

    span {
      width: 100%;
      &.header {
        font-size: 2rem;
        font-weight: 800;
      }

      &.price {
        b {
          font-size: 3.2rem;
          font-weight: 600;
        }
        font-size: 1.8rem;
        font-weight: 500;
      }

      &.text {
        font-size: 1.2rem;
        line-height: 1.8;
      }
    }
  }

  input[type='radio']:checked + label {
    background: ${theme.colors.B5};
    border: 1px solid ${theme.colors.B50};
  }
`;

const CurrentSubscription = styled.div`
  font-size: 2rem;

  margin: ${theme.spacing.x3} 0;
`;

const UpdateSubscription = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  padding-bottom: ${theme.spacing.x4};
  h3 {
    margin-top: ${theme.spacing.x2};
    font-weight: 800;
  }
  .info {
    font-size: 2rem;
    text-align: center;
  }
  a {
    color: ${theme.colors.blue};
  }
`;

const ConfirmSubscription = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  padding-bottom: ${theme.spacing.x4};
`;

const PreviewSubscription = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${theme.spacing.x2};

  .header {
    font-weight: 800;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
`;

const ReadonlyInfo = styled.div`
  padding: ${theme.spacing.x2};
  background: ${theme.colors.V5};
  border: 1px solid ${theme.colors.V60};
  border-radius: 4px;
  width: 50%;
`;

declare global {
  interface Window {
    Stripe: any;
    Rewardful: any;
  }
}

interface Props {
  workspace: TabilityTypes.Workspace;
}

function PricingVersion(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState(workspace.billing_subscription_plan);
  const [previewInvoice, setPreviewInvoice]: any = useState(null);
  const [, setStripeCustomer]: any = useState(null);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(true);

  const defaultQuantity =
    workspace.billing_user_limit === 0 || workspace.billing_user_limit === 5 ? 10 : workspace.billing_user_limit;
  const [selectedQuantity, setSelectedQuantity] = useState(defaultQuantity);

  const [isSubscriptionUpdated, setIsSubscriptionUpdated] = useState(false);

  // Workspace mutations
  const [createWorkspaceCheckoutSessionMutation, { isLoading: isLoadingCheckout }]: [any, any] = useMutation(
    remoteApi.createWorkspaceCheckoutSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_id } = data;

        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        stripe
          .redirectToCheckout({
            sessionId: session_id,
          })
          .then((result: any) => {
            if (result.error) {
              alert(result.error.message);
            }
          });
      },
    },
  );

  // Workspace mutations
  const [previewWorkspaceSubscriptionMutation, { isLoading: isLoadingPreview }]: [any, any] = useMutation(
    remoteApi.previewWorkspaceSubscription,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { preview, customer } = data;
        setPreviewInvoice(preview);
        setStripeCustomer(customer);
      },
    },
  );

  // Workspace mutations
  const [updateWorkspaceSubscriptionMutation, { isLoading: isUpdatingSubscription }]: [any, any] = useMutation(
    remoteApi.updateWorkspaceSubscription,
    {
      onSuccess: () => {
        setHasPaymentMethod(true);
        setIsSubscriptionUpdated(true);
        setTimeout(() => {
          window.location.replace(`/${workspace.slug}/settings/billing`);
        }, 3000);
      },
      onError: (error: any) => {
        const errorMsg = error.response.data.message;
        if (errorMsg === 'Sorry, you need to add a credit card to your account') {
          setHasPaymentMethod(false);
        }
      },
    },
  );

  const handlePreview = () => {
    const quantity = selectedPlan === 'tability-v4-free' ? 5 : selectedQuantity;
    const params = {
      workspaceSlug: workspace.slug,
      body: {
        price_id: selectedPlan,
        quantity,
      },
    };
    previewWorkspaceSubscriptionMutation(params);
  };

  const handleUpdateSubscription = () => {
    const quantity = selectedPlan === 'tability-v4-free' ? 5 : selectedQuantity;
    const params = {
      workspaceSlug: workspace.slug,
      body: {
        price_id: selectedPlan,
        quantity,
      },
    };
    updateWorkspaceSubscriptionMutation(params);
  };

  const handleSubscribe = () => {
    const params = {
      workspaceSlug: workspace.slug,
      body: {
        price_id: selectedPlan,
        quantity: selectedQuantity,
      },
    };
    createWorkspaceCheckoutSessionMutation(params);
  };

  // Workspace mutations
  const [createWorkspacePortalSessionMutation, { isLoading: isLoadingPortal }]: [any, any] = useMutation(
    remoteApi.createWorkspacePortalSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_url } = data;

        window.location.href = session_url;
      },
    },
  );

  const handleShowPortal = () => {
    const params = {
      workspaceSlug: workspace.slug,
    };
    createWorkspacePortalSessionMutation(params);
  };

  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const hasPlan = workspaceUtils.hasSubscription(workspace);

  const costMapping: any = {
    'tability-v4-free': 0,
    'tability-v4-basic': 4,
    'tability-v4-plus': 5,
    'tability-v4-premium': 8,
  };
  const readonlyMapping: any = {
    'tability-v4-free': 0,
    'tability-v4-basic': 0,
    'tability-v4-plus': 0,
    'tability-v4-premium': 2,
  };
  const aiMapping: any = {
    'tability-v4-free': 1000,
    'tability-v4-basic': 2000,
    'tability-v4-plus': 2500,
    'tability-v4-premium': 4000,
  };

  let monthlyCost = 0;
  let aiCredits = 0;
  let readonlySeats = 0;

  if (selectedPlan && selectedQuantity) {
    monthlyCost = selectedQuantity * costMapping[selectedPlan];
    aiCredits = selectedQuantity * aiMapping[selectedPlan];
    readonlySeats = selectedQuantity * readonlyMapping[selectedPlan];
  }

  if (isSubscriptionUpdated) {
    return (
      <Fragment>
        <Helmet>
          <script src="https://js.stripe.com/v3/" />
          <title>
            {workspace.name} | {t('workspaceSettingsBilling.plans.title')} | Tability
          </title>
        </Helmet>
        <ChromeSideNav>
          <WorkspaceSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <ContentTitle>
              <h1>{t('workspaceSettingsBilling.v4.confirmSubscription')}</h1>
            </ContentTitle>
            <ConfirmSubscription>
              <h3>{t('workspaceSettingsBilling.v4.subscriptionUpdated')}</h3>
              <p>{t('workspaceSettingsBilling.v4.redirecting')}</p>
            </ConfirmSubscription>
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  // TODO Fix has payment detection

  // const hasPaymentMethod =
  //   stripeCustomer && stripeCustomer.invoice_settings && stripeCustomer.invoice_settings.default_payment_method;

  if (previewInvoice) {
    return (
      <Fragment>
        <Helmet>
          <script src="https://js.stripe.com/v3/" />
          <title>
            {workspace.name} | {t('workspaceSettingsBilling.plans.title')} | Tability
          </title>
        </Helmet>
        <ChromeSideNav>
          <WorkspaceSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <ContentTitle>
              <h1>{t('workspaceSettingsBilling.v4.confirmSubscription')}</h1>
            </ContentTitle>
            <ConfirmSubscription>
              <h3>{t('workspaceSettingsBilling.v4.summary')}</h3>
              {selectedPlan === 'tability-v4-free' && (
                <PreviewGrid>
                  <div>
                    <b>{t('workspaceSettingsBilling.v4.subscriptionType')}</b>
                  </div>
                  <div>{workspaceUtils.planIdToString(selectedPlan)}</div>
                  <div>
                    <Trans
                      i18nKey="workspaceSettingsBilling.v4.price"
                      components={{ b: <b /> }}
                      values={{ price: 0 }}
                    />
                  </div>
                  <div>
                    <b>{t('workspaceSettingsBilling.v4.numberSeats')}</b>
                  </div>
                  <div>5</div>
                  <div>
                    <Trans
                      i18nKey="workspaceSettingsBilling.v4.price"
                      components={{ b: <b /> }}
                      values={{ price: 0 }}
                    />
                  </div>
                  <div>
                    <b>{t('workspaceSettingsBilling.v4.credits')}</b>
                  </div>
                  <div>{t('workspaceSettingsBilling.v4.creditsPrice', { credits: '5,000' })}</div>
                  <div>{t('workspaceSettingsBilling.v4.included')}</div>
                </PreviewGrid>
              )}
              {selectedPlan !== 'tability-v4-free' && (
                <PreviewGrid>
                  <div>
                    <b>{t('workspaceSettingsBilling.v4.subscriptionType')}</b>
                  </div>
                  <div>{workspaceUtils.planIdToString(selectedPlan)}</div>
                  <div>
                    <Trans
                      i18nKey="workspaceSettingsBilling.v4.priceUsers"
                      values={{ price: costMapping[selectedPlan] }}
                      components={{ b: <b /> }}
                    />
                  </div>
                  <div>
                    <b>{t('workspaceSettingsBilling.v4.numberSeats')}</b>
                  </div>
                  <div>{selectedQuantity}</div>
                  <div>
                    <Trans
                      i18nKey="workspaceSettingsBilling.v4.price"
                      components={{ b: <b /> }}
                      values={{ price: monthlyCost }}
                    />
                  </div>
                  <div>
                    <b>{t('workspaceSettingsBilling.v4.readonlySeats')}</b>
                  </div>
                  <div>{readonlySeats}</div>
                  <div>
                    {readonlySeats > 0 && <>{t('workspaceSettingsBilling.v4.included')}</>}
                    {readonlySeats === 0 && <>–</>}
                  </div>
                  <div>
                    <b>{t('workspaceSettingsBilling.v4.credits')}</b>
                  </div>
                  <div>{t('workspaceSettingsBilling.v4.creditsPrice', { credits: aiCredits })}</div>
                  <div>{t('workspaceSettingsBilling.v4.included')}</div>
                </PreviewGrid>
              )}
              <h3>{t('workspaceSettingsBilling.v4.changes')}</h3>
              <PreviewGrid>
                {previewInvoice.lines.data.map((line: any, index: number) => {
                  return (
                    <>
                      <div></div>
                      <div>{line.description}</div>
                      <div>{formatter.format(line.amount / 100)}</div>
                    </>
                  );
                })}
                <div>
                  <b>{t('workspaceSettingsBilling.v4.toPay')}</b>
                </div>
                <div />
                <div>{formatter.format(previewInvoice.total / 100)}</div>
              </PreviewGrid>
              {!hasPaymentMethod && (
                <>
                  <div>{t('workspaceSettingsBilling.v4.paymentDetailsMissing')}</div>
                  <Actions>
                    <KoalaButton
                      onClick={() => handleShowPortal()}
                      disabled={isLoadingPortal}
                      loading={isLoadingPortal}
                      appearance="subtle"
                    >
                      {t('workspaceSettingsBilling.v4.addPayment')}
                    </KoalaButton>
                  </Actions>
                </>
              )}
              {hasPaymentMethod && (
                <Actions>
                  <KoalaButton
                    onClick={() => handleUpdateSubscription()}
                    disabled={isUpdatingSubscription}
                    loading={isUpdatingSubscription}
                  >
                    {t('workspaceSettingsBilling.v4.confirm')}
                  </KoalaButton>
                  <KoalaButton onClick={() => setPreviewInvoice(null)} appearance="secondary">
                    {t('shared.cancel')}
                  </KoalaButton>
                </Actions>
              )}
              <StripeLogoContainer>
                <img src={StripeLogo} alt="Powered by Stripe" />
              </StripeLogoContainer>
            </ConfirmSubscription>
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  const reviewDisabled = !selectedPlan || !selectedQuantity;

  return (
    <Fragment>
      <Helmet>
        <script src="https://js.stripe.com/v3/" />
        <title>
          {workspace.name} | {t('workspaceSettingsBilling.plans.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsBilling.plans.title')}</h1>
          </ContentTitle>
          <CurrentSubscription>
            <p>
              <Trans
                i18nKey="workspaceSettingsBilling.currentPlan"
                values={{ plan: workspaceUtils.billingSubscriptionToString(workspace) }}
                components={{ b: <b /> }}
              />
            </p>
          </CurrentSubscription>
          <UpdateSubscription>
            <h3>{t('workspaceSettingsBilling.v4.newPlan')}</h3>
            <PlanOptions>
              {false && ( // Removed the free plan
                <>
                  <input
                    type="radio"
                    id="free"
                    value={selectedPlan}
                    checked={selectedPlan === 'tability-v4-free'}
                    name="plan"
                    onChange={() => setSelectedPlan('tability-v4-free')}
                  />
                  <label htmlFor="free">
                    <span className="header">{t('workspaceSettingsBilling.v4.free')}</span>
                    <span className="price">
                      <Trans
                        i18nKey="workspaceSettingsBilling.v4.price"
                        components={{ b: <b /> }}
                        values={{ price: 0 }}
                      />
                    </span>
                    <span className="text">{t('workspaceSettingsBilling.v4.freeInfo')}</span>
                    <span className="text">
                      ✔️ {t('workspaceSettingsBilling.v4.creditPerUser', { count: 1000 })}
                      <br />
                      <Trans i18nKey="workspaceSettingsBilling.v4.freeFeatures" components={{ br: <br /> }} />
                    </span>
                  </label>
                </>
              )}
              <input
                type="radio"
                id="basic"
                value={selectedPlan}
                checked={selectedPlan === 'tability-v4-basic'}
                name="plan"
                onChange={() => setSelectedPlan('tability-v4-basic')}
              />
              <label htmlFor="basic">
                <span className="header">{t('workspaceSettingsBilling.v4.basic')}</span>
                <span>{t('workspaceSettingsBilling.v4.basicSummary')}</span>
                <span className="price">
                  <Trans
                    i18nKey="workspaceSettingsBilling.v4.priceUsers"
                    components={{ b: <b /> }}
                    values={{ price: 4 }}
                  />
                </span>
                <span className="text">{t('workspaceSettingsBilling.v4.basicInfo')}</span>
                <span className="text">
                  {t('workspaceSettingsBilling.v4.creditPerUser', { count: 2000 })}
                  <br />
                  <Trans i18nKey="workspaceSettingsBilling.v4.basicFeatures" components={{ br: <br /> }} />
                </span>
              </label>
              <input
                type="radio"
                id="plus"
                value={selectedPlan}
                checked={selectedPlan === 'tability-v4-plus'}
                name="plan"
                onChange={() => setSelectedPlan('tability-v4-plus')}
              />
              <label htmlFor="plus">
                <span className="header">{t('workspaceSettingsBilling.v4.plus')}</span>
                <span>{t('workspaceSettingsBilling.v4.plusSummary')}</span>
                <span className="price">
                  <Trans
                    i18nKey="workspaceSettingsBilling.v4.priceUsers"
                    components={{ b: <b /> }}
                    values={{ price: 5 }}
                  />
                </span>
                <span className="text">{t('workspaceSettingsBilling.v4.plusInfo')}</span>
                <span className="text">
                  {t('workspaceSettingsBilling.v4.creditPerUser', { count: 2500 })}
                  <br />
                  <Trans i18nKey="workspaceSettingsBilling.v4.plusFeatures" components={{ br: <br /> }} />
                </span>
              </label>
              <input
                type="radio"
                id="premium"
                value={selectedPlan}
                checked={selectedPlan === 'tability-v4-premium'}
                name="plan"
                onChange={() => setSelectedPlan('tability-v4-premium')}
              />
              <label htmlFor="premium">
                <span className="header">{t('workspaceSettingsBilling.v4.premium')}</span>
                <span>{t('workspaceSettingsBilling.v4.premiumSummary')}</span>
                <span className="price">
                  <Trans
                    i18nKey="workspaceSettingsBilling.v4.priceUsers"
                    components={{ b: <b /> }}
                    values={{ price: 8 }}
                  />
                </span>
                <span className="text">{t('workspaceSettingsBilling.v4.premiumInfo')}</span>
                <span className="text">
                  {t('workspaceSettingsBilling.v4.creditPerUser', { count: 4000 })}
                  <br />
                  <Trans i18nKey="workspaceSettingsBilling.v4.premiumFeatures" components={{ br: <br /> }} />
                </span>
              </label>
            </PlanOptions>
            <a href="https://www.tability.io/pricing" target="_blank" rel="noopener noreferrer">
              {t('workspaceSettingsBilling.compareButton')}
            </a>
            {!selectedPlan && <p className="info"> {t('workspaceSettingsBilling.v4.selectPlan')}</p>}
            {selectedPlan && (
              <>
                {selectedPlan === 'tability-v4-free' && (
                  <>
                    <h3>{t('workspaceSettingsBilling.v4.chooseNumberSeats')}</h3>
                    <div>{t('workspaceSettingsBilling.v4.freeLimited')}</div>
                  </>
                )}
                {selectedPlan !== 'tability-v4-free' && (
                  <>
                    <h3>{t('workspaceSettingsBilling.v4.chooseNumberSeats')}</h3>
                    <div>
                      <select
                        defaultValue={selectedQuantity}
                        value={selectedQuantity}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          setSelectedQuantity(parseInt(e.target.value));
                        }}
                      >
                        <option value="">{t('workspaceSettingsBilling.v4.selectOption')}</option>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>75</option>
                        <option>100</option>
                        <option>125</option>
                        <option>150</option>
                        <option>175</option>
                        <option>200</option>
                        <option>225</option>
                        <option>250</option>
                        <option>275</option>
                        <option>300</option>
                        <option>325</option>
                        <option>350</option>
                        <option>375</option>
                        <option>400</option>
                        <option>425</option>
                        <option>450</option>
                        <option>475</option>
                        <option>500</option>
                        <option>550</option>
                        <option>600</option>
                        <option>650</option>
                        <option>700</option>
                        <option>750</option>
                        <option>800</option>
                        <option>850</option>
                        <option>900</option>
                        <option>950</option>
                        <option>1000</option>
                      </select>
                      &nbsp; {t('workspaceSettingsBilling.v4.seats')}
                    </div>
                    <p>
                      <Trans
                        i18nKey="workspaceSettingsBilling.v4.needMore"
                        components={{ a: <a href="mailto:sales@tability.io">link</a> }}
                      />
                    </p>
                    <ReadonlyInfo>
                      <p>
                        <b>{t('workspaceSettingsBilling.v4.readonlySeatsInfoTitle')}</b>
                      </p>
                      <p>{t('workspaceSettingsBilling.v4.readonlySeatsInfoDescription')}</p>
                    </ReadonlyInfo>
                  </>
                )}
                <h3>{t('workspaceSettingsBilling.v4.newSubscription')}</h3>

                {selectedPlan === 'tability-v4-free' && (
                  <>
                    <PreviewSubscription>
                      <div className="header">{t('workspaceSettingsBilling.v4.plan')}</div>
                      <div>{workspaceUtils.planIdToString(selectedPlan)}</div>
                      <div className="header">{t('workspaceSettingsBilling.v4.seats')}</div>
                      <div>5</div>
                      <div className="header">{t('workspaceSettingsBilling.v4.credits')}</div>
                      <div>{t('workspaceSettingsBilling.v4.creditsPrice', { credits: '5,000' })}</div>
                      <div className="header">{t('workspaceSettingsBilling.v4.subscriptionCost')}</div>
                      <div>
                        <Trans
                          i18nKey="workspaceSettingsBilling.v4.price"
                          components={{ b: <b /> }}
                          values={{ price: 0 }}
                        />
                      </div>
                    </PreviewSubscription>
                  </>
                )}
                {selectedPlan !== 'tability-v4-free' && (
                  <>
                    <PreviewSubscription>
                      <div className="header">{t('workspaceSettingsBilling.v4.plan')}</div>
                      <div>{workspaceUtils.planIdToString(selectedPlan)}</div>
                      <div className="header">{t('workspaceSettingsBilling.v4.seats')}</div>
                      <div>{selectedQuantity}</div>
                      <div className="header">{t('workspaceSettingsBilling.v4.readonlySeats')}</div>
                      <div>{readonlySeats}</div>
                      <div className="header">{t('workspaceSettingsBilling.v4.credits')}</div>
                      <div>
                        {t('workspaceSettingsBilling.v4.creditsPrice', { credits: aiCredits.toLocaleString() })}
                      </div>
                      <div className="header">{t('workspaceSettingsBilling.v4.subscriptionCost')}</div>
                      <div>
                        <Trans
                          i18nKey="workspaceSettingsBilling.v4.price"
                          components={{ b: <b /> }}
                          values={{ price: monthlyCost.toLocaleString() }}
                        />
                      </div>
                    </PreviewSubscription>
                  </>
                )}
                <div>
                  {hasPlan && (
                    <KoalaButton
                      onClick={() => handlePreview()}
                      disabled={isLoadingPreview || reviewDisabled}
                      loading={isLoadingPreview}
                    >
                      {t('workspaceSettingsBilling.v4.reviewConfirm')}
                    </KoalaButton>
                  )}

                  {!hasPlan && (
                    <KoalaButton
                      onClick={() => handleSubscribe()}
                      disabled={isLoadingCheckout || reviewDisabled}
                      loading={isLoadingCheckout}
                    >
                      {t('workspaceSettingsBilling.v4.reviewUpgrade')}
                    </KoalaButton>
                  )}
                </div>
                <StripeLogoContainer>
                  <img src={StripeLogo} alt="Powered by Stripe" />
                </StripeLogoContainer>
              </>
            )}
          </UpdateSubscription>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default PricingVersion;
