import React, { useState } from 'react';
import styled from 'styled-components';

import TeamSearchableDropdown from 'components/TeamSearchableDropdown';
import { Plan, TeamRelationship, Workspace } from 'types';
import { useMutation, useQueryCache, useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaTextBadge from 'koala/components/TextBadge';
import theme from 'theme';
import { shallowEqual, useSelector } from 'react-redux';
import TeamLabel from 'components/TeamLabel';
import { useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';

const Trigger = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.subtleText};
  padding: 0;

  &.item {
    &.action {
      color: ${theme.colors.subtleText};
      svg {
        width: 1.2rem;
        path {
          fill: ${theme.colors.subtleText};
        }
      }
      &:hover {
        color: #151515;
        cursor: pointer;
        svg {
          path {
            fill: #151515;
          }
        }
      }
    }
  }
`;

interface Props {
  plan: Plan;
  canEdit: boolean;
}

function TeamRelationships(props: Props) {
  const { plan, canEdit } = props;
  const planId = plan.id;
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [teamRelationships, setTeamRelationships] = useState<TeamRelationship[]>([]);

  const teamRelationshipQueryKey = [queryKeys.teamRelationships, planId];
  const staleTime = 0;
  useQuery(teamRelationshipQueryKey, remoteApi.fetchPlanTeamRelationships, {
    staleTime,
    onSuccess: (response) => {
      setTeamRelationships(response.data);
    },
  });

  const [addTeamRelationshipMutation]: [any, any] = useMutation(remoteApi.createPlanTeamRelationship, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.teamRelationships);
    },
  });

  const [deleteTeamRelationshipMutation]: [any, any] = useMutation(remoteApi.deleteTeamRelationship, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.teamRelationships);
    },
  });

  const handleAssignTeam = (team_id: string) => {
    if (team_id) {
      const params = {
        team_id,
      };

      addTeamRelationshipMutation({
        planId,
        team_relationship: params,
      });
    }
  };

  const ignoredTeamIds = teamRelationships.map((tr: TeamRelationship) => tr.team_id);

  return (
    <>
      {teamRelationships.map((teamRelationship: TeamRelationship, i) => {
        const { team } = teamRelationship;
        const handleUnassignTeam = (e: React.MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();
          deleteTeamRelationshipMutation(teamRelationship.id);
        };

        if (canEdit) {
          return (
            <TeamLabel team={team} workspace={currentWorkspace} isLink handleUnassign={handleUnassignTeam} key={i} />
          );
        } else {
          return <KoalaTextBadge key={i}>{team.name}</KoalaTextBadge>;
        }
      })}
      <TeamSearchableDropdown
        canEdit={canEdit}
        ignoredTeams={ignoredTeamIds}
        callback={handleAssignTeam}
        trigger={
          <Trigger className={`item action`}>
            <KoalaIcon iconName="plus" />
            <small>{t('workspacePlan.addTeam')}</small>
          </Trigger>
        }
      />
    </>
  );
}

export default React.memo(TeamRelationships);
