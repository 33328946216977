import styled from 'styled-components';
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
// import { useMutation, useQuery, useQueryCache } from 'react-query';
import KoalaButton from 'koala/components/Button';
import KoalaTextBadge from 'koala/components/TextBadge';
import FormField from 'components/FormField';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import yaml from 'js-yaml';
import queryKeys from 'config/queryKeys';

import { shallowEqual, useSelector } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Workspace } from 'types';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

const TitleContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const TabbyThinking = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
  img {
    width: 16rem;
  }
`;

const TipsContent = styled.div`
  font-size: 1.5rem;
  h3 {
    font-size: 2.4rem;
    margin: ${theme.spacing.x4} 0 ${theme.spacing.x1} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
  h4 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-top: ${theme.spacing.x3};
    margin-bottom: ${theme.spacing.x1};
  }
  a {
    color: ${theme.colors.B50};
    text-decoration: underline;
  }
  ul {
    list-style-type: disc;
    margin-left: 3rem;
    margin-top: ${theme.spacing.half};
    margin-bottom: ${theme.spacing.half};

    li {
      padding-bottom: ${theme.spacing.half};
      color: ${theme.colors.N90};
    }
  }
  p {
    color: ${theme.colors.N90};
  }

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }
`;

const PreviewContainer = styled.div`
  border: 2px dotted ${theme.colors.N50};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  margin: ${theme.spacing.x1} 0;
`;

const PreviewLine = styled.div`
  display: grid;
  grid-template-columns: 9rem 1fr;
  gap: ${theme.spacing.x1};

  div:first-of-type {
    justify-self: flex-end;
  }

  div:nth-of-type(2) {
    font-family: monospace, 'Courier New';
    font-size: 1.2rem;
  }
`;

const WritingImg = 'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-typing_vjitqx.gif';
const NoCreditsImg =
  'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-sleeping_tvsdtw.gif';

interface Props {
  planId: string;
}

function PopulateAI(props: Props) {
  const { planId } = props;
  const dispatch = useDispatch();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentPing = useSelector((state: any) => state.session.currentPing, shallowEqual);
  const { t } = useTranslation();
  const translationKey = 'modals.AI';

  const { usage } = currentPing;
  let billing_ai_credits_limit = 0;
  let ai_credits_used = 0;
  if (usage) {
    billing_ai_credits_limit = usage.billing_ai_credits_limit;
    ai_credits_used = usage.ai_credits_used;
  }

  const [objectiveDescription, setObjectiveDescription] = useState('');
  const [previewYml, setPreviewYml] = useState<any>(null);
  const [previewData, setPreviewData] = useState<any>(null);
  const queryCache = useQueryCache();
  // Functions to update the outcome
  const [previewAIPlanMutation, { isLoading, isError }]: [any, any] = useMutation(remoteApi.previewAIPlan, {
    onSuccess: (response) => {
      const previewYml = response.data.preview;
      queryCache.invalidateQueries(queryKeys.currentPing);
      try {
        const previewData = yaml.load(previewYml);
        setPreviewYml(previewYml);
        setPreviewData(previewData);
      } catch (e) {
        setPreviewData(null);
        setPreviewYml(null);
      }
    },
  });

  const [populateAIYMLPlanMutation, { isLoading: isPopulating, isError: isPopulatingError }]: [any, any] = useMutation(
    remoteApi.populateAIYMLPlan,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.currentPlan);
        queryCache.invalidateQueries(queryKeys.objectives);
        queryCache.invalidateQueries(queryKeys.outcomes);
        queryCache.invalidateQueries(queryKeys.initiatives);
        queryCache.invalidateQueries(queryKeys.currentPing);
        dispatch(setGlobalModalContent(''));
      },
    },
  );

  const handleCancel = (e: React.MouseEvent) => {
    dispatch(setGlobalModalContent(''));
  };

  const handleGeneratePreview = (e: any) => {
    e.preventDefault();
    // Don't trigger if already loading the template.
    if (isLoading) {
      return;
    }
    const params = {
      planId,
      // @ts-ignore
      objective_description: objectiveDescription,
    };

    previewAIPlanMutation(params);
  };

  const handlePopulatePreview = (e: any) => {
    e.preventDefault();
    // Don't trigger if already loading the template.
    if (isLoading) {
      return;
    }
    const params = {
      planId,
      // @ts-ignore
      ai_yml: previewYml,
    };

    populateAIYMLPlanMutation(params);
  };

  const handleObjectiveDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const inputName = e.target.value;
    setObjectiveDescription(inputName);
  };

  const hasCredit = billing_ai_credits_limit > ai_credits_used;
  const needsUpgrade = !currentWorkspace.billing_subscription_plan || currentWorkspace.pricing_version !== 3;
  const isOwner = currentMembership && ['owner'].includes(currentMembership.role);
  const objectiveLabel = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase();
  const placeholder =
    t(`${translationKey}.outcomePlaceholder`, { label: objectiveLabel }) ??
    `Describe your ${objectiveLabel} here in 1 or 2 sentences.`;
  return (
    <ModalGrid>
      <ModalHeader>
        <TitleContainer>
          <h2>{t(`${translationKey}.title`)}</h2>
        </TitleContainer>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        {!hasCredit && (
          <TipsContent>
            <TabbyThinking>
              <img src={NoCreditsImg} alt="Tabby sleeping" />
            </TabbyThinking>
            <p>{t(`${translationKey}.lowCredits`)}</p>
            <p className="subtle">
              {needsUpgrade && (
                <>
                  {isOwner && (
                    <Trans
                      i18nKey={`${translationKey}.upgradeCredits`}
                      components={{
                        Link: <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel} />,
                      }}
                    />
                  )}
                  {!isOwner && (
                    <small>
                      <Trans
                        i18nKey={`${translationKey}.upgradeCredits`}
                        components={{
                          Link: <b />,
                        }}
                      />
                    </small>
                  )}

                  <Trans
                    i18nKey={`${translationKey}.writeReview`}
                    components={{
                      a: (
                        <a
                          href="https://guides.tability.app/docs/other/how-to-get-more-ai-credits"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          text
                        </a>
                      ),
                    }}
                  />
                </>
              )}
            </p>
          </TipsContent>
        )}
        {hasCredit && (
          <>
            <TipsContent>
              <Form onSubmit={handleGeneratePreview}>
                <FormField>
                  <label>
                    {t(`${translationKey}.outcome`, { label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1) })}
                  </label>
                  <textarea
                    placeholder={placeholder}
                    className="segment-input"
                    value={objectiveDescription}
                    onChange={handleObjectiveDescriptionChange}
                  />
                  <div>
                    {!previewData && (
                      <KoalaButton
                        onClick={handleGeneratePreview}
                        disabled={isLoading || !objectiveDescription || !hasCredit}
                        loading={isLoading}
                      >
                        {t(`${translationKey}.generate`)}
                      </KoalaButton>
                    )}
                    {previewData && (
                      <KoalaButton
                        onClick={handleGeneratePreview}
                        disabled={isLoading || !objectiveDescription || !hasCredit}
                        loading={isLoading}
                        appearance="subtle"
                      >
                        {t(`${translationKey}.regenerate`)}
                      </KoalaButton>
                    )}
                  </div>
                </FormField>
              </Form>
              {isLoading && (
                <TabbyThinking>
                  <img src={WritingImg} alt="Tabby thinking" />
                  <p>{t(`${translationKey}.goalsLoading`)}</p>
                </TabbyThinking>
              )}
              {!isLoading && (
                <>
                  {isError && (
                    <p>
                      <b>{t(`${translationKey}.lowCredits`)}</b>
                    </p>
                  )}
                  {isPopulatingError && (
                    <p>
                      <b>{t(`${translationKey}.errorPopulating`)}</b>
                    </p>
                  )}
                  {previewData && (
                    <>
                      <b>{t(`${translationKey}.suggestion`)}</b>
                      <PreviewContainer>
                        <PreviewLine>
                          <div>
                            <KoalaTextBadge size="small" variant="teal" edge="circle">
                              {translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}
                            </KoalaTextBadge>
                          </div>
                          <div>{previewData['objective']}</div>
                        </PreviewLine>
                        {previewData['outcomes'].map((outcome: string, index: number) => (
                          <PreviewLine key={index}>
                            <div>
                              <KoalaTextBadge size="small" variant="yellow" edge="circle">
                                #
                              </KoalaTextBadge>
                            </div>
                            <div>{outcome}</div>
                          </PreviewLine>
                        ))}
                      </PreviewContainer>
                      <KoalaButton
                        onClick={handlePopulatePreview}
                        disabled={isPopulating || !previewYml || !hasCredit}
                        loading={isPopulating}
                      >
                        {t(`${translationKey}.useSuggestion`)}
                      </KoalaButton>
                    </>
                  )}
                  {!previewData && (
                    <>
                      <p>{t(`${translationKey}.examples`)}</p>
                      <ul>
                        <li>{t(`${translationKey}.example1`)}</li>
                        <li>{t(`${translationKey}.example2`)}</li>
                        <li>{t(`${translationKey}.example3`)}</li>
                        <li>{t(`${translationKey}.example4`)}</li>
                      </ul>
                      <p>
                        {t(`${translationKey}.outcomeSuggestions`, {
                          label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                        })}
                      </p>
                      <p></p>
                    </>
                  )}
                </>
              )}
            </TipsContent>
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <p className="subtle">
          <small>
            {t(`${translationKey}.creditsUsed`, {
              used: ai_credits_used.toLocaleString(),
              limit: billing_ai_credits_limit.toLocaleString(),
            })}
          </small>
          <br />
          {needsUpgrade && (
            <>
              {isOwner && (
                <small>
                  <Trans
                    i18nKey={`${translationKey}.upgradeCredits`}
                    components={{
                      Link: <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel} />,
                    }}
                  />
                </small>
              )}
              {!isOwner && (
                <small>
                  <Trans
                    i18nKey={`${translationKey}.upgradeCredits`}
                    components={{
                      Link: <b />,
                    }}
                  />
                </small>
              )}
            </>
          )}
        </p>
      </ModalFooter>
    </ModalGrid>
  );
}

export default PopulateAI;
