/**
 * Outcome component
 */
import React, { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
//import * as TabilityTypes from '../../../types';
import _ from 'lodash';

// Actions
import {
  editorSetDisplayNewBlockForm,
  editorSelectBlockAbove,
  editorSelectBlockBelow,
  editorUnselectBlock,
  editorUpdateInitiative,
  editorDeleteInitiative,
} from 'state/actions/workspaceEditorActions';

// UI Components
import TitleInput from './TitleInput';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  initiativeId: string;
}

function InitiativeTitle(props: Props) {
  const dispatch = useDispatch();
  const { initiativeId } = props;
  const { t } = useTranslation();
  // Used to avoid saving objective title on first render
  const isInitialRender = useRef(true);

  // Get the data needed to build the component
  const initiative = useSelector((state: any) => state.editorEntities.initiatives[initiativeId]);
  const [title, setTitle] = useState(initiative.title);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  useEffect(() => {
    setTitle(initiative.title);
  }, [initiative.title]);

  const blockId = `initiative:${initiativeId}`;

  const isFocused = useSelector(
    (state: any) => state.editorUI.selectedBlockId === blockId && !state.editorUI.displayNewBlockForm,
  );

  const saveInitiativeTitle = (newTitle: string) => {
    const title = newTitle;
    if (!title) {
      return;
    }
    const initiativeParams = {
      title,
    };
    dispatch(editorUpdateInitiative(initiativeId, initiativeParams));
  };

  const debounceSaveInitiativeTitle = useRef(
    _.debounce((newTitle: string) => saveInitiativeTitle(newTitle), 500, {
      maxWait: 5000,
    }),
  );

  useEffect(() => {
    if (!isInitialRender.current) {
      debounceSaveInitiativeTitle.current(title);
    }
  }, [title]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
  }, []);

  if (!initiative) {
    return null;
  }

  const handleChange = (e: any) => {
    e.preventDefault();
    let title = e.target.value || '';
    title = title.replace(/(\r\n|\n|\r)/gm, ''); // Remove new lines
    setTitle(title);
  };

  const handlePress = (e: any) => {
    if (e.keyCode === 27) {
      // Esc key
      e.preventDefault();
      e.currentTarget.blur();
      dispatch(editorUnselectBlock());
    }

    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault();
      e.currentTarget.blur();
      dispatch(editorSetDisplayNewBlockForm(true));
    }

    if (e.keyCode === 38) {
      // Arrow up
      e.preventDefault();
      dispatch(editorSelectBlockAbove());
    }

    if (e.keyCode === 40) {
      // Arrow down
      e.preventDefault();
      dispatch(editorSelectBlockBelow());
    }

    if (e.keyCode === 8) {
      // Delete key
      const value = e.target.value;
      if (!value) {
        // Only delete if there's no value
        e.preventDefault();
        dispatch(editorSelectBlockAbove());
        dispatch(editorDeleteInitiative(initiative.id));
      }
    }
  };

  const initLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();
  return (
    <TitleInput
      text={title}
      type="initiative"
      placeholder={t('workspacePlan.write.placeholder', { label: initLabel }) ?? `Write ${initLabel}`}
      handleChange={handleChange}
      handlePress={handlePress}
      isFocused={isFocused}
    />
  );
}

export default React.memo(InitiativeTitle);
