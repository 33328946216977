import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';
import theme from 'theme';
import { Helmet } from 'react-helmet';

// Components
import PassiveIncome from './passive_income.png';
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import AccountSettingsNav from 'components/AccountSettingsNav';
import KoalaButton from 'koala/components/Button';
import CopyTextInput from 'components/CopyTextInput';
import { useTranslation } from 'react-i18next';

const OfferBlock = styled.div`
  display: flex;
  align-items: center;
  background: #f3f3f3;
  border-radius: 16px;
  padding: ${theme.spacing.x2};
  margin: ${theme.spacing.x3} 0;
  &:hover {
    background: #e7e7e7;
  }
  .content {
    flex: 1;
  }
  img {
    height: 8rem;
    margin-right: ${theme.spacing.x2};
  }
  h4 {
    font-weight: 700;
    margin-bottom: ${theme.spacing.x1};
  }
  p {
  }
  margin-bottom: ${theme.spacing.x2};
`;

const ShareButtonsContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.x2};
  gap: ${theme.spacing.x1};
`;

function AccountDetails() {
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const { t } = useTranslation();
  const referralLink = `https://${process.env.REACT_APP_DOMAIN}/signup?ref=${currentUser.referral_token}`;

  const twitterShareTitle =
    '@tabilityio has made our OKRs super easy to manage — I definitely recommend checking it out.';
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURI(referralLink)}&title=${twitterShareTitle}`;
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(referralLink)}`;

  const handleShareTwitter = () => {
    window.open(twitterShareUrl);
  };

  const handleShareLinkedIn = () => {
    window.open(linkedInShareUrl);
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t('accountReferral.title')} | Tability</title>
      </Helmet>

      <ChromeSideNav>
        <AccountSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('accountReferral.title')}</h1>
          </ContentTitle>
          <h2>{t('accountReferral.information')}</h2>
          <OfferBlock>
            <img src={PassiveIncome} alt="Earn through referrals" />
            <div className="content">
              <p>{t('accountReferral.step1')}</p>
              <p>{t('accountReferral.step2')}</p>
              <p>{t('accountReferral.step3')}</p>
              <p>
                <CopyTextInput text={referralLink} />
              </p>
              <ShareButtonsContainer>
                <KoalaButton onClick={handleShareTwitter}>{t('accountReferral.twitter')}</KoalaButton>
                <KoalaButton onClick={handleShareLinkedIn}>{t('accountReferral.linkedIn')}</KoalaButton>
              </ShareButtonsContainer>
            </div>
          </OfferBlock>
          <p className="subtle">{t('accountReferral.payments')}</p>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default AccountDetails;
