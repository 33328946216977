import React from 'react';

const ConciergeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.084 13.8097H15.916C15.4378 10.9972 12.9748 8.84923 10 8.84923C7.02524 8.84923 4.56223 10.9972 4.084 13.8097ZM2 14.8088C2 10.4092 5.58642 6.85112 10 6.85112C14.4136 6.85112 18 10.4092 18 14.8088C18 15.3606 17.5523 15.8078 17 15.8078H3C2.44772 15.8078 2 15.3606 2 14.8088Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.86789C12 5.41965 11.5523 5.86694 11 5.86694L9 5.86694C8.44772 5.86694 8 5.41965 8 4.86789C8 4.31613 8.44772 3.86884 9 3.86884L11 3.86884C11.5523 3.86884 12 4.31613 12 4.86789Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 17.751C19 18.3027 18.5523 18.75 18 18.75L2 18.75C1.44772 18.75 1 18.3027 1 17.751C1 17.1992 1.44772 16.7519 2 16.7519L18 16.7519C18.5523 16.7519 19 17.1992 19 17.751Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.86884C10.5523 3.86884 11 4.31613 11 4.86789V7.35311C11 7.90487 10.5523 8.35216 10 8.35216C9.44772 8.35216 9 7.90487 9 7.35311V4.86789C9 4.31613 9.44772 3.86884 10 3.86884Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.58451 1.54189C5.19302 1.15271 4.55828 1.15271 4.16678 1.54189C3.77529 1.93107 3.77529 2.56206 4.16678 2.95124L5.30553 4.08326C5.69702 4.47244 6.33176 4.47244 6.72326 4.08326C7.11475 3.69408 7.11475 3.06309 6.72326 2.67391L5.58451 1.54189ZM3.26004 4.51139C2.72639 4.36924 2.17737 4.68592 2.03377 5.2187C1.89016 5.75148 2.20635 6.29862 2.73999 6.44076L4.29606 6.85524C4.82971 6.99739 5.37873 6.68071 5.52233 6.14793C5.66594 5.61515 5.34975 5.06801 4.81611 4.92587L3.26004 4.51139ZM15.8332 1.5419C16.2247 1.93109 16.2247 2.56207 15.8332 2.95126L14.6944 4.08327C14.303 4.47245 13.6682 4.47245 13.2767 4.08327C12.8852 3.69409 12.8852 3.0631 13.2767 2.67392L14.4155 1.5419C14.807 1.15272 15.4417 1.15272 15.8332 1.5419ZM17.26 6.44078C17.7937 6.29864 18.1099 5.7515 17.9663 5.21872C17.8227 4.68594 17.2736 4.36926 16.74 4.51141L15.1839 4.92589C14.6503 5.06804 14.3341 5.61517 14.4777 6.14795C14.6213 6.68074 15.1703 6.99741 15.704 6.85527L17.26 6.44078Z"
      fill="#737373"
    />
  </svg>
);
export default ConciergeIcon;
