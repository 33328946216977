import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import KoalaColorPicker from 'koala/components/ColorPicker';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaTextButton from 'koala/components/TextButton';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

import * as remoteApi from 'api/remote';
import { Team } from 'types';
import theme from 'theme';
import FormField from 'components/FormField';
import styled from 'styled-components';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';

const Field = styled(FormField)`
  margin-bottom: ${theme.spacing.x1};
`;
const InputRow = styled.div`
  display: flex;
  gap: ${theme.spacing.x4};
`;

interface Props {
  teamId: string;
}
function EditTeamColor(props: Props) {
  const { teamId } = props;
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.editTeamColor';

  const [teamColor, setTeamColor] = useState<string | null>(null);
  const [teamTextColor, setTeamTextColor] = useState<string | null>(null);
  const [team, setTeam] = useState<Team>();

  const queryKey = [queryKeys.teams, teamId];
  const { isLoading } = useQuery(queryKey, remoteApi.fetchTeamDetails, {
    staleTime: 0,
    onSuccess: (response) => {
      const team: Team = response.data;
      setTeam(team);
      setTeamColor(team.label_bg_color ?? null);
      setTeamTextColor(team.label_text_color ?? null);
    },
  });

  // Mutation that will update the team in the backend
  const [updateTeamMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateTeam, {
    onSuccess: (teamResponse) => {
      queryCache.setQueryData(queryKey, teamResponse);
      queryCache.invalidateQueries(queryKeys.currentTeam);
      queryCache.invalidateQueries(queryKeys.teams);
      dispatch(setGlobalModalContent(''));
    },
  });

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleColorChange = (type: string, color?: string) => {
    if (type === 'background') {
      setTeamColor(color ?? null);
    } else if (type === 'text') {
      setTeamTextColor(color ?? null);
    }
  };

  const updateTeamColors = (newTitle: string) => {
    if (!team) {
      return;
    }
    const teamParams = {
      label_bg_color: teamColor,
      label_text_color: teamTextColor,
    };
    const mutationParams = {
      teamId: team.nano_slug,
      team: teamParams,
    };
    updateTeamMutation(mutationParams);
  };

  const defaultBackground = theme.colors.N0;
  const defaultText = theme.colors.N60;

  if (isLoading) {
    return (
      <ModalGrid>
        <ModalHeader>
          <h2>{t(`${translationKey}.title`)}</h2>
          <KoalaIconButton iconName="close" onClick={handleCancel} />
        </ModalHeader>
        <ModalContent>
          <KoalaLoader size="medium" />
        </ModalContent>
        <ModalFooter>
          <KoalaButton disabled={true}>{t('shared.save')}</KoalaButton>
          <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
        </ModalFooter>
      </ModalGrid>
    );
  }

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton iconName="close" onClick={handleCancel} />
      </ModalHeader>
      <ModalContent>
        <FormField>
          <KoalaTextBadge backgroundColor={teamColor ?? defaultBackground} textColor={teamTextColor ?? defaultText}>
            {team?.name}
          </KoalaTextBadge>
        </FormField>
        <InputRow>
          <Field>
            <label>{t(`${translationKey}.bgColor`)}</label>
            <KoalaColorPicker
              handleSetColor={(color?: string) => handleColorChange('background', color)}
              defaultColor={defaultBackground}
              selectedColor={teamColor}
            />
          </Field>
          <Field>
            <label>{t(`${translationKey}.textColor`)}</label>
            <KoalaColorPicker
              handleSetColor={(color?: string) => handleColorChange('text', color)}
              defaultColor={defaultText}
              selectedColor={teamTextColor}
            />
          </Field>
        </InputRow>
      </ModalContent>
      <ModalFooter>
        <KoalaButton submit onClick={updateTeamColors} loading={isUpdating} disabled={isUpdating}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default React.memo(EditTeamColor);
