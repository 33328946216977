import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';

import queryKeys from 'config/queryKeys';
import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import ChargebeeIcon from 'components/_assets/IconChargebee.png';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
}

function ChargebeeIntegration(props: Props) {
  const { workspace, integrations } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];
  const dispatch = useDispatch();
  const [uninstallChargebeeMutation, { isLoading: isUninstallingChargebee }] = useMutation(
    remoteApi.uninstallChargebee,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
      },
    },
  );

  const chargebeeIntegration = integrations.filter((i: Integration) => i.integration_type === 'chargebee')[0];
  const uninstallChargebeeLabel = isUninstallingChargebee ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove');

  const handleUninstallChargebee = (e: any) => {
    e.preventDefault();
    uninstallChargebeeMutation(workspace.slug);
  };

  const handleInstallChargebee = (e: any) => {
    dispatch(setGlobalModalContent('workspace::connect.chargebee'));
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={ChargebeeIcon} alt="Chargebee" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'Chargebee' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Chargebee' })}</p>
      </IntegrationContent>
      <IntegrationAction>
        {chargebeeIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: chargebeeIntegration.connected_to }}
              />
            </div>
            <KoalaTextButton size="small" onClick={handleUninstallChargebee}>
              {uninstallChargebeeLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!chargebeeIntegration && (
          <KoalaButton onClick={handleInstallChargebee}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Chargebee' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default ChargebeeIntegration;
