import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';

// Components
import { PanelGrid, PanelHeader, PanelContent } from 'components/GlobalPanel';
import KoalaIconButton from 'koala/components/IconButton';
import { Trans, useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TipsContent = styled.div`
  font-size: 1.4rem;
  padding: 0 ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x4};
  h3 {
    font-size: 2.4rem;
    margin: ${theme.spacing.x4} 0 ${theme.spacing.x1} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
  h4 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-top: ${theme.spacing.x3};
    margin-bottom: ${theme.spacing.x1};
  }
  a {
    color: ${theme.colors.B50};
    text-decoration: underline;
  }
  ul {
    list-style-type: disc;
    margin-left: 3rem;

    li {
      padding-bottom: ${theme.spacing.half};
      color: ${theme.colors.N90};
    }
  }
  p {
    color: ${theme.colors.N90};
  }
`;

interface Props {}

function MovePlan(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const translationKey = 'panels.writingOKRs';
  const closePanel = () => {
    history.push(location.pathname);
  };

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.tips.okrs.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.push(location.pathname);
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    closePanel();
  };

  const label = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 2);
  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>{t(`${translationKey}.title`)}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        <TipsContent>
          <h3>{t(`${translationKey}.planHeader`)}</h3>
          <p>
            <Trans
              i18nKey={`${translationKey}.planInfo`}
              components={{
                a: (
                  <a
                    href="https://guides.tability.app/docs/about-tability/best-practices"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    a
                  </a>
                ),
              }}
            />
          </p>
          <h3>{t(`${translationKey}.goalsHeader`)}</h3>
          <p>{t(`${translationKey}.goalsInfo`)}</p>
          <h4>{t(`${translationKey}.goal1Header`)}</h4>
          <p>{t(`${translationKey}.goal1line1`)}</p>
          <p>{t(`${translationKey}.goal1line2`)}</p>
          <p>{t(`${translationKey}.goal1line3`)}</p>
          <p>
            <Trans
              i18nKey={`${translationKey}.goal1line4`}
              components={{
                a: (
                  <a
                    href="https://www.tability.io/okrs/how-to-write-good-objectives"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    a
                  </a>
                ),
              }}
              values={{ label: label }}
            />
          </p>
          <h4>{t(`${translationKey}.goal2Header`)}</h4>
          <p>{t(`${translationKey}.goal2line1`)}</p>
          <p>{t(`${translationKey}.goal2line2`)}</p>
          <p>{t(`${translationKey}.goal2line3`)}</p>
          <p>{t(`${translationKey}.goal2line4`)}</p>
          <p>{t(`${translationKey}.goal2line5`)}</p>
          <p>{t(`${translationKey}.goal2line6`)}</p>
          <p>
            <Trans
              i18nKey={`${translationKey}.goal2line7`}
              components={{
                a: (
                  <a
                    href="https://www.tability.io/okrs/how-to-write-good-key-results"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    a
                  </a>
                ),
              }}
            />
          </p>
          <h4>{t(`${translationKey}.goal3Header`)}</h4>
          <p>{t(`${translationKey}.goal3info`)}</p>
          <h4>{t(`${translationKey}.goal4Header`)}</h4>
          <p>{t(`${translationKey}.goal4line1`)}</p>
          <p>{t(`${translationKey}.goal4line2`)}</p>
          <h3>{t(`${translationKey}.resources`)}</h3>
          <h4>{t(`${translationKey}.relatedDocs`)}</h4>
          <ul>
            <li>
              <a
                href="https://guides.tability.app/docs/features/plans/editing-outcomes-key-results"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(`${translationKey}.doc1`)}
              </a>
            </li>
            <li>
              <a
                href="https://guides.tability.app/docs/features/plans/creating-and-editing-plans-and-sub-plans"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(`${translationKey}.doc2`)}
              </a>
            </li>
            <li>
              <a
                href="https://guides.tability.app/docs/tutorials/recommended-structure-for-your-workspace"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(`${translationKey}.doc3`)}
              </a>
            </li>
          </ul>
          <h4>{t(`${translationKey}.otherResources`)}</h4>
          <ul>
            <li>
              <a href="https://www.tability.io/templates" target="_blank" rel="noopener noreferrer">
                {t(`${translationKey}.resource1`)}
              </a>
            </li>
            <li>
              <a
                href="https://www.tability.io/okrs/introduction-to-flowing-okrs-a-pragmatic-implementation-of-okrs"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(`${translationKey}.resource2`)}
              </a>
            </li>
            <li>
              <a href="https://www.tability.io/okrs/what-are-okrs-definition" target="_blank" rel="noopener noreferrer">
                {t(`${translationKey}.resource3`)}
              </a>
            </li>
          </ul>
        </TipsContent>
      </PanelContent>
    </PanelGrid>
  );
}

export default MovePlan;
