import { KoalaSizes } from 'koala/shared/global';
import { colors } from 'koala/shared/styles';
import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  confidence?: string;
  /* progress display in the progress bar from 0 to 100, or null if no target */
  value: number | null;
  dataFor?: string;
  className?: string;
  style?: React.CSSProperties;
  isPending?: boolean;
  isCompleted?: boolean;
  size?: (typeof KoalaSizes)[number];
}

const ConfidenceContainer = styled.div`
  min-width: 3.6rem;
  padding: 2px 4px;
  border-radius: 4px;
  height: fit-content;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span {
    font-size: 11px;
    line-height: 15.4px;
    font-weight: 600;
    text-align: center;
  }

  &.confidence--green {
    color: ${colors.G50};
    background: ${colors.G5};
    border: 1px solid ${colors.G20};
  }
  &.confidence--yellow {
    color: ${colors.Y80};
    background: ${colors.Y10};
    border: 1px solid ${colors.Y50};
  }
  &.confidence--red {
    color: ${colors.R70};
    background: ${colors.P10};
    border: 1px solid ${colors.R20};
  }

  &.confidence--blue {
    color: ${colors.B50};
    background: ${colors.B5};
    border: 1px solid ${colors.B10};
  }
  &.confidence--grey {
    color: ${colors.N70};
    background: ${colors.N10};
    border: 1px solid ${colors.N20};
  }
  &.confidence--default {
    color: ${colors.N70};
    background: ${colors.N10};
    border: 1px solid ${colors.N20};
  }

  &.confidence--large {
    width: 5rem;
    padding: 4px;
    height: 2.4rem;
    span {
      font-size: 15px;
      line-height: 15.4px;
      font-weight: 600;
      text-align: center;
    }
  }
`;

const PendingFlag = styled.div`
  height: 1rem;
  width: 1rem;
  background: ${colors.R50};
  border-radius: 50%;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
`;

const CompletedFlag = styled.div`
  font-weight: 800;
  font-size: 1.2rem;
`;

const KoalaConfidenceText: FC<Props> = ({ ...props }) => {
  const { confidence, value, dataFor, className, isPending, size, isCompleted } = props;
  const confidenceClass = `${className ?? ''} confidence--${confidence ?? 'default'} confidence--${size ?? 'medium'}`;
  const confidenceValue: string = value !== null && value >= 0 ? `${value.toFixed()}%` : `—`;

  return (
    <ConfidenceContainer className={confidenceClass} data-tip={dataFor ? true : false} data-for={dataFor}>
      {isPending && <PendingFlag />}
      {isCompleted && <CompletedFlag>&#10003;</CompletedFlag>}
      {!isCompleted && <span>{confidenceValue}</span>}
    </ConfidenceContainer>
  );
};

export default KoalaConfidenceText;
