import React, { useEffect } from 'react';
//import ReactQuill from 'react-quill';
// CSS
import 'components/QuillBodyEditor/quill.css';
import 'components/QuillBodyEditor/quill.mention.css';

interface Props {
  body: string;
}

function QuillBodyRenderer(props: Props) {
  const { body } = props;

  useEffect(() => {
    const divElement = document.getElementById('ql-div-with-links');

    const handleLinkClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Check if the clicked element is an anchor link within the div
      if (target.tagName === 'A') {
        event.stopPropagation();
      }
    };

    divElement?.addEventListener('click', handleLinkClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      divElement?.removeEventListener('click', handleLinkClick);
    };
  }, []);

  return (
    <div className="quill">
      <div className="ql-container ql-notes ql-display-only">
        <div className="ql-editor ql-display-only" id="ql-div-with-links" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  );

  // return <ReactQuill value={body} theme="bubble" readOnly={true} />;
}

export default React.memo(QuillBodyRenderer);
