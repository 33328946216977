import KoalaLoader from 'koala/components/Loader';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { Objective, Outcome } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import OutcomeExpandable from 'components/OutcomeExpandable';

const ObjectiveBlock = styled.div`
  margin-bottom: ${theme.spacing.x2};

  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;

  .subtle {
    padding: 1.2rem ${theme.spacing.x2};
  }
`;

const ObjectiveHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1.2rem ${theme.spacing.x2};
  background: ${theme.colors.N0};
  gap: ${theme.spacing.x1};
  grid-area: title;
  font-weight: 700;
  font-size: 14px;

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr 7rem 7rem 6rem;
    grid-template-areas: 'title outcomes initiatives progress';
  }

  @media ${theme.devices.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas: 'title';
  }
`;

const OutcomeBlockContainer = styled.div`
  border-top: 1px solid ${theme.colors.N10};

  :last-of-type {
    .outcome-row {
      border-bottom-left-radius: ${theme.spacing.half};
      border-bottom-right-radius: ${theme.spacing.half};
    }
  }

  &:last-of-type {
    border-bottom: 0;
  }
  hr {
    color: ${theme.colors.N20};
    background-color: ${theme.colors.N20};
    border: none;
    height: 1px;
    margin: ${theme.spacing.half} 0;
  }
`;

const LoaderContainer = styled.div`
  padding: ${theme.spacing.x1};
`;

interface Props {
  objective: Objective;
  outcomes: Outcome[];
  isLoading: boolean;
}

function PlanObjective(props: Props) {
  const { objective, outcomes, isLoading } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  return (
    <ObjectiveBlock key={objective.id}>
      <ObjectiveHeader>
        {objective.title}&nbsp;
        {isLoading && <KoalaLoader />}
      </ObjectiveHeader>
      {isLoading && outcomes.length === 0 && (
        <LoaderContainer>
          <KoalaLoader size="large" />
        </LoaderContainer>
      )}
      {!isLoading && outcomes.length === 0 && (
        <div className="empty-objective subtle">
          This {translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase()} does not have any{' '}
          {translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase()}.
        </div>
      )}
      {outcomes.map((outcome) => {
        return (
          <OutcomeBlockContainer key={outcome.id}>
            <OutcomeExpandable outcome={outcome} key={outcome.id} />
          </OutcomeBlockContainer>
        );
      })}
    </ObjectiveBlock>
  );
}

export default PlanObjective;
