import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useLocation, useHistory } from 'react-router-dom';
import * as workspaceUtils from 'utils/workspaceUtils';

import Map from './Map';

import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';

// API
import * as remoteApi from 'api/remote';

// Components
import { PanelGrid, PanelHeader, PanelContent, PanelActions } from 'components/GlobalPanel';
import Loader from 'components/Loader';

import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

import UpgradePanel from 'components/UpgradePanel';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LargeEmptyState = styled.div`
  background: #fff;
  padding: ${theme.spacing.x4};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

interface Props {
  outcomeId: string;
}

function EditOutcomePanel(props: Props) {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const { outcomeId } = props;

  const currentMembership: TabilityTypes.Membership = useSelector(
    (state: any) => state.session.currentMembership,
    shallowEqual,
  );
  const currentUser: TabilityTypes.User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const hasPremiumSubscription = workspaceUtils.hasPremiumSubscription(currentWorkspace);
  // const isTrial = workspaceUtils.hasV4Trial(currentWorkspace);

  // Adds the current user to the membership to be able to display the user in the panel
  currentMembership.user = currentUser;

  // React to the ESC key to hide the panel
  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.goBack();
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = 0;

  // Get the outcome details
  const { data: outcomeResponse }: any = useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
  });

  const outcome: TabilityTypes.Outcome = outcomeResponse ? outcomeResponse.data : null;

  const handleCancel = (e: any) => {
    e.preventDefault();
    history.goBack();
  };

  // Now we can display the app with the Chrome
  if (!outcome) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1);
  const showUpgradePanel = !hasPremiumSubscription;

  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>{t('panels.outcomeMap.title', { outcomeLabel: outcomeLabel })}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        <ReactFlowProvider>
          <Map outcome={outcome} />
        </ReactFlowProvider>
        {showUpgradePanel && (
          <Mask>
            <LargeEmptyState>
              <h3>{t('panels.outcomeMap.notAvailable', { outcomeLabel: outcomeLabel })}</h3>
              <UpgradePanel
                title={t('panels.outcomeMap.upgradeHeader', { outcomeLabel: outcomeLabel })}
                description={t('panels.outcomeMap.upgradeInfo', { outcomeLabel: outcomeLabel })}
              />
            </LargeEmptyState>
          </Mask>
        )}
      </PanelContent>

      <PanelActions>
        <KoalaButton onClick={handleCancel} appearance="subtle">
          {t('shared.close')}
        </KoalaButton>
      </PanelActions>
    </PanelGrid>
  );
}

export default React.memo(EditOutcomePanel);
