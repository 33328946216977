import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import KoalaSelect from 'koala/components/Select';
import { useTranslation } from 'react-i18next';

interface Props {
  value: any;
  handleChange: any;
}
function InsightFilter(props: Props) {
  const { value, handleChange } = props;
  const { t } = useTranslation();

  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const handleSingleOptionSelect = (selectId: string, option: any) => {
    const value = option ? option.value : '';
    handleChange(value);
  };

  const insightOptions = [
    { label: t('workspacePlans.insightFilter.noCheckins'), value: 'no_checkins' },
    { label: t('workspacePlans.insightFilter.negativeNCS'), value: 'negative_ncs' },
    { label: t('workspacePlans.insightFilter.positiveNCS'), value: 'positive_ncs' },
    {
      label: t('workspacePlans.insightFilter.noOutcomeProgress', {
        label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLocaleLowerCase(),
      }),
      value: 'no_outcome_progress',
    },
    {
      label: t('workspacePlans.insightFilter.noInitiativeProgress', {
        label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLocaleLowerCase(),
      }),
      value: 'no_initiative_progress',
    },
    { label: t('workspacePlans.insightFilter.empty'), value: 'no_outcomes' },
    {
      label: t('workspacePlans.insightFilter.tooManyOutcomes', {
        label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLocaleLowerCase(),
      }),
      value: 'too_many_outcomes',
    },
  ];

  const getSelectedInsightOptionsFromFilter = () => {
    return insightOptions.filter((o: any) => {
      return o.value === value;
    });
  };

  return (
    <KoalaSelect
      isClearable
      placeholder={t('workspacePlans.insightFilterPlaceholder')}
      options={insightOptions}
      handleChange={(options) => handleSingleOptionSelect('plan_status', options)}
      selectedOption={getSelectedInsightOptionsFromFilter()}
    />
  );
}

export default React.memo(InsightFilter);
