import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1ZM14.3407 6.92423C14.4605 6.56489 14.367 6.16873 14.0991 5.9009C13.8313 5.63306 13.4351 5.53954 13.0758 5.65932L7.9878 7.35532C7.6892 7.45485 7.45488 7.68917 7.35535 7.98777L5.65935 13.0758C5.53957 13.4351 5.63309 13.8313 5.90093 14.0991C6.16876 14.3669 6.56493 14.4605 6.92426 14.3407L12.0123 12.6447C12.3109 12.5451 12.5452 12.3108 12.6447 12.0122L14.3407 6.92423ZM8.18917 11.8109L9.0946 9.09457L11.8109 8.18914L10.9055 10.9054L8.18917 11.8109Z"
      fill="#737373"
    />
  </svg>
);
