import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import FavroIcon from '../_assets/IconFavro.png';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
}

function FavroIntegration(props: Props) {
  const { workspace, integrations } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];
  const dispatch = useDispatch();
  const [uninstallFavroMutation, { isLoading: isUninstallingFavro }] = useMutation(remoteApi.uninstallFavro, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });
  const favroIntegration = integrations.filter((i: Integration) => i.integration_type === 'favro')[0];
  const uninstallFavroLabel = isUninstallingFavro ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove');

  const handleUninstallFavro = (e: React.MouseEvent) => {
    e.preventDefault();
    uninstallFavroMutation(workspace.slug);
  };

  const handleInstallFavro = (e: React.MouseEvent) => {
    dispatch(setGlobalModalContent('workspace::connect.favro'));
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={FavroIcon} alt="Favro" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>
          {t('workspaceSettingsIntegrations.dataConnectorHeader', {
            integration: 'Favro',
          })}
        </h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Favro' })}</p>
        <p>
          <a
            href="https://guides.tability.app/docs/features/integrations/data-sources"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {favroIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: favroIntegration.connected_to }}
              />
            </div>
            <KoalaTextButton size="small" onClick={handleUninstallFavro}>
              {uninstallFavroLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!favroIntegration && (
          <KoalaButton onClick={handleInstallFavro}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Favro' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default FavroIntegration;
