import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import { spacing } from 'koala/shared/styles';
import React, { FC } from 'react';
import styled from 'styled-components';

const LegendContainer = styled.div`
  ul {
    padding: 0;
    margin: 0;
    display: grid;
    gap: ${spacing.x1};
  }
  li {
    display: flex;
    align-items: center;
    gap: ${spacing.x1};
  }

  &.legend--small {
    font-size: 1.2rem;
    ul {
      gap: ${spacing.half};
    }
    li {
      gap: ${spacing.half};
    }
  }
  &.legend--large {
    font-size: 1.6rem;
  }

  &.legend--horizontal {
    ul {
      display: flex;
    }
  }
`;

interface LegendData {
  icon: any;
  title: string;
}
interface LegendProps {
  /** data for legend */
  data: LegendData[];
  /** set direction of legend */
  isVertical?: boolean;
  /** Appearance of legend from KoalaAppearances */
  appearance?: (typeof KoalaAppearances)[number];
  /** Size of legend from KoalaSizes */
  size?: (typeof KoalaSizes)[number];
  /** Optional class name for legend */
  className?: string;
  /** Optional styling for legend */
  style?: React.CSSProperties;
}

const KoalaLegend: FC<LegendProps> = ({ children, ...props }) => {
  const { data, className, isVertical, size } = props;

  let legendClass = `${className ?? ''} legend--${isVertical ? 'vertical' : 'horizontal'}`;
  if (size) {
    legendClass += ` legend--${size}`;
  }

  return (
    <LegendContainer className={legendClass}>
      <ul>
        {data.map((item, i) => (
          <li key={i}>
            {item.icon}
            {item.title}
          </li>
        ))}
      </ul>
    </LegendContainer>
  );
};

KoalaLegend.defaultProps = {
  appearance: 'primary',
  size: 'medium',
  isVertical: true,
};

export default KoalaLegend;
