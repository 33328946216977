import createReducer from './createReducer';
//import * as TabilityTypes from '../../types';
import * as actionTypes from '../actions/actionTypes';
import ymlUtils from 'utils/ymlUtils';
//import update from 'immutability-helper';

const INITIAL_STATE = {
  data: {
    plan: null,
    objectives: {},
    outcomes: {},
    initiatives: {},
    planItems: {},
    objectiveItems: {},
    outcomeItems: {},
  },
  cursor: null,
  blockIds: [],
};

const ymlEditorReducer = createReducer(INITIAL_STATE)({
  [actionTypes.YML_EDITOR_RESET]: () => INITIAL_STATE,
  [actionTypes.YML_EDITOR_LOAD_TEMPLATE]: (state: any, action: any) => {
    const newState = ymlUtils.ymlToEditorData(action.template);
    if (!newState) {
      return state;
    }
    return newState;
  },
  [actionTypes.YML_EDITOR_REORDER_ITEMS]: (state: any, action: any) => {
    const newState = ymlUtils.reorderItems(state, action.result);
    return newState;
  },
  [actionTypes.YML_EDITOR_UPDATE_ITEM]: (state: any, action: any) => {
    const { item, itemType } = action;

    const oldItem = state.data[itemType][item.id];
    const newItem = {
      ...oldItem,
      ...item,
    };
    const newState = {
      ...state,
      data: {
        ...state.data,
        [itemType]: {
          ...state.data[itemType],
          [item.id]: newItem,
        },
      },
    };
    return newState;
  },
  [actionTypes.YML_EDITOR_SET_CURSOR]: (state: any, action: any) => {
    const { cursor } = action;
    return {
      ...state,
      cursor,
    };
  },
  [actionTypes.YML_EDITOR_MOVE_CURSOR_UP]: (state: any) => {
    const cursor = ymlUtils.getPreviousBlockId(state);
    return {
      ...state,
      cursor,
    };
  },
  [actionTypes.YML_EDITOR_MOVE_CURSOR_DOWN]: (state: any) => {
    const cursor = ymlUtils.getNextBlockId(state);
    return {
      ...state,
      cursor,
    };
  },
  [actionTypes.YML_EDITOR_ADD_ITEM]: (state: any) => {
    const newState = ymlUtils.addItem(state);

    return {
      ...newState,
    };
  },
  [actionTypes.YML_EDITOR_ADD_CHILD_ITEM]: (state: any) => {
    const newState = ymlUtils.addChildItem(state);

    return {
      ...newState,
    };
  },
  [actionTypes.YML_EDITOR_REMOVE_ITEM]: (state: any, action: any) => {
    const { blockId } = action;
    const newState = ymlUtils.removeItem(state, blockId);

    return {
      ...newState,
    };
  },
});

export default ymlEditorReducer;
