import React, { useEffect, Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { Link, useParams, useLocation } from 'react-router-dom';
import * as amplitudeUtils from 'utils/amplitude';
import * as outcomeUtils from 'utils/outcomeUtils';
import { Helmet } from 'react-helmet';
import Cookies from 'universal-cookie';

// API
import * as remoteApi from 'api/remote';

import AccessDenied from 'components/AccessDenied';
import NotFound from 'components/NotFound';

import TabilityIconBlack from 'components/_assets/TabilityIconBlack';
import Wordmark from 'components/Wordmark';
import FormField from 'components/FormField';

import Loader from 'components/Loader';
import KoalaButton from 'koala/components/Button';
import KoalaConfidenceText from 'koala/components/ConfidenceText';
import KoalaTextBadge from 'koala/components/TextBadge';
import CheckinsChart from 'components/Chart';
import { useTranslation } from 'react-i18next';
import EmbeddedOutcome from 'components/EmbeddedOutcome';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
  padding: ${theme.spacing.x2};
`;
const LabelSpacing = styled.div`
  margin-left: ${theme.spacing.x1};
`;

const ConfidenceAndTitle = styled.div`
  display: flex;
  align-items: center;

  .confidence {
    display: flex;
    align-items: center;
    position: relative;
    top: 2px;
  }

  h3 {
    margin-left: ${theme.spacing.x1};
  }
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.x1};

  background: ${theme.colors.N3};

  svg {
    margin-right: ${theme.spacing.x1};
    width: 2rem;
  }
`;

const HeaderActionsMeta = styled.div`
  display: flex;
  align-items: center;
  div.prct {
    margin-right: ${theme.spacing.x3};
  }

  .plan-link {
    font-weight: 600;
    color: ${theme.colors.subtleText};
  }

  button {
    margin-left: ${theme.spacing.x2};
  }
`;

const CheckinContainer = styled.div`
  padding: 0 ${theme.spacing.x2};
`;

const Container = styled.div`
  max-width: 80rem;
`;

const AccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: ${theme.spacing.x3};
  }

  form {
    padding-bottom: ${theme.spacing.x8};
    width: 40rem;

    button {
      margin-right: ${theme.spacing.x2};
    }
    .danger {
      color: ${theme.colors.red};
    }
  }
`;

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x8};
`;

function OutcomeBlock() {
  const cookies = new Cookies();
  const location = useLocation();
  const { workspaceSlug, outcomeId } = useParams<{ workspaceSlug: string; outcomeId: string }>();
  const { t } = useTranslation(undefined, { useSuspense: false });
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.outcome.embed.view', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const COOKIENAME = '_tbty_pp';
  const COOKIEPATH = location.pathname;

  const public_access_password = cookies.get(COOKIENAME);

  const params = {
    public_access_password,
  };

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentOutcome, outcomeId, params];
  const staleTime = 0;
  const [accessDenied, setAccessDenied] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [password, setPassword] = useState('');

  // Get the outcome details
  const {
    isError,
    error,
    data: outcomeResponse,
    isLoading,
  }: any = useQuery(queryKey, remoteApi.fetchPublicOutcomeDetails, {
    staleTime,
    onError: (error: any) => {
      const errorString = error.response.data.error;
      const accessDenied = errorString.includes('Public access is disabled');
      setAccessDenied(accessDenied);
      const passwordRequired = errorString.includes('Password is missing');
      setPasswordRequired(passwordRequired);
      if (public_access_password) {
        setPasswordError(true);
      }
    },
  });

  const outcome = outcomeResponse ? outcomeResponse.data : null;

  // Construct the query key using the plan Id
  const queryKeyCheckins = [queryKeys.checkins, outcomeId, params];

  // Get the plan details
  const { data: checkinsResponse }: any = useQuery(queryKeyCheckins, remoteApi.fetchPublicCheckins, {
    staleTime,
  });

  let checkins = [];
  if (checkinsResponse) {
    checkins = checkinsResponse.data;
  }

  // Now we can display the app with the Chrome
  if (isLoading) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  if (accessDenied) {
    const workspacePath = `https://${process.env.REACT_APP_DOMAIN}/${workspaceSlug}/inbox#outcome:${outcomeId}:show`;
    return (
      <Fragment>
        <Helmet>
          <title>{t('publicPlan.restricted.title')} | Tability</title>
        </Helmet>
        <AccessContainer>
          <WordmarkWrapper>
            <Wordmark type="dark" />
          </WordmarkWrapper>

          <h2>{t('workspaceEmbedOutcome.restrictedHeader')}</h2>
          <p>{t('workspaceEmbedOutcome.restrictedInfo')}</p>
          <p>
            <a href={workspacePath} target="_blank" rel="noopener noreferrer" className="button">
              {t('publicPlan.viewButton')}
            </a>
          </p>
        </AccessContainer>
      </Fragment>
    );
  }

  const handlePasswordChange = (e: any) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleSavePassword = (e: any) => {
    e.preventDefault();
    cookies.set(COOKIENAME, password, {
      path: COOKIEPATH,
      secure: true,
    });
    window.location.reload();
  };

  if (passwordRequired) {
    return (
      <Fragment>
        <Helmet>
          <title>{t('publicPlan.password.title')} | Tability</title>
        </Helmet>
        <AccessContainer>
          <WordmarkWrapper>
            <Wordmark type="dark" />
          </WordmarkWrapper>

          <h2>{t('workspaceEmbedOutcome.passwordHeader')}</h2>
          <form onSubmit={handleSavePassword}>
            <FormField>
              <label>{t('publicPlan.password.passwordLabel')}</label>
              <input type="password" value={password} onChange={handlePasswordChange} />
            </FormField>
            {passwordError && (
              <FormField>
                <span className="danger">{t('publicPlan.password.error')}</span>
              </FormField>
            )}
            <FormField>
              <KoalaButton submit>{t('shared.submit')}</KoalaButton>
              <KoalaButton isLink to="/" appearance="subtle">
                {t('publicPlan.password.return')}
              </KoalaButton>
            </FormField>
          </form>
        </AccessContainer>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  const { plan } = outcome;
  const { workspace } = plan;

  const currentCheckin = outcome.current_checkin;
  const outcomeUrl = `https://${process.env.REACT_APP_DOMAIN}/${workspaceSlug}/plans/${plan.nano_slug}/outcomes#outcome:${outcome.nano_slug}:show`;

  const hasTarget = outcomeUtils.hasTarget(outcome);
  return (
    <Container>
      <Helmet>
        <title>
          {workspace.name} | {outcome.title} | Tability
        </title>
      </Helmet>

      <HeaderActions>
        <HeaderActionsMeta>
          <TabilityIconBlack />
          <Link className="plan-link" to={outcomeUrl}>
            {plan.title}
          </Link>
        </HeaderActionsMeta>
        <HeaderActionsMeta>
          <KoalaButton href={outcomeUrl} target="_blank" rel="noopener noreferrer" appearance="subtle" size="small">
            {t('workspaceEmbedOutcome.viewButton')}
          </KoalaButton>
        </HeaderActionsMeta>
      </HeaderActions>
      <Header>
        <ConfidenceAndTitle>
          <KoalaConfidenceText
            isCompleted={outcome.completed}
            value={hasTarget ? outcome.outcome_progress_prct * 100 : null}
            confidence={currentCheckin ? currentCheckin.confidence : 'grey'}
            isPending={outcome.is_pending_checkin}
          />
          <h3>{outcome.title}</h3>

          <LabelSpacing>
            {outcome.completed && (
              <KoalaTextBadge variant="blue-light" isLowercase>
                {t('shared.status.completed')}
              </KoalaTextBadge>
            )}
            {outcome.archived && (
              <KoalaTextBadge variant="violet-light" isLowercase>
                {t('shared.status.archived')}
              </KoalaTextBadge>
            )}
          </LabelSpacing>
        </ConfidenceAndTitle>
      </Header>
      <CheckinContainer>
        <CheckinsChart outcome={outcome} checkins={checkins} />
        <EmbeddedOutcome outcome={outcome} key={outcome.id} planId={plan.nano_slug} />
      </CheckinContainer>
    </Container>
  );
}

export default React.memo(OutcomeBlock);
