import KoalaButton from 'koala/components/Button';
import React from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin-bottom: ${theme.spacing.x3};
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingBilling(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();

  if (workspace.billing_subscription_standup_plan) {
    return (
      <Container>
        <h3>{t('workspaceSettingsBilling.standups.title')}</h3>
        <p>{t('workspaceSettingsBilling.standups.active')}</p>
      </Container>
    );
  }

  return (
    <Container>
      <h3>{t('workspaceSettingsBilling.standups.title')}</h3>
      <p>{t('workspaceSettingsBilling.standups.notActive')}</p>
      <p className="subtle">{t('workspaceSettingsBilling.standups.pricing')}</p>
      <p>
        <KoalaButton onClick={() => window.Intercom('show')} appearance="subtle">
          {t('workspaceSettingsBilling.standups.contact')}
        </KoalaButton>
      </p>
    </Container>
  );
}

export default WorkspaceSettingBilling;
