import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 95rem;
  position: relative;
  padding: ${theme.spacing.x4} ${theme.spacing.x2};
`;
