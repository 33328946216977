import React from 'react';

const TrashIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5.00098V15.001C5 16.1055 5.89543 17.001 7 17.001H13C14.1046 17.001 15 16.1055 15 15.001V5.00099H17V15.001C17 17.2101 15.2091 19.001 13 19.001H7C4.79086 19.001 3 17.2101 3 15.001V5.00098H5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.99902C9.44772 2.99902 9 3.44674 9 3.99902H7C7 2.34217 8.34315 0.999023 10 0.999023C11.6569 0.999023 13 2.34217 13 3.99902H11C11 3.44674 10.5523 2.99902 10 2.99902Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4.99121C2 4.43893 2.44772 3.99121 3 3.99121H17C17.5523 3.99121 18 4.43893 18 4.99121C18 5.5435 17.5523 5.99121 17 5.99121H3C2.44772 5.99121 2 5.5435 2 4.99121Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8C8.55228 8 9 8.44772 9 9V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V9C7 8.44772 7.44772 8 8 8ZM12 8C12.5523 8 13 8.44772 13 9V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V9C11 8.44772 11.4477 8 12 8Z"
      fill="#737373"
    />
  </svg>
);

export default TrashIcon;
