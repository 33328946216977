import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';

import { IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import JiraIcon from '../_assets/IconJira.png';
import * as workspaceUtils from 'utils/workspaceUtils';
import KoalaButton from 'koala/components/Button';
import UpgradeIcon from 'components/_assets/UpgradeIcon';

const UpgradeBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface Props {
  workspace: Workspace;
  integrations: Integration[];
}

function JiraIntegration(props: Props) {
  const { workspace, integrations } = props;
  const { t } = useTranslation();
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  const jiraIntegration = integrations.filter((i) => i.integration_type === 'jira_cloud')[0];

  const outcomes = translate(workspace, CustomTermKey.OUTCOME, 2).toLowerCase();
  const initiatives = translate(workspace, CustomTermKey.INITIATIVE, 2).toLowerCase();

  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(workspace) || workspace.pricing_version >= 2;

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={JiraIcon} alt="Jira" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>
          {t('workspaceSettingsIntegrations.jiraHeader', {
            outcomes,
          })}
        </h3>
        <p>
          {t('workspaceSettingsIntegrations.jiraInfo', {
            outcomes,
            initiatives,
          })}
        </p>
        <p>
          <a href="https://guides.tability.app/docs/integrations/jira" target="_blank" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {jiraIntegration && (
          <span>
            <Trans
              i18nKey="workspaceSettingsIntegrations.connected"
              components={{ b: <b /> }}
              values={{ value: jiraIntegration.connected_to.replace('https://', '') }}
            />
          </span>
        )}
        {!jiraIntegration && hasEssentialsSubscription && (
          <KoalaButton
            href="https://marketplace.atlassian.com/apps/1228182/tability-okrs-goal-tracking-for-jira?hosting=cloud&tab=overview"
            target="_blank"
            rel="noopener noreferrer"
            className="button primary"
          >
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Jira' })}
          </KoalaButton>
        )}
        {!jiraIntegration && !hasEssentialsSubscription && (
          <UpgradeBlock>
            <p>
              <UpgradeIcon /> {t('workspaceSettingsIntegrations.planRequired')}
            </p>
            {currentMembership && ['owner'].includes(currentMembership.role) && (
              <KoalaButton isLink to={`/${workspace.slug}/settings/billing`} appearance="secondary" size="small">
                {t('shared.upgrade.startTrial')}
              </KoalaButton>
            )}
          </UpgradeBlock>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default JiraIntegration;
