import { TFunction } from 'i18next';

export function translateRole(role: string, t: TFunction) {
  switch (role) {
    case 'owner':
      return t('role.owner');
    case 'user':
      return t('role.user');
    case 'admin':
      return t('role.admin');
    default:
      return role;
  }
}
