import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useHistory, Link } from 'react-router-dom';

import KoalaButton from 'koala/components/Button';
import * as checkinUtils from 'utils/checkinUtils';
import KoalaColorBlock from 'koala/components/ColorBlock';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useTranslation } from 'react-i18next';

export const BlockGutter = styled.div`
  position: relative;
  grid-area: gutter;
  display: flex;

  align-items: center;
`;

export const BlockContent = styled.div`
  line-height: 2.4rem;
  font-weight: 400;
  grid-area: content;
  background: transparent;
  margin: 0;
  .block-title {
    display: flex;
  }
  display: flex;
  justify-content: space-between;
  p {
    margin-top: ${theme.spacing.x1};
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
`;

const BlockProgress = styled.div`
  position: relative;
  grid-area: progress;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > div {
    margin-right: ${theme.spacing.x2};

    &.progress {
      font-size: 1.4rem;
      color: ${theme.colors.subtleText};
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
export const BlockMeta = styled.div`
  line-height: 2.4rem;
  font-weight: 500;
  grid-area: meta;
  display: flex;
  justify-content: flex-start;
  min-width: 6.5rem;
  margin-right: ${theme.spacing.x1};

  > div {
    margin-right: ${theme.spacing.x2};

    &:last-of-type {
      margin-right: 0;
    }
  }

  button.icon {
    height: 2.4rem;
    width: 2.4rem;

    svg {
      width: 2rem;
      height: 2rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
  }
`;

const BlockGrid = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: 2rem 1fr auto;
  grid-template-rows: auto;
  grid-template-areas: 'gutter content progress';
  align-items: center;
  column-gap: ${theme.spacing.x1};
  margin: 0;
  padding: 1.2rem ${theme.spacing.x2};
  border-radius: 4px;

  :hover {
    background: ${theme.colors.N3};
  }
  &.selected,
  :active,
  :focus {
    background: ${theme.colors.B5};
  }

  &.no-hover-bg {
    &:hover {
      background: transparent;
    }
  }
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  isSelected: boolean;
}

function OutcomeBlock(props: Props) {
  const history = useHistory();
  const { outcome, isSelected } = props;

  const dispatch = useDispatch();
  const blockId = `outcome:${outcome.nano_slug}`;
  const hashPath = `#${blockId}:show`;
  const alignPath = `${blockId}:add.dependency`;
  const { t } = useTranslation();

  const handleOutcomeClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashPath);
  };

  const handleAlignClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setGlobalModalContent(alignPath));
  };

  const currentCheckin = outcome.current_checkin;
  const currentConfidence = currentCheckin ? currentCheckin.confidence : 'grey';

  // Calculate the progress prct
  const className = isSelected ? 'selected' : '';
  const confidenceColor = checkinUtils.confidenceToColor(currentConfidence);

  return (
    <BlockGrid onClick={handleOutcomeClick} className={className}>
      <BlockGutter>
        <KoalaColorBlock color={confidenceColor} className="color-block" isCompleted={outcome.completed} />
      </BlockGutter>
      <BlockContent>
        <Link to={hashPath}>{outcome.title}</Link>
      </BlockContent>
      <BlockProgress>
        <KoalaButton onClick={handleAlignClick} appearance="subtle" size="small">
          {t('shared.align')}
        </KoalaButton>
      </BlockProgress>
    </BlockGrid>
  );
}

export default React.memo(OutcomeBlock);
