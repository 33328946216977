import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as remoteApi from 'api/remote';

import KoalaIconButton from 'koala/components/IconButton';

// Views
import styled from 'styled-components';
import theme from 'theme';

import { useTranslation } from 'react-i18next';
import ShareSelection from 'components/ShareSelection';
import KoalaButton from 'koala/components/Button';
import { useLocation } from 'react-router-dom';
import CopyTextInput from 'components/CopyTextInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};

  .send-btn {
    width: fit-content;
  }

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    line-height: 1.4;
    font-size: 1.4rem;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    border-radius: 4px;
    border: 1px solid ${theme.colors.N20};
    width: 100%;
    resize: none;
  }
  label {
    color: ${theme.colors.N70};
    margin-bottom: ${theme.spacing.x1};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    display: block;
  }

  .success {
    color: ${theme.colors.G80};
    background: ${theme.colors.G10};
    padding: ${theme.spacing.x1};
    border-radius: 4px;
  }
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

function ShareLinkViaEmail() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [userList, setUserList] = useState<string[]>([]);
  const [message, setMessage] = useState('');
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const translationKey = 'modals.share';
  const [shareSuccessful, setShareSuccessful] = useState(false);

  useEffect(() => {
    setShareSuccessful(false);
  }, [userList]);

  const [shareLinkMutation, { isLoading: isShareByEmailsLoading }] = useMutation(remoteApi.shareLinkViaEmail, {
    onSuccess: () => {
      setShareSuccessful(true);
    },
  });
  // Get the plan details
  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };
  const link = `https://${process.env.REACT_APP_DOMAIN}${location.pathname}`;

  const handleShare = () => {
    const mutationParams = {
      workspaceSlug: currentWorkspace.slug,
      share: {
        list: userList,
        message,
        link,
      },
    };
    shareLinkMutation(mutationParams);
  };

  const emailInputPlaceholder = t('modals.share.emailInputPlaceholder') ?? 'e.g Ava Andrews or ava@acme.co';
  const emailMessagePlaceholder = t('modals.share.emailMessagePlaceholder') ?? 'Anything else they should know';

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`, { label: 'link' })}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Container>
          <h3>{t('modals.share.emailTitle')}</h3>

          <InputSection>
            <label>{t('modals.share.emailInput')}</label>
            <ShareSelection userList={userList} setUserList={setUserList} placeholder={emailInputPlaceholder} />
          </InputSection>

          <InputSection>
            <label>{t('modals.share.emailMessage')}</label>
            <textarea
              placeholder={emailMessagePlaceholder}
              rows={3}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </InputSection>
          {!shareSuccessful && (
            <Actions>
              <KoalaButton
                className="send-btn"
                disabled={userList.length === 0 || isShareByEmailsLoading}
                onClick={handleShare}
                loading={isShareByEmailsLoading}
              >
                {t('shared.share')}
              </KoalaButton>
            </Actions>
          )}
          {shareSuccessful && <div className="success">{t('shared.shared')}</div>}

          <h3>{t('modals.share.linkTitle')}</h3>
          <div>
            <p>{t('modals.share.onlyLoggedIn')}</p>
            <CopyTextInput text={link} />
          </div>
        </Container>
      </ModalContent>
    </ModalGrid>
  );
}

export default ShareLinkViaEmail;
