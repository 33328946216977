import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';
import theme from 'theme';
import parse from 'parse-link-header';
// API
import * as remoteApi from 'api/remote';

// Components
import RadioField from 'components/RadioField';
import PopcornTabby from './Popcorn_tabby.png';

import KoalaButton from 'koala/components/Button';

import NewsItem from 'components/Newsblocks/NewsItem';
import { useTranslation } from 'react-i18next';
import { UXPREFERENCES } from 'config/constants';
import { shallowEqual, useSelector } from 'react-redux';
import { TabilityEvent } from 'types';
import { AxiosResponse } from 'axios';
import KoalaLoader from 'koala/components/Loader';

const InboxPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  > p {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing.x2};
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing.x6} 0;
  gap: ${theme.spacing.x1};
  img {
    width: 20rem;
    margin-bottom: ${theme.spacing.x1};
  }
  h4 {
    font-size: 1.8rem;
    font-weight: 600;
    color: ${theme.colors.subtleText};
  }
  p {
    color: ${theme.colors.subtleText};
  }
  a {
    color: ${theme.colors.subtleText};
    text-decoration: underline;
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EventList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

const Container = styled.div`
  background: ${theme.colors.N0};
  padding: ${theme.spacing.x2};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};
`;

interface Props {}

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

function Inbox(props: Props) {
  const { t } = useTranslation();
  // Getting the feed filter UX preference
  const storedUXPreferences = localStorage.getItem(UXPREFERENCES);
  const UXPreferences = storedUXPreferences ? JSON.parse(storedUXPreferences) : {};
  const newsfeedViewType = UXPreferences.newsfeedViewType !== undefined ? UXPreferences.newsfeedViewType : 'everything';
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  // Query that fetches all the outcomes with pending checkins
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [viewType, setViewType] = React.useState(newsfeedViewType); // ['kanban', 'listing', 'timeline'

  let filter = {};

  if (viewType === 'following') {
    filter = { following: currentMembership.id };
  } else if (viewType === 'watching') {
    filter = { watching: currentMembership.id };
  } else if (viewType === 'teams') {
    filter = { teams: currentMembership.id };
  }
  const params = { filter };

  const eventsQueryKey = [queryKeys.events, workspaceSlug, params];
  const {
    isFetching,
    isFetchingMore,
    data: outcomesResponse,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(eventsQueryKey, remoteApi.fetchEventsWithFilters, {
    staleTime: 1000 * 60 * 5,
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  let events: AxiosResponse<TabilityEvent[]>[] = [];

  if (outcomesResponse) {
    events = outcomesResponse;
  }

  const supportedEvents = ['checkin.created'];

  const handleSetViewType = (viewType: string) => {
    setViewType(viewType);
    localStorage.setItem(UXPREFERENCES, JSON.stringify({ ...UXPreferences, newsfeedViewType: viewType }));
  };

  const isLoading = isFetching;

  return (
    <Container>
      <Header>
        <h3>{t('newsfeed.header')}</h3>
        <RadioField className="compact">
          <div className="radio-group compact">
            <>
              <input
                type="radio"
                id="everything-view"
                checked={viewType === 'everything'}
                name="everything-view"
                value={'false'}
                onChange={() => handleSetViewType('everything')}
              />
              <label htmlFor="everything-view" className="light" data-tip data-for="everything-view">
                {t('feed.everything')}
              </label>
            </>
            <>
              <input
                type="radio"
                id="watching-view"
                checked={viewType === 'watching'}
                name="watching-view"
                value={'false'}
                onChange={() => handleSetViewType('watching')}
              />
              <label htmlFor="watching-view" className="light" data-tip data-for="watching-view">
                {t('feed.watching')}
              </label>
            </>
            <>
              <input
                type="radio"
                id="following-view"
                checked={viewType === 'following'}
                name="following-view"
                value={'true'}
                onChange={() => handleSetViewType('following')}
              />
              <label htmlFor="following-view" className="light" data-tip data-for="following-view">
                {t('feed.following')}
              </label>
            </>
            <>
              <input
                type="radio"
                id="teams-view"
                checked={viewType === 'teams'}
                name="teams-view"
                value={'true'}
                onChange={() => handleSetViewType('teams')}
              />
              <label htmlFor="teams-view" className="light" data-tip data-for="teams-view">
                {t('feed.teams')}
              </label>
            </>
          </div>
        </RadioField>
      </Header>
      <InboxPanel>
        {isLoading && <KoalaLoader />}
        {!isLoading && (
          <>
            {(events.length === 0 || events[0].data.length === 0) && (
              <EmptyState>
                <img src={PopcornTabby} alt="Popcorn Tabby" />
                <h4>{t('feed.waiting')}</h4>
                {viewType === 'everything' && <p>{t('feed.waitingTipEverything')}</p>}
                {viewType === 'everything' && (
                  <a
                    href="https://guides.tability.app/docs/tutorials/track-progress-with-check-ins"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('shared.learnMore')}
                  </a>
                )}
                {viewType === 'watching' && <p>{t('feed.waitingTipWatching')}</p>}
                {viewType === 'following' && <p>{t('feed.waitingTipFollowing')}</p>}
                {viewType === 'teams' && <p>{t('feed.waitingTipTeams')}</p>}
              </EmptyState>
            )}
            {events.map((group: any, i: number) => {
              return (
                <EventList key={i}>
                  {group.data.map((event: any, index: number) => {
                    if (!event.source) {
                      return null;
                    }

                    if (!supportedEvents.includes(event.event_type)) {
                      return null;
                    }

                    return <NewsItem event={event} key={event.id} />;
                  })}
                </EventList>
              );
            })}
            {canFetchMore && (
              <LoadMore>
                <KoalaButton
                  onClick={() => fetchMore()}
                  loading={!!isFetchingMore}
                  disabled={!!isFetchingMore}
                  appearance="secondary"
                >
                  {t('shared.loadMore')}
                </KoalaButton>
              </LoadMore>
            )}
          </>
        )}
      </InboxPanel>
    </Container>
  );
}

export default Inbox;
