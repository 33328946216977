import React from 'react';
//import theme from 'theme';

// Redux
import { useSelector } from 'react-redux';

import { Draggable, Droppable } from 'react-beautiful-dnd';
import Initiative from './Initiative';
import ItemTitle from './ItemTitle';
import LineGrid, { Label } from './LineGrid';
import OutcomeMetrics from './OutcomeMetrics';

type OutcomeProps = {
  outcomeId: string;
  index: string;
};

export default function Outcome(props: OutcomeProps) {
  const { outcomeId, index } = props;

  const items = useSelector((state: any) => state.ymlEditor.data.outcomeItems[outcomeId]);

  const blockId = `outcomes:${outcomeId}`;
  const selected = useSelector((state: any) => state.ymlEditor.cursor === blockId);
  const className = selected ? 'selected' : '';

  return (
    // @ts-ignore
    <Draggable draggableId={outcomeId} index={index}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <LineGrid className={className}>
              <div>
                <Label className="outcome">#</Label>
              </div>
              <div>
                <ItemTitle itemId={outcomeId} itemType="outcomes" />
              </div>
              <OutcomeMetrics outcomeId={outcomeId} />
            </LineGrid>
            <Droppable droppableId={outcomeId} type="outcomeItems">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((initiativeId: string, index: number) => {
                    // @ts-ignore
                    return <Initiative initiativeId={initiativeId} index={index} key={initiativeId} />;
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        );
      }}
    </Draggable>
  );
}
