import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
// API
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { ChromeContent, ChromeNav } from 'components/Chrome';
import Loader from 'components/Loader';
import styled from 'styled-components';
import NotFound from 'components/NotFound';
import AccessDenied from 'components/AccessDenied';
import { Helmet } from 'react-helmet';
import { shallowEqual, useSelector } from 'react-redux';
import PlanNav from 'components/PlanNav';
import * as planUtils from 'utils/planUtils';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';
import PlanTitle from 'components/PlanTitle';
import PlanIconSelector from 'components/PlanIconSelector';
import ReactTooltip from 'react-tooltip';

import QuillBodyEditor from 'components/QuillBodyEditor';
import MarkdownContent from 'components/MarkdownContent';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 0 auto ${theme.spacing.x6} auto;
  h2 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const ViewMode = styled.div`
  background: ${theme.colors.N0};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};
  padding: ${theme.spacing.x2};

  :hover {
    background: ${theme.colors.N3};
    cursor: text;
  }
  :active,
  :focus {
    background: ${theme.colors.B5};
  }
  .ql-notes {
    img {
      max-width: 100%;
    }
  }
`;
const EditMode = styled.div`
  .ql-notes {
    margin: 0 0 ${theme.spacing.x1} 0;
    background: ${theme.colors.N0};
  }

  label {
    color: #6a6a6a;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
    display: block;
  }
`;

const EditorActions = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

const ContentContainer = styled.div`
  margin: ${theme.spacing.x2} ${theme.spacing.x4} 0 ${theme.spacing.x8};

  @media ${theme.devices.tablet} {
    margin: ${theme.spacing.x1};
  }
`;

const PlanTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

function WorkspacePlanNotes() {
  const { planId } = useParams<{ planId: string }>();
  const { t } = useTranslation();
  const queryKey = [queryKeys.currentPlan, planId, 'notes'];
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const staleTime = 0;
  const [originalText, setOriginalText] = useState('');
  const [currentText, setCurrentText] = useState(originalText);

  const [showEdit, setShowEdit] = useState(false);
  const wrapperRef: any = useRef<HTMLDivElement>();

  useEffect(() => {
    // if in text editor and click outside, will save progress and switch back to view mode
    function handleClickOutside(event: any) {
      if (showEdit && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowEdit(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEdit, wrapperRef, currentText]);

  // Get the plan details
  const {
    isError,
    error,
    data: planResponse,
    isLoading: planIsLoading,
  }: any = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
    onSuccess: (planResponse) => {
      const plan = planResponse.data;

      // Once the plan is loaded, we set the currentText and originalText values.
      setCurrentText(plan.notes);
      setOriginalText(plan.notes);
    },
  });

  // Mutation that will update the plan notes in the backend
  const [updatePlanMutation, { isLoading }] = useMutation(remoteApi.updatePlan, {
    onSuccess: (planResponse) => {
      // Update originalText once saving is confirmed
      setOriginalText(planResponse.data.notes);
    },
  });

  // Function that update the plan notes
  const updatePlanNotes = (newNotes: string) => {
    const planParams = {
      notes: newNotes,
    };
    const mutationParams = {
      planId: planId,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  // We use a debounce function to avoid saving the text on every character change.
  // This function waits 500ms to see if there's another character changed, and a max of 2s before saving.
  const debounceUpdatePlanNotes = useRef(
    _.debounce((newNotes: string) => updatePlanNotes(newNotes), 500, {
      maxWait: 2000,
    }),
  );

  useEffect(() => {
    // Make sure that we don't do useless update if text hasn't changed.
    if (currentText !== originalText) {
      debounceUpdatePlanNotes.current(currentText);
    }
  }, [currentText, originalText]);

  const handleTextClicked = () => {
    setShowEdit(true);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    debounceUpdatePlanNotes.current(currentText);
    setShowEdit(false);
  };

  const plan = planResponse ? planResponse.data : null;

  // We display the loader until we have loaded the plan
  if (!plan || planIsLoading) {
    return (
      <Fragment>
        <ChromeContent>
          <LoadingContainer>
            <Loader size="big" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);

  // Strip HTML from text to see if we have an empty field.
  let doc = new DOMParser().parseFromString(originalText, 'text/html');
  const notesAreEmpty = !originalText || !doc.body.textContent || doc.body.textContent === '';

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspacePlan.notes.title', { title: plan.title })} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader className="no_border">
        <WorkspaceHeader
          workspace={currentWorkspace}
          useGreyBackground={true}
          title={
            <PlanTitleContainer>
              <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
              <ReactTooltip
                place="bottom"
                type="dark"
                id="planIconSelector"
                className="tooltip"
                effect="solid"
                delayShow={200}
              >
                {t('workspacePlan.planIcon')}
              </ReactTooltip>
              <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
            </PlanTitleContainer>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeNav>
        <PlanNav plan={plan} />
      </ChromeNav>
      <ChromeContent style={{ position: 'relative' }} isGreyBackground>
        <ContentContainer>
          <Wrapper>
            <h2> {t('workspacePlan.notes.title', { title: plan.title })}</h2>
            {!showEdit && (
              <ViewMode onClick={handleTextClicked}>
                {!notesAreEmpty && <MarkdownContent source={originalText} />}
                {notesAreEmpty && (
                  <p className="subtle">
                    <em>{t('workspacePlan.notes.placeholder')}</em>
                  </p>
                )}
              </ViewMode>
            )}
            {showEdit && (
              <EditMode ref={wrapperRef}>
                <QuillBodyEditor
                  value={currentText}
                  onChange={(e: string) => {
                    setCurrentText(e);
                  }}
                  disableMentions={true}
                  quillClassName="ql-notes"
                  allowHeaders={true}
                />
                <EditorActions>
                  <KoalaButton onClick={handleSave}>{t('shared.save')}</KoalaButton>
                  {isLoading && <Loader />}
                </EditorActions>
              </EditMode>
            )}
          </Wrapper>
        </ContentContainer>
      </ChromeContent>
    </Fragment>
  );
}

export default React.memo(WorkspacePlanNotes);
