import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

import { useHistory } from 'react-router-dom';
import * as workspaceUtils from 'utils/workspaceUtils';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIcon from 'koala/components/Icons';
import { useTranslation } from 'react-i18next';

const UpgradeBlock = styled.div`
  padding: ${theme.spacing.x2};
  border-radius: 4px;
  background: ${theme.colors.upgradeBg};
  display: flex;
  gap: ${theme.spacing.x2};
  flex-direction: column;
  align-items: center;

  max-width: 60rem;
  margin: 0 auto;

  p {
    text-align: center;
    max-width: 50rem;
  }
`;

const UpgradeTitle = styled.p`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${theme.spacing.x1};
  }
`;

const UpgradeOptions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${theme.devices.tablet} {
    flex-wrap: wrap;
    margin-top: ${theme.spacing.x1};

    a {
      margin-top: ${theme.spacing.x1};
    }
  }
`;

interface Props {
  title: string;
  description: string;
}

function UpgradePanel(props: Props) {
  const dispatch = useDispatch();
  const { title, description } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isOwner = currentMembership.role === 'owner';
  const upgradeLabel = workspaceUtils.hasSubscription(currentWorkspace)
    ? t('shared.upgrade.upgradeWorkspace')
    : t('shared.upgrade.startTrial');

  const handleUpgrade = (e: any) => {
    e.preventDefault();
    const route = `/${currentWorkspace.slug}/settings/billing/plans`;
    history.push(route);
    dispatch(setGlobalModalContent(''));
  };

  if (currentWorkspace.pricing_version >= 2) {
    return (
      <div>
        <UpgradeBlock>
          <UpgradeTitle>
            <KoalaIcon iconName="upgrade" /> <b>{title}</b>
          </UpgradeTitle>
          <div>{description}</div>
          <UpgradeOptions>
            {isOwner && <KoalaButton onClick={handleUpgrade}>{t('shared.upgrade.upgradeButton')}</KoalaButton>}
            {!isOwner && (
              <KoalaTextButton href="https://tability.io/pricing" rel="noopener noreferrer" target="_blank">
                {t('shared.upgrade.learnMore')}
              </KoalaTextButton>
            )}
          </UpgradeOptions>
        </UpgradeBlock>
      </div>
    );
  }

  return (
    <div>
      <UpgradeBlock>
        <UpgradeTitle>
          <KoalaIcon iconName="upgrade" /> <b>{title}</b>
        </UpgradeTitle>
        <div>{description}</div>
        <UpgradeOptions>
          {isOwner && <KoalaButton onClick={handleUpgrade}>{upgradeLabel}</KoalaButton>}
          <KoalaTextButton href="https://tability.io/pricing" rel="noopener noreferrer" target="_blank">
            {t('shared.upgrade.learnMore')}
          </KoalaTextButton>
        </UpgradeOptions>
      </UpgradeBlock>
    </div>
  );
}

export default React.memo(UpgradePanel);
