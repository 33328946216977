import styled from 'styled-components';
import theme from 'theme';
import React, { Fragment } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import queryKeys from 'config/queryKeys';
import parse from 'parse-link-header';
import * as routes from 'routes';

// API
import * as remoteApi from 'api/remote';
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';
import PlanIconLabel from 'components/PlanIconLabel';
import { useTranslation } from 'react-i18next';

const PlanOptionLoader = styled.div`
  display: flex;
  padding: ${theme.spacing.x1} ${theme.spacing.x1};
  align-items: center;
  border-radius: 4px;
`;
const PlanOption = styled(Link)`
  display: flex;
  padding: ${theme.spacing.x1} ${theme.spacing.x1};
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.4rem;
  &.active {
    cursor: initial;
  }
  &:hover,
  &.active {
    background: #f3f3f3;
  }

  &.outcome {
    svg {
      height: 1.4rem;
      min-height: 1.4rem;
      margin-right: ${theme.spacing.x1};
      path {
        stroke: ${theme.colors.blue};
      }
    }
  }

  span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

interface Props {
  handleClosePanel: any;
}

function RecentlyViewed(props: Props) {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const recentVisitsQueryKey = [queryKeys.recentVisits, workspaceSlug];
  const { handleClosePanel } = props;
  const { t } = useTranslation();

  // recently visited
  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  const { isLoadingRecentVisits, data: visitsResponse }: any = useInfiniteQuery(
    recentVisitsQueryKey,
    remoteApi.fetchRecentVisits,
    {
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
    },
  );
  let recentVisits = [];

  if (visitsResponse) {
    recentVisits = visitsResponse;
  }

  return (
    <div>
      <p>{t('panels.search.recent')}</p>
      {isLoadingRecentVisits && (
        <PlanOptionLoader>
          <Loader />
        </PlanOptionLoader>
      )}
      {recentVisits.length > 0 &&
        recentVisits.map((group: any, i: number) => {
          return (
            <Fragment key={i}>
              {group.data.map((visit: any, index: number) => {
                const plan = visit.visited;
                if (!plan) {
                  return null;
                }
                const planRoute = routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
                  ':planId',
                  plan.nano_slug,
                );
                return (
                  <PlanOption key={plan.id} to={planRoute} onClick={handleClosePanel} className="plan">
                    <PlanIconLabel plan={plan} size="medium" crop={40} />
                  </PlanOption>
                );
              })}
            </Fragment>
          );
        })}
    </div>
  );
}

export default RecentlyViewed;
