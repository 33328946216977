import React from 'react';
import styled from 'styled-components';

// Components
import TabilityWordmarkLight from 'components/_assets/tability_logo_black.png';
import TabilityWordmarkDark from 'components/_assets/tability_logo_green.png';

const LogoContainer = styled.div`
  a {
    display: flex;
    position: relative;
    align-items: flex-start;
  }

  img {
    width: 15rem;
    max-width: 15rem;
  }
`;

interface Props {
  type: string;
}

function Wordmark(props: Props) {
  const { type } = props;
  return (
    <LogoContainer>
      <a href="https://www.tability.io" target="_blank" rel="noopener noreferrer">
        {type === 'light' && <img src={TabilityWordmarkLight} alt="Tability" />}
        {type !== 'light' && <img src={TabilityWordmarkDark} alt="Tability" />}
      </a>
    </LogoContainer>
  );
}

export default React.memo(Wordmark);
