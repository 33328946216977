import DropdownMenu from 'components/DropdownMenu';
import MarkdownContent from 'components/MarkdownContent';
import Reactions from 'components/Reactions';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { formatDistanceToNowLocale } from 'utils/dateUtils';
import * as remoteApi from 'api/remote';
import * as TabilityTypes from 'types';
import queryKeys from 'config/queryKeys';
import BlockActions from 'components/BlockActions';
import { EmojiData, Picker } from 'emoji-mart';
import { CustomEmojisList } from 'components/Reactions/CustomEmojis';

import RetrospectiveReplyEdit from './RetrospectiveReplyEdit';

const PickerContainer = styled.div`
  height: 0px;
  width: 0px;
  position: relative;
`;
const Grid = styled.div`
  margin-left: ${theme.spacing.x4};
  padding: 1.2rem ${theme.spacing.x2};
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'avatar meta'
    '. content'
    '. footer';
  gap: ${theme.spacing.x1};
  position: relative;
  .block-actions {
    opacity: 0;
    top: ${theme.spacing.x1};
    right: ${theme.spacing.x1};
    padding: ${theme.spacing.half};
  }

  &:hover {
    background: ${theme.colors.N3};
    .block-actions {
      opacity: 1;
    }
  }
`;

const Avatar = styled.div`
  grid-area: avatar;
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.N70};
`;

const Footer = styled.div`
  grid-area: footer;
  display: flex;
  align-items: center;
  color: ${theme.colors.N70};
  .emoji-picker-inner-container {
    width: 280px;
  }
`;

const Content = styled.div`
  grid-area: content;
  .ql-editor.ql-display-only {
    padding: 0;
  }
`;

const PickerInner = styled.div`
  width: 300px;
  height: fit-content;
  position: absolute;
  left: -270px;
  top: 15px;

  button:hover {
    background: ${theme.colors.N5};
  }
`;
interface Props {
  retrospectiveReply: TabilityTypes.RetrospectiveReply;
  setShowNewReply: (value: boolean) => void;
}

function RetrospectiveReply(props: Props) {
  const { retrospectiveReply, setShowNewReply } = props;
  const { t, i18n } = useTranslation();
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentUserId = useSelector((state: any) => (state.session.currentUser ? state.session.currentUser.id : null));
  const [isEditing, setIsEditing] = useState(false);
  const user = retrospectiveReply.membership ? retrospectiveReply.membership.user : null;
  const currentUserRole = useSelector((state: any) =>
    state.session.currentMembership ? state.session.currentMembership.role : null,
  );
  const queryCache = useQueryCache();
  const emojiRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [reactions, setReactions] = useState<TabilityTypes.CachedReaction[]>([]);
  useEffect(() => {
    if (retrospectiveReply.cached_reactions && typeof retrospectiveReply.cached_reactions !== 'string') {
      setReactions(retrospectiveReply.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [retrospectiveReply]);

  const [deleteRetrospectiveReplytMutation] = useMutation(remoteApi.deleteRetrospectiveReply, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.retrospectiveReplies, retrospectiveReply.retrospective_comment_id]);
    },
  });

  const [addReactionMutation] = useMutation(remoteApi.createRetrospectiveReplyReaction, {
    onSuccess: (response) => {
      queryCache.invalidateQueries([queryKeys.retrospectiveReplies, retrospectiveReply.retrospective_comment_id]);
    },
  });

  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: (response) => {
      queryCache.invalidateQueries([queryKeys.retrospectiveReplies, retrospectiveReply.retrospective_comment_id]);
    },
  });

  let menu_items: any[] = [];
  if (user && currentUserId === user.id) {
    menu_items = [
      <span key="edit" data-action="edit" data-id={retrospectiveReply.id}>
        {t('shared.edit')}
      </span>,
      <span key="delete" data-action="delete" data-id={retrospectiveReply.id}>
        {t('shared.delete')}
      </span>,
    ];
  } else if (currentUserRole === 'owner' || currentUserRole === 'admin') {
    menu_items = [
      <span key="delete" data-action="delete" data-id={retrospectiveReply.id}>
        {t('shared.delete')}
      </span>,
    ];
  }

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    const replyId = value.props['data-id'];
    switch (action) {
      case 'edit':
        setIsEditing(true);
        break;
      case 'delete':
        deleteRetrospectiveReplytMutation(replyId);

        break;
    }
  };

  const handleCreateReply = () => {
    setShowNewReply(true);
  };

  const handleReaction = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const addEmoji = (emojiData: EmojiData | string, e: React.SyntheticEvent) => {
    e.stopPropagation();
    setShowEmojiPicker(false);
    if (typeof emojiData === 'string') {
      addReactionMutation({ retrospectiveReplyId: retrospectiveReply.id, emotion: emojiData });
    } else if (typeof emojiData !== 'string' && emojiData.colons) {
      addReactionMutation({ retrospectiveReplyId: retrospectiveReply.id, emotion: emojiData.colons });
    }
  };
  const userCustomEmojis = currentUser && currentUser.referrals_count ? CustomEmojisList : undefined;

  if (isEditing) {
    return <RetrospectiveReplyEdit retrospectiveReply={retrospectiveReply} setIsEditing={setIsEditing} />;
  }

  return (
    <Grid>
      <Avatar>
        <KoalaAvatar user={user} size={2.4} />
      </Avatar>
      <Meta>
        {retrospectiveReply.membership?.cached_name}
        <div>{formatDistanceToNowLocale(retrospectiveReply.created_at, i18n, true, true)}</div>
      </Meta>
      <Content>
        <MarkdownContent source={retrospectiveReply.body} />
      </Content>
      <Footer>
        <Reactions
          reactions={reactions}
          size="small"
          addCallback={(emotion) =>
            addReactionMutation({ retrospectiveReplyId: retrospectiveReply.id, emotion: emotion })
          }
          removeCallback={(reactionId) => deleteReactionMutation(reactionId)}
          hideAddButton={true}
        />
      </Footer>
      <BlockActions className="block-actions">
        <KoalaIconButton edge="square" iconName="comment" onClick={handleCreateReply} size="small" />
        <KoalaIconButton edge="square" iconName="emoji" onClick={handleReaction} size="small" />
        <PickerContainer>
          <PickerInner ref={emojiRef} onClick={(e) => e.stopPropagation()} className="emoji-picker-inner-container">
            {showEmojiPicker ? (
              <Picker
                set="twitter"
                title="Pick emoji"
                emoji="point_up"
                style={{
                  position: 'relative',
                  zIndex: 99,
                  marginTop: `${theme.spacing.half}`,
                  width: '100%',
                }}
                onClick={addEmoji}
                custom={userCustomEmojis}
              />
            ) : null}
          </PickerInner>
        </PickerContainer>
        <DropdownMenu
          trigger={<KoalaIconButton edge="square" iconName="ellipsis" size="small" />}
          position="right"
          onSelection={handleMenuSelection}
          items={menu_items}
        />
      </BlockActions>
    </Grid>
  );
}

export default React.memo(RetrospectiveReply);
