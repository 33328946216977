import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Plan } from 'types';
import { Emoji, Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import styled from 'styled-components';
import KoalaIconButton from 'koala/components/IconButton';
import { useMutation, useQueryCache } from 'react-query';
import { updatePlan } from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';
import theme from 'theme';
import DropdownMenu from 'components/DropdownMenu';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  button.toggle-emoji {
    position: unset;
  }
  .emoji-mart-anchor,
  .emoji-mart-search-icon {
    display: none;
  }

  .AriaMenuButton-menu {
    position: fixed;
    top: unset;
    right: unset;
  }
`;

const EmojiPicker = styled.div`
  button:hover {
    background: ${theme.colors.N5};
  }
`;
interface Props {
  plan: Plan;
  id: string;
  hasEditPermission: boolean;
}

function PlanIconSelector(props: Props) {
  const { plan, id, hasEditPermission } = props;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const queryCache = useQueryCache();
  const { plan_icon } = plan;
  const queryKeyPlan = [queryKeys.currentPlan, plan.nano_slug];
  const emojiRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const buttonRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Mutation that will update the plan in the backend
  const [updatePlanMutation, { isLoading }]: [any, any] = useMutation(updatePlan, {
    onSuccess: (planResponse) => {
      queryCache.setQueryData(queryKeyPlan, planResponse);
      queryCache.invalidateQueries(queryKeys.currentPlan);
      queryCache.invalidateQueries(queryKeys.plans);
    },
  });

  const updatePlanIcon = useCallback(
    (icon: string | undefined) => {
      const planParams = {
        plan_icon: icon ?? null,
      };
      const mutationParams = {
        planId: plan.nano_slug,
        plan: planParams,
      };
      updatePlanMutation(mutationParams);
    },
    [plan.nano_slug, updatePlanMutation],
  );

  const addEmoji = (emoji: any) => {
    updatePlanIcon(emoji.colons);
    /*setIcon(emoji.colons);*/
    setShowEmojiPicker(false);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  if (isLoading) {
    return <KoalaLoader />;
  }

  const dropdownItems = [
    <span key="change" data-action="change">
      Change
    </span>,
    <span key="unset" data-action="unset">
      Unset
    </span>,
  ];

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'change':
        setShowEmojiPicker(true);
        break;
      case 'unset':
        updatePlanIcon(undefined);
        break;
    }
  };

  if (!hasEditPermission) {
    return (
      <Container>
        {!plan_icon && <KoalaIconButton className="toggle-emoji" iconName="plan" edge="square" />}
        {plan_icon && <Emoji emoji={plan_icon} size={24} set="twitter" />}
      </Container>
    );
  }

  return (
    <Container>
      <div ref={buttonRef}>
        {!plan_icon && (
          <KoalaIconButton
            className="toggle-emoji"
            onClick={toggleEmojiPicker}
            iconName="plan"
            dataFor={id}
            edge="square"
          />
        )}
        {plan_icon && dropdownItems.length > 0 && (
          <DropdownMenu
            trigger={
              <KoalaIconButton dataFor={id} edge="square">
                <Emoji emoji={plan_icon} size={24} set="twitter" />
              </KoalaIconButton>
            }
            items={dropdownItems}
            onSelection={handleMenuSelection}
          />
        )}
      </div>
      <EmojiPicker ref={emojiRef}>
        {showEmojiPicker ? (
          <Picker
            set="twitter"
            title="Pick your emoji..."
            emoji="point_up"
            style={{
              position: 'fixed',
              zIndex: 99,
              marginTop: `${theme.spacing.half}`,
            }}
            onSelect={addEmoji}
          />
        ) : null}
      </EmojiPicker>
    </Container>
  );
}

export default PlanIconSelector;
