import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useQuery, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
// API
import * as remoteApi from 'api/remote';

// Components
import AddPersonIcon from 'components/_assets/AddPersonIcon';
import Loader from 'components/Loader';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  h3 {
    span {
      display: inline-block;
      text-decoration: underline;
    }
  }

  b.subtle {
    display: block;
    margin-bottom: ${theme.spacing.x2};
  }
`;

const Header = styled.div`
  margin-bottom: 5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OwnerLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.x1};
  > div:first-child {
    margin-right: ${theme.spacing.x2};
  }
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background: #f3f3f3;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }

  &.active {
    background: #f3f3f3;
    &:hover {
      cursor: initial;
      background: #f3f3f3;
    }
  }

  &.not-assigned {
    opacity: 0.5;
  }

  &.option-button {
    background: #f3f3f3;
    svg {
      height: 1.8rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
    &:hover {
      cursor: pointer;
      background: #e7e7e7;
    }
  }
`;

const Block = styled.div`
  margin-bottom: ${theme.spacing.x4};
`;

interface Props {
  planId: string;
}

function EditOutcomeOwner(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const queryCache = useQueryCache();

  const { planId } = props;
  const { t } = useTranslation();
  const translationKey = 'workspacePlan.write.manageReviewers';

  const [reviewers, setReviewers]: any = useState([]);

  const reviewersQueryKey = [queryKeys.reviewers, planId];
  const { isFetching: isFetchingReviewers } = useQuery(reviewersQueryKey, remoteApi.fetchPlanReviewers, {
    staleTime: 0,
    onSuccess: (response) => {
      setReviewers(response.data);
    },
  });

  const closePanel = () => {
    history.push(location.pathname);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    closePanel();
  };

  const [addReviewerMutation, { isLoading: isAddingReviewer }]: [any, any] = useMutation(remoteApi.createReviewer, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.reviewers);
    },
  });

  const [deleteReviewerMutation, { isLoading: isDeletingReviewer }]: [any, any] = useMutation(
    remoteApi.deleteReviewer,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.reviewers);
      },
    },
  );

  const handleAddReviewer = (membership_id: any) => {
    if (membership_id) {
      const params = {
        membership_id,
      };

      addReviewerMutation({
        planId,
        reviewer: params,
      });
    }
  };

  const handleDeleteReviewer = (reviewerId: string) => {
    deleteReviewerMutation(reviewerId);
  };

  const isUpdatingReviewers = isAddingReviewer || isDeletingReviewer || isFetchingReviewers;

  return (
    <Container>
      <Header>
        <h3>{t(`${translationKey}.title`)}</h3>
        <KoalaIconButton onClick={handleCancel} iconName="rightArrow" />
      </Header>
      <Block>
        <b className="subtle">
          {t('workspacePlan.write.reviewers')} {isUpdatingReviewers && <Loader size="small" />}
        </b>
        {reviewers.map((reviewer: any, i: number) => {
          const { membership } = reviewer;
          const { user } = membership;
          return (
            <OwnerLine key={i}>
              <OwnerOption className="active" data-reviewer-id={reviewer.id} key={reviewer.id}>
                <KoalaAvatar user={user} size={2.2} />
                <span className="owner-option-name">{user.name}</span>
              </OwnerOption>
              <KoalaTextButton onClick={() => handleDeleteReviewer(reviewer.id)}>{t('shared.remove')}</KoalaTextButton>
            </OwnerLine>
          );
        })}
      </Block>
      <Block>
        <UserSearchableDropdown
          position="left"
          trigger={
            <OwnerOption className="active option-button">
              <AddPersonIcon /> <span className="owner-option-name">{t(`${translationKey}.add`)}</span>
            </OwnerOption>
          }
          callback={handleAddReviewer}
        />
      </Block>
    </Container>
  );
}

export default EditOutcomeOwner;
