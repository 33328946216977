import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatLocale } from 'utils/dateUtils';
import KoalaTextBadge from 'koala/components/TextBadge';
import { BlurDiv } from '.';
import { AuditEvent } from 'types';

function FrequencySection(props: any) {
  const { meta, plan } = props;
  const { t } = useTranslation();

  const remindersPeriod = meta['reminders_period'];
  const remindersInterval = meta['reminders_interval'];

  // get the interval
  const previousInterval = remindersInterval ? remindersInterval[0] : plan.reminders_interval;
  const newInterval = remindersInterval ? remindersInterval[1] : plan.reminders_interval;

  // get the period
  const previousPeriod = remindersPeriod ? remindersPeriod[0] : plan.reminders_period;
  const newPeriod = remindersPeriod ? remindersPeriod[1] : plan.reminders_period;

  // if one of the values is empty (i.e. deleted plan, don't show)
  if (!previousInterval || !newInterval || !previousPeriod || !newPeriod) {
    return <></>;
  }
  // put them together
  const previousValue =
    previousPeriod === 'week'
      ? t('shared.frequencyWeek', { count: previousInterval })
      : t('shared.frequencyMonth', { count: previousInterval });
  // put them together
  const newValue =
    newPeriod === 'week'
      ? t('shared.frequencyWeek', { count: newInterval })
      : t('shared.frequencyMonth', { count: newInterval });

  return (
    <li>
      <Trans
        i18nKey="auditEvents.planRemindersUpdated"
        values={{ previous: previousValue, new: newValue }}
        components={{ b: <b /> }}
      />
    </li>
  );
}

interface Props {
  event: AuditEvent;
  canViewAudit: boolean;
}

function PlanUpdated(props: Props) {
  const { event, canViewAudit } = props;
  const { t, i18n } = useTranslation();

  const { membership_cached_fullname, membership_cached_email, source_cached_title, membership_id, event_meta, plan } =
    event;
  let name = membership_cached_fullname || membership_cached_email;
  if (!membership_id) {
    name += ` ${t('auditEvents.deleted')}`;
  }

  const meta = JSON.parse(event_meta);

  const PlanStatusLabel = (labelProps: any) => {
    const isPublished = labelProps.state === 'published';
    return (
      <KoalaTextBadge isLowercase size="small" variant={isPublished ? 'neutral-light' : 'yellow'}>
        {isPublished ? 'Published' : 'Draft'}
      </KoalaTextBadge>
    );
  };

  return (
    <>
      <span>
        <Trans
          i18nKey="auditEvents.planUpdated"
          values={{ name, title: source_cached_title }}
          components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
        />
      </span>
      <BlurDiv isBlurred={!canViewAudit}>
        {meta['title'] && (
          <li>
            <Trans
              i18nKey="auditEvents.titleUpdated"
              values={{ previous: meta['title'][0], new: meta['title'][1] }}
              components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
            />
          </li>
        )}
        {meta['start_at'] && (
          <li>
            <Trans
              i18nKey="auditEvents.planStartUpdated"
              values={{
                previous: meta['start_at'][0] ? formatLocale(meta['start_at'][0], 'dd MMM yyyy', i18n) : '-',
                new: meta['start_at'][1] ? formatLocale(meta['start_at'][1], 'dd MMM yyyy', i18n) : '-',
              }}
              components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
            />
          </li>
        )}
        {meta['finish_at'] && (
          <li>
            <Trans
              i18nKey="auditEvents.planFinishUpdated"
              values={{
                previous: meta['finish_at'][0] ? formatLocale(meta['finish_at'][0], 'dd MMM yyyy', i18n) : '-',
                new: meta['finish_at'][1] ? formatLocale(meta['finish_at'][1], 'dd MMM yyyy', i18n) : '-',
              }}
              components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
            />
          </li>
        )}
        {meta['state'] && (
          <li>
            <Trans
              i18nKey="auditEvents.planStateUpdated"
              components={{
                previous: <PlanStatusLabel state={meta['state'][0]} />,
                new: <PlanStatusLabel state={meta['state'][1]} />,
                blur: <BlurDiv isBlurred={!canViewAudit} />,
              }}
            />
          </li>
        )}
        {(meta['reminders_period'] || meta['reminders_interval']) && <FrequencySection meta={meta} plan={plan} />}

        {meta['public_access_enabled'] && (
          <li>
            <Trans
              i18nKey={
                meta['public_access_enabled'][1]
                  ? 'auditEvents.planPublicStatusEnabled'
                  : 'auditEvents.planPublicStatusDisabled'
              }
              components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
            />
          </li>
        )}
      </BlurDiv>
    </>
  );
}

export default React.memo(PlanUpdated);
