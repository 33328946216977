import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as remoteApi from 'api/remote';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Initiative, Objective, Outcome, Plan } from 'types';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import PlanIconLabel from 'components/PlanIconLabel';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  initiativeId: string;
}

function MoveInitiative(props: Props) {
  const { initiativeId } = props;
  const dispatch = useDispatch();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.moveInitiative';

  const [initiative, setInitiative] = useState<Initiative>();
  const [outcome, setOutcome] = useState<Outcome>();
  const [outcomeList, setOutcomeList] = useState<Outcome[]>([]);
  const [objective, setObjective] = useState<Objective>();
  const [objectiveList, setObjectiveList] = useState<Objective[]>([]);
  const [plan, setPlan] = useState<Plan>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // when plan changes, load objectives
  useEffect(() => {
    if (plan) {
      remoteApi.fetchPlanObjectives(queryKeys.objectives, plan.id).then((response) => {
        const objectives = response.data;
        setObjectiveList(objectives);
      });
    } else {
      setObjectiveList([]);
    }
  }, [plan]);

  // when objective changes, load outcomes
  useEffect(() => {
    if (objective) {
      remoteApi.fetchOutcomes(queryKeys.outcomes, objective.id).then((response) => {
        const outcomes = response.data;
        setOutcomeList(outcomes);
      });
    } else {
      setOutcomeList([]);
    }
  }, [objective]);

  const [updateInitiativeMutation, { isLoading: isUpdating }]: [any, any] = useMutation(remoteApi.updateInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentInitiative);
      queryCache.invalidateQueries(queryKeys.initiatives);
    },
  });

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  // Get the task details
  const queryKey = [queryKeys.currentInitiative, initiativeId];
  const staleTime = 0;
  useQuery(queryKey, remoteApi.fetchInitiativeDetails, {
    staleTime,
    onSuccess: (response: any) => {
      const initiative: Initiative = response.data;
      setInitiative(initiative);
      setOutcome(initiative.outcome);
      setObjective(initiative.outcome.objective);
      setPlan(initiative.plan);
    },
  });

  const handleSave = () => {
    const outcome_id = outcome?.id;

    const params = {
      outcome_id,
    };
    updateInitiativeMutation({
      initiativeId: initiativeId,
      initiative: params,
    });
    dispatch(setGlobalModalContent(''));
  };

  if (!initiative) {
    return null;
  }

  const handleLoadPlans = (input: string) => {
    let planParams;
    if (input) {
      planParams = {
        filter: {
          title: input,
        },
        order_direction: 'asc',
        order_attribute: 'title',
        limit: 5,
      };
    } else {
      planParams = {
        order_direction: 'asc',
        order_attribute: 'title',
        limit: 5,
      };
    }
    return remoteApi.fetchPlans(queryKeys.plans, workspaceSlug, planParams, 0).then((response) => {
      const plans = response.data;
      return plans;
    });
  };

  const handlePlanSelection = (selectedPlan: any) => {
    setPlan(selectedPlan);
    setObjective(undefined);
    setOutcome(undefined);
  };
  const handleObjectiveSelection = (selectedObjective: any) => {
    setObjective(selectedObjective);
    setOutcome(undefined);
  };

  const handleOutcomeSelection = (selectedOutcome: any) => {
    setOutcome(selectedOutcome);
  };

  const allowSave = plan && objective && outcome && outcome.id !== initiative.outcome_id && !isUpdating;

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`, { label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1) })}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <FormField>
          <Trans
            i18nKey={`${translationKey}.description`}
            components={{ b: <b /> }}
            values={{ title: initiative?.title }}
          />
        </FormField>
        <FormField>
          <label>{t(`${translationKey}.plan`)}</label>
          <AsyncSelect
            defaultOptions
            isClearable
            value={plan}
            loadOptions={handleLoadPlans}
            onChange={(option) => handlePlanSelection(option)}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            formatOptionLabel={(option) => <PlanIconLabel plan={option} size="small" />}
            placeholder={t('shared.selectPlaceholder')}
          />
        </FormField>
        {plan && (
          <FormField>
            <label>{translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}</label>
            {objectiveList.length > 0 && (
              <Select
                defaultOptions
                isClearable
                placeholder={t('shared.selectPlaceholder')}
                value={objective ? objective : null}
                options={objectiveList}
                onChange={(option) => handleObjectiveSelection(option)}
                getOptionLabel={(option) => option.title}
                getOptionValue={(option) => option.id}
              />
            )}
            {objectiveList.length === 0 && (
              <>
                {t(`${translationKey}.noObjective`, {
                  label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 2).toLowerCase(),
                })}
              </>
            )}
          </FormField>
        )}

        {objective && (
          <FormField>
            <label>{translate(currentWorkspace, CustomTermKey.OUTCOME, 1)}</label>
            {outcomeList.length > 0 && (
              <Select
                defaultOptions
                isClearable
                placeholder={t('shared.selectPlaceholder')}
                value={outcome ? outcome : null}
                options={outcomeList}
                onChange={(option) => handleOutcomeSelection(option)}
                getOptionLabel={(option) => option.title}
                getOptionValue={(option) => option.id}
              />
            )}
            {outcomeList.length === 0 && (
              <>
                {t(`${translationKey}.noOutcomes`, {
                  obj: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
                  outs: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                })}
              </>
            )}
          </FormField>
        )}
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!allowSave} loading={isUpdating}>
          {t('shared.save')}
        </KoalaButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default MoveInitiative;
