import FormField from 'components/FormField';
import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Outcome } from 'types';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import styled from 'styled-components';
const ErrorContainer = styled.small`
  color: #d0402e;
`;
interface CurrentValueProps {
  outcome: Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcome.id, `data_source:asana:${outcome.data_source_type}`, 'current'];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null || currentValue === undefined) {
    return <FormField>Sorry, an error occurred while fetching the current value.</FormField>;
  }

  const dataTypeToLabel: any = {
    issues_count: '<number> issues',
    issues_closed: '<number> issues closed',
  };

  const label = dataTypeToLabel[outcome.data_source_type || ''];
  const labelWithNumber = label.replace('<number>', currentValue.toLocaleString());
  return (
    <FormField>
      <label>Preview value</label>
      <p>{labelWithNumber}</p>
    </FormField>
  );
}

interface Props {
  outcome: Outcome;
  workspaceSlug: string;
}

// Component that loads the current value of from the data source
function AsanaSource(props: Props) {
  const { outcome, workspaceSlug } = props;
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');
  const [project, setProject] = useState<string>(outcome.data_source_meta ? outcome.data_source_meta.project_id : '');

  const [projectOptions, setProjectOptions] = useState<KoalaSelectOption[]>([]);
  const [projectError, setProjectErrors] = useState('');
  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const staleTime = 0;
  const queryKeyAsana = ['asana_projects', workspaceSlug];

  const { isLoading } = useQuery(queryKeyAsana, remoteApi.listAsanaProjects, {
    staleTime,
    onSuccess: (response) => {
      if (!response.data?.projects) {
        setProjectOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.projects.map((project: any) => ({
        label: project.name,
        value: project.gid,
      }));
      setProjectOptions(options);
    },
    onError: (error: any) => {
      const errorString = error.response.data.error;
      setProjectErrors(errorString);
    },
  });

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'asana',
      data_source_type: data_source_type,
      data_source_meta: {
        project_id: project,
      },
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const availableDataSourceTypes = ['issues_count', 'issues_closed'];

  const onProjectChange = (option: any) => {
    const value = option ? option.value : null;
    setProject(value);
  };

  const selectedOption: any = projectOptions.find((option) => option.value === project);

  const hasChanged = outcome.data_source_type !== data_source_type || outcome.data_source_meta.project_id !== project;
  return (
    <>
      <FormField>
        <label>Change Metric type</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">Select a metric</option>
          <option value="issues_count">Number of issues</option>
          <option value="issues_closed">Number of issues closed</option>
        </select>
      </FormField>
      <FormField>
        <label>
          Select Project
          {isLoading && <KoalaLoader />}
        </label>
        {!isLoading && (
          <div style={{ width: '50%' }}>
            <KoalaSelect
              handleChange={(options) => onProjectChange(options)}
              placeholder="Select Asana project"
              selectedOption={selectedOption}
              // selectedOption={getSelectedProject}
              options={projectOptions}
              className="small"
            />
            {projectError && <ErrorContainer>{projectError}</ErrorContainer>}
          </div>
        )}
      </FormField>
      <FormField>
        <b>Asana metrics require a project to be selected.</b>
      </FormField>
      {hasChanged && (
        <FormField>
          <KoalaButton
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={!data_source_type || isUpdating || !project}
            appearance="subtle"
          >
            Save & preview
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'asana' &&
        outcome.data_source_type &&
        outcome.data_source_type === data_source_type && (
          <>{availableDataSourceTypes.includes(outcome.data_source_type) && <CurrentValue outcome={outcome} />}</>
        )}
    </>
  );
}

export default React.memo(AsanaSource);
