import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useLocation, useHistory } from 'react-router-dom';
import { parseISO } from 'date-fns';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import { PanelGrid, PanelHeader, PanelContent, PanelActions } from 'components/GlobalPanel';
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import QuillBodyEditor from 'components/QuillBodyEditor';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import TagPickerInitiative from 'components/TagPickerInitiative';

import InitiativeContributors from './InitiativeContributors';

import 'theme/DatePicker.css';
import DatePicker from 'react-datepicker';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaAvatar from 'koala/components/Avatar';
import { Trans, useTranslation } from 'react-i18next';
import { CONTEXT_STORAGE_KEY } from 'config/constants';
import _ from 'lodash';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const AddPersonContainer = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    g {
      stroke: ${theme.colors.subtleText};
    }
  }
`;

const OwnerLine = styled.div`
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: ${theme.spacing.x2};
  }
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;
  background: #f3f3f3;

  &:hover {
    cursor: pointer;
    background: #e7e7e7;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }
`;

const UpgradeOption = styled.p`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${theme.spacing.x1};
  }
`;

interface Props {
  initiativeId: string;
}

function EditInitiativePanel(props: Props) {
  const { initiativeId } = props;
  const location = useLocation();
  const history = useHistory();
  const queryCache = useQueryCache();
  const inputEl: any = useRef(null);
  const { t } = useTranslation();
  const translationKey = 'panels.initiative';
  const storageKey = `${CONTEXT_STORAGE_KEY}_initiative_description_${initiativeId}`;

  const currentMembership: TabilityTypes.Membership = useSelector(
    (state: any) => state.session.currentMembership,
    shallowEqual,
  );
  const currentUser: TabilityTypes.User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Check if the workspace has the right subscription
  const hasStarterSubscription =
    workspaceUtils.hasStarterSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  // Adds the current user to the membership to be able to display the user in the panel
  currentMembership.user = currentUser;

  // Params that will be used for the form
  const [title, setTitle] = useState('');
  const [dueAt, setDueAt]: any = useState(null);
  const [body, setBody] = useState('');
  const [membership, setMembership]: [any, any] = useState(null);

  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.focus();
    }
  }, [inputEl]);

  useEffect(() => {
    debounceStoreText.current(body);
  }, [body]);

  const storeText = (newText: string) => {
    if (newText) {
      sessionStorage.setItem(storageKey, newText);
    }
  };

  const debounceStoreText = useRef(
    _.debounce((newText: string) => storeText(newText), 1000, {
      maxWait: 5000,
    }),
  );

  // React to the ESC key to hide the panel
  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.goBack();
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  // Query keys and query params
  const queryKey = [queryKeys.currentInitiative, initiativeId];
  const staleTime = 0;

  // Functions to update the initiative
  const [updateInitiativeMutation, { isLoading: isUpdating }]: [any, any] = useMutation(remoteApi.updateInitiative, {
    onSuccess: (_, variables) => {
      sessionStorage.removeItem(storageKey);
      queryCache.invalidateQueries(queryKeys.initiatives);
      queryCache.invalidateQueries(queryKeys.currentInitiative);

      if (variables.dontGoBack) {
        // Don't close the panel if dontGoBack set to true
        return;
      }
      history.goBack();
    },
  });

  // Get the outcome details
  const { data: initiativeResponse } = useQuery(queryKey, remoteApi.fetchInitiativeDetails, {
    staleTime,
    onSuccess: (response) => {
      // Set the params on success
      const initiative = response.data;
      setTitle(initiative.title);
      setMembership(initiative.membership);
      const _dueAt: any = initiative.due_at ? parseISO(initiative.due_at) : null;
      setDueAt(_dueAt);
      const _body = sessionStorage.getItem(storageKey) ?? initiative.body;
      setBody(_body ?? '');
    },
  });

  const initiative = initiativeResponse ? initiativeResponse.data : null;

  const handleTitleChange = (e: any) => {
    e.preventDefault();
    const title = e.target.value;
    setTitle(title);
  };

  const handleChangeDueAt = (date: any) => {
    setDueAt(date);
  };

  // END SEARCH LOGIC

  const handleAssignOwner = (membership_id: string) => {
    const params = {
      membership_id,
    };

    updateInitiativeMutation({
      initiativeId,
      initiative: params,
      dontGoBack: true, // Don't close the panel after changing the owner.
    });
  };

  const handleRemoveOwner = (e: any) => {
    e.preventDefault();
    const params = {
      membership_id: null,
    };

    updateInitiativeMutation({
      initiativeId,
      initiative: params,
      dontGoBack: true, // Don't close the panel after changing the owner.
    });
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    const due_at = dueAt ? dueAt.toISOString() : null;
    const params = {
      title,
      due_at,
      body,
    };

    updateInitiativeMutation({
      initiativeId,
      initiative: params,
    });
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    history.goBack();
  };

  // Now we can display the app with the Chrome
  if (!initiative) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  if (initiative.integration_type === 'jira_cloud' && initiative.integration_remote_id) {
    return (
      <PanelGrid>
        <PanelHeader>
          <Header>
            <h2>
              {t(`${translationKey}.editTitle`, { label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1) })}
            </h2>
            <KoalaIconButton onClick={handleCancel} iconName="close" />
          </Header>
        </PanelHeader>
        <PanelContent>
          <p>
            {t(`${translationKey}.jira`, {
              label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
            })}
          </p>
          <p>
            <Trans
              i18nKey={`${translationKey}.integrationLink`}
              values={{
                title: initiative.title,
                label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
              }}
              components={{
                a: (
                  <a
                    href={initiative.title}
                    style={{ color: theme.colors.blue }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    text
                  </a>
                ),
              }}
            />
          </p>
        </PanelContent>
      </PanelGrid>
    );
  }

  const label = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();
  const descriptionPlaceholder =
    t(`${translationKey}.descriptionPlaceholder`, { label: label }) ?? `About this ${label}`;
  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>
            {t(`${translationKey}.editTitle`, { label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1) })}
          </h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        <form onSubmit={handleSave}>
          <FormField>
            <label>{t(`${translationKey}.title`)}</label>
            <input
              type="text"
              value={title}
              ref={inputEl}
              onChange={handleTitleChange}
              placeholder={t(`${translationKey}.titlePlaceholder`) ?? 'What can help you achieve this goal?'}
            />
          </FormField>
          <FormField>
            <label>{t(`${translationKey}.owner`)}</label>
            <OwnerLine>
              <UserSearchableDropdown
                trigger={
                  <OwnerOption className="active not-assigned">
                    <AddPersonContainer>
                      <KoalaAvatar user={membership ? membership.user : null} size={2.4} />
                    </AddPersonContainer>
                    <span className="owner-option-name">
                      {membership ? membership.user.fullname || membership.user.email : t('shared.notAssigned')}
                    </span>
                  </OwnerOption>
                }
                callback={handleAssignOwner}
              />
              {membership && (
                <KoalaButton onClick={handleRemoveOwner} type="button" appearance="subtle">
                  {t('shared.userSelect.unassign')}
                </KoalaButton>
              )}
            </OwnerLine>
          </FormField>
          <InitiativeContributors initiativeId={initiative.id} />
          {hasStarterSubscription && (
            <FormField>
              <label>{t(`${translationKey}.dueDate`)}</label>
              <DatePicker selected={dueAt} onChange={handleChangeDueAt} isClearable dateFormat="d MMM yyyy" />
            </FormField>
          )}
          {!hasStarterSubscription && (
            <FormField>
              <label>{t(`${translationKey}.dueDate`)}</label>
              <UpgradeOption>
                <UpgradeIcon />
                <span>{t(`${translationKey}.dueDateUnavailable`)}</span>
              </UpgradeOption>
            </FormField>
          )}
          <FormField>
            <TagPickerInitiative initiative={initiative} />
          </FormField>
          <FormField>
            <label>{t(`${translationKey}.description`)}</label>
            <QuillBodyEditor
              value={body}
              onChange={setBody}
              placeholder={descriptionPlaceholder}
              disableMentions={true}
              quillClassName="ql-notes"
            />
          </FormField>
        </form>
      </PanelContent>
      <PanelActions>
        <KoalaButton submit onClick={handleSave} disabled={isUpdating} loading={isUpdating}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </PanelActions>
    </PanelGrid>
  );
}

export default React.memo(EditInitiativePanel);
