import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9999 15.0001C19.9999 16.2273 19.0049 17.2223 17.7777 17.2223H13.3333V2.77783H17.7777C19.0049 2.77783 19.9999 3.77283 19.9999 5.00005V15.0001Z"
      fill="#ED2939"
    />
    <path
      d="M2.22222 2.77783C0.995 2.77783 0 3.77283 0 5.00005V15.0001C0 16.2273 0.995 17.2223 2.22222 17.2223H6.66667V2.77783H2.22222Z"
      fill="#002495"
    />
    <path d="M6.66675 2.77783H13.3334V17.2223H6.66675V2.77783Z" fill="white" />
  </svg>
);
