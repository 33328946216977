/**
  This is the playground editor. It loads the state from local storage and saves there too
 */

import React from 'react';
import styled from 'styled-components';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import theme from 'theme';
import { Base64 } from 'js-base64';
import { shallowEqual, useSelector } from 'react-redux';
import * as routes from 'routes';

import { useParams } from 'react-router-dom';

// Components
import KoalaTabs from 'koala/components/Tabs';
import KoalaIcon from 'koala/components/Icons';
const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 5;
  margin: 0;
  padding: 0 ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.N10};
  background: #fff;
`;

function ListingNav() {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // get the encoded default filters
  const objectiveDefaultFilters = Base64.encode(
    JSON.stringify({
      plan_status: 'is_in_progress',
      sorting: 'plan',
    }),
  );

  const outcomeDefaultFilters = Base64.encode(
    JSON.stringify({
      plan_status: 'is_in_progress',
      sorting: 'plan',
    }),
  );
  const initiativeDefaultFilters = Base64.encode(
    JSON.stringify({
      plan_status: 'is_in_progress',
      sorting: 'plan',
    }),
  );

  return (
    <Header>
      <KoalaTabs
        tabs={[
          {
            key: 'objectives',
            label: (
              <>
                <KoalaIcon iconName="flag" /> {translate(currentWorkspace, CustomTermKey.OBJECTIVE, 2)}
              </>
            ),
            link: routes.WORKSPACE_OBJECTIVES_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
              ':filter',
              objectiveDefaultFilters,
            ),
          },
          {
            key: 'outcomes',
            label: (
              <>
                <KoalaIcon iconName="metric" iconSize={1.8} /> {translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}
              </>
            ),
            link: routes.WORKSPACE_OUTCOMES_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
              ':filter',
              outcomeDefaultFilters,
            ),
          },
          {
            key: 'initiatives',
            label: (
              <>
                <KoalaIcon iconName="task" /> {translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}
              </>
            ),
            link: routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
              ':filter',
              initiativeDefaultFilters,
            ),
          },
        ]}
      />
    </Header>
  );
}

export default ListingNav;
