import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

// API
import * as remoteApi from 'api/remote';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Routes
import * as routes from 'routes';

// Components
import Loader from 'components/Loader';
import { ModalGrid, ModalHeader, ModalContent, ModalFooter } from 'components/GlobalModal';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import { Trans, useTranslation } from 'react-i18next';

const Form = styled.form``;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  .form-label {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${theme.colors.subtleText};
    margin-bottom: ${theme.spacing.x1};
  }

  margin: ${theme.spacing.x3} 0;
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

interface Props {
  planId: string;
}

function DeletePlan(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { planId } = props;
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.deletePlan';

  // Get the plan details
  const { data: planResponse }: any = useQuery([queryKeys.currentPlan, planId], remoteApi.fetchPlanDetails, {
    staleTime: 0,
  });

  // Plan mutations
  const [deletePlanMutation, { isLoading }]: [any, any] = useMutation(remoteApi.deletePlan, {
    onSuccess: () => {
      dispatch(setGlobalModalContent(''));
      queryCache.invalidateQueries(queryKeys.plans);
      queryCache.invalidateQueries([queryKeys.currentPlan, planId]);
      queryCache.invalidateQueries([queryKeys.currentWorkspace]);
      history.push(routes.WORKSPACE_PLANS_ROUTE.replace(':workspaceSlug', workspaceSlug));
    },
  });

  // Form values
  const [confirmText, setConfirmText] = useState('');

  const plan = planResponse ? planResponse.data : null;

  // Now we can display the app with the Chrome
  if (!plan) {
    return (
      <LoadingContainer>
        <Loader size="medium" />
      </LoadingContainer>
    );
  }

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleChange = (e: any) => {
    setConfirmText(e.target.value);
  };

  const deleteText = t(`${translationKey}.deleteText`) ?? 'DELETE';
  const isDisabled = confirmText !== deleteText || isLoading;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!isDisabled) {
      deletePlanMutation(planId);
    }
  };

  return (
    <ModalGrid>
      <ModalHeader>
        {plan.is_dummy && <h2>{t(`${translationKey}.titleSample`)}</h2>}
        {!plan.is_dummy && <h2>{t(`${translationKey}.title`)}</h2>}
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleSubmit}>
          {plan.is_dummy && (
            <p>
              <Trans
                i18nKey={`${translationKey}.labelSample`}
                components={{ b: <b /> }}
                values={{ title: plan.title }}
              />
            </p>
          )}
          {!plan.is_dummy && (
            <>
              <p>
                <Trans i18nKey={`${translationKey}.label`} components={{ b: <b /> }} values={{ title: plan.title }} />
              </p>
              <p>{t(`${translationKey}.label2`)}</p>
            </>
          )}

          <FormField>
            <div className="form-label">{t(`${translationKey}.confirm`)}</div>
            <input
              onChange={handleChange}
              value={confirmText}
              className="small"
              placeholder={t(`${translationKey}.typeDelete`, { text: deleteText }) ?? `type "${deleteText}" to confirm`}
            />
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton disabled={isDisabled} loading={isLoading} onClick={handleSubmit}>
          {t('shared.delete')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default DeletePlan;
