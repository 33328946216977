import React from 'react';

import DependenciesSource from 'modals/DataConnectors/DependenciesSource';
import ChartMogulSource from 'modals/DataConnectors/ChartMogulSource';
import FavroSource from 'modals/DataConnectors/FavroSource';
import HubspotSource from 'modals/DataConnectors/HubspotSource';
import GoogleSheetsSource from 'modals/DataConnectors/GoogleSheetsSource';
import JiraCloudSource from 'modals/DataConnectors/JiraCloudSource';
import LinkSource from 'modals/DataConnectors/LinkSource';
import ClickupSource from 'modals/DataConnectors/ClickupSource';
import AsanaSource from 'modals/DataConnectors/AsanaSource';
import AmplitudeSource from 'modals/DataConnectors/AmplitudeSource';
import ZendeskSalesSource from 'modals/DataConnectors/ZendeskSalesSource';
import GithubSource from 'modals/DataConnectors/GithubSource';
import ZendeskSupportSource from 'modals/DataConnectors/ZendeskSupportSource';
import HelpScoutSource from 'modals/DataConnectors/HelpScoutSource';
import GitlabSource from 'modals/DataConnectors/GitlabSource';
import PostgresqlSource from 'modals/DataConnectors/PostgresqlSource';
import MysqlSource from 'modals/DataConnectors/MysqlSource';
import MariaDBSource from 'modals/DataConnectors/MariaDBSource';
import TableauSource from 'modals/DataConnectors/TableauSource';
import { Outcome } from 'types';
import TrelloSource from 'modals/DataConnectors/TrelloSource';
import PowerBISource from 'modals/DataConnectors/PowerBISource';
import MondaySource from 'modals/DataConnectors/MondaySource';
import ChargebeeSource from 'modals/DataConnectors/ChargebeeSource';
import BigQuerySource from 'modals/DataConnectors/BigQuerySource';
import SalesforceSource from 'modals/DataConnectors/SalesforceSource';
import PagerDutySource from 'modals/DataConnectors/PagerDutySource';
import SentrySource from 'modals/DataConnectors/SentrySource';

interface Props {
  outcome: Outcome;
  setScoreChanges: (value: string) => void;
}

function DataSourceLabel(props: Props) {
  const { outcome, setScoreChanges } = props;

  return (
    <>
      {outcome.data_source_origin === 'dependencies' && (
        <DependenciesSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'chartmogul' && (
        <ChartMogulSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'favro' && <FavroSource outcome={outcome} setScoreChanges={setScoreChanges} />}
      {outcome.data_source_origin === 'hubspot' && (
        <HubspotSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'jira_cloud' && (
        <JiraCloudSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'postgresql' && (
        <PostgresqlSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'power_bi' && (
        <PowerBISource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'mysql' && <MysqlSource outcome={outcome} setScoreChanges={setScoreChanges} />}
      {outcome.data_source_origin === 'mariadb' && (
        <MariaDBSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'tableau' && (
        <TableauSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'google_sheets' && (
        <GoogleSheetsSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'clickup' && (
        <ClickupSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'gitlab' && <GitlabSource outcome={outcome} setScoreChanges={setScoreChanges} />}
      {outcome.data_source_origin === 'trello' && <TrelloSource outcome={outcome} setScoreChanges={setScoreChanges} />}
      {outcome.data_source_origin === 'asana' && <AsanaSource outcome={outcome} setScoreChanges={setScoreChanges} />}
      {outcome.data_source_origin === 'chargebee' && (
        <ChargebeeSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'monday' && <MondaySource outcome={outcome} setScoreChanges={setScoreChanges} />}
      {outcome.data_source_origin === 'amplitude' && (
        <AmplitudeSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'zendesk_support' && (
        <ZendeskSupportSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'zendesk_sales' && (
        <ZendeskSalesSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'github' && <GithubSource outcome={outcome} setScoreChanges={setScoreChanges} />}
      {outcome.data_source_origin === 'helpscout' && (
        <HelpScoutSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'link' && <LinkSource outcome={outcome} />}
      {outcome.data_source_origin === 'big_query' && (
        <BigQuerySource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'salesforce' && (
        <SalesforceSource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'pagerduty' && (
        <PagerDutySource outcome={outcome} setScoreChanges={setScoreChanges} />
      )}
      {outcome.data_source_origin === 'sentry' && <SentrySource outcome={outcome} setScoreChanges={setScoreChanges} />}
    </>
  );
}

export default DataSourceLabel;
