import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  display: flex;
  justify-content: flex-end;

  .icon {
    height: 2rem;
    width: 2rem;

    background-color: transparent;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    svg {
      width: 1.6rem;
      height: 1.6rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
  }

  .value {
    margin-left: 0.4rem;
    width: 2rem;
    cursor: pointer;
  }

  &:hover {
    color: ${theme.colors.N80};
    .icon {
      background: #d1d1d1;
      svg {
        width: 1.6rem;
        height: 1.6rem;
        g {
          stroke: ${theme.colors.N80};
        }
      }
    }
  }
`;
