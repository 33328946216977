import React, { useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import styled from 'styled-components';
import theme from 'theme';

const ErrorContainer = styled.p`
  color: ${theme.colors.red};
`;

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcome.id, `data_source:sentry:${outcome.data_source_type}`, 'current'];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null || currentValue === undefined) {
    return <FormField>{t(`panels.editOutcome.errorFetching`)}</FormField>;
  }
  const sentryKey = `modals.dataConnectors.sentryData`;

  const dataTypeToLabel: any = {
    unresolved_issues: t(`${sentryKey}.unresolved_issues_label`),
    resolve_time: t(`${sentryKey}.resolve_time_label`),
    resolved_issues: t(`${sentryKey}.resolved_issues_label`),
    new_issues_weekly: t(`${sentryKey}.new_issues_weekly_label`),
    last_seen_issues: t(`${sentryKey}.last_seen_issues_label`),
  };

  const label = dataTypeToLabel[outcome.data_source_type || ''];
  const labelWithNumber = label.replace('<number>', currentValue.toLocaleString());
  return (
    <FormField>
      <label>{t('modals.dataConnectors.preview')}</label>
      <p>{labelWithNumber}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  workspaceSlug: string;
}

function SentrySource(props: Props) {
  const { outcome, workspaceSlug } = props;
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');
  const [projectOptions, setProjectOptions] = useState<KoalaSelectOption[]>([]);
  const [project, setProject] = useState<string>(outcome.data_source_meta ? outcome.data_source_meta.project_id : '');
  const [projectError, setProjectError] = useState('');
  const { t } = useTranslation();
  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }]: [any, any] = useMutation(
    remoteApi.updateOutcomeDataSource,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.currentOutcome);
      },
    },
  );

  const staleTime = 0;
  const queryKeyProjects = ['sentry_projects', workspaceSlug];
  const { isLoading } = useQuery(queryKeyProjects, remoteApi.listSentryProjects, {
    staleTime,
    onSuccess: (response) => {
      if (!response.data?.projects) {
        setProjectOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.projects.map((project: any) => ({
        label: project.name,
        value: project.id,
      }));
      setProjectOptions(options);
    },
    onError: (error: any) => {
      const errorString = error.response.data.error;
      setProjectError(errorString);
    },
  });

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'sentry',
      data_source_type: data_source_type,
      data_source_meta: {
        project_id: project,
      },
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const onProjectChange = (option: any) => {
    const value = option ? option.value : null;
    setProject(value);
  };

  const selectedOption: any = projectOptions.find((option) => option.value === project);

  const availableDataSourceTypes = [
    'unresolved_issues',
    'resolve_time',
    'resolved_issues',
    'new_issues_weekly',
    'last_seen_issues',
  ];
  const connectorKey = `modals.dataConnectors`;
  const sentryKey = `modals.dataConnectors.sentryData`;
  return (
    <>
      <FormField>
        <label>
          {t(`${sentryKey}.project`)}
          {isLoading && <KoalaLoader />}
        </label>
        {!isLoading && (
          <div style={{ width: '50%' }}>
            <KoalaSelect
              handleChange={(options) => onProjectChange(options)}
              placeholder={t(`${sentryKey}.project`) ?? 'Select project'}
              selectedOption={selectedOption}
              // selectedOption={getSelectedProject}
              options={projectOptions}
              className="small"
            />
            {projectError && <ErrorContainer>{projectError}</ErrorContainer>}
          </div>
        )}
      </FormField>
      <FormField>
        <label>{t(`${connectorKey}.changeMetric`)}</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">{t(`${connectorKey}.selectMetric`)}</option>
          <option value="unresolved_issues">{t(`${sentryKey}.unresolved_issues`)}</option>
          <option value="resolve_time">{t(`${sentryKey}.resolve_time`)}</option>
          <option value="resolved_issues">{t(`${sentryKey}.resolved_issues`)}</option>
          <option value="new_issues_weekly">{t(`${sentryKey}.new_issues_weekly`)}</option>
          <option value="last_seen_issues">{t(`${sentryKey}.last_seen_issues`)}</option>
        </select>
      </FormField>
      {outcome.data_source_type !== data_source_type && (
        <FormField>
          <KoalaButton
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={!data_source_type || isUpdating}
            appearance="subtle"
          >
            {t(`panels.editOutcome.savePreview`)}
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'sentry' &&
        outcome.data_source_type &&
        outcome.data_source_type === data_source_type && (
          <>{availableDataSourceTypes.includes(outcome.data_source_type) && <CurrentValue outcome={outcome} />}</>
        )}
    </>
  );
}

export default React.memo(SentrySource);
