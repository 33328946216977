import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { setHasSyncError } from 'state/actions/workspaceEditorActions';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { useTranslation } from 'react-i18next';

const Mask = styled.div<{ showModal: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: ${(props) => (props.showModal ? '50' : '-1')};
  opacity: ${(props) => (props.showModal ? '1' : '0')};
  transition: 0.2s ease-out;
`;

const ContentWrapper = styled.div<{ showModal: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60rem;
  margin-left: -30rem;
  margin-top: -15rem;
  height: 30rem;
  z-index: 60;
  display: ${(props) => (props.showModal ? 'block' : 'none')};

  transition: all 0.1s ease-out;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 3.2rem;
  border-radius: 4px;

  background: #fff;
  h2 {
    margin-bottom: ${theme.spacing.x3};
  }

  p {
    margin-bottom: ${theme.spacing.x2};
  }

  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  flex: 1;
`;

const Actions = styled.div`
  display: flex;
  button {
    margin-right: 1.6rem;
  }
`;

function Confirm() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translationKey = 'workspacePlan.write.sync';
  const hasSyncError: boolean = useSelector((state: any) => state.editorUI.hasSyncError);
  const syncErrorText: boolean = useSelector((state: any) => state.editorUI.syncErrorText);
  const handleCloseModal = () => {
    dispatch(setHasSyncError(false));
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div>
      <Mask showModal={hasSyncError} />
      <ContentWrapper showModal={hasSyncError}>
        <Content>
          <Text>
            <h2>{t(`${translationKey}.header`)}</h2>
            {syncErrorText && <p>{syncErrorText}</p>}
            {!syncErrorText && <p>{t(`${translationKey}.info`)}</p>}
          </Text>
          <Actions>
            <KoalaButton onClick={handleRefresh}>{t('shared.refresh')}</KoalaButton>
            <KoalaTextButton onClick={handleCloseModal}>{t('shared.cancel')}</KoalaTextButton>
          </Actions>
        </Content>
      </ContentWrapper>
    </div>
  );
}

export default React.memo(Confirm);
