import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { useTranslation } from 'react-i18next';

const URLFields = styled.div`
  margin-top: ${theme.spacing.x2};
  input {
    height: 3.2rem;
    margin-bottom: ${theme.spacing.x1};
  }
`;

const BlockActions = styled.div`
  button {
    margin-right: ${theme.spacing.x1};
  }
`;
const Wrapper = styled.div``;

interface Props {
  outcome: TabilityTypes.Outcome;
}

function NewOutcomeLinkForm(props: Props) {
  const queryCache = useQueryCache();
  const { outcome } = props;
  const buttonEl: any = useRef();
  const { t } = useTranslation();
  const translationKey = 'panels.shared';

  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');

  const [displayForm, setDisplayForm] = useState(false);

  // OutcomeLink mutations
  const [createOutcomeLinkMutation, { isLoading: isCreating }]: [any, any] = useMutation(remoteApi.createOutcomeLink, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomeLinks);
      setTitle('');
      setUrl('');
      setDisplayForm(false);
    },
  });

  useEffect(() => {
    if (buttonEl && buttonEl.current && displayForm) {
      buttonEl.current.scrollIntoView();
    }
  }, [buttonEl, displayForm]);

  //const scrollToFormInput = () => buttonEl.current.scrollIntoView();

  const showForm = (e: any) => {
    e.preventDefault();
    setDisplayForm(true);
  };

  const hideForm = (e: any) => {
    e.preventDefault();
    setDisplayForm(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      title,
      url,
    };

    createOutcomeLinkMutation({
      outcomeId: outcome.id,
      outcomeLink: params,
    });

    //scrollToFormInput();
  };

  const handleTitleChange = (e: any) => {
    e.preventDefault();
    const title = e.target.value;
    setTitle(title);
  };

  const handleUrlChange = (e: any) => {
    e.preventDefault();
    const url = e.target.value;
    setUrl(url);
  };

  const isFetching = isCreating;

  if (!displayForm) {
    return (
      <Wrapper>
        <KoalaButton onClick={showForm} appearance="secondary" size="small">
          {t(`${translationKey}.addLink`)}
        </KoalaButton>
      </Wrapper>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <URLFields>
        <input value={url} onChange={handleUrlChange} placeholder={t(`${translationKey}.url`) ?? `URL`} type="url" />
        <input
          value={title}
          onChange={handleTitleChange}
          placeholder={t(`${translationKey}.urlTitle`) ?? `Title (optional)`}
        />
      </URLFields>
      <BlockActions>
        <KoalaButton disabled={isFetching} loading={isFetching} ref={buttonEl} submit size="small">
          {t('shared.add')}
        </KoalaButton>
        <KoalaTextButton size="small" onClick={hideForm}>
          {t('shared.cancel')}
        </KoalaTextButton>
      </BlockActions>
    </form>
  );
}

export default React.memo(NewOutcomeLinkForm);
