import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import MentionBox from 'components/MentionBox';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import { CONTEXT_STORAGE_KEY } from 'config/constants';
import { Standup } from 'types';
import _ from 'lodash';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    'gutter content'
    'gutter footer';
  column-gap: ${theme.spacing.x1};

  margin-left: ${theme.spacing.x3};

  &.primary {
    background: #f3f3f3;
    border-radius: 8px;
    padding: ${theme.spacing.x3};

    border-bottom: 0;
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
`;

const Content = styled.div`
  grid-area: content;
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.6rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    border-radius: 4px;
    background: #f3f3f3;
    border: 0px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }

    width: 100%;

    &.minimized {
      min-height: 4rem;
      height: 4rem;
    }
  }
`;

const Footer = styled.div`
  grid-area: footer;
  margin: 0 0 ${theme.spacing.x2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.subtleText};

  > div {
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.x1};
    button {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

interface Props {
  standup: Standup;
  setShowNewComment: (value: boolean) => void;
}

function CommentBlock(props: Props) {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const { standup, setShowNewComment } = props;
  const [body, setBody] = useState('');
  const storageKey = `${CONTEXT_STORAGE_KEY}_standup_comment_${standup.nano_slug}`;

  const user = useSelector((state: any) => state.session.currentUser, shallowEqual);

  // Standup comment mutations
  const [createStandupCommentMutation, { isLoading }]: [any, any] = useMutation(remoteApi.createStandupComment, {
    onSuccess: () => {
      sessionStorage.removeItem(storageKey);
      queryCache.invalidateQueries(queryKeys.standups);
      queryCache.invalidateQueries(queryKeys.standupComments);
      setBody('');
    },
  });

  useEffect(() => {
    debounceStoreText.current(body);
  }, [body]);

  useEffect(() => {
    const savedContext = sessionStorage.getItem(storageKey) || '';
    setBody(savedContext);
  }, [storageKey]);

  const storeText = (newText: string) => {
    if (newText) {
      sessionStorage.setItem(storageKey, newText);
    }
  };

  const debounceStoreText = useRef(
    _.debounce((newText: string) => storeText(newText), 1000, {
      maxWait: 5000,
    }),
  );

  const handleSubmit = () => {
    const standupComment = {
      body,
    };
    createStandupCommentMutation({
      standupId: standup.id,
      standup_comment: standupComment,
    });
  };

  const replyPlaceholder = t('workspaceStandups.replyPlaceholder');
  return (
    <Grid>
      <Gutter>
        <KoalaAvatar user={user} size={2.4} />
      </Gutter>
      <Content>
        <MentionBox
          setValue={setBody}
          value={body}
          placeholder={replyPlaceholder}
          comment={true}
          cmdEnterCallback={handleSubmit}
        />
      </Content>

      <Footer>
        <div>
          <KoalaButton size="small" onClick={handleSubmit} disabled={isLoading} loading={isLoading}>
            {t('shared.save')}
          </KoalaButton>
          <KoalaTextButton size="small" onClick={() => setShowNewComment(false)} appearance="secondary">
            {t('shared.cancel')}
          </KoalaTextButton>
        </div>
      </Footer>
    </Grid>
  );
}

export default React.memo(CommentBlock);
