import React from 'react';

const LoveFilledIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.35967 3.5677C0.546778 5.38059 0.546778 8.31986 2.35967 10.1328L10.0189 17.792L17.6403 10.1705C19.4532 8.35763 19.4532 5.41835 17.6403 3.60547C15.8274 1.79258 12.8882 1.79258 11.0753 3.60547L10.0189 4.66187L8.92471 3.5677C7.11182 1.75482 4.17255 1.75482 2.35967 3.5677Z"
      fill="#737373"
    />
  </svg>
);
export default LoveFilledIcon;
