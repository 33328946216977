/**
  This is the playground editor. It loads the state from local storage and saves there too
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as TabilityTypes from 'types';
import { format } from 'date-fns';
import download from 'downloadjs';
import * as workspaceUtils from 'utils/workspaceUtils';
import * as planUtils from 'utils/planUtils';
import * as membershipUtils from 'utils/membershipUtils';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import * as remoteApi from 'api/remote';
import { WORKSPACE_PLAN_WRITE_ROUTE } from 'routes';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Routes
import * as routes from 'routes';

// Components
import ReactTooltip from 'react-tooltip';
import { useHistory, Redirect } from 'react-router-dom';
import Loader from 'components/Loader';
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import DropdownMenu from 'components/DropdownMenu';
import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTabs from 'koala/components/Tabs';
import { useTranslation } from 'react-i18next';
import { useHotkeys } from 'react-hotkeys-hook';
import ShareButton from 'components/ShareButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .menu-title {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x1};
    width: 100%;
    position: relative;
  }
  @media ${theme.devices.laptop} {
    .text-badge {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.N10};
  gap: ${theme.spacing.x2};
  justify-content: space-between;
  padding: 0 ${theme.spacing.x2};
  background: #fff;
`;

const WrapperLine = styled.div`
  display: flex;
  position: relative;
  bottom: ${theme.spacing.half};
  align-items: center;
  justify-content: flex-end;

  .settings-nav {
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacing.x1};

    .superlocked {
      svg {
        path {
          fill: ${theme.colors.orange};
        }
      }
    }
  }

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr 25rem;

    .hide-tablet {
      display: none;
    }
  }
  @media ${theme.devices.mobile} {
    grid-template-columns: 1fr 17rem;
    .hide-mobile {
      display: none;
    }
  }
`;

const UpgradeOption = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  plan: TabilityTypes.Plan;
}

function PlanNav(props: Props) {
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { planId, workspaceSlug } = useParams<{ planId: string; workspaceSlug: string }>();
  const { plan } = props;
  const history = useHistory();
  const location = useLocation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);
  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);
  const { t } = useTranslation();
  const blockId = `plan:${plan.id}`;
  const [redirect, setRedirect]: any = useState(null);
  useHotkeys('P', () => handlePresent(), { scopes: ['*'] });
  useHotkeys(
    'E',
    () => {
      if (hasEditPermission) {
        handleEdit();
      }
    },
    { scopes: ['*'] },
  );
  // Mutation that will create the workspace in the backend
  const [downloadPlanPDFMutation]: [any, any] = useMutation(remoteApi.downloadPlanPDF, {
    onSuccess: (response: any) => {
      const content = response.headers['content-type'];
      const fileName = `${format(new Date(), 'Y-MM-dd')}-plan.pdf`;
      download(response.data, fileName, content);
    },
  });

  // Plan mutations
  const [copyPlanMutation, { isLoading: isCopying }]: [any, any] = useMutation(remoteApi.copyPlan, {
    onSuccess: (response: any) => {
      const createdPlan = response.data;
      const planRoute = WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
        ':planId',
        createdPlan.nano_slug,
      );
      history.push(planRoute);
    },
  });

  const [archivePlanMutation]: [any, any] = useMutation(remoteApi.archivePlan, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.currentPlan, planId]);
      queryCache.invalidateQueries([queryKeys.plans]);
      queryCache.invalidateQueries([queryKeys.objectives]);
      queryCache.invalidateQueries([queryKeys.outcomes]);
      queryCache.invalidateQueries([queryKeys.initiatives]);
    },
  });

  const queryKeyPlan = [queryKeys.currentPlan, plan.nano_slug];
  const [updatePlanMutation]: [any, any] = useMutation(remoteApi.updatePlan, {
    onSuccess: (planResponse) => {
      queryCache.setQueryData(queryKeyPlan, planResponse);
      queryCache.invalidateQueries(queryKeys.currentPlan);
      queryCache.invalidateQueries(queryKeys.plans);
    },
  });

  // Mutation that will create the workspace in the backend
  const [createPlanMutation]: [any, any] = useMutation(remoteApi.createPlan, {
    onSuccess: (response: any) => {
      const createdPlan = response.data;
      const planRoute = WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
        ':planId',
        createdPlan.nano_slug,
      );
      setRedirect(planRoute);
    },
  });

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const handleEdit = () => {
    const route = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId);
    history.push(route);
  };

  const handleCopyPlan = () => {
    copyPlanMutation(planId);
  };

  const handleShare = () => {
    dispatch(setGlobalModalContent(`plan:${plan.id}:share`));
  };

  const handleDownloadPlanPDF = () => {
    downloadPlanPDFMutation(planId);
  };

  const handleConfigure = () => {
    history.push(
      routes.WORKSPACE_PLAN_SETTINGS_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
    );
  };
  const handleUnpublish = () => {
    const planParams = {
      state: 'draft',
    };
    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);

    history.push(routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId));
  };

  const handleCreatePlan = (parent_id: string) => {
    const mutationParams = {
      workspaceSlug: workspaceSlug,
      plan: {
        title: 'Untitled plan',
        parent_id,
      },
    };
    createPlanMutation(mutationParams);
  };

  const archivePlan = () => {
    archivePlanMutation({
      planId: plan.id,
      plan: {
        archived: true,
      },
    });
  };

  const unarchivePlan = () => {
    archivePlanMutation({
      planId: plan.id,
      plan: {
        archived: false,
      },
    });
  };

  const handlePresent = () => {
    const presentPath = routes.WORKSPACE_PLAN_PREZ_ROOT_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
      ':planId',
      plan.nano_slug,
    );
    window.open(presentPath, '_blank');
  };

  const handleEditPermissions = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const nanoBlockId = `plan:${plan.nano_slug}`;
    dispatch(setGlobalModalContent(`${nanoBlockId}:edit.permissions`));
  };

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    let nanoBlockId;
    let url;
    switch (action) {
      case 'share-plan':
        handleShare();
        break;
      case 'download-pdf':
        handleDownloadPlanPDF();
        break;
      case 'create-plan':
        handleCreatePlan(plan.id);
        break;
      case 'move-plan':
        nanoBlockId = `plan:${plan.nano_slug}`;
        dispatch(setGlobalModalContent(`${blockId}:move`));
        break;
      case 'tv-mode':
        const publicAccessPath = routes.PUBLIC_PLAN_TV_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
          ':planId',
          plan.nano_slug,
        );
        history.push(publicAccessPath);
        break;
      case 'upgrade-copy-plan':
        const upgradeModalHash = 'workspace:tability20-essentials:upgrade';
        dispatch(setGlobalModalContent(upgradeModalHash));
        break;
      case 'copy-plan':
        handleCopyPlan();
        break;
      case 'show-archives':
        nanoBlockId = `plan:${plan.nano_slug}`;
        url = `#${nanoBlockId}:archives`;
        history.push(url);
        break;
      case 'delete-plan':
        dispatch(setGlobalModalContent(`${blockId}:delete`));
        break;
      case 'configure-plan':
        handleConfigure();
        break;
      case 'unpublish-plan':
        handleUnpublish();
        break;
      case 'edit-plan-content':
        handleEdit();
        break;
      case 'archive':
        archivePlan();
        break;
      case 'unarchive':
        unarchivePlan();
        break;
      case 'public-sharing':
        handlePresent();
        break;
      case 'show-activity':
        const activityRoute = routes.WORKSPACE_PLAN_ACTIVITY_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
          ':planId',
          planId,
        );
        history.push(activityRoute);
        break;
      case 'audit-log':
        dispatch(setGlobalModalContent(`audit.plan:${plan.id}:show`));
        break;
    }
  };

  // Check if the workspace has the right subscription
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  const hasPremiumSubscription = workspaceUtils.hasPremiumSubscription(currentWorkspace);
  const menuItems = () => {
    const items = [];
    if (hasEssentialsSubscription) {
      items.push(<span data-action="copy-plan">{t('workspacePlans.options.copy')}</span>);
    } else {
      items.push(
        <UpgradeOption data-action="upgrade-copy-plan">
          <span>{t('workspacePlans.options.copy')}</span>
          <span>
            <UpgradeIcon />
          </span>
        </UpgradeOption>,
      );
    }
    if (hasEditPermission) {
      items.push(<span data-action="configure-plan">{t('workspacePlans.options.settings')}</span>);
    }
    items.push(<span data-action="create-plan">{t('workspacePlans.options.subPlan')}</span>);
    if (hasEditPermission) {
      items.push(<span data-action="move-plan">{t('workspacePlans.options.move')}</span>);
    }
    items.push(<span data-action="show-activity">{t('workspacePlans.options.showActivity')}</span>);
    items.push(<span data-action="show-archives">{t('workspacePlans.options.showArchived')}</span>);
    if (hasEditPermission) {
      items.push(<span data-action="unpublish-plan">{t('workspacePlans.options.unpublish')}</span>);
      if (!plan.archived) {
        items.push(
          <span key="archive" data-action="archive">
            {t('workspacePlans.options.archive')}
          </span>,
        );
      } else {
        items.push(
          <span key="unarchive" data-action="unarchive">
            {t('workspacePlans.options.unarchive')}
          </span>,
        );
      }
      items.push(<span data-action="delete-plan">{t('workspacePlans.options.delete')}</span>);
    }
    items.push(<span data-action="audit-log">{t('workspacePlans.options.audit')}</span>);
    return items;
  };

  // Managing permissions
  const planLocked = plan.global_permission_type !== 'edit';
  const lockedButtonIcon = planLocked ? 'locked' : 'unlocked';
  const superlockedClassName = plan.global_permission_type === 'none' ? 'superlocked' : '';
  const buttonTipMapping: any = {
    edit: t('workspacePlan.planNav.permissionEdit'),
    view: t('workspacePlan.planNav.permissionView'),
    none: t('workspacePlan.planNav.permissionNone'),
  };
  let lockedButtonTip: string = buttonTipMapping[plan.global_permission_type];

  // Managing the visibility of the actions at the end of the line
  let showPlanActions = true;

  // Hide actions if we're in the Map, Insights, Notes or Retro
  const pathnameParts = location.pathname.split('/');
  const sectionPathName = pathnameParts[4];
  if (['notes', 'insights', 'map', 'retrospectives'].includes(sectionPathName)) {
    showPlanActions = false;
  }

  const normalTabs = [
    {
      label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
      key: 'workspacePlanTrackLink',
      tooltip: t('workspacePlan.planNav.outcomesTooltip') ?? 'Track progress on your plan',
      id: 'plan-nav-outcomes',
      link: routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
    },
    {
      label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
      key: 'workspacePlanWorkLink',
      id: 'plan-nav-tasks',
      tooltip:
        t('workspacePlan.planNav.initiativesTooltip', {
          initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
        }) ?? `Prioritize ${translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase()}`,
      link: routes.WORKSPACE_PLAN_WORK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
    },
    {
      label: t('workspacePlan.planNav.notes') ?? 'Notes',
      key: 'workspacePlanNotesLink',
      id: 'plan-nav-notes',
      link: routes.WORKSPACE_PLAN_NOTES_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
    },
    {
      label: t('workspacePlan.planNav.map') ?? 'Map',
      key: 'workspacePlanMapLink',
      id: 'plan-nav-map',
      tooltip: t('workspacePlan.planNav.mapTooltip') ?? 'View plan map',
      link: routes.WORKSPACE_PLAN_MAP_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
      upgradeRequired: !hasPremiumSubscription,
    },
    {
      label: t('workspacePlan.planNav.retrospectives') ?? 'Retrospectives',
      key: 'workspacePlanRetrospectiveLink',
      id: 'plan-nav-retrospectives',
      link: routes.WORKSPACE_PLAN_RETROSPECTIVES_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
        ':planId',
        planId,
      ),
      upgradeRequired: !hasPremiumSubscription,
    },
  ];

  const readOnlyTabs = [
    {
      label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
      key: 'workspacePlanTrackLink',
      tooltip: t('workspacePlan.planNav.outcomesTooltip') ?? 'Track progress on your plan',
      id: 'plan-nav-outcomes',
      link: routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
    },
    {
      label: t('workspacePlan.planNav.map') ?? 'Map',
      key: 'workspacePlanMapLink',
      id: 'plan-nav-map',
      tooltip: t('workspacePlan.planNav.mapTooltip') ?? 'View plan map',
      link: routes.WORKSPACE_PLAN_MAP_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
      upgradeRequired: !hasPremiumSubscription,
    },
    {
      label: t('workspacePlan.planNav.retrospectives') ?? 'Retrospectives',
      key: 'workspacePlanRetrospectiveLink',
      id: 'plan-nav-retrospectives',
      link: routes.WORKSPACE_PLAN_RETROSPECTIVES_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
        ':planId',
        planId,
      ),
      upgradeRequired: !hasPremiumSubscription,
    },
  ];

  const simplifiedTabs = [
    {
      label: t('shared.terms.metrics'),
      key: 'workspacePlanTrackLink',
      tooltip: t('workspacePlan.planNav.outcomesTooltip') ?? 'Track progress on your plan',
      id: 'plan-nav-outcomes',
      link: routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
    },
    {
      label: t('workspacePlan.planNav.notes') ?? 'Notes',
      key: 'workspacePlanNotesLink',
      id: 'plan-nav-notes',
      link: routes.WORKSPACE_PLAN_NOTES_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
    },
    {
      label: t('workspacePlan.planNav.map') ?? 'Map',
      key: 'workspacePlanMapLink',
      id: 'plan-nav-map',
      tooltip: t('workspacePlan.planNav.mapTooltip') ?? 'View plan map',
      link: routes.WORKSPACE_PLAN_MAP_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
      upgradeRequired: !hasPremiumSubscription,
    },
    {
      label: t('workspacePlan.planNav.retrospectives') ?? 'Retrospectives',
      key: 'workspacePlanRetrospectiveLink',
      id: 'plan-nav-retrospectives',
      link: routes.WORKSPACE_PLAN_RETROSPECTIVES_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
        ':planId',
        planId,
      ),
      upgradeRequired: !hasPremiumSubscription,
    },
  ];

  const draftTabs = [
    {
      label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
      key: 'workspacePlanTrackLink',
      tooltip: t('workspacePlan.planNav.outcomesTooltip') ?? 'Track progress on your plan',
      id: 'plan-nav-outcomes',
      link: routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
    },
  ];

  let tabsToDisplay = normalTabs;
  if (plan.plan_type === 'simplified') {
    tabsToDisplay = simplifiedTabs;
  }
  if (isReadOnly) {
    tabsToDisplay = readOnlyTabs;
  }
  if (plan.state === 'draft') {
    tabsToDisplay = draftTabs;
  }
  const planShareLink = `https://${process.env.REACT_APP_DOMAIN}${location.pathname}`;

  return (
    <Wrapper>
      <Container>
        <KoalaTabs tabs={tabsToDisplay} />
        <WrapperLine>
          <div className="settings-nav">
            {isCopying && <Loader />}
            {showPlanActions && (
              <>
                {!isReadOnly && (
                  <>
                    <div data-tip data-for="lockedButton" className={superlockedClassName}>
                      <KoalaIconButton
                        className="hide-mobile"
                        onClick={handleEditPermissions}
                        disabled={!hasEditPermission}
                        iconName={lockedButtonIcon}
                      />
                    </div>
                    <ReactTooltip
                      place="bottom"
                      type="dark"
                      id="lockedButton"
                      className="tooltip"
                      effect="solid"
                      delayShow={200}
                    >
                      {lockedButtonTip}
                    </ReactTooltip>
                    {hasEditPermission && (
                      <>
                        <KoalaButton
                          dataFor="planEditButton"
                          onClick={handleEdit}
                          className="plan-nav-edit-plan hide-tablet"
                          appearance="subtle"
                        >
                          {t('workspacePlan.planNav.editContent')}
                        </KoalaButton>
                        <ReactTooltip
                          place="bottom"
                          type="dark"
                          id="planEditButton"
                          className="tooltip"
                          effect="solid"
                          delayShow={200}
                        >
                          {t(`workspacePlan.planNav.editContentTooltip`)}
                        </ReactTooltip>
                      </>
                    )}
                  </>
                )}
                <div data-tip data-for="presentButton">
                  <KoalaIconButton
                    className="hide-mobile  plan-nav-present"
                    onClick={handlePresent}
                    iconName="present"
                  />
                </div>
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  id="presentButton"
                  className="tooltip"
                  effect="solid"
                  delayShow={200}
                >
                  {t('workspacePlan.planNav.openPresentation')}
                </ReactTooltip>
                <ShareButton id={plan.id} link={planShareLink} shareCallback={handleShare} className="plan-nav-share" />
              </>
            )}
            {!showPlanActions && sectionPathName !== 'retrospectives' && (
              <ShareButton id={plan.id} link={planShareLink} shareCallback={handleShare} className="plan-nav-share" />
            )}
            {!isReadOnly && (
              <DropdownMenu
                trigger={<KoalaIconButton iconName="ellipsis" />}
                onSelection={handleMenuSelection}
                items={menuItems()}
              />
            )}
          </div>
        </WrapperLine>
      </Container>
    </Wrapper>
  );
}

export default PlanNav;
