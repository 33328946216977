import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.934 3.49277C13.0601 2.61888 11.6107 2.73883 10.8924 3.7445L8.38394 7.25624L5.43645 6.92874C3.55746 6.71997 2.46455 8.9939 3.80138 10.3307L6.02821 12.5576L3.19978 15.386C2.80926 15.7765 2.80926 16.4097 3.19978 16.8002C3.59031 17.1907 4.22347 17.1907 4.614 16.8002L7.44242 13.9718L9.66926 16.1986C11.0061 17.5354 13.28 16.4425 13.0712 14.5635L12.7437 11.6161L16.2555 9.10765C17.2612 8.38931 17.3811 6.93987 16.5072 6.06597L13.934 3.49277ZM8.15023 11.8511L11.0835 14.7844L10.756 11.8369C10.676 11.1168 10.9917 10.4097 11.5813 9.98858L15.093 7.48019L12.5198 4.90698L10.0114 8.41873C9.59026 9.00831 8.88319 9.32402 8.16308 9.24401L5.21559 8.91651L8.14883 11.8498L8.15023 11.8511Z"
      fill="#737373"
    />
  </svg>
);
