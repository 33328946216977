import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

// API
import * as remoteApi from 'api/remote';

// Components
import Loader from 'components/Loader';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  a {
    text-decoration: underline;
  }
`;

function InstallPowerBI() {
  const location = useLocation();
  const { t } = useTranslation(undefined, { useSuspense: false });

  // Extract params from the URL
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const state = params.get('state');
  const stateDecoded = state ? JSON.parse(Base64.decode(state)) : null;
  const workspaceSlug = stateDecoded ? stateDecoded.slug : '';
  const redirectAfter = stateDecoded ? stateDecoded.redirect_after : '';
  const [installPowerBIMutation, { isError, isLoading, isSuccess }] = useMutation(remoteApi.installPowerBI, {
    onSuccess: () => {
      window.location.href = redirectAfter;
    },
  });

  useEffect(() => {
    if (code) {
      const body = {
        code,
        state,
      };
      const params = {
        body,
        workspaceSlug,
      };
      installPowerBIMutation(params);
    }
  }, [code, installPowerBIMutation, state, workspaceSlug]);

  return (
    <Container>
      {isLoading && <Loader />}
      {isSuccess && <p>{t('installIntegration.redirecting')}</p>}
      {isError && (
        <Fragment>
          <p>{t('installIntegration.connectError', { integration: 'Power BI' })}</p>
          <p>
            <Link to="/">{t('installIntegration.backLogin')}</Link>
          </p>
        </Fragment>
      )}
    </Container>
  );
}

export default InstallPowerBI;
