import React from 'react';

const StarIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99998 2C10.3806 2 10.7282 2.21607 10.8967 2.55738L12.9387 6.69425L17.5053 7.36172C17.8818 7.41676 18.1945 7.68077 18.3119 8.04278C18.4293 8.40478 18.331 8.80204 18.0584 9.06757L14.7548 12.2852L15.5345 16.831C15.5988 17.2061 15.4446 17.5853 15.1366 17.8091C14.8286 18.0328 14.4203 18.0623 14.0834 17.8851L9.99998 15.7376L5.91654 17.8851C5.57962 18.0623 5.17133 18.0328 4.86335 17.8091C4.55538 17.5853 4.40113 17.2061 4.46548 16.831L5.24513 12.2852L1.94158 9.06757C1.66896 8.80204 1.57069 8.40478 1.68807 8.04278C1.80544 7.68077 2.11812 7.41676 2.49468 7.36172L7.06127 6.69425L9.10328 2.55738C9.27175 2.21607 9.61937 2 9.99998 2ZM9.99998 5.25925L8.62224 8.05041C8.4767 8.34525 8.19552 8.54971 7.87016 8.59727L4.78772 9.04781L7.01738 11.2195C7.25325 11.4492 7.36092 11.7804 7.30526 12.1049L6.77927 15.1717L9.53453 13.7227C9.82592 13.5695 10.1741 13.5695 10.4654 13.7227L13.2207 15.1717L12.6947 12.1049C12.6391 11.7804 12.7467 11.4492 12.9826 11.2195L15.2122 9.04781L12.1298 8.59727C11.8045 8.54971 11.5233 8.34525 11.3777 8.05041L9.99998 5.25925Z"
      fill="#737373"
    />
  </svg>
);
export default StarIcon;
