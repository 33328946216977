import { addDays } from 'date-fns';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { midString } from 'state/reducers/utils';
import styled from 'styled-components';
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
const BlockGrid = styled.div`
  display: grid;
  position: relative;
  border: 2px solid ${theme.colors.B20};
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'task-title';
  align-items: start;
  gap: ${theme.spacing.x1};
  min-height: 3.8rem;
  padding: 1.2rem;
  background: #fff;
  border-radius: 4px;
  margin-top: ${theme.spacing.half};
`;
const BlockHeader = styled.div`
  grid-area: task-title;
  textarea {
    border: none;
    padding: 0;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    resize: none;
    border: none;
    outline: none;
  }
`;
interface Props {
  week: Date;
  outcomeId: string;
  handleCancel: () => void;
}
function CreateInitiativeCard(props: Props) {
  const { handleCancel, outcomeId, week } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [title, setTitle] = useState('');
  const outcomesToInitiativesMapping = useSelector((state: any) => state.editorEntities.outcomesToInitiativesMapping);
  const initiatives = useSelector((state: any) => state.editorEntities.initiatives);
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        handleCancel();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, handleCancel]);
  const [createInitiativeMutation, { isLoading }]: [any, any] = useMutation(remoteApi.createInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      setTitle('');
      setTimeout(() => {
        inputRef.current?.scrollIntoView();
      }, 200);
    },
  });
  const handlePress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    }
    if (e.key === 'Escape') {
      handleCancel();
    }
  };
  const handleSave = () => {
    if (title === '') {
      return;
    }
    let lastInitiative = null;
    // Get the last initiative of the outcome to set the rank
    const initiativeIds = outcomesToInitiativesMapping[outcomeId] || [];
    if (initiativeIds.length) {
      const lastInitiativeId = initiativeIds[initiativeIds.length - 1];
      lastInitiative = initiatives[lastInitiativeId];
    }
    const lastRank = lastInitiative ? lastInitiative.rank : '';
    const rank = midString(lastRank, '');
    const due_at = addDays(week, 4);
    const params = {
      outcomeId: outcomeId,
      initiative: {
        title,
        state: 'open',
        rank,
        due_at,
      },
    };
    createInitiativeMutation(params);
  };
  const handleTitleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setTitle(value);
  };
  const label = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();
  return (
    <BlockGrid ref={wrapperRef}>
      <BlockHeader>
        <textarea
          ref={inputRef}
          placeholder={t('workspacePlans.table.initiativePlaceholder', { label: label }) ?? `What's your ${label}?`}
          onChange={handleTitleChange}
          onKeyDown={(e) => handlePress(e)}
          value={title}
          rows={3}
        />
      </BlockHeader>
      {isLoading && <KoalaLoader />}
    </BlockGrid>
  );
}
export default CreateInitiativeCard;
