import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaIconButton from 'koala/components/IconButton';
import * as routes from 'routes';
import { shallowEqual, useSelector } from 'react-redux';
import { Base64 } from 'js-base64';
import { useTranslation } from 'react-i18next';

const TagContainer = styled.div`
  display: flex;
  flex: 1;
  gap: ${theme.spacing.x1};
  padding: 0.4rem;
  flex-wrap: wrap;
`;

const TagButton = styled.div`
  color: ${theme.colors.subtleText};
  align-self: center;
`;
// Components
interface Props {
  initiative: TabilityTypes.Initiative;
  setDisplayPicker: (displayPicker: boolean) => void;
}

function TagPickerInitiative(props: Props) {
  const { initiative, setDisplayPicker } = props;
  const tagList = initiative.cached_tag_list || '';
  const tags = tagList.length > 0 ? tagList.split(',') : [];
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  return (
    <TagContainer>
      {tags.length === 0 && <TagButton>{t('shared.none')}</TagButton>}
      {tags.length > 0 && (
        <>
          {tags.map((tag: string) => {
            const filterHash: { [key: string]: any } = { tag: [{ value: tag, label: tag }] };
            const encodedHash = Base64.encode(JSON.stringify(filterHash));
            const filterPath = routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(
              ':workspaceSlug',
              currentWorkspace.slug,
            ).replace(':filter', encodedHash);
            return (
              <KoalaTextBadge key={tag} variant="neutral-light" linkTo={filterPath}>
                {tag}
              </KoalaTextBadge>
            );
          })}
        </>
      )}
      <KoalaIconButton iconName="edit" edge="square" size="small" onClick={() => setDisplayPicker(true)} />
    </TagContainer>
  );
}
export default React.memo(TagPickerInitiative);
