import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import * as TabilityTypes from 'types';
import RetrospectiveCommentEdit from './RetrospectiveCommentEdit';
import RetrospectiveReplyNew from '../RetrospectiveReply/RetrospectiveReplyNew';
import parse from 'parse-link-header';
import { AxiosResponse } from 'axios';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import RetrospectiveComment from './RetrospectiveCommentView';
import RetrospectiveReply from '../RetrospectiveReply';
import KoalaLoader from 'koala/components/Loader';

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
`;

function getNextPage(response: any) {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
}
interface Props {
  retrospectiveComment: TabilityTypes.RetrospectiveComment;
}

function RetrospectiveCommentBlock(props: Props) {
  const { retrospectiveComment } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [showNewReply, setShowNewReply] = useState(false);
  const [replyGroups, setReplyGroups] = useState<AxiosResponse<TabilityTypes.RetrospectiveReply[]>[]>([]);
  const { t } = useTranslation();

  // Get the retrospective replies
  const staleTime = 0;
  const {
    isLoading: isRepliesLoading,
    fetchMore,
    canFetchMore,
    isFetchingMore,
  } = useInfiniteQuery([queryKeys.retrospectiveReplies, retrospectiveComment.id], remoteApi.fetchRetrospectiveReplies, {
    staleTime,
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    onSuccess: (response: AxiosResponse<TabilityTypes.RetrospectiveReply[]>[]) => {
      setReplyGroups(response);
    },
  });

  return (
    <div>
      {!isEditing && (
        <RetrospectiveComment
          retrospectiveComment={retrospectiveComment}
          setIsEditing={setIsEditing}
          setShowNewReply={setShowNewReply}
        />
      )}
      {isEditing && (
        <RetrospectiveCommentEdit retrospectiveComment={retrospectiveComment} setIsEditing={setIsEditing} />
      )}
      {isRepliesLoading && <KoalaLoader />}
      {!isRepliesLoading &&
        replyGroups.map((group, i) => (
          <Fragment key={i}>
            {group.data.map((reply) => (
              <RetrospectiveReply setShowNewReply={setShowNewReply} retrospectiveReply={reply} key={reply.id} />
            ))}
          </Fragment>
        ))}
      {showNewReply && (
        <RetrospectiveReplyNew retrospectiveComment={retrospectiveComment} setShowNewReply={setShowNewReply} />
      )}
      {canFetchMore && (
        <LoadMore>
          <KoalaButton onClick={() => fetchMore()} appearance="secondary" loading={!!isFetchingMore}>
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
    </div>
  );
}

export default React.memo(RetrospectiveCommentBlock);
