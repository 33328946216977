import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as outcomeUtils from 'utils/outcomeUtils';
import * as checkinUtils from 'utils/checkinUtils';

import KoalaTextBadge from 'koala/components/TextBadge';
import PresentationInitiative from './PresentationInitiative';

export const BlockContent = styled.div`
  grid-area: content;
  background: transparent;
  margin: 0;
  position: relative;

  h4.outcome {
    font-size: 1.8rem;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
`;
export const BlockMeta = styled.div`
  grid-area: meta;
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.x1};
  justify-content: flex-start;
`;

const BlockGrid = styled.div`
  display: grid;
  grid-template-columns: 10rem 1fr 20rem;
  grid-template-rows: auto;
  gap: ${theme.spacing.x2};

  > div {
    display: flex;
    align-items: flex-start;
    align-items: center;
  }

  .label {
    justify-content: flex-end;
  }
  .text {
    font-family: monospace, 'Courier New';
    font-size: 1.3rem;
  }

  .meta {
  }
`;

interface Props {
  outcome: any;
  isSelected: boolean;
}

function OutcomeBlock(props: Props) {
  const { outcome } = props;

  const initiatives: any = outcome.initiatives;

  // Calculate the progress prct

  let formattedOutcomeFrom = null;
  let formattedOutcomeTo = null;
  const outcomeHasTarget = outcomeUtils.hasTarget(outcome);

  if (outcomeHasTarget) {
    formattedOutcomeFrom = outcomeUtils.nFormatter(outcome.from || 0, 2);
    formattedOutcomeTo = checkinUtils.formattedScore(outcome.score_format, outcome.to);
  }

  const blockId = `outcome:${outcome.nano_slug}`;
  const hashPath = `#${blockId}:show`;

  return (
    <>
      <BlockGrid>
        <div className="label">
          <KoalaTextBadge variant="yellow" edge="circle">
            KR
          </KoalaTextBadge>
        </div>
        <div className="text">{outcome.title}</div>
        <div>
          {!outcomeHasTarget && (
            <KoalaTextBadge variant="neutral-light" linkTo={hashPath}>
              No target
            </KoalaTextBadge>
          )}
          {outcomeHasTarget && (
            <KoalaTextBadge variant="neutral-light" linkTo={hashPath} maxWidth="20rem">
              {formattedOutcomeFrom} &#10142; {formattedOutcomeTo}
            </KoalaTextBadge>
          )}
        </div>
      </BlockGrid>
      {initiatives.map((initiative: any, index: number) => {
        return <PresentationInitiative initiative={initiative} key={`initiative-${index}`} />;
      })}
    </>
  );
}

export default React.memo(OutcomeBlock);
