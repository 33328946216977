import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Login() {
  const { t } = useTranslation();
  // Displays the list of templates
  return <Container>{t('errors.notFound')}</Container>;
}

export default Login;
