import React from 'react';

const LabelPlusIcon = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00094 10.5025C5.00088 11.0548 5.44856 11.5025 6.00084 11.5026C6.55313 11.5026 7.00088 11.0549 7.00094 10.5027L7.00121 7.50137L10.0025 7.50164C10.5548 7.50169 11.0025 7.05402 11.0026 6.50173C11.0026 5.94945 10.5549 5.50169 10.0027 5.50164L7.00139 5.50137L7.00167 2.50009C7.00172 1.94781 6.55404 1.50005 6.00176 1.5C5.44947 1.49995 5.00172 1.94762 5.00167 2.49991L5.00139 5.50118L2.00009 5.50091C1.44781 5.50086 1.00005 5.94853 1 6.50082C0.999949 7.0531 1.44762 7.50086 1.99991 7.50091L5.00121 7.50118L5.00094 10.5025Z"
      fill="#737373"
    />
  </svg>
);
export default LabelPlusIcon;
