import React from 'react';

const ArrowRightIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 10.25C19 10.8023 18.5523 11.25 18 11.25L2 11.25C1.44771 11.25 1 10.8023 1 10.25C1 9.69771 1.44771 9.25 2 9.25L18 9.25C18.5523 9.25 19 9.69772 19 10.25Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7071 9.54289C19.0976 9.93342 19.0976 10.5666 18.7071 10.9571L10.7071 18.9571C10.3166 19.3476 9.68342 19.3476 9.29289 18.9571C8.90237 18.5666 8.90237 17.9334 9.29289 17.5429L16.5858 10.25L9.29289 2.95711C8.90237 2.56658 8.90237 1.93342 9.29289 1.54289C9.68342 1.15237 10.3166 1.15237 10.7071 1.54289L18.7071 9.54289Z"
      fill="#737373"
    />
  </svg>
);

export default ArrowRightIcon;
