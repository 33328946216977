import React, { useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import { useTranslation } from 'react-i18next';

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcome.id, `data_source:favro:${outcome.data_source_type}`, 'current'];
  const staleTime = 300;

  const { data, isLoading, isFetching } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading || isFetching) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null || currentValue === undefined) {
    const error = t('panels.editOutcome.errorFetching') ?? 'Sorry, an error occurred while fetching the current value.';
    return <FormField>{error}</FormField>;
  }

  const favroTranslationKey = 'modals.dataConnectors.favroData';
  const dataTypeToLabel: any = {
    widget_completed_tasks: t(`${favroTranslationKey}.widget_completed_tasks`),
    widget_open_tasks: t(`${favroTranslationKey}.widget_open_tasks`),
    collection_count: t(`${favroTranslationKey}.collection_count`),
    column_cards: t(`${favroTranslationKey}.column_cards`),
  };

  const label = dataTypeToLabel[outcome.data_source_type || ''];
  const labelWithNumber = label.replace('<number>', currentValue.toLocaleString());
  return (
    <FormField>
      <label>{t(`modals.dataConnectors.preview`)}</label>
      <p>{labelWithNumber}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  workspaceSlug: string;
}

function FavroSource(props: Props) {
  const { outcome, workspaceSlug } = props;
  const { t } = useTranslation();
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');
  const [errors, setErrors] = useState('');
  const [organizationOptions, setOrganizationOptions] = useState<KoalaSelectOption[]>([]);
  const [widgetOptions, setWidgetOptions] = useState<KoalaSelectOption[]>([]);
  const [collectionOptions, setCollectionOptions] = useState<KoalaSelectOption[]>([]);
  const [columnOptions, setColumnOptions] = useState<KoalaSelectOption[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState(outcome.data_source_meta?.organizationId || '');
  const [selectedWidget, setSelectedWidget] = useState(outcome.data_source_meta?.widgetId || '');
  const [selectedCollection, setSelectedCollection] = useState(outcome.data_source_meta?.collectionId || '');
  const [selectedColumn, setSelectedColumn] = useState(outcome.data_source_meta?.columnId || '');
  const [hasChanged, setHasChanged] = useState(false);
  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: (response) => {
      setHasChanged(false);
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const staleTime = 0;
  const queryKeyOrganizations = ['favro_organizations', workspaceSlug];
  const { isLoading: isLoadingOrganizations } = useQuery(queryKeyOrganizations, remoteApi.listFavroOrganizations, {
    staleTime,
    onSuccess: (response) => {
      setErrors('');
      if (!response.data?.organizations) {
        setOrganizationOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.organizations.map((v: any) => ({
        label: v.name,
        value: v.organizationId,
      }));
      setOrganizationOptions(options);
    },
    onError: (error: any) => {
      setErrors(error.response.data.error);
    },
  });

  const queryKeyWidgets = ['favro_widgets', workspaceSlug, selectedOrganization];
  const { isLoading: isLoadingWidgets } = useQuery(queryKeyWidgets, remoteApi.listFavroWidgets, {
    staleTime,
    onSuccess: (response) => {
      setErrors('');
      if (!response.data?.widgets) {
        setWidgetOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.widgets.map((v: any) => ({
        label: v.name,
        value: v.widgetCommonId,
      }));
      setWidgetOptions(options);
    },
    onError: (error: any) => {
      setErrors(error.response.data.error);
    },
  });

  const queryKeyCollections = ['favro_collections', workspaceSlug, selectedOrganization];
  const { isLoading: isLoadingCollections } = useQuery(queryKeyCollections, remoteApi.listFavroCollections, {
    staleTime,
    onSuccess: (response) => {
      setErrors('');
      if (!response.data?.collections) {
        setCollectionOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.collections.map((v: any) => ({
        label: v.name,
        value: v.collectionId,
      }));
      setCollectionOptions(options);
    },
    onError: (error: any) => {
      setErrors(error.response.data.error);
    },
  });

  const queryKeyColumns = ['favro_columns', workspaceSlug, selectedOrganization, selectedWidget];
  const { isLoading: isLoadingColumns } = useQuery(queryKeyColumns, remoteApi.listFavroColumns, {
    staleTime,
    onSuccess: (response) => {
      setErrors('');
      if (!response.data?.columns) {
        setColumnOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.columns.map((v: any) => ({
        label: v.name,
        value: v.columnId,
      }));
      setColumnOptions(options);
    },
    onError: (error: any) => {
      setErrors(error.response.data.error);
    },
    enabled: !!selectedWidget,
  });

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'favro',
      data_source_type: data_source_type,
      data_source_meta: {
        organizationId: selectedOrganization,
        widgetId: selectedWidget,
        collectionId: selectedCollection,
        columnId: selectedColumn,
      },
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const onOrganizationChange = (option: any) => {
    const value = option ? option.value : null;
    setSelectedOrganization(value);
    setHasChanged(outcome.data_source_meta?.organizationId !== value);
  };
  const onWidgetChange = (option: any) => {
    const value = option ? option.value : null;
    setSelectedWidget(value);
    setHasChanged(outcome.data_source_meta?.widgetId !== value);
  };
  const onCollectionChange = (option: any) => {
    const value = option ? option.value : null;
    setSelectedCollection(value);
    setHasChanged(outcome.data_source_meta?.collectionId !== value);
  };
  const onColumnChange = (option: any) => {
    const value = option ? option.value : null;
    setSelectedColumn(value);
    setHasChanged(outcome.data_source_meta?.columnId !== value);
  };

  const onMetricChanged = (e: any) => {
    const newMetric = e.currentTarget.value;
    setDataSourceType(newMetric);
    setHasChanged(outcome.data_source_type !== newMetric);

    setSelectedCollection('');
    setSelectedColumn('');
    setSelectedWidget('');
    setErrors('');
  };

  const showSave = () => {
    switch (data_source_type) {
      case 'widget_completed_tasks':
        return !!selectedWidget;
      case 'widget_open_tasks':
        return !!selectedWidget;
      case 'column_cards':
        return !!selectedWidget && !!selectedColumn;
      case 'collection_count':
        return !!selectedCollection;
      default:
        return false;
    }
  };

  const selectedOrganizationOption = organizationOptions.find((option) => option.value === selectedOrganization);
  const selectedWidgetOption = widgetOptions.find((option) => option.value === selectedWidget);
  const selectedCollectionOption = collectionOptions.find((option) => option.value === selectedCollection);
  const selectedColumnOption = columnOptions.find((option) => option.value === selectedColumn);

  const showWidgets = ['widget_completed_tasks', 'widget_open_tasks', 'column_cards'].includes(data_source_type);
  const showCollections = ['collection_count'].includes(data_source_type);
  const showColumns = ['column_cards'].includes(data_source_type);

  const favroTranslationKey = 'modals.dataConnectors.favroData';

  return (
    <>
      <FormField>
        <label>{t('modals.dataConnectors.changeMetric')}</label>
        <select value={data_source_type} onChange={onMetricChanged}>
          <option value="">{t('modals.dataConnectors.selectMetric')}</option>
          <option value="widget_completed_tasks">{t(`${favroTranslationKey}.widgetCompleted`)}</option>
          <option value="widget_open_tasks"> {t(`${favroTranslationKey}.widgetOpen`)}</option>
          <option value="collection_count"> {t(`${favroTranslationKey}.collection`)}</option>
          <option value="column_cards"> {t(`${favroTranslationKey}.column`)}</option>
        </select>
      </FormField>

      <FormField>
        <label>
          {t(`${favroTranslationKey}.organizationLabel`)}
          {isLoadingOrganizations && <KoalaLoader />}
        </label>
        {!isLoadingOrganizations && (
          <div style={{ width: '50%' }}>
            <KoalaSelect
              handleChange={(options) => onOrganizationChange(options)}
              placeholder={t(`${favroTranslationKey}.organizationPlaceholder`) ?? 'Select organization'}
              selectedOption={selectedOrganizationOption}
              options={organizationOptions}
              className="small"
            />
          </div>
        )}
      </FormField>

      {showWidgets && (
        <FormField>
          <label>{t(`${favroTranslationKey}.widgetLabel`)}</label>
          {isLoadingWidgets && <KoalaLoader />}
          {!isLoadingWidgets && (
            <div style={{ width: '50%' }}>
              <KoalaSelect
                handleChange={(options) => onWidgetChange(options)}
                placeholder={t(`${favroTranslationKey}.widgetPlaceholder`) ?? 'Select widget'}
                selectedOption={selectedWidgetOption}
                options={widgetOptions}
                className="small"
              />
            </div>
          )}
        </FormField>
      )}

      {showCollections && (
        <FormField>
          <label>{t(`${favroTranslationKey}.collectionLabel`)}</label>
          {isLoadingCollections && <KoalaLoader />}
          {!isLoadingCollections && (
            <div style={{ width: '50%' }}>
              <KoalaSelect
                handleChange={(options) => onCollectionChange(options)}
                placeholder={t(`${favroTranslationKey}.collectionPlaceholder`) ?? 'Select collection'}
                selectedOption={selectedCollectionOption}
                options={collectionOptions}
                className="small"
              />
            </div>
          )}
        </FormField>
      )}

      {showColumns && (
        <FormField>
          <label>{t(`${favroTranslationKey}.columnLabel`)}</label>
          {isLoadingColumns && <KoalaLoader />}
          {!isLoadingColumns && (
            <div style={{ width: '50%' }}>
              <KoalaSelect
                handleChange={(options) => onColumnChange(options)}
                placeholder={t(`${favroTranslationKey}.columnPlaceholder`) ?? 'Select column'}
                selectedOption={selectedColumnOption}
                options={columnOptions}
                className="small"
              />
            </div>
          )}
        </FormField>
      )}

      {hasChanged && showSave() && (
        <FormField>
          <KoalaButton
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={!data_source_type || isUpdating}
            appearance="subtle"
          >
            {t('panels.editOutcome.savePreview')}
          </KoalaButton>
        </FormField>
      )}
      {!hasChanged && showSave() && <CurrentValue outcome={outcome} />}
      {errors && <FormField>{errors}</FormField>}
    </>
  );
}

export default React.memo(FavroSource);
