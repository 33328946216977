import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  top: ${theme.spacing.x1};
  right: ${theme.spacing.x1};
  background: #f3f3f3;

  box-shadow: 0px 0px 2px rgba(21, 21, 21, 0.25);
  border-radius: 4px;

  button.icon {
    border-radius: 8px;
  }

  &:hover {
    background: #fff;
  }
`;
