import KoalaIcon from 'koala/components/Icons';
import KoalaTextBadge from 'koala/components/TextBadge';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { isMeasurable, hasDefaultTarget } from 'utils/outcomeUtils';
import { useSelector, shallowEqual } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import { UXPREFERENCES } from 'config/constants';

// Components
import WarningIcon from './WarningIcon';
import DangerIcon from './DangerIcon';
import KoalaButton from 'koala/components/Button';

const ExpandableContainer = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  background: #fff;
`;

const ExpandableHeader = styled.div`
  display: flex;
  padding: ${theme.spacing.half} ${theme.spacing.x1};
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.4rem;
  > div {
    display: flex;
    align-items: center;
  }
  &:hover {
    cursor: pointer;
    background: ${theme.colors.N3};
  }
  svg {
    transform: rotate(-90deg);
  }
  &.expanded svg {
    transform: rotate(0deg);
  }
`;

const ExpandableContent = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${theme.colors.blockBorder};
  gap: ${theme.spacing.x1};
`;

const WarningItem = styled.div`
  padding: ${theme.spacing.x1};
  border-bottom: 1px solid ${theme.colors.blockBorder};
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${theme.spacing.x1};
  white-space: wrap;

  .icon {
    position: relative;
    top: -1px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.half};
    font-size: 1.2rem;

    a {
      color: ${theme.colors.blue};
    }
  }
  .actions {
    display: flex;
    gap: ${theme.spacing.x1};
    justify-content: space-between;
    align-items: center;
  }

  &:last-child {
    border-bottom: 0;
  }
`;

// Defining the threshold variables
const objectiveCountWarning = 5;
const objectiveCountDanger = 8;

const outcomesCountWarning = 16;
const outcomesCountDanger = 25;

const outcomesPerUserCountWarning = 7;
const outcomesPerUserCountDanger = 10;

const outcomesNotMeasurableThreshold = 30;
const outcomesWithDefaultTargetsThreshold = 30;

// This function returns the icon corrsponding to the warning level
// I've extracted it out of the component to not reload the logic on every refresh
const warningLevelToIcon = (warningLevel: string) => {
  if (warningLevel === 'danger') {
    return <DangerIcon />;
  }
  return <WarningIcon />;
};

interface Props {
  plan: TabilityTypes.Plan;
  outcomes: TabilityTypes.Outcome[];
  objectives: TabilityTypes.Objective[];
}

const PlanWarnings = (props: Props) => {
  const { objectives, outcomes } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const storedUXPreferences = localStorage.getItem(UXPREFERENCES);
  const UXPreferences = storedUXPreferences ? JSON.parse(storedUXPreferences) : {};
  const showPlanWarnings = UXPreferences.showPlanWarnings !== undefined ? UXPreferences.showPlanWarnings : false;
  const [isExpanded, setIsExpanded] = React.useState(showPlanWarnings);

  const expandedClass = isExpanded ? 'expanded' : '';
  const outcomesNotMeasurable: any = [];
  const outcomesWithDefaultTargets: any = [];
  const outcomesWithoutOwners: any = [];
  const membersWithTooManyOutcomes: any = [];
  const outcomeOwners: any = [];
  const outcomesWithoutCheckins: any = [];
  const membersToOutcomesMapping: any = {};
  const warnings = [];

  outcomes.forEach((outcome) => {
    if (!isMeasurable(outcome)) {
      outcomesNotMeasurable.push(outcome);
    }
    if (hasDefaultTarget(outcome)) {
      outcomesWithDefaultTargets.push(outcome);
    }
    if (!outcome.membership) {
      outcomesWithoutOwners.push(outcome);
    }
    if (outcome.membership) {
      if (!outcomeOwners.includes(outcome.membership.id)) {
        outcomeOwners.push(outcome.membership.id);
      }
    }
    if (!outcome.current_checkin) {
      outcomesWithoutCheckins.push(outcome);
    }
    if (outcome.membership) {
      const { membership } = outcome;
      if (!membersToOutcomesMapping[membership.id]) {
        membersToOutcomesMapping[membership.id] = {
          membership: membership,
          outcomes: [outcome],
        };
      } else {
        membersToOutcomesMapping[membership.id].outcomes.push(outcome);
      }
    }
  });

  // Functions that toggles outcomes without checkins
  const toggleWithoutCheckins = () => {
    outcomesWithoutCheckins.forEach((outcome: any) => {
      const outcomeIdClass = `outcome-${outcome.nano_slug}`;
      const outcomeRow = document.getElementsByClassName(outcomeIdClass)[0];
      if (outcome) {
        outcomeRow.classList.toggle('highlight');
      }
    });
  };

  // Functions that toggles not measurable outcomes
  const toggleNotMeasurable = () => {
    outcomesNotMeasurable.forEach((outcome: any) => {
      const outcomeIdClass = `outcome-${outcome.nano_slug}`;
      const outcomeRow = document.getElementsByClassName(outcomeIdClass)[0];
      if (outcome) {
        outcomeRow.classList.toggle('highlight');
      }
    });
  };

  // Functions that toggles with default targets
  const toggleWithDefaultTargets = () => {
    outcomesWithDefaultTargets.forEach((outcome: any) => {
      const outcomeIdClass = `outcome-${outcome.nano_slug}`;
      const outcomeRow = document.getElementsByClassName(outcomeIdClass)[0];
      if (outcome) {
        outcomeRow.classList.toggle('highlight');
      }
    });
  };

  // Functions that toggles with default targets
  const toggleWithoutOwners = () => {
    outcomesWithoutOwners.forEach((outcome: any) => {
      const outcomeIdClass = `outcome-${outcome.nano_slug}`;
      const outcomeRow = document.getElementsByClassName(outcomeIdClass)[0];
      if (outcome) {
        outcomeRow.classList.toggle('highlight');
      }
    });
  };

  // Functions that toggles with default targets
  const toggleTooManyOutcomes = (outcomes: any[]) => {
    outcomes.forEach((outcome: any) => {
      const outcomeIdClass = `outcome-${outcome.nano_slug}`;
      const outcomeRow = document.getElementsByClassName(outcomeIdClass)[0];
      if (outcome) {
        outcomeRow.classList.toggle('highlight');
      }
    });
  };

  for (const property in membersToOutcomesMapping) {
    if (membersToOutcomesMapping[property].outcomes.length > outcomesPerUserCountWarning) {
      membersWithTooManyOutcomes.push(membersToOutcomesMapping[property]);
    }
  }

  const hasTooManyObjectives = objectives.length > objectiveCountWarning ? true : false;
  const hasTooManyOutcomes = outcomes.length > outcomesCountWarning ? true : false;
  const hasOnlyOneOwner = outcomeOwners.length === 1 ? true : false;

  if (hasOnlyOneOwner) {
    warnings.push({
      key: 'onlyOneOwner',
      level: 'danger',
      docUrl: 'https://guides.tability.app/docs/about-tability/best-practices#share-ownership',
      labels: {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLocaleLowerCase(),
      },
    });
  }
  if (outcomesWithoutOwners.length > 0) {
    warnings.push({
      key: 'tooManyOutcomesWithoutOwners',
      level: 'danger',
      docUrl: 'https://guides.tability.app/docs/features/plans/owners-and-contributors',
      labels: {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLocaleLowerCase(),
      },
      callback: toggleWithoutOwners,
    });
  }
  if (outcomesWithoutCheckins.length > 0) {
    let warningLevel = outcomesWithoutCheckins.length === outcomes.length ? 'danger' : 'warning';
    let warningKey = outcomesWithoutCheckins.length === outcomes.length ? 'noCheckins' : 'someCheckinsMissing';
    warnings.push({
      key: warningKey,
      level: warningLevel,
      docUrl: 'https://guides.tability.app/docs/features/check-ins/how-to-create-check-ins',
      labels: {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLocaleLowerCase(),
      },
      callback: toggleWithoutCheckins,
    });
  }
  if (hasTooManyObjectives) {
    let warningLevel = objectives.length > objectiveCountDanger ? 'danger' : 'warning';
    let warningKey = objectives.length > objectiveCountDanger ? 'tooManyObjectivesDanger' : 'tooManyObjectives';
    warnings.push({
      key: warningKey,
      level: warningLevel,
      docUrl: null,
      labels: {
        objectives: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 2).toLocaleLowerCase(),
      },
    });
  }
  if (hasTooManyOutcomes) {
    let warningLevel = outcomes.length > outcomesCountDanger ? 'danger' : 'warning';
    let warningKey = outcomes.length > outcomesCountDanger ? 'tooManyOutcomesDanger' : 'tooManyOutcomes';
    warnings.push({
      key: warningKey,
      level: warningLevel,
      docUrl: null,
      labels: {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLocaleLowerCase(),
      },
    });
  }
  if (outcomesNotMeasurable.length > 0) {
    if ((outcomesNotMeasurable.length / outcomes.length) * 100 > outcomesNotMeasurableThreshold) {
      warnings.push({
        key: 'tooManyOutcomesNotMeasurable',
        level: 'warning',
        docUrl: 'https://guides.tability.app/docs/features/plans/editing-outcomes-key-results',
        labels: {
          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLocaleLowerCase(),
          threshold: outcomesNotMeasurableThreshold,
        },
        callback: toggleNotMeasurable,
      });
    }
  }
  if (outcomesWithDefaultTargets.length > 0) {
    if ((outcomesWithDefaultTargets.length / outcomes.length) * 100 > outcomesWithDefaultTargetsThreshold) {
      warnings.push({
        key: 'tooManyOutcomesWithDefaultTargets',
        level: 'warning',
        docUrl: 'https://guides.tability.app/docs/features/plans/editing-outcomes-key-results',
        labels: {
          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLocaleLowerCase(),
          threshold: outcomesWithDefaultTargetsThreshold,
        },
        callback: toggleWithDefaultTargets,
      });
    }
  }
  if (membersWithTooManyOutcomes.length > 0) {
    membersWithTooManyOutcomes.forEach((memberWithOutcomeHash: any) => {
      const { outcomes } = memberWithOutcomeHash;
      let warningLevel = outcomes.length > outcomesPerUserCountDanger ? 'danger' : 'warning';
      warnings.push({
        key: 'tooManyOutcomesForTheUser',
        level: warningLevel,
        docUrl: 'https://www.tability.io/odt-articles/tracking-okrs-how-many-okrs-per-owner',
        labels: {
          count: memberWithOutcomeHash.outcomes.length,
          name: memberWithOutcomeHash.membership.cached_fullname,
          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLocaleLowerCase(),
        },
        callback: () => {
          toggleTooManyOutcomes(outcomes);
        },
      });
    });
  }

  const handleToggleWarnings = (e: React.MouseEvent) => {
    setIsExpanded(!isExpanded);
    localStorage.setItem(UXPREFERENCES, JSON.stringify({ ...UXPreferences, showPlanWarnings: !isExpanded }));
  };

  const warningsCount = warnings.length;
  const counterVariant = warningsCount > 0 ? 'yellow-light' : 'neutral-light';

  return (
    <ExpandableContainer>
      <ExpandableHeader onClick={handleToggleWarnings} className={expandedClass}>
        <div>
          <KoalaIcon iconName="downChevron" />
          {t('workspacePlan.warnings.title')}
        </div>
        <KoalaTextBadge variant={counterVariant} size="small" edge="circle">
          {warningsCount}
        </KoalaTextBadge>
      </ExpandableHeader>
      {isExpanded && (
        <ExpandableContent>
          {warningsCount === 0 && <WarningItem>{t(`workspacePlan.warnings.noWarnings`)}</WarningItem>}
          {warningsCount > 0 && (
            <>
              {warnings.map((warning) => (
                <WarningItem>
                  <div className="icon">{warningLevelToIcon(warning.level)}</div>
                  <div className="content">
                    {t(`workspacePlan.warnings.${warning.key}`, warning.labels)}
                    <div className="actions">
                      {warning.docUrl && (
                        <a href={warning.docUrl} target="_blank" rel="noreferrer nofollower">
                          Learn more
                        </a>
                      )}
                      {warning.callback && (
                        <KoalaButton onClick={warning.callback} size="small" appearance="secondary">
                          highlight
                        </KoalaButton>
                      )}
                    </div>
                  </div>
                </WarningItem>
              ))}
            </>
          )}
        </ExpandableContent>
      )}
    </ExpandableContainer>
  );
};

export default PlanWarnings;
