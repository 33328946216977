// This file contains the filters for the app

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { StatsResult } from './index';
import DistributionGraph from './DistributionGraph';
import KoalaConfidencePie from 'koala/components/ConfidencePie';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${theme.devices.mobile} {
    display: none;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
`;

const StatsCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  padding: ${theme.spacing.x2};
  border-radius: 4px;

  span {
    font-weight: 700;
    display: flex;
    font-size: 3.2rem;

    &.success {
      color: ${theme.colors.green};
    }
    &.danger {
      color: ${theme.colors.red};
    }
    &.warning {
      color: ${theme.colors.yellow};
    }
    &.waiting {
      color: ${theme.colors.subtleText};
    }
    &.subtle {
      opacity: 0.4;
    }
  }

  label {
    font-size: 1rem;
    font-weight: 800;
    letter-spacing: 1px;
    color: ${theme.colors.subtleText};
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    font-weight: 800;
  }

  &.clickable {
    cursor: pointer;
    &:hover {
      background: ${theme.colors.N3};
    }
    &:active,
    &:focus {
      background: ${theme.colors.B5};
    }
  }
`;

const SideStats = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
`;

const ProgressStat = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const DistributionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  .graph {
    grid-column: 2 / 6;
  }
`;

interface PieData {
  x: number;
  y: number;
  color: string;
}
interface Props {
  outcomesStats: StatsResult | null;
  customFilterHash: any;
  updateURL: (filterHash: { [key: string]: any }) => void;
}

function SearchStats(props: Props) {
  const { outcomesStats, updateURL, customFilterHash } = props;
  const { t } = useTranslation();
  if (!outcomesStats) {
    return null;
  }

  const setCardFilter = (filter: any) => {
    const filterHash = {
      ...customFilterHash,
      ...filter,
    };
    updateURL(filterHash);
  };

  const noprogressClassname = outcomesStats.grey_outcomes_count > 0 ? 'waiting' : 'subtle';
  const onTrackClassname = outcomesStats.green_outcomes_count > 0 ? 'success' : 'subtle';
  const offTrackClassname = outcomesStats.red_outcomes_count > 0 ? 'danger' : 'subtle';
  const atRiskClassname = outcomesStats.yellow_outcomes_count > 0 ? 'warning' : 'subtle';
  const noTargetClassname = outcomesStats.pnone_outcomes_count > 0 ? '' : 'subtle';

  const data: PieData[] = [];
  let dataIndex = 1;

  if (outcomesStats.red_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: outcomesStats.red_outcomes_count,
      color: theme.colors.red,
    });
  }
  if (outcomesStats.yellow_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: outcomesStats.yellow_outcomes_count,
      color: theme.colors.yellow,
    });
  }
  if (outcomesStats.green_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: outcomesStats.green_outcomes_count,
      color: theme.colors.green,
    });
  }
  if (outcomesStats.grey_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: outcomesStats.grey_outcomes_count,
      color: theme.colors.grey,
    });
  }

  return (
    <Container>
      <StatsGrid>
        <StatsCard
          className="clickable"
          onClick={() =>
            setCardFilter({
              confidence: 'grey',
            })
          }
        >
          <label>{t('shared.confidence.pending')}</label>
          <SideStats>
            <span className={noprogressClassname}>{outcomesStats.grey_outcomes_count}</span>
          </SideStats>
        </StatsCard>
        <StatsCard
          className="clickable"
          onClick={() =>
            setCardFilter({
              confidence: 'green',
            })
          }
        >
          <label>{t('shared.confidence.onTrack')}</label>
          <SideStats>
            <span className={onTrackClassname}>{outcomesStats.green_outcomes_count}</span>
          </SideStats>
        </StatsCard>

        <StatsCard
          className="clickable"
          onClick={() =>
            setCardFilter({
              confidence: 'yellow',
            })
          }
        >
          <label>{t('shared.confidence.atRisk')}</label>
          <SideStats>
            <span className={atRiskClassname}>{outcomesStats.yellow_outcomes_count}</span>
          </SideStats>
        </StatsCard>
        <StatsCard
          className="clickable"
          onClick={() =>
            setCardFilter({
              confidence: 'red',
            })
          }
        >
          <label>{t('shared.confidence.offTrack')}</label>
          <SideStats>
            <span className={offTrackClassname}>{outcomesStats.red_outcomes_count}</span>
          </SideStats>
        </StatsCard>
        <StatsCard
          className="clickable"
          onClick={() =>
            setCardFilter({
              insights: 'without_target',
            })
          }
        >
          <label>{t('workspaceFilters.withoutTargets')}</label>
          <SideStats>
            <span className={noTargetClassname}>{outcomesStats.pnone_outcomes_count}</span>
          </SideStats>
        </StatsCard>
      </StatsGrid>
      <DistributionContainer>
        <StatsCard>
          <ProgressStat>
            <label>{t('workspaceFilters.avgCompletion')}</label>
            <SideStats>
              <KoalaConfidencePie data={data} size="large" />
              <span>{Math.round(outcomesStats.avg_outcomes_progress * 100)}%</span>
            </SideStats>
          </ProgressStat>
        </StatsCard>

        <StatsCard className="graph">
          <label>{t('workspaceFilters.progressDistribution')}</label>
          <DistributionGraph outcomesStats={outcomesStats} updateURL={updateURL} customFilterHash={customFilterHash} />
        </StatsCard>
      </DistributionContainer>
    </Container>
  );
}

export default SearchStats;
