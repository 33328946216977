import React from 'react';

const UpgradeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.32843 18.9419C7.89336 18.7868 7.62112 18.354 7.66979 17.8946L8.22178 12.6846L5 12.6846C4.63282 12.6846 4.29517 12.4833 4.12046 12.1604C3.94575 11.8374 3.96207 11.4447 4.16298 11.1374L10.4939 1.45283C10.7466 1.06628 11.2311 0.903043 11.6662 1.05787C12.1012 1.2127 12.3737 1.64532 12.3254 2.10459L11.7774 7.31544L15 7.31544C15.3673 7.31544 15.7049 7.51673 15.8796 7.83976C16.0543 8.1628 16.0379 8.55559 15.8368 8.8629L9.50105 18.5475C9.24818 18.934 8.7635 19.097 8.32843 18.9419Z"
      fill="#955BE3"
    />
  </svg>
);

export default UpgradeIcon;
