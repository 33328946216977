import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../shared/styles';
import KoalaIcon from '../Icons';
import KoalaLoader from '../Loader';

export interface IconButtonProps {
  iconName?: string;
  size?: (typeof KoalaSizes)[number] | number;
  onClick?: (e: any) => void;
  iconAppearance?: (typeof KoalaAppearances)[number];
  iconColor?: string;
  className?: string;
  style?: React.CSSProperties;
  edge?: 'square' | 'circle';
  dataFor?: string;
  disabled?: boolean;
  loading?: boolean;
  isLink?: boolean;
  to?: string;
  href?: string;
  target?: string;
  rel?: string;
  ref?: any;
  submit?: boolean;
}

const IconButton = styled.button<{ size: number | string }>`
  padding: 0.8rem;
  width: 3.8rem;
  height: 3.8rem;
  border: 0px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  &:hover {
    background: #0f0f0f19;
  }
  &:active {
    background: #0f0f0f35;
  }
  &:focus {
    border: 2px solid ${colors.B50};
    outline-offset: 2px;
  }

  &.icon-btn--circle {
    border-radius: 50%;
  }
  &.icon-btn--square {
    border-radius: 15%;
  }

  &.icon-btn--xsmall {
    width: 1.6rem;
    height: 1.6rem;
    padding: 0.2rem;
  }

  &.icon-btn--small {
    width: 2.7rem;
    height: 2.7rem;
    padding: 0.4rem;
  }
  &.icon-btn--medium {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.4rem;
  }
  &.icon-btn--large {
    width: 4rem;
    height: 4rem;
  }
  &.icon-btn--customSize {
    width: ${(props) => props.size}rem;
    height: ${(props) => props.size}rem;
  }
  &.icon-btn--primary {
    border: none;
    color: ${colors.N0};
    text-decoration: none;
    svg {
      path {
        fill: ${colors.B50};
      }
    }
    &:hover {
      background: ${colors.B5};
    }
    &:active {
      background: ${colors.B10};
    }
  }
`;

export const LinkIconButton = styled(IconButton).attrs({ as: 'a' })``;

export const LinkToIconButton = styled(IconButton).attrs({ as: Link })``;

const KoalaIconButton: React.FunctionComponent<IconButtonProps> = ({ children, ...props }) => {
  const {
    iconName,
    size,
    iconColor,
    className,
    onClick,
    edge,
    dataFor,
    disabled,
    loading,
    isLink,
    to,
    href,
    target,
    rel,
    style,
    ref,
    submit,
  } = props;

  let iconClass = className ? className : '';
  iconClass += ` icon-btn--${edge}`;
  if (typeof size === 'number') {
    iconClass += ` icon-btn--customSize`;
  } else {
    iconClass += `  icon-btn--${size}`;
  }

  let iconButtonInner;
  if (iconName) {
    iconButtonInner = <KoalaIcon iconName={iconName} iconSize={size} iconAppearance={iconColor}></KoalaIcon>;
  } else if (children) {
    iconButtonInner = children;
  }

  if (loading) {
    iconButtonInner = <KoalaLoader size={size} appearance={iconColor} />;
  }
  let type: 'button' | 'submit' = submit ? 'submit' : 'button';
  if (isLink) {
    return (
      <LinkToIconButton
        className={iconClass}
        {...{ style, to, disabled, ref, type, size }}
        data-tip={dataFor ? true : false}
        data-for={dataFor}
        size={size ?? 'medium'}
      >
        {iconButtonInner}
      </LinkToIconButton>
    );
  } else if (href) {
    return (
      <LinkIconButton
        className={iconClass}
        {...{ style, href, target, rel, disabled, ref, type }}
        data-tip={dataFor ? true : false}
        data-for={dataFor}
        type="button"
        size={size ?? 'medium'}
      >
        {iconButtonInner}
      </LinkIconButton>
    );
  } else {
    return (
      <IconButton
        className={iconClass}
        {...{ onClick, style, disabled, ref, type, size }}
        data-tip={dataFor ? true : false}
        data-for={dataFor}
        size={size ?? 'medium'}
      >
        {iconButtonInner}
      </IconButton>
    );
  }
};

KoalaIconButton.defaultProps = {
  size: 'medium',
  iconColor: colors.N60,
  edge: 'circle',
  disabled: false,
};

export default KoalaIconButton;
