import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import queryKeys from 'config/queryKeys';

// Routes
import { DASHBOARD_WORKSPACES_NEW_ROUTE } from 'routes';

// API
import { fetchCurrentUserMemberships } from 'api/remote';

import { NavLink } from 'react-router-dom';
import WorkspaceAvatar from 'components/WorkspaceAvatar';
import KoalaIcon from 'koala/components/Icons';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SidebarButton = styled(NavLink)`
  background: transparent;
  color: currentColor;
  padding: 8px;
  display: flex;
  font-weight: 400;
  font-size: 1.4rem;
  align-items: center;
  display: flex;
  gap: ${theme.spacing.x2};

  &:hover,
  &.active {
    text-decoration: none;
  }
`;

const Header = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  margin: ${theme.spacing.x1};
`;

const Footer = styled.div`
  margin: ${theme.spacing.x1} 0;
  button {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    opacity: 0.7;
    transition: 0s;
    svg {
      width: 1.6rem;

      g {
        stroke: #60aeb9;
      }
      margin-right: ${theme.spacing.x1};
    }

    &:hover {
      opacity: 1;
      background: transparent;
      text-decoration: underline;
    }
  }
`;
interface Props {
  handleCloseMobileSidebar: any;
}

function AppSidebar(props: Props) {
  const { handleCloseMobileSidebar } = props;
  const { data: membershipsResponse }: any = useQuery(queryKeys.currentUserMemberships, fetchCurrentUserMemberships, {
    staleTime: queryKeys.staleTime,
  });
  const history = useHistory();
  const { t } = useTranslation();

  let memberships = [];

  if (membershipsResponse && membershipsResponse.data) {
    memberships = membershipsResponse.data;
  }

  const handleAddWorkspace = (e: any) => {
    e.preventDefault();
    history.push(DASHBOARD_WORKSPACES_NEW_ROUTE);
  };

  return (
    <Container>
      <Header>{t('sidebar.workspaces')}</Header>
      {memberships.map((membership: any) => {
        const { workspace } = membership;
        return (
          <SidebarButton
            className="workspace-link"
            to={`/${workspace.slug}`}
            key={workspace.id}
            onClick={handleCloseMobileSidebar}
          >
            <WorkspaceAvatar workspace={workspace} />
            <span>{workspace.name}</span>
          </SidebarButton>
        );
      })}
      <Footer>
        <button onClick={handleAddWorkspace}>
          <KoalaIcon iconName="plus" iconAppearance="currentColor" />
          {t('sidebar.newWorkspace')}
        </button>
      </Footer>
    </Container>
  );
}

export default React.memo(AppSidebar);
