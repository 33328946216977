import React from 'react';

const RightChevronIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 10.0684C13.9986 10.3336 13.8919 10.5874 13.7034 10.774L7.70336 16.7108C7.31077 17.0993 6.67761 17.0959 6.28916 16.7034C5.90071 16.3108 5.90406 15.6776 6.29664 15.2892L11.5858 10.0557L6.2892 4.70339C5.90073 4.31083 5.90404 3.67768 6.2966 3.2892C6.68917 2.90073 7.32232 2.90404 7.7108 3.2966L13.7108 9.35976C13.8974 9.54828 14.0014 9.8032 14 10.0684Z"
      fill="#737373"
    />
  </svg>
);

export default RightChevronIcon;
