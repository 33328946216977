import React from 'react';
import * as TabilityTypes from 'types';
import KoalaTextBadge from 'koala/components/TextBadge';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface Props {
  outcome: TabilityTypes.Outcome;
}

const OutcomeWeight = (props: Props) => {
  const { t } = useTranslation();
  const { outcome } = props;
  if (outcome.weight === 0) {
    return (
      <>
        <KoalaTextBadge edge="circle" size="small" variant="neutral-light" dataFor={`outcome-${outcome.id}-weight`}>
          {outcome.weight}x
        </KoalaTextBadge>
        <ReactTooltip id={`outcome-${outcome.id}-weight`} place="bottom" effect="solid">
          <span>{t(`shared.zeroXWeight`)}</span>
        </ReactTooltip>
      </>
    );
  }

  if (outcome.weight === 2) {
    return (
      <>
        <KoalaTextBadge edge="circle" size="small" variant="teal-light" dataFor={`outcome-${outcome.id}-weight`}>
          {outcome.weight}x
        </KoalaTextBadge>
        <ReactTooltip id={`outcome-${outcome.id}-weight`} place="bottom" effect="solid">
          <span>{t(`shared.twoXWeight`)}</span>
        </ReactTooltip>
      </>
    );
  }
  if (outcome.weight === 3) {
    return (
      <>
        <KoalaTextBadge edge="circle" size="small" variant="violet-light" dataFor={`outcome-${outcome.id}-weight`}>
          {outcome.weight}x
        </KoalaTextBadge>
        <ReactTooltip id={`outcome-${outcome.id}-weight`} place="bottom" effect="solid">
          <span>{t(`shared.threeXWeight`)}</span>
        </ReactTooltip>
      </>
    );
  }
  return null;
};

export default OutcomeWeight;
