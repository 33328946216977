import { Emoji } from 'emoji-mart';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import theme from 'theme';
import { Membership } from 'types';
import * as reactionUtils from 'utils/reactionUtils';
import { CustomEmojiMapping } from './CustomEmojis';
import { KoalaSizes } from 'koala/shared/global';
import { useTranslation } from 'react-i18next';

interface Props {
  emoji: string;
  reactions: reactionUtils.UserReaction[];
  currentMembership: Membership;
  handleToggleEmoji: (emoji: string, e: React.SyntheticEvent) => void;
  isReadOnly: boolean;
  defaultShow?: boolean;
  size?: (typeof KoalaSizes)[number];
  customClassName?: string;
  isSquare?: boolean;
}

const EmojiButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  padding: 0.1rem ${theme.spacing.x1};
  border-radius: 16px;
  border: none;
  color: ${theme.colors.N60};
  background: ${theme.colors.N5};
  border: 2px solid transparent;
  outline: none;

  &.square-edges {
    border-radius: 4px;
    height: 2.3rem;
  }

  &.emoji-selected {
    background: ${theme.colors.B5};
    color: ${theme.colors.B50};
  }

  &.read-only {
    cursor: default;
    &:hover,
    &:active,
    &:focus {
      background: ${theme.colors.N5};
      filter: none;
    }
  }

  &:hover {
    background: ${theme.colors.N10};
  }
  &:active {
    background: ${theme.colors.N20};
  }
  &:focus {
    border: 2px solid #324dd3;
  }
`;
const EmojiContainer = styled.div`
  .tooltip {
    max-width: 300px;
    overflow-wrap: break-word;
    display: block;
  }
`;

function ReactionEmoji(props: Props) {
  const {
    emoji,
    reactions,
    currentMembership,
    handleToggleEmoji,
    isReadOnly,
    defaultShow,
    size,
    customClassName,
    isSquare,
  } = props;
  const { t } = useTranslation();
  const isSelected = !isReadOnly && reactions.some((reaction) => reaction.membershipId === currentMembership.id);

  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const id = `emoji-icon-${emoji}-${randomIndex}`;

  let numberUsers: string | number = Object.keys(reactions).length;
  let names;
  // if no users reacted and not set to show as default, don't show emoji
  if (numberUsers === 0 && !defaultShow) {
    return <></>;
    //otherwise if shown by default, set tooltip and number
  } else if (numberUsers === 0) {
    names = t('reactions.add');
    numberUsers = '';
  } else {
    names = reactionUtils.reactionNames(numberUsers, isSelected, reactions, t, currentMembership);
  }

  const emojiClass = `${isSelected ? 'emoji-selected' : ''} ${isReadOnly ? 'read-only' : ''} ${
    isSquare ? 'square-edges' : ''
  }`;

  const isCustomEmoji = CustomEmojiMapping[emoji] !== undefined;

  const emojiSize = size && size === 'small' ? 14 : 18;

  return (
    <EmojiContainer>
      <EmojiButton
        className={`${emojiClass} ${customClassName}`}
        aria-disabled={isReadOnly}
        onClick={(e) => handleToggleEmoji(emoji, e)}
        data-tip
        data-for={id}
      >
        {isCustomEmoji && <Emoji emoji={CustomEmojiMapping[emoji]} size={emojiSize} set="twitter" />}
        {!isCustomEmoji && <Emoji emoji={emoji} size={emojiSize} set="twitter" />}
        {numberUsers}
      </EmojiButton>
      <ReactTooltip
        // place="bottom"
        type="dark"
        id={id}
        className="tooltip"
        effect="solid"
        delayShow={200}
        multiline={true}
      >
        {names}
      </ReactTooltip>
    </EmojiContainer>
  );
}

export default ReactionEmoji;
