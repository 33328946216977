import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
interface Props {
  timeout: number;
}
const SessionIdleTimer = (props: Props) => {
  const { timeout } = props;
  const dispatch = useDispatch();

  const onIdle = () => {
    dispatch(setGlobalModalContent('session::timeout'));
  };

  const sessionTimeout = timeout * 60 * 1000;
  useIdleTimer({ onIdle, timeout: sessionTimeout });

  return null;
};

export default SessionIdleTimer;
