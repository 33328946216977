import React from 'react';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

// Components
import OutcomeLinkBlock from 'components/OutcomeLinkBlock';
import Loader from 'components/Loader';
import NewOutcomeLinkForm from './NewOutcomeLinkForm';

interface Props {
  outcome: any;
  isReadOnly: boolean;
}

function InitiativesTab(props: Props) {
  const { outcome, isReadOnly } = props;

  // Construct the query key using the plan Id
  const queryKeyLinks = [queryKeys.outcomeLinks, outcome.id];
  const staleTime = 0;

  // Get the plan details
  const { data: outcomeLinksResponse, isLoading }: any = useQuery(queryKeyLinks, remoteApi.fetchOutcomeLinks, {
    staleTime,
  });

  let outcomeLinks = [];
  if (outcomeLinksResponse) {
    outcomeLinks = outcomeLinksResponse.data;
  }

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="panel-tasks">
      {outcomeLinks.map((outcomeLink: any, i: number) => {
        return <OutcomeLinkBlock outcomeLink={outcomeLink} key={i} />;
      })}
      {!isReadOnly && <NewOutcomeLinkForm outcome={outcome} />}
    </div>
  );
}

export default React.memo(InitiativesTab);
