import React from 'react';

const ArrowUpIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.25C10.5523 1.25 11 1.69772 11 2.25V18.25C11 18.8023 10.5523 19.25 10 19.25C9.44772 19.25 9 18.8023 9 18.25V2.25C9 1.69772 9.44772 1.25 10 1.25Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29289 1.54289C9.68342 1.15237 10.3166 1.15237 10.7071 1.54289L18.7071 9.54289C19.0976 9.93342 19.0976 10.5666 18.7071 10.9571C18.3166 11.3476 17.6834 11.3476 17.2929 10.9571L10 3.66421L2.70711 10.9571C2.31658 11.3476 1.68342 11.3476 1.29289 10.9571C0.902369 10.5666 0.902369 9.93342 1.29289 9.54289L9.29289 1.54289Z"
      fill="#737373"
    />
  </svg>
);

export default ArrowUpIcon;
