import { createGlobalStyle, css } from 'styled-components';
import { colors, typography } from './styles';

// export const fontUrl = 'https://fonts.googleapis.com/css?family=Nunito+Sans:400,700,800,900';

export const htmlStyles = css`
  font-size: 62.5%;
`;

export const bodyStyles = css`
  font-family: apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  color: ${colors.N100};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  color: #151515;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.4rem;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${typography.weight.normal};
    margin: 0;
    padding: 0;
  }
  button,
  input,
  textarea,
  select {
    outline: none;
  }
  sub,
  sup {
    font-size: 0.8em;
  }
  sub {
    bottom: -0.2em;
  }
  sup {
    top: -0.2em;
  }
  b,
  em {
    font-weight: ${typography.weight.bold};
  }
  hr {
    border: none;
    border-top: 1px solid ${colors.N10};
    clear: both;
    margin-bottom: 1.25rem;
  }
  code,
  pre {
    font-size: ${typography.size.buttonStandard};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
    vertical-align: baseline;
    color: ${colors.B50};
  }
  pre {
    line-height: 18px;
    padding: 11px 1rem;
    white-space: pre-wrap;
    background: rgba(0, 0, 0, 0.05);
    color: ${colors.N100};
    border-radius: 3px;
    margin: 1rem 0;
  }

  input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    display: inline-block;
    padding: 0 0.8rem;
    height: 3.2rem;
    width: 100%;
    outline: 0;
    transition: border 0.2s ease;
    border: 1px solid #949494;
    border-radius: 4px;
    &.error {
      border: 1px solid #d0402e;
    }

    &.small {
      width: 50%;
    }

    &.tiny {
      width: 25%;
    }
    &:read-only {
      opacity: 0.7;
    }
  }

  .subtle {
    color: ${colors.N80};
  }

  &.ReactModal__Body--open {
    overflow: hidden;
    &.hide-intercom #intercom-container {
      display: none;
    }
  }
  .ReactModalPortal > div {
    opacity: 0;
  }
  .ReactModalPortal .ReactModal__Overlay {
    transition: all 200ms ease-in;
    &--after-open {
      opacity: 1;
    }
    &--before-close {
      opacity: 0;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
html {
  ${htmlStyles}
}
 body {
   ${bodyStyles}
 }
`;

export const KoalaSizes = ['xsmall', 'small', 'medium', 'large', 'xlarge'] as const;
export const KoalaAppearances = ['primary', 'secondary', 'subtle', 'danger', 'disabled', 'special', 'custom'] as const;

// used to change the color of a component
export const KoalaTextBadgeVariants = [
  'neutral',
  'neutral-light',
  'neutral-outlined',
  'blue',
  'blue-light',
  'blue-outlined',
  'green',
  'green-light',
  'green-outlined',
  'violet',
  'violet-light',
  'violet-outlined',
  'red',
  'red-light',
  'red-outlined',
  'teal',
  'teal-light',
  'teal-outlined',
  'yellow',
  'yellow-light',
  'yellow-outlined',
  'orange-outlined',
  'urgent',
] as const;

export const KoalaViewport = {
  mobile: `(max-width: 576px)`,
  tablet: `(max-width: 768px)`,
  laptop: `(max-width: 1024px)`,
  desktop: `(max-width: 1440px)`,
};
