import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

// Components

import AsyncCreatableSelect from 'react-select/async-creatable';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${theme.spacing.half};
  align-items: center;
  .tag-select {
    flex: 1;
    min-width: 20rem;
  }

  .tag-select-control {
    height: 30px;

    .tag-select-placeholder {
      line-height: 26px;
    }

    .tag-select-value {
      line-height: 26px !important;

      .tag-select-value-label {
        line-height: 26px;
      }
    }

    .tag-select-input {
      height: 26px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
  width: 100%;
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  setDisplayPicker: (displayPicker: boolean) => void;
}

function TagPicker(props: Props) {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const { outcome, setDisplayPicker } = props;

  const [tag_list, setTagList] = useState(outcome.cached_tag_list);
  const tagList = outcome.cached_tag_list || '';
  const tagListArray = tagList.length > 0 ? tagList.split(',') : [];
  const currentTagsForSelect = tagListArray.map((tag) => {
    return {
      label: tag.trim(),
      value: tag.trim(),
    };
  });

  // Functions to update the outcome
  const [updateOutcomeMutation]: [any, any] = useMutation(remoteApi.updateOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.currentOutcome);
      setDisplayPicker(false);
    },
  });

  const handleSave = (e: any) => {
    e.preventDefault();
    const params = {
      tag_list,
    };

    updateOutcomeMutation({
      outcomeId: outcome.id,
      outcome: params,
    });
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    setDisplayPicker(false);
  };

  const handleLoadTags = (input: string) => {
    let tagParams;
    if (input) {
      tagParams = {
        search: input,
      };
    } else {
      tagParams = {};
    }
    return remoteApi.fetchTags(queryKeys.tags, outcome.workspace_id, tagParams, 1).then((response) => {
      const tags = response.data;
      const options = tags.map((tag: any) => ({
        label: tag.name,
        value: tag.name,
      }));
      return options;
    });
  };

  const handleSelectChange = (newValue: any) => {
    const tagList = newValue.map((option: any) => option.value).join(',');
    setTagList(tagList);
  };

  const customStyles = {
    control: (base: any) => ({
      ...base,
      minHeight: 30,
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base: any) => ({
      ...base,
      padding: 4,
    }),
    multiValue: (base: any) => ({
      ...base,
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: '0px 6px',
    }),
    input: (base: any) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
  };

  return (
    <Container>
      <AsyncCreatableSelect
        className="tag-select"
        styles={customStyles}
        isClearable
        placeholder={t('shared.selectOrType') ?? 'Select or type...'}
        isMulti
        defaultOptions
        allowCreateWhileLoading={false}
        loadOptions={handleLoadTags}
        onChange={handleSelectChange}
        defaultValue={currentTagsForSelect}
      />
      <Actions>
        <KoalaButton size="small" onClick={handleSave}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel} size="small">
          {t('shared.cancel')}
        </KoalaTextButton>
      </Actions>
    </Container>
  );
}

export default React.memo(TagPicker);
