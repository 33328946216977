import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as checkinUtils from 'utils/checkinUtils';
import { Outcome, Plan } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import MarkdownContent from 'components/MarkdownContent';
import Reactions from 'components/Reactions';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaTextBadge from 'koala/components/TextBadge';
import CurrentGrowth from 'components/CurrentGrowth';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowLocale } from 'utils/dateUtils';

const Checkins = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  .checkin-block {
    margin-bottom: 0;
  }
  .checkin-container {
    padding: 0;
  }
`;
const SpacingContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  justify-content: space-between;
  align-items: center;
`;
const ContentBox = styled.div`
  background-color: #ffffff;
  border: solid 1px ${theme.colors.blockBorder};
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x3};

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.x2} ${theme.spacing.x1};
  }
`;
const More = styled.div`
  background: ${theme.colors.infoBg};
  padding: ${theme.spacing.x1};
  border-radius: 8px;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    'content content'
    'footer footer';
  column-gap: ${theme.spacing.x2};

  padding: ${theme.spacing.x2};

  .actions {
    opacity: 0;
  }

  background: #f3f3f3;
  border-radius: 8px;

  border-bottom: 0;
  margin-bottom: ${theme.spacing.x2};

  .actions {
    opacity: 1;
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }

  background: #fff;
  padding: 0;
  border-radius: 0;

  @media ${theme.devices.mobile} {
    padding: ${theme.spacing.x2};
    column-gap: ${theme.spacing.x2};
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 3.8rem;
`;

const Score = styled.div`
  font-weight: 600;
  font-size: 2rem;
`;

const Content = styled.div`
  grid-area: content;
  margin: ${theme.spacing.x2} 0;
`;

const Footer = styled.div`
  grid-area: footer;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: ${theme.spacing.x1};
  color: ${theme.colors.subtleText};

  > div {
    display: flex;
    align-items: center;
  }

  @media ${theme.devices.mobile} {
    margin-right: 1.6rem;
  }
`;

interface Props {
  outcome: Outcome;
  plan: Plan;
}

function PresentationCheckin(props: Props) {
  const { outcome, plan } = props;
  const { t, i18n } = useTranslation();
  const { current_checkin } = outcome;
  const { workspace } = plan;
  const planUrl = `${workspace.url}/plans/${plan.nano_slug}/outcomes#outcome:${outcome.nano_slug}:show`;

  const user = current_checkin && current_checkin.membership ? current_checkin.membership.user : null;
  const confidenceText = current_checkin ? checkinUtils.confidenceToScore(current_checkin.confidence, t) : 'Pending';
  const formattedScore = current_checkin ? checkinUtils.checkinScore(current_checkin, outcome, t) : 'Pending';
  const displayScore = confidenceText !== formattedScore;
  const reactions = current_checkin && current_checkin.cached_reactions;

  return (
    <Checkins>
      <SpacingContainer>
        <h3>{t('publicPlan.latestCheckin')}</h3>
      </SpacingContainer>
      <ContentBox>
        {current_checkin && (
          <>
            <Grid>
              <Gutter>
                <KoalaAvatar user={user} size={3.2} />
                {displayScore && <Score>{formattedScore}</Score>}
                <CurrentGrowth outcome={outcome} />
              </Gutter>
              <Meta>
                {outcome.outcome_type !== 'kpi' && (
                  <KoalaTextBadge variant={checkinUtils.confidenceToVariant(current_checkin.confidence)}>
                    {checkinUtils.confidenceToScore(current_checkin.confidence, t)}
                  </KoalaTextBadge>
                )}
              </Meta>
              <Content className="checkin-block-content">
                {current_checkin.body && <MarkdownContent source={current_checkin.body} />}
                {!current_checkin.body && (
                  <p className="subtle">
                    <em>{t('shared.noComment')}</em>
                  </p>
                )}
                {current_checkin.is_remote && (
                  <p className="subtle">
                    --
                    <br />
                    <em>{t('publicPlan.remote')}</em>
                  </p>
                )}
              </Content>
              <Footer className="checkin-block-footer">
                <div>{formatDistanceToNowLocale(current_checkin.checkin_date, i18n, true)}</div>
                {reactions && (
                  <div>
                    <Reactions reactions={reactions} />
                  </div>
                )}
              </Footer>
            </Grid>
            <More>
              <a href={planUrl}>
                {t('publicPlan.seeAll', {
                  initiatives: translate(workspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                })}
                →
              </a>
            </More>
          </>
        )}
        {!current_checkin && <p className="subtle">{t('publicPlan.noCheckin')}</p>}
      </ContentBox>
    </Checkins>
  );
}

export default PresentationCheckin;
