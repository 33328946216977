import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import * as amplitudeUtils from 'utils/amplitude';

import { useSelector, shallowEqual } from 'react-redux';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as workspaceUtils from 'utils/workspaceUtils';
import * as dataSourceUtils from 'utils/dataSourceUtils';
import { CustomTermKey, translate } from 'utils/customTermUtils';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import Toggle from 'components/Toggle';
import UpgradePanel from 'components/UpgradePanel';
import KoalaButton from 'koala/components/Button';
import KoalaIcon from 'koala/components/Icons';
import { useParams } from 'react-router-dom';
import KoalaLoader from 'koala/components/Loader';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextBadge from 'koala/components/TextBadge';

// Data connectors
import DependenciesSource from './DataConnectors/DependenciesSource';
import ChartMogulSource from './DataConnectors/ChartMogulSource';
import HubspotSource from './DataConnectors/HubspotSource';
import JiraSource from './DataConnectors/JiraSource';
import LinkSource from './DataConnectors/LinkSource';
import ClickupSource from './DataConnectors/ClickupSource';
import FavroSource from './DataConnectors/FavroSource';
import HelpScoutSource from './DataConnectors/HelpScoutSource';
import AsanaSource from './DataConnectors/AsanaSource';
import GithubSource from './DataConnectors/GithubSource';
import AmplitudeSource from './DataConnectors/AmplitudeSource';
import ZendeskSalesSource from './DataConnectors/ZendeskSalesSource';
import ZendeskSupportSource from './DataConnectors/ZendeskSupportSource';
import GoogleSheetSource from './DataConnectors/GoogleSheetSource';
import { useTranslation } from 'react-i18next';
import GitlabSource from './DataConnectors/GitlabSource';
import PostgresqlSource from './DataConnectors/PostgresqlSource';
import PowerBISource from './DataConnectors/PowerBISource';
import MysqlSource from './DataConnectors/MysqlSource';
import MariadbSource from './DataConnectors/MariadbSource';
import TableauSource from './DataConnectors/TableauSource';
import SalesforceSource from './DataConnectors/SalesforceSource';
import TrelloSource from './DataConnectors/TrelloSource';
import BigQuerySource from './DataConnectors/BigQuerySource';
import GoogleTasksSource from './DataConnectors/GoogleTasksSource';
import MondaySource from './DataConnectors/MondaySource';
import ChargebeeSource from './DataConnectors/ChargebeeSource';
import PagerDutySource from './DataConnectors/PagerDutySource';
import SentrySource from './DataConnectors/SentrySource';

const DisabledContainer = styled.div`
  opacity: 0.7;
  margin-top: ${theme.spacing.x2};
`;

const InlineItems = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const UpgradeInfo = styled.div`
  background: ${theme.colors.V10};
  color: ${theme.colors.V100};
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-radius: 4px;
  margin: ${theme.spacing.x1} 0;
`;

const UpgradeBanner = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  background: ${theme.colors.V50};
  color: #fff;
  padding: ${theme.spacing.x1};
  font-weight: 600;
  border-radius: 4px;
  justify-content: center;
  margin: ${theme.spacing.x2} 0;

  button {
    color: #fff;
  }

  .upgrade-icon {
    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

const PreviewWrapper = styled.div`
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 15rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }
`;

interface Props {
  outcome: TabilityTypes.Outcome;
}

function DataSources(props: Props) {
  const { outcome } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const translationKey = 'panels.editOutcome';

  const currentMembership: TabilityTypes.Membership = useSelector(
    (state: any) => state.session.currentMembership,
    shallowEqual,
  );

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.outcome.edit.datasource.view', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Data sources are only for Premium subscriptions
  const hasV4Plus = workspaceUtils.hasV4PlusSubscription(currentWorkspace);
  const hasPremium = workspaceUtils.hasPremiumSubscription(currentWorkspace);
  const hasTrial = workspaceUtils.hasTrial(currentWorkspace);
  const isAdmin = ['owner', 'admin'].includes(currentMembership.role);
  const isOwner = ['owner'].includes(currentMembership.role);

  const [data_source_origin, setDataSourceOrigin] = useState(outcome.data_source_origin || '');
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');
  const [data_source_meta, setDataSourceMeta] = useState(outcome.data_source_meta);

  // Reload the params if they have been updated in a child component
  useEffect(() => {
    setDataSourceOrigin(outcome.data_source_origin || '');
    setDataSourceType(outcome.data_source_type || '');
    setDataSourceMeta(outcome.data_source_meta);
  }, [outcome.data_source_origin, outcome.data_source_type, outcome.data_source_meta]);

  const queryCache = useQueryCache();

  // Mutation to delete existing data source
  const [deleteOutcomeDataSourceMutation, { isLoading: isDeleting }] = useMutation(remoteApi.deleteOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.currentOutcome]);
    },
  });

  const handleDeleteDataSource = () => {
    deleteOutcomeDataSourceMutation(outcome.id);
  };

  // Functions to update the toggle of the daily sync
  const [updateOutcomeMutation, { isLoading: isSavingSyncStatus }]: [any, any] = useMutation(remoteApi.updateOutcome, {
    onSuccess: (_, variables) => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcome.id]);
    },
  });

  const handleToggleDailySync = (event: React.ChangeEvent<HTMLInputElement>) => {
    const params = {
      data_source_sync_enabled: event.currentTarget.checked,
    };

    updateOutcomeMutation({
      outcomeId: outcome.id,
      outcome: params,
    });
  };

  // Retrieve the integrations to double check that we have the right connectors
  const queryKey = [queryKeys.integrations, workspaceSlug];

  const { data: integrationData, isFetching: isInitialFetch }: any = useQuery(queryKey, remoteApi.fetchIntegrations, {
    staleTime: 1000,
  });

  // Set upgrade required to true
  let upgradeRequired = true;
  let showUpgradeBanner = false;

  // Only Plus workspaces or trials should have access to data sources
  if (hasV4Plus || hasTrial || hasPremium) {
    upgradeRequired = false;
  }

  if (hasTrial) {
    showUpgradeBanner = true;
  }

  // Return upgrade panel if we don't have the Premium subscription
  if (upgradeRequired) {
    return (
      <>
        <UpgradePanel
          title={t(`${translationKey}.dataSourceUpgrade`)}
          description={t(`${translationKey}.dataSourceUpgradeDescription`)}
        />
        <DisabledContainer>
          <FormField>{t(`${translationKey}.connectDataSource`)}</FormField>
          <FormField>
            <label>{t(`${translationKey}.dataSource`)}</label>
            <select defaultValue={data_source_origin} disabled>
              <option value="">{t(`${translationKey}.noDataSource`)}</option>
            </select>
          </FormField>
          <FormField>
            <KoalaButton appearance="secondary" disabled>
              {t(`shared.connect`)}
            </KoalaButton>
          </FormField>
        </DisabledContainer>
      </>
    );
  }

  // Display loader if we're still pulling the integrations
  if (isInitialFetch) {
    return <KoalaLoader />;
  }

  const integrations = integrationData.data;

  // Hubspot data
  const notConnected = t(`${translationKey}.notConnected`) ?? ' (not connected)';
  const hasHubspot = integrations.some((integration: any) => integration.integration_type === 'hubspot');
  const hubspotLabel = `Hubspot ${hasHubspot ? '' : notConnected}`;

  // Jira data
  const hasJira = integrations.some((integration: any) => integration.integration_type === 'jira_cloud');
  const jiraLabel = `Jira ${hasJira ? '' : notConnected}`;

  // ChartMogul data
  const hasChartMogul = integrations.some((integration: any) => integration.integration_type === 'chartmogul');
  const chartmogulLabel = `ChartMogul ${hasChartMogul ? '' : notConnected}`;

  // ChartMogul data
  const hasChargebee = integrations.some((integration: any) => integration.integration_type === 'chargebee');
  const chargebeeLabel = `Chargebee ${hasChargebee ? '' : notConnected}`;

  // Clickup data
  const hasClickup = integrations.some((integration: any) => integration.integration_type === 'clickup');
  const clickupLabel = `Clickup ${hasClickup ? '' : notConnected}`;

  // Favro data
  const hasFavro = integrations.some((integration: any) => integration.integration_type === 'favro');
  const favroLabel = `Favro ${hasFavro ? '' : notConnected}`;

  // Help Scout data
  const hasHelpScout = integrations.some((integration: any) => integration.integration_type === 'helpscout');
  const helpScoutLabel = `Help Scout ${hasHelpScout ? '' : notConnected}`;

  // Asana data
  const hasAsana = integrations.some((integration: any) => integration.integration_type === 'asana');
  const asanaLabel = `Asana ${hasAsana ? '' : notConnected}`;

  //Gitlab
  const hasGitlab = integrations.some((integration: any) => integration.integration_type === 'gitlab');
  const gitlabLabel = `Gitlab ${hasGitlab ? '' : notConnected}`;

  // Amplitude
  const hasAmplitude = integrations.some((integration: any) => integration.integration_type === 'amplitude');
  const amplitudeLabel = `Amplitude ${hasAmplitude ? '' : notConnected}`;

  // Github
  const hasGithub = integrations.some((integration: any) => integration.integration_type === 'github');
  const githubLabel = `Github ${hasGithub ? '' : notConnected}`;

  // Zendesk data
  const hasZendeskSupport = integrations.some((integration: any) => integration.integration_type === 'zendesk_support');
  const zendeskSupportLabel = hasZendeskSupport ? 'Zendesk Support' : 'Zendesk Support (not connected)';

  // Zendesk Sales data
  const hasZendeskSales = integrations.some((integration: any) => integration.integration_type === 'zendesk_sales');
  const zendeskLabelSales = hasZendeskSales ? 'Zendesk Sales' : 'Zendesk Sales (not connected)';

  // PagerDuty data
  const hasPagerDuty = integrations.some((integration: any) => integration.integration_type === 'pagerduty');
  const pagerDutyLabel = `PagerDuty ${hasAmplitude ? '' : notConnected}`;

  // Google
  const googleIntegration = integrations.find((integration: any) => integration.integration_type === 'google');
  const googleLabel = (service: string) => {
    if (service === 'BigQuery') {
      if (googleIntegration) {
        return `${service}`;
      }
      return `${service} ${notConnected}`;
    }

    if (googleIntegration) {
      return `Google ${service}`;
    }
    return `Google ${service} ${notConnected}`;
  };

  // PostgreSQL
  const hasPostgresql = integrations.find((integration: any) => integration.integration_type === 'postgresql');
  const postgresqlLabel = hasPostgresql ? 'PostgreSQL' : 'PostgreSQL (not connected)';

  // PowerBI
  const hasPowerBI = integrations.find((integration: any) => integration.integration_type === 'power_bi');
  const powerBILabel = hasPowerBI ? 'Power BI' : `Power BI  ${notConnected}`;

  // MySQL
  const hasMysql = integrations.find((integration: any) => integration.integration_type === 'mysql');
  const mysqlLabel = `MySQL ${hasMysql ? '' : notConnected}`;

  // MariaDB
  const hasMariaDB = integrations.find((integration: any) => integration.integration_type === 'mariadb');
  const mariadbLabel = `MariaDB ${hasMariaDB ? '' : notConnected}`;

  // Trello
  const hasTrello = integrations.find((integration: any) => integration.integration_type === 'trello');
  const trelloLabel = `Trello ${hasTrello ? '' : notConnected}`;
  // Tableau
  const hasTableau = integrations.find((integration: any) => integration.integration_type === 'tableau');
  const tableauLabel = `Tableau ${hasTableau ? '' : notConnected}`;

  // Monday
  const hasMonday = integrations.find((integration: any) => integration.integration_type === 'monday');
  const mondayLabel = `Monday ${hasMonday ? '' : notConnected}`;

  // Salesforce
  const hasSalesforce = integrations.find((integration: any) => integration.integration_type === 'salesforce');
  const salesforceLabel = `Salesforce ${hasSalesforce ? '' : notConnected}`;
  // Sentry
  const hasSentry = integrations.find((integration: any) => integration.integration_type === 'sentry');
  const sentryLabel = `Sentry ${hasSentry ? '' : notConnected}`;

  // Generic helper method that displays a message if the user needs to connect to an integration
  const needIntegration = (integrationTitle: string) => {
    return (
      <FormField>
        <p>{t(`${translationKey}.connectWorkspace`, { title: integrationTitle })}</p>
        {isAdmin && (
          <p>
            <KoalaButton to={`/${workspaceSlug}/settings/integrations`} isLink appearance="subtle">
              {t(`${translationKey}.connectTo`, { title: integrationTitle })}
            </KoalaButton>
          </p>
        )}
      </FormField>
    );
  };

  // Generic helper method that displays a message if the user needs to connect to a personal integration
  const needPersonalIntegration = (integrationTitle: string) => {
    return (
      <FormField>
        <p>{t(`${translationKey}.connectAccount`, { title: integrationTitle })}</p>
        <p>
          <KoalaButton to={`/${workspaceSlug}/account/integrations`} isLink appearance="subtle">
            {t(`${translationKey}.connectTo`, { title: integrationTitle })}
          </KoalaButton>
        </p>
      </FormField>
    );
  };

  // Now we're here! We can display the connector logic
  return (
    <>
      {showUpgradeBanner && (
        <UpgradeBanner>
          <KoalaIcon iconName="upgrade" className="upgrade-icon" />
          {t(`shared.availableDuringTrial`, { subscription: 'Premium' })}
          {isOwner && (
            <KoalaButton size="small" appearance="secondary" isLink to={`/${currentWorkspace.slug}/settings/billing`}>
              {t('workspaceInsights.upgrade.unlock')}
            </KoalaButton>
          )}
        </UpgradeBanner>
      )}
      <FormField>{t(`${translationKey}.connectDataSource`)}</FormField>
      {outcome.data_source_origin && (
        <>
          <FormField>
            <label>{t(`${translationKey}.currentDataSource`)}</label>
            <InlineItems>
              <p>
                <KoalaTextBadge edge="circle" size="small" maxWidth="30rem" variant="neutral-light">
                  {dataSourceUtils.dataSourceOriginToLabel(outcome.data_source_origin, t)}
                </KoalaTextBadge>
              </p>
              <KoalaIconButton
                iconName="close"
                size="small"
                loading={isDeleting}
                disabled={isDeleting}
                onClick={handleDeleteDataSource}
              />
            </InlineItems>
          </FormField>
          {outcome.data_source_origin !== 'link' && (
            <FormField>
              <label style={{ display: 'flex', alignItems: 'center', gap: theme.spacing.half }}>
                {t(`${translationKey}.enableAutosync`)} {!hasPremium && <KoalaIcon iconName="upgrade" />}
              </label>
              {!hasPremium && <UpgradeInfo>{t(`${translationKey}.autosyncInfo`)}</UpgradeInfo>}
              <Toggle>
                <input
                  className="tgl tgl-light"
                  id="daily_sync_enabled"
                  type="checkbox"
                  name="daily_sync_enabled"
                  defaultChecked={outcome.data_source_sync_enabled}
                  onChange={handleToggleDailySync}
                  disabled={!hasPremium || outcome.data_source_origin === 'link'}
                />
                <label className="tgl-btn" htmlFor="daily_sync_enabled" data-tip data-for="toggleContainer" />
                <div>{t(`${translationKey}.syncProgress`)}</div>
                {isSavingSyncStatus && <KoalaLoader />}
              </Toggle>
            </FormField>
          )}
        </>
      )}
      <FormField>
        <label>{t(`${translationKey}.changeDataSource`)}</label>
        <select value={data_source_origin} onChange={(e) => setDataSourceOrigin(e.currentTarget.value)}>
          <option value="">{t(`${translationKey}.selectDataSource`)}</option>
          <option value="link">{t(`${translationKey}.link`)}</option>
          <option value="dependencies">
            {t(`${translationKey}.dependentOutcome`, {
              outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
            })}
          </option>
          <option value="amplitude">{amplitudeLabel}</option>
          <option value="asana">{asanaLabel}</option>
          <option value="big_query">{googleLabel('BigQuery')}</option>
          <option value="chartmogul">{chartmogulLabel}</option>
          <option value="chargebee">{chargebeeLabel}</option>
          <option value="clickup">{clickupLabel}</option>
          <option value="favro">{favroLabel}</option>
          <option value="github">{githubLabel}</option>
          <option value="gitlab">{gitlabLabel}</option>
          <option value="google_sheets">{googleLabel('Sheets')}</option>
          {false && (
            <>
              <option value="google_tasks">{googleLabel('Tasks')}</option>
            </>
          )}
          <option value="helpscout">{helpScoutLabel}</option>
          <option value="pagerduty">{pagerDutyLabel}</option>
          <option value="hubspot">{hubspotLabel}</option>
          <option value="jira_cloud">{jiraLabel}</option>
          <option value="mariadb">{mariadbLabel}</option>
          <option value="monday">{mondayLabel}</option>
          <option value="mysql">{mysqlLabel}</option>
          <option value="postgresql">{postgresqlLabel}</option>
          <option value="power_bi">{powerBILabel}</option>
          <option value="salesforce">{salesforceLabel}</option>
          <option value="sentry">{sentryLabel}</option>
          <option value="tableau">{tableauLabel}</option>
          <option value="trello">{trelloLabel}</option>
          {false && (
            <>
              <option value="zendesk_support">{zendeskSupportLabel}</option>
              <option value="zendesk_sales">{zendeskLabelSales}</option>
            </>
          )}
        </select>
      </FormField>
      <PreviewWrapper>
        {data_source_origin === 'dependencies' && <DependenciesSource outcome={outcome} />}
        {data_source_origin === 'link' && <LinkSource outcome={outcome} />}
        {data_source_origin === 'chartmogul' && (
          <>
            {hasChartMogul && <ChartMogulSource outcome={outcome} />}
            {!hasChartMogul && needIntegration('ChartMogul')}
          </>
        )}
        {data_source_origin === 'chargebee' && (
          <>
            {hasChargebee && <ChargebeeSource outcome={outcome} />}
            {!hasChargebee && needIntegration('Chargebee')}
          </>
        )}
        {data_source_origin === 'asana' && (
          <>
            {hasAsana && <AsanaSource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasAsana && needIntegration('Asana')}
          </>
        )}
        {data_source_origin === 'amplitude' && (
          <>
            {hasAmplitude && <AmplitudeSource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasAmplitude && needIntegration('Amplitude')}
          </>
        )}
        {data_source_origin === 'github' && (
          <>
            {hasGithub && <GithubSource outcome={outcome} />}
            {!hasGithub && needIntegration('Github')}
          </>
        )}
        {data_source_origin === 'gitlab' && (
          <>
            {hasGitlab && <GitlabSource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasGitlab && needIntegration('Gitlab')}
          </>
        )}

        {data_source_origin === 'zendesk_support' && (
          <>
            {hasZendeskSupport && <ZendeskSupportSource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasZendeskSupport && needIntegration('Zendesk Support')}
          </>
        )}
        {data_source_origin === 'zendesk_sales' && (
          <>
            {hasZendeskSales && <ZendeskSalesSource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasZendeskSales && needIntegration('Zendesk Sales')}
          </>
        )}
        {data_source_origin === 'pagerduty' && (
          <>
            {hasPagerDuty && <PagerDutySource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasPagerDuty && needIntegration('PagerDuty')}
          </>
        )}
        {data_source_origin === 'hubspot' && (
          <>
            {hasHubspot && (
              <HubspotSource
                outcome={outcome}
                setDataSourceType={setDataSourceType}
                setDataSourceMeta={setDataSourceMeta}
                data_source_type={data_source_type}
                data_source_meta={data_source_meta}
              />
            )}
            {!hasHubspot && needIntegration('Hubspot')}
          </>
        )}
        {data_source_origin === 'jira_cloud' && (
          <>
            {hasJira && <JiraSource outcome={outcome} />}
            {!hasJira && needIntegration('Jira')}
          </>
        )}
        {data_source_origin === 'clickup' && (
          <>
            {hasClickup && <ClickupSource outcome={outcome} />}
            {!hasClickup && needIntegration('Clickup')}
          </>
        )}
        {data_source_origin === 'helpscout' && (
          <>
            {hasHelpScout && <HelpScoutSource outcome={outcome} />}
            {!hasHelpScout && needIntegration('Help Scout')}
          </>
        )}
        {data_source_origin === 'google_sheets' && (
          <>
            {googleIntegration && <GoogleSheetSource outcome={outcome} integration={googleIntegration} />}
            {!googleIntegration && needPersonalIntegration('Google')}
          </>
        )}
        {data_source_origin === 'big_query' && (
          <>
            {googleIntegration && (
              <BigQuerySource outcome={outcome} workspaceSlug={workspaceSlug} integration={googleIntegration} />
            )}
          </>
        )}
        {data_source_origin === 'google_tasks' && (
          <>
            {googleIntegration && <GoogleTasksSource outcome={outcome} integration={googleIntegration} />}
            {!googleIntegration && needPersonalIntegration('Google')}
          </>
        )}
        {data_source_origin === 'postgresql' && (
          <>
            {hasPostgresql && <PostgresqlSource outcome={outcome} />}
            {!hasPostgresql && needIntegration('PostgreSQL')}
          </>
        )}
        {data_source_origin === 'power_bi' && (
          <>
            {hasPowerBI && <PowerBISource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasPowerBI && needIntegration('Power BI')}
          </>
        )}
        {data_source_origin === 'mysql' && (
          <>
            {hasMysql && <MysqlSource outcome={outcome} />}
            {!hasMysql && needIntegration('MySQL')}
          </>
        )}
        {data_source_origin === 'mariadb' && (
          <>
            {hasMariaDB && <MariadbSource outcome={outcome} />}
            {!hasMariaDB && needIntegration('MariaDB')}
          </>
        )}
        {data_source_origin === 'monday' && (
          <>
            {hasMonday && <MondaySource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasMonday && needIntegration('Monday.com')}
          </>
        )}
        {data_source_origin === 'tableau' && (
          <>
            {hasTableau && <TableauSource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasTableau && needIntegration('Tableau')}
          </>
        )}
        {data_source_origin === 'trello' && (
          <>
            {hasTrello && <TrelloSource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasTrello && needIntegration('Trello')}
          </>
        )}
        {data_source_origin === 'salesforce' && (
          <>
            {hasSalesforce && <SalesforceSource outcome={outcome} />}
            {!hasSalesforce && needIntegration('Salesforce')}
          </>
        )}
        {data_source_origin === 'sentry' && (
          <>
            {hasSentry && <SentrySource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasSentry && needIntegration('Sentry')}
          </>
        )}
        {data_source_origin === 'favro' && (
          <>
            {hasFavro && <FavroSource outcome={outcome} workspaceSlug={workspaceSlug} />}
            {!hasFavro && needIntegration('Favro')}
          </>
        )}
      </PreviewWrapper>
    </>
  );
}

export default React.memo(DataSources);
