import KoalaIcon from 'koala/components/Icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import theme from 'theme';

const Icon = styled.button`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  &.share-link-btn {
    margin: 0px;
  }

  &.recently-clicked {
    background-color: ${theme.colors.B80};
  }
`;
interface Props {
  id: string;
  link: string;
}

function ShareLinkComponent(props: Props) {
  const { id, link } = props;
  const { t } = useTranslation();
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(link);
    setHasBeenClicked(true);

    setTimeout(() => {
      setHasBeenClicked(false);
    }, 3000);
  };

  if (hasBeenClicked) {
    return (
      <>
        <Icon
          onClick={handleClick}
          key={`has-been-clicked-${id}`}
          data-tip
          data-for={`has-been-clicked-${id}`}
          className="share-link-btn recently-clicked"
        >
          <KoalaIcon iconName="link" iconAppearance="inherit" iconSize={1.2} />
        </Icon>
        <ReactTooltip id={`has-been-clicked-${id}`} type="dark" delayHide={3000} place="bottom" effect="solid">
          {t('shared.copiedToClipboard')}
        </ReactTooltip>
      </>
    );
  }
  return (
    <Icon onClick={handleClick} className="share-link-btn">
      <KoalaIcon iconName="link" iconAppearance="inherit" iconSize={1.2} />
    </Icon>
  );
}

export default ShareLinkComponent;
