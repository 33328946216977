import { ChromeSideNav, ChromeContent } from 'components/Chrome';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import KoalaButton from 'koala/components/Button';
import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import theme from 'theme';
import { Workspace, WorkspaceSettings } from 'types';
import * as remoteApi from 'api/remote';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaToggleButton from 'koala/components/ToggleButton';
import { useDispatch } from 'react-redux';
import { setCurrentWorkspaceSettings } from 'state/actions/sessionActions';

const CustomTable = styled.table`
  width: 100%;
  margin: ${theme.spacing.x2} 0;

  &.small {
    width: 75%;
  }

  td {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
    vertical-align: middle;

    .error {
      color: ${theme.colors.red};
    }

    &.term-name {
      text-transform: capitalize;
    }

    &:first-of-type {
      padding-left: 0;
      width: 12rem;
      font-weight: 700;
    }

    &.toggle-button {
      padding: 0;
      .toggle-container {
        display: flex;
        align-items: center;
        gap: ${theme.spacing.half};
      }
    }
  }

  th {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.2rem;

    &:first-of-type {
      padding-left: 0;
      width: 12rem;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

interface Props {
  workspace: Workspace;
  workspaceSettings: WorkspaceSettings;
}

function WorkspaceSettingsTerms(props: Props) {
  const { workspace, workspaceSettings } = props;
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [objectiveTerm, setObjectiveTerm] = useState({
    singular: workspace.custom_term_objective,
    plural: workspace.custom_term_objectives,
  });
  const [outcomeTerm, setOutcomeTerm] = useState({
    singular: workspace.custom_term_outcome,
    plural: workspace.custom_term_outcomes,
  });
  const [initiativeTerm, setInitiativeTerm] = useState({
    singular: workspace.custom_term_initiative,
    plural: workspace.custom_term_initiatives,
  });
  const [initiativeLabels, setInitiativeLabels] = useState<{ [key: string]: string | undefined }>({
    backlog: workspace.custom_term_backlog,
    planned: workspace.custom_term_planned,
    design: workspace.custom_term_design,
    in_progress: workspace.custom_term_in_progress,
    in_review: workspace.custom_term_in_review,
    ready: workspace.custom_term_ready,
    blocked: workspace.custom_term_blocked,
    done: workspace.custom_term_done,
    abandoned: workspace.custom_term_abandoned,
  });

  const [initiativeLabelStates, setInitiativeLabelStates] = useState<{ [key: string]: boolean }>({
    planned_state_active: workspaceSettings?.planned_state_active ?? true,
    design_state_active: workspaceSettings?.design_state_active ?? true,
    in_progress_state_active: workspaceSettings?.in_progress_state_active ?? true,
    in_review_state_active: workspaceSettings?.in_review_state_active ?? true,
    ready_state_active: workspaceSettings?.ready_state_active ?? true,
    blocked_state_active: workspaceSettings?.blocked_state_active ?? true,
    abandoned_state_active: workspaceSettings?.abandoned_state_active ?? true,
  });

  // Workspace mutations
  const [updateWorkspaceMutation, { isLoading }]: [any, any] = useMutation(remoteApi.updateWorkspace, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentWorkspace);
    },
  });
  const [updateWorkspaceSettingsMutation] = useMutation(remoteApi.updateWorkspaceSettings, {
    onSuccess: (response) => {
      queryCache.invalidateQueries(queryKeys.currentWorkspaceSettings);
      dispatch(setCurrentWorkspaceSettings(response.data));
    },
  });

  const handleOutcomeChange = (type: string, value: string) => {
    const newTerm = type === 'singular' ? { ...outcomeTerm, singular: value } : { ...outcomeTerm, plural: value };
    setOutcomeTerm(newTerm);
  };

  const handleObjectiveChange = (type: string, value: string) => {
    const newTerm = type === 'singular' ? { ...objectiveTerm, singular: value } : { ...objectiveTerm, plural: value };
    setObjectiveTerm(newTerm);
  };
  const handleInitiativeChange = (type: string, value: string) => {
    const newTerm = type === 'singular' ? { ...initiativeTerm, singular: value } : { ...initiativeTerm, plural: value };
    setInitiativeTerm(newTerm);
  };
  const handleInitiativeLabelChange = (type: string, value: string) => {
    const newInitiativeLabels = { ...initiativeLabels, [type]: value };
    setInitiativeLabels(newInitiativeLabels);
  };

  const handleSubmit = () => {
    const params = {
      custom_term_objective: objectiveTerm.singular,
      custom_term_objectives: objectiveTerm.plural,
      custom_term_outcome: outcomeTerm.singular,
      custom_term_outcomes: outcomeTerm.plural,
      custom_term_initiative: initiativeTerm.singular,
      custom_term_initiatives: initiativeTerm.plural,
      custom_term_backlog: initiativeLabels.backlog,
      custom_term_planned: initiativeLabels.planned,
      custom_term_design: initiativeLabels.design,
      custom_term_in_progress: initiativeLabels.in_progress,
      custom_term_in_review: initiativeLabels.in_review,
      custom_term_ready: initiativeLabels.ready,
      custom_term_blocked: initiativeLabels.blocked,
      custom_term_done: initiativeLabels.done,
      custom_term_abandoned: initiativeLabels.abandoned,
    };
    updateWorkspaceMutation({
      workspaceId: workspace.id,
      workspace: params,
    });

    const settingsParams = {
      planned_state_active: initiativeLabelStates.planned_state_active,
      design_state_active: initiativeLabelStates.design_state_active,
      in_progress_state_active: initiativeLabelStates.in_progress_state_active,
      in_review_state_active: initiativeLabelStates.in_review_state_active,
      ready_state_active: initiativeLabelStates.ready_state_active,
      blocked_state_active: initiativeLabelStates.blocked_state_active,
      abandoned_state_active: initiativeLabelStates.abandoned_state_active,
    };
    updateWorkspaceSettingsMutation({
      workspaceId: workspace.id,
      settings: settingsParams,
    });
  };

  const handleReset = () => {
    const params = {
      custom_term_objective: null,
      custom_term_objectives: null,
      custom_term_outcome: null,
      custom_term_outcomes: null,
      custom_term_initiative: null,
      custom_term_initiatives: null,
      custom_term_backlog: null,
      custom_term_planned: null,
      custom_term_design: null,
      custom_term_in_progress: null,
      custom_term_in_review: null,
      custom_term_ready: null,
      custom_term_blocked: null,
      custom_term_done: null,
      custom_term_abandoned: null,
    };
    updateWorkspaceMutation({
      workspaceId: workspace.id,
      workspace: params,
    });

    const settingsParams = {
      planned_state_active: true,
      design_state_active: true,
      in_progress_state_active: true,
      in_review_state_active: true,
      ready_state_active: true,
      blocked_state_active: true,
      abandoned_state_active: true,
    };
    updateWorkspaceSettingsMutation({
      workspaceId: workspace.id,
      settings: settingsParams,
    });
  };

  const toggleState = (type: string) => {
    const newValue = !initiativeLabelStates[type];
    const newInitiativeLabelStates = { ...initiativeLabelStates, [type]: newValue };
    setInitiativeLabelStates(newInitiativeLabelStates);
  };

  const stateActiveLabel = (enabled: boolean) => {
    if (enabled) {
      return t('shared.enabled');
    }
    return t('shared.disabled');
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsTerms.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsTerms.title')}</h1>
          </ContentTitle>
          <FormField>
            <p>{t('workspaceSettingsTerms.info')}</p>
          </FormField>
          <CustomTable>
            <tr>
              <th>{t('workspaceSettingsTerms.default')}</th>
              <th>{t('workspaceSettingsTerms.singular')}</th>
              <th>{t('workspaceSettingsTerms.plural')}</th>
            </tr>

            <tr>
              <td className="term-name">{t('workspaceSettingsTerms.objective')}</td>
              <td>
                <input
                  type="text"
                  value={objectiveTerm.singular}
                  placeholder={t('workspaceSettingsTerms.objective') ?? 'Objective'}
                  onChange={(e) => handleObjectiveChange('singular', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={objectiveTerm.plural}
                  placeholder={t('workspaceSettingsTerms.objectives') ?? 'Objectives'}
                  onChange={(e) => handleObjectiveChange('plural', e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="term-name">{t('workspaceSettingsTerms.outcome')}</td>
              <td>
                <input
                  type="text"
                  value={outcomeTerm.singular}
                  placeholder={t('workspaceSettingsTerms.outcome') ?? 'Outcome'}
                  onChange={(e) => handleOutcomeChange('singular', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={outcomeTerm.plural}
                  placeholder={t('workspaceSettingsTerms.outcomes') ?? 'Outcomes'}
                  onChange={(e) => handleOutcomeChange('plural', e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="term-name">{t('workspaceSettingsTerms.initiative')}</td>
              <td>
                <input
                  type="text"
                  value={initiativeTerm.singular}
                  placeholder={t('workspaceSettingsTerms.initiative') ?? 'Initiative'}
                  onChange={(e) => handleInitiativeChange('singular', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={initiativeTerm.plural}
                  placeholder={t('workspaceSettingsTerms.initiatives') ?? 'Initiatives'}
                  onChange={(e) => handleInitiativeChange('plural', e.target.value)}
                />
              </td>
            </tr>
          </CustomTable>
          <h3>
            {t('workspaceSettingsTerms.initiative_labels', {
              initiative: translate(workspace, CustomTermKey.INITIATIVE, 1),
            })}
          </h3>
          <CustomTable className="small">
            <tr>
              <td>
                <KoalaTextBadge isLowercase={true} variant={'neutral-light'} size="small" maxWidth="fit-content">
                  {t('shared.initiatives.backlog')}
                </KoalaTextBadge>
              </td>
              <td>
                <input
                  type="text"
                  value={initiativeLabels.backlog}
                  placeholder={t('shared.initiatives.backlog') ?? 'backlog'}
                  onChange={(e) => handleInitiativeLabelChange('backlog', e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <KoalaTextBadge isLowercase={true} variant={'yellow-light'} size="small" maxWidth="fit-content">
                  {t('shared.initiatives.planned')}
                </KoalaTextBadge>
              </td>
              <td>
                <input
                  type="text"
                  value={initiativeLabels.planned}
                  placeholder={t('shared.initiatives.planned') ?? 'Planned'}
                  onChange={(e) => handleInitiativeLabelChange('planned', e.target.value)}
                />
              </td>
              <td className="toggle-button">
                <div className="toggle-container">
                  <KoalaToggleButton
                    defaultChecked={initiativeLabelStates['planned_state_active']}
                    handleChange={() => toggleState('planned_state_active')}
                    size="small"
                    id="planned_state_active"
                  />
                  {stateActiveLabel(initiativeLabelStates['planned_state_active'])}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <KoalaTextBadge isLowercase={true} variant={'blue-light'} size="small" maxWidth="fit-content">
                  {t('shared.initiatives.design')}
                </KoalaTextBadge>
              </td>
              <td>
                <input
                  type="text"
                  value={initiativeLabels.design}
                  placeholder={t('shared.initiatives.design') ?? 'Design'}
                  onChange={(e) => handleInitiativeLabelChange('design', e.target.value)}
                />
              </td>
              <td className="toggle-button">
                <div className="toggle-container">
                  <KoalaToggleButton
                    defaultChecked={initiativeLabelStates['design_state_active']}
                    handleChange={() => toggleState('design_state_active')}
                    size="small"
                    id="design_state_active"
                  />
                  {stateActiveLabel(initiativeLabelStates['design_state_active'])}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <KoalaTextBadge isLowercase={true} variant={'blue-light'} size="small" maxWidth="fit-content">
                  {t('shared.initiatives.in_progress')}
                </KoalaTextBadge>
              </td>
              <td>
                <input
                  type="text"
                  value={initiativeLabels.in_progress}
                  placeholder={t('shared.initiatives.in_progress') ?? 'In progress'}
                  onChange={(e) => handleInitiativeLabelChange('in_progress', e.target.value)}
                />
              </td>
              <td className="toggle-button">
                <div className="toggle-container">
                  <KoalaToggleButton
                    defaultChecked={initiativeLabelStates['in_progress_state_active']}
                    handleChange={() => toggleState('in_progress_state_active')}
                    size="small"
                    id="in_progress_state_active"
                  />
                  {stateActiveLabel(initiativeLabelStates['in_progress_state_active'])}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <KoalaTextBadge isLowercase={true} variant={'blue-light'} size="small" maxWidth="fit-content">
                  {t('shared.initiatives.in_review')}
                </KoalaTextBadge>
              </td>
              <td>
                <input
                  type="text"
                  value={initiativeLabels.in_review}
                  placeholder={t('shared.initiatives.in_review') ?? 'In review'}
                  onChange={(e) => handleInitiativeLabelChange('in_review', e.target.value)}
                />
              </td>
              <td className="toggle-button">
                <div className="toggle-container">
                  <KoalaToggleButton
                    defaultChecked={initiativeLabelStates['in_review_state_active']}
                    handleChange={() => toggleState('in_review_state_active')}
                    size="small"
                    id="in_review_state_active"
                  />
                  {stateActiveLabel(initiativeLabelStates['in_review_state_active'])}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <KoalaTextBadge isLowercase={true} variant={'teal-light'} size="small" maxWidth="fit-content">
                  {t('shared.initiatives.ready')}
                </KoalaTextBadge>
              </td>
              <td>
                <input
                  type="text"
                  value={initiativeLabels.ready}
                  placeholder={t('shared.initiatives.ready') ?? 'Ready'}
                  onChange={(e) => handleInitiativeLabelChange('ready', e.target.value)}
                />
              </td>
              <td className="toggle-button">
                <div className="toggle-container">
                  <KoalaToggleButton
                    defaultChecked={initiativeLabelStates['ready_state_active']}
                    handleChange={() => toggleState('ready_state_active')}
                    size="small"
                    id="readdy_state_active"
                  />
                  {stateActiveLabel(initiativeLabelStates['ready_state_active'])}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <KoalaTextBadge isLowercase={true} variant={'red-light'} size="small" maxWidth="fit-content">
                  {t('shared.initiatives.blocked')}
                </KoalaTextBadge>
              </td>
              <td>
                <input
                  type="text"
                  value={initiativeLabels.blocked}
                  placeholder={t('shared.initiatives.blocked') ?? 'Blocked'}
                  onChange={(e) => handleInitiativeLabelChange('blocked', e.target.value)}
                />
              </td>
              <td className="toggle-button">
                <div className="toggle-container">
                  <KoalaToggleButton
                    defaultChecked={initiativeLabelStates['blocked_state_active']}
                    handleChange={() => toggleState('blocked_state_active')}
                    size="small"
                    id="blocked_state_active"
                  />
                  {stateActiveLabel(initiativeLabelStates['blocked_state_active'])}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <KoalaTextBadge isLowercase={true} variant={'green-light'} size="small" maxWidth="fit-content">
                  {t('shared.initiatives.done')}
                </KoalaTextBadge>
              </td>
              <td>
                <input
                  type="text"
                  value={initiativeLabels.done}
                  placeholder={t('shared.initiatives.done') ?? 'Done'}
                  onChange={(e) => handleInitiativeLabelChange('done', e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <KoalaTextBadge isLowercase={true} variant={'neutral-outlined'} size="small" maxWidth="fit-content">
                  {t('shared.initiatives.abandoned')}
                </KoalaTextBadge>
              </td>
              <td>
                <input
                  type="text"
                  value={initiativeLabels.abandoned}
                  placeholder={t('shared.initiatives.abandoned') ?? 'Abandoned'}
                  onChange={(e) => handleInitiativeLabelChange('abandoned', e.target.value)}
                />
              </td>
              <td className="toggle-button">
                <div className="toggle-container">
                  <KoalaToggleButton
                    defaultChecked={initiativeLabelStates['abandoned_state_active']}
                    handleChange={() => toggleState('abandoned_state_active')}
                    size="small"
                    id="abandoned_state_active"
                  />
                  {stateActiveLabel(initiativeLabelStates['abandoned_state_active'])}
                </div>
              </td>
            </tr>
          </CustomTable>

          <Actions>
            <KoalaButton onClick={handleSubmit} loading={isLoading} disabled={isLoading}>
              {t('shared.save')}
            </KoalaButton>
            <KoalaButton appearance="subtle" onClick={handleReset}>
              {t('workspaceSettingsTerms.reset')}
            </KoalaButton>
          </Actions>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsTerms;
