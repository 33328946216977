import React from 'react';

const SelectIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C9.73478 18 9.48043 17.8946 9.29289 17.7071L5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929C5.68342 11.9024 6.31658 11.9024 6.70711 12.2929L10 15.5858L13.2929 12.2929C13.6834 11.9024 14.3166 11.9024 14.7071 12.2929C15.0976 12.6834 15.0976 13.3166 14.7071 13.7071L10.7071 17.7071C10.5196 17.8946 10.2652 18 10 18Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29289 2.29289C9.68342 1.90237 10.3166 1.90237 10.7071 2.29289L14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711C14.3166 8.09763 13.6834 8.09763 13.2929 7.70711L10 4.41421L6.70711 7.70711C6.31658 8.09763 5.68342 8.09763 5.29289 7.70711C4.90237 7.31658 4.90237 6.68342 5.29289 6.29289L9.29289 2.29289Z"
      fill="#737373"
    />
  </svg>
);

export default SelectIcon;
