import React, { Fragment } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
// API
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { ChromeContent, ChromeNav } from 'components/Chrome';
import Loader from 'components/Loader';
import styled from 'styled-components';
import NotFound from 'components/NotFound';
import AccessDenied from 'components/AccessDenied';
import { Helmet } from 'react-helmet';
import { shallowEqual, useSelector } from 'react-redux';
import PlanNav from 'components/PlanNav';
import * as planUtils from 'utils/planUtils';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';
import PlanTitle from 'components/PlanTitle';
import PlanIconSelector from 'components/PlanIconSelector';
import ReactTooltip from 'react-tooltip';

import theme from 'theme';
import Newsfeed from './Newsfeed';
import { useTranslation } from 'react-i18next';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 0 auto ${theme.spacing.x6} auto;
  h2 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const ContentContainer = styled.div`
  margin: ${theme.spacing.x2} ${theme.spacing.x4} 0 ${theme.spacing.x8};

  @media ${theme.devices.tablet} {
    margin: ${theme.spacing.x1};
  }
`;

const PlanTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

function WorkspacePlanNotes() {
  const { planId } = useParams<{ planId: string }>();
  const { t } = useTranslation();
  const queryKey = [queryKeys.currentPlan, planId, 'notes'];
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const staleTime = 0;

  // Get the plan details
  const {
    isError,
    error,
    data: planResponse,
    isLoading: planIsLoading,
  }: any = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
  });

  const plan = planResponse ? planResponse.data : null;

  // We display the loader until we have loaded the plan
  if (!plan || planIsLoading) {
    return (
      <Fragment>
        <ChromeContent>
          <LoadingContainer>
            <Loader size="big" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspacePlan.activity.title', { plan: plan.title })} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader className="no_border">
        <WorkspaceHeader
          workspace={currentWorkspace}
          useGreyBackground={true}
          title={
            <PlanTitleContainer>
              <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
              <ReactTooltip
                place="bottom"
                type="dark"
                id="planIconSelector"
                className="tooltip"
                effect="solid"
                delayShow={200}
              >
                {t('workspacePlan.planIcon')}
              </ReactTooltip>
              <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
            </PlanTitleContainer>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeNav>
        <PlanNav plan={plan} />
      </ChromeNav>
      <ChromeContent style={{ position: 'relative' }} isGreyBackground>
        <ContentContainer>
          <Wrapper>
            <Newsfeed planId={plan.id} />
          </Wrapper>
        </ContentContainer>
      </ChromeContent>
    </Fragment>
  );
}

export default React.memo(WorkspacePlanNotes);
