/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';
import { useMutation } from 'react-query';
import theme from 'theme';

// Components
import Wordmark from 'components/Wordmark';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaButton from 'koala/components/Button';
import { Trans, useTranslation } from 'react-i18next';

// Components
const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;

  a.text {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const MetaContainer = styled.div`
  flex: 1;
  background-color: #0a5055;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 800px) {
    flex: unset;
  }
`;

const MetaContent = styled.div`
  padding: 0 12rem 8rem 12rem;
  height: 40rem;
  h1 {
    color: #fff;
    margin-top: 3.2rem;
    font-size: 6rem;
  }
  img {
    width: 15rem;
  }
  @media (max-width: 800px) {
    height: 25rem;
    padding: 4rem 12rem;

    h1 {
      font-size: 4rem;
    }
  }
`;

const Content = styled.div`
  padding: 0 12rem 8rem 12rem;
  height: 40rem;
  width: 100%;
  p {
    font-size: 2rem;
    line-height: 1.4;
  }

  span {
    border-top: 1px solid #d1d1d1;

    display: block;
    padding-top: 1.6rem;
    margin-top: 1.6rem;
    line-height: 1;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  margin-top: 3.2rem;
  flex-direction: column;
  input {
    margin-bottom: 1.6rem;
  }
`;

const ErrorList = styled.ul`
  margin-bottom: 1.6rem;
  color: #d0402e;
`;

const ReconfirmSuccess = styled.div`
  margin-bottom: 1.6rem;
  background: ${theme.colors.successBg};
  padding: ${theme.spacing.x2};
  margin: 0 0 ${theme.spacing.x2} 0;
  border-radius: 8px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 1.6rem;
  }
`;

const FormHelp = styled.div`
  color: ${theme.colors.subtleText};
  a {
    color: ${theme.colors.subtleText};
  }
  margin-top: ${theme.spacing.x3};
  padding-bottom: ${theme.spacing.x3};
`;

function SessionResetPassword() {
  const [email, setEmail] = useState('');
  const [hasSentResetLink, setHasSentResetLink] = useState(false);
  const { t } = useTranslation(undefined, { useSuspense: false });

  const [resetPasswordMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.resetPassword, {
    onSuccess: () => {
      setHasSentResetLink(true);
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      email,
    };
    resetPasswordMutation({
      user: params,
    });
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'email':
        setEmail(value);
        break;
    }
  };

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: ['Sorry, an error occurred on the remote server.'],
      };
    } else {
      errors = error.response ? error.response.data.errors : {};
    }
  }

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'email':
        return t('errors.email');
      default:
        return '';
    }
  };

  // Displays the list of templates
  return (
    <Container>
      <Helmet>
        <title>{t('sessionNewPassword.title')} | Tability</title>
      </Helmet>
      <MetaContainer>
        <MetaContent>
          <Wordmark type="light" />
          <h1>
            <Trans i18nKey="sessionNewPassword.largeText" />
          </h1>
        </MetaContent>
      </MetaContainer>
      <ContentContainer>
        <Content>
          <p>
            <b>Tability – {t('sessionNewPassword.title')}</b>
          </p>
          <Form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              className={'small'}
              autoComplete="off"
              placeholder={t('sessionNewPassword.emailPlaceholder') ?? 'Work email'}
              required={true}
              onChange={handleChange}
            />
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            {hasSentResetLink && (
              <ReconfirmSuccess>
                <Trans i18nKey="sessionNewPassword.reconfirmLink" values={{ email }} components={{ b: <b /> }} />
              </ReconfirmSuccess>
            )}
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading}>
                {t('sessionNewPassword.resetButton')}
              </KoalaButton>
            </Actions>
          </Form>
          <FormHelp>
            {t('sessionNewPassword.loginText')}{' '}
            <KoalaTextButton isLink to="/login" isInlineText>
              {t('sessionNewPassword.loginButton')}
            </KoalaTextButton>
          </FormHelp>
        </Content>
      </ContentContainer>
    </Container>
  );
}

export default SessionResetPassword;
