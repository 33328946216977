import queryKeys from 'config/queryKeys';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { Plan, Retrospective } from 'types';
import * as remoteApi from 'api/remote';
import * as routes from 'routes';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useParams, Link } from 'react-router-dom';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useHotkeys } from 'react-hotkeys-hook';
import { formatLocale } from 'utils/dateUtils';

const Container = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${theme.spacing.x1};
  padding: ${theme.spacing.x1};
`;
const EmptyState = styled.div`
  width: 100%;
  align-items: center;
  padding: ${theme.spacing.x2};
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetroRow = styled(Link)<{ selected: boolean }>`
  padding: ${theme.spacing.x2};
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: ${theme.spacing.half};
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.selected
      ? `background-color: ${theme.colors.B5};`
      : `
      background:color: #ffffff;
      :hover {
        background-color: ${theme.colors.N5};
      }
  `}

  &:hover {
    background: ${theme.colors.N3};
  }
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  plan: Plan;
}

function PlanRetrospectives(props: Props) {
  const { plan } = props;
  const { t, i18n } = useTranslation();
  const translationKey = 'workspacePlan.retrospectives';
  const dispatch = useDispatch();
  const { workspaceSlug, retrospectiveId } = useParams<{
    workspaceSlug: string;
    retrospectiveId: string;
  }>();

  useHotkeys('C', () => handleCreate(), { scopes: ['*'] });
  // Get the retrospectives
  const staleTime = 0;
  const planRetrospectivesQueryKey = [queryKeys.retrospectives, plan.id];
  const { data: retrospectivesResponse, isLoading: retrospectivesAreLoading } = useQuery(
    planRetrospectivesQueryKey,
    remoteApi.fetchRetrospectives,
    {
      staleTime,
    },
  );
  const retrospectives: Retrospective[] = retrospectivesResponse ? retrospectivesResponse.data : [];

  const handleCreate = () => {
    dispatch(setGlobalModalContent(`plan:${plan.nano_slug}:create.retrospective`));
  };

  const isLoading = retrospectivesAreLoading;
  return (
    <Container>
      <Header>
        <KoalaButton onClick={handleCreate} size="small" appearance="subtle">
          {t(`${translationKey}.create`)}
        </KoalaButton>
      </Header>
      <List>
        {isLoading && <KoalaLoader />}
        {!isLoading && retrospectives.length === 0 && <EmptyState>{t(`${translationKey}.empty`)}</EmptyState>}
        {!isLoading &&
          retrospectives.map((retro) => {
            const path = routes.WORKSPACE_RETROSPECTIVE_ROUTE.replace(':workspaceSlug', workspaceSlug)
              .replace(':planId', plan.nano_slug)
              .replace(':retrospectiveId', retro.nano_slug);

            return (
              <RetroRow to={path} selected={retrospectiveId === retro.nano_slug} className="item">
                <DateContainer>
                  <KoalaTextBadge
                    isLowercase={true}
                    variant="neutral-light"
                    edge="circle"
                    size="small"
                    className="date-label"
                  >
                    {formatLocale(retro.created_at, 'dd MMM yyyy', i18n)}
                  </KoalaTextBadge>
                  {!retro.published && (
                    <KoalaTextBadge variant="yellow-light" isLowercase={true} size="small">
                      {t('shared.status.draft')}
                    </KoalaTextBadge>
                  )}
                </DateContainer>

                <div>{retro.title}</div>
              </RetroRow>
            );
          })}
      </List>
    </Container>
  );
}
export default PlanRetrospectives;
