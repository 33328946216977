import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2141_10650)">
      <path
        d="M20 15.0001C20 16.2273 19.005 17.2223 17.7778 17.2223H2.22222C0.995 17.2223 0 16.2273 0 15.0001V5.00005C0 3.77283 0.995 2.77783 2.22222 2.77783H17.7778C19.005 2.77783 20 3.77283 20 5.00005V15.0001Z"
        fill="#006600"
      />
      <path
        d="M17.7777 2.77783H8.33325V17.2223H17.7777C19.0049 17.2223 19.9999 16.2273 19.9999 15.0001V5.00005C19.9999 3.77283 19.0049 2.77783 17.7777 2.77783Z"
        fill="#D52B1E"
      />
      <path
        d="M8.33336 5.55566C5.87892 5.55566 3.88892 7.54566 3.88892 10.0001C3.88892 12.4546 5.87892 14.4446 8.33336 14.4446C10.7878 14.4446 12.7778 12.4546 12.7778 10.0001C12.7778 7.54566 10.7878 5.55566 8.33336 5.55566ZM10.5522 8.85455L11.7128 8.08066C11.9856 8.56011 12.1589 9.10233 12.2061 9.68122L10.5522 8.85455ZM8.88892 13.3334H10.3289C9.89614 13.5929 9.40947 13.7701 8.88892 13.8446V13.3334V13.3334ZM4.46058 9.68122C4.50669 9.11344 4.67336 8.58011 4.93725 8.10789L5.82725 8.99789L4.46058 9.68122ZM4.46058 10.3184L6.54225 11.3596L6.79058 10.8623L5.06558 10.0001L6.24114 9.41233L7.02558 10.1968L7.41836 9.804L6.76503 9.15066L6.79058 9.13789L6.54225 8.64066L6.35114 8.73622L5.80947 8.19455L6.62114 8.33011L6.71225 7.78233L5.31558 7.54955C5.9178 6.809 6.78781 6.29789 7.77781 6.15622V12.7779H6.05614L7.37614 11.8979L7.06781 11.4357L5.40114 12.5468L5.40892 12.5584C4.87614 11.949 4.53003 11.174 4.46058 10.3184V10.3184ZM7.77781 13.8446C7.25725 13.7696 6.77058 13.5929 6.33781 13.3334H7.77781V13.8446ZM11.2706 12.5446L9.64114 10.9151L9.24836 11.3079L9.51003 11.5696L9.29058 11.8984L10.6106 12.7779H8.88892V6.15566C9.62281 6.26066 10.2911 6.56733 10.8328 7.02289L9.35669 7.51511L9.53225 8.04178L11.1989 7.48622L11.1417 7.31511C11.2345 7.41178 11.3211 7.51344 11.4034 7.61955L9.84558 8.65789L10 8.889L9.87558 9.13733L11.6011 10.0001L9.87614 10.8629L10.1245 11.3601L12.2061 10.319C12.1373 11.1673 11.7967 11.9373 11.2706 12.5446Z"
        fill="#FFCC4D"
      />
      <path
        d="M6.11108 7.22217V11.1111C6.11108 12.3383 7.10608 13.3333 8.33331 13.3333C9.56053 13.3333 10.5555 12.3383 10.5555 11.1111V7.22217H6.11108Z"
        fill="#D52B1E"
      />
      <path
        d="M6.66675 7.77783V11.1112C6.66675 12.0312 7.41286 12.7778 8.33341 12.7778C9.25397 12.7778 10.0001 12.0312 10.0001 11.1112V7.77783H6.66675Z"
        fill="white"
      />
      <path d="M7.22217 9.44434H9.44439V10.5554H7.22217V9.44434Z" fill="#829ACD" />
      <path d="M7.77783 8.88867H8.88894V11.1109H7.77783V8.88867Z" fill="#829ACD" />
      <path
        d="M6.66675 9.4445H7.2223V10.5556H6.66675V9.4445ZM7.77786 9.4445H8.88897V10.5556H7.77786V9.4445ZM9.44453 9.4445H10.0001V10.5556H9.44453V9.4445ZM7.77786 11.1112H8.88897V12.2223H7.77786V11.1112ZM7.77786 7.77783H8.88897V8.88894H7.77786V7.77783Z"
        fill="#003399"
      />
    </g>
    <defs>
      <clipPath id="clip0_2141_10650">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
