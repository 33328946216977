import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';

import KoalaButton from 'koala/components/Button';
import WorkspaceAvatar from 'components/WorkspaceAvatar';
import KoalaColorPicker from 'koala/components/ColorPicker';
import KoalaIconButton from 'koala/components/IconButton';
import SidebarPreview from './SidebarPreview';
import { getWorkspaceDefaultTheme, WorkspaceThemeKey, WorkspaceThemeType } from 'utils/workspaceThemeUtils';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import { Trans, useTranslation } from 'react-i18next';

const PreviewContainer = styled.div`
  max-width: 110rem;
  .subtle {
    font-size: 1.4rem;
  }

  h3 {
    margin-bottom: ${theme.spacing.x1};
  }

  position: absolute;
  right: 10%;
  top: 10vh;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  margin-bottom: ${theme.spacing.x2};
  h3 {
    margin-bottom: ${theme.spacing.x1};
  }
  ul {
    padding-left: ${theme.spacing.x2};
    display: list-item;
    margin-bottom: ${theme.spacing.x1};
  }
  li {
    list-style: circle;
  }
`;

const FormGroup = styled.div`
  margin-bottom: ${theme.spacing.x2};

  > div {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;
const FileSelection = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

const AvatarContainer = styled.div`
  margin-top: ${theme.spacing.x2};
  display: flex;
  .close-theme_icon {
    position: relative;
    top: -12px;
    right: 12px;
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingsTheme(props: Props) {
  const { workspace } = props;
  const [theme_icon, setAvatar] = useState<File>();
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  let defaultTheme = getWorkspaceDefaultTheme(workspace);
  const [selectedTheme, setSelectedTheme] = useState<WorkspaceThemeType>({
    [WorkspaceThemeKey.workspaceIconBg]: workspace.theme_icon_bg,
    [WorkspaceThemeKey.sidebarBg]: workspace.theme_bg,
    [WorkspaceThemeKey.sidebarActiveBg]: workspace.theme_link_active_bg,
    [WorkspaceThemeKey.sidebarIcon]: workspace.theme_link_icon,
    [WorkspaceThemeKey.sidebarText]: workspace.theme_link_text,
    [WorkspaceThemeKey.sidebarBadgeBg]: workspace.theme_badge_bg,
    [WorkspaceThemeKey.sidebarBadgeText]: workspace.theme_badge_text,
    [WorkspaceThemeKey.sidebarAltBg]: workspace.theme_alt_bg,
  });

  useEffect(() => {
    setSelectedTheme({
      [WorkspaceThemeKey.workspaceIconBg]: workspace.theme_icon_bg,
      [WorkspaceThemeKey.sidebarBg]: workspace.theme_bg,
      [WorkspaceThemeKey.sidebarActiveBg]: workspace.theme_link_active_bg,
      [WorkspaceThemeKey.sidebarIcon]: workspace.theme_link_icon,
      [WorkspaceThemeKey.sidebarText]: workspace.theme_link_text,
      [WorkspaceThemeKey.sidebarBadgeBg]: workspace.theme_badge_bg,
      [WorkspaceThemeKey.sidebarBadgeText]: workspace.theme_badge_text,
      [WorkspaceThemeKey.sidebarAltBg]: workspace.theme_alt_bg,
    });
  }, [workspace]);

  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  // Workspace mutations
  const [updateWorkspaceMutation] = useMutation(remoteApi.updateWorkspace, {
    onSuccess: (response) => {
      queryCache.invalidateQueries(queryKeys.currentWorkspace);
    },
  });
  // Workspace mutations
  const [updateWorkspaceAvatarMutation, { isLoading }]: [any, any] = useMutation(remoteApi.updateWorkspaceAvatar, {
    onSuccess: (response) => {
      queryCache.invalidateQueries(queryKeys.currentWorkspace);
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (theme_icon) {
      updateWorkspaceAvatarMutation({
        workspaceId: workspace.id,
        workspace: { theme_icon },
      });
    }
    if (!selectedTheme) {
      return;
    }

    const params = {
      theme_icon_bg: selectedTheme[WorkspaceThemeKey.workspaceIconBg],
      theme_bg: selectedTheme[WorkspaceThemeKey.sidebarBg],
      theme_alt_bg: selectedTheme[WorkspaceThemeKey.sidebarAltBg],
      theme_link_icon: selectedTheme[WorkspaceThemeKey.sidebarIcon],
      theme_link_text: selectedTheme[WorkspaceThemeKey.sidebarText],
      theme_link_active_bg: selectedTheme[WorkspaceThemeKey.sidebarActiveBg],
      theme_badge_bg: selectedTheme[WorkspaceThemeKey.sidebarBadgeBg],
      theme_badge_text: selectedTheme[WorkspaceThemeKey.sidebarBadgeText],
    };
    updateWorkspaceMutation({
      workspaceId: workspace.id,
      workspace: params,
    });
  };

  const openFileExplorer = () => {
    if (fileRef && fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleAvatarChange = (e: ChangeEvent<HTMLInputElement>) => {
    const ev = e.target.files;
    if (!ev || ev.length === 0) {
      return;
    }

    const image = new Image();
    let fr = new FileReader();
    fr.onload = function () {
      if (fr !== null && typeof fr.result == 'string') {
        image.src = fr.result;
      }
    };
    fr.readAsDataURL(ev[0]);
    image.onload = async function () {
      const width = image.width;
      const height = image.height;
      if (width !== height) {
        alert(t('workspaceSettingsTheme.fileSquare') ?? 'The file must be square');
      } else if (width < 150) {
        alert(t('workspaceSettingsTheme.fileMin') ?? 'The file needs to be a minimum of 150x150px');
      } else if (width > 1000) {
        alert(t('workspaceSettingsTheme.fileMax') ?? 'The file needs to less than 1000x1000px');
      } else {
        setAvatar(ev[0]);
      }
    };
  };

  const handleColorChange = (key: WorkspaceThemeKey, color?: string) => {
    const newTheme = { ...selectedTheme };
    newTheme[key] = color ?? null;
    setSelectedTheme(newTheme);
  };

  const handleClearIcon = (e: Event) => {
    e.preventDefault();
    const params = { theme_icon: null };
    updateWorkspaceMutation({
      workspaceId: workspace.id,
      workspace: params,
    });
  };

  const handleReset = (e: React.FormEvent) => {
    e.preventDefault();
    const default_params = {
      [WorkspaceThemeKey.workspaceIconBg]: defaultTheme[WorkspaceThemeKey.workspaceIconBg],
      [WorkspaceThemeKey.sidebarBg]: defaultTheme[WorkspaceThemeKey.sidebarBg],
      [WorkspaceThemeKey.sidebarActiveBg]: defaultTheme[WorkspaceThemeKey.sidebarActiveBg],
      [WorkspaceThemeKey.sidebarIcon]: defaultTheme[WorkspaceThemeKey.sidebarIcon],
      [WorkspaceThemeKey.sidebarText]: defaultTheme[WorkspaceThemeKey.sidebarText],
      [WorkspaceThemeKey.sidebarBadgeBg]: defaultTheme[WorkspaceThemeKey.sidebarBadgeBg],
      [WorkspaceThemeKey.sidebarBadgeText]: defaultTheme[WorkspaceThemeKey.sidebarBadgeText],
      [WorkspaceThemeKey.sidebarAltBg]: defaultTheme[WorkspaceThemeKey.sidebarAltBg],
    };
    setSelectedTheme(default_params);
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsTheme.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsTheme.title')}</h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <h3>{t('workspaceSettingsTheme.icon')}</h3>
              <FormField>
                <label>{t('workspaceSettingsTheme.avatarBackgroundColor')}</label>
                <KoalaColorPicker
                  handleSetColor={(color?: string) => handleColorChange(WorkspaceThemeKey.workspaceIconBg, color)}
                  defaultColor={defaultTheme[WorkspaceThemeKey.workspaceIconBg]}
                  selectedColor={selectedTheme[WorkspaceThemeKey.workspaceIconBg]}
                />
              </FormField>
              <FormField>
                <label>{t('workspaceSettingsTheme.upload')}</label>
                {t('workspaceSettingsTheme.uploadInfo')}
                <Trans i18nKey="workspaceSettingsTheme.uploadRequirements" components={{ li: <li />, ul: <ul /> }} />
                <FileSelection>
                  <KoalaButton appearance="secondary" size="small" onClick={openFileExplorer} type="button">
                    {t('workspaceSettingsTheme.fileButton')}
                  </KoalaButton>
                  <p className="subtle">{theme_icon ? theme_icon.name : t('workspaceSettingsTheme.noFile')}</p>
                  <input
                    type="file"
                    id="theme_iconInput"
                    ref={fileRef}
                    accept="image/gif, image/jpeg, image/png"
                    onChange={handleAvatarChange}
                    style={{ display: 'none' }}
                  />
                </FileSelection>

                {workspace.theme_icon_url && (
                  <AvatarContainer>
                    <WorkspaceAvatar
                      workspace={workspace}
                      size="xlarge"
                      overrideColor={
                        selectedTheme[WorkspaceThemeKey.workspaceIconBg] ??
                        defaultTheme[WorkspaceThemeKey.workspaceIconBg]
                      }
                    />
                    <KoalaIconButton
                      onClick={handleClearIcon}
                      iconName="close"
                      size="small"
                      className="close-theme_icon"
                    />
                  </AvatarContainer>
                )}
              </FormField>
            </FormGroup>
            <FormGroup>
              <h3>{t('workspaceSettingsTheme.sidebar')}</h3>
              <FormField>
                <label>{t('workspaceSettingsTheme.bg')}</label>
                <KoalaColorPicker
                  handleSetColor={(color?: string) => handleColorChange(WorkspaceThemeKey.sidebarBg, color)}
                  defaultColor={defaultTheme[WorkspaceThemeKey.sidebarBg]}
                  selectedColor={selectedTheme[WorkspaceThemeKey.sidebarBg]}
                />
              </FormField>
              <FormField>
                <label>{t('workspaceSettingsTheme.activeBg')}</label>
                <KoalaColorPicker
                  handleSetColor={(color?: string) => handleColorChange(WorkspaceThemeKey.sidebarActiveBg, color)}
                  defaultColor={defaultTheme[WorkspaceThemeKey.sidebarActiveBg]}
                  selectedColor={selectedTheme[WorkspaceThemeKey.sidebarActiveBg]}
                />
              </FormField>
              <FormField>
                <label>{t('workspaceSettingsTheme.altBg')}</label>
                <KoalaColorPicker
                  handleSetColor={(color?: string) => handleColorChange(WorkspaceThemeKey.sidebarAltBg, color)}
                  defaultColor={defaultTheme[WorkspaceThemeKey.sidebarAltBg]}
                  selectedColor={selectedTheme[WorkspaceThemeKey.sidebarAltBg]}
                />
              </FormField>
              <FormField>
                <label>{t('workspaceSettingsTheme.iconColor')}</label>
                <KoalaColorPicker
                  handleSetColor={(color?: string) => handleColorChange(WorkspaceThemeKey.sidebarIcon, color)}
                  defaultColor={defaultTheme[WorkspaceThemeKey.sidebarIcon]}
                  selectedColor={selectedTheme[WorkspaceThemeKey.sidebarIcon]}
                />
              </FormField>
              <FormField>
                <label>{t('workspaceSettingsTheme.textColor')}</label>
                <KoalaColorPicker
                  handleSetColor={(color?: string) => handleColorChange(WorkspaceThemeKey.sidebarText, color)}
                  defaultColor={defaultTheme[WorkspaceThemeKey.sidebarText]}
                  selectedColor={selectedTheme[WorkspaceThemeKey.sidebarText]}
                />
              </FormField>
            </FormGroup>
            <FormGroup>
              <h3>{t('workspaceSettingsTheme.badge')}</h3>
              <FormField>
                <label>{t('workspaceSettingsTheme.badgeBg')}</label>
                <KoalaColorPicker
                  handleSetColor={(color?: string) => handleColorChange(WorkspaceThemeKey.sidebarBadgeBg, color)}
                  defaultColor={defaultTheme[WorkspaceThemeKey.sidebarBadgeBg]}
                  selectedColor={selectedTheme[WorkspaceThemeKey.sidebarBadgeBg]}
                />
              </FormField>
              <FormField>
                <label>{t('workspaceSettingsTheme.badgeText')}</label>
                <KoalaColorPicker
                  handleSetColor={(color?: string) => handleColorChange(WorkspaceThemeKey.sidebarBadgeText, color)}
                  defaultColor={defaultTheme[WorkspaceThemeKey.sidebarBadgeText]}
                  selectedColor={selectedTheme[WorkspaceThemeKey.sidebarBadgeText]}
                />
              </FormField>
            </FormGroup>
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading}>
                {t('shared.save')}
              </KoalaButton>
            </Actions>
          </Form>
          <PreviewContainer>
            <h3>{t('workspaceSettingsTheme.preview')}</h3>
            <SidebarPreview selectedTheme={selectedTheme} defaultTheme={defaultTheme} workspace={workspace} />
            <KoalaButton onClick={handleReset} appearance="secondary">
              {t('workspaceSettingsTheme.reset')}
            </KoalaButton>
          </PreviewContainer>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsTheme;
