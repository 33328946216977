import React from 'react';

const CommentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.99998C6.13402 2.99998 3.00001 6.13399 3.00001 9.99998C3.00001 11.2767 3.34087 12.4707 3.93587 13.4993C4.06876 13.729 4.10488 14.0021 4.03629 14.2585L3.40143 16.6312L5.88785 16.0283C6.13184 15.9691 6.38967 16.0038 6.60921 16.1256C7.61281 16.6825 8.76783 17 10 17C13.866 17 17 13.866 17 9.99998C17 6.13399 13.866 2.99998 10 2.99998ZM5.993 18.0607C7.20062 18.6621 8.5621 19 10 19C14.9706 19 19 14.9705 19 9.99998C19 5.02942 14.9706 0.999985 10 0.999985C5.02945 0.999985 1.00001 5.02942 1.00001 9.99998C1.00001 11.4863 1.36107 12.8909 2.00078 14.1282L1.03399 17.7415C0.942767 18.0825 1.03802 18.4462 1.28463 18.6987C1.53123 18.9512 1.89267 19.055 2.23566 18.9718L5.993 18.0607Z"
      fill="#737373"
    />
  </svg>
);
export default CommentIcon;
