import ValidationError from 'koala/shared/images/icons/ValidationError';
import ValidationSuccess from 'koala/shared/images/icons/ValidationSuccess';
import ValidationWarning from 'koala/shared/images/icons/ValidationWarning';
import { colors, spacing } from 'koala/shared/styles';
import React, { FC } from 'react';
import styled from 'styled-components';

const ValidationContainer = styled.div`
  display: flex;
  align-items: center;
  &.validation--success {
    color: ${colors.G50};
  }
  &.validation--warning {
    color: ${colors.Y60};
  }
  &.validation--error {
    color: ${colors.R70};
  }
`;
const ValidationIconContainer = styled.div`
  margin-right: ${spacing.half};
`;
const ValidationMessage = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
`;

export interface InputValidationProps {
  state: 'success' | 'warning' | 'error';
  text: string;
  className?: string;
  style?: React.CSSProperties;
}

const KoalaInputValidation: FC<InputValidationProps> = ({ children = 'Text', ...props }) => {
  const { state, text } = props;

  const stateClass = `validation--${state}`;

  let validationIcon;
  switch (state) {
    case 'success':
      validationIcon = <ValidationSuccess />;
      break;
    case 'warning':
      validationIcon = <ValidationWarning />;
      break;
    case 'error':
      validationIcon = <ValidationError />;
      break;
  }

  return (
    <ValidationContainer className={stateClass}>
      <ValidationIconContainer>{validationIcon}</ValidationIconContainer>
      <ValidationMessage>{text}</ValidationMessage>
    </ValidationContainer>
  );
};

KoalaInputValidation.defaultProps = {
  text: 'default message',
};

export default KoalaInputValidation;
