import React from 'react';
import styled from 'styled-components';
import { DashboardWidget } from 'types';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';
import { DragHandle, WidgetContainer, WidgetHeader } from '.';
import MarkdownContent from 'components/MarkdownContent';

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${theme.spacing.x2};
  overflow: auto;

  .ql-editor {
    padding: 0px;
  }
`;

interface Props {
  widget: DashboardWidget;
}

function TextBlockWidget(props: Props) {
  const { widget } = props;
  const { t } = useTranslation();
  const widgetTitle: string = widget.title ? widget.title : t('workspaceDashboards.textBlock');
  // Query keys and query params

  const text = widget.widget_meta.text;

  if (!text) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <ContentContainer>
          <p>{t('workspaceDashboards.widgetError')}</p>
        </ContentContainer>
      </WidgetContainer>
    );
  }

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <ContentContainer>
        <MarkdownContent source={text} />
      </ContentContainer>
    </WidgetContainer>
  );
}

export default TextBlockWidget;
