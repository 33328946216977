import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import GithubIcon from 'components/_assets/IconGithub.png';
import KoalaButton from 'koala/components/Button';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import queryKeys from 'config/queryKeys';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
  state: string;
}

function GithubIntegration(props: Props) {
  const { workspace, integrations, state } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallGithubMutation, { isLoading: isUninstallingGithub }] = useMutation(remoteApi.uninstallGithub, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const githubIntegration = integrations.filter((i: Integration) => i.integration_type === 'github')[0];
  const githubOAuthURL =
    `https://github.com/login/oauth/authorize` +
    `?scope=read:org%20user%20project%20repo` +
    `&client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&state=${state}`;
  const uninstallGithubLabel = isUninstallingGithub ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove');

  const handleUninstallGithub = (e: any) => {
    e.preventDefault();
    uninstallGithubMutation(workspace.slug);
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={GithubIcon} alt="Github" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'Github' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Github' })}</p>
        <p>
          <a
            href="https://guides.tability.app/docs/integrations/data-sources"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {githubIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: githubIntegration.connected_to }}
              />
            </div>
            <KoalaButton href={githubOAuthURL} appearance="secondary" size="small">
              {t('workspaceSettingsIntegrations.reinstall')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={handleUninstallGithub}>
              {uninstallGithubLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!githubIntegration && (
          <KoalaButton href={githubOAuthURL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Github' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default GithubIntegration;
