import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';

// Components
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import OutcomeExpandable from 'components/OutcomeExpandable';
import { AxiosResponse } from 'axios';
import { CheckinsListHeader } from './CheckinsList';

const Wrapper = styled.div`
  margin-top: ${theme.spacing.x1};
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

const OutcomeList = styled.div`
  .outcome-expanded {
    border-bottom: 1px solid ${theme.colors.blockBorder};
  }
  .outcome-expanded:last-of-type {
    border-bottom: 0;
  }
`;

interface Props {
  activeCheckins: AxiosResponse<TabilityTypes.Outcome[]>[];
  canFetchMore: boolean;
  isFetchingMore: boolean;
  fetchMore: () => void;
}

function ActiveOutcomesList(props: Props) {
  // Query that fetches all the outcomes with pending checkins
  const { t } = useTranslation();
  const { canFetchMore, fetchMore, isFetchingMore, activeCheckins } = props;
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  return (
    <Wrapper>
      <CheckinsListHeader>
        <h4>
          {t('workspaceInbox.activeOutcomes', {
            outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
          })}
        </h4>
      </CheckinsListHeader>
      <OutcomeList>
        {activeCheckins.map((group, i: number) => {
          return (
            <Fragment key={i}>
              {group.data.map((outcome: TabilityTypes.Outcome, index: number) => {
                return <OutcomeExpandable outcome={outcome} hideTags={true} hideExpand={true} key={index} />;
              })}
            </Fragment>
          );
        })}
      </OutcomeList>

      {canFetchMore && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMore()}
            loading={isFetchingMore}
            disabled={isFetchingMore}
            appearance="secondary"
          >
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
    </Wrapper>
  );
}

export default ActiveOutcomesList;
