import React, { useRef, useState, useEffect } from 'react';
import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useMutation, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import queryKeys from 'config/queryKeys';
import ReactTooltip from 'react-tooltip';
import { CONTEXT_STORAGE_KEY } from 'config/constants';
import { Initiative, Membership } from 'types';
import _ from 'lodash';

// API
import * as remoteApi from 'api/remote';

import MentionBox from 'components/MentionBox';

import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import styled from 'styled-components';
import theme from 'theme';
import PreviousStandup from './PreviousStandup';
import { startOfDay, subDays, subWeeks } from 'date-fns';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const ShortcutContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.x1};
  margin: ${theme.spacing.x1} 0px;
`;
const Wrapper = styled.div`
  .ql-editor {
    min-height: 35rem;
  }
`;

const Title = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

interface Props {}

function CreateStandup(props: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [error, setError] = useState('');
  const translateKey = 'modals.createStandup';

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  // Error management
  const [errorStandupsLimitExceeded, setErrorStandupsLimitExceeded] = useState(false);
  const storageKey = `${CONTEXT_STORAGE_KEY}_standup_${currentMembership.id}`;

  // Standup mutations
  const [createStandupMutation, { isLoading }]: [any, any] = useMutation(remoteApi.createStandup, {
    onSuccess: () => {
      sessionStorage.removeItem(storageKey);
      queryCache.invalidateQueries(queryKeys.standups);
      queryCache.invalidateQueries(queryKeys.currentUserMemberships);
      setBody('');
      dispatch(setGlobalModalContent(''));
    },
    onError: (error: any) => {
      const errorText = error.response.data.error;
      if (errorText.includes('standups limit') && error.response.status === 401) {
        setErrorStandupsLimitExceeded(true);
      }
    },
  });

  const [searchInitiativesMutation]: [any, any] = useMutation(remoteApi.searchInitiativesForMutation);

  const dailyStandupTemplate = `<p>${t(`${translateKey}.dailyPrevious`)}</p><ul><li>${t(
    `${translateKey}.action`,
  )}</li></ul><p>${t(`${translateKey}.dailyToday`)}</p><ul><li>${t(`${translateKey}.action`)}</li></ul><p>${t(
    `${translateKey}.blockers`,
  )}</p><ul><li>${t(`${translateKey}.blocker`)}</li></ul>`;

  const weeklyStandupTemplate = `<p>${t(`${translateKey}.weeklyPrevious`)}</p><ul><li>${t(
    `${translateKey}.action`,
  )}</li></ul><p>${t(`${translateKey}.weeklyToday`)}</p><ul><li>${t(`${translateKey}.action`)}</li></ul><p>${t(
    `${translateKey}.blockers`,
  )}</p><ul><li>${t(`${translateKey}.blocker`)}</li></ul>`;

  let defaultBody = dailyStandupTemplate;
  if (currentMembership.standup_frequency === 'weekly') {
    defaultBody = weeklyStandupTemplate;
  }

  // Form values
  const [body, setBody] = useState(defaultBody);

  useEffect(() => {
    debounceStoreText.current(body);
  }, [body]);

  useEffect(() => {
    const savedContext = sessionStorage.getItem(storageKey) || '';
    let plainContext = new DOMParser().parseFromString(savedContext, 'text/html');

    const body = plainContext.body.textContent === '' ? defaultBody : savedContext;
    setBody(body);
  }, [storageKey, defaultBody]);

  const storeText = (newText: string) => {
    if (newText) {
      sessionStorage.setItem(storageKey, newText);
    }
  };

  const debounceStoreText = useRef(
    _.debounce((newText: string) => storeText(newText), 1000, {
      maxWait: 5000,
    }),
  );

  const handleSubmit = () => {
    const standup = {
      body,
    };
    createStandupMutation({
      workspaceSlug,
      standup,
    });
  };

  const standupsDisabled =
    (currentWorkspace.billing_standups_limit > 0 &&
      currentWorkspace.standups_count >= currentWorkspace.billing_standups_limit) ||
    errorStandupsLimitExceeded;

  const editorPlaceholder: string = t('workspaceStandups.newPlaceholder');

  const addTasksToStandup = (headerText: string, data: Initiative[]) => {
    if (data.length < 0) {
      return;
    }
    const header = `<p>${headerText}:</p><ul>`;

    const path = `https://${process.env.REACT_APP_DOMAIN}/${workspaceSlug}/standups`;
    const textList = data.map((initiative) => {
      const initiativePath = path.concat(`#initiative:${initiative.nano_slug}:show`);
      return `<li><a href="${initiativePath}" rel="noopener noreferrer" target="_blank">${initiative.title}</a></li>`;
    });
    textList.push('</ul>');
    setBody((prev) => prev.concat(header, ...textList));
  };

  const handleAddInProgress = async () => {
    setError('');
    const filter = {
      is_in_progress: true,
      membership_id: [currentMembership.id],
      work_state: ['planned', 'design', 'in_progress', 'in_review', 'ready', 'blocked'],
    };
    try {
      const result = await searchInitiativesMutation({
        workspaceSlug,
        filter,
      });
      addTasksToStandup(
        t(`${translateKey}.inProgress`, { initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2) }),
        result.data ?? [],
      );
    } catch (error) {
      const errorText = t(`${translateKey}.inProgressError`);
      setError(errorText);
    }
  };

  const handleCompletedLastWeek = async () => {
    setError('');
    const filter = {
      closed_after_date: subWeeks(startOfDay(new Date()), 1),
      membership_id: [currentMembership.id],
      work_state: ['done'],
    };
    try {
      const result = await searchInitiativesMutation({
        workspaceSlug,
        filter,
      });
      addTasksToStandup(
        t(`${translateKey}.lastWeek`, { initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2) }),
        result.data ?? [],
      );
    } catch (error) {
      const errorText = t(`${translateKey}.recentlyCompleteError`);
      setError(errorText);
    }
  };

  const handleCompletedYesterday = async () => {
    setError('');
    const filter = {
      closed_after_date: subDays(startOfDay(new Date()), 1),
      membership_id: [currentMembership.id],
      work_state: ['done'],
    };
    try {
      const result = await searchInitiativesMutation({
        workspaceSlug,
        filter,
      });
      addTasksToStandup(
        t(`${translateKey}.yesterday`, { initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2) }),
        result.data ?? [],
      );
    } catch (error) {
      const errorText = t(`${translateKey}.recentlyCompleteError`);
      setError(errorText);
    }
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <Title>
          <h2>{t(`modals.createStandup.title`)}</h2>
        </Title>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <PreviousStandup />
        <Wrapper>
          <MentionBox
            setValue={setBody}
            value={body}
            placeholder={editorPlaceholder}
            comment={true}
            cmdEnterCallback={handleSubmit}
          />
        </Wrapper>
        <ShortcutContainer>
          <KoalaButton appearance="subtle" prefixIcon="plus" size="small" onClick={handleAddInProgress}>
            {t(`${translateKey}.inProgress`, { initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2) })}
          </KoalaButton>
          <KoalaButton appearance="subtle" prefixIcon="plus" size="small" onClick={handleCompletedLastWeek}>
            {t(`${translateKey}.lastWeek`, { initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2) })}
          </KoalaButton>
          <KoalaButton appearance="subtle" prefixIcon="plus" size="small" onClick={handleCompletedYesterday}>
            {t(`${translateKey}.yesterday`, { initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2) })}
          </KoalaButton>
          {error && <p>{error}</p>}
        </ShortcutContainer>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSubmit} disabled={isLoading || standupsDisabled} loading={isLoading}>
          {t('shared.save')}
        </KoalaButton>

        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
        {errorStandupsLimitExceeded && <p>{t('workspaceStandups.exceededQuota')}</p>}
        {standupsDisabled && (
          <ReactTooltip place="bottom" type="dark" id="standup-actions" className="tooltip" effect="solid">
            {t('workspaceStandups.upgrade.enable')}
          </ReactTooltip>
        )}
      </ModalFooter>
    </ModalGrid>
  );
}

export default CreateStandup;
