import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  padding: 0px 16px;
  height: 2rem;
  line-height: 2rem;
  border-radius: 4px;
  font-size: 1.2rem;
  background-color: ${theme.colors.N10};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  max-width: 20rem;
  display: inline-block;

  &.no-value {
    opacity: 0.5;
  }

  &:hover {
    background: ${theme.colors.N20};
  }

  &.timeline {
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 2.4rem;
    height: 2.4rem;
    font-weight: 800;
  }

  &.due-date {
    display: inline-flex;
    align-items: center;
    background: transparent;

    svg {
      margin-right: 0.4rem;
      width: 2rem;
    }

    &:hover {
      background: ${theme.colors.N10};
    }
  }
`;
