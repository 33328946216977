import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import * as remoteApi from 'api/remote';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import request from 'state/utils/request';
import { Segment, Team } from 'types';
import { addDays, endOfYear, startOfYear, subDays } from 'date-fns';
import 'theme/DatePicker.css';
import DatePicker from 'react-datepicker';
import { ValueType } from 'react-select';
import * as routes from 'routes';
import queryKeys from 'config/queryKeys';
import { AxiosResponse } from 'axios';

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x1};
`;

const FilterWrapper = styled.div`
  display: grid;
  grid-template-areas: 'label label' 'select button';
  align-items: center;
  column-gap: ${theme.spacing.half};
  label {
    grid-area: label;
    color: ${theme.colors.subtleText};
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1rem;
    display: block;
    margin-bottom: 0.4rem;
  }
`;

const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  font-size: 1.4rem;
  small {
    display: block;
    max-width: 4rem;
  }
`;

interface OptionType {
  value: string;
  label: string;
}

interface Props {
  updateURL: (filterHash: { [key: string]: any }) => void;
  customFilterHash: { [key: string]: any };
}

function MapFilters(props: Props) {
  const { customFilterHash, updateURL } = props;
  const { workspaceSlug, filter } = useParams<{
    workspaceSlug: string;
    filter: string;
  }>();

  const { t } = useTranslation();
  const history = useHistory();
  const [selectedSection, setSelectedSection] = useState<Segment>();

  const filterHash = { ...customFilterHash };
  const start_at: Date = filterHash['start_at'] ? new Date(filterHash['start_at']) : new Date();
  const finish_at: Date = filterHash['finish_at'] ? new Date(filterHash['finish_at']) : new Date();

  const loadSegmentOptions = (input: string) => {
    const filter = input ? { title: input } : {};
    return remoteApi
      .fetchMapSegments(queryKeys.segments, workspaceSlug, filter)
      .then((response: AxiosResponse<Segment[]>) => response.data);
  };

  const debouncedLoadOptions: any = useRef(
    _.debounce((inputValue: string, callback: any) => loadOptions(inputValue, callback), 200, {
      maxWait: 2000,
    }),
  );

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  const loadOptions: any = (inputValue: string, callback: any) => {
    // Get default users if nothing selected yet
    const teamsApiURL = remoteApi.TEAMS_API.replace(':workspaceSlug', workspaceSlug || '');
    if (!inputValue) {
      const teamsParams = {
        per_page: 10,
      };
      request
        .get(teamsApiURL, { params: teamsParams })
        .then((response) => {
          const teams = response.data;
          if (teams && teams.length > 0) {
            const options = teams.map((team: Team) => {
              return {
                value: team.id,
                label: team.name,
              };
            });
            return options;
          } else {
            return [];
          }
        })
        .then(callback);
      return;
    }

    // If we have a search param, use it
    const teamsParams = inputValue
      ? {
          name: inputValue,
          per_page: 10,
        }
      : null;
    request
      .get(teamsApiURL, { params: teamsParams })
      .then((response) => {
        const teams = response.data;
        if (teams && teams.length > 0) {
          const options = teams.map((team: Team) => {
            return {
              value: team.id,
              label: team.name,
            };
          });
          return options;
        } else {
          return [];
        }
      })
      .then(callback);
  };

  const updateFilters = (attribute: string, value: any) => {
    filterHash[attribute] = value;
    updateURL(filterHash);
  };

  const updateTimelineFilter = (attribute: string, options: ValueType<KoalaSelectOption, boolean>) => {
    const option = options ? (options as KoalaSelectOption) : null;
    filterHash[attribute] = option?.value;

    if (attribute === 'timeline' && option?.value === 'custom') {
      const refDate = Date.now();
      const start_at = subDays(startOfYear(refDate), 1).toISOString();
      const finish_at = addDays(endOfYear(refDate), 1).toISOString();
      filterHash.start_at = start_at;
      filterHash.finish_at = finish_at;
    }

    if (filterHash.timeline === null) {
      filterHash.start_at = null;
      filterHash.finish_at = null;
    }
    updateURL(filterHash);
  };

  const handleSegmentChange = (segment: any) => {
    setSelectedSection(segment);
    if (!segment) {
      history.push(
        routes.WORKSPACE_MAP_V2_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(':filter', filter),
      );
    } else {
      history.push(
        routes.WORKSPACE_MAP_V2_SEGMENT_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug)
          .replace(':filter', segment.filter_hash)
          .replace(':segmentId', segment.nano_slug),
      );
    }
  };

  const handleSelectDate = (selectId: string, date: Date | null) => {
    if (date) {
      updateFilters(selectId, date.toISOString());
    }
  };

  const updatePlanStatus = (options: ValueType<KoalaSelectOption, boolean>) => {
    const option = options ? (options as KoalaSelectOption) : null;
    updateFilters('status', option?.value);
  };

  const getSelectedSingleOptionFromFilter = (selectId: string, options: OptionType[]) => {
    if (typeof filterHash[selectId] !== 'undefined') {
      return options.find((o) => o.value === filterHash[selectId]);
    }
    return null;
  };

  const timelineOptions = [
    { label: t('shared.timelineThisQuarter'), value: 'current_quarter' },
    { label: t('shared.timelineNextQuarter'), value: 'next_quarter' },
    { label: t('shared.timelineLastQuarter'), value: 'last_quarter' },
    { label: t('shared.timelineCustom'), value: 'custom' },
  ];

  const planStatusOptions = [
    { label: 'Planning', value: t('workspaceFilters.planning') },
    { label: 'Active', value: t('workspaceFilters.active') },
  ];

  const selectedTimeline = getSelectedSingleOptionFromFilter('timeline', timelineOptions);
  const selectedStatus = getSelectedSingleOptionFromFilter('status', planStatusOptions);

  return (
    <FiltersContainer>
      {false && (
        <FilterWrapper>
          <label>{t('workspaceFilters.view')}</label>
          <KoalaSelect
            isClearable
            size="xsmall"
            id="segment"
            placeholder={t('workspaceFilters.placeholder')}
            selectedOption={selectedSection}
            loadOptions={loadSegmentOptions}
            handleChange={(option) => handleSegmentChange(option)}
            labelFormat={(option) => option.title}
            getOptionValue={(option: Segment) => option.id}
          />
        </FilterWrapper>
      )}
      <FilterWrapper>
        <label>{t('workspaceFilters.planStatus')}</label>
        <KoalaSelect
          isClearable
          size="xsmall"
          id="status"
          placeholder={t('workspaceFilters.placeholder')}
          options={planStatusOptions}
          handleChange={(options) => updatePlanStatus(options)}
          selectedOption={selectedStatus}
        />
      </FilterWrapper>
      <FilterWrapper>
        <label>{t('workspaceFilters.teams')}</label>
        <KoalaSelect
          isMultiSelect
          isClearable
          handleChange={(options) => updateFilters('team', options)}
          placeholder={t('workspaceFilters.placeholder')}
          selectedOption={filterHash['team']}
          loadOptions={debouncedLoadOptions.current}
          size="xsmall"
          id="team"
        />
      </FilterWrapper>
      <FilterWrapper>
        <label>{t('workspaceFilters.timeline')}</label>
        <KoalaSelect
          isClearable
          size="xsmall"
          id="timeline"
          placeholder={t('workspaceFilters.placeholder')}
          options={timelineOptions}
          handleChange={(options) => updateTimelineFilter('timeline', options)}
          selectedOption={selectedTimeline}
        />
      </FilterWrapper>
      {selectedTimeline && selectedTimeline.value === 'custom' && (
        <FilterWrapper>
          <label>{t('workspaceFilters.customTimeline')}</label>
          <DatesWrapper>
            <small className="subtle">{t('workspaceFilters.customTimelineStart')}</small>
            <DatePicker
              selected={start_at}
              onChange={(date: Date | null) => handleSelectDate('start_at', date)}
              dateFormat="d MMM yyyy"
            />
            <small className="subtle">{t('workspaceFilters.customTimelineEnd')}</small>
            <DatePicker
              selected={finish_at}
              onChange={(date: Date | null) => handleSelectDate('finish_at', date)}
              dateFormat="d MMM yyyy"
            />
          </DatesWrapper>
        </FilterWrapper>
      )}
    </FiltersContainer>
  );
}

export default MapFilters;
