import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Outcome } from 'types';
import KoalaProgressBar from 'koala/components/ProgressBar';
import { formattedScore } from 'utils/checkinUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const ProgressDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  label {
    color: ${theme.colors.N60};
    font-weight: 800;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  &.with-gap {
    margin-bottom: ${theme.spacing.x1};
  }
`;

interface Props {
  outcome: Outcome;
  checkinDate?: Date;
  currentValue: string | number;
  confidence: string;
}

function ProgressBars(props: Props) {
  const { outcome, currentValue, confidence } = props;

  const startValue = outcome.from;
  const targetValue = outcome.to;
  const isFlatGrowth = startValue === targetValue; // Can't calculate progress prct for a flat growth line
  let prctGoal;
  if (startValue !== null && targetValue !== null && currentValue !== null && !isFlatGrowth) {
    const currentValueNumber = typeof currentValue === 'string' ? parseFloat(currentValue) : currentValue;
    prctGoal = ((currentValueNumber - startValue) / (targetValue - startValue)) * 100;
    prctGoal = Math.min(Math.max(0, prctGoal), 100); // Set progress prct between 0 and 100
  }

  const { score_format, to, from } = outcome;

  return (
    <>
      <Container>
        {prctGoal !== undefined && (
          <>
            <ProgressDetails>
              <b>{formattedScore(score_format, currentValue)}</b>
              <span>{prctGoal.toFixed(0)}%</span>
            </ProgressDetails>

            <KoalaProgressBar value={prctGoal} appearance="secondary" confidence={confidence} />
            <ProgressDetails className="with-gap">
              <label>{formattedScore(score_format, from)}</label>
              <label>{formattedScore(score_format, to)}</label>
            </ProgressDetails>
          </>
        )}
      </Container>
    </>
  );
}

export default ProgressBars;
