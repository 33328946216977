import React, { useState } from 'react';

import DisplayCommentBlock from './_DisplayCommentBlock';
import EditCommentBlock from './_EditCommentBlock';
import { Checkin, CheckinComment } from 'types';

interface Props {
  checkinComment: CheckinComment;
  checkin: Checkin;
  handleShowNewComment: (value: boolean) => void;
}

function CommentBlock(props: Props) {
  const { checkinComment, checkin, handleShowNewComment } = props;

  const [isEditing, setIsEditing] = useState(false);

  const showNewComment = () => {
    handleShowNewComment(true);
  };

  if (isEditing) {
    return <EditCommentBlock checkinComment={checkinComment} setIsEditing={setIsEditing} />;
  }

  return (
    <DisplayCommentBlock
      checkinComment={checkinComment}
      checkin={checkin}
      setIsEditing={setIsEditing}
      handleShowNewComment={showNewComment}
    />
  );
}

export default React.memo(CommentBlock);
