import styled from 'styled-components'; // Fixing the build
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Base64 } from 'js-base64';

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

function ConnectSalesforce() {
  const dispatch = useDispatch();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const { t } = useTranslation();
  const translationKey = 'modals.connectSalesforce';
  const [domain, setDomain] = useState('');

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = (e: React.FormEvent) => {
    const oauthState = {
      slug: workspaceSlug,
      currentDate: new Date(),
      domain,
      redirect_after: `/${workspaceSlug}/settings/integrations`,
    };

    const state = Base64.encode(JSON.stringify(oauthState));

    const redirectURL = `https://${process.env.REACT_APP_DOMAIN}/install/salesforce`;
    const authorizationLink = `https://${domain}.my.salesforce.com/services/oauth2/authorize?client_id=${process.env.REACT_APP_SALESFORCE_CLIENT_ID}&redirect_uri=${redirectURL}&response_type=code&state=${state}`;
    window.location.href = authorizationLink;
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleSave}>
          <FormField>
            <label>{t(`${translationKey}.domainName`)}</label>
            <input
              placeholder={t(`${translationKey}.domainNamePlaceholder`) ?? 'Domain name'}
              className="segment-input"
              value={domain}
              onChange={(e) => setDomain(e.currentTarget.value)}
            />
            <small className="subtle">{t(`${translationKey}.domainInfo`)}</small>
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!domain}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default ConnectSalesforce;
