// This components can be used anytime you want to also have mentions.
import React, { useRef } from 'react';
import request from 'state/utils/request';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';

import KoalaSelect from 'koala/components/Select';
import { useTranslation } from 'react-i18next';

interface Props {
  value: any;
  handleChange: any;
}
function TagFilter(props: Props) {
  const { value, handleChange } = props;
  const { t } = useTranslation();

  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const debouncedLoadOptions: any = useRef(
    _.debounce((inputValue: string, callback: any) => loadOptions(inputValue, callback), 500, {
      maxWait: 2000,
    }),
  );

  const loadOptions = (inputValue: string, callback: any) => {
    let params;
    if (inputValue) {
      params = {
        search: inputValue,
      };
    } else {
      params = {};
    }

    const url = remoteApi.TAGS_API.replace(':workspaceSlug', workspaceSlug);
    request
      .get(url, {
        params,
      })
      .then((response) => {
        const tags = response.data;
        const options = tags.map((tag: any) => ({
          id: 'tag',
          label: tag.name,
          value: tag.name,
        }));
        return options;
      })
      .then(callback);
  };

  return (
    <KoalaSelect
      isMultiSelect
      isClearable
      placeholder={t('workspaceFilters.placeholder')}
      id="tag"
      handleChange={(options) => handleChange('tag', options)}
      selectedOption={value}
      loadOptions={debouncedLoadOptions.current}
      size="xsmall"
    />
  );
}

export default React.memo(TagFilter);
