import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import CopyTextInput from 'components/CopyTextInput';
import FormField from 'components/FormField';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Objective, Plan } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
  objective: Objective;
  plan: Plan;
}

function DeletePlan(props: Props) {
  const { objective, plan } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const embedUrl = `https://${process.env.REACT_APP_DOMAIN}/${workspaceSlug}/embed/objective/${objective.nano_slug}`;
  const iframeCode = `<iframe width="700" height="500" src="${embedUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;

  if (!plan.public_access_enabled) {
    return <>{t(`modals.share.publicAccessRequired`)}</>;
  }

  return (
    <>
      <FormField>
        <label>{t('modals.share.iframeCode')}</label>
        <p className="subtle">
          {t('modals.share.iframeDescription', {
            label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
          })}
        </p>
        <CopyTextInput text={iframeCode} />
      </FormField>
      <FormField>
        <label>{t('modals.share.embed')}</label>
        <p className="subtle">{t('modals.share.embedDescription')}</p>
        <CopyTextInput text={embedUrl} />
        <small className="subtle">{t('modals.share.embedSize')}</small>
      </FormField>
    </>
  );
}

export default DeletePlan;
