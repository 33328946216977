import React, { Fragment, useEffect } from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import * as amplitudeUtils from 'utils/amplitude';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import theme from 'theme';

// Components
import { ChromeContent } from 'components/Chrome';
import ContentNarrowWrapper from 'components/ContentNarrowWrapper';

const OnboardingWrapper = styled.div`
  width: 60rem;
  margin: ${theme.spacing.x3} auto;
  padding-bottom: ${theme.spacing.x3};
`;

const OnboardingHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  p {
    font-size: 1.8rem;
  }
  padding-bottom: ${theme.spacing.x3};
`;

const OnboardingFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  p {
    font-size: 1.8rem;
  }
  a {
    color: ${theme.colors.blue};
    &:hover {
      text-decoration: underline;
    }
  }
  padding-top: ${theme.spacing.x3};
`;

const OnboardingVideoPreview = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  max-width: 80rem;
  margin: ${theme.spacing.x3} auto;

  .content {
    a {
      color: ${theme.colors.blue};
    }
  }

  ul {
    list-style: none;
  }

  li + li {
    margin-top: ${theme.spacing.x3};
  }
  li {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x2};
    span {
      color: ${theme.colors.N100};
      font-weight: 800;
      font-size: 1.8rem;
      display: inline-block;
    }
    a {
      color: ${theme.colors.B50};
      &:visited {
        color: ${theme.colors.T80};
        background: ${theme.colors.N5};
      }
      &:hover {
        text-decoration: underline;
      }

      display: flex;
      align-items: center;
      gap: ${theme.spacing.x2};
    }
  }
`;

function Quickstart() {
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.quickstart.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{currentWorkspace.name} | Getting started | Tability</title>
        <script src="https://fast.wistia.com/embed/medias/pb7mfbrs95.jsonp" async></script>
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
      </Helmet>
      <ChromeContent isGreyBackground>
        <ContentNarrowWrapper>
          <Container>
            <OnboardingWrapper>
              <OnboardingHeader>
                <h1>Welcome to your workspace!</h1>
                <p>Take a quick tour of our features with the video below 👇</p>
              </OnboardingHeader>
              <OnboardingVideoPreview>
                <div
                  className="wistia_responsive_padding"
                  style={{ padding: '62.29% 0 0 0', position: 'relative', borderRadius: '8px', overflow: 'hidden' }}
                >
                  <div
                    className="wistia_responsive_wrapper"
                    style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}
                  >
                    <span
                      className="wistia_embed wistia_async_pb7mfbrs95 popover=true popoverAnimateThumbnail=true popoverSize=1664x1038 popoverBorderRadius=8"
                      style={{ display: 'inline-block', height: '100%', position: 'relative', width: '100%' }}
                    >
                      &nbsp;
                    </span>
                  </div>
                </div>
              </OnboardingVideoPreview>
              <OnboardingFooter>
                <p>
                  Then take a look at{' '}
                  <a href="https://guides.tability.app/" target="_blank" rel="noopener noreferrer">
                    our guides
                  </a>{' '}
                  to accelerate your onboarding.
                </p>
              </OnboardingFooter>
            </OnboardingWrapper>
          </Container>
        </ContentNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default Quickstart;
