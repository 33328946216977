import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import { DashboardWidget, Membership, Segment, Workspace } from 'types';
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import { DragHandle, SourceTitle, WidgetContainer, WidgetHeader } from '.';
import { Link } from 'react-router-dom';
import * as filterUtils from 'utils/filterUtils';
import { Base64 } from 'js-base64';
import KoalaLoader from 'koala/components/Loader';
import KoalaIcon from 'koala/components/Icons';
import { WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS } from 'routes';
import KoalaConfidencePie from 'koala/components/ConfidencePie';
import { StatsResult } from 'pages/WorkspaceOutcomes';
import { VictoryTooltip } from 'victory';

const OutcomeContainer = styled.div`
  padding: ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  background: #fff;
  justify-content: start;

  .item:last-of-type {
    border: none;
  }
  .outcome-expanded {
    border-bottom: 1px solid ${theme.colors.N10};
  }
`;

const PieContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${theme.spacing.x2} 0px ${theme.spacing.x1};
`;

const ProgressContainer = styled.div`
  font-weight: 700;
  display: flex;
  font-size: 2.8rem;
  justify-content: center;
`;

interface PieData {
  x: number;
  y: number;
  color: string;
  label?: string;
}

interface Props {
  widget: DashboardWidget;
}

function OutcomeStatsWidget(props: Props) {
  const { widget } = props;
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  const [outcomesStats, setOutcomesStats] = useState<StatsResult | null>(null);

  const segment: Segment = widget.source ? widget.source : null;
  const filter = segment ? segment.filter_hash : null;

  const customFilterHash = filter ? JSON.parse(Base64.decode(filter)) : {};

  const filterParams = filterUtils.getFilterHash(customFilterHash, currentMembership);
  // Query keys and query params

  const widgetTitle: string = widget.title
    ? widget.title
    : t('workspaceDashboards.outcomeStats', {
        outcome: translate(workspace, CustomTermKey.OUTCOME, 1),
      });

  // Get the outcome stats
  const queryKeyStats = [
    queryKeys.outcomes,
    'stats',
    {
      workspaceSlug: workspace.slug,
      filter: filterParams,
    },
  ];
  const { isLoading: isLoadingStats }: any = useQuery(queryKeyStats, remoteApi.fetchOutcomesStats, {
    onSuccess: (response: any) => {
      setOutcomesStats(response.data);
    },
  });

  const data: PieData[] = [];
  let dataIndex = 1;

  const segmentRoute = WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspace.slug)
    .replace(':segmentId', segment.id)
    .replace(':filter', segment.filter_hash);

  if (!outcomesStats) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <OutcomeContainer>
          <SourceTitle>
            <Link to={segmentRoute} className="widget-source">
              {segment.title}
            </Link>
          </SourceTitle>
          <KoalaConfidencePie data={data} size="large" />
        </OutcomeContainer>
      </WidgetContainer>
    );
  }

  if (outcomesStats.red_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: outcomesStats.red_outcomes_count,
      color: theme.colors.red,
      label: 'off track',
    });
  }
  if (outcomesStats.yellow_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: outcomesStats.yellow_outcomes_count,
      color: theme.colors.yellow,
      label: 'at risk',
    });
  }
  if (outcomesStats.green_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: outcomesStats.green_outcomes_count,
      color: theme.colors.green,
      label: 'on track',
    });
  }
  if (outcomesStats.grey_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: outcomesStats.grey_outcomes_count,
      color: theme.colors.grey,
      label: 'pending',
    });
  }

  if (isLoadingStats) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <KoalaLoader />
      </WidgetContainer>
    );
  }

  const labelComponent = (
    <VictoryTooltip
      flyoutPadding={{ top: 4, bottom: 4, left: 8, right: 8 }}
      cornerRadius={4}
      flyoutStyle={{
        fill: theme.colors.N5,
        stroke: theme.colors.N5,
      }}
      style={[
        {
          fontSize: '12px',
          fontFamily: theme.font.fontFamily,
        },
      ]}
    />
  );

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <OutcomeContainer>
        <SourceTitle>
          <Link to={segmentRoute} className="widget-source">
            {segment.title}
          </Link>
        </SourceTitle>
        <PieContainer>
          <KoalaConfidencePie data={data} size="large" tooltipComponent={labelComponent} />
        </PieContainer>
        <ProgressContainer>
          {t('workspaceDashboards.outcomeStatsProgress', {
            value: Math.round(outcomesStats.avg_outcomes_progress * 100),
          })}
        </ProgressContainer>
      </OutcomeContainer>
    </WidgetContainer>
  );
}

export default OutcomeStatsWidget;
