import FormField from 'components/FormField';
import React, { useRef, useState, useEffect } from 'react';
import * as remoteApi from 'api/remote';
import request from 'state/utils/request';
import _ from 'lodash';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import { ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Plan } from 'types';

interface Props {
  workspaceSlug: string;
  setPlanId: (value: string) => void;
  existingPlan?: Plan;
}

function PlanSelect(props: Props) {
  const { workspaceSlug, setPlanId, existingPlan } = props;
  const [selectedPlanOption, setSelectedPlanOption] = useState<KoalaSelectOption | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (existingPlan) {
      setSelectedPlanOption({
        value: existingPlan.id,
        label: existingPlan.title,
        meta: {
          icon: existingPlan.plan_icon,
        },
      });
    }
  }, [existingPlan]);

  const handleSelectPlan = (selected: ValueType<KoalaSelectOption, boolean>) => {
    const option = selected as KoalaSelectOption;
    if (option && typeof option.value === 'string') {
      setPlanId(option.value);
      setSelectedPlanOption(option);
    } else {
      setPlanId('');
      setSelectedPlanOption(null);
    }
  };

  const loadOptions = (inputValue: string, callback: any) => {
    const url = `${remoteApi.WORKSPACE_DETAILS_API.replace(':workspaceSlug', workspaceSlug)}/search/plans`;
    if (!inputValue) {
      const planParams = {
        filter: {
          title: inputValue,
          archived: false,
        },
        per_page: 10,
      };
      request
        .post(url, { params: planParams })
        .then((response) => {
          const plans = response.data;
          if (plans && plans.length > 0) {
            const options = plans.map((plan: Plan) => {
              return {
                value: plan.id,
                label: plan.title,
                meta: {
                  icon: plan.plan_icon,
                },
              };
            });
            return options;
          } else {
            return [];
          }
        })
        .then(callback);
      return;
    }

    // If we have a search param, use it
    const planParams = inputValue
      ? {
          filter: {
            title: inputValue,
            archived: false,
          },
          per_page: 10,
        }
      : null;
    request
      .post(url, planParams)
      .then((response) => {
        const plans = response.data;
        if (plans && plans.length > 0) {
          const options = plans.map((plan: Plan) => {
            return {
              id: 'plan',
              value: plan.id,
              label: plan.title,
              meta: {
                icon: plan.plan_icon,
              },
            };
          });
          return options;
        } else {
          return [];
        }
      })
      .then(callback);
  };

  const debouncedLoadOptions: any = useRef(
    _.debounce((inputValue: string, callback) => loadOptions(inputValue, callback), 200, {
      maxWait: 2000,
    }),
  );

  const formatOption = (option: KoalaSelectOption) => {
    return <PlanIconLabel planIcon={option.meta?.icon} planTitle={option.label} size="xsmall" />;
  };

  return (
    <FormField className="plan-select">
      <label>{t(`workspaceDashboards.plan`)}</label>
      <KoalaSelect
        handleChange={handleSelectPlan}
        placeholder={t(`workspaceDashboards.placeholder`, { item: 'plan' }) ?? 'Select a plan or type to search'}
        selectedOption={selectedPlanOption}
        isClearable
        loadOptions={debouncedLoadOptions.current}
        size="small"
        id="plan"
        labelFormat={formatOption}
      />
    </FormField>
  );
}
export default PlanSelect;
