import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00103 5.99958C5.00103 4.6192 6.12005 3.50018 7.50044 3.50018C8.88082 3.50018 9.99984 4.6192 9.99984 5.99958C9.99984 7.37659 8.88628 8.49352 7.51055 8.49897H7.50046H7.49032C6.11459 8.49352 5.00103 7.37659 5.00103 5.99958ZM4.28549 9.1474C3.4909 8.33598 3.00103 7.22499 3.00103 5.99958C3.00103 3.51463 5.01548 1.50018 7.50044 1.50018C9.01288 1.50018 10.351 2.24643 11.1668 3.3908C11.7859 2.83684 12.6033 2.50001 13.4995 2.50001C15.4322 2.50001 16.999 4.06683 16.999 5.99959C16.999 6.83918 16.7034 7.60972 16.2105 8.21276C17.8754 9.158 18.9987 10.947 18.9987 12.9984V14.1754C18.9987 14.8304 18.5471 15.3989 17.9091 15.5472C16.6224 15.8463 15.3123 16.0121 13.9995 16.0444V16.2972C13.9995 16.8362 13.6625 17.3177 13.156 17.5021C9.50299 18.8324 5.49794 18.8324 1.84493 17.5021C1.33847 17.3177 1.0014 16.8362 1.0014 16.2972V14.499C1.0014 12.1628 2.33659 10.1384 4.28549 9.1474ZM7.48719 10.499H7.0014C4.79226 10.499 3.0014 12.2898 3.0014 14.499V15.7856C5.92497 16.7379 9.07595 16.7379 11.9995 15.7856V15.0479C11.9995 15.0462 11.9995 15.0445 11.9995 15.0428V14.499C11.9995 14.2048 11.9678 13.918 11.9075 13.6418C11.5151 11.8446 9.91445 10.499 7.99952 10.499H7.51368C7.50927 10.499 7.50485 10.499 7.50044 10.499C7.49602 10.499 7.49161 10.499 7.48719 10.499ZM13.9825 14.0442C14.9938 14.0174 16.003 13.9023 16.9987 13.699V12.9984C16.9987 11.0668 15.4336 9.50075 13.5024 9.49916L13.4995 9.49916L13.4965 9.49916C12.9051 9.49964 12.3484 9.6464 11.8602 9.90582C13.0597 10.9151 13.8582 12.3856 13.9825 14.0442ZM13.4994 7.49916L13.5019 7.49916C14.3289 7.49787 14.999 6.82698 14.999 5.99959C14.999 5.1714 14.3277 4.50001 13.4995 4.50001C12.6713 4.50001 11.9999 5.1714 11.9999 5.99959C11.9999 6.82697 12.67 7.49786 13.4971 7.49916L13.4994 7.49916Z"
      fill="#737373"
    />
  </svg>
);
