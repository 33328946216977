import React from 'react';
import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaTextBadge from 'koala/components/TextBadge';
import styled from 'styled-components';
import theme from 'theme';

const Title = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

interface Props {}

function BetaLanguage(props: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const translationKey = 'modals.betaLanguage';

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <Title>
          <h2>{t(`${translationKey}.title`)}</h2>
          <KoalaTextBadge variant="violet-light">Beta</KoalaTextBadge>
        </Title>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>{t(`${translationKey}.info`)}</ModalContent>
    </ModalGrid>
  );
}

export default BetaLanguage;
