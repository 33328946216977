import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
//import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import MembershipSettingsNav from 'components/MembershipSettingsNav';
import KoalaButton from 'koala/components/Button';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  h3 {
    margin: ${theme.spacing.x2} 0;
  }
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const FormLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  label {
    margin: 0;
    padding: 0;
  }
`;

function WorkspaceSettingsDetails() {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);

  const queryKey = queryKeys.currentUserMemberships;

  const [email_system, setEmailSystem]: any = useState(currentMembership.email_system);
  const [email_views_notifications, setEmailViewsNotifications]: any = useState(
    currentMembership.email_views_notifications,
  );
  const [email_weekly_reminders, setEmailWeeklyReminders]: any = useState(currentMembership.email_weekly_reminders);
  const [email_notifications, setEmailNotifications]: any = useState(currentMembership.email_notifications);
  const [email_weekly_reports, setEmailWeeklyReports]: any = useState(currentMembership.email_weekly_reports);
  const [chat_push_weekly_reminders, setChatPushWeeklyReminders]: any = useState(
    currentMembership.chat_push_weekly_reminders,
  );
  const [send_push_notifications, setSendPushNotification]: any = useState(currentMembership.send_push_notifications);

  const [email_standup_reminders, setEmailStandupReminders]: any = useState(currentMembership.email_standup_reminders);
  const [email_urgent_reminders, setEmailUrgentReminders]: any = useState(currentMembership.email_urgent_reminders);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  // Workspace mutations
  const [updateMembershipMutation, { isError, isLoading, error }]: [any, any] = useMutation(
    remoteApi.updateMembership,
    {
      onMutate: () => {
        queryCache.invalidateQueries(queryKey);
        setIsFormSaved(false);
      },
      onSuccess: () => {
        setIsFormSaved(true);
        setIsFormDirty(false);
      },
    },
  );

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;
    setIsFormDirty(true);
    setIsFormSaved(false);

    switch (name) {
      case 'email_system':
        setEmailSystem(value);
        break;
      case 'email_weekly_reminders':
        setEmailWeeklyReminders(value);
        break;
      case 'email_standup_reminders':
        setEmailStandupReminders(value);
        break;
      case 'email_urgent_reminders':
        setEmailUrgentReminders(value);
        break;
      case 'chat_push_weekly_reminders':
        setChatPushWeeklyReminders(value);
        break;
      case 'email_notifications':
        setEmailNotifications(value);
        break;
      case 'email_views_notifications':
        setEmailViewsNotifications(value);
        break;
      case 'email_weekly_reports':
        setEmailWeeklyReports(value);
        break;
      case 'send_push_notifications':
        setSendPushNotification(value);
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      email_system,
      email_standup_reminders,
      email_urgent_reminders,
      email_weekly_reminders,
      email_notifications,
      email_views_notifications,
      email_weekly_reports,
      chat_push_weekly_reminders,
      send_push_notifications,
    };
    updateMembershipMutation({
      membershipId: currentMembership.id,
      membership: params,
    });
  };

  // Display loader if fetching
  if (!currentMembership) {
    return (
      <Fragment>
        <ChromeSideNav>
          <MembershipSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <Loader />
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'email_system':
        return 'The admin notifications';
      case 'email_standup_reminders':
        return 'The standup email reminders';
      case 'email_urgent_reminders':
        return 'The urgent email reminders';
      case 'email_weekly_reminders':
        return 'The weekly email reminders';
      case 'chat_push_weekly_reminders':
        return 'The weekly chat push reminders';
      case 'email_notifications':
        return 'The email notifications';
      case 'email_weekly_reports':
        return 'The weekly email reports';
      case 'send_push_notifications':
        return 'The mobile notifications';
      default:
        return '';
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspaceMembershipNotifications.title')} | Tability
        </title>
      </Helmet>
      <Prompt
        message={t('shared.confirmLeave') ?? 'Your changes are not saved. Are you sure you want to leave?'}
        when={isFormDirty}
      />
      <ChromeSideNav>
        <MembershipSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceMembershipNotifications.title')}</h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <FormLine>
                <KoalaAvatar user={currentUser} />
                {currentUser.name}
              </FormLine>
            </FormField>
            <h3>{t('workspaceMembershipNotifications.accountability')}</h3>
            <FormField>
              <FormLine>
                <input
                  type="checkbox"
                  id="email_urgent_reminders"
                  checked={email_urgent_reminders}
                  onChange={handleChange}
                  name="email_urgent_reminders"
                />
                <label htmlFor="email_urgent_reminders">
                  {t('workspaceMembershipNotifications.urgentReminders', {
                    initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                  })}
                </label>
              </FormLine>
            </FormField>
            <FormField>
              <FormLine>
                <input
                  type="checkbox"
                  id="email_standup_reminders"
                  checked={email_standup_reminders}
                  onChange={handleChange}
                  name="email_standup_reminders"
                />
                <label htmlFor="email_standup_reminders">
                  {t('workspaceMembershipNotifications.standupReminders')}
                </label>
              </FormLine>
            </FormField>
            <FormField>
              <FormLine>
                <input
                  id="email_weekly_reminders"
                  type="checkbox"
                  checked={email_weekly_reminders}
                  onChange={handleChange}
                  name="email_weekly_reminders"
                />
                <label htmlFor="email_weekly_reminders">{t('workspaceMembershipNotifications.weeklyCheckins')}</label>
              </FormLine>
            </FormField>
            <FormField>
              <FormLine>
                <input
                  id="email_notifications"
                  type="checkbox"
                  checked={email_notifications}
                  onChange={handleChange}
                  name="email_notifications"
                />
                <label htmlFor="email_notifications">{t('workspaceMembershipNotifications.unseenNotifications')}</label>
              </FormLine>
            </FormField>
            <FormField>
              <FormLine>
                <input
                  id="email_views_notifications"
                  type="checkbox"
                  checked={email_views_notifications}
                  onChange={handleChange}
                  name="email_views_notifications"
                />
                <label htmlFor="email_views_notifications">
                  {t('workspaceMembershipNotifications.viewsNotifications')}
                </label>
              </FormLine>
            </FormField>

            <FormField>
              <FormLine>
                <input
                  id="email_weekly_report"
                  type="checkbox"
                  checked={email_weekly_reports}
                  onChange={handleChange}
                  name="email_weekly_reports"
                />
                <label htmlFor="email_weekly_report">{t('workspaceMembershipNotifications.weeklyReport')}</label>
              </FormLine>
            </FormField>
            <h3>{t('workspaceMembershipNotifications.chat')}</h3>
            <FormField>
              <FormLine>
                <input
                  id="chat_push_weekly_reminders"
                  type="checkbox"
                  checked={chat_push_weekly_reminders}
                  onChange={handleChange}
                  name="chat_push_weekly_reminders"
                />
                <label htmlFor="chat_push_weekly_reminders">
                  {t('workspaceMembershipNotifications.weeklyCheckinsChat')}
                </label>
              </FormLine>
            </FormField>
            <h3>{t('workspaceMembershipNotifications.mobile')}</h3>
            <FormField>
              <FormLine>
                <input
                  id="send_push_notifications"
                  type="checkbox"
                  checked={send_push_notifications}
                  onChange={handleChange}
                  name="send_push_notifications"
                />
                <label htmlFor="send_push_notifications">
                  {t('workspaceMembershipNotifications.mobileNotifications')}
                </label>
              </FormLine>
            </FormField>
            <h3>{t('workspaceMembershipNotifications.system')}</h3>
            <FormField>
              <FormLine>
                <input
                  id="email_system"
                  type="checkbox"
                  checked={email_system}
                  onChange={handleChange}
                  name="email_system"
                />
                <label htmlFor="email_system">{t('workspaceMembershipNotifications.onboarding')}</label>
              </FormLine>
            </FormField>
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading}>
                {t('shared.save')}
              </KoalaButton>
              {isFormSaved && <div>{t('shared.saved')}</div>}
            </Actions>
          </Form>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
