import MentionBox from 'components/MentionBox';
import KoalaButton from 'koala/components/Button';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import styled from 'styled-components';
import { RetrospectiveSection } from 'types';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import KoalaTextButton from 'koala/components/TextButton';
import { useTranslation } from 'react-i18next';

const NewSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  justify-content: start;
`;
const Actions = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;

interface Props {
  section: RetrospectiveSection;
  setShowEdit: (value: string) => void;
}

function TextSectionEdit(props: Props) {
  const { setShowEdit, section } = props;
  const [originalText, setOriginalText] = useState(section ? section.body : '');
  const [currentText, setCurrentText] = useState(originalText);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const queryCache = useQueryCache();
  const { t } = useTranslation();

  useEffect(() => {
    // if in text editor and click outside, will save progress and switch back to view mode
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowEdit('');
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, setShowEdit]);

  useEffect(() => {
    // Make sure that we don't do useless update if text hasn't changed.
    if (currentText !== originalText) {
      debounceUpdateSection.current(currentText);
    }
  }, [currentText, originalText]);

  const [updateSectionMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateRetrospectiveSection, {
    onSuccess: (response) => {
      // Update originalText once saving is confirmed
      setOriginalText(response.data.body);
      queryCache.invalidateQueries([queryKeys.retrospectiveSections, section.retrospective_id]);
    },
  });

  // Function that updates the section
  const updateSection = (body: string) => {
    const mutationParams = { retrospectiveSectionId: section.id, retrospectiveSection: { body, section_type: 'text' } };
    updateSectionMutation(mutationParams);
  };

  // We use a debounce function to avoid saving the text on every character change.
  // This function waits 500ms to see if there's another character changed, and a max of 2s before saving.
  const debounceUpdateSection = useRef(
    _.debounce((body: string) => updateSection(body), 1000, {
      maxWait: 2000,
    }),
  );

  const handleSave = (e: any) => {
    e.preventDefault();
    debounceUpdateSection.current(currentText);
    setShowEdit('');
  };

  const handleCancel = () => {
    setShowEdit('');
  };

  return (
    <NewSection ref={wrapperRef}>
      <MentionBox
        setValue={setCurrentText}
        value={currentText}
        placeholder={t(`workspaceRetrospective.addText`) ?? 'Add text'}
        allowHeaders={true}
      />
      <Actions>
        <KoalaButton onClick={handleSave} loading={isUpdating}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </Actions>
    </NewSection>
  );
}

export default React.memo(TextSectionEdit);
