import React, { useEffect } from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';
import theme from 'theme';

import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';

import FormField from 'components/FormField';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useTranslation } from 'react-i18next';

const InlineItems = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
  setScoreChanges: (value: string) => void;
}

function CurrentValue(props: CurrentValueProps) {
  const { outcome, setScoreChanges } = props;
  const { t } = useTranslation();
  const translationKey = 'modals.dataConnectors';

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcome.id, `data_source:gitlab:${outcome.data_source_type}`, 'current'];
  const staleTime = 300;

  const { data, isFetching } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  const currentValue: number | undefined = data?.data.result;
  useEffect(() => {
    if (typeof currentValue === 'number') {
      setScoreChanges(currentValue.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const gitlabKey = 'modals.dataConnectors.gitlabData';
  const dataTypeToLabel: any = {
    issues_created: t(`${gitlabKey}.issues_created`),
    issues_closed: t(`${gitlabKey}.issues_closed`),
    merge_requests_created: t(`${gitlabKey}.merge_requests_created`),
    merge_requests_closed: t(`${gitlabKey}.merge_requests_closed`),
    deployments_created: t(`${gitlabKey}.deployments_created`),
    avg_deploy_time: t(`${gitlabKey}.avg_deploy_time`),
    pipelines_created: t(`${gitlabKey}.pipelines_created`),
    successful_pipelines: t(`${gitlabKey}.successful_pipelines`),
    failed_pipelines: t(`${gitlabKey}.failed_pipelines`),
  };

  if (!outcome.data_source_type) {
    return null;
  }

  return (
    <FormField>
      <label>{t(`${translationKey}.title`)}</label>
      <InlineItems>
        <KoalaTextBadge edge="circle" size="small" maxWidth="30rem" variant="neutral-light">
          {t(`${translationKey}.dataSource`, {
            dataSource: 'Gitlab',
            label: dataTypeToLabel[outcome.data_source_type],
          })}
        </KoalaTextBadge>
        {isFetching && <KoalaLoader size="small" />}
      </InlineItems>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  setScoreChanges: (value: string) => void;
}

function GitlabSource(props: Props) {
  const { outcome, setScoreChanges } = props;

  const availableDataSourceTypes = [
    'issues_created',
    'issues_closed',
    'merge_requests_created',
    'merge_requests_closed',
    'deployments_created',
    'avg_deploy_time',
    'pipelines_created',
    'successful_pipelines',
    'failed_pipelines',
  ];

  if (!outcome.data_source_type || outcome.data_source_origin !== 'gitlab') {
    return null;
  }

  if (availableDataSourceTypes.includes(outcome.data_source_type)) {
    return <CurrentValue outcome={outcome} setScoreChanges={setScoreChanges} />;
  }

  return null;
}

export default React.memo(GitlabSource);
