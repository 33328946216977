import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/';
import apiMiddleware from './middlewares/apiMiddleware';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'tabilityRoot',
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares: any[] = [thunkMiddleware, apiMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export default function configureStore() {
  const store = createStore(persistedReducer, {}, composeWithDevTools(applyMiddleware(...middlewares)));
  const persistor = persistStore(store);
  return { store, persistor };
}
