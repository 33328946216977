import React from 'react';

const SearchIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 8C16.5 5.51472 14.4853 3.5 12 3.5C9.51472 3.5 7.5 5.51472 7.5 8C7.5 10.4853 9.51472 12.5 12 12.5C14.4853 12.5 16.5 10.4853 16.5 8ZM12 1.5C15.5899 1.5 18.5 4.41015 18.5 8C18.5 11.5899 15.5899 14.5 12 14.5C8.41015 14.5 5.5 11.5899 5.5 8C5.5 4.41015 8.41015 1.5 12 1.5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.20711 11.7929C8.59763 12.1834 8.59763 12.8166 8.20711 13.2071L3.20711 18.2071C2.81658 18.5976 2.18342 18.5976 1.79289 18.2071C1.40237 17.8166 1.40237 17.1834 1.79289 16.7929L6.79289 11.7929C7.18342 11.4024 7.81658 11.4024 8.20711 11.7929Z"
      fill="#737373"
    />
  </svg>
);
export default SearchIcon;
