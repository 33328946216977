import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import React, { FC } from 'react';
import { StyledLinkTextButton, StyledLinkToTextButton, StyledTextButton } from './textButton.styles';

export interface TextButtonProps {
  appearance?: (typeof KoalaAppearances)[number];
  size?: (typeof KoalaSizes)[number];
  onClick?: (e: any) => void;
  href?: string;
  isLink?: boolean;
  rel?: string;
  target?: string;
  to?: string;
  isInlineText?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const KoalaTextButton: FC<TextButtonProps> = ({ children = 'Text', ...props }) => {
  const { appearance, onClick, href, isLink, rel, target, to, isInlineText, className, style, size, disabled, type } =
    props;
  let textInner = <>{children}</>;
  let buttonAppearance = disabled ? 'disabled' : appearance;

  let textClass = className ? className : '';
  textClass += isInlineText ? ' text-btn--inline' : ` text-btn--${buttonAppearance} text-btn--${size}`;

  if (isLink) {
    return (
      <StyledLinkToTextButton className={textClass} {...{ style, to }} type={type}>
        {textInner}
      </StyledLinkToTextButton>
    );
  } else if (href) {
    return (
      <StyledLinkTextButton className={textClass} {...{ style, href, target, rel }} type={type}>
        {textInner}
      </StyledLinkTextButton>
    );
  } else {
    return (
      <StyledTextButton className={textClass} {...{ style, onClick, disabled }} type={type}>
        {textInner}
      </StyledTextButton>
    );
  }
};

KoalaTextButton.defaultProps = {
  appearance: 'primary',
  isInlineText: false,
  size: 'medium',
};

export default KoalaTextButton;
