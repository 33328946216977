/**
 * Objective component
 */
import React, { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  editorSetDisplayNewBlockForm,
  editorSelectBlockAbove,
  editorSelectBlockBelow,
  editorUnselectBlock,
  editorDeleteObjective,
  editorUpdateObjective,
} from 'state/actions/workspaceEditorActions';

// Actions

// UI Components
import TitleInput from './TitleInput';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  objectiveId: string;
}

function Objective(props: Props) {
  const dispatch = useDispatch();
  const { objectiveId } = props;
  const { t } = useTranslation();

  // Used to avoid saving objective title on first render
  const isInitialRender = useRef(true);

  // Get the objective from state using the objective Id passed in props
  const objective = useSelector((state: any) => state.editorEntities.objectives[objectiveId]);
  const outcomeIds = useSelector((state: any) => state.editorEntities.objectivesToOutcomesMapping[objectiveId] || []);
  const [title, setTitle] = useState(objective.title);
  const blockId = `objective:${objectiveId}`;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const isFocused = useSelector(
    (state: any) => state.editorUI.selectedBlockId === blockId && !state.editorUI.displayNewBlockForm,
  );

  const saveObjectiveTitle = (newTitle: string) => {
    const title = newTitle;
    if (!title) {
      return;
    }

    const objectiveParams = {
      title,
    };
    dispatch(editorUpdateObjective(objectiveId, objectiveParams));
  };

  const removeObjective = () => {
    dispatch(editorSelectBlockAbove());
    dispatch(editorDeleteObjective(objectiveId));
  };

  const debounceSaveObjectiveTitle = useRef(
    _.debounce((newTitle: string) => saveObjectiveTitle(newTitle), 500, {
      maxWait: 5000,
    }),
  );

  useEffect(() => {
    if (!isInitialRender.current) {
      debounceSaveObjectiveTitle.current(title);
    }
  }, [title]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
  }, []);

  if (!objective) {
    return null;
  }

  const handleChange = (e: any) => {
    e.preventDefault();
    let title = e.target.value || '';
    title = title.replace(/(\r\n|\n|\r)/gm, ''); // Remove new lines
    setTitle(title);
  };

  const objectiveLabel = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase();
  const outcomesLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase();
  const initiativesLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase();

  const handlePress = (e: any) => {
    if (e.keyCode === 27) {
      // Esc key
      e.preventDefault();
      e.currentTarget.blur();
      dispatch(editorUnselectBlock());
    }

    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault();
      e.currentTarget.blur();
      dispatch(editorSetDisplayNewBlockForm(true));
    }

    if (e.keyCode === 38) {
      // Arrow up
      e.preventDefault();
      dispatch(editorSelectBlockAbove());
    }

    if (e.keyCode === 40) {
      // Arrow down
      e.preventDefault();
      dispatch(editorSelectBlockBelow());
    }
    // Disable field if we press delete and no text is entered
    if (e.keyCode === 8) {
      // Delete key
      const value = e.target.value;
      if (!value) {
        // Only delete if there's no value
        e.preventDefault();
        const deleteConfirm =
          t('workspacePlan.write.deleteObjective', { objectiveLabel, outcomesLabel, initiativesLabel }) ??
          `Deleting this ${objectiveLabel} will delete the ${outcomesLabel} and ${initiativesLabel} underneath. Is that ok?`;
        if (outcomeIds.length > 0) {
          if (window.confirm(deleteConfirm)) {
            removeObjective();
          }
        } else {
          removeObjective();
        }
      }
    }
  };

  const placeholder = t(`workspacePlan.write.placeholder`, { label: objectiveLabel }) ?? `Write ${objectiveLabel}`;
  return (
    <TitleInput
      text={title}
      type="objective"
      placeholder={placeholder}
      handleChange={handleChange}
      handlePress={handlePress}
      isFocused={isFocused}
    />
  );
}

export default React.memo(Objective);
