import React from 'react';
import { useTranslation } from 'react-i18next';

import { IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Workspace } from 'types';
import BigQueryIcon from '../_assets/IconBigQuery.svg';
import KoalaButton from 'koala/components/Button';

interface Props {
  workspace: Workspace;
}

function BigQueryIntegration(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={BigQueryIcon} alt="BigQuery" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.bigQueryHeader')}</h3>
        <p>{t('workspaceSettingsIntegrations.bigQueryInfo')}</p>
        <p>
          <a href="https://guides.tability.app/docs/integrations/bigquery" target="_blank" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
        <p>
          <KoalaButton isLink size="small" appearance="secondary" to={`/${workspace.slug}/account/integrations`}>
            {t('workspaceSettingsIntegrations.connectGoogleAccount')}
          </KoalaButton>
        </p>
      </IntegrationContent>
    </IntegrationBlock>
  );
}

export default BigQueryIntegration;
