import React from 'react';

const MetricIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.98287 2.1843C9.08465 1.64148 8.72712 1.11892 8.18429 1.01714C7.64147 0.91536 7.11891 1.2729 7.01713 1.81572L6.42008 4.99998H3.80662C3.25433 4.99998 2.80662 5.4477 2.80662 5.99998C2.80662 6.55227 3.25433 6.99998 3.80662 6.99998H6.04508L4.92007 13H2C1.44772 13 1 13.4477 1 14C1 14.5523 1.44772 15 2 15H4.54507L4.01713 17.8157C3.91535 18.3585 4.27289 18.881 4.81571 18.9828C5.35854 19.0846 5.88109 18.7271 5.98287 18.1842L6.57992 15H11.5451L11.0171 17.8157C10.9153 18.3585 11.2729 18.8811 11.8157 18.9829C12.3585 19.0846 12.8811 18.7271 12.9829 18.1843L13.5799 15H16.1934C16.7457 15 17.1934 14.5523 17.1934 14C17.1934 13.4477 16.7457 13 16.1934 13H13.9549L15.0799 6.99998H18C18.5523 6.99998 19 6.55227 19 5.99998C19 5.4477 18.5523 4.99998 18 4.99998H15.4549L15.9829 2.18435C16.0847 1.64152 15.7271 1.11897 15.1843 1.01719C14.6415 0.915405 14.1189 1.27294 14.0171 1.81577L13.4201 4.99998H8.45493L8.98287 2.1843ZM8.07993 6.99998L6.95493 13H11.9201L13.0451 6.99998H8.07993Z"
      fill="#737373"
    />
  </svg>
);

export default MetricIcon;
