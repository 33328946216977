import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import ClickupIcon from '../_assets/IconClickup.png';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaButton from 'koala/components/Button';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
}

function ClickupIntegration(props: Props) {
  const { workspace, integrations } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();

  const queryKey = [queryKeys.integrations, workspace.slug];
  const [uninstallClickupMutation, { isLoading: isUninstallingClickup }] = useMutation(remoteApi.uninstallClickup, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const handleUninstallClickup = (e: React.MouseEvent) => {
    e.preventDefault();
    uninstallClickupMutation(workspace.slug);
  };

  const clickupIntegrations = integrations.filter((i: Integration) => i.integration_type === 'clickup');
  let clickupWorkspaces = '';
  if (clickupIntegrations.length > 0) {
    clickupWorkspaces = clickupIntegrations.map((integration: Integration) => integration.connected_to).join(', ');
  }
  const clickupRedirectURL = `https://${process.env.REACT_APP_DOMAIN}/install/clickup`;
  const clickupOAuthURL = `https://app.clickup.com/api?client_id=${
    process.env.REACT_APP_CLICKUP_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(clickupRedirectURL)}`;

  const uninstallClickupLabel = isUninstallingClickup ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove');

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={ClickupIcon} alt="Clickup" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>
          {t('workspaceSettingsIntegrations.clickupHeader', {
            outcomes: translate(workspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
          })}
        </h3>
        <p>{t('workspaceSettingsIntegrations.clickupInfo')}</p>
        <p>
          <a href="https://guides.tability.app/docs/integrations/clickup" target="_blank" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {clickupIntegrations.length > 0 && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: clickupWorkspaces }}
              />
            </div>
            <KoalaButton href={clickupOAuthURL} appearance="secondary" size="small">
              {t('workspaceSettingsIntegrations.reinstall')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={handleUninstallClickup}>
              {uninstallClickupLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {clickupIntegrations.length === 0 && (
          <KoalaButton href={clickupOAuthURL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Clickup' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default ClickupIntegration;
