import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useDispatch } from 'react-redux';

// API
import { fetchWorkspacePing } from 'api/remote';

// Actions
import { setCurrentPing } from 'state/actions/sessionActions';

// Pings for notifications

interface Props {
  currentWorkspace: any;
}

function MembershipStats(props: Props) {
  const dispatch = useDispatch();
  const { currentWorkspace } = props;
  const workspacePingQueryKey = [queryKeys.currentPing, currentWorkspace.slug];
  useQuery(workspacePingQueryKey, fetchWorkspacePing, {
    staleTime: 0,
    refetchInterval: 60 * 1000,
    onSuccess: (response) => {
      dispatch(setCurrentPing(response.data));
    },
  });

  return null;
}

export default MembershipStats;
