import React from 'react';

const SnoozeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.99997 3.99829C2.44768 3.99829 1.99997 3.55058 1.99997 2.99829C1.99997 2.44601 2.44768 1.99829 2.99997 1.99829H9.99997C10.4044 1.99829 10.7691 2.24193 10.9238 2.61561C11.0786 2.98928 10.9931 3.4194 10.7071 3.7054L5.41418 8.99829H9.99997C10.5523 8.99829 11 9.44601 11 9.99829C11 10.5506 10.5523 10.9983 9.99997 10.9983H2.99997C2.59551 10.9983 2.23087 10.7546 2.07609 10.381C1.92131 10.0073 2.00686 9.57718 2.29286 9.29118L7.58575 3.99829H2.99997ZM13 9.74875C12.4477 9.74875 12 9.30103 12 8.74875C12 8.19646 12.4477 7.74875 13 7.74875H17C17.4044 7.74875 17.7691 7.99239 17.9239 8.36607C18.0786 8.73974 17.9931 9.16986 17.7071 9.45586L15.4142 11.7487H17C17.5523 11.7487 18 12.1965 18 12.7487C18 13.301 17.5523 13.7487 17 13.7487H13C12.5955 13.7487 12.2309 13.5051 12.0761 13.1314C11.9213 12.7578 12.0069 12.3276 12.2929 12.0416L14.5858 9.74875H13ZM6 14.0017C6 14.554 6.44772 15.0017 7 15.0017H7.58579L6.29289 16.2946C6.0069 16.5806 5.92134 17.0107 6.07612 17.3844C6.2309 17.7581 6.59554 18.0017 7 18.0017H10C10.5523 18.0017 11 17.554 11 17.0017C11 16.4494 10.5523 16.0017 10 16.0017H9.41421L10.7071 14.7088C10.9931 14.4228 11.0787 13.9927 10.9239 13.619C10.7691 13.2454 10.4045 13.0017 10 13.0017H7C6.44772 13.0017 6 13.4494 6 14.0017Z"
      fill="#737373"
    />
  </svg>
);
export default SnoozeIcon;
