import React, { Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { useDispatch } from 'react-redux';

// Actions
import { sessionActions } from 'state/actions/';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import KoalaTextButton from 'koala/components/TextButton';
import Wordmark from 'components/Wordmark';
import TrialExpired from './trial_expired.png';
import { Workspace } from 'types';
import KoalaButton from 'koala/components/Button';
import GlobalModal from 'components/GlobalModal';

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x8};
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
`;

const Footer = styled.div`
  margin-bottom: ${theme.spacing.x3};
  display: flex;
  gap: ${theme.spacing.x3};
  align-items: center;
  button.secondary,
  a {
    color: ${theme.colors.subtleText};
    text-decoration: none;
    font-weight: normal;
  }
`;

const Container = styled.div`
  flex: 1;
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    font-size: 2.2rem;
    font-weight: 700;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 90rem;
  gap: ${theme.spacing.x3};
  margin-bottom: ${theme.spacing.x4};

  img {
    width: 42rem;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  span {
    font-weight: 800;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;

const InviteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

interface Props {
  membership: any;
}

function Suspended(props: Props) {
  const dispatch = useDispatch();
  const { membership } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const isOwner = ['owner'].includes(membership.role);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const handleLogout = (e: any) => {
    dispatch(sessionActions.logout());
  };

  const handleInvite = (e: any) => {
    e.preventDefault();
    const action = `workspace:${currentWorkspace.slug}:invite`;
    dispatch(setGlobalModalContent(action));
  };

  // Displays the list of templates
  return (
    <ContainerWrapper>
      <GlobalModal />
      <Container>
        <Helmet>
          <title>Your trial of Tability has expired | Tability</title>
        </Helmet>

        <WordmarkWrapper>
          <Wordmark type="dark" />
        </WordmarkWrapper>
        <Wrapper>
          <div>
            <img src={TrialExpired} alt="Tabby Explore" />
          </div>
          <MessageContainer>
            <h1>Your free trial of Tability has expired</h1>
            <p>
              Upgrade to continue using your workspace <span>{currentWorkspace.name}</span>.
            </p>
            {isOwner && (
              <Fragment>
                <Actions>
                  <Link to={`/${workspaceSlug}/settings/billing`} className="button primary">
                    Upgrade
                  </Link>
                  <a
                    href="https://calendly.com/tability/trial-demo"
                    target="_blank"
                    rel="nopener noreferrer"
                    className="button"
                  >
                    Schedule a demo
                  </a>
                </Actions>
              </Fragment>
            )}
            {!isOwner && <p>Please contact the owner of this workspace to update the billing information.</p>}
          </MessageContainer>
        </Wrapper>
        {!currentWorkspace.trial_period_extended && (
          <InviteContainer>
            <h3>Need more time to explore</h3>
            <p>Invite 2 people to get 1 more week to try Tability.</p>
            <KoalaButton onClick={handleInvite} appearance="secondary">
              Invite teammates
            </KoalaButton>
          </InviteContainer>
        )}
      </Container>
      <Footer>
        <Link to={`/${workspaceSlug}/settings/delete`}>Manage your workspace</Link>
        <Link to={`/account`}>Manage your account</Link>
        <div>
          <KoalaTextButton onClick={handleLogout} appearance="subtle">
            Logout
          </KoalaTextButton>
        </div>
      </Footer>
    </ContainerWrapper>
  );
}

export default Suspended;
