import React, { useEffect, Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useParams, useLocation } from 'react-router-dom';
import * as amplitudeUtils from 'utils/amplitude';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Helmet } from 'react-helmet';
import Cookies from 'universal-cookie';

// API
import * as remoteApi from 'api/remote';

import AccessDenied from 'components/AccessDenied';
import NotFound from 'components/NotFound';

import TabilityIconBlack from 'components/_assets/TabilityIconBlack';
import Wordmark from 'components/Wordmark';
import FormField from 'components/FormField';

import Loader from 'components/Loader';
import KoalaButton from 'koala/components/Button';
import ReactTooltip from 'react-tooltip';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import KoalaNCSPie from 'koala/components/NCSPie';
import KoalaIcon from 'koala/components/Icons';

import KoalaTextBadge from 'koala/components/TextBadge';
import DataContainer from 'components/DataContainer';
import { useTranslation } from 'react-i18next';
import * as objectiveUtils from 'utils/objectiveUtils';
import { Outcome } from 'types';
import EmbeddedOutcome from 'components/EmbeddedOutcome';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
  padding: ${theme.spacing.x1};
`;
const LabelSpacing = styled.div`
  margin-left: ${theme.spacing.x1};
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  .confidence {
    display: flex;
    align-items: center;
    position: relative;
    top: 2px;
  }

  h3 {
    margin-left: ${theme.spacing.x1};
  }
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.x1};

  background: ${theme.colors.N3};

  svg {
    margin-right: ${theme.spacing.x1};
    width: 2rem;
  }
`;

const HeaderActionsMeta = styled.div`
  display: flex;
  align-items: center;
  div.prct {
    margin-right: ${theme.spacing.x3};
  }

  .plan-link {
    font-weight: 600;
    color: ${theme.colors.subtleText};
  }

  button {
    margin-left: ${theme.spacing.x2};
  }
`;

const Container = styled.div``;

const AccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: ${theme.spacing.x3};
  }

  form {
    padding-bottom: ${theme.spacing.x8};
    width: 40rem;

    button {
      margin-right: ${theme.spacing.x2};
    }
    .danger {
      color: ${theme.colors.red};
    }
  }
`;

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x8};
`;

const ObjectiveMetrics = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${theme.spacing.x2};
  margin: ${theme.spacing.x1} 0;
`;

function OutcomeBlock() {
  const cookies = new Cookies();
  const location = useLocation();
  const { workspaceSlug, objectiveId } = useParams<{ workspaceSlug: string; objectiveId: string }>();
  const { t } = useTranslation(undefined, { useSuspense: false });
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.objective.embed.view', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const COOKIENAME = '_tbty_pp';
  const COOKIEPATH = location.pathname;

  const public_access_password = cookies.get(COOKIENAME);

  const params = {
    public_access_password,
  };

  // Construct the query key using the plan Id
  const queryKeyObjective = [queryKeys.currentObjective, objectiveId, params];
  const staleTime = 0;
  const [accessDenied, setAccessDenied] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [password, setPassword] = useState('');

  // Get the outcome details
  const {
    isError,
    error,
    data: objectiveResponse,
    isLoading,
  }: any = useQuery(queryKeyObjective, remoteApi.fetchPublicObjectiveDetails, {
    staleTime,
    onError: (error: any) => {
      const errorString = error.response.data.error;
      const accessDenied = errorString.includes('Public access is disabled');
      setAccessDenied(accessDenied);
      const passwordRequired = errorString.includes('Password is missing');
      setPasswordRequired(passwordRequired);
      if (public_access_password) {
        setPasswordError(true);
      }
    },
  });

  const objective = objectiveResponse ? objectiveResponse.data : null;

  // Construct the query key using the plan Id
  const queryKeyOutcomes = [queryKeys.outcomes, objectiveId, params];

  // Get the plan details
  const { data: outcomesResponse }: any = useQuery(queryKeyOutcomes, remoteApi.fetchPublicOutcomes, {
    staleTime,
  });

  let outcomes = [];
  if (outcomesResponse) {
    outcomes = outcomesResponse.data;
  }

  // Now we can display the app with the Chrome
  if (isLoading) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  if (accessDenied) {
    const workspacePath = `https://${process.env.REACT_APP_DOMAIN}/${workspaceSlug}/inbox#objective:${objectiveId}:show`;
    return (
      <Fragment>
        <Helmet>
          <title>{t('publicPlan.restricted.title')} | Tability</title>
        </Helmet>
        <AccessContainer>
          <WordmarkWrapper>
            <Wordmark type="dark" />
          </WordmarkWrapper>

          <h2>{t('workspaceEmbedOutcome.restrictedHeader')}</h2>
          <p>{t('workspaceEmbedOutcome.restrictedInfo')}</p>
          <p>
            <a href={workspacePath} target="_blank" rel="noopener noreferrer" className="button">
              {t('publicPlan.viewButton')}
            </a>
          </p>
        </AccessContainer>
      </Fragment>
    );
  }

  const handlePasswordChange = (e: any) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleSavePassword = (e: any) => {
    e.preventDefault();
    cookies.set(COOKIENAME, password, {
      path: COOKIEPATH,
      secure: true,
    });
    window.location.reload();
  };

  if (passwordRequired) {
    return (
      <Fragment>
        <Helmet>
          <title>{t('publicPlan.password.title')} | Tability</title>
        </Helmet>
        <AccessContainer>
          <WordmarkWrapper>
            <Wordmark type="dark" />
          </WordmarkWrapper>

          <h2>{t('workspaceEmbedOutcome.passwordHeader')}</h2>
          <form onSubmit={handleSavePassword}>
            <FormField>
              <label>{t('publicPlan.password.passwordLabel')}</label>
              <input type="password" value={password} onChange={handlePasswordChange} />
            </FormField>
            {passwordError && (
              <FormField>
                <span className="danger">{t('publicPlan.password.error')}</span>
              </FormField>
            )}
            <FormField>
              <KoalaButton submit>{t('shared.submit')}</KoalaButton>
              <KoalaButton isLink to="/" appearance="subtle">
                {t('publicPlan.password.return')}
              </KoalaButton>
            </FormField>
          </form>
        </AccessContainer>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  const { plan } = objective;
  const { workspace } = plan;

  const objectiveUrl = `https://${process.env.REACT_APP_DOMAIN}/${workspaceSlug}/plans/${plan.nano_slug}/outcomes#objective:${objective.nano_slug}:show`;

  const confidenceData = objectiveUtils.getConfidenceData(objective);
  const outcomeProgressData = objectiveUtils.getOutcomeProgressData(objective);
  const initiativeProgressData = objectiveUtils.getInitiativeProgressData(objective);

  return (
    <Container>
      <Helmet>
        <title>
          {workspace.name} | {objective.title} | Tability
        </title>
      </Helmet>

      <HeaderActions>
        <HeaderActionsMeta>
          <TabilityIconBlack />
          <a href={objectiveUrl} className="plan-link" target="_blank" rel="noopener noreferrer">
            {plan.title}
          </a>
        </HeaderActionsMeta>
        <HeaderActionsMeta>
          <KoalaButton href={objectiveUrl} target="_blank" rel="noopener noreferrer" appearance="subtle" size="small">
            {t('workspaceEmbedOutcome.viewButton')}
          </KoalaButton>
        </HeaderActionsMeta>
      </HeaderActions>
      <Header>
        <Title>
          <h3>{objective.title}</h3>
          <LabelSpacing>
            {objective.archived && (
              <KoalaTextBadge variant="violet-light" isLowercase>
                {t('shared.status.archived')}
              </KoalaTextBadge>
            )}
          </LabelSpacing>
        </Title>
      </Header>
      <ObjectiveMetrics>
        <DataContainer>
          <h4>{translate(workspace, CustomTermKey.OUTCOME, 2)}</h4>
          <KoalaCircularProgress data={outcomeProgressData} size="medium" />
        </DataContainer>
        <DataContainer>
          <h4>{translate(workspace, CustomTermKey.INITIATIVE, 2)}</h4>
          <KoalaCircularProgress data={initiativeProgressData} size="medium" />
        </DataContainer>
        <DataContainer>
          <h4>
            Confidence
            <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
          </h4>
          <ReactTooltip
            place="bottom"
            type="dark"
            className="tooltip"
            effect="solid"
            id="explain-ncs"
            delayHide={500}
            clickable={true}
          >
            Net Confidence Score (NCS) measures the overall
            <br />
            confidence of your plan.{' '}
            <a
              href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </ReactTooltip>
          <KoalaNCSPie
            centerLabel={[Math.round(objective.ncs).toString(), 'NCS']}
            data={confidenceData}
            size="medium"
          />
        </DataContainer>
      </ObjectiveMetrics>
      {outcomes.map((outcome: Outcome) => {
        return <EmbeddedOutcome outcome={outcome} key={outcome.nano_slug} planId={plan.nano_slug} />;
      })}
    </Container>
  );
}

export default React.memo(OutcomeBlock);
