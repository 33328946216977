import React from 'react';
import { Button, Wrapper } from 'react-aria-menubutton';
import styled from 'styled-components';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import MenuItems from './MenuItems';

const StyledWrapper = styled(Wrapper)<{ position: string; width: number }>`
  display: inline-block;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: 400;

  .AriaMenuButton-trigger {
    cursor: pointer;
    background-color: transparent;
  }

  .AriaMenuButton-trigger:hover,
  .AriaMenuButton-trigger:focus,
  .AriaMenuButton-trigger.is-open {
    outline: none;
  }

  .AriaMenuButton-trigger:active,
  .AriaMenuButton-trigger.is-open {
    background-color: #dcdcdc;
    background-image: none;
    border-color: #b5b5b5;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
  }

  .AriaMenuButton-menu {
    background: #fff;
    border: 1px solid rgba(200, 200, 200, 0.4);
    padding: 0.4rem;
    list-style-type: none;

    position: absolute;
    top: 100%;
    ${(props) => props.position}: 0;
    z-index: 99;
    border-radius: 3px;
    margin: 4px 0 0 0;
    border: 1px solid #d1d1d1;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    width: ${({ width }) => width}rem;
  }

  .AriaMenuButton-menu--flushRight {
    right: 0;
  }

  .AriaMenuButton-menuItem {
    cursor: pointer;
    padding: 8px;
    border-bottom: 1px solid #eee;
  }

  .AriaMenuButton-menuItem:hover,
  .AriaMenuButton-menuItem:focus {
    background-color: #4a5ba7;
    color: #fff;
  }

  .AriaMenuButton-menuItem.is-selected {
    cursor: default;
    font-weight: bold;
  }
`;

interface Props {
  callback: (value: string) => void;
  trigger: JSX.Element;
  unassignCallback?: () => void;
  ignoredMemberships?: string[];
  position?: 'left' | 'right';
  width?: number;
}

function SearchableDropdown(props: Props) {
  const { callback, trigger, unassignCallback } = props;
  const dispatch = useDispatch();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const canUnassign = unassignCallback !== undefined;

  const handleSelection = (value: any) => {
    const id = value.props['data-action'];
    if (id === 'invite user') {
      handleInvite();
    } else if (id === 'unassign' && unassignCallback) {
      unassignCallback();
    } else {
      callback(id);
    }
  };

  const handleInvite = () => {
    const action = `workspace:${workspaceSlug}:invite`;
    dispatch(setGlobalModalContent(action));
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const position = props.position || 'right';
  const width = props.width || 30;

  return (
    <StyledWrapper onSelection={handleSelection} onClick={handleClick} position={position} width={width}>
      <Button className={`AriaMenuButton-trigger`}>{trigger}</Button>
      <MenuItems canUnassign={canUnassign} ignoredMemberships={props.ignoredMemberships} />
    </StyledWrapper>
  );
}

export default SearchableDropdown;
