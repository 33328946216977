import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as remoteApi from 'api/remote';
import * as workspaceUtils from 'utils/workspaceUtils';
import parse from 'parse-link-header';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import styled from 'styled-components';
import KoalaIcon from 'koala/components/Icons';
import theme from 'theme';
import AuditEventItem from 'components/AuditEventItem';
const LoadMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${theme.spacing.x2};
`;
const EventList = styled.div`
  display: flex;
  flex-direction: column;
`;
const UpgradeBanner = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  background: ${theme.colors.V50};
  color: #fff;
  padding: ${theme.spacing.x1};
  font-weight: 600;
  border-radius: 4px;
  justify-content: center;
  margin: ${theme.spacing.x2} 0;

  .icon--upgrade {
    color: white;
  }
`;

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

interface Props {
  isPlan: boolean;
  sourceId: string;
}

function AuditLog(props: Props) {
  const { sourceId, isPlan } = props;
  const { t } = useTranslation();
  const translationKey = 'modals.auditLog';
  const dispatch = useDispatch();
  const history = useHistory();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const hasPremiumSubscription = workspaceUtils.hasPremiumSubscription(currentWorkspace);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  const canViewAudit = hasPremiumSubscription || currentWorkspace.pricing_version === 3;
  const isOwner = currentMembership.role === 'owner';

  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const eventsQueryKey = [queryKeys.events, workspaceSlug, sourceId];

  const {
    isLoading,
    isFetchingMore,
    data: outcomesResponse,
    fetchMore,
    canFetchMore,
  }: any = useInfiniteQuery(
    eventsQueryKey,
    isPlan ? remoteApi.fetchPlanAuditEvents : remoteApi.fetchSourceAuditEvents,
    {
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
    },
  );

  let events = [];

  if (outcomesResponse) {
    events = outcomesResponse;
  }

  // Display empty state if there are no events
  if (events.length === 0 || events[0].data.length === 0) {
    events = [];
  }

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleUpgrade = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
    history.push(`/${currentWorkspace.slug}/settings/billing`);
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        {!canViewAudit && (
          <UpgradeBanner>
            <KoalaIcon iconName="upgrade" />
            {t(`shared.availableDuringTrial`, { subscription: 'Premium' })}
            {isOwner && (
              <KoalaButton
                size="small"
                appearance="secondary"
                onClick={handleUpgrade}
                isLink
                to={`/${currentWorkspace.slug}/settings/billing`}
              >
                {t('workspaceInsights.upgrade.unlock')}
              </KoalaButton>
            )}
          </UpgradeBanner>
        )}
        {isLoading && <KoalaLoader />}
        {events.length === 0 && <div>{t(`${translationKey}.empty`)}</div>}

        {events.map((group: any, i: number) => {
          return (
            <EventList key={i}>
              {group.data.map((event: any) => (
                <AuditEventItem event={event} key={event.id} />
              ))}
            </EventList>
          );
        })}

        {canFetchMore && (
          <LoadMoreContainer>
            <KoalaButton onClick={() => fetchMore()} loading={isFetchingMore} appearance="secondary">
              {t('shared.loadMore')}
            </KoalaButton>
          </LoadMoreContainer>
        )}
      </ModalContent>
    </ModalGrid>
  );
}

export default AuditLog;
