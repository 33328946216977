import styled from 'styled-components'; // Fixing the build
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

const ErrorMesssage = styled.div`
  color: ${theme.colors.R70};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

function ConnectTableau() {
  const dispatch = useDispatch();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const { t } = useTranslation();
  const translationKey = 'modals.tableau';
  const [url, setUrl] = useState('');
  const [tokenName, setTokenName] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [error, setError] = useState('');
  const queryCache = useQueryCache();

  const [installTableauMutation, { isLoading }] = useMutation(remoteApi.installTableau, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.integrations]);
      dispatch(setGlobalModalContent(''));
    },
    onError: (e: AxiosError) => {
      const errorMessage = e.response?.data.error;
      setError(errorMessage ?? 'Unable to connect');
    },
  });

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    const pattern = /(?:https?:\/\/)?([^.]+)\.online\.tableau\.com\/#\/site\/([^/]+)\/home/;

    // Extract the parts using the regex pattern
    const matches = url.match(pattern);

    let environment = '';
    let site = '';
    if (matches) {
      environment = matches[1];
      site = matches[2];
    }
    if (!environment || !site) {
      setError('URL does not match expected pattern');
      return;
    }
    const params = {
      body: {
        site,
        environment,
        tokenName,
        accessToken,
      },
      workspaceSlug: workspaceSlug,
    };
    installTableauMutation(params);
  };

  const allowSave = url && tokenName && accessToken && !isLoading;
  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`, { database: 'Tableau' })}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleSave}>
          <FormField>
            <label>{t(`${translationKey}.url`)}</label>
            <input
              placeholder={t(`${translationKey}.urlPlaceholder`) ?? 'URL'}
              className="segment-input"
              value={url}
              onChange={(e) => setUrl(e.currentTarget.value)}
            />
            <small className="subtle">{t(`${translationKey}.urlInfo`)}</small>
          </FormField>
          <FormField>
            <label>{t(`${translationKey}.tokenName`)}</label>
            <input
              placeholder={t(`${translationKey}.namePlaceholder`) ?? 'Token name'}
              className="segment-input"
              value={tokenName}
              onChange={(e) => setTokenName(e.currentTarget.value)}
            />
          </FormField>
          <FormField>
            <label>{t(`${translationKey}.accessToken`)}</label>
            <input
              placeholder={t(`${translationKey}.accessToken`) ?? 'Personal access token'}
              className="segment-input"
              value={accessToken}
              onChange={(e) => setAccessToken(e.currentTarget.value)}
            />
            <small className="subtle">
              <Trans
                i18nKey={`${translationKey}.link`}
                components={{
                  a: (
                    <a
                      style={{ color: theme.colors.blue }}
                      href="https://help.tableau.com/current/pro/desktop/en-us/useracct.htm#create-a-personal-access-token"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      text
                    </a>
                  ),
                }}
              />
            </small>
          </FormField>

          {error && (
            <ErrorMesssage>
              <small>{t(`${translationKey}.error`, { database: 'Tableau' })}</small>
              <small>Error: {error}</small>
            </ErrorMesssage>
          )}
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!allowSave} loading={isLoading}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default ConnectTableau;
