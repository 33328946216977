// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { getTime, parseISO, format, toDate, isAfter } from 'date-fns';
import * as planUtils from 'utils/planUtils';
import EmptyStatePanel from 'components/EmptyStatePanel';
import Chart from 'components/CheckinChart';
import { VictoryChart, VictoryBar, VictoryVoronoiContainer, VictoryGroup } from 'victory';
import { useTranslation } from 'react-i18next';
// import { debounce } from 'lodash';
// import useResizeObserver from 'utils/resizeObserver';

const Container = styled.div`
  height: 25rem;
  display: flex;
  margin-bottom: ${theme.spacing.x1};
  width: 100%;

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

interface Props {
  trends: any[];
  plan: TabilityTypes.Plan;
}

interface ChartProps {
  currentData: any;
  yDomain: TabilityTypes.Domain;
  xDomain: any;
  isInsideTimeline: boolean;
  width: number;
}
function RenderChart(props: ChartProps) {
  const { currentData, yDomain, xDomain, isInsideTimeline, width } = props;
  const { i18n, t } = useTranslation();
  return (
    <VictoryChart
      width={width}
      height={250}
      domainPadding={10}
      padding={{ top: 30, bottom: 30, left: 50, right: 10 }}
      minDomain={{ y: 0 }}
      domain={{ x: xDomain }}
      containerComponent={<VictoryVoronoiContainer />}
    >
      {Chart.XAxis(i18n)}
      {Chart.YAxis()}
      {isInsideTimeline && Chart.TodayLine(yDomain, t)}

      <VictoryGroup scale="time">
        <VictoryBar
          barWidth={20}
          cornerRadius={{ topLeft: 2, topRight: 2 }}
          labels={({ datum }) => [`${format(toDate(datum.x), 'd MMM yyyy')}`, `${datum.y} check-ins`]}
          labelComponent={Chart.LabelTooltip()}
          data={currentData}
          interpolation="linear"
          style={{
            data: {
              fill: theme.colors.B20,
            },
          }}
        />
      </VictoryGroup>
    </VictoryChart>
  );
}

function CheckinsChart(props: Props) {
  const { trends, plan } = props;
  let yDomain: TabilityTypes.Domain = { min: 0, max: 0 };

  // resize the chart to fit the container
  const chartRef: any = useRef(null);

  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = (ev: Event) => {
    if (chartRef && chartRef.current) {
      setWidth(chartRef.current.offsetWidth);
    }
  };

  // useEffect replaces `componentDidMount` and others
  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    // Removes listener on unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      setWidth(chartRef.current.offsetWidth);
    }
  }, [chartRef]);

  // If the plan doesn't have a start and end date, display a warning
  if (!planUtils.hasTimeline(plan)) {
    return (
      <EmptyStatePanel>
        <p>Sorry, you need to define a timeline for your plan to see the progress chart.</p>
      </EmptyStatePanel>
    );
  }

  const currentData: any[] = []; // This data is used to trace the current line as a solid line

  const startDate: Date = parseISO(plan.start_at);
  const finishDate: Date = parseISO(plan.finish_at);
  let chartFinishDate = finishDate;

  trends.forEach((trend: any, index: number) => {
    const date = parseISO(trend.week);
    if (isAfter(date, chartFinishDate)) {
      chartFinishDate = date;
    }

    // Create the point using the checkin data
    const point: any = {
      x: date,
      y: trend.checkins_count,
      timestamp: getTime(date),
      trend,
    };
    currentData.push(point);

    // Update domain
    yDomain.max = Math.max(point.y, yDomain.max);
  });

  const isInsideTimeline = planUtils.isInPlanTimeline(startDate, finishDate, new Date());
  const xDomain = [startDate, chartFinishDate];
  if (yDomain.max === 0) {
    yDomain.max = 10;
  }

  // Now we can display the app with the Chrome
  return (
    <Container className="panel-checkins-chart" ref={chartRef}>
      <RenderChart
        currentData={currentData}
        width={width}
        xDomain={xDomain}
        yDomain={yDomain}
        isInsideTimeline={isInsideTimeline}
      />
    </Container>
  );
}

export default CheckinsChart;
