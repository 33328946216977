import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import ZendeskSalesIcon from 'components/_assets/IconZendeskSales.png';
import KoalaButton from 'koala/components/Button';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
  state: string;
}

function ZendeskSalesIntegration(props: Props) {
  const { workspace, integrations, state } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallZendeskSalesMutation, { isLoading: isUninstallingZendeskSales }] = useMutation(
    remoteApi.uninstallZendeskSales,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
      },
    },
  );

  const handleUninstallZendeskSales = (e: any) => {
    e.preventDefault();
    uninstallZendeskSalesMutation(workspace.slug);
  };

  const zendeskSalesIntegration = integrations.filter((i: Integration) => i.integration_type === 'zendesk_sales')[0];

  const zendeskSalesRedirectURL = `https://${process.env.REACT_APP_DOMAIN}/install/zendesk_sales`;
  const zendeskSalesOAuthURL =
    `https://api.getbase.com/oauth2/authorize` +
    `?client_id=${process.env.REACT_APP_ZENDESK_SALES_CLIENT_ID}` +
    `&response_type=code&redirect_uri=${zendeskSalesRedirectURL}&state=${state}
`;
  const uninstallZendeskSalesLabel = isUninstallingZendeskSales ? (
    <KoalaLoader />
  ) : (
    t('workspaceSettingsIntegrations.remove')
  );

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={ZendeskSalesIcon} alt="Zendesk Sales" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'Zendesk Sales' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Zendesk Sales' })}</p>
      </IntegrationContent>
      <IntegrationAction>
        {zendeskSalesIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: zendeskSalesIntegration.connected_to }}
              />
            </div>
            <KoalaButton href={zendeskSalesOAuthURL} appearance="secondary" size="small">
              {t('workspaceSettingsIntegrations.reinstall')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={handleUninstallZendeskSales}>
              {uninstallZendeskSalesLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!zendeskSalesIntegration && (
          <KoalaButton href={zendeskSalesOAuthURL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Zendesk Sales' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default ZendeskSalesIntegration;
