/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useInfiniteQuery, useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import queryKeys from 'config/queryKeys';
import { useHistory, Redirect } from 'react-router-dom';
import parse from 'parse-link-header';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';

// Routes
import { WORKSPACE_PLAN_WRITE_ROUTE, WORKSPACE_PLAN_SETTINGS_ROUTE } from 'routes';

// API
import { fetchPlans, createPlan, copyPlan, archivePlan, updatePlan } from 'api/remote';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import EmptyStatePanel from 'components/EmptyStatePanel';
import Loader from 'components/Loader';
import KoalaButton from 'koala/components/Button';

import { AxiosResponse } from 'axios';
import PlanRow from './PlanRow';
import { MovedPlan } from '.';

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${theme.spacing.x2};
`;

const LoaderContainer = styled.div<{ level: number }>`
  padding: ${theme.spacing.x1} 0;
  padding-left: ${({ level }) => level * 3 + 1.8}rem;
`;

interface Props {
  workspace: TabilityTypes.Workspace;
  parentId: string;
  level: number;
  teamIds: string[];
  movePlan: (movedPlan: MovedPlan, hoverId: string | null) => void;
  selectedPlans: TabilityTypes.Plan[];
  setSelectedPlans: (newPlans: TabilityTypes.Plan[]) => void;
  setPlanlistRefreshedAt: (date: number) => void;
  hideDonePlans: boolean;
  showWatched: boolean;
}

function PlansList(props: Props) {
  const {
    workspace,
    parentId,
    level,
    teamIds,
    movePlan,
    selectedPlans,
    setSelectedPlans,
    setPlanlistRefreshedAt,
    hideDonePlans,
    showWatched,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t } = useTranslation();

  const storageKey = `__tbty_${workspace.slug}_expanded_plans`;
  const storedExpandedPlansValue = localStorage.getItem(storageKey);
  const storedExpandedPlans = storedExpandedPlansValue ? JSON.parse(storedExpandedPlansValue) : {};
  const currentMembership: TabilityTypes.Membership = useSelector(
    (state: any) => state.session.currentMembership,
    shallowEqual,
  );

  const [expandedPlans, setExpandedPlans] = useState(storedExpandedPlans);
  // Query that fetches all the plans
  //const { isLoading, isError, data: plansResponse }: any = useQuery([queryKeys.plans, workspace.slug], fetchPlans);

  const getNextPage = (response: AxiosResponse<TabilityTypes.Plan[]>) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  const watched = showWatched ? currentMembership.id : null;

  const filter = {
    children_of: parentId,
    team_id: teamIds,
    done: !hideDonePlans,
    watched,
  };

  const params = {
    filter,
  };

  const {
    isLoading,
    isError,
    isFetchingMore,
    data: plansResponse,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery([queryKeys.plans, workspace.slug, params], fetchPlans, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    onSuccess: () => {
      setPlanlistRefreshedAt(Date.now());
    },
  });

  // Archiving logic
  const [archivePlanMutation, { isLoading: isArchiving }] = useMutation(archivePlan, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.plans);
    },
  });

  const handleArchivePlan = (planId: string) => {
    const params = {
      archived: true,
    };
    const mutationParams = {
      planId,
      plan: params,
    };
    archivePlanMutation(mutationParams);
  };

  const [updatePlanMutation] = useMutation(updatePlan, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.plans);
    },
  });

  // Mutation that will create the workspace in the backend
  const [{ data: createdPlanResponse }]: [any, any] = useMutation(createPlan);

  // Plan mutations
  const [copyPlanMutation, { isLoading: isCopying }] = useMutation(copyPlan, {
    onSuccess: (response: AxiosResponse<TabilityTypes.Plan>) => {
      const createdPlan = response.data;
      const planRoute = WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspace.slug).replace(
        ':planId',
        createdPlan.nano_slug,
      );
      history.push(planRoute);
    },
  });

  // Redirect to the plan if a new plan has been created
  if (createdPlanResponse && createdPlanResponse.data) {
    const createdPlan = createdPlanResponse.data;
    const planRoute = WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspace.slug).replace(
      ':planId',
      createdPlan.nano_slug,
    );
    return <Redirect to={planRoute} />;
  }

  //
  if (isLoading || isArchiving) {
    return (
      <LoaderContainer level={level}>
        <Loader size="medium" />
      </LoaderContainer>
    );
  }

  if (isError) {
    return (
      <EmptyStatePanel>
        <p>
          <b>{t('workspacePlans.errorLine1')}</b>
        </p>
        <p>{t('workspacePlans.errorLine2')}</p>
      </EmptyStatePanel>
    );
  }

  let plans: AxiosResponse<TabilityTypes.Plan[]>[] = []; // Initialize the plans as an empty array

  if (plansResponse) {
    plans = plansResponse;
  }

  // Below we decide whether or not to show an empty state. We're not using workspace.plans_count for this because
  // it would require to reload the workspace after creating a plan, to make sure we have updated stats.

  // Instead we're going to wait for the plan query to happen, and see if we have any results.

  // We're using InfiniteQuery, so getting the count of plans is a bit weird.
  const plansCount = plans[0].data.length;

  // If we're at level 0 (root) and the number of plans is zero, then it means there are no plans in the workspace.
  // We need to check for level 0, because this same components is used to list sub-plans.
  if (level === 0 && plansCount === 0) {
    return <EmptyStatePanel>{t('workspacePlans.emptyState')}</EmptyStatePanel>;
  }

  const toggleExpand = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const planId = e.currentTarget.dataset.plan;

    let newExpandedPlans;

    if (expandedPlans[planId]) {
      newExpandedPlans = {
        ...expandedPlans,
        [planId]: false,
      };
    } else {
      newExpandedPlans = {
        ...expandedPlans,
        [planId]: true,
      };
    }

    setExpandedPlans(newExpandedPlans);
    localStorage.setItem(storageKey, JSON.stringify(newExpandedPlans));
  };

  const handleEdit = (planId: string) => {
    const planRoute = WORKSPACE_PLAN_SETTINGS_ROUTE.replace(':workspaceSlug', workspace.slug).replace(
      ':planId',
      planId,
    );
    history.push(planRoute);
  };

  const handleCopyPlan = (planId: string) => {
    copyPlanMutation(planId);
  };

  const handleUnpublish = (planId: string) => {
    const planParams = {
      state: 'draft',
    };
    const mutationParams = {
      planId: planId,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  const handleCreateSubPlan = (parent_id: string) => {
    dispatch(setGlobalModalContent(`plan:${parent_id}:create.plan`));
  };

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    const planId = value.props['data-plan-id'];
    const blockId = `plan:${planId}`;
    switch (action) {
      case 'create-plan':
        handleCreateSubPlan(planId);
        break;
      case 'copy-plan':
        handleCopyPlan(planId);
        break;
      case 'upgrade-copy-plan':
        const upgradeModalHash = 'workspace:tability20-essentials:upgrade';
        dispatch(setGlobalModalContent(upgradeModalHash));
        break;
      case 'delete-plan':
        dispatch(setGlobalModalContent(`${blockId}:delete`));
        break;
      case 'move-plan':
        dispatch(setGlobalModalContent(`${blockId}:move`));
        break;
      case 'edit-plan':
        handleEdit(planId);
        break;
      case 'archive-plan':
        if (window.confirm('Are you sure that you want to archive this plan?')) {
          handleArchivePlan(planId);
        }
        break;
      case 'unpublish-plan':
        handleUnpublish(planId);
        break;
    }
  };

  return (
    <Fragment>
      {plans.map((group: AxiosResponse<TabilityTypes.Plan[]>, i: number) => {
        return (
          <Fragment key={i}>
            {group.data.map((p: TabilityTypes.Plan, index: number) => {
              // This little bit of logic will expand the level 0 by default if the user has never clicked on
              // the expand icon
              // This works because until you've expanded the plans for the first time, the expandedPlans object is
              // undefined.
              if (expandedPlans[p.id] === undefined && level === 0) {
                expandedPlans[p.id] = true;
              }

              return (
                <Fragment key={index}>
                  <PlanRow
                    expandedPlans={expandedPlans}
                    level={level}
                    plan={p}
                    handleMenuSelection={handleMenuSelection}
                    toggleExpand={toggleExpand}
                    workspace={workspace}
                    handleCreateSubPlan={handleCreateSubPlan}
                    key={index}
                    movePlan={movePlan}
                    selectedPlans={selectedPlans}
                    setSelectedPlans={setSelectedPlans}
                  />
                  {expandedPlans[p.id] && (
                    <PlansList
                      workspace={workspace}
                      parentId={p.id}
                      level={level + 1}
                      teamIds={teamIds}
                      movePlan={movePlan}
                      selectedPlans={selectedPlans}
                      setSelectedPlans={setSelectedPlans}
                      setPlanlistRefreshedAt={setPlanlistRefreshedAt}
                      hideDonePlans={hideDonePlans}
                      showWatched={showWatched}
                    />
                  )}
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}

      {canFetchMore && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMore()}
            loading={!!isFetchingMore}
            disabled={!!isFetchingMore}
            appearance="secondary"
          >
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
      {isCopying && <Loader size={'big'} />}
    </Fragment>
  );
}

export default React.memo(PlansList);
