import React, { useState } from 'react';

import TeamSearchableDropdown from 'components/TeamSearchableDropdown';
import { Outcome, TeamRelationship, Workspace } from 'types';
import { useMutation, useQueryCache, useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import styled from 'styled-components';
import theme from 'theme';
import TeamLabel from 'components/TeamLabel';
import { shallowEqual, useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.half};
  width: 100%;

  .text-badge-label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
interface Props {
  outcome: Outcome;
  canEdit: boolean;
}

function TeamRelationships(props: Props) {
  const { outcome, canEdit } = props;
  const queryCache = useQueryCache();

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [teamRelationships, setTeamRelationships] = useState<TeamRelationship[]>([]);

  const teamRelationshipQueryKey = [queryKeys.teamRelationships, outcome.id];
  const staleTime = 0;
  useQuery(teamRelationshipQueryKey, remoteApi.fetchOutcomeTeamRelationships, {
    staleTime,
    onSuccess: (response) => {
      setTeamRelationships(response.data);
    },
  });

  const [addTeamRelationshipMutation]: [any, any] = useMutation(remoteApi.createOutcomeTeamRelationship, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.teamRelationships);
    },
  });

  const [deleteTeamRelationshipMutation]: [any, any] = useMutation(remoteApi.deleteTeamRelationship, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.teamRelationships);
    },
  });

  const handleAssignTeam = (team_id: string) => {
    if (team_id) {
      const params = {
        team_id,
      };

      addTeamRelationshipMutation({
        outcomeId: outcome.id,
        team_relationship: params,
      });
    }
  };

  const ignoredTeamIds = teamRelationships.map((tr: TeamRelationship) => tr.team_id);

  return (
    <Container>
      {teamRelationships.map((teamRelationship: TeamRelationship, i) => {
        const { team } = teamRelationship;
        const handleUnassignTeam = (e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          deleteTeamRelationshipMutation(teamRelationship.id);
        };

        if (canEdit) {
          return (
            <TeamLabel
              team={team}
              key={i}
              handleUnassign={handleUnassignTeam}
              isLink={true}
              workspace={currentWorkspace}
            />
          );
        } else {
          return <TeamLabel team={team} key={i} isLink={true} workspace={currentWorkspace} />;
        }
      })}
      <TeamSearchableDropdown
        canEdit={canEdit}
        ignoredTeams={ignoredTeamIds}
        callback={handleAssignTeam}
        trigger={<KoalaIconButton iconName="plus" size="small" />}
      />
    </Container>
  );
}

export default React.memo(TeamRelationships);
