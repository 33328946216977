import FormField from 'components/FormField';
import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import * as remoteApi from 'api/remote';
import KoalaButton from 'koala/components/Button';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { Plan } from 'types';
import AsyncSelect from 'react-select/async';
import PlanIconLabel from 'components/PlanIconLabel';
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation } from 'react-i18next';

const PlanInfoContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;
interface Props {
  planId: string;
}

function MovePlan(props: Props) {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const dispatch = useDispatch();
  const { planId } = props;
  const [plan, setPlan] = useState<Plan>();
  const [parentPlan, setParentPlan] = useState<Plan | null>();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.movePlan';

  // Get the plan details
  useQuery([queryKeys.currentPlan, planId], remoteApi.fetchPlanDetails, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const plan: Plan = response ? response.data : null;
      setPlan(plan);
      setParentPlan(plan.parent);
    },
  });

  // Mutation that will update the plan in the backend
  const [updatePlanMutation]: [any, any] = useMutation(remoteApi.updatePlan, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.plans);
      queryCache.invalidateQueries([queryKeys.currentPlan, planId]);
      dispatch(setGlobalModalContent(''));
    },
  });

  if (!plan) {
    return null;
  }

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSelectParent = (selectedParent: any) => {
    setParentPlan(selectedParent);
  };

  const fetchData = (input: string) => {
    const filter = input
      ? {
          title: input,
          exclude: plan.id,
        }
      : {
          exclude: plan.id,
          status: 'active',
        };
    const params = {
      filter,
      order_direction: 'asc',
      order_attribute: 'title',
      limit: 10,
    };

    // @ts-ignore
    return remoteApi.fetchPlans(queryKeys.plans, workspaceSlug, params, undefined).then((response) => {
      const plans = response.data;
      return plans;
    });
  };

  const handleLoadOptions = (input: string) => {
    return fetchData(input);
  };

  const handleSave = () => {
    const parent_id = parentPlan ? parentPlan.id : null;
    const params = {
      parent_id,
    };
    updatePlanMutation({
      planId,
      plan: params,
    });
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <FormField>
          <PlanInfoContainer>
            <p>{t(`${translationKey}.info`)}</p>
            <b>
              <PlanIconLabel plan={plan} size="small" />
            </b>
          </PlanInfoContainer>
        </FormField>
        <FormField>
          <label>{t(`${translationKey}.parent`)}</label>
          <AsyncSelect
            isClearable
            defaultOptions
            placeholder={t(`${translationKey}.placeholder`)}
            value={parentPlan}
            loadOptions={handleLoadOptions}
            onChange={(option) => handleSelectParent(option)}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.title}
            formatOptionLabel={(option) => <PlanIconLabel plan={option} size="small" />}
          />
        </FormField>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave}>{t('shared.save')}</KoalaButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default MovePlan;
