import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextBadge from 'koala/components/TextBadge';
import React from 'react';
import { useMutation, useQueryCache } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { Tag } from 'types';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';

const Container = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.x1};
  grid-template-columns: 1fr auto;
`;

interface Props {
  tag: Tag;
}

function TagRow(props: Props) {
  const { tag } = props;
  const queryCache = useQueryCache();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const [deleteTagsMutation] = useMutation(remoteApi.deleteTag, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentWorkspace);
      queryCache.invalidateQueries([queryKeys.tags, workspaceSlug]);
    },
  });

  const handleDelete = () => {
    if (window.confirm('Delete this tag?')) {
      deleteTagsMutation({ workspaceSlug, tagId: tag.id });
    }
  };

  return (
    <Container>
      <KoalaTextBadge variant="neutral-outlined" isLowercase edge="circle" maxWidth="fit-content">
        {tag.name}
      </KoalaTextBadge>
      <KoalaIconButton iconName="trash" onClick={handleDelete} />
    </Container>
  );
}

export default React.memo(TagRow);
