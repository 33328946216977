import React from 'react';

const GiftIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.78571 3C6.45489 3 6 3.33818 6 3.99902C6 4.65986 6.45489 4.99804 6.78571 4.99804H8.67375C8.60483 4.81629 8.52481 4.62719 8.43283 4.43998C7.9738 3.50568 7.42135 3 6.78571 3ZM15.6209 5.50528H18C18.5523 5.50528 19 5.95299 19 6.50528V9.50381C19 10.0561 18.5523 10.5038 18 10.5038V17.0005C18 17.4982 17.634 17.9201 17.1414 17.9904L10.1599 18.9873C10.1078 18.9957 10.0544 19 10 19C9.94557 19 9.89216 18.9957 9.84009 18.9873L2.85865 17.9904C2.36597 17.9201 2 17.4982 2 17.0005V10.5038C1.44772 10.5038 1 10.0561 1 9.50381V6.50528C1 5.95299 1.44772 5.50528 2 5.50528H4.3791C4.13511 5.05116 4 4.53072 4 3.99902C4 2.4518 5.14409 1 6.78571 1C8.41117 1 9.42117 2.13513 10 3.13167C10.5788 2.13513 11.5888 1 13.2143 1C14.8559 1 16 2.4518 16 3.99902C16 4.53072 15.8649 5.05116 15.6209 5.50528ZM11 16.8471L16 16.1331V10.5038H11V16.8471ZM9 10.5038V16.8471L4 16.1331V10.5038H9ZM11 8.50381H17V7.50528H11V8.50381ZM9 7.50528V8.50381H3V7.50528H9ZM13.2143 4.99804H11.3263C11.3952 4.81629 11.4752 4.62719 11.5672 4.43998C12.0262 3.50568 12.5787 3 13.2143 3C13.5451 3 14 3.33818 14 3.99902C14 4.65986 13.5451 4.99804 13.2143 4.99804Z"
      fill="#737373"
    />
  </svg>
);

export default GiftIcon;
