import styled from 'styled-components';
import { colors } from 'koala/shared/styles';

const Icon = styled.div<{ bgImage?: string; size?: number }>`
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.N5};
  background-image: ${(props) => props.bgImage};
  color: ${(props) => (props.bgImage ? 'transparent' : colors.N100)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  font-weight: 600;
  padding: 0px;
  cursor: unset;
  :hover,
  :active,
  :focus {
    outline: none;
    background-image: ${(props) => props.bgImage};
    background-color: ${colors.N5};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    filter: none;
  }

  &.avatar--small {
    width: 2.2rem;
    height: 2.2rem;
    font-size: 1rem;
  }
  &.avatar--medium {
    width: 2.4rem;
    height: 2.4rem;
    font-size: 1.2rem;
  }
  &.avatar--large {
    width: 4rem;
    height: 4rem;
    font-size: 1.6rem;
  }
  &.avatar--xlarge {
    width: 6rem;
    height: 6rem;
    font-size: 2.4rem;
  }
  &.avatar--custom-size {
    width: ${(props) => props.size}rem;
    height: ${(props) => props.size}rem;
    font-size: ${(props) => (props.size ? props.size / 2.5 : 1.2)}rem;
  }

  &.avatar--border {
    border: 1px solid ${colors.N0};
  }
  &.avatar--purple {
    background: ${colors.V20};
    color: ${colors.V90};
  }
  &.avatar--red {
    background: ${colors.R5};
    color: ${colors.R100};
  }
  &.avatar--green {
    background: ${colors.G10};
    color: ${colors.G100};
  }
  &.avatar--yellow {
    background: ${colors.Y20};
    color: ${colors.Y100};
  }
  &.avatar--blue {
    background: ${colors.B20};
    color: ${colors.B80};
  }
  &.avatar--orange {
    background: ${colors.O20};
    color: ${colors.O90};
  }
  &.avatar--teal {
    background: ${colors.T20};
    color: ${colors.T100};
  }
`;

export const IconButton = styled(Icon).attrs({ as: 'button' })<{ type: string }>`
  cursor: pointer;

  :focus,
  :active {
    border: 2px solid ${colors.B50};
    outline-offset: 2px;
    filter: brightness(85%);
  }
  :hover {
    filter: brightness(90%);
  }
`;

export default Icon;
