import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache, useQuery } from 'react-query';
import theme from 'theme';
import { Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// API
import * as remoteApi from 'api/remote';
import * as workspaceUtils from 'utils/workspaceUtils';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';

// API
import KoalaButton from 'koala/components/Button';
import queryKeys from 'config/queryKeys';
import { Trans, useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import ReactTooltip from 'react-tooltip';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 15rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const RadioBlock = styled.div`
  display: flex;
  align-items: center;
  label {
    color: #151515;
    text-transform: none;
    font-weight: normal;
    font-size: 1.6rem;

    input {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

const InlineDiv = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingsPrivacy(props: Props) {
  const { workspace } = props;
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [viewers_name_enabled, setViewersNameEnabled] = useState(workspace.viewers_name_enabled.toString());
  const [open_ai_enabled, setOpenAIEnabled] = useState(workspace.open_ai_enabled.toString());
  const [custom_open_ai_access_token, setCustomOpenAiAccessToken] = useState('');
  const [ai_metrics_enabled, setAIMetricsEnabled] = useState(workspace.ai_metrics_enabled.toString());
  const [maskedAIField, setMaskedAIField] = useState(true);

  const showCustomOpenAiToken = workspaceUtils.hasPremiumSubscription(workspace);

  // We need to fetch the admin details to get the workspace auth token
  const queryKey = [queryKeys.currentWorkspace, workspace.slug, 'admin'];
  useQuery(queryKey, remoteApi.fetchWorkspaceAdminDetails, {
    staleTime: 0,
    onSuccess: (response) => {
      const workspace: TabilityTypes.Workspace = response.data;
      setCustomOpenAiAccessToken(workspace.custom_open_ai_access_token);
    },
  });

  // Workspace mutations
  const [updateWorkspaceMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.updateWorkspace, {
    onMutate: () => {
      setIsFormSaved(false);
    },
    onSuccess: (response) => {
      setIsFormSaved(true);
      setIsFormDirty(false);
      queryCache.invalidateQueries(queryKeys.currentWorkspace);
    },
  });

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else if (error.response.status === 402) {
      errors = {
        subscription: [t('errors.planError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const params = {
      viewers_name_enabled,
      open_ai_enabled,
      ai_metrics_enabled,
      custom_open_ai_access_token,
    };
    updateWorkspaceMutation({
      workspaceId: workspace.id,
      workspace: params,
    });
  };

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'accepted_domains':
        return t('errors.acceptedDomains');
      case 'invite_link_token_enabled':
        return t('errors.invitationLink');
      default:
        return '';
    }
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'viewers_name_enabled':
        setViewersNameEnabled(value);
        break;
      case 'open_ai_enabled':
        setOpenAIEnabled(value);
        if (value === 'false') {
          setAIMetricsEnabled(value);
        }
        break;
      case 'ai_metrics_enabled':
        setAIMetricsEnabled(value);
        break;
      case 'custom_open_ai_access_token':
        setCustomOpenAiAccessToken(value);
        break;
    }
  };

  const aiFieldType = maskedAIField ? 'password' : 'text';
  const aiFieldLabel = maskedAIField ? t('shared.show') : t('shared.hide');

  const toggleAIFieldMask = (e: MouseEvent) => {
    e.preventDefault();
    setMaskedAIField(!maskedAIField);
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsPrivacy.title')} | Tability
        </title>
      </Helmet>
      <Prompt
        message={t('shared.confirmLeave') ?? 'Your changes are not saved. Are you sure you want to leave?'}
        when={isFormDirty}
      />
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsPrivacy.title')}</h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <label>{t('workspaceSettingsPrivacy.AIHeader')}</label>
              <RadioBlock>
                <label>
                  <input
                    type="radio"
                    name="open_ai_enabled"
                    autoComplete="off"
                    value="true"
                    checked={open_ai_enabled === 'true'}
                    onChange={handleChange}
                  />
                  <Trans i18nKey="workspaceSettingsPrivacy.enabled" components={{ b: <b /> }} />
                </label>
              </RadioBlock>
              <RadioBlock>
                <label>
                  <input
                    type="radio"
                    name="open_ai_enabled"
                    autoComplete="off"
                    value="false"
                    checked={open_ai_enabled === 'false'}
                    onChange={handleChange}
                  />
                  <Trans i18nKey="workspaceSettingsPrivacy.disabled" components={{ b: <b /> }} />
                </label>
              </RadioBlock>
            </FormField>
            {open_ai_enabled === 'true' && (
              <>
                <FormField>
                  <label>{t('workspaceSettingsPrivacy.customOpenAIAccessToken')}</label>
                  <InlineDiv data-tip data-for="custom-open-ai-token">
                    <input
                      className="small"
                      value={custom_open_ai_access_token}
                      onChange={handleChange}
                      autoComplete="off"
                      name="custom_open_ai_access_token"
                      disabled={!showCustomOpenAiToken}
                      type={aiFieldType}
                    />
                    <KoalaButton onClick={toggleAIFieldMask} appearance="subtle">
                      {aiFieldLabel}
                    </KoalaButton>
                  </InlineDiv>
                  <small className="subtle">
                    {t('workspaceSettingsPrivacy.customOpenAIInfo')}. &nbsp;
                    <a
                      href="https://help.openai.com/en/articles/4936850-where-do-i-find-my-openai-api-key"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('workspaceSettingsPrivacy.customOpenAIDoc')}
                    </a>
                  </small>
                </FormField>
                {!showCustomOpenAiToken && (
                  <ReactTooltip id="custom-open-ai-token" place="bottom" type="dark" effect="solid">
                    {t('workspaceSettingsPrivacy.customOpenAIUpgradeRequired')}
                  </ReactTooltip>
                )}
                <FormField>
                  <label>{t('workspaceSettingsPrivacy.AIMetrics')}</label>
                  <RadioBlock>
                    <label>
                      <input
                        type="radio"
                        name="ai_metrics_enabled"
                        autoComplete="off"
                        value="true"
                        checked={ai_metrics_enabled === 'true'}
                        onChange={handleChange}
                      />
                      <Trans
                        i18nKey="workspaceSettingsPrivacy.metricsEnabled"
                        components={{ b: <b /> }}
                        values={{ outcome: translate(workspace, CustomTermKey.OUTCOME, 1) }}
                      />
                    </label>
                  </RadioBlock>
                  <RadioBlock>
                    <label>
                      <input
                        type="radio"
                        name="ai_metrics_enabled"
                        autoComplete="off"
                        value="false"
                        checked={ai_metrics_enabled === 'false'}
                        onChange={handleChange}
                      />
                      <Trans
                        i18nKey="workspaceSettingsPrivacy.metricsDisabled"
                        components={{ b: <b /> }}
                        values={{ outcome: translate(workspace, CustomTermKey.OUTCOME, 1) }}
                      />
                    </label>
                  </RadioBlock>
                </FormField>
              </>
            )}
            <FormField>
              <label>{t('workspaceSettingsPrivacy.viewHeader')}</label>
              <RadioBlock>
                <label>
                  <input
                    type="radio"
                    name="viewers_name_enabled"
                    autoComplete="off"
                    value="true"
                    checked={viewers_name_enabled === 'true'}
                    onChange={handleChange}
                  />
                  <Trans i18nKey="workspaceSettingsPrivacy.public" components={{ b: <b /> }} />
                </label>
              </RadioBlock>
              <RadioBlock>
                <label>
                  <input
                    type="radio"
                    name="viewers_name_enabled"
                    autoComplete="off"
                    value="false"
                    checked={viewers_name_enabled === 'false'}
                    onChange={handleChange}
                  />
                  <Trans i18nKey="workspaceSettingsPrivacy.anon" components={{ b: <b /> }} />
                </label>
              </RadioBlock>
            </FormField>
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading}>
                {t('shared.save')}
              </KoalaButton>
              {isFormSaved && <div>{t('shared.saved')}</div>}
            </Actions>
          </Form>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsPrivacy;
