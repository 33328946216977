import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Account pages
import AccountDetails from 'pages/AccountDetails';
import AccountEmail from 'pages/AccountEmail';
import AccountPassword from 'pages/AccountPassword';
import Account2FA from 'pages/Account2FA';
import AccountDelete from 'pages/AccountDelete';
import AccountReferral from 'pages/AccountReferral';

// Components
//import AccessDenied from 'components/AccessDenied';
import NotFound from 'components/NotFound';
import AccountHeader from 'components/AccountHeader';
import { ChromeWrapperWithSettingsNav, ChromeHeader } from 'components/Chrome';

import * as routes from 'routes';

function AccountRoutes() {
  return (
    <ChromeWrapperWithSettingsNav>
      <ChromeHeader>
        <AccountHeader />
      </ChromeHeader>
      <Switch>
        <Route path={routes.ACCOUNT_ROOT_ROUTE} exact={true}>
          <AccountDetails />
        </Route>
        <Route path={routes.ACCOUNT_EMAIL_ROUTE} exact={true}>
          <AccountEmail />
        </Route>
        <Route path={routes.ACCOUNT_REFERRAL_ROUTE} exact={true}>
          <AccountReferral />
        </Route>
        <Route path={routes.ACCOUNT_PASSWORD_ROUTE} exact={true}>
          <AccountPassword />
        </Route>
        <Route path={routes.ACCOUNT_2FA_ROUTE} exact={true}>
          <Account2FA />
        </Route>
        <Route path={routes.ACCOUNT_DELETE_ROUTE} exact={true}>
          <AccountDelete />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </ChromeWrapperWithSettingsNav>
  );
}

export default AccountRoutes;
