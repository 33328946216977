import React, { Fragment, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Dashboard, Membership, Workspace } from 'types';
import { ChromeContent } from 'components/Chrome';
import styled from 'styled-components';
import theme from 'theme';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';
import WorkspaceHeader from 'components/WorkspaceHeader';
import ContentTitle from 'components/ContentTitle';
import KoalaButton from 'koala/components/Button';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import queryKeys from 'config/queryKeys';
import { useInfiniteQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import { AxiosResponse } from 'axios';
import parse from 'parse-link-header';
import EmptyStatePanel from 'components/EmptyStatePanel';
import KoalaLoader from 'koala/components/Loader';
import { hasPremiumSubscription, hasTrial } from 'utils/workspaceUtils';
import UpgradeRequiredV4 from './UpgradeRequiredV4';
import UpgradeBanner from './UpgradeBanner';
import DashboardTeaser from './dashboards_teaser.png';
import DashboardLine from './DashboardLine';
import _ from 'lodash';

const SearchInput = styled.input`
  border: 1px solid ${theme.colors.inputBorder};
  width: 30%;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const Mask = styled.div`
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
`;

const UpgradeModal = styled.div`
  position: absolute;
  left: 50%;
  z-index: 80;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  max-height: 25rem;
  height: 25rem;
  top: 50%;
  width: 60rem;
  max-width: 60rem;
  margin-left: -40rem;
  margin-top: -20rem;
  background: #fff;

  @media ${theme.devices.mobile} {
    width: 100%;
    margin: 20rem 0 0 0;
    margin-top: -20rem;
    left: unset;
    overflow: auto;
    height: 30rem;
    max-height: 30rem;
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x2};
`;

const Wrapper = styled.div`
  margin: 0 auto ${theme.spacing.x4} auto;
  max-width: 120rem;
  padding: 1.2rem ${theme.spacing.x2};
  display: flex;
  flex-direction: column;

  h3 {
    padding: ${theme.spacing.x2} 0;
  }

  .outcome-header {
    display: flex;
    flex-wrap: wrap;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const ListingContainer = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
`;

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  align-items: center;

  img {
    max-width: 80rem;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.2);
  }
`;
const GroupSeparator = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  background: ${theme.colors.N5};
  font-weight: 900;
  font-size: 1rem;
  text-transform: uppercase;
  border-bottom: 1px solid ${theme.colors.blockBorder};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  &.all-dashboards {
    border-top: 1px solid ${theme.colors.blockBorder};
  }
`;

const GroupContainer = styled.div``;

const EmptySection = styled.div`
  text-align: center;
  color: ${theme.colors.subtleText};
  padding: ${theme.spacing.x4};
`;

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

interface Props {
  workspace: Workspace;
}

function WorkspaceDashboards(props: Props) {
  const { workspace } = props;
  const dispatch = useDispatch();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  const [nameToSearch, setNameToSearch] = useState('');
  const workspaceSlug = workspace.slug;
  const handleCreateDashboard = () => {
    dispatch(setGlobalModalContent('dashboard::create'));
  };

  const dashboardQueryKey = [
    queryKeys.dashboards,
    'search',
    {
      workspaceSlug,
      limit: 5,
      filter: {
        title: nameToSearch,
        is_favorited_by: {
          is_favorite: false,
          membership_id: currentMembership.id,
        },
      },
    },
  ];
  const {
    data: dashboardsResponse,
    isLoading: dashboardsLoading,
    fetchMore,
    canFetchMore,
    isFetchingMore,
  } = useInfiniteQuery(dashboardQueryKey, remoteApi.searchDashboards, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  const favoritedDashboardQueryKey = [
    queryKeys.dashboards,
    'favorites',
    {
      workspaceSlug,
      limit: 5,
      filter: {
        title: nameToSearch,
        is_favorited_by: {
          is_favorite: true,
          membership_id: currentMembership.id,
        },
      },
    },
  ];
  const {
    data: dashboardsFavoritedResponse,
    isLoading: isLoadingFavorites,
    canFetchMore: canFetchMoreFavorites,
    fetchMore: fetchMoreFavorites,
    isFetchingMore: isFetchingMoreFavorites,
  } = useInfiniteQuery(favoritedDashboardQueryKey, remoteApi.searchDashboards, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  // START SEARCH LOGIC
  const performSearch = (newName: string) => {
    setNameToSearch(newName);
  };
  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );
  const handleSearch = (e: any) => {
    const newName = e.target.value;
    debouncePerformSearch.current(newName);
  };

  // Set upgrade required to true
  let upgradeRequired = true;
  let showUpgradeBanner = false;

  // Only Premium workspaces should have access to insights
  if (hasPremiumSubscription(currentWorkspace) || hasTrial(currentWorkspace)) {
    upgradeRequired = false;
  }

  if (hasTrial(currentWorkspace)) {
    showUpgradeBanner = true;
  }

  let dashboards: AxiosResponse<Dashboard[]>[] = [];
  let favoriteDashboards: AxiosResponse<Dashboard[]>[] = [];
  let isEmpty = false;
  if (dashboardsResponse) {
    dashboards = dashboardsResponse;
  }
  if (dashboardsFavoritedResponse) {
    favoriteDashboards = dashboardsFavoritedResponse;
  }

  const isDashboardsEmpty = dashboards.length === 0 || dashboards[0].data.length === 0;
  const isFavoriteEmpty = favoriteDashboards.length === 0 || favoriteDashboards[0].data.length === 0;
  if (isDashboardsEmpty && isFavoriteEmpty && !nameToSearch) {
    isEmpty = true;
  }

  const isLoading = dashboardsLoading;

  return (
    <Fragment>
      <MobileReadyChromeHeader>
        <WorkspaceHeader
          workspace={workspace}
          useGreyBackground={true}
          title={
            <HeaderTitle>
              <h1>{t('workspaceDashboards.title')}</h1>
            </HeaderTitle>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeContent>
        {upgradeRequired && (
          <Mask>
            <UpgradeModal>{<UpgradeRequiredV4 />}</UpgradeModal>
          </Mask>
        )}
        <Helmet>
          <title>
            {workspace.name} | {t('workspaceDashboards.title')} | Tability
          </title>
        </Helmet>
        <Wrapper>
          {showUpgradeBanner && <UpgradeBanner />}
          <ContentTitle>
            <SearchInput
              placeholder={t('workspaceDashboards.filter') ?? 'Filter dashboards by name'}
              onChange={handleSearch}
            />
            <HeaderActions>
              <KoalaButton onClick={handleCreateDashboard}>{t('workspaceDashboards.create')}</KoalaButton>
            </HeaderActions>
          </ContentTitle>
          {isEmpty && (
            <ListingContainer>
              <EmptyStatePanel>
                <PanelContainer>
                  <h3>{t('workspaceDashboards.empty.title')}</h3>
                  <img src={DashboardTeaser} alt="Tability dashboards" />
                  <p>
                    {t('workspaceDashboards.empty.description')}{' '}
                    <a
                      href="https://guides.tability.app/docs/features/reporting/custom-dashboards"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('shared.learnMore')}
                    </a>
                  </p>
                </PanelContainer>
              </EmptyStatePanel>
            </ListingContainer>
          )}
          {!isEmpty && (
            <ListingContainer>
              {isLoading && (
                <EmptyStatePanel>
                  <KoalaLoader />
                </EmptyStatePanel>
              )}

              <GroupContainer>
                <GroupSeparator className="item">{t('workspaceDashboards.favorites')}</GroupSeparator>
                {isFavoriteEmpty && (
                  <EmptySection className="item">{t('workspaceDashboards.noFavorites')}</EmptySection>
                )}
                {!isLoadingFavorites &&
                  favoriteDashboards.map((group, i: number) =>
                    group.data.map((dashboard: Dashboard) => (
                      <DashboardLine dashboard={dashboard} workspace={workspace} isFavorite={true} />
                    )),
                  )}
                {canFetchMoreFavorites && (
                  <LoadMore>
                    <KoalaButton
                      appearance="secondary"
                      onClick={() => fetchMoreFavorites()}
                      loading={!!isFetchingMoreFavorites}
                    >
                      {t('shared.loadMore')}
                    </KoalaButton>
                  </LoadMore>
                )}
              </GroupContainer>

              <GroupContainer>
                <GroupSeparator className="all-dashboards">{t('workspaceDashboards.allDashboards')}</GroupSeparator>
                {isDashboardsEmpty && <EmptySection>{t('workspaceDashboards.noDashboards')}</EmptySection>}
                {!dashboardsLoading &&
                  dashboards.map((group, i: number) =>
                    group.data.map((dashboard: Dashboard) => (
                      <DashboardLine dashboard={dashboard} workspace={workspace} />
                    )),
                  )}

                {canFetchMore && (
                  <LoadMore>
                    <KoalaButton onClick={() => fetchMore()} appearance="secondary" loading={!!isFetchingMore}>
                      {t('shared.loadMore')}
                    </KoalaButton>
                  </LoadMore>
                )}
              </GroupContainer>
            </ListingContainer>
          )}
        </Wrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceDashboards;
