import React, { useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryCache } from 'react-query';
import { Plan, Recommendation } from 'types';
import * as remoteApi from 'api/remote';
import styled from 'styled-components';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import FeedbackCard from './FeedbackCard';
import queryKeys from 'config/queryKeys';
import parse from 'parse-link-header';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import KoalaLoader from 'koala/components/Loader';
import KoalaToggleButton from 'koala/components/ToggleButton';
import ReactTooltip from 'react-tooltip';
import { AI_PLAN_FEEDBACK_HIDE_RESOLVED } from 'config/constants';
import KoalaTextBadge from 'koala/components/TextBadge';

const ScoreContainer = styled.div`
  background: #f3f3f3;
  border-radius: 4px;
  padding: ${theme.spacing.x2};
  margin: 0 ${theme.spacing.x2} ${theme.spacing.x2} 0;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  .ai-button {
    background: ${theme.colors.V5};
    color: ${theme.colors.V70};

    &:hover {
      background: ${theme.colors.V10};
    }
  }
`;
const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;

  label {
    font-size: 1.1rem;
  }
`;

const ErrorMesssage = styled.div`
  color: ${theme.colors.R70};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  margin-top: ${theme.spacing.half};
`;

const SectionTitle = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.x1};
`;

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

interface Props {
  plan: Plan;
}

function PlanAiFeedback(props: Props) {
  const { plan } = props;
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const [selectedRecommendation, setSelectedRecommendation] = useState('');
  const localStorageHideResolved = localStorage.getItem(AI_PLAN_FEEDBACK_HIDE_RESOLVED);
  const [hideResolvedRecommendations, setHideResolvedRecommendations] = useState(localStorageHideResolved === 'true');
  const [aiError, setAiError] = useState('');
  const filter = {
    pending: hideResolvedRecommendations,
  };
  const params = {
    filter,
  };
  const queryKey = [queryKeys.planRecommendations, plan.id, params];
  const staleTime = queryKeys.staleTime;

  // Get the existing recommendations
  const {
    data: recommendationsResponse,
    isLoading,
    canFetchMore,
    isFetchingMore,
    fetchMore,
  } = useInfiniteQuery(queryKey, remoteApi.fetchPlanRecommendations, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    staleTime,
  });

  const [analyseAIPlanMutation, { isLoading: isAnalysisLoading }] = useMutation(remoteApi.analyseAIPlan, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
    onError: (response: AxiosError) => {
      switch (response.response?.data.error) {
        case 'over_credit_limit':
          setAiError(t('workspacePlan.write.aiFeedback.overCreditLimit') ?? 'You have run out of AI credits');
          break;
        case 'empty_plan':
          setAiError(t('workspacePlan.write.aiFeedback.emptyPlan') ?? 'This plan is empty');
          break;
        case 'ai_error':
          setAiError(t('workspacePlan.write.aiFeedback.aiError') ?? 'An error occurred while generating the analysis');
          break;
      }
    },
  });
  const handleAnalysePlan = () => {
    setAiError('');
    analyseAIPlanMutation(plan.id);
  };
  const handleHideResolved = (hideResolved: boolean) => {
    localStorage.setItem(AI_PLAN_FEEDBACK_HIDE_RESOLVED, hideResolved.toString());
    setHideResolvedRecommendations(hideResolved);
  };

  const translationKey = 'workspacePlan.write.aiFeedback';

  return (
    <ScoreContainer>
      <SectionTitle>
        {t(`${translationKey}.title`)}
        <KoalaTextBadge variant="yellow" size="small" edge="square">
          beta
        </KoalaTextBadge>
      </SectionTitle>
      <div>
        <KoalaButton
          size="small"
          appearance="special"
          onClick={handleAnalysePlan}
          loading={isAnalysisLoading}
          disabled={isAnalysisLoading}
        >
          {t(`${translationKey}.generateAnalysis`)}
        </KoalaButton>
        {aiError && (
          <ErrorMesssage>
            <small>{aiError}</small>
          </ErrorMesssage>
        )}
      </div>

      <ToggleButtonContainer>
        <KoalaToggleButton
          handleChange={() => handleHideResolved(!hideResolvedRecommendations)}
          defaultChecked={hideResolvedRecommendations}
          id="hide-resolved-recommendations"
          dataFor="koala-toggle-hide-resolved-recommendations"
          size="small"
        />
        <label htmlFor="koala-toggle-hide-resolved-recommendations">
          {t(`${translationKey}.hideResolvedRecommendations`)}
        </label>
        <ReactTooltip id="koala-toggle-hide-resolved-recommendations" place="bottom" type="dark" effect="solid">
          {t(`${translationKey}.hideResolvedRecommendationsHelp`)}
        </ReactTooltip>
      </ToggleButtonContainer>

      {isLoading && <KoalaLoader />}

      {!isLoading &&
        recommendationsResponse?.map((group: AxiosResponse<Recommendation[]>) =>
          group.data.map((recommendation) => (
            <FeedbackCard
              recommendation={recommendation}
              selectedRecommendation={selectedRecommendation}
              setSelectedRecomendationCallback={setSelectedRecommendation}
              key={recommendation.id}
            />
          )),
        )}

      {canFetchMore && (
        <LoadMoreContainer>
          <KoalaButton
            appearance="secondary"
            size="small"
            loading={!!isFetchingMore}
            disabled={!!isFetchingMore}
            onClick={() => fetchMore()}
          >
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMoreContainer>
      )}
    </ScoreContainer>
  );
}

export default PlanAiFeedback;
