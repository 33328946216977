import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { Link } from 'react-router-dom';
import { CustomTermKey, translate } from 'utils/customTermUtils';

// API
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import { fetchInitiatives } from 'api/remote';

import InitiativeRow from 'components/InitiativeRow';
import MarkdownContent from 'components/MarkdownContent';
import OutcomeWeight from 'components/OutcomeWeight';
import { useTranslation } from 'react-i18next';
import MetricsCard from 'components/MetricsCard';
import * as remoteApi from 'api/remote';
import ContributingOutcome from './ContributingOutcome';
import KoalaIcon from 'koala/components/Icons';

export const BlockContent = styled.div`
  grid-area: content;
  background: transparent;
  margin: 0;
  position: relative;

  h3.outcome {
    font-size: 1.8rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .outcome-title {
    display: flex;
    gap: ${theme.spacing.x1};
    align-items: center;
  }
`;
const BlockMeta = styled.div`
  grid-area: meta;
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.x1};
  justify-content: flex-start;
`;

const BlockInitiative = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;
const BlockOutcome = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;
const BlockAdditionalContent = styled.div`
  grid-area: outcomes;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;
const BlockGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 22rem;
  grid-template-rows: auto;
  grid-template-areas:
    'content meta'
    'outcomes meta';
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1} 0;
  margin-bottom: ${theme.spacing.x2};

  .meta {
  }

  &:hover,
  &.selected,
  &:focus {
    .block-action {
      opacity: 1;
    }
  }

  &.no-hover-bg {
    &:hover {
      background: transparent;
    }
  }

  .block-action {
    opacity: 0;
  }
`;

const ExpandButton = styled.div`
  display: inline-flex;
  justify-content: center;
  line-height: 2rem;
  gap: ${theme.spacing.x1};

  .icon--custom {
    transition: all ease 0.2s;
  }
  .expanded {
    transform: rotate(90deg);
  }
`;

const RelatedContentBlock = styled.div`
  color: ${theme.colors.N70};
  cursor: pointer;

  &:hover {
    color: ${theme.colors.N100};
  }
`;
const ExpandedSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1};
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  isSelected: boolean;
  hideClosedInitiatives: boolean;
  defaultExpanded: boolean;
  setDefaultChanged: (value: boolean) => void;
  defaultChanged: boolean;
}

function OutcomeBlock(props: Props) {
  const { outcome, isSelected, hideClosedInitiatives, defaultExpanded, setDefaultChanged, defaultChanged } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  //const [contributing, setContributing] = useState<TabilityTypes.OutcomeRelationship[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const staleTime = 60 * 1000 * 5;
  const queryKeyInitiatives = [queryKeys.initiatives, outcome.id];
  const { data: initiativesResponse }: any = useQuery(queryKeyInitiatives, fetchInitiatives, {
    staleTime,
  });

  useEffect(() => {
    // when main expand/collapse triggered it will set default changed to false
    // so if default changed is false, reset isExpanded back to default
    if (!defaultChanged) {
      setIsExpanded(defaultExpanded ?? false);
    }
  }, [defaultChanged, defaultExpanded]);

  useEffect(() => {
    const shouldBeExpanded = defaultExpanded ?? false;
    if (shouldBeExpanded) {
      setIsExpanded(true);
      if (setDefaultChanged) {
        setDefaultChanged(true);
      }
    }
  }, [defaultExpanded, setDefaultChanged]);

  const toggleExpand = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
    if (setDefaultChanged) {
      setDefaultChanged(true);
    }
  };

  // Get the relationship outcomes
  const queryKeyRelationship = [queryKeys.outcomeRelationships, outcome.id];
  const { data: outcomesRelationshipResponse }: any = useQuery(
    queryKeyRelationship,
    remoteApi.fetchOutcomeRelationships,
    {
      staleTime,
    },
  );

  let initiatives: TabilityTypes.Initiative[] = initiativesResponse ? initiativesResponse.data : [];
  if (hideClosedInitiatives) {
    initiatives = initiatives.filter((initiative) => initiative.state !== 'closed');
  }

  const outcomeRelationships: TabilityTypes.OutcomeRelationship[] = outcomesRelationshipResponse
    ? outcomesRelationshipResponse.data
    : [];
  const contributing: TabilityTypes.OutcomeRelationship[] = [];
  outcomeRelationships.forEach((outcomeRelationship) => {
    if (
      outcomeRelationship.outcome_target_id === outcome.id &&
      outcomeRelationship.relationship_type === 'contributes_to'
    ) {
      contributing.push(outcomeRelationship);
    }
  });

  const blockId = `outcome:${outcome.nano_slug}`;
  const hashPath = `#${blockId}:show`;

  const currentCheckin = outcome.current_checkin;

  const body = currentCheckin ? currentCheckin.body : 'Waiting on first check-in.';

  const className = isSelected ? 'selected' : '';

  const hasAdditionalContent = contributing.length > 0 || initiatives.length > 0;

  return (
    <BlockGrid className={className}>
      <BlockContent>
        <div className="outcome-title">
          <OutcomeWeight outcome={outcome} />
          <h3 className="outcome">
            <Link to={hashPath}>{outcome.title}</Link>
          </h3>
        </div>
        {!currentCheckin && <p className="subtle">{t('publicPlan.noCheckin')}</p>}
        {currentCheckin && body && <MarkdownContent source={body} />}
        {currentCheckin && !body && <p className="subtle">{t('shared.noComment')}</p>}
      </BlockContent>
      <BlockMeta>
        <MetricsCard outcome={outcome} />
      </BlockMeta>
      {hasAdditionalContent && (
        <BlockAdditionalContent>
          <RelatedContentBlock onClick={toggleExpand}>
            <ExpandButton>
              <KoalaIcon iconName="rightChevron" iconSize={1.2} className={isExpanded ? 'expanded' : 'collapsed'} />
              <small>{t('publicPlan.relatedContent')}</small>
            </ExpandButton>
          </RelatedContentBlock>

          {isExpanded && (
            <ExpandedSection>
              {contributing.length > 0 && (
                <BlockOutcome>
                  <b>
                    {t('publicPlan.dependentOutcomes', {
                      outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                    })}
                  </b>
                  {contributing.map((relationship) => (
                    <ContributingOutcome outcomeRelationship={relationship} key={relationship.id} />
                  ))}
                </BlockOutcome>
              )}
              {initiatives.length > 0 && (
                <BlockInitiative>
                  <b>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</b>
                  {initiatives.map((initiative) => {
                    return (
                      <InitiativeRow
                        showHeirarchy={false}
                        initiative={initiative}
                        hideOwners={true}
                        hideDueDate={true}
                        key={initiative.id}
                        showMeta={true}
                        compact={true}
                        isReadOnly={true}
                        allowLink={true}
                      />
                    );
                  })}
                </BlockInitiative>
              )}
            </ExpandedSection>
          )}
        </BlockAdditionalContent>
      )}
    </BlockGrid>
  );
}

export default React.memo(OutcomeBlock);
