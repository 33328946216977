import React from 'react';

const HomeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.4 2.2C9.75555 1.93333 10.2444 1.93333 10.6 2.2L18.6 8.2C19.0418 8.53137 19.1314 9.15817 18.8 9.6C18.4686 10.0418 17.8418 10.1314 17.4 9.8L17 9.5V15C17 16.6569 15.6569 18 14 18H11H9H6C4.34314 18 3 16.6569 3 15V9.5L2.6 9.8C2.15817 10.1314 1.53137 10.0418 1.2 9.6C0.868627 9.15817 0.95817 8.53137 1.4 8.2L9.4 2.2ZM12 16V12C12 10.8954 11.1046 10 10 10C8.89543 10 8 10.8954 8 12V16H6C5.44771 16 5 15.5523 5 15V8L10 4.25L15 8V15C15 15.5523 14.5523 16 14 16H12Z"
      fill="#737373"
    />
  </svg>
);

export default HomeIcon;
