import React from 'react';

const DownChevronIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0474 13C10.3126 12.9986 10.5664 12.8919 10.7529 12.7034L14.7108 8.70336C15.0993 8.31077 15.0959 7.67762 14.7034 7.28916C14.3108 6.90071 13.6776 6.90406 13.2892 7.29664L10.0347 10.5858L6.7034 7.2892C6.31083 6.90073 5.67768 6.90404 5.2892 7.29661C4.90073 7.68917 4.90404 8.32232 5.2966 8.7108L9.33871 12.7108C9.52723 12.8974 9.78214 13.0014 10.0474 13Z"
      fill="#737373"
    />
  </svg>
);
export default DownChevronIcon;
