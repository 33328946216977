import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import MentionBox from 'components/MentionBox';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import { CONTEXT_STORAGE_KEY } from 'config/constants';
import { Checkin } from 'types';
import _ from 'lodash';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    'gutter content'
    'gutter footer';
  column-gap: ${theme.spacing.x1};

  margin: ${theme.spacing.x2} 0;

  &.primary {
    background: #f3f3f3;
    border-radius: 8px;
    padding: ${theme.spacing.x3};

    border-bottom: 0;
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
`;

const Content = styled.div`
  grid-area: content;
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.6rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    border-radius: 4px;
    background: #f3f3f3;
    border: 0px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }

    width: 100%;

    &.minimized {
      min-height: 4rem;
      height: 4rem;
    }
  }
`;

const Footer = styled.div`
  grid-area: footer;
  margin: 0 0 ${theme.spacing.x2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.subtleText};

  > div {
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.x1};
    button {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

const ReplyPlaceholder = styled.input`
  background: #fff;
  border: 1px solid ${theme.colors.blockBorder};
`;

interface Props {
  checkin: Checkin;
}

function CommentBlock(props: Props) {
  const queryCache = useQueryCache();

  const { checkin } = props;
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const [maskEditor, setMaskEditor] = useState(true);
  const [body, setBody] = useState('');
  const storageKey = `${CONTEXT_STORAGE_KEY}_checkin_comment_${checkin.nano_slug}`;

  // Checkin comment mutations
  const [createCheckinCommentMutation, { isLoading }]: [any, any] = useMutation(remoteApi.createCheckinComment, {
    onSuccess: () => {
      sessionStorage.removeItem(storageKey);
      queryCache.invalidateQueries(queryKeys.checkinComments);
      setBody('');
      setMaskEditor(true);
    },
  });

  useEffect(() => {
    debounceStoreText.current(body);
  }, [body]);

  useEffect(() => {
    const savedContext = sessionStorage.getItem(storageKey) || '';
    setBody(savedContext);
  }, [storageKey]);

  const storeText = (newText: string) => {
    if (newText) {
      sessionStorage.setItem(storageKey, newText);
    }
  };

  const debounceStoreText = useRef(
    _.debounce((newText: string) => storeText(newText), 1000, {
      maxWait: 5000,
    }),
  );

  const handleSubmit = () => {
    const checkinComment = {
      body,
    };
    createCheckinCommentMutation({
      checkinId: checkin.id,
      checkin_comment: checkinComment,
    });
  };

  if (maskEditor) {
    return (
      <Grid>
        <Gutter>
          <KoalaAvatar user={user} />
        </Gutter>
        <Content>
          <ReplyPlaceholder
            type="text"
            placeholder={t('shared.reply') ?? 'Reply'}
            onFocus={() => {
              setMaskEditor(false);
            }}
          />
        </Content>
      </Grid>
    );
  }
  return (
    <Grid>
      <Gutter>
        <KoalaAvatar user={user} />
      </Gutter>
      <Content>
        <MentionBox
          setValue={setBody}
          value={body}
          placeholder={t('shared.reply') ?? 'Reply'}
          comment={true}
          cmdEnterCallback={handleSubmit}
        />
      </Content>

      <Footer>
        <div>
          <KoalaButton onClick={handleSubmit} disabled={isLoading} loading={isLoading}>
            {t('shared.save')}
          </KoalaButton>
          <KoalaTextButton onClick={() => setMaskEditor(true)} appearance="secondary">
            {t('shared.cancel')}
          </KoalaTextButton>
        </div>
      </Footer>
    </Grid>
  );
}

export default React.memo(CommentBlock);
