import queryKeys from 'config/queryKeys';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { DragHandle, SourceTitle, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import { Link } from 'react-router-dom';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaLoader from 'koala/components/Loader';
import { WORKSPACE_PLAN_TEMPLATE_ROUTE, WORKSPACE_PLAN_TRACK_ROUTE, WORKSPACE_PLAN_WRITE_ROUTE } from 'routes';
import { AxiosError, AxiosResponse } from 'axios';
import * as planUtils from 'utils/planUtils';
import { DashboardWidget, Plan } from 'types';
import Chart from 'components/CheckinChart';
import { format, getTime, isAfter, isBefore, parseISO, toDate } from 'date-fns';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryGroup, VictoryVoronoiContainer } from 'victory';
import { nFormatter } from 'utils/outcomeUtils';
import KoalaIcon from 'koala/components/Icons';

const Container = styled.div`
  height: 18rem;
  display: flex;
  width: 100%;
  height: 90%;
  max-height: 100%;

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

interface ChartProps {
  ncsData: any;
  isInsideTimeline: boolean;
  xDomain: any;
  dimensions: any;
}
function RenderChart(props: ChartProps) {
  const { ncsData, isInsideTimeline, xDomain, dimensions } = props;
  const { i18n, t } = useTranslation();
  return (
    <VictoryChart
      width={dimensions.width}
      height={dimensions.height - 20}
      domainPadding={{ x: 15 }}
      padding={{ top: 10, bottom: 5, left: 40, right: 10 }}
      domain={{ y: [-100, 100], x: xDomain }}
      containerComponent={<VictoryVoronoiContainer />}
    >
      {Chart.XAxis(i18n)}
      {Chart.YAxis()}
      <VictoryAxis
        dependentAxis
        tickFormat={(t: number) => {
          return nFormatter(t, 2);
        }}
        style={{
          grid: { stroke: 'transparent' },
          axis: { stroke: 'transparent' },
          tickLabels: {
            fill: theme.colors.N60,
            fontFamily: theme.font.fontFamily,
            fontSize: '12px',
          },
        }}
      />
      {isInsideTimeline && Chart.TodayLine({ min: -100, max: 100 }, t)}
      <VictoryGroup scale="time">
        <VictoryArea
          style={{
            data: {
              stroke: theme.colors.B50,
              fill: theme.colors.B20,
              fillOpacity: 0.2,
              strokeWidth: 3,
              strokeLinecap: 'round',
            },
          }}
          labels={({ datum }: any) => [`${format(toDate(datum.x), 'd MMM yyyy')}`, `${datum.displayText}`]}
          labelComponent={Chart.LabelTooltip()}
          data={ncsData}
          interpolation="monotoneX"
        />
      </VictoryGroup>
    </VictoryChart>
  );
}

interface Props {
  widget: DashboardWidget;
}

function PlanNCSGraphWidget(props: Props) {
  const { widget } = props;
  const planId = widget.source_id;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [error, setError] = useState('');
  const queryKey = [queryKeys.currentPlan, planId];
  const [trends, setTrends] = useState([]);
  const [plan, setPlan] = useState<Plan>();
  const widgetTitle: string = widget.title ? widget.title : t('workspaceDashboards.PlanNCSGraph');

  const chartRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({ width: 200, height: 200 });

  useEffect(() => {
    const handleResize = (entries: any) => {
      for (let entry of entries) {
        if (entry.target === chartRef.current) {
          setDimensions({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (chartRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(chartRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef.current]);

  // Get the plan details
  const { isLoading } = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    onSuccess: (response: AxiosResponse) => {
      const data = response.data;
      setPlan(data);
    },
    onError: (error: AxiosError) => {
      const errorText = error.response?.data.error;
      if (errorText.includes('not allowed') && error.response?.status === 401) {
        setError(
          t('workspaceDashboards.permissionError') ?? 'You do not have permission to view the content in this widget',
        );
      } else {
        setError(t('workspaceDashboards.widgetError') ?? 'Unable to fetch widget');
      }
    },
  });

  // Fetch trends data
  useQuery([queryKeys.currentPlan, plan?.id, 'trends'], remoteApi.fetchPlanTrends, {
    staleTime: 0,
    onSuccess: (response) => {
      setTrends(response.data);
    },
    enabled: !!plan,
  });

  if (isLoading) {
    return (
      <WidgetContainer>
        <KoalaLoader />
        <Container className="panel-checkins-chart" ref={chartRef} />
      </WidgetContainer>
    );
  }

  if (!plan) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <WidgetContent>
          <p>{error ? t('workspaceDashboards.widgetError') : 'Unable to load plan'}</p>
        </WidgetContent>
      </WidgetContainer>
    );
  }

  if (!plan.start_at || !plan.finish_at) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <WidgetContent>
          <p>Missing plan timeline</p>
        </WidgetContent>
      </WidgetContainer>
    );
  }
  const ncsData: any[] = [];

  const startDate: Date = parseISO(plan.start_at);
  const finishDate: Date = parseISO(plan.finish_at);

  const firstPoint = {
    x: startDate,
    y: 0,
    timestamp: getTime(startDate),
    displayText: '0 NCS',
  };

  // By default we're going to use the first point from the target
  // We'll change that if we find a checkin prior to the first point.
  let useFirstPoint = true;
  let chartFinishDate = finishDate;

  trends.forEach((trend: any, index: number) => {
    const date = parseISO(trend.reference_date);
    // If the checkin was done prior to the first point we don't store
    // the first point in the list of current points
    if (isBefore(date, firstPoint.x)) {
      useFirstPoint = false;
    }

    if (isAfter(date, chartFinishDate)) {
      chartFinishDate = date;
    }

    // Create the point using the checkin data
    const ncsPoint: any = {
      x: date,
      y: trend.ncs,
      timestamp: getTime(date),
      displayText: `${Math.round(trend.ncs)} NCS`,
      trend,
    };
    ncsData.push(ncsPoint);
  });

  if (useFirstPoint) {
    ncsData.push(firstPoint);
  }

  const isInsideTimeline = planUtils.isInPlanTimeline(startDate, finishDate, new Date());
  const xDomain = [startDate, chartFinishDate];

  const stateToRouteMapping: { [key: string]: string } = {
    draft: WORKSPACE_PLAN_WRITE_ROUTE,
    published: WORKSPACE_PLAN_TRACK_ROUTE,
    template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
  };
  let routeMappingState = plan.state || 'draft';
  const routeTarget = stateToRouteMapping[routeMappingState];
  const planRoute = routeTarget.replace(':workspaceSlug', currentWorkspace.slug).replace(':planId', plan.nano_slug);

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <WidgetContent>
        <SourceTitle>
          <Link to={planRoute}>
            <PlanIconLabel plan={plan} size="small" />
          </Link>
        </SourceTitle>
        <Container className="panel-checkins-chart" ref={chartRef}>
          <RenderChart
            dimensions={dimensions}
            ncsData={ncsData}
            isInsideTimeline={isInsideTimeline}
            xDomain={xDomain}
          />
        </Container>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default PlanNCSGraphWidget;
