import { addDays, addMonths, endOfQuarter, startOfQuarter, subDays, subMonths } from 'date-fns';
import { Membership } from 'types';

export const getFilterHash = (customFilterHash: any, currentMembership: Membership) => {
  const filterParams: any = {};
  let start_at, finish_at, refDate;
  let is_in_progress, is_draft;
  let is_overdue, without_target, with_default_target, with_pending_checkin, without_owner, reporting, not_aligned;

  if (customFilterHash.timeline) {
    switch (customFilterHash.timeline) {
      case 'current_quarter':
        refDate = Date.now();
        start_at = subDays(startOfQuarter(refDate), 1).toISOString();
        finish_at = addDays(endOfQuarter(refDate), 1).toISOString();
        break;
      case 'last_quarter':
        refDate = subMonths(Date.now(), 3);
        start_at = subDays(startOfQuarter(refDate), 1).toISOString();
        finish_at = addDays(endOfQuarter(refDate), 1).toISOString();
        break;
      case 'next_quarter':
        refDate = addMonths(Date.now(), 3);
        start_at = subDays(startOfQuarter(refDate), 1).toISOString();
        finish_at = addDays(endOfQuarter(refDate), 1).toISOString();
        break;
      case 'custom':
        start_at = customFilterHash.start_at;
        finish_at = customFilterHash.finish_at;
        break;
      default:
        start_at = null;
        finish_at = null;
    }
  }

  if (customFilterHash.plan_status) {
    switch (customFilterHash.plan_status) {
      case 'is_in_progress':
        is_in_progress = true;
        break;
      case 'is_draft':
        is_draft = true;
        break;
    }
  }

  if (customFilterHash.insights) {
    switch (customFilterHash.insights) {
      case 'without_target':
        without_target = true;
        break;
      case 'without_owner':
        without_owner = true;
        break;
      case 'with_default_target':
        with_default_target = true;
        break;
      case 'with_pending_checkin':
        with_pending_checkin = true;
        break;
      case 'reporting':
        reporting = true;
        break;
      case 'not_aligned':
        not_aligned = true;
        break;
      case 'is_overdue':
        is_overdue = true;
        break;
    }
  }
  if (without_target) {
    filterParams['without_target'] = without_target;
  }
  if (with_default_target) {
    filterParams['with_default_target'] = with_default_target;
  }
  if (not_aligned) {
    filterParams['not_aligned'] = not_aligned;
  }
  if (with_pending_checkin) {
    filterParams['with_pending_checkin'] = true;
  }
  if (without_owner) {
    filterParams['without_owner'] = true;
  }
  if (reporting) {
    filterParams['reporting'] = currentMembership.id;
  }
  if (is_overdue) {
    filterParams['is_overdue'] = true;
  }

  if (customFilterHash.state) {
    filterParams['state'] = customFilterHash.state;
  }
  if (customFilterHash.roadmap_state && customFilterHash.roadmap_state.length > 0) {
    filterParams['roadmap_state'] = customFilterHash.roadmap_state;
  }
  if (customFilterHash.work_state && customFilterHash.work_state.length > 0) {
    filterParams['work_state'] = customFilterHash.work_state;
  }

  if (customFilterHash.membership && customFilterHash.membership.length > 0) {
    filterParams['membership_id'] = customFilterHash.membership.map((m: any) => m.value);
  }
  if (customFilterHash.contributor && customFilterHash.contributor.length > 0) {
    filterParams['contributor_id'] = customFilterHash.contributor.map((m: any) => m.value);
  }
  if (customFilterHash.plan && customFilterHash.plan.length > 0) {
    filterParams['plan_id'] = customFilterHash.plan.map((m: any) => m.value);
  }
  if (customFilterHash.tag && customFilterHash.tag.length > 0) {
    filterParams['tag'] = customFilterHash.tag.map((t: any) => t.value);
  }
  if (customFilterHash.team && customFilterHash.team.length > 0) {
    filterParams['team_id'] = customFilterHash.team.map((m: any) => m.value);
  }
  if (start_at) {
    filterParams['start_at'] = start_at;
  }
  if (finish_at) {
    filterParams['finish_at'] = finish_at;
  }
  if (is_in_progress) {
    filterParams['is_in_progress'] = is_in_progress;
  }
  if (is_draft) {
    filterParams['is_draft'] = is_draft;
  }
  if (customFilterHash.progress_above_equal || customFilterHash.progress_above_equal === 0) {
    filterParams['progress_above_equal'] = customFilterHash.progress_above_equal;
  }
  if (customFilterHash.progress_below || customFilterHash.progress_below === 0) {
    filterParams['progress_below'] = customFilterHash.progress_below;
  }
  if (customFilterHash.confidence && customFilterHash.confidence.length > 0) {
    filterParams['confidence'] = customFilterHash.confidence;
  }
  if (customFilterHash.confidence_above_equal || customFilterHash.confidence_above_equal === 0) {
    filterParams['confidence_above_equal'] = customFilterHash.confidence_above_equal;
  }
  if (customFilterHash.confidence_below || customFilterHash.confidence_below === 0) {
    filterParams['confidence_below'] = customFilterHash.confidence_below;
  }
  if (customFilterHash.sorting) {
    filterParams['sorting'] = customFilterHash.sorting;
  }
  if (customFilterHash.weight && customFilterHash.weight.length > 0) {
    filterParams['weight'] = customFilterHash.weight;
  }
  if (customFilterHash.completed || customFilterHash.completed === false) {
    filterParams['completed'] = customFilterHash.completed;
  }

  filterParams['archived'] = false;

  return filterParams;
};
