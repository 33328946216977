import React from 'react';

export default ({ className }: { className?: string }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      className="outerFlame"
      clipRule="evenodd"
      d="M20 13.3333C20 18.8562 15.5228 20 10 20C4.47715 20 0 18.8562 0 13.3333C0 11.6667 0 8.33333 3.33333 3.33333C3.9212 3.77423 4.53498 4.17218 5.16554 4.53513C5.30025 4.41302 5.4376 4.28875 5.57758 4.16209L5.57758 4.16209C6.83807 3.02159 8.31221 1.68779 10 0C11.6878 1.68779 13.1619 3.0216 14.4224 4.16209C14.5624 4.28875 14.6998 4.41302 14.8345 4.53513C15.465 4.17218 16.0788 3.77423 16.6667 3.33333C20 8.33333 20 11.6667 20 13.3333Z"
      fill="#FF8819"
    />
    <path
      className="innerFlame"
      d="M15 15C15 17.7614 12.7614 18.3333 10 18.3333C7.23858 18.3333 5 17.7614 5 15C5 12.2386 6.66667 11.6667 10 8.33334C13.3333 11.6667 15 12.2386 15 15Z"
      fill="#F9D980"
    />
  </svg>
);
