import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Account pages
import PublicTemplates from 'pages/PublicTemplates';
import PublicTemplatesDetails from 'pages/PublicTemplatesDetails';
import PublicTemplatesEdit from 'pages/PublicTemplatesEdit';

// Components
import NotFound from 'components/NotFound';

import * as routes from 'routes';

function PublicTemplatesRoutes() {
  return (
    <Switch>
      <Route path={routes.PUBLIC_TEMPLATES_ROOT_ROUTE} exact={true}>
        <PublicTemplates />
      </Route>
      <Route path={routes.PUBLIC_TEMPLATES_DETAILS_ROUTE} exact={true}>
        <PublicTemplatesDetails />
      </Route>
      <Route path={routes.PUBLIC_TEMPLATES_EDIT_ROUTE} exact={true}>
        <PublicTemplatesEdit />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

export default React.memo(PublicTemplatesRoutes);
