import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useSelector, shallowEqual } from 'react-redux';
import theme from 'theme';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import AccountSettingsNav from 'components/AccountSettingsNav';
import KoalaButton from 'koala/components/Button';
import { Trans, useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media ${theme.devices.mobile} {
    input {
      width: 100%;
    }
  }
`;

const ConfirmMessage = styled.div`
  padding: ${theme.spacing.x2};
  background: ${theme.colors.successBg};
  margin: ${theme.spacing.x2} 0;
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

function AccountDetails() {
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const { t } = useTranslation();
  const [email, setEmail] = useState(currentUser.email);
  const [showConfirm, setShowConfirm] = useState(false);

  const [updateAccountMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.updateEmail, {
    onMutate: () => {
      setShowConfirm(false);
    },
    onSuccess: (response) => {
      setShowConfirm(true);
    },
  });

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data.errors : {};
    }
  }

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'email':
        return t('errors.email');
      default:
        return '';
    }
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'email':
        setEmail(value);
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      email,
    };
    updateAccountMutation({
      user: params,
    });
  };

  // Error classnames used to highlight input fields that have errors
  const emailClassname = errors['email'] ? 'error' : '';

  return (
    <Fragment>
      <Helmet>
        <title>{t('accountEmail.title')} | Tability</title>
      </Helmet>
      <ChromeSideNav>
        <AccountSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('accountEmail.title')}</h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <label>{t('accountEmail.emailLabel')}</label>
              <input
                type="text"
                name="email"
                className={`${emailClassname} small`}
                autoComplete="off"
                placeholder={t('accountEmail.emailLabel') ?? 'Your email'}
                value={email}
                required={true}
                onChange={handleChange}
              />
            </FormField>

            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading}>
                {t('shared.save')}
              </KoalaButton>
            </Actions>
          </Form>
          {showConfirm && (
            <ConfirmMessage>
              <Trans i18nKey="accountEmail.confirmMessage" values={{ email }} components={{ b: <b /> }} />
            </ConfirmMessage>
          )}
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default AccountDetails;
