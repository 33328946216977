import styled from 'styled-components';
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
// import { useMutation, useQuery, useQueryCache } from 'react-query';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useHistory, useParams } from 'react-router-dom';
import KoalaInputValidation from 'koala/components/InputValidation';
import * as routes from 'routes';
import { Segment } from 'types';
import { useTranslation } from 'react-i18next';

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

interface Props {
  filterHash: string;
}

function CreateMapSegment(props: Props) {
  const { filterHash } = props;
  const dispatch = useDispatch();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const { t } = useTranslation();
  const translationKey = 'modals.createView';

  const [title, setTitle] = useState('');
  const queryCache = useQueryCache();
  const history = useHistory();

  const [createSegmentMutation, { isLoading: isCreating, isError, error }]: [any, any] = useMutation(
    remoteApi.createMapSegment,
    {
      onSuccess: (response) => {
        const data: Segment = response.data;
        queryCache.invalidateQueries(queryKeys.segments);

        const filterPath = routes.WORKSPACE_MAP_V2_SEGMENT_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug)
          .replace(':segmentId', data.nano_slug)
          .replace(':filter', data.filter_hash);
        history.push(filterPath);
        dispatch(setGlobalModalContent(''));
      },
    },
  );

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    const params = {
      filterHash: filterHash,
      title: title,
      workspaceSlug: workspaceSlug,
    };
    createSegmentMutation(params);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputTitle = e.target.value;
    setTitle(inputTitle);
  };

  let errorMessage = t('errors.somethingWentWrong');
  if (isError) {
    const data = error.response.data;
    if (error.response.status === 422 && data.errors['title']) {
      errorMessage = t(`${translationKey}.error`, { error: data.errors['title'] });
    }
  }

  const allowSave = title && !isCreating;

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleSave}>
          <FormField>
            <label>{t(`${translationKey}.name`) ?? 'Name'}</label>
            <input
              placeholder={t(`${translationKey}.namePlaceholder`) ?? 'Segment name'}
              className="segment-input"
              value={title}
              onChange={handleTitleChange}
            />
            {isError && <KoalaInputValidation state="error" text={errorMessage} />}
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!allowSave} loading={isCreating}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default CreateMapSegment;
