import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { useParams } from 'react-router-dom';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import PlanSelect from './PlanSelect';
import OutcomeSelect from './OutcomeSelect';
import { DashboardWidget } from 'types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;
`;

interface Props {
  handleSaveWidget: (type: string, source: string, source_id: string, widgetId?: string) => void;
  isLoading: boolean;
  isEditing?: boolean;
  widget?: DashboardWidget;
}

function OutcomeGraphWidgetDetails(props: Props) {
  const { handleSaveWidget, isLoading, isEditing, widget } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const [planId, setPlanId] = useState<string | null>(null);
  const [outcomeId, setOutcomeId] = useState<string | null>(null);

  useEffect(() => {
    if (widget?.source) {
      setPlanId(widget.source.plan_id);
      setOutcomeId(widget.source.id);
    }
  }, [widget]);

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (!outcomeId) {
      return;
    }
    handleSaveWidget('outcome-graph', 'outcome', outcomeId, widget?.id);
  };

  const changePlan = (value: string) => {
    setPlanId(value);
    setOutcomeId(null);
  };

  const existingOutcome = widget?.source;

  return (
    <Container>
      <PlanSelect workspaceSlug={workspaceSlug} setPlanId={changePlan} existingPlan={existingOutcome?.plan} />
      {planId && (
        <OutcomeSelect
          workspaceSlug={workspaceSlug}
          setOutcomeId={setOutcomeId}
          planId={planId}
          existingOutcome={existingOutcome}
        />
      )}
      <div>
        <KoalaButton onClick={handleSave} loading={isLoading} disabled={!outcomeId}>
          {isEditing ? t('shared.save') : t('shared.add')}
        </KoalaButton>
      </div>
    </Container>
  );
}

export default OutcomeGraphWidgetDetails;
