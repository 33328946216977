import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  text-transform: uppercase;
  font-size: 1rem;
  padding: 0 0.8rem;
  height: 2.4rem;
  line-height: 2.4rem;
  display: inline-block;
  align-items: center;
  font-weight: 800;
  border-radius: 4px;
  letter-spacing: 1px;

  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  background-color: ${theme.colors.infoBg};

  &.draft {
    background: #ababab;
    color: #fff;
  }

  &.active {
    background-color: #5067d9;
    color: #fff;
  }

  &.expired {
    background-color: #373737;
    color: #fff;
  }

  &.archived {
    background-color: #955be3;
    color: #fff;
  }

  &.dummy {
    background-color: #955be3;
    color: #fff;
  }

  &.beta {
    background-color: ${theme.colors.yellow};
    color: #151515;
  }
`;
