import React from 'react';
import { ContentAction } from './NewsItem';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Workspace } from 'types';
import { shallowEqual, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
interface Props {
  event: any;
}

function Block(props: Props) {
  const { event } = props;
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { membership, source } = event;
  if (!membership) {
    return null;
  }

  const { user } = membership;
  const name = user.fullname || user.email;

  return (
    <>
      <ContentAction>
        <Trans
          i18nKey="newsfeed.initiativeCreated"
          values={{
            name,
            initative: translate(workspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
            title: source.title,
          }}
          components={{ b: <b /> }}
        />
      </ContentAction>
    </>
  );
}

export default React.memo(Block);
