import React, { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { nanoid } from 'nanoid';

// Actions
import {
  editorCreateObjective,
  editorSetDisplayNewBlockForm,
  editorSelectBlockBelow,
} from 'state/actions/workspaceEditorActions';

// UI Components
import BlockGrid, { BlockGutter, BlockContent, BlockMeta } from './BlockGrid';
import BlockLabel from './BlockLabel';
import TitleInput from './TitleInput';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  planId: string;
  afterObjectiveId?: string;
}

function NewBlockForm(props: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const { afterObjectiveId, planId } = props;
  const currentWorkspaceId = useSelector((state: any) => state.editorUI.currentWorkspaceId);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const afterObjective = useSelector((state: any) => {
    return afterObjectiveId ? state.editorEntities.objectives[afterObjectiveId] : null;
  });

  // Handles title changes
  const handleChange = (e: any) => {
    e.preventDefault();
    let newTitle = e.target.value || '';

    newTitle = newTitle.replace(/(\r\n|\n|\r)/gm, ''); // Remove new lines

    setTitle(newTitle);
  };
  const handleBlur = (e: any) => {
    if (title.length > 0) {
      addObjective(title);
      dispatch(editorSetDisplayNewBlockForm(false));
    }
  };

  const addObjective = (title: string) => {
    const newObjective: any = {
      id: uuidv4(),
      nano_slug: nanoid(12),
      workspace_id: currentWorkspaceId,
      plan_id: planId,
      title,
      rank: '',
    };

    dispatch(editorCreateObjective(newObjective, afterObjective));
    setTitle('');
  };

  const handlePress = (e: any) => {
    if (e.keyCode === 27) {
      // Esc key
      e.preventDefault();
      if (title.length > 0) {
        addObjective(title);
      }
      dispatch(editorSetDisplayNewBlockForm(false));
    }

    // Disable field if we press delete and no text is entered
    if (e.keyCode === 8) {
      // Delete key
      const value = e.target.value;
      if (!value) {
        e.preventDefault();
        dispatch(editorSelectBlockBelow());
      }
    }

    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault();
      if (title.length > 0) {
        addObjective(title);
      } else {
        dispatch(editorSelectBlockBelow());
      }
    }

    if (e.keyCode === 38) {
      // Arrow up
      e.preventDefault();
      if (title.length > 0) {
        addObjective(title);
      }
      dispatch(editorSetDisplayNewBlockForm(false));
    }

    if (e.keyCode === 40) {
      // Arrow down
      e.preventDefault();
      if (title.length > 0) {
        addObjective(title);
      }
      dispatch(editorSelectBlockBelow());
    }
  };

  const label = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase();
  const placeholder = t(`workspacePlan.write.objectivePlaceholder`, { label: label }) ?? `What's your ${label}`;
  return (
    <BlockGrid className={'objective'}>
      <BlockGutter>
        <BlockLabel className={'objective new'}>{translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}</BlockLabel>
      </BlockGutter>
      <BlockContent className={'objective'}>
        <TitleInput
          text={title}
          type={'objective'}
          isNewInput={true}
          placeholder={placeholder}
          handleChange={handleChange}
          handlePress={handlePress}
          handleBlur={handleBlur}
        />
      </BlockContent>
      <BlockMeta></BlockMeta>
    </BlockGrid>
  );
}

export default NewBlockForm;
