import styled from 'styled-components';
import theme from 'theme';
import { Link } from 'react-router-dom';

export default styled(Link)`
  background: transparent;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  font-size: 1rem;
  line-height: 1rem;
  color: ${theme.colors.blue};
  letter-spacing: 1px;
  font-weight: 800;
  border-radius: ${theme.spacing.x2};
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;

  border: 1px solid ${theme.colors.blue};

  &:hover {
    background: rgba(50, 77, 211, 0.15);
  }
`;
