/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as amplitudeUtils from 'utils/amplitude';
import { useDispatch } from 'react-redux';
import { uiActions } from 'state/actions/';
import theme from 'theme';

// Components
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Wordmark from 'components/Wordmark';
import Star from 'components/_assets/Star.svg';
import Phuong from 'components/_assets/phuong_pic.jpeg';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';

// Components
const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;

  a.text {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x8};
`;

const MetaContainer = styled.div`
  flex: 1;
  background-color: #0a5055;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div {
    text-align: center;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const MetaContent = styled.div`
  padding: 3.2rem 4.8rem;
  background: #fff;
  border-radius: 16px;
  position: relative;

  max-width: 50rem;

  .tabby_peek {
    width: 120px;
    position: absolute;
    top: -81px;
    left: 20px;
    transform: scaleX(-1);
  }
`;

const MetaTitle = styled.div`
  text-align: center;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: ${theme.spacing.x2};
`;

const MetaNote = styled.div`
  font-size: 2.8rem;
  font-weight: 500;
  margin-bottom: ${theme.spacing.x2};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-left: ${theme.spacing.x1};
  }
`;

const MetaQuote = styled.div`
  font-weight: 400;
  text-align: center;
  margin-bottom: ${theme.spacing.x2};
`;

const MetaAuthor = styled.div`
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 800;
  color: ${theme.colors.subtleText};
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 50%;
    width: 2.4rem;
    margin-right: ${theme.spacing.x1};
  }
`;

const Content = styled.div`
  padding: 0 12rem 8rem 12rem;
  width: 100%;

  h2 {
    margin-bottom: ${theme.spacing.x2};
  }

  @media (max-width: 800px) {
    padding: ${theme.spacing.x4};
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 1.6rem;
  align-items: center;
  button {
    margin-right: 1.6rem;
  }
`;

const URLContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

const Domain = styled.div`
  font-size: 1.8rem;
`;

const FormHelp = styled.div`
  color: ${theme.colors.subtleText};
  a {
    color: ${theme.colors.subtleText};
  }
  margin-top: ${theme.spacing.x2};
`;

const ErrorMessage = styled.div`
  padding: ${theme.spacing.x3};
  background: ${theme.colors.dangerBg};
  margin: ${theme.spacing.x2} 0;
  border-radius: 8px;
`;

function Login() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.session.sso.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Reset Redux state for the UI
  useEffect(() => {
    dispatch(uiActions.resetPageState('Login'));
  }, [dispatch]);

  const [slug, setSlug] = useState('');

  const handleChange = (e: any) => {
    setSlug(e.target.value);
  };

  const handleSSOLogin = () => {
    const authAPI = `https://${process.env.REACT_APP_TABILITY_API_DOMAIN}/auth/saml`;

    // We need to change the ID to lowercase in order to reduce errors due to capitalization of IDs
    const saml_init_url = `${authAPI}/init/${slug.toLowerCase()}`;
    // @ts-ignore
    window.location = saml_init_url;
  };

  const searchParams = new URLSearchParams(location.search);
  const errorSlug = searchParams.get('slug');
  const ssoError = searchParams.get('error');

  const ssoErrorToString = (ssoError: string) => {
    switch (ssoError) {
      case 'sso_not_set':
        return (
          <p>
            SSO is not configured for the workspace ID <b>{errorSlug}</b>.
          </p>
        );
      case 'membership_not_created':
        return <p>Sorry, your account was created but you are not allowed to join this workspace.</p>;
      case 'workspace_not_found':
        return <p>We could not find a workspace with the specified ID.</p>;
    }
  };

  // Displays the list of templates
  return (
    <Container>
      <Helmet>
        <title>Sign in via SSO | Tability</title>
      </Helmet>
      <MetaContainer>
        <MetaContent>
          <img
            src="https://res.cloudinary.com/tability/image/upload/v1623762825/static_assets/Tabby_peeking_hrz1kx.png"
            alt="Tabby"
            className="tabby_peek"
          />
          <MetaTitle>What our customers have to say about us</MetaTitle>
          <MetaNote>
            <img src={Star} alt="Star" />
            <img src={Star} alt="Star" />
            <img src={Star} alt="Star" />
            <img src={Star} alt="Star" />
            <img src={Star} alt="Star" />
          </MetaNote>
          <MetaQuote>"Tability has helped me create better OKRs and stay focused on outcomes."</MetaQuote>
          <MetaAuthor>
            <img src={Phuong} alt="Phuong N., customer success director" /> Phuong N. – Customer Success Director
          </MetaAuthor>
        </MetaContent>
      </MetaContainer>
      <ContentContainer>
        <Content>
          <WordmarkWrapper>
            <Wordmark type="dark" />
          </WordmarkWrapper>
          <h2>Sign in via SSO</h2>
          {ssoError && (
            <ErrorMessage>
              {ssoErrorToString(ssoError)}
              <p>Please contact support if you think that this is an issue.</p>
              <p>
                <KoalaButton onClick={() => window.Intercom('show')} appearance="subtle">
                  Contact support
                </KoalaButton>
              </p>
            </ErrorMessage>
          )}
          <URLContainer>
            <Domain>https://tability.app/</Domain>
            <input type="text" className="small" placeholder="workspace ID" onChange={handleChange} value={slug} />
          </URLContainer>
          <Actions>
            <KoalaButton submit disabled={!slug} onClick={handleSSOLogin}>
              Continue
            </KoalaButton>
          </Actions>
          <FormHelp>
            Not using SSO?{' '}
            <KoalaTextButton isLink to="/login" isInlineText>
              Login via email and password
            </KoalaTextButton>
          </FormHelp>
        </Content>
      </ContentContainer>
    </Container>
  );
}

export default Login;
