import { fetchCheckins, fetchOutcomeDetails } from 'api/remote';
import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import TabilityWordmarkGreenIcon from 'components/_assets/TabilityWordmarkCustom';
import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextButton from 'koala/components/TextButton';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import { Outcome, Plan } from 'types';
import { hasTimeline } from 'utils/planUtils';
import DownloadCheckinsChart from './DownloadCheckinsChart';
import CheckinDetails from './CheckinDetails';
import BackgroundSelection from './BackgroundSelection';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';

const FormField = styled.div`
  display: flex;
  flex-direction: column;

  &.form-checkbox {
    flex-direction: row;
  }

  margin-bottom: ${theme.spacing.x3};

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 15rem;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'title title bg bg'
    'checkboxes checkboxes checkboxes .';
  width: 100%;
  gap: ${theme.spacing.x2};

  .form-label {
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${theme.colors.N100};
    margin-bottom: ${theme.spacing.half};
  }
`;

const OptionTitle = styled.div`
  grid-area: title;
  input {
    border: solid 1px ${theme.colors.N30};
  }
`;
const OptionBackground = styled.div`
  grid-area: bg;
`;
const OptionCheckboxes = styled.div`
  grid-area: checkboxes;
  display: flex;
  gap: ${theme.spacing.x3};
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;

  input {
    border: solid 1px ${theme.colors.N30};
  }
`;

const Chart = styled.div<{ background: string; height: number }>`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x3};
  width: 70rem;
  min-height: ${({ height }) => height}rem;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.background};
  .checkin-block-footer {
    display: none;
  }
  .download-checkin-chart {
    width: 100%;
    border-radius: 8px;
    padding: ${theme.spacing.x2};
    margin-bottom: ${theme.spacing.x2};
  }
  .checkin-block-content {
    margin-bottom: 0;
  }
`;
const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow: scroll;

  @media ${theme.devices.tablet} {
    justify-content: left;
  }
`;
const ChartTitle = styled.div<{ useLightText: boolean }>`
  text-align: center;
  color: ${(props) => (props.useLightText ? theme.colors.N0 : theme.colors.N100)};
  margin-bottom: ${theme.spacing.x2};
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;
const ChartFooter = styled.div<{ useLightText: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: ${(props) => (props.useLightText ? theme.colors.N0 : theme.colors.N60)};
  height: 3rem;
  .footer-text {
    margin-right: ${theme.spacing.x1};
  }
`;
const LogoContainer = styled.div<{ useLightText: boolean }>`
  display: block;
  transform: scale(0.5);
  width: 15.4rem;
  height: 4.2rem;
  margin: 0 -3.5rem;
  .tability-text {
    fill: ${(props) => (props.useLightText ? theme.colors.N0 : theme.colors.N100)};
  }
  .tability-icon {
    fill: ${(props) => (props.useLightText ? theme.colors.N100 : '#0A5055')};
  }
`;

const ChartContainer = styled.div<{ chartHeight: number }>`
  border-radius: 8px;
  background: ${theme.colors.N0};
  padding: ${theme.spacing.x2} ${theme.spacing.x2} ${theme.spacing.x2} ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  width: 100%;
  height: ${(props) => props.chartHeight}rem;
`;

interface Props {
  outcomeId: string;
}

function DownloadChart(props: Props) {
  const { outcomeId } = props;

  const [title, setTitle] = useState('');
  const [plan, setPlan] = useState<Plan>();
  const [background, setBackground] = useState('grey');
  const [showAnalysis, setShowAnalysis] = useState(true);
  const [showMetrics, setShowMetrics] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const printRef = useRef() as MutableRefObject<HTMLInputElement>;

  // Get the outcome details
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = queryKeys.staleTime;
  const { data: outcomeResponse }: any = useQuery(queryKey, fetchOutcomeDetails, {
    staleTime,
  });
  const outcome: Outcome = outcomeResponse ? outcomeResponse.data : null;

  const queryKeyCheckins = [queryKeys.checkins, outcomeId];
  const { data: checkinsResponse }: any = useQuery(queryKeyCheckins, fetchCheckins, {
    staleTime,
  });
  const checkins = checkinsResponse ? checkinsResponse.data : [];

  useEffect(() => {
    setTitle(outcome.title);
    setPlan(outcome.plan);
  }, [outcome]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleChange = (e: any) => {
    setHasDownloaded(false);
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'title':
        setTitle(value);
        break;
      case 'analysis':
        setShowAnalysis(value);
        break;
      case 'metrics':
        setShowMetrics(value);
        break;
      case 'tooltip':
        setShowTooltip(value);
        break;
    }
  };
  // HTML2CANVAS
  const handleDownload = async () => {
    setIsDownloading(true);
    const elem = printRef.current;
    html2canvas(elem, { allowTaint: true, useCORS: true, scale: 4 }).then((canvas) => {
      const data = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      if (typeof link.download === 'string') {
        link.href = data;
        const filename = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        link.download = `${filename}.png`;
        link.addEventListener('click', (e: MouseEvent) => {
          e.stopPropagation();
        });
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
      setIsDownloading(false);
      setHasDownloaded(true);
    });
  };

  const useLightText = background !== 'grey';

  const backgroundGradients: { [key: string]: string } = {
    grey: '#F4F4F4',
    green: 'linear-gradient(241.42deg, #6ECAD6 0%, #0A5055 100.02%)',
    purple: 'linear-gradient(241.42deg, #324DD3 0%, #F4CDC9 100.02%)',
    orange: 'linear-gradient(241.42deg, #FFA047 0%, #A06BE6 100.02%)',
  };

  const handleSetBackground = (value: string) => {
    setHasDownloaded(false);
    setBackground(value);
  };

  const outcomeHastarget = outcome.outcome_type !== 'no_metric';
  const chartHeight = outcomeHastarget ? 35 : 14;
  const imageMinHeight = outcomeHastarget ? 55 : 30;

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t('modals.downloadChart.title')}</h2>
        <KoalaIconButton iconName="close" onClick={handleCancel} />
      </ModalHeader>
      <ModalContent>
        <OptionsContainer>
          <OptionTitle>
            <div className="form-label">{t('modals.downloadChart.chart')}</div>
            <input autoComplete="off" name="title" value={title} onChange={handleChange} required={true} />
          </OptionTitle>
          <OptionBackground>
            <div className="form-label">{t('modals.downloadChart.background')}</div>
            <BackgroundSelection
              setBackground={handleSetBackground}
              background={background}
              backgroundGradients={backgroundGradients}
            />
          </OptionBackground>

          <OptionCheckboxes>
            <FormField className="form-checkbox">
              <input
                type="checkbox"
                name="analysis"
                id="analysis"
                checked={showAnalysis}
                required={true}
                onChange={handleChange}
              />
              <label htmlFor="analysis">{t('modals.downloadChart.analysis')}</label>
            </FormField>
            {outcome.outcome_type !== 'no_metric' && (
              <FormField className="form-checkbox">
                <input
                  type="checkbox"
                  name="metrics"
                  id="metrics"
                  checked={showMetrics}
                  required={true}
                  onChange={handleChange}
                />
                <label htmlFor="metrics">{t('modals.downloadChart.yAxis')}</label>
              </FormField>
            )}
            <FormField className="form-checkbox">
              <input
                type="checkbox"
                name="tooltip"
                id="tooltip"
                checked={showTooltip}
                required={true}
                onChange={handleChange}
              />
              <label htmlFor="tooltip">{t('modals.downloadChart.tooltip')}</label>
            </FormField>
          </OptionCheckboxes>
        </OptionsContainer>
        <PreviewContainer>
          <Chart background={backgroundGradients[background]} ref={printRef} height={imageMinHeight}>
            <ChartTitle useLightText={useLightText}>{outcome.plan.title}</ChartTitle>
            {plan && hasTimeline(plan) && (
              <ChartContainer chartHeight={chartHeight}>
                <DownloadCheckinsChart
                  outcome={outcome}
                  checkins={checkins}
                  showMetrics={showMetrics}
                  showTooltip={showTooltip}
                  chartTitle={title}
                />
              </ChartContainer>
            )}
            {showAnalysis && checkins.length > 0 && <CheckinDetails checkin={checkins[0]} outcome={outcome} />}
            <ChartFooter useLightText={useLightText}>
              <span className="footer-text">{t('modals.downloadChart.poweredBy')}</span>
              <LogoContainer useLightText={useLightText}>
                <TabilityWordmarkGreenIcon />
              </LogoContainer>
            </ChartFooter>
          </Chart>
        </PreviewContainer>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleDownload} loading={isDownloading} disabled={isDownloading}>
          {hasDownloaded ? `${t('modals.downloadChart.downloaded')}!` : t('modals.downloadChart.download')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default DownloadChart;
