import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import * as ymlEditorActions from 'state/actions/ymlEditorActions';

// Components

import { Droppable } from 'react-beautiful-dnd';
import Objective from './Objective';
import KoalaButton from 'koala/components/Button';

const Container = styled.div`
  padding: ${theme.spacing.x2};
  border: 2px dotted #151515;
`;

const PlanContent = styled.div`
  padding-bottom: ${theme.spacing.x2};
`;

export default function Plan() {
  const dispatch = useDispatch();
  // Get the state data passed via props
  const plan = useSelector((state: any) => state.ymlEditor.data.plan);
  const items = useSelector((state: any) => state.ymlEditor.data.planItems[plan.id]);

  const handleCreateObjective = () => {
    // @ts-ignore
    dispatch(ymlEditorActions.setCursor(null));
    dispatch(ymlEditorActions.addItem());
  };

  if (!items) {
    return null;
  }
  return (
    <Container>
      <PlanContent>
        <Droppable droppableId={plan.id} type="planItems">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((objectiveId: string, index: number) => {
                // Retrieve the objective
                // @ts-ignore
                return <Objective objectiveId={objectiveId} key={objectiveId} index={index} />;
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </PlanContent>

      <KoalaButton onClick={handleCreateObjective} appearance="subtle">
        Add objective
      </KoalaButton>
    </Container>
  );
}
