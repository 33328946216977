import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 15.0001C0 16.2273 0.995 17.2223 2.22222 17.2223H17.7778C19.005 17.2223 20 16.2273 20 15.0001V12.7778H0V15.0001Z"
      fill="#c60b1e"
    />
    <path d="M0 7.77783H20V12.7778H0V7.77783Z" fill="#ffc400" />
    <path
      d="M17.7778 2.77783H2.22222C0.995 2.77783 0 3.77283 0 5.00005V7.77783H20V5.00005C20 3.77283 19.005 2.77783 17.7778 2.77783Z"
      fill="#c60b1e"
    />
  </svg>
);
