import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Template } from 'types';
// Custom components
import CategoryLabel from './CategoryLabel';

export const TemplateGrid = styled.div`
  border-bottom: 1px solid #e7e7e7;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  column-gap: ${theme.spacing.x2};
  align-items: center;

  padding: ${theme.spacing.x1};
  cursor: pointer;

  &:hover,
  &.active {
    background: #f3f3f3;
  }

  &.no-hover {
    &:hover {
      background: none;
    }
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &.compact {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

const Description = styled.div``;

const Categories = styled.div`
  margin-top: ${theme.spacing.x1};
  > div {
    margin-right: ${theme.spacing.x1};
  }
`;

interface Props {
  template: Template;
  setSelectedTemplateId: (value: string) => void;
  selectedTemplateId: string | null;
  handleSelectTag: (e: React.MouseEvent<HTMLDivElement>) => void;
}
function TemplateDirectory(props: Props) {
  const { selectedTemplateId, setSelectedTemplateId, template, handleSelectTag } = props;
  const selectedClass = selectedTemplateId === template.id ? 'active' : '';
  const compactClass = selectedTemplateId ? 'compact' : '';

  const handleSelectTemplate = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setSelectedTemplateId(template.id);
  };

  const tagList = template.cached_tag_list || '';
  const tags = tagList.length > 0 ? tagList.split(',') : [];

  return (
    <TemplateGrid className={`${selectedClass} ${compactClass}`} onClick={handleSelectTemplate}>
      <Description>
        {template.title}
        <p className="subtle">{template.excerpt}</p>
        {!selectedTemplateId && (
          <Categories>
            {tags.map((tag, i) => (
              <CategoryLabel onClick={handleSelectTag} data-tag={tag.trim()} key={i}>
                {tag.trim()}
              </CategoryLabel>
            ))}
          </Categories>
        )}
      </Description>
    </TemplateGrid>
  );
}

export default TemplateDirectory;
