import React, { useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import styled from 'styled-components';
import theme from 'theme';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';

const ErrorContainer = styled.small`
  color: ${theme.colors.R50};
`;

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();
  // Query keys and query params
  const outcomeSQL = outcome.data_source_meta?.sql || '';
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:trello:sql${encodeURIComponent(outcomeSQL)}`,
    'current',
  ];
  const staleTime = 300;

  const {
    data: sourceData,
    isLoading,
    isFetching,
  } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading || isFetching) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const result = sourceData?.data.result;
  if (result?.error) {
    return (
      <FormField>
        <p>{t(`panels.editOutcome.queryError`)}</p>
        <p>{result.error}</p>
      </FormField>
    );
  }
  const currentValue = toNumber(result);

  if (currentValue === null || currentValue === undefined) {
    return <FormField>{t(`panels.editOutcome.errorFetching`)}</FormField>;
  }

  return (
    <FormField>
      <label>{t('modals.dataConnectors.preview')}</label>
      <p>{currentValue} cards</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  workspaceSlug: string;
}

function TrelloSource(props: Props) {
  const { outcome, workspaceSlug } = props;
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [boardOptions, setBoardOptions] = useState<KoalaSelectOption[]>([]);
  const [listOptions, setListOptions] = useState<KoalaSelectOption[]>([]);
  const [board, setBoard] = useState(outcome.data_source_meta.board_id ?? '');
  const [list, setList] = useState(outcome.data_source_meta.list_id ?? '');

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });
  const staleTime = 0;

  const queryKeyBoards = ['trello_boards', workspaceSlug];
  const { isLoading: isLoadingBoards } = useQuery(queryKeyBoards, remoteApi.listTrelloBoards, {
    staleTime,
    onSuccess: (response) => {
      if (!response.data?.boards) {
        setBoardOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.boards.map((project: any) => ({
        label: project.name,
        value: project.id,
      }));
      setBoardOptions(options);
    },
    onError: (error: any) => {
      const errorString = error.response.data.error;
      setError(errorString);
    },
  });

  const queryKeyBoardLists = ['trello_board_lists', workspaceSlug, board];
  const { isLoading: isLoadingBoardLists } = useQuery(queryKeyBoardLists, remoteApi.listTrelloBoardLists, {
    staleTime,
    onSuccess: (response) => {
      if (!response.data?.lists) {
        setListOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.lists.map((list: any) => ({
        label: list.name,
        value: list.id,
      }));
      setListOptions(options);
    },
    onError: (error: any) => {
      const errorString = error.response.data.error;
      setError(errorString);
    },
    enabled: !!board,
  });

  const handleUpdate = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'trello',
      data_source_type: '',
      data_source_meta: { boardId: board, listId: list },
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const onBoardChange = (option: any) => {
    const value = option ? option.value : null;
    setBoard(value);
  };

  const onListChange = (option: any) => {
    const value = option ? option.value : null;
    setList(value);
  };

  const trelloTranslationKey = 'modals.dataConnectors.trelloData';

  const selectedBoard = boardOptions.find((option) => option.value === board);
  const selectedList = listOptions.find((option) => option.value === list);

  const hasChanged = outcome.data_source_meta.list_id !== list;

  return (
    <>
      <FormField>
        <label>
          {t(`${trelloTranslationKey}.board`)}
          {isLoadingBoards && <KoalaLoader />}
        </label>
        {!isLoadingBoards && (
          <div style={{ width: '50%' }}>
            <KoalaSelect
              handleChange={(options) => onBoardChange(options)}
              placeholder={t(`${trelloTranslationKey}.boardPlaceholder`) ?? 'Select Trello board'}
              selectedOption={selectedBoard}
              options={boardOptions}
              className="small"
            />
          </div>
        )}
      </FormField>

      {board && (
        <FormField>
          <label>
            {t(`${trelloTranslationKey}.list`)}
            {isLoadingBoardLists && <KoalaLoader />}
          </label>
          {!isLoadingBoardLists && (
            <div style={{ width: '50%' }}>
              <KoalaSelect
                handleChange={(options) => onListChange(options)}
                placeholder={t(`${trelloTranslationKey}.listPlaceholder`) ?? 'Select list'}
                selectedOption={selectedList}
                options={listOptions}
                className="small"
              />
            </div>
          )}
        </FormField>
      )}
      {error && <ErrorContainer>{error}</ErrorContainer>}

      {hasChanged && (
        <FormField>
          <KoalaButton
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={isUpdating || !list || !!error}
            appearance="subtle"
            type="button"
          >
            {t(`panels.editOutcome.savePreview`)}
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'trello' && !hasChanged && <CurrentValue outcome={outcome} />}
    </>
  );
}

export default React.memo(TrelloSource);
