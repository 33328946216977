import React, { ReactElement, useState } from 'react';
import KoalaTextBadge from 'koala/components/TextBadge';
import styled from 'styled-components';
import theme from 'theme';
import DropdownMenu from 'components/DropdownMenu';
import ReactTooltip from 'react-tooltip';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { User } from 'types';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useMutation } from 'react-query';

import * as remoteApi from 'api/remote';
import { sessionActions } from 'state/actions/';
import { AxiosResponse } from 'axios';
import KoalaLoader from 'koala/components/Loader';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

import FlagEN from './assets/FlagEN';
import FlagFR from './assets/FlagFR';
import FlagDE from './assets/FlagDE';
import FlagPT from './assets/FlagPT';
import FlagES from './assets/FlagES';
import FlagVI from './assets/FlagVI';

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;
const Container = styled.div`
  .text-badge-label {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
  }
`;
const FlagContainer = styled.div`
  display: inline-flex;
  height: auto;
  align-items: center;
  justify-content: center;
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

const BetaLozenge = styled.span`
  text-transform: uppercase;
  font-size: 1rem;
  color: ${theme.colors.O70};
`;

function getFlag(language: string) {
  switch (language) {
    case 'fr':
      return <FlagFR />;
    case 'en':
      return <FlagEN />;
    case 'de':
      return <FlagDE />;
    case 'pt':
      return <FlagPT />;
    case 'es':
      return <FlagES />;
    case 'vi':
      return <FlagVI />;
    default:
      return <FlagEN />;
  }
}

// function getFlag(language: string) {
//   switch (language) {
//     case 'fr':
//       return 'FR';
//     case 'en':
//       return 'EN';
//     case 'de':
//       return 'DE';
//     case 'pt':
//       return 'PT';
//     case 'es':
//       return 'ES';
//     default:
//       return 'EN';
//   }
// }

function LanguageSwitcher() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser: User = useSelector((state: any) => state.session.currentUser, shallowEqual);

  const [language, setLanguage] = useState(currentUser.language_preference ?? 'en');

  const [updateAccountMutation, { isLoading }] = useMutation(remoteApi.updateAccount, {
    onSuccess: (response: AxiosResponse<User>) => {
      const user: User = response.data;
      dispatch(sessionActions.setCurrentUser(user));
      i18next.changeLanguage(user.language_preference);
      if (user.language_preference !== 'en') {
        dispatch(setGlobalModalContent(`user::beta.language`));
      }
    },
  });

  const languageOptions = [
    <DropdownItem data-action="en">
      <FlagContainer>{getFlag('en')}</FlagContainer>
      English
    </DropdownItem>,
    <DropdownItem data-action="fr">
      <FlagContainer>{getFlag('fr')}</FlagContainer>
      Français
    </DropdownItem>,
    // <DropdownItem data-action="de">
    //   <FlagContainer>{getFlag('de')}</FlagContainer>
    //   Deutsch
    // </DropdownItem>,
    <DropdownItem data-action="pt">
      <FlagContainer>{getFlag('pt')}</FlagContainer>
      Português
    </DropdownItem>,
    <DropdownItem data-action="es">
      <FlagContainer>{getFlag('es')}</FlagContainer>
      Español
    </DropdownItem>,
    <DropdownItem data-action="vi">
      <FlagContainer>{getFlag('vi')}</FlagContainer>
      Tiếng Việt
    </DropdownItem>,
    <DropdownItem data-action="improve">{t('components.languageSwitcher.improve')}</DropdownItem>,
  ];

  const changeLanguageHandler = (lang: string) => {
    setLanguage(lang);
    const params = {
      language_preference: lang,
    };
    updateAccountMutation({
      user: params,
    });
  };

  const selectLanguage = (value: ReactElement) => {
    const action = value.props['data-action'];
    if (action === 'improve') {
      window.Intercom('show');
    } else {
      changeLanguageHandler(action);
    }
  };

  if (isLoading) {
    return <KoalaLoader />;
  }
  return (
    <>
      <DropdownMenu
        items={languageOptions}
        trigger={
          <Container data-tip data-for="language-picker">
            <KoalaTextBadge variant="neutral-light" isLowercase maxWidth="unset">
              <FlagContainer>{getFlag(language)}</FlagContainer>
              {!['en', 'es'].includes(language) && <BetaLozenge>{t('shared.beta')}</BetaLozenge>}
            </KoalaTextBadge>
          </Container>
        }
        onSelection={selectLanguage}
      />
      {language !== 'en' && (
        <ReactTooltip place="bottom" id={`language-picker`} delayShow={500} effect="solid">
          <Trans i18nKey={'components.languageSwitcher.tooltip'} />
        </ReactTooltip>
      )}
    </>
  );
}

export default LanguageSwitcher;
