import styled from 'styled-components';
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
// import { useMutation, useQuery, useQueryCache } from 'react-query';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useHistory, useParams } from 'react-router-dom';
import KoalaInputValidation from 'koala/components/InputValidation';
import * as routes from 'routes';
import { Team } from 'types';
import { useTranslation } from 'react-i18next';

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

function CreateTeam() {
  const dispatch = useDispatch();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();

  const [name, setName] = useState('');
  const queryCache = useQueryCache();
  const history = useHistory();
  const { t } = useTranslation();
  const translationKey = 'modals.createTeam';

  const [createTeamMutation, { isLoading: isCreating, isError }]: [any, any] = useMutation(remoteApi.createTeam, {
    onSuccess: (response) => {
      const team: Team = response.data;
      queryCache.invalidateQueries([queryKeys.teams]);
      const path = routes.WORKSPACE_TEAM_DETAILS_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
        ':teamId',
        team.nano_slug,
      );
      history.push(path);
      dispatch(setGlobalModalContent(''));
    },
  });

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    const team = {
      name,
    };
    const params = {
      team,
      workspaceSlug: workspaceSlug,
    };
    createTeamMutation(params);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputName = e.target.value;
    setName(inputName);
  };

  const allowSave = name && !isCreating;

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleSave}>
          <FormField>
            <label>{t(`${translationKey}.name`)}</label>
            <input
              placeholder={t(`${translationKey}.placeholder`) ?? 'Team name'}
              className="segment-input"
              value={name}
              onChange={handleNameChange}
            />
            {isError && <KoalaInputValidation state="error" text={t(`${translationKey}.error`)} />}
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!allowSave} loading={isCreating}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default CreateTeam;
