import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import * as checkinUtils from 'utils/checkinUtils';
import * as outcomeUtils from 'utils/outcomeUtils';
import { useTranslation } from 'react-i18next';
import { formatLocale } from 'utils/dateUtils';

import ReactTooltip from 'react-tooltip';

const Container = styled.div`
  font-size: 1.2rem;
  color: ${theme.colors.subtleText};
  line-height: 1;
  font-weight: 500;
  &.positive {
    color: ${theme.colors.G60};
  }
  &.negative {
    color: ${theme.colors.R60};
  }
  &.neutral {
    color: ${theme.colors.Y80};
  }
  &.kpi {
    color: ${theme.colors.B60};
  }
`;

const ScoreContainer = styled.div`
  .score-summary {
    border-top: 1px solid #fff;
    padding-top: ${theme.spacing.half};
    margin-top: ${theme.spacing.half};
  }

  div {
    display: flex;
    width: 100%;
    gap: ${theme.spacing.x2};
    justify-content: space-between;
    b {
    }
  }
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  showNull?: boolean;
}

function CurrentGrowth(props: Props) {
  const { outcome, showNull } = props;
  const { t, i18n } = useTranslation();

  const { current_checkin, previous_checkin } = outcome;

  // Get current growth between current checkin and previous checkin (or starting point)
  const currentGrowth = outcomeUtils.getProgressPercentageSinceLastCheckin(outcome);

  const getNullValue = () => {
    if (showNull) {
      return <>–</>;
    }
    return null;
  };

  // Don't display anything if there's no currentGrowth information, or if current_checkin is missing
  if (currentGrowth === null || current_checkin === null || current_checkin === undefined) {
    return getNullValue();
  }

  // Set the default to kpi class, we'll change everything if necessary with the logic below
  let currentGrowthClass = 'kpi';

  // The switch below handles the growth class for improve_metric, stay_above, and stay_below outcome types.
  switch (outcome.outcome_type) {
    case 'improve_metric':
      // Check that we have an outcome with the right data
      if (outcome.from === null || outcome.to === null) {
        return getNullValue();
      }
      const targetDifference = outcome.to - outcome.from;
      if (targetDifference * currentGrowth < 0) {
        currentGrowthClass = 'negative';
      } else if (currentGrowth === 0) {
        currentGrowthClass = 'neutral';
      } else {
        currentGrowthClass = 'positive';
      }
      break;
    case 'stay_above':
      if (outcome.to === null) {
        return getNullValue();
      }
      if (currentGrowth < 0) {
        currentGrowthClass = 'negative';
      } else if (currentGrowth === 0) {
        currentGrowthClass = 'neutral';
      } else {
        currentGrowthClass = 'positive';
      }
      break;
    case 'stay_below':
      if (outcome.to === null) {
        return getNullValue();
      }
      if (currentGrowth > 0) {
        currentGrowthClass = 'negative';
      } else if (currentGrowth === 0) {
        currentGrowthClass = 'neutral';
      } else {
        currentGrowthClass = 'positive';
      }
      break;
  }

  // Only return null if there's no currentGrowth
  // Note: avoid things like if (!currentGrowth), because currentGrowth can be 0, and !0 === true so the
  // logic would return null
  if (currentGrowth === null) {
    return getNullValue();
  }

  const currentScore = current_checkin.score;
  const previousScore = previous_checkin ? previous_checkin.score : outcome.from || 0;

  const currentDate = current_checkin.checkin_date;
  const previousDate = previous_checkin ? previous_checkin.checkin_date : null;

  // Get the score difference
  const scoreDifference = currentScore - previousScore;
  let scoreDifferenceFormatted = outcomeUtils.nFormatter(scoreDifference, 2);
  scoreDifferenceFormatted = checkinUtils.applyFormat(outcome.score_format, scoreDifferenceFormatted);

  const currentScoreFormatted = checkinUtils.checkinScore(current_checkin, outcome, t);
  const previousScoreFormatted = previous_checkin
    ? checkinUtils.checkinScore(previous_checkin, outcome, t)
    : outcomeUtils.startingScore(outcome, t);

  // Format the dates
  let currentDateFormatted = ` (${formatLocale(currentDate, 'MMM do', i18n)})`;
  let previousDateFormatted = previousDate ? ` (${formatLocale(previousDate, 'MMM do', i18n)})` : null;

  return (
    <>
      <Container className={currentGrowthClass} data-tip data-for={`var-outcome-${outcome.nano_slug}`}>
        {currentGrowth >= 0 && <span>+</span>}
        <span>{outcomeUtils.nFormatter(currentGrowth, 2)}%</span>
      </Container>
      <ReactTooltip
        place="bottom"
        type="dark"
        className="tooltip"
        effect="solid"
        id={`var-outcome-${outcome.nano_slug}`}
      >
        <ScoreContainer>
          <div>
            <span>
              {t('shared.current')}:{currentDateFormatted}
            </span>
            <b>{currentScoreFormatted}</b>
          </div>
          <div>
            <span>
              {t('shared.previous')} {previousDateFormatted}:
            </span>
            <b>{previousScoreFormatted}</b>
          </div>
          <div className="score-summary">
            <span>{t('shared.difference')}:</span>
            <b>{scoreDifferenceFormatted}</b>
          </div>
        </ScoreContainer>
      </ReactTooltip>
    </>
  );
}

export default CurrentGrowth;
