import React from 'react';
import styled from 'styled-components';
import Loader from 'components/Loader';

// Components
const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function LoadingScreen() {
  // Displays the list of templates
  return (
    <Container>
      <Loader size="big" />
    </Container>
  );
}

export default LoadingScreen;
