import React, { useState } from 'react';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';

import { useQuery, useMutation, useQueryCache } from 'react-query';

import * as remoteApi from 'api/remote';
import { Outcome, Relationship, Objective } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import KoalaLoader from 'koala/components/Loader';
import RelatedOutcome from 'components/RelatedOutcome';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';
import { Link } from 'react-router-dom';
interface Props {
  objective: Objective;
  hasEditPermission: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  header {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ${theme.spacing.x1};
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
  }
`;

const OutcomesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
`;

const ObjectivesContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.half};
  font-size: 1.2rem;
  color: ${theme.colors.subtleText};
  a {
    color: ${theme.colors.subtleText};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Line = styled.div`
  display: flex;
  position: relative;
  gap: ${theme.spacing.x1};
  justify-content: space-between;

  .action {
    opacity: 0.2;
    transition: all ease 0.2s;
  }

  &:hover {
    .action {
      opacity: 1;
    }
  }
`;

function AlignmentAccordion(props: Props) {
  const { objective, hasEditPermission } = props;

  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'panels.outcome';
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  let relationships_count = 0;
  const [parentOutcomes, setParentOutcomes] = useState<Relationship[]>([]);
  const [relatedOutcomes, setRelatedOutcomes] = useState<Relationship[]>([]);
  const [contributingOutcomes, setContributingOutcomes] = useState<Relationship[]>([]);

  const [parentObjectives, setParentObjectives] = useState<Relationship[]>([]);
  const [relatedObjectives, setRelatedObjectives] = useState<Relationship[]>([]);
  const [contributingObjectives, setContributingObjectives] = useState<Relationship[]>([]);

  const staleTime = 0;

  // Functions to destroy the relationship
  const [deleteRelationshipMutation, { isLoading: isDeletingRelationship }]: any = useMutation(
    remoteApi.deleteRelationship,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.relationships);
      },
    },
  );

  const handleDeleteRelationship = (relationshipId: string) => {
    deleteRelationshipMutation(relationshipId);
  };

  const queryKeyOutcomes = [queryKeys.relationships, objective?.id, 'outcomes'];

  const { isLoading: isLoadingOutcomes } = useQuery(queryKeyOutcomes, remoteApi.fetchRelationshipsForObjective, {
    staleTime,
    onSuccess: (response: any) => {
      const relationships: Relationship[] = response.data;

      const parentOutcomes: Relationship[] = [];
      const relatedOutcomes: Relationship[] = [];
      const contributingOutcomes: Relationship[] = [];
      relationships.forEach((relationship) => {
        if (relationship.source_id === objective.id && relationship.relationship_type === 'contributes_to') {
          parentOutcomes.push(relationship);
        }
        if (relationship.relationship_type === 'relates_to') {
          relatedOutcomes.push(relationship);
        }
        if (relationship.target_id === objective.id && relationship.relationship_type === 'contributes_to') {
          contributingOutcomes.push(relationship);
        }
      });
      setParentOutcomes(parentOutcomes);
      setRelatedOutcomes(relatedOutcomes);
      setContributingOutcomes(contributingOutcomes);
    },
  });

  const queryKeyObjectives = [queryKeys.relationships, objective?.id, 'objectives'];

  const { isLoading: isLoadingObjectives } = useQuery(queryKeyObjectives, remoteApi.fetchRelationshipsForObjective, {
    staleTime,
    onSuccess: (response: any) => {
      const relationships: Relationship[] = response.data;

      const parentObjectives: Relationship[] = [];
      const relatedObjectives: Relationship[] = [];
      const contributingObjectives: Relationship[] = [];
      relationships.forEach((relationship) => {
        if (relationship.source_id === objective.id && relationship.relationship_type === 'contributes_to') {
          parentObjectives.push(relationship);
        }
        if (relationship.relationship_type === 'relates_to') {
          relatedObjectives.push(relationship);
        }
        if (relationship.target_id === objective.id && relationship.relationship_type === 'contributes_to') {
          contributingObjectives.push(relationship);
        }
      });
      setParentObjectives(parentObjectives);
      setRelatedObjectives(relatedObjectives);
      setContributingObjectives(contributingObjectives);
    },
  });

  if (isLoadingOutcomes || isLoadingObjectives) {
    return <KoalaLoader />;
  }

  relationships_count =
    parentOutcomes?.length +
    relatedOutcomes?.length +
    contributingOutcomes?.length +
    parentObjectives?.length +
    relatedObjectives?.length +
    contributingObjectives?.length;

  if (relationships_count === 0) {
    return <p className="subtle">{t(`${translationKey}.noDependencies`)}</p>;
  }

  const DisplayOutcome = ({
    outcome,
    aligned = false,
    relationshipId,
  }: {
    outcome: Outcome;
    aligned?: boolean;
    relationshipId: string;
  }) => {
    return (
      <Line>
        <RelatedOutcome outcome={outcome} aligned={aligned} />
        {hasEditPermission && (
          <div className="action">
            {isDeletingRelationship && <KoalaLoader />}
            {!isDeletingRelationship && (
              <KoalaIconButton
                onClick={() => handleDeleteRelationship(relationshipId)}
                iconName="close"
                size="xsmall"
              />
            )}
          </div>
        )}
      </Line>
    );
  };

  const DisplayObjective = ({
    objective,
    aligned = false,
    relationshipId,
  }: {
    objective: Objective;
    aligned?: boolean;
    relationshipId: string;
  }) => {
    return (
      <Line>
        <ObjectivesContainer>
          <KoalaIcon iconName="flag" iconSize="xsmall" iconAppearance={theme.colors.T80} />
          <Link to={`#objective:${objective.nano_slug}:show`}>{objective.title}</Link>
        </ObjectivesContainer>
        {hasEditPermission && (
          <div className="action">
            {isDeletingRelationship && <KoalaLoader />}
            {!isDeletingRelationship && (
              <KoalaIconButton
                onClick={() => handleDeleteRelationship(relationshipId)}
                iconName="close"
                size="xsmall"
              />
            )}
          </div>
        )}
      </Line>
    );
  };

  return (
    <Container>
      {(parentOutcomes?.length > 0 || parentObjectives?.length > 0) && (
        <div>
          <header>
            {t(`${translationKey}.parent`, {
              label: translate(currentWorkspace, CustomTermKey.OUTCOME, parentOutcomes?.length),
            })}
          </header>
          <OutcomesContainer>
            {parentObjectives.map((relationship) => {
              const objective = relationship.target as Objective;
              return <DisplayObjective objective={objective} relationshipId={relationship.id} />;
            })}
            {parentOutcomes.map((relationship) => {
              const outcome = relationship.target as Outcome;
              return <DisplayOutcome outcome={outcome} relationshipId={relationship.id} />;
            })}
          </OutcomesContainer>
        </div>
      )}
      {(contributingOutcomes?.length > 0 || contributingObjectives?.length > 0) && (
        <div>
          <header>
            <KoalaIcon iconName="alignmentArrow" iconSize="xsmall" iconAppearance="custom" />
            {t(`${translationKey}.aligned`, {
              label: translate(currentWorkspace, CustomTermKey.OUTCOME, contributingOutcomes?.length),
            })}
          </header>
          <OutcomesContainer>
            {contributingObjectives.map((relationship) => {
              const objective = relationship.source as Objective;
              return <DisplayObjective objective={objective} aligned={true} relationshipId={relationship.id} />;
            })}
            {contributingOutcomes.map((relationship) => {
              const outcome = relationship.source as Outcome;
              return <DisplayOutcome outcome={outcome} aligned={true} relationshipId={relationship.id} />;
            })}
          </OutcomesContainer>
        </div>
      )}
      {(relatedOutcomes?.length > 0 || relatedObjectives?.length > 0) && (
        <div>
          <header>
            {t(`${translationKey}.related`, { label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1) })}
          </header>
          <OutcomesContainer>
            {relatedObjectives.map((relationship) => {
              let objective;
              if (relationship.source_id === props.objective.id) {
                objective = relationship.target as Objective;
              } else {
                objective = relationship.source as Objective;
              }
              return <DisplayObjective objective={objective} aligned={true} relationshipId={relationship.id} />;
            })}
            {relatedOutcomes.map((relationship) => {
              let outcome;
              if (relationship.source_id === props.objective.id) {
                outcome = relationship.target as Outcome;
              } else {
                outcome = relationship.source as Outcome;
              }
              return <DisplayOutcome outcome={outcome} relationshipId={relationship.id} />;
            })}
          </OutcomesContainer>
        </div>
      )}
    </Container>
  );
}

export default AlignmentAccordion;
