export const colors: { [key: string]: string } = {
  purple: '#955BE3',

  // new colors
  // neutral
  N0: '#FFFFFF',
  N5: '#F4F4F4',
  N10: '#E8E8E8',
  N20: '#D0D0D0',
  N30: '#B9B9B9',
  N40: '#A1A1A1',
  N50: '#8A8A8A',
  N60: '#737373',
  N70: '#5B5B5B',
  N80: '#444444',
  N90: '#2C2C2C',
  N100: '#151515',
  // blue
  B5: '#EBEDFB',
  B10: '#C2CAF2',
  B20: '#99A6E9',
  B30: '#7082E0',
  B40: '#475FD7',
  B50: '#324DD3',
  B60: '#2D45BE',
  B70: '#283EA9',
  B80: '#233694',
  B90: '#1E2E7F',
  B100: '#1E2E7F',
  // green
  G5: '#ECF4F1',
  G10: '#C6DDD6',
  G20: '#A0C6BB',
  G30: '#8CBAAD',
  G40: '#539884',
  G50: '#408C76',
  G60: '#3A7E6A',
  G70: '#33705E',
  G80: '#2D6253',
  G90: '#265447',
  G100: '#20463B',
  //violet
  V5: '#F4EFFC',
  V10: '#EADEF9',
  V20: '#D5BDF4',
  V30: '#B58CEB',
  V40: '#A06BE6',
  V50: '#955BE3',
  V60: '#8652CC',
  V70: '#7749B6',
  V80: '#68409F',
  V90: '#593788',
  V100: '#4B2E72',
  //red
  R5: '#FCDAD6',
  R10: '#FBC8C2',
  R20: '#F9A499',
  R30: '#F67F70',
  R40: '#F35A47',
  R50: '#F24833',
  R60: '#DA412E',
  R70: '#C23A29',
  R80: '#A93224',
  R90: '#912B1F',
  R100: '#79241A',
  //teal
  T5: '#F1FAFB',
  T10: '#E2F4F7',
  T20: '#C5EAEF',
  T30: '#A8DFE6',
  T40: '#8BD5DE',
  T50: '#6ECAD6',
  T60: '#63B6C1',
  T70: '#58A2AB',
  T80: '#4D8D96',
  T90: '#427980',
  T100: '#37656B',
  //yellow
  Y5: '#FEF9EA',
  Y10: '#FDF2D5',
  Y20: '#FBE6AA',
  Y30: '#F9D980',
  Y40: '#F7CD55',
  Y50: '#F5C02B',
  Y60: '#DDAD27',
  Y70: '#C49A22',
  Y80: '#AC861E',
  Y90: '#93731A',
  Y100: '#7B6016',
  //pink
  P5: '#FDF5F4',
  P10: '#FCF0EF',
  P20: '#FBEBE9',
  P30: '#F7DCD9',
  P40: '#F5D2CE',
  P50: '#F4CDC9',
  P60: '#DCB9B5',
  P70: '#C3A4A1',
  P80: '#AB908D',
  P90: '#927B79',
  P100: '#7A6765',
  //orange
  O5: '#FFF3E8',
  O10: '#FFE7D1',
  O20: '#FFCFA3',
  O30: '#FFB875',
  O40: '#FFA047',
  O50: '#FF8819',
  O60: '#E67A17',
  O70: '#CC6D14',
  O80: '#B35F12',
  O90: '#99520F',
  O100: '#80440D',
};

// This will be used for rem spacing definitions
const baseSpacing = 0.8;
export const spacing = {
  half: `${baseSpacing / 2}rem`,
  x1: `${baseSpacing}rem`,
  x2: `${baseSpacing * 2}rem`,
  x3: `${baseSpacing * 3}rem`,
  x4: `${baseSpacing * 4}rem`,
  x5: `${baseSpacing * 5}rem`,
  x6: `${baseSpacing * 6}rem`,
  x7: `${baseSpacing * 7}rem`,
  x8: `${baseSpacing * 8}rem`,
  x9: `${baseSpacing * 9}rem`,
  x10: `${baseSpacing * 10}rem`,
  x11: `${baseSpacing * 11}rem`,
  x12: `${baseSpacing * 12}rem`,
  x13: `${baseSpacing * 13}rem`,
  x14: `${baseSpacing * 14}rem`,
};

export const typography = {
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  weight: {
    normal: '500',
    thick: '600',
    bold: 'bold',
    label: '800',
  },
  size: {
    buttonStandard: '1.6rem',
    buttonSmall: '1.2rem',
  },
};
