import React, { Fragment, useState, useEffect } from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';

import { useMutation, useQuery, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';

// Components
import Loader from 'components/Loader';
import { ChromeContent } from 'components/Chrome';
import ContentNarrowWrapper from 'components/ContentNarrowWrapper';
import UpgradePanel from 'components/UpgradePanel';

import Description from './Description';
import TeamMembers from './TeamMembers';

import PlansList from './PlansList';
import TeamHero from 'components/TeamHero';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useTranslation } from 'react-i18next';

const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LargeEmptyState = styled.div`
  background: #fff;
  padding: ${theme.spacing.x4};
  border-radius: 8px;
  border: 1px solid ${theme.colors.blockBorder};

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  svg {
    margin-left: ${theme.spacing.x1};
  }
`;

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const TopSection = styled.div`
  display: flex;
  gap: ${theme.spacing.x3};
`;

const TeamHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

function Inbox() {
  const { workspaceSlug, teamId } = useParams<{ workspaceSlug: string; teamId: string }>();
  const queryKey = [queryKeys.teams, teamId];
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  const [team, setMembership]: any = useState(null);

  const queryCache = useQueryCache();
  const history = useHistory();

  useQuery(queryKey, remoteApi.fetchTeamDetails, {
    staleTime: 0,
    onSuccess: (response) => {
      const team = response.data;
      setMembership(team);
    },
  });

  const [deleteTeamMutation, { isLoading: isDeletingTeam }]: [any, any] = useMutation(remoteApi.deleteTeam, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
      history.push(`/${workspaceSlug}/teams`);
    },
  });

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.team.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Display loader if fetching
  if (!team) {
    return (
      <Fragment>
        <ChromeContent isGreyBackground>
          <ContentNarrowWrapper>
            <Loader size="big" />
          </ContentNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  const handleSetColor = () => {
    dispatch(setGlobalModalContent(`team:${team.id}:set.color`));
  };

  const handleDeleteTeam = () => {
    if (window.confirm(t('workspaceTeams.teamMember.delete') ?? 'Are you sure you want to delete this team?')) {
      deleteTeamMutation(team.id);
    }
  };

  const inboxLoading = isDeletingTeam;

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {team.name} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader>
        <WorkspaceHeader
          workspace={currentWorkspace}
          useGreyBackground={true}
          title={
            <TeamHeader>
              <Title>
                {t('workspaceTeams.title')}
                {inboxLoading && <Loader />}
              </Title>
            </TeamHeader>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeContent>
        <ContentNarrowWrapper>
          <TeamHero team={team} handleDeleteTeam={handleDeleteTeam} handleSetColor={handleSetColor} />

          <Content>
            <TopSection>
              <Description team={team} />

              <TeamMembers team={team} />
              {!hasEssentialsSubscription && (
                <Mask>
                  <LargeEmptyState>
                    <h3>{t('workspaceTeams.teamMember.notAvailable')}</h3>
                    <UpgradePanel
                      title={t('workspaceTeams.teamMember.upgrade') ?? 'Upgrade to the Essentials subscription'}
                      description={
                        t('workspaceTeams.teamMember.upgradeInfo') ??
                        'Upgrade to the Essentials subscription or above to enable people dashboards.'
                      }
                    />
                  </LargeEmptyState>
                </Mask>
              )}
            </TopSection>
            <PlansList teamId={team.id} workspace={currentWorkspace} />
          </Content>
        </ContentNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default Inbox;
