import React, { Fragment, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import _ from 'lodash';
import RecentlyViewed from './RecentlyViewed';
import SearchResults from './SearchResults';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';

const Mask = styled.div<{ showSearchPanel: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${(props) => (props.showSearchPanel ? '50' : '-1')};
  opacity: ${(props) => (props.showSearchPanel ? '1' : '0')};
  transition: 0.2s ease-out;
`;

const ContentWrapper = styled.div<{ showSearchPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40rem;
  z-index: 60;
  transform: translateX(${(props) => (props.showSearchPanel ? '0%' : '100%')});
  transition: 0.2s ease-out;

  @media ${theme.devices.mobile} {
    max-width: 40rem;
    width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 3.2rem;

  background: #fff;

  input {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #151515;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: ${theme.spacing.x3};
`;

interface Props {
  showSearchPanel: boolean;
  setShowSearchPanel: (value: boolean) => void;
}

function SearchPanel(props: Props) {
  const { showSearchPanel, setShowSearchPanel } = props;
  const { t } = useTranslation();
  // Form values
  const [inputValue, setInputValue] = useState('');
  const [filterText, setFilterText] = useState('');

  const handleClosePanel = () => {
    setShowSearchPanel(false);
  };

  // START SEARCH LOGIC
  const performSearch = (filterText: string) => {
    setFilterText(filterText);
  };

  const debouncePerformSearch = useRef(
    _.debounce((filterText: string) => performSearch(filterText), 500, {
      maxWait: 2000,
    }),
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterText = e.target.value;
    setInputValue(filterText);
    debouncePerformSearch.current(filterText);
  };

  return (
    <Fragment>
      <Mask onClick={handleClosePanel} showSearchPanel={showSearchPanel} />
      <ContentWrapper showSearchPanel={showSearchPanel}>
        <Content>
          <SearchHeader>
            <p> {t('panels.search.title')}</p>
            <KoalaIconButton onClick={handleClosePanel} iconName="close" />
          </SearchHeader>
          <input
            onChange={handleSearch}
            onFocus={() => setShowSearchPanel(true)}
            placeholder={t('panels.search.title') ?? 'Search'}
            value={inputValue}
          />

          {!filterText && <RecentlyViewed handleClosePanel={handleClosePanel} />}
          {filterText && <SearchResults handleClosePanel={handleClosePanel} filterText={filterText} />}
        </Content>
      </ContentWrapper>
    </Fragment>
  );
}

export default SearchPanel;
