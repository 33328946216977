import CommentWithCountIcon from 'components/_assets/CommentWithCountIcon';
import { format } from 'date-fns';
import { TFunction, i18n } from 'i18next';
import React from 'react';
import theme from 'theme';
import { checkinType, closedInitiativePointType, Domain, DownloadDetails, Outcome } from 'types';
import { confidenceToScore, formattedScore } from 'utils/checkinUtils';
import { formatLocale } from 'utils/dateUtils';
import { nFormatter } from 'utils/outcomeUtils';
import {
  VictoryArea,
  VictoryGroup,
  VictoryAxis,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  InterpolationPropType,
} from 'victory';

const Area = (targetData: checkinType[], showArea = true) => {
  return (
    <VictoryArea
      style={{
        data: {
          fill: theme.colors.N5,
          fillOpacity: showArea ? 0.5 : 0,
          stroke: theme.colors.N10,
          strokeWidth: 2,
        },
      }}
      data={targetData}
    />
  );
};

const CurrentLine = (currentData: checkinType[], customInterpolation?: InterpolationPropType) => {
  return (
    <VictoryArea
      style={{
        data: {
          stroke: theme.colors.B20,
          fill: theme.colors.B20,
          fillOpacity: 0.2,
          strokeWidth: 3,
          strokeLinecap: 'round',
        },
      }}
      data={currentData}
      interpolation={customInterpolation || 'monotoneX'}
    />
  );
};

const Title = (title: string, x: number, y: number) => {
  return (
    <VictoryLabel
      text={title}
      textAnchor="middle"
      x={x}
      y={y}
      style={[
        {
          fontFamily: theme.font.fontFamily,
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '20px',
        },
      ]}
    />
  );
};

const Trendline = (trendData: checkinType[]) => {
  return (
    <VictoryLine
      name="trendPoints"
      style={{ data: { stroke: '#7E98E6', strokeDasharray: '4, 4', strokeWidth: 3 } }}
      data={trendData}
      interpolation={'cardinal'}
    ></VictoryLine>
  );
};

const XAxis = (i18n: i18n) => {
  return (
    <VictoryAxis
      scale="time"
      tickFormat={(t: any) => {
        return formatLocale(t, 'd MMM', i18n);
      }}
      style={{
        axisLabel: { fontFamily: theme.font.fontFamily },
        axis: { stroke: theme.colors.N10 },
        tickLabels: {
          fill: theme.colors.N70,
          fontFamily: theme.font.fontFamily,
          fontSize: '12px',
        },
        ticks: { stroke: theme.colors.N10, size: 5 },
      }}
    />
  );
};

const YAxis = (showMetrics = true) => {
  return (
    <VictoryAxis
      dependentAxis
      tickFormat={(t: number) => {
        return nFormatter(t, 2);
      }}
      tickCount={5}
      crossAxis={false}
      style={{
        grid: { stroke: theme.colors.N10 },
        axis: { stroke: 'transparent' },
        tickLabels: {
          fill: showMetrics ? theme.colors.N70 : 'transparent',
          fontFamily: theme.font.fontFamily,
          fontSize: '12px',
        },
      }}
    />
  );
};

const LabelTooltip = () => {
  return (
    <VictoryTooltip
      cornerRadius={4}
      flyoutStyle={{
        fill: 'rgba(255, 255, 255, 1)',
        stroke: theme.colors.blockBorder,
      }}
      dy={-12}
      flyoutPadding={{ top: 10, bottom: 20, left: 15, right: 15 }}
      constrainToVisibleArea
      style={[
        {
          fill: theme.colors.subtleText,
          fontSize: '10px',
          fontFamily: theme.font.fontFamily,
        },
        {
          fontWeight: '400',
          fill: theme.colors.black,
          fontSize: '14px',
          marginBottom: 4,
          fontFamily: theme.font.fontFamily,
        },
      ]}
      labelComponent={<VictoryLabel lineHeight={2} />}
    />
  );
};

const LabelTooltipStack = () => {
  return (
    <VictoryTooltip
      cornerRadius={4}
      flyoutStyle={{
        fill: 'rgba(255, 255, 255, 1)',
        stroke: theme.colors.blockBorder,
      }}
      dy={-12}
      flyoutPadding={{ top: 10, bottom: 10, left: 15, right: 15 }}
      constrainToVisibleArea
      style={{
        fontWeight: '400',
        fill: theme.colors.black,
        fontSize: '12px',
        marginBottom: 1,
        fontFamily: theme.font.fontFamily,
      }}
      labelComponent={<VictoryLabel lineHeight={1.4} />}
    />
  );
};

const TaskLabelTooltip = () => {
  return (
    <VictoryTooltip
      cornerRadius={4}
      dx={4}
      dy={5}
      constrainToVisibleArea
      flyoutStyle={{
        fill: 'rgba(255, 255, 255, 1)',
        stroke: theme.colors.blockBorder,
      }}
      style={{
        fontWeight: '400',
        fill: theme.colors.black,
        fontSize: '12px',
        marginBottom: 4,
        fontFamily: theme.font.fontFamily,
      }}
      flyoutPadding={{ top: 10, bottom: 10, left: 15, right: 15 }}
      labelComponent={<VictoryLabel lineHeight={1.2} />}
    />
  );
};

const ScatterInitiatives = (closedInitiativesData: closedInitiativePointType[], yDomain: Domain) => {
  const TaskPoint = (props: any) => {
    const { x, y, datum } = props;
    if (!datum || !x || !y) {
      return <></>;
    }

    return (
      <g transform={`translate(${x - 5} ${y + 10}) scale(1.6)`}>
        <circle cx="6" cy="6" r="6" fill={theme.colors.B20} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7186 3.65655C9.04205 3.90298 9.09449 4.35247 8.83573 4.66052L5.83573 8.23193C5.68626 8.40987 5.45661 8.50921 5.21756 8.49933C4.97852 8.48945 4.75877 8.37153 4.62604 8.18193L3.12604 6.03908C2.89628 5.71085 2.98941 5.26738 3.33405 5.04855C3.6787 4.82973 4.14435 4.91843 4.37412 5.24666L5.30517 6.57673L7.66443 3.7681C7.92319 3.46006 8.39515 3.41011 8.7186 3.65655Z"
          fill={theme.colors.B50}
        />
      </g>
    );
  };

  return (
    <VictoryScatter
      data={closedInitiativesData}
      size={6}
      labels={({ datum }: any) => {
        return [`Completed ${format(datum.x, 'd MMM yyyy')}`, ...datum.titlesArray];
      }}
      dataComponent={<TaskPoint />}
      labelComponent={TaskLabelTooltip()}
    />
  );
};

const ScatterPoints = (currentData: checkinType[], outcome: Outcome, checkinClicked?: (params: any) => void) => {
  const canClickCheckin = checkinClicked ? true : false;

  const handleClickCheckin = (e: any) => {
    e.stopPropagation();
    if (checkinClicked) {
      return [
        {
          target: 'data',
          mutation: (params: any) => {
            checkinClicked(params);
          },
        },
      ];
    }
  };

  return (
    <VictoryScatter
      data={currentData}
      size={({ datum }) => (datum.checkin?.is_remote ? 3 : 6)}
      labels={({ datum }: any) => {
        const currentConfidence = datum.checkin?.confidence || 'grey';
        const datumColorToConfidence: any = {
          red: 'off track',
          grey: 'pending',
          yellow: 'at risk',
          green: 'on track',
        };
        const syncedLabel = datum.checkin?.is_remote ? ' (synced)' : '';
        if (outcome.outcome_type === 'no_metric') {
          return [format(datum.x, 'd MMM yyyy'), `${datumColorToConfidence[currentConfidence]}${syncedLabel}`];
        }
        return [format(datum.x, 'd MMM yyyy'), `${formattedScore(outcome.score_format, datum.y)}${syncedLabel}`];
      }}
      labelComponent={LabelTooltip()}
      style={{
        data: {
          fill: '#fff',
          stroke: ({ datum }: any) => datum.color,
          strokeWidth: ({ datum }: any) => (datum.checkin?.is_remote ? 2 : 4),
          cursor: canClickCheckin ? 'pointer' : 'default',
        },
      }}
      events={
        canClickCheckin
          ? [
              {
                target: 'data',
                eventHandlers: {
                  onClick: handleClickCheckin,
                },
              },
            ]
          : undefined
      }
    />
  );
};

const DownloadScatterPointsPrevious = (
  currentData: checkinType[],
  outcome: Outcome,
  downloadDetails: DownloadDetails,
) => {
  const prevData = currentData;

  return (
    <VictoryScatter
      data={prevData}
      size={6}
      style={{
        data: {
          fill: '#fff',
          stroke: ({ datum }: any) => datum.color,
          strokeWidth: 4,
        },
      }}
    />
  );
};

const DownloadScatterPointsLatest = (
  currentData: checkinType[],
  outcome: Outcome,
  downloadDetails: DownloadDetails,
  t: TFunction,
) => {
  if (currentData.length === 0) {
    return;
  }
  const latestData = currentData.length > 1 ? currentData[currentData.length - 2] : currentData[0];

  const getTooltip = (showTooltip: boolean) => {
    return showTooltip ? (
      <VictoryTooltip
        active
        cornerRadius={4}
        flyoutStyle={{
          fill: 'rgba(255, 255, 255, 1)',
          stroke: theme.colors.blockBorder,
        }}
        flyoutPadding={10}
        flyoutHeight={60}
        labelComponent={
          <VictoryLabel
            lineHeight={1.8}
            style={[
              {
                fontWeight: '600',
                textTransform: 'uppercase',
                fill: theme.colors.subtleText,
                fontSize: '12px',
                fontFamily: theme.font.fontFamily,
              },
              {
                fontWeight: '400',
                fill: theme.colors.black,
                fontSize: '14px',
                marginBottom: 4,
                wordWrap: 'wrap',
                fontFamily: theme.font.fontFamily,
              },
            ]}
          />
        }
      />
    ) : (
      <></>
    );
  };

  const getDataLabel = (datum: any) => {
    let label: string;
    if (outcome.outcome_type === 'no_metric') {
      label = datum.checkin ? confidenceToScore(datum.checkin.confidence, t) : '-';
    } else {
      label = formattedScore(outcome.score_format, datum.y);
    }
    return label;
  };

  return (
    <VictoryScatter
      data={[latestData]}
      size={6}
      labels={({ datum }: any) => {
        return [format(datum.x, 'd MMM yyyy'), getDataLabel(datum)];
      }}
      labelComponent={getTooltip(downloadDetails.showTooltip)}
      style={{
        data: {
          fill: '#fff',
          stroke: ({ datum }: any) => datum.color,
          strokeWidth: 4,
        },
      }}
    />
  );
};

const CheckinComments = (currentData: checkinType[]) => {
  const CommentPoint = (props: any) => {
    const { x, y, datum } = props;
    if (!datum || !x || !y) {
      return <></>;
    }
    const { checkin } = datum;
    if (checkin && checkin.checkin_comments_count > 0) {
      return (
        <g transform={`translate(${x - 8},${y - 30}) scale(0.8)`}>
          <CommentWithCountIcon comments_count={checkin.checkin_comments_count} />
        </g>
      );
    }
    return <></>;
  };
  return <VictoryScatter data={currentData} dataComponent={<CommentPoint />} />;
};

const TodayLine = (yDomain: Domain, t: TFunction) => {
  return (
    <VictoryGroup
      style={{
        data: { strokeWidth: 4 },
      }}
      scale="time"
    >
      <VictoryLine
        name="todayLine"
        data={[
          { x: new Date(), y: yDomain.min - 1 },
          { x: new Date(), y: yDomain.max },
        ]}
        style={{
          data: { stroke: theme.colors.N10, strokeWidth: 2, strokeDasharray: '4, 2' },
        }}
      />

      <VictoryLabel
        text={t('shared.today') ?? 'Today'}
        datum={{ x: new Date(), y: yDomain.max }}
        dy={-15}
        textAnchor="middle"
        style={{ fill: theme.colors.N60, fontWeight: 400, fontSize: 10, fontFamily: theme.font.fontFamily }}
      />
    </VictoryGroup>
  );
};

export default {
  Area,
  Title,
  XAxis,
  YAxis,
  LabelTooltip,
  LabelTooltipStack,
  Trendline,
  CurrentLine,
  ScatterPoints,
  ScatterInitiatives,
  CheckinComments,
  TodayLine,
  DownloadScatterPointsPrevious,
  DownloadScatterPointsLatest,
};
