import React, { useEffect, useState } from 'react';
import OutcomeWeight from 'components/OutcomeWeight';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Outcome } from 'types';
import * as checkinUtils from 'utils/checkinUtils';
import * as outcomeUtils from 'utils/outcomeUtils';
import * as membershipUtils from 'utils/membershipUtils';

import * as remoteApi from 'api/remote';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import UserSelectButton from 'components/UserSelectButton';
import { useQueryCache, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import KoalaStackedAvatar from 'koala/components/StackedAvatars';
import OutcomeChildren from './OutcomeChildren';
import KoalaIconButton from 'koala/components/IconButton';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
// import BlockActions from 'components/BlockActions';
import CurrentGrowth from 'components/CurrentGrowth';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import Parents from './Parents';
import KoalaIcon from 'koala/components/Icons';
import OutcomeTarget from 'components/OutcomeTarget';
import KoalaProgressBar from 'koala/components/ProgressBar';

const Container = styled.div`
  .show-on-hover {
    opacity: 0;
    transition: all ease 0.2s;
  }

  &:hover {
    .show-on-hover {
      opacity: 1;
    }
  }
`;

const MetricRow = styled.div`
  display: grid;
  position: relative;

  grid-template-columns: 1fr 20rem 15rem 6rem 9rem 7rem;
  grid-template-areas: 'metric target current growth owners actions';

  .block-actions {
    opacity: 0;
    z-index: 2;
  }

  &:hover {
    background: ${theme.colors.N3};
    cursor: pointer;
    .block-actions {
      opacity: 1;
    }
  }

  header {
    font-size: 1.2rem;
    color: ${theme.colors.subtleText};
  }

  &.header {
    &:hover {
      background: none;
    }
  }

  @media ${theme.devices.smallDesktop} {
    grid-template-columns: 1fr 20rem 15rem;
    grid-template-areas: 'metric target current';
  }
  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr 15rem;
    grid-template-areas: 'metric current';
  }
`;

const BlockMetric = styled.div`
  grid-area: metric;
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
  padding: ${theme.spacing.x1};
  border-right: 1px solid ${theme.colors.blockBorder};
`;
const BlockTarget = styled.div`
  grid-area: target;
  min-height: 3.2rem;
  display: flex;
  align-items: center;
  color: ${theme.colors.subtleText};
  padding: ${theme.spacing.x1};
  border-right: 1px solid ${theme.colors.blockBorder};
  @media ${theme.devices.laptop} {
    display: none;
  }
`;
const BlockCurrent = styled.div<{ color?: string }>`
  grid-area: current;
  min-height: 3.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing.x1};
  padding: 0 ${theme.spacing.x2};
  padding: ${theme.spacing.x1};
  border-right: 1px solid ${theme.colors.blockBorder};
  .metric {
    color: ${(props) => props.color};
  }
  .null {
    line-height: 1rem;
    height: 1rem;
  }

  @media ${theme.devices.smallDesktop} {
    border: 0;
  }
`;
const BlockGrowth = styled.div`
  grid-area: growth;
  min-height: 3.2rem;
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1};
  border-right: 1px solid ${theme.colors.blockBorder};
  @media ${theme.devices.smallDesktop} {
    display: none;
  }
`;
const ChildrenContainer = styled.div`
  border-top: 1px solid ${theme.colors.blockBorder};
`;

const BlockGutter = styled.div`
  grid-area: gutter;
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #f3f3f3;
    cursor: pointer;
  }
  .expand-gutter {
    transition: all ease 0.2s;
  }
  div.expanded {
    transform: rotate(90deg);
  }

  @media ${theme.devices.mobile} {
    display: none;
  }
`;

export const BlockContent = styled.div`
  font-weight: 400;
  grid-area: title;
  background: transparent;
  margin: 0;
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.x1};
  min-width: 0rem;

  width: 100%;

  .color-block {
    min-width: 20px;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  @media ${theme.devices.mobile} {
    padding-left: 1.2rem;
  }
`;

const BlockActions = styled.div`
  grid-area: actions;
  position: relative;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  padding: ${theme.spacing.x1};
  @media ${theme.devices.smallDesktop} {
    display: none;
  }
`;

const BlockOwners = styled.div`
  grid-area: owners;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 8rem;
  flex-direction: row-reverse;
  padding: ${theme.spacing.x1};
  border-right: 1px solid ${theme.colors.blockBorder};

  .contributors {
    margin-left: -12px;
  }

  @media ${theme.devices.smallDesktop} {
    display: none;
  }
`;

const CurrentGrowthContainer = styled.div`
  width: 5rem;
`;

const ScoreContainer = styled.div`
  .score-summary {
    border-top: 1px solid #fff;
    padding-top: ${theme.spacing.half};
    margin-top: ${theme.spacing.half};
  }

  div {
    display: flex;
    width: 100%;
    gap: ${theme.spacing.x2};
    justify-content: space-between;
    b {
    }
  }
`;

interface Props {
  outcome: Outcome;
  defaultExpanded?: boolean;
  hideClosedInitiatives?: boolean;
  hideOwners?: boolean;
  showPlan?: boolean;
  hideExpand?: boolean;
  hideTags?: boolean;
  hideActions?: boolean;
  outcomeIndex?: string;
  hasEditPermission?: boolean;
  showParentsLabel?: boolean;
  setDefaultChanged?: (value: boolean) => void;
  defaultChanged?: boolean;
}

export function MetricHeader() {
  const { t } = useTranslation();
  return (
    <Container>
      <MetricRow className="header">
        <BlockMetric>
          <header>{t('shared.terms.metric')}</header>
        </BlockMetric>
        <BlockTarget>
          <header>{t('shared.terms.target')}</header>
        </BlockTarget>
        <BlockCurrent>
          <header>{t('shared.terms.current')}</header>
        </BlockCurrent>
        <BlockGrowth>
          <header>{t('shared.terms.growth')}</header>
        </BlockGrowth>
        <BlockOwners>
          <header>{t('shared.terms.owners')}</header>
        </BlockOwners>
        <BlockActions></BlockActions>
      </MetricRow>
    </Container>
  );
}

function MetricExpandable(props: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryCache = useQueryCache();
  const params = new URLSearchParams(location.search);
  const isOnboardingRoute = params.get('onboarding') === '1';

  const {
    outcome,
    defaultExpanded,
    hideClosedInitiatives,
    hideTags,
    outcomeIndex,
    hasEditPermission,
    showParentsLabel,
    setDefaultChanged,
    defaultChanged,
  } = props;
  const { nano_slug, current_checkin, membership } = outcome;
  const { t } = useTranslation();

  const history = useHistory();
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  // Expands the first outcome if we're on the onboarding route
  //const shouldBeExpanded = (defaultExpanded ?? false) || (isOnboardingRoute && outcomeIndex === '0-0');
  const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? false);

  const hashPath = `#outcome:${nano_slug}:show`;
  const hashEditPath = `#outcome:${nano_slug}:edit`;
  const handleOutcomeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashPath);
  };

  const handleOutcomeEdit = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashEditPath);
  };

  useEffect(() => {
    // when main expand/collapse triggered it will set default changed to false
    // so if default changed is false, reset isExpanded back to default
    if (!defaultChanged) {
      setIsExpanded(defaultExpanded ?? false);
    }
  }, [defaultChanged, defaultExpanded]);

  useEffect(() => {
    const shouldBeExpanded = (defaultExpanded ?? false) || (isOnboardingRoute && outcomeIndex === '0-0');
    if (shouldBeExpanded) {
      setIsExpanded(true);
      if (setDefaultChanged) {
        setDefaultChanged(true);
      }
    }
  }, [defaultExpanded, isOnboardingRoute, outcomeIndex, setDefaultChanged]);

  const toggleExpand = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
    if (setDefaultChanged) {
      setDefaultChanged(true);
    }
  };

  const [updateOutcomeMutation] = useMutation(remoteApi.updateOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
      queryCache.invalidateQueries(queryKeys.outcomes);
    },
  });
  const handleAssignUser = (value: string) => {
    const membership_id = value;
    if (membership_id) {
      const params = {
        membership_id,
      };

      updateOutcomeMutation({
        outcomeId: outcome.nano_slug,
        outcome: params,
      });
    }
  };

  const handleUnassignUser = () => {
    const params = {
      membership_id: null,
    };
    updateOutcomeMutation({
      outcomeId: outcome.id,
      outcome: params,
    });
  };

  const handleShowCheckinForm = (e: React.MouseEvent) => {
    e.stopPropagation();
    const blockId = `outcome:${outcome.nano_slug}`;
    dispatch(setGlobalModalContent(`${blockId}:create.checkin`));
  };

  const currentConfidence = current_checkin ? current_checkin.confidence : 'grey';

  const expandedClassName = isExpanded ? 'expanded' : '';
  const hideTagsClassName = hideTags ? 'hide-tags' : '';

  const formattedScore = current_checkin
    ? checkinUtils.checkinScore(current_checkin, outcome, t)
    : outcomeUtils.startingScore(outcome, t);

  let indexClassname = outcomeIndex ? `outcome-${outcomeIndex}` : '';

  return (
    <Container className={`outcome-expanded ${indexClassname}`}>
      <MetricRow
        className={`outcome-row ${hideTagsClassName} outcome-${outcome.nano_slug}`}
        onClick={handleOutcomeClick}
        id={nano_slug}
      >
        <BlockMetric>
          <BlockGutter onClick={toggleExpand}>
            <KoalaIcon iconSize="xsmall" className={`${expandedClassName} expand-gutter`} iconName="rightChevron" />
          </BlockGutter>
          <OutcomeWeight outcome={outcome} />
          <Link to={hashPath}>{outcome.title}</Link>
          {showParentsLabel && <Parents outcomeId={outcome.id} />}
        </BlockMetric>
        <BlockTarget>
          <OutcomeTarget outcome={outcome} />
        </BlockTarget>
        <BlockCurrent
          className="outcome-row--progress"
          color={checkinUtils.confidenceToDarkColor(currentConfidence)}
          data-tip
          data-for={`progress-${outcome.nano_slug}`}
        >
          <div className="metric">{formattedScore}</div>
          {outcomeUtils.hasTarget(outcome) && (
            <>
              <KoalaProgressBar
                value={outcome.outcome_progress_prct * 100}
                appearance="secondary"
                confidence={currentConfidence}
                dataFor={`progress-${outcome.nano_slug}`}
              />
              <ReactTooltip
                place="bottom"
                type="dark"
                className="tooltip"
                effect="solid"
                id={`progress-${outcome.nano_slug}`}
              >
                <ScoreContainer>{Math.round(outcome.outcome_progress_prct * 100)}%</ScoreContainer>
              </ReactTooltip>
            </>
          )}
          {!outcomeUtils.hasTarget(outcome) && <span className="null">–</span>}
        </BlockCurrent>
        <BlockGrowth>
          <CurrentGrowthContainer>
            <CurrentGrowth outcome={outcome} showNull={true} />
          </CurrentGrowthContainer>
        </BlockGrowth>
        <BlockOwners className="outcome-row--owners">
          <KoalaStackedAvatar
            owner={membership ? membership.user : null}
            contributors={outcome.contributors}
            size="small"
            hideOwner={true}
            className="contributors"
            direction="right"
            showNumber={true}
          />
          <UserSelectButton
            canEdit={!isReadOnly && hasEditPermission === true}
            handleAssignUser={handleAssignUser}
            selectedUser={membership ? membership.user : null}
            handleUnassignUser={handleUnassignUser}
            size="small"
          />
        </BlockOwners>
        <BlockActions className="block-actions">
          <KoalaIconButton
            dataFor={`${outcome.nano_slug}-action-checkin`}
            edge="square"
            iconName="writeUpdate"
            size="small"
            onClick={handleShowCheckinForm}
            className="icon-btn--primary"
          />
          <ReactTooltip
            place="bottom"
            type="dark"
            id={`${outcome.nano_slug}-action-checkin`}
            className="tooltip"
            effect="solid"
          >
            {t('shared.createCheckin')}
          </ReactTooltip>
          {hasEditPermission && (
            <>
              <KoalaIconButton
                dataFor={`${outcome.nano_slug}-action-edit`}
                edge="square"
                iconName="settings"
                size="small"
                onClick={handleOutcomeEdit}
              />
              <ReactTooltip
                place="bottom"
                type="dark"
                id={`${outcome.nano_slug}-action-edit`}
                className="tooltip"
                effect="solid"
              >
                {t('shared.edit')}
              </ReactTooltip>
            </>
          )}
        </BlockActions>
      </MetricRow>
      {isExpanded && (
        <ChildrenContainer>
          <OutcomeChildren outcomeId={outcome.id} hideClosedInitiatives={hideClosedInitiatives ?? false} />
        </ChildrenContainer>
      )}
    </Container>
  );
}

export default MetricExpandable;
