import FormField from 'components/FormField';
import queryKeys from 'config/queryKeys';
import React from 'react';
import { useQuery } from 'react-query';
import { Objective, Plan } from 'types';

import * as remoteApi from 'api/remote';
import styled from 'styled-components';
import Select from 'react-select';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SelectionContainer = styled.div`
  display: flex;
  align-items: center;
  &.no-result :hover {
    background: unset;
  }
`;

interface Props {
  plan: Plan | undefined;
  objective: Objective | undefined;
  setObjective: (e: any) => void;
}

function ObjectivesList(props: Props) {
  const { plan, objective, setObjective } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const handleSelection = (selected: any) => {
    setObjective(selected);
  };
  // Get the objectives
  const staleTime = 0;
  const { data: objectivesResponse, isLoading: objectivesAreLoading } = useQuery(
    [queryKeys.objectives, plan?.id],
    remoteApi.fetchPlanObjectives,
    {
      staleTime,
    },
  );

  const filteredObjectives: Objective[] = objectivesResponse ? objectivesResponse.data : [];

  // format objectives
  let objectives = filteredObjectives.map((objective: Objective) => {
    return (
      <SelectionContainer data-action={objective.id}>
        <span>{objective.title}</span>
      </SelectionContainer>
    );
  });

  return (
    <FormField>
      <label>{translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}</label>
      {objectives.length === 0 && (
        <div>
          {t('modals.moveOutcome.noPlan', {
            label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 2).toLowerCase(),
          })}
        </div>
      )}
      {objectives.length > 0 && (
        <Select
          defaultOptions
          placeholder={t('modals.moveOutcome.searchObjective', {
            label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 2),
          })}
          value={objective ? objective : null}
          options={filteredObjectives}
          isLoading={objectivesAreLoading}
          onChange={(option) => handleSelection(option)}
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.id}
        />
      )}
    </FormField>
  );
}

export default ObjectivesList;
