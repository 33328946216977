import React from 'react';

const ArrowLeftIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 10.25C1 9.69772 1.44772 9.25 2 9.25L18 9.25C18.5523 9.25 19 9.69772 19 10.25C19 10.8023 18.5523 11.25 18 11.25L2 11.25C1.44772 11.25 1 10.8023 1 10.25Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.29289 10.9571C0.902369 10.5666 0.902369 9.93342 1.29289 9.54289L9.29289 1.54289C9.68342 1.15237 10.3166 1.15237 10.7071 1.54289C11.0976 1.93342 11.0976 2.56658 10.7071 2.95711L3.41421 10.25L10.7071 17.5429C11.0976 17.9334 11.0976 18.5666 10.7071 18.9571C10.3166 19.3476 9.68342 19.3476 9.29289 18.9571L1.29289 10.9571Z"
      fill="#737373"
    />
  </svg>
);

export default ArrowLeftIcon;
