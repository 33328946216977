import React, { useState } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import * as routes from 'routes';
import styled from 'styled-components';
import theme from 'theme';
import { Objective, Outcome, Plan } from 'types';

import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import Loader from 'components/Loader';
import PlanIconLabel from 'components/PlanIconLabel';

// API
import * as remoteApi from 'api/remote';
import PlanStatus from 'components/PlanStatus';
import KoalaIcon from 'koala/components/Icons';
import OutcomeBlockCompact from './OutcomeBlockCompact';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const Container = styled.div`
  max-width: 100%;
  width: 100%;
  background: #fff;
  overflow: hidden;
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  font-size: 1.4rem;
  position: relative;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.expired {
    opacity: 0.6;
  }

  &:hover {
    opacity: 1;
  }
`;
const PlanTitle = styled.div`
  font-weight: 600;
  padding: 4px;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  gap: ${theme.spacing.x1};
  .superlocked {
    path {
      fill: ${theme.colors.orange};
    }
  }
`;

const ObjectiveTitle = styled.div`
  padding: 4px 0;
  font-size: 1.2rem;
  font-weight: 600;
`;

const PlanHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: ${theme.spacing.x1};
  border-bottom: 1px solid #d1d1d1;

  &:hover {
    background: ${theme.colors.N3};
  }
`;

const PlanContent = styled.div`
  max-height: 25rem;
  overflow: auto;
`;
const PlanPrivate = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

const ObjectiveHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${theme.spacing.x1} ${theme.spacing.x1} 0 ${theme.spacing.x1};
`;

const ObjectiveContent = styled.div`
  margin-bottom: ${theme.spacing.x2};
  .progress-bar {
    margin: 0 ${theme.spacing.x1};
    width: auto;
  }
  .subtle {
    margin: ${theme.spacing.x1} ${theme.spacing.x2};
  }
`;

const TextContainer = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

const LoadingContainer = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

interface Props {
  plan: Plan;
}

function PlanOverviewNav(props: Props) {
  const { plan } = props;
  const planId = plan.id;
  const location = useLocation();
  const history = useHistory();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [outcomesByObjective, setOutcomesByObjective] = useState<{ [key: string]: Outcome[] }>({});
  const [isPrivate, setIsPrivate] = useState(false);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Load the data for the nav
  // Get the objectives
  const { isLoading: objectivesAreLoading } = useQuery([queryKeys.objectives, planId], remoteApi.fetchPlanObjectives, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const objectives: Objective[] = response.data;
      setObjectives(objectives);
    },
    onError: (err: any) => {
      if (err.response.status === 401) {
        setIsPrivate(true);
      }
    },
  });

  // Get the outcomes
  const { isLoading: outcomesAreLoading } = useQuery([queryKeys.outcomes, planId], remoteApi.fetchPlanOutcomes, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const outcomes: Outcome[] = response.data;
      const outcomesByObjective: { [key: string]: Outcome[] } = {};
      // Iterate on each outcome and group items by objective_id in an array
      outcomes.forEach((outcome) => {
        if (outcomesByObjective[outcome.objective_id]) {
          outcomesByObjective[outcome.objective_id].push(outcome);
        } else {
          outcomesByObjective[outcome.objective_id] = [outcome];
        }
      });
      setOutcomesByObjective(outcomesByObjective);
    },
  });

  // Display a loading container while we wait
  if (objectivesAreLoading || outcomesAreLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  // Past this point, we have objectives and outcomes.
  const routeTarget =
    plan.state === 'published' ? routes.WORKSPACE_PLAN_TRACK_ROUTE : routes.WORKSPACE_PLAN_WRITE_ROUTE;
  const planRoute = routeTarget.replace(':workspaceSlug', workspaceSlug).replace(':planId', plan.nano_slug);
  const isLoading = outcomesAreLoading || objectivesAreLoading;

  const planLocked = plan.global_permission_type !== 'edit';
  const superlockedClassName = plan.global_permission_type === 'none' ? 'superlocked' : '';

  const isBlockSelected = (blockId: string) => {
    return location.hash.includes(blockId);
  };

  const handleOutcomeClick = (e: React.MouseEvent<HTMLDivElement>, outcome: Outcome) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`#outcome:${outcome.nano_slug}:show`);
  };

  // Show private
  if (isPrivate) {
    return (
      <Container>
        <PlanHeader>
          <PlanTitle>
            <Link to={planRoute}>
              <PlanIconLabel plan={plan} size="xsmall" />
            </Link>
            {planLocked && <KoalaIcon iconName="locked" iconSize="small" className={superlockedClassName} />}
          </PlanTitle>
          <PlanStatus plan={plan} size="small" />
        </PlanHeader>
        <PlanPrivate>
          <p>{t('workspacePlan.write.noAccess')}</p>
        </PlanPrivate>
      </Container>
    );
  }

  return (
    <Container>
      <PlanHeader>
        <PlanTitle>
          <Link to={planRoute}>
            <PlanIconLabel plan={plan} size="xsmall" />
          </Link>
          {planLocked && <KoalaIcon iconName="locked" iconSize="small" className={superlockedClassName} />}
        </PlanTitle>
        <PlanStatus plan={plan} size="small" />
      </PlanHeader>
      <PlanContent>
        {isLoading && (
          <TextContainer>
            <Loader />
          </TextContainer>
        )}
        {objectives.length === 0 && (
          <TextContainer>
            <p className="subtle">{t('workspacePlan.empty')}</p>
          </TextContainer>
        )}
        {objectives.map((objective) => {
          const outcomesToDisplay = outcomesByObjective[objective.id] || [];

          return (
            <ObjectiveContent key={objective.id}>
              <ObjectiveHeader>
                <ObjectiveTitle className="title">{objective.title}</ObjectiveTitle>
              </ObjectiveHeader>

              <>
                {outcomesToDisplay.length === 0 && (
                  <div className="subtle">
                    {t(`workspacePlan.objectiveEmpty`, {
                      objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
                      outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                    })}
                  </div>
                )}
                {outcomesToDisplay.map((outcome) => (
                  <OutcomeBlockCompact
                    outcome={outcome}
                    key={outcome.id}
                    isSelected={isBlockSelected(`outcome:${outcome.nano_slug}`)}
                    handleClick={(e) => handleOutcomeClick(e, outcome)}
                  />
                ))}
              </>
            </ObjectiveContent>
          );
        })}
      </PlanContent>
    </Container>
  );
}
export default PlanOverviewNav;
