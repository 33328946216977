import React, { Fragment, useState, useEffect } from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useMutation, useQuery, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';

// Components
import Loader from 'components/Loader';
import { ChromeContent } from 'components/Chrome';
import ContentNarrowWrapper from 'components/ContentNarrowWrapper';
import UpgradePanel from 'components/UpgradePanel';

// import ActiveInitiativesList from './ActiveInitiativesList';
import ActiveOutcomesList from './ActiveOutcomesList';
import PendingCheckinsList from './PendingCheckinsList';

import DropdownMenu from 'components/DropdownMenu';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaAvatar from 'koala/components/Avatar';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import TeamList from './TeamList';
import FollowButton from './FollowButton';
import ReportToSelection from './ReportToSelection';
import { useTranslation } from 'react-i18next';
import ActiveInitiativesList from './ActiveInitiativesList';
import ShareButton from 'components/ShareButton';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding: ${theme.spacing.x4};
`;

const HeadingContainer = styled.div`
  padding: 1.2rem;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0px;
`;

const InboxPanel = styled.div`
  margin-bottom: ${theme.spacing.x2};
  background: #ffffff;
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  border-radius: 4px;
  p {
    margin: 1.2rem ${theme.spacing.x2};
  }
  &.with-padding {
    padding: 1.2rem;
  }
`;

const LargeEmptyState = styled.div`
  background: #fff;
  padding: ${theme.spacing.x4};
  border-radius: 8px;
  border: 1px solid ${theme.colors.blockBorder};

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  div {
    margin-right: ${theme.spacing.x1};
  }

  svg {
    margin-left: ${theme.spacing.x1};
  }
`;

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 60vh;

  @media ${theme.devices.laptop} {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  width: 100%;
  flex: 3;
  min-width: 40rem;

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }

  @media ${theme.devices.tablet} {
    min-width: unset;
  }
`;

const RightColumn = styled.div`
  margin-left: ${theme.spacing.x2};
  flex: 2;
  min-width: 30rem;
  max-width: 30rem;

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }

  @media ${theme.devices.laptop} {
    max-width: unset;
    margin-left: 0;
  }
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

function Inbox() {
  const { workspaceSlug, membershipId } = useParams<{ workspaceSlug: string; membershipId: string }>();
  const queryKey = [queryKeys.memberships, membershipId];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  const [checkinsLoading, setCheckinsLoading] = useState(false);
  const [initiativesLoading] = useState(false);
  const [outcomesLoading, setOutcomesLoading] = useState(false);
  const [membership, setMembership] = useState<TabilityTypes.Membership | null>(null);

  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const queryCache = useQueryCache();
  const history = useHistory();

  useQuery(queryKey, remoteApi.fetchMembershipDetails, {
    staleTime: 0,
    onSuccess: (response) => {
      const membership = response.data;
      setMembership(membership);
    },
  });

  const [deleteMembershipMutation, { isLoading: isRemovingMembership }]: [any, any] = useMutation(
    remoteApi.deleteMembership,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
        history.push(`/${workspaceSlug}/people`);
      },
    },
  );

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.profile.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Display loader if fetching
  if (!membership) {
    return (
      <Fragment>
        <MobileReadyChromeHeader>
          <WorkspaceHeader workspace={currentWorkspace} useGreyBackground={true} />
        </MobileReadyChromeHeader>
        <ChromeContent isGreyBackground>
          <ContentNarrowWrapper>
            <Loader size="big" />
          </ContentNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'remove-user':
        handleRemoveFromWorkspace();
        break;
    }
  };

  const handleRemoveFromWorkspace = () => {
    if (window.confirm(t('workspaceProfile.confirmRemove') ?? 'Are you sure you want to remove this user?')) {
      deleteMembershipMutation(membership.id);
    }
  };

  const handleShare = () => {
    dispatch(setGlobalModalContent('workspace:filters:share'));
  };

  const link = `https://${process.env.REACT_APP_DOMAIN}${location.pathname}`;

  const inboxLoading = checkinsLoading || initiativesLoading || outcomesLoading || isRemovingMembership;

  const { user } = membership;
  const isAdmin = ['admin', 'owner'].includes(currentMembership.role);

  // Create the menu
  const menuItems = [<span data-action="remove-user">{t('workspaceProfile.remove')}</span>];

  const showTeamList =
    workspaceUtils.hasV4PlusSubscription(currentWorkspace) || workspaceUtils.hasPremiumSubscription(currentWorkspace);

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspaceProfile.title', { user: user.name })} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader>
        <WorkspaceHeader
          workspace={currentWorkspace}
          useGreyBackground={true}
          title={
            <ProfileHeader>
              <Title>
                <KoalaAvatar user={user} size={4} />
                {t('workspaceProfile.title', { user: user.name })}
              </Title>
              <FollowButton />
              <ShareButton id={membership.id} link={link} shareCallback={handleShare} size="small" />
              {isAdmin && hasEssentialsSubscription && (
                <DropdownMenu
                  trigger={<KoalaIconButton iconName="ellipsis" />}
                  onSelection={handleMenuSelection}
                  items={menuItems}
                />
              )}
              {inboxLoading && <Loader />}
            </ProfileHeader>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeContent className="grey-bg">
        <ContentWrapper>
          <Content>
            {!hasEssentialsSubscription && (
              <Mask>
                <LargeEmptyState>
                  <h3>{t('workspaceProfile.notAvailable')}</h3>
                  <UpgradePanel
                    title={t('shared.upgrade.upgradeWorkspace') ?? 'Upgrade your workspace'}
                    description={
                      t('workspaceProfile.upgradeInfo') ??
                      'Upgrade to the Essentials subscription or above to enable people dashboards.'
                    }
                  />
                </LargeEmptyState>
              </Mask>
            )}
            <LeftColumn>
              <InboxPanel>
                <HeadingContainer>
                  {t('workspaceProfile.outcomes', {
                    outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                  })}
                </HeadingContainer>
                {membership && (
                  <>
                    <PendingCheckinsList
                      membership={membership}
                      setCheckinsLoading={setCheckinsLoading}
                      isContributing={false}
                    />
                    <ActiveOutcomesList membership={membership} setOutcomesLoading={setOutcomesLoading} />
                    <PendingCheckinsList
                      membership={membership}
                      setCheckinsLoading={setCheckinsLoading}
                      isContributing={true}
                    />
                  </>
                )}
              </InboxPanel>

              {membership && (
                <InboxPanel>
                  <HeadingContainer>
                    {t(`workspacePeople.initiatives`, {
                      initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                    })}
                  </HeadingContainer>
                  <ActiveInitiativesList membership={membership} showContributingInitiatives={false} />
                  <ActiveInitiativesList membership={membership} showContributingInitiatives={true} />
                </InboxPanel>
              )}
            </LeftColumn>
            <RightColumn>
              <InboxPanel className="with-padding">
                {membership && <ReportToSelection membershipId={membershipId} />}
                {showTeamList && <TeamList membership={membership} />}
              </InboxPanel>
            </RightColumn>
          </Content>
        </ContentWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default Inbox;
