import React from 'react';
import InitiativeRow from 'components/InitiativeRow';
import styled from 'styled-components';
import theme from 'theme';
import { midString } from 'state/reducers/utils';

import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useSelector, shallowEqual } from 'react-redux';
import * as membershipUtils from 'utils/membershipUtils';
// API
import * as remoteApi from 'api/remote';

import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import { Initiative, OutcomeRelationship } from 'types';
import AddTask from './AddTask';

const InitiativeContainer = styled.li<{ isDragging?: boolean }>`
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'title';
  align-items: center;
  display: list-item;
  list-style-type: circle;
  background: ${theme.colors.N0};
  .initiative-row {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
  }

  &:hover {
    background: ${theme.colors.N3};
  }
`;

interface Props {
  outcomeId: string;
  initiatives: Initiative[];
  hideClosedInitiatives: boolean;
  parents: OutcomeRelationship[];
}

function Initiatives(props: Props) {
  const { outcomeId, initiatives, hideClosedInitiatives, parents } = props;
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  const queryCache = useQueryCache();

  const droppableId = `expandInitiatives-${outcomeId}`;
  const lastInitiative = initiatives[initiatives.length - 1];

  const queryKeyInitiatives = [queryKeys.initiatives, outcomeId];

  const [updateInitiativeMutation]: [any, any] = useMutation(remoteApi.updateInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeyInitiatives);
    },
  });

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const initiativeId = result.draggableId;
    let rank = '';
    let selectedInitiatve = initiatives[result.source.index];

    if (result.source.index === result.destination.index) {
      return;
    }

    // Going backwards
    if (result.source.index > result.destination.index) {
      const index = result.destination.index;

      const currentItem = initiatives[index];
      let currentItemRank = currentItem && currentItem.rank ? currentItem.rank : '';

      // Then get the previous item
      const previousItem = initiatives[index - 1];
      let previousItemRank = previousItem && previousItem.rank ? previousItem.rank : '';

      rank = midString(previousItemRank, currentItemRank);
    }

    // Going forward
    if (result.source.index < result.destination.index) {
      const index = result.destination.index;

      const currentItem = initiatives[index];
      let currentItemRank = currentItem && currentItem.rank ? currentItem.rank : '';

      // Then get the next item
      const nextItem = initiatives[index + 1];
      let nextItemRank = nextItem && nextItem.rank ? nextItem.rank : '';

      rank = midString(currentItemRank, nextItemRank);
    }

    let initiativeParams = {
      rank,
    };

    if (selectedInitiatve) {
      selectedInitiatve.rank = rank;
      // Reorder the initiatives by rank
      initiatives.sort((a, b) => {
        return a.rank.localeCompare(b.rank);
      });
    }

    updateInitiativeMutation({
      initiativeId: initiativeId,
      initiative: initiativeParams,
    });

    return;
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {initiatives.map((initiative, index) => {
              if (hideClosedInitiatives && initiative.closed_at) {
                return <></>;
              }
              return (
                <Draggable draggableId={initiative.id} index={index} key={initiative.id} isDragDisabled={isReadOnly}>
                  {(provided, snapshot) => {
                    return (
                      <InitiativeContainer
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        className="initiative tree-item"
                        key={initiative.id}
                      >
                        <InitiativeRow initiative={initiative} showMeta={true} />
                      </InitiativeContainer>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
            {!isReadOnly && <AddTask lastInitiative={lastInitiative} outcomeId={outcomeId} parents={parents} />}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default Initiatives;
