import React, { Fragment, useState } from 'react';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Routes
import * as routes from 'routes';

// Components
import FormField from 'components/FormField';
import Toggle from 'components/Toggle';
import { Link, useParams } from 'react-router-dom';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import CopyTextInput from 'components/CopyTextInput';
import 'theme/DatePicker.css';
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { useTranslation } from 'react-i18next';

const PresetsBlock = styled.div`
  margin-bottom: 3.2rem;
  button {
    margin-right: 1.6rem;
    &:last-of-type {
      margin: 0;
    }
  }

  small.danger {
    display: block;
    margin-top: ${theme.spacing.x1};
    color: red;
  }
`;

const BackLink = styled(Link)`
  color: ${theme.colors.blue};
  margin-bottom: ${theme.spacing.x2};
  font-size: 1.8rem;
  font-weight: 600;
`;

const Form = styled.form`
  h2 {
    margin-bottom: ${theme.spacing.x3};
  }
  h3 {
    margin-bottom: ${theme.spacing.x2};
  }

  margin-bottom: ${theme.spacing.x6};
  button {
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const UpgradeBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
  background: rgba(197, 165, 239, 0.25);
  border-radius: 4px;

  max-width: 60rem;
  margin: ${theme.spacing.x3} auto;

  p {
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.x2};
`;

interface Props {
  plan: TabilityTypes.Plan;
}

function Settings(props: Props) {
  const queryCache = useQueryCache();
  const { plan } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const [public_access_enabled, setPublicAccessEnabled] = useState(plan.public_access_enabled.toString());
  const [update_password, setUpdatePassword] = useState(false);
  const [public_access_password, setPublicAccessPassword] = useState('');

  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const hasStarterSubscription =
    workspaceUtils.hasStarterSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;
  const v4_5_RequireUpgrade =
    [4, 5].includes(currentWorkspace.pricing_version) && !workspaceUtils.hasBasicSubscription(currentWorkspace);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, plan.nano_slug];

  // Mutation that will update the plan in the backend
  const [updatePlanMutation, { isLoading, error, isError }]: [any, any] = useMutation(remoteApi.updatePlan, {
    onSuccess: (planResponse) => {
      queryCache.setQueryData(queryKey, planResponse);
    },
  });

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('error.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'public_access_enabled':
        setPublicAccessEnabled(value);
        break;
      case 'public_access_password':
        setPublicAccessPassword(value);
        break;
      case 'update_password':
        setUpdatePassword(value);
        break;
    }
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    let planParams: any = {
      public_access_enabled,
    };

    if (update_password) {
      planParams = {
        ...planParams,
        public_access_password,
      };
    }
    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  const publicAccessPath = routes.PUBLIC_PLAN_PREZ_ROOT_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    plan.nano_slug,
  );
  const publicAccessLink = `https://${process.env.REACT_APP_DOMAIN}${publicAccessPath}`;

  const passwordClassname = errors['public_access_password'] ? 'error' : '';

  // Displays the list of templates
  return (
    <SettingsNarrowWrapper>
      <BackLink to={`/${workspaceSlug}/plans/${plan.nano_slug}/outcomes`}>
        &larr; {t('planSettings.back', { title: plan.title })}
      </BackLink>
      <Form onSubmit={handleSave}>
        <h2>{t('planSettings.sharing')}</h2>
        {v4_5_RequireUpgrade && (
          <UpgradeBanner>
            <p>
              <UpgradeIcon />
              <b>{t('planSettings.upgradePaidSubscription')}</b>
            </p>
            <p>{t('planSettings.upgradePaidSubscriptionInfo')}</p>
            {currentMembership && ['owner'].includes(currentMembership.role) && (
              <p>
                <KoalaButton isLink to={`/${workspaceSlug}/settings/billing`}>
                  {t('shared.upgrade.upgradeButton')}
                </KoalaButton>
              </p>
            )}
            {currentMembership && !['owner'].includes(currentMembership.role) && (
              <p>
                <KoalaTextButton href="https://tability.io/pricing" target="_blank" rel="noreferrer">
                  {t('shared.upgrade.learnMore')}
                </KoalaTextButton>
              </p>
            )}
          </UpgradeBanner>
        )}
        {!hasStarterSubscription && (
          <UpgradeBanner>
            <p>
              <UpgradeIcon />
              <b>{t('planSettings.upgradeStarter')}</b>
            </p>
            <p>{t('planSettings.upgradeStarterInfo')}</p>
            {currentMembership && ['owner'].includes(currentMembership.role) && (
              <p>
                <KoalaButton isLink to={`/${workspaceSlug}/settings/billing`}>
                  {t('shared.upgrade.startTrial')}
                </KoalaButton>
              </p>
            )}
            {currentMembership && !['owner'].includes(currentMembership.role) && (
              <p>
                <KoalaTextButton href="https://tability.io/pricing" target="_blank" rel="noreferrer">
                  {t('shared.upgrade.learnMore')}
                </KoalaTextButton>
              </p>
            )}
          </UpgradeBanner>
        )}

        <FormField>
          <label>{t('planSettings.enablePublic')}</label>
          <PresetsBlock>
            <select
              value={public_access_enabled}
              onChange={handleChange}
              name="public_access_enabled"
              disabled={!hasStarterSubscription || v4_5_RequireUpgrade}
            >
              <option value={'true'}>{t('shared.enabled')}</option>
              <option value={'false'}>{t('shared.disabled')}</option>
            </select>
          </PresetsBlock>
        </FormField>
        <FormField>
          {public_access_enabled === 'true' && (
            <Fragment>
              {plan.is_password_protected && (
                <div>
                  <p>
                    <span role="img" aria-label="locked">
                      🔒
                    </span>{' '}
                    {t('planSettings.passwordEnabled')}
                  </p>
                  <p>{t('planSettings.removePassword')}</p>
                </div>
              )}
              {!plan.is_password_protected && (
                <div>
                  <p>
                    <span role="img" aria-label="unlocked">
                      🌍
                    </span>{' '}
                    {t('planSettings.public')}
                  </p>
                  <p>{t('planSettings.addPassword')}</p>
                </div>
              )}
            </Fragment>
          )}
          <ToggleContainer>
            <Toggle>
              <input
                className="tgl tgl-light"
                id="update_password"
                type="checkbox"
                name="update_password"
                onChange={handleChange}
                disabled={!hasStarterSubscription && v4_5_RequireUpgrade}
              />
              <label className="tgl-btn" htmlFor="update_password" />
              <div>{t('planSettings.changePassword')}</div>
            </Toggle>
          </ToggleContainer>
        </FormField>
        {update_password && (
          <FormField>
            <label>{t('planSettings.password')}</label>
            <PresetsBlock>
              <input
                placeholder={t('planSettings.passwordPlaceholder') ?? 'Leave blank to remove the password protection'}
                type="password"
                onChange={handleChange}
                name="public_access_password"
                className={`${passwordClassname} small`}
              />
              {errors['public_access_password'] && (
                <small className="danger">
                  {t('planSettings.passwordError', { error: errors['public_access_password'] })}
                </small>
              )}
            </PresetsBlock>
          </FormField>
        )}
        {public_access_enabled === 'true' && (
          <FormField>
            <label>{t('planSettings.publicLink')}</label>
            <p className="light">{t('planSettings.publicLinkInfo')}</p>
            <CopyTextInput text={publicAccessLink} />
          </FormField>
        )}
        <Actions>
          <KoalaButton
            submit
            disabled={isLoading || !hasStarterSubscription || v4_5_RequireUpgrade}
            loading={isLoading}
          >
            {t('shared.save')}
          </KoalaButton>
        </Actions>
      </Form>
    </SettingsNarrowWrapper>
  );
}

export default Settings;
