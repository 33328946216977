import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
//import * as TabilityTypes from 'types';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import * as outcomeUtils from 'utils/outcomeUtils';
import * as planUtils from 'utils/planUtils';
import * as checkinUtils from 'utils/checkinUtils';
import * as amplitudeUtils from 'utils/amplitude';
import Cookies from 'universal-cookie';

// API
import * as remoteApi from 'api/remote';

import { PanelGrid, PanelHeader, PanelContent } from 'components/GlobalPanel';

import CheckinBlock from './CheckinBlock';

import Loader from 'components/Loader';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import KoalaConfidenceText from 'koala/components/ConfidenceText';
import CheckinsChart from 'components/Chart';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useTranslation } from 'react-i18next';

const ProgressBarContainer = styled.div`
  height: 1rem;
  width: 100%;
  background: ${theme.colors.blockBorder};
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ prct: number; color: string }>`
  height: 100%;
  background-color: ${(props) => checkinUtils.confidenceToColor(props.color)};
  border-radius: 4px;
  width: ${(props) => props.prct}%;
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ConfidenceAndTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  h2 {
    margin-left: ${theme.spacing.x2};
    flex: 1;
  }
`;

const ProgressBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.x3};
  > div {
    margin-right: ${theme.spacing.x2};
  }
`;

const CurrentCheckinContainer = styled.div`
  background: #fff;
`;

const More = styled.div`
  margin: ${theme.spacing.x2} 0;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

function OutcomeBlock() {
  const cookies = new Cookies();
  const location = useLocation();
  const hash = location.hash;
  const [, outcomeId] = hash.split(':');
  const history = useHistory();
  const { planId, workspaceSlug } = useParams<{ planId: string; workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const workspaceUrl = `/${workspaceSlug}`;
  const planUrl = `${workspaceUrl}/plans/${planId}/outcomes`;

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('public.outcome.view', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.push(location.pathname);
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(location.pathname);
  };

  // Get the checkins
  const COOKIENAME = '_tbty_pp';
  const public_access_password = cookies.get(COOKIENAME);

  const params = {
    public_access_password,
  };

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentOutcome, outcomeId, params];
  const staleTime = queryKeys.staleTime;

  // Get the outcome details
  const { data: outcomeResponse } = useQuery(queryKey, remoteApi.fetchPublicOutcomeDetails, {
    staleTime,
  });

  const outcome = outcomeResponse ? outcomeResponse.data : null;

  // Construct the query key using the plan Id
  const queryKeyCheckins = [queryKeys.checkins, outcomeId, params];

  // Get the plan details
  const { data: checkinsResponse } = useQuery(queryKeyCheckins, remoteApi.fetchPublicCheckins, {
    staleTime,
  });

  let checkins = [];
  if (checkinsResponse) {
    checkins = checkinsResponse.data;
  }

  // Now we can display the app with the Chrome
  if (!outcome) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  const { plan } = outcome;

  const currentCheckin = checkins ? checkins[0] : null;

  const currentColor = currentCheckin ? currentCheckin.confidence : null;

  const hasTarget = outcomeUtils.hasTarget(outcome);

  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <ConfidenceAndTitle>
            <KoalaConfidenceText
              isCompleted={outcome.completed}
              value={hasTarget ? outcome.outcome_progress_prct * 100 : null}
              confidence={currentCheckin ? currentCheckin.confidence : 'grey'}
            />
            <h2>{outcome.title}</h2>
            {outcome.archived && (
              <KoalaTextBadge variant="violet-light" isLowercase>
                {t('shared.status.archived')}
              </KoalaTextBadge>
            )}

            <KoalaIconButton onClick={handleCancel} iconName="rightArrow" />
          </ConfidenceAndTitle>
        </Header>
      </PanelHeader>
      <PanelContent>
        {planUtils.hasTimeline(plan) && <CheckinsChart outcome={outcome} checkins={checkins} />}
        {outcomeUtils.hasTarget(outcome) && (
          <ProgressBlock>
            <div className="prct">{Math.round(outcome.outcome_progress_prct * 100)}%</div>
            <ProgressBarContainer>
              <ProgressBar prct={outcome.outcome_progress_prct * 100} color={currentColor} />
            </ProgressBarContainer>
          </ProgressBlock>
        )}
        <CurrentCheckinContainer>
          {currentCheckin && <CheckinBlock checkin={currentCheckin} outcome={outcome} />}
        </CurrentCheckinContainer>
        <More>
          <Link to={`${planUrl}#outcome:${outcome.nano_slug}:show`}>
            {t('publicPlan.seeAll', {
              initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
            })}
          </Link>
        </More>
      </PanelContent>
    </PanelGrid>
  );
}

export default OutcomeBlock;
