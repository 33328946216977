import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Base64 } from 'js-base64';

// API
import * as remoteApi from 'api/remote';

// Components
import GoogleIcon from 'components/_assets/google-button-icon.png';

import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import ContentTitle from 'components/ContentTitle';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import Loader from 'components/Loader';
import MembershipSettingsNav from 'components/MembershipSettingsNav';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Trans, useTranslation } from 'react-i18next';

const IntegrationBlock = styled.div`
  display: grid;
  grid-template-rows: auto;
  column-gap: ${theme.spacing.x3};
  grid-template-columns: 3rem 1fr 24rem;
  grid-template-areas: 'side content action';
  margin: ${theme.spacing.x2} 0;
  position: relative;
  overflow: hidden;
  border: 1px solid ${theme.colors.blockBorder};
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-radius: 8px;

  h3 {
    font-size: 1.6rem;
  }

  &:hover {
    background: #f3f3f3;
  }

  a {
    color: ${theme.colors.blue};
  }

  @media ${theme.devices.laptop} {
    grid-template-columns: 6rem 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'side content' '. action';
  }
  @media ${theme.devices.mobile} {
    grid-template-columns: 3rem 1fr;
  }
`;

const IntegrationLogo = styled.div`
  display: flex;
  grid-area: side;
  align-items: center;

  img {
    width: 100%;
    display: block;
    margin: ${theme.spacing.x2} 0;
  }
  @media ${theme.devices.mobile} {
    align-items: flex-start;
  }
`;

const IntegrationContent = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing.half};

  h3 {
    display: flex;
    align-items: center;
    svg {
      margin-right: ${theme.spacing.x1};
    }
  }

  p + p {
    margin: 0;
  }
`;

const IntegrationAction = styled.div`
  grid-area: action;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    display: flex;
    align-items: center;
    svg {
      margin-right: ${theme.spacing.x1};
    }
  }

  @media ${theme.devices.laptop} {
    justify-content: flex-start;
    margin-top: ${theme.spacing.x2};
  }
`;

const ActionBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.half};
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

const staleTime = 0;

function WorkspaceSettingsDetails(props: Props) {
  const { workspace } = props;
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];
  const { t } = useTranslation();

  const [integrations, setIntegrations]: any = useState([]);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  const { isFetching: isInitialFetch }: any = useQuery(queryKey, remoteApi.fetchIntegrations, {
    staleTime,
    onSuccess: (response: any) => {
      setIntegrations(response.data);
    },
  });

  const [uninstallGoogleMutation, { isLoading: isUninstallingGoogle }]: [any, any] = useMutation(
    remoteApi.uninstallGoogle,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
      },
    },
  );

  const handleUninstallGoogle = (e: any) => {
    e.preventDefault();
    uninstallGoogleMutation(workspace.slug);
  };

  // tabilityRedirect is the redirection to use after the app is installed
  const tabilityRedirect = `${workspace.url}/account/integrations`;
  const oauthState = {
    redirect_after: tabilityRedirect,
    slug: workspace.slug,
    membership_id: currentMembership.id,
  };

  const state = Base64.encode(JSON.stringify(oauthState));
  // Extract the Jira integration
  const googleIntegration = integrations.filter((i: TabilityTypes.Integration) => i.integration_type === 'google')[0];

  // Creating the OAuth URL for Google
  const googleClienttID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
  // const googleScope =
  //   'email profile https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/bigquery.readonly https://www.googleapis.com/auth/tasks.readonly';
  const googleScope =
    'email profile https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/bigquery';
  const googleRedirectURL = `https://${process.env.REACT_APP_DOMAIN || ''}/install/google`;
  const googleOauthURL =
    'https://accounts.google.com/o/oauth2/v2/auth' +
    `?scope=${googleScope}` +
    `&access_type=offline` +
    `&client_id=${googleClienttID}` +
    `&response_type=code` +
    `&prompt=consent` +
    `&state=${state}` +
    `&redirect_uri=${encodeURIComponent(googleRedirectURL)}`;

  if (isInitialFetch) {
    return (
      <Fragment>
        <ChromeSideNav>
          <MembershipSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <ContentTitle>
              <h1>{t('workspaceSettingsIntegrations.title')}</h1>
            </ContentTitle>
            <Loader size="medium" />
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  const uninstallGoogleLabel = isUninstallingGoogle ? <Loader /> : t('workspaceSettingsIntegrations.remove');

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsIntegrations.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <MembershipSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsIntegrations.title')}</h1>
          </ContentTitle>
          <IntegrationBlock>
            <IntegrationLogo>
              <img src={GoogleIcon} alt="Google" />
            </IntegrationLogo>
            <IntegrationContent>
              <h3>
                {t('workspaceSettingsIntegrations.googleHeader', {
                  outcomes: translate(workspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                })}
              </h3>
              <p>{t('workspaceSettingsIntegrations.googleInfo')}</p>
              <p>
                <a
                  href="https://guides.tability.app/docs/integrations/google-integrations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('workspaceSettingsIntegrations.integrationLink')}
                </a>
              </p>
            </IntegrationContent>
            <IntegrationAction>
              {googleIntegration && (
                <ActionBlock>
                  <div>
                    <Trans
                      i18nKey="workspaceSettingsIntegrations.connected"
                      components={{ b: <b /> }}
                      values={{ value: googleIntegration.connected_to }}
                    />
                  </div>
                  <KoalaButton href={googleOauthURL} appearance="subtle">
                    {t('workspaceSettingsIntegrations.reinstall')}
                  </KoalaButton>
                  <KoalaTextButton onClick={handleUninstallGoogle}>{uninstallGoogleLabel}</KoalaTextButton>
                </ActionBlock>
              )}
              {!googleIntegration && (
                <KoalaButton href={googleOauthURL}>
                  {t('workspaceSettingsIntegrations.connectTo', { integration: 'Google' })}
                </KoalaButton>
              )}
            </IntegrationAction>
          </IntegrationBlock>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
