import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import { DashboardWidget, Membership, Segment, Workspace } from 'types';
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import { DragHandle, SourceTitle, WidgetContainer, WidgetHeader } from '..';
import { Link, useHistory } from 'react-router-dom';
import * as filterUtils from 'utils/filterUtils';
import { Base64 } from 'js-base64';
import KoalaLoader from 'koala/components/Loader';
import KoalaIcon from 'koala/components/Icons';
import { WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS } from 'routes';
import { StatsResult } from 'pages/WorkspaceOutcomes';
import * as routes from 'routes';
import { AxiosResponse } from 'axios';
import DistributionGraph from './DistributionGraph';

const OutcomeContainer = styled.div`
  padding: ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  background: #fff;
  max-height: 100%;
  flex: 1;
  justify-content: start;

  .item:last-of-type {
    border: none;
  }
  .outcome-expanded {
    border-bottom: 1px solid ${theme.colors.N10};
  }
`;

const GraphContainer = styled.div`
  max-height: 100%;
  flex: 1;
`;

interface Props {
  widget: DashboardWidget;
}

function OutcomeDistributionWidget(props: Props) {
  const { widget } = props;
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  const [outcomesStats, setOutcomesStats] = useState<StatsResult | null>(null);

  const segment: Segment = widget.source ? widget.source : null;
  const filter = segment ? segment.filter_hash : null;

  const filterPath = segment.id
    ? routes.WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS.replace(':segmentId', segment.id)
    : routes.WORKSPACE_OUTCOMES_ROUTE_WITH_FILTERS;
  const history = useHistory();
  const workspaceSlug = workspace.slug;

  const customFilterHash = filter ? JSON.parse(Base64.decode(filter)) : {};

  const filterParams = filterUtils.getFilterHash(customFilterHash, currentMembership);

  const widgetTitle: string = widget.title
    ? widget.title
    : t('workspaceDashboards.outcomeStats', {
        outcome: translate(workspace, CustomTermKey.OUTCOME, 1),
      });

  // Get the outcome stats
  const queryKeyStats = [
    queryKeys.outcomes,
    'stats',
    {
      workspaceSlug: workspace.slug,
      filter: filterParams,
    },
  ];
  const { isLoading: isLoadingStats } = useQuery(queryKeyStats, remoteApi.fetchOutcomesStats, {
    onSuccess: (response: AxiosResponse<StatsResult>) => {
      setOutcomesStats(response.data);
    },
  });

  const segmentRoute = WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspace.slug)
    .replace(':segmentId', segment.id)
    .replace(':filter', segment.filter_hash);

  if (isLoadingStats) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <KoalaLoader />
      </WidgetContainer>
    );
  }

  const updateURL = (url: string) => {
    const encodedHash = Base64.encode(JSON.stringify(url));
    history.push(filterPath.replace(':workspaceSlug', workspaceSlug).replace(':filter', encodedHash));
  };

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <OutcomeContainer>
        <SourceTitle>
          <Link to={segmentRoute} className="widget-source">
            {segment.title}
          </Link>
        </SourceTitle>

        <GraphContainer>
          <DistributionGraph outcomesStats={outcomesStats} updateURL={updateURL} customFilterHash={customFilterHash} />
        </GraphContainer>
      </OutcomeContainer>
    </WidgetContainer>
  );
}

export default OutcomeDistributionWidget;
