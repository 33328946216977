/**
 * Section component
 */
import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { nanoid } from 'nanoid';

// Actions
import {
  editorCreateObjective,
  editorCreateOutcome,
  editorSetDisplayNewBlockForm,
  editorSelectBlockBelow,
} from 'state/actions/workspaceEditorActions';

// UI Components
import BlockGrid, { BlockGutter, BlockContent, BlockMeta } from './BlockGrid';
import BlockLabel from './BlockLabel';
import TitleInput from './TitleInput';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedObjectiveId: string;
  defaultFormEntityType: string;
}

function NewBlockForm(props: Props) {
  const dispatch = useDispatch();
  const { selectedObjectiveId, defaultFormEntityType } = props;
  const selectedObjective = useSelector((state: any) => state.editorEntities.objectives[selectedObjectiveId]);
  const currentWorkspaceId = useSelector((state: any) => state.editorUI.currentWorkspaceId);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [entityType, setEntityType] = useState(defaultFormEntityType);

  // Reset the entity type if it changed from the parent.
  // This happens if you use the objective menu to show the objective or outcome form
  useEffect(() => {
    setEntityType(defaultFormEntityType);
  }, [defaultFormEntityType]);

  // Handles changes to the title
  const handleChange = (e: any) => {
    e.preventDefault();
    let newTitle = e.target.value || '';
    let newEntityType = entityType;
    let hasChangedEntityType = false; // Flag used for checks

    newTitle = newTitle.replace(/(\r\n|\n|\r)/gm, ''); // Remove new lines

    // Hashtags automatically change type as objective
    if (newTitle.substring(0, 1) === '#' && entityType !== 'objective') {
      newTitle = newTitle.substring(1); // Remove the hashtag
      newEntityType = 'objective';
      hasChangedEntityType = true;
    }

    // Dashes change type as outcome
    if (!hasChangedEntityType && newTitle.substring(0, 1) === '-' && entityType !== 'outcome') {
      newTitle = newTitle.substring(1); // Remove the dash
      newEntityType = 'outcome';
    }

    // Update entity type if it changed
    if (entityType !== newEntityType) {
      setEntityType(newEntityType);
    }

    setTitle(newTitle);
  };

  const addObjective = (title: string) => {
    const newObjective: any = {
      id: uuidv4(),
      nano_slug: nanoid(12),
      workspace_id: currentWorkspaceId,
      plan_id: selectedObjective.plan_id,
      title,
      rank: '',
    };
    dispatch(editorCreateObjective(newObjective, selectedObjective));
  };

  const addOutcome = (title: string) => {
    const newOutcome: any = {
      id: uuidv4(),
      nano_slug: nanoid(12),
      workspace_id: currentWorkspaceId,
      objective_id: selectedObjective.id,
      title,
      rank: '',
      from: 0,
      to: 100,
      score_format: '_number_%',
      membership_id: null,
    };
    dispatch(editorCreateOutcome(newOutcome));
  };

  const handleBlur = (e: any) => {
    if (title.length > 0) {
      if (entityType === 'objective') {
        addObjective(title);
      }
      if (entityType === 'outcome') {
        addOutcome(title);
      }
      dispatch(editorSetDisplayNewBlockForm(false));
    }
  };

  const handlePress = (e: any) => {
    if (e.keyCode === 27) {
      // Esc key
      e.preventDefault();
      if (title.length > 0) {
        if (entityType === 'objective') {
          addObjective(title);
        }
        if (entityType === 'outcome') {
          addOutcome(title);
        }
      }
      dispatch(editorSetDisplayNewBlockForm(false));
    }

    // Disable field if we press delete and no text is entered
    if (e.keyCode === 8) {
      // Delete key
      const value = e.target.value;
      if (!value) {
        e.preventDefault();
        dispatch(editorSetDisplayNewBlockForm(false));
      }
    }

    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault();
      if (title.length > 0) {
        if (entityType === 'objective') {
          addObjective(title);
        }
        if (entityType === 'outcome') {
          addOutcome(title);
        }
      } else {
        dispatch(editorSetDisplayNewBlockForm(false));
      }
    }

    if (e.keyCode === 38) {
      // Arrow up
      e.preventDefault();
      if (title.length > 0) {
        if (entityType === 'objective') {
          addObjective(title);
        }
        if (entityType === 'outcome') {
          addOutcome(title);
        }
      }
      dispatch(editorSetDisplayNewBlockForm(false));
    }

    if (e.keyCode === 40) {
      // Arrow down
      e.preventDefault();
      if (title.length > 0) {
        if (entityType === 'objective') {
          addObjective(title);
        }
        if (entityType === 'outcome') {
          addOutcome(title);
        }
      }
      dispatch(editorSelectBlockBelow());
    }
    if (e.keyCode === 9 && !e.shiftKey) {
      // Tab
      e.preventDefault();
      setEntityType('outcome');
    }

    if (e.shiftKey && e.keyCode === 9) {
      // shift Tab
      e.preventDefault();
      setEntityType('objective');
    }
  };

  const objectiveLabel = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase();
  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();
  const outcomesLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase();
  let placeholder =
    t(`workspacePlan.write.addSectionPlaceholder`) ??
    `Do you have another ${objectiveLabel}? (Or press tab to start adding ${outcomesLabel})`;

  if (entityType === 'outcome') {
    placeholder =
      t('workspacePlan.write.addMetricPlaceholder') ??
      `What is a measurable ${outcomeLabel} for the ${objectiveLabel} above?`;
  }
  const labelTitle = entityType === 'objective' ? 'section' : '#';
  return (
    <BlockGrid className={entityType}>
      <BlockGutter>
        <BlockLabel className={`${entityType} new`}>{labelTitle}</BlockLabel>
      </BlockGutter>
      <BlockContent className={entityType}>
        <TitleInput
          text={title}
          type={entityType}
          isNewInput={true}
          placeholder={placeholder}
          handleChange={handleChange}
          handlePress={handlePress}
          handleBlur={handleBlur}
        />
      </BlockContent>
      <BlockMeta></BlockMeta>
    </BlockGrid>
  );
}

export default NewBlockForm;
