import styled from 'styled-components';
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
// import { useMutation, useQuery, useQueryCache } from 'react-query';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';

import { shallowEqual, useSelector } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Workspace } from 'types';
import { Link } from 'react-router-dom';

const TitleContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const TabbyThinking = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
  img {
    width: 16rem;
  }
`;

const TipsContent = styled.div`
  font-size: 1.5rem;
  h3 {
    font-size: 2.4rem;
    margin: ${theme.spacing.x4} 0 ${theme.spacing.x1} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
  h4 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-top: ${theme.spacing.x3};
    margin-bottom: ${theme.spacing.x1};
  }
  a {
    color: ${theme.colors.B50};
    text-decoration: underline;
  }
  ul {
    list-style-type: disc;
    margin-left: 3rem;
    margin-top: ${theme.spacing.half};
    margin-bottom: ${theme.spacing.half};

    li {
      padding-bottom: ${theme.spacing.half};
      color: ${theme.colors.N90};
    }
  }
  p {
    color: ${theme.colors.N90};
  }
`;

const WritingImg = 'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-typing_vjitqx.gif';
const NoCreditsImg =
  'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-sleeping_tvsdtw.gif';

interface Props {
  planId: string;
}

function PopulateAI(props: Props) {
  const { planId } = props;
  const dispatch = useDispatch();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentPing = useSelector((state: any) => state.session.currentPing, shallowEqual);

  const { usage } = currentPing;
  let billing_ai_credits_limit = 0;
  let ai_credits_used = 0;
  if (usage) {
    billing_ai_credits_limit = usage.billing_ai_credits_limit;
    ai_credits_used = usage.ai_credits_used;
  }

  const [objectiveDescription, setObjectiveDescription] = useState('');
  const queryCache = useQueryCache();
  // Functions to update the outcome
  const [populateAIPlanMutation, { isLoading, isError }]: [any, any] = useMutation(remoteApi.populateAIPlan, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentPlan);
      queryCache.invalidateQueries(queryKeys.objectives);
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.initiatives);
      queryCache.invalidateQueries(queryKeys.currentPing);
      dispatch(setGlobalModalContent(''));
    },
  });

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handlePopulate = (e: any) => {
    e.preventDefault();
    // Don't trigger if already loading the template.
    if (isLoading) {
      return;
    }
    const params = {
      planId,
      // @ts-ignore
      objective_description: objectiveDescription,
    };

    populateAIPlanMutation(params);
  };

  const handleObjectiveDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputName = e.target.value;
    setObjectiveDescription(inputName);
  };

  const hasCredit = currentWorkspace.billing_ai_credits_limit > currentWorkspace.ai_credits_used;
  const needsUpgrade = !currentWorkspace.billing_subscription_plan || currentWorkspace.pricing_version !== 3;
  const isOwner = currentMembership && ['owner'].includes(currentMembership.role);

  return (
    <ModalGrid>
      <ModalHeader>
        <TitleContainer>
          <h2>Generate via AI</h2>
        </TitleContainer>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        {!hasCredit && (
          <>
            <TabbyThinking>
              <img src={NoCreditsImg} alt="Tabby sleeping" />
            </TabbyThinking>
            <p>Sorry! You do not have enough credits to generate examples.</p>
            <p className="subtle">
              <small>
                AI credits used: {ai_credits_used.toLocaleString()} / {billing_ai_credits_limit.toLocaleString()}
              </small>
              <br />
              {needsUpgrade && (
                <>
                  {isOwner && (
                    <small>
                      <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel}>
                        Upgrade
                      </Link>{' '}
                      to get more AI credits.
                    </small>
                  )}
                  {!isOwner && <small>Upgrade to get more AI credits.</small>}
                </>
              )}
            </p>
          </>
        )}
        {hasCredit && (
          <>
            {!isLoading && (
              <TipsContent>
                <Form onSubmit={handlePopulate}>
                  <FormField>
                    <label>What's your {translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}?</label>
                    <input
                      placeholder={`Describe your ${translate(
                        currentWorkspace,
                        CustomTermKey.OBJECTIVE,
                        1,
                      )} in one sentence`}
                      className="segment-input"
                      value={objectiveDescription}
                      onChange={handleObjectiveDescriptionChange}
                    />
                  </FormField>
                </Form>

                {isError && (
                  <p>
                    <b>Sorry, something went wrong while generating your example.</b>
                  </p>
                )}
                <p>Examples:</p>
                <ul>
                  <li>"Rank on the top 5 of Google"</li>
                  <li>"Build an amazing growth engine"</li>
                  <li>"Create a vibrant office culture"</li>
                </ul>
                <p>
                  Tability will suggest {translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase()} for you!
                </p>
                <p></p>
                <p className="subtle">
                  <small>
                    AI credits used: {ai_credits_used.toLocaleString()} / {billing_ai_credits_limit.toLocaleString()}
                  </small>
                  <br />
                  {needsUpgrade && (
                    <>
                      {isOwner && (
                        <small>
                          <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel}>
                            Upgrade
                          </Link>{' '}
                          to get more AI credits.
                        </small>
                      )}
                      {!isOwner && <small>Upgrade to get more AI credits.</small>}
                    </>
                  )}
                </p>
              </TipsContent>
            )}
            {isLoading && (
              <TabbyThinking>
                <img src={WritingImg} alt="Tabby thinking" />
                <h3>Please wait while Tabby is generating your goals</h3>
              </TabbyThinking>
            )}
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <KoalaButton
          onClick={handlePopulate}
          disabled={isLoading || !objectiveDescription || !hasCredit}
          loading={isLoading}
        >
          Generate
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>Cancel</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default PopulateAI;
