import React from 'react';

const WarningIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="6" fill="#DDAD27" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 7.5C5.58579 7.5 5.25 7.16421 5.25 6.75L5.25 3C5.25 2.58579 5.58579 2.25 6 2.25C6.41421 2.25 6.75 2.58579 6.75 3L6.75 6.75C6.75 7.16421 6.41421 7.5 6 7.5Z"
      fill="white"
    />
    <path
      d="M6.75 9C6.75 9.41421 6.41421 9.75 6 9.75C5.58579 9.75 5.25 9.41421 5.25 9C5.25 8.58579 5.58579 8.25 6 8.25C6.41421 8.25 6.75 8.58579 6.75 9Z"
      fill="white"
    />
  </svg>
);

export default WarningIcon;
