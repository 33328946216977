import styled from 'styled-components'; // Fixing the build
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useParams } from 'react-router-dom';
import { Workspace } from 'types';
import { Base64 } from 'js-base64';

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

function ConnectZendeskSupport() {
  const dispatch = useDispatch();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();

  const [url, setURL] = useState('');
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // tabilityRedirect is the redirection to use after the app is installed
  const tabilityRedirect = `${currentWorkspace.url}/settings/integrations`;
  const oauthState = {
    redirect_after: tabilityRedirect,
    slug: workspaceSlug,
    subdomain: url,
  };

  const state = Base64.encode(JSON.stringify(oauthState));

  const zendeskRedirectURL = `https://${process.env.REACT_APP_DOMAIN}/install/zendesk_support`;
  const zendeskOAuthURL =
    `https://${url}.zendesk.com/oauth/authorizations/new` +
    `?response_type=code` +
    `&state=${state}` +
    `&redirect_uri=${encodeURIComponent(zendeskRedirectURL)}` +
    `&client_id=${process.env.REACT_APP_ZENDESK_SUPPORT_CLIENT_ID}&scope=read%20write`;

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>Connect Tability to Zendesk Support</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form>
          <FormField>
            <label>Zendesk subdomain</label>
            <input
              placeholder="Enter Zendesk url"
              className="segment-input"
              value={url}
              onChange={(e) => setURL(e.currentTarget.value)}
            />
            <small className="subtle">
              i.e. <b>yoursubdomain</b>.zendesk.com
            </small>
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton href={zendeskOAuthURL} disabled={!url}>
          Connect
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>Cancel</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default ConnectZendeskSupport;
