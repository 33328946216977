import React, { useState } from 'react';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

import styled from 'styled-components';
import theme from 'theme';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { parseISO, differenceInDays } from 'date-fns';
// API
import * as remoteApi from 'api/remote';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

import KoalaProgressBar from 'koala/components/ProgressBar';
import { useTranslation } from 'react-i18next';
import KoalaButton from 'koala/components/Button';
import KoalaCounterButton from 'koala/components/CounterButton';

// Components
const Container = styled.div`
  font-size: 1.4rem;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  background: ${theme.colors.G70};
  color: #fff;
  height: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};

  a {
    color: ${theme.colors.blue};
    &:hover {
      text-decoration: underline;
    }
  }

  b {
    color: ${theme.colors.R80};
  }

  &:hover {
    background: ${theme.colors.G80};
    cursor: pointer;
    transition: all 0.1s ease-out;
  }

  &.warning {
    background: ${theme.colors.Y30};
    color: ${theme.colors.N100};
    .btn--subtle {
      border: 1px solid ${theme.colors.N100};
      color: ${theme.colors.N100} !important;
    }
    &:hover {
      background: ${theme.colors.Y40};
      cursor: pointer;
      transition: all 0.1s ease-out;
    }
  }

  animation: mymove 0.5s ease-out forwards;

  @keyframes mymove {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .btn--subtle {
    border: 1px solid #fff;
    color: #fff !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > div {
    &:first-of-type,
    &:last-of-type {
      width: 25rem;
    }
  }
`;
const InlineElement = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const WrapperV2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: ${theme.spacing.x2};
`;
const InlineElementV2 = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  span {
    color: ${theme.colors.Y50};
    font-weight: 500;
  }
  .button.yellow {
    height: 2.4rem;
    display: inline-flex;
    align-items: center;
  }
`;

const BarContainer = styled.div`
  width: 10rem;
`;

function Quickstart() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [stats, setStats]: any = useState(null);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isOwner = ['owner'].includes(currentMembership.role);

  const statsQueryKey = [queryKeys.currentWorkspace, currentWorkspace.slug, 'stats'];

  useQuery(statsQueryKey, remoteApi.fetchWorkspaceStats, {
    staleTime: 0,
    onSuccess: (response) => {
      setStats(response.data);
    },
  });

  // Return true if one of the following actions have not been done:
  // - There are no published plans
  // - There's only one user
  // - There are no closed tasks
  // - There are no checkins
  const calculateStepsCompleted = (stats: any) => {
    let completedSteps = 1; // counting the workspace creation

    if (stats.published_plans_count > 0) {
      completedSteps++;
    }

    if (stats.users_count > 1) {
      completedSteps++;
    }

    if (stats.integrations_count > 0) {
      completedSteps++;
    }

    if (stats.checkins_count > 0) {
      completedSteps++;
    }
    if (currentWorkspace.billing_subscription_plan) {
      completedSteps++;
    }
    return completedSteps;
  };

  if (!stats) {
    return null;
  }

  const stepsCompleted = calculateStepsCompleted(stats);
  const stepsTotal = 6;

  // Don't display if we have completed the 5 steps.
  if (stepsCompleted >= stepsTotal) {
    return null;
  }

  let daysLeft = -1;
  let trialLengthInDay = 0;
  let trialPrct = 0;

  const today = new Date();

  const workspaceCreatedAt = parseISO(currentWorkspace.created_at);
  const trialFinishAt = currentWorkspace.trial_period_finish_at
    ? parseISO(currentWorkspace.trial_period_finish_at)
    : null;

  if (trialFinishAt) {
    daysLeft = Math.max(differenceInDays(trialFinishAt, today), 0);
    trialLengthInDay = differenceInDays(trialFinishAt, workspaceCreatedAt);
  }

  trialPrct = ((trialLengthInDay - daysLeft) / trialLengthInDay) * 100;

  const prct = (stepsCompleted / stepsTotal) * 100;

  if (!trialFinishAt) {
    return (
      <Container onClick={() => dispatch(setGlobalModalContent('workspace::onboarding'))}>
        <Wrapper>
          <div />
          <div>
            <InlineElement>
              {t('workspaceInbox.onboarding.checkistTitle')}
              <BarContainer>
                <KoalaProgressBar
                  value={prct}
                  barColor={theme.colors.B80}
                  bgColor={theme.colors.B20}
                  appearance="custom"
                />
              </BarContainer>
              {stepsCompleted} / 6 {t('workspaceInbox.onboarding.stepsCompleted')}
              {isOwner && (
                <KoalaButton
                  isLink
                  to={`/${currentWorkspace.slug}/settings/billing`}
                  appearance="subtle"
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                >
                  Upgrade
                </KoalaButton>
              )}
            </InlineElement>
          </div>
          <div />
        </Wrapper>
      </Container>
    );
  }

  const bannerClass = daysLeft < 7 ? 'warning' : 'info';

  const barColor = daysLeft < 7 ? theme.colors.R80 : theme.colors.G40;
  const bgColor = daysLeft < 7 ? theme.colors.R20 : theme.colors.G10;

  if (currentWorkspace.created_at > '2024-03-24') {
    return (
      <Container onClick={() => dispatch(setGlobalModalContent('workspace::onboarding'))}>
        <WrapperV2>
          <div>
            <InlineElement>
              Premium Trial
              <BarContainer>
                <KoalaProgressBar value={trialPrct} barColor={barColor} bgColor={bgColor} appearance="custom" />
              </BarContainer>
              {daysLeft} days remaining
            </InlineElement>
          </div>
          <div>
            <InlineElementV2>
              <span>Need help setting goals?</span>
              <a
                href="https://calendly.com/tability/trial-demo"
                target="_blank"
                rel="noopener noreferrer"
                className="button yellow btn--small"
                onClick={(e) => e.stopPropagation()}
              >
                Book a call with us
              </a>
              <KoalaButton
                isLink
                to={`/${currentWorkspace.slug}/accelerator`}
                appearance="subtle"
                size="small"
                onClick={(e) => e.stopPropagation()}
              >
                Check the onboarding guide
              </KoalaButton>
            </InlineElementV2>
          </div>
          {false && (
            <div>
              <InlineElementV2>
                <span>Onboarding completion</span>
                <KoalaCounterButton
                  appearance="primary"
                  iconName="taskV2"
                  value={`${stepsCompleted} / 6`}
                  selected={false}
                />
              </InlineElementV2>
            </div>
          )}
        </WrapperV2>
      </Container>
    );
  }

  return (
    <Container onClick={() => dispatch(setGlobalModalContent('workspace::onboarding'))} className={bannerClass}>
      <Wrapper>
        <div />
        <div>
          <InlineElement>
            Premium Trial
            <BarContainer>
              <KoalaProgressBar
                value={trialPrct}
                barColor={theme.colors.N60}
                bgColor={theme.colors.N20}
                appearance="custom"
              />
            </BarContainer>
            {daysLeft} days remaining
            {isOwner && (
              <KoalaButton
                isLink
                to={`/${currentWorkspace.slug}/settings/billing`}
                appearance="subtle"
                size="small"
                onClick={(e) => e.stopPropagation()}
              >
                Upgrade
              </KoalaButton>
            )}
          </InlineElement>
        </div>
        <div>
          <InlineElement>
            <span>Onboarding completion</span>
            <KoalaCounterButton
              appearance="primary"
              iconName="taskV2"
              value={`${stepsCompleted} / 6`}
              selected={false}
            />
          </InlineElement>
        </div>
      </Wrapper>
    </Container>
  );
}

export default Quickstart;
