import React from 'react';
import { VictoryChart, VictoryGroup, VictoryVoronoiContainer, VictoryAxis } from 'victory';
import Chart from 'components/CheckinChart';
import { useHistory } from 'react-router-dom';
import { checkinType, closedInitiativePointType, Domain, DownloadDetails, Outcome, Plan } from 'types';
import { parseISO } from 'date-fns';
import theme from 'theme';
import { useTranslation } from 'react-i18next';

interface Props {
  isInsideTimeline: boolean;
  currentData: checkinType[];
  closedInitiativesData: closedInitiativePointType[];
  outcome: Outcome;
  plan: Plan;
  downloadDetails?: DownloadDetails;
  width: number;
  yDomain: Domain;
}
function NoMetricChart(props: Props) {
  const { isInsideTimeline, currentData, closedInitiativesData, yDomain, outcome, plan, downloadDetails } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const handleClickCheckin = (params: any) => {
    const { datum } = params;
    if (datum.checkin) {
      const checkinPath = `#checkin:${datum.checkin.nano_slug}:show`;
      history.push(checkinPath);
    }
  };
  const noMetricData = currentData.map((data) => {
    const yConfidenceMapping: { [color: string]: number } = {
      red: -1,
      grey: 0,
      yellow: 0,
      green: 1,
    };
    let yValue = 0;
    const currentConfidence = data.checkin?.confidence || 'grey';
    yValue = yConfidenceMapping[currentConfidence];
    return { ...data, y: yValue };
  });

  if (!plan.start_at || !plan.finish_at) {
    return;
  }
  const startDate: Date = parseISO(plan.start_at);
  const finishDate: Date = parseISO(plan.finish_at);

  const showTodayLine = isInsideTimeline && !downloadDetails;
  const topPadding = downloadDetails ? 30 : 10;
  return (
    <VictoryChart
      width={downloadDetails?.width ? downloadDetails.width : props.width}
      height={downloadDetails ? downloadDetails.height - 10 : 270}
      padding={{ top: topPadding, bottom: 10, left: 80, right: 10 }}
      containerComponent={<VictoryVoronoiContainer />}
      domainPadding={{ y: [20, 0] }}
      domain={{ y: [yDomain.min, yDomain.max], x: [startDate, finishDate] }}
    >
      {downloadDetails && downloadDetails.chartTitle && Chart.Title(downloadDetails.chartTitle, 310, 5)}
      {Chart.XAxis(i18n)}
      <VictoryAxis
        dependentAxis
        tickCount={3}
        tickFormat={(t: number) => {
          if (t === 0) {
            return 'at risk';
          }
          if (t === 1) {
            return 'on track';
          }
          if (t === -1) {
            return 'off track';
          }
          return '';
        }}
        style={{
          grid: { stroke: theme.colors.N10 },
          axis: { stroke: 'transparent' },
          tickLabels: {
            fill: theme.colors.N60,
            fontFamily: theme.font.fontFamily,
            fontSize: '12px',
          },
        }}
      />
      {showTodayLine && Chart.TodayLine(yDomain, t)}

      <VictoryGroup
        style={{
          data: { strokeWidth: 4 },
        }}
        scale="time"
      >
        {Chart.CurrentLine(noMetricData, 'step')}
        {Chart.CheckinComments(noMetricData)}
        {downloadDetails && Chart.DownloadScatterPointsPrevious(noMetricData, outcome, downloadDetails)}
        {downloadDetails && Chart.DownloadScatterPointsLatest(noMetricData, outcome, downloadDetails, t)}
        {!downloadDetails && Chart.ScatterPoints(noMetricData, outcome, handleClickCheckin)}
        {!downloadDetails && Chart.ScatterInitiatives(closedInitiativesData, yDomain)}
      </VictoryGroup>
    </VictoryChart>
  );
}

export default NoMetricChart;
