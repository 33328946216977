import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MarkdownContent from 'components/MarkdownContent';
import TagList from 'components/TagPickerObjective/TagList';
import styled from 'styled-components';
import { BlurDiv, ContentComment } from '.';
import { AuditEvent } from 'types';
import { Workspace } from 'types';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const TagSection = styled.div`
  display: inline-flex;
  align-items: center;
`;

interface Props {
  event: AuditEvent;
  canViewAudit: boolean;
}

function ObjectiveUpdated(props: Props) {
  const { event, canViewAudit } = props;
  const { t } = useTranslation();

  const { membership_cached_fullname, membership_cached_email, source_cached_title, membership_id, event_meta } = event;
  let name = membership_cached_fullname || membership_cached_email;
  if (!membership_id) {
    name += ` ${t('auditEvents.deleted')}`;
  }

  const meta = JSON.parse(event_meta);

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const objectiveLabel = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase();

  return (
    <>
      <span>
        <Trans
          i18nKey="auditEvents.objectiveUpdated"
          values={{ name, title: source_cached_title, objective: objectiveLabel }}
          components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
        />
      </span>
      <BlurDiv isBlurred={!canViewAudit}>
        {meta['title'] && (
          <li>
            <Trans
              i18nKey="auditEvents.titleUpdated"
              values={{ previous: meta['title'][0], new: meta['title'][1] }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['plan_id'] && (
          <li>
            <Trans
              i18nKey="auditEvents.objectivePlanUpdated"
              values={{
                previous: meta['plan'] ? meta['plan'][0] : meta['plan_id'][0],
                new: meta['plan'] ? meta['plan'][1] : meta['plan_id'][1],
              }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['description'] && (
          <li>
            <Trans i18nKey="auditEvents.objectiveDescriptionUpdated" />
            <ContentComment>
              <MarkdownContent source={meta['description'][1]} />
            </ContentComment>
          </li>
        )}
        {meta['tag_list'] && (
          <li>
            <TagSection>
              <Trans
                i18nKey="auditEvents.objectiveTagsUpdated"
                components={{
                  previous: <TagList tagList={meta['tag_list'][0].join(',')} disabled={true} />,
                  new: <TagList tagList={meta['tag_list'][1].join(',')} disabled={true} />,
                }}
              />
            </TagSection>
          </li>
        )}
      </BlurDiv>
    </>
  );
}

export default React.memo(ObjectiveUpdated);
