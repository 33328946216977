import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import React, { FC } from 'react';
import KoalaIcon from '../Icons';
import KoalaLoader from '../Loader';
import StyledButton, { StyledLinkButton, StyledLinkToButton } from './Button.styles';

export interface ButtonProps {
  loading?: boolean;
  loadingText?: string;
  appearance?: (typeof KoalaAppearances)[number];
  disabled?: boolean;
  size?: (typeof KoalaSizes)[number];
  onClick?: (e: any) => void;
  prefixIcon?: string;
  suffixIcon?: string;
  submit?: boolean;
  isLink?: boolean;
  to?: string;
  href?: string;
  target?: string;
  rel?: string;
  className?: string;
  style?: React.CSSProperties;
  ref?: any;
  type?: 'submit' | 'button' | 'reset' | undefined;
  htmlFor?: string;
  dataFor?: string;
}

const getIcon = (icon: string, type: string, size?: (typeof KoalaSizes)[number], appearance?: string) => {
  return <KoalaIcon iconName={icon} iconSize={size} iconAppearance={appearance} className={`btn-icon-${type}`} />;
};

const KoalaButton: FC<ButtonProps> = ({ children = 'Basic', ...props }) => {
  const {
    loading,
    loadingText,
    appearance,
    disabled,
    size,
    onClick,
    prefixIcon,
    suffixIcon,
    submit,
    isLink,
    to,
    href,
    target,
    rel,
    className,
    style,
    ref,
    type,
    htmlFor,
    dataFor,
  } = props;
  let buttonInner = <>{children}</>;
  let buttonAppearance = disabled ? 'disabled' : appearance;

  let prefix: JSX.Element | null = null;
  let suffix: JSX.Element | null = null;
  if (loading) {
    buttonInner = (
      <>
        <KoalaLoader size={size} appearance={buttonAppearance} />
        {loadingText}
      </>
    );
  } else {
    prefix = prefixIcon ? getIcon(prefixIcon, 'prefix', size, buttonAppearance) : <></>;
    suffix = suffixIcon ? getIcon(suffixIcon, 'suffix', size, buttonAppearance) : <></>;
  }

  let buttonType = submit ? 'submit' : type;
  let buttonClass = className ?? '';

  buttonClass += ` btn--${buttonAppearance}`;
  buttonClass += ` btn--${size}`;

  if (isLink) {
    return (
      <StyledLinkToButton
        data-tip={dataFor ? true : false}
        data-for={dataFor}
        type={buttonType}
        className={buttonClass}
        {...{ style, to, ref, onClick, htmlFor }}
      >
        {prefix}
        {buttonInner}
        {suffix}
      </StyledLinkToButton>
    );
  }
  if (href) {
    return (
      <StyledLinkButton
        data-tip={dataFor ? true : false}
        data-for={dataFor}
        type={buttonType}
        className={buttonClass}
        {...{ style, href, target, onClick, rel, ref, htmlFor }}
      >
        {prefix}
        {buttonInner}
        {suffix}
      </StyledLinkButton>
    );
  } else {
    return (
      <StyledButton
        data-tip={dataFor ? true : false}
        data-for={dataFor}
        type={buttonType}
        className={buttonClass}
        {...{ style, ref, onClick, disabled, htmlFor }}
      >
        {prefix}
        {buttonInner}
        {suffix}
      </StyledButton>
    );
  }
};

KoalaButton.defaultProps = {
  loading: false,
  appearance: 'primary',
  disabled: false,
  size: 'medium',
  submit: false,
};

export default KoalaButton;
