import React, { useRef } from 'react';
import styled from 'styled-components';
import { checkinType, Outcome } from 'types';
import { getChartDatapoints, getTrendline } from 'utils/checkinUtils';
import { hasTarget } from 'utils/outcomeUtils';
import CheckinsChart from 'components/Chart';
import { useTranslation } from 'react-i18next';

const Container = styled.div<{ height: number }>`
  max-height: ${(props) => props.height * 2}rem;
  height: ${(props) => props.height}rem;
  width: ${62 * 2}rem;

  transform: scale(0.5);
  transform-origin: top left;
  .VictoryContainer {
    overflow: visible;
  }
`;

interface Props {
  outcome: Outcome;
  checkins: [];
  showMetrics: boolean;
  showTooltip: boolean;
  chartTitle: string;
}

function DownloadCheckinsChart(props: Props) {
  const { outcome, checkins, showMetrics, showTooltip, chartTitle } = props;
  const { plan } = outcome;
  const containerRef = useRef(null);
  const { t } = useTranslation();

  const outcomeHasTarget = hasTarget(outcome);
  let { currentData, finishDate, yDomain } = getChartDatapoints(plan, outcome, checkins, outcomeHasTarget);

  if (finishDate === undefined) {
    return <>{t('shared.noTimeline')}</>;
  }
  let trendData: checkinType[] = getTrendline(currentData, finishDate, plan.reminders_period);
  if (trendData.length > 1) {
    const newPointY = trendData[1].y;
    if (newPointY && newPointY < yDomain.min) {
      yDomain.min = newPointY;
    } else if (newPointY && newPointY > yDomain.max) {
      yDomain.max = newPointY;
    }
  }
  // set container height depending on target then add space for tooltip if needed
  let height = outcome.outcome_type !== 'no_metric' ? 320 : 120;

  const downloadDetails = {
    showTooltip,
    showMetrics,
    showToday: false,
    chartTitle,
    height,
    width: 600,
  };

  return (
    <Container className="panel-checkins-chart" ref={containerRef} height={height}>
      <CheckinsChart outcome={outcome} checkins={checkins} downloadDetails={downloadDetails} />
    </Container>
  );
}

export default DownloadCheckinsChart;
