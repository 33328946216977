import { createGlobalStyle } from 'styled-components';
import theme from 'theme';

const GlobalStyles = createGlobalStyle`
  body, html, #root {
    height: 100%;
    min-height: 100%;

  }

  html {
    font-size: 62.5%;
  }

  body {
    background-color: #fff;
  
    color: #151515;
    font-family: ${theme.font.fontFamily} !important;
    font-size: 1.4rem;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.N60} transparent;
  }

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-color: ${theme.colors.N40};
    background-clip: content-box;
  }
  *::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-color: ${theme.colors.N40};
    background-clip: content-box;
    border-radius: 20px;
  }

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  h4 {
    
  }
  
  small {
    font-size: 1.2rem;
  }
  
  em {
    font-style: italic;
  }

  a {
    color: #151515;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &.primary {
      color: ${theme.colors.blue};
      &:hover {
        text-decoration: underline;
      }
    }
  }

  b { font-weight: 700; }

  p {
    line-height: 1.4;
  }

  p + p {
    margin-top: 1.6rem;
  }

  .underline {
    text-decoration: underline;
  }

  .subtle {
    color: ${theme.colors.subtleText};
  }

  button, .button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    outline: none;
    background-color: transparent;
    border: 1px solid #324DD3;
    border-radius: 4px;
    color: #324DD3;
    cursor: pointer;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    padding: 0.8rem 1.6rem;
    transition: all 0.1s ease;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      background: rgba(50, 77, 211, 0.15);
      text-decoration: none;
    }

    
    &:active {
      filter: brightness(85%);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.primary {
      background-color: #324DD3;
      border: 1px solid #324DD3;
      color: #fff;

      &:hover{
        background-color: #203287;
        border: 1px solid #203287;
      }
    }

    &.purple {
      background-color: ${theme.colors.V70};
      border: 1px solid ${theme.colors.V70};
      color: #fff;

      &:hover{
        background-color: ${theme.colors.V80};
        border: 1px solid ${theme.colors.V80};
      }
    }

    &.yellow {
      background-color: ${theme.colors.Y70};
      border: 1px solid ${theme.colors.Y70};
      color: #fff;

      &:hover{
        background-color: ${theme.colors.Y80};
        border: 1px solid ${theme.colors.Y80};
      }
    }

    &.danger-primary {
      background-color: ${theme.colors.red};
      border: 1px solid ${theme.colors.red};
      color: #fff;

      &:hover{
        background-color: #D0402E;
        border: 1px solid #D0402E;
      }
    }

    &.secondary {
      text-decoration: underline;
      color: #324DD3;
      padding: 0;
      border: 1px solid transparent;
      background-color: transparent;

      &:hover{
        color: #203287;
      }

      &:focus {
        border: 0;
      }

      &:active {
        filter: brightness(80%);
      }
    }

    &.secondary-light {
      padding: 0;
      border: 1px solid transparent;
      background-color: transparent;

      &:focus {
        border: 0;
      }

      &:active {
        filter: brightness(80%);
      }

      &:hover {
        filter: brightness(0.8);
      }
    }

    /* SIZES */
    &.btn--small {
      padding: 0.8rem 1.2rem;
      font-size: 1.1rem;
      font-weight: 500;
      border-radius: 4px;
    }
    &.btn--medium {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1;
      padding: 0.8rem 1.6rem;
      height: 3.2rem;
    }

    .btn-icon-prefix {
      margin-right: ${theme.spacing.x1};
    }
    .btn-icon-suffix {
      margin-left: ${theme.spacing.x1};
    }

    &.icon {
      height: 3rem;
      width: 3rem;
      padding: 0.8rem;
      border-radius: 50%;
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      transition: none;

      &:hover {
        background: rgba(15, 15, 15, 0.1);
        border: 1px solid transparent;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        g, circle {
          stroke: ${theme.colors.subtleText};
        }
      }
    }
  }

  input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    display: inline-block;
    padding: 0 0.8rem;
    height: 3.2rem;
    width: 100%;
    outline: 0;
    transition: border 0.2s ease;
    border: 1px solid ${theme.colors.inputBorder};
    border-radius: 4px;
    &.error {
      border: 1px solid #D0402E;
    }

    &.small {
      width: 50%;
    }

    &.tiny {
      width: 25%;
    }
    &:read-only {
      opacity: 0.7;
    }
  }
  input[type="checkbox"], input[type="radio"] {
    width: initial;
    height: initial;
  }

  input[type="file"] {
    width: initial;
    height: initial;
    padding: 0;
  }

  input[type="checkbox"], input[type="radio"], input[type="file"] {
    width: initial;
    height: initial;
    border: initial;
  }

  select {
    padding: 0 ${theme.spacing.x1};
    height: 3.2rem;
    font-size: 1.4rem;
    border: 1px solid #949494;
    border-radius: 4px;

    &.small {
      width: 50%;
    }

    &.tiny {
      width: 25%;
    }
  }

  span.beta-lozenge {
    display: inline-block;
    font-size: 1rem;
    padding: 0.4rem 0.8rem;
    font-weight: 800;
    border-radius: 4px;
    letter-spacing: 1px;
    background-color: #e7e7e7;
    color: #151515;
    margin-left: ${theme.spacing.x1};
  }

  .tooltip a {
    color: #fff;
    text-decoration: underline;
  }

  /* required to show date picker above panels/modals */
  .react-datepicker-popper {  z-index: 80 }
`;

export default GlobalStyles;
