import React from 'react';

const TaskIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6247 7.21914C14.056 7.56415 14.1259 8.19344 13.7809 8.6247L9.78087 13.6247C9.58157 13.8738 9.27537 14.0129 8.95664 13.9991C8.63792 13.9852 8.34491 13.8202 8.16795 13.5547L6.16795 10.5547C5.8616 10.0952 5.98577 9.47431 6.4453 9.16796C6.90483 8.8616 7.5257 8.98578 7.83205 9.44531L9.07345 11.3074L12.2191 7.37531C12.5641 6.94405 13.1934 6.87413 13.6247 7.21914Z"
      fill="#737373"
    />
  </svg>
);
export default TaskIcon;
