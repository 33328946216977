import React from 'react';

const ShareIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6947 2.48041C12.0782 2.33047 12.5143 2.43092 12.7936 2.73347L17.7348 8.08641C18.0884 8.46947 18.0884 9.05991 17.7348 9.44297L12.7936 14.7959C12.5143 15.0985 12.0782 15.1989 11.6947 15.049C11.3112 14.899 11.0588 14.5294 11.0588 14.1176V12.685C8.40841 12.8906 6.67222 13.8975 5.5796 14.866C4.95972 15.4154 4.53628 15.9626 4.27085 16.3659C4.13837 16.5672 4.04615 16.7313 3.98927 16.8399C3.96086 16.8941 3.94137 16.9343 3.93017 16.9582C3.92458 16.9701 3.92106 16.9778 3.91955 16.9812L3.91895 16.9826C3.73225 17.4177 3.26569 17.6625 2.80121 17.5683C2.33505 17.4737 2 17.0639 2 16.5882C2 12.3123 3.08661 9.33732 4.99482 7.43825C6.6561 5.78491 8.81415 5.07245 11.0588 4.91634V3.41175C11.0588 3 11.3112 2.63034 11.6947 2.48041ZM13.0588 5.9694V6.88234H12.0588C9.77666 6.88234 7.80868 7.45951 6.40564 8.85585C5.43679 9.82006 4.65801 11.2538 4.26939 13.3548C5.83329 11.9747 8.32557 10.647 12.0588 10.647H13.0588V11.56L15.6391 8.76469L13.0588 5.9694ZM3.92053 16.9789L3.92015 16.9798L3.91951 16.9813L3.91886 16.9828C3.91867 16.9832 3.91865 16.9833 3.91878 16.983"
      fill="#737373"
    />
  </svg>
);
export default ShareIcon;
