import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import MariaDBIcon from 'components/_assets/IconMariaDB.png';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
}

function MariaDBIntegration(props: Props) {
  const { workspace, integrations } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallMariaDBMutation, { isLoading: isUninstallingMariaDB }] = useMutation(remoteApi.uninstallMariaDB, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const mariaDBIntegration = integrations.filter((i: Integration) => i.integration_type === 'mariadb')[0];

  const handleUninstallMariaDB = () => {
    uninstallMariaDBMutation(workspace.slug);
  };
  const handleInstallMariaDB = () => {
    dispatch(setGlobalModalContent('workspace::connect.mariaDB'));
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={MariaDBIcon} alt="MariaDBIcon" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'MariaDB' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'MariaDB' })}</p>
      </IntegrationContent>
      <IntegrationAction>
        {mariaDBIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: mariaDBIntegration.connected_to }}
              />
            </div>
            <KoalaTextButton size="small" onClick={handleUninstallMariaDB}>
              {isUninstallingMariaDB ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove')}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!mariaDBIntegration && (
          <KoalaButton onClick={handleInstallMariaDB}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'MariaDB' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default MariaDBIntegration;
