import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { shallowEqual, useSelector } from 'react-redux';

// API
import * as remoteApi from 'api/remote';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import ReactTooltip from 'react-tooltip';
import KoalaIcon from 'koala/components/Icons';
import { Trans, useTranslation } from 'react-i18next';
import { formatLocale } from 'utils/dateUtils';

const Container = styled.div`
  padding: ${theme.spacing.x1} 0;
`;

const UpgradeContainer = styled.div`
  background: ${theme.colors.V5};
  padding: ${theme.spacing.x1};
  border-radius: 4px;
`;

const Line = styled.div`
  padding: ${theme.spacing.x1} 0;
`;

const FeedbackLine = styled.div`
  padding: ${theme.spacing.x1} 0;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const LoadingLine = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
  padding: ${theme.spacing.x1} 0;
`;

const InfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x1};
  }
  img {
    width: 3rem;
    position: relative;
    bottom: 4px;
  }
  gap: ${theme.spacing.x2};

  h4 {
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 900;
  }
`;

const Feedback = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x1} 0;
  padding-top: 0;
  > div {
    padding: ${theme.spacing.half} 0;
    &.task {
      padding: ${theme.spacing.half};
      &:hover {
        background: ${theme.colors.N3};
      }
    }
  }
`;

const Suggestion = styled.div`
  display: list-item;
  list-style-type: disc;
  margin-left: ${theme.spacing.x3};
`;

const Info = styled.div`
  background-color: ${theme.colors.B5};
  color: ${theme.colors.B80};
  padding: ${theme.spacing.x1};
  font-size: 1.2rem;
  border-radius: 4px;
  margin: ${theme.spacing.x1} 0;
  a {
    color: ${theme.colors.B50};
    text-decoration: underline;
  }
`;

interface Props {
  outcome: any;
}

function InfoTab(props: Props) {
  const { outcome } = props;
  const queryCache = useQueryCache();
  const currentPing = useSelector((state: any) => state.session.currentPing, shallowEqual);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t, i18n } = useTranslation();
  const translationKey = 'panels.outcome';

  let usage = null;

  if (currentPing) {
    usage = currentPing.usage;
  }

  let ai_credits_used = 0;
  let billing_ai_credits_limit = 0;

  if (usage !== null) {
    billing_ai_credits_limit = usage.billing_ai_credits_limit;
    ai_credits_used = usage.ai_credits_used;
  }

  const hasCredit = billing_ai_credits_limit > ai_credits_used;

  const [generateAnalysisOutcomeMutation, { isLoading, isError }] = useMutation(remoteApi.generateAnalysisOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcome.nano_slug]);
      queryCache.invalidateQueries(queryKeys.currentPing);
    },
  });

  const handleGenerateAnalysis = () => {
    generateAnalysisOutcomeMutation({
      outcomeId: outcome.id,
    });
  };

  const [deleteAnalysisOutcomeMutation, { isLoading: isDeleting }] = useMutation(remoteApi.deleteAnalysisOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcome.nano_slug]);
    },
  });

  const handleDeleteAnalysis = () => {
    deleteAnalysisOutcomeMutation(outcome.id);
  };

  if (usage === null) {
    return <KoalaLoader />;
  }

  if (currentWorkspace.billing_subscription_plan === 'tability-v4-free') {
    return (
      <UpgradeContainer>
        <InfoHeader>
          <div>
            <img
              src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png"
              alt="Seems completed"
            />
            <h4>{t(`${translationKey}.tabbysAdvice`)}</h4>
            <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-coaching" />
            <ReactTooltip
              place="bottom"
              type="dark"
              className="tooltip"
              effect="solid"
              id="explain-coaching"
              delayHide={500}
              clickable={true}
            >
              {t(`${translationKey}.coachingInfo`)}
              <br />
              <a
                href="https://guides.tability.app/docs/features/ai-features/coaching-ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('shared.learnMore')}
              </a>
            </ReactTooltip>
          </div>
        </InfoHeader>
        <Line>
          <p className="subtle">{t(`${translationKey}.coachingNotAvailable`)}</p>
        </Line>
      </UpgradeContainer>
    );
  }

  // Don't display coach for kpis and no metrics
  if (['kpi', 'no_metric'].includes(outcome.outcome_type)) {
    return (
      <Container>
        <InfoHeader>
          <div>
            <img
              src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png"
              alt="Seems completed"
            />
            <h4>{t(`${translationKey}.tabbysAdvice`)}</h4>
            <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-coaching" />
            <ReactTooltip
              place="bottom"
              type="dark"
              className="tooltip"
              effect="solid"
              id="explain-coaching"
              delayHide={500}
              clickable={true}
            >
              {t(`${translationKey}.coachingInfo`)}
              <br />
              <a
                href="https://guides.tability.app/docs/features/ai-features/coaching-ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('shared.learnMore')}
              </a>
            </ReactTooltip>
          </div>
        </InfoHeader>
        <Line>
          <small className="subtle">
            {t(`${translationKey}.credits`, {
              used: ai_credits_used.toLocaleString(),
              limit: billing_ai_credits_limit.toLocaleString(),
            })}
          </small>
          {!hasCredit && (
            <Info>
              <Trans
                i18nKey={`${translationKey}.creditsUpgrade`}
                components={{
                  a: (
                    <a
                      href="https://guides.tability.app/docs/other/how-to-get-more-ai-credits"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      text
                    </a>
                  ),
                }}
              />
            </Info>
          )}
        </Line>
        <Line>
          <p className="subtle">{t(`${translationKey}.coachingNoGoal`)}</p>
        </Line>
      </Container>
    );
  }

  if (!outcome.ai_analysis || !outcome.ai_analysis_created_at) {
    return (
      <Container>
        <InfoHeader>
          <div>
            <img
              src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png"
              alt="Seems completed"
            />
            <h4>{t(`${translationKey}.tabbysAdvice`)}</h4>
            <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-coaching" />
            <ReactTooltip
              place="bottom"
              type="dark"
              className="tooltip"
              effect="solid"
              id="explain-coaching"
              delayHide={500}
              clickable={true}
            >
              {t(`${translationKey}.coachingInfo`)}
              <br />
              <a
                href="https://guides.tability.app/docs/features/ai-features/coaching-ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(`shared.learnMore`)}
              </a>
            </ReactTooltip>
          </div>
          <div data-tip data-for="coachAction">
            <KoalaButton onClick={handleGenerateAnalysis} size="small" disabled={isLoading || !hasCredit}>
              {t(`shared.generate`)}
            </KoalaButton>
          </div>
          {!hasCredit && (
            <ReactTooltip
              place="bottom"
              type="dark"
              id="coachAction"
              className="tooltip"
              effect="solid"
              delayShow={100}
            >
              {t(`${translationKey}.notEnoughCredits`)}
            </ReactTooltip>
          )}
        </InfoHeader>
        {isLoading && (
          <LoadingLine>
            <KoalaLoader /> <span>{t(`${translationKey}.generating`)}</span>
          </LoadingLine>
        )}

        {!isLoading && (
          <>
            <Line>
              <p className="subtle">{t(`${translationKey}.analysisInfo`)}</p>
            </Line>
          </>
        )}
        <Line>
          <small className="subtle">
            {t(`${translationKey}.credits`, {
              used: ai_credits_used.toLocaleString(),
              limit: billing_ai_credits_limit.toLocaleString(),
            })}
          </small>
          {!hasCredit && (
            <Info>
              <Trans
                i18nKey={`${translationKey}.creditsUpgrade`}
                components={{
                  a: (
                    <a
                      href="https://guides.tability.app/docs/other/how-to-get-more-ai-credits"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      text
                    </a>
                  ),
                }}
              />
            </Info>
          )}
        </Line>
      </Container>
    );
  }

  const feedback = outcome.ai_analysis.split('\n');

  /*const confidenceToVariant: any = {
    "on track": "green-light",
    "at risk": "yellow-light",
    "off track": "red-light",
  }*/
  return (
    <Container>
      <InfoHeader>
        <div>
          <img
            src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png"
            alt="Seems completed"
          />
          <h4>{t(`${translationKey}.tabbysAdvice`)}</h4>
          <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-coaching" />
          <ReactTooltip
            place="bottom"
            type="dark"
            className="tooltip"
            effect="solid"
            id="explain-coaching"
            delayHide={500}
            clickable={true}
          >
            {t(`${translationKey}.coachingInfo`)}
            <br />
            <a
              href="https://guides.tability.app/docs/features/ai-features/coaching-ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('shared.learnMore')}
            </a>
          </ReactTooltip>
        </div>
        <div data-tip data-for="coachAction">
          <KoalaButton onClick={handleGenerateAnalysis} size="small" disabled={isLoading || !hasCredit}>
            {t(`${translationKey}.generateNew`)}
          </KoalaButton>
        </div>
        {!hasCredit && (
          <ReactTooltip place="bottom" type="dark" id="coachAction" className="tooltip" effect="solid" delayShow={100}>
            {t(`${translationKey}.notEnoughCredits`)}
          </ReactTooltip>
        )}
      </InfoHeader>
      {isLoading && (
        <LoadingLine>
          <KoalaLoader /> <span>{t(`${translationKey}.generating`)}</span>
        </LoadingLine>
      )}
      {isError && <Line>{t(`${translationKey}.somethingHappened`)}</Line>}

      {!isLoading && (
        <>
          <Feedback>
            <p className="subtle">
              {t(`${translationKey}.created`, {
                time: formatLocale(outcome.ai_analysis_created_at, 'dd MMMM yyyy', i18n),
              })}
            </p>
            {feedback.map((phrase: string, index: number) => {
              if (phrase[0] === '-') {
                const subPhrase = phrase.substring(1);
                return (
                  <div className="task">
                    <Suggestion key={index}>{subPhrase}</Suggestion>
                  </div>
                );
              }
              return <div key={index}>{phrase}</div>;
            })}
          </Feedback>
          <Line>
            <KoalaButton
              appearance="subtle"
              loading={isDeleting}
              disabled={isDeleting}
              size="small"
              onClick={handleDeleteAnalysis}
            >
              {t(`${translationKey}.removeAnalysis`)}
            </KoalaButton>
          </Line>
        </>
      )}
      <Line>
        <small className="subtle">
          {t(`${translationKey}.credits`, {
            used: ai_credits_used.toLocaleString(),
            limit: billing_ai_credits_limit.toLocaleString(),
          })}
        </small>
        {!hasCredit && (
          <Info>
            <Trans
              i18nKey={`${translationKey}.creditsUpgrade`}
              components={{
                a: (
                  <a
                    href="https://guides.tability.app/docs/other/how-to-get-more-ai-credits"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    text
                  </a>
                ),
              }}
            />
          </Info>
        )}
      </Line>
      <FeedbackLine>
        <small className="subtle">{t(`${translationKey}.feedback`)}</small>
        <div>
          <KoalaButton onClick={() => window.Intercom('show')} appearance="secondary" size="small">
            {t(`${translationKey}.feedbackButton`)}
          </KoalaButton>
        </div>
      </FeedbackLine>
    </Container>
  );
}

export default React.memo(InfoTab);
