import CheckinBlock from 'components/CheckinBlock';
import KoalaButton from 'koala/components/Button';
import CheckinComments from 'panels/shared/CheckinComments';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import { Checkin, Outcome } from 'types';
const Checkins = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  .checkin-block {
    margin-bottom: 0;
  }
`;
const SpacingContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  justify-content: space-between;
  align-items: center;
`;
const ContentBox = styled.div`
  background-color: #ffffff;
  border: solid 1px ${theme.colors.blockBorder};
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x3};
  gap: ${theme.spacing.x1};

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.x2} ${theme.spacing.x1};
  }
`;

interface Props {
  outcome: Outcome;
  checkins: Checkin[];
  isReadOnly: boolean;
}

function PresentationCheckin(props: Props) {
  const { outcome, checkins, isReadOnly } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { nano_slug } = outcome;

  const currentCheckin = checkins ? checkins[0] : null;

  const blockId = `outcome:${nano_slug}`;
  const handleCreateCheckin = () => {
    dispatch(setGlobalModalContent(`${blockId}:create.checkin`));
  };
  return (
    <Checkins>
      <SpacingContainer>
        <h3>{t('publicPlan.latestCheckin')}</h3>
        {!isReadOnly && (
          <KoalaButton onClick={handleCreateCheckin} appearance="subtle">
            {t('shared.createCheckin')}
          </KoalaButton>
        )}
      </SpacingContainer>
      <ContentBox>
        {currentCheckin && (
          <>
            <CheckinBlock checkin={currentCheckin} showProgressPrct outcome={outcome} className="primary" />
            <div>
              <CheckinComments checkin={currentCheckin} />
            </div>
          </>
        )}
        {!currentCheckin && <>{t('publicPlan.noCheckin')}</>}
      </ContentBox>
    </Checkins>
  );
}

export default PresentationCheckin;
