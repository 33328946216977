import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';
import theme from 'theme';
import parse from 'parse-link-header';
// API
import * as remoteApi from 'api/remote';

// Components
import Loader from 'components/Loader';

import KoalaButton from 'koala/components/Button';

import { useTranslation } from 'react-i18next';
import AuditEventItem from 'components/AuditEventItem';
import { AuditEvent } from 'types';

const InboxPanel = styled.div`
  margin-bottom: ${theme.spacing.x4};
  > p {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

const EventList = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {}

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

function Inbox(props: Props) {
  const { t } = useTranslation();
  // Query that fetches all the outcomes with pending checkins
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const eventsQueryKey = [queryKeys.events, workspaceSlug];
  const {
    isLoading,
    isFetchingMore,
    data: outcomesResponse,
    fetchMore,
    canFetchMore,
  }: any = useInfiniteQuery(eventsQueryKey, remoteApi.fetchAuditEvents, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  //
  if (isLoading) {
    return (
      <Fragment>
        <Loader size="medium" />
      </Fragment>
    );
  }

  let events = [];
  if (outcomesResponse) {
    events = outcomesResponse;
  }

  // Display empty state if you're at inbox zero
  if (events.length === 0 || events[0].data.length === 0) {
    return null;
  }

  const supportedSources = ['workspace', 'plan', 'outcome', 'initiative'];

  return (
    <Fragment>
      <InboxPanel>
        {events.map((group: any, i: number) => {
          return (
            <EventList key={i}>
              {group.data.map((event: AuditEvent) => {
                if (!supportedSources.includes(event.source_type)) {
                  return null;
                }
                return <AuditEventItem event={event} key={event.id} />;
              })}
            </EventList>
          );
        })}
        {canFetchMore && (
          <LoadMore>
            <KoalaButton
              onClick={() => fetchMore()}
              loading={isFetchingMore}
              disabled={isFetchingMore}
              appearance="secondary"
            >
              {t('shared.loadMore')}
            </KoalaButton>
          </LoadMore>
        )}
      </InboxPanel>
    </Fragment>
  );
}

export default Inbox;
