import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import AddPersonIcon from 'components/_assets/AddPersonIcon';
import Loader from 'components/Loader';
import FormField from 'components/FormField';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaAvatar from 'koala/components/Avatar';
import { t } from 'i18next';

const OwnerLine = styled.div`
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: ${theme.spacing.x2};
  }
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;
  background: #f3f3f3;

  &:hover {
    cursor: pointer;
    background: #e7e7e7;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }
`;

interface Props {
  initiativeId: string;
}

function InitiativeContributors(props: Props) {
  const { initiativeId } = props;
  const queryCache = useQueryCache();

  const [initiativeContributors, setInitiativeContributors]: any = useState([]);

  const initiativeContributorsQueryKey = [queryKeys.initiativeContributors, initiativeId];
  const { isFetching: isFetchingInitiativeContributors } = useQuery(
    initiativeContributorsQueryKey,
    remoteApi.fetchInitiativeContributors,
    {
      staleTime: 0,
      onSuccess: (response) => {
        setInitiativeContributors(response.data);
      },
    },
  );

  const [addInitiativeContributorMutation, { isLoading: isAddingInitiativeContributor }]: [any, any] = useMutation(
    remoteApi.createInitiativeContributor,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.initiativeContributors);
      },
    },
  );

  const [deleteInitiativeContributorMutation, { isLoading: isDeletingInitiativeContributor }]: [any, any] = useMutation(
    remoteApi.deleteInitiativeContributor,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.initiativeContributors);
      },
    },
  );

  const handleAddInitiativeContributor = (membership_id: any) => {
    if (membership_id) {
      const params = {
        membership_id,
      };

      addInitiativeContributorMutation({
        initiativeId,
        initiative_contributor: params,
      });
    }
  };

  const handleDeleteInitiativeContributor = (initiativeContributorId: string) => {
    deleteInitiativeContributorMutation(initiativeContributorId);
  };

  const isUpdatingInitiativeContributors =
    isAddingInitiativeContributor || isDeletingInitiativeContributor || isFetchingInitiativeContributors;

  return (
    <FormField>
      <label>
        {t(`shared.contributors`)} {isUpdatingInitiativeContributors && <Loader size="small" />}
      </label>
      {initiativeContributors.map((initiativeContributor: any) => {
        const { membership } = initiativeContributor;
        const { user } = membership;
        return (
          <OwnerLine>
            <OwnerOption
              className="active"
              data-initiativeContributor-id={initiativeContributor.id}
              key={initiativeContributor.id}
            >
              <KoalaAvatar user={user} size={2.2} />
              <span className="owner-option-name">{user.name}</span>
            </OwnerOption>
            <KoalaTextButton
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault();
                handleDeleteInitiativeContributor(initiativeContributor.id);
              }}
            >
              {t(`shared.remove`)}
            </KoalaTextButton>
          </OwnerLine>
        );
      })}
      <UserSearchableDropdown
        trigger={
          <OwnerOption className="active option-button">
            <AddPersonIcon /> <span className="owner-option-name">{t('shared.addContributor')}</span>
          </OwnerOption>
        }
        position="left"
        callback={handleAddInitiativeContributor}
      />
    </FormField>
  );
}

export default React.memo(InitiativeContributors);
