import React, { useState } from 'react';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
import { addMonths, parseISO, startOfQuarter, endOfQuarter, addDays } from 'date-fns';

// API
import * as remoteApi from 'api/remote';

// Components
import FormField from 'components/FormField';
import { Link, useParams } from 'react-router-dom';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';

import 'theme/DatePicker.css';
import DatePicker from 'react-datepicker';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import KoalaToggleButton from 'koala/components/ToggleButton';

const PresetsBlock = styled.div`
  margin-bottom: 3.2rem;
  button {
    margin-right: 1.6rem;
    &:last-of-type {
      margin: 0;
    }
  }
`;

const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.half};

  .interval {
    width: 6rem;
  }
`;

const BackLink = styled(Link)`
  color: ${theme.colors.blue};
  margin-bottom: ${theme.spacing.x2};
  font-size: 1.8rem;
  font-weight: 600;
`;

const Form = styled.form`
  h2 {
    margin-bottom: ${theme.spacing.x3};
  }
  h3 {
    margin-bottom: ${theme.spacing.x2};
  }

  margin-bottom: ${theme.spacing.x6};
  button {
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
}

function Settings(props: Props) {
  const queryCache = useQueryCache();
  const { plan } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const _start = plan.start_at ? parseISO(plan.start_at) : null;
  const _finish = plan.finish_at ? parseISO(plan.finish_at) : null;
  const _reminders_start_at = plan.reminders_start_at ? parseISO(plan.reminders_start_at) : null;
  const [startAt, setStartAt] = useState(_start);
  const [finishAt, setFinishAt] = useState(_finish);
  const [title, setTitle] = useState(plan.title);
  const [remindersStartAt, setRemindersStartAt] = useState(_reminders_start_at);
  const [reminders_hour, setRemindersHour] = useState(plan.reminders_hour);
  const [reminders_period, setRemidersPeriod] = useState(plan.reminders_period);
  const [reminders_interval, setRemindersInterval] = useState(plan.reminders_interval);
  const [limitAtRiskEnabled, setLimitAtRiskEnabled] = useState(plan.limit_at_risk_enabled);
  const [limitAtRiskValue, setLimitAtRiskValue] = useState(plan.limit_at_risk_value ?? 3);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, plan.nano_slug];

  // Mutation that will update the plan in the backend
  const [updatePlanMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.updatePlan, {
    onSuccess: (planResponse) => {
      queryCache.setQueryData(queryKey, planResponse);
    },
  });

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'title':
        setTitle(value);
        break;
    }
  };

  const handleChangeStart = (date: any) => {
    setStartAt(date);
  };
  const handleChangeFinish = (date: any) => {
    setFinishAt(date);
  };

  const handleChangeRemindersStartAt = (date: any) => {
    setRemindersStartAt(date);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    const start_at = startAt ? startAt : null;
    const finish_at = finishAt ? finishAt : null;
    const reminders_start_at = remindersStartAt ? remindersStartAt : null;
    const limit_at_risk_value = limitAtRiskEnabled ? limitAtRiskValue : null;
    const planParams = {
      title,
      start_at,
      finish_at,
      reminders_hour,
      reminders_interval,
      reminders_period,
      reminders_start_at,
      limit_at_risk_enabled: limitAtRiskEnabled,
      limit_at_risk_value,
    };
    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  const setTimelineToCurrentQuarter = (e: any) => {
    const refDate = Date.now();
    const startAt = startOfQuarter(refDate);
    const finishAt = endOfQuarter(refDate);
    setStartAt(startAt);
    setFinishAt(finishAt);
  };

  const setTimelineToNextQuarter = (e: any) => {
    const refDate = addMonths(Date.now(), 3); // Add 3 months to get the next quarter
    const startAt = startOfQuarter(refDate);
    const finishAt = endOfQuarter(refDate);
    setStartAt(startAt);
    setFinishAt(finishAt);
  };

  const changeStatusEnabled = (e: React.MouseEvent<HTMLInputElement>) => {
    setLimitAtRiskEnabled(e.currentTarget.checked);
  };

  const titleClassname = errors['title'] ? 'error' : '';

  const minDate = startAt ? addDays(startAt, -8) : null;
  const maxDate = finishAt ? addDays(finishAt, 8) : null;

  // Displays the list of templates
  return (
    <SettingsNarrowWrapper>
      <BackLink to={`/${workspaceSlug}/plans/${plan.nano_slug}/outcomes`}>
        &larr; {t('planSettings.back', { title: plan.title })}{' '}
      </BackLink>
      <Form onSubmit={handleSave}>
        <h2>{t('planSettings.title', { title: plan.title })}</h2>

        <h3>{t('planSettings.details')}</h3>
        <FormField>
          <label>{t('planSettings.planTitle')}</label>
          <input
            type="text"
            name="title"
            className={`${titleClassname} small`}
            autoComplete="off"
            placeholder={t('planSettings.planPlaceholder') ?? 'Q1 Company OKRs, Q3 Product Goals...'}
            value={title}
            required={true}
            onChange={handleChange}
          />
        </FormField>
        <h3>{t('planSettings.timeline')}</h3>
        <FormField>
          <label>{t('planSettings.presets')}</label>
          <PresetsBlock>
            <KoalaButton onClick={setTimelineToCurrentQuarter} appearance="subtle">
              {t('planSettings.currentQuarter')}
            </KoalaButton>
            <KoalaButton onClick={setTimelineToNextQuarter} appearance="subtle">
              {t('planSettings.nextQuarter')}
            </KoalaButton>
          </PresetsBlock>
        </FormField>
        <FormField>
          <label>{t('shared.planStart')}</label>
          <DatePicker selected={startAt} onChange={handleChangeStart} dateFormat="d MMM yyyy" />
        </FormField>
        <FormField>
          <label>{t('shared.planFinish')}</label>
          <DatePicker selected={finishAt} onChange={handleChangeFinish} dateFormat="d MMM yyyy" />
        </FormField>
        <h3>{t('planSettings.workflow')}</h3>
        <FormField>
          <label>{t('planSettings.reminders')}</label>
          <InlineContainer>
            <span>{t('planSettings.sendReminders')}</span>
            <input
              type="number"
              required
              className="interval"
              value={reminders_interval}
              onChange={(e) => setRemindersInterval(parseInt(e.currentTarget.value))}
            />
            <select value={reminders_period} onChange={(e) => setRemidersPeriod(e.currentTarget.value)}>
              <option value="week">{t('shared.time.week', { count: reminders_interval })}</option>
              <option value="month">{t('shared.time.month', { count: reminders_interval })}</option>
            </select>
            <span>{t('shared.at')}</span>
            <div>
              <select
                value={reminders_hour}
                onChange={(e) => setRemindersHour(parseInt(e.currentTarget.value))}
                required
              >
                {Array(24)
                  .fill(0)
                  .map((_, i) => (
                    <option value={i} key={i}>
                      {i}
                    </option>
                  ))}
              </select>
              <span>:00</span>
            </div>
            <span>{t('planSettings.startingOn')}</span>
            <DatePicker
              selected={remindersStartAt}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleChangeRemindersStartAt}
              dateFormat="d MMM yyyy"
            />
          </InlineContainer>
        </FormField>

        <FormField>
          <label>{t('planSettings.limitAtRiskEnabled')}</label>
          <InlineContainer>
            <KoalaToggleButton handleChange={changeStatusEnabled} defaultChecked={limitAtRiskEnabled} />
            <span>{t('planSettings.limitAtRiskEnabledDetails')}</span>
          </InlineContainer>
          {limitAtRiskEnabled && (
            <InlineContainer>
              <span>{t('planSettings.limitAtRiskValueBefore')}</span>
              <input
                type="number"
                required
                className="interval"
                value={limitAtRiskValue}
                onChange={(e) => setLimitAtRiskValue(parseInt(e.currentTarget.value))}
                min={2}
              />
              <span>{t('planSettings.limitAtRiskValueAfter')}</span>
            </InlineContainer>
          )}
        </FormField>

        <Actions>
          <KoalaButton submit disabled={isLoading} loading={isLoading}>
            {t('shared.save')}
          </KoalaButton>
        </Actions>
      </Form>
    </SettingsNarrowWrapper>
  );
}

export default Settings;
