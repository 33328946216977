import React from 'react';
import * as TabilityTypes from 'types';
import theme from 'theme';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;

  const outcomeJQL = outcome.data_source_meta?.jql || '';
  // Query keys and query params
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:jira_cloud:jql${encodeURIComponent(outcomeJQL)}`,
    'current',
  ];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null) {
    return <FormField>Sorry, an error occurred while fetching the current value.</FormField>;
  }

  return (
    <FormField>
      <label>Preview value</label>
      <p>{currentValue} results found</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
}

function JiraSource(props: Props) {
  const { outcome } = props;

  const [data_source_meta, setDataSourceMeta] = React.useState<any>(outcome.data_source_meta);
  const outcomeJQL = outcome.data_source_meta?.jql || '';

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }]: [any, any] = useMutation(
    remoteApi.updateOutcomeDataSource,
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries(queryKeys.currentOutcome);
      },
    },
  );

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'jira_cloud',
      data_source_type: 'jql',
      data_source_meta,
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const jql = data_source_meta?.jql || '';
  return (
    <>
      <FormField>
        <label>JQL</label>
        <input
          value={jql}
          onChange={(e) => setDataSourceMeta({ jql: e.currentTarget.value })}
          placeholder="Write your JQL here"
        />
        <small style={{ marginTop: theme.spacing.x1 }}>
          Note: Tability does not valid your JQL, so make sure you test it in Jira first!
        </small>
      </FormField>
      {outcomeJQL !== jql && (
        <FormField>
          <KoalaButton onClick={handleUpdate} loading={isUpdating} disabled={!jql || isUpdating} appearance="subtle">
            Save & preview
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'jira_cloud' && outcomeJQL === jql && (
        <>
          <CurrentValue outcome={outcome} />
        </>
      )}
    </>
  );
}

export default React.memo(JiraSource);
