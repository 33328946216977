import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useLocation, Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import * as objectiveUtils from 'utils/objectiveUtils';
import * as textUtils from 'utils/textUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import ReactTooltip from 'react-tooltip';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import KoalaNCSPie from 'koala/components/NCSPie';
import KoalaIcon from 'koala/components/Icons';
import KoalaButton from 'koala/components/Button';

import { PanelGrid, PanelHeader, PanelContent } from 'components/GlobalPanel';
import Loader from 'components/Loader';
import OutcomesList from './OutcomesList';
import DataContainer from 'components/DataContainer';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Objective } from 'types';
import DropdownMenu from 'components/DropdownMenu';
import TagPickerObjective from 'components/TagPickerObjective';
import MarkdownContent from 'components/MarkdownContent';
import PlanPermissionSync from 'components/PlanPermissionSync';
import PlanIconLabel from 'components/PlanIconLabel';
import { Trans, useTranslation } from 'react-i18next';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaTextBadge from 'koala/components/TextBadge';
import ShareButton from 'components/ShareButton';
import * as routes from 'routes';
import Relationships from './Relationships';

const HeaderTitle = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
  .plan-link {
    font-weight: 600;
    color: ${theme.colors.subtleText};
  }

  @media ${theme.devices.mobile} {
    .hide-mobile {
      display: none;
    }
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ObjectiveMetrics = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${theme.spacing.x2};
  margin: ${theme.spacing.x2} 0;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x1};
`;

const ObjectiveTag = styled.div`
  margin-top: ${theme.spacing.x2};
`;
const DescriptionContainer = styled.div`
  margin-bottom: ${theme.spacing.x1};
`;

const ObjectiveLabel = styled.div`
  display: block;
  margin-bottom: ${theme.spacing.half};
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
`;

const Container = styled.div`
  display: flex;
  display: flex;
  min-height: 100%;
  @media ${theme.devices.tablet} {
    flex-direction: column;
  }
`;

const Main = styled.div`
  border-right: 1px solid ${theme.colors.blockBorder};
  min-height: 100%;
  padding: ${theme.spacing.x2};
  flex: 1;

  @media ${theme.devices.tablet} {
    border: 0;
  }
`;
const Meta = styled.div`
  min-width: 40rem;
  max-width: 40rem;
  width: 40rem;
  @media ${theme.devices.tablet} {
    width: 100%;
    max-width: 100%;
  }
`;

const MetaContentBlock = styled.div`
  border-bottom: 1px solid ${theme.colors.blockBorder};

  .icon-btn--square {
    transition: transform 0.2s ease;
    &:focus {
      outline: none;
      border: 0;
    }
  }

  &.expanded {
    .clickable {
      .icon-btn--square {
        transform: rotate(90deg);
      }
    }
  }

  h4 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 800;
  }

  b {
    display: block;
    margin: ${theme.spacing.x2} 0 ${theme.spacing.x1} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &.no-bottom-border {
    border: 0;
  }
`;

const MetaContentLine = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  gap: ${theme.spacing.x1};
  label {
    color: ${theme.colors.subtleText};
    width: 10rem;
    min-width: 10rem;
  }

  &.with-actions {
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.half};
    }
  }
  margin-bottom: ${theme.spacing.x1};
  &:last-of-type {
    margin-bottom: 0;
  }
  &.clickable {
    cursor: pointer;
    margin-bottom: 0;
    &:hover {
      background: ${theme.colors.N3};
    }
  }

  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

const MetaContentMain = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

interface Props {
  objectiveId: string;
}

function ObjectivePanel(props: Props) {
  const location = useLocation();
  const history = useHistory();
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [objective, setObjective] = useState<Objective | null>(null);
  const [hasEditPermission, setHasEditPermission] = useState(false);
  const { t } = useTranslation();
  const { objectiveId } = props;

  // React to the ESC key to hide the panel
  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.push(location.pathname);
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  // Query keys and query params
  const queryKey = [queryKeys.currentObjective, objectiveId];
  const staleTime = 0;

  const shareObjective = () => {
    const blockId = `objective:${objectiveId}`;
    dispatch(setGlobalModalContent(`${blockId}:share`));
  };

  // Get the outcome details
  useQuery(queryKey, remoteApi.fetchObjectiveDetails, {
    staleTime,
    onSuccess: (response) => {
      setObjective(response.data);
    },
  });

  const [archiveObjectiveMutation, { isLoading: isArchiving }] = useMutation(remoteApi.archiveObjective, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.objectives);
      queryCache.invalidateQueries([queryKeys.currentObjective, objectiveId]);
    },
  });

  const handleCancel = (e: Event) => {
    e.preventDefault();
    history.push(location.pathname);
  };

  // Now we can display the app with the Chrome
  if (!objective || isArchiving) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  const archiveObjective = () => {
    archiveObjectiveMutation({
      objectiveId: objectiveId,
      objective: {
        archived: true,
      },
    });
  };

  const unarchiveObjective = () => {
    archiveObjectiveMutation({
      objectiveId: objectiveId,
      objective: {
        archived: false,
      },
    });
  };

  const handleMenuSelection = (value: ReactElement) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'edit':
        const editHashRoute = `#objective:${objectiveId}:edit`;
        history.push(editHashRoute);
        break;
      case 'archive':
        archiveObjective();
        break;
      case 'unarchive':
        unarchiveObjective();
        break;
      case 'move':
        dispatch(setGlobalModalContent(`objective:${objective.nano_slug}:move`));
        break;
      case 'audit-log':
        dispatch(setGlobalModalContent(`audit:${objective.nano_slug}:show`));
        break;
    }
  };

  let menuItems: ReactElement[] = [];
  if (hasEditPermission) {
    menuItems.push(
      <span key="edit" data-action="edit" data-id={objective.nano_slug}>
        {t('shared.edit')}
      </span>,
      <span key="move" data-action="move" data-id={objective.nano_slug}>
        {t('shared.move')}
      </span>,
    );
    if (!objective.archived) {
      menuItems.push(
        <span key="archive" data-action="archive">
          {t('shared.archive')}
        </span>,
      );
    } else {
      menuItems.push(
        <span key="unarchive" data-action="unarchive">
          {t('shared.unarchive')}
        </span>,
      );
    }
    menuItems.push(
      <span key="audit-log" data-action="audit-log">
        {t('shared.audit')}
      </span>,
    );
  }

  const blockId = `objective:${objectiveId}`;

  const handleAddDependency = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(`${blockId}:add.dependency`));
  };

  const { plan } = objective;

  const confidenceData = objectiveUtils.getConfidenceData(objective);
  const outcomeProgressData = objectiveUtils.getOutcomeProgressData(objective);
  const initiativeProgressData = objectiveUtils.getInitiativeProgressData(objective);

  const hasDescription = objective.description && !textUtils.isEmptyHTMLText(objective.description);

  const objectiveLink = `https://${process.env.REACT_APP_DOMAIN}${routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(
    ':workspaceSlug',
    workspaceSlug,
  ).replace(':planId', plan.nano_slug)}#objective:${objective.nano_slug}:show`;

  return (
    <PanelGrid>
      <PanelHeader>
        <PlanPermissionSync planId={plan.id} setHasEditPermission={setHasEditPermission} />
        <Header>
          <HeaderTitle>
            <Link className="plan-link" to={`/${workspaceSlug}/plans/${objective.plan_id}/outcomes`}>
              <PlanIconLabel plan={plan} size="small" />
            </Link>
          </HeaderTitle>
          <HeaderActions>
            <ShareButton id={objective.id} link={objectiveLink} shareCallback={shareObjective} />
            {hasEditPermission && (
              <DropdownMenu
                items={menuItems}
                trigger={<KoalaIconButton iconName="ellipsis" />}
                onSelection={handleMenuSelection}
              />
            )}
            <KoalaIconButton onClick={handleCancel} iconName="close" />
          </HeaderActions>
        </Header>
      </PanelHeader>
      <PanelContent className="outcome">
        <Container>
          <Main>
            <HeaderTitle>
              <KoalaIcon iconName="flag" iconAppearance={theme.colors.T80} />
              <h2>{objective.title}</h2>
              {objective.archived && (
                <KoalaTextBadge className="archive-label" isLowercase={true} variant="violet-light">
                  {t('shared.status.archived')}
                </KoalaTextBadge>
              )}
            </HeaderTitle>

            <ObjectiveMetrics>
              <DataContainer>
                <h4>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</h4>
                <KoalaCircularProgress data={outcomeProgressData} size="large" />
              </DataContainer>
              <DataContainer>
                <h4>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</h4>
                <KoalaCircularProgress data={initiativeProgressData} size="large" />
              </DataContainer>
              <DataContainer>
                <h4>
                  {t('shared.ncs.full')}
                  <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
                </h4>
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  className="tooltip"
                  effect="solid"
                  id="explain-ncs"
                  delayHide={500}
                  clickable={true}
                >
                  <Trans i18nKey={'shared.ncs.tooltip'} components={{ br: <br /> }} />{' '}
                  <a
                    href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('shared.learnMore')}
                  </a>
                </ReactTooltip>
                <KoalaNCSPie
                  centerLabel={[Math.round(objective.ncs).toString(), t('shared.ncs.acronym')]}
                  data={confidenceData}
                  size="large"
                />
              </DataContainer>
            </ObjectiveMetrics>
            {hasDescription && (
              <DescriptionContainer>
                <ObjectiveLabel>{t('panels.description')}</ObjectiveLabel>
                {hasDescription && <MarkdownContent source={objective.description} />}
              </DescriptionContainer>
            )}
            <OutcomesList objectiveId={objectiveId} />
          </Main>
          <Meta>
            <MetaContentBlock className={`outcome-relationships-container`}>
              <MetaContentLine className="outcome-relationships-header with-actions">
                <h4>{t(`panels.outcome.relationships`)}</h4>
                {hasEditPermission && (
                  <KoalaButton
                    className="outcome-panel-add-relationship-button"
                    onClick={handleAddDependency}
                    appearance="secondary"
                    size="small"
                  >
                    {t(`panels.outcome.addRelationship`)}
                  </KoalaButton>
                )}
              </MetaContentLine>
              <MetaContentMain>
                <Relationships objective={objective} hasEditPermission={hasEditPermission} />
              </MetaContentMain>
            </MetaContentBlock>
            <MetaContentBlock>
              <MetaContentMain>
                <ObjectiveTag>
                  <ObjectiveLabel>{t('shared.tags')}</ObjectiveLabel>
                  <TagPickerObjective objective={objective} disabled={!hasEditPermission} />
                </ObjectiveTag>
              </MetaContentMain>
            </MetaContentBlock>
          </Meta>
        </Container>
      </PanelContent>
    </PanelGrid>
  );
}

export default React.memo(ObjectivePanel);
