import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import { ObjectiveStatsResult } from '.';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${theme.devices.mobile} {
    display: none;
  }
`;

const StatsCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  padding: ${theme.spacing.x2};
  border-radius: 4px;

  span {
    font-weight: 700;
    display: flex;
    font-size: 3.2rem;

    &.success {
      color: ${theme.colors.G50};
    }
    &.danger {
      color: ${theme.colors.R50};
    }
    &.warning {
      color: ${theme.colors.Y50};
    }
    &.waiting {
      color: ${theme.colors.subtleText};
    }
    &.subtle {
      opacity: 0.4;
    }
  }

  label {
    font-size: 1rem;
    font-weight: 800;
    letter-spacing: 1px;
    color: ${theme.colors.subtleText};
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    font-weight: 800;
  }

  &.clickable {
    cursor: pointer;
    &:hover {
      background: ${theme.colors.N3};
    }
    &:active,
    &:focus {
      background: ${theme.colors.B5};
    }
  }
`;

const SideStats = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
`;

const ProgressStat = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const DistributionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  .graph {
    grid-column: 2 / 6;
  }
`;

interface Props {
  objectiveStats: ObjectiveStatsResult | null;
  customFilterHash: {};
  updateURL: (newHash: {}) => void;
}

function SearchStats(props: Props) {
  const { objectiveStats, updateURL, customFilterHash } = props;
  const { t } = useTranslation();
  if (!objectiveStats) {
    return null;
  }

  const setCardFilter = (filter: any) => {
    const filterHash = {
      ...customFilterHash,
      ...filter,
    };
    updateURL(filterHash);
  };

  const lowClassname = objectiveStats.low_confidence > 0 ? 'danger' : 'subtle';
  const midClassname = objectiveStats.mid_confidence > 0 ? 'warning' : 'subtle';
  const highClassname = objectiveStats.high_confidence > 0 ? 'success' : 'subtle';
  let avgClassname = 'success';
  if (objectiveStats.avg_ncs < 0) {
    avgClassname = 'danger';
  } else if (objectiveStats.avg_ncs < 30) {
    avgClassname = 'warning';
  }

  return (
    <Container>
      <DistributionContainer>
        <StatsCard>
          <ProgressStat>
            <label>{t('workspaceFilters.avgNCS')}</label>
            <SideStats>
              <span className={avgClassname}>{Math.round(objectiveStats.avg_ncs)}</span>
            </SideStats>
          </ProgressStat>
        </StatsCard>
        <StatsCard
          className="clickable"
          onClick={() =>
            setCardFilter({
              confidence_below: 0,
              confidence_above_equal: null,
            })
          }
        >
          <label>{t('workspaceFilters.lowConfidence')}</label>
          <SideStats>
            <span className={lowClassname}>{objectiveStats.low_confidence}</span>
          </SideStats>
        </StatsCard>
        <StatsCard
          className="clickable"
          onClick={() =>
            setCardFilter({
              confidence_above_equal: 0,
              confidence_below: 30,
            })
          }
        >
          <label>{t('workspaceFilters.moderateConfidence')}</label>
          <SideStats>
            <span className={midClassname}>{objectiveStats.mid_confidence}</span>
          </SideStats>
        </StatsCard>

        <StatsCard
          className="clickable"
          onClick={() =>
            setCardFilter({
              confidence_below: null,
              confidence_above_equal: 30,
            })
          }
        >
          <label>{t('workspaceFilters.highConfidence')}</label>
          <SideStats>
            <span className={highClassname}>{objectiveStats.high_confidence}</span>
          </SideStats>
        </StatsCard>
      </DistributionContainer>
    </Container>
  );
}

export default SearchStats;
