import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from 'theme';
import { useTranslation } from 'react-i18next';

// Components
const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  a {
    text-decoration: underline;
  }
`;

function Login() {
  const { t } = useTranslation();
  // Displays the list of templates
  return (
    <Container>
      <span>{t('errors.accessDenied')}</span>
      <Link to="/">{t('shared.backDashboard')}</Link>
    </Container>
  );
}

export default Login;
