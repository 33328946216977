import React from 'react';
import theme from 'theme';
import { Team, Workspace } from 'types';
import styled from 'styled-components';
import * as workspaceUtils from 'utils/workspaceUtils';
import { shallowEqual, useSelector } from 'react-redux';
import DropdownMenu from 'components/DropdownMenu';
import KoalaIconButton from 'koala/components/IconButton';
import TeamTitle from 'components/TeamTitle';
import { useTranslation } from 'react-i18next';

const Container = styled.div<{ backgroundColor?: string; textColor?: string; maxWidth?: string }>`
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  padding: ${theme.spacing.x2};
  margin: ${theme.spacing.x3} 0px;
  border-radius: 8px;
  display: flex;
  align-items: start;
  justify-content: space-between;

  .AriaMenuButton {
    color: ${theme.colors.N100};
  }
`;
const Actions = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 8rem;
  max-width: 110rem;
  width: 100%;
  margin: 0 auto;
  font-size: 3.2rem;
  font-weight: 600;
`;

interface Props {
  team: Team;
  handleSetColor: () => void;
  handleDeleteTeam: () => void;
}

function TeamLabel(props: Props) {
  const { team, handleSetColor, handleDeleteTeam } = props;
  const { t } = useTranslation();
  const background = team.label_bg_color ?? theme.colors.N5;
  const text = team.label_text_color ?? theme.colors.N100;

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Create the menu
  const menuItems = [
    <span data-action="set-color">{t('workspaceTeams.teamMember.editColor')}</span>,
    <span data-action="delete-team">{t('workspaceTeams.teamMember.deleteTeam')}</span>,
  ];
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'delete-team':
        handleDeleteTeam();
        break;
      case 'set-color':
        handleSetColor();
        break;
    }
  };

  return (
    <Container backgroundColor={background} textColor={text}>
      <InnerContainer>
        <TeamTitle team={team} />
      </InnerContainer>
      <Actions>
        {hasEssentialsSubscription && (
          <DropdownMenu
            trigger={<KoalaIconButton iconName="ellipsis" iconColor={text} />}
            onSelection={handleMenuSelection}
            items={menuItems}
          />
        )}
      </Actions>
    </Container>
  );
}

export default TeamLabel;
