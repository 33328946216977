import React, { Fragment, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useInfiniteQuery, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import parse from 'parse-link-header';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Helmet } from 'react-helmet';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import * as workspaceUtils from 'utils/workspaceUtils';
import { format } from 'date-fns';
import download from 'downloadjs';

// API
import * as remoteApi from 'api/remote';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';

// Components
import { ChromeContent } from 'components/Chrome';
import ContentTitle from 'components/ContentTitle';
import Loader from 'components/Loader';
import _ from 'lodash';
import KoalaButton from 'koala/components/Button';
import KoalaAvatar from 'koala/components/Avatar';
import { KoalaSelectOption } from 'koala/components/Select';
import EmptyStatePanel from 'components/EmptyStatePanel';
import { useTranslation } from 'react-i18next';
import KoalaTextBadge from 'koala/components/TextBadge';
import DropdownMenu from 'components/DropdownMenu';
import KoalaIconButton from 'koala/components/IconButton';

import TeamFilter from './TeamFilter';
import InsightFilter from './InsightFilter';
import InviteTeaser from './InviteTeaser';

const Wrapper = styled.div`
  margin: 0 auto ${theme.spacing.x4} auto;
  max-width: 120rem;
  padding: 1.2rem ${theme.spacing.x2};

  h3 {
    padding: ${theme.spacing.x2} 0;
  }

  .outcome-header {
    display: flex;
    flex-wrap: wrap;
  }
`;

const FilterWrapper = styled.div`
  flex: 1;
  display: flex;
  gap: ${theme.spacing.x1};
  height: 32px;
`;

const ListingContainer = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
`;

const ListingLine = styled.div`
  border-bottom: 1px solid ${theme.colors.blockBorder};
  display: grid;
  grid-template-columns: 1fr 20rem;
  gap: ${theme.spacing.x2};
  align-items: center;
  padding: 1.2rem ${theme.spacing.x2};
  cursor: pointer;

  label {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  > div {
    display: flex;
    gap: ${theme.spacing.x1};
    align-items: center;
  }

  :hover {
    background: ${theme.colors.N3};
  }

  &.header {
    border-bottom: 0;
    :hover {
      background: none;
    }
  }
  &.selected,
  :focus,
  :active {
    background: ${theme.colors.B5};
  }

  :first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  :last-of-type {
    border-bottom: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x1};
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingsDetails(props: Props) {
  const { workspace } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const [teamsFilter, setTeamsFilter] = useState<KoalaSelectOption[]>();

  const hasV4Trial = workspaceUtils.hasV4Trial(workspace);

  let showInvites = true;
  const isAdmin = ['admin', 'owner'].includes(currentMembership.role);
  if (workspace.invites_restricted && !isAdmin) {
    showInvites = false;
  }

  // Params used for the search
  const [nameToSearch, setNameToSearch] = useState('');
  const [searchedMemberships, setSearchedMemberships]: any = useState([]);
  const [resultsCount, setResultsCount] = useState<string | null>(null);
  const teamIds: string[] = teamsFilter ? teamsFilter.map((option) => option.value as string) : [];
  const [insightFilter, setInsightFilter] = useState<string>('');

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const insightFilterURL = params.get('insight');

  useEffect(() => {
    if (insightFilterURL) {
      setInsightFilter(insightFilterURL);
      history.replace(`${location.pathname}`); // Must be a replace otherwise you can never go back
    }
  }, [insightFilterURL, location.pathname, history]);

  const insightFilterParam = () => {
    if (insightFilter === 'too_many_outcomes') {
      return {
        outcomes_count_above: 7,
      };
    }
    return null;
  };

  // Search query attributes
  const searchMembershipsQueryKey = [
    queryKeys.memberships,
    workspace.id,
    {
      name: nameToSearch,
      team_id: teamIds,
      ...insightFilterParam(),
      per_page: 25,
    },
  ];
  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  // Search params for search
  const { isLoading, isFetchingMore, fetchMore, canFetchMore } = useInfiniteQuery(
    searchMembershipsQueryKey,
    remoteApi.fetchWorkspaceMemberships,
    {
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
      onSuccess: (response) => {
        const resultsCount = response[0].headers['x-total'];
        setResultsCount(resultsCount);
        setSearchedMemberships(response);
      },
    },
  );

  // Mutation that will create the workspace in the backend
  const [exportPeopleMutation]: [any, any] = useMutation(remoteApi.exportWorkspaceMemberships, {
    onSuccess: (response: any) => {
      const content = response.headers['content-type'];
      const fileName = `people-${format(new Date(), 'Y-MM-dd')}.csv`;
      download(response.data, fileName, content);
    },
  });

  const handleExportPeople = () => {
    const filterParams = {
      name: nameToSearch,
      team_id: teamIds,
    };
    const mutationParams = {
      workspaceSlug: workspace.slug,
      filter: filterParams,
    };
    exportPeopleMutation(mutationParams);
  };

  // START SEARCH LOGIC
  const performSearch = (newName: string) => {
    setNameToSearch(newName);
  };

  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );

  const handleSearch = (e: any) => {
    const newName = e.target.value;
    debouncePerformSearch.current(newName);
  };

  const handleChangeTeamIds = (options: KoalaSelectOption[]) => {
    setTeamsFilter(options);
  };

  const handleInvite = (e: any) => {
    e.preventDefault();
    const action = `workspace:${workspace.slug}:invite`;
    dispatch(setGlobalModalContent(action));
  };

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'export':
        handleExportPeople();
        break;
    }
  };

  const menuItems = () => {
    const items = [];
    items.push(<span data-action="export">{t('shared.export')}</span>);
    return items;
  };

  const resultsCountInt = parseInt(resultsCount ?? '0', 0);

  return (
    <>
      <MobileReadyChromeHeader>
        <WorkspaceHeader
          workspace={workspace}
          useGreyBackground={true}
          title={
            <h1>
              {t('workspacePeople.title')} ({resultsCount})
            </h1>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeContent isGreyBackground>
        <Helmet>
          <title>
            {workspace.name} | {t('workspacePeople.title')} | Tability
          </title>
        </Helmet>
        <Wrapper>
          {hasV4Trial && resultsCountInt < 3 && <InviteTeaser />}
          {resultsCountInt > 1 && (
            <ContentTitle>
              <FilterWrapper>
                <input
                  type="text"
                  onChange={handleSearch}
                  placeholder={t('workspacePeople.filterUsers') ?? 'Filter users'}
                  className="tiny"
                />
                <TeamFilter handleChange={handleChangeTeamIds} value={teamsFilter ?? []} />
                <InsightFilter handleChange={setInsightFilter} value={insightFilter} />
              </FilterWrapper>
              <HeaderActions>
                {showInvites && <KoalaButton onClick={handleInvite}>{t('workspacePeople.inviteButton')}</KoalaButton>}
                <DropdownMenu
                  trigger={<KoalaIconButton iconName="ellipsis" />}
                  onSelection={handleMenuSelection}
                  items={menuItems()}
                />
              </HeaderActions>
            </ContentTitle>
          )}
          {searchedMemberships.length > 0 && (
            <ListingLine className="header">
              <label>{t('workspacePeople.peopleHeaderUser')}</label>
              <label>
                {t('workspacePeople.peopleHeaderOutcomes', {
                  outcomes: translate(workspace, CustomTermKey.OUTCOME, 2),
                })}
              </label>
            </ListingLine>
          )}
          <ListingContainer>
            {isLoading && <Loader size="medium" />}
            {!isLoading && resultsCount === '0' && <EmptyStatePanel>{t('workspacePeople.emptyState')}</EmptyStatePanel>}
            {searchedMemberships.map((group: any, i: number) => {
              return (
                <Fragment key={i}>
                  {group.data.map((membership: TabilityTypes.Membership) => {
                    const { user } = membership;
                    const name = user.fullname || user.email;
                    const path = `/${workspace.slug}/people/${membership.id}`;
                    return (
                      <ListingLine key={membership.id} onClick={() => history.push(path)}>
                        <div>
                          <KoalaAvatar user={user} size={3.2} />
                          <Link to={path}>{name}</Link>
                        </div>
                        <div>
                          <KoalaTextBadge backgroundColor={theme.colors.R10} textColor={theme.colors.R80}>
                            {membership.red_outcomes_count}
                          </KoalaTextBadge>
                          <KoalaTextBadge backgroundColor={theme.colors.Y10} textColor={theme.colors.Y80}>
                            {membership.yellow_outcomes_count}
                          </KoalaTextBadge>
                          <KoalaTextBadge backgroundColor={theme.colors.G10} textColor={theme.colors.G80}>
                            {membership.green_outcomes_count}
                          </KoalaTextBadge>
                          <KoalaTextBadge backgroundColor={theme.colors.N10} textColor={theme.colors.N80}>
                            {membership.grey_outcomes_count}
                          </KoalaTextBadge>
                        </div>
                      </ListingLine>
                    );
                  })}
                </Fragment>
              );
            })}
            {canFetchMore && (
              <LoadMore>
                <KoalaButton onClick={() => fetchMore()} appearance="secondary">
                  {t('shared.loadMore')}
                </KoalaButton>
                {isFetchingMore && <Loader size="small" />}
              </LoadMore>
            )}
          </ListingContainer>
        </Wrapper>
      </ChromeContent>
    </>
  );
}

export default WorkspaceSettingsDetails;
