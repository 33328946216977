import React, { ReactElement } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import * as remoteApi from 'api/remote';
import * as routes from 'routes';
import queryKeys from 'config/queryKeys';
import { Membership, Segment } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import DropdownMenu from 'components/DropdownMenu';
import KoalaIconButton from 'koala/components/IconButton';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';

const SegmentContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

interface Props {
  workspaceSlug: string;
  segment: Segment;
  filterHash: string;
}

function ViewBlock(props: Props) {
  const { segment, filterHash, workspaceSlug } = props;
  const queryCache = useQueryCache();
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const path = routes.WORKSPACE_MAP_V2_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug);

  const [updateSegmentMutation, { isLoading }] = useMutation(remoteApi.updateSegment, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.segments);
      queryCache.invalidateQueries([queryKeys.segments, segment.id]);
    },
  });
  const [deleteSegmentMutation] = useMutation(remoteApi.deleteSegment, {
    onSuccess: () => {
      const filterPath = path.replace(':filter', filterHash);
      history.push(filterPath);
      queryCache.invalidateQueries(queryKeys.segments);
    },
  });

  const updateSegment = () => {
    if (!segment) return;
    const params = {
      id: segment.id,
      filterHash: filterHash,
      title: segment.title,
    };
    updateSegmentMutation(params);
  };
  const handleDeleteSegment = () => {
    const text = t('workspaceMap.deleteView') ?? 'Delete this view?';
    if (window.confirm(text)) {
      deleteSegmentMutation(segment.id);
    }
  };

  const handleRenameSegment = () => {
    dispatch(setGlobalModalContent(`segment:${segment.nano_slug}:rename`));
  };

  const handleSelection = (value: ReactElement) => {
    const action = value.key;
    switch (action) {
      case 'delete':
        handleDeleteSegment();
        break;
      case 'rename':
        handleRenameSegment();
        break;
    }
  };

  const isOwner = segment.membership.id === currentMembership.id;
  const canUpdate = segment.filter_hash !== filterHash && isOwner;

  const dropdownItems = [
    <span key="rename">{t('shared.rename')}</span>,
    <span key="delete">{t('shared.delete')}</span>,
  ];

  return (
    <SegmentContainer>
      <h3>{t('workspaceMap.view', { view: segment.title })}</h3>
      {isLoading && <KoalaLoader />}
      {canUpdate && <KoalaTextButton onClick={updateSegment}>{t('shared.update')}</KoalaTextButton>}
      {isOwner && (
        <DropdownMenu
          items={dropdownItems}
          onSelection={handleSelection}
          position="left"
          trigger={<KoalaIconButton iconName="ellipsis" />}
        />
      )}
    </SegmentContainer>
  );
}

export default ViewBlock;
