import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import * as amplitudeUtils from 'utils/amplitude';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';
import { workspaceEditorActions } from 'state/actions';

// Header component
import WorkspaceHeaderWriteMode from 'components/WorkspaceHeaderWriteMode';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';

// Components
import AccessDenied from 'components/AccessDenied';
import { ChromeContent } from 'components/Chrome';
import Loader from 'components/Loader';
import NotFound from 'components/NotFound';

// Editor components
import Editor from './Editor';
import EditorSimplified from './EditorSimplified';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

function PlanWrite() {
  const dispatch = useDispatch();
  const { planId } = useParams<{ planId: string }>();
  const [plan, setPlan]: any = useState(null);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plan.write.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Construct the query key using the plan Id
  const staleTime = 0;
  useEffect(() => {
    dispatch(workspaceEditorActions.resetPlan());
    return () => {
      dispatch(workspaceEditorActions.resetPlan());
    };
  }, [dispatch]);

  // Get the plan details
  const {
    isLoading: isLoadingPlan,
    isError,
    error,
  }: any = useQuery([queryKeys.currentPlan, planId], remoteApi.fetchPlanDetails, {
    staleTime: 0,
    onSuccess: (response) => {
      const { data } = response;
      dispatch(workspaceEditorActions.loadPlan(data));
      setPlan(data);
    },
  });

  // Get the objectives
  const { isLoading: isLoadingObjectives } = useQuery([queryKeys.objectives, planId], remoteApi.fetchPlanObjectives, {
    staleTime,
    onSuccess: (response) => {
      const { data } = response;
      dispatch(workspaceEditorActions.loadObjectives(data));
    },
  });

  // Get the outcomes
  const { isLoading: isLoadingOutcomes } = useQuery([queryKeys.outcomes, planId], remoteApi.fetchPlanOutcomes, {
    staleTime,
    onSuccess: (response) => {
      const { data } = response;
      dispatch(workspaceEditorActions.loadOutcomes(data));
    },
  });

  // Get the initiatives
  const params = {
    state: 'open',
  };
  const { isLoading: isLoadingInitiatives } = useQuery(
    [queryKeys.initiatives, planId, params],
    remoteApi.fetchPlanInitiatives,
    {
      staleTime,
      onSuccess: (response) => {
        const { data } = response;
        dispatch(workspaceEditorActions.loadInitiatives(data));
      },
    },
  );

  const currentPlan = useSelector((state: any) => {
    if (plan) {
      return state.editorEntities.plans[plan.id];
    }
    return null;
  });

  // Set the filter for the review comments (only fetch unresolved comments)
  const reviewCommentFilter = {
    resolved: false,
  };
  useQuery([queryKeys.reviewComments, planId, reviewCommentFilter], remoteApi.fetchPlanReviewComments, {
    staleTime,
    onSuccess: (response) => {
      dispatch(workspaceEditorActions.loadReviewComments(response.data));
    },
  });

  // Set the filter for the review comments (only fetch unresolved comments)
  const resolvedCommentFilter = {
    resolved: true,
  };
  useQuery([queryKeys.reviewComments, planId, resolvedCommentFilter], remoteApi.fetchPlanReviewComments, {
    staleTime,
    onSuccess: (response) => {
      dispatch(workspaceEditorActions.loadResolvedComments(response.data));
    },
  });

  const isLoadingItems =
    !currentPlan || isLoadingPlan || isLoadingObjectives || isLoadingOutcomes || isLoadingInitiatives;

  // Now we can display the app with the Chrome
  if (isLoadingItems && !isError) {
    return (
      <Fragment>
        <ChromeContent>
          <LoadingContainer>
            <Loader size="big" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return (
        <ChromeContent isGreyBackground>
          <AccessDenied />
        </ChromeContent>
      );
    }
    return <NotFound />;
  }

  if (currentPlan.plan_type === 'simplified') {
    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | Edit {plan.title} | Tability
          </title>
        </Helmet>
        <MobileReadyChromeHeader className="no_border">
          <WorkspaceHeaderWriteMode />
        </MobileReadyChromeHeader>
        <ChromeContent>
          <EditorSimplified plan={plan} />
        </ChromeContent>
      </Fragment>
    );
  }

  // Displays the list of templates
  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | Edit {plan.title} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader className="no_border">
        <WorkspaceHeaderWriteMode />
      </MobileReadyChromeHeader>
      <ChromeContent>
        <Editor plan={plan} />
      </ChromeContent>
    </Fragment>
  );
}

export default PlanWrite;
