// This components can be used anytime you want to also have mentions.
import React, { useRef } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
// API
import * as remoteApi from 'api/remote';

import PlanIconLabel from 'components/PlanIconLabel';
import KoalaSelect from 'koala/components/Select';
import { useTranslation } from 'react-i18next';
import queryKeys from 'config/queryKeys';

interface Props {
  selectedValue: { value: string; label: string; icon: string }[];
  handleChange: any;
}
function PlanFilter(props: Props) {
  const { selectedValue, handleChange } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const debouncedLoadOptions: any = useRef(
    _.debounce((inputValue: string, callback: any) => loadOptions(inputValue, callback), 200, {
      maxWait: 2000,
    }),
  );

  // This function is going to fetch the list of plan using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  const loadOptions = (inputValue: string, callback: any) => {
    const filter = {
      archived: false,
      is_in_progress: true,
      title: '',
    };
    if (inputValue) {
      filter['title'] = inputValue;
    }
    // Get default users if nothing selected yet
    const planParams = {
      workspaceSlug,
      filter,
      per_page: 10,
    };
    remoteApi
      .searchPlans(queryKeys.plans, 'search', planParams, 0)
      .then((response) => {
        const plans = response.data;
        if (plans && plans.length > 0) {
          const options = plans.map((plan: any) => {
            return {
              value: plan.id,
              label: plan.title,
              icon: plan.plan_icon,
            };
          });
          return options;
        } else {
          return [];
        }
      })
      .then(callback);
    return;
  };

  const formatOption = (option: any) => {
    return <PlanIconLabel planIcon={option.icon} planTitle={option.label} size="xsmall" />;
  };

  return (
    <KoalaSelect
      handleChange={handleChange}
      placeholder={t('workspaceInsights.filterPlans')}
      selectedOption={selectedValue}
      isClearable
      isMultiSelect
      loadOptions={debouncedLoadOptions.current}
      id="plan"
      labelFormat={formatOption}
    />
  );
}

export default React.memo(PlanFilter);
