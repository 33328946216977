import React, { useEffect } from 'react';
import * as amplitudeUtils from 'utils/amplitude';
import styled from 'styled-components';
import theme from 'theme';

import GoalSettingImg from './goal_setting.png';
import GoalTrackingImg from './goal_tracking.png';
import LiveCoachingImg from './live_coaching.png';
import RunningPilotImg from './running_pilot.png';
//import SettingUpImg from './setting_up.png';
import GettingStartedImg from './getting_started.png';

const Card = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
  > div {
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
  }
  img {
    height: 6rem;
  }

  span {
  }

  .title {
    font-size: 2.4rem;
    font-weight: 600;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x4};
  padding: ${theme.spacing.x3} 0;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  a {
    color: ${theme.colors.blue};
    &:hover {
      text-decoration: underline;
    }
    margin-left: ${theme.spacing.x10};
  }
`;

const coachingContent = [
  {
    title: 'How to implement stress-free OKRs',
    description: 'A 30min talk to make OKRs 10x easier to manage',
    link: 'https://my.demio.com/ref/NEPvbaBF4WOJxhzU',
    icon: '👀',
  },
];

const startContent = [
  {
    title: 'Watch a complete demo',
    description: 'A complete demo of Tability on demand',
    link: 'https://guides.tability.app/docs/tability-accelerator/getting-started/watch-a-demo',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252FtuMy70YHzqLcsNehoGsW%252FScreenshot%25202024-05-02%2520at%25202.44.56%2520PM.png%3Falt%3Dmedia%26token%3Df1d48c14-90a2-4b8d-933d-54ff69557762&width=245&dpr=1&quality=100&sign=bae801cd7d5d63e2f9920c17a421e32ad1f511d8bcf78a04ec1d360bfce45f5d',
  },
  {
    title: 'How does Tability work?',
    description: 'Understand the basics of Tability',
    link: 'https://guides.tability.app/docs/about-tability/how-does-tability-work',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252FxMWGbakOBCKZNhjgbHl0%252Fimage.png%3Falt%3Dmedia%26token%3Dc2d69de8-89b8-4421-9fcf-01689920001a&width=768&dpr=1&quality=100&sign=ad6b84f6cfd3f5464efc3d0b0be019d8f6e75d3212d4d633efd8e4db2e5dc055',
  },
  {
    title: 'Watch tutorials from Tability University',
    description: 'Video library to help you learn the product',
    link: 'https://guides.tability.app/docs/tability-university',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252Flj3KYJm2jF29QrOzjM40%252FTabbyTeachGreen.png%3Falt%3Dmedia%26token%3Dcd1cbd8c-0630-4e4d-953c-7e5f70c867da&width=245&dpr=1&quality=100&sign=33970b306388f44cbee4e6d8f484984f72926f1f6684113eda14dae2d0d04e93',
  },
  {
    title: 'Schedule an onboarding call',
    description: 'Learn Tability from the experts',
    link: 'https://calendly.com/tability/expert-onboarding',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252F4YtEwBijYNokkI7dYi4w%252FScreenshot%25202024-05-02%2520at%25202.51.43%2520PM.png%3Falt%3Dmedia%26token%3D8c437366-bc49-49e6-9b2a-d4e55832f88c&width=245&dpr=1&quality=100&sign=1eccc20b6619fe26303fd041bf96d05d174b1aab9be6d0da79faab274c6fd30a',
  },
  {
    title: 'Get the mobile app',
    description: 'Get your goals in your pocket',
    link: 'https://www.tability.io/features/mobile',
    image:
      'https://assets-global.website-files.com/5f21d1ffffa8639e7606ea49/63ee80981fac140fbdfc6988_tability-cowboy-tabby%20(1)-p-500.png',
  },
  {
    title: 'Subscribe to our blog',
    description: 'Outcome Driven Teams (ODT) is your home for goal setting content',
    link: 'https://www.tability.io/odt/subscribe',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252Fzgsc9utWa16iPksQNElm%252FScreenshot%25202024-05-02%2520at%25202.53.21%2520PM.png%3Falt%3Dmedia%26token%3D52ae56e6-a23e-42dd-b10c-3450a057152d&width=245&dpr=1&quality=100&sign=2fc8c6c584d451c2d69105b6c4ad31f85c7aec6b32e45b10203d4a4e916218c0',
  },
  {
    title: 'Frequently Asked Questions',
    description: 'Get quick answers to our most common questions',
    link: 'https://guides.tability.app/docs/tability-accelerator/getting-started/tability-frequently-asked-questions',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252FfXLieBpc61su4qf9raMd%252Ftabyfaq.png%3Falt%3Dmedia%26token%3Dedbd73f0-1410-491c-881e-3c5fb44709b6&width=245&dpr=1&quality=100&sign=cf52326497c8fe37731568c2a423a1770903ce687bff6edae22510fa2d5ce425',
  },
];
const goalsettingContent = [
  {
    title: 'Goal setting Masterclass',
    description: 'Learn how to set great goals in 20 minutes',
    link: 'https://www.loom.com/share/237ad04ff46a45de84d41866cd21a160?sid=cec71514-fde2-4b7d-aa20-eb612f40e2d5',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252F5LDqb3EyRkOVKY8wp1qD%252FScreenshot%25202024-05-02%2520at%25203.25.09%2520PM.png%3Falt%3Dmedia%26token%3D0f1595e2-f4b1-4e62-9696-d9d861d7f294&width=245&dpr=1&quality=100&sign=c9bc07f0c2bad80362c63d9ee5c38a8484e611184178c8d57c0adb9c074099ed',
  },
  {
    title: 'Recommended structure for your workspace',
    description: 'Our recommendations to simplify your workspace structure',
    link: 'https://guides.tability.app/docs/tutorials/recommended-structure-for-your-workspace',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252FfrwqrezB8mziwAqUhc24%252Fimage-5.png%3Falt%3Dmedia%26token%3De3d27e5d-c3c1-4f82-97b0-a1e6c98d2e98&width=768&dpr=1&quality=100&sign=12576f4d52ab9ea25e9ee0444626fab0ea5ddfd00971a341fb8e72596ddd795b',
  },
  {
    title: "Tability's OKRs open sourced",
    description: 'See the process that we took to define our own top-level OKRs (including the OKRs)',
    link: 'https://www.tability.io/odt/articles/open-sourcing-our-top-level-okrs',
    image:
      'https://assets-global.website-files.com/5f7cf835bc0fc5eb13e5c57d/662e386cc7c646eb7c570e5d_benchmarks-cover(1)(1)-p-1080.png',
  },
  {
    title: "New to OKRs? Here's a plan that will work for 93% of startups",
    description: 'A simple OKR template that you can use for your company-level OKRs',
    link: 'https://medium.com/tability/new-to-okrs-heres-a-plan-that-will-work-for-93-of-startups-20678872942',
    image: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*Qs0O9ySBZ-5y0PDQHUnOoQ.png',
  },
  {
    title: 'Cascading vs. aligning OKRs',
    description: "See why it's best to align rather than cascade OKRs",
    link: 'https://www.tability.io/okrs/cascading-vs-aligning-okrs',
    image:
      'https://assets-global.website-files.com/5f7cf835bc0fc5eb13e5c57d/6170f692d2a15b094d7be8ea_cascading_vs_aligning.png',
  },
  {
    title: 'Aligning team goals to company goals in Tability',
    description: 'Learn Tability from the experts',
    link: 'https://guides.tability.app/docs/tability-accelerator/goal-setting/aligning-team-goals-to-company-goals',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2Flh7-us.googleusercontent.com%2FE2vj4de4wloHMT2Uib4-_8zfCetJfOCOFyFIrt8bpbKhzlWL37orX7IixRkMO1eN03SM9oYDQl__Y-5n_k0CO7U6ZWUeY8aYrwB1E1A3ApjHOvXOy39WhZ1wYZj9sp2sNa1lB0PZnPQd8vw4jy5majg&width=768&dpr=1&quality=100&sign=fe268262ad0c48f1db0548eb417fa267a8239dae4f21c2a6eb148ab038d63d20',
  },
  {
    title: 'Setting OKRs for projects',
    description: '3 case studies on how to set OKRs for projects',
    link: 'https://www.tability.io/odt/articles/how-to-set-okrs-when-your-key-result-is-a-project',
    image: 'https://assets-global.website-files.com/5f7cf835bc0fc5eb13e5c57d/65fadd3c7ee0045f6ab4b17b_tbg_okrs.png',
  },
  {
    title: 'Goal setting resources',
    description: 'OKRs templates, KPIs library, AI goal generator, and benchmarks',
    link: 'https://guides.tability.app/docs/tability-accelerator/goal-setting/goal-setting-resources',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252FMUQPBI0hl7aNB3xa3fVx%252FSimple_OKRs_examples_for_every_team___Tability.png%3Falt%3Dmedia%26token%3Dcc8e28ac-2b35-4472-90cc-b0a11d6f44f3&width=245&dpr=1&quality=100&sign=968bb77066f6712e34261007fbe1be89217785b09f8f6b72f17864254285ae0f',
  },
];

const goaltrackingContent = [
  {
    title: 'Goal execution Masterclass',
    description: 'A webinar to dive into running your goal process and helping your team be successful',
    link: 'https://www.loom.com/share/e6303a0eae664364ac478c515756151c',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252FpDyvFqG1ce1XnhS0HsY4%252FScreenshot%25202024-05-09%2520at%25206.04.19%2520PM.png%3Falt%3Dmedia%26token%3De6720b58-10dc-4f9b-a099-48d4f6529aa1&width=245&dpr=1&quality=100&sign=07778010fe7420c51363de39845ad16aa6a9739f60109f1859eb217f475db711',
  },
  {
    title: 'Running a weekly routine',
    description: 'Build healthy goal-tracking habits with our weekly process template',
    link: 'https://guides.tability.app/docs/tability-accelerator/goal-execution/running-a-weekly-routine',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252FnUxLCmqF9rKrmar8c1Vk%252Fimage.png%3Falt%3Dmedia%26token%3D86b504c8-17e7-4282-b6b6-9b3399dd5451&width=768&dpr=1&quality=100&sign=c93cc2785471ad348c5ff7b2f8525c8443acb2a273f82d17570614defee13ea6',
  },
  {
    title: 'Writing check-ins in Tability',
    description: 'Best practices for your weekly check-ins + template',
    link: 'https://guides.tability.app/docs/tability-accelerator/goal-execution/writing-check-ins-in-tability',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252FToCDT5mMfPgBHaequydv%252FCheck-ins_guide_-_Google_Docs.png%3Falt%3Dmedia%26token%3Db4f3f28a-4463-4d65-9aea-ea14763b8cd3&width=245&dpr=1&quality=100&sign=6744d834f41922796420f0ca96ea490e767387ca60d0305226172d1c652ea2d0',
  },
  {
    title: 'How to automate check-ins',
    description: 'Save time by pulling data automatically from your preferred tools',
    link: 'https://guides.tability.app/docs/tutorials/track-progress-with-check-ins/automate-check-ins',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252FF3lEgxPodPMOy8EDZFM8%252FTabbytiedpink.png%3Falt%3Dmedia%26token%3D52ef9b9c-6beb-4331-8ece-50f817264d00&width=245&dpr=1&quality=100&sign=1fbb616a2193e5bd5ba0d358642eccf025f33dbebadf570e3a3ab5bcdfde26e2',
  },
  {
    title: 'OKR scoring guide',
    description: 'See why you should not score OKRs like Google',
    link: 'https://www.tability.io/okrs/how-to-score-your-okrs',
    image:
      'https://assets-global.website-files.com/5f7cf835bc0fc5eb13e5c57d/616386f7850d0781f35dfb25_dontscorelikegoogle.png',
  },
  {
    title: 'Sharing updates with stakeholders',
    description: 'Take a look at how you can share digest and reports from Tability',
    link: 'https://guides.tability.app/docs/tability-accelerator/goal-execution/sharing-your-goals-with-stakeholders',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2Flh7-us.googleusercontent.com%2FW-VZIOVcXnWknTQKLXnHuEWSu1SrAuRkGuWWw__uNPfhRfy8OqzCwtJKtoUuRAeNmHVR74pY9cgn-7RM0UI5KkrUgP7hz5B5p7LKsxfSQslrdYNKdAzBcuXT8QuQs9SWqN5FL8vg6neC-rLRCNlMD9c&width=768&dpr=1&quality=100&sign=98a5ea53b734f3a9b2732b4dd8f0bcda1338556219c9ff832579d9daffbe768a',
  },
];
const pilotContent = [
  {
    title: 'How to be successful with Tability',
    description: 'Become a Tability champion in 20 minutes',
    link: 'https://www.loom.com/share/81798863de7d427ca373b4de281eef83?sid=36924920-7ed6-4298-8aac-fbbdf71c6d73',
    image:
      'https://www.gitbook.com/cdn-cgi/image/width=371,dpr=2,format=auto/https%3A%2F%2Ffiles.gitbook.com%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252FBV8qe0TJXbL7yykqCZvh%252FScreen%2520Shot%25202024-05-08%2520at%252011.56.50%2520am%2520(2).png%3Falt%3Dmedia%26token%3De2492989-49e7-4eb8-b185-f81f880912c5',
  },
  {
    title: 'Copy the Pilot Checklist',
    description: "Use the Pilot Checklist to make sure that you're all set for success",
    link: 'https://docs.google.com/spreadsheets/d/1xkrUPzGSQd8X6XAxxNQeLwDzPbO3qqORL6u1agOvo3I/edit#gid=0',
    image:
      'https://guides.tability.app/~gitbook/image?url=https%3A%2F%2F318707989-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FMULqjSmZyGczPy2I6FGH%252Fuploads%252FB0CnxgjcWu2jc7SCxtvv%252FScreen%2520Shot%25202024-05-08%2520at%252012.17.40%2520am.png%3Falt%3Dmedia%26token%3Df32801c2-7992-4d4c-9d85-db431f68356a&width=245&dpr=1&quality=100&sign=d4d3d88b3609217923d2a0e62b9a4289443839d592158696b0db53f1d3598e24',
  },
  {
    title: 'Read our best practices to simplify OKRs',
    description: '4 principles and 15 rules to make OKRs easier to adopt',
    link: 'https://www.tability.io/okrs/introduction-to-flowing-okrs-a-pragmatic-implementation-of-okrs',
    image: 'https://assets-global.website-files.com/5f7cf835bc0fc5eb13e5c57d/6331b3442d1a56fd7038289b_image%2011.png',
  },
];

function Quickstart() {
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.accelerator.home.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const logAmplitudeVisit = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const href = e.currentTarget.href;
    amplitudeUtils.sendAmplitudeData('app.workspace.accelerator.link.click', {
      'Tability Platform': 'web',
      'Tability Version': 2,
      href: href,
    });
  };

  return (
    <Container>
      <Section>
        <Card>
          <img src={LiveCoachingImg} alt="Live coaching" />
          <div>
            <span className="title">Live coaching & webinars</span>
            <span className="text">Demo, Q&A, onboarding and coaching by the Tability team</span>
          </div>
        </Card>
        {coachingContent.map((item: any, index: number) => (
          <a href={item.link} key={index} target="_blank" rel="noreferrer" onClick={logAmplitudeVisit}>
            {index + 1}. <b>{item.title}:</b> {item.description}
          </a>
        ))}
      </Section>
      <Section>
        <Card>
          <img src={GettingStartedImg} alt="Getting Started" />
          <div>
            <span className="title">Getting started</span>
            <span className="text">Your starting point</span>
          </div>
        </Card>
        {startContent.map((item: any, index: number) => (
          <a href={item.link} key={index} target="_blank" rel="noreferrer" onClick={logAmplitudeVisit}>
            {index + 1}. <b>{item.title}:</b> {item.description}
          </a>
        ))}
      </Section>
      <Section>
        <Card>
          <img src={RunningPilotImg} alt="Running a pilot" />
          <div>
            <span className="title">Running a successful pilot</span>
            <span className="text">Checklist, best practices, tips</span>
          </div>
        </Card>
        {pilotContent.map((item: any, index: number) => (
          <a href={item.link} key={index} target="_blank" rel="noreferrer" onClick={logAmplitudeVisit}>
            {index + 1}. <b>{item.title}:</b> {item.description}
          </a>
        ))}
      </Section>
      <Section>
        <Card>
          <img src={GoalSettingImg} alt="Setting goals" />
          <div>
            <span className="title">Setting goals</span>
            <span className="text">How to find the right goals and metrics to track</span>
          </div>
        </Card>
        {goalsettingContent.map((item: any, index: number) => (
          <a href={item.link} key={index} target="_blank" rel="noreferrer" onClick={logAmplitudeVisit}>
            {index + 1}. <b>{item.title}:</b> {item.description}
          </a>
        ))}
      </Section>
      <Section>
        <Card>
          <img src={GoalTrackingImg} alt="Tracking goals" />
          <div>
            <span className="title">Tracking goals</span>
            <span className="text">How to write check-ins and share feedback</span>
          </div>
        </Card>
        {goaltrackingContent.map((item: any, index: number) => (
          <a href={item.link} key={index} target="_blank" rel="noreferrer" onClick={logAmplitudeVisit}>
            {index + 1}. <b>{item.title}:</b> {item.description}
          </a>
        ))}
      </Section>
    </Container>
  );
}

export default Quickstart;
