import React from 'react';
import yaml from 'js-yaml';
import styled from 'styled-components';
import theme from 'theme';
import { Link } from 'react-router-dom';
import * as TabilityTypes from 'types';
import KoalaTextBadge from 'koala/components/TextBadge';

import { parseISO, formatDistanceToNow } from 'date-fns';

// Custom components

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 1.6rem;
  font-weight: 700;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const PlanWrapper = styled.div`
  background: ${theme.colors.N5};
  border: 1px solid ${theme.colors.N10};
  border-radius: 4px;
  padding: ${theme.spacing.x2};
  height: 15rem;
  overflow: hidden;
  margin: ${theme.spacing.x2} 0;

  &:hover {
    background: ${theme.colors.N10};
  }
  box-shadow: inset 0 0 10px ${theme.colors.N10};
`;
const PlanContent = styled.div`
  transform: scale(1);
`;

const LineGrid = styled.div`
  display: grid;
  grid-template-columns: 9rem 1fr;
  grid-template-rows: auto;
  column-gap: ${theme.spacing.x1};
  align-items: flex-start;
  margin-bottom: ${theme.spacing.x1};
  font-size: 1.2rem;

  > div {
    display: flex;
    &:first-of-type {
      justify-content: flex-end;
    }
  }
  span {
    position: relative;
    top: 2px;
    font-family: monospace, 'Courier New';
  }
`;

const Label = styled.div`
  font-size: 0.8rem;
  letter-spacing: 1px;
  color: ${theme.colors.N100};
  background-color: ${theme.colors.T40};
  border: 0;
  border-radius: 8px;
  height: 2rem;
  line-height: 2rem;
  padding: 0 ${theme.spacing.x1};
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;

  &.objective {
    background-color: ${theme.colors.T40};
  }

  &.outcome {
    font-size: 1rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0 ${theme.spacing.x1};
    background-color: ${theme.colors.Y40};
    color: ${theme.colors.N100};
    letter-spacing: 0;
  }
`;

const TaskLabel = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1rem;
  min-width: 2rem;
  min-height: 2rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.subtleText};
  background: #fff;
  border: 2px solid ${theme.colors.subtleText};

  border-radius: 50%;
  font-weight: 800;
  position: relative;

  margin-right: ${theme.spacing.x1};
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  gap: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x1};
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  template: TabilityTypes.Plan;
}
function TemplateDirectory(props: Props) {
  const { template } = props;

  let objectives = [];
  try {
    // @ts-ignore
    const content = yaml.load(template.yaml_content);
    // @ts-ignore
    objectives = content.objectives;
  } catch (e) {}

  const tagList = template.cached_tag_list || '';
  const tags = tagList.length > 0 ? tagList.split(',') : [];

  return (
    <Container>
      <Title>
        <Link to={`/templates/t/${template.nano_slug}`}>{template.title}</Link>
      </Title>
      <Link to={`/templates/t/${template.nano_slug}`}>
        <PlanWrapper>
          <PlanContent>
            {objectives.map((objective: any) => {
              const outcomes = objective.outcomes || [];
              return (
                <>
                  <LineGrid>
                    <div>
                      <Label className="objective">Objective</Label>
                    </div>
                    <span>{objective.title}</span>
                  </LineGrid>
                  {outcomes.map((outcome: any) => {
                    const initiatives = outcome.initiatives || [];
                    return (
                      <>
                        <LineGrid>
                          <div>
                            <Label className="outcome">#</Label>
                          </div>
                          <span>{outcome.title}</span>
                        </LineGrid>
                        {initiatives.map((initiative: any) => {
                          return (
                            <>
                              <LineGrid>
                                <div></div>
                                <div>
                                  <div>
                                    <TaskLabel>&#10003;</TaskLabel>
                                  </div>
                                  <span>{initiative.title}</span>
                                </div>
                              </LineGrid>
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </>
              );
            })}
          </PlanContent>
        </PlanWrapper>
      </Link>

      {tags.length > 0 && (
        <TagContainer>
          {tags.map((tag: string) => {
            return (
              <KoalaTextBadge
                isLowercase={true}
                key={tag}
                linkTo={`/templates/${tag}`}
                variant="neutral-outlined"
                edge="circle"
                size="small"
              >
                {tag}
              </KoalaTextBadge>
            );
          })}
        </TagContainer>
      )}

      <Meta>
        <small className="subtle">
          Published {formatDistanceToNow(parseISO(template.created_at), { addSuffix: true })}
        </small>
      </Meta>
    </Container>
  );
}

export default TemplateDirectory;
