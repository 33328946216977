import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative, OutcomeRelationship } from 'types';
import * as checkinUtils from 'utils/checkinUtils';
import KoalaCircularProgress, { ProgressData } from 'koala/components/CircularProgress';
import { useTranslation } from 'react-i18next';
import KoalaColorBlock from 'koala/components/ColorBlock';
import KoalaTextBadge from 'koala/components/TextBadge';
import ReactTooltip from 'react-tooltip';
import UserSelectButton from 'components/UserSelectButton';
import KoalaStackedAvatar from 'koala/components/StackedAvatars';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaIcon from 'koala/components/Icons';
import Initiatives from './Initiatives';
import { AxiosResponse } from 'axios';
import KoalaLoader from 'koala/components/Loader';

const OutcomeListItem = styled.li``;
const OutcomeChildContainer = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'left right';
  align-items: start;
  column-gap: ${theme.spacing.x1};

  &:hover {
    background: ${theme.colors.N3};
  }
  &.selected,
  &:active,
  &:focus {
    background: ${theme.colors.B5};
  }
`;

const BlockLeft = styled.div`
  grid-area: left;
  display: flex;

  > div {
    &:first-of-type {
      padding-left: 1.2rem;
    }
  }
`;

const BlockRight = styled.div`
  grid-area: right;
  align-items: start;
  justify-content: right;
  gap: ${theme.spacing.x1};
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-areas: 'tags progress owners';
  padding: 1.2rem 0;

  @media ${theme.devices.smallDesktop} {
    grid-template-columns: auto auto;
    grid-template-areas: 'progress owners';
  }
  @media ${theme.devices.tablet} {
    display: none;
  }
`;

const InitiativesContainer = styled.ul`
  margin-left: 4.4rem;

  transition: all ease 0.4s;

  .initiative-row {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
  }
`;

const ExpandButton = styled.div`
  display: inline-flex;
  width: 3.6rem;
  justify-content: center;
  padding: 1.2rem;
  line-height: 2rem;

  div {
    transition: all ease 0.2s;
  }
  .expanded {
    transform: rotate(90deg);
  }
`;

const OutcomeTitle = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  padding: 1.2rem 0;

  .plan-title {
    color: ${theme.colors.subtleText};
    font-size: 1.2rem;
  }
`;

const BlockOwners = styled.div`
  grid-area: owners;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 2rem;
  width: 8rem;
  flex-direction: row-reverse;

  .contributors {
    margin-left: -12px;
  }

  @media ${theme.devices.tablet} {
    display: none;
  }
`;

interface Props {
  outcomeRelationship: OutcomeRelationship;
  hideClosedInitiatives: boolean;
}

function OutcomeChild(props: Props) {
  const { outcomeRelationship, hideClosedInitiatives } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const [isExpanded, setIsExpanded] = useState(false);
  const [initiatives, setInitiatives] = useState<Initiative[] | null>(null);
  const outcome = outcomeRelationship.outcome_source;
  const progressPrct = outcome.outcome_progress_prct * 100;
  const currentConfidence = outcome.current_checkin ? outcome.current_checkin.confidence : 'grey';
  const hashPath = `#outcome:${outcome.nano_slug}:show`;

  const [updateOutcomeMutation] = useMutation(remoteApi.updateOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
      queryCache.invalidateQueries(queryKeys.outcomes);
    },
  });

  // get the initiatives
  const queryKeyInitiatives = [queryKeys.initiatives, outcome.id];
  const { isLoading: isInitiativeLoading } = useQuery(queryKeyInitiatives, remoteApi.fetchInitiatives, {
    onSuccess: (response: AxiosResponse) => {
      setInitiatives(response.data);
    },
  });

  const handleAssignUser = (value: string, outcomeId: string) => {
    const membership_id = value;
    if (membership_id) {
      const params = {
        membership_id,
      };

      updateOutcomeMutation({
        outcomeId,
        outcome: params,
      });
    }
  };

  const handleUnassignUser = (outcomeId: string) => {
    const params = {
      membership_id: null,
    };
    updateOutcomeMutation({
      outcomeId,
      outcome: params,
    });
  };

  const handleOutcomeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashPath);
  };

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  const confidenceColor = checkinUtils.confidenceToColor(currentConfidence);
  const formattedScore = outcome.current_checkin
    ? checkinUtils.checkinScore(outcome.current_checkin, outcome, t)
    : 'Pending';
  const progressData: ProgressData = {
    progressPercentage: progressPrct,
    colorType: checkinUtils.confidenceToColorLetter(currentConfidence),
    centerLabel: '',
  };

  const initiativeCount = initiatives ? initiatives.length : 0;

  return (
    <OutcomeListItem className="outcome tree-item" key={outcome.id}>
      <OutcomeChildContainer onClick={handleOutcomeClick}>
        <BlockLeft className="outcome-row--left">
          {initiativeCount > 0 && (
            <ExpandButton className="expand-gutter" onClick={toggleExpand} data-outcome={outcome.id}>
              <KoalaIcon iconName="rightChevron" iconSize={1.2} className={isExpanded ? 'expanded' : 'collapsed'} />
            </ExpandButton>
          )}
          {initiativeCount === 0 && (
            <ExpandButton className="expand-gutter">
              <span>&middot;</span>
            </ExpandButton>
          )}
          <OutcomeTitle>
            <KoalaColorBlock
              color={confidenceColor}
              className="color-block"
              isPending={outcome.is_pending_checkin}
              isCompleted={outcome.completed}
            />
            <Link to={hashPath}>
              <span className="plan-title">{outcome.plan.title} / </span>
              {outcome.title}
            </Link>
          </OutcomeTitle>
        </BlockLeft>
        <BlockRight className="outcome-row--right">
          <KoalaTextBadge
            isLowercase={true}
            variant={checkinUtils.confidenceToVariant(currentConfidence)}
            edge="circle"
            size="small"
            style={{ border: 'none' }}
            maxWidth="10rem"
            dataFor={`progress-${outcome.nano_slug}`}
          >
            {formattedScore}
          </KoalaTextBadge>
          <ReactTooltip
            place="bottom"
            type="dark"
            className="tooltip"
            effect="solid"
            id={`progress-${outcome.nano_slug}`}
          >
            {formattedScore}
          </ReactTooltip>
          <KoalaCircularProgress data={progressData} size="xsmall" dataFor={`percentage-${outcome.nano_slug}`} />
          <ReactTooltip
            place="bottom"
            type="dark"
            className="tooltip"
            effect="solid"
            id={`percentage-${outcome.nano_slug}`}
          >
            {progressPrct > 0 ? progressPrct.toFixed(0) : 0}% completed
          </ReactTooltip>
          <BlockOwners>
            <KoalaStackedAvatar
              owner={outcome.membership ? outcome.membership.user : null}
              contributors={outcome.contributors}
              size="small"
              hideOwner={true}
              className="contributors"
              direction="right"
            />
            <UserSelectButton
              canEdit={true}
              handleAssignUser={(value) => handleAssignUser(value, outcome.id)}
              selectedUser={outcome.membership ? outcome.membership.user : null}
              handleUnassignUser={() => handleUnassignUser(outcome.id)}
              size="small"
            />
          </BlockOwners>
        </BlockRight>
      </OutcomeChildContainer>
      {isExpanded && initiatives && (
        <InitiativesContainer className={isExpanded ? 'expanded' : 'collapsed'}>
          {isInitiativeLoading && <KoalaLoader />}
          <Initiatives
            hideClosedInitiatives={hideClosedInitiatives}
            initiatives={initiatives}
            outcomeId={outcome.id}
            parents={[]}
          />
        </InitiativesContainer>
      )}
    </OutcomeListItem>
  );
}

export default React.memo(OutcomeChild);
