import React from 'react';
import * as TabilityTypes from 'types';
import theme from 'theme';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();
  const translationKey = 'panels.editOutcome';

  const outcomeURL = outcome.data_source_meta?.url || '';
  // Query keys and query params
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:link:${encodeURIComponent(outcomeURL)}`,
    'current',
  ];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null) {
    return <FormField>{t(`${translationKey}.errorFetching`)}</FormField>;
  }

  return (
    <FormField>
      <label>{t(`${translationKey}.previewLink`)}</label>
      <a href={currentValue} target="_blank" className="primary" rel="noopener noreferrer">
        {currentValue}
      </a>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
}

function LinkSource(props: Props) {
  const { outcome } = props;
  const { t } = useTranslation();
  const translationKey = 'panels.editOutcome';

  const [data_source_meta, setDataSourceMeta] = React.useState<any>(outcome.data_source_meta);
  const outcomeURL = outcome.data_source_meta?.url || '';

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }]: [any, any] = useMutation(
    remoteApi.updateOutcomeDataSource,
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries(queryKeys.currentOutcome);
      },
    },
  );

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'link',
      data_source_type: 'url',
      data_source_meta,
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const url = data_source_meta?.url || '';
  return (
    <>
      <FormField>
        <label>{t(`${translationKey}.link`)}</label>
        <input
          value={url}
          type="url"
          onChange={(e) => setDataSourceMeta({ url: e.currentTarget.value })}
          placeholder={t(`${translationKey}.linkPlaceholder`) ?? 'Copy the link to your data source'}
        />
        <small style={{ marginTop: theme.spacing.x1 }}>{t(`${translationKey}.linkInfo`)}</small>
      </FormField>
      {outcomeURL !== url && (
        <FormField>
          <KoalaButton onClick={handleUpdate} loading={isUpdating} disabled={!url || isUpdating} appearance="subtle">
            {t(`${translationKey}.savePreview`)}
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'link' && outcomeURL === url && (
        <>
          <CurrentValue outcome={outcome} />
        </>
      )}
    </>
  );
}

export default React.memo(LinkSource);
