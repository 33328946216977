import React from 'react';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import { DASHBOARD_WORKSPACES_ROUTE, WORKSPACE_INBOX_ROUTE } from 'routes';

// API
import { fetchCurrentUserMemberships } from 'api/remote';

// Components
import LoadingScreen from 'components/LoadingScreen';
import LoginWithRedirect from './LoginWithRedirect';

// This component will load the memberships from the user and redirect to the appropriate workspace if it exists.
// If no workspace has been found, will redirect to the workspace creation screen

const membershipsQueryKey = queryKeys.currentUserMemberships;
const staleTime = queryKeys.staleTime;

function MembershipRedirect() {
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const redirectLink = useSelector((state: any) => state.session.redirectLink, shallowEqual);
  const { data: membershipsResponse, isLoading }: any = useQuery(membershipsQueryKey, fetchCurrentUserMemberships, {
    staleTime,
  });

  let memberships = [];

  if (membershipsResponse && membershipsResponse.data) {
    memberships = membershipsResponse.data;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (redirectLink && redirectLink !== '/') {
    return <LoginWithRedirect />;
  }

  if (currentUser && currentUser.last_workspace_visited) {
    return <Redirect to={`/${currentUser.last_workspace_visited}`} />;
  }

  // If we don't have a membership, redirect to the workspace creation screen
  if (memberships.length === 0) {
    return <Redirect to={DASHBOARD_WORKSPACES_ROUTE} />;
  }

  // Redirect to the workspace of the first membership found
  const firstWorkspace = memberships[0].workspace;
  return <Redirect to={WORKSPACE_INBOX_ROUTE.replace(':workspaceSlug', firstWorkspace.slug)} />;
}

export default MembershipRedirect;
