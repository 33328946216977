import { Workspace } from 'types';

interface CustomTerm {
  singular: TermDetails;
  plural: TermDetails;
}
interface TermDetails {
  workspaceKey: string;
  defaultValue: string;
}

export enum CustomTermKey {
  OBJECTIVE = 'objective',
  OUTCOME = 'outcome',
  INITIATIVE = 'initiative',
}

const termDirectory: { [key: string]: CustomTerm } = {
  objective: {
    singular: {
      workspaceKey: 'custom_term_objective',
      defaultValue: 'Objective',
    },
    plural: {
      workspaceKey: 'custom_term_objectives',
      defaultValue: 'Objectives',
    },
  },
  outcome: {
    singular: {
      workspaceKey: 'custom_term_outcome',
      defaultValue: 'Outcome',
    },
    plural: {
      workspaceKey: 'custom_term_outcomes',
      defaultValue: 'Outcomes',
    },
  },
  initiative: {
    singular: {
      workspaceKey: 'custom_term_initiative',
      defaultValue: 'Initiative',
    },
    plural: {
      workspaceKey: 'custom_term_initiatives',
      defaultValue: 'Initiatives',
    },
  },
};

export const translate = (workspace: Workspace, key: CustomTermKey, quantity: number) => {
  const type: string = quantity === 1 ? 'singular' : 'plural';
  const termDetails = termDirectory[key][type as keyof CustomTerm];

  // Safeguard in case the workspace is null
  if (!workspace) {
    return termDetails.defaultValue.toString();
  }
  let value = workspace[termDetails.workspaceKey as keyof Workspace];
  if (!value) {
    value = termDetails.defaultValue;
  }
  return value.toString();
};
