import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useLocation } from 'react-router-dom';
import theme from 'theme';
import * as TabilityTypes from 'types';

// API
import * as remoteApi from 'api/remote';
import { fetchCurrentUserMemberships } from 'api/remote';

// Components
import TabilityIcon from 'components/_assets/tability_icon_black.png';
import ClickupIcon from 'components/_assets/IconClickup.png';
import Loader from 'components/Loader';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  a {
    text-decoration: underline;
  }
`;

const Logo = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
  img {
    height: 4rem;
  }
  span {
    font-size: 3.2rem;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};
`;

function InstallSlack() {
  const location = useLocation();
  const { t } = useTranslation(undefined, { useSuspense: false });

  const [selectedWorkspaceSlug, setSelectedWorkspaceSlug] = useState<string | null>(null);
  const [adminMemberships, setAdminMemberships] = useState<TabilityTypes.Membership[]>([]);

  const membershipsQueryKey = queryKeys.currentUserMemberships;
  const staleTime = 0;

  const { isLoading }: any = useQuery(membershipsQueryKey, fetchCurrentUserMemberships, {
    staleTime,
    onSuccess: (response) => {
      const admin_memberships = response.data.filter((m: TabilityTypes.Membership) =>
        ['admin', 'owner'].includes(m.role),
      );
      setAdminMemberships(admin_memberships);

      // Set value of first selected workspace
      if (admin_memberships.length > 0) {
        setSelectedWorkspaceSlug(admin_memberships[0].workspace.slug);
      }
    },
  });

  // Extract params from the URL
  const params = new URLSearchParams(location.search);
  const code = params.get('code');

  const [installClickupMutation, { isError, isLoading: isInstalling }]: [any, any] = useMutation(
    remoteApi.installClickup,
    {
      onSuccess: () => {
        const redirectUrl = `https://${process.env.REACT_APP_DOMAIN}/${selectedWorkspaceSlug}/settings/integrations`;
        window.location.href = redirectUrl;
      },
    },
  );

  const selectWorkspace = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const slug = e.target.value;
    setSelectedWorkspaceSlug(slug);
  };

  const installClickup = (e: React.MouseEvent) => {
    e.preventDefault();
    const body = {
      code,
      workspace_slug: selectedWorkspaceSlug,
    };
    const params = {
      body,
      workspaceSlug: selectedWorkspaceSlug,
    };
    installClickupMutation(params);
  };

  return (
    <Container>
      <Logo>
        <img src={TabilityIcon} alt="Tability" />
        <span role="img">🤝</span>
        <img src={ClickupIcon} alt="Clickup" />
      </Logo>
      {isLoading && <Loader />}
      {adminMemberships.length === 0 && (
        <>
          <p>{t('installIntegration.admin', { integration: 'Clickup' })}</p>
          <Actions>
            <KoalaTextButton to="/" isLink>
              {t('installIntegration.back')}
            </KoalaTextButton>
          </Actions>
        </>
      )}
      {adminMemberships.length > 0 && (
        <>
          <p>{t('installIntegration.selectWorkspace', { integration: 'Clickup' })}</p>
          <select onChange={selectWorkspace}>
            {adminMemberships.map((membership: any) => {
              return (
                <option key={membership.id} value={membership.workspace.slug}>
                  {membership.workspace.name}
                </option>
              );
            })}
          </select>
          <Actions>
            <KoalaButton disabled={!selectedWorkspaceSlug} onClick={installClickup} loading={isInstalling}>
              {t('installIntegration.connect')}
            </KoalaButton>
            <KoalaTextButton to="/" isLink>
              {t('installIntegration.back')}
            </KoalaTextButton>
          </Actions>
          {isError && <p>{t('installIntegration.connectError', { integration: 'Clickup' })}</p>}
        </>
      )}
    </Container>
  );
}

export default InstallSlack;
