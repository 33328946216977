import React, { useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import * as amplitudeUtils from 'utils/amplitude';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative, Objective, Outcome, Plan, Workspace } from 'types';
import ObjectiveSection from './ObjectiveSection';
import EmptyStatePanel from 'components/EmptyStatePanel';

const ScrollContainer = styled.div`
  overflow: auto;
  scroll-behavior: smooth;
  margin-bottom: ${theme.spacing.x5};
`;

interface Props {
  plan: Plan;
  hideClosedInitiatives: boolean;
}

export function ListingView(props: Props) {
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plan.work.listing.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);
  const { plan, hideClosedInitiatives } = props;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const v4Required = currentWorkspace.pricing_version < 4;

  const objectives: { [key: string]: Objective } = useSelector((state: any) => state.editorEntities.objectives);
  const objectiveIds: string[] = useSelector(
    (state: any) => state.editorEntities.plansToObjectivesMapping[plan.id] || [],
  );
  const outcomes: { [key: string]: Outcome } = useSelector((state: any) => state.editorEntities.outcomes);
  const initiatives: { [key: string]: Initiative } = useSelector((state: any) => state.editorEntities.initiatives);
  const initiativeIds: string[] = useSelector((state: any) => state.editorEntities.initiativeIds);

  const initiativesByObjective: { [objectiveId: string]: Initiative[] } = {};
  initiativeIds.forEach((id: string) => {
    const initiative: Initiative = initiatives[id];
    if (initiative) {
      const outcome = outcomes ? outcomes[initiative.outcome_id] : null;
      if (outcome) {
        if (initiativesByObjective[outcome.objective_id]) {
          initiativesByObjective[outcome.objective_id].push(initiative);
        } else {
          initiativesByObjective[outcome.objective_id] = [initiative];
        }
      }
    }
  });

  const outcomesByObjective: { [objectiveId: string]: Outcome[] } = {};
  Object.keys(outcomes).forEach((outcomeId) => {
    const outcome = outcomes ? outcomes[outcomeId] : null;
    if (outcome) {
      if (outcomesByObjective[outcome.objective_id]) {
        outcomesByObjective[outcome.objective_id].push(outcome);
      } else {
        outcomesByObjective[outcome.objective_id] = [outcome];
      }
    }
  });

  const handleSupport = (e: React.MouseEvent) => {
    e.preventDefault();
    window.Intercom('showNewMessage');
  };

  if (v4Required) {
    return (
      <EmptyStatePanel>
        <h3>This feature is not available in your workspace</h3>
        <br />
        <br />
        <p>Sorry, you're on a legacy subscription and this feature is not available in your workspace.</p>

        <p>
          Please{' '}
          <a href="https://guides.tability.app/docs/support" onClick={handleSupport}>
            contact support
          </a>
        </p>
      </EmptyStatePanel>
    );
  }

  return (
    <ScrollContainer ref={scrollContainerRef}>
      {objectiveIds.map((objectiveId, i) => {
        const objective = objectives[objectiveId];
        if (objective) {
          return (
            <ObjectiveSection
              objective={objective}
              outcomes={outcomesByObjective[objectiveId]}
              initiatives={initiativesByObjective[objectiveId]}
              hideClosedInitiatives={hideClosedInitiatives}
            />
          );
        }
        return <></>;
      })}
    </ScrollContainer>
  );
}

export default ListingView;
