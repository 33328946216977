import React, { useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;

  // Query keys and query params
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:chartmogul:${outcome.data_source_type}`,
    'current',
  ];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null || currentValue === undefined) {
    return <FormField>Sorry, an error occurred while fetching the current value.</FormField>;
  }

  const dataTypeToLabel: any = {
    current_mrr: '$<number> MRR',
    current_arr: '$<number> ARR',
    current_arpa: '$<number> ARPA',
    current_asp: '$<number> ASP',
    current_customer_count: '<number> customers',
    current_customer_churn_rate: '<number>%',
    current_mrr_churn_rate: '<number>%',
    current_ltv: '$<number> LTV',
  };

  const label = dataTypeToLabel[outcome.data_source_type || ''];
  const labelWithNumber = label.replace('<number>', currentValue.toLocaleString());
  return (
    <FormField>
      <label>Preview value</label>
      <p>{labelWithNumber}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
}

function ChartMogulSource(props: Props) {
  const { outcome } = props;
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }]: [any, any] = useMutation(
    remoteApi.updateOutcomeDataSource,
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries(queryKeys.currentOutcome);
      },
    },
  );

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'chartmogul',
      data_source_type: data_source_type,
      data_source_meta: {},
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const availableDataSourceTypes = [
    'current_mrr',
    'current_arr',
    'current_arpa',
    'current_asp',
    'current_customer_count',
    'current_customer_churn_rate',
    'current_mrr_churn_rate',
    'current_ltv',
  ];

  return (
    <>
      <FormField>
        <label>Change Metric type</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">Select a metric</option>
          <option value="current_mrr">MRR</option>
          <option value="current_arr">ARR</option>
          <option value="current_arpa">ARPA</option>
          <option value="current_asp">ASP</option>
          <option value="current_customer_count">Customer Count</option>
          <option value="current_customer_churn_rate">Customer churn rate</option>
          <option value="current_mrr_churn_rate">MRR churn rate</option>
          <option value="current_ltv">LTV</option>
        </select>
      </FormField>
      {outcome.data_source_type !== data_source_type && (
        <FormField>
          <KoalaButton
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={!data_source_type || isUpdating}
            appearance="subtle"
          >
            Save & preview
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'chartmogul' &&
        outcome.data_source_type &&
        outcome.data_source_type === data_source_type && (
          <>{availableDataSourceTypes.includes(outcome.data_source_type) && <CurrentValue outcome={outcome} />}</>
        )}
    </>
  );
}

export default React.memo(ChartMogulSource);
