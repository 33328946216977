import styled from 'styled-components'; // Fixing the build
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

const ErrorMesssage = styled.div`
  color: ${theme.colors.R70};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

function ConnectMariaDB() {
  const dispatch = useDispatch();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const { t } = useTranslation();
  const translationKey = 'modals.connectSQL';
  const [database, setDatabase] = useState('');
  const [server, setServer] = useState('');
  const [port, setPort] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const queryCache = useQueryCache();

  const [installMariaDBMutation, { isLoading }] = useMutation(remoteApi.installMariaDB, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.integrations]);
      dispatch(setGlobalModalContent(''));
    },
    onError: (e: AxiosError) => {
      const errorMessage = e.response?.data.error;
      setError(errorMessage ?? 'Unable to connect');
    },
  });

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    const params = {
      body: {
        database,
        server,
        port,
        username,
        password,
      },
      workspaceSlug: workspaceSlug,
    };
    installMariaDBMutation(params);
  };

  const allowSave = database && server && username && password && !isLoading;
  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`, { database: 'MariaDB' })}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleSave}>
          <FormField>
            <label>{t(`${translationKey}.database`)}</label>
            <input
              placeholder={t(`${translationKey}.databasePlaceholder`) ?? 'Database'}
              className="segment-input"
              value={database}
              onChange={(e) => setDatabase(e.currentTarget.value)}
            />
          </FormField>
          <FormField>
            <label>{t(`${translationKey}.server`)}</label>
            <input
              placeholder={t(`${translationKey}.serverPlaceholder`) ?? 'Server'}
              className="segment-input"
              value={server}
              onChange={(e) => setServer(e.currentTarget.value)}
            />
          </FormField>
          <FormField>
            <label>{t(`${translationKey}.serverPort`)}</label>
            <input
              placeholder={t(`${translationKey}.serverPortPlaceholder`, { port: 3306 }) ?? 'Port'}
              className="segment-input tiny"
              value={port}
              onChange={(e) => setPort(e.currentTarget.value)}
            />
          </FormField>
          <FormField>
            <label>{t(`${translationKey}.username`)}</label>
            <input
              placeholder={t(`${translationKey}.username`) ?? 'Username'}
              className="segment-input"
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
            />
            <small className="subtle">{t(`${translationKey}.usernameInfo`)}</small>
          </FormField>
          <FormField>
            <label>{t(`${translationKey}.password`)}</label>
            <input
              placeholder={t(`${translationKey}.password`) ?? 'Password'}
              className="segment-input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </FormField>
          {error && (
            <ErrorMesssage>
              <small>{t(`${translationKey}.error`, { database: 'MariaDB' })}</small>
              <small>Error: {error}</small>
            </ErrorMesssage>
          )}
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!allowSave} loading={isLoading}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default ConnectMariaDB;
