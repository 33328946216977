/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as amplitudeUtils from 'utils/amplitude';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import theme from 'theme';

// Components
import Loader from 'components/Loader';
import TemplateBlockCompact from 'components/TemplateBlockCompact';

// Components
const Container = styled.div``;

const Templates = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: ${theme.spacing.x2};

  @media ${theme.devices.desktop} {
    grid-template-columns: 1fr;
    row-gap: ${theme.spacing.x2};
  }
`;

interface Props {
  tag: string;
  excludeId: string;
}

function TemplatesHome(props: Props) {
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.session.login.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const [public_templates, setPublicTemplates] = useState([]);
  const { excludeId, tag } = props;

  // Construct the query key using the plan Id
  const queryParams = {
    tag,
    order: 'random',
    exclude: excludeId,
    limit: 3,
  };

  // Construct the query key using the plan Id
  const staleTime = 0;

  const queryKeyRelatedTemplates = [queryKeys.publicTemplates, queryParams];
  // Get the templates details
  const { isLoading } = useQuery(queryKeyRelatedTemplates, remoteApi.fetchPublicTemplates, {
    staleTime,
    onSuccess: (response) => setPublicTemplates(response.data),
  });

  // Displays the list of templates
  return (
    <Container>
      <h3>Related templates</h3>
      <Templates>
        {public_templates.map((template: any) => {
          return <TemplateBlockCompact key={template.id} template={template} />;
        })}
      </Templates>
      {isLoading && <Loader size="big" />}
    </Container>
  );
}

export default TemplatesHome;
