import React, { useState } from 'react';
import { InitiativeComment, InitiativeReply } from 'types';
import InitiativeReplyEdit from './InitiativeReplyEdit';
import InitiativeReplyDisplay from './InitiativeReplyDisplay';

interface Props {
  reply: InitiativeReply;
  setShowNewReply: (value: boolean) => void;
  comment: InitiativeComment;
}

function InitiativeReplyBlock(props: Props) {
  const { reply, setShowNewReply, comment } = props;
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {isEditing && <InitiativeReplyEdit reply={reply} setIsEditing={setIsEditing} />}
      {!isEditing && (
        <InitiativeReplyDisplay
          reply={reply}
          setIsEditing={setIsEditing}
          setShowNewReply={setShowNewReply}
          comment={comment}
        />
      )}
    </>
  );
}

export default InitiativeReplyBlock;
