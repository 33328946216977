import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';

const Container = styled.div`
  display: flex;
  margin: ${theme.spacing.x1} 0;
  align-items: center;

  input {
    border-radius: 4px 0 0 4px;
    background: ${theme.colors.B5};
    border-color: ${theme.colors.inputBorder};
  }

  button {
    border-radius: 0 4px 4px 0;
    border-left: 0;
    border-color: ${theme.colors.inputBorder};
  }
`;

interface Props {
  text: string;
}

function CopyTextInput(props: Props) {
  const { t } = useTranslation();
  const [copiedText, setCopiedText] = useState(t('copyTextInput.copy'));
  const inputRef: any = useRef(null);

  function copyToClipboard(e: any) {
    e.preventDefault();
    inputRef.current.select();
    document.execCommand('copy');

    e.target.focus();
    setCopiedText(t('copyTextInput.copied'));
  }

  return (
    <Container className="copy-text-container">
      <input className="copy-text-input" type="text" ref={inputRef} value={props.text} readOnly={true} />
      <button className="copy-text-btn" onClick={copyToClipboard}>
        {copiedText}
      </button>
    </Container>
  );
}

export default React.memo(CopyTextInput);
