import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

// API
import * as remoteApi from 'api/remote';

// Components
import { PanelGrid, PanelHeader, PanelContent, PanelActions } from 'components/GlobalPanel';
import Loader from 'components/Loader';
import KoalaIconButton from 'koala/components/IconButton';
import { Objective } from 'types';
import FormField from 'components/FormField';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import TagPickerObjective from 'components/TagPickerObjective';
import QuillBodyEditor from 'components/QuillBodyEditor';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { useTranslation } from 'react-i18next';
import { CONTEXT_STORAGE_KEY } from 'config/constants';
import _ from 'lodash';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

interface Props {
  objectiveId: string;
}

function EditObjectivePanel(props: Props) {
  const { objectiveId } = props;
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const translationKey = 'panels';
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [objective, setObjective] = useState<Objective | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const queryCache = useQueryCache();
  const storageKey = `${CONTEXT_STORAGE_KEY}_objective_description_${objectiveId}`;

  const inputEl = useRef(null);

  // React to the ESC key to hide the panel
  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.push(location.pathname);
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  // Get the objective details
  const queryKey = [queryKeys.currentObjective, objectiveId];
  const staleTime = 0;
  useQuery(queryKey, remoteApi.fetchObjectiveDetails, {
    staleTime,
    onSuccess: (response) => {
      const obj: Objective = response.data;
      setObjective(obj);
      setTitle(obj.title);
      const _description = sessionStorage.getItem(storageKey) ?? obj.description;
      setDescription(_description ?? '');
    },
  });

  useEffect(() => {
    debounceStoreText.current(description);
  }, [description]);

  const storeText = (newText: string) => {
    if (newText) {
      sessionStorage.setItem(storageKey, newText);
    }
  };

  const debounceStoreText = useRef(
    _.debounce((newText: string) => storeText(newText), 1000, {
      maxWait: 5000,
    }),
  );

  // Functions to update the objective
  const [updateObjectiveMutation, { isLoading: isUpdating }]: [any, any] = useMutation(remoteApi.updateObjective, {
    onSuccess: (_, variables) => {
      sessionStorage.removeItem(storageKey);
      queryCache.invalidateQueries(queryKeys.objectives);
      queryCache.invalidateQueries(queryKeys.currentObjective);
      if (variables.dontGoBack) {
        // Don't close the panel if dontGoBack set to true
        return;
      }
      history.goBack();
    },
  });

  const handleCancel = (e: Event) => {
    e.preventDefault();
    history.goBack();
  };

  // Now we can display the app with the Chrome
  if (!objective) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }
  const handleSave = (e: any) => {
    e.preventDefault();
    const params = {
      title,
      description,
    };

    updateObjectiveMutation({
      objectiveId,
      objective: params,
    });
  };
  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const title = e.target.value;
    setTitle(title);
  };

  const label = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1);
  const titlePlaceholder = t(`${translationKey}.objectivePlaceholder`, { label: label });
  const objectiveDescriptionPlaceholder =
    t(`${translationKey}.objectiveDescriptionPlaceholder`, { label: label }) ?? `About this ${label}`;
  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>{t(`${translationKey}.editTitle`, { label: label })}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        <form onSubmit={handleSave}>
          <FormField>
            <label>{t(`${translationKey}.title`)}</label>
            <input
              type="text"
              value={title}
              ref={inputEl}
              onChange={handleTitleChange}
              placeholder={titlePlaceholder}
            />
          </FormField>
          <FormField>
            <label>{t('shared.tags')}</label>
            <TagPickerObjective objective={objective} />
          </FormField>
          <FormField>
            <label>{t(`${translationKey}.description`)}</label>
            <QuillBodyEditor
              value={description}
              onChange={setDescription}
              placeholder={objectiveDescriptionPlaceholder}
              disableMentions={true}
              quillClassName="ql-notes"
            />
          </FormField>
        </form>
      </PanelContent>
      <PanelActions>
        <KoalaButton submit onClick={handleSave} disabled={isUpdating} loading={isUpdating}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </PanelActions>
    </PanelGrid>
  );
}

export default React.memo(EditObjectivePanel);
