import { ModalGrid } from 'components/GlobalModal';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import SearchResults from './SearchResults';
import RecentlyViewed from './RecentlyViewed';
import { useHotkeys } from 'react-hotkeys-hook';

const ScrollSection = styled.div`
  grid-area: modal-content;
  display: flex;
  position: relative;

  overflow: auto;
  height: 100%;
`;
const SearchBar = styled.div`
  grid-area: modal-header;
  display: flex;
  padding: ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.N20};
`;

function CommandCenter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const translationKey = 'modals.commandCenter';
  const [inputValue, setInputValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [focusElement, setFocusElement] = useState<HTMLElement | null>(null);

  // need to specify can close if focus is on input
  useHotkeys(`escape`, () => dispatch(setGlobalModalContent('')), { scopes: ['modals'], enableOnFormTags: ['INPUT'] });

  // reset focus when searchText changes
  useEffect(() => {
    setFocusElement(null);
  }, [searchText]);
  // if up arrow pressed, go to previous item in the list or if at top go to last item
  useHotkeys(
    'up',
    (e) => {
      e.preventDefault();
      const items = document.getElementsByClassName('result-item');
      if (items.length > 0) {
        let prev =
          focusElement && focusElement.previousElementSibling
            ? focusElement.previousElementSibling
            : items[items.length - 1];
        prev.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setFocusElement(prev as HTMLElement);
      }
    },
    { scopes: ['modals'], enableOnFormTags: ['INPUT'] },
  );
  // if down arrow pressed, go to next item in the list or if at last go to first item
  useHotkeys(
    'down',
    (e) => {
      e.preventDefault();
      const items = document.getElementsByClassName('result-item');
      if (items.length > 0) {
        let next = focusElement && focusElement.nextElementSibling ? focusElement.nextElementSibling : items[0];
        next.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setFocusElement(next as HTMLElement);
      }
    },
    { scopes: ['modals'], enableOnFormTags: ['INPUT'] },
  );
  // if enter is hit, triggers click event on item that was in focus
  useHotkeys('enter', () => focusElement?.click(), { scopes: ['modals'], enableOnFormTags: ['INPUT'] });

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const performSearch = (filterText: string) => {
    setSearchText(filterText);
  };
  const debouncePerformSearch = useRef(
    _.debounce((filterText: string) => performSearch(filterText), 500, {
      maxWait: 2000,
    }),
  );
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterText = e.target.value;
    setInputValue(filterText);
    debouncePerformSearch.current(filterText);
  };

  return (
    <ModalGrid>
      <SearchBar>
        <input
          ref={inputRef}
          onChange={handleSearch}
          placeholder={t(`${translationKey}.search`) ?? 'Search...'}
          value={inputValue}
        />
      </SearchBar>

      <ScrollSection>
        {searchText && (
          <SearchResults searchText={searchText} focusElement={focusElement} setFocusElement={setFocusElement} />
        )}
        {!searchText && <RecentlyViewed focusElement={focusElement} setFocusElement={setFocusElement} />}
      </ScrollSection>
    </ModalGrid>
  );
}

export default CommandCenter;
