import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import MondayIcon from '../_assets/IconMonday.png';
import KoalaButton from 'koala/components/Button';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
  state: string;
}

function MondayIntegration(props: Props) {
  const { workspace, integrations, state } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallMondayMutation, { isLoading: isUninstallingMonday }] = useMutation(remoteApi.uninstallMonday, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const mondayIntegration = integrations.filter((i: Integration) => i.integration_type === 'monday')[0];

  const mondayRedirectURL = `https://${process.env.REACT_APP_DOMAIN}/install/monday`;
  const mondayScope = 'boards:read workspaces:read';
  const mondayOAuthURL =
    `https://auth.monday.com/oauth2/authorize` +
    `?client_id=${process.env.REACT_APP_MONDAY_CLIENT_ID}` +
    `&redirect_uri=${encodeURIComponent(mondayRedirectURL)}` +
    `&scope=${encodeURIComponent(mondayScope)}&state=${state}`;
  const uninstallMondayLabel = isUninstallingMonday ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove');

  const handleUninstallMonday = (e: React.MouseEvent) => {
    e.preventDefault();
    uninstallMondayMutation(workspace.slug);
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={MondayIcon} alt="Monday.com" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'Monday.com' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Monday.com' })}</p>
        <p>
          <a
            href="https://guides.tability.app/docs/features/integrations/monday"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {mondayIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: mondayIntegration.connected_to }}
              />
            </div>
            <KoalaButton href={mondayOAuthURL} appearance="secondary" size="small">
              {t('workspaceSettingsIntegrations.reinstall')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={handleUninstallMonday}>
              {uninstallMondayLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!mondayIntegration && (
          <KoalaButton href={mondayOAuthURL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Monday.com' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default MondayIntegration;
