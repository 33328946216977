import React, { useState, useEffect } from 'react';
import * as TabilityTypes from 'types';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as amplitudeUtils from 'utils/amplitude';
// import { REFERENCE_TEMPLATE_STORAGE_KEY } from 'config/constants';

// Components
import KoalaTextButton from 'koala/components/TextButton';

// API
import * as remoteApi from 'api/remote';

// Actions
import { sessionActions } from 'state/actions/';
import { useTranslation } from 'react-i18next';

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${theme.spacing.x8};
  h4 {
    font-weight: 800;
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  width: 100%;
  gap: ${theme.spacing.x8};

  a {
    color: ${theme.colors.blue};
  }

  @media ${theme.devices.laptop} {
    display: flex;
    width: 100%;
    gap: ${theme.spacing.x1};
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: initial;
    margin-top: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};

    .hide-mobile {
      display: none;
    }

    .special-mobile {
      position: relative;
      bottom: ${theme.spacing.x3};
    }
  }
`;

const Content = styled.div`
  justify-self: flex-end;
  width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${theme.devices.laptop} {
    margin-top: 0;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};

  @media (max-width: 800px) {
    input,
    select {
      &.small {
        width: 100%;
      }

      &.tiny {
        width: 50%;
      }
    }
  }
`;

const ErrorList = styled.ul`
  margin-bottom: 1.6rem;
  color: #d0402e;
`;

const Footer = styled.div`
  margin: ${theme.spacing.x4} 0 ${theme.spacing.x3} 0;

  button.secondary {
    color: ${theme.colors.subtleText};
    text-decoration: none;
    font-weight: normal;
  }
`;

const RadioField = styled.div`
  display: flex;
  flex-direction: column;

  .form-label {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${theme.colors.subtleText};
    margin-bottom: ${theme.spacing.x1};
  }

  .radio-group {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x1};
    position: relative;
    // hide radio button and put it behind label so not clickable
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    &.vertical {
      flex-direction: column;
    }

    &.wrap,
    &.wrap-long {
      flex-wrap: wrap;
    }

    input[type='radio']:focus + label {
      border: 1px solid ${theme.colors.blue};
    }

    label {
      color: ${theme.colors.black};
      background-color: #fff;
      cursor: pointer;
      min-width: 10rem;
      padding: ${theme.spacing.x2};
      transition: all 0.2s ease;
      border: solid 1px ${theme.colors.blockBorder};
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: ${theme.colors.B5};
        cursor: pointer;
      }

      span {
        display: inline-block;
        padding: ${theme.spacing.half} ${theme.spacing.x1};
        border-radius: 4px;
        background: ${theme.colors.N5};
        font-size: 1.1rem;
        font-weight: 500;
        color: ${theme.colors.N70};
        margin-bottom: ${theme.spacing.x2};
      }

      &.disabled {
        cursor: not-allowed;
        filter: grayscale(0.7);
        opacity: 0.7;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        g,
        path,
        circle {
          stroke: ${theme.colors.subtleText};
        }
      }
    }

    input[type='radio']:checked + label {
      color: #fff;
      background: ${theme.colors.B5};
      border: 1px solid ${theme.colors.B30};
      color: ${theme.colors.N100};

      span {
        background: ${theme.colors.B50};
        font-size: 1.1rem;
        font-weight: 500;
        color: ${theme.colors.N0};
      }
    }

    &.compact {
      label {
        font-weight: 600;
        font-size: 1.4rem;
        padding: ${theme.spacing.x1} ${theme.spacing.x1};
      }

      input[type='radio']:checked + label {
        color: ${theme.colors.B50};
      }
    }
  }

  &.icons-only {
    .radio-group {
      display: flex;
      align-items: center;
    }
    label {
      display: flex;
      align-items: center;
    }
  }
`;
const About = styled.div`
  width: 50rem;
`;
const Instruction = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  img {
    height: 50rem;
  }
  @media ${theme.devices.laptop} {
    display: none;
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceOnboardingWorkflow(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { workspace } = props;
  const { t } = useTranslation();

  const [creator_team_integrations, setCreatorTeamIntegration] = useState('');

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.onboarding.rollout', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  useEffect(() => {
    window.Intercom('update', {
      has_created_workspace: true,
      workspace_slug: workspace.slug,
    });
  }, [workspace]);

  // Workspace mutations
  const [updateWorkspaceMutation, { isError, error }]: [any, any] = useMutation(remoteApi.updateWorkspace, {
    onSuccess: (response: any) => {
      const workspace = response.data;
      dispatch(sessionActions.setCurrentWorkspace(workspace));
      history.push(`/${workspace.slug}/onboarding`);
    },
  });

  // Workspace mutations
  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: ['Sorry, an error occurred on the remote server.'],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'creator_team_integrations':
        setCreatorTeamIntegration(value);
        const params = {
          creator_team_integrations: value,
        };

        updateWorkspaceMutation({
          workspaceId: workspace.id,
          workspace: params,
        });
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'name':
        return t('errors.workspaceName');
      case 'slug':
        return t('errors.workspaceID');
      default:
        return '';
    }
  };

  const handleLogout = (e: any) => {
    dispatch(sessionActions.logout());
  };

  return (
    <Wrapper>
      <Helmet>
        <title>{workspace.name} | Tell us about you | Tability</title>
      </Helmet>
      <Container>
        <Content>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <h2>How did you discover Tability?</h2>
            </FormGroup>
            <FormGroup>
              <>
                <RadioField>
                  <div className="radio-group compact">
                    <>
                      <input
                        type="radio"
                        id="just-look"
                        required
                        checked={creator_team_integrations === 'Searching on Google'}
                        name="creator_team_integrations"
                        value="Searching on Google"
                        onChange={handleChange}
                      />
                      <label htmlFor="just-look">Searching on Google</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="help-please"
                        required
                        checked={creator_team_integrations === 'Someone recommended it'}
                        name="creator_team_integrations"
                        value="Someone recommended it"
                        onChange={handleChange}
                      />
                      <label htmlFor="help-please">Someone recommended it</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="by-myself"
                        required
                        checked={creator_team_integrations === 'Used it with another team'}
                        name="creator_team_integrations"
                        value="Used it with another team"
                        onChange={handleChange}
                      />
                      <label htmlFor="by-myself">Used it with another team</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="by-myself"
                        required
                        checked={creator_team_integrations === 'I saw an ad'}
                        name="creator_team_integrations"
                        value="I saw an ad"
                        onChange={handleChange}
                      />
                      <label htmlFor="by-myself">I saw an ad</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="by-myself"
                        required
                        checked={creator_team_integrations === 'I saw it in a blog post'}
                        name="creator_team_integrations"
                        value="I saw it in a blog post"
                        onChange={handleChange}
                      />
                      <label htmlFor="by-myself">I saw it in a blog post</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="evaluating"
                        required
                        checked={creator_team_integrations === 'Heard about it in a video/podcast'}
                        name="creator_team_integrations"
                        value="Heard about it in a video/podcast"
                        onChange={handleChange}
                      />
                      <label htmlFor="evaluating">Heard about it in a video/podcast</label>
                    </>
                  </div>
                </RadioField>
              </>
            </FormGroup>
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
          </Form>
        </Content>
        <About>
          <Instruction>
            <div>
              <img
                src="https://res.cloudinary.com/tability/image/upload/v1681537799/static_assets/completed_qr118m.png"
                alt="Tabby"
              />
            </div>
          </Instruction>
        </About>
      </Container>
      <Footer>
        <KoalaTextButton onClick={handleLogout} appearance="subtle">
          Logout
        </KoalaTextButton>
      </Footer>
    </Wrapper>
  );
}

export default WorkspaceOnboardingWorkflow;
