import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import KoalaConfidenceText from 'koala/components/ConfidenceText';
import * as outcomeUtils from 'utils/outcomeUtils';

const Container = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;
const Title = styled.span`
  font-weight: 500;
`;

interface Props {
  outcome: TabilityTypes.Outcome;
}

function OutcomeBlock(props: Props) {
  const { outcome } = props;

  const currentCheckin = outcome.current_checkin;
  const hasTarget = outcomeUtils.hasTarget(outcome);

  return (
    <Container>
      {outcome && (
        <KoalaConfidenceText
          isCompleted={outcome.completed}
          value={hasTarget ? outcome.outcome_progress_prct * 100 : null}
          confidence={currentCheckin ? currentCheckin.confidence : 'grey'}
          isPending={outcome.is_pending_checkin}
        />
      )}
      <Title>{outcome.title}</Title>
    </Container>
  );
}

export default React.memo(OutcomeBlock);
