import React from 'react';

const LabelTaskIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM8.83573 4.66052C9.09449 4.35247 9.04205 3.90298 8.7186 3.65655C8.39515 3.41011 7.92319 3.46006 7.66443 3.7681L5.30517 6.57673L4.37412 5.24666C4.14435 4.91843 3.6787 4.82973 3.33405 5.04855C2.98941 5.26737 2.89628 5.71085 3.12604 6.03908L4.62604 8.18193C4.75877 8.37153 4.97852 8.48945 5.21756 8.49933C5.45661 8.50921 5.68626 8.40987 5.83573 8.23193L8.83573 4.66052Z"
      fill="#737373"
    />
  </svg>
);
export default LabelTaskIcon;
