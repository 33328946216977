import KoalaButton from 'koala/components/Button';
import KoalaIcon from 'koala/components/Icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';

const Banner = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  background: ${theme.colors.V50};
  color: #fff;
  padding: ${theme.spacing.x1};
  font-weight: 600;
  border-radius: 4px;
  justify-content: center;
  margin: ${theme.spacing.x2} 0;

  button {
    color: #fff;
  }

  .upgrade-icon {
    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

function UpgradeBanner() {
  const { t } = useTranslation();
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const isOwner = currentMembership.role === 'owner';

  return (
    <Banner>
      <KoalaIcon iconName="upgrade" className="upgrade-icon" />
      <p>{t(`shared.availableDuringTrial`, { subscription: 'Premium' })}</p>
      {isOwner && (
        <KoalaButton size="small" appearance="secondary" isLink to={`/${currentWorkspace.slug}/settings/billing`}>
          {t('workspaceInsights.upgrade.unlock')}
        </KoalaButton>
      )}
    </Banner>
  );
}

export default UpgradeBanner;
