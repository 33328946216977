import React from 'react';
import Chart, { DemoCheckin } from 'components/Chart';
import { Outcome } from 'types';
import { addWeeks } from 'date-fns';
import EmptyStatePanel from 'components/EmptyStatePanel';
import { useTranslation } from 'react-i18next';

interface Props {
  newOutcome: Outcome;
}

function ExampleChart(props: Props) {
  const { newOutcome } = props;
  const { t } = useTranslation();
  const translationKey = 'panels.editOutcome';
  const { outcome_type, from, to, plan, y_axis_min, y_axis_max } = newOutcome;
  const detailsRequired = (
    <EmptyStatePanel>
      <p>{t(`${translationKey}.detailsMissing`)}</p>
    </EmptyStatePanel>
  );
  const domainError = (
    <EmptyStatePanel>
      <p>{t(`${translationKey}.minGreater`)}</p>
    </EmptyStatePanel>
  );

  let demoOutcome: Outcome = newOutcome;
  const planStart = plan.start_at ? new Date(plan.start_at) : new Date();

  let firstPoint = 0,
    secondPoint = 0,
    thirdPoint = 0,
    forthPoint = 0;
  if (outcome_type === 'improve_metric') {
    if (from === null || to === null) {
      return detailsRequired;
    }
    const increment = (to - from) / 25;
    firstPoint = from + increment * 3;
    secondPoint = from + increment * 2;
    thirdPoint = secondPoint + increment * 3;
    forthPoint = thirdPoint + increment * 6;
  } else if (outcome_type === 'stay_above') {
    if (to === null || y_axis_min === null || y_axis_max === null) {
      return detailsRequired;
    }
    if (y_axis_min >= y_axis_max) {
      return domainError;
    }

    const increment = (y_axis_max - y_axis_min) / 25;
    firstPoint = to - increment;
    secondPoint = firstPoint - increment * 3;
    thirdPoint = secondPoint + increment * 3;
    forthPoint = firstPoint + increment * 5;
  } else if (outcome_type === 'stay_below') {
    if (to === null || y_axis_min === null || y_axis_max === null) {
      return detailsRequired;
    }
    if (y_axis_min >= y_axis_max) {
      return domainError;
    }
    const increment = (y_axis_max - y_axis_min) / 25;
    firstPoint = to + increment;
    secondPoint = firstPoint + increment * 2;
    thirdPoint = to + increment;
    forthPoint = firstPoint - increment * 5;
  } else if (outcome_type === 'kpi') {
    if (from === null || y_axis_min === null || y_axis_max === null) {
      return detailsRequired;
    }
    if (y_axis_min >= y_axis_max) {
      return domainError;
    }
    const increment = (y_axis_max - y_axis_min) / 25;
    firstPoint = from + increment;
    secondPoint = firstPoint + increment * 2;
    thirdPoint = secondPoint + increment * 3;
    forthPoint = thirdPoint + increment * 4;
  }

  const demoCheckins: DemoCheckin[] = [
    {
      plan: newOutcome.plan,
      checkin_date: addWeeks(planStart, 2).toISOString(),
      confidence: 'yellow',
      score: firstPoint,
    },
    {
      plan: newOutcome.plan,
      checkin_date: addWeeks(planStart, 4).toISOString(),
      confidence: 'red',
      score: secondPoint,
    },
    {
      plan: newOutcome.plan,
      checkin_date: addWeeks(planStart, 6).toISOString(),
      confidence: 'yellow',
      score: thirdPoint,
    },
    {
      plan: newOutcome.plan,
      checkin_date: addWeeks(planStart, 8).toISOString(),
      confidence: 'green',
      score: forthPoint,
    },
  ];

  return <Chart outcome={demoOutcome} checkins={demoCheckins} hideTrendLine={true} />;
}

export default ExampleChart;
