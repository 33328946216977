import KoalaIconButton from 'koala/components/IconButton';
import React from 'react';
import theme from 'theme';

interface Props {
  setShowMobileSidebar: any;
  isSetting: any;
}
function MobileNav(props: Props) {
  const { setShowMobileSidebar, isSetting } = props;

  let color = isSetting ? 'white' : theme.colors.N60;

  const handleShowMobileSidebar = (e: any) => {
    e.preventDefault();
    setShowMobileSidebar(true);
  };

  return <KoalaIconButton iconName="menu" onClick={handleShowMobileSidebar} iconColor={color} />;
}

export default React.memo(MobileNav);
