import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

// Components
import PlansTab from './PlansTab';
import { PanelGrid, PanelHeader, PanelContent } from 'components/GlobalPanel';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function MovePlan() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const closePanel = () => {
    history.push(location.pathname);
  };

  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.push(location.pathname);
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    closePanel();
  };

  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>{t('panels.workspaceArchives.title')}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        <PlansTab />
      </PanelContent>
    </PanelGrid>
  );
}

export default MovePlan;
