import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import * as routes from 'routes';
import * as amplitudeUtils from 'utils/amplitude';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as planUtils from 'utils/planUtils';

// API
import * as remoteApi from 'api/remote';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';
import PlanTitle from 'components/PlanTitle';
import PlanIconSelector from 'components/PlanIconSelector';
import ReactTooltip from 'react-tooltip';

// Components
import AccessDenied from 'components/AccessDenied';
import { ChromeContent, ChromeNav } from 'components/Chrome';
import Loader from 'components/Loader';
import NotFound from 'components/NotFound';
import PlanNav from 'components/PlanNav';
import TabbyGood from './tabby_good.png';

// Tracker component
import Tracker from './Tracker';
import KoalaButton from 'koala/components/Button';
import { Outcome } from 'types';
import { useTranslation } from 'react-i18next';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const PopupBanner = styled.div`
  width: 50rem;
  max-width: 50rem;
  background: #fff;
  padding: ${theme.spacing.x4};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};

  img {
    width: 15rem;
  }

  p {
    b {
      font-size: 2.2rem;
    }
    text-align: center;

    &.tinyimg {
      img {
        width: 8rem;
      }
    }

    &.actions {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        margin: ${theme.spacing.x1};
      }
    }
  }
`;

const EmptyState = styled.div`
  padding: ${theme.spacing.x3} ${theme.spacing.x4} 0 ${theme.spacing.x8};
`;

const EmptySection = styled.div`
  margin-bottom: ${theme.spacing.x6};
  background: #f3f3f3;
  border-radius: 4px;
  padding: ${theme.spacing.x3};
  text-align: center;

  h3 {
    margin: 0 0 ${theme.spacing.x1} 0;
  }

  a {
    color: ${theme.colors.blue};
  }
`;

const PlanTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

function PlanWrite() {
  const history = useHistory();
  const { planId, workspaceSlug } = useParams<{ planId: string; workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plan.track.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, planId];
  const staleTime = 1000 * 60 * 5; // 5 minutes
  const [outcomesByObjective, setOutcomesByObjective] = useState<{ [objectiveId: string]: Outcome[] }>({});

  // Get the plan details
  const {
    isError,
    error,
    data: planResponse,
    isLoading: planIsLoading,
  }: any = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
  });

  // Get the objectives
  const { data: objectivesResponse, isLoading: objectivesAreLoading } = useQuery(
    [queryKeys.objectives, planId],
    remoteApi.fetchPlanObjectives,
    {
      staleTime,
    },
  );

  // Get the outcomes
  const {
    data: outcomesResponse,
    isLoading: outcomesAreLoading,
    isFetching: outcomesAreFetching,
  } = useQuery([queryKeys.outcomes, planId], remoteApi.fetchPlanOutcomes, {
    staleTime,
  });

  // Get the initiatives
  const { data: initiativesResponse, isLoading: initiativesAreLoading } = useQuery(
    [queryKeys.initiatives, planId],
    remoteApi.fetchPlanInitiatives,
    {
      staleTime,
    },
  );

  useEffect(() => {
    if (outcomesResponse?.data) {
      const outcomes: Outcome[] = outcomesResponse.data;
      const outcomesByObjective: { [objectiveId: string]: Outcome[] } = {};
      // Iterate on each outcome and group items by objective_id in an array
      outcomes.forEach((outcome) => {
        if (outcomesByObjective[outcome.objective_id]) {
          outcomesByObjective[outcome.objective_id].push(outcome);
        } else {
          outcomesByObjective[outcome.objective_id] = [outcome];
        }
      });
      setOutcomesByObjective(outcomesByObjective);
    }
  }, [outcomesResponse]);

  const plan = planResponse ? planResponse.data : null;
  const objectives = objectivesResponse ? objectivesResponse.data : [];
  const outcomes = outcomesResponse ? outcomesResponse.data : [];
  const initiatives = initiativesResponse ? initiativesResponse.data : [];

  const dataIsLoading = planIsLoading || objectivesAreLoading || outcomesAreLoading || initiativesAreLoading;

  // We display the loader until we have loaded the plan and objectives.
  // The reason why we wait for objectives to be loaded here is to check in the Track components
  // if we should display the empty state, or the tracking content
  if ((!plan || dataIsLoading) && !isError) {
    return (
      <Fragment>
        <ChromeContent isGreyBackground>
          <LoadingContainer>
            <Loader size="big" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return (
        <ChromeContent isGreyBackground>
          <AccessDenied />
        </ChromeContent>
      );
    }
    return <NotFound />;
  }

  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);

  // Display empty state if we don't have objectives
  if (plan.archived) {
    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | {t('workspacePlan.title', { plan: plan.title })} | Tability
          </title>
        </Helmet>
        <MobileReadyChromeHeader className="no_border">
          <WorkspaceHeader
            workspace={currentWorkspace}
            useGreyBackground={true}
            title={
              <PlanTitleContainer>
                <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  id="planIconSelector"
                  className="tooltip"
                  effect="solid"
                  delayShow={200}
                >
                  {t('workspacePlan.planIcon')}
                </ReactTooltip>
                <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
              </PlanTitleContainer>
            }
          />
        </MobileReadyChromeHeader>
        <ChromeNav>
          <PlanNav plan={plan} />
        </ChromeNav>
        <ChromeContent className="mask" isGreyBackground>
          <EmptyState>
            <EmptySection>
              <h3>{t('workspacePlan.archived')}</h3>
            </EmptySection>
          </EmptyState>
        </ChromeContent>
      </Fragment>
    );
  }

  // Display empty state if plan is still in draft mode
  if (plan.state === 'draft') {
    const writeRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
      ':planId',
      planId,
    );

    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | {t('workspacePlan.title', { plan: plan.title })} | Tability
          </title>
        </Helmet>
        <MobileReadyChromeHeader className="no_border">
          <WorkspaceHeader
            workspace={currentWorkspace}
            useGreyBackground={true}
            title={
              <PlanTitleContainer>
                <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  id="planIconSelector"
                  className="tooltip"
                  effect="solid"
                  delayShow={200}
                >
                  {t('workspacePlan.planIcon')}
                </ReactTooltip>
                <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
              </PlanTitleContainer>
            }
          />
        </MobileReadyChromeHeader>
        <ChromeNav>
          <PlanNav plan={plan} />
        </ChromeNav>
        <ChromeContent className="mask" isGreyBackground>
          <Tracker
            plan={plan}
            objectives={objectives}
            initiatives={initiatives}
            outcomes={outcomes}
            outcomesByObjective={outcomesByObjective}
            outcomesLoading={outcomesAreFetching}
          />
          <Mask>
            <PopupBanner>
              <p>
                <img src={TabbyGood} alt={t('workspacePlan.draft.imgAlt') ?? 'Your plan ready to track'} />
              </p>
              <p>
                <b>{t('workspacePlan.draft.title')}</b>
              </p>
              <p className="subtle">{t('workspacePlan.draft.description')}</p>
              {hasEditPermission && (
                <p className="actions">
                  <KoalaButton onClick={() => history.push(writeRoute)}>{t('workspacePlan.draft.edit')}</KoalaButton>
                </p>
              )}
            </PopupBanner>
          </Mask>
        </ChromeContent>
      </Fragment>
    );
  }

  // Displays the list of templates
  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspacePlan.title', { plan: plan.title })} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader className="no_border">
        <WorkspaceHeader
          workspace={currentWorkspace}
          useGreyBackground={true}
          title={
            <PlanTitleContainer>
              <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
              <ReactTooltip
                place="bottom"
                type="dark"
                id="planIconSelector"
                className="tooltip"
                effect="solid"
                delayShow={200}
              >
                {t('workspacePlan.planIcon')}
              </ReactTooltip>
              <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
            </PlanTitleContainer>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeNav>
        <PlanNav plan={plan} />
      </ChromeNav>
      <ChromeContent className="grey-bg">
        <Tracker
          plan={plan}
          objectives={objectives}
          outcomes={outcomes}
          outcomesByObjective={outcomesByObjective}
          outcomesLoading={outcomesAreFetching}
          initiatives={initiatives}
        />
      </ChromeContent>
    </Fragment>
  );
}

export default PlanWrite;
