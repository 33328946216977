import styled from 'styled-components'; // Fixing the build
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';
import KoalaInputValidation from 'koala/components/InputValidation';
import { Trans, useTranslation } from 'react-i18next';

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

function ConnectChartMogul() {
  const dispatch = useDispatch();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const { t } = useTranslation();
  const translationKey = 'modals.connectChartMogul';
  const [apiKey, setApiKey] = useState('');
  const queryCache = useQueryCache();

  const [installChartMogulMutation, { isLoading, isError }]: [any, any] = useMutation(remoteApi.installChartMogul, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.integrations]);
      dispatch(setGlobalModalContent(''));
    },
  });

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    const params = {
      body: {
        api_key: apiKey,
      },
      workspaceSlug: workspaceSlug,
    };
    installChartMogulMutation(params);
  };

  const allowSave = apiKey && !isLoading;

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleSave}>
          <FormField>
            <label>{t(`${translationKey}.apiKey`)}</label>
            <input
              placeholder={t(`${translationKey}.amplitudeKeyPlaceholder`) ?? 'API Key'}
              className="segment-input"
              value={apiKey}
              onChange={(e) => setApiKey(e.currentTarget.value)}
            />
            {isError && <KoalaInputValidation state="error" text="Sorry, we could not connect to ChartMogul" />}
            <small className="subtle">
              <Trans
                i18nKey={`${translationKey}.link`}
                components={{
                  a: (
                    <a
                      style={{ color: theme.colors.blue }}
                      href="https://help.chartmogul.com/hc/en-us/articles/4407796325906-Creating-and-Managing-API-keys"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      text
                    </a>
                  ),
                }}
              />
            </small>
          </FormField>
          <FormField></FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!allowSave} loading={isLoading}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default ConnectChartMogul;
