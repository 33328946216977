import React from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQueryCache, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import PlanStatus from 'components/PlanStatus';

// API
import { updatePlan } from 'api/remote';

// Components

import KoalaInlineTextInput from 'koala/components/InlineTextInput';

const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  .hide {
    display: none;
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
  hasEditPermission: boolean;
}

const PlanTitle = (props: Props) => {
  const { plan, hasEditPermission } = props;
  const queryCache = useQueryCache();
  const queryKeyPlan = [queryKeys.currentPlan, plan.nano_slug];

  // Mutation that will update the plan in the backend
  const [updatePlanMutation, { isLoading: isUpdating }]: [any, any] = useMutation(updatePlan, {
    onSuccess: (planResponse) => {
      queryCache.setQueryData(queryKeyPlan, planResponse);
      queryCache.invalidateQueries(queryKeys.currentPlan);
      queryCache.invalidateQueries(queryKeys.plans);
    },
  });

  const updatePlanTitle = (newTitle: string) => {
    const planParams = {
      title: newTitle,
    };
    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  return (
    <Container>
      <KoalaInlineTextInput
        handleChangeText={updatePlanTitle}
        placeholder="Enter plan title"
        text={plan.title}
        canEdit={hasEditPermission}
        size="large"
        hideOverflow={true}
        isUpdating={isUpdating}
      />
      <PlanStatus plan={plan} />
    </Container>
  );
};

export default PlanTitle;
