import React, { useEffect, useState } from 'react';

// API

// Routes

// Components
import { ModalGrid, ModalHeader, ModalContent, ModalFooter } from 'components/GlobalModal';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaButton from 'koala/components/Button';
import { useDispatch } from 'react-redux';
import { sessionActions } from 'state/actions';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useTranslation } from 'react-i18next';

interface Props {}

function SessionTimeoutWarning(props: Props) {
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(60);
  const { t } = useTranslation();
  const translationKey = 'modals.sessionTimeout';

  const handleLogout = () => {
    dispatch(sessionActions.logout());
  };

  const handleContinue = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  useEffect(() => {
    if (!timeLeft) {
      dispatch(sessionActions.logout());
    }
  }, [dispatch, timeLeft]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
      </ModalHeader>
      <ModalContent>
        <p>{t(`${translationKey}.expiring`, { count: timeLeft })}</p>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleContinue}>{t(`${translationKey}.continue`)}</KoalaButton>
        <KoalaTextButton onClick={handleLogout}>{t('shared.logout')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default SessionTimeoutWarning;
