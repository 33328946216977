/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import * as amplitudeUtils from 'utils/amplitude';
import theme from 'theme';
import { useInfiniteQuery } from 'react-query';
import parse from 'parse-link-header';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

// Components
import { Helmet } from 'react-helmet';
import TemplateHeader from 'components/TemplateHeader';
import Loader from 'components/Loader';
import TemplateBlock from 'components/TemplateBlock';
import TemplateSidenav from 'components/TemplateSidenav';
import KoalaButton from 'koala/components/Button';
import { Plan } from 'types';

// Components
const Container = styled.div`
  h2 {
    text-align: center;
  }

  @media ${theme.devices.desktop} {
    width: auto;
    .hide-mobile {
      display: none;
    }
  }
`;

const Header = styled.div`
  padding: ${theme.spacing.x3};
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 3.2rem;
  }
  h3 {
    font-weight: 400;
    color: ${theme.colors.N70};
  }
`;

const TagHeader = styled.div`
  padding: ${theme.spacing.x3} 0;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 3.2rem;
  }
  h3 {
    font-weight: 400;
    color: ${theme.colors.N70};
  }

  b {
    font-weight: 900;
  }
`;

const Wrapper = styled.div`
  max-width: 120rem;
  width: 120rem;
  margin: 0 auto;
  flex: 1;
  display: flex;
  gap: ${theme.spacing.x2};
  padding: ${theme.spacing.x3};

  @media ${theme.devices.desktop} {
    width: auto;
  }
`;

const TemplateMain = styled.div`
  flex: 1;
`;

const TemplatesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: ${theme.spacing.x5};
  flex: 1;
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${theme.spacing.x2};
`;

function TemplatesHome() {
  const { tag } = useParams<{ tag: string }>();

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.templates.home.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  const [templatesGroups, setTemplatesGroups] = useState<AxiosResponse<any>[]>([]);
  const [keyword, setKeyword] = useState<string | null>(null);

  const filter = {
    tag,
    keyword,
  };

  const queryKey = [queryKeys.publicTemplates, filter];
  // Get the templates details
  const { isFetching, isFetchingMore, fetchMore, canFetchMore }: any = useInfiniteQuery(
    queryKey,
    remoteApi.fetchTemplates,
    {
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
      onSuccess: (response) => {
        setTemplatesGroups(response);
      },
    },
  );

  const tagLabelMapping: any = {
    product: 'Product',
    marketing: 'Marketing',
    engineering: 'Engineering',
    hr: 'HR',
    sales: 'Sales',
    design: 'Design',
    growth: 'Growth',
  };

  const hasTemplates = templatesGroups && templatesGroups[0] && templatesGroups[0].data.length > 0;

  // Displays different headers based on the params
  const renderHeader = () => {
    if (keyword) {
      return (
        <TagHeader>
          <h1>
            <b>{keyword}</b> templates
          </h1>
        </TagHeader>
      );
    }
    if (tag) {
      return (
        <TagHeader>
          <h1>{tagLabelMapping[tag] || tag}</h1>
          <h3>Find inspiring OKRs, playbook and checklists to help you achieve your goals</h3>
        </TagHeader>
      );
    }

    return (
      <Header>
        <h1>What's your next plan?</h1>
        <h3>Find inspiring OKRs, playbook and checklists to help you achieve your goals</h3>
      </Header>
    );
  };

  // Displays the list of templates
  return (
    <Container>
      <Helmet>
        <title>OKRs and goals templates | Tability</title>
      </Helmet>
      <TemplateHeader />
      <Wrapper>
        <TemplateSidenav setKeyword={setKeyword} />
        <TemplateMain>
          {renderHeader()}
          {!hasTemplates && <p>Sorry, we could not find any {tagLabelMapping[tag] || tag} templates.</p>}

          <TemplatesWrapper>
            {templatesGroups.map((group: any, index: number) => (
              <Fragment key={index}>
                {group.data.map((template: Plan) => {
                  return <TemplateBlock key={template.id} template={template} />;
                })}
              </Fragment>
            ))}
          </TemplatesWrapper>
          {canFetchMore && (
            <LoadMore>
              <KoalaButton
                appearance="secondary"
                onClick={() => fetchMore()}
                loading={isFetchingMore}
                disabled={isFetchingMore}
              >
                Load more
              </KoalaButton>
            </LoadMore>
          )}
          {isFetching && <Loader />}
        </TemplateMain>
      </Wrapper>
    </Container>
  );
}

export default TemplatesHome;
