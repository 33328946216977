/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import * as amplitudeUtils from 'utils/amplitude';
import theme from 'theme';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import { parseISO, formatDistanceToNow } from 'date-fns';
import yaml from 'js-yaml';

// Components
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import TemplateHeader from 'components/TemplateHeader';
import Loader from 'components/Loader';
import MarkdownContent, { MarkdownWrapper } from 'components/MarkdownContent';
import CategoryLabel from 'components/TemplateBlock/CategoryLabel';

import RelatedTemplates from './RelatedTemplates';

import TwitterSVG from './Twitter.svg';
import LinkedInSVG from './LinkedIn.svg';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
//import LinkSVG from './Link.svg';

// Components
const Container = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${theme.devices.desktop} {
    flex-direction: column;
    h2,
    a {
      margin: ${theme.spacing.x1} 0;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 96rem;

  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x3};
`;

const Description = styled.div`
  margin: ${theme.spacing.x1} 0;
`;

const Content = styled.div`
  margin: ${theme.spacing.x4} 0;
  flex: 1;
`;

const PlanContent = styled.div`
  background: #f3f3f3;
  padding: ${theme.spacing.x3};
  border-radius: 8px;
  margin-bottom: ${theme.spacing.x2};
`;

const LineGrid = styled.div`
  display: grid;
  grid-template-columns: 11rem 1fr;
  grid-template-rows: auto;
  column-gap: ${theme.spacing.x2};
  align-items: center;
  margin-bottom: ${theme.spacing.x1};

  > div {
    display: flex;
    &:first-of-type {
      justify-content: flex-end;
    }

    &:nth-child(2) {
      font-family: monospace, 'Courier New';
    }
  }

  @media ${theme.devices.desktop} {
    grid-template-columns: 7rem 1fr;
    align-items: flex-start;
    column-gap: ${theme.spacing.x2};
    > div {
      &:nth-child(2) {
        font-family: monospace, 'Courier New';
        font-size: 1.4rem;
      }
    }
  }
`;

const Categories = styled.div`
  margin-top: ${theme.spacing.x2};
  > a {
    margin-right: ${theme.spacing.x1};
    margin-bottom: ${theme.spacing.x1};
  }
`;

const Label = styled.div`
  font-size: 1rem;
  height: 2.8rem;
  line-height: 2.8rem;
  letter-spacing: 1px;
  color: #fff;
  background-color: #6096ec;
  border: 0;
  border-radius: 20px;
  padding: 0 1.6rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;

  &.objective {
    background-color: #60aeb9;
  }

  &.outcome {
    background-color: #f5c02b;
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0;
    padding: 0 ${theme.spacing.x2};
  }

  @media ${theme.devices.desktop} {
    height: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: 0px;
    border: 0;
    border-radius: 16px;
    padding: 0 0.8rem;

    &.outcome {
      font-size: 1.2rem;
      padding: 0 ${theme.spacing.x2};
    }
  }
`;

const TaskLabel = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.4rem;
  min-width: 2.8rem;
  min-height: 2.8rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.subtleText};
  color: #fff;
  background-color: #d1d1d1;
  border-radius: 50%;
  font-weight: 800;
  position: relative;

  margin-right: ${theme.spacing.x2};

  @media ${theme.devices.desktop} {
    min-width: 2.4rem;
    min-height: 2.4rem;
    font-size: 1rem;
    border: 0;
    border-radius: 16px;
    padding: 0 0.8rem;
  }
`;

const MetaColumns = styled.div`
  display: flex;

  @media ${theme.devices.desktop} {
    flex-direction: column;
  }
`;

const MetaInfo = styled.div`
  width: 25rem;
  min-width: 25rem;
  margin-left: ${theme.spacing.x6};
  h3 {
    margin: ${theme.spacing.x3} 0 ${theme.spacing.x1} 0;
  }

  @media ${theme.devices.desktop} {
    width: auto;
    min-width: initial;
    margin-left: 0;
  }
`;

const ShareLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkButton = styled.a`
  &:hover {
    text-decoration: underline;
  }
  display: inline-block;
  padding: 0;
  margin-bottom: ${theme.spacing.x1};
  span {
    display: inline-block;
    text-align: center;
    background: #6d6d6d;
    padding: 0;
    margin-right: ${theme.spacing.x1};
    height: 2rem;
    width: 2rem;
    line-height: 2rem;
    border-radius: 4px;
    border: 0;

    img {
      width: 1.2rem;
    }
  }
`;

const MetaContent = styled.div``;

const RelatedItems = styled.div`
  margin-top: ${theme.spacing.x4};
  h3 {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: ${theme.spacing.x2};
    padding-bottom: ${theme.spacing.x1};
  }

  .header {
    font-weight: 800;
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    margin: ${theme.spacing.x3} 0 ${theme.spacing.x1} 0;
    color: ${theme.colors.subtleText};
  }
`;

const Iframe = styled.iframe`
  width: 640px;
  height: 360px;

  @media ${theme.devices.desktop} {
    width: 100%;
  }
`;

function capitalizeFirstLetter(tagToCapitalize: string) {
  return tagToCapitalize.charAt(0).toUpperCase() + tagToCapitalize.slice(1);
}

function TemplatesHome() {
  const [template, setTemplate]: any = useState(null);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  useEffect(() => {
    if (template) {
      amplitudeUtils.sendAmplitudeData('app.templates.file.visit', {
        'Tability Platform': 'web',
        'Tability Version': 2,
        'Template title': template.title,
      });
    }
  }, [template]);

  const { templateId } = useParams<{ templateId: string }>();

  const queryKeyTemplate = [queryKeys.publicTemplates, templateId];
  const staleTime = 0;

  // Get the template2 details
  useQuery(queryKeyTemplate, remoteApi.fetchPublicTemplateDetails, {
    staleTime,
    onSuccess: (response) => setTemplate(response.data),
  });

  if (!template) {
    return (
      <Container>
        <Helmet>
          <title>OKRs template | Tability</title>
        </Helmet>
        <TemplateHeader />
        <Wrapper>
          <Loader size="big" />
        </Wrapper>
      </Container>
    );
  }

  let objectives = [];
  try {
    const content: any = yaml.load(template.yaml_content);
    objectives = content.objectives;
  } catch (e) {}

  const tags = template.tags.split(',');

  const firstTag = tags[0];
  const firstTagCapitalized = capitalizeFirstLetter(firstTag);

  const twitterShareUrl = `https://www.twitter.com/share?url=${window.location.href}`;
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`;

  // Displays the list of templates
  return (
    <Container>
      <Helmet>
        <title>
          {firstTagCapitalized} OKRs template: {template.title} | Tability
        </title>
        <meta name="description" content={template.description} />
      </Helmet>
      <TemplateHeader />
      <Wrapper>
        <Header>{firstTagCapitalized} template for OKRs and team goals</Header>
        <Header>
          <h2>{template.title}</h2>
          <div>
            <Link to="/" className="button primary">
              Use this template
            </Link>
          </div>
        </Header>
        <Description>
          <p>{template.excerpt}</p>
        </Description>
        <Content>
          <PlanContent>
            {objectives.map((objective: any) => {
              const outcomes = objective.outcomes || [];
              return (
                <Fragment>
                  <LineGrid>
                    <div>
                      <Label className="objective">{translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}</Label>
                    </div>
                    <div>{objective.title}</div>
                  </LineGrid>
                  {outcomes.map((outcome: any) => {
                    const initiatives = outcome.initiatives || [];
                    return (
                      <Fragment>
                        <LineGrid>
                          <div>
                            <Label className="outcome">#</Label>
                          </div>
                          <div>{outcome.title}</div>
                        </LineGrid>
                        {initiatives.map((initiative: any) => {
                          return (
                            <Fragment>
                              <LineGrid>
                                <div></div>
                                <div>
                                  <div>
                                    <TaskLabel>&#10003;</TaskLabel>
                                  </div>
                                  {initiative.title}
                                </div>
                              </LineGrid>
                            </Fragment>
                          );
                        })}
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
          </PlanContent>
          <MetaColumns>
            <MetaContent>
              <RelatedItems>
                <h3>About this template</h3>
                <MarkdownContent source={template.description} />
              </RelatedItems>
              <RelatedItems>
                <h3>How you can use this template</h3>
                <MarkdownWrapper>
                  <ol>
                    <li>
                      Sign up for{' '}
                      <a href="https://tability.io" target="_blank" rel="noopener noreferrer">
                        Tability
                      </a>{' '}
                      (it's free for 5 users!)
                    </li>
                    <li>Create a new OKRs/team plan.</li>
                    <li>Use one or more templates to build your plan.</li>
                  </ol>
                </MarkdownWrapper>
                <Iframe
                  title="Use these templates with Tability"
                  src="https://www.loom.com/embed/ba97208dba584c338ec85c4db684bf65"
                  frameBorder="0"
                  allowFullScreen
                ></Iframe>
              </RelatedItems>
              <RelatedItems>
                <RelatedTemplates tag={firstTag} excludeId={template.id} />
              </RelatedItems>
            </MetaContent>
            <MetaInfo>
              <RelatedItems>
                <Link to="/" className="button primary">
                  Use this template
                </Link>
                <Categories>
                  {tags.map((tag: any) => (
                    <CategoryLabel to={`/templates/${tag.trim()}`}>{tag.trim()}</CategoryLabel>
                  ))}
                </Categories>
                <span className="header">Creator</span>
                <a href={template.org_url} target="_blank" rel="noopener noreferrer">
                  {template.org_name}
                </a>
                <br />
                <a href={template.author_url} target="_blank" rel="noopener noreferrer">
                  {template.author_name}
                </a>
                <span className="header">Last updated</span>
                <span>{formatDistanceToNow(parseISO(template.updated_at))} ago</span>
                <span className="header">Copied</span>
                <span>{template.used_count} times</span>
              </RelatedItems>
              <h3>Share</h3>
              <ShareLinks>
                <LinkButton href={twitterShareUrl} target="_blank" rel="noopener noreferrer">
                  <span>
                    <img src={TwitterSVG} alt="Share on Twitter" />
                  </span>
                  tweet
                </LinkButton>
                <LinkButton href={linkedInShareUrl} target="_blank" rel="noopener noreferrer">
                  <span>
                    <img src={LinkedInSVG} alt="Share on LinkedIn" />
                  </span>
                  share
                </LinkButton>
              </ShareLinks>
            </MetaInfo>
          </MetaColumns>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default TemplatesHome;
