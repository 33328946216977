import React, { useEffect } from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';
import theme from 'theme';

import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';

import FormField from 'components/FormField';
import KoalaTextBadge from 'koala/components/TextBadge';

const InlineItems = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
  setScoreChanges: (value: string) => void;
}

function CurrentValue(props: CurrentValueProps) {
  const { outcome, setScoreChanges } = props;

  // Query keys and query params
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:zendesk_support:${outcome.data_source_type}`,
    'current',
  ];
  const staleTime = 300;

  const { data, isFetching } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  const currentValue = data?.data.result;

  useEffect(() => {
    if (currentValue) {
      setScoreChanges(currentValue.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const dataTypeToLabel: any = {
    tickets_created: 'Tickets created',
    tickets_completed: 'Tickets completed',
    resolution_time: 'Minutes to resolution',
    wait_time: 'Minutes waiting',
    on_hold_time: 'Minutes on hold',
  };

  if (!outcome.data_source_type) {
    return null;
  }

  return (
    <FormField>
      <label>Data source</label>
      <InlineItems>
        <KoalaTextBadge edge="circle" size="small" maxWidth="30rem" variant="neutral-light">
          Zendesk Support ({dataTypeToLabel[outcome.data_source_type]})
        </KoalaTextBadge>
        {isFetching && <KoalaLoader size="small" />}
      </InlineItems>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  setScoreChanges: (value: string) => void;
}

function ZendeskSupportSource(props: Props) {
  const { outcome, setScoreChanges } = props;

  const availableDataSourceTypes = [
    'tickets_created',
    'tickets_created',
    'resolution_time',
    'wait_time',
    'on_hold_time',
  ];

  if (!outcome.data_source_type || outcome.data_source_origin !== 'zendesk_support') {
    return null;
  }

  if (availableDataSourceTypes.includes(outcome.data_source_type)) {
    return <CurrentValue outcome={outcome} setScoreChanges={setScoreChanges} />;
  }

  return null;
}

export default React.memo(ZendeskSupportSource);
