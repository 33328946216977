import React from 'react';
import { useSelector } from 'react-redux';
//import theme from 'theme';

// Redux
import { Draggable } from 'react-beautiful-dnd';
import ItemTitle from './ItemTitle';
import LineGrid, { TaskLabel } from './LineGrid';

interface InitiativeProps {
  initiativeId: string;
  index: string;
}

export default function Initiative(props: InitiativeProps) {
  const { index, initiativeId } = props;

  const blockId = `initiatives:${initiativeId}`;
  const selected = useSelector((state: any) => state.ymlEditor.cursor === blockId);
  const className = selected ? 'selected' : '';

  return (
    // @ts-ignore
    <Draggable draggableId={initiativeId} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <LineGrid className={className}>
            <div />
            <div>
              <div>
                <TaskLabel>&#10003;</TaskLabel>
              </div>
              <ItemTitle itemId={initiativeId} itemType="initiatives" />
            </div>
          </LineGrid>
        </div>
      )}
    </Draggable>
  );
}
