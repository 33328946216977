/**
 * Outcome component
 */
import React, { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
//import * as TabilityTypes from '../../../types';
import _ from 'lodash';

// Actions
import {
  editorSetDisplayNewBlockForm,
  editorSelectBlockAbove,
  editorSelectBlockBelow,
  editorUnselectBlock,
  editorUpdateOutcome,
  editorDeleteOutcome,
} from 'state/actions/workspaceEditorActions';

// UI Components
import TitleInput from './TitleInput';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  outcomeId: string;
}

function OutcomeTitle(props: Props) {
  const dispatch = useDispatch();
  const { outcomeId } = props;
  const { t } = useTranslation();

  // Used to avoid saving objective title on first render
  const isInitialRender = useRef(true);

  // Get the data needed to build the component
  const outcome = useSelector((state: any) => state.editorEntities.outcomes[outcomeId]);
  const initiativeIds = useSelector((state: any) => state.editorEntities.outcomesToInitiativesMapping[outcomeId] || []);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [title, setTitle] = useState(outcome.title);

  useEffect(() => {
    setTitle(outcome.title);
  }, [outcome.title]);

  const blockId = `outcome:${outcomeId}`;

  const isFocused = useSelector(
    (state: any) => state.editorUI.selectedBlockId === blockId && !state.editorUI.displayNewBlockForm,
  );

  const saveOutcomeTitle = (newTitle: string) => {
    const title = newTitle;
    if (!title) {
      return;
    }
    const outcomeParams = {
      title,
    };
    dispatch(editorUpdateOutcome(outcomeId, outcomeParams));
  };

  const debounceSaveOutcomeTitle = useRef(
    _.debounce((newTitle: string) => saveOutcomeTitle(newTitle), 500, {
      maxWait: 5000,
    }),
  );

  useEffect(() => {
    if (!isInitialRender.current) {
      debounceSaveOutcomeTitle.current(title);
    }
  }, [title]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
  }, []);

  if (!outcome) {
    return null;
  }

  const handleChange = (e: any) => {
    e.preventDefault();
    let title = e.target.value || '';
    title = title.replace(/(\r\n|\n|\r)/gm, ''); // Remove new lines
    setTitle(title);
  };

  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();
  const initiativesLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase();
  const handlePress = (e: any) => {
    if (e.keyCode === 27) {
      // Esc key
      e.preventDefault();
      e.currentTarget.blur();
      dispatch(editorUnselectBlock());
    }

    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault();
      e.currentTarget.blur();
      dispatch(editorSetDisplayNewBlockForm(true));
    }

    if (e.keyCode === 38) {
      // Arrow up
      e.preventDefault();
      dispatch(editorSelectBlockAbove());
    }

    if (e.keyCode === 40) {
      // Arrow down
      e.preventDefault();
      dispatch(editorSelectBlockBelow());
    }

    // Disable field if we press delete and no text is entered
    if (e.keyCode === 8) {
      // Delete key
      const value = e.target.value;
      if (!value) {
        // Only delete if there's no value
        e.preventDefault();
        if (initiativeIds.length > 0) {
          const confirmation =
            t('workspacePlan.write.deleteOutcome', {
              outcomeLabel: outcomeLabel,
              initiativesLabel: initiativesLabel,
            }) ?? `Deleting this ${outcomeLabel} will delete the ${initiativesLabel} underneath. Is that ok?`;
          if (window.confirm(confirmation)) {
            dispatch(editorSelectBlockAbove());
            dispatch(editorDeleteOutcome(outcome.id));
          }
        } else {
          dispatch(editorSelectBlockAbove());
          dispatch(editorDeleteOutcome(outcome.id));
        }
      }
    }
  };

  return (
    <TitleInput
      text={title}
      type="outcome"
      placeholder={t('workspacePlan.write.placeholder', { label: outcomeLabel }) ?? `Write ${outcomeLabel}`}
      handleChange={handleChange}
      handlePress={handlePress}
      isFocused={isFocused}
    />
  );
}

export default React.memo(OutcomeTitle);
