import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import AccountPanel from './AccountPanel';
import SearchPanel from 'panels/SearchPanel';

import NotificationsList from './NotificationsList';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaIcon from 'koala/components/Icons';
import LanguageSwitcher from 'components/LanguageSwitcher';
import * as membershipUtils from 'utils/membershipUtils';
import KoalaTextBadge from 'koala/components/TextBadge';
import ReactTooltip from 'react-tooltip';

const Container = styled.div`
  display: flex;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  align-items: center;
  justify-content: space-between;

  .canny-button {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.4rem;
    border: 0px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;

    border-radius: 50%;

    &:hover {
      background: #0f0f0f19;
    }
    &:active {
      background: #0f0f0f35;
    }
  }

  .Canny_Badge {
    height: 1.2rem;
    width: 1.2rem;
    background: #955be3 !important;
  }

  @media ${theme.devices.mobile} {
    padding-left: ${theme.spacing.x4};
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  min-height: 5rem;

  button {
    margin-left: ${theme.spacing.half};
  }

  .space-left,
  .avatar,
  .role {
    margin-left: ${theme.spacing.x2};
  }
`;

const NotificationBadge = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  background: ${theme.colors.red};
  position: absolute;
  border-radius: 50%;
  top: -1px;
  right: -1px;
  border: 1px solid #fff;
`;

const NotificationButtonContainer = styled.div`
  position: relative;
`;

interface Props {
  workspace: TabilityTypes.Workspace;
  useGreyBackground: boolean;
  title?: any;
}

function PlaygroundHeader(props: Props) {
  const [showAccountPanel, setShowAccountPanel] = useState(false); // Used to display the account panel
  const [showNotifications, setShowNotifications] = useState(false); // Used to display the notifications panel
  const [showSearchPanel, setShowSearchPanel] = useState(false); // Used to display the search panel
  const { t } = useTranslation();

  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);
  const currentPing = useSelector((state: any) => state.session.currentPing, shallowEqual);

  const { useGreyBackground, title } = props;

  useEffect(() => {
    window.Canny('initChangelog', {
      appID: '60aeb0271bfea77b6a53261f',
      position: 'bottom',
      align: 'right',
    });
  }, []);

  const handleShowAccount = (e: any) => {
    e.preventDefault();
    setShowAccountPanel(true);
  };

  const handleShowNotifications = (e: any) => {
    e.preventDefault();
    setShowNotifications(true);
  };

  const handleShowSearch = (e: any) => {
    e.preventDefault();
    setShowSearchPanel(true);
  };

  let unseenNotificationsCount = 0;
  if (currentPing && currentPing.notifications) {
    unseenNotificationsCount = currentPing.notifications.unseen;
  }

  const iconColor = useGreyBackground ? `${theme.colors.N60}` : `#6D6D6D`;

  return (
    <Container>
      {title}
      <AccountPanel
        showAccountPanel={showAccountPanel}
        setShowAccountPanel={setShowAccountPanel}
        currentUser={currentUser}
      />
      <SearchPanel showSearchPanel={showSearchPanel} setShowSearchPanel={setShowSearchPanel} />
      {showNotifications && <NotificationsList setShowNotifications={setShowNotifications} />}
      <Actions>
        <LanguageSwitcher />
        <KoalaIconButton onClick={handleShowSearch} iconName="search" iconColor={iconColor} />
        <NotificationButtonContainer>
          {unseenNotificationsCount > 0 && <NotificationBadge />}
          <KoalaIconButton onClick={handleShowNotifications} iconName="bell" iconColor={iconColor} />
        </NotificationButtonContainer>
        <button data-canny-changelog className="canny-button">
          <KoalaIcon iconName="sparkles" iconAppearance={iconColor} />
        </button>
        {isReadOnly && (
          <>
            <KoalaTextBadge variant="yellow-light" className="role" dataFor="accountRole">
              Read-only
            </KoalaTextBadge>
            <ReactTooltip
              place="bottom"
              type="dark"
              id="accountRole"
              className="tooltip"
              effect="solid"
              delayShow={200}
            >
              {t('shared.readonlyTip')}
            </ReactTooltip>
          </>
        )}
        <KoalaAvatar user={currentUser} handleClick={handleShowAccount} />
      </Actions>
    </Container>
  );
}

export default React.memo(PlaygroundHeader);
