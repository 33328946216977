import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as TabilityTypes from 'types';

// API
import * as remoteApi from 'api/remote';

import MentionBox from 'components/MentionBox';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    '. content'
    '. footer';
  column-gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  &.primary {
    background: #f3f3f3;
    border-radius: 4px;
    padding: ${theme.spacing.x3};

    border-bottom: 0;
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  grid-area: content;
  margin: ${theme.spacing.x1} 0;

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.6rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }

    width: 70%;
  }
`;

const Footer = styled.div`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.subtleText};

  > div {
    display: flex;
    align-items: center;

    button {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

interface Props {
  standup: TabilityTypes.Standup;
  setIsEditing: any;
}

function CommentBlock(props: Props) {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const { standup, setIsEditing } = props;
  const user = standup.membership?.user;

  // Review comment mutations
  const [updateStandupMutation]: [any, any] = useMutation(remoteApi.updateStandup, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.standups);
      setIsEditing(false);
    },
  });

  // Form values
  const [body, setBody] = useState(standup.body);

  const handleSubmit = () => {
    const params = {
      body,
    };
    updateStandupMutation({
      standupId: standup.id,
      standup: params,
    });
  };

  const placeholder = t('workspaceStandups.editPlaceholder');

  const userName = user ? user.fullname || user.email : t('shared.deletedUser');
  return (
    <Grid>
      <Gutter>
        <KoalaAvatar user={user} size={2.4} hideTooltip />
      </Gutter>
      <Meta>
        <p>{userName}</p>
      </Meta>
      <Content>
        <MentionBox
          setValue={setBody}
          value={body}
          placeholder={placeholder}
          comment={true}
          cmdEnterCallback={handleSubmit}
        />
      </Content>
      <Footer>
        <div>
          <KoalaButton onClick={handleSubmit} size="small">
            {t('shared.update')}
          </KoalaButton>
          <KoalaTextButton
            onClick={() => {
              setIsEditing(false);
            }}
            size="small"
          >
            {t('shared.cancel')}
          </KoalaTextButton>
        </div>
      </Footer>
    </Grid>
  );
}

export default React.memo(CommentBlock);
