import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  background: ${theme.colors.N5};
  border: 0px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  padding-top: ${theme.spacing.x1};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  h4 {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
    font-size: 1rem;
    min-height: 2.4rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: ${theme.spacing.x1};
    font-weight: 800;
  }

  &.clickable {
    &:hover {
      cursor: pointer;
      background: ${theme.colors.N3};
    }
  }

  &.green {
    background: ${theme.colors.G5};
  }
  &.red {
    background: ${theme.colors.R5};
  }
  &.yellow {
    background: ${theme.colors.Y5};
  }
`;
