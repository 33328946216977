/**
This is the playground editor. It loads the state from local storage and saves there too
*/

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as amplitudeUtils from 'utils/amplitude';

// See https://stackoverflow.com/questions/39823648/how-do-integrate-amplitude-analytics-with-a-react-app

function AmplitudeIDSync() {
  const currentUserId = useSelector((state: any) => (state.session.currentUser ? state.session.currentUser.id : null));

  // Init amplitude
  useEffect(() => {
    amplitudeUtils.initAmplitude();
  }, []);

  const location = window.location;
  const urlParams = new URLSearchParams(location.search);
  const ampId = urlParams.get('ampId');

  // Create the redirect URL
  const redirectUrl = new URL(location.href);
  redirectUrl.searchParams.delete('ampId'); // Remove ampId from the search param

  useEffect(() => {
    if (ampId) {
      amplitudeUtils.setAmplitudeUserDevice(ampId);
      amplitudeUtils.sendAmplitudeData('app.device.id.synced', {
        'Tability Platform': 'web',
        'Tability Version': 2,
      });
      // Redirect to barebone path after sync
      window.location.href = redirectUrl.href;
    }
  }, [ampId, redirectUrl.href]); // empty array => run only once

  useEffect(() => {
    if (currentUserId) {
      amplitudeUtils.setAmplitudeUserId(currentUserId);
      amplitudeUtils.sendAmplitudeData('app.user.activity', {
        'Tability Platform': 'web',
        'Tability Version': 2,
      });
    }
  }, [currentUserId]);

  return null;
}

export default AmplitudeIDSync;
