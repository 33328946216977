export const isEmptyHTMLText = (htmlText: string) => {
  // Strip HTML from text to see if we have an empty field.

  let doc = new DOMParser().parseFromString(htmlText, 'text/html');
  const isEmpty = !htmlText || !doc.body.textContent || doc.body.textContent === '';

  return isEmpty;
};

export function truncateString(str: string, num: number) {
  if (!str || !num) {
    return null;
  }
  if (str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
}
