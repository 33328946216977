/**
 * Initiative component
 */
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// UI Components
import CommentButton from './CommentButton';
import CommentIcon from 'components/_assets/CommentIcon';

interface Props {
  objectiveId: string;
  reviewCommentsCount: number;
}

function ObjectiveBlockMeta(props: Props) {
  const history = useHistory();
  const { objectiveId, reviewCommentsCount } = props;
  const blockId = `objective:${objectiveId}`;

  const handleCreateComment = () => {
    const hashPath = `#editor:${blockId}:comment`;
    history.push(hashPath);
  };

  const commentClass = reviewCommentsCount > 0 ? 'has-comments' : 'masked-icon';
  return (
    <Fragment>
      <div className="spacer" />
      <CommentButton className={commentClass} onClick={handleCreateComment}>
        <div className="icon">
          <CommentIcon />
        </div>
        <div className="value">{reviewCommentsCount > 0 && <Fragment>{reviewCommentsCount}</Fragment>}</div>
      </CommentButton>
    </Fragment>
  );
}

export default React.memo(ObjectiveBlockMeta);
