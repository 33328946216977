import React, { useState } from 'react';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

import { OutcomeRelationship } from 'types';
import * as remoteApi from 'api/remote';
import styled from 'styled-components';
import theme from 'theme';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  b {
    color: ${theme.colors.N50};
  }
`;

interface Props {
  outcomeId: string;
}

function OutcomeChildren(props: Props) {
  const { outcomeId } = props;
  const { t } = useTranslation();

  const [parents, setParent] = useState<OutcomeRelationship[]>([]);

  const staleTime = 0;

  const queryKeyRelationship = [queryKeys.outcomeRelationships, outcomeId];

  // Get the outcomes
  useQuery(queryKeyRelationship, remoteApi.fetchOutcomeRelationships, {
    staleTime,
    onSuccess: (response: any) => {
      const outcomeRelationships: OutcomeRelationship[] = response.data;
      const parents: OutcomeRelationship[] = [];
      outcomeRelationships.forEach((outcomeRelationship) => {
        if (
          outcomeRelationship.outcome_source_id === outcomeId &&
          outcomeRelationship.relationship_type === 'contributes_to'
        ) {
          parents.push(outcomeRelationship);
        }
      });
      setParent(parents);
    },
  });

  const parentsCount = parents.length;
  const parentsLabel =
    parentsCount > 1
      ? t('shared.outcomes.parentsPlural', { count: parentsCount })
      : t('shared.outcomes.parentsSingular', { count: parentsCount });

  if (parentsCount === 0) {
    return null;
  }

  return (
    <>
      <KoalaTextBadge variant="neutral-light" size="small" dataFor={`${outcomeId}-parents`}>
        {parentsLabel}
      </KoalaTextBadge>
      <ReactTooltip place="bottom" type="dark" className="tooltip" effect="solid" id={`${outcomeId}-parents`}>
        <ParentContainer>
          {parents.map((outcomeRelationship: OutcomeRelationship) => {
            const outcome = outcomeRelationship.outcome_target;
            const plan = outcome.plan;
            return (
              <span>
                <b>{plan.title} /</b> {outcome.title}
              </span>
            );
          })}
        </ParentContainer>
      </ReactTooltip>
    </>
  );
}

export default OutcomeChildren;
