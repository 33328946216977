import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

// Dashboard pages
import DashboardWorkspacesNew from 'pages/DashboardWorkspacesNew';
import DashboardTerms from 'pages/DashboardTerms';
import DashboardWorkspaces from 'pages/DashboardWorkspaces';
import NotFound from 'components/NotFound';
import OnboardingPassword from 'pages/OnboardingPassword';

// Components
import { ChromeWrapper, ChromeContent } from 'components/Chrome';
import LoadingScreen from 'components/LoadingScreen';

import {
  DASHBOARD_ROOT_ROUTE,
  DASHBOARD_INBOX_ROUTE,
  DASHBOARD_WORKSPACES_ROUTE,
  DASHBOARD_WORKSPACES_NEW_ROUTE,
  DASHBOARD_TERMS_ROUTE,
  ONBOARDING_PASSWORD_ROUTE,
} from 'routes';

function DashboardRoutes() {
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);

  if (!currentUser) {
    return <LoadingScreen />;
  }
  // Now we can display the app with the Chrome
  return (
    <ChromeWrapper>
      <ChromeContent>
        <Switch>
          <Route path={DASHBOARD_ROOT_ROUTE} exact={true} render={() => <Redirect to="/" />} />
          <Route path={DASHBOARD_INBOX_ROUTE} exact={true} render={() => <Redirect to="/" />} />
          <Route path={DASHBOARD_WORKSPACES_NEW_ROUTE} exact={true}>
            <DashboardWorkspacesNew />
          </Route>
          <Route path={DASHBOARD_TERMS_ROUTE} exact={true}>
            <DashboardTerms />
          </Route>
          <Route path={ONBOARDING_PASSWORD_ROUTE} exact={true}>
            <OnboardingPassword />
          </Route>
          <Route path={DASHBOARD_WORKSPACES_ROUTE} exact={true}>
            <DashboardWorkspaces />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </ChromeContent>
    </ChromeWrapper>
  );
}

export default DashboardRoutes;
