import React from 'react';

const CheckBoxCheckIcon = () => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3201 1.64001L4.38005 8.36002L1.68005 5.30547"
      stroke="#293C9D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckBoxCheckIcon;
