import React from 'react';

export default () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.9752 32H7.0262C3.14521 32 0 28.8535 0 24.9741V7.02588C0 3.14507 3.14521 0 7.0262 0H24.9738C28.8548 0 32 3.14507 32 7.02588V24.9727C32.0014 28.8535 28.8548 32 24.9752 32Z"
      fill="#151515"
    />
    <path
      d="M17.7107 12.5875C17.0533 11.9286 16.6477 11.0249 16.6477 10.0202V7.30347H15.3527V10.0202V19.7713C15.3527 21.1332 15.9048 22.3642 16.7971 23.2565C17.688 24.1459 18.919 24.6967 20.278 24.6967H22.9947V23.4017H20.278C19.2733 23.4017 18.3696 22.9961 17.7107 22.3386C17.0533 21.6797 16.6477 20.776 16.6477 19.7713V13.3246C16.9892 13.7003 17.3863 14.0148 17.8289 14.271V19.6674C17.8289 21.082 18.9759 22.229 20.3905 22.229H22.9962V20.934H20.3905C20.039 20.934 19.7259 20.7931 19.4953 20.5626C19.2648 20.3306 19.1239 20.0189 19.1239 19.666V14.7947C19.4967 14.8844 19.8796 14.9456 20.2795 14.9456H22.9962V13.6505H20.2795C19.2733 13.6505 18.3696 13.2464 17.7107 12.5875Z"
      fill="white"
    />
    <path
      d="M20.3889 11.1757C20.0374 11.1757 19.7243 11.0349 19.4937 10.8043C19.2632 10.5723 19.1223 10.2607 19.1223 9.90775V7.30347H17.8273V9.90918C17.8273 11.3237 18.9743 12.4708 20.3889 12.4708H22.9946V11.1757H20.3889Z"
      fill="white"
    />
    <path
      d="M14.2026 20.2324C14.1841 20.0815 14.1713 19.9278 14.1713 19.7713V9.90918V7.30347H12.8763V9.90918C12.8763 10.2607 12.7354 10.5738 12.5049 10.8057C12.2729 11.0363 11.9612 11.1772 11.6083 11.1772H9.00403V12.4722H11.6097C12.0737 12.4722 12.502 12.3398 12.8763 12.1235V13.4428C12.512 13.5666 12.1292 13.6519 11.7207 13.6519H9.00403V14.947H11.7207C12.1178 14.947 12.5049 14.8957 12.8763 14.8061V19.7713C12.8763 21.7494 14.0433 23.4572 15.7254 24.2384C15.9459 24.3409 16.1793 24.422 16.417 24.4918C16.2605 24.3637 16.1096 24.2285 15.9673 24.0862C14.9668 23.0871 14.3151 21.7309 14.2026 20.2324Z"
      fill="white"
    />
  </svg>
);
