import FormField from 'components/FormField';
import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Outcome } from 'types';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';

interface CurrentValueProps {
  outcome: Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;

  // Query keys and query params
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:zendesk_support:${outcome.data_source_type}`,
    'current',
  ];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null || currentValue === undefined) {
    return <FormField>Sorry, an error occurred while fetching the current value.</FormField>;
  }

  const dataTypeToLabel: any = {
    tickets_created: '<number> tickets created',
    tickets_completed: '<number> tickets completed',
    resolution_time: '<number> minutes to resolution',
    wait_time: '<number> minutes waiting',
    on_hold_time: '<number> minutes on hold',
  };

  const label = dataTypeToLabel[outcome.data_source_type || ''];
  const labelWithNumber = label.replace('<number>', currentValue.toLocaleString());
  return (
    <FormField>
      <label>Preview value</label>
      <p>{labelWithNumber}</p>
    </FormField>
  );
}

interface Props {
  outcome: Outcome;
  workspaceSlug: string;
}

// Component that loads the current value of from the data source
function ZendeskSupportSource(props: Props) {
  const { outcome } = props;
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');
  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'zendesk_support',
      data_source_type: data_source_type,
      data_source_meta: {},
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const availableDataSourceTypes = [
    'tickets_created',
    'tickets_completed',
    'resolution_time',
    'wait_time',
    'on_hold_time',
  ];

  const hasChanged = outcome.data_source_type !== data_source_type;
  return (
    <>
      <FormField>
        <label>Change Metric type</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">Select a metric</option>
          <option value="tickets_created">Number of tickets created</option>
          <option value="tickets_completed">Number of tickets completed</option>
          <option value="resolution_time">Average resolution time</option>
          <option value="wait_time">Average wait time</option>
          <option value="on_hold_time">Average time on hold</option>
        </select>
      </FormField>
      {hasChanged && (
        <FormField>
          <KoalaButton
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={!data_source_type || isUpdating}
            appearance="subtle"
          >
            Save & preview
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'zendesk_support' &&
        outcome.data_source_type &&
        outcome.data_source_type === data_source_type && (
          <>{availableDataSourceTypes.includes(outcome.data_source_type) && <CurrentValue outcome={outcome} />}</>
        )}
    </>
  );
}

export default React.memo(ZendeskSupportSource);
