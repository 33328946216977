import React from 'react';

const LabelAlignmentArrowIcon = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 1.5C2 0.947715 1.55228 0.5 1 0.5C0.447715 0.5 0 0.947715 0 1.5V5.5C0 7.15685 1.34315 8.5 3 8.5H8.58579L6.29289 10.7929C5.90237 11.1834 5.90237 11.8166 6.29289 12.2071C6.68342 12.5976 7.31658 12.5976 7.70711 12.2071L11.7071 8.20711C12.0976 7.81658 12.0976 7.18342 11.7071 6.79289L7.70711 2.79289C7.31658 2.40237 6.68342 2.40237 6.29289 2.79289C5.90237 3.18342 5.90237 3.81658 6.29289 4.20711L8.58579 6.5H3C2.44772 6.5 2 6.05229 2 5.5V1.5Z"
      fill="#737373"
    />
  </svg>
);

export default LabelAlignmentArrowIcon;
