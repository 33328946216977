import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';

// API
import * as remoteApi from 'api/remote';

// Actions
import { sessionActions } from 'state/actions/';

import { Link } from 'react-router-dom';

import * as routes from 'routes';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';

const Mask = styled.div<{ showAccountPanel: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${(props) => (props.showAccountPanel ? '50' : '-1')};
  opacity: ${(props) => (props.showAccountPanel ? '1' : '0')};
  transition: 0.2s ease-out;
`;

const ContentWrapper = styled.div<{ showAccountPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40rem;
  z-index: 60;
  transform: translateX(${(props) => (props.showAccountPanel ? '0%' : '100%')});
  transition: 0.2s ease-out;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 3.2rem;

  background: #fff;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 6rem;
  grid-template-rows: auto;
  grid-template-areas: 'sp-content sp-sidebar ';
  height: 100%;
`;

const GridSidebar = styled.div`
  grid-area: sp-sidebar;
`;

const GridContent = styled.div`
  grid-area: sp-content;
  display: flex;
  flex-direction: column;

  b {
    color: ${theme.colors.subtleText};
    padding: ${theme.spacing.x4} 0 ${theme.spacing.x2} 0;
  }

  .sp-item {
    color: ${theme.colors.black};
    margin: ${theme.spacing.x2} 0;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  hr {
    border: 0px;
    width: 100%;
    height: 1px;
    background: ${theme.colors.blockBorder};
  }
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  color: #151515;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: ${theme.spacing.x3};

  div {
    margin-right: ${theme.spacing.x1};
  }
`;

interface Props {
  showAccountPanel: boolean;
  setShowAccountPanel: (value: boolean) => void;
  currentUser: TabilityTypes.User;
  currentMembership: TabilityTypes.Membership;
}

function Panel(props: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [deleteMembershipMutation]: [any, any] = useMutation(remoteApi.deleteMembership, {
    onSuccess: () => {
      window.location.replace('/');
    },
  });

  const { showAccountPanel, setShowAccountPanel, currentUser, currentMembership } = props;
  const name = currentUser.fullname || currentUser.email;

  const handleClosePanel = () => {
    setShowAccountPanel(false);
  };

  const handleLeaveWorkspace = () => {
    deleteMembershipMutation(currentMembership.id);
  };

  const handleLogout = () => {
    dispatch(sessionActions.logout());
  };

  return (
    <Fragment>
      <Mask onClick={handleClosePanel} showAccountPanel={showAccountPanel} />
      <ContentWrapper showAccountPanel={showAccountPanel}>
        <Content>
          <Grid>
            <GridSidebar>
              <KoalaIconButton onClick={handleClosePanel} iconName="rightArrow" />
            </GridSidebar>
            <GridContent>
              <UserProfile>
                <KoalaAvatar user={currentUser} />
                {name}
              </UserProfile>
              <Link className="sp-item" target="blank" to={routes.ACCOUNT_ROOT_ROUTE} onClick={handleClosePanel}>
                {t('accountDetails.title')}
              </Link>
              <div className="sp-item" role="button" onClick={handleLeaveWorkspace}>
                {t('shared.leave')}
              </div>
              <hr />
              <div className="sp-item" role="button" onClick={handleLogout}>
                {t('shared.logout')}
              </div>
            </GridContent>
          </Grid>
        </Content>
      </ContentWrapper>
    </Fragment>
  );
}

export default Panel;
