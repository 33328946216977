import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { useInfiniteQuery, useQueryCache, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import parse from 'parse-link-header';
import { parseISO, formatDistanceToNow } from 'date-fns';
import { useHistory, Link, useLocation } from 'react-router-dom';
import Loader from 'components/Loader';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  padding: ${theme.spacing.x4};
`;

const Table = styled.table`
  a {
    color: ${theme.colors.blue};
    &:hover {
      text-decoration: underline;
    }
  }
  width: 100%;
  margin-top: ${theme.spacing.x2};

  tr {
    cursor: pointer;
    &:hover {
      background-color: #f3f3f3;
    }
  }
  td {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
    vertical-align: middle;

    .error {
      color: ${theme.colors.red};
    }

    &.published {
      background: ${theme.colors.successBg};
    }
    &.draft {
      background: ${theme.colors.warningBg};
    }
  }

  th {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.2rem;

    &:nth-child(1) {
      width: 5rem;
    }
    &:nth-child(2) {
      width: 20%;
    }
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
`;

export default function PublicTemplates() {
  const history = useHistory();
  const location = useLocation();
  const queryCache = useQueryCache();
  const searchParams = new URLSearchParams(location.search);
  const order = searchParams.get('order');
  const { t } = useTranslation();
  const queryKey = [queryKeys.publicTemplates, { show_all: true, order }];
  const [publicTemplatesGroups, setPublicTemplatesGroups] = useState([]);
  // Get the templates details

  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  const { canFetchMore, fetchMore, isFetchingMore } = useInfiniteQuery(queryKey, remoteApi.fetchPublicTemplates, {
    staleTime: 0,
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    onSuccess: (response: any) => {
      setPublicTemplatesGroups(response);
    },
  });

  // Mutation that will create the public_template in the backend
  const [createPublicTemplateMutation]: [any, any] = useMutation(remoteApi.createPublicTemplate, {
    onSuccess: (response: any) => {
      const public_template = response.data;
      queryCache.invalidateQueries(queryKey); // Reload the current user public_templates
      history.push(`/public_templates/${public_template.nano_slug}`);
    },
  });

  const handleCreate = (e: any) => {
    e.preventDefault();
    const templateParams = {
      title: 'New template',
      description: 'TBD',
      tags: 'tbd',
      author_name: 'tbd',
      published: false,
    };
    createPublicTemplateMutation(templateParams);
  };

  let counter = 0;
  return (
    <Container>
      <h1>{t('publicTemplates.title')}</h1>
      <KoalaButton onClick={handleCreate} appearance="subtle">
        {t('publicTemplates.createButton')}
      </KoalaButton>
      <Table>
        <tbody>
          <tr>
            <th>#</th>
            <th>{t('publicTemplates.titleLabel')}</th>
            <th>
              <Link to={`${location.pathname}?order=created_at`}>Created</Link>
            </th>
            <th>{t('publicTemplates.published')}</th>
            <th>{t('publicTemplates.tags')}</th>
            <th>{t('publicTemplates.author')}</th>
            <th>{t('publicTemplates.org')}</th>
            <th>
              <Link to={location.pathname}>{t('publicTemplates.used')}</Link>
            </th>
          </tr>
          {publicTemplatesGroups.map((group: any, index: number) => {
            const public_templates = group.data;
            return (
              <Fragment key={index}>
                {public_templates.map((template: any) => {
                  counter++;
                  const publishedClass = template.published ? 'published' : 'draft';
                  return (
                    <tr key={template.nano_slug}>
                      <td>{counter}</td>
                      <td>
                        <Link to={`/public_templates/${template.nano_slug}`}>{template.title}</Link>
                      </td>
                      <td>{formatDistanceToNow(parseISO(template.created_at))} ago</td>
                      <td className={publishedClass}>{template.published.toString()}</td>
                      <td>{template.tags}</td>
                      <td>{template.author_name}</td>
                      <td>{template.org_name}</td>
                      <td>{template.used_count}</td>
                    </tr>
                  );
                })}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      {canFetchMore && (
        <LoadMore>
          <KoalaButton onClick={() => fetchMore()} appearance="secondary">
            {t('shared.loadMore')}
          </KoalaButton>
          {isFetchingMore && <Loader size="small" />}
        </LoadMore>
      )}
    </Container>
  );
}
