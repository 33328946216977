import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

const DisabledLink = styled.div`
  width: 100%;
  height: 4.8rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  padding: 0 ${theme.spacing.x2};
  position: relative;
  cursor: not-allowed;

  opacity: 0.5;
`;

interface Props {
  className?: string;
  to: string;
  onClick?: (e: React.MouseEvent) => void;
  dataFor?: string;
  children: any;
  isDisabled?: boolean;
}

function SidebarNavLink(props: Props) {
  const { children, onClick, to, className, isDisabled, dataFor } = props;
  if (isDisabled) {
    return (
      <DisabledLink data-tip={dataFor ? true : false} data-for={dataFor} className={className}>
        {children}
      </DisabledLink>
    );
  }
  return (
    <NavLink
      to={to}
      data-tip={dataFor ? true : false}
      data-for={dataFor}
      onClick={onClick}
      className={`enabled ${className}`}
    >
      {children}
    </NavLink>
  );
}

export default SidebarNavLink;
