import React, { useState } from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';

// API

import DisplayStandupBlock from './_DisplayStandupBlock';
import EditStandupBlock from './_EditStandupBlock';
import StandupComments from '../StandupComments';
import NewCommentBlock from '../StandupComments/NewCommentBlock';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  standup: TabilityTypes.Standup;
}

function CommentBlock(props: Props) {
  const { standup } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [showNewComment, setShowNewComment] = useState(false);

  const handleSetComment = (value: boolean) => {
    setShowNewComment(value);
  };

  return (
    <Container>
      {isEditing && <EditStandupBlock standup={standup} setIsEditing={setIsEditing} />}
      {!isEditing && (
        <DisplayStandupBlock standup={standup} setIsEditing={setIsEditing} setShowNewComment={handleSetComment} />
      )}
      {standup.standup_comments_count > 0 && <StandupComments standup={standup} />}
      {showNewComment && <NewCommentBlock standup={standup} setShowNewComment={handleSetComment} />}
    </Container>
  );
}

export default React.memo(CommentBlock);
