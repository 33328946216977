import React, { useState } from 'react';
import * as TabilityTypes from 'types';

import TagPicker from './TagPicker';
import TagList from './TagList';
import { useTranslation } from 'react-i18next';

// Components
interface Props {
  initiative: TabilityTypes.Initiative;
}

function TagPickerInitiative(props: Props) {
  const { initiative } = props;
  const [displayPicker, setDisplayPicker] = useState(false);
  const { t } = useTranslation();

  if (displayPicker) {
    return (
      <div className="tag-container tag-container--picker">
        <label>{t('shared.tags')}</label>
        <TagPicker initiative={initiative} setDisplayPicker={setDisplayPicker} />
      </div>
    );
  }
  return (
    <div className="tag-container tag-container--list">
      <label>{t('shared.tags')}</label>
      <TagList initiative={initiative} setDisplayPicker={setDisplayPicker} />
    </div>
  );
}

export default React.memo(TagPickerInitiative);
