export const getDefaultWidgetDimensions = (widgetType: string) => {
  switch (widgetType) {
    case 'plan-summary':
      return { w: 9, h: 4, minW: 3, minH: 4 };
    case 'objective-summary':
      return { w: 5, h: 6, minW: 3, minH: 6 };
    case 'outcome-summary':
      return { w: 4, h: 5, minW: 4, minH: 5 };
    case 'initiative-summary':
      return { w: 4, h: 3, minW: 4, minH: 3 };
    case 'user-profile':
      return { w: 3, h: 5, minW: 3, minH: 4 };
    case 'objective-list':
      return { w: 8, h: 8, minW: 8, minH: 6 };
    case 'outcome-list':
      return { w: 8, h: 8, minW: 8, minH: 6 };
    case 'initiative-list':
      return { w: 8, h: 8, minW: 8, minH: 6 };
    case 'outcome-task-graph':
      return { w: 6, h: 7, minW: 6, minH: 7 };
    case 'plan-confidence-graph':
      return { w: 6, h: 7, minW: 6, minH: 7 };
    case 'plan-ncs-graph':
      return { w: 4, h: 5, minW: 4, minH: 5 };
    case 'outcome-graph':
      return { w: 4, h: 5, minW: 4, minH: 5 };
    default:
      return { w: 3, h: 6, minW: 2, minH: 3 };
  }
};
