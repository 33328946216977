/**
  This is the playground editor. It loads the state from local storage and saves there too
 */

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory, useParams } from 'react-router-dom';
import * as TabilityTypes from 'types';

// Routes
import * as routes from 'routes';

// Components
import { Link } from 'react-router-dom';
import Wordmark from 'components/Wordmark';
import PlanIconLabel from 'components/PlanIconLabel';
import RadioField from 'components/RadioField';
import KoalaIcon from 'koala/components/Icons';
import ReactTooltip from 'react-tooltip';
import PlanStatus from 'components/PlanStatus';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.x1};
  border-bottom: solid 1px ${theme.colors.N40};
  height: 4.8rem;

  .dark {
    path {
      fill: ${theme.colors.N0};
    }
  }
`;

const PoweredByContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  background: #000;
  color: #fff;

  > div {
    display: flex;
    gap: ${theme.spacing.x1};
    align-items: center;
  }

  img {
    width: 8rem;
  }

  a.button {
    border-color: #fff;
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }

  a.button.primary {
    border-color: transparent;
    text-decoration: none;
  }

  @media ${theme.devices.mobile} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
  }

  &.dark {
    background: #151515;
  }
`;

const HeaderSection = styled.div<{ darkMode?: boolean }>`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;

  .view-selection {
    margin: 0;
  }
  &.dark {
    color: ${theme.colors.N0};

    a {
      color: #fff;
    }
  }
  .radio-group {
    height: 3.2rem;
  }

  label.larger-icon {
    svg {
      width: 2.4rem;
      height: 2.4rem;
      path {
        stroke: none;
      }
    }
  }
`;

const Container = styled.div`
  grid-area: header;
`;

interface Props {
  plan: TabilityTypes.Plan;
  isDashboard?: boolean;
}

function PublicPlanNav(props: Props) {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { plan, isDashboard } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const className = isDashboard ? 'dark' : '';

  const handleViewChange = (e: any) => {
    let presentPath =
      e.target.value === 'dashboard' ? routes.PUBLIC_PLAN_PREZ_DASHBOARD_ROUTE : routes.PUBLIC_PLAN_PREZ_ROOT_ROUTE;
    presentPath = presentPath.replace(':workspaceSlug', workspaceSlug).replace(':planId', plan.nano_slug);
    history.push(presentPath);
  };

  const trackPath = routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    plan.nano_slug,
  );

  return (
    <Container>
      <PoweredByContainer className={className}>
        <div>
          <span>{t('shared.poweredBy')}</span>
          <Wordmark type="light" />
        </div>
        <div>
          <Link to={'/login'} className="button">
            {t('shared.login')}
          </Link>
          <Link to={'/signup'} className="button primary">
            {t('shared.signup')}
          </Link>
        </div>
      </PoweredByContainer>
      <Wrapper>
        <HeaderSection className={className}>
          <Link to={trackPath}>
            <PlanIconLabel plan={plan} />
          </Link>
          <PlanStatus plan={plan} />
        </HeaderSection>
        <HeaderSection>
          <RadioField className="view-selection">
            <div className="radio-group icons-only ">
              <>
                <input
                  type="radio"
                  id="compact"
                  checked={!isDashboard}
                  name="view"
                  value={'presentation'}
                  onChange={handleViewChange}
                />
                <label htmlFor="compact" className="light larger-icon" data-tip data-for="presentation-view">
                  <KoalaIcon iconName="present" />
                </label>
              </>
              <>
                <input
                  type="radio"
                  id="tree"
                  checked={isDashboard}
                  name="view"
                  value={'dashboard'}
                  onChange={handleViewChange}
                />
                <label htmlFor="tree" className="light larger-icon" data-tip data-for="dashboard-view">
                  <KoalaIcon iconName="tv" />
                </label>
              </>
            </div>
            <ReactTooltip place="bottom" type="dark" id={`presentation-view`} className="tooltip" effect="solid">
              {t('publicPlan.presentation')}
            </ReactTooltip>
            <ReactTooltip place="bottom" type="dark" id={`dashboard-view`} className="tooltip" effect="solid">
              {t('publicPlan.tv')}
            </ReactTooltip>
          </RadioField>
        </HeaderSection>
      </Wrapper>
    </Container>
  );
}

export default PublicPlanNav;
