import React from 'react';
import { ChromeSideNav, ChromeContent } from 'components/Chrome';
import ContentTitle from 'components/ContentTitle';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import { Fragment, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Tag, Workspace } from 'types';
import * as remoteApi from 'api/remote';
import { useInfiniteQuery } from 'react-query';
import parse from 'parse-link-header';
import queryKeys from 'config/queryKeys';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import KoalaLoader from 'koala/components/Loader';
import styled from 'styled-components';
import theme from 'theme';
import TagRow from './TagRow';
import KoalaButton from 'koala/components/Button';
import { AxiosResponse } from 'axios';

const TagSection = styled.div`
  padding-top: ${theme.spacing.x2};
`;

const TagHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 9rem;
  padding: ${theme.spacing.x1};

  color: ${theme.colors.N70};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.1rem;
`;

const FetchSection = styled.div`
  display: flex;
  padding: ${theme.spacing.x1};
  align-items: center;
  justify-content: center;
`;

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

interface Props {
  workspace: Workspace;
}

function WorkspaceSettingsTags(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();
  const [nameToSearch, setNameToSearch] = useState('');

  const tagParams = nameToSearch ? { search: nameToSearch } : {};

  const searchTagsQueryKey = [queryKeys.tags, workspace.slug, tagParams];
  const [tags, setTags] = useState<AxiosResponse<Tag[]>[]>([]);

  const staleTime = 0;

  const { isFetching, canFetchMore, fetchMore, isLoading } = useInfiniteQuery(searchTagsQueryKey, remoteApi.fetchTags, {
    staleTime,
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    onSuccess: (response) => {
      setTags(response);
    },
  });

  // START SEARCH LOGIC
  const performSearch = (newName: string) => {
    setNameToSearch(newName);
  };
  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );
  const handleSearch = (e: any) => {
    const newName = e.target.value;
    debouncePerformSearch.current(newName);
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsTags.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsTags.title')}</h1>
          </ContentTitle>
          <input placeholder={t('workspaceSettingsTags.searchPlaceholder') ?? 'Search tags'} onChange={handleSearch} />

          <TagSection>
            <TagHeader>
              <span>{t(`workspaceSettingsTags.tagName`)}</span>
            </TagHeader>
            {isLoading && <KoalaLoader />}

            {tags.map((group, i: number) => {
              return (
                <Fragment key={i}>
                  {group.data.map((tag: Tag, index: number) => (
                    <TagRow tag={tag} key={index} />
                  ))}
                </Fragment>
              );
            })}

            {canFetchMore && (
              <FetchSection>
                <KoalaButton onClick={() => fetchMore()} loading={isFetching} appearance="secondary">
                  {t(`shared.loadMore`)}
                </KoalaButton>
              </FetchSection>
            )}
          </TagSection>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsTags;
