import React from 'react';

// This icon will be used for check-ins and initiative updates

const WriteUpdateIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5C3 3.89543 3.89543 3 5 3H8.4C8.95229 3 9.4 2.55228 9.4 2C9.4 1.44772 8.95229 1 8.4 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2091 2.79086 19 5 19H15C17.2091 19 19 17.2091 19 15V11.6C19 11.0477 18.5523 10.6 18 10.6C17.4477 10.6 17 11.0477 17 11.6V15C17 16.1046 16.1046 17 15 17H5C3.89543 17 3 16.1046 3 15V5ZM17.2071 2.79291C15.9882 1.57396 14.0118 1.57395 12.7929 2.79291L12.2961 3.28974L12.2929 3.29289L12.2897 3.29606L6.29601 9.28979C6.29497 9.29082 6.29393 9.29185 6.29289 9.29289C6.29185 9.29393 6.29082 9.29497 6.28979 9.29601L5.29289 10.2929C5.10536 10.4804 5 10.7348 5 11V14C5 14.5523 5.44772 15 6 15H9C9.26522 15 9.51957 14.8947 9.70711 14.7071L10.7071 13.7071L16.7071 7.70711L17.2071 7.20712C18.4261 5.98817 18.4261 4.01186 17.2071 2.79291ZM10 11.5858L14.5858 7.00001L13 5.41422L8.41422 10L10 11.5858ZM7 11.4142V13H8.58579L7 11.4142ZM14.4415 4.02733L15.9727 5.55847C16.2191 5.12994 16.1591 4.57337 15.7929 4.20712C15.4266 3.84087 14.8701 3.78094 14.4415 4.02733Z"
      fill="#737373"
    />
  </svg>
);

export default WriteUpdateIcon;
