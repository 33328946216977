/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';
import { USER_REFERRAL_TOKEN } from 'config/constants';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions, uiActions } from 'state/actions/';

// Components
import { Helmet } from 'react-helmet';
import GoogleSignIn from 'components/GoogleSignIn';
import { Link, useLocation } from 'react-router-dom';
import Wordmark from 'components/Wordmark';
import KoalaButton from 'koala/components/Button';
import PasswordValidation from 'components/PasswordValidation';

import TemplateAdContent from './TemplateAdContent';

import CustomerLogos from './cust_logos_tabby.png';
import MagicTabby from './magic_tabby.png';
import NavigatorTabby from './tabby_navigator.png';
import TrackTabby from './track_tabby.png';
import { Trans, useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';

// Components
const Wrapper = styled.div`
  min-height: 100%;
  background: #fff;

  h2 {
    margin-bottom: ${theme.spacing.x1};
  }

  h4 {
    margin-bottom: ${theme.spacing.x3};
  }

  @media (max-width: 1024px) {
    padding-top: 0;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: ${theme.spacing.x6};
  min-height: 100vh;

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 0;
  }

  @media (max-width: 800px) {
    padding: ${theme.spacing.x6} ${theme.spacing.x4} ${theme.spacing.x4} ${theme.spacing.x4};
  }
`;

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x4};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.x2};
`;

const OrSeparator = styled.div`
  margin: ${theme.spacing.x2} 0;
  border-bottom: 2px solid #d1d1d1;
  flex: 1;
  display: flex;
  justify-content: center;
  span {
    display: block;
    font-size: 1.4rem;
    line-height: 1.4rem;
    background-color: #fff;
    padding: 0 ${theme.spacing.x2};
    position: relative;
    top: 0.7rem;
  }
`;

const MetaContainer = styled.div`
  padding: ${theme.spacing.x8} ${theme.spacing.x8} 0 ${theme.spacing.x8};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgb(10, 80, 85);
  max-height: 100vh;
  overflow: hidden;
  flex: 4;
  padding-bottom: ${theme.spacing.x8};

  @media ${theme.devices.laptop} {
    display: none;
  }
`;

const MetaContent = styled.div`
  align-items: center;
  width: 100%;
  max-width: 90rem;

  h1 {
    font-size: 3.6rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: ${theme.spacing.x3};
    color: ${theme.colors.N0};
  }

  h3 {
    font-size: 2rem;
    line-height: 3.2rem;
    font-weight: 400;
    margin-bottom: ${theme.spacing.x3};
    color: ${theme.colors.N0};
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 400;
    max-width: 70rem;
    margin-bottom: ${theme.spacing.x3};
    color: ${theme.colors.N0};
    a {
      color: ${theme.colors.G20};
      margin-left: ${theme.spacing.x1};
    }
  }

  p {
    line-height: 1.4;
  }

  ul {
    margin: ${theme.spacing.x1} 0;
  }

  li {
    font-size: 1.8rem;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const SocialProof = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  padding: ${theme.spacing.x2} 0;
  img {
    width: 100%;
  }

  b {
    font-weight: 800;
    font-size: 1.1rem;
    text-transform: uppercase;
    color: ${theme.colors.G5};
  }

  @media ${theme.devices.desktop} {
    flex-direction: column;
  }
`;

const Content = styled.div`
  width: 100%;
  align-items: center;
  min-width: 35rem;
  max-width: 35rem;
  position: relative;
  p {
    font-size: 2rem;
    line-height: 1.4;
  }

  .tabby_peek {
    width: 10rem;
    position: absolute;
    top: -6.8rem;
    right: 4rem;
    z-index: 40;
  }

  @media (max-width: 800px) {
    padding: ${theme.spacing.x4};
  }
`;
const ContentContainer = styled.div`
  position: relative;
  padding: ${theme.spacing.x4};
  padding-bottom: ${theme.spacing.x8};
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;
  input {
    margin-bottom: ${theme.spacing.x2};

    @media (max-width: 800px) {
      &.small {
        width: 100%;
      }
    }
  }
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  list-style-type: disc;
  padding-left: ${theme.spacing.x3};
  color: #d0402e;

  li + li {
    margin-top: 0.8rem;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const CheckConfirm = styled.div`
  background: rgba(64, 140, 118, 0.2);
  border-radius: 8px;
  padding: ${theme.spacing.x2};
  margin-top: 3.2rem;
  p {
    line-height: 1.4;
    font-size: 1.6rem;
    margin-top: 0;
    padding: 0;
    margin-bottom: 1.6rem;
  }
`;

const FormHelp = styled.div`
  color: ${theme.colors.subtleText};
  a {
    color: ${theme.colors.subtleText};
  }
  margin-top: ${theme.spacing.x2};
`;

const FormWrapper = styled.div`
  margin-top: 10rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PasswordValidationContainer = styled.div`
  width: 100%;
  margin-bottom: ${theme.spacing.x2};
`;

const StepsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x4};
  margin: ${theme.spacing.x8} 0;
`;
const Step = styled.div`
  border: 3px solid #f7cd55;
  background: #236266;
  flex: 1;
  padding: ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  align-items: center;
  border-radius: 8px;
  position: relative;

  img {
    height: 12rem;
    @media ${theme.devices.desktop} {
      display: none;
    }

    &.navigator-tabby {
      padding: 1.5rem;
    }
  }

  strong {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: left;
    color: #fff;
  }

  span {
    font-size: 24px;
    background: #f7cd55;
    position: absolute;
    top: -24px;
    left: -24px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    font-weight: 800;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid #f7cd55;
    color: #151515;
  }
`;

// Quote style that looks fun
const Quote = styled.div`
  background: #236266;
  border-radius: 4px;
  padding: ${theme.spacing.x2};
  margin-top: ${theme.spacing.x4};
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  big {
    font-size: 2rem;
    color: #fff;
  }
  a {
    text-decoration: none;
    font-weight: 400;
    color: ${theme.colors.G20};
    display: flex;
    align-items: center;
    span {
      margin-left: ${theme.spacing.x1};
    }
    svg {
      path {
        fill: ${theme.colors.Y50};
      }
    }
    &:hover {
      text-decoration: none;
      filter: brightness(0.9);
    }
  }
`;

const TrialText = styled.small`
  font-size: 1.4rem;
  color: ${theme.colors.subtleText};
  margin-bottom: ${theme.spacing.x2};
  display: block;
  text-align: center;
`;

function Signup() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.session.signup.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
      'Search params': location.search,
    });
  }, [location]);

  // Reset Redux state for the UI
  useEffect(() => {
    dispatch(uiActions.resetPageState('Signup'));
  }, [dispatch]);

  const urlParams = new URLSearchParams(location.search);
  const tpl = urlParams.get('tpl');

  /**
  The code below looks for a query param ?ref=<user.referral_token>
  If it's detected it stores it in local storage so that we can re-use
  during workspace creation.
  */
  const ref = urlParams.get('ref'); // ref Id if it exists

  useEffect(() => {
    if (ref) {
      localStorage.setItem(USER_REFERRAL_TOKEN, JSON.stringify(ref));
    }
  }, [ref]);

  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [allowPasswordSubmit, setAllowPasswordSubmit] = useState(false);

  const uiState = useSelector((state: any) => state.pageSignup);

  const { hasErrors, isSignedUp, errors, isRequesting } = uiState;

  const handleSignup = (e: any) => {
    e.preventDefault();
    const credentials = {
      email,
      password_confirmation: passwordConfirmation,
      password,
    };
    dispatch(sessionActions.signup(credentials));
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'passwordConfirmation':
        setPasswordConfirmation(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
    }
  };

  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'email':
        return t('errors.email');
      case 'password':
        return t('errors.password');
      case 'password_confirmation':
        return t('errors.confirmPassword');
      default:
        return '';
    }
  };

  const emailClassname = errors['email'] ? 'error' : '';
  const passwordClassname = errors['password'] ? 'error' : '';
  const passwordConfirmationClassname = errors['password_confirmation'] ? 'error' : '';

  return (
    <Wrapper>
      <Helmet>
        <title>{t('sessionSignup.title')} | Tability</title>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=G-S2587LR71B`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-S2587LR71B');
          `}
        </script>
        <script async>
          {`
          _linkedin_partner_id = "2105057";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);
          `}
        </script>
      </Helmet>
      <Container>
        {tpl && <TemplateAdContent />}
        <MetaContainer>
          <MetaContent>
            <h1>The accountability system for OKRs and KPIs</h1>
            <h2>
              Built for high performing teams that care about results
              <a href="https://www.tability.io" target="_blank" rel="noreferrer">
                Learn more about Tability
              </a>
            </h2>
            <Quote>
              <big>“Can't imagine OKRs without Tability”</big>
              <a
                href="https://www.g2.com/products/tability/reviews/tability-review-9851374"
                rel="noreferrer"
                target="_blank"
              >
                <KoalaIcon iconName="star" />
                <KoalaIcon iconName="star" />
                <KoalaIcon iconName="star" />
                <KoalaIcon iconName="star" />
                <KoalaIcon iconName="star" />
                <span>G2 review</span>
              </a>
            </Quote>
            <StepsContainer>
              <Step>
                <span>1</span>
                <img src={MagicTabby} alt="Signup" aria-label="Signup" />
                <strong>Build the right OKRs and tactics assisted by AI</strong>
              </Step>
              <Step>
                <span>2</span>
                <img src={TrackTabby} alt="Signup" aria-label="Signup" />
                <strong>Get live updates on progress and actionable insights</strong>
              </Step>
              <Step>
                <span>3</span>
                <img src={NavigatorTabby} className="navigator-tabby" alt="Signup" aria-label="Signup" />
                <strong>Keep the right urgency and anticipate roadblocks early</strong>
              </Step>
            </StepsContainer>
            <SocialProof>
              <b>Trusted by modern teams</b>
              <div>
                <a href="https://www.tability.io/customers" target="_blank" rel="noreferrer">
                  <img src={CustomerLogos} alt="Tability customers" />
                </a>
              </div>
            </SocialProof>
          </MetaContent>
        </MetaContainer>
        <ContentContainer>
          <FormWrapper>
            <WordmarkWrapper>
              <Wordmark type="dark" />
            </WordmarkWrapper>
            <Content>
              <TrialText>14-day free trial, no credit card required</TrialText>
              <GoogleSignIn buttonLabel={t('sessionSignup.google')} />
              <OrSeparator>
                <span>{t('sessionSignup.or')}</span>
              </OrSeparator>
              {isSignedUp && (
                <CheckConfirm>
                  <p>
                    <b>{t('sessionSignup.oneMoreStep')}</b>
                  </p>
                  <p>
                    <Trans i18nKey="sessionSignup.sentConfirmation" values={{ email }} components={{ b: <b /> }} />
                  </p>
                  <KoalaButton isLink to="/login" appearance="subtle">
                    {t('sessionSignup.back')}
                  </KoalaButton>
                </CheckConfirm>
              )}
              {!isSignedUp && (
                <Form onSubmit={handleSignup}>
                  <input
                    type="email"
                    name="email"
                    autoComplete="off"
                    placeholder={t('sessionSignup.emailPlaceholder') ?? 'Work email'}
                    required={true}
                    onChange={handleChange}
                    className={`${emailClassname}`}
                  />
                  <input
                    type="password"
                    name="password"
                    autoComplete="off"
                    placeholder={t('sessionSignup.passwordPlaceholder') ?? 'Password'}
                    required={true}
                    onChange={handleChange}
                    className={`${passwordClassname}`}
                  />
                  <input
                    type="password"
                    name="passwordConfirmation"
                    autoComplete="off"
                    placeholder={t('sessionSignup.confirmPlaceholder') ?? 'Confirm your password'}
                    required={true}
                    onChange={handleChange}
                    className={`${passwordConfirmationClassname}`}
                  />
                  {password && (
                    <PasswordValidationContainer>
                      <PasswordValidation password={password} setAllowPasswordSubmit={setAllowPasswordSubmit} />
                    </PasswordValidationContainer>
                  )}
                  {hasErrors && (
                    <ErrorList>
                      {Object.keys(errors).map((errorKey: string) => {
                        // Get the first error for the key
                        // NOTE: there might be more! I'm just keeping the UI simpler right now.
                        const error = errors[errorKey][0];
                        return (
                          <li>
                            {errorKeyToString(errorKey)} {error.toLowerCase()}
                          </li>
                        );
                      })}
                    </ErrorList>
                  )}
                  <Actions>
                    <KoalaButton submit disabled={isRequesting || !allowPasswordSubmit} loading={isRequesting}>
                      {t('sessionSignup.getStarted')}
                    </KoalaButton>
                  </Actions>
                  <FormHelp>
                    <b>{t('sessionSignup.loginText')}</b>{' '}
                    <Link to="/login">
                      <b>{t('sessionSignup.loginButton')}</b>
                    </Link>
                  </FormHelp>
                  <FormHelp>
                    <Trans i18nKey="sessionSignup.sso" components={{ Link: <Link to="/sso" /> }} />
                  </FormHelp>
                </Form>
              )}
            </Content>
          </FormWrapper>
        </ContentContainer>
      </Container>
    </Wrapper>
  );
}

export default Signup;
