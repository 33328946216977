import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useParams, useLocation } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import queryString from 'query-string';
import queryKeys from 'config/queryKeys';
import parse from 'parse-link-header';
import * as amplitudeUtils from 'utils/amplitude';

// API
import * as remoteApi from 'api/remote';

// Components
import Loader from 'components/Loader';
import OutcomeBlockCompact from './OutcomeBlockCompact';
import KoalaButton from 'koala/components/Button';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
`;

function JiraOutcomes() {
  const location = useLocation();
  const parsedLocation = queryString.parse(location.search);
  const { issue_key, remote_instance_id } = parsedLocation;

  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [outcomes, setOutcomes]: any = useState([]);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const initiative_integration = `jira_cloud:${remote_instance_id}:${issue_key}`;
  const filter = {
    initiative_integration,
  };

  // Construct the query key using the plan Id
  const queryKey = [
    queryKeys.outcomes,
    'search',
    {
      workspaceSlug,
      filter,
    },
  ];

  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  // Get the plan details
  const { isLoading, isFetchingMore, fetchMore, canFetchMore }: any = useInfiniteQuery(
    queryKey,
    remoteApi.searchOutcomes,
    {
      onSuccess: (response: any) => {
        setOutcomes(response);
      },
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
    },
  );

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plan.track.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  if (isLoading || !issue_key) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const hasItems = outcomes[0].data.length > 0;

  return (
    <div>
      {!hasItems && (
        <div>
          There are no {translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase()} attached to this issue.
        </div>
      )}
      {outcomes.map((group: any, i: number) => {
        return (
          <Fragment key={i}>
            {group.data.map((outcome: any) => {
              return <OutcomeBlockCompact key={outcome.id} outcome={outcome} isSelected={false} />;
            })}
          </Fragment>
        );
      })}
      {canFetchMore && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMore()}
            loading={isFetchingMore}
            disabled={isFetchingMore}
            appearance="secondary"
          >
            Load more
          </KoalaButton>
        </LoadMore>
      )}
    </div>
  );
}

export default JiraOutcomes;
