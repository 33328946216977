import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { Link } from 'react-router-dom';
import KoalaTextBadge from 'koala/components/TextBadge';
import * as checkinUtils from 'utils/checkinUtils';
import { useTranslation } from 'react-i18next';
import KoalaColorBlock from 'koala/components/ColorBlock';

export const BlockGutter = styled.div`
  position: relative;
  grid-area: gutter;
  display: flex;

  align-items: center;
`;

export const BlockContent = styled.div`
  line-height: 2.4rem;
  font-weight: 400;
  grid-area: content;
  background: transparent;
  position: relative;

  display: flex;
  overflow: hidden;
  justify-content: space-between;
  p {
    margin-top: ${theme.spacing.x1};
  }

  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    &:hover {
      text-decoration: none;
    }
  }
`;

const BlockGrid = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'gutter content meta';
  align-items: center;
  column-gap: 0.8rem;
  margin: ${theme.spacing.half} 0 0 0;
  padding: 0.4rem ${theme.spacing.x1};
  border-radius: 0px;
  font-size: 1.2rem;
  position: relative;

  &:hover {
    background: ${theme.colors.N3};
  }
  &.selected,
  &:active,
  &:focus {
    background: ${theme.colors.B5};
  }

  &.no-hover-bg {
    &:hover {
      background: transparent;
    }
  }
`;

const BlockMeta = styled.div`
  display: flex;
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  isSelected: boolean;
  handleClick: (e: MouseEvent<HTMLDivElement>) => void;
}

function OutcomeBlock(props: Props) {
  const { outcome, isSelected, handleClick } = props;
  const { t } = useTranslation();

  const blockId = `outcome:${outcome.nano_slug}`;
  const hashPath = `#${blockId}:show`;

  const { current_checkin } = outcome;
  const className = isSelected ? 'selected' : '';

  const currentConfidence = current_checkin ? current_checkin.confidence : 'grey';

  const confidenceColor = checkinUtils.confidenceToColor(currentConfidence);
  const formattedScore = current_checkin ? checkinUtils.checkinScore(current_checkin, outcome, t) : '—';

  return (
    <BlockGrid onClick={handleClick} id={outcome.nano_slug} className={`${className} outcome-row`}>
      <BlockGutter>
        <KoalaColorBlock
          color={confidenceColor}
          size="small"
          isPending={outcome.is_pending_checkin}
          isCompleted={outcome.completed}
        />
      </BlockGutter>
      <BlockContent>
        <Link to={hashPath}>{outcome.title}</Link>
      </BlockContent>
      <BlockMeta>
        <KoalaTextBadge
          isLowercase={true}
          variant={checkinUtils.confidenceToVariant(currentConfidence)}
          edge="circle"
          size="small"
          style={{ border: 'none' }}
          maxWidth="8rem"
          dataFor={`progress-${outcome.nano_slug}`}
        >
          {formattedScore}
        </KoalaTextBadge>
      </BlockMeta>
    </BlockGrid>
  );
}

export default React.memo(OutcomeBlock);
