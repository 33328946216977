import * as TabilityTypes from 'types';
import theme from 'theme';
import IconList from 'koala/shared/images/icons/IconList';

interface ConfidenceData {
  x: number;
  y: number;
  color: string;
  legendTitle: string;
}

export const getConfidenceData = (membership: TabilityTypes.Membership) => {
  const data: ConfidenceData[] = [];
  let dataIndex = 1;

  if (
    !membership.green_outcomes_count &&
    !membership.yellow_outcomes_count &&
    !membership.red_outcomes_count &&
    !membership.grey_outcomes_count
  ) {
    data.push({
      x: dataIndex++,
      y: 1,
      color: theme.colors.yellow,
      legendTitle: 'no outcomes',
    });
    return data;
  }

  if (membership.green_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: membership.green_outcomes_count,
      color: theme.colors.green,
      legendTitle: 'on track',
    });
  }
  if (membership.yellow_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: membership.yellow_outcomes_count,
      color: theme.colors.yellow,
      legendTitle: 'at risk',
    });
  }
  if (membership.red_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: membership.red_outcomes_count,
      color: theme.colors.red,
      legendTitle: 'off track',
    });
  }
  if (membership.grey_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: membership.grey_outcomes_count,
      color: theme.colors.N30,
      legendTitle: 'pending',
    });
  }

  return data;
};

export const getOutcomeProgressData = (membership: TabilityTypes.Membership) => ({
  progressPercentage: membership.outcome_progress_prct * 100,
  colorType: 'B',
  centerLabel: `${Math.round(membership.outcome_progress_prct * 100)}%`,
  getCenterIcon: IconList['flag'],
});

export const getInitiativeProgressData = (membership: TabilityTypes.Membership) => ({
  progressPercentage: membership.initiative_progress_prct * 100,
  colorType: 'B',
  centerLabel: `${membership.closed_initiatives_count}/${membership.total_initiatives_count}`,
  getCenterIcon: IconList['task'],
});

export const isReadOnly = (membership: TabilityTypes.Membership) => {
  return membership.role === 'readonly';
};
