import yaml from 'js-yaml';
import { v4 as uuidv4 } from 'uuid';

// This function takes a YML file and return the data model ready to be used in the editor
function ymlToEditorData(template: any) {
  let parsedYML: any = null;
  try {
    parsedYML = yaml.load(template.yaml_content);
  } catch (e) {
    return null;
  }

  const plan = {
    id: template.id,
    title: template.title,
  };
  const objectives: any = {};
  const outcomes: any = {};
  const initiatives: any = {};
  const planItems: any = {
    [template.id]: [],
  };
  const objectiveItems: any = {};
  const outcomeItems: any = {};
  const blockIds: any = [];

  if (!parsedYML) {
    return {
      data: {
        plan,
        objectives,
        outcomes,
        initiatives,
        planItems,
        objectiveItems,
        outcomeItems,
      },
      blockIds,
      cursor: null,
    };
  }

  const parsedObjectives = parsedYML.objectives || [];

  // Iterate on the objectives
  parsedObjectives.forEach((objective: any, index: number) => {
    const objectiveId = uuidv4();
    const { title } = objective;
    objectives[objectiveId] = {
      id: objectiveId,
      parentId: template.id,
      title,
    };
    objectiveItems[objectiveId] = [];
    planItems[template.id].push(objectiveId);
    const blockId = `objectives:${objectiveId}`;
    blockIds.push(blockId);

    const parsedOutcomes = objective.outcomes || [];

    // Iterate on the objective outcomes
    parsedOutcomes.forEach((outcome: any, index: number) => {
      const outcomeId = uuidv4();
      const { title, from, to, score_format } = outcome;
      outcomes[outcomeId] = {
        id: outcomeId,
        parentId: objectiveId,
        title,
        from,
        to,
        score_format,
      };
      outcomeItems[outcomeId] = [];
      objectiveItems[objectiveId].push(outcomeId);
      const blockId = `outcomes:${outcomeId}`;
      blockIds.push(blockId);

      const parsedInitiatives = outcome.initiatives || [];

      // Iterate on the outcome initiatives
      parsedInitiatives.forEach((initiative: any, index: number) => {
        const initiativeId = uuidv4();
        const { title } = initiative;
        initiatives[initiativeId] = {
          id: initiativeId,
          parentId: outcomeId,
          title,
        };
        outcomeItems[outcomeId].push(initiativeId);
        const blockId = `initiatives:${initiativeId}`;
        blockIds.push(blockId);
      });
    });
  });

  const state = {
    data: {
      plan,
      objectives,
      outcomes,
      initiatives,
      planItems,
      objectiveItems,
      outcomeItems,
    },
    blockIds,
    cursor: null,
  };

  return state;
}

function editorDataToYML(editorData: any) {
  const { plan, objectives, outcomes, initiatives, planItems, objectiveItems, outcomeItems } = editorData;

  const ymlStructure: any = {
    objectives: [],
  };
  planItems[plan.id].forEach((objectiveId: string) => {
    const objective = objectives[objectiveId];
    objective.outcomes = [];

    objectiveItems[objective.id].forEach((outcomeId: string) => {
      const outcome = outcomes[outcomeId];
      outcome.initiatives = [];

      outcomeItems[outcome.id].forEach((initiativeId: string) => {
        const initiative = initiatives[initiativeId];

        outcome.initiatives.push(initiative);
      });

      objective.outcomes.push(outcome);
    });
    ymlStructure.objectives.push(objective);
  });

  const yml = yaml.dump(ymlStructure);
  return yml;
}

function getBlockIdsFromState(state: any) {
  const { plan, planItems, objectiveItems, outcomeItems } = state.data;

  const blockIds: any = [];
  planItems[plan.id].forEach((objectiveId: string) => {
    const blockId = `objectives:${objectiveId}`;
    blockIds.push(blockId);
    objectiveItems[objectiveId].forEach((outcomeId: string) => {
      const blockId = `outcomes:${outcomeId}`;
      blockIds.push(blockId);
      outcomeItems[outcomeId].forEach((initiativeId: string) => {
        const blockId = `initiatives:${initiativeId}`;
        blockIds.push(blockId);
      });
    });
  });

  return blockIds;
}

function addItem(state: any) {
  const { cursor } = state;
  const { plan } = state.data;

  // This hash will help us find the parent
  const parentTypesAssociation: any = {
    objectives: 'planItems',
    outcomes: 'objectiveItems',
    initiatives: 'outcomeItems',
  };

  const childrenTypesAssociation: any = {
    objectives: 'objectiveItems',
    outcomes: 'outcomeItems',
  };

  let parentItemsType = 'planItems';
  let parentItems = Array.from(state.data[parentItemsType][plan.id]);
  let childrenItemsType = 'objectiveItems';
  let newItemType = 'objectives';
  let parentId = plan.id;
  let insertIndex = parentItems.length;
  // Check if we're on a specific line
  if (cursor) {
    // First we get the current item
    const [itemType, itemId] = cursor.split(':');
    const selectedItem = state.data[itemType][itemId];

    // Now we can get the parent
    parentItemsType = parentTypesAssociation[itemType]; // get the correct parentItems
    childrenItemsType = childrenTypesAssociation[itemType]; // get the correct parentItems
    parentItems = Array.from(state.data[parentItemsType][selectedItem.parentId]);
    newItemType = itemType;
    parentId = selectedItem.parentId;
    insertIndex = parentItems.indexOf(selectedItem.id);
  }
  let newItem: any;
  switch (newItemType) {
    case 'objectives':
      newItem = {
        id: uuidv4(),
        parentId,
        title: '',
      };
      break;
    case 'outcomes':
      newItem = {
        id: uuidv4(),
        parentId,
        title: '',
        from: 0,
        to: 100,
        score_format: '_number_%',
      };
      break;
    case 'initiatives':
      newItem = {
        id: uuidv4(),
        parentId,
        title: '',
      };
      break;
  }

  parentItems.splice(insertIndex + 1, 0, newItem.id);

  // Insert the item
  const newState = {
    ...state,
    data: {
      ...state.data,
      [newItemType]: {
        ...state.data[newItemType],
        [newItem.id]: newItem,
      },
      [childrenItemsType]: {
        ...state.data[childrenItemsType],
        [newItem.id]: [],
      },
      [parentItemsType]: {
        ...state.data[parentItemsType],
        [parentId]: parentItems,
      },
    },
  };

  const blockIds = getBlockIdsFromState(newState);

  return {
    ...newState,
    blockIds,
    cursor: `${newItemType}:${newItem.id}`,
  };
}

// Add a child item type
function addChildItem(state: any) {
  const { cursor } = state;

  // Can only do that if we have
  if (!cursor) {
    return state;
  }

  // This hash will help us find the parent
  const parentTypesAssociation: any = {
    objectives: 'objectiveItems',
    outcomes: 'outcomeItems',
  };

  const childrenTypesAssociation: any = {
    objectives: 'outcomeItems',
    outcomes: 'initiativeItems',
  };

  const itemTypeAssociation: any = {
    objectives: 'outcomes',
    outcomes: 'initiatives',
  };

  // First we get the current item
  const [itemType, itemId]: any = cursor.split(':');

  // Initiatives don't have child objects, only siblings
  if (itemType === 'initiatives') {
    return state;
  }
  const selectedItem = state.data[itemType][itemId];

  // Now we set all the params
  const parentItemsType = parentTypesAssociation[itemType]; // set the parentItems to be the selected item type
  const childrenItemsType = childrenTypesAssociation[itemType]; // set the parentItems to be the new item type
  const parentItems = Array.from(state.data[parentItemsType][selectedItem.id]); // get the parent Items
  const newItemType = itemTypeAssociation[itemType]; // set the new Item Type to be the child class
  const parentId = selectedItem.id; // selected item is going to be the parent

  let newItem: any;
  switch (newItemType) {
    case 'objectives':
      newItem = {
        id: uuidv4(),
        parentId,
        title: '',
      };
      break;
    case 'outcomes':
      newItem = {
        id: uuidv4(),
        parentId,
        title: '',
        from: 0,
        to: 100,
        score_format: '_number_%',
      };
      break;
    case 'initiatives':
      newItem = {
        id: uuidv4(),
        parentId,
        title: '',
      };
      break;
  }

  parentItems.splice(0, 0, newItem.id);

  // Insert the item
  const newState = {
    ...state,
    data: {
      ...state.data,
      [newItemType]: {
        ...state.data[newItemType],
        [newItem.id]: newItem,
      },
      [childrenItemsType]: {
        ...state.data[childrenItemsType],
        [newItem.id]: [],
      },
      [parentItemsType]: {
        ...state.data[parentItemsType],
        [parentId]: parentItems,
      },
    },
  };

  const blockIds = getBlockIdsFromState(newState);

  return {
    ...newState,
    blockIds,
    cursor: `${newItemType}:${newItem.id}`,
  };
}

function removeItem(state: any, blockId: any) {
  const newState = {
    ...state,
  };
  if (!blockId) {
    return state;
  }

  // First we get the current item
  const [itemType, itemId]: any = blockId.split(':');

  // Then we remove it from the state
  // This hash will help us find the parent
  const parentTypesAssociation: any = {
    objectives: 'planItems',
    outcomes: 'objectiveItems',
    initiatives: 'outcomeItems',
  };

  const childrenTypesAssociation: any = {
    objectives: 'objectiveItems',
    outcomes: 'outcomeItems',
    initiatives: null,
  };

  const selectedItem: any = state.data[itemType][itemId];

  // Now we can get the parent
  const parentItemsType = parentTypesAssociation[itemType]; // get the correct parentItems
  const childrenItemsType = childrenTypesAssociation[itemType]; // get the correct items array
  const parentItems = Array.from(state.data[parentItemsType][selectedItem.parentId]);
  const parentIndex = parentItems.indexOf(itemId);

  // remove from state
  parentItems.splice(parentIndex, 1); // remove from parent
  if (childrenItemsType) {
    delete newState.data[childrenItemsType][itemId]; // Remove from the children Ids list
  }
  delete newState.data[itemType][itemId];

  newState.data[parentItemsType] = {
    ...newState.data[parentItemsType],
    [selectedItem.parentId]: parentItems,
  };

  const blockIds = getBlockIdsFromState(newState);

  // Fake state just to get the right cursor
  const cursorState = {
    ...state,
    cursor: blockId,
  };

  const cursor = getPreviousBlockId(cursorState);

  return {
    ...newState,
    blockIds,
    cursor,
  };
}

function reorderItems(state: any, result: any) {
  const { destination, source, draggableId, type } = result;

  // If we're not dropping on a droppable stop here.
  if (!destination) {
    return state;
  }

  // If we're dropping on the same spot, stop here.
  if (destination.droppableId === source.droppableId && destination.index === source.index) {
    return state;
  }

  const sourceItemIds = state.data[type][source.droppableId];
  const destinationItemIds = state.data[type][destination.droppableId];

  // Code to handle moving in the same column
  if (sourceItemIds === destinationItemIds) {
    // Start by duplicating the state.
    // We need to use Array from to create a copy instead of a reference
    const newItemIds = Array.from(sourceItemIds);

    // Move the items
    newItemIds.splice(source.index, 1);
    newItemIds.splice(destination.index, 0, draggableId);

    // Recreate the state state
    const newState = {
      ...state,
      data: {
        ...state.data,
        [type]: {
          ...state.data[type],
          [source.droppableId]: newItemIds,
        },
      },
    };

    const blockIds = getBlockIdsFromState(newState);

    return {
      ...newState,
      blockIds,
    };
  }

  // If we're here, it means that we're dropping the element in a different droppable (start != finish)

  // Duplicate the start items, and remove the dragged item from it.
  const newSourceItemIds = Array.from(sourceItemIds);
  newSourceItemIds.splice(source.index, 1);

  // Add the dragged object in the droppable target
  const newDestinationItemIds = Array.from(destinationItemIds);
  newDestinationItemIds.splice(destination.index, 0, draggableId);

  // Recreate the data state
  const newState = {
    ...state,
    data: {
      ...state.data,
      [type]: {
        ...state.data[type],
        [source.droppableId]: newSourceItemIds,
        [destination.droppableId]: newDestinationItemIds,
      },
    },
  };

  const blockIds = getBlockIdsFromState(newState);

  return {
    ...newState,
    blockIds,
  };
}

// Return the next block Id
function getNextBlockId(state: any) {
  const { blockIds, cursor } = state;

  // Don't move if there's no block selected as a cursor
  if (!cursor) {
    return null;
  }

  const index = blockIds.indexOf(cursor);
  return blockIds[index + 1] ? blockIds[index + 1] : cursor;
}

function getPreviousBlockId(state: any) {
  const { blockIds, cursor } = state;

  // Don't move if there's no block selected as a cursor
  if (!cursor) {
    return null;
  }

  const index = blockIds.indexOf(cursor);
  return blockIds[index - 1] ? blockIds[index - 1] : cursor;
}

export default {
  ymlToEditorData,
  editorDataToYML,
  addItem,
  addChildItem,
  removeItem,
  reorderItems,
  getPreviousBlockId,
  getNextBlockId,
};
