import React from 'react';

const LabelCheckActionIcon = () => (
  <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.62466 0.219167C8.05592 0.564174 8.12584 1.19346 7.78084 1.62472L3.78091 6.6247C3.58162 6.87381 3.27542 7.01289 2.9567 6.99906C2.63797 6.98523 2.34498 6.82015 2.16802 6.5547L0.168053 3.55472C-0.138294 3.09519 -0.0141224 2.47433 0.445398 2.16797C0.904919 1.86162 1.52578 1.9858 1.83212 2.44532L3.0735 4.30742L6.21913 0.37534C6.56413 -0.0559199 7.19341 -0.125841 7.62466 0.219167Z"
      fill="#737373"
    />
  </svg>
);

export default LabelCheckActionIcon;
