import React from 'react';

const PlusIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.5C10.5523 2.5 11 2.94772 11 3.5V16.5C11 17.0523 10.5523 17.5 10 17.5C9.44772 17.5 9 17.0523 9 16.5V3.5C9 2.94772 9.44772 2.5 10 2.5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10C2.5 9.44772 2.94772 9 3.5 9H16.5C17.0523 9 17.5 9.44772 17.5 10C17.5 10.5523 17.0523 11 16.5 11H3.5C2.94772 11 2.5 10.5523 2.5 10Z"
      fill="#737373"
    />
  </svg>
);

export default PlusIcon;
