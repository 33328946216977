import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const SVG = styled.svg`
  enable-background: new 0 0 50 50;
`;

interface Props {
  size?: string;
}

function Loader(props: Props) {
  const { size } = props;

  let loaderSize = theme.spacing.x2;
  if (size && size === 'medium') {
    loaderSize = theme.spacing.x4;
  }
  if (size && size === 'big') {
    loaderSize = theme.spacing.x6;
  }
  return (
    <SVG version="1.1" id="loader-1" x="0px" y="0px" width={loaderSize} height={loaderSize} viewBox="0 0 50 50">
      <path
        fill="#000"
        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </path>
    </SVG>
  );
}

export default React.memo(Loader);
