import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useInfiniteQuery, useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import parse from 'parse-link-header';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// API
import * as remoteApi from 'api/remote';

// Components
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaIcon from 'koala/components/Icons';
import { AxiosResponse } from 'axios';
import PendingCheckinsList from './PendingCheckinsList';
import ActiveOutcomesList from './ActiveOutcomesList';
import KoalaLoader from 'koala/components/Loader';
import ContributingOutcomesList from './ContributingOutcomesList';

export const CheckinsListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

const Banner = styled.div<{ color: string }>`
  padding: 1.2rem ${theme.spacing.x2};
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.x1};
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const StreakContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  .text-badge-label {
    gap: ${theme.spacing.half};
  }
`;

const EmptyState = styled.div`
  padding: ${theme.spacing.x2};
`;

interface Props {
  currentMembership: TabilityTypes.Membership;
  setCheckinsLoading: Function;
}

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

function CheckinsList(props: Props) {
  // Query that fetches all the outcomes with pending checkins
  const { currentMembership, setCheckinsLoading } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [streakData, setStreakData] = useState<TabilityTypes.StreakData>({
    streak_count: 0,
    streak_events: [],
  });
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  const pendingCheckinsQueryKey = [queryKeys.checkins, currentMembership.id, false, { limit: 5 }];
  const {
    isLoading: isLoadingPending,
    isFetchingMore: isFetchingMorePending,
    data: pendingResponse,
    fetchMore: fetchMorePending,
    canFetchMore: canFetchMorePending,
  } = useInfiniteQuery(pendingCheckinsQueryKey, remoteApi.fetchMembershipPendingCheckins, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  const activeOutcomeQueryKey = [queryKeys.outcomes, currentMembership.id, false, { limit: 5 }];
  const {
    isLoading: isLoadingActive,
    isFetchingMore: isFetchingMoreActive,
    data: activeResponse,
    fetchMore: fetchMoreActive,
    canFetchMore: canFetchMoreActive,
  } = useInfiniteQuery(activeOutcomeQueryKey, remoteApi.fetchMembershipActiveOutcomes, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  const queryKeyStreak = [queryKeys.streak, currentMembership.id];

  const { isLoading: isLoadingStreaks } = useQuery(queryKeyStreak, remoteApi.fetchMembershipCurrentStreak, {
    staleTime: 0,
    onSuccess: (response) => {
      const streakData = response.data;
      setStreakData(streakData);
    },
  });

  useEffect(() => {
    if (isLoadingActive || isLoadingPending || isLoadingStreaks) {
      setCheckinsLoading(true);
    } else {
      setCheckinsLoading(false);
    }
  }, [setCheckinsLoading, isLoadingActive, isLoadingPending, isLoadingStreaks]);

  if (isLoadingActive || isLoadingPending || isLoadingStreaks) {
    return (
      <EmptyState>
        <KoalaLoader />
      </EmptyState>
    );
  }

  const streakCount = streakData.streak_count;

  let pendingCheckins: AxiosResponse<TabilityTypes.Outcome[]>[] = pendingResponse ?? [];
  const noPendingCheckins = pendingCheckins.length === 0 || pendingCheckins[0].data.length === 0;

  let activeCheckins: AxiosResponse<TabilityTypes.Outcome[]>[] = activeResponse ?? [];
  const noActiveCheckins = activeCheckins.length === 0 || activeCheckins[0].data.length === 0;

  const handleShowStreakLadder = () => {
    dispatch(setGlobalModalContent(`workspace:${currentWorkspace.slug}:streak.ladder`));
  };

  if (noPendingCheckins && noActiveCheckins) {
    return (
      <>
        <Banner color={theme.colors.N5} className="item">
          <LeftContainer>
            <KoalaIcon iconName="flag" />
            <p>{t('workspaceInbox.noOutstandingCheckins')}</p>
          </LeftContainer>
          <StreakContainer>
            <p>{t('workspaceInbox.startStreak')}</p>
            <KoalaTextBadge variant="yellow-outlined" isLowercase onClick={handleShowStreakLadder}>
              <KoalaIcon iconName="fire" iconSize="small" iconAppearance={theme.colors.Y50} />
              {streakCount}x
            </KoalaTextBadge>
          </StreakContainer>
        </Banner>
        <ContributingOutcomesList currentMembership={currentMembership} />
      </>
    );
  }
  return (
    <>
      <PendingCheckinsList
        pendingCheckins={pendingCheckins}
        noPendingCheckins={noPendingCheckins}
        canFetchMore={!!canFetchMorePending}
        isFetchingMore={!!isFetchingMorePending}
        fetchMore={fetchMorePending}
        streakCount={streakCount}
      />
      {!noActiveCheckins && (
        <ActiveOutcomesList
          activeCheckins={activeCheckins}
          canFetchMore={!!canFetchMoreActive}
          isFetchingMore={!!isFetchingMoreActive}
          fetchMore={fetchMoreActive}
        />
      )}

      <ContributingOutcomesList currentMembership={currentMembership} />
    </>
  );
}

export default CheckinsList;
