import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import NotionIcon from '../_assets/IconNotion.png';

function NotionIntegration() {
  const { t } = useTranslation();

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={NotionIcon} alt="Notion" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.notionHeader')}</h3>
        <p>{t('workspaceSettingsIntegrations.notionInfo')}</p>
        <p>
          <a href="https://guides.tability.app/docs/integrations/notion" target="_blank" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        <ActionBlock>
          <div>{t('workspaceSettingsIntegrations.notionEnabled')}</div>
        </ActionBlock>
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default NotionIntegration;
