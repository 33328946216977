import React from 'react';

const CloseIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.98959 3.98955C4.38012 3.59902 5.01328 3.59902 5.40381 3.98955L10 8.58574L14.5962 3.98955C14.9867 3.59902 15.6199 3.59902 16.0104 3.98955C16.4009 4.38007 16.4009 5.01324 16.0104 5.40376L11.4142 9.99995L16.0104 14.5961C16.4009 14.9867 16.4009 15.6198 16.0104 16.0104C15.6199 16.4009 14.9867 16.4009 14.5962 16.0104L10 11.4142L5.4038 16.0104C5.01328 16.4009 4.38011 16.4009 3.98959 16.0104C3.59906 15.6198 3.59906 14.9867 3.98959 14.5961L8.58578 9.99995L3.98959 5.40376C3.59907 5.01324 3.59907 4.38007 3.98959 3.98955Z"
      fill="#737373"
    />
  </svg>
);
export default CloseIcon;
