import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';

import { IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Workspace } from 'types';
import ZapierIcon from '../_assets/IconZapier.png';
import * as workspaceUtils from 'utils/workspaceUtils';
import KoalaButton from 'koala/components/Button';
import UpgradeIcon from 'components/_assets/UpgradeIcon';

const UpgradeBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface Props {
  workspace: Workspace;
}

function ZapierIntegration(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(workspace) || workspace.pricing_version >= 2;
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={ZapierIcon} alt="Zapier" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.zapierHeader')}</h3>
        <p>{t('workspaceSettingsIntegrations.zapierInfo')}</p>
        <p>
          <a href="https://guides.tability.app/docs/integrations/zapier" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {hasEssentialsSubscription && (
          <KoalaButton
            href="https://zapier.com/apps/tability/integrations"
            target="_blank"
            rel="noopener noreferrer"
            appearance="secondary"
            size="small"
          >
            {t('workspaceSettingsIntegrations.visitZapier')}
          </KoalaButton>
        )}
        {!hasEssentialsSubscription && (
          <UpgradeBlock>
            <p>
              <UpgradeIcon /> {t('workspaceSettingsIntegrations.planRequired')}
            </p>
            {currentMembership && ['owner'].includes(currentMembership.role) && (
              <KoalaButton isLink to={`/${workspace.slug}/settings/billing`} appearance="secondary" size="small">
                {t('shared.upgrade.startTrial')}
              </KoalaButton>
            )}
          </UpgradeBlock>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default ZapierIntegration;
