import React, { Fragment, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import theme from 'theme';
import styled, { createGlobalStyle } from 'styled-components';
import * as TabilityTypes from 'types';
import { getScorecard } from 'state/actions/workspaceEditorActions';
import Reviewers from './Reviewers';
import ReactTooltip from 'react-tooltip';
import KoalaTextButton from 'koala/components/TextButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import PlanAiFeedback from './AiFeedback';

//const TABBY_GOOD_URL = 'https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png';
const TABBY_SURPRISED_URL =
  'https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_surprised_tranev.png';
const TABBY_SAD_URL = 'https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_sad_l6xbaw.png';

const TABBY_LOVE_URL =
  'https://res.cloudinary.com/tability/image/upload/v1613902140/static_assets/tabby_love_ix787e.png';
const TABBY_WRITE_URL =
  'https://res.cloudinary.com/tability/image/upload/v1613902140/static_assets/tabby_writing_vieeut.png';

const Container = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  overflow: auto;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const Emotion = styled.div`
  margin: 1.6rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .emotion-image {
    max-width: 8rem;
  }
  .help {
    margin-top: ${theme.spacing.x2};
  }

  &.no-tips {
    flex: 1;
    justify-content: center;
    p {
      margin-top: ${theme.spacing.x2};
    }
  }
  img {
    width: 100%;
  }
  p {
    width: 80%;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;

    &.publish-advice {
      font-size: 1.4rem;
    }
  }
`;

const SectionTitle = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.x1};
`;

const ScoreContainer = styled.div`
  background: #f3f3f3;
  border-radius: 4px;
  padding: ${theme.spacing.x2};
  margin: 0 ${theme.spacing.x2} ${theme.spacing.x2} 0;
`;
const InsightsContainer = styled.div`
  flex: 1;
  background: #f3f3f3;
  border-radius: 4px;
  padding: ${theme.spacing.x2};
  margin: 0 ${theme.spacing.x2} ${theme.spacing.x2} 0;
  display: flex;
  flex-direction: column;
`;

const ReviewersContainer = styled.div`
  background: #f3f3f3;
  border-radius: 4px;
  padding: ${theme.spacing.x2};
  padding-bottom: ${theme.spacing.x1};
  margin: 0 ${theme.spacing.x2} ${theme.spacing.x2} 0;
  display: flex;
  flex-direction: column;
`;

const InsightTip = styled.div`
  border-radius: 4px;
  width: 100%;
  border: 2px solid #fff;
  padding: ${theme.spacing.x1};
  margin-bottom: 10px;
  background: #fff;
  cursor: pointer;
  transition: all ease 0.2s;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);

  &:hover {
    border: 2px solid #bc96ed;
    background: #fff;
  }

  .insight-description {
    transition: all ease 0.2s;
    max-height: 0;
    overflow: hidden;
    padding-top: 0.8rem;
  }
`;

const TipLabel = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: #6a6a6a;
  margin-bottom: 0.8rem;
`;
const TipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  font-size: 1.3rem;
`;

const TotalScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  big {
    transition: all ease 0.2s;
    font-size: 3.5rem;
    font-weight: 700;
  }
  span {
    display: inline-block;
    margin-left: 0.4rem;
    font-size: 1.4rem;
    color: ${theme.colors.subtleText};
    font-weight: 600;
  }
`;

const ItemScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  label {
    font-weight: 800;
    color: ${theme.colors.subtleText};
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: block;
    margin-bottom: 0.8rem;
  }
`;

const ItemBar = styled.div`
  background-color: #d1d1d1;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1.6rem;
`;

const ItemBarFill = styled.div`
  transition: all ease 0.2s;
  background-color: #324dd3;
  height: 100%;
  border-radius: 4px;
`;

const HideHighlightsButton = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
  button {
    display: none;
    span {
      display: inline-block;
      margin-right: 0.4rem;
      font-size: 1.2rem;
      position: relative;
      bottom: 1px;
    }
  }
  &.show-button {
    button {
      display: block;
    }
  }
`;

const getColor = (score: number): string => {
  let color = '#F24833';

  if (score > 40) {
    color = '#F5C02B';
  }

  if (score > 75) {
    color = '#408C76';
  }

  return color;
};

const HighlightStyle = createGlobalStyle`
  .${(props: any) => props.highlightedInsightClass}.tip {
    border: 2px solid #BC96ED !important;

    .insight-description {
      max-height: 20rem;
    }
  }
`;

const HighlightRow = createGlobalStyle`
  .${(props: any) => props.highlightedInsightClass} {
    &.editor-block {
      border: 2px solid transparent;
      background: rgba(188, 150, 237, 0.2);
    }
    
  }
`;

const HighlightAvatars = createGlobalStyle`
  #plan-content .avatar--custom-size {
    border: 1px solid ${theme.colors.V50};
    box-shadow: ${theme.colors.V50} 0px 1px 5px;
  }
`;

const HighlightTargets = createGlobalStyle`
  .${(props: any) => props.highlightedInsightClass} {
    .target-button {
      border: 1px solid ${theme.colors.V50};
    box-shadow: ${theme.colors.V50} 0px 1px 5px;
    } 
  }
`;

const getInsightData = (insightKey: string, workspace: TabilityTypes.Workspace, t: TFunction): any => {
  const objectives = translate(workspace, CustomTermKey.OBJECTIVE, 2).toLowerCase();
  const outcomes = translate(workspace, CustomTermKey.OUTCOME, 2).toLowerCase();
  const initiatives = translate(workspace, CustomTermKey.INITIATIVE, 2).toLowerCase();
  const translationKey = 'workspacePlan.write.insight';
  switch (insightKey) {
    case 'focus.timeline.none':
      return {
        summary: t(`${translationKey}.timelineNone`),
        description: t(`${translationKey}.timelineNoneInfo`),
      };
    case 'focus.outcomes.none':
      return {
        summary: t(`${translationKey}.outcomesNone`, {
          outcomes: outcomes,
          objectives: objectives,
        }),
        description: '',
      };
    case 'focus.objectives.getting_many':
      return {
        summary: t(`${translationKey}.manyObjectives`, { objectives: objectives }),
        description: t(`${translationKey}.manyObjectivesInfo`, { objectives: objectives }),
      };
    case 'measurable.outcomes.not_enough':
      return {
        summary: t(`${translationKey}.moreOutcomes`, { objectives: objectives, outcomes: outcomes }),
        description: t(`${translationKey}.moreOutcomesInfo`, { objectives: objectives, outcomes: outcomes }),
      };
    case 'focus.outcomes.too_many':
      return {
        summary: t(`${translationKey}.manyOutcomes`, { objectives: objectives, outcomes: outcomes }),
        description: t(`${translationKey}.manyOutcomesInfo`, { objectives: objectives, outcomes: outcomes }),
      };
    case 'descriptive.title.too_short':
      return {
        summary: t(`${translationKey}.shortTitle`),
        description: t(`${translationKey}.shortTitleInfo`, { objectives: objectives, outcomes: outcomes }),
      };
    case 'descriptive.title.too_long':
      return {
        summary: t(`${translationKey}.longTitle`),
        description: t(`${translationKey}.longTitleInfo`),
      };
    case 'focus.objectives.too_many':
      return {
        summary: t(`${translationKey}.tooManyObjectives`, { objectives: objectives }),
        description: t(`${translationKey}.tooManyObjectivesInfo`, { objectives: objectives }),
      };
    case 'measurable.target.not_set':
      return {
        summary: t(`${translationKey}.setTargets`),
        description: t(`${translationKey}.setTargetsInfo`, { outcomes: outcomes }),
      };
    case 'measurable.target.default':
      return {
        summary: t(`${translationKey}.realTargets`),
        description: t(`${translationKey}.realTargetsInfo`),
      };
    case 'actionable.initiatives.none':
      return {
        summary: t(`${translationKey}.addInitiatives`, { initiatives: initiatives }),
        description: t(`${translationKey}.addInitiativesInfo`, { outcomes: outcomes, initiatives: initiatives }),
      };
    case 'actionable.initiatives.not_enough':
      return {
        summary: t(`${translationKey}.moreInitiatives`, { outcomes: outcomes, initiatives: initiatives }),
        description: t(`${translationKey}.moreInitiativesInfo`, { outcomes: outcomes, initiatives: initiatives }),
      };
    case 'ownership.user.none':
      return {
        summary: t(`${translationKey}.addOwners`, { outcomes: outcomes }),
        description: t(`${translationKey}.addOwnersInfo`, { outcomes: outcomes }),
      };
    case 'ownership.diverse.none':
      return {
        summary: t(`${translationKey}.oneOwner`, { outcomes: outcomes }),
        description: t(`${translationKey}.oneOwnerInfo`, { outcomes: outcomes }),
      };
    case 'ownership.diverse.some':
      return {
        summary: t(`${translationKey}.fewOwners`, { outcomes: outcomes }),
        description: t(`${translationKey}.fewOwnersInfo`, { outcomes: outcomes }),
      };
    default:
      return {
        summary: insightKey,
        description: insightKey,
      };
  }
};

const getTabbyEmotion = (score: number): any => {
  if (score >= 90) {
    return TABBY_LOVE_URL;
  }

  if (score >= 75) {
    return TABBY_LOVE_URL;
  }

  if (score >= 40) {
    return TABBY_SURPRISED_URL;
  }

  if (score >= 0) {
    return TABBY_SAD_URL;
  }

  return TABBY_SAD_URL;
};

const getTabbyEmotionText = (score: number, t: TFunction) => {
  if (score >= 80) {
    return t('workspacePlan.write.solidPlan');
  }

  return null;
};

interface Props {
  plan: TabilityTypes.Plan;
  isReadOnly?: boolean;
}

function Scorecard(props: Props) {
  const dispatch = useDispatch();
  const { plan, isReadOnly } = props;
  const { t } = useTranslation();
  const translationKey = 'workspacePlan.write';
  const [highlightedInsight, setHighlightedInsight] = useState('');
  const scorecard = useSelector((state: any) => state.editorUI.scorecard);
  const planLastChanged = useSelector((state: any) => state.editorUI.planLastChanged);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  useEffect(() => {
    dispatch(getScorecard());
  }, [dispatch, planLastChanged]);

  if (!scorecard) {
    return (
      <Container>
        <ColumnsWrapper>
          <ReviewersContainer>
            <SectionTitle>
              <span>{t(`${translationKey}.reviewers`)}</span>
            </SectionTitle>
            <Reviewers plan={plan} isReadOnly={isReadOnly} />
          </ReviewersContainer>
          <ScoreContainer>
            <TotalScore>
              <SectionTitle>{t(`${translationKey}.planScore`)}</SectionTitle>
              <div>
                <big>0</big>
                <span>/100</span>
              </div>
            </TotalScore>
            <ItemScore>
              <label>{t(`${translationKey}.focus`)}</label>
              <ItemBar />
            </ItemScore>
            <ItemScore>
              <label>{t(`${translationKey}.descriptive`)}</label>
              <ItemBar />
            </ItemScore>
            <ItemScore>
              <label>{t(`${translationKey}.measurable`)}</label>
              <ItemBar />
            </ItemScore>
            <ItemScore>
              <label>{t(`${translationKey}.ownership`)}</label>
              <ItemBar />
            </ItemScore>
            <ItemScore>
              <label>{t(`${translationKey}.actionable`)}</label>
              <ItemBar />
            </ItemScore>
          </ScoreContainer>
          <InsightsContainer>
            <SectionTitle>
              <span>{t(`${translationKey}.tips`)}</span>
              <HideHighlightsButton />
            </SectionTitle>
            <Emotion className="no-tips">
              <div className="emotion-image">
                <img src={TABBY_WRITE_URL} alt="Tabby emotion" />
              </div>
              <p>{t(`${translationKey}.tipsInfo`)}</p>
            </Emotion>
          </InsightsContainer>
        </ColumnsWrapper>
      </Container>
    );
  }

  const { focusScore, descriptiveScore, measurableScore, ownershipScore, actionableScore, totalScore, insights } =
    scorecard;

  const focusInsights = insights.focus;
  const descriptiveInsights = insights.descriptive;
  const measurableInsights = insights.measurable;
  const ownershipInsights = insights.ownership;
  const actionableInsights = insights.actionable;

  const insightsCount =
    focusInsights.length + descriptiveInsights.length + measurableInsights.length + ownershipInsights.length;
  const highlightedInsightClass = highlightedInsight ? highlightedInsight.replace(/\./g, '-') : '';
  const tipsClass = insightsCount === 0 ? 'no-tips' : '';

  const renderInsights = (insightHeader: string, insightsArray: any[], firstClassname = '') => {
    if (!insightsArray || insightsArray.length === 0) {
      return null;
    }

    return (
      <Fragment>
        {insightsArray.map((key: string, index: number) => {
          const insightData = getInsightData(key, currentWorkspace, t);

          const className = key.replace(/\./g, '-');

          const keyToHighlight = className === highlightedInsightClass ? '' : key;

          return (
            <InsightTip
              key={index}
              onClick={() => setHighlightedInsight(keyToHighlight)}
              className={`${className} tip`}
            >
              <TipLabel>{insightHeader}</TipLabel>
              <TipContent>
                {insightData.summary}
                <div className="insight-description">{insightData.description}</div>
              </TipContent>
            </InsightTip>
          );
        })}
      </Fragment>
    );
  };

  const hideHighlightClass = highlightedInsightClass ? 'show-button' : '';

  const emotion = getTabbyEmotion(totalScore);
  const emotionText = getTabbyEmotionText(totalScore, t);

  const highlightRow =
    !highlightedInsight.includes('ownership.diverse') && !highlightedInsight.includes('measurable.target');
  return (
    <Container>
      <HighlightStyle highlightedInsightClass={highlightedInsightClass} />
      {highlightRow && <HighlightRow highlightedInsightClass={highlightedInsightClass} />}
      {highlightedInsight.includes('ownership.diverse') && <HighlightAvatars />}
      {highlightedInsight.includes('measurable.target') && (
        <HighlightTargets highlightedInsightClass={highlightedInsightClass} />
      )}
      <ColumnsWrapper>
        <ReviewersContainer>
          <SectionTitle>
            <span>{t(`${translationKey}.reviewers`)}</span>
          </SectionTitle>
          <Reviewers plan={plan} isReadOnly={isReadOnly} />
        </ReviewersContainer>

        <ScoreContainer>
          <TotalScore>
            <SectionTitle>{t(`${translationKey}.planScore`)}</SectionTitle>
            <div>
              <big>{totalScore}</big>
              <span>/100</span>
            </div>
          </TotalScore>
          <ItemScore data-tip data-for="scorecard-focus">
            <label>{t(`${translationKey}.focus`)}</label>
            <ItemBar>
              <ItemBarFill
                style={{
                  width: `${focusScore}%`,
                  backgroundColor: getColor(focusScore),
                }}
              />
            </ItemBar>
          </ItemScore>
          <ItemScore data-tip data-for="scorecard-descriptive">
            <label>{t(`${translationKey}.descriptive`)}</label>
            <ItemBar>
              <ItemBarFill
                style={{
                  width: `${descriptiveScore}%`,
                  backgroundColor: getColor(descriptiveScore),
                }}
              />
            </ItemBar>
          </ItemScore>
          <ItemScore data-tip data-for="scorecard-measurable">
            <label>{t(`${translationKey}.measurable`)}</label>
            <ItemBar>
              <ItemBarFill
                style={{
                  width: `${measurableScore}%`,
                  backgroundColor: getColor(measurableScore),
                }}
              />
            </ItemBar>
          </ItemScore>
          <ItemScore data-tip data-for="scorecard-ownership">
            <label>{t(`${translationKey}.ownership`)}</label>
            <ItemBar>
              <ItemBarFill
                style={{
                  width: `${ownershipScore}%`,
                  backgroundColor: getColor(ownershipScore),
                }}
              />
            </ItemBar>
          </ItemScore>
          <ItemScore data-tip data-for="scorecard-actionable">
            <label>{t(`${translationKey}.actionable`)}</label>
            <ItemBar>
              <ItemBarFill
                style={{
                  width: `${actionableScore}%`,
                  backgroundColor: getColor(actionableScore),
                }}
              />
            </ItemBar>
          </ItemScore>
          <ReactTooltip type="dark" id="scorecard-focus" className="tooltip" effect="solid" delayShow={200}>
            {t(`${translationKey}.focusTooltip`)}
          </ReactTooltip>
          <ReactTooltip type="dark" id="scorecard-descriptive" className="tooltip" effect="solid" delayShow={200}>
            {t(`${translationKey}.descriptiveTooltip`)}
          </ReactTooltip>
          <ReactTooltip type="dark" id="scorecard-measurable" className="tooltip" effect="solid" delayShow={200}>
            {t(`${translationKey}.measurableTooltip`, {
              label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
            })}
          </ReactTooltip>
          <ReactTooltip type="dark" id="scorecard-ownership" className="tooltip" effect="solid" delayShow={200}>
            {t(`${translationKey}.ownershipTooltip`, {
              label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
            })}
          </ReactTooltip>
        </ScoreContainer>

        {currentWorkspace.open_ai_enabled && <PlanAiFeedback plan={plan} />}
        <InsightsContainer>
          <SectionTitle>
            <span>{t(`${translationKey}.tips`)}</span>
            <HideHighlightsButton className={hideHighlightClass}>
              <KoalaTextButton onClick={() => setHighlightedInsight('')} appearance="secondary">
                <span>&#10005;</span> {t(`${translationKey}.hideHighlights`)}
              </KoalaTextButton>
            </HideHighlightsButton>
          </SectionTitle>
          {totalScore > 95 && (
            <Emotion className={tipsClass}>
              <div className="emotion-image">
                <img src={emotion} alt="Tabby emotion" />
              </div>
              {emotionText && <p>{emotionText}</p>}
              {plan.state === 'draft' && <p className="publish-advice">{t(`${translationKey}.publishPlan`)}</p>}
            </Emotion>
          )}
          {renderInsights(t(`${translationKey}.focus`) ?? 'Focus', focusInsights, 'first')}
          {renderInsights(t(`${translationKey}.descriptive`) ?? 'Descriptive', descriptiveInsights)}
          {renderInsights(t(`${translationKey}.measurable`) ?? 'Measurable', measurableInsights)}
          {renderInsights(t(`${translationKey}.ownership`) ?? 'Ownership', ownershipInsights)}
          {renderInsights(t(`${translationKey}.actionable`) ?? 'Actionable', actionableInsights)}
        </InsightsContainer>
      </ColumnsWrapper>
    </Container>
  );
}

export default React.memo(Scorecard);
