import * as TabilityTypes from '../types';
/* Outcome assistan

When a plan is submitted we need to send back a scorecard showing the score of the plan

The scorecard is done using 5 criteria:
- Focus
- Descriptive
- Measurable
- Ownership
- Peer feedback

You can get a total of 500 points, each category giving you between 0 and 100 max.
*/

interface Insight {
  insightKey: string;
  score: number;
}

const getPlanFocusInsight = (plan: TabilityTypes.Plan): Insight => {
  let insight: Insight = {
    score: 100,
    insightKey: '',
  };
  const hasTimeline = plan.start_at && plan.finish_at;
  if (!hasTimeline) {
    insight = {
      score: 10,
      insightKey: 'focus.timeline.none',
    };
  }

  return insight;
};

// Gives a focus score for the number of objectives
const getObjectivesFocusInsight = (objectivesCount: number): Insight => {
  let insight: Insight = {
    score: 100,
    insightKey: '',
  };

  // Gives half the score if we're starting to see too many objectives.
  if (objectivesCount > 3 && objectivesCount <= 5) {
    insight = {
      score: 50,
      insightKey: 'focus.objectives.getting_many',
    };
  }

  // Be harsh and prevent people from having too few objectives
  if (objectivesCount > 5) {
    insight = {
      score: 10,
      insightKey: 'focus.objectives.too_many',
    };
  }
  return insight;
};

// Gives a focus score for the number of outcomes for an objective
const getOutcomesFocusInsight = (outcomesCount: number): Insight => {
  let insight: Insight = {
    score: 100,
    insightKey: '',
  };

  // Half the score if we're starting to see too many KRs
  if (outcomesCount > 4 && outcomesCount <= 5) {
    insight = {
      score: 50,
      insightKey: 'focus.outcomes.too_many',
    };
  }
  // return 0 if we have too many outcomes
  if (outcomesCount > 6) {
    insight = {
      score: 10,
      insightKey: 'focus.outcomes.too_many',
    };
  }

  return insight;
};

// Gives a focus score for the number of initiatives for an objective
const getInitiativesActionableInsight = (initiativesCount: number): Insight => {
  let insight: Insight = {
    score: 100,
    insightKey: '',
  };

  // Half the score if we're starting to see too many KRs
  if (initiativesCount < 3 && initiativesCount > 0) {
    insight = {
      score: 50,
      insightKey: `actionable.initiatives.not_enough`,
    };
  }
  // return 0 if we have too many outcomes
  if (initiativesCount === 0) {
    insight = {
      score: 10,
      insightKey: 'actionable.initiatives.none',
    };
  }

  return insight;
};

// Gives a score 0 => 100 based on the descriptive of the title.
const getTitleDescriptiveInsight = (title: string): Insight => {
  // Count the number of words in the title
  const wordCount = title ? title.split(' ').length : 0;
  let insight: Insight = {
    score: 100,
    insightKey: '',
  };

  // Set score to 0 if title is too short or too long
  if (wordCount < 3) {
    insight = {
      score: 25,
      insightKey: 'descriptive.title.too_short',
    };
  }

  // Set score to 0 if title is  too long
  if (wordCount > 30) {
    insight = {
      score: 25,
      insightKey: 'descriptive.title.too_long',
    };
  }

  // Set score to 50 is title is starting to get long
  if (wordCount > 20) {
    insight = {
      score: 50,
      insightKey: 'descriptive.title.too_long',
    };
  }

  return insight;
};

// Gives a focus score for the number of outcomes for an objective
const getOutcomesCountMeasurableInsight = (outcomesCount: number): Insight => {
  let insight: Insight = {
    score: 100,
    insightKey: '',
  };

  // return 0 if we don't have any outcomes
  if (outcomesCount === 0) {
    insight = {
      score: 10,
      insightKey: 'measurable.outcomes.not_enough',
    };
  }

  // return 25 if we have just one outcome
  if (outcomesCount === 1) {
    insight = {
      score: 25,
      insightKey: 'measurable.outcomes.not_enough',
    };
  }
  return insight;
};

// Gives a score 0 => 100 based on the target of the outcome.
const getOutcomeMeasurableInsight = (outcome: TabilityTypes.Outcome): Insight => {
  let insight: Insight = {
    score: 100,
    insightKey: '',
  };

  // Return 0 if we do not have a target
  if (outcome.to === null || outcome.from === null) {
    insight = {
      score: 0,
      insightKey: 'measurable.target.not_set',
    };

    return insight;
  }

  // Return 50 points if using the default format
  if (outcome.to === 100 && outcome.from === 0 && outcome.score_format === '_number_%') {
    insight = {
      score: 50,
      insightKey: 'measurable.target.default',
    };

    return insight;
  }

  return insight;
};

// Gives an ownership score based on who the owner is
// - Should penalize if one user has too many outcomes attahed to them
// - Should penalize if no users have any outcomes
const getOwnershipInsight = (outcome: TabilityTypes.Outcome): Insight => {
  let insight: Insight = {
    score: 100,
    insightKey: '',
  };

  // Return 0 if we do not have a target
  if (!outcome.membership) {
    insight = {
      score: 0,
      insightKey: 'ownership.user.none',
    };

    return insight;
  }

  return insight;
};

// Gives an ownership score based on who the owner is
// - Should penalize if one user has too many outcomes attahed to them
// - Should penalize if no users have any outcomes
const getUniqueOwnershipInsight = (uniqueOwnersLength: number): Insight => {
  let insight: Insight = {
    score: 100,
    insightKey: '',
  };

  if (uniqueOwnersLength <= 1) {
    insight = {
      score: 25,
      insightKey: 'ownership.diverse.none',
    };
    return insight;
  }

  if (uniqueOwnersLength === 2) {
    insight = {
      score: 50,
      insightKey: 'ownership.diverse.some',
    };
    return insight;
  }

  return insight;
};

//######################
//# SCORECARD FUNCTION #
//######################

export const getScorecard = (planId: string, editorEntities: any) => {
  if (!planId || !editorEntities || editorEntities === undefined) {
    return null;
  }
  let focusScore: number,
    descriptiveScore: number,
    measurableScore: number,
    ownershipScore: number,
    actionableScore: number,
    //peerFeedbackScore,
    totalScore;

  const insights: any = {
    focus: [],
    descriptive: [],
    measurable: [],
    ownership: [],
    feedback: [],
    actionable: [],
  };
  const blockInsights: { [key: string]: string } = {};
  const { plans, objectives, outcomes, initiativeIds } = editorEntities;
  const { plansToObjectivesMapping, objectivesToOutcomesMapping } = editorEntities;
  const currentPlan = plans[planId];
  const objectiveIds = plansToObjectivesMapping[planId] || [];

  // Return null if no current plan
  if (!currentPlan || objectiveIds.length === 0) {
    return null;
  }

  const storeInsight = (insight: Insight, blockId: string) => {
    // Only store insights if we have one
    if (insight.insightKey) {
      const insightType = insight.insightKey.split('.')[0];

      // Stores the key in the descriptive insights
      if (insights[insightType].indexOf(insight.insightKey) < 0) {
        insights[insightType].push(insight.insightKey);
      }

      // Stores the insight class if it doesn't exist yet.
      if (!blockInsights[blockId]) {
        blockInsights[blockId] = '';
      }

      const blockClass = insight.insightKey.replace(/\./g, '-');
      if (!blockInsights[blockId].includes(blockClass)) {
        blockInsights[blockId] += ` ${blockClass}`;
      }
    }
  };

  const focusPlanInsight = getPlanFocusInsight(currentPlan);
  const focusPlanScore = focusPlanInsight.score;
  storeInsight(focusPlanInsight, 'plan');

  /*
  Focus rules: start with 100 points and lose points if you...
  - ...have too many objectives
  - ...have too many KRs per objectives

  Objectives
  - 1-3 objectives = -0 points
  - 4-5 = -10 points x objectives above 3
  - 5+ objectives: -15 points x objectives above 3 (5 = -45points)

  KRs:
  - All objectives have 1-3 KRs = -0 points
  - Objectives have 4-5 KRs = -10 points / objective
  - Some objectives have 5+ KRs = -15 points / objective
  */
  const focusObjectivesInsight = getObjectivesFocusInsight(objectiveIds.length);
  const focusObjectivesScore = focusObjectivesInsight.score;

  storeInsight(focusObjectivesInsight, 'plan');

  // Calculate focus score for the outcomes listed in the objectives
  let focusOutcomesScore = 0;
  let focusOutcomesItems = 0;

  objectiveIds.forEach((objectiveId: string) => {
    const objective = objectives[objectiveId];
    if (!objective) {
      return;
    }

    const outcomeIds = objectivesToOutcomesMapping[objective.id] || [];
    const outcomesCount = outcomeIds.length;
    const focusOutcomeInsight = getOutcomesFocusInsight(outcomesCount);
    focusOutcomesScore += focusOutcomeInsight.score;

    storeInsight(focusOutcomeInsight, `objective:${objectiveId}`);

    focusOutcomesItems++;
  });
  focusOutcomesScore = focusOutcomesItems === 0 ? 0 : focusOutcomesScore / focusOutcomesItems;
  focusScore = Math.round((focusPlanScore + focusObjectivesScore + focusOutcomesScore) / 3);

  /*
  Descriptive rule: start with 100 points and lose points if you...
  - ...write objectives and KRs that are too short

  scorePerItem = items / 100 * itemDescriptiveRatio
  */

  // Store the number of items to average things at the end
  let descriptiveItemsCount = 0;
  descriptiveScore = 0;

  // Iterate on each objective to analyze their title
  objectiveIds.forEach((objectiveId: string) => {
    const objective = objectives[objectiveId];

    if (!objective) {
      return;
    }

    const outcomeIds = objectivesToOutcomesMapping[objective.id] || [];

    const objectiveInsight: Insight = getTitleDescriptiveInsight(objective.title);

    descriptiveItemsCount++;
    descriptiveScore += objectiveInsight.score;
    const objectiveBlockId = `objective:${objectiveId}`;

    storeInsight(objectiveInsight, objectiveBlockId);

    // Iterate on the outcomes to analyze their title in the same way
    outcomeIds.forEach((outcomeId: string) => {
      const outcome = outcomes[outcomeId];

      if (!outcome) {
        return;
      }

      const outcomeInsight = getTitleDescriptiveInsight(outcome.title);
      descriptiveItemsCount++;
      descriptiveScore += outcomeInsight.score;
      const outcomeBlockId = `outcome:${outcomeId}`;

      storeInsight(outcomeInsight, outcomeBlockId);
    });
  });

  descriptiveScore = descriptiveItemsCount === 0 ? 0 : Math.round(descriptiveScore / descriptiveItemsCount);

  /*
  Measurable rule: start with 100 and lose points if...
  - ...your outcomes don't have clear targets

  scorePerItem = items / 100 * itemMeasurable
  */

  // Store the number of items to average things at the end
  let measurableItemsCount = 0;
  measurableScore = 0;

  // Iterate on each objective to analyze their outcomes
  objectiveIds.forEach((objectiveId: string) => {
    const objective = objectives[objectiveId];

    if (!objective) {
      return;
    }

    const outcomeIds = objectivesToOutcomesMapping[objective.id] || [];

    // Get the focus tips based on number of outcomes for the objective
    const outcomesCount = outcomeIds.length;
    const measurableOutcomesCountInsight = getOutcomesCountMeasurableInsight(outcomesCount);
    measurableScore += measurableOutcomesCountInsight.score;
    storeInsight(measurableOutcomesCountInsight, `objective:${objectiveId}`);

    measurableItemsCount++;

    // Iterate on the outcomes to analyze their targets
    outcomeIds.forEach((outcomeId: string) => {
      measurableItemsCount++;
      const outcome = outcomes[outcomeId];

      if (!outcome) {
        return;
      }
      const outcomeInsight = getOutcomeMeasurableInsight(outcome);
      measurableScore += outcomeInsight.score;
      const outcomeBlockId = `outcome:${outcomeId}`;

      storeInsight(outcomeInsight, outcomeBlockId);
    });
  });

  measurableScore = measurableItemsCount === 0 ? 0 : Math.round(measurableScore / (measurableItemsCount || 1));

  /*
    Ownership rule: start with 100 points and lose points if
    - owners have too many things attached to them

    scorePerItem = items / 100 * itemOwnership
  */

  // Store the number of items to average things at the end
  let ownershipItemsCount = 0;
  ownershipScore = 0;
  let uniqueOwners: string[] = [];

  // Iterate on each objective to analyze their outcomes
  objectiveIds.forEach((objectiveId: string) => {
    const objective = objectives[objectiveId];

    if (!objective) {
      return;
    }

    const outcomeIds = objectivesToOutcomesMapping[objective.id] || [];

    // Iterate on the outcomes to analyze their targets
    outcomeIds.forEach((outcomeId: string) => {
      ownershipItemsCount++;
      const outcome = outcomes[outcomeId];

      if (!outcome) {
        return;
      }
      if (outcome.membership && !uniqueOwners.includes(outcome.membership.id)) {
        uniqueOwners.push(outcome.membership.id);
      }
      const outcomeInsight = getOwnershipInsight(outcome);
      ownershipScore += outcomeInsight.score;
      const outcomeBlockId = `outcome:${outcomeId}`;

      storeInsight(outcomeInsight, outcomeBlockId);
    });
  });

  const uniqueOwnersInsight = getUniqueOwnershipInsight(uniqueOwners.length);
  storeInsight(uniqueOwnersInsight, 'plan');

  ownershipScore = ownershipItemsCount === 0 ? 0 : Math.round(ownershipScore / (ownershipItemsCount || 1));
  ownershipScore = (ownershipScore + uniqueOwnersInsight.score * 3) / 4;

  const actionableInitiativesInsight = getInitiativesActionableInsight(initiativeIds.length);
  storeInsight(actionableInitiativesInsight, 'plan');
  actionableScore = actionableInitiativesInsight.score;

  /*
    Peer feedback: start with 100 points and lose points if
    - you haven't shared it with anyone
    - you're the only one that looked at this page

    No one but you looked at it: 0
    There's another contributor: 50 points
    Someone commented on it: 75 points
    You enabled automatic peer feedback: 100 points
  */

  // Avoid having 0 as a score so people don't feel too bad

  totalScore = Math.round((focusScore + descriptiveScore + measurableScore + ownershipScore + actionableScore) / 5);
  const scorecard = {
    focusScore,
    descriptiveScore,
    measurableScore,
    ownershipScore,
    actionableScore,
    totalScore,
    insights: {
      descriptive: insights['descriptive'],
      focus: insights['focus'],
      measurable: insights['measurable'],
      ownership: insights['ownership'],
      actionable: insights['actionable'],
      blockInsights: blockInsights,
    },
  };

  return scorecard;
};
