import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQuery, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Utils
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';

// API
import { fetchWorkspaceAdminDetails } from 'api/remote';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  .session_duration {
    width: 10rem;
    margin-right: ${theme.spacing.x1};
  }
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

const staleTime = 0;

function WorkspaceSettingsDetails(props: Props) {
  const { workspace } = props;
  const queryKey = [queryKeys.currentWorkspace, workspace.slug, 'admin'];
  const { t } = useTranslation();
  const [restricted_domains, setRestrictedDomains] = useState('');
  const [restricted_ips, setRestrictedIps] = useState('');
  const [confirm, setConfirm] = useState('');
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { isFetching: isInitialFetch }: any = useQuery(queryKey, fetchWorkspaceAdminDetails, {
    staleTime,
    onSuccess: (response) => {
      const workspace = response.data;
      setRestrictedDomains(workspace.restricted_domains.toString());
      setRestrictedIps(workspace.restricted_ips.toString());
    },
  });

  // Workspace mutations
  const [updateWorkspaceMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.updateWorkspace, {
    onMutate: () => {
      setIsFormSaved(false);
    },
    onSuccess: () => {
      setIsFormSaved(true);
      setIsFormDirty(false);
    },
  });

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;
    setIsFormDirty(true);
    setIsFormSaved(false);

    switch (name) {
      case 'restricted_domains':
        setRestrictedDomains(value);
        break;
      case 'restricted_ips':
        setRestrictedIps(value);
        break;
      case 'confirm':
        setConfirm(value);
        break;
    }
  };

  const confirmText = t('workspaceSettingsWhitelisting.confirmText') ?? 'CONFIRM';
  const isDisabled = confirm !== confirmText;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isDisabled) {
      return false;
    }
    const new_restricted_domains = restricted_domains ? restricted_domains.replace(' ', '').split(',') : [];
    const new_restricted_ips = restricted_ips ? restricted_ips.replace(' ', '').split(',') : [];
    const params = {
      restricted_domains: new_restricted_domains,
      restricted_ips: new_restricted_ips,
    };
    updateWorkspaceMutation({
      workspaceId: workspace.id,
      workspace: params,
    });
  };

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'restricted_domains':
        return t('errors.restrictedDomains');
      case 'restricted_ips':
        return t('errors.restrictedIPs');
      default:
        return '';
    }
  };

  // Error classnames used to highlight input fields that have errors
  const restrictedDomainsClassname = errors['restricted_domains'] ? 'error' : '';
  const restrictedIpsClassname = errors['restricted_ips'] ? 'error' : '';

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsWhitelisting.title')} | Tability
        </title>
      </Helmet>
      <Prompt
        message={t('shared.confirmLeave') ?? 'Your changes are not saved. Are you sure you want to leave?'}
        when={isFormDirty}
      />
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>
              {t('workspaceSettingsWhitelisting.title')}&nbsp;
              {isInitialFetch && <Loader size="small" />}
            </h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            {workspace.pricing_version === 3 && (
              <>
                <FormField>
                  <label>{t('workspaceSettingsWhitelisting.email')}</label>
                  <small className="light">{t('workspaceSettingsWhitelisting.emailInfo')}</small>
                  <input
                    type="text"
                    name="restricted_domains"
                    className={`${restrictedDomainsClassname} small`}
                    autoComplete="off"
                    placeholder={t('workspaceSettingsWhitelisting.emailPlaceholder') ?? 'Ex: acme.com'}
                    value={restricted_domains}
                    required={false}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <label>{t('workspaceSettingsWhitelisting.ip')}</label>
                  <small className="light">{t('workspaceSettingsWhitelisting.ipInfo')}</small>
                  <input
                    type="text"
                    name="restricted_ips"
                    className={`${restrictedIpsClassname} small`}
                    autoComplete="off"
                    placeholder={
                      t('workspaceSettingsWhitelisting.ipPlaceholder') ??
                      'Ex: 104.192.143.1, 2401:1d80:1010::150, 104.192.143.0/28'
                    }
                    value={restricted_ips}
                    required={false}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <label>{t('workspaceSettingsWhitelisting.confirm')}</label>
                  <small className="light">{t('workspaceSettingsWhitelisting.confirmInfo')}</small>
                  <input
                    type="text"
                    className="tiny"
                    name="confirm"
                    autoComplete="off"
                    placeholder={t('workspaceSettingsWhitelisting.confirmPlaceholder') ?? 'CONFIRM'}
                    value={confirm}
                    required={true}
                    onChange={handleChange}
                  />
                </FormField>
              </>
            )}
            {[4, 5].includes(workspace.pricing_version) && workspaceUtils.hasPremiumSubscription(workspace) && (
              <>
                <FormField>
                  <label>{t('workspaceSettingsWhitelisting.email')}</label>
                  <small className="light">{t('workspaceSettingsWhitelisting.emailInfo')}</small>
                  <input
                    type="text"
                    name="restricted_domains"
                    className={`${restrictedDomainsClassname} small`}
                    autoComplete="off"
                    placeholder={t('workspaceSettingsWhitelisting.emailPlaceholder') ?? 'Ex: acme.com'}
                    value={restricted_domains}
                    required={false}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <label>{t('workspaceSettingsWhitelisting.ip')}</label>
                  <small className="light">{t('workspaceSettingsWhitelisting.ipInfo')}</small>
                  <input
                    type="text"
                    name="restricted_ips"
                    className={`${restrictedIpsClassname} small`}
                    autoComplete="off"
                    placeholder={
                      t('workspaceSettingsWhitelisting.ipPlaceholder') ??
                      'Ex: 104.192.143.1, 2401:1d80:1010::150, 104.192.143.0/28'
                    }
                    value={restricted_ips}
                    required={false}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <label>{t('workspaceSettingsWhitelisting.confirm', { text: confirmText })}</label>
                  <small className="light">{t('workspaceSettingsWhitelisting.confirmInfo')}</small>
                  <input
                    type="text"
                    className="tiny"
                    name="confirm"
                    autoComplete="off"
                    placeholder={t('workspaceSettingsWhitelisting.confirmPlaceholder') ?? 'CONFIRM'}
                    value={confirm}
                    required={true}
                    onChange={handleChange}
                  />
                </FormField>
              </>
            )}
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isLoading || isDisabled} loading={isLoading}>
                {t('shared.save')}
              </KoalaButton>
              {isFormSaved && <div>{t('shared.saved')}</div>}
            </Actions>
          </Form>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
