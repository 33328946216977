import styled from 'styled-components';
import { colors, spacing } from '../../shared/styles';

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  .tgl {
    // hide orignial checkbox
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;

    // styling for toggle btn
    + .tgl-btn {
      outline: 0;
      display: block;
      margin: 0 ${spacing.x1} 0 0;
      position: relative;
      cursor: pointer;
      user-select: none;
      &:after,
      &:before {
        position: relative;
        display: block;
        content: '';
        width: 50%;
        height: 100%;
      }

      &:after {
        left: 0;
      }

      &:before {
        display: none;
      }
    }

    /* &:checked + .tgl-btn {
      background-color: ${colors.B50};
    } */

    &:checked + .tgl-btn:after {
      left: 50%;
    }

    &:disabled + .tgl-btn {
      cursor: not-allowed;
      background: ${colors.N20};
    }
  }

  .tgl-btn {
    background: ${colors.N30};
    border-radius: 2rem;
    padding: 2px;
    transition: all 0.4s ease;
    &:after {
      border-radius: 50%;
      background: #fff;
      transition: all 0.2s ease;
    }
  }

  input:focus + .tgl-btn {
    transition: none;
    outline-offset: 2px;
  }

  /* APPEARANCE */
  &.toggle-btn--primary {
    .tgl {
      &:checked + .tgl-btn {
        background-color: ${colors.B50};
      }
    }
  }
  &.toggle-btn--secondary {
    .tgl {
      &:checked + .tgl-btn {
        background-color: ${colors.T50};
      }
    }
  }

  /* SIZE */
  &.toggle-btn--small {
    .tgl-btn {
      width: 3.2rem;
      height: 1.8rem;
    }
  }
  &.toggle-btn--medium {
    .tgl-btn {
      width: 4rem;
      height: 2.2rem;
    }
  }
  &.toggle-btn--large {
    .tgl-btn {
      width: 4.8rem;
      height: 2.6rem;
    }
  }
`;

export default ToggleContainer;
