import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const GradientBox = styled.div<{ background: string; isSelected: boolean }>`
  background: ${(props) => props.background};
  width: 24px;
  height: 24px;
  border: ${(props) => (props.isSelected ? `solid 2px ${theme.colors.N30}` : `solid 1px ${theme.colors.N30}`)};
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;

  span {
    display: ${(props) => (props.isSelected ? 'unset' : 'none')};
  }

  :hover {
    border: solid 2px ${theme.colors.N60};
  }
`;

const Container = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;

interface Props {
  setBackground: (e: any) => void;
  background: string;
  backgroundGradients: { [key: string]: string };
}

function BackgroundSelection(props: Props) {
  const { background, backgroundGradients, setBackground } = props;

  const handleChangeBackground = (value: any) => {
    setBackground(value);
  };
  return (
    <Container>
      {(Object.keys(backgroundGradients) as Array<keyof typeof backgroundGradients>).map((key) => {
        const isSelected = background === key;
        return (
          <GradientBox
            background={backgroundGradients[key]}
            isSelected={isSelected}
            onClick={() => handleChangeBackground(key)}
          >
            <span>✓</span>
          </GradientBox>
        );
      })}
    </Container>
  );
}

export default BackgroundSelection;
