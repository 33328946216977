import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { Membership } from 'types';
import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import MarkdownContent from 'components/MarkdownContent';

const Container = styled.div`
  min-height: 2.4rem;
`;
const Header = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  cursor: pointer;

  .collapsed {
    transform: rotate(-90deg);
  }

  label {
    margin-bottom: ${theme.spacing.x1};
    color: ${theme.colors.N70};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    display: block;
  }
`;
const StandupContainer = styled.div`
  .quill,
  .ql-container {
    max-height: 280px;
    overflow: scroll;
  }

  &.show-standup {
    max-height: 300px;
    transition: max-height 0.4s ease;
    border: 1px solid ${theme.colors.N20};
    border-radius: 4px;
    margin-bottom: ${theme.spacing.x1};
    padding: 0px ${theme.spacing.x1} 0px ${theme.spacing.x2};
  }
  &.hide-standup {
    max-height: 0;
    transition: max-height 0.4s ease;
  }
`;

function PreviousStandup() {
  const { t } = useTranslation();
  const [showLastStandup, setShowLastStandup] = useState(false);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  // Search query attributes
  const standupsQueryKey = [
    queryKeys.standups,
    currentWorkspace.id,
    {
      filter: {
        membership_id: currentMembership.id,
        limit: 1,
      },
    },
  ];

  // Search params for search
  const { isLoading, data: standupResponse } = useQuery(standupsQueryKey, remoteApi.fetchStandups);
  const handlePreviousCheckin = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowLastStandup((prev) => !prev);
  };

  const previousStandup = standupResponse?.data[0];

  if (isLoading) {
    return (
      <Container>
        <KoalaLoader size="small" />
      </Container>
    );
  }
  if (!previousStandup) {
    return <></>;
  }

  return (
    <Container>
      <Header onClick={handlePreviousCheckin}>
        <KoalaIconButton
          iconName="downChevron"
          size="xsmall"
          onClick={handlePreviousCheckin}
          className={showLastStandup ? 'expanded' : 'collapsed'}
        />
        <label>{t('modals.createStandup.latest')}</label>
      </Header>

      <StandupContainer className={showLastStandup ? 'show-standup' : 'hide-standup'}>
        {showLastStandup && <MarkdownContent source={previousStandup.body} />}
      </StandupContainer>
    </Container>
  );
}

export default PreviousStandup;
