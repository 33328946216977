import React from 'react';

// Components
import CopyTextInput from 'components/CopyTextInput';
import { MarkdownWrapper } from 'components/MarkdownContent';
import { Plan } from 'types';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};

  .copy-text-container {
    margin: 0px;
  }
`;
interface Props {
  plan: Plan;
  embedUrl: string;
  typeLabel: string;
}

function DeletePlan(props: Props) {
  const { t } = useTranslation();
  const { plan, embedUrl, typeLabel } = props;

  if (!plan.public_access_enabled) {
    return <>{t(`modals.share.publicAccessRequired`)}</>;
  }

  return (
    <Container>
      <h3>{t('modals.share.notionTitle')}</h3>
      <CopyTextInput text={embedUrl} />
      <div>
        <label>{t('modals.share.code')}</label>
        <MarkdownWrapper>
          <Trans
            i18nKey={'modals.share.notion'}
            values={{ label: typeLabel }}
            components={{ b: <b />, CopyTextInput: <CopyTextInput text={embedUrl} /> }}
          />

          <p>
            <a href="https://guides.tability.app/docs/integrations/notion" target="_blank" rel="noopener noreferrer">
              {t('modals.share.documentation')}
            </a>
          </p>
        </MarkdownWrapper>
      </div>
    </Container>
  );
}

export default DeletePlan;
