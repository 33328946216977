import React, { useState, useEffect, MouseEvent } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';

// Routes
import * as routes from 'routes';

import WorkspaceNav from './WorkspaceNav';
import WorkspacesList from './WorkspacesList';
import TabilityWordmarkBlack from 'components/_assets/TabilityWordmarkBlack';
import TabilityIconBlack from 'components/_assets/TabilityIconBlack';
import Loader from 'components/Loader';
import { Link, useHistory } from 'react-router-dom';

import KoalaIconButton from 'koala/components/IconButton';
import WorkspaceAvatar from 'components/WorkspaceAvatar';
import { Workspace } from 'types';
import { getWorkspaceDefaultTheme, WorkspaceThemeKey } from 'utils/workspaceThemeUtils';

const buttonBg = ['#C5A5EF', '#6ECAD6', '#FF9A3B', '#F4CDC9', '#7E98E6', '#F5C02B', '#8CC1B5'];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 23rem;
  width: 23rem;
  .icon-logo {
    display: none;
  }

  .settings-small-screen {
    display: none;
  }

  @media ${theme.devices.minMobile} and (${theme.devices.desktop}) {
    padding: ${theme.spacing.x3} ${theme.spacing.x2};
    align-items: center;
    width: auto;
    .mask-small-screen {
      display: none;
    }

    .settings-small-screen {
      display: flex;
      margin-bottom: ${theme.spacing.x3};
      svg {
        g,
        circle {
          stroke: ${(props: any) => props.color || '#000'};
        }
      }
    }
    .icon-logo {
      display: block;
      margin-bottom: ${theme.spacing.x1};
    }
    .wordmark-logo {
      display: none;
    }
  }

  &.collapsed {
    padding: ${theme.spacing.x3} ${theme.spacing.x2};
    align-items: center;
    width: auto;
    .mask-small-screen {
      display: none;
    }

    .settings-small-screen {
      display: flex;
      margin-bottom: ${theme.spacing.x3};
      svg {
        g,
        circle {
          stroke: ${(props: any) => props.color || '#000'};
        }
      }
    }
    .icon-logo {
      display: block;
      margin-bottom: ${theme.spacing.x1};
    }
    .wordmark-logo {
      display: none;
    }
  }
`;

const WordmarkContainer = styled.div`
  padding: ${theme.spacing.x2};
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    display: flex;
  }

  .icon {
    svg {
      g,
      circle {
        stroke: ${(props: any) => props.color || '#000'};
      }
    }
  }

  .close-mobile-nav {
    display: none;
  }

  @media ${theme.devices.minMobile} and (${theme.devices.desktop}) {
    flex-direction: column;
    padding: 0;
  }

  &.collapsed {
    flex-direction: column;
    padding: 0;
  }

  @media ${theme.devices.mobile} {
    padding-left: 0rem;

    .settings {
      display: none;
    }
    .close-mobile-nav {
      display: flex;
      margin: 0px ${theme.spacing.x1};
    }
  }
`;

const CurrentContainer = styled.div<{ textColor: string; backgroundColor: string }>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  font-weight: 400;
  font-size: 1.4rem;
  color: ${(props) => props.textColor};
  margin-bottom: ${theme.spacing.x2};
  position: relative;
  background: ${(props) => props.backgroundColor};
  &.collapsed {
    padding: 0;
  }

  @media ${theme.devices.minMobile} and (${theme.devices.desktop}) {
    padding: 0;
  }
`;

const CurrentName = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: ${theme.spacing.x1};
  }
`;

const WorkspacesListContainer = styled.div<{ altColor: string; activeColor: string; textColor: string }>`
  position: absolute;
  background: ${(props) => props.altColor};
  color: ${(props) => props.textColor};
  border-radius: 4px;
  filter: drop-shadow(0px 0px 8px rgba(21, 21, 21, 0.15));
  z-index: 100;
  left: ${theme.spacing.x2};
  top: 7rem;
  width: 30rem;
  max-height: 50rem;
  overflow: auto;

  .workspace-link {
    &.active,
    &:hover {
      background: ${(props) => props.activeColor};
    }
  }
  button {
    color: ${(props) => props.textColor};
  }
`;

interface Props {
  setShowMobileSidebar: (value: boolean) => void;
  isCollapsed: boolean;
}

function AppSidebar(props: Props) {
  // Get the current workspace from redux store
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const history = useHistory();
  const defaultTheme = getWorkspaceDefaultTheme(currentWorkspace);

  const [showWorkspaces, setShowWorkspaces] = useState(false);
  const { setShowMobileSidebar, isCollapsed } = props;
  useEffect(() => {
    const handleCloseFromClick = () => {
      setShowWorkspaces(false);
    };
    if (showWorkspaces) {
      document.addEventListener('click', handleCloseFromClick, false);
    } else {
      document.removeEventListener('click', handleCloseFromClick, false);
    }
    return () => document.removeEventListener('click', handleCloseFromClick, false);
  }, [showWorkspaces]);

  if (!currentWorkspace) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  const currentHash =
    currentWorkspace.id.charCodeAt(0) + currentWorkspace.id.charCodeAt(1) + currentWorkspace.id.charCodeAt(2);
  const colorIndex = currentHash % buttonBg.length;
  const color = buttonBg[colorIndex];

  const handleToggleWorspaces = (e: MouseEvent) => {
    e.preventDefault();
    setShowWorkspaces(!showWorkspaces);
  };

  const handleShowSettings = (e: MouseEvent) => {
    e.preventDefault();
    handleCloseMobileSidebar();
    history.push(routes.WORKSPACE_SETTINGS_ROUTE.replace(':workspaceSlug', currentWorkspace.slug));
  };

  const handleCloseMobileSidebar = () => {
    setShowMobileSidebar(false);
  };

  const handleStopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  // Collapse the nav if we're in the map view
  const collapsedClass = isCollapsed ? 'collapsed' : '';

  return (
    <Container color={color} className={collapsedClass}>
      <WordmarkContainer color={color} className={collapsedClass}>
        <KoalaIconButton
          className="close-mobile-nav"
          onClick={handleCloseMobileSidebar}
          iconName="leftArrow"
          iconColor={currentWorkspace.theme_link_icon ?? defaultTheme[WorkspaceThemeKey.sidebarIcon]}
        />
        <Link to={`/${currentWorkspace.slug}`} className="wordmark-logo" onClick={handleCloseMobileSidebar}>
          <TabilityWordmarkBlack />
        </Link>
        <Link to={`/${currentWorkspace.slug}`} className="icon-logo" onClick={handleCloseMobileSidebar}>
          <TabilityIconBlack />
        </Link>
        {currentMembership && ['admin', 'owner'].includes(currentMembership.role) && (
          <KoalaIconButton
            className="mask-small-screen settings"
            onClick={handleShowSettings}
            iconName="settings"
            iconColor={currentWorkspace.theme_link_icon ?? defaultTheme[WorkspaceThemeKey.sidebarIcon]}
          />
        )}
      </WordmarkContainer>
      <CurrentContainer
        onClick={handleToggleWorspaces}
        backgroundColor={currentWorkspace.theme_alt_bg ?? defaultTheme[WorkspaceThemeKey.sidebarAltBg]}
        textColor={currentWorkspace.theme_link_text ?? defaultTheme[WorkspaceThemeKey.sidebarText]}
        className={collapsedClass}
      >
        <CurrentName>
          <WorkspaceAvatar workspace={currentWorkspace} />
          <span className="mask-small-screen">{currentWorkspace.name}</span>
        </CurrentName>
        <KoalaIconButton
          className="mask-small-screen"
          onClick={handleToggleWorspaces}
          iconName="select"
          iconColor={currentWorkspace.theme_link_icon ?? defaultTheme[WorkspaceThemeKey.sidebarIcon]}
        />
        {showWorkspaces && (
          <WorkspacesListContainer
            onClick={handleStopPropagation}
            textColor={currentWorkspace.theme_link_text ?? defaultTheme[WorkspaceThemeKey.sidebarText]}
            altColor={currentWorkspace.theme_alt_bg ?? defaultTheme[WorkspaceThemeKey.sidebarAltBg]}
            activeColor={currentWorkspace.theme_link_active_bg ?? defaultTheme[WorkspaceThemeKey.sidebarActiveBg]}
          >
            <WorkspacesList handleCloseMobileSidebar={handleCloseMobileSidebar} />
          </WorkspacesListContainer>
        )}
      </CurrentContainer>
      <WorkspaceNav
        workspace={currentWorkspace}
        showSettings={handleShowSettings}
        handleCloseMobileSidebar={handleCloseMobileSidebar}
        isCollapsed={isCollapsed}
      />
    </Container>
  );
}

export default React.memo(AppSidebar);
