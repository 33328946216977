import React, { useState } from 'react';

import DisplayCommentBlock from './_DisplayCommentBlock';
import EditCommentBlock from './_EditCommentBlock';

interface Props {
  standupComment: any;
}

function CommentBlock(props: Props) {
  const { standupComment } = props;

  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return <EditCommentBlock standupComment={standupComment} setIsEditing={setIsEditing} />;
  }

  return <DisplayCommentBlock standupComment={standupComment} setIsEditing={setIsEditing} />;
}

export default React.memo(CommentBlock);
