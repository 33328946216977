// Session actions
export const LOGIN_REQUESTED = 'SESSION.LOGIN_REQUESTED';
export const LOGIN_COMPLETED = 'SESSION.LOGIN_COMPLETED';
export const LOGIN_FAILED = 'SESSION.LOGIN_FAILED';

export const SAVE_NEW_PASSWORD_REQUESTED = 'SESSION.SAVE_NEW_PASSWORD_REQUESTED';
export const SAVE_NEW_PASSWORD_COMPLETED = 'SESSION.SAVE_NEW_PASSWORD_COMPLETED';
export const SAVE_NEW_PASSWORD_FAILED = 'SESSION.SAVE_NEW_PASSWORD_FAILED';

export const LOGOUT_REQUESTED = 'SESSION.LOGOUT_REQUESTED';
export const LOGOUT_COMPLETED = 'SESSION.LOGOUT_COMPLETED';
export const LOGOUT_FAILED = 'SESSION.LOGOUT_FAILED';

export const REFRESH_TOKENS_REQUESTED = 'SESSION.REFRESH_TOKENS_REQUESTED';
export const REFRESH_TOKENS_COMPLETED = 'SESSION.REFRESH_TOKENS_COMPLETED';
export const REFRESH_TOKENS_FAILED = 'SESSION.REFRESH_TOKENS_FAILED';

export const SIGNUP_REQUESTED = 'SESSION.SIGNUP_REQUESTED';
export const SIGNUP_COMPLETED = 'SESSION.SIGNUP_COMPLETED';
export const SIGNUP_FAILED = 'SESSION.SIGNUP_FAILED';

export const CONFIRM_REQUESTED = 'SESSION.CONFIRM_REQUESTED';
export const CONFIRM_COMPLETED = 'SESSION.CONFIRM_COMPLETED';
export const CONFIRM_FAILED = 'SESSION.CONFIRM_FAILED';

export const RESEND_CONFIRM_REQUESTED = 'SESSION.RESEND_CONFIRM_REQUESTED';
export const RESEND_CONFIRM_COMPLETED = 'SESSION.RESEND_CONFIRM_COMPLETED';
export const RESEND_CONFIRM_FAILED = 'SESSION.RESEND_CONFIRM_FAILED';

export const SET_CURRENT_USER = 'SESSION.SET_CURRENT_USER';
export const SET_ACCESS_TOKEN = 'SESSION.SET_ACCESS_TOKEN';
export const SET_CURRENT_MEMBERSHIP = 'SESSION.SET_CURRENT_MEMBERSHIP';
export const SET_CURRENT_WORKSPACE = 'SESSION.SET_CURRENT_WORKSPACE';
export const SET_CURRENT_WORKSPACE_SETTINGS = 'SESSION.SET_CURRENT_WORKSPACE_SETTINGS';
export const SET_CURRENT_PING = 'SESSION.SET_CURRENT_PING';
export const SET_CURRENT_INTEGRATIONS = 'SESSION.SET_CURRENT_INTEGRATIONS';
export const SET_WORKSPACE_MEMBERSHIPS = 'SESSION.SET_WORKSPACE_MEMBERSHIPS';
export const SET_IS_EMPTY_WORKSPACE = 'SESSION.IS_EMPTY_WORKSPACE';
export const SET_REDIRECT_LINK = 'SESSION.REDIRECT_LINK';

// Templates actions
export const SET_REFERENCE_TEMPLATE = 'TEMPLATE.SET_REFERENCE';

// UI Actions
export const RESET_PAGE_STATE = 'PAGE.RESET_STATE';

// Local API action
export const LOCAL_PLAN_CREATE_COMPLETED = 'LOCAL.PLAN.CREATE_COMPLETED';
export const LOCAL_PLAN_FETCH_DETAILS_COMPLETED = 'LOCAL.PLAN.FETCH_DETAILS_COMPLETED';
export const LOCAL_PLAN_UPDATE_DETAILS_COMPLETED = 'LOCAL.PLAN.UPDATE_DETAILS_COMPLETED';

// Entities loading actions
export const LOAD_PLAN = 'LOAD_PLAN';

//PLAN stuff
export const UPDATE_PLAN = 'UPDATE_PLAN';

// Re-order stuff

export const REORDER_OBJECTIVE = 'REORDER_OBJECTIVE';
export const CREATE_OBJECTIVE = 'CREATE_OBJECTIVE';
export const UPDATE_OBJECTIVE = 'UPDATE_OBJECTIVE';
export const DELETE_OBJECTIVE = 'DELETE_OBJECTIVE';

export const REORDER_OUTCOME = 'REORDER_OUTCOME';
export const MOVE_OUTCOME = 'MOVE_OUTCOME';
export const CREATE_OUTCOME = 'CREATE_OUTCOME';
export const UPDATE_OUTCOME = 'UPDATE_OUTCOME';
export const DELETE_OUTCOME = 'DELETE_OUTCOME';

export const REORDER_TASK = 'REORDER_TASK';
export const MOVE_TASK = 'MOVE_TASK';
export const CREATE_TASK = 'CREATE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const DELETE_TASK = 'DELETE_TASK';

// Global UI
export const SET_GLOBAL_PANEL_CONTENT = 'SET_GLOBAL_PANEL_CONTENT';
export const SET_GLOBAL_MODAL_CONTENT = 'SET_GLOBAL_MODAL_CONTENT';

// Write plan actions
export const SET_DISPLAY_NEW_BLOCK_FORM = 'SET_DISPLAY_NEW_BLOCK_FORM';
export const SELECT_BLOCK = 'SELECT_BLOCK';
export const SELECT_BLOCK_ABOVE = 'SELECT_BLOCK_ABOVE';
export const SELECT_BLOCK_BELOW = 'SELECT_BLOCK_BELOW';
export const UNSELECT_BLOCK = 'UNSELECT_BLOCK';
export const GET_SCORECARD = 'GET_SCORECARD';
export const SET_PANEL_CONTENT = 'SET_PANEL_CONTENT';

// Editor actions
export const EDITOR_SET_DISPLAY_NEW_BLOCK_FORM = 'EDITOR.SET_DISPLAY_NEW_BLOCK_FORM';
export const EDITOR_SELECT_BLOCK = 'EDITOR.SELECT_BLOCK';
export const EDITOR_SELECT_BLOCK_ABOVE = 'EDITOR.SELECT_BLOCK_ABOVE';
export const EDITOR_SELECT_BLOCK_BELOW = 'EDITOR.SELECT_BLOCK_BELOW';
export const EDITOR_UNSELECT_BLOCK = 'EDITOR.UNSELECT_BLOCK';
export const EDITOR_GET_SCORECARD = 'EDITOR.GET_SCORECARD';
export const EDITOR_SET_PANEL_CONTENT = 'EDITOR.SET_PANEL_CONTENT';

// Playground Home action
export const RESET_CREATED_PLAN_ID = 'RESET_CREATED_PLAN_ID';

// Workspace Editor actons
export const WORKSPACE_EDITOR_SET_PANEL_CONTENT = 'WORKSPACE.EDITOR.SET_PANEL_CONTENT';
export const WORKSPACE_EDITOR_SET_SYNC_ERROR = 'WORKSPACE.EDITOR.WORKSPACE_EDITOR_SET_SYNC_ERROR';
export const WORKSPACE_EDITOR_SET_SYNC_ERROR_TEXT = 'WORKSPACE.EDITOR.WORKSPACE_EDITOR_SET_SYNC_ERROR_TEXT';
export const WORKSPACE_EDITOR_SET_LOADING_OUTCOME_METRIC =
  'WORKSPACE.EDITOR.WORKSPACE_EDITOR_SET_LOADING_OUTCOME_METRIC';
export const WORKSPACE_EDITOR_RESET_LOADING_METRIC = 'WORKSPACE.EDITOR.WORKSPACE_EDITOR_RESET_LOADING_METRIC';
export const WORKSPACE_EDITOR_LOAD_PLAN = 'WORKSPACE.EDITOR.LOAD_PLAN';
export const WORKSPACE_EDITOR_LOAD_OBJECTIVES = 'WORKSPACE.EDITOR.LOAD_OBJECTIVES';
export const WORKSPACE_EDITOR_LOAD_OUTCOMES = 'WORKSPACE.EDITOR.LOAD_OUTCOMES';
export const WORKSPACE_EDITOR_LOAD_INITIATIVES = 'WORKSPACE.EDITOR.LOAD_INITIATIVES';
export const WORKSPACE_EDITOR_LOAD_REVIEW_COMMENTS = 'WORKSPACE.EDITOR.LOAD_REVIEW_COMMENTS';
export const WORKSPACE_EDITOR_LOAD_RESOLVED_COMMENTS = 'WORKSPACE.EDITOR.LOAD_RESOLVED_COMMENTS';

// Workspace Editor UI actions
export const WORKSPACE_EDITOR_RESET = 'WORKSPACE.EDITOR.RESET';
export const WORKSPACE_EDITOR_UPDATE_PLAN = 'WORKSPACE.EDITOR.UPDATE_PLAN';

export const WORKSPACE_EDITOR_SET_DISPLAY_NEW_BLOCK_FORM = 'WORKSPACE.EDITOR.SET_DISPLAY_NEW_BLOCK_FORM';
export const WORKSPACE_EDITOR_SELECT_BLOCK = 'WORKSPACE.EDITOR.SELECT_BLOCK';
export const WORKSPACE_EDITOR_SELECT_BLOCK_ABOVE = 'WORKSPACE.EDITOR.SELECT_BLOCK_ABOVE';
export const WORKSPACE_EDITOR_SELECT_BLOCK_BELOW = 'WORKSPACE.EDITOR.SELECT_BLOCK_BELOW';
export const WORKSPACE_EDITOR_UNSELECT_BLOCK = 'WORKSPACE.EDITOR.UNSELECT_BLOCK';
export const WORKSPACE_EDITOR_GET_SCORECARD = 'WORKSPACE.EDITOR.GET_SCORECARD';

export const WORKSPACE_EDITOR_CREATE_OBJECTIVE = 'WORKSPACE.EDITOR.CREATE_OBJECTIVE';
export const WORKSPACE_EDITOR_UPDATE_OBJECTIVE = 'WORKSPACE.EDITOR.UPDATE_OBJECTIVE';
export const WORKSPACE_EDITOR_DELETE_OBJECTIVE = 'WORKSPACE.EDITOR.DELETE_OBJECTIVE';
export const WORKSPACE_EDITOR_REORDER_OBJECTIVE = 'WORKSPACE.EDITOR.REORDER_OBJECTIVE';

export const WORKSPACE_EDITOR_CREATE_OUTCOME = 'WORKSPACE.EDITOR.CREATE_OUTCOME';
export const WORKSPACE_EDITOR_UPDATE_OUTCOME = 'WORKSPACE.EDITOR.UPDATE_OUTCOME';
export const WORKSPACE_EDITOR_DELETE_OUTCOME = 'WORKSPACE.EDITOR.DELETE_OUTCOME';
export const WORKSPACE_EDITOR_REORDER_OUTCOME = 'WORKSPACE.EDITOR.REORDER_OUTCOME';
export const WORKSPACE_EDITOR_MOVE_OUTCOME = 'WORKSPACE.EDITOR.MOVE_OUTCOME';

export const WORKSPACE_EDITOR_CREATE_TASK = 'WORKSPACE.EDITOR.CREATE_TASK';
export const WORKSPACE_EDITOR_UPDATE_TASK = 'WORKSPACE.EDITOR.UPDATE_TASK';
export const WORKSPACE_EDITOR_DELETE_TASK = 'WORKSPACE.EDITOR.DELETE_TASK';
export const WORKSPACE_EDITOR_REORDER_TASK = 'WORKSPACE.EDITOR.REORDER_TASK';
export const WORKSPACE_EDITOR_MOVE_TASK = 'WORKSPACE.EDITOR.MOVE_TASK';

// YML Editor Reducer

export const YML_EDITOR_RESET = 'YML_EDITOR_RESET';
export const YML_EDITOR_LOAD_TEMPLATE = 'YML_EDITOR_LOAD_TEMPLATE';
export const YML_EDITOR_REORDER_ITEMS = 'YML_EDITOR_REORDER_ITEMS';
export const YML_EDITOR_UPDATE_ITEM = 'YML_EDITOR_UPDATE_ITEM';
export const YML_EDITOR_SET_CURSOR = 'YML_EDITOR_SET_CURSOR';
export const YML_EDITOR_MOVE_CURSOR_UP = 'YML_EDITOR_MOVE_CURSOR_UP';
export const YML_EDITOR_MOVE_CURSOR_DOWN = 'YML_EDITOR_MOVE_CURSOR_DOWN';
export const YML_EDITOR_ADD_ITEM = 'YML_EDITOR_ADD_ITEM';
export const YML_EDITOR_ADD_CHILD_ITEM = 'YML_EDITOR_ADD_CHILD_ITEM';
export const YML_EDITOR_REMOVE_ITEM = 'YML_EDITOR_REMOVE_ITEM';
