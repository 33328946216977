import { Fragment } from 'react';
import { KoalaAppearances, KoalaSizes, KoalaViewport } from 'koala/shared/global';
import { colors, spacing } from 'koala/shared/styles';
import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import KoalaIcon from '../Icons';
import { NavLink, NavLinkProps } from 'react-router-dom';
import theme from 'theme';

interface Props {
  tabs: {
    label: string | React.ReactNode;
    key: string;
    tooltip?: string;
    id?: string;
    upgradeRequired?: boolean;
    link?: string;
  }[];
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  activeTab?: string;
  setActiveTab?: (tab: string) => void;
  appearance?: (typeof KoalaAppearances)[number];
  size?: (typeof KoalaSizes)[number];
  className?: string;
  style?: React.CSSProperties;
}

const Tabs = styled.div`
  display: flex;
  gap: ${spacing.x2};

  &.tab--subtle {
    border-bottom: 1px solid ${colors.N20};
  }

  @media ${KoalaViewport.tablet} {
    width: 100%;
    display: flex;
    gap: ${spacing.x1};
  }
`;

const TabButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  justify-content: center;
  // switch back to N60 when on white bg
  text-transform: uppercase;
  color: ${colors.N70};
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.2;
  height: 3.4rem;
  border-bottom: 4px solid transparent;
  transition: all ease 0.2s;

  padding: 0 0 ${spacing.x1} 0;
  &:hover,
  &.active,
  &:focus {
    cursor: pointer;
    color: ${colors.N100};
    border-bottom: 4px solid currentColor;
    background: none;

    svg {
      path {
        fill: ${colors.N100};
      }
    }
  }

  &.tab-button--secondary {
    font-weight: 700;
    text-transform: uppercase;
    &:hover,
    &.active {
      color: ${colors.B50};
    }
  }
  &.tab-button--subtle {
    font-weight: 500;
    font-size: 12px;
    text-transform: none;
    height: unset;
    border-bottom: 2px solid transparent;
    &:hover,
    &.active {
      border-bottom: 2px solid currentColor;
    }
  }

  @media ${KoalaViewport.tablet} {
    flex-grow: 1;
    flex-shrink: 1;
  }
`;

const StyledLinkTabButton = styled(TabButton).attrs({ as: NavLink })<NavLinkProps>`
  text-decoration: none;
`;

const KoalaTabs: FC<Props> = ({ ...props }) => {
  const { tabs, activeTab, setActiveTab, appearance, size, className, style } = props;

  const tabClass = `${className ?? ''} tab--${size ?? 'medium'} tab--${appearance ?? 'primary'}`;
  const tabButtonClass = `tab-button--${appearance ?? 'primary'} `;

  const buttonSetTab = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick(e);
    }

    if (setActiveTab) {
      setActiveTab(e.currentTarget.dataset.key || '');
    }
  };

  return (
    <Tabs className={tabClass} style={style}>
      {tabs.map((tab, index) => {
        let tabButton;
        if (tab.link) {
          tabButton = (
            <StyledLinkTabButton
              to={tab.link ?? ''}
              className={`${tabButtonClass} ${tab.key === activeTab ? 'active' : ''}`}
              id={tab.id}
              data-tip
              data-for={`tab-tooltip-${tab.key}`}
              key={`tab-${index}`}
            >
              {tab.upgradeRequired && <KoalaIcon iconName="upgrade" iconSize={2} />}
              {tab.label}
            </StyledLinkTabButton>
          );
        } else {
          tabButton = (
            <TabButton
              tabIndex={0}
              onClick={buttonSetTab}
              className={`${tabButtonClass} ${tab.key === activeTab ? 'active' : ''}`}
              id={tab.id}
              data-tip
              data-key={tab.key}
              data-for={`tab-tooltip-${tab.key}`}
              key={`tab-${index}`}
            >
              {tab.upgradeRequired && <KoalaIcon iconName="upgrade" iconSize={2} />}
              {tab.label}
            </TabButton>
          );
        }
        return (
          <Fragment key={`tab-${index}`}>
            {tabButton}
            {tab.tooltip && (
              <ReactTooltip
                place="bottom"
                type="dark"
                id={`tab-tooltip-${tab.key}`}
                className="tooltip"
                effect="solid"
                delayShow={200}
              >
                {tab.tooltip}
              </ReactTooltip>
            )}
          </Fragment>
        );
      })}
    </Tabs>
  );
};

KoalaTabs.defaultProps = {
  appearance: 'primary',
  size: 'medium',
};

export default KoalaTabs;
