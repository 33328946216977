import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';
import * as TabilityTypes from 'types';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaProgressBar from 'koala/components/ProgressBar';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Actions = styled.div`
  margin-top: ${theme.spacing.x2};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;
  gap: ${theme.spacing.x1};
`;

interface Props {}

function MovePlan(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const translationKey = 'modals.workspaceCheckinsQuota';
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  const isOwner = currentMembership.role === 'owner';

  const closePanel = () => {
    dispatch(setGlobalModalContent(''));
  };

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.checkins_quota.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const handleCancel = (e: any) => {
    e.preventDefault();
    closePanel();
  };

  const handleUpgrade = (e: any) => {
    e.preventDefault();
    const route = `/${currentWorkspace.slug}/settings/billing`;
    history.push(route);
  };

  // Calculate the check-ins usage prct
  let checkinsQuotaPrct = 100;
  if (currentWorkspace.billing_checkins_limit) {
    checkinsQuotaPrct = Math.min(
      100,
      (currentWorkspace.checkins_count / currentWorkspace.billing_checkins_limit) * 100,
    );
  }

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <h3>{t(`${translationKey}.workspaceTrial`)}</h3>
        <ProgressContainer>
          <KoalaProgressBar value={checkinsQuotaPrct} appearance="secondary" />
          <div className="checkins-quota-text">
            {t(`${translationKey}.checkinsUsed`, {
              count: currentWorkspace.checkins_count,
              limit: currentWorkspace.billing_checkins_limit,
            })}
          </div>
        </ProgressContainer>
        <p>
          <b>{t(`${translationKey}.line1`)}</b>
        </p>
        <p>{t(`${translationKey}.line2`)}</p>
        <p>{t(`${translationKey}.line3`, { limit: currentWorkspace.billing_checkins_limit })}</p>
        <p>{t(`${translationKey}.line4`)}</p>
        <Actions>
          {isOwner && <KoalaButton onClick={handleUpgrade}>{t('shared.upgrade.upgradeButton')}</KoalaButton>}
          <KoalaTextButton href="https://tability.io/pricing" rel="noopener noreferrer" target="_blank">
            {t('shared.upgrade.learnMore')}
          </KoalaTextButton>
        </Actions>
      </ModalContent>
    </ModalGrid>
  );
}

export default MovePlan;
