import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import * as ymlEditorActions from 'state/actions/ymlEditorActions';

const Input = styled.input``;

type Props = {
  itemId: string;
  itemType: string;
};

function TextInput(props: Props) {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { itemId, itemType } = props;

  const item = useSelector((state: any) => state.ymlEditor.data[itemType][itemId]);
  const blockId = `${itemType}:${itemId}`;
  const isFocused = useSelector((state: any) => state.ymlEditor.cursor === blockId);

  useEffect(() => {
    if (isFocused && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [isFocused]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(ymlEditorActions.setCursor(blockId));
  };

  const handleTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
    // @ts-ignore
    const title = e.target.value;
    const newItem = {
      ...item,
      title,
    };
    dispatch(ymlEditorActions.updateItem(newItem, itemType));
  };

  const handlePress = (e: any) => {
    if (e.keyCode === 27) {
      // Esc key
      e.currentTarget.blur();
      // @ts-ignore
      dispatch(ymlEditorActions.setCursor(null));
    }

    if (e.keyCode === 9) {
      // Tab key
      e.preventDefault();
      const value = e.target.value;
      if (value) {
        dispatch(ymlEditorActions.addChildItem());
      }
      if (!value) {
        if (itemType !== 'initiatives') {
          dispatch(ymlEditorActions.removeItem(blockId));
          dispatch(ymlEditorActions.addChildItem());
        }
      }
    }

    if (e.keyCode === 13) {
      // Enter key
      //e.currentTarget.blur();
      //dispatch(ymlEditorActions.setCursor(null));
      dispatch(ymlEditorActions.addItem());
    }

    if (e.keyCode === 38) {
      // Arrow up
      e.preventDefault();
      dispatch(ymlEditorActions.moveCursorUp());
    }

    if (e.keyCode === 40) {
      // Arrow down
      e.preventDefault();
      dispatch(ymlEditorActions.moveCursorDown());
    }

    if (e.keyCode === 8) {
      // Delete key
      const value = e.target.value;
      if (!value) {
        // Only delete if there's no value
        dispatch(ymlEditorActions.removeItem(blockId));
        e.preventDefault();
        // DELETE CODE
      }
    }
  };

  return (
    <Input
      className="editor"
      // @ts-ignore
      ref={inputRef}
      placeholder={`Untitled ${itemType}`}
      value={item.title}
      onClick={handleClick}
      onChange={handleTitleChange}
      onKeyDown={handlePress}
    />
  );
}

export default React.memo(TextInput);
