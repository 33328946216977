import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Initiative, Workspace } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import parse from 'parse-link-header';
import { useHistory } from 'react-router-dom';

import * as remoteApi from 'api/remote';
import InitiativeRow from 'components/InitiativeRow';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';
import theme from 'theme';
import * as integrationUtils from 'utils/integrationUtils';

import { PanelGrid, PanelHeader, PanelContent } from 'components/GlobalPanel';
import { useTranslation } from 'react-i18next';

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x1};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
`;

const IntegrationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.x3};

  p {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};
interface Props {
  outcomeId: string;
}

function ViewUnsetInitiatives(props: Props) {
  const { outcomeId } = props;
  const history = useHistory();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const filter = {
    outcome_id: outcomeId,
    without_due_date: true,
  };

  const queryKey = [
    queryKeys.initiatives,
    'search',
    {
      workspaceSlug: currentWorkspace.slug,
      filter,
    },
  ];
  const {
    data: initiativesResponse,
    isLoading,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  }: any = useInfiniteQuery(queryKey, remoteApi.searchInitiatives, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  let initiatives: Initiative[] = [];
  let integrationInitiatives: Initiative[] = [];
  if (initiativesResponse && initiativesResponse[0]?.data) {
    initiatives = initiativesResponse[0].data.filter((initiative: Initiative) => !initiative.integration_type);
    integrationInitiatives = initiativesResponse[0].data.filter(
      (initiative: Initiative) => initiative.integration_type && !integrationUtils.getDueDate(initiative),
    );
  }

  const handleCancel = () => {
    history.goBack();
  };

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <PanelGrid onClick={handleStopPropagation}>
      <PanelHeader>
        <Header>
          <h2>
            {t('panels.initiative.withoutDueDates', {
              label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
            })}
          </h2>
          <KoalaIconButton iconName="close" onClick={handleCancel} />
        </Header>
      </PanelHeader>
      <PanelContent>
        {isLoading && <KoalaLoader />}
        {initiatives.map((initiative) => (
          <InitiativeRow key={initiative.id} initiative={initiative} showMeta={true} />
        ))}
        {integrationInitiatives.length > 0 && (
          <IntegrationsContainer>
            <p className="subtle">
              {t(`panels.initiative.withoutDueDatesIntegrations`, {
                initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
              })}
            </p>
            {integrationInitiatives.map((initiative) => (
              <InitiativeRow key={initiative.id} initiative={initiative} showMeta={true} />
            ))}
          </IntegrationsContainer>
        )}
        {canFetchMore && (
          <LoadMore>
            <KoalaButton loading={isFetchingMore} onClick={() => fetchMore()} appearance="secondary">
              {t('shared.loadMore')}
            </KoalaButton>
          </LoadMore>
        )}
      </PanelContent>
    </PanelGrid>
  );
}

export default ViewUnsetInitiatives;
