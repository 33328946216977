import React, { ChangeEvent, useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import KoalaInputValidation from 'koala/components/InputValidation';

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();
  // Query keys and query params
  const outcomeSQL = outcome.data_source_meta?.sql || '';
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:mariadb:sql${encodeURIComponent(outcomeSQL)}`,
    'current',
  ];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = toNumber(data?.data.result);

  if (currentValue === null || currentValue === undefined) {
    return <FormField>{t(`panels.editOutcome.errorFetching`)}</FormField>;
  }

  return (
    <FormField>
      <label>{t('modals.dataConnectors.preview')}</label>
      <p>{currentValue}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
}

function MariaDBSource(props: Props) {
  const { outcome } = props;
  const { t } = useTranslation();
  const [query, setQuery] = useState((outcome.data_source_meta && outcome.data_source_meta.sql) ?? '');
  const [error, setError] = useState('');
  const outcomeSQL = outcome.data_source_meta?.sql || '';

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const handleUpdate = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    // Prevent saving if query doesn't have a select
    const selectPattern = /^SELECT\b/i; // Check for 'SELECT' at the start
    const semicolonPattern = /;/; // Check for the presence of a semicolon

    if (!selectPattern.test(query)) {
      setError('SQL must be a SELECT query');
      return;
    }
    if (semicolonPattern.test(query)) {
      setError('SQL cannot contain a ;');
      return;
    }

    e.preventDefault();
    let body = {
      data_source_origin: 'mariadb',
      data_source_type: 'sql',
      data_source_meta: { sql: query },
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const handleUpdateQuery = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    setError('');
  };

  const mariaDBKey = `modals.dataConnectors.SQLData`;
  const placeholder = t(`${mariaDBKey}.placeholder`) ?? 'Write your SQL here';
  return (
    <>
      <FormField>
        <label>{t(`${mariaDBKey}.query`)}</label>
        <input value={query} onChange={handleUpdateQuery} placeholder={placeholder} />
        <small>{t(`${mariaDBKey}.queryInfo`)}</small>
        {error && <KoalaInputValidation state="error" text={error} />}
      </FormField>
      <FormField>
        <KoalaButton
          onClick={handleUpdate}
          loading={isUpdating}
          disabled={!query || isUpdating || !!error}
          appearance="subtle"
          type="button"
        >
          {t(`panels.editOutcome.savePreview`)}
        </KoalaButton>
      </FormField>
      {outcome.data_source_origin === 'mariadb' && outcomeSQL === query && <CurrentValue outcome={outcome} />}
    </>
  );
}

export default React.memo(MariaDBSource);
