/**
  This is the playground editor. It loads the state from local storage and saves there too
 */

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';

// Components
import * as TabilityTypes from 'types';
import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';
import PlanIconLabel from 'components/PlanIconLabel';
import {
  WORKSPACE_PLAN_PREZ_DASHBOARD_ROUTE,
  WORKSPACE_PLAN_PREZ_ROOT_ROUTE,
  WORKSPACE_PLAN_TRACK_ROUTE,
} from 'routes';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import RadioField from 'components/RadioField';
import KoalaIcon from 'koala/components/Icons';
import PlanStatus from 'components/PlanStatus';
import KoalaToggleButton from 'koala/components/ToggleButton';

const Wrapper = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.x2};
  border-bottom: solid 1px ${theme.colors.N40};
  height: 6.5rem;

  .dark {
    path {
      fill: ${theme.colors.N0};
    }
  }
`;
const DarkModeSection = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  &.dark {
    color: ${theme.colors.N0};
  }
`;

const HeaderSection = styled.div<{ darkMode?: boolean }>`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;

  .view-selection {
    margin: 0;
  }
  &.dark {
    color: ${theme.colors.N0};

    a {
      color: #fff;
    }
  }
  .radio-group {
    height: 3.2rem;
  }

  label.larger-icon {
    svg {
      width: 2.4rem;
      height: 2.4rem;
      path {
        stroke: none;
      }
    }
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
  isDashboard?: boolean;
  handleDownload?: () => void;
  isDarkMode?: boolean;
  setIsDarkMode?: (e: boolean) => void;
}

function PlanNav(props: Props) {
  const { plan, isDashboard, handleDownload, isDarkMode, setIsDarkMode } = props;
  const history = useHistory();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const className = isDarkMode ? 'dark' : '';

  const handleShare = () => {
    dispatch(setGlobalModalContent(`plan:${plan.id}:share`));
  };
  const trackPath = WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    plan.nano_slug,
  );
  const handleBack = () => {
    history.push(trackPath);
  };

  const handleViewChange = (e: any) => {
    let presentPath =
      e.target.value === 'dashboard' ? WORKSPACE_PLAN_PREZ_DASHBOARD_ROUTE : WORKSPACE_PLAN_PREZ_ROOT_ROUTE;
    presentPath = presentPath.replace(':workspaceSlug', workspaceSlug).replace(':planId', plan.nano_slug);
    history.push(presentPath);
  };

  const handleToggleDarkMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setIsDarkMode) {
      setIsDarkMode(e.target.checked);
    }
  };

  return (
    <Wrapper>
      <HeaderSection className={className}>
        <Link to={trackPath}>
          <PlanIconLabel plan={plan} />
        </Link>
        <PlanStatus plan={plan} />
      </HeaderSection>
      <HeaderSection>
        {handleDownload && (
          <KoalaButton onClick={handleDownload} appearance="secondary">
            {t('workspacePlans.options.downloadPDF')}
          </KoalaButton>
        )}
        {isDashboard && (
          <DarkModeSection className={className}>
            <p>{t('publicPlan.darkMode')}</p>

            <KoalaToggleButton
              id="activate-dark-mode"
              handleChange={handleToggleDarkMode}
              size="small"
              defaultChecked={isDarkMode}
            />
          </DarkModeSection>
        )}
        <KoalaButton onClick={handleShare}>{t('shared.share')}</KoalaButton>
        <RadioField className="view-selection">
          <div className="radio-group icons-only ">
            <>
              <input
                type="radio"
                id="compact"
                checked={!isDashboard}
                name="view"
                value={'presentation'}
                onChange={handleViewChange}
              />
              <label htmlFor="compact" className="light larger-icon" data-tip data-for="presentation-view">
                <KoalaIcon iconName="present" />
              </label>
            </>
            <>
              <input
                type="radio"
                id="tree"
                checked={isDashboard}
                name="view"
                value={'dashboard'}
                onChange={handleViewChange}
              />
              <label htmlFor="tree" className="light larger-icon" data-tip data-for="dashboard-view">
                <KoalaIcon iconName="tv" />
              </label>
            </>
          </div>
          <ReactTooltip place="bottom" type="dark" id={`presentation-view`} className="tooltip" effect="solid">
            {t('publicPlan.presentation')}
          </ReactTooltip>
          <ReactTooltip place="bottom" type="dark" id={`dashboard-view`} className="tooltip" effect="solid">
            {t('publicPlan.tv')}
          </ReactTooltip>
        </RadioField>
        <KoalaIconButton iconName="close" onClick={handleBack} className={className} />
      </HeaderSection>
    </Wrapper>
  );
}

export default PlanNav;
