import React from 'react';
import theme from 'theme';

export default (props: any) => (
  <g
    id="Icon-dump"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <g
      id="message-circle-copy-2"
      transform="translate(1.000000, 1.000000)"
      stroke={theme.colors.subtleText}
      strokeWidth="2"
    >
      <path
        d="M18.0000285,8.5 C18.0034413,9.81986781 17.6950689,11.1218846 17.1,12.3 C15.6609591,15.1793278 12.7189075,16.9987544 9.5,17 C8.18013219,17.0034413 6.87811542,16.6950689 5.7,16.1 L0,18 L1.9,12.3 C1.30493114,11.1218846 0.996558748,9.81986781 1,8.5 C1.00124556,5.28109249 2.82067222,2.33904087 5.7,0.9 C6.87811542,0.30493114 8.18013219,-0.00344125221 9.5,-2.84881021e-05 L10,-2.84881021e-05 C14.3158094,0.238099691 17.7619003,3.68419058 18.0000285,8 L18.0000285,8.5 L18.0000285,8.5 Z"
        id="Shape"
      />
      <text fontSize={16} style={{ fill: theme.colors.subtleText, stroke: 'none', fontWeight: 600 }} x={22} y={14}>
        {props.comments_count}
      </text>
    </g>
  </g>
);
