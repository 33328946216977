import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextBadge from 'koala/components/TextBadge';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
const ShortcutSection = styled.div`
  padding-bottom: ${theme.spacing.x2};
  h3 {
    margin-bottom: ${theme.spacing.half};
  }
`;

const Row = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
  padding: ${theme.spacing.half} 0px;
`;
const Controls = styled.div`
  display: flex;
  gap: ${theme.spacing.half};
`;

function WorkspaceShortcuts() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const translationKey = 'modals.workspaceShortcuts';
  const isMac = window.navigator.userAgent.includes('Mac');

  const handleCancel = () => {
    dispatch(setGlobalModalContent(''));
  };
  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton iconName="close" onClick={handleCancel} />
      </ModalHeader>
      <ModalContent>
        <ShortcutSection>
          <h3>{t(`${translationKey}.global`)}</h3>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">?</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.shortcuts`)}
          </Row>
          <Row>
            <Controls>
              {isMac ? (
                <KoalaTextBadge variant="neutral-outlined">⌘</KoalaTextBadge>
              ) : (
                <KoalaTextBadge variant="neutral-outlined">Ctrl</KoalaTextBadge>
              )}
              +<KoalaTextBadge variant="neutral-outlined">K</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.search`)}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">G</KoalaTextBadge>+
              <KoalaTextBadge variant="neutral-outlined">H</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.home`)}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">G</KoalaTextBadge>+
              <KoalaTextBadge variant="neutral-outlined">P</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.plans`)}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">G</KoalaTextBadge>+
              <KoalaTextBadge variant="neutral-outlined">M</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.map`)}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">G</KoalaTextBadge>+
              <KoalaTextBadge variant="neutral-outlined">F</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.filters`)}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">G</KoalaTextBadge>+
              <KoalaTextBadge variant="neutral-outlined">U</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.people`)}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">G</KoalaTextBadge>+
              <KoalaTextBadge variant="neutral-outlined">T</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.teams`)}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">G</KoalaTextBadge>+
              <KoalaTextBadge variant="neutral-outlined">S</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.standups`)}
          </Row>
        </ShortcutSection>
        <ShortcutSection>
          <h3>{t(`${translationKey}.outcome`, { outcome: translate(workspace, CustomTermKey.OUTCOME, 1) })}</h3>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">K</KoalaTextBadge>/
              <KoalaTextBadge variant="neutral-outlined">J</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.navigate`)}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">C</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.createCheckin`)}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">E</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.editOutcome`, {
              outcome: translate(workspace, CustomTermKey.OUTCOME, 1).toLowerCase(),
            })}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">I</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.createInitiative`, {
              initiative: translate(workspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
            })}
          </Row>
        </ShortcutSection>
        <ShortcutSection>
          <h3>
            {t(`${translationKey}.initiative`, { initiative: translate(workspace, CustomTermKey.INITIATIVE, 1) })}
          </h3>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">C</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.createStatus`)}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">E</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.editInitiative`, {
              initiative: translate(workspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
            })}
          </Row>
        </ShortcutSection>
        <ShortcutSection>
          <h3>{t(`${translationKey}.plan`)}</h3>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">P</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.presentation`)}
          </Row>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">E</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.editPlan`)}
          </Row>
        </ShortcutSection>
        <ShortcutSection>
          <h3>{t(`${translationKey}.standupsSection`)}</h3>
          <Row>
            <Controls>
              <KoalaTextBadge variant="neutral-outlined">C</KoalaTextBadge>
            </Controls>
            {t(`${translationKey}.standupCreate`)}
          </Row>
        </ShortcutSection>
      </ModalContent>
    </ModalGrid>
  );
}

export default WorkspaceShortcuts;
