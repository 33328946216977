import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import useAutoLayout from 'utils/useAutoLayout';
import theme from 'theme';
import { useTranslation } from 'react-i18next';

import ReactFlow, {
  Background,
  useReactFlow,
  Position,
  Node,
  Edge,
  OnNodesChange,
  OnEdgesChange,
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
} from 'reactflow';
import 'reactflow/dist/style.css';

// API
import OutcomeNode from 'components/OutcomeNode';

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const MapInfo = styled.div`
  position: absolute;
  top: ${theme.spacing.x1};
  left: ${theme.spacing.x2};
  font-size: 1rem;
  color: ${theme.colors.subtleText};
`;

const nodeTypes = {
  outcome: OutcomeNode,
};

interface Props {
  outcome: TabilityTypes.Outcome;
}

function Map(props: Props) {
  const { t } = useTranslation();
  const { outcome } = props;
  const position = { x: 0, y: 0 };
  const { fitView } = useReactFlow();

  // Auto layout of items when they're added
  useAutoLayout({ direction: 'TB' });

  const initialNodes = [
    {
      id: outcome.nano_slug,
      type: 'outcome',
      data: { outcome, width: 400, height: 200, defaultExpanded: true },
      position,
      sourcePosition: Position.Bottom,
      targetPosition: Position.Top,
    },
  ];

  const [nodes, setNodes] = useState<Node[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>([]);

  // This function filters the nodes to avoid duplicate
  // This works because findIndex always returns the first element found in an array
  // See https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
  const filteredNodes = nodes.filter(
    (node, index, self) => index === self.findIndex((reference) => reference.id === node.id),
  );

  // This function filters the edges to avoid duplicate (see above)
  const filteredEdges = edges.filter(
    (edge, index, self) => index === self.findIndex((reference) => reference.id === edge.id),
  );

  const onNodesChange: OnNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), []);
  const onEdgesChange: OnEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);

  useEffect(() => {
    fitView({ duration: 400, maxZoom: 0.7 });
  }, [nodes, fitView]);

  return (
    <Container>
      <MapInfo>
        {t('panels.outcomeMap.info')} -{' '}
        <a
          href="https://guides.tability.app/docs/features/outcomes-key-results/cascading-map"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('shared.learnMore').toLowerCase()}
        </a>
      </MapInfo>
      <ReactFlow
        nodes={filteredNodes}
        edges={filteredEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
      >
        <Background color={theme.colors.N20} size={2} />
        <Controls />
      </ReactFlow>
    </Container>
  );
}

export default React.memo(Map);
