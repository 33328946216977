import React from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { parseISO, startOfWeek, subWeeks, addWeeks, addMonths, startOfMonth, format } from 'date-fns';
import { getLocaleMonth } from 'utils/dateUtils';
// Translations
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

// API and State
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';

import Loader from 'components/Loader';

import {
  VictoryTooltip,
  VictoryLabel,
  VictoryAxis,
  VictoryVoronoiContainer,
  VictoryChart,
  VictoryHistogram,
} from 'victory';

const LabelTooltip = () => {
  return (
    <VictoryTooltip
      cornerRadius={4}
      flyoutStyle={{
        fill: 'rgba(255, 255, 255, 1)',
        stroke: theme.colors.blockBorder,
      }}
      dy={-12}
      flyoutPadding={{ top: 10, bottom: 10, left: 15, right: 15 }}
      constrainToVisibleArea
      style={[
        {
          fontWeight: '900',
          textTransform: 'uppercase',
          fill: theme.colors.subtleText,
          fontSize: '10px',
          fontFamily: theme.font.fontFamily,
          marginBottom: 0,
        },
        {
          fontWeight: '600',
          fill: theme.colors.black,
          fontSize: '12px',
          fontFamily: theme.font.fontFamily,
        },
      ]}
      labelComponent={<VictoryLabel lineHeight={1.4} />}
    />
  );
};

const Card = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  align-items: center;
  background: ${theme.colors.N0};

  h4 {
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;
const ActivityWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${theme.spacing.x2};
`;
const GraphContainer = styled.div`
  max-width: 100%;
  max-height: 15rem;
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

interface CheckinActivity {
  checkins: number;
  week: string;
}

interface ViewActivity {
  views: number;
  week: string;
}

interface InitiativeActivity {
  initiative_comments: number;
  week: string;
}

const staleTime = 60 * 1000 * 5;

function Component() {
  const { t, i18n } = useTranslation();
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  const queryKeyCheckins = [queryKeys.currentWorkspace, currentWorkspace.slug, 'checkins_activity'];
  const { isFetching: isFetchinCheckins, data: checkinsResponse } = useQuery(
    queryKeyCheckins,
    remoteApi.fetchWorkspaceCheckinsActivity,
    {
      staleTime,
    },
  );

  const queryKeyViews = [queryKeys.currentWorkspace, currentWorkspace.slug, 'views_activity'];
  const { isFetching: isFetchinViews, data: viewsResponse } = useQuery(
    queryKeyViews,
    remoteApi.fetchWorkspaceViewsActivity,
    {
      staleTime,
    },
  );

  const queryKeyInitiativesCommented = [
    queryKeys.currentWorkspace,
    currentWorkspace.slug,
    'initiatives_commented_activity',
  ];

  const { isFetching: isFetchinInitiativesCommented, data: initiativesCommentedResponse } = useQuery(
    queryKeyInitiativesCommented,
    remoteApi.fetchWorkspaceInitiativesCommentedActivity,
    {
      staleTime,
    },
  );

  const checkinsActivity: CheckinActivity[] = checkinsResponse?.data || [];
  const viewsActivity: ViewActivity[] = viewsResponse?.data || [];
  const initiativesCommentedActivity: InitiativeActivity[] = initiativesCommentedResponse?.data || [];

  interface GraphData {
    x: Date;
  }

  const checkinsData: GraphData[] = [];
  checkinsActivity.forEach((activity) => {
    const checkinsCount = activity.checkins || 0;
    for (let i = 0; i < checkinsCount; i++) {
      checkinsData.push({
        x: parseISO(activity.week),
      });
    }
  });

  const viewsData: GraphData[] = [];
  viewsActivity.forEach((activity) => {
    const viewsCount = activity.views || 0;
    for (let i = 0; i < viewsCount; i++) {
      viewsData.push({
        x: parseISO(activity.week),
      });
    }
  });

  const initiativesData: GraphData[] = [];
  initiativesCommentedActivity.forEach((activity) => {
    const initiativesCommentedCount = activity.initiative_comments || 0;
    for (let i = 0; i < initiativesCommentedCount; i++) {
      initiativesData.push({
        x: parseISO(activity.week),
      });
    }
  });

  const refDate = Date.now();
  const bins: Date[] = [];

  // Create the bins for the VictoryHistogram component.
  // This loop will create 24 bins for weeks going from W-24 to last week
  for (let i = 24; i >= 0; i--) {
    const bin = subWeeks(startOfWeek(refDate), i);
    bins.push(bin);
  }

  // Add an extra bin as the startOfWeek of refDate might not capture the latest data
  const lastBin = addWeeks(startOfWeek(refDate), 1);
  bins.push(lastBin);

  // get months for the axis
  const firstBin = startOfMonth(subWeeks(startOfWeek(refDate), 24));
  const months: Date[] = [
    addMonths(firstBin, 1),
    addMonths(firstBin, 2),
    addMonths(firstBin, 3),
    addMonths(firstBin, 4),
    addMonths(firstBin, 5),
    addMonths(firstBin, 6),
  ];

  return (
    <>
      <h3>{t('workspaceInsights.recentActivityHeader')}</h3>
      <ActivityWrapper>
        <Card>
          <h4>{t('workspaceInsights.checkinsActivityHeader')}</h4>
          {isFetchinCheckins && <Loader />}
          {!isFetchinCheckins && checkinsData.length > 0 && (
            <GraphContainer>
              <VictoryChart
                containerComponent={<VictoryVoronoiContainer />}
                width={330}
                height={150}
                padding={{ top: 20, bottom: 25, left: 0, right: 15 }}
              >
                <VictoryHistogram
                  data={checkinsData}
                  bins={bins}
                  cornerRadius={4}
                  binSpacing={2}
                  labels={({ datum }: any) => {
                    return [format(datum.x, 'd MMM yyyy'), datum.y];
                  }}
                  labelComponent={LabelTooltip()}
                  style={{
                    data: {
                      fill: theme.colors.B50,
                      strokeWidth: 0,
                      width: 25,
                    },
                    labels: {
                      fill: theme.colors.subtleText,
                      fontSize: 10,
                    },
                  }}
                />
                <VictoryAxis
                  tickValues={months}
                  tickCount={months.length}
                  tickFormat={(x) => {
                    return getLocaleMonth(x, i18n);
                  }}
                  style={{
                    axis: { stroke: theme.colors.blockBorder },
                    tickLabels: {
                      fill: theme.colors.subtleText,
                      fontSize: 12,
                    },
                  }}
                />
              </VictoryChart>
            </GraphContainer>
          )}
          {!isFetchinCheckins && checkinsData.length === 0 && (
            <EmptyState>
              <small className="subtle">{t('workspaceInsights.noActivity')}</small>
            </EmptyState>
          )}
        </Card>
        <Card>
          <h4>
            {t('workspaceInsights.viewsActivityHeader', {
              label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLocaleLowerCase(),
            })}
          </h4>
          {isFetchinViews && <Loader />}
          {!isFetchinViews && viewsData.length > 0 && (
            <GraphContainer>
              <VictoryChart
                containerComponent={<VictoryVoronoiContainer />}
                width={330}
                height={150}
                padding={{ top: 20, bottom: 25, left: 0, right: 15 }}
              >
                <VictoryHistogram
                  data={viewsData}
                  bins={bins}
                  labels={({ datum }: any) => {
                    return [format(datum.x, 'd MMM yyyy'), datum.y];
                  }}
                  labelComponent={LabelTooltip()}
                  cornerRadius={4}
                  binSpacing={2}
                  style={{
                    data: {
                      fill: theme.colors.B50,
                      strokeWidth: 0,
                      width: 25,
                    },
                    labels: {
                      fill: theme.colors.subtleText,
                      fontSize: 10,
                    },
                  }}
                />
                <VictoryAxis
                  tickValues={months}
                  tickCount={months.length}
                  tickFormat={(x) => {
                    return getLocaleMonth(x, i18n);
                  }}
                  style={{
                    axis: { stroke: theme.colors.blockBorder },
                    tickLabels: {
                      fill: theme.colors.subtleText,
                      fontSize: 12,
                    },
                  }}
                />
              </VictoryChart>
            </GraphContainer>
          )}
          {!isFetchinViews && viewsData.length === 0 && (
            <EmptyState>
              <small className="subtle">{t('workspaceInsights.noActivity')}</small>
            </EmptyState>
          )}
        </Card>
        <Card>
          <h4>{t('workspaceInsights.initiativesActivityHeader')}</h4>
          {isFetchinInitiativesCommented && <Loader />}
          {!isFetchinInitiativesCommented && initiativesData.length > 0 && (
            <GraphContainer>
              <VictoryChart
                containerComponent={<VictoryVoronoiContainer />}
                width={330}
                height={150}
                padding={{ top: 20, bottom: 25, left: 0, right: 15 }}
              >
                <VictoryHistogram
                  data={initiativesData}
                  bins={bins}
                  labels={({ datum }: any) => {
                    return [format(datum.x, 'd MMM yyyy'), datum.y];
                  }}
                  labelComponent={LabelTooltip()}
                  cornerRadius={4}
                  binSpacing={2}
                  style={{
                    data: {
                      fill: theme.colors.B50,
                      strokeWidth: 0,
                      width: 25,
                    },
                    labels: {
                      fill: theme.colors.subtleText,
                      fontSize: 10,
                    },
                  }}
                />
                <VictoryAxis
                  tickValues={months}
                  tickCount={months.length}
                  tickFormat={(x) => {
                    return getLocaleMonth(x, i18n);
                  }}
                  style={{
                    axis: { stroke: theme.colors.blockBorder },
                    tickLabels: {
                      fill: theme.colors.subtleText,
                      fontSize: 12,
                    },
                  }}
                />
              </VictoryChart>
            </GraphContainer>
          )}
          {!isFetchinInitiativesCommented && initiativesData.length === 0 && (
            <EmptyState>
              <small className="subtle">{t('workspaceInsights.noActivity')}</small>
            </EmptyState>
          )}
        </Card>
      </ActivityWrapper>
    </>
  );
}

export default Component;
