import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 3.92171C3.94772 3.92171 3.5 4.36942 3.5 4.92171V12.0988C3.5 12.6511 3.94772 13.0988 4.5 13.0988H7H8V14.0988L8 16.0743L10.9066 13.367L11.1946 13.0988H11.5882H15.5C16.0523 13.0988 16.5 12.6511 16.5 12.0988V4.92171C16.5 4.36942 16.0523 3.92171 15.5 3.92171H4.5ZM1.5 4.92171C1.5 3.26485 2.84315 1.92171 4.5 1.92171H15.5C17.1569 1.92171 18.5 3.26485 18.5 4.92171V12.0988C18.5 13.7557 17.1569 15.0988 15.5 15.0988H11.9818L9.36314 17.5378C8.0842 18.7291 6 17.8221 6 16.0743V15.0988H4.5C2.84315 15.0988 1.5 13.7557 1.5 12.0988V4.92171ZM5 9.92078C5 9.36849 5.44772 8.92078 6 8.92078H12.0156C12.5679 8.92078 13.0156 9.36849 13.0156 9.92078C13.0156 10.4731 12.5679 10.9208 12.0156 10.9208H6C5.44772 10.9208 5 10.4731 5 9.92078ZM6 5.72937C5.44772 5.72937 5 6.17708 5 6.72937C5 7.28165 5.44772 7.72937 6 7.72937H14C14.5523 7.72937 15 7.28165 15 6.72937C15 6.17708 14.5523 5.72937 14 5.72937H6Z"
      fill="#737373"
    />
  </svg>
);
