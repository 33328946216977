import { ColorResult, Sketch, SwatchPresetColor } from '@uiw/react-color';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Wrapper, Menu } from 'react-aria-menubutton';
import { colors, spacing } from 'koala/shared/styles';

const Container = styled.div`
  cursor: pointer;
`;
const InputContainer = styled.div`
  background: ${colors.N0};
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: ${spacing.x1};
  border: 1px solid #949494;
  width: 13rem;

  .subtle {
    color: ${colors.N50};
  }
`;

const Color = styled.div<{ color: string }>`
  width: 3.2rem;
  height: 3.2rem;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: ${(props) => `${props.color}`};
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.half};

  input {
    border: none;
    padding: 0;
    width: 7rem;
  }
`;

const StyledWrapper = styled(Wrapper)`
  display: inline-block;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: 400;
`;

const StyledMenu = styled(Menu)`
  position: absolute;
  display: block;
  z-index: 1;
  margin-top: ${spacing.half};
`;

interface ColorBlockProps {
  /** default color for the block */
  defaultColor: string;
  /** selected color for the block */
  selectedColor: string | null;
  /** placeholder for the input */
  placeholder?: string;
  handleSetColor: (value?: string) => void;
  /** Optional className for ColorBlock */
  className?: string;
  /** Optional styling for ColorBlock */
  style?: React.CSSProperties;
}

function KoalaColorPicker(props: ColorBlockProps) {
  const { defaultColor, selectedColor, handleSetColor } = props;
  const [newColor, setNewColor] = useState(selectedColor);

  const handlePickerChange = (color: ColorResult) => {
    setNewColor(color.hex);
    handleSetColor(color.hex);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const triggerButton = (
    <>
      <InputContainer>
        <Color color={newColor ?? defaultColor} />
        <TextContainer>
          <input value={newColor ?? ''} placeholder={newColor ?? defaultColor} maxLength={7} readOnly={true} />
        </TextContainer>
      </InputContainer>
    </>
  );

  const presetColors: SwatchPresetColor[] = [
    { color: '#2e6a6e' },
    { color: '#0a5055' },
    { color: '#0d383b' },
    { color: colors.B20 },
    { color: colors.B60 },
    { color: colors.R20 },
    { color: colors.R60 },
    { color: colors.V20 },
    { color: colors.V60 },
    { color: colors.G20 },
    { color: colors.G60 },
    { color: colors.T20 },
    { color: colors.T60 },
    { color: colors.Y20 },
    { color: colors.Y60 },
    { color: colors.O20 },
    { color: colors.O60 },
    { color: colors.N0 },
    { color: colors.N20 },
    { color: colors.N40 },
    { color: colors.N60 },
    { color: colors.N80 },
    { color: colors.N100 },
  ];

  return (
    <Container>
      <StyledWrapper onClick={handleClick} className="styled-wrapper">
        <Button className={`AriaMenuButton-trigger`}>{triggerButton}</Button>
        <StyledMenu className="AriaMenuButton-menu">
          <Sketch color={newColor ?? defaultColor} onChange={handlePickerChange} presetColors={presetColors} />
        </StyledMenu>
      </StyledWrapper>
    </Container>
  );
}

KoalaColorPicker.defaultProps = {
  defaultColor: colors.N50,
};

export default KoalaColorPicker;
