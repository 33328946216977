import React from 'react';

const LabelCalendarIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0C4.55228 0 5 0.447715 5 1V3C5 3.55228 4.55228 4 4 4C3.44772 4 3 3.55228 3 3V1C3 0.447715 3.44772 0 4 0ZM8 0C8.55229 0 9 0.447715 9 1V3C9 3.55228 8.55229 4 8 4C7.44772 4 7 3.55228 7 3V1C7 0.447715 7.44772 0 8 0ZM0 4C0 2.89543 0.895431 2 2 2H10C11.1046 2 12 2.89543 12 4V5V10C12 11.1046 11.1046 12 10 12H2C0.895431 12 0 11.1046 0 10V5V4ZM10 10V6H2V10H10Z"
      fill="#737373"
    />
  </svg>
);
export default LabelCalendarIcon;
