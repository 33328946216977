import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useLocation, useHistory } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';

// Components
import { PanelGrid, PanelHeader, PanelContent } from 'components/GlobalPanel';
import ModalContent from './ModalContent';
import Loader from 'components/Loader';

import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

interface Props {
  outcomeId: string;
}

function EditOutcomePanel(props: Props) {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const { outcomeId } = props;

  const currentMembership: TabilityTypes.Membership = useSelector(
    (state: any) => state.session.currentMembership,
    shallowEqual,
  );
  const currentUser: TabilityTypes.User = useSelector((state: any) => state.session.currentUser, shallowEqual);

  // Adds the current user to the membership to be able to display the user in the panel
  currentMembership.user = currentUser;

  // Params that will be used for the form
  const [, setRemoteCheckinsEnabled] = useState(false);

  // React to the ESC key to hide the panel
  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.push(location.pathname);
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = 0;

  // Get the outcome details
  const { data: outcomeResponse }: any = useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
    onSuccess: (response) => {
      // Set the params on success
      const outcome = response.data;
      setRemoteCheckinsEnabled(outcome.remote_checkins_enabled);
    },
  });

  const outcome = outcomeResponse ? outcomeResponse.data : null;

  const handleCancel = (e: any) => {
    e.preventDefault();
    history.goBack();
  };

  // Now we can display the app with the Chrome
  if (!outcome) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>{t('panels.automateTitle')}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        <ModalContent outcome={outcome} />
      </PanelContent>
    </PanelGrid>
  );
}

export default React.memo(EditOutcomePanel);
