import React from 'react';

const TooltipIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM6 3.62664C5.7932 3.62664 5.60617 3.70276 5.46231 3.82971C5.29208 3.97993 5.18668 4.19714 5.18668 4.43996C5.18668 4.88915 4.82254 5.25329 4.37336 5.25329C3.92417 5.25329 3.56004 4.88915 3.56004 4.43996C3.56004 3.71102 3.88072 3.05596 4.38602 2.61005C4.81563 2.23094 5.38184 2 6 2C7.34756 2 8.43997 3.09241 8.43997 4.43996C8.43997 5.36476 7.92516 6.16813 7.17036 6.58126C7.02873 6.65878 6.92197 6.74298 6.85957 6.81574C6.82716 6.85353 6.81642 6.87607 6.81332 6.88487C6.81061 7.33173 6.44752 7.69315 6.00002 7.69315C5.55084 7.69316 5.1867 7.32903 5.18669 6.87984C5.18668 6.40784 5.39224 6.02798 5.62483 5.75679C5.85386 5.48973 6.13702 5.29249 6.38937 5.15437C6.64376 5.01513 6.81332 4.74676 6.81332 4.43996C6.81332 3.99078 6.44919 3.62664 6 3.62664ZM6.81334 9.18668C6.81334 9.63586 6.44921 10 6.00002 10C5.55084 10 5.1867 9.63586 5.1867 9.18668C5.1867 8.73749 5.55084 8.37336 6.00002 8.37336C6.44921 8.37336 6.81334 8.73749 6.81334 9.18668Z"
      fill="#737373"
    />
  </svg>
);
export default TooltipIcon;
