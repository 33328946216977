// This components can be used anytime you want to also have mentions.
import React, { useRef } from 'react';
import request from 'state/utils/request';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';

import KoalaSelect from 'koala/components/Select';
import { Team } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
  value: any;
  handleChange: any;
}
function TeamFilter(props: Props) {
  const { value, handleChange } = props;
  const { t } = useTranslation();

  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const debouncedLoadOptions: any = useRef(
    _.debounce((inputValue: string, callback: any) => loadOptions(inputValue, callback), 200, {
      maxWait: 2000,
    }),
  );

  // This function is going to fetch the list of membership using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  const loadOptions: any = (inputValue: string, callback: any) => {
    // Get default users if nothing selected yet
    const teamsApiURL = remoteApi.TEAMS_API.replace(':workspaceSlug', workspaceSlug || '');
    if (!inputValue) {
      const teamsParams = {
        per_page: 10,
      };
      request
        .get(teamsApiURL, { params: teamsParams })
        .then((response) => {
          const teams = response.data;
          if (teams && teams.length > 0) {
            const options = teams.map((team: Team) => {
              return {
                value: team.id,
                label: team.name,
              };
            });
            return options;
          } else {
            return [];
          }
        })
        .then(callback);
      return;
    }

    // If we have a search param, use it
    const teamsParams = inputValue
      ? {
          name: inputValue,
          per_page: 10,
        }
      : null;
    request
      .get(teamsApiURL, { params: teamsParams })
      .then((response) => {
        const teams = response.data;
        if (teams && teams.length > 0) {
          const options = teams.map((team: Team) => {
            return {
              value: team.id,
              label: team.name,
            };
          });
          return options;
        } else {
          return [];
        }
      })
      .then(callback);
  };

  return (
    <KoalaSelect
      isMultiSelect
      isClearable
      handleChange={(options) => handleChange(options)}
      placeholder={t('workspacePlans.teamFilterPlaceholder') ?? 'Filter plans by team'}
      selectedOption={value}
      loadOptions={debouncedLoadOptions.current}
      id="team"
    />
  );
}

export default React.memo(TeamFilter);
