import createReducer from './createReducer';
import * as actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  isAuthenticated: false,
  isInitialized: false,
  isEmptyWorkspace: false,
  currentUser: null,
  accessToken: null,
  currentMembership: null,
  currentWorkspace: null,
  currentWorkspaceSettings: null,
  currentPing: null,
  refreshAttempts: 0,
  referenceTemplate: null,
  currentIntegrations: [],
  workspaceMemberships: [],
  redirectLink: null, // Redirect link that is set if the user is not authenticated
  forceLogout: false, // Force Logout is used to bypass the redirectLink capture
};

const INITIAL_STATE_INITIALIZED = {
  ...INITIAL_STATE,
  isInitialized: true,
};

const sessionReducer = createReducer(INITIAL_STATE)({
  [actionTypes.SET_IS_EMPTY_WORKSPACE]: (state: any, action: any) => {
    const { isEmptyWorkspace } = action;
    return {
      ...state,
      isEmptyWorkspace,
    };
  },
  [actionTypes.SET_REFERENCE_TEMPLATE]: (state: any, action: any) => {
    const { referenceTemplate } = action;
    return {
      ...state,
      referenceTemplate,
    };
  },
  [actionTypes.SET_CURRENT_USER]: (state: any, action: any) => {
    const { currentUser } = action;
    return {
      ...state,
      currentUser,
    };
  },
  [actionTypes.SET_CURRENT_MEMBERSHIP]: (state: any, action: any) => {
    const { currentMembership } = action;
    return {
      ...state,
      currentMembership,
    };
  },
  [actionTypes.SET_CURRENT_WORKSPACE]: (state: any, action: any) => {
    const { currentWorkspace } = action;
    const isEmptyWorkspace = currentWorkspace.plans_count === 0;
    return {
      ...state,
      currentWorkspace,
      isEmptyWorkspace,
    };
  },
  [actionTypes.SET_CURRENT_WORKSPACE_SETTINGS]: (state: any, action: any) => {
    const { currentWorkspaceSettings } = action;
    return {
      ...state,
      currentWorkspaceSettings,
    };
  },
  [actionTypes.SET_CURRENT_PING]: (state: any, action: any) => {
    const { currentPing } = action;
    return {
      ...state,
      currentPing,
    };
  },
  [actionTypes.SET_ACCESS_TOKEN]: (state: any, action: any) => {
    const { accessToken } = action;
    return {
      ...state,
      accessToken,
    };
  },
  [actionTypes.SET_CURRENT_INTEGRATIONS]: (state: any, action: any) => {
    const { currentIntegrations } = action;
    return {
      ...state,
      currentIntegrations,
    };
  },
  [actionTypes.SET_WORKSPACE_MEMBERSHIPS]: (state: any, action: any) => {
    const { workspaceMemberships } = action;
    return {
      ...state,
      workspaceMemberships,
    };
  },
  [actionTypes.SET_REDIRECT_LINK]: (state: any, action: any) => {
    const { redirectLink } = action;
    return {
      ...state,
      redirectLink,
    };
  },
  // When we load the initial plan we set the current plan ID for reference.
  [actionTypes.LOGIN_COMPLETED]: (state: any, action: any) => {
    const { user, access_token } = action.response.data;
    return {
      ...state,
      refreshAttempts: 0,
      isAuthenticated: true,
      currentUser: user,
      accessToken: access_token,
    };
  },
  // When we load the initial plan we set the current plan ID for reference.
  [actionTypes.SAVE_NEW_PASSWORD_COMPLETED]: (state: any) => {
    return {
      ...state,
      refreshAttempts: 0,
    };
  },
  [actionTypes.CONFIRM_COMPLETED]: (state: any, action: any) => {
    const { user, access_token } = action.response.data;
    return {
      ...state,
      isAuthenticated: true,
      refreshAttempts: 0,
      currentUser: user,
      accessToken: access_token,
    };
  },
  [actionTypes.REFRESH_TOKENS_COMPLETED]: (state: any, action: any) => {
    const { user, access_token } = action.response.data;
    return {
      ...state,
      refreshAttempts: 0,
      isAuthenticated: true,
      isInitialized: true,
      currentUser: user,
      accessToken: access_token,
    };
  },

  // Failures
  [actionTypes.LOGOUT_COMPLETED]: (state: any) => ({
    ...INITIAL_STATE_INITIALIZED,
    forceLogout: true,
  }),
  [actionTypes.LOGIN_FAILED]: (state: any) => INITIAL_STATE_INITIALIZED,
  [actionTypes.SAVE_NEW_PASSWORD_FAILED]: (state: any) => INITIAL_STATE_INITIALIZED,
  [actionTypes.CONFIRM_FAILED]: (state: any) => INITIAL_STATE_INITIALIZED,
  [actionTypes.REFRESH_TOKENS_FAILED]: (state: any) => {
    const refreshAttempts = state.refreshAttempts + 1;

    // Do 2 background refresh attempts before logging you out.
    if (refreshAttempts < 2) {
      return {
        ...state,
        refreshAttempts,
        isInitialized: true,
      };
    }

    return {
      ...state,
      isAuthenticated: false,
      isInitialized: true,
      currentUser: null,
    };
  },
});

export default sessionReducer;
