import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import GoogleButtonIcon from 'components/_assets/google-button-icon.png';
import KoalaButton from 'koala/components/Button';

const ButtonContainer = styled.div`
  display: flex;
  a {
    flex: 1;
    justify-content: center;
  }
`;

interface Props {
  buttonLabel: string;
}
function GoogleSignIn(props: Props) {
  const { buttonLabel } = props;

  // Creating the OAuth URL for Google
  const googleClienttID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
  const googleScope = 'email profile';
  const googleRedirectURL = `https://${process.env.REACT_APP_DOMAIN || ''}/authenticate/google`;
  const googleOauthURL =
    'https://accounts.google.com/o/oauth2/v2/auth' +
    `?scope=${encodeURIComponent(googleScope)}` +
    `&client_id=${googleClienttID}` +
    `&response_type=code` +
    `&prompt=select_account` +
    `&redirect_uri=${encodeURIComponent(googleRedirectURL)}`;

  return (
    <ButtonContainer>
      <Helmet>
        <script src="https://apis.google.com/js/client:platform.js?onload=start" async defer type="text/javascript" />
      </Helmet>
      <KoalaButton href={googleOauthURL} appearance="subtle">
        <img src={GoogleButtonIcon} alt="" className="margin-right" />
        {buttonLabel}
      </KoalaButton>
    </ButtonContainer>
  );
}

export default React.memo(GoogleSignIn);
