import * as actionTypes from './actionTypes';

export const setGlobalPanelContent = (globalPanelContent: string) => ({
  type: actionTypes.SET_GLOBAL_PANEL_CONTENT,
  globalPanelContent,
});

export const setGlobalModalContent = (globalModalContent: string) => ({
  type: actionTypes.SET_GLOBAL_MODAL_CONTENT,
  globalModalContent,
});
