import queryKeys from 'config/queryKeys';
import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import * as remoteApi from 'api/remote';
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import Wordmark from 'components/Wordmark';
import KoalaButton from 'koala/components/Button';
import RetrospectivePage from './RetrospectivePage';
import { ChromeContent } from 'components/Chrome';
import KoalaLoader from 'koala/components/Loader';
import UpgradeIcon from 'koala/shared/images/icons/UpgradeIcon';
import * as routes from 'routes';
import NotFound from 'components/NotFound';
import { Plan, Retrospective, Workspace } from 'types';

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x8};
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const AccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};
  height: 100%;
  align-items: center;
  justify-content: center;
  .actions {
    display: flex;
    gap: ${theme.spacing.x1};
  }
`;

const UpgradeBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
  background: ${theme.colors.V5};
  border-radius: 8px;

  p {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x1};
  }
`;
const PublicHeader = styled.div`
  grid-area: header;
  display: flex;
  width: 100%;
  background-color: ${theme.colors.N100};
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;
const PoweredBySection = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  color: ${theme.colors.N0};
  align-items: center;
  img {
    width: 8rem;
  }
`;
const PublicActions = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};

  .white-btn {
    border-color: #fff;
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const ContentContainer = styled.div`
  grid-area: content;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: block;
`;
const Grid = styled.div`
  display: grid;
  grid-template-areas: 'header' 'content';
  grid-template-rows: auto 1fr;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

function PublicRetrospective() {
  const { planId, workspaceSlug, retrospectiveId } = useParams<{
    planId: string;
    workspaceSlug: string;
    retrospectiveId: string;
  }>();
  const { t } = useTranslation();
  const [upgradeRequired, setUpgradeRequired] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);

  // Get the plan details
  const staleTime = 0;

  // get retrospective details
  const retrospectiveQueryKey = [queryKeys.currentRetrospective, retrospectiveId];
  const { data: retrospectiveResponse, isLoading: retrospectiveIsLoading } = useQuery(
    retrospectiveQueryKey,
    remoteApi.fetchPublicRetrospectiveDetails,
    {
      staleTime,
      onError: (error: any) => {
        const errorString = error.response.data.error;
        const upgradeRequired = error.response.status === 402;
        setUpgradeRequired(upgradeRequired);
        const accessDenied = errorString.includes('Public access');
        setAccessDenied(accessDenied);
      },
    },
  );

  const retrospective: Retrospective = retrospectiveResponse ? retrospectiveResponse.data : null;
  const dataIsLoading = retrospectiveIsLoading;

  const retrospectivePath = routes.WORKSPACE_RETROSPECTIVE_ROUTE.replace(':workspaceSlug', workspaceSlug)
    .replace(':planId', planId)
    .replace(':retrospectiveId', retrospectiveId);

  // Loading state
  if (dataIsLoading) {
    return (
      <Fragment>
        <ChromeContent>
          <LoadingContainer>
            <KoalaLoader size="large" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (upgradeRequired) {
    return (
      <Fragment>
        <Helmet>
          <title>{t('publicPlan.upgrade.title')} | Tability</title>
        </Helmet>
        <AccessContainer>
          <WordmarkWrapper>
            <Wordmark type="dark" />
          </WordmarkWrapper>

          <h2>{t('publicPlan.upgrade.header')}</h2>
          <UpgradeBanner>
            <p>
              <UpgradeIcon />
              <b>{t('publicPlan.upgrade.limited')}</b>
            </p>
            <p>{t('publicPlan.upgrade.upgradeToEnable')}</p>
          </UpgradeBanner>
          <KoalaButton isLink to={retrospectivePath} appearance="subtle">
            {t('publicPlan.viewButton')}
          </KoalaButton>
        </AccessContainer>
      </Fragment>
    );
  }

  if (accessDenied) {
    return (
      <Fragment>
        <Helmet>
          <title>{t('publicRetrospective.restricted.title')} | Tability</title>
        </Helmet>
        <AccessContainer>
          <WordmarkWrapper>
            <Wordmark type="dark" />
          </WordmarkWrapper>

          <h2>{t('publicRetrospective.restricted.header')}</h2>
          <p>{t('publicRetrospective.restricted.info')}</p>
          <p>
            <KoalaButton isLink to={retrospectivePath} appearance="subtle">
              {t('publicPlan.viewButton')}
            </KoalaButton>
          </p>
        </AccessContainer>
      </Fragment>
    );
  }
  const plan: Plan | null = retrospective?.plan ?? null;
  const workspace: Workspace | null = plan?.workspace ?? null;
  if (!retrospective || !plan || !workspace) {
    return <NotFound />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {retrospective.title} | Tability
        </title>
      </Helmet>
      <Grid>
        <PublicHeader>
          <PoweredBySection>
            <span>{t('shared.poweredBy')}</span>
            <Wordmark type="light" />
          </PoweredBySection>
          <PublicActions>
            <KoalaButton isLink to="/login" appearance="subtle" className="white-btn">
              {t('shared.login')}
            </KoalaButton>
            <KoalaButton isLink to="/signup">
              {t('shared.signup')}
            </KoalaButton>
          </PublicActions>
        </PublicHeader>
        <ContentContainer>
          <RetrospectivePage retrospective={retrospective} />
        </ContentContainer>
      </Grid>
    </Fragment>
  );
}

export default PublicRetrospective;
