import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from 'theme';

export default styled(Link)`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;

  .icon {
    height: 3.8rem;
    width: 3.8rem;

    background-color: transparent;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    svg {
      width: 1.8rem;
      height: 1.8rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
  }

  .value {
    margin-left: 0.4rem;
    width: 2rem;
    cursor: pointer;
  }

  &:hover {
    color: ${theme.colors.blue};
    .icon {
      background: #d1d1d1;
    }
  }
`;
