import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  margin: 0 auto;
  max-width: 120rem;
  width: 100%;
  padding: 1.2rem ${theme.spacing.x4};
  margin-bottom: ${theme.spacing.x8};
`;
