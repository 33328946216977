import React from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcome.id, `data_source:hubspot:${outcome.data_source_type}`, 'current'];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null) {
    return <FormField>Sorry, an error occurred while fetching the current value.</FormField>;
  }

  const dataTypeToLabel: any = {
    emails_logged: '<number> emails logged',
    calls_logged: '<number> calls logged',
    meetings_logged: '<number> meetings logged',
    contacts_created: '<number> contacts created',
    deals_created: '<number> deals created',
    deals_won: '<number> deals won',
    deals_lost: '<number> deals lost',
    sum_deals_amount_created: '$<number>',
    sum_deals_amount_won: '$<number>',
    sum_deals_amount_lost: '$<number>',
    avg_deals_amount_created: '$<number> ',
    avg_deals_amount_won: '$<number>',
    avg_deals_amount_lost: '$<number>',
  };

  const label = dataTypeToLabel[outcome.data_source_type || ''];
  const labelWithNumber = label.replace('<number>', currentValue.toLocaleString());
  return (
    <FormField>
      <label>Preview value</label>
      <p>{labelWithNumber}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  setDataSourceType: (dataSourceType: string) => void;
  setDataSourceMeta: (dataSourceMeta: any) => void;
  data_source_type: string;
  data_source_meta: any;
}

function HubspotSource(props: Props) {
  const { outcome, data_source_type, setDataSourceType } = props;

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }]: [any, any] = useMutation(
    remoteApi.updateOutcomeDataSource,
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries(queryKeys.currentOutcome);
      },
    },
  );

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'hubspot',
      data_source_type: data_source_type,
      data_source_meta: {},
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const availableDataSourceTypes = [
    'emails_logged',
    'calls_logged',
    'meetings_logged',
    'contacts_created',
    'deals_created',
    'sum_deals_amount_created',
    'avg_deals_amount_created',
    'deals_won',
    'sum_deals_amount_won',
    'avg_deals_amount_won',
    'deals_lost',
    'sum_deals_amount_lost',
    'avg_deals_amount_lost',
  ];

  return (
    <>
      <FormField>
        <label>Change Metric type</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">Select a metric</option>
          <option value="emails_logged">Number of emails logged</option>
          <option value="calls_logged">Number of calls logged</option>
          <option value="meetings_logged">Number of meetings logged</option>
          <option value="contacts_created">New contacts created</option>
          <option value="deals_created">New deals created</option>
          <option value="deals_won">Number of deals won</option>
          <option value="deals_lost">Number of deals lost</option>
          <option value="sum_deals_amount_created">Total $ amount of new deals created</option>
          <option value="sum_deals_amount_won">Total $ amount of deals won</option>
          <option value="sum_deals_amount_lost">Total amount $ of deals lost</option>
          <option value="avg_deals_amount_created">Average $ amount of new deals created</option>
          <option value="avg_deals_amount_won">Average $ amount of deals won</option>
          <option value="avg_deals_amount_lost">Average amount $ of deals lost</option>
        </select>
      </FormField>
      <FormField>
        <b>
          All metrics are limited to the time period of the plan (ex: number of emails between start date and finish
          date of the plan)
        </b>
      </FormField>
      {outcome.data_source_type !== data_source_type && (
        <FormField>
          <KoalaButton
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={!data_source_type || isUpdating}
            appearance="subtle"
          >
            Save & preview
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'hubspot' &&
        outcome.data_source_type &&
        outcome.data_source_type === data_source_type && (
          <>{availableDataSourceTypes.includes(outcome.data_source_type) && <CurrentValue outcome={outcome} />}</>
        )}
    </>
  );
}

export default React.memo(HubspotSource);
