import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import yaml from 'js-yaml';

import { useParams } from 'react-router-dom';
import QuillBodyRenderer from 'components/QuillBodyRenderer';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaButton from 'koala/components/Button';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';

const Container = styled.div`
  width: 80rem;
  margin: 0 auto;
  padding: ${theme.spacing.x4} 0;
`;

const Field = styled.div`
  label {
    font-weight: 700;
    text-transform: uppercase;
  }
  margin-bottom: ${theme.spacing.x3};
`;

const Content = styled.div`
  margin: ${theme.spacing.x4} 0;
  flex: 1;
`;

const PlanContent = styled.div`
  background: #f3f3f3;
  padding: ${theme.spacing.x3};
  border-radius: 8px;
  margin-bottom: ${theme.spacing.x2};
`;

const LineGrid = styled.div`
  display: grid;
  grid-template-columns: 11rem 1fr;
  grid-template-rows: auto;
  column-gap: ${theme.spacing.x2};
  align-items: center;
  margin-bottom: ${theme.spacing.x1};

  > div {
    display: flex;
    &:first-of-type {
      justify-content: flex-end;
    }

    &:nth-child(2) {
      font-family: monospace, 'Courier New';
    }
  }

  @media ${theme.devices.desktop} {
    grid-template-columns: 7rem 1fr;
    align-items: flex-start;
    column-gap: ${theme.spacing.x2};
    > div {
      &:nth-child(2) {
        font-family: monospace, 'Courier New';
        font-size: 1.4rem;
      }
    }
  }
`;

const Label = styled.div`
  font-size: 1rem;
  height: 2.8rem;
  line-height: 2.8rem;
  letter-spacing: 1px;
  color: #fff;
  background-color: #6096ec;
  border: 0;
  border-radius: 20px;
  padding: 0 1.6rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;

  &.objective {
    background-color: #60aeb9;
  }

  &.outcome {
    background-color: #f5c02b;
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0;
    padding: 0 ${theme.spacing.x2};
  }

  @media ${theme.devices.desktop} {
    height: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: 0px;
    border: 0;
    border-radius: 16px;
    padding: 0 0.8rem;

    &.outcome {
      font-size: 1.2rem;
      padding: 0 ${theme.spacing.x2};
    }
  }
`;

const TaskLabel = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.4rem;
  min-width: 2.8rem;
  min-height: 2.8rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.subtleText};
  color: #fff;
  background-color: #d1d1d1;
  border-radius: 50%;
  font-weight: 800;
  position: relative;

  margin-right: ${theme.spacing.x2};

  @media ${theme.devices.desktop} {
    min-width: 2.4rem;
    min-height: 2.4rem;
    font-size: 1rem;
    border: 0;
    border-radius: 16px;
    padding: 0 0.8rem;
  }
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: ${theme.spacing.x2};
  }
`;

export default function PublicTemplates() {
  const { publicTemplateId } = useParams<{ publicTemplateId: string }>();
  const { t } = useTranslation();
  const queryKeyTemplate = [queryKeys.publicTemplates, publicTemplateId];
  const staleTime = 0;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Get the template2 details
  useQuery(queryKeyTemplate, remoteApi.fetchPublicTemplateDetails, {
    staleTime,
    onSuccess: (response) => setTemplate(response.data),
  });

  const [template, setTemplate]: any = useState(null);

  if (!template) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  let objectives = [];
  try {
    const content: any = yaml.load(template.yaml_content);
    objectives = content.objectives;
  } catch (e) {}

  const tags = template.tags.split(',');

  return (
    <Container>
      <h2>{template.title || t('publicTemplates.untitled')}</h2>

      <Nav>
        <KoalaTextButton isLink to="/public_templates/">
          {t('publicTemplates.back')}
        </KoalaTextButton>
        <KoalaButton isLink to={`/public_templates/${template.nano_slug}/edit`}>
          {t('shared.edit')}
        </KoalaButton>
      </Nav>
      <Content>
        <PlanContent>
          {objectives.map((objective: any) => {
            const outcomes = objective.outcomes || [];
            return (
              <Fragment>
                <LineGrid>
                  <div>
                    <Label className="objective">{translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}</Label>
                  </div>
                  <div>{objective.title}</div>
                </LineGrid>
                {outcomes.map((outcome: any) => {
                  const initiatives = outcome.initiatives || [];
                  return (
                    <Fragment>
                      <LineGrid>
                        <div>
                          <Label className="outcome">#</Label>
                        </div>
                        <div>{outcome.title}</div>
                      </LineGrid>
                      {initiatives.map((initiative: any) => {
                        return (
                          <Fragment>
                            <LineGrid>
                              <div></div>
                              <div>
                                <div>
                                  <TaskLabel>&#10003;</TaskLabel>
                                </div>
                                {initiative.title}
                              </div>
                            </LineGrid>
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
        </PlanContent>
        <Field>
          <label>{t('publicTemplates.titleLabel')}</label>
          <p>{template.title}</p>
        </Field>
        <Field>
          <label>{t('publicTemplates.type')}</label>
          <p>{template.template_type}</p>
        </Field>
        <Field>
          <label>{t('publicTemplates.published')}</label>
          <p>{template.published.toString()}</p>
        </Field>

        <Field>
          <label>{t('publicTemplates.excerpt')}</label>
          <p>{template.excerpt}</p>
        </Field>
        <Field>
          <label>{t('publicTemplates.description')}</label>
          <p>
            <QuillBodyRenderer body={template.description} />
          </p>
        </Field>
        <Field>
          <label>{t('publicTemplates.author')}</label>
          <p>
            {template.author_name} ({template.author_url})
          </p>
        </Field>
        <Field>
          <label>{t('publicTemplates.org')}</label>
          <p>
            {template.org_name} ({template.org_url})
          </p>
        </Field>
        <Field>
          <label>{t('publicTemplates.tags')}</label>
          <p>
            {tags.map((tag: any) => (
              <span>{tag.trim()},</span>
            ))}
          </p>
        </Field>
      </Content>
    </Container>
  );
}
