import React from 'react';

const ClockIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5C10.5523 5 11 5.44772 11 6V9.46481L13.5547 11.168C14.0142 11.4743 14.1384 12.0952 13.832 12.5547C13.5257 13.0142 12.9048 13.1384 12.4453 12.832L9.4453 10.832C9.1671 10.6466 9 10.3344 9 10V6C9 5.44772 9.44772 5 10 5Z"
      fill="#737373"
    />
  </svg>
);
export default ClockIcon;
