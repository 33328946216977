/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as amplitudeUtils from 'utils/amplitude';
import theme from 'theme';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';

// Components
import { Helmet } from 'react-helmet';
import TemplateHeader from 'components/TemplateHeader';
import Loader from 'components/Loader';
import TemplateBlock from 'components/TemplateBlock';

// Components
const Container = styled.div`
  h2 {
    text-align: center;
  }

  @media ${theme.devices.desktop} {
    width: auto;
    .hide-mobile {
      display: none;
    }
  }
`;

const Header = styled.div`
  padding: ${theme.spacing.x3};
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 3.2rem;
  }
  h3 {
    font-weight: 400;
    color: ${theme.colors.N70};
  }
`;

const Wrapper = styled.div`
  max-width: 100rem;
  width: 100rem;
  margin: 0 auto;
  flex: 1;
  display: flex;
  gap: ${theme.spacing.x2};
  padding: ${theme.spacing.x3};

  @media ${theme.devices.desktop} {
    width: auto;
  }
`;

const TemplatesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: ${theme.spacing.x2};
  flex: 1;
`;

function TemplatesHome() {
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.templates.home.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const queryKey = [queryKeys.publicTemplates];
  const staleTime = 0;
  const [public_templates, setPublicTemplates] = useState([]);
  // Get the templates details
  const { isLoading } = useQuery(queryKey, remoteApi.fetchTemplates, {
    staleTime,
    onSuccess: (response) => setPublicTemplates(response.data),
  });

  // Displays the list of templates
  return (
    <Container>
      <Helmet>
        <title>OKRs and goals templates | Tability</title>
      </Helmet>
      <TemplateHeader />
      <Wrapper>
        <div>
          <Header>
            <h1>What's your next plan?</h1>
            <h3>Find inspiring OKRs, playbook and checklists to help you achieve your goals</h3>
          </Header>

          <TemplatesWrapper>
            {public_templates.map((template: any) => {
              return <TemplateBlock key={template.id} template={template} />;
            })}
          </TemplatesWrapper>
          {isLoading && <Loader size="big" />}
        </div>
      </Wrapper>
    </Container>
  );
}

export default TemplatesHome;
