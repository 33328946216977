import React from 'react';

const TextIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00159 3.00159C3.4493 3.00159 3.00159 3.4493 3.00159 4.00159V7.27345C3.00159 7.82573 3.4493 8.27345 4.00159 8.27345C4.55387 8.27345 5.00159 7.82573 5.00159 7.27345V5.00159H8.99999V14.9984H7.27344C6.72116 14.9984 6.27344 15.4461 6.27344 15.9984C6.27344 16.5507 6.72116 16.9984 7.27344 16.9984H9.99999H12.7265C13.2788 16.9984 13.7265 16.5507 13.7265 15.9984C13.7265 15.4461 13.2788 14.9984 12.7265 14.9984H11V5.00159H14.9984V7.27345C14.9984 7.82573 15.4461 8.27345 15.9984 8.27345C16.5507 8.27345 16.9984 7.82573 16.9984 7.27345V4.00159C16.9984 3.4493 16.5507 3.00159 15.9984 3.00159H9.99999H4.00159Z"
      fill="#737373"
    />
  </svg>
);
export default TextIcon;
