import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import MarkdownContent from 'components/MarkdownContent';
import QuillBodyEditor from 'components/QuillBodyEditor';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative } from 'types';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import * as remoteApi from 'api/remote';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const ViewMode = styled.div`
  background: ${theme.colors.N0};
  border-radius: 4px;
  padding: ${theme.spacing.half} ${theme.spacing.x1};

  :hover {
    background: ${theme.colors.N3};
    cursor: text;
  }
  :active,
  :focus {
    background: ${theme.colors.B5};
  }
  &.readonly {
    :hover {
      background: ${theme.colors.N0};
      cursor: initial;
    }
    :active,
    :focus {
      background: ${theme.colors.N0};
    }
  }
  .ql-notes {
    img {
      max-width: 100%;
    }
  }
`;
const EditMode = styled.div`
  .ql-notes {
    margin: 0 0 ${theme.spacing.x1} 0;
    background: ${theme.colors.N0};
  }

  label {
    color: #6a6a6a;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
    display: block;
  }
`;

const EditorActions = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

interface Props {
  initiative: Initiative;
  isReadOnly: boolean;
}

function InitiativeDescription(props: Props) {
  const { initiative, isReadOnly } = props;
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [originalText, setOriginalText] = useState(initiative.body ?? '');
  const [currentText, setCurrentText] = useState(originalText);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  useEffect(() => {
    // if in text editor and click outside, will save progress and switch back to view mode
    function handleClickOutside(event: any) {
      if (showEdit && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowEdit(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEdit, wrapperRef, currentText]);

  // Mutation that will update the description in the backend
  const [updateInitiativeMutation, { isLoading }] = useMutation(remoteApi.updateInitiative, {
    onSuccess: (response) => {
      // Update originalText once saving is confirmed
      setOriginalText(response.data.body);
    },
  });

  const handleTextClicked = () => {
    if (isReadOnly) {
      return;
    }
    setShowEdit(true);
  };

  // Function that updates the description
  const updatDescription = (newBody: string) => {
    const initiativeParams = {
      body: newBody,
    };
    const mutationParams = {
      initiativeId: initiative.id,
      initiative: initiativeParams,
    };
    updateInitiativeMutation(mutationParams);
  };

  // We use a debounce function to avoid saving the text on every character change.
  // This function waits 500ms to see if there's another character changed, and a max of 2s before saving.
  const debounceUpdateDescription = useRef(
    _.debounce((newBody: string) => updatDescription(newBody), 500, {
      maxWait: 2000,
    }),
  );

  useEffect(() => {
    // Make sure that we don't do useless update if text hasn't changed.
    if (currentText !== originalText) {
      debounceUpdateDescription.current(currentText);
    }
  }, [currentText, originalText]);

  const handleSave = (e: any) => {
    e.preventDefault();
    debounceUpdateDescription.current(currentText);
    setShowEdit(false);
  };

  // Strip HTML from text to see if we have an empty field.
  let doc = new DOMParser().parseFromString(originalText, 'text/html');
  const descriptionIsEmpty = !originalText || !doc.body.textContent || doc.body.textContent === '';

  const readonlyClass = isReadOnly ? 'readonly' : '';

  return (
    <>
      {!showEdit && (
        <ViewMode onClick={handleTextClicked} className={readonlyClass}>
          {!descriptionIsEmpty && <MarkdownContent source={originalText} />}
          {descriptionIsEmpty && (
            <p className="subtle">
              <em>
                {t('modals.initiative.updateDescription', {
                  initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
                })}
              </em>
            </p>
          )}
        </ViewMode>
      )}
      {showEdit && (
        <EditMode ref={wrapperRef}>
          <QuillBodyEditor
            value={currentText}
            onChange={(e: string) => {
              setCurrentText(e);
            }}
            disableMentions={true}
            quillClassName="ql-notes"
            allowHeaders={true}
          />
          <EditorActions>
            <KoalaButton onClick={handleSave}>{t('shared.save')}</KoalaButton>
            {isLoading && <KoalaLoader />}
          </EditorActions>
        </EditMode>
      )}
    </>
  );
}

export default InitiativeDescription;
