import React, { Fragment } from 'react';
import queryKeys from 'config/queryKeys';
import { useInfiniteQuery } from 'react-query';
import { Initiative, InitiativeComment } from 'types';
import parse from 'parse-link-header';

import * as remoteApi from 'api/remote';
import styled from 'styled-components';
import theme from 'theme';
import InitiativeCommentBlock from './InitiativeComment';
import { AxiosResponse } from 'axios';
import KoalaButton from 'koala/components/Button';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  height: 100%;

  .ql-display-only {
    padding: 0;
  }
`;

const LoadMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyState = styled.div`
  display: flex;
  margin-top: ${theme.spacing.x2};
  align-items: center;
  justify-content: center;
`;

interface Props {
  initiative: Initiative;
}

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

function InitiativeStatus(props: Props) {
  const { initiative } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const queryKeyInitiativeComments = [queryKeys.initiativeComments, initiative.id];
  const staleTime = queryKeys.staleTime;

  const {
    isFetching,
    canFetchMore,
    fetchMore,
    isFetchingMore,
    data: initiativeCommentsResponse,
  } = useInfiniteQuery(queryKeyInitiativeComments, remoteApi.fetchInitiativeComments, {
    staleTime,
    getFetchMore: (lastGroup) => {
      return getNextPage(lastGroup);
    },
  });

  let initiativeComments: AxiosResponse<InitiativeComment[]>[] = [];
  if (initiativeCommentsResponse) {
    initiativeComments = initiativeCommentsResponse;
  }

  const noResultsFound = initiativeComments.length > 0 && initiativeComments[0].data.length === 0;
  if (noResultsFound) {
    return (
      <EmptyState>
        <p className="subtle">
          {t('modals.initiative.empty', {
            label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
          })}
        </p>
      </EmptyState>
    );
  }

  return (
    <Container>
      {initiativeComments.map((group, i) => {
        return (
          <Fragment key={i}>
            {group.data.map((comment, index) => {
              let isSetState = true;
              if (index < group.data.length - 1) {
                isSetState = comment.work_state !== group.data[index + 1].work_state;
              }
              return <InitiativeCommentBlock comment={comment} isSetNewState={isSetState} key={comment.id} />;
            })}
          </Fragment>
        );
      })}
      {canFetchMore && (
        <LoadMore>
          <KoalaButton onClick={() => fetchMore()} appearance="secondary" loading={isFetching}>
            {t('shared.loadMore')}
          </KoalaButton>
          {isFetchingMore && <Loader size="small" />}
        </LoadMore>
      )}
    </Container>
  );
}

export default InitiativeStatus;
