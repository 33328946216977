/**
  This is the playground editor. It loads the state from local storage and saves there too
 */

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as TabilityTypes from 'types';
import * as planUtils from 'utils/planUtils';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Routes
import * as routes from 'routes';

// Components
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import PlanTitle from './PlanTitle';
import DropdownMenu from 'components/DropdownMenu';
import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';
import PlanIconSelector from './PlanIconSelector';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .menu-title {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: ${theme.spacing.x1};
    width: 100%;
    position: relative;
  }
  @media ${theme.devices.laptop} {
    .text-badge {
      display: none;
    }
  }
`;

const WrapperLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 38rem;
  grid-template-rows: 4rem;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${theme.spacing.x2} 1.2rem;

  .settings-nav {
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacing.x1};
    .monitor-icon {
      svg {
        height: 1.4rem;
        margin-left: ${theme.spacing.x1};
      }
      rect,
      path {
        fill: #fff;
      }
    }
  }

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr 25rem;

    .hide-tablet {
      display: none;
    }
  }
  @media ${theme.devices.mobile} {
    grid-template-columns: 1fr 17rem;
    .hide-mobile {
      display: none;
    }
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
}

function PlanNav(props: Props) {
  const dispatch = useDispatch();
  const { planId, workspaceSlug } = useParams<{ planId: string; workspaceSlug: string }>();
  const { plan } = props;
  const history = useHistory();
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);

  const blockId = `plan:${plan.id}`;

  const handleEdit = () => {
    const route = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId);
    history.push(route);
  };

  const handleConfigure = () => {
    history.push(
      routes.WORKSPACE_PLAN_SETTINGS_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId),
    );
  };

  const handleEditPermissions = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const nanoBlockId = `plan:${plan.nano_slug}`;
    dispatch(setGlobalModalContent(`${nanoBlockId}:edit.permissions`));
  };

  const handleShare = () => {
    dispatch(setGlobalModalContent(`plan:${plan.id}:share`));
  };

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    let nanoBlockId;
    let url;
    switch (action) {
      case 'show-archives':
        nanoBlockId = `plan:${plan.nano_slug}`;
        url = `#${nanoBlockId}:archives`;
        history.push(url);
        break;
      case 'delete-plan':
        dispatch(setGlobalModalContent(`${blockId}:delete`));
        break;
      case 'configure-plan':
        handleConfigure();
        break;
    }
  };

  // Check if the workspace has the right subscription
  const menuItems = () => {
    const items = [];
    if (hasEditPermission) {
      items.push(<span data-action="configure-plan">Plan settings</span>);
    }
    items.push(<span data-action="show-archives">Show archived items</span>);
    if (hasEditPermission) {
      items.push(<span data-action="delete-plan">Delete plan</span>);
    }
    return items;
  };

  // Managing permissions
  const planLocked = plan.global_permission_type === 'view';
  const lockedButtonIcon = planLocked ? 'locked' : 'unlocked';
  const lockedButtonTip: any = planLocked ? 'Editing is locked' : 'Everyone can edit this plan';
  const presentPath = routes.TEMPLATE_FILE_ROUTE.replace(':planId', plan.nano_slug);
  return (
    <Wrapper>
      <WrapperLine>
        <div className="menu-title">
          <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
          <ReactTooltip
            place="bottom"
            type="dark"
            id="planIconSelector"
            className="tooltip"
            effect="solid"
            delayShow={200}
          >
            Select plan icon
          </ReactTooltip>
          <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
        </div>
        <div className="settings-nav">
          <div data-tip data-for="shareButton">
            <KoalaIconButton className="hide-mobile" onClick={handleShare} iconName="share" />
          </div>
          <ReactTooltip place="bottom" type="dark" id="shareButton" className="tooltip" effect="solid" delayShow={200}>
            Share this plan
          </ReactTooltip>
          <div data-tip data-for="lockedButton">
            <KoalaIconButton
              className="hide-mobile"
              onClick={handleEditPermissions}
              disabled={!hasEditPermission}
              iconName={lockedButtonIcon}
            />
          </div>
          <ReactTooltip place="bottom" type="dark" id="lockedButton" className="tooltip" effect="solid" delayShow={200}>
            {lockedButtonTip}
          </ReactTooltip>
          {hasEditPermission && (
            <KoalaButton onClick={handleEdit} className="plan-nav-edit-plan hide-tablet" appearance="subtle">
              Edit content
            </KoalaButton>
          )}
          <a href={presentPath} target="_blank" rel="noopener noreferrer" className="button primary">
            Preview
          </a>
          <DropdownMenu
            trigger={<KoalaIconButton iconName="ellipsis" />}
            onSelection={handleMenuSelection}
            items={menuItems()}
          />
        </div>
      </WrapperLine>
    </Wrapper>
  );
}

export default PlanNav;
