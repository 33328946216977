import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import HubspotIcon from 'components/_assets/IconHubspot.png';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
  state: string;
}

function HubspotIntegration(props: Props) {
  const { workspace, integrations, state } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallHubspotMutation, { isLoading: isUninstallingHubspot }] = useMutation(remoteApi.uninstallHubspot, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const hubspotIntegration = integrations.filter((i: Integration) => i.integration_type === 'hubspot')[0];
  const uninstallHubspotLabel = isUninstallingHubspot ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove');

  const hubspotRedirectURL = `https://${process.env.REACT_APP_DOMAIN}/install/hubspot`;
  const hubspotOAuthURL =
    `https://app.hubspot.com/oauth/authorize?client_id=${
      process.env.REACT_APP_HUBSPOT_CLIENT_ID
    }&response_type=code&redirect_uri=${encodeURIComponent(hubspotRedirectURL)}` +
    `&state=${state}` +
    `&scope=tickets%20e-commerce%20sales-email-read%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.companies.read%20crm.objects.owners.read%20crm.objects.deals.read%20conversations.read`;

  const handleUninstallHubspot = (e: React.MouseEvent) => {
    e.preventDefault();
    uninstallHubspotMutation(workspace.slug);
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={HubspotIcon} alt="Hubspot" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>
          {t('workspaceSettingsIntegrations.dataConnectorHeader', {
            integration: 'Hubspot',
          })}
        </h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Hubspot' })}</p>

        <p>
          <a href="https://guides.tability.app/docs/integrations/hubspot" target="_blank" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {hubspotIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: hubspotIntegration.connected_to }}
              />
            </div>
            <KoalaButton href={hubspotOAuthURL} appearance="secondary" size="small">
              {t('workspaceSettingsIntegrations.reinstall')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={handleUninstallHubspot}>
              {uninstallHubspotLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!hubspotIntegration && (
          <KoalaButton href={hubspotOAuthURL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Hubspot' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default HubspotIntegration;
