import createReducer from './createReducer';
import * as actionTypes from 'state/actions/actionTypes';
import { getScorecard } from 'scorecardEngine/';
import * as utils from './utils';

const INITIAL_STATE = {
  currentPlanId: '',
  currentWorkspaceId: '',
  selectedBlockId: '',
  displayNewBlockForm: false,
  scorecard: null,
  planLastChanged: null,
  panelContent: '',
  hasSyncError: false,
  syncErrorText: '',
  outcomesLoading: [],
};

const editorUIReducer = createReducer(INITIAL_STATE)({
  // When we load the initial plan we set the current plan ID for reference.
  [actionTypes.WORKSPACE_EDITOR_RESET]: () => INITIAL_STATE,

  [actionTypes.WORKSPACE_EDITOR_LOAD_PLAN]: (state: any, action: any) => {
    const planResponse = action.plan;

    return {
      ...state,
      planLastChanged: Date.now(),
      currentPlanId: planResponse.id,
      currentWorkspaceId: planResponse.workspace_id,
    };
  },
  [actionTypes.WORKSPACE_EDITOR_LOAD_OBJECTIVES]: (state: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },
  [actionTypes.WORKSPACE_EDITOR_LOAD_OUTCOMES]: (state: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },
  [actionTypes.WORKSPACE_EDITOR_LOAD_INITIATIVES]: (state: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },
  // Set the flag to display the new block form
  [actionTypes.WORKSPACE_EDITOR_SET_DISPLAY_NEW_BLOCK_FORM]: (state: any, action: any) => {
    return {
      ...state,
      displayNewBlockForm: action.displayNewBlockForm,
    };
  },
  // Select a block in the editor
  [actionTypes.WORKSPACE_EDITOR_SELECT_BLOCK]: (state: any, action: any) => {
    return {
      ...state,
      selectedBlockId: action.blockId,
      displayNewBlockForm: false,
    };
  },
  // Select a block in the editor
  [actionTypes.WORKSPACE_EDITOR_UNSELECT_BLOCK]: (state: any, action: any) => {
    return {
      ...state,
      selectedBlockId: null,
      displayNewBlockForm: false,
    };
  },

  /***************************
   *  NAVIGATION REDUCERS
   ***************************/

  // Select the block below the current one
  [actionTypes.WORKSPACE_EDITOR_SELECT_BLOCK_BELOW]: (state: any, action: any) => {
    const { editorEntities } = action;
    const { plansToObjectivesMapping } = editorEntities;
    const { currentPlanId, selectedBlockId } = state;
    const objectiveIds = plansToObjectivesMapping[currentPlanId] || [];

    // Return current state if:
    // - we don't have a current plan
    // - or the current plan doesn't have any objectives
    if (!currentPlanId || objectiveIds.length === 0) {
      return state;
    }

    if (!selectedBlockId) {
      return state;
    }

    const newSelectedBlockId = utils.getBlockBelow(selectedBlockId, editorEntities);

    if (!newSelectedBlockId) {
      return state;
    }

    return {
      ...state,
      selectedBlockId: newSelectedBlockId,
      displayNewBlockForm: false,
    };
  },

  // Selects the block above the current one
  [actionTypes.WORKSPACE_EDITOR_SELECT_BLOCK_ABOVE]: (state: any, action: any) => {
    const { editorEntities } = action;
    const { plansToObjectivesMapping } = editorEntities;
    const { currentPlanId, selectedBlockId } = state;
    const objectiveIds = plansToObjectivesMapping[currentPlanId] || [];

    // Return current state if:
    // - we don't have a current plan
    // - or the current plan doesn't have any objectives
    if (!currentPlanId || objectiveIds.length === 0) {
      return state;
    }

    if (!selectedBlockId) {
      return state;
    }

    const newSelectedBlockId = utils.getBlockAbove(selectedBlockId, editorEntities);

    if (!newSelectedBlockId) {
      return state;
    }

    return {
      ...state,
      selectedBlockId: newSelectedBlockId,
      displayNewBlockForm: false,
    };
  },

  /**************************
   * CRUD REACTIONS STUFF
   *************************/

  [actionTypes.WORKSPACE_EDITOR_CREATE_OBJECTIVE]: (state: any, action: any) => {
    const { objective } = action;
    return {
      ...state,
      selectedBlockId: `objective:${objective.id}`,
      displayNewBlockForm: true,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_CREATE_OUTCOME]: (state: any, action: any) => {
    const { outcome } = action;
    return {
      ...state,
      selectedBlockId: `outcome:${outcome.id}`,
      displayNewBlockForm: true,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_CREATE_TASK]: (state: any, action: any) => {
    const { initiative } = action;
    return {
      ...state,
      selectedBlockId: `initiative:${initiative.id}`,
      displayNewBlockForm: true,
      planLastChanged: Date.now(),
    };
  },

  /********************
   * Scorecard stuff
   ********************/

  [actionTypes.WORKSPACE_EDITOR_GET_SCORECARD]: (state: any, action: any) => {
    const { editorEntities } = action;
    const { currentPlanId } = state;
    const scorecard = getScorecard(currentPlanId, editorEntities);
    return {
      ...state,
      scorecard,
    };
  },

  /********************
   * Panel stuff
   ********************/

  [actionTypes.WORKSPACE_EDITOR_SET_PANEL_CONTENT]: (state: any, action: any) => {
    return {
      ...state,
      panelContent: action.panelContent,
    };
  },
  [actionTypes.WORKSPACE_EDITOR_SET_SYNC_ERROR]: (state: any, action: any) => {
    return {
      ...state,
      hasSyncError: action.hasSyncError,
    };
  },
  [actionTypes.WORKSPACE_EDITOR_SET_SYNC_ERROR_TEXT]: (state: any, action: any) => {
    return {
      ...state,
      syncErrorText: action.syncErrorText,
    };
  },
  [actionTypes.WORKSPACE_EDITOR_SET_LOADING_OUTCOME_METRIC]: (state: any, action: any) => {
    const { isLoading, outcomeId } = action;
    const prevState: string[] = state['outcomesLoading'];
    let outcomesLoading: string[] = prevState;
    if (isLoading) {
      outcomesLoading.push(outcomeId);
    } else {
      outcomesLoading = outcomesLoading.filter((prev) => prev !== outcomeId);
    }
    return {
      ...state,
      outcomesLoading,
    };
  },
  [actionTypes.WORKSPACE_EDITOR_RESET_LOADING_METRIC]: (state: any, action: any) => {
    return {
      ...state,
      outcomesLoading: [],
    };
  },

  /*****************************
   * REACTION TO CRUD AND PLAN CHANGE ACTIONS
   ******************************/

  [actionTypes.WORKSPACE_EDITOR_UPDATE_PLAN]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_UPDATE_OBJECTIVE]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_UPDATE_OUTCOME]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_UPDATE_TASK]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_DELETE_OBJECTIVE]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_DELETE_OUTCOME]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_DELETE_TASK]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_REORDER_OBJECTIVE]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_REORDER_OUTCOME]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_REORDER_TASK]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_MOVE_OUTCOME]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },

  [actionTypes.WORKSPACE_EDITOR_MOVE_TASK]: (state: any, action: any) => {
    return {
      ...state,
      planLastChanged: Date.now(),
    };
  },
});

export default editorUIReducer;
