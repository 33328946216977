import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
import DatePicker from 'react-datepicker';
import { toDate, parseISO, getTime } from 'date-fns';
import * as types from 'types';

import 'theme/DatePicker.css';

// API
import * as remoteApi from 'api/remote';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import Loader from 'components/Loader';
import MentionBox from 'components/MentionBox';
import { ModalGrid, ModalHeader, ModalContent, ModalFooter } from 'components/GlobalModal';
import FormField from 'components/FormField';
import RadioField from 'components/RadioField';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import ProgressBars from './CheckinComponents/ProgressBars';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import InitiativesTab from 'panels/OutcomePanel/InitiativesTab';
import CheckinsChart from 'components/Chart';
import { confidenceToColor } from 'utils/checkinUtils';
import { useTranslation } from 'react-i18next';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Columns = styled.div`
  display: flex;
  h4 {
    margin-bottom: ${theme.spacing.x1};
    font-weight: 800;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: ${theme.colors.subtleText};
  }

  @media ${theme.devices.mobile} {
    flex-direction: column-reverse;
  }
`;

const UpdateColumn = styled.div`
  flex: 1;
  padding-right: ${theme.spacing.x2};

  @media ${theme.devices.mobile} {
    width: 100%;
    padding: 0px;

    input.small {
      min-width: 15rem;
    }

    .radio-group > label {
      padding: 0 ${theme.spacing.x1};
      white-space: normal;
      min-width: 7rem;
      text-align: center;
    }
  }
`;

const ChartColumn = styled.div`
  flex: 1;
  padding-left: ${theme.spacing.x2};

  @media ${theme.devices.mobile} {
    width: 100%;
    padding: 0;
    margin-bottom: ${theme.spacing.x4};
  }
`;

const InvisibleButton = styled.button`
  display: none;
`;

const TasksContainer = styled(FormField)`
  background: ${theme.colors.N0};
  padding: ${theme.spacing.x2};
  margin: ${theme.spacing.x2} 0;
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};
`;

interface Props {
  checkinId: string;
}

function EditCheckin(props: Props) {
  const dispatch = useDispatch();
  const { checkinId } = props;
  const queryCache = useQueryCache();
  const currentWorkspace: types.Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const translationKey = 'modals.checkin';

  // Checkin mutations
  const [updateCheckinMutation, { isLoading }]: [any, any] = useMutation(remoteApi.updateCheckin, {
    onSuccess: () => {
      dispatch(setGlobalModalContent(''));
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.checkins);
      queryCache.invalidateQueries(queryKeys.currentCheckin);
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcomeId]);
    },
  });

  // Form values
  const [checkin_date, setCheckinDate] = useState(new Date());
  const [score, setScore] = useState('');
  const [confidence, setConfidence] = useState('yellow');
  const [body, setBody] = useState('');

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentCheckin, checkinId];
  const staleTime = 0;

  // Get the checkin details
  const { data: checkinResponse }: any = useQuery(queryKey, remoteApi.fetchCheckinDetails, {
    staleTime,
    onSuccess: (checkinResponse) => {
      const checkin = checkinResponse.data;
      setCheckinDate(toDate(parseISO(checkin.checkin_date)));
      setScore(checkin.score);
      setConfidence(checkin.confidence);
      setBody(checkin.body);
    },
  });

  const checkin = checkinResponse ? checkinResponse.data : null;
  const outcome: types.Outcome = checkin ? checkin.outcome : null;
  const outcomeId: string | null = checkin ? checkin.outcome_id : null;

  // Construct the query key using the plan Id
  const queryKeyCheckins = [queryKeys.checkins, outcomeId, { sort: 'checkin_date', order: 'asc' }];

  // Get the plan details
  const { data: checkinsResponse }: any = useQuery(queryKeyCheckins, remoteApi.fetchCheckins, {
    staleTime,
    enabled: outcomeId !== null,
  });

  let checkins = [];
  if (checkinsResponse) {
    checkins = checkinsResponse.data;
  }

  // Now we can display the app with the Chrome
  if (!outcome) {
    return (
      <LoadingContainer>
        <Loader size="medium" />
      </LoadingContainer>
    );
  }

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'checkin_date':
        setCheckinDate(value);
        break;
      case 'score':
        setScore(value);
        break;
      case 'confidence':
        setConfidence(value);
        break;
      case 'body':
        setBody(value);
        break;
    }
  };

  const handleSubmit = () => {
    const params = {
      checkin_date,
      score,
      confidence,
      body,
    };
    updateCheckinMutation({
      checkinId,
      checkin: params,
    });
  };

  const draftCheckin: types.checkinType = {
    x: checkin_date,
    y: Number(score),
    timestamp: getTime(checkin_date),
    color: confidenceToColor(confidence),
    checkin: { ...checkin, confidence },
  };

  const analysisPlaceholder = t(`${translationKey}.placeholder`);

  const progressValue = score !== '' ? score : checkin.score;
  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{outcome.title}</h2>
        <KoalaIconButton iconName="close" onClick={handleCancel} />
      </ModalHeader>
      <ModalContent>
        <Columns>
          <UpdateColumn>
            <form onSubmit={handleSubmit}>
              <FormField>
                <label>{t(`${translationKey}.date`)}</label>
                <DatePicker
                  className="small"
                  selected={checkin_date}
                  dateFormat="d MMM yyyy"
                  onChange={(date: any) => setCheckinDate(date)}
                />
              </FormField>
              {
                // Only display the score field if the outcome has a target
                outcome.outcome_type !== 'no_metric' && (
                  <FormField>
                    <label>{t(`${translationKey}.progress`)}</label>
                    <input
                      className="small"
                      type="number"
                      autoComplete="off"
                      name="score"
                      value={score}
                      onChange={handleChange}
                      required={true}
                    />
                  </FormField>
                )
              }
              {outcome.outcome_type !== 'kpi' && (
                <RadioField>
                  <label>{t(`shared.confidence.label`)}</label>
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="green"
                      checked={confidence === 'green'}
                      name="confidence"
                      value={'green'}
                      onChange={handleChange}
                    />
                    <label htmlFor="green" className="green">
                      {t(`shared.confidence.onTrack`)}
                    </label>
                    <input
                      type="radio"
                      id="yellow"
                      checked={confidence === 'yellow'}
                      name="confidence"
                      value={'yellow'}
                      onChange={handleChange}
                    />
                    <label htmlFor="yellow" className="yellow">
                      {t(`shared.confidence.atRisk`)}
                    </label>
                    <input
                      type="radio"
                      id="red"
                      checked={confidence === 'red'}
                      name="confidence"
                      value={'red'}
                      onChange={handleChange}
                    />
                    <label htmlFor="red" className="red">
                      {t(`shared.confidence.offTrack`)}
                    </label>
                  </div>
                </RadioField>
              )}
              <FormField>
                <label>{t(`${translationKey}.analysis`)}</label>
                <MentionBox
                  value={body}
                  setValue={setBody}
                  placeholder={analysisPlaceholder}
                  cmdEnterCallback={handleSubmit}
                />
              </FormField>
              <InvisibleButton type="submit" />
            </form>
          </UpdateColumn>
          <ChartColumn>
            <CheckinsChart checkins={checkins} outcome={outcome} draftCheckin={draftCheckin} isEdit={true} />

            {checkin_date && (
              <ProgressBars
                outcome={outcome}
                checkinDate={checkin_date}
                currentValue={progressValue}
                confidence={confidence}
              />
            )}
            <TasksContainer>
              <label>
                {t(`${translationKey}.related`, { label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2) })}
              </label>
              <InitiativesTab outcome={outcome} hideMeta openTaskInNewTab />
            </TasksContainer>
          </ChartColumn>
        </Columns>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSubmit} disabled={isLoading} loading={isLoading}>
          {t('shared.update')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel} type="button">
          {t('shared.cancel')}
        </KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default EditCheckin;
