import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import zxcvbn from 'zxcvbn';

const ProgressBarContainer = styled.div`
  height: ${theme.spacing.x1};
  min-width: 10rem;
  position: relative;
  background: ${theme.colors.blockBorder};
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ color: string; prct: string }>`
  height: 100%;
  background: ${theme.colors.blue};
  background: ${(props) => props.color};
  border-radius: 4px;
  width: ${(props) => props.prct}%;
`;

const ValidationContainer = styled.div`
  label {
    color: #6a6a6a;
  }
`;

interface Props {
  password: string;
  setAllowPasswordSubmit: (val: boolean) => void;
}

interface StrengthDetails {
  prct: string;
  color: string;
  label: string;
}

function PasswordValidation(props: Props) {
  const { password, setAllowPasswordSubmit } = props;
  const { t } = useTranslation();
  const passwordScore = zxcvbn(password);
  let details: StrengthDetails;

  useEffect(() => {
    if (password.length < 8 || passwordScore.score < 3) {
      setAllowPasswordSubmit(false);
    } else {
      setAllowPasswordSubmit(true);
    }
  }, [passwordScore, password, setAllowPasswordSubmit]);

  if (password.length < 8) {
    details = { prct: '12.5', color: theme.colors.red, label: t('passwordValidation.short') };
  } else {
    switch (passwordScore.score) {
      case 2:
        details = { prct: '50', color: theme.colors.yellow, label: t('passwordValidation.weak') };
        break;
      case 3:
        details = { prct: '75', color: theme.colors.blue, label: t('passwordValidation.good') };
        break;
      case 4:
        details = { prct: '100', color: theme.colors.green, label: t('passwordValidation.great') };
        break;
      default:
        details = { prct: '25', color: theme.colors.red, label: t('passwordValidation.notStrong') };
        break;
    }
  }

  return (
    <ValidationContainer>
      <ProgressBarContainer>
        <ProgressBar color={details.color} prct={details.prct} />
      </ProgressBarContainer>
      <label>{details.label}</label>
    </ValidationContainer>
  );
}

export default React.memo(PasswordValidation);
