import styled from 'styled-components';
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
// import { useMutation, useQuery, useQueryCache } from 'react-query';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaInputValidation from 'koala/components/InputValidation';
import { useTranslation } from 'react-i18next';
import { Segment } from 'types';

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

interface Props {
  segmentId: string;
}

function RenameSegment(props: Props) {
  const { segmentId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translationKey = 'modals.renameSegment';

  const queryCache = useQueryCache();

  const { data: segmentResponse } = useQuery([queryKeys.segments, segmentId], remoteApi.fetchSegmentDetails);
  const segment: Segment | null = segmentResponse ? segmentResponse.data : null;
  const [title, setTitle] = useState(segment?.title);

  const [updateSegmentMutation, { isLoading, isError, error }]: [any, any] = useMutation(remoteApi.updateSegment, {
    onSuccess: (response) => {
      queryCache.invalidateQueries([queryKeys.segments]);
      dispatch(setGlobalModalContent(''));
    },
  });

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    const params = {
      id: segmentId,
      title: title,
    };
    updateSegmentMutation(params);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputTitle = e.target.value;
    setTitle(inputTitle);
  };

  let errorMessage = t('errors.somethingWentWrong') ?? 'Something went wrong';
  if (isError) {
    const data = error.response.data;
    if (error.response.status === 422 && data['title']) {
      errorMessage = t(`${translationKey}.segmentError`, { error: data['title'] });
    }
  }

  const allowSave = title && !isLoading && title !== segment?.title;
  const placeholder = t(`${translationKey}.segmentName`) ?? 'Segment name';
  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{segment?.segmentable_type === 'map' ? t(`${translationKey}.mapTitle`) : t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleSave}>
          <FormField>
            <label>{t(`${translationKey}.name`)}</label>
            <input placeholder={placeholder} className="segment-input" value={title} onChange={handleTitleChange} />
            {isError && <KoalaInputValidation state="error" text={errorMessage} />}
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!allowSave} loading={isLoading}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default RenameSegment;
