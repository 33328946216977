import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

// Actions
import { sessionActions } from 'state/actions/';

// Components
import MembershipRedirect from 'routes/MembershipRedirect';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: 2.4rem;
  a {
    text-decoration: underline;
  }
`;

function SAMLLogin() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state: any) => state.session.isAuthenticated);
  const params = new URLSearchParams(location.search);

  const token = params.get('token');
  const refresh_token = params.get('refresh_token');

  const mobileSchema = `${process.env.REACT_APP_TABILITY_MOBILE_SCHEMA}`;
  //const mobileSchema = `exp://127.0.0.1:19000/--/`;

  useEffect(() => {
    if (token && !isMobile) {
      dispatch(sessionActions.setAccessToken(token));
      dispatch(sessionActions.refreshTokens());
    }
    if (isMobile && refresh_token && mobileSchema) {
      window.location.replace(`${mobileSchema}/sso/success?token=${token}&refresh_token=${refresh_token}`);
    }
  }, [token, dispatch, mobileSchema, refresh_token]);

  if (isAuthenticated) {
    return <MembershipRedirect />;
  }

  if (isMobile) {
    return (
      <Container>
        <p>{t('SAML.loggedIn')}</p>
        <a
          href={`${mobileSchema}/sso/success?token=${token}&refresh_token=${refresh_token}`}
          onClick={() => window.close()}
          className="button primary"
        >
          {t('SAML.backApp')}
        </a>
      </Container>
    );
  }

  return (
    <Container>
      <p>{t('SAML.loggedIn')}</p>
      <Link to={'/'} className="button primary">
        {t('SAML.backWeb')}
      </Link>
    </Container>
  );
}

export default SAMLLogin;
