import * as actionTypes from './actionTypes';
import request from 'state/utils/request';
import { AUTH_LOGIN_API, AUTH_REFRESH_API, AUTH_SIGNUP_API, AUTH_PASSWORD_API, AUTH_CONFIRM_API } from 'api/remote';
import { Membership } from 'types';

// Session action

interface CredentialsProps {
  email: string;
  password: string;
}

export const setCurrentMembership = (currentMembership: any) => ({
  type: actionTypes.SET_CURRENT_MEMBERSHIP,
  currentMembership,
});

export const setReferenceTemplate = (referenceTemplate: string) => ({
  type: actionTypes.SET_REFERENCE_TEMPLATE,
  referenceTemplate,
});

export const setIsEmptyWorkspace = (isEmptyWorkspace: boolean) => ({
  type: actionTypes.SET_IS_EMPTY_WORKSPACE,
  isEmptyWorkspace,
});

export const setCurrentPing = (currentPing: any) => ({
  type: actionTypes.SET_CURRENT_PING,
  currentPing,
});

export const setCurrentIntegrations = (currentIntegrations: any) => ({
  type: actionTypes.SET_CURRENT_INTEGRATIONS,
  currentIntegrations,
});

export const setWorkspaceMemberships = (workspaceMemberships: Membership[]) => ({
  type: actionTypes.SET_WORKSPACE_MEMBERSHIPS,
  workspaceMemberships,
});

export const setCurrentUser = (currentUser: any) => ({
  type: actionTypes.SET_CURRENT_USER,
  currentUser,
});

export const setAccessToken = (accessToken: any) => ({
  type: actionTypes.SET_ACCESS_TOKEN,
  accessToken,
});

export const setCurrentWorkspace = (currentWorkspace: any) => ({
  type: actionTypes.SET_CURRENT_WORKSPACE,
  currentWorkspace,
});

export const setCurrentWorkspaceSettings = (currentWorkspaceSettings: any) => ({
  type: actionTypes.SET_CURRENT_WORKSPACE_SETTINGS,
  currentWorkspaceSettings,
});

export const setRedirectLink = (redirectLink: string | null) => ({
  type: actionTypes.SET_REDIRECT_LINK,
  redirectLink,
});

export const login = (credentials: CredentialsProps) => ({
  types: [actionTypes.LOGIN_REQUESTED, actionTypes.LOGIN_COMPLETED, actionTypes.LOGIN_FAILED],
  callAPI: () => {
    const body = {
      user: credentials,
    };
    return request.post(AUTH_LOGIN_API, body, {
      ignoreAuthHeaders: true,
      withCredentials: true,
    });
  },
});

export const loginCompleted = (response: any) => ({
  type: actionTypes.LOGIN_COMPLETED,
  response,
});

export const saveNewPassword = (params: any) => ({
  types: [
    actionTypes.SAVE_NEW_PASSWORD_REQUESTED,
    actionTypes.SAVE_NEW_PASSWORD_COMPLETED,
    actionTypes.SAVE_NEW_PASSWORD_FAILED,
  ],
  callAPI: () => {
    const body = {
      user: params,
    };
    return request.put(AUTH_PASSWORD_API, body, {
      ignoreAuthHeaders: true,
      withCredentials: true,
    });
  },
});

export const refreshTokens = () => ({
  types: [
    actionTypes.REFRESH_TOKENS_REQUESTED,
    actionTypes.REFRESH_TOKENS_COMPLETED,
    actionTypes.REFRESH_TOKENS_FAILED,
  ],
  callAPI: () => {
    return request.post(AUTH_REFRESH_API, {}, { withCredentials: true });
  },
});

export const logout = () => ({
  types: [actionTypes.LOGOUT_REQUESTED, actionTypes.LOGOUT_COMPLETED, actionTypes.LOGOUT_FAILED],
  callAPI: () => {
    return request.delete(AUTH_LOGIN_API, { withCredentials: true });
  },
});

export const signup = (credentials: CredentialsProps) => ({
  types: [actionTypes.SIGNUP_REQUESTED, actionTypes.SIGNUP_COMPLETED, actionTypes.SIGNUP_FAILED],
  callAPI: () => {
    const body = {
      user: credentials,
    };
    return request.post(AUTH_SIGNUP_API, body, {
      ignoreAuthHeaders: true,
    });
  },
});

export const confirm = (token: string) => ({
  types: [actionTypes.CONFIRM_REQUESTED, actionTypes.CONFIRM_COMPLETED, actionTypes.CONFIRM_FAILED],
  callAPI: () => {
    const params = {
      confirmation_token: token,
    };
    return request.get(AUTH_CONFIRM_API, {
      params,
      ignoreAuthHeaders: true,
      withCredentials: true,
    });
  },
});

export const resendConfirmation = (email: string) => ({
  types: [
    actionTypes.RESEND_CONFIRM_REQUESTED,
    actionTypes.RESEND_CONFIRM_COMPLETED,
    actionTypes.RESEND_CONFIRM_FAILED,
  ],
  callAPI: () => {
    const body = {
      user: {
        email,
      },
    };
    return request.post(AUTH_CONFIRM_API, body, {
      ignoreAuthHeaders: true,
    });
  },
});
