/**
 * Helper function
 */

//import * as TabilityTypes from '../../types';
import { v4 as uuidv4 } from 'uuid';
import update from 'immutability-helper';

// Sort playgroundEntities by rank
export const sortEntityIdsByRank = ({
  entityIds,
  playgroundEntities,
}: {
  entityIds: Array<string>;
  playgroundEntities: any;
}): Array<string> => {
  // Make a copy of the array to be safe

  const entityIdsCopy = [...entityIds];
  entityIdsCopy.sort((a: string, b: string) => {
    const entityA = playgroundEntities[a];
    const entityB = playgroundEntities[b];
    if (entityA && entityB) {
      if (entityA.rank < entityB.rank) {
        return -1;
      }
      if (entityA.rank > entityB.rank) {
        return 1;
      }
      return 0;
    }
    return 0;
  });

  return entityIdsCopy;
};

// Function that returns a mid-string between 2 strings. Used for ranking
export function midString(_prev: string, _next: string) {
  let p, n, pos, str;
  const prev = _prev || '~';
  const next = _next || '~';

  for (pos = 0; p === n; pos++) {
    // find leftmost non-matching character
    p = pos < prev.length ? prev.charCodeAt(pos) : 96;
    n = pos < next.length ? next.charCodeAt(pos) : 123;
  }
  str = prev.slice(0, pos - 1); // copy identical part of string
  if (p === 96) {
    // prev string equals beginning of next
    while (n === 97) {
      // next character is 'a'
      n = pos < next.length ? next.charCodeAt(pos++) : 123; // get char from next
      str += 'a'; // insert an 'a' to match the 'a'
    }
    if (n === 98) {
      // next character is 'b'
      str += 'a'; // insert an 'a' to match the 'b'
      n = 123; // set to end of alphabet
    }
  } else if (p && p + 1 === n) {
    // found consecutive characters
    str += String.fromCharCode(p); // insert character from prev
    n = 123; // set to end of alphabet
    while ((p = pos < prev.length ? prev.charCodeAt(pos++) : 96) === 122) {
      // p='z'
      str += 'z'; // insert 'z' to match 'z'
    }
  }
  if (p && n) {
    return str + String.fromCharCode(Math.ceil((p + n) / 2)); // append middle character
  } else {
    return '~n';
  }
}

// Turns a plan Json into a planJson with Ids
export const planJsonToPersistedPlanJson = (planJson: string) => {
  const planObject = JSON.parse(planJson);

  const planPersisted: any = {
    id: uuidv4(),
    title: planObject.title,
    objectives: [],
  };

  let prevObjectiveRank = '';
  const objectives: any[] = [];

  // Iterate through objectives
  planObject.objectives.forEach((objective: any) => {
    // Set the iteration parameter
    const objectiveId = uuidv4();
    let prevOutcomeRank = '';
    const outcomes: any[] = [];

    objective.outcomes.forEach((outcome: any) => {
      const outcomeId = uuidv4();
      let prevInitiativeRank = '';
      const initiatives: any[] = [];

      outcome.initiatives.forEach((initiative: any) => {
        const initiativeId = uuidv4();
        const initiativeRank = midString(prevInitiativeRank, '');
        prevInitiativeRank = initiativeRank;

        const initiativePersisted = {
          id: initiativeId,
          outcome_id: outcomeId,
          title: initiative.title,
          state: initiative.state,
          rank: initiativeRank,
        };

        initiatives.push(initiativePersisted);
      });

      // Create the new outcome with Ids
      const outcomeRank = midString(prevOutcomeRank, '');
      prevOutcomeRank = outcomeRank;

      const outcomePersisted = {
        id: outcomeId,
        objective_id: objectiveId,
        rank: outcomeRank,
        title: outcome.title,
        from: outcome.from,
        to: outcome.to,
        score_format: outcome.score_format,
        initiatives,
      };

      outcomes.push(outcomePersisted);
    });

    // Get the rank for the objective
    const objectiveRank = midString(prevObjectiveRank, '');

    // Store the rank to prepare the new objective
    prevObjectiveRank = objectiveRank;

    const objectivePersisted = {
      id: objectiveId,
      plan_id: planPersisted.id,
      rank: objectiveRank,
      title: objective.title,
      outcomes,
    };
    objectives.push(objectivePersisted);
  });

  planPersisted.objectives = objectives;

  return JSON.stringify(planPersisted);
};

// Get the rank between afterEntity and afterEntity + 1
export const getRankAfterEntity = (afterEntity: any, entityIdsSorted: any, playgroundEntities: any): string => {
  // Set the default setting for the rank object
  let prevRank = '',
    nextRank = '';

  if (afterEntity) {
    const afterEntityIndex = entityIdsSorted.indexOf(afterEntity.id);
    prevRank = afterEntity.rank;
    const nextEntityId = entityIdsSorted[afterEntityIndex + 1];
    if (nextEntityId) {
      const nextEntity = playgroundEntities[nextEntityId];
      nextRank = nextEntity.rank;
    }
  } else {
    // Get the rank of last entity if it exists
    if (entityIdsSorted.length > 0) {
      const lastEntityId = entityIdsSorted[entityIdsSorted.length - 1];
      const lastEntity = playgroundEntities[lastEntityId];
      prevRank = lastEntity.rank;
    }
  }

  // Create the new Objective
  const rank = midString(prevRank, nextRank) || '';
  return rank;
};

export const moveOutcome = (state: any, orderData: any) => {
  // Check that we're not dropping in same spot
  if (orderData.destination.droppableId === orderData.source.droppableId) {
    return state;
  }

  let prevRank = '',
    nextRank = '',
    rank;
  const [, dragEntityId] = orderData.draggableId.split(':');
  const [, srcEntityId] = orderData.source.droppableId.split(':');
  const [, destEntityId] = orderData.destination.droppableId.split(':');

  const srcObjective = state.objectives[srcEntityId];
  const destObjective = state.objectives[destEntityId];
  const outcomes = state.outcomes;
  const srcOutcomeIds = state.objectivesToOutcomesMapping[srcObjective.id] || [];
  const destOutcomeIds = state.objectivesToOutcomesMapping[destObjective.id] || [];

  const srcOutcome = outcomes[dragEntityId];
  const destinationOutcomeId = destOutcomeIds[orderData.destination.index];

  if (destinationOutcomeId) {
    nextRank = outcomes[destinationOutcomeId].rank;
    const prevRankOutcomeId = destOutcomeIds[orderData.destination.index - 1];
    if (prevRankOutcomeId) {
      prevRank = outcomes[prevRankOutcomeId].rank;
    } else {
      prevRank = '';
    }
  }

  // Calculate the new rank
  rank = midString(prevRank, nextRank) || '';

  // Set the new rank on the entity
  const newOutcome = {
    ...srcOutcome,
    objective_id: destObjective.id,
    rank,
  };

  const newSrcOutcomeIds = Array.from(srcOutcomeIds);
  const newDestOutcomeIds = Array.from(destOutcomeIds);
  const [removed] = newSrcOutcomeIds.splice(orderData.source.index, 1);
  newDestOutcomeIds.splice(orderData.destination.index, 0, removed);

  const newObjectivesToOutcomesMapping = update(state.objectivesToOutcomesMapping, {
    $merge: {
      [srcObjective.id]: newSrcOutcomeIds,
      [destObjective.id]: newDestOutcomeIds,
    },
  });

  const newState = update(state, {
    objectivesToOutcomesMapping: {
      $set: newObjectivesToOutcomesMapping,
    },
    outcomes: {
      $merge: {
        [newOutcome.id]: newOutcome,
      },
    },
  });
  return newState;
};

export const moveInitiative = (state: any, orderData: any) => {
  // Check that we're not dropping in same spot
  if (orderData.destination.droppableId === orderData.source.droppableId) {
    return state;
  }

  let prevRank = '',
    nextRank = '',
    rank;
  const [, dragEntityId] = orderData.draggableId.split(':');
  const [, srcEntityId] = orderData.source.droppableId.split(':');
  const [, destEntityId] = orderData.destination.droppableId.split(':');

  const srcOutcome = state.outcomes[srcEntityId];
  const destOutcome = state.outcomes[destEntityId];
  const initiatives = state.initiatives;
  const srcInitiativeIds = state.outcomesToInitiativesMapping[srcOutcome.id] || [];
  const destInitiativeIds = state.outcomesToInitiativesMapping[destOutcome.id] || [];

  const srcInitiative = initiatives[dragEntityId];
  const destinationInitiativeId = destInitiativeIds[orderData.destination.index];

  if (destinationInitiativeId) {
    nextRank = initiatives[destinationInitiativeId].rank;
    const prevRankInitiativeId = destInitiativeIds[orderData.destination.index - 1];
    if (prevRankInitiativeId) {
      prevRank = initiatives[prevRankInitiativeId].rank;
    } else {
      prevRank = '';
    }
  }

  // Calculate the new rank
  rank = midString(prevRank, nextRank) || '';

  // Set the new rank on the entity
  const newInitiative = {
    ...srcInitiative,
    outcome_id: destOutcome.id,
    rank,
  };

  const newSrcInitiativeIds = Array.from(srcInitiativeIds);
  const newDestInitiativeIds = Array.from(destInitiativeIds);
  const [removed] = newSrcInitiativeIds.splice(orderData.source.index, 1);
  newDestInitiativeIds.splice(orderData.destination.index, 0, removed);

  const newOutcomesToInitiativesMapping = update(state.outcomesToInitiativesMapping, {
    $merge: {
      [srcOutcome.id]: newSrcInitiativeIds,
      [destOutcome.id]: newDestInitiativeIds,
    },
  });

  const newState = update(state, {
    outcomesToInitiativesMapping: {
      $set: newOutcomesToInitiativesMapping,
    },
    initiatives: {
      $merge: {
        [newInitiative.id]: newInitiative,
      },
    },
  });
  return newState;
};

/**
 * ORDERRING STUFF
 */

export const reorderEntities = (state: any, orderData: any) => {
  // Check that we're not dropping in same spot
  if (orderData.destination.index === orderData.source.index) {
    return state;
  }

  let prevRank = '',
    nextRank = '',
    rank;
  const [droppableEntityType, droppableEntityId] = orderData.source.droppableId.split(':');

  let parent, dataEntities, siblingIds;
  switch (droppableEntityType) {
    case 'plan':
      parent = state.plans[droppableEntityId];
      siblingIds = state.plansToObjectivesMapping[droppableEntityId] || [];
      dataEntities = state.objectives;
      break;
    case 'objective':
      parent = state.objectives[droppableEntityId];
      siblingIds = state.objectivesToOutcomesMapping[droppableEntityId] || [];
      dataEntities = state.outcomes;
      break;
    case 'outcome':
      parent = state.outcomes[droppableEntityId];
      siblingIds = state.outcomesToInitiativesMapping[droppableEntityId] || [];
      dataEntities = state.initiatives;
      break;
  }

  const sourceEntityId = siblingIds[orderData.source.index];
  const destinationEntityId = siblingIds[orderData.destination.index];

  // If we're moving down the list
  if (orderData.destination.index > orderData.source.index) {
    prevRank = dataEntities[destinationEntityId].rank;
    const nextRankEntityId = siblingIds[orderData.destination.index + 1];
    if (nextRankEntityId) {
      nextRank = dataEntities[nextRankEntityId].rank;
    } else {
      nextRank = '';
    }
  }

  // Moving up the list
  if (orderData.destination.index < orderData.source.index) {
    nextRank = dataEntities[destinationEntityId].rank;
    const prevRankEntityId = siblingIds[orderData.destination.index - 1];
    if (prevRankEntityId) {
      prevRank = dataEntities[prevRankEntityId].rank;
    } else {
      prevRank = '';
    }
  }

  // Calculate the new rank
  rank = midString(prevRank, nextRank) || '~n';

  // Set the new rank on the entity
  const sourceEntity = dataEntities[sourceEntityId];
  const newEntity = {
    ...sourceEntity,
    rank,
  };

  const newSiblingIds = Array.from(siblingIds);
  const [removed] = newSiblingIds.splice(orderData.source.index, 1);
  newSiblingIds.splice(orderData.destination.index, 0, removed);

  let newState;
  switch (droppableEntityType) {
    case 'plan':
      newState = update(state, {
        plansToObjectivesMapping: {
          [parent.id]: {
            $set: newSiblingIds,
          },
        },
        objectives: {
          $merge: {
            [newEntity.id]: newEntity,
          },
        },
      });
      break;
    case 'objective':
      newState = update(state, {
        objectivesToOutcomesMapping: {
          [parent.id]: {
            $set: newSiblingIds,
          },
        },
        outcomes: {
          $merge: {
            [newEntity.id]: newEntity,
          },
        },
      });
      break;
    case 'outcome':
      newState = update(state, {
        outcomesToInitiativesMapping: {
          [parent.id]: {
            $set: newSiblingIds,
          },
        },
        initiatives: {
          $merge: {
            [newEntity.id]: newEntity,
          },
        },
      });
      break;
  }

  return newState;
};

/**
 * NAVIGATE TO BLOCK BELOW
 */

export const getBlockBelow = (currentBlockId: string, existingEntities: any) => {
  const { objectivesToOutcomesMapping, outcomesToInitiativesMapping } = existingEntities;

  const [entityType, entityId] = currentBlockId.split(':');

  let children, childrenEntityType;

  // Set the entityObject and parentHash that we'll use in the algo
  switch (entityType) {
    case 'objective':
      children = objectivesToOutcomesMapping[entityId] || [];
      childrenEntityType = 'outcome';
      break;
    case 'outcome':
      children = outcomesToInitiativesMapping[entityId] || [];
      childrenEntityType = 'initiative';
      break;
    case 'initiative':
      children = null;
      childrenEntityType = null;
      break;
  }

  // Return the first child if it exists
  if (children && children.length > 0) {
    return `${childrenEntityType}:${children[0]}`;
  }

  return nextSiblingAndUp(currentBlockId, existingEntities);
};

export const nextSiblingAndUp = (currentBlockId: string, existingEntities: any): any => {
  const { objectives, outcomes, initiatives } = existingEntities;
  const { plansToObjectivesMapping, objectivesToOutcomesMapping, outcomesToInitiativesMapping } = existingEntities;

  const [entityType, entityId] = currentBlockId.split(':');

  let entityObject, parentId, siblings, parentEntityType;

  // Set the params we will use in the algo
  switch (entityType) {
    case 'objective':
      entityObject = objectives[entityId]; // Entity is the objective
      parentId = entityObject.plan_id;
      siblings = plansToObjectivesMapping[parentId] || []; // Sibling from parent plan
      parentEntityType = 'plan'; // Parent entity areplans
      break;
    case 'outcome':
      entityObject = outcomes[entityId]; // Entity is the outcome
      parentId = entityObject.objective_id;
      siblings = objectivesToOutcomesMapping[parentId] || []; // Siblings from parent objective
      parentEntityType = 'objective'; // Parent entity is an objective
      break;
    case 'initiative':
      entityObject = initiatives[entityId]; // Entity is the initiative
      parentId = entityObject.outcome_id;
      siblings = outcomesToInitiativesMapping[parentId] || []; // Siblings from parent outcome
      parentEntityType = 'outcome'; // Parent entity is an outcome
      break;
  }

  // If the current block as a sibling, return the sibling!
  if (siblings && siblings.length > 0) {
    const currentIndex = siblings.indexOf(entityObject.id);

    // Checks that there's a sibling after that we can go to
    if (siblings[currentIndex + 1]) {
      return `${entityType}:${siblings[currentIndex + 1]}`;
    }
  }

  // Only look for a parent if we're not on an objective
  if (entityType !== 'objective') {
    // If we couldn't find a sibling, we look for the sibling of the parent
    const parentBlockId = `${parentEntityType}:${parentId}`;

    return nextSiblingAndUp(parentBlockId, existingEntities);
  }

  return null;
};

/**
 * NAVIGATE TO BLOCK ABOVE
 */

export const getBlockAbove = (currentBlockId: string, existingEntities: any) => {
  const { objectives, outcomes, initiatives } = existingEntities;
  const { plansToObjectivesMapping, objectivesToOutcomesMapping, outcomesToInitiativesMapping } = existingEntities;

  const [entityType, entityId] = currentBlockId.split(':');

  let entityObject, parentId, siblings, parentEntityType;

  // Set the params we will use in the algo
  switch (entityType) {
    case 'objective':
      entityObject = objectives[entityId]; // Entity is the objective
      parentId = entityObject.plan_id;
      siblings = plansToObjectivesMapping[parentId] || []; // Sibling from parent plan
      parentEntityType = 'plan'; // Parent entity areplans
      break;
    case 'outcome':
      entityObject = outcomes[entityId]; // Entity is the outcome
      parentId = entityObject.objective_id;
      siblings = objectivesToOutcomesMapping[parentId] || []; // Siblings from parent objective
      parentEntityType = 'objective'; // Parent entity is an objective
      break;
    case 'initiative':
      entityObject = initiatives[entityId]; // Entity is the initiative
      parentId = entityObject.outcome_id;
      siblings = outcomesToInitiativesMapping[parentId] || []; // Siblings from parent outcome
      parentEntityType = 'outcome'; // Parent entity is an outcome
      break;
  }

  const siblingPosition = siblings.indexOf(entityObject.id);

  // Quick check to make sure we have a sibling
  if (siblingPosition < 0) {
    return null;
  }

  // If we're on the first child, return the parent
  if (siblingPosition === 0) {
    // Only look for a parent if we're not on an objective
    if (entityType !== 'objective') {
      // If we couldn't find a sibling, we return the parent
      const parentBlockId = `${parentEntityType}:${parentId}`;

      return parentBlockId;
    }

    return null;
  }

  // Get the previous sibling id
  const prevSiblingId = siblings[siblingPosition - 1];
  const siblingBlockId = `${entityType}:${prevSiblingId}`;

  // Otherwise look for the prev block by going down the children of the previous sibling
  return prevSiblingAndDown(siblingBlockId, existingEntities);
};

export const prevSiblingAndDown = (currentBlockId: string, existingEntities: any): any => {
  const { objectivesToOutcomesMapping, outcomesToInitiativesMapping } = existingEntities;

  const [entityType, entityId] = currentBlockId.split(':');

  let children, childrenEntityType;

  // Set the params we will use in the algo
  switch (entityType) {
    case 'objective':
      children = objectivesToOutcomesMapping[entityId] || [];
      childrenEntityType = 'outcome'; // Parent entity areplans
      break;
    case 'outcome':
      children = outcomesToInitiativesMapping[entityId] || [];
      childrenEntityType = 'initiative'; // Parent entity is an objective
      break;
    case 'initiative':
      children = null;
      childrenEntityType = null; // Parent entity is an outcome
      break;
  }

  // If the current block has children, keep recurring
  if (children && children.length > 0) {
    const lastChildBlockId = `${childrenEntityType}:${children[children.length - 1]}`;

    return prevSiblingAndDown(lastChildBlockId, existingEntities);
  }

  return currentBlockId;
};
