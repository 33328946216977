import queryKeys from 'config/queryKeys';
import React, { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Team } from 'types';
import * as remoteApi from 'api/remote';
import KoalaIcon from 'koala/components/Icons';

import { Menu, MenuItem } from 'react-aria-menubutton';
import Loader from 'components/Loader';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const UserSelection = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const UserSelectionIcon = styled.div`
  grid-area: icon;
  align-items: center;
  display: flex;
  justify-content: center;
`;
const UserSelectionText = styled.div`
  grid-area: text;
  span {
    word-break: break-word;
  }
`;

interface Props {
  ignoredTeams?: string[];
  canUnassign?: boolean;
}

function UserItems(props: Props) {
  const { ignoredTeams, canUnassign } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const [nameToSearch, setNameToSearch] = useState('');
  const searchTeamsQueryKey = [
    queryKeys.teams,
    workspaceSlug,
    {
      name: nameToSearch,
      per_page: 10,
    },
  ];
  const [searchedTeams, setSearchedTeams] = useState<Team[]>([]);

  const staleTime = 0;

  const { isFetching: isFetchingMemberships } = useQuery(searchTeamsQueryKey, remoteApi.fetchWorkspaceTeams, {
    staleTime,
    onSuccess: (response) => {
      setSearchedTeams(response.data);
    },
  });

  const performSearch = (newName: string) => {
    setNameToSearch(newName);
  };

  let teams = [];

  // Adds all the teams returned by search
  searchedTeams.forEach((team: Team) => {
    if (ignoredTeams && ignoredTeams.includes(team.id)) {
      return; // Ignore the ignoredTeams
    }

    teams.push(
      <UserSelection data-action={team.id}>
        <UserSelectionText>
          <span className="owner-option-name">{team.name}</span>
        </UserSelectionText>
      </UserSelection>,
    );
  });

  if (canUnassign) {
    teams.push(
      <UserSelection data-action={'unassign'}>
        <UserSelectionIcon>
          <KoalaIcon iconName="close" />
        </UserSelectionIcon>
        <UserSelectionText>
          <span className="owner-option-name">{t('shared.unassign')}</span>
        </UserSelectionText>
      </UserSelection>,
    );
  }

  teams.push(
    <UserSelection data-action={'create-team'}>
      <UserSelectionIcon>
        <KoalaIcon iconName="plus" iconSize="small" />
      </UserSelectionIcon>
      <UserSelectionText>
        <span className="owner-option-name">{t('shared.createTeam')}</span>
      </UserSelectionText>
    </UserSelection>,
  );

  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );
  const handleSearch = (e: any) => {
    const newName = e.target.value;
    debouncePerformSearch.current(newName);
  };

  return (
    <>
      <input type="text" onChange={handleSearch} placeholder={t('shared.addTag') ?? 'Search team'} />
      <ul>
        {isFetchingMemberships && (
          <li className="AriaMenuButton-menuItemWrapper">
            <MenuItem className="AriaMenuButton-menuItem">
              <Loader />
            </MenuItem>
          </li>
        )}
        {!isFetchingMemberships &&
          teams.map((item, index) => (
            <li className="AriaMenuButton-menuItemWrapper" key={index}>
              <MenuItem className="AriaMenuButton-menuItem">{item}</MenuItem>
            </li>
          ))}
      </ul>
    </>
  );
}

// We need this function to make sure that we don't trigger any useless membership search.
// We'll only display the menu content (and execute related code) once it's opened.
function MenuItems(props: Props) {
  const menuContent = (menuState: any) => {
    if (menuState.isOpen) {
      // @ts-ignore
      return <UserItems {...props} />;
    } else {
      return null;
    }
  };

  // @ts-ignore
  return <Menu className="AriaMenuButton-menu">{menuContent}</Menu>;
}

export default React.memo(MenuItems);
