import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import * as remoteApi from 'api/remote';
import * as routes from 'routes';
import { setIsEmptyWorkspace } from 'state/actions/sessionActions';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation, Trans } from 'react-i18next';
import { Workspace } from 'types';
import { Link } from 'react-router-dom';

import { useHistory } from 'react-router-dom';

import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';

import { startOfQuarter, endOfQuarter } from 'date-fns';

const NoCreditsImg =
  'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-sleeping_tvsdtw.gif';

const PlanInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  a {
    color: ${theme.colors.blue};
  }
`;

const ErrorContainer = styled.p`
  color: ${theme.colors.red};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 5rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }

  .inline {
    display: flex;
    gap: ${theme.spacing.x1};
  }
`;

const Block = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  padding: ${theme.spacing.x2};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const TabbyThinking = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
  img {
    width: 15rem;
  }
  padding: ${theme.spacing.x2};
`;

const TipsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
  font-size: 1.5rem;
  h3 {
    font-size: 2.4rem;
    margin: ${theme.spacing.x4} 0 ${theme.spacing.x1} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
  h4 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-top: ${theme.spacing.x3};
    margin-bottom: ${theme.spacing.x1};
  }
  a {
    color: ${theme.colors.B50};
    text-decoration: underline;
  }
  ul {
    list-style-type: disc;
    margin-left: 3rem;
    margin-top: ${theme.spacing.half};
    margin-bottom: ${theme.spacing.half};

    li {
      padding-bottom: ${theme.spacing.half};
      color: ${theme.colors.N90};
    }
  }
  p {
    color: ${theme.colors.N90};
  }
`;

function ImportPlanContent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const translationKey = 'modals.importPlanContent';
  const [cvsFile, setCSVFile] = useState<File | null>(null);
  const [textContent, setTextContent] = useState('');

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isEmptyWorkspace = useSelector((state: any) => state.session.isEmptyWorkspace);

  let billing_ai_credits_limit = 0;
  let ai_credits_used = 0;
  billing_ai_credits_limit = currentWorkspace.billing_ai_credits_limit;
  ai_credits_used = currentWorkspace.ai_credits_used;

  const needsUpgrade = !currentWorkspace.billing_subscription_plan || currentWorkspace.pricing_version !== 3;
  const isOwner = currentMembership && ['owner'].includes(currentMembership.role);

  const hasCredit = billing_ai_credits_limit > ai_credits_used;

  // Mutation that will update the plan in the backend
  const [fillFromCSVMutation, { isLoading, isError }]: [any, any] = useMutation(remoteApi.fillFromCSV, {
    onSuccess: (_, params) => {
      const { planId } = params;
      const planRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', currentWorkspace.slug).replace(
        ':planId',
        planId,
      );
      history.push(`${planRoute}?onboarding=1`);
      if (isEmptyWorkspace) {
        dispatch(setIsEmptyWorkspace(false));
      }

      dispatch(setGlobalModalContent(''));
    },
  });

  // Get the starting date from the plan start_at and reminderConfig setting
  const getRemindersStartAt = (start_at: Date) => {
    if (!start_at) {
      return null;
    }

    // Clone the starting date
    const date = new Date(start_at.getTime());

    // The date offset is the default weekly_reminder_day set at the workspace level.
    const dateOffset = currentWorkspace.weekly_reminder_day;

    // Calculate difference between current date and default reminder day.
    const daysUntilOffset = (dateOffset - date.getDay()) % 7;

    // Return the first "weekly_reminder_day" after the start_at date.
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + daysUntilOffset + 7);
  };

  const handleCreateNewPlan = (e: any) => {
    e.preventDefault();
    const refDate = Date.now();
    const start_at = startOfQuarter(refDate);
    const finish_at = endOfQuarter(refDate);
    const title = `${currentWorkspace.name} OKRs`;
    const reminders_start_at = getRemindersStartAt(start_at);
    const mutationParams = {
      workspaceSlug: currentWorkspace.slug,
      plan: {
        title,
        reminders_start_at,
        reminders_period: 'week',
        reminders_interval: 1,
        reminders_hour: currentWorkspace.weekly_reminder_hour,
        start_at,
        finish_at,
      },
    };
    createPlanMutation(mutationParams);
  };

  // Mutation that will update the plan in the backend
  const [fillFromTextMutation, { isLoading: isLoadingPlansStructure, isError: isErrorPlansStructure }]: [any, any] =
    useMutation(remoteApi.fillFromText, {
      onSuccess: (_, params) => {
        const { planId } = params;
        const planRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', currentWorkspace.slug).replace(
          ':planId',
          planId,
        );
        history.push(`${planRoute}?onboarding=1`);
        if (isEmptyWorkspace) {
          dispatch(setIsEmptyWorkspace(false));
        }
        dispatch(setGlobalModalContent(''));
      },
    });

  // Mutation that will create the workspace in the backend
  const [createPlanMutation, { isLoading: isCreatingPlan }]: [any, any] = useMutation(remoteApi.createPlan, {
    onSuccess: (response: any) => {
      const createdPlan = response.data;
      if (cvsFile) {
        // TODO: UNSKIP
        const params = {
          planId: createdPlan.nano_slug,
          file: cvsFile,
        };
        fillFromCSVMutation(params);
      } else {
        const params = {
          planId: createdPlan.nano_slug,
          text: textContent,
        };
        fillFromTextMutation(params);
      }
    },
  });

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleCSVFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputTarget = e.target;
    setCSVFile(inputTarget.files ? inputTarget.files[0] : null);
  };

  if (!hasCredit) {
    return (
      <ModalGrid>
        <ModalHeader>
          <h2>Import your goals with AI</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </ModalHeader>
        <ModalContent>
          <TipsContent>
            <TabbyThinking>
              <img src={NoCreditsImg} alt="Tabby sleeping" />
            </TabbyThinking>
            <p>{t(`modals.chatAIInitiatives.lowCredits`)}</p>
            <p className="subtle">
              {needsUpgrade && (
                <>
                  {isOwner && (
                    <Trans
                      i18nKey={`modals.chatAIInitiatives.upgradeCredits`}
                      components={{
                        Link: <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel} />,
                      }}
                    />
                  )}
                  {!isOwner && (
                    <small>
                      <Trans
                        i18nKey={`modals.chatAIInitiatives.upgradeCredits`}
                        components={{
                          Link: <b />,
                        }}
                      />
                    </small>
                  )}

                  <Trans
                    i18nKey={`modals.chatAIInitiatives.writeReview`}
                    components={{
                      a: (
                        <a
                          href="https://guides.tability.app/docs/other/how-to-get-more-ai-credits"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          text
                        </a>
                      ),
                    }}
                  />
                </>
              )}
            </p>
          </TipsContent>
        </ModalContent>
      </ModalGrid>
    );
  }

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>Import your goals with AI</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Wrapper>
          <form onSubmit={handleCreateNewPlan}>
            <Block>
              <PlanInfoContainer>
                <b>{t(`${translationKey}.option1Title`)}</b>
                <small className="subtle">{t(`${translationKey}.option1Info`)}</small>
              </PlanInfoContainer>
              <input type="file" accept=".csv" onChange={handleCSVFileChange} required />
              <div>
                <KoalaButton
                  type="submit"
                  loading={isLoading || isCreatingPlan}
                  disabled={isLoading || isCreatingPlan || !cvsFile}
                >
                  {t(`${translationKey}.option1Button`)}
                </KoalaButton>
              </div>
              {isError && (
                <ErrorWrapper>
                  <ErrorContainer className="error">{t(`${translationKey}.option1Error`)}</ErrorContainer>
                  <div>
                    <KoalaButton appearance="subtle" size="small" onClick={() => window.Intercom('show')}>
                      {t('shared.getHelp')}
                    </KoalaButton>
                  </div>
                </ErrorWrapper>
              )}
            </Block>
          </form>
          <div>OR</div>
          <form onSubmit={handleCreateNewPlan}>
            <Block>
              <PlanInfoContainer>
                <b>{t(`${translationKey}.option2Title`)}</b>
                <small className="subtle">{t(`${translationKey}.option2Info`)}</small>
              </PlanInfoContainer>
              <div>
                <textarea
                  placeholder={t(`${translationKey}.option2Placeholder`) || ''}
                  value={textContent}
                  onChange={(e) => setTextContent(e.target.value)}
                  rows={10}
                  required
                />
              </div>
              <div>
                <KoalaButton
                  type="submit"
                  loading={isLoadingPlansStructure || isCreatingPlan}
                  disabled={isLoadingPlansStructure || isCreatingPlan || !textContent}
                >
                  {t(`${translationKey}.option2Button`)}
                </KoalaButton>
              </div>
              {isErrorPlansStructure && (
                <ErrorWrapper>
                  <ErrorContainer className="error">{t(`${translationKey}.option2Error`)}</ErrorContainer>
                  <div>
                    <KoalaButton appearance="subtle" size="small" onClick={() => window.Intercom('show')}>
                      {t('shared.getHelp')}
                    </KoalaButton>
                  </div>
                </ErrorWrapper>
              )}
            </Block>
          </form>
        </Wrapper>
      </ModalContent>
    </ModalGrid>
  );
}

export default ImportPlanContent;
