import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import Cookies from 'universal-cookie';

import { parseISO, differenceInCalendarDays } from 'date-fns';

import EmbeddedOutcome from 'components/EmbeddedOutcome';
import MarkdownContent from 'components/MarkdownContent';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import { getConfidenceData } from 'utils/planUtils';

import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import Loader from 'components/Loader';
import ReactTooltip from 'react-tooltip';

// API
import * as remoteApi from 'api/remote';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import KoalaNCSPie from 'koala/components/NCSPie';
import KoalaIcon from 'koala/components/Icons';
import PlanStatus from 'components/PlanStatus';
import { Trans, useTranslation } from 'react-i18next';

import TabilityIconBlack from 'components/_assets/TabilityIconBlack';
import KoalaButton from 'koala/components/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.N0};
`;

const ObjectiveBlock = styled.div`
  margin-bottom: ${theme.spacing.x3};
`;

const ObjectiveHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: ${theme.spacing.x2} 0;
  padding-bottom: ${theme.spacing.x2};

  h2 {
    font-size: 2rem;
    font-weight: 800;
  }

  border-bottom: 1px solid ${theme.colors.blockBorder};
  margin-bottom: ${theme.spacing.x1};
  padding-bottom: ${theme.spacing.x1};
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  max-width: 90rem;
  justify-content: space-between;
  padding: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x1};

  background: ${theme.colors.N3};

  svg {
    margin-right: ${theme.spacing.x1};
    width: 2rem;
  }
`;

const HeaderActionsMeta = styled.div`
  display: flex;
  align-items: center;
  div.prct {
    margin-right: ${theme.spacing.x3};
  }

  .plan-link {
    font-weight: 600;
    color: ${theme.colors.subtleText};
  }

  button {
    margin-left: ${theme.spacing.x2};
  }
`;

const Grid = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};
  margin-bottom: ${theme.spacing.x2};
  max-width: 90rem;

  .spacer {
    padding: ${theme.spacing.x1} 0;
  }
`;

const Panel = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  &.no-padding {
    padding: 0;
  }
`;

const EmptyObjective = styled.div`
  padding: ${theme.spacing.x2} 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const PlanOverview = styled.div`
  display: grid;
  gap: ${theme.spacing.x2};
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media ${theme.devices.mobile} {
    grid-template-columns: 1fr 1fr;
  }
`;

const InfoBlock = styled.div`
  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-radius: 4px;
  h3 {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    min-height: 2.4rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: ${theme.spacing.x1};
    font-weight: 800;
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
}

function PublicPlanPresentationOverview(props: Props) {
  const cookies = new Cookies();
  const { t } = useTranslation();
  const COOKIENAME = '_tbty_pp';

  const public_access_password = cookies.get(COOKIENAME);

  const params = {
    public_access_password,
  };

  const { plan } = props;

  const { workspace } = plan;
  const workspaceUrl = `/${workspace.slug}`;
  const planUrl = `${workspaceUrl}/plans/${plan.nano_slug}/outcomes`;
  const currentWorkspace = workspace;

  const [objectives, setObjectives] = useState<TabilityTypes.Objective[]>([]);
  const [outcomesByObjective, setOutcomesByObjective] = useState<{ [key: string]: TabilityTypes.Outcome[] }>({});

  // Load the data for the nav
  // Get the objectives
  const { isLoading: objectivesAreLoading } = useQuery(
    [queryKeys.objectives, plan.id, params],
    remoteApi.fetchPublicPlanObjectives,
    {
      staleTime: 0,
      onSuccess: (response) => {
        const objectives: TabilityTypes.Objective[] = response.data;
        setObjectives(objectives);
      },
    },
  );

  // Get the outcomes
  const { isLoading: outcomesAreLoading } = useQuery(
    [queryKeys.outcomes, plan.id, params],
    remoteApi.fetchPublicPlanOutcomes,
    {
      staleTime: 0,
      onSuccess: (response) => {
        const outcomes: TabilityTypes.Outcome[] = response.data;
        const outcomesByObjective: { [key: string]: TabilityTypes.Outcome[] } = {};
        // Iterate on each outcome and group items by objective_id in an array
        outcomes.forEach((outcome) => {
          if (outcomesByObjective[outcome.objective_id]) {
            outcomesByObjective[outcome.objective_id].push(outcome);
          } else {
            outcomesByObjective[outcome.objective_id] = [outcome];
          }
        });
        setOutcomesByObjective(outcomesByObjective);
      },
    },
  );

  if (objectivesAreLoading || outcomesAreLoading) {
    return <Loader size="big" />;
  }

  const planFinishAt = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  let differenceInDays = planFinishAt ? differenceInCalendarDays(planFinishAt, new Date()) : 0;
  if (differenceInDays < 0) {
    differenceInDays = 0;
  }
  // Strip HTML from text to see if we have an empty field.
  // @ts-ignore
  let doc = new DOMParser().parseFromString(plan.notes, 'text/html');
  const notesAreEmpty = !plan.notes || !doc.body.textContent || doc.body.textContent === '';

  const confidenceData = getConfidenceData(plan);

  const planStartAtISO = plan && plan.start_at ? parseISO(plan.start_at) : null;
  const planFinishAtISO = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  let planDaysCount = planFinishAtISO && planStartAtISO ? differenceInCalendarDays(planFinishAtISO, planStartAtISO) : 0;
  let daysSpent = planStartAtISO ? differenceInCalendarDays(new Date(), planStartAtISO) : 0;
  let daysLeft = planFinishAtISO ? Math.max(0, differenceInCalendarDays(planFinishAtISO, new Date())) : 0;
  let days_progress_prct = planDaysCount ? (daysSpent / planDaysCount) * 100 : 0;

  const timelineProgressData = {
    progressPercentage: days_progress_prct,
    colorType: 'B',
    centerLabel: [`${daysLeft}`, t('shared.time.daysLeft', { count: daysLeft })],
  };

  const outcomeProgressData = {
    progressPercentage: plan.outcome_progress_prct * 100,
    colorType: 'B',
    centerLabel: `${Math.round(plan.outcome_progress_prct * 100)}%`,
  };

  const initiativeProgressData = {
    progressPercentage: plan.initiative_progress_prct * 100,
    colorType: 'B',
    centerLabel: `${plan.closed_initiatives_count}/${plan.total_initiatives_count}`,
  };

  return (
    <>
      <Container>
        <HeaderActions>
          <HeaderActionsMeta>
            <TabilityIconBlack />
            <a href={planUrl} className="plan-link" target="_blank" rel="noopener noreferrer">
              {plan.title}
            </a>
          </HeaderActionsMeta>
          <HeaderActionsMeta>
            <KoalaButton href={planUrl} target="_blank" rel="noopener noreferrer" appearance="subtle" size="small">
              {t('workspaceEmbedOutcome.viewButton')}
            </KoalaButton>
          </HeaderActionsMeta>
        </HeaderActions>
        <Grid>
          <Header>
            <h1>
              <a href={planUrl} target="_blank" rel="noopener noreferrer">
                {plan.title}
              </a>
            </h1>
            <PlanStatus plan={plan} />
          </Header>
          <PlanOverview>
            <InfoBlock>
              <h3>{t('publicPlan.timeline')}</h3>
              <KoalaCircularProgress data={timelineProgressData} size="medium" />
            </InfoBlock>
            <InfoBlock>
              <h3>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</h3>
              <KoalaCircularProgress data={outcomeProgressData} size="medium" />
            </InfoBlock>
            <InfoBlock>
              <h3>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</h3>
              <KoalaCircularProgress data={initiativeProgressData} size="medium" />
            </InfoBlock>
            <InfoBlock>
              <h3>
                {t('shared.ncs.title')}
                <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
              </h3>
              <ReactTooltip
                place="bottom"
                type="dark"
                className="tooltip"
                effect="solid"
                id="explain-ncs"
                delayHide={500}
                clickable={true}
              >
                <Trans i18nKey="shared.ncs.tooltip" />{' '}
                <a
                  href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('shared.learnMore')}
                </a>
              </ReactTooltip>
              <KoalaNCSPie
                centerLabel={[Math.round(plan.ncs).toString(), t('shared.ncs.acronym')]}
                data={confidenceData}
                size="medium"
              />
            </InfoBlock>
          </PlanOverview>
          {plan.notes && (
            <div>
              <Panel>
                {!notesAreEmpty && <MarkdownContent source={plan.notes} />}
                {notesAreEmpty && (
                  <p className="subtle">
                    <em>{t('publicPlan.noNotes')}</em>
                  </p>
                )}
              </Panel>
            </div>
          )}
          <div>
            <Panel className="no-padding">
              {objectives.map((objective) => {
                const outcomesToDisplay = outcomesByObjective[objective.id] || [];
                return (
                  <ObjectiveBlock key={objective.id}>
                    <ObjectiveHeader>
                      <h2>{objective.title}</h2>
                    </ObjectiveHeader>
                    {outcomesToDisplay.length === 0 && (
                      <EmptyObjective className="subtle">
                        {t('publicPlan.noOutcomes', {
                          objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
                          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                        })}
                      </EmptyObjective>
                    )}
                    {outcomesToDisplay.map((outcome) => {
                      //const outcome = outcomes.find((o) => o.id === outcomeId);
                      if (outcome) {
                        return <EmbeddedOutcome outcome={outcome} key={outcome.id} planId={plan.nano_slug} />;
                      } else {
                        return null;
                      }
                    })}
                  </ObjectiveBlock>
                );
              })}
            </Panel>
          </div>
        </Grid>
      </Container>
    </>
  );
}

export default PublicPlanPresentationOverview;
