import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as remoteApi from 'api/remote';
import { Objective, Outcome, Plan } from 'types';
import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import PlansList from './PlansList';
import ObjectivesList from './ObjectivesList';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  outcomeId: string;
}

function MoveOutcome(props: Props) {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.moveOutcome';
  const { outcomeId } = props;
  const dispatch = useDispatch();
  const [outcome, setOutcome] = useState<Outcome>();
  const [objective, setObjective] = useState<Objective>();
  const [plan, setPlan] = useState<Plan>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // OUTCOME
  // Get the outcome details
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = 0;
  useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
    onSuccess: (response: any) => {
      const outcome: Outcome = response.data;
      setOutcome(outcome);
      setPlan(outcome.plan);
      setObjective(outcome.objective);
    },
  });

  const [updateOutcomeMutation]: [any, any] = useMutation(remoteApi.updateOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = () => {
    const params = {
      // @ts-ignore
      objective_id: objective.id,
    };
    updateOutcomeMutation({
      // @ts-ignore
      outcomeId: outcome.id,
      outcome: params,
    });
    dispatch(setGlobalModalContent(''));
  };

  const handleSetPlan = (plan: any) => {
    setPlan(plan);
    setObjective(undefined);
  };

  const allowSave = outcome && plan && objective && outcome.objective_id !== objective.id;

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`, { label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1) })}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        {outcome && (
          <>
            <FormField>
              <Trans
                components={{ b: <b /> }}
                i18nKey={`${translationKey}.description`}
                values={{ title: outcome.title }}
              />
            </FormField>
            <PlansList plan={plan} setPlan={handleSetPlan} />

            <ObjectivesList plan={plan} objective={objective} setObjective={setObjective} />
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!allowSave}>
          {t('shared.save')}
        </KoalaButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default MoveOutcome;
