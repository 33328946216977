import React from 'react';
import { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import theme from 'theme';

// API
import * as remoteApi from 'api/remote';

// Components
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { Base64 } from 'js-base64';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  a {
    text-decoration: underline;
  }
`;

function InstallSalesforce() {
  const location = useLocation();
  const { t } = useTranslation(undefined, { useSuspense: false });

  // Extract params from the URL
  const params = new URLSearchParams(location.search);
  const code = params.get('code');

  const state = params.get('state');
  const stateDecoded = state ? JSON.parse(Base64.decode(state)) : null;
  const workspaceSlug = stateDecoded ? stateDecoded.slug : '';
  const redirectAfter = stateDecoded ? stateDecoded.redirect_after : '';
  const domain = stateDecoded ? stateDecoded.domain : '';
  const [installSalesforceMutation, { isError, isFetching, isSuccess }]: [any, any] = useMutation(
    remoteApi.installSalesforce,
    {
      onSuccess: () => {
        window.location.href = redirectAfter;
      },
    },
  );

  useEffect(() => {
    if (code) {
      const body = {
        code,
        domain,
      };
      const params = {
        body,
        workspaceSlug,
      };
      installSalesforceMutation(params);
    }
  }, [code, installSalesforceMutation, state, workspaceSlug, domain]);

  return (
    <Container>
      {isFetching && <Loader />}
      {isSuccess && <p>{t('installIntegration.redirecting')}</p>}
      {isError && (
        <Fragment>
          <p>{t('installIntegration.connectError', { integration: 'Salesforce' })}</p>
          <p>
            <Link to="/">{t('installIntegration.backLogin')}</Link>
          </p>
        </Fragment>
      )}
    </Container>
  );
}

export default InstallSalesforce;
