import FormField from 'components/FormField';
import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Outcome } from 'types';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';

interface CurrentValueProps {
  outcome: Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;

  // Query keys and query params
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:amplitude:${outcome.data_source_type}`,
    'current',
  ];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null || currentValue === undefined) {
    return <FormField>Sorry, an error occurred while fetching the current value.</FormField>;
  }

  const dataTypeToLabel: any = {
    issues_created: '<number> issues created',
    issues_completed: '<number> issues completed',
    avg_session_length: '<number> seconds',
    avg_session_per_user: '<number> sessions per user',
    active_users: '<number> active users',
    new_users: '<number> new users',
  };

  const label = dataTypeToLabel[outcome.data_source_type || ''];
  const labelWithNumber = label.replace('<number>', currentValue.toLocaleString());
  return (
    <FormField>
      <label>Preview value</label>
      <p>{labelWithNumber}</p>
    </FormField>
  );
}

interface Props {
  outcome: Outcome;
  workspaceSlug: string;
}

// Component that loads the current value of from the data source
function AmplitudeSource(props: Props) {
  const { outcome } = props;
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const handleUpdate = (e: any) => {
    e.preventDefault();

    let body = {
      data_source_origin: 'amplitude',
      data_source_type: data_source_type,
      data_source_meta: {},
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const availableDataSourceTypes = [
    'issues_created',
    'issues_completed',
    'active_users',
    'new_users',
    'avg_session_length',
    'avg_session_per_user',
  ];

  const hasChanged = outcome.data_source_type !== data_source_type;
  return (
    <>
      <FormField>
        <label>Change Metric type</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">Select a metric</option>
          <option value="issues_created">Issues created</option>
          <option value="issues_completed">Issues completed</option>
          <option value="active_users">Number of active users</option>
          <option value="new_users">Number of new users</option>
          <option value="avg_session_length">Average session length</option>
          <option value="avg_session_per_user">Average sessions per user</option>
        </select>
      </FormField>
      {hasChanged && (
        <FormField>
          <KoalaButton
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={!data_source_type || isUpdating}
            appearance="subtle"
          >
            Save & preview
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'amplitude' &&
        outcome.data_source_type &&
        outcome.data_source_type === data_source_type && (
          <>{availableDataSourceTypes.includes(outcome.data_source_type) && <CurrentValue outcome={outcome} />}</>
        )}
    </>
  );
}

export default React.memo(AmplitudeSource);
