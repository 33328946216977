import styled from 'styled-components';
import theme from 'theme';

export const BlockMenu = styled.div`
  position: absolute;
  right: ${theme.spacing.x1};
  top: -30px;
  z-index: 1;
  background: #fff;
  padding: ${theme.spacing.half};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};

  display: flex;
  gap: ${theme.spacing.half};

  button {
    &.button--menu {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: transparent;
      color: #bdbdbd;
      border: 0;
      border-radius: 4px;
      font-weight: 400;
      width: 2.2rem;
      min-width: 2.2rem;
      height: 2.2rem;
      font-size: 1.4rem;
      padding: 0.4rem;

      polygon {
        stroke: #737373;
      }

      &:hover,
      &:focus {
        background-color: #ddd;
        color: #949494;
      }

      &:active {
        background-color: #dcdcdc;
        background-image: none;
        border-color: #b5b5b5;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
      }

      .value {
        color: #737373;
        font-weight: 600;
        padding-left: 0.4rem;
      }
    }
  }
`;

export const BlockGutter = styled.div`
  grid-area: gutter;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 2rem;
  gap: ${theme.spacing.half};

  circle {
    fill: ${theme.colors.N40};
  }

  .AriaMenuButton {
    opacity: 0;
    transition: all ease 0.2s;
  }

  .icon-button {
    opacity: 0;
    transition: all ease 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    border-radius: 4px;
    font-weight: 400;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.4rem;
    padding: 0 ${theme.spacing.x1};

    img {
      height: 1.5rem;
    }
  }

  &.commented {
    background: ${theme.colors.warningBg};
  }
`;
export const BlockContent = styled.div`
  display: flex;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  grid-area: content;
  background: transparent;
  margin: 0;
  flex-wrap: wrap;
  .block-title {
    display: flex;
  }

  .objective {
    font-weight: 600;
  }

  .initiative {
  }

  &.normal-font {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  }
`;
export const BlockMeta = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  grid-area: meta;
  display: flex;
  gap: ${theme.spacing.x2};
  justify-content: flex-start;

  .spacer {
    flex: 1;
  }

  button.tpl {
    line-height: 2rem;
    height: 2rem;
    font-size: 1.4rem;
  }
  &.normal-font {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  }
`;

const BlockGrid = styled.div`
  position: relative;
  display: grid;
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 0.6rem 0.8rem;
  margin: 0.2rem 0;
  grid-template-columns: 18rem 1fr 32rem;
  grid-template-rows: auto;
  grid-template-areas: 'gutter content meta';
  align-items: start;
  column-gap: 1.6rem;

  @media ${theme.devices.desktop} {
    grid-template-columns: 14rem 1fr 28rem;
  }

  .meta {
  }

  &:hover,
  &.selected,
  &:focus {
    .AriaMenuButton {
      opacity: 1;
    }

    .icon-button {
      opacity: 1;
    }

    background: #f3f3f3;
  }

  &.no-hover-bg {
    &:hover {
      background: transparent;
    }
  }

  .option-menu {
    opacity: 0;
    display: none;
  }

  &:hover {
    .option-menu {
      opacity: 1;
      display: flex;
    }

    .masked-icon {
      opacity: 1;
    }
  }

  .force-visible {
    opacity: 1;
  }

  .masked-icon {
    opacity: 0;
  }

  &.commented {
    background: ${theme.colors.warningBg};
  }

  &.compact {
    padding: 0 0.8rem;
    margin-bottom: 0rem;

    &:hover {
      background: none;
    }
  }

  &.ai-feedback-highlight {
    border: 2px solid transparent;
    background: rgba(188, 150, 237, 0.2);
  }
`;

export default BlockGrid;
