import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache, useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { generateSlug } from 'utils/naming';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import * as userUtils from 'utils/userUtils';

import { User } from 'types';
import { sessionActions } from 'state/actions/';
import { AxiosResponse } from 'axios';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import CopyTextInput from 'components/CopyTextInput';
import FormField from 'components/FormField';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';

import timeZones from './timeZones';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  margin-bottom: ${theme.spacing.x2};
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const DummyBlock = styled.div`
  padding: ${theme.spacing.x2};
  background: ${theme.colors.warningBg};
  border-radius: 8px;
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingsDetails(props: Props) {
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { workspace } = props;

  const [name, setName] = useState(workspace.name);
  const [slug, setSlug] = useState(workspace.slug);
  const [time_zone, setTimeZone] = useState(workspace.time_zone);

  const [auth_token, setAuthToken]: [any, any] = useState(null);

  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);

  const isTabilityUser = userUtils.isTabilityUser(currentUser);

  // We need to fetch the admin details to get the workspace auth token
  const queryKey = [queryKeys.currentWorkspace, workspace.slug, 'admin'];
  useQuery(queryKey, remoteApi.fetchWorkspaceAdminDetails, {
    staleTime: 0,
    onSuccess: (response) => {
      const workspace: TabilityTypes.Workspace = response.data;
      setAuthToken(workspace.auth_token);
    },
  });

  // Workspace mutations
  const [updateWorkspaceMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.updateWorkspace, {
    onSuccess: () => {
      if (slug !== workspace.slug) {
        const { origin } = window.location;
        window.location.replace(`${origin}/${slug}/settings`);
      } else {
        queryCache.invalidateQueries(queryKeys.currentWorkspace);
      }
    },
  });

  const [updateAccountMutation, { isLoading: isResettingExplainer }] = useMutation(remoteApi.updateAccount, {
    onSuccess: (response: AxiosResponse<User>) => {
      const user: User = response.data;
      dispatch(sessionActions.setCurrentUser(user));
    },
  });

  const resetExplainer = () => {
    const params = {
      explainer_inbox_seen: false,
      explainer_plans_seen: false,
      explainer_plan_dashboard_seen: false,
      explainer_plan_kanban_seen: false,
      explainer_plan_retrospectives_seen: false,
      explainer_insights_seen: false,
      explainer_map_seen: false,
      explainer_filters_seen: false,
      explainer_people_dashboard_seen: false,
      explainer_teams_seen: false,
      explainer_team_dashboard_seen: false,
      explainer_standup_seen: false,
      explainer_outcome_dashboard_seen: false,
      explainer_initiative_dashboard_seen: false,
      explainer_presentation_seen: false,
      explainer_editor_seen: false,
    };
    updateAccountMutation({
      user: params,
    });
  };

  // Workspace mutations
  const [resetDummyDataMutation, { isLoading: isLoadingDumnmy }]: any = useMutation(remoteApi.resetWorkspaceDummyData, {
    onSuccess: () => {
      alert('dummy data reloaded');
    },
  });

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'slug':
        const slug = generateSlug(value);
        setSlug(slug);
        break;
      case 'time_zone':
        setTimeZone(value);
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      name,
      slug,
      time_zone,
    };
    updateWorkspaceMutation({
      workspaceId: workspace.id,
      workspace: params,
    });
  };

  const handleResetDummyData = () => {
    resetDummyDataMutation(workspace.slug);
  };

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'name':
        return t('errors.workspaceName');
      case 'slug':
        return t('errors.workspaceID');
      default:
        return '';
    }
  };

  // Error classnames used to highlight input fields that have errors
  const nameClassname = errors['name'] ? 'error' : '';
  const slugClassname = errors['slug'] ? 'error' : '';

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsDetails.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsDetails.title')}</h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <label>{t('workspaceSettingsDetails.nameLabel')}</label>
              <input
                type="text"
                name="name"
                className={`${nameClassname} small`}
                autoComplete="off"
                placeholder={t('workspaceSettingsDetails.nameLabel') ?? 'Workspace name'}
                value={name}
                required={false}
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsDetails.idLabel')}</label>
              <input
                type="text"
                name="slug"
                value={slug}
                className={`${slugClassname} small`}
                autoComplete="off"
                placeholder={t('workspaceSettingsDetails.idLabel') ?? 'Workspace ID'}
                required={false}
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsDetails.timezone')}</label>
              <select value={time_zone} onChange={handleChange} name="time_zone">
                {timeZones.map((t) => (
                  <option value={t} key={t}>
                    {t}
                  </option>
                ))}
              </select>
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsDetails.version')}</label>
              <span>2.0</span>
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsDetails.authToken')}</label>
              <CopyTextInput text={auth_token} />
            </FormField>
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading}>
                {t('shared.save')}
              </KoalaButton>
            </Actions>
          </Form>
          {isTabilityUser && (
            <DummyBlock>
              <p>
                <h3> Reset sample data (TABILITY ONLY!)</h3>
              </p>
              <p>Click on the button below to recreate the sample plan</p>
              <p>ONLY FOR TABILITY, NORMAL USERS DON'T SEE THIS</p>
              <p>
                <KoalaButton
                  onClick={handleResetDummyData}
                  appearance="subtle"
                  loading={isLoadingDumnmy}
                  disabled={isLoadingDumnmy}
                >
                  Reset dummy data
                </KoalaButton>
                <KoalaButton
                  onClick={resetExplainer}
                  appearance="subtle"
                  loading={isResettingExplainer}
                  disabled={isResettingExplainer}
                >
                  Reset explainers
                </KoalaButton>
              </p>
            </DummyBlock>
          )}
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
