import KoalaIcon from 'koala/components/Icons';
import React from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';
import PilotImg from './running_pilot.png';

const TutorialLink = styled.a`
  display: flex;
  gap: ${theme.spacing.x1};
  color: ${theme.colors.N0};
  svg {
    path {
      fill: ${theme.colors.N0};
    }
  }

  padding: 1.2rem ${theme.spacing.x2};
  width: 100%;
  align-items: center;
  &:hover {
    background: ${theme.colors.N80};
    cursor: pointer;

    .plan-icon {
      background: ${theme.colors.N10};
    }
  }
  .plan-icon {
    background: ${theme.colors.N5};
    padding: ${theme.spacing.x1};
    border-radius: 4px;
  }
  .uni {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
  }
`;

const TutorialInternalLink = styled(Link)`
  display: flex;
  gap: ${theme.spacing.x1};

  padding: 1.2rem ${theme.spacing.x2};
  width: 100%;
  align-items: center;
  color: ${theme.colors.N0};
  svg {
    path {
      fill: ${theme.colors.N0};
    }
  }
  &:hover {
    background: ${theme.colors.N80};
    cursor: pointer;

    .plan-icon {
      background: ${theme.colors.N10};
    }
  }
  .plan-icon {
    background: ${theme.colors.N5};
    padding: ${theme.spacing.x1};
  }
  .uni {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    padding: ${theme.spacing.x2} ${theme.spacing.x2} ${theme.spacing.x1} ${theme.spacing.x2};
    display: flex;
    gap: ${theme.spacing.x1};
  }
  position: relative;

  img {
    width: 6rem;
    position: absolute;
    right: ${theme.spacing.x2};
    bottom: ${theme.spacing.x1};
  }
`;

function Tutorials() {
  const { t } = useTranslation();
  const translationKey = 'workspaceInbox.tutorials';
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  const recordClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    amplitudeUtils.sendAmplitudeData('app.workspace.inbox.tutorial.click', {
      'Tability Platform': 'web',
      'Tability Version': 2,
      'Tutorial URL': e.currentTarget.href,
    });
  };

  return (
    <Container>
      <h3>{t(`${translationKey}.title`)}</h3>
      <TutorialLink
        onClick={recordClick}
        href="https://guides.tability.app/docs/about-tability/how-does-tability-work"
        target="_blank"
        rel="noopener noreferrer"
      >
        <KoalaIcon iconName="info" />
        {t(`${translationKey}.about`)}
      </TutorialLink>
      <TutorialInternalLink onClick={recordClick} to={`/${currentWorkspace.slug}/accelerator`}>
        <KoalaIcon iconName="compass" />
        {t(`${translationKey}.accelerator`)}
      </TutorialInternalLink>
      <img src={PilotImg} alt="Pilot" />
    </Container>
  );
}

export default Tutorials;
