import React, { ChangeEvent, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';

import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
// import { useMutation, useQuery, useQueryCache } from 'react-query';
import KoalaButton from 'koala/components/Button';
import KoalaTextBadge from 'koala/components/TextBadge';
import FormField from 'components/FormField';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import yaml from 'js-yaml';
import queryKeys from 'config/queryKeys';

import { shallowEqual, useSelector } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Workspace } from 'types';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Avatar from 'components/Avatar';

const TitleContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const TabbyThinking = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
  img {
    width: 12rem;
  }
  padding: ${theme.spacing.x2};
`;

const EmptyTips = styled.div`
  font-size: 1.2rem;
  ul {
    list-style-type: disc;
    margin-left: 3rem;
    margin-top: ${theme.spacing.half};
    margin-bottom: ${theme.spacing.half};

    li {
      padding-bottom: ${theme.spacing.half};
      color: ${theme.colors.N90};
    }
  }
  p {
    color: ${theme.colors.N90};
  }
  margin-bottom: ${theme.spacing.x2};

  background: ${theme.colors.N3};
  padding: ${theme.spacing.x2};
  border-radius: 4px;
`;

const TipsContent = styled.div`
  font-size: 1.5rem;
  h3 {
    font-size: 2.4rem;
    margin: ${theme.spacing.x4} 0 ${theme.spacing.x1} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
  h4 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-top: ${theme.spacing.x3};
    margin-bottom: ${theme.spacing.x1};
  }
  a {
    color: ${theme.colors.B50};
    text-decoration: underline;
  }
  ul {
    list-style-type: disc;
    margin-left: 3rem;
    margin-top: ${theme.spacing.half};
    margin-bottom: ${theme.spacing.half};

    li {
      padding-bottom: ${theme.spacing.half};
      color: ${theme.colors.N90};
    }
  }
  p {
    color: ${theme.colors.N90};
  }
`;

const PreviewContainer = styled.div`
  border: 2px dotted ${theme.colors.N50};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  margin: ${theme.spacing.x1} 0;
`;

const PreviewLine = styled.div`
  display: grid;
  grid-template-columns: 9rem 1fr;
  gap: ${theme.spacing.x1};

  div:first-of-type {
    justify-self: flex-end;
  }

  div:nth-of-type(2) {
    font-family: monospace, 'Courier New';
    font-size: 1.2rem;
  }
`;

const ChatInput = styled.div`
  grid-area: input;
  max-width: 80rem;
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 5rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }
`;

const ChatPanel = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr auto;
  gap: ${theme.spacing.x4};
  grid-template-areas:
    'chat preview'
    'input preview';
  height: 100%;
  &.initial {
    justify-content: center;
  }
`;

const ChatPreview = styled.div`
  grid-area: preview;
  max-width: 60rem;
  min-width: 50rem;
`;

const Chat = styled.div`
  grid-area: chat;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x4};
  max-width: 80rem;
  max-height: 100%;
  overflow-y: auto;
  padding-right: ${theme.spacing.x1};
`;

const Message = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${theme.spacing.x2};
  .message_avatar {
    width: 3.2rem;
    height: 3.2rem;

    .img {
      width: 100%;
    }
  }
  p + p {
    margin-top: ${theme.spacing.x1};
  }
  strong {
    font-weight: 800;
  }

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    width: 100%;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
    margin-top: 8px;
  }
`;

const NoCreditsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.x2};
`;

const WritingImg = 'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-typing_vjitqx.gif';
const NoCreditsImg =
  'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-sleeping_tvsdtw.gif';

interface Props {
  planId: string;
}

function PopulateAI(props: Props) {
  const { planId } = props;
  const dispatch = useDispatch();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentPing = useSelector((state: any) => state.session.currentPing, shallowEqual);
  const { t } = useTranslation();
  const translationKey = 'modals.chatAIOutcomes';

  const { usage } = currentPing;
  let billing_ai_credits_limit = 0;
  let ai_credits_used = 0;
  if (usage) {
    billing_ai_credits_limit = usage.billing_ai_credits_limit;
    ai_credits_used = usage.ai_credits_used;
  }

  const [content, setContent] = useState('');
  const [previewYml, setPreviewYml] = useState<any>(null);
  const [previewData, setPreviewData] = useState<any>(null);
  const [aiThread, setAiThread] = useState<any>(null);
  const [messages, setMessages] = useState<any>([
    {
      role: 'assistant',
      content:
        'Hello! I am your AI assistant. I can help you generate outcomes for your objectives. Describe your objective in 1 or 2 sentences and I will suggest outcomes for you.',
    },
  ]);

  const [problemDescription, setProblemDescription] = useState<string>('');
  // const [successDescription, setSuccessDescription] = useState<string>('');
  const [contextDescription, setContextDescription] = useState<string>('');
  const [initiatialSuggestion, setInitialSuggestion] = useState(false);

  const messagesEndRef: any = useRef(null);

  const scrollToBottom = () => {
    // Use scrollIntoView with behavior smooth to scroll to the bottom
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Scroll to the bottom whenever the messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Create a ref to attach to the textarea element
  const textAreaRef: any = useRef(null);

  // Use useEffect to set focus when the component mounts
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []); // Empty dependency array ensures this runs only once after initial render

  const queryCache = useQueryCache();

  const extractYmlFromContent = (content: string) => {
    let text = content.split('---');

    if (!text[1]) {
      return;
    }
    // Patterns used to parse the lines
    const objectivePattern = /Objective: (.*)/;
    const outcomePattern = /- KR[0-9]*: (.*)/;

    // Turn the lines into an array using the line breaks
    const lines = text[1].split('\n');

    let objective = null;
    const outcomes: any = [];

    lines.forEach((line) => {
      if (line.trim() === '') return; // Ignore empty lines

      line = line.trim(); // Clean whitespaces that might be at the beginning/end of the line
      const objMatch = line.match(objectivePattern); // Check if we have an objective
      if (objMatch) objective = objMatch[1].replace(/\.$/, '');

      const outMatch = line.match(outcomePattern); // Check if we have an outcome
      if (outMatch) outcomes.push(outMatch[1].replace(/\.$/, ''));
    });

    // Return the results
    const data = {
      objective: objective,
      outcomes: outcomes,
    };

    return yaml.dump(data);
  };

  // Functions to update the outcome
  const [createAiThreadForOutcomesMutation, { isLoading, isError }]: [any, any] = useMutation(
    remoteApi.createAiThreadForOutcomes,
    {
      onMutate: (variables) => {
        const userMessage = {
          role: 'user',
          content: variables.content,
        };

        const newMessages = [...messages, userMessage];
        setMessages(newMessages);
        setInitialSuggestion(true);
      },
      onSuccess: (response, variables) => {
        const aiThread = response.data;
        setAiThread(aiThread);
        setMessages(aiThread.messages);
        queryCache.invalidateQueries(queryKeys.currentPing);
        setContent('');

        try {
          const previewYml = extractYmlFromContent(aiThread.messages[aiThread.messages.length - 1].content);
          const previewData = previewYml ? yaml.load(previewYml) : null;
          setPreviewYml(previewYml);
          setPreviewData(previewData);
        } catch (e) {
          // setPreviewData(null);
          // setPreviewYml(null);
        }
      },
    },
  );

  const [postAiThreadMessageMutation, { isLoading: isLoadingChat, isError: isErrorChat }]: [any, any] = useMutation(
    remoteApi.postAiThreadMessage,
    {
      onMutate: (variables) => {
        const userMessage = {
          role: 'user',
          content: variables.content,
        };

        const newMessages = [...messages, userMessage];
        setMessages(newMessages);
      },
      onSuccess: (response, variables) => {
        const userMessage = {
          role: 'user',
          content: variables.content,
        };
        const aiThreadResponse = response.data;

        const newMessages = [...messages, userMessage, aiThreadResponse];
        setMessages(newMessages);
        setContent('');
        queryCache.invalidateQueries(queryKeys.currentPing);
        try {
          const previewYml = extractYmlFromContent(aiThreadResponse.content);
          const previewData = previewYml ? yaml.load(previewYml) : null;
          setPreviewYml(previewYml);
          setPreviewData(previewData);
        } catch (e) {
          // setPreviewData(null);
          // setPreviewYml(null);
        }
      },
    },
  );

  const [populateAIYMLPlanMutation, { isLoading: isPopulating, isError: isPopulatingError }]: [any, any] = useMutation(
    remoteApi.populateAIYMLPlan,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.currentPlan);
        queryCache.invalidateQueries(queryKeys.objectives);
        queryCache.invalidateQueries(queryKeys.outcomes);
        queryCache.invalidateQueries(queryKeys.initiatives);
        queryCache.invalidateQueries(queryKeys.currentPing);
        dispatch(setGlobalModalContent(''));
      },
    },
  );

  const handleCancel = (e: React.MouseEvent) => {
    dispatch(setGlobalModalContent(''));
  };

  const handleGeneratePreview = (e: any) => {
    e.preventDefault();
    // Don't trigger if already loading the template.
    if (isLoading) {
      return;
    }
    const params = {
      workspaceSlug: currentWorkspace.slug,
      // @ts-ignore
      content: content,
    };

    createAiThreadForOutcomesMutation(params);
  };

  const handleGenerateInitialPreview = (e: any) => {
    e.preventDefault();
    // Don't trigger if already loading the template.

    let content = `${t(`${translationKey}.problemPrompt`)}\n${problemDescription}`;

    if (contextDescription) {
      content = `${content}\n
${t(`${translationKey}.contextPrompt`)}\n${contextDescription}\n
\n
${t(`${translationKey}.questionPrompt`)}
`;
    }
    if (isLoading) {
      return;
    }
    const params = {
      workspaceSlug: currentWorkspace.slug,
      // @ts-ignore
      content: content,
    };

    createAiThreadForOutcomesMutation(params);
  };

  const handlePostAiThreadMessage = (e: any) => {
    e.preventDefault();
    // Don't trigger if already loading the template.
    if (isLoading) {
      return;
    }
    const params = {
      aiThreadId: aiThread.id,
      // @ts-ignore
      content: content,
    };

    postAiThreadMessageMutation(params);
  };

  const handlePopulatePreview = (e: any) => {
    e.preventDefault();
    // Don't trigger if already loading the template.
    if (isLoading) {
      return;
    }
    const params = {
      planId,
      // @ts-ignore
      ai_yml: previewYml,
    };

    populateAIYMLPlanMutation(params);
  };

  const handleObjectiveDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const inputName = e.target.value;
    setContent(inputName);
  };

  const hasCredit = billing_ai_credits_limit > ai_credits_used;
  const needsUpgrade = !currentWorkspace.billing_subscription_plan || currentWorkspace.pricing_version !== 3;
  const isOwner = currentMembership && ['owner'].includes(currentMembership.role);
  const objectiveLabel = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase();
  let placeholder =
    t(`${translationKey}.outcomePlaceholder`, { label: objectiveLabel }) ??
    `Describe your ${objectiveLabel} here in 1 or 2 sentences.`;

  if (aiThread) {
    placeholder = t(`${translationKey}.chatPlaceholder`);
  }

  if (!hasCredit) {
    return (
      <ModalGrid>
        <ModalHeader>
          <TitleContainer>
            <h2>{t(`${translationKey}.title`)}</h2>
          </TitleContainer>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </ModalHeader>
        <ModalContent>
          <NoCreditsContainer>
            <TabbyThinking>
              <img src={NoCreditsImg} alt="Tabby sleeping" />
            </TabbyThinking>
            <p>{t(`${translationKey}.lowCredits`)}</p>
            <p className="subtle">
              {needsUpgrade && (
                <>
                  {isOwner && (
                    <Trans
                      i18nKey={`${translationKey}.upgradeCredits`}
                      components={{
                        Link: <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel} />,
                      }}
                    />
                  )}
                  {!isOwner && (
                    <small>
                      <Trans
                        i18nKey={`${translationKey}.upgradeCredits`}
                        components={{
                          Link: <b />,
                        }}
                      />
                    </small>
                  )}

                  <Trans
                    i18nKey={`${translationKey}.writeReview`}
                    components={{
                      a: (
                        <a
                          href="https://guides.tability.app/docs/other/how-to-get-more-ai-credits"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          text
                        </a>
                      ),
                    }}
                  />
                </>
              )}
            </p>
          </NoCreditsContainer>
        </ModalContent>
        <ModalFooter>
          <p className="subtle">
            <small>
              {t(`${translationKey}.creditsUsed`, {
                used: ai_credits_used.toLocaleString(),
                limit: billing_ai_credits_limit.toLocaleString(),
              })}
            </small>
            <br />
            {needsUpgrade && (
              <>
                {isOwner && (
                  <small>
                    <Trans
                      i18nKey={`${translationKey}.upgradeCredits`}
                      components={{
                        Link: <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel} />,
                      }}
                    />
                  </small>
                )}
                {!isOwner && (
                  <small>
                    <Trans
                      i18nKey={`${translationKey}.upgradeCredits`}
                      components={{
                        Link: <b />,
                      }}
                    />
                  </small>
                )}
              </>
            )}
          </p>
        </ModalFooter>
      </ModalGrid>
    );
  }

  const hasFilledInitialForm = problemDescription;

  if (!initiatialSuggestion) {
    return (
      <ModalGrid>
        <ModalHeader>
          <TitleContainer>
            <h2>{t(`${translationKey}.title`)}</h2>
          </TitleContainer>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </ModalHeader>
        <ModalContent>
          <ChatPanel className="initial">
            <Chat>
              <Message>
                <div className="message_avatar">
                  <img
                    src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png"
                    alt="Tabby"
                    className="img"
                  />
                </div>
                <div>
                  <p>
                    <strong>Tabby</strong>
                  </p>
                  <p>{t(`${translationKey}.initialQuestionIntro`)}</p>
                  <p>
                    <Trans
                      i18nKey={`${translationKey}.initialQuestion1`}
                      components={{
                        b: <b />,
                      }}
                    />
                  </p>
                  <FormField>
                    <textarea
                      className="segment-input"
                      value={problemDescription}
                      onChange={(e) => setProblemDescription(e.target.value)}
                      rows={6}
                      placeholder={t(`${translationKey}.initialQuestion1Placeholder`) || ''}
                    />
                  </FormField>
                  <p>
                    <Trans
                      i18nKey={`${translationKey}.initialQuestion3`}
                      components={{
                        b: <b />,
                      }}
                    />
                  </p>
                  <FormField>
                    <textarea
                      rows={2}
                      className="segment-input"
                      value={contextDescription}
                      onChange={(e) => setContextDescription(e.target.value)}
                      placeholder={t(`${translationKey}.initialQuestion3Placeholder`) || ''}
                    />
                  </FormField>
                </div>
              </Message>
              {(isLoading || isLoadingChat) && (
                <TabbyThinking>
                  <img src={WritingImg} alt="Tabby thinking" />
                  <p>{t(`${translationKey}.goalsLoading`)}</p>
                </TabbyThinking>
              )}
              <div ref={messagesEndRef} />
            </Chat>
            <ChatInput>
              <Form onSubmit={handleGenerateInitialPreview}>
                <FormField style={{ marginBottom: 0 }}>
                  <div>
                    <KoalaButton
                      onClick={handleGenerateInitialPreview}
                      disabled={isLoading || !hasFilledInitialForm || !hasCredit}
                      loading={isLoading}
                    >
                      {t(`${translationKey}.generate`)}
                    </KoalaButton>
                  </div>
                </FormField>
              </Form>
            </ChatInput>
          </ChatPanel>
        </ModalContent>
        <ModalFooter>
          <p className="subtle">
            <small>
              {t(`${translationKey}.creditsUsed`, {
                used: ai_credits_used.toLocaleString(),
                limit: billing_ai_credits_limit.toLocaleString(),
              })}
            </small>
            <br />
            {needsUpgrade && (
              <>
                {isOwner && (
                  <small>
                    <Trans
                      i18nKey={`${translationKey}.upgradeCredits`}
                      components={{
                        Link: <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel} />,
                      }}
                    />
                  </small>
                )}
                {!isOwner && (
                  <small>
                    <Trans
                      i18nKey={`${translationKey}.upgradeCredits`}
                      components={{
                        Link: <b />,
                      }}
                    />
                  </small>
                )}
              </>
            )}
          </p>
        </ModalFooter>
      </ModalGrid>
    );
  }

  return (
    <ModalGrid>
      <ModalHeader>
        <TitleContainer>
          <h2>{t(`${translationKey}.title`)}</h2>
        </TitleContainer>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <ChatPanel>
          <Chat>
            {messages.map((message: any, index: number) => {
              if (message.role === 'system') {
                return null;
              }
              if (message.role === 'assistant') {
                const messageContent = message.content; //.split(`---`);
                return (
                  <Message>
                    <div className="message_avatar">
                      <img
                        src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png"
                        alt="Tabby"
                        className="img"
                      />
                    </div>
                    <div>
                      <p>
                        <strong>Tabby</strong>
                      </p>
                      {messageContent.split(`\n`).map((i: string, key: number) => {
                        if (i === '---') {
                          return (
                            <p key={key}>
                              <b>{t(`${translationKey}.suggestions`)}</b>
                            </p>
                          );
                        }
                        return <p key={key}>{i}</p>;
                      })}
                    </div>
                  </Message>
                );
              }
              return (
                <Message>
                  <div className="message_avatar">
                    <Avatar user={currentUser} />
                  </div>
                  <div>
                    <p>
                      <strong>{t(`${translationKey}.you`)}</strong>
                    </p>
                    {message.content.split(`\n`).map((i: string, key: number) => {
                      return <p key={key}>{i}</p>;
                    })}
                  </div>
                </Message>
              );
            })}
            {(isLoading || isLoadingChat) && (
              <TabbyThinking>
                <img src={WritingImg} alt="Tabby thinking" />
                <p>{t(`${translationKey}.goalsLoading`)}</p>
              </TabbyThinking>
            )}
            <div ref={messagesEndRef} />
          </Chat>
          <ChatInput>
            <Form onSubmit={handleGeneratePreview}>
              {!aiThread && !isLoading && (
                <EmptyTips>
                  <p>{t(`${translationKey}.examples`)}</p>
                  <ul>
                    <li>{t(`${translationKey}.example1`)}</li>
                    <li>{t(`${translationKey}.example2`)}</li>
                    <li>{t(`${translationKey}.example3`)}</li>
                    <li>{t(`${translationKey}.example4`)}</li>
                  </ul>
                </EmptyTips>
              )}
              <FormField style={{ marginBottom: 0 }}>
                <label>
                  {t(`${translationKey}.outcome`, { label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1) })}
                </label>
                <textarea
                  placeholder={placeholder}
                  className="segment-input"
                  value={content}
                  onChange={handleObjectiveDescriptionChange}
                  ref={textAreaRef}
                />
                <div>
                  {!aiThread && (
                    <KoalaButton
                      onClick={handleGeneratePreview}
                      disabled={isLoading || !content || !hasCredit}
                      loading={isLoading}
                    >
                      {t(`${translationKey}.generate`)}
                    </KoalaButton>
                  )}
                  {aiThread && (
                    <KoalaButton
                      onClick={handlePostAiThreadMessage}
                      disabled={isLoading || !content || !hasCredit}
                      loading={isLoading}
                      appearance="subtle"
                    >
                      {t(`${translationKey}.regenerate`)}
                    </KoalaButton>
                  )}
                </div>
              </FormField>
            </Form>
          </ChatInput>
          <ChatPreview>
            <TipsContent>
              {!isLoading && (
                <>
                  {(isError || isErrorChat) && (
                    <p>
                      <b>{t(`${translationKey}.lowCredits`)}</b>
                    </p>
                  )}
                  {isPopulatingError && (
                    <p>
                      <b>{t(`${translationKey}.errorPopulating`)}</b>
                    </p>
                  )}
                  {previewData && (
                    <>
                      <b>{t(`${translationKey}.suggestion`)}</b>
                      <PreviewContainer>
                        <PreviewLine>
                          <div>
                            <KoalaTextBadge size="small" variant="teal" edge="circle">
                              {translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}
                            </KoalaTextBadge>
                          </div>
                          <div>{previewData['objective']}</div>
                        </PreviewLine>
                        {previewData['outcomes'].map((outcome: string, index: number) => (
                          <PreviewLine key={index}>
                            <div>
                              <KoalaTextBadge size="small" variant="yellow" edge="circle">
                                #
                              </KoalaTextBadge>
                            </div>
                            <div>{outcome}</div>
                          </PreviewLine>
                        ))}
                      </PreviewContainer>
                      <KoalaButton
                        onClick={handlePopulatePreview}
                        disabled={isPopulating || !previewYml || !hasCredit}
                        loading={isPopulating}
                      >
                        {t(`${translationKey}.useSuggestion`)}
                      </KoalaButton>
                    </>
                  )}
                  {!previewData && <PreviewContainer>{t(`${translationKey}.templatePlaceholder`)}</PreviewContainer>}
                </>
              )}
            </TipsContent>
          </ChatPreview>
        </ChatPanel>
      </ModalContent>
      <ModalFooter>
        <p className="subtle">
          <small>
            {t(`${translationKey}.creditsUsed`, {
              used: ai_credits_used.toLocaleString(),
              limit: billing_ai_credits_limit.toLocaleString(),
            })}
          </small>
          <br />
          {needsUpgrade && (
            <>
              {isOwner && (
                <small>
                  <Trans
                    i18nKey={`${translationKey}.upgradeCredits`}
                    components={{
                      Link: <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel} />,
                    }}
                  />
                </small>
              )}
              {!isOwner && (
                <small>
                  <Trans
                    i18nKey={`${translationKey}.upgradeCredits`}
                    components={{
                      Link: <b />,
                    }}
                  />
                </small>
              )}
            </>
          )}
        </p>
      </ModalFooter>
    </ModalGrid>
  );
}

export default PopulateAI;
