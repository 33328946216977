import FormField from 'components/FormField';
import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Outcome } from 'types';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import styled from 'styled-components';
import theme from 'theme';

const ErrorContainer = styled.small`
  color: ${theme.colors.R80};
`;
interface CurrentValueProps {
  outcome: Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcome.id, `data_source:gitlab:${outcome.data_source_type}`, 'current'];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null || currentValue === undefined) {
    return <FormField>Sorry, an error occurred while fetching the current value.</FormField>;
  }
  const gitlabTranslation = 'modals.dataConnectors.gitlabData';

  const dataTypeToLabel: any = {
    issues_created: t(`${gitlabTranslation}.issues_created_label`),
    issues_closed: t(`${gitlabTranslation}.issues_closed_label`),
    merge_requests_created: t(`${gitlabTranslation}.merge_requests_created_label`),
    merge_requests_closed: t(`${gitlabTranslation}.merge_requests_closed_label`),
    deployments_created: t(`${gitlabTranslation}.deployments_created_label`),
    avg_deploy_time: t(`${gitlabTranslation}.avg_deploy_time_label`),
    pipelines_created: t(`${gitlabTranslation}.pipelines_created_label`),
    successful_pipelines: t(`${gitlabTranslation}.successful_pipelines_label`),
    failed_pipelines: t(`${gitlabTranslation}.failed_pipelines_label`),
  };

  const label = dataTypeToLabel[outcome.data_source_type || ''];
  const labelWithNumber = label.replace('<number>', currentValue.toLocaleString());
  return (
    <FormField>
      <label>Preview value</label>
      <p>{labelWithNumber}</p>
    </FormField>
  );
}

interface Props {
  outcome: Outcome;
  workspaceSlug: string;
}

// Component that loads the current value of from the data source
function GitlabSource(props: Props) {
  const { outcome, workspaceSlug } = props;
  const { t } = useTranslation();
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');
  const [projectOptions, setProjectOptions] = useState<KoalaSelectOption[]>([]);
  const [projectError, setProjectError] = useState('');

  const [project, setProject] = useState<number | null>(outcome?.data_source_meta?.project);

  const queryCache = useQueryCache();

  const staleTime = 0;
  const queryKey = ['gitlab_projects', workspaceSlug];
  const { isLoading } = useQuery(queryKey, remoteApi.listGitlabProjects, {
    staleTime,
    onSuccess: (response) => {
      if (!response.data?.projects) {
        setProjectOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.projects.map((project: any) => ({
        label: project.name,
        value: project.id,
      }));
      setProjectOptions(options);
    },
    onError: (error: any) => {
      const errorString = error.response.data.error;
      setProjectError(errorString);
    },
  });

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const handleUpdate = (e: any) => {
    e.preventDefault();

    let body = {
      data_source_origin: 'gitlab',
      data_source_type: data_source_type,
      data_source_meta: { project },
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const onProjectChange = (option: any) => {
    const value = option ? option.value : null;
    setProject(value);
  };

  const availableDataSourceTypes = [
    'issues_created',
    'issues_closed',
    'merge_requests_created',
    'merge_requests_closed',
    'deployments_created',
    'avg_deploy_time',
    'pipelines_created',
    'successful_pipelines',
    'failed_pipelines',
  ];
  const gitlabTranslation = 'modals.dataConnectors.gitlabData';
  const selectedOption = projectOptions.find((option) => option.value === project);
  return (
    <>
      <FormField>
        <label>{t('panels.editOutcome.changeMetric')}</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">{t('panels.editOutcome.selectMetric')}</option>
          <option value="issues_created">{t(`${gitlabTranslation}.issues_created`)}</option>
          <option value="issues_closed">{t(`${gitlabTranslation}.issues_closed`)}</option>
          <option value="merge_requests_created">{t(`${gitlabTranslation}.merge_requests_created`)}</option>
          <option value="merge_requests_closed">{t(`${gitlabTranslation}.merge_requests_closed`)}</option>
          <option value="deployments_created">{t(`${gitlabTranslation}.deployments_created`)}</option>
          <option value="avg_deploy_time">{t(`${gitlabTranslation}.avg_deploy_time`)}</option>
          <option value="pipelines_created">{t(`${gitlabTranslation}.pipelines_created`)}</option>
          <option value="successful_pipelines">{t(`${gitlabTranslation}.successful_pipelines`)}</option>
          <option value="failed_pipelines">{t(`${gitlabTranslation}.failed_pipelines`)}</option>
        </select>
      </FormField>
      <FormField>
        <label>{t(`${gitlabTranslation}.project`)}</label>
        {isLoading && <KoalaLoader />}
        {!isLoading && (
          <div style={{ width: '50%' }}>
            <KoalaSelect
              handleChange={(options) => onProjectChange(options)}
              placeholder={t(`${gitlabTranslation}.projectPlaceholder`) ?? 'Select Gitlab project'}
              selectedOption={selectedOption}
              options={projectOptions}
              className="small"
              isClearable
            />
            {projectError && <ErrorContainer>{projectError}</ErrorContainer>}
          </div>
        )}
      </FormField>
      <FormField>
        <KoalaButton
          onClick={handleUpdate}
          loading={isUpdating}
          disabled={!data_source_type || isUpdating || !project}
          appearance="subtle"
        >
          {t('panels.editOutcome.savePreview')}
        </KoalaButton>
      </FormField>
      {outcome.data_source_origin === 'gitlab' &&
        outcome.data_source_type &&
        project &&
        project === outcome.data_source_meta.project &&
        outcome.data_source_type === data_source_type && (
          <>{availableDataSourceTypes.includes(outcome.data_source_type) && <CurrentValue outcome={outcome} />}</>
        )}
    </>
  );
}

export default React.memo(GitlabSource);
