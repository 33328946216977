import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
//import * as TabilityTypes from 'types';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import * as amplitudeUtils from 'utils/amplitude';

// API
import * as remoteApi from 'api/remote';

import { PanelGrid, PanelHeader, PanelContent } from 'components/GlobalPanel';

import CheckinBlock from 'components/CheckinBlock';
import CheckinComments from '../shared/CheckinComments';

import Loader from 'components/Loader';
import KoalaIconButton from 'koala/components/IconButton';
import NotFound from 'components/NotFound';
import AccessDenied from 'components/AccessDenied';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
`;

const ConfidenceAndTitle = styled.div`
  display: flex;
  align-items: center;

  h2 {
  }
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.x2};
`;

const HeaderActionsMeta = styled.div`
  display: flex;
  align-items: center;
  div.prct {
    margin-right: ${theme.spacing.x3};
  }

  button {
    margin-left: ${theme.spacing.x2};
  }
`;

const CommentsContainer = styled.div``;

interface Props {
  checkinId: string;
}

function OutcomeBlock(props: Props) {
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.checkin.view', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const { checkinId } = props;
  const location = useLocation();
  const history = useHistory();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.push(location.pathname);
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentCheckin, checkinId];
  const staleTime = queryKeys.staleTime;

  // Get the checkin details
  const {
    data: checkinResponse,
    isError,
    error,
  }: any = useQuery(queryKey, remoteApi.fetchCheckinDetails, {
    staleTime,
  });

  const checkin = checkinResponse ? checkinResponse.data : null;

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  // Now we can display the app with the Chrome
  if (!checkin) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  // Get the outcome and plan
  const { outcome } = checkin;
  const { plan } = outcome;

  const outcomeUrl = `#outcome:${outcome.nano_slug}:show`;

  const handleCancel = (e: any) => {
    e.preventDefault();
    history.push(outcomeUrl);
  };

  return (
    <PanelGrid>
      <PanelHeader>
        <HeaderActions>
          <HeaderActionsMeta>
            <ul>
              <li className="subtle">
                <Link to={`/${workspaceSlug}/plans/${plan.nano_slug}/outcomes`}>{plan.title}</Link>
              </li>
            </ul>
          </HeaderActionsMeta>
          <HeaderActionsMeta>
            <KoalaIconButton onClick={handleCancel} iconName="rightArrow" />
          </HeaderActionsMeta>
        </HeaderActions>
        <Header>
          <ConfidenceAndTitle>
            <h2>
              <Link to={outcomeUrl}>{outcome.title}</Link>
            </h2>
          </ConfidenceAndTitle>
        </Header>
      </PanelHeader>
      <PanelContent>
        <CheckinBlock checkin={checkin} outcome={outcome} className="primary" />
        <CommentsContainer>
          <CheckinComments checkin={checkin} />
        </CommentsContainer>
      </PanelContent>
    </PanelGrid>
  );
}

export default React.memo(OutcomeBlock);
