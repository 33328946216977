import React from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { Dashboard, Membership, Workspace } from 'types';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import KoalaIconButton from 'koala/components/IconButton';
import DropdownMenu from 'components/DropdownMenu';
import KoalaLoader from 'koala/components/Loader';

const ListingLine = styled.div`
  border-bottom: 1px solid ${theme.colors.blockBorder};
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 1.2rem ${theme.spacing.x2};
  cursor: pointer;

  label {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  > div {
    display: flex;
    align-items: center;
  }

  :hover {
    background: ${theme.colors.N3};
  }

  &.header {
    border-bottom: 0;
    :hover {
      background: none;
    }
  }
  &.selected,
  :focus,
  :active {
    background: ${theme.colors.B5};
  }

  :first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  :last-of-type {
    border-bottom: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

const DashboardAction = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

interface Props {
  dashboard: Dashboard;
  workspace: Workspace;
  isFavorite?: boolean;
}

function DashboardLine(props: Props) {
  const { dashboard, workspace, isFavorite } = props;
  const queryCache = useQueryCache();
  const history = useHistory();
  const { t } = useTranslation();
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  const [updateDashboardMutation, { isLoading: dashboardIsDeleting }] = useMutation(remoteApi.deleteDashboard, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.dashboards);
    },
  });

  const [createDashboardFavoriteMutation, { isLoading: dashboardFavoriteAdding }] = useMutation(
    remoteApi.createDashboardFavorite,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.dashboards);
      },
    },
  );
  const [removeDashboardFavoriteMutation, { isLoading: dashboardFavoriteRemoving }] = useMutation(
    remoteApi.deleteDashboardFavorite,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.dashboards);
      },
    },
  );

  const handleDelete = (id: string) => {
    updateDashboardMutation(id);
  };

  const addFavorite = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const params = {
      membershipId: currentMembership.id,
      dashboardId: dashboard.id,
    };
    createDashboardFavoriteMutation(params);
  };

  const removeFavorite = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const params = {
      dashboardId: dashboard.id,
      membershipId: currentMembership.id,
    };
    removeDashboardFavoriteMutation(params);
  };

  const handleMenuSelection = (value: React.ReactElement<any>) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'edit':
        history.push(`/${workspace.slug}/dashboards/${dashboard.id}/edit`);
        break;
      case 'delete':
        handleDelete(dashboard.id);
        break;
    }
  };

  const menuItems = [
    <span data-action="edit">{t('shared.edit')}</span>,
    <span data-action="delete">{t('shared.delete')}</span>,
  ];

  return (
    <ListingLine
      className="item"
      key={dashboard.id}
      onClick={() => history.push(`/${workspace.slug}/dashboards/${dashboard.nano_slug}`)}
    >
      <div>{dashboard.title}</div>
      <DashboardAction>
        {dashboardIsDeleting && <KoalaLoader />}

        {!isFavorite && <KoalaIconButton iconName="love" onClick={addFavorite} loading={dashboardFavoriteAdding} />}
        {isFavorite && (
          <KoalaIconButton iconName="loveFilled" onClick={removeFavorite} loading={dashboardFavoriteRemoving} />
        )}

        <DropdownMenu
          trigger={<KoalaIconButton iconName="ellipsis" />}
          onSelection={handleMenuSelection}
          items={menuItems}
        />
      </DashboardAction>
    </ListingLine>
  );
}

export default React.memo(DashboardLine);
