import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import parse from 'parse-link-header';
import { parseISO } from 'date-fns';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { TFunction, i18n } from 'i18next';
import { useTranslation } from 'react-i18next';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useHotkeys } from 'react-hotkeys-hook';

// API
import * as remoteApi from 'api/remote';

// Header component

// Components
import Loader from 'components/Loader';
import KoalaButton from 'koala/components/Button';
import EmptyStatePanel from 'components/EmptyStatePanel';
import KoalaAvatar from 'koala/components/Avatar';
import StandupBlock from './StandupBlock';
import UnfollowButton from './UnfollowButton';
import WorkspaceAvatar from 'components/WorkspaceAvatar';
import { formatDistanceToNowLocale } from 'utils/dateUtils';

const ListingContainer = styled.div`
  min-width: 38rem;
  width: 38rem;
  max-width: 38rem;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'standups';

  height: 100%;
  min-height: 100%;
  position: relative;
  border: 1px solid ${theme.colors.N10};
  border-radius: 8px;
  flex: 1;
`;

const Standups = styled.div`
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: ${theme.spacing.x2};

  .empty_state_header {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 800;
  }

  .ql-editor {
    font-size: 1.4rem !important;
  }
`;

const DateDivider = styled.div<{ isToday: boolean }>`
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.6rem;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1} 0px;

  span {
    background-color: ${({ isToday }) => (isToday ? theme.colors.Y10 : theme.colors.N5)};
    height: 2rem;
    border-radius: 1.2rem;
    padding: 2px ${theme.spacing.x1};
    text-align: center;
    color: ${({ isToday }) => (isToday ? theme.colors.Y100 : theme.colors.N70)};
  }

  div {
    border-color: ${({ isToday }) => (isToday ? theme.colors.Y50 : theme.colors.N20)};
  }
`;

const DividerLine = styled.div`
  border-top: 1px solid ${theme.colors.N10};
  flex: 1;
`;

const Header = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.x1};
  min-height: 11.2rem;

  span {
    flex: 1;
    font-size: 1.6rem;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
    text-overflow: ellipsis;
    max-width: 28rem;
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x1};
`;

const StandupFormContainer = styled.div`
  grid-area: header;
  min-height: 11.2rem;
`;

const NewStandupGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    'gutter content'
    'gutter footer';
  gap: ${theme.spacing.x1};
  margin: 1.2rem ${theme.spacing.x2};

  &.primary {
    background: #f3f3f3;
    border-radius: 8px;
    padding: ${theme.spacing.x3};

    border-bottom: 0;
  }
`;

const NewStandupGutter = styled.div`
  grid-area: gutter;
  height: fit-content;
  margin-top: 1.2rem;
`;

const NewStandupReplyPlaceholder = styled.input`
  background: #fff;
  border: 1px solid ${theme.colors.blockBorder};
`;

const NewStandupContent = styled.div`
  grid-area: content;
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.6rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    border-radius: 4px;
    background: #f3f3f3;
    border: 0px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }

    width: 100%;

    &.minimized {
      min-height: 4rem;
      height: 4rem;
    }
  }
`;

function isDistanceAboveOneDay(currentDate: Date, previousDate: Date): boolean {
  const oneDay = 1000 * 3600 * 24;
  const distance = currentDate.getTime() - previousDate.getTime();
  return distance > oneDay;
}

function formatDistanceDay(date: Date, t: TFunction, i18nValue: i18n): { label: string; isToday: boolean } {
  const oneDay = 1000 * 3600 * 24;
  const distance = Date.now() - date.getTime();
  if (distance <= oneDay && distance >= 0) {
    return { label: t('workspaceStandups.today'), isToday: true };
  }
  if (distance > oneDay && distance <= oneDay * 2) {
    return { label: t('workspaceStandups.yesterday'), isToday: false };
  }
  return { label: formatDistanceToNowLocale(date, i18nValue, true, true), isToday: false };
}

interface Props {
  workspace: TabilityTypes.Workspace;
  membership?: TabilityTypes.Membership;
  follower?: TabilityTypes.Follower;
}

function StandupColumn(props: Props) {
  const { workspace, membership, follower } = props;
  const dispatch = useDispatch();
  const { t, i18n: i18nValue } = useTranslation();
  const [standups, setStandups]: any = useState([]);
  const [resultsCount, setResultsCount]: any = useState(0);

  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);

  const showCreateStandupForm = () => {
    dispatch(setGlobalModalContent(`workspace::create.standup`));
  };

  useHotkeys('c', () => showCreateStandupForm(), { scopes: ['*'] });

  let filter = {};

  if (membership) {
    filter = {
      membership_id: membership.id,
    };
  }

  // Search query attributes
  const standupsQueryKey = [
    queryKeys.standups,
    workspace.id,
    {
      filter,
    },
  ];
  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  // Search params for search
  const { isLoading, isFetchingMore, fetchMore, canFetchMore } = useInfiniteQuery(
    standupsQueryKey,
    remoteApi.fetchStandups,
    {
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
      onSuccess: (response) => {
        const resultsCount = response[0].headers['x-total'];
        setResultsCount(resultsCount);
        setStandups(response);
      },
    },
  );

  const isCurrentUser = membership && membership.id === currentMembership.id;

  let dateReference: Date | null = null;

  return (
    <>
      <ListingContainer>
        {isCurrentUser && (
          <StandupFormContainer>
            <NewStandupGrid>
              <NewStandupGutter>
                <KoalaAvatar user={currentUser} size={2.4} />
              </NewStandupGutter>
              <NewStandupContent>
                <NewStandupReplyPlaceholder
                  onClick={showCreateStandupForm}
                  placeholder={t('workspaceStandups.maskedPlaceholder') || ''}
                  type="text"
                />
              </NewStandupContent>
            </NewStandupGrid>
          </StandupFormContainer>
        )}
        {!isCurrentUser && (
          <Header>
            {membership && follower && (
              <>
                <KoalaAvatar user={membership.user} size={2.4} />
                <span>{membership.user.name}</span>
                <UnfollowButton follower={follower} />
              </>
            )}
            {!membership && (
              <>
                <WorkspaceAvatar workspace={workspace} size="small" />
                <span>{t('workspaceStandups.allStandups')}</span>
              </>
            )}
          </Header>
        )}
        <Standups>
          {isLoading && <Loader size="medium" />}
          {parseInt(resultsCount) === 0 && isCurrentUser && (
            <EmptyStatePanel>
              <p className="empty_state_header">{t('workspaceStandups.emptyState.title')}</p>
              <p>{t('workspaceStandups.emptyState.information')}</p>
            </EmptyStatePanel>
          )}
          {parseInt(resultsCount) === 0 && !isCurrentUser && membership && (
            <EmptyStatePanel>
              <p>{t('workspaceStandups.emptyState.noStandups')}</p>
            </EmptyStatePanel>
          )}
          {parseInt(resultsCount) === 0 && !isCurrentUser && !membership && (
            <EmptyStatePanel>
              <p>{t('workspaceStandups.emptyState.workspaceActivity')}</p>
            </EmptyStatePanel>
          )}
          {standups.map((group: any, i: number) => {
            return (
              <Fragment key={i}>
                {group.data.map((standup: TabilityTypes.Standup) => {
                  let showDate = false;
                  const standupDate = parseISO(standup.created_at);
                  if (dateReference === null || isDistanceAboveOneDay(dateReference, parseISO(standup.created_at))) {
                    showDate = true;
                    dateReference = standupDate;
                  } else {
                    showDate = false;
                  }
                  const { label, isToday } = formatDistanceDay(dateReference, t, i18nValue);

                  return (
                    <>
                      {showDate && (
                        <DateDivider isToday={isToday}>
                          <DividerLine />
                          <span>{label}</span>
                          <DividerLine />
                        </DateDivider>
                      )}
                      <StandupBlock standup={standup} />
                    </>
                  );
                })}
              </Fragment>
            );
          })}
          {canFetchMore && (
            <LoadMore>
              <KoalaButton onClick={() => fetchMore()} appearance="secondary">
                {t('shared.loadMore')}
              </KoalaButton>
              {isFetchingMore && <Loader size="small" />}
            </LoadMore>
          )}
        </Standups>
      </ListingContainer>
    </>
  );
}

export default StandupColumn;
