import MentionBox from 'components/MentionBox';
import KoalaAvatar from 'koala/components/Avatar';
import React, { useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { InitiativeReply } from 'types';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'avatar header'
    '. content'
    '. actions';
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1} 0px 0px ${theme.spacing.x6};
`;

const AvatarSection = styled.div`
  grid-area: avatar;
`;

const ContentSection = styled.div`
  grid-area: content;
`;

const TitleSection = styled.div`
  grid-area: header;
  display: flex;
  gap: ${theme.spacing.x1};
`;

const ActionSection = styled.div`
  display: flex;
  grid-area: actions;
  gap: ${theme.spacing.x1};
`;

interface Props {
  reply: InitiativeReply;
  setIsEditing: (value: boolean) => void;
}

function InitiativeReplyEdit(props: Props) {
  const { reply, setIsEditing } = props;
  const queryCache = useQueryCache();
  const { membership } = reply;
  const [body, setBody] = useState(reply.body);
  const { t } = useTranslation();

  // Review comment mutations
  const [updateInitiativeReplyMutation, { isLoading }] = useMutation(remoteApi.updateInitiativeReply, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.initiativeReplies, reply.initiative_comment_id]);
      setIsEditing(false);
    },
  });

  const handleSubmit = () => {
    const params = {
      body,
    };
    updateInitiativeReplyMutation({
      initiativeReplyId: reply.id,
      initiative_reply: params,
    });
  };

  const user = membership ? membership.user : null;

  if (!user) {
    return <></>;
  }

  return (
    <Container>
      <AvatarSection>
        <KoalaAvatar user={user} />
      </AvatarSection>
      <TitleSection>
        <p>
          <b>{user.fullname}</b>
        </p>
      </TitleSection>
      <ContentSection>
        <MentionBox
          setValue={setBody}
          value={body}
          placeholder={t('modals.initiative.replyPlaceholder') ?? 'add a reply'}
          comment={true}
          cmdEnterCallback={handleSubmit}
        />
      </ContentSection>
      <ActionSection>
        <KoalaButton onClick={handleSubmit} loading={isLoading} disabled={isLoading}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={() => setIsEditing(false)}>{t('shared.cancel')}</KoalaTextButton>
      </ActionSection>
    </Container>
  );
}

export default React.memo(InitiativeReplyEdit);
