import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import parse from 'parse-link-header';
import { useTranslation } from 'react-i18next';

// API
import * as remoteApi from 'api/remote';

// Components
import KoalaButton from 'koala/components/Button';
import OutcomeExpandable from 'components/OutcomeExpandable';
import { CheckinsListHeader } from './CheckinsList';

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

export const BlockMeta = styled.div`
  font-weight: 700;
  grid-area: meta;
  display: flex;
  align-items: center;
  height: 100%;
`;

const OutcomeList = styled.div`
  .outcome-expanded {
    border-bottom: 1px solid ${theme.colors.blockBorder};
  }
  .outcome-expanded:last-of-type {
    border-bottom: 0;
  }
`;

interface Props {
  currentMembership: TabilityTypes.Membership;
}

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

function ContributingOutcomesList(props: Props) {
  // Query that fetches all the outcomes with pending checkins
  const { t } = useTranslation();
  const { currentMembership } = props;
  const pendingCheckinsQueryKey = [queryKeys.checkins, currentMembership.id, true, { limit: 5 }];
  const activeOutcomeQueryKey = [queryKeys.outcomes, currentMembership.id, true, { limit: 5 }];
  const {
    isLoading: isLoadingPending,
    isFetchingMore: isFetchingMorePending,
    data: outcomesResponsePending,
    fetchMore: fetchMorePending,
    canFetchMore: canFetchMorePending,
  }: any = useInfiniteQuery(pendingCheckinsQueryKey, remoteApi.fetchMembershipPendingCheckins, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  const {
    isLoading: isLoadingActive,
    isFetchingMore: isFetchingMoreActive,
    data: outcomesResponseActive,
    fetchMore: fetchMoreActive,
    canFetchMore: canFetchMoreActive,
  }: any = useInfiniteQuery(activeOutcomeQueryKey, remoteApi.fetchMembershipActiveOutcomes, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  const isLoading = isLoadingPending || isLoadingActive;

  if (isLoading) {
    return null;
  }

  let pendingCheckins = [];
  let activeOutcomes = [];

  if (outcomesResponsePending) {
    pendingCheckins = outcomesResponsePending;
  }

  if (outcomesResponseActive) {
    activeOutcomes = outcomesResponseActive;
  }

  const zeroPending = pendingCheckins.length === 0 || pendingCheckins[0].data.length === 0;
  const zeroActive = activeOutcomes.length === 0 || activeOutcomes[0].data.length === 0;

  // ignore if contributing and no checkins
  if (zeroActive && zeroPending) {
    return <></>;
  }

  return (
    <>
      <CheckinsListHeader>
        <h4>{t('workspaceInbox.contributing')}</h4>
      </CheckinsListHeader>
      <OutcomeList>
        {pendingCheckins.map((group: any, i: number) => {
          return (
            <Fragment key={i}>
              {group.data.map((outcome: TabilityTypes.Outcome, index: number) => {
                return <OutcomeExpandable outcome={outcome} hideExpand={true} hideTags={true} />;
              })}
            </Fragment>
          );
        })}
      </OutcomeList>
      {canFetchMorePending && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMorePending()}
            loading={isFetchingMorePending}
            disabled={isFetchingMorePending}
            appearance="secondary"
          >
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
      <OutcomeList>
        {activeOutcomes.map((group: any, i: number) => {
          return (
            <Fragment key={i}>
              {group.data.map((outcome: TabilityTypes.Outcome, index: number) => {
                return <OutcomeExpandable outcome={outcome} hideExpand={true} hideTags={true} />;
              })}
            </Fragment>
          );
        })}
      </OutcomeList>
      {canFetchMoreActive && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMoreActive()}
            loading={isFetchingMoreActive}
            disabled={isFetchingMoreActive}
            appearance="secondary"
          >
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
    </>
  );
}

export default ContributingOutcomesList;
