import React from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQueryCache, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

// Components
import KoalaInlineTextInput from 'koala/components/InlineTextInput';

const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  .hide {
    display: none;
  }
`;

interface Props {
  team: TabilityTypes.Team;
}

const TeamTitle = (props: Props) => {
  const { team } = props;
  const queryCache = useQueryCache();
  const queryKeyTeam = [queryKeys.currentTeam, team.nano_slug];

  // Mutation that will update the team in the backend
  const [updateTeamMutation, { isLoading: isUpdating }]: [any, any] = useMutation(remoteApi.updateTeam, {
    onSuccess: (teamResponse) => {
      queryCache.setQueryData(queryKeyTeam, teamResponse);
      queryCache.invalidateQueries(queryKeys.currentTeam);
      queryCache.invalidateQueries(queryKeys.teams);
    },
  });

  const updateTeamName = (newTitle: string) => {
    const teamParams = {
      name: newTitle,
    };
    const mutationParams = {
      teamId: team.nano_slug,
      team: teamParams,
    };
    updateTeamMutation(mutationParams);
  };

  return (
    <Container>
      <KoalaInlineTextInput
        handleChangeText={updateTeamName}
        placeholder="Enter team title"
        text={team.name}
        size="large"
        hideOverflow={true}
        isUpdating={isUpdating}
      />
    </Container>
  );
};

export default TeamTitle;
