import React from 'react';

const ValidationSuccess = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="6" fill="#408C76" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.25 4.5L5.25 8.25L3.75 6" fill="#408C76" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.71852 3.91435C9.04197 4.1731 9.09441 4.64507 8.83565 4.96852L5.83565 8.71852C5.68618 8.90536 5.45653 9.00967 5.21749 8.99929C4.97844 8.98892 4.75869 8.86511 4.62597 8.66602L3.12597 6.41602C2.8962 6.07138 2.98933 5.60572 3.33398 5.37596C3.67862 5.1462 4.14428 5.23933 4.37404 5.58397L5.30509 6.98055L7.66435 4.03148C7.92311 3.70803 8.39508 3.65559 8.71852 3.91435Z"
      fill="white"
    />
  </svg>
);

export default ValidationSuccess;
