import React, { memo } from 'react';
import PlanIconLabel from 'components/PlanIconLabel';
import PlanStatus from 'components/PlanStatus';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';
import { Plan } from 'types';
import { Link, useParams } from 'react-router-dom';
import * as routes from 'routes';

const Container = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  padding: 1.2rem ${theme.spacing.x2};
  border-radius: 8px;
  background-color: ${theme.colors.N5};
  font-size: 1.8rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const TitleDetails = styled.div`
  display: flex;
  gap: ${theme.spacing.half};
`;

const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;
interface Props {
  id: any;
  data: { plan: Plan };
}

function PlanNode(props: Props) {
  const { data } = props;
  const { plan } = data;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const routeTarget =
    plan.state === 'published' ? routes.WORKSPACE_PLAN_TRACK_ROUTE : routes.WORKSPACE_PLAN_WRITE_ROUTE;
  const planRoute = routeTarget.replace(':workspaceSlug', workspaceSlug).replace(':planId', plan.nano_slug);

  return (
    <Container>
      <Link to={planRoute}>
        <PlanIconLabel plan={plan} />
      </Link>
      <TitleDetails>
        <PlanStatus plan={plan} />
      </TitleDetails>
      <CustomHandle type="source" position={Position.Bottom} key="out" id="out" isConnectableStart={false} />
    </Container>
  );
}

export default memo(PlanNode);
