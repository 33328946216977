// This components can be used anytime you want to also have mentions.
import React from 'react';
import QuillBodyEditor from 'components/QuillBodyEditor';

interface Props {
  value: string;
  setValue: any;
  comment?: boolean;
  placeholder?: string;
  cmdEnterCallback?: any;
  allowHeaders?: boolean;
  disableAutoFocus?: boolean;
}

function MentionBox(props: Props) {
  const { value, setValue, placeholder, cmdEnterCallback, allowHeaders, disableAutoFocus } = props;

  return (
    <QuillBodyEditor
      value={value}
      onChange={setValue}
      placeholder={placeholder}
      cmdEnterCallback={cmdEnterCallback}
      allowHeaders={allowHeaders}
      disableAutoFocus={disableAutoFocus}
    />
  );
}

export default React.memo(MentionBox);
