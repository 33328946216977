import React, { Fragment, useEffect } from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import * as amplitudeUtils from 'utils/amplitude';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import theme from 'theme';

// Components
import { ChromeContent } from 'components/Chrome';
import ContentNarrowWrapper from 'components/ContentNarrowWrapper';

import { useParams } from 'react-router-dom';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIcon from 'koala/components/Icons';

import Home from './Home';
import Section from './Section';

const ButtonContainer = styled.div`
  a {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
  }
`;

const Wrapper = styled.div`
  padding: ${theme.spacing.x3} 0;
  h3 {
    font-weight: 400;
  }
`;

function Quickstart() {
  const { section } = useParams<{ section: string }>();
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.accelerator.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const sectionToTitle: any = {
    coaching: 'Live coaching & webinars',
    start: 'Getting started',
    setup: 'Setting your workspace up',
    pilot: 'Running a successful pilot',
    goalsetting: 'Setting goals',
    goaltracking: 'Tracking goals',
  };

  return (
    <Fragment>
      <Helmet>
        <title>{currentWorkspace.name} | Getting started | Tability</title>
        <script src="https://fast.wistia.com/embed/medias/pb7mfbrs95.jsonp" async></script>
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
      </Helmet>
      <ChromeContent isGreyBackground>
        <ContentNarrowWrapper>
          <Wrapper>
            {!section && (
              <>
                <h1>Tability Onboarding Accelerator</h1>
                <h3>A curated selection of resources to help you set and achieve your goals faster</h3>
              </>
            )}
            {section && (
              <>
                <h1>{sectionToTitle[section]}</h1>
                <ButtonContainer>
                  <KoalaTextButton isLink to={`/${currentWorkspace.slug}/accelerator`}>
                    <KoalaIcon iconName="leftArrow" iconSize="xsmall" />
                    Back to Accelerator home
                  </KoalaTextButton>
                </ButtonContainer>
              </>
            )}
            {!section && <Home />}
            {section && <Section />}
          </Wrapper>
        </ContentNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default Quickstart;
