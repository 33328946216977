import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import theme from 'theme';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import AccountSettingsNav from 'components/AccountSettingsNav';
import KoalaButton from 'koala/components/Button';
import PasswordValidation from 'components/PasswordValidation';
import { useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media ${theme.devices.mobile} {
    input {
      width: 100%;
    }
  }
`;

const ConfirmMessage = styled.div`
  padding: ${theme.spacing.x2};
  background: ${theme.colors.successBg};
  margin: ${theme.spacing.x2} 0;
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const PasswordValidationContainer = styled.div`
  width: 50%;
  margin-bottom: ${theme.spacing.x2};
`;

function AccountPassword() {
  const [current_password, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');
  const [allowPasswordSubmit, setAllowPasswordSubmit] = useState(false);
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);

  const [updatePasswordMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.updatePassword, {
    onMutate: () => {
      setShowConfirm(false);
    },
    onSuccess: (response) => {
      setShowConfirm(true);
    },
  });

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data.errors : {};
    }
  }

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'current_password':
        return t('errors.currentPassword');
      case 'password':
        return t('errors.newPassword');
      case 'password_confirmation':
        return t('errors.confirmPassword');
      default:
        return '';
    }
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'password':
        setPassword(value);
        break;
      case 'current_password':
        setCurrentPassword(value);
        break;
      case 'password_confirmation':
        setPasswordConfirmation(value);
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      current_password,
      password,
      password_confirmation,
    };
    updatePasswordMutation({
      user: params,
    });
  };

  // Error classnames used to highlight input fields that have errors
  const currentPasswordClassname = errors['current_password'] ? 'error' : '';
  const passwordClassname = errors['password'] ? 'error' : '';
  const passwordConfirmationClassname = errors['password_confirmation'] ? 'error' : '';

  return (
    <Fragment>
      <Helmet>
        <title>{t('accountPassword.title')} | Tability</title>
      </Helmet>
      <ChromeSideNav>
        <AccountSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('accountPassword.title')}</h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <label>{t('accountPassword.currentPassword')}</label>
              <input
                type="password"
                name="current_password"
                className={`${currentPasswordClassname} small`}
                autoComplete="off"
                placeholder={t('accountPassword.currentPassword') ?? 'Current password'}
                value={current_password}
                required={true}
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <label>{t('accountPassword.newPassword')}</label>
              <input
                type="password"
                name="password"
                className={`${passwordClassname} small`}
                autoComplete="off"
                placeholder={t('accountPassword.newPassword') ?? 'New password'}
                value={password}
                required={true}
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <label>{t('accountPassword.confirmPassword')}</label>
              <input
                type="password"
                name="password_confirmation"
                className={`${passwordConfirmationClassname} small`}
                autoComplete="off"
                placeholder={t('accountPassword.confirmPassword') ?? 'Confirm password'}
                value={password_confirmation}
                required={true}
                onChange={handleChange}
              />
            </FormField>
            {password && (
              <PasswordValidationContainer>
                <PasswordValidation password={password} setAllowPasswordSubmit={setAllowPasswordSubmit} />
              </PasswordValidationContainer>
            )}

            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isLoading || !allowPasswordSubmit} loading={isLoading}>
                {t('shared.save')}
              </KoalaButton>
            </Actions>
          </Form>
          {showConfirm && <ConfirmMessage>{t('accountPassword.confirmMessage')}</ConfirmMessage>}
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default AccountPassword;
