import React, { ChangeEvent, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';

import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
// import { useMutation, useQuery, useQueryCache } from 'react-query';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import yaml from 'js-yaml';
import { Outcome } from 'types';

import { shallowEqual, useSelector } from 'react-redux';
import { Workspace } from 'types';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Avatar from 'components/Avatar';
import KoalaIcon from 'koala/components/Icons';
import RelatedOutcome from 'components/RelatedOutcome';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const TitleContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const TabbyThinking = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
  img {
    width: 12rem;
  }
  padding: ${theme.spacing.x2};
`;

const EmptyTips = styled.div`
  font-size: 1.2rem;
  ul {
    list-style-type: disc;
    margin-left: 3rem;
    margin-top: ${theme.spacing.half};
    margin-bottom: ${theme.spacing.half};

    li {
      padding-bottom: ${theme.spacing.half};
      color: ${theme.colors.N90};
    }
  }
  p {
    color: ${theme.colors.N90};
  }
  margin-bottom: ${theme.spacing.x2};

  background: ${theme.colors.N3};
  padding: ${theme.spacing.x2};
  border-radius: 4px;
`;

const TipsContent = styled.div`
  font-size: 1.5rem;
  h3 {
    font-size: 2.4rem;
    margin: ${theme.spacing.x4} 0 ${theme.spacing.x1} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
  h4 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-top: ${theme.spacing.x3};
    margin-bottom: ${theme.spacing.x1};
  }
  a {
    color: ${theme.colors.B50};
    text-decoration: underline;
  }
  ul {
    list-style-type: disc;
    margin-left: 3rem;
    margin-top: ${theme.spacing.half};
    margin-bottom: ${theme.spacing.half};

    li {
      padding-bottom: ${theme.spacing.half};
      color: ${theme.colors.N90};
    }
  }
  p {
    color: ${theme.colors.N90};
  }
`;

const PreviewContainer = styled.div`
  border: 2px dotted ${theme.colors.N50};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  margin: ${theme.spacing.x1} 0;
`;

const PreviewLine = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${theme.spacing.x1};
  align-items: center;

  div:first-of-type {
    justify-self: flex-end;
  }

  div:nth-of-type(2) {
    font-family: monospace, 'Courier New';
    font-size: 1.2rem;
  }
`;

const ChatInput = styled.div`
  grid-area: input;
  max-width: 80rem;
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 4rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }
`;

const ChatPanel = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr auto;
  gap: ${theme.spacing.x4};
  grid-template-areas:
    'chat preview'
    'input preview';
  height: 100%;
`;

const ChatPreview = styled.div`
  grid-area: preview;
  max-width: 45rem;
  min-width: 45rem;
`;

const Chat = styled.div`
  grid-area: chat;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x4};
  max-width: 80rem;
  max-height: 100%;
  overflow-y: auto;
  padding-right: ${theme.spacing.x1};
`;

const Message = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${theme.spacing.x2};
  .message_avatar {
    width: 3.2rem;
    height: 3.2rem;

    .img {
      width: 100%;
    }
  }
  p + p {
    margin-top: ${theme.spacing.x1};
  }
  strong {
    font-weight: 800;
  }
`;

const OutcomeContainer = styled.div`
  padding-bottom: ${theme.spacing.x2};
  label {
    margin-left: ${theme.spacing.x2};
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: ${theme.colors.subtleText};
  }
`;

const WritingImg = 'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-typing_vjitqx.gif';
const NoCreditsImg =
  'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-sleeping_tvsdtw.gif';

interface Props {
  outcomeId: string;
}

function PopulateAI(props: Props) {
  const { outcomeId } = props;
  const dispatch = useDispatch();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentPing = useSelector((state: any) => state.session.currentPing, shallowEqual);
  const { t } = useTranslation();
  const translationKey = 'modals.chatAIInitiatives';
  const [outcome, setOutcome] = useState<Outcome>();

  // Get the outcome details
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = 0;
  useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
    onSuccess: (response: any) => {
      const outcome: Outcome = response.data;
      setOutcome(outcome);
    },
  });

  const { usage } = currentPing;
  let billing_ai_credits_limit = 0;
  let ai_credits_used = 0;
  if (usage) {
    billing_ai_credits_limit = usage.billing_ai_credits_limit;
    ai_credits_used = usage.ai_credits_used;
  }

  const [content, setContent] = useState('');
  const [previewYml, setPreviewYml] = useState<any>(null);
  const [previewData, setPreviewData] = useState<any>(null);
  const [aiThread, setAiThread] = useState<any>(null);
  const [messages, setMessages] = useState<any>([
    {
      role: 'assistant',
      content:
        'Hello! I am Tabby, your AI assistant. I can help you find relevant action items for your goal. Do you have any additional context that you want to provide?',
    },
  ]);

  const messagesEndRef: any = useRef(null);

  const scrollToBottom = () => {
    // Use scrollIntoView with behavior smooth to scroll to the bottom
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Scroll to the bottom whenever the messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Create a ref to attach to the textarea element
  const textAreaRef: any = useRef(null);

  // Use useEffect to set focus when the component mounts
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []); // Empty dependency array ensures this runs only once after initial render

  const queryCache = useQueryCache();

  const extractYmlFromContent = (content: string) => {
    let text = content.split('---');

    if (!text[1]) {
      return;
    }
    // Patterns used to parse the lines
    const initiativePattern = /- Task*: (.*)/;

    // Turn the lines into an array using the line breaks
    const lines = text[1].split('\n');

    const initiatives: any = [];

    lines.forEach((line) => {
      if (line.trim() === '') return; // Ignore empty lines

      line = line.trim(); // Clean whitespaces that might be at the beginning/end of the line

      const initiativeMatch = line.match(initiativePattern); // Check if we have an outcome
      if (initiativeMatch) initiatives.push(initiativeMatch[1].replace(/\.$/, ''));
    });

    // Return the results
    const data = {
      initiatives: initiatives,
    };

    return yaml.dump(data);
  };

  // Functions to update the outcome
  const [createAiThreadForInitiativesMutation, { isLoading, isError }]: [any, any] = useMutation(
    remoteApi.createAiThreadForInitiatives,
    {
      onMutate: (variables) => {
        const userMessage = {
          role: 'user',
          content: variables.content,
        };

        const newMessages = [...messages, userMessage];
        setMessages(newMessages);
      },
      onSuccess: (response, variables) => {
        const aiThread = response.data;
        setAiThread(aiThread);
        setMessages(aiThread.messages);
        queryCache.invalidateQueries(queryKeys.currentPing);
        setContent('');

        try {
          const previewYml = extractYmlFromContent(aiThread.messages[aiThread.messages.length - 1].content);
          const previewData = previewYml ? yaml.load(previewYml) : null;
          setPreviewYml(previewYml);
          setPreviewData(previewData);
        } catch (e) {
          // setPreviewData(null);
          // setPreviewYml(null);
        }
      },
    },
  );

  const [postAiThreadMessageMutation, { isLoading: isLoadingChat, isError: isErrorChat }]: [any, any] = useMutation(
    remoteApi.postAiThreadMessage,
    {
      onMutate: (variables) => {
        const userMessage = {
          role: 'user',
          content: variables.content,
        };

        const newMessages = [...messages, userMessage];
        setMessages(newMessages);
      },
      onSuccess: (response, variables) => {
        const userMessage = {
          role: 'user',
          content: variables.content,
        };
        const aiThreadResponse = response.data;

        const newMessages = [...messages, userMessage, aiThreadResponse];
        setMessages(newMessages);
        setContent('');
        queryCache.invalidateQueries(queryKeys.currentPing);
        try {
          const previewYml = extractYmlFromContent(aiThreadResponse.content);
          const previewData = previewYml ? yaml.load(previewYml) : null;
          setPreviewYml(previewYml);
          setPreviewData(previewData);
        } catch (e) {
          // setPreviewData(null);
          // setPreviewYml(null);
        }
      },
    },
  );

  const [populateAIYMLOutcomeMutation, { isLoading: isPopulating, isError: isPopulatingError }]: [any, any] =
    useMutation(remoteApi.populateAIYMLOutcome, {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.initiatives);
        queryCache.invalidateQueries(queryKeys.currentPing);
        dispatch(setGlobalModalContent(''));
      },
    });

  const handleCancel = (e: React.MouseEvent) => {
    dispatch(setGlobalModalContent(''));
  };

  const handleGeneratePreview = (e: any) => {
    e.preventDefault();
    // Don't trigger if already loading the template.
    if (isLoading) {
      return;
    }
    const params = {
      outcomeId: outcomeId,
      content: content,
    };

    createAiThreadForInitiativesMutation(params);
  };

  const handlePostAiThreadMessage = (e: any) => {
    e.preventDefault();
    // Don't trigger if already loading the template.
    if (isLoading) {
      return;
    }
    const params = {
      aiThreadId: aiThread.id,
      // @ts-ignore
      content: content,
    };

    postAiThreadMessageMutation(params);
  };

  const handlePopulatePreview = (e: any) => {
    e.preventDefault();
    // Don't trigger if already loading the template.
    if (isLoading) {
      return;
    }
    const params = {
      outcomeId: outcomeId,
      // @ts-ignore
      ai_yml: previewYml,
    };

    populateAIYMLOutcomeMutation(params);
  };

  const handleObjectiveDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const inputName = e.target.value;
    setContent(inputName);
  };

  const hasCredit = billing_ai_credits_limit > ai_credits_used;
  const needsUpgrade = !currentWorkspace.billing_subscription_plan || currentWorkspace.pricing_version !== 3;
  const isOwner = currentMembership && ['owner'].includes(currentMembership.role);
  let placeholder = t(`${translationKey}.emptyPlaceholder`);

  if (aiThread) {
    placeholder = t(`${translationKey}.chatPlaceholder`);
  }

  if (!outcome) {
    return null;
  }

  return (
    <ModalGrid>
      <ModalHeader>
        <TitleContainer>
          <h2>
            {t(`${translationKey}.title`, { initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2) })}
          </h2>
        </TitleContainer>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        {!hasCredit && (
          <TipsContent>
            <TabbyThinking>
              <img src={NoCreditsImg} alt="Tabby sleeping" />
            </TabbyThinking>
            <p>{t(`${translationKey}.lowCredits`)}</p>
            <p className="subtle">
              {needsUpgrade && (
                <>
                  {isOwner && (
                    <Trans
                      i18nKey={`${translationKey}.upgradeCredits`}
                      components={{
                        Link: <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel} />,
                      }}
                    />
                  )}
                  {!isOwner && (
                    <small>
                      <Trans
                        i18nKey={`${translationKey}.upgradeCredits`}
                        components={{
                          Link: <b />,
                        }}
                      />
                    </small>
                  )}

                  <Trans
                    i18nKey={`${translationKey}.writeReview`}
                    components={{
                      a: (
                        <a
                          href="https://guides.tability.app/docs/other/how-to-get-more-ai-credits"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          text
                        </a>
                      ),
                    }}
                  />
                </>
              )}
            </p>
          </TipsContent>
        )}
        {hasCredit && (
          <ChatPanel>
            <Chat>
              {messages.map((message: any, index: number) => {
                if (message.role === 'system') {
                  return null;
                }
                if (message.role === 'assistant') {
                  const messageContent = message.content; //.split(`---`);
                  return (
                    <Message>
                      <div className="message_avatar">
                        <img
                          src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_happy_ldzb8v.png"
                          alt="Tabby"
                          className="img"
                        />
                      </div>
                      <div>
                        <p>
                          <strong>Tabby</strong>
                        </p>
                        {messageContent.split(`\n`).map((i: string, key: number) => {
                          if (i === '---') {
                            return (
                              <p key={key}>
                                <b>{t(`${translationKey}.suggestions`)}</b>
                              </p>
                            );
                          }
                          i = i.replace(/- Task:/g, '•');
                          return <p key={key}>{i}</p>;
                        })}
                      </div>
                    </Message>
                  );
                }
                return (
                  <Message>
                    <div className="message_avatar">
                      <Avatar user={currentUser} />
                    </div>
                    <div>
                      <p>
                        <strong>{t(`${translationKey}.you`)}</strong>
                      </p>
                      {message.content.split(`\n`).map((i: string, key: number) => {
                        return <p key={key}>{i}</p>;
                      })}
                    </div>
                  </Message>
                );
              })}
              {(isLoading || isLoadingChat) && (
                <TabbyThinking>
                  <img src={WritingImg} alt="Tabby thinking" />
                  <p>{t(`${translationKey}.resultsLoading`)}</p>
                </TabbyThinking>
              )}
              <div ref={messagesEndRef} />
            </Chat>
            <ChatInput>
              <Form onSubmit={handleGeneratePreview}>
                {!aiThread && !isLoading && (
                  <EmptyTips>
                    <p>{t(`${translationKey}.examples`)}</p>
                    <ul>
                      <li>{t(`${translationKey}.example1`)}</li>
                      <li>{t(`${translationKey}.example2`)}</li>
                      <li>{t(`${translationKey}.example3`)}</li>
                    </ul>
                  </EmptyTips>
                )}
                <FormField style={{ marginBottom: 0 }}>
                  <label>{t(`${translationKey}.message`)}</label>
                  <textarea
                    placeholder={placeholder}
                    className="segment-input"
                    value={content}
                    onChange={handleObjectiveDescriptionChange}
                    ref={textAreaRef}
                  />
                  <div>
                    {!aiThread && (
                      <KoalaButton
                        onClick={handleGeneratePreview}
                        disabled={isLoading || !content || !hasCredit}
                        loading={isLoading}
                      >
                        {t(`${translationKey}.generate`)}
                      </KoalaButton>
                    )}
                    {aiThread && (
                      <KoalaButton
                        onClick={handlePostAiThreadMessage}
                        disabled={isLoading || !content || !hasCredit}
                        loading={isLoading}
                        appearance="subtle"
                      >
                        {t(`${translationKey}.regenerate`)}
                      </KoalaButton>
                    )}
                  </div>
                </FormField>
              </Form>
            </ChatInput>
            <ChatPreview>
              <OutcomeContainer>
                <label>{t(`${translationKey}.relatedGoal`)}</label>
                <RelatedOutcome outcome={outcome} unlinked={true} />
              </OutcomeContainer>
              <TipsContent>
                {!isLoading && (
                  <>
                    {(isError || isErrorChat) && (
                      <p>
                        <b>{t(`${translationKey}.lowCredits`)}</b>
                      </p>
                    )}
                    {isPopulatingError && (
                      <p>
                        <b>{t(`${translationKey}.errorPopulating`)}</b>
                      </p>
                    )}
                    {previewData && previewData['initiatives'] && (
                      <>
                        <b>{t(`${translationKey}.suggestion`)}</b>
                        <PreviewContainer>
                          {previewData['initiatives'].map((initiative: string, index: number) => (
                            <PreviewLine key={index}>
                              <KoalaIcon iconName="task" />
                              <div>{initiative}</div>
                            </PreviewLine>
                          ))}
                        </PreviewContainer>
                        <KoalaButton
                          onClick={handlePopulatePreview}
                          disabled={isPopulating || !previewYml || !hasCredit}
                          loading={isPopulating}
                        >
                          {t(`${translationKey}.useSuggestion`)}
                        </KoalaButton>
                      </>
                    )}
                    {!previewData && <PreviewContainer>{t(`${translationKey}.templatePlaceholder`)}</PreviewContainer>}
                  </>
                )}
              </TipsContent>
            </ChatPreview>
          </ChatPanel>
        )}
      </ModalContent>
      <ModalFooter>
        <p className="subtle">
          <small>
            {t(`${translationKey}.creditsUsed`, {
              used: ai_credits_used.toLocaleString(),
              limit: billing_ai_credits_limit.toLocaleString(),
            })}
          </small>
          <br />
          {needsUpgrade && (
            <>
              {isOwner && (
                <small>
                  <Trans
                    i18nKey={`${translationKey}.upgradeCredits`}
                    components={{
                      Link: <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel} />,
                    }}
                  />
                </small>
              )}
              {!isOwner && (
                <small>
                  <Trans
                    i18nKey={`${translationKey}.upgradeCredits`}
                    components={{
                      Link: <b />,
                    }}
                  />
                </small>
              )}
            </>
          )}
        </p>
      </ModalFooter>
    </ModalGrid>
  );
}

export default PopulateAI;
