import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

// Components
import CopyTextInput from 'components/CopyTextInput';
import { Plan } from 'types';
import { MarkdownWrapper } from 'components/MarkdownContent';
import styled from 'styled-components';
import theme from 'theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};

  .copy-text-container {
    margin: 0px;
  }
`;

interface Props {
  plan: Plan;
  embedUrl: string;
}

function DeletePlan(props: Props) {
  const { plan, embedUrl } = props;
  const { t } = useTranslation();

  if (!plan.public_access_enabled) {
    return <>{t(`modals.share.publicAccessRequired`)}</>;
  }

  return (
    <Container>
      <h3>{t('modals.share.confluenceTitle')}</h3>
      <CopyTextInput text={embedUrl} />
      <div>
        <label>{t('modals.share.code')}</label>
        <MarkdownWrapper>
          <p>
            <Trans i18nKey={'modals.share.confluence'} components={{ b: <b /> }} />
          </p>

          <ul>
            <Trans i18nKey={'modals.share.confluenceList'} components={{ b: <b />, li: <li /> }} />
          </ul>
          <p>
            <a
              href="https://guides.tability.app/docs/integrations/confluence"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('modals.share.documentation')}
            </a>
          </p>
        </MarkdownWrapper>
      </div>
    </Container>
  );
}

export default DeletePlan;
