import React, { Fragment, useState, useEffect } from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';
import { Helmet } from 'react-helmet';
import * as workspaceUtils from 'utils/workspaceUtils';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';

// Components
import Loader from 'components/Loader';
import { ChromeContent } from 'components/Chrome';

import ActiveInitiativesList from './ActiveInitiativesList';
import RecentPlans from 'components/RecentPlans';

import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

import Tutorials from './Tutorials';
import OnboardingChecklist from './OnboardingChecklist';

import Newsfeed from './Newsfeed';

import CheckinsList from './CheckinsList';
import Network from './Network';
import OnboardingCheckist from './OnboardingChecklist';

const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  svg {
    margin-left: ${theme.spacing.x1};
  }

  @media ${theme.devices.mobile} {
    .avatar--custom-size {
      display: none;
    }
    gap: unset;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.x1};
  padding: 1.2rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 35rem 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'network focus'
    'plans focus '
    'tutorials focus'
    '. focus';

  gap: ${theme.spacing.x4};
  position: relative;

  .inbox-plans {
    grid-area: plans;

    @media ${theme.devices.mobile} {
      display: none;
    }
  }
  .inbox-network {
    grid-area: network;
  }
  .learn-tability {
    grid-area: tutorials;
  }

  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'network'
      'plans'
      'focus'
      'tutorials';
  }
`;

const FocusColumn = styled.div`
  grid-area: focus;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x4};
`;

const GridBlock = styled.div`
  background: #ffffff;
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;

  .item:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: none;
  }

  &.dark {
    background: ${theme.colors.N90};
    color: ${theme.colors.N0};
    border: 0;
  }

  &.help {
    h3 {
      display: flex;
      align-items: center;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: ${theme.spacing.x2};
      gap: ${theme.spacing.x2};
      a {
        font-size: 1.2rem;
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing.x1};
        border: 1px solid ${theme.colors.blockBorder};
        padding: ${theme.spacing.x2};
        border-radius: 4px;
        &:hover {
          background: ${theme.colors.N3};
        }
      }
    }
  }
`;

// Padding has to be 1.2rem to match the expandable outcomes padding.
const GridBlockSpacer = styled.div`
  padding: 1.2rem;
`;

const ContentWrapper = styled.div`
  max-width: 140rem;
  margin: 0 auto;
  padding: ${theme.spacing.x4};
`;

function Inbox() {
  const currentMembership: TabilityTypes.Membership = useSelector(
    (state: any) => state.session.currentMembership,
    shallowEqual,
  );
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );
  const hasV4Trial = workspaceUtils.hasV4Trial(currentWorkspace);
  const { t } = useTranslation();
  const [checkinsLoading, setCheckinsLoading] = useState(false);
  const [initiativesLoading, setInitiativesLoading] = useState(false);

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.inbox.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const inboxLoading = checkinsLoading || initiativesLoading;

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspaceInbox.title')} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader>
        <WorkspaceHeader
          workspace={currentWorkspace}
          useGreyBackground={true}
          title={
            <Title>
              {t('workspaceInbox.title')}
              {inboxLoading && <Loader />}
            </Title>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeContent className="grey-bg">
        <ContentWrapper>
          {hasV4Trial && <OnboardingCheckist />}
          <Grid>
            <FocusColumn>
              {false && <OnboardingChecklist />}
              <GridBlock className="inbox-outcomes">
                <SectionHeader>
                  <h3>
                    {t('workspaceInbox.outcomesHeader', {
                      outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                    })}
                  </h3>
                </SectionHeader>
                {currentMembership && (
                  <CheckinsList currentMembership={currentMembership} setCheckinsLoading={setCheckinsLoading} />
                )}
              </GridBlock>
              <GridBlock className="inbox-initiatives">
                <SectionHeader>
                  <h3>
                    {t('workspaceInbox.initiativesHeader', {
                      initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                    })}
                  </h3>
                </SectionHeader>
                {currentMembership && (
                  <>
                    <ActiveInitiativesList
                      currentMembership={currentMembership}
                      setInitiativesLoading={setInitiativesLoading}
                      showContributingInitiatives={false}
                    />
                    <ActiveInitiativesList
                      currentMembership={currentMembership}
                      setInitiativesLoading={setInitiativesLoading}
                      showContributingInitiatives={true}
                    />
                  </>
                )}
              </GridBlock>
              <Newsfeed />
            </FocusColumn>
            <GridBlock className="learn-tability help dark">
              <Tutorials />
            </GridBlock>
            <GridBlock className="inbox-plans">
              <GridBlockSpacer>
                <RecentPlans vertical={true} />
              </GridBlockSpacer>
            </GridBlock>
            <GridBlock className="inbox-network">
              <GridBlockSpacer>
                <Network />
              </GridBlockSpacer>
            </GridBlock>
          </Grid>
        </ContentWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default Inbox;
