import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import * as workspaceUtils from 'utils/workspaceUtils';

// API

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import Loader from 'components/Loader';
import { ModalGrid, ModalHeader, ModalContent } from 'components/GlobalModal';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { Trans, useTranslation } from 'react-i18next';

const Title = styled.h2`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${theme.spacing.x1};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const UpgradeOptions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${theme.spacing.x2};
  button,
  a {
    margin: ${theme.spacing.x2};
  }
  @media ${theme.devices.mobile} {
    flex-direction: column;
  }
`;

function UpgradeRequired() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isOwner = currentMembership.role === 'owner';
  const upgradeLabel = workspaceUtils.hasSubscription(currentWorkspace)
    ? t('shared.upgrade.upgradeWorkspace')
    : t('shared.upgrade.startTrial');
  // Now we can display the app with the Chrome
  if (!currentWorkspace) {
    return (
      <LoadingContainer>
        <Loader size="medium" />
      </LoadingContainer>
    );
  }

  const handleUpgrade = (e: any) => {
    e.preventDefault();
    const route = `/${currentWorkspace.slug}/settings/billing`;
    history.push(route);
    dispatch(setGlobalModalContent(''));
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <Title>
          <UpgradeIcon /> {t('workspaceMap.upgrade.title')}
        </Title>
      </ModalHeader>
      <ModalContent>
        <p>
          <Trans
            i18nKey="workspaceMap.upgrade.information"
            values={{ planLabel: 'Tability Essentials package' }}
            components={{ b: <b /> }}
          />
        </p>
        <UpgradeOptions>
          {isOwner && (
            <KoalaButton onClick={handleUpgrade} style={{ marginRight: '1.6rem' }}>
              {upgradeLabel}
            </KoalaButton>
          )}
          <KoalaTextButton href="https://tability.io/pricing" rel="noopener noreferrer" target="_blank">
            {t('shared.upgrade.learnMore')}
          </KoalaTextButton>
        </UpgradeOptions>
      </ModalContent>
    </ModalGrid>
  );
}

export default UpgradeRequired;
