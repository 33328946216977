/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';

// Components
import { Helmet } from 'react-helmet';
import FormField from 'components/FormField';
import Wordmark from 'components/Wordmark';
import PasswordValidation from 'components/PasswordValidation';

// Actions
import { sessionActions } from 'state/actions/';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { useTranslation } from 'react-i18next';

// Components

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: ${theme.spacing.x8};

  min-width: 60rem;
  width: 60rem;

  @media (max-width: 800px) {
    width: 100%;
    min-width: initial;
    margin-top: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};

    .hide-mobile {
      display: none;
    }

    .special-mobile {
      position: relative;
      bottom: ${theme.spacing.x3};
    }
  }

  a {
    color: ${theme.colors.blue};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${theme.spacing.x4} 0 ${theme.spacing.x2} 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  margin-top: ${theme.spacing.x8};

  @media (max-width: 800px) {
    margin-top: ${theme.spacing.x4};

    input,
    select {
      &.small {
        width: 100%;
      }

      &.tiny {
        width: 50%;
      }
    }
  }
`;

const ErrorList = styled.ul`
  margin-bottom: 1.6rem;
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.x2};
  button {
    margin-right: 1.6rem;
  }
`;

const Footer = styled.div`
  margin-bottom: ${theme.spacing.x3};

  button.secondary {
    color: ${theme.colors.subtleText};
    text-decoration: none;
    font-weight: normal;
  }
`;

const PasswordValidationContainer = styled.div`
  width: 50%;
  margin-bottom: ${theme.spacing.x2};
`;

function Login() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');
  const [allowPasswordSubmit, setAllowPasswordSubmit] = useState(false);

  const [updateRequiredPasswordMutation, { isError, isLoading, error }]: [any, any] = useMutation(
    remoteApi.updateRequiredPassword,
    {
      onSuccess: (response) => {
        const currentUser = response.data;
        dispatch(sessionActions.setCurrentUser(currentUser));
        history.push('/');
      },
    },
  );

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data.errors : {};
    }
  }

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'password':
        return t('errors.password');
      case 'password_confirmation':
        return t('errors.passwordConfirmation');
      default:
        return '';
    }
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'password':
        setPassword(value);
        break;
      case 'password_confirmation':
        setPasswordConfirmation(value);
        break;
    }
  };

  const handleLogout = (e: any) => {
    dispatch(sessionActions.logout());
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      password,
      password_confirmation,
    };
    updateRequiredPasswordMutation({
      user: params,
    });
  };

  // Error classnames used to highlight input fields that have errors
  const passwordClassname = errors['password'] ? 'error' : '';
  const passwordConfirmationClassname = errors['password_confirmation'] ? 'error' : '';

  return (
    <Wrapper>
      <Helmet>
        <title>{t('onboardingPassword.title')} | Tability</title>
      </Helmet>
      <Container>
        <Wordmark type="dark" />
        <Header>
          <h1>{t('onboardingPassword.title')}</h1>
        </Header>
        <p>{t('onboardingPassword.information')}</p>
        <Form onSubmit={handleSubmit}>
          <FormField>
            <label>{t('onboardingPassword.passwordLabel')}</label>
            <input
              type="password"
              name="password"
              className={`${passwordClassname} small`}
              autoComplete="off"
              value={password}
              required={true}
              onChange={handleChange}
            />
          </FormField>
          <FormField>
            <label>{t('onboardingPassword.passwordConfirmationLabel')}</label>
            <input
              type="password"
              name="password_confirmation"
              className={`${passwordConfirmationClassname} small`}
              autoComplete="off"
              value={password_confirmation}
              required={true}
              onChange={handleChange}
            />
          </FormField>
          {password && (
            <PasswordValidationContainer>
              <PasswordValidation password={password} setAllowPasswordSubmit={setAllowPasswordSubmit} />
            </PasswordValidationContainer>
          )}

          {isError && (
            <ErrorList>
              {Object.keys(errors).map((errorKey: string) => {
                // Get the first error for the key
                // NOTE: there might be more! I'm just keeping the UI simpler right now.
                const error: string = errors[errorKey][0];
                return (
                  <li>
                    {errorKeyToString(errorKey)} {error.toLowerCase()}
                  </li>
                );
              })}
            </ErrorList>
          )}

          <Actions>
            <div data-tip data-for="submit_action">
              <KoalaButton
                submit
                disabled={isLoading || !allowPasswordSubmit || password !== password_confirmation}
                loading={isLoading}
              >
                {t('onboardingPassword.update')}
              </KoalaButton>
            </div>
          </Actions>
        </Form>
      </Container>
      <Footer>
        <KoalaTextButton onClick={handleLogout} appearance="subtle">
          {t('shared.logout')}
        </KoalaTextButton>
      </Footer>
    </Wrapper>
  );
}

export default Login;
