import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  Edge,
  Node,
  Background,
  Controls,
  useReactFlow,
  NodeMouseHandler,
  OnNodesChange,
  OnEdgesChange,
  applyNodeChanges,
  applyEdgeChanges,
} from 'reactflow';
import 'reactflow/dist/style.css';
import styled from 'styled-components';
import PlanNode from './PlanNode';
import theme from 'theme';
import WorkspaceNode from './WorkspaceNode';
import useExpandCollapse from './usExpandCollapse';
import { Workspace } from 'types';
import { shallowEqual, useSelector } from 'react-redux';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const nodeTypes = {
  plan: PlanNode,
  workspace: WorkspaceNode,
};

interface Props {
  initialEdges: Edge[];
  initialNodes: Node[];
}

function Map(props: Props) {
  const { initialEdges, initialNodes } = props;
  const reactFlowInstance = useReactFlow();
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [fitNeeded, setFitNeeded] = useState(false);

  const onNodesChange: OnNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);
  const onEdgesChange: OnEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);

  // set expanded when arrow is clicked in PlanNode
  const handleNodeExpand: NodeMouseHandler = useCallback(
    (_, node) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, expanded: !n.data.expanded },
            };
          }

          return n;
        }),
      );
      setFitNeeded(true);
    },
    [setNodes],
  );

  // set expanded when arrow is clicked in PlanNode
  const handleNodeExpandOutcomes: NodeMouseHandler = useCallback(
    (_, node) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, outcomesExpanded: !n.data.outcomesExpanded },
            };
          }

          return n;
        }),
      );
      setFitNeeded(true);
    },
    [setNodes],
  );

  const handleFitView: NodeMouseHandler = useCallback(() => {
    setFitNeeded(true);
  }, [setFitNeeded]);

  // when initial values change, set edges and nodes
  useEffect(() => {
    setEdges([...initialEdges]);
    // pass handleNodeExpand callback to nodes
    setNodes(() =>
      initialNodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            handleExpand: handleNodeExpand,
            handleFitView: handleFitView,
            handleExpandOutcomes: handleNodeExpandOutcomes,
          },
        };
      }),
    );
  }, [handleNodeExpand, initialEdges, initialNodes, handleFitView, handleNodeExpandOutcomes]);

  // handle expand, collapse and layout
  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(nodes, edges, {
    layoutNodes: true,
    treeWidth: 520,
    treeHeight: 500,
  });

  // when visible nodes change, update stored value for expanded plans
  useEffect(() => {
    const newExpanded = visibleNodes.reduce((dict: { [key: string]: boolean }, { id, data }) => {
      dict[id] = data.expanded;
      return dict;
    }, {});

    const neOutcomesExpanded = visibleNodes.reduce((dict: { [key: string]: boolean }, { id, data }) => {
      dict[id] = data.outcomesExpanded;
      return dict;
    }, {});
    const storageKey = `__tbty_${workspace.slug}_expanded_plans`;
    localStorage.setItem(storageKey, JSON.stringify(newExpanded));

    const storageKeyPlanOutcomes = `__tbty_${workspace.slug}_expanded_plan_outcomes`;
    localStorage.setItem(storageKeyPlanOutcomes, JSON.stringify(neOutcomesExpanded));
  }, [visibleNodes, workspace]);

  // // fit graph in screen
  useEffect(() => {
    if (fitNeeded) {
      reactFlowInstance.fitView({ duration: 300, maxZoom: 1.2, minZoom: 0.2 });
      setFitNeeded(false);
    }
  }, [fitNeeded, reactFlowInstance]);

  return (
    <Container>
      <ReactFlow
        nodes={visibleNodes}
        edges={visibleEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        snapToGrid={true}
        minZoom={0.1}
        maxZoom={2}
        proOptions={{ account: 'paid-pro', hideAttribution: true }}
        defaultViewport={{
          x: 0,
          y: 0,
          zoom: 0.8,
        }}
      >
        <Background color={theme.colors.N20} size={2} />
        <Controls />
      </ReactFlow>
    </Container>
  );
}

export default Map;
