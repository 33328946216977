import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import * as objectiveUtils from 'utils/objectiveUtils';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import PlanIconLabel from 'components/PlanIconLabel';
import * as routes from 'routes';

import KoalaProgressBar from 'koala/components/ProgressBar';
import KoalaNCSPie from 'koala/components/NCSPie';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';

const BlockGrid = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-areas: 'objective plan meta';
  grid-template-columns: 1fr 40rem 10rem;
  grid-template-rows: auto;
  align-items: center;
  column-gap: ${theme.spacing.x2};
  margin: 0;
  padding: 1.2rem ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.blockBorder};

  a {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x1};
    max-width: 99%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }

  &:hover {
    background: ${theme.colors.N3};
  }
  &:active,
  &.selected,
  &:focus {
    background: ${theme.colors.B5};
  }

  &.no-hover-bg {
    &:hover {
      background: transparent;
    }
  }
  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr 20rem;
    grid-template-areas: 'objective plan';
  }

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr;
    grid-template-areas: 'objective';
  }

  @media ${theme.devices.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas: 'objective';
  }
`;

const BlockMeta = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};

  @media ${theme.devices.laptop} {
    display: none;
  }

  @media ${theme.devices.mobile} {
    display: none;
  }
`;

const BlockTitle = styled(Link)`
  grid-area: objective;
  min-width: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.closed {
    text-decoration: line-through;
  }
`;

const BlockPlan = styled.div`
  grid-area: plan;
  display: flex;
  .plan-title {
    display: block;
    width: fit-content;
  }

  @media ${theme.devices.tablet} {
    display: none;
  }
`;
interface Props {
  objective: TabilityTypes.Objective;
}

function InitiativeBlockCompact(props: Props) {
  const { objective } = props;
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const blockId = `objective:${objective.nano_slug}`;
  const hashPath = `#${blockId}:show`;
  const isSelected = location.hash.includes(hashPath);
  const { plan } = objective;

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashPath);
  };
  const handleStopPropagation = (e: any) => {
    e.stopPropagation();
  };
  const planUrl = routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    plan.id,
  );

  const selectedClass = isSelected ? 'selected' : '';

  const confidenceData = objectiveUtils.getConfidenceData(objective);
  const objectiveProgress = Math.round((objective.outcome_progress_prct || 0) * 100);

  return (
    <BlockGrid onClick={handleClick} className={`item ${selectedClass}`}>
      <BlockTitle to={hashPath} onClick={(e) => e.stopPropagation()}>
        <KoalaIcon iconName="flag" iconAppearance={theme.colors.T80} iconSize={1.8} />
        {objective.title}
      </BlockTitle>
      <BlockPlan>
        <Link className="subtle" to={planUrl} onClick={handleStopPropagation}>
          <PlanIconLabel plan={plan} size="small" crop={40} />
        </Link>
      </BlockPlan>
      <BlockMeta>
        <KoalaProgressBar
          value={objectiveProgress}
          appearance="custom"
          bgColor={theme.colors.T20}
          barColor={theme.colors.T70}
          dataFor={`obj-percentage-${objective.nano_slug}`}
        />
        <ReactTooltip
          place="bottom"
          type="dark"
          className="tooltip"
          effect="solid"
          id={`obj-percentage-${objective.nano_slug}`}
        >
          {t('workspaceFilters.progressAcross', {
            progress: objectiveProgress,
            type: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
          })}
        </ReactTooltip>
        <KoalaNCSPie data={confidenceData} size="xsmall" centerLabel={''} dataFor={`obj-ncs-${objective.nano_slug}`} />
        <ReactTooltip
          place="bottom"
          type="dark"
          className="tooltip"
          effect="solid"
          id={`obj-ncs-${objective.nano_slug}`}
        >
          {Math.round(objective.ncs)} {t('shared.ncs.acronym')}
        </ReactTooltip>
      </BlockMeta>
    </BlockGrid>
  );
}

export default React.memo(InitiativeBlockCompact);
