import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setGlobalPanelContent } from 'state/actions/globalUIActions';

const sanitizedHash = (hash: string) => {
  return hash.replace('#', '');
};

function HashHandler() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { hash } = location;

  useEffect(() => {
    if (hash && sanitizedHash(hash).split(':').length >= 3) {
      dispatch(setGlobalPanelContent(sanitizedHash(hash)));
    } else {
      dispatch(setGlobalPanelContent(''));
    }
  }, [hash, dispatch]);

  return null;
}

export default React.memo(HashHandler);
