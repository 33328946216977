// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { getTime, isBefore, isAfter, parseISO, format, toDate } from 'date-fns';
import * as planUtils from 'utils/planUtils';
import EmptyStatePanel from 'components/EmptyStatePanel';
import { nFormatter } from 'utils/outcomeUtils';
import Chart from 'components/CheckinChart';
import { VictoryChart, VictoryArea, VictoryAxis, VictoryVoronoiContainer, VictoryGroup } from 'victory';
import { useTranslation } from 'react-i18next';
// import { debounce } from 'lodash';
// import useResizeObserver from 'utils/resizeObserver';

const Container = styled.div`
  height: 25rem;
  display: flex;
  margin-bottom: ${theme.spacing.x1};
  width: 100%;

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

interface Props {
  trends: any[];
  plan: TabilityTypes.Plan;
}

interface ChartProps {
  ncsData: any;
  isInsideTimeline: boolean;
  xDomain: any;
  width: number;
}
function RenderChart(props: ChartProps) {
  const { ncsData, isInsideTimeline, xDomain, width } = props;
  const { i18n, t } = useTranslation();
  return (
    <VictoryChart
      width={width}
      height={250}
      domainPadding={{ x: 15 }}
      padding={{ top: 30, bottom: 10, left: 50, right: 10 }}
      domain={{ y: [-100, 100], x: xDomain }}
      containerComponent={<VictoryVoronoiContainer />}
    >
      {Chart.XAxis(i18n)}
      <VictoryAxis
        dependentAxis
        tickFormat={(t: number) => {
          return nFormatter(t, 2);
        }}
        style={{
          grid: { stroke: 'transparent' },
          axis: { stroke: 'transparent' },
          tickLabels: {
            fill: theme.colors.N60,
            fontFamily: theme.font.fontFamily,
            fontSize: '12px',
          },
        }}
      />
      {isInsideTimeline && Chart.TodayLine({ min: -100, max: 100 }, t)}
      <VictoryGroup scale="time">
        <VictoryArea
          style={{
            data: {
              stroke: theme.colors.B50,
              fill: theme.colors.B20,
              fillOpacity: 0.2,
              strokeWidth: 3,
              strokeLinecap: 'round',
            },
          }}
          labels={({ datum }) => [`${format(toDate(datum.x), 'd MMM yyyy')}`, `${datum.displayText}`]}
          labelComponent={Chart.LabelTooltip()}
          data={ncsData}
          interpolation="monotoneX"
        />
      </VictoryGroup>
    </VictoryChart>
  );
}

function CheckinsChart(props: Props) {
  const { trends, plan } = props;

  // resize the chart to fit the container
  const chartRef: any = useRef(null);

  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = (ev: Event) => {
    if (chartRef && chartRef.current) {
      setWidth(chartRef.current.offsetWidth);
    }
  };

  // useEffect replaces `componentDidMount` and others
  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    // Removes listener on unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      setWidth(chartRef.current.offsetWidth);
    }
  }, [chartRef]);

  // If the plan doesn't have a start and end date, display a warning
  if (!planUtils.hasTimeline(plan)) {
    return (
      <EmptyStatePanel>
        <p>Sorry, you need to define a timeline for your plan to see the progress chart.</p>
      </EmptyStatePanel>
    );
  }

  const ncsData: any[] = [];

  const startDate: Date = parseISO(plan.start_at);
  const finishDate: Date = parseISO(plan.finish_at);

  const firstPoint = {
    x: startDate,
    y: 0,
    timestamp: getTime(startDate),
    displayText: '0 NCS',
  };

  // By default we're going to use the first point from the target
  // We'll change that if we find a checkin prior to the first point.
  let useFirstPoint = true;
  let chartFinishDate = finishDate;

  trends.forEach((trend: any, index: number) => {
    const date = parseISO(trend.reference_date);
    // If the checkin was done prior to the first point we don't store
    // the first point in the list of current points
    if (isBefore(date, firstPoint.x)) {
      useFirstPoint = false;
    }

    if (isAfter(date, chartFinishDate)) {
      chartFinishDate = date;
    }

    // Create the point using the checkin data
    const ncsPoint: any = {
      x: date,
      y: trend.ncs,
      timestamp: getTime(date),
      displayText: `${Math.round(trend.ncs)} NCS`,
      trend,
    };
    ncsData.push(ncsPoint);
  });

  if (useFirstPoint) {
    ncsData.push(firstPoint);
  }

  const isInsideTimeline = planUtils.isInPlanTimeline(startDate, finishDate, new Date());
  const xDomain = [startDate, chartFinishDate];

  // Now we can display the app with the Chrome
  return (
    <Container className="panel-checkins-chart" ref={chartRef}>
      <RenderChart width={width} ncsData={ncsData} isInsideTimeline={isInsideTimeline} xDomain={xDomain} />
    </Container>
  );
}

export default CheckinsChart;
