import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

// Components
import Loader from 'components/Loader';
import OutcomeBlock from './OutcomeBlock';
import { Outcome } from 'types';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const Empty = styled.div`
  margin-bottom: ${theme.spacing.x2};
  color: ${theme.colors.subtleText};
`;

const Container = styled.div`
  display: flex;
  margin-top: ${theme.spacing.x2};
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

interface Props {
  objectiveId: string;
}

function OutcomeList(props: Props) {
  const { objectiveId } = props;

  // Query keys and query params
  const queryKey = [queryKeys.outcomes, objectiveId];
  const staleTime = 0;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [outcomes, setOutcomes] = useState<Outcome[]>([]);

  // Get the outcomes
  const { isLoading } = useQuery(queryKey, remoteApi.fetchOutcomes, {
    staleTime,
    onSuccess: (response) => {
      setOutcomes(response.data);
    },
  });

  // Now we can display the app with the Chrome
  if (isLoading) {
    return <Loader size="big" />;
  }

  return (
    <Container>
      {outcomes.length === 0 && (
        <Empty>
          {t('panels.noOutcomes', {
            objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
            outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
          })}
        </Empty>
      )}
      {outcomes.map((outcome) => {
        return <OutcomeBlock key={outcome.id} outcome={outcome} isSelected={false} />;
      })}
    </Container>
  );
}

export default React.memo(OutcomeList);
