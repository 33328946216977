import React, { Fragment, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalGrid, ModalHeader, ModalContent } from 'components/GlobalModal';
import styled from 'styled-components';
import theme from 'theme';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import parse from 'parse-link-header';

import * as remoteApi from 'api/remote';

// Custom components
import KoalaIconButton from 'koala/components/IconButton';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { Template } from 'types';
import TemplateBlock from './TemplateBlock';
import TemplateContent from './TemplateContent';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';

const TemplatesWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: ${theme.spacing.x2};
`;

const ListWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const TemplateListContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
`;
const TemplatePreview = styled.div`
  position: relative;
  width: 70%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CategoriesWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 ${theme.spacing.x3} 0;
  padding: 0 ${theme.spacing.x3};
  flex-flow: wrap;
  gap: ${theme.spacing.x1};
  max-height: 10rem;
  overflow: hidden;
  input[type='radio'] {
    visibility: hidden;
    display: none;
  }

  input[type='radio']:checked + label {
    background-color: ${theme.colors.blue};
    color: #fff;
  }
`;

const CategoryLabel = styled.label`
  background: transparent;
  padding: ${theme.spacing.x1} ${theme.spacing.x1};
  font-size: 1rem;
  line-height: 1rem;
  color: ${theme.colors.blue};
  font-weight: 800;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;

  border: 1px solid ${theme.colors.blue};

  &:hover {
    background: rgba(50, 77, 211, 0.15);
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing.x2};
  flex: 1;
  input {
    width: 70%;
  }
`;

const ListingTitle = styled.h3`
  padding-left: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x2};
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

interface Props {
  planId: string;
}

function capitalizeFirstLetter(tagToCapitalize: string) {
  return tagToCapitalize.charAt(0).toUpperCase() + tagToCapitalize.slice(1);
}

function TemplateDirectory(props: Props) {
  const { planId } = props;
  const dispatch = useDispatch();
  const [selectedTag, setSelectedTag] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [nameToSearch, setNameToSearch] = useState('');
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  const { t } = useTranslation();
  const translationKey = 'modals.templatesDirectory';
  // Construct the query key using the plan Id

  interface queryParamProps {
    tag?: string;
    keyword?: string;
    limit: number;
  }

  let queryParams: queryParamProps = { limit: 50 };

  if (selectedTag) {
    queryParams = { tag: selectedTag.replaceAll('-', ' '), limit: 50 };
  }

  if (nameToSearch) {
    queryParams = { keyword: nameToSearch, limit: 50 };
  }

  const queryKey = [queryKeys.publicTemplates, queryParams];

  const getNextPage = (response: AxiosResponse<Template[]>) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  const {
    isFetching,
    fetchMore,
    canFetchMore,
    data: templateResponse,
  } = useInfiniteQuery([queryKey, queryParams], remoteApi.fetchTemplates, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  let templates: AxiosResponse<Template[]>[] = [];
  if (templateResponse) {
    templates = templateResponse;
  }

  let resultsCount = 0;

  if (templates.length > 0) {
    resultsCount = parseInt(templates[0].headers['x-total']);
  }

  // Close the modal
  const handleCancel = () => {
    dispatch(setGlobalModalContent(''));
  };

  const handleSelectTag = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tag = e.target.value;
    setSelectedTag(tag);
    setSearchValue('');
    setNameToSearch('');
  };

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  // @ts-ignore
  const selectedTagCapitalized = selectedTag
    ? capitalizeFirstLetter(selectedTag.replaceAll('-', ' '))
    : t(`${translationKey}.all`);

  const performSearch = (newName: string) => {
    setSelectedTag('');
    setNameToSearch(newName);
  };

  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );

  const handleTemplateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setSearchValue(newName);
    debouncePerformSearch.current(newName);
  };

  const handleSelectTagFromDiv = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const tag = e.currentTarget.dataset.tag ?? '';
    setSelectedTag(tag);
    setSelectedTemplateId(null);
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <SearchContainer>
          <input
            placeholder={t(`${translationKey}.placeholder`) ?? 'Search templates'}
            onChange={handleTemplateSearch}
            value={searchValue}
          />
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </SearchContainer>
      </ModalHeader>
      <ModalContent>
        <Container>
          <CategoriesWrapper onClick={handleStopPropagation}>
            <input
              type="radio"
              name="category"
              checked={!selectedTag}
              value=""
              id="category-all"
              onChange={handleSelectTag}
            />
            <CategoryLabel htmlFor="category-all">{t(`${translationKey}.all`)}</CategoryLabel>
            <input
              type="radio"
              name="category"
              checked={selectedTag === 'startups'}
              value="startups"
              id="category-startups"
              onChange={handleSelectTag}
            />
            <CategoryLabel htmlFor="category-startups">{t(`${translationKey}.startups`)}</CategoryLabel>
            <input
              type="radio"
              name="category"
              checked={selectedTag === 'product'}
              value="product"
              id="category-product"
              onChange={handleSelectTag}
            />
            <CategoryLabel htmlFor="category-product">{t(`${translationKey}.product`)}</CategoryLabel>
            <input
              type="radio"
              name="category"
              checked={selectedTag === 'marketing'}
              value="marketing"
              id="category-marketing"
              onChange={handleSelectTag}
            />
            <CategoryLabel htmlFor="category-marketing">{t(`${translationKey}.marketing`)}</CategoryLabel>
            <input
              type="radio"
              name="category"
              checked={selectedTag === 'engineering'}
              value="engineering"
              id="category-engineering"
              onChange={handleSelectTag}
            />
            <CategoryLabel htmlFor="category-engineering">{t(`${translationKey}.engineering`)}</CategoryLabel>
            <input
              type="radio"
              name="category"
              checked={selectedTag === 'customer-success'}
              value="customer-success"
              id="category-success"
              onChange={handleSelectTag}
            />
            <CategoryLabel htmlFor="category-success">{t(`${translationKey}.customer-success`)}</CategoryLabel>
            <input
              type="radio"
              name="category"
              checked={selectedTag === 'design'}
              value="design"
              id="category-design"
              onChange={handleSelectTag}
            />
            <CategoryLabel htmlFor="category-design">{t(`${translationKey}.design`)}</CategoryLabel>
            <input
              type="radio"
              name="category"
              checked={selectedTag === 'sales'}
              value="sales"
              id="category-sales"
              onChange={handleSelectTag}
            />
            <CategoryLabel htmlFor="category-sales">{t(`${translationKey}.sales`)}</CategoryLabel>
            <input
              type="radio"
              name="category"
              checked={selectedTag === 'hr'}
              value="hr"
              id="category-hr"
              onChange={handleSelectTag}
            />
            <CategoryLabel htmlFor="category-hr">{t(`${translationKey}.hr`)}</CategoryLabel>
            <input
              type="radio"
              name="category"
              checked={selectedTag === 'support'}
              value="support"
              id="category-support"
              onChange={handleSelectTag}
            />
            <CategoryLabel htmlFor="category-support">{t(`${translationKey}.support`)}</CategoryLabel>
          </CategoriesWrapper>
          <TemplatesWrapper>
            <ListWrapper>
              <TemplateListContainer>
                <ListingTitle>
                  {t(`${translationKey}.tagTemplate`, { tag: selectedTagCapitalized, search: nameToSearch })}&nbsp; (
                  {resultsCount}){isFetching && <KoalaLoader />}
                </ListingTitle>
                {templates.map((group, groupKey) => (
                  <Fragment key={groupKey}>
                    {group.data.map((template, i) => {
                      return (
                        <TemplateBlock
                          selectedTemplateId={selectedTemplateId}
                          setSelectedTemplateId={setSelectedTemplateId}
                          template={template}
                          handleSelectTag={handleSelectTagFromDiv}
                          key={i}
                        />
                      );
                    })}
                  </Fragment>
                ))}
                {resultsCount === 0 && (
                  <p className="subtle" style={{ marginLeft: theme.spacing.x1 }}>
                    No results found
                  </p>
                )}

                {canFetchMore && (
                  <LoadMore>
                    <KoalaButton loading={isFetching} onClick={() => fetchMore()} appearance="secondary">
                      {t('shared.loadMore')}
                    </KoalaButton>
                  </LoadMore>
                )}
              </TemplateListContainer>
            </ListWrapper>
            {selectedTemplateId && (
              <TemplatePreview>
                <TemplateContent
                  planId={planId}
                  // @ts-ignore
                  selectedTemplateId={selectedTemplateId}
                  setSelectedTemplateId={setSelectedTemplateId}
                  handleSelectTag={handleSelectTagFromDiv}
                />
              </TemplatePreview>
            )}
          </TemplatesWrapper>
        </Container>
      </ModalContent>
    </ModalGrid>
  );
}

export default TemplateDirectory;
