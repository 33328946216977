import KoalaTextBadge from 'koala/components/TextBadge';
import { KoalaTextBadgeVariants } from 'koala/shared/global';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Plan } from 'types';

interface Props {
  plan: Plan;
  size?: 'small' | 'medium';
}

const Container = styled.div`
  width: fit-content;
`;
function PlanStatus(props: Props) {
  const { plan, size } = props;
  const { t } = useTranslation();

  let stateLabel = plan.state;
  let stateLabelClass: (typeof KoalaTextBadgeVariants)[number] = 'neutral-light';
  if (plan.state === 'published' && plan.active) {
    stateLabel = t('shared.status.inProgress') ?? 'In Progress';
    stateLabelClass = 'blue-light';
  }
  if (plan.state === 'published' && plan.expired) {
    stateLabel = t('shared.status.done') ?? 'Done';
    stateLabelClass = 'neutral-light';
  }
  if (plan.state === 'draft') {
    stateLabel = t('shared.status.draft') ?? 'Draft';
    stateLabelClass = 'yellow';
  }
  if (plan.is_dummy) {
    stateLabel = t('shared.status.sample') ?? 'Sample Plan';
    stateLabelClass = 'violet-light';
  }
  if (plan.archived) {
    stateLabel = t('shared.status.archived') ?? 'Archived';
    stateLabelClass = 'violet-light';
  }

  return (
    <Container>
      <KoalaTextBadge isLowercase={true} size={size} variant={stateLabelClass}>
        {stateLabel}
      </KoalaTextBadge>
    </Container>
  );
}

export default PlanStatus;
