import { KoalaSizes } from 'koala/shared/global';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import spacing from 'theme/spacing';
import KoalaLoader from 'koala/components/Loader';

const NavTitle = styled.div<{ isEditing: boolean; hasText: boolean }>`
  &.hide-overflow {
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.show-overflow {
    height: 100%;
    width: 100%;
    white-space: normal;
  }
  border-radius: 4px;
  cursor: pointer;
  padding: 0.4rem;
  max-width: 80rem;
  display: ${(props) => (props.isEditing ? 'none' : 'block')};
  font-style: ${(props) => (props.hasText ? 'none' : 'italic')};
  border: none;
  background: none;
  text-align: left;
  &:hover {
    background: #0f0f0f19;
  }
  &.editable {
    &:focus,
    &:active {
      border: 2px solid ${colors.B50};
      outline-offset: 1px;
    }
  }
  &.read-only {
    cursor: default;
    &:hover {
      background: unset;
    }
  }
`;

const Container = styled.div<{ isEditing: boolean }>`
  line-height: 1.2;
  width: ${(props) => (props.isEditing ? '100%' : 'initial')};
  display: flex;
  align-items: center;
  gap: ${spacing.x1};
  overflow: hidden;
  button {
    color: ${colors.N100};
  }
  input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  }
  &.inline-text--small {
    > div,
    input {
      font-size: 1.4rem;
      font-weight: 400;
    }
  }
  &.inline-text--medium {
    > div,
    input {
      font-size: 1.8rem;
      font-weight: 600;
    }
  }
  &.inline-text--large {
    > div,
    input {
      font-weight: 700;
      font-size: 2.4rem;
    }
  }
`;

const TitleInputForm = styled.input<{ isEditing: boolean }>`
  border: none;
  width: 100%;
  overflow-wrap: break-word;
  outline: none;
  box-shadow: none;
  resize: none;
  padding: 0;
  margin: 0;
  display: ${(props) => (props.isEditing ? 'block' : 'none')};
  padding: 0.4rem;
  background: transparent;

  &.hide-overflow {
    width: inherit;
    overflow-y: auto;
    overflow-x: none;
    overflow-wrap: break-word;
    height: 100%;
  }
  &.show-overflow {
    height: 100%;
    min-width: 100%;
  }

  &:focus {
    outline: 0;
  }
`;

interface Props {
  size?: (typeof KoalaSizes)[number];
  text: string;
  placeholder: string;
  handleChangeText: (value: string) => void;
  hideOverflow?: boolean;
  canEdit?: boolean;
  isUpdating?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

function KoalaInlineTextInput(props: Props) {
  const { size, className, style, text, placeholder, canEdit, handleChangeText, hideOverflow, isUpdating } = props;
  const inputEl: any = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(text);

  useEffect(() => {
    if (inputEl && inputEl.current && isEditing) {
      const inputText = inputEl.current;
      inputText.focus();
      inputText.select();
    }
  }, [isEditing, hideOverflow]);

  useEffect(() => {
    setTitle(text);
  }, [text]);

  const handlePress = (e: any) => {
    if (e.keyCode === 27) {
      // Esc key
      e.preventDefault();
      setTitle(text);
      setIsEditing(false);
    }

    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  const handleEdit = (e: any) => {
    if (canEdit) {
      setIsEditing(true);
    }
  };
  const handleBlur = (e: any) => {
    handleChangeText(title);
    setIsEditing(false);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    let title = e.target.value || '';
    title = title.replace(/(\r\n|\n|\r)/gm, ''); // Remove new lines
    setTitle(title);
  };

  const containerClass = `inline-text ${className ?? ''} inline-text--${size}`;
  const navTextClass = `content ${canEdit ? 'editable' : 'read-only'} ${
    hideOverflow ? 'hide-overflow' : 'show-overflow'
  }`;
  const hasText = text !== '';
  const displayedText = hasText ? text : placeholder;

  return (
    <Container className={containerClass} style={style} isEditing={isEditing}>
      {!isEditing && (
        <NavTitle className={navTextClass} onClick={handleEdit} isEditing={isEditing} hasText={hasText}>
          {displayedText}
        </NavTitle>
      )}
      {isEditing && (
        <TitleInputForm
          ref={inputEl}
          placeholder={placeholder}
          onKeyDown={handlePress}
          onChange={handleChange}
          onBlur={handleBlur}
          value={title}
          isEditing={isEditing}
        />
      )}
      {isUpdating && <KoalaLoader />}
    </Container>
  );
}

KoalaInlineTextInput.defaultProps = {
  size: 'medium',
  canEdit: true,
};

export default KoalaInlineTextInput;
