import React, { useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import ReactCanvasConfetti from 'react-canvas-confetti';

import { useSelector, shallowEqual } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import PlanPublished from './Chart_tabby.png';

const ImgWrapper = styled.div`
  min-width: 40%;
  width: 40%;
  img {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  gap: ${theme.spacing.x4};
  align-items: center;
  max-width: 60rem;
  margin: 0 auto;

  h3 {
    font-size: 1.6rem;
    font-weight: 700;
  }

  > div.content {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2};
  }
`;

function StreakLadder() {
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const translationKey = 'modals.planPublished';
  const refAnimationInstance: any = useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isOnboardingRoute = params.get('onboarding') === '1';

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.1, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.1, {
      spread: 60,
    });

    makeShot(0.15, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  useEffect(() => {
    if (refAnimationInstance && refAnimationInstance.current) {
      fire();
    }
  }, [refAnimationInstance, fire]);

  return (
    <Container>
      {isOnboardingRoute && (
        <ReactCanvasConfetti
          refConfetti={getInstance}
          style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, pointerEvents: 'none' }}
        />
      )}
      <div className="content">
        <h1>{t(`${translationKey}.title`)}</h1>
        <h3>{t(`${translationKey}.info`)}</h3>
        <p>
          {t(`${translationKey}.content`, {
            label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
          })}
        </p>
      </div>
      <ImgWrapper>
        <img src={PlanPublished} alt="Tabby is content!" />
      </ImgWrapper>
    </Container>
  );
}

export default StreakLadder;
