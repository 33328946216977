import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { useParams } from 'react-router-dom';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import PlanSelect from './PlanSelect';
import { DashboardWidget } from 'types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;
`;

interface Props {
  handleSaveWidget: (type: string, source: string, source_id: string, widgetId?: string) => void;
  isLoading: boolean;
  isEditing?: boolean;
  widget?: DashboardWidget;
}

function PlanNCSGraphWidgetDetails(props: Props) {
  const { handleSaveWidget, isLoading, isEditing, widget } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const [planId, setPlanId] = useState<string>(widget ? widget.source_id : '');

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (!planId) {
      return;
    }
    handleSaveWidget('plan-ncs-graph', 'plan', planId, widget?.id);
  };

  return (
    <Container>
      <PlanSelect workspaceSlug={workspaceSlug} setPlanId={setPlanId} existingPlan={widget?.source} />
      <div>
        <KoalaButton onClick={handleSave} loading={isLoading} disabled={!planId}>
          {isEditing ? t('shared.save') : t('shared.add')}
        </KoalaButton>
      </div>
    </Container>
  );
}

export default PlanNCSGraphWidgetDetails;
