import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Objective, Outcome, Plan } from 'types';

import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import Loader from 'components/Loader';

// API
import * as remoteApi from 'api/remote';
import KoalaTextBadge from 'koala/components/TextBadge';
import { t } from 'i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const Container = styled.div`
  background: ${theme.colors.backgroundLight};
  border-radius: 4px;
  padding: ${theme.spacing.x2};
  margin-top: ${theme.spacing.x1};
`;

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  .plan-title {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.4;
    margin-bottom: ${theme.spacing.x1};
  }
  .plan-icon {
    display: center;
    align-items: center;
  }
`;

const Grid = styled.div`
  display: grid;

  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  gap: ${theme.spacing.x1};

  .objective {
    font-weight: 600;
  }
  .outcome {
    font-weight: 400;
  }
`;

const BadgeWrapper = styled.div`
  display: flex;
  justify-self: flex-end;
`;

interface Props {
  parent: Plan;
}

function PlanOverviewNav(props: Props) {
  const { parent } = props;
  const planId = parent.id;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [outcomesByObjective, setOutcomesByObjective] = useState<{ [key: string]: Outcome[] }>({});
  const [isPrivate, setIsPrivate] = useState(false);

  // Load the data for the nav
  // Get the objectives
  const { isLoading: objectivesAreLoading } = useQuery([queryKeys.objectives, planId], remoteApi.fetchPlanObjectives, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const objectives: Objective[] = response.data;
      setObjectives(objectives);
    },
    onError: (err: any) => {
      if (err.response.status === 401) {
        setIsPrivate(true);
      }
    },
  });

  // Get the outcomes
  const { isLoading: outcomesAreLoading } = useQuery([queryKeys.outcomes, planId], remoteApi.fetchPlanOutcomes, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const outcomes: Outcome[] = response.data;
      const outcomesByObjective: { [key: string]: Outcome[] } = {};
      // Iterate on each outcome and group items by objective_id in an array
      outcomes.forEach((outcome) => {
        if (outcomesByObjective[outcome.objective_id]) {
          outcomesByObjective[outcome.objective_id].push(outcome);
        } else {
          outcomesByObjective[outcome.objective_id] = [outcome];
        }
      });
      setOutcomesByObjective(outcomesByObjective);
    },
  });

  // Display a loading container while we wait
  if (objectivesAreLoading || outcomesAreLoading) {
    return (
      <Container>
        <PlanContainer>
          <span className="plan-title">{parent.title}</span>
        </PlanContainer>

        <Loader />
      </Container>
    );
  }

  // Display a loading container while we wait
  if (isPrivate) {
    return (
      <Container>
        <PlanContainer>
          <span className="plan-title">{parent.title}</span>
        </PlanContainer>
        <p>{t('workspacePlan.write.noAccess')}</p>
      </Container>
    );
  }

  return (
    <Container>
      <PlanContainer>
        <span className="plan-title">{parent.title}</span>
      </PlanContainer>
      <Grid>
        {objectives.map((objective) => {
          const objectiveOutcomes = outcomesByObjective[objective.id];
          return (
            <Fragment key={objective.id}>
              <BadgeWrapper>
                <KoalaTextBadge variant="teal" size="small" edge="circle">
                  {translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}
                </KoalaTextBadge>
              </BadgeWrapper>
              <div className="objective">{objective.title}</div>
              {objectiveOutcomes &&
                objectiveOutcomes.map((outcome) => {
                  return (
                    <Fragment key={outcome.id}>
                      <BadgeWrapper>
                        <KoalaTextBadge variant="yellow" size="small" edge="circle">
                          #
                        </KoalaTextBadge>
                      </BadgeWrapper>
                      <div className="outcome">{outcome.title}</div>
                    </Fragment>
                  );
                })}
            </Fragment>
          );
        })}
      </Grid>
    </Container>
  );
}
export default PlanOverviewNav;
