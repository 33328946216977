import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import TableauIcon from '../_assets/IconTableau.png';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
}

function TableauIntegration(props: Props) {
  const { workspace, integrations } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];
  const dispatch = useDispatch();

  const [uninstallTableauMutation, { isLoading: isUninstallingTableau }] = useMutation(remoteApi.uninstallTableau, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const handleUninstallTableau = () => {
    uninstallTableauMutation(workspace.slug);
  };

  const handleInstallTableau = () => {
    dispatch(setGlobalModalContent('workspace::connect.tableau'));
  };
  const tableauIntegration = integrations.filter((i: Integration) => i.integration_type === 'tableau')[0];

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={TableauIcon} alt="TableauIcon" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'Tableau' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Tableau' })}</p>
        <p>
          <a href="https://guides.tability.app/docs/integrations/tableau" target="_blank" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {tableauIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: tableauIntegration.connected_to }}
              />
            </div>
            <KoalaTextButton size="small" onClick={handleUninstallTableau}>
              {isUninstallingTableau ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove')}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!tableauIntegration && (
          <KoalaButton onClick={handleInstallTableau}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Tableau' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default TableauIntegration;
