import React from 'react';

const DownloadIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 11.5C2.55228 11.5 3 11.9477 3 12.5V15.8333C3 16.1417 3.28645 16.5 3.77778 16.5H16.2222C16.7136 16.5 17 16.1417 17 15.8333V12.5C17 11.9477 17.4477 11.5 18 11.5C18.5523 11.5 19 11.9477 19 12.5V15.8333C19 17.3659 17.6946 18.5 16.2222 18.5H3.77778C2.30543 18.5 1 17.3659 1 15.8333V12.5C1 11.9477 1.44772 11.5 2 11.5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29289 6.79289C4.68342 6.40237 5.31658 6.40237 5.70711 6.79289L10 11.0858L14.2929 6.79289C14.6834 6.40237 15.3166 6.40237 15.7071 6.79289C16.0976 7.18342 16.0976 7.81658 15.7071 8.20711L10.7071 13.2071C10.3166 13.5976 9.68342 13.5976 9.29289 13.2071L4.29289 8.20711C3.90237 7.81658 3.90237 7.18342 4.29289 6.79289Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.5C10.5523 1.5 11 1.94772 11 2.5V12.5C11 13.0523 10.5523 13.5 10 13.5C9.44772 13.5 9 13.0523 9 12.5V2.5C9 1.94772 9.44772 1.5 10 1.5Z"
      fill="#737373"
    />
  </svg>
);

export default DownloadIcon;
