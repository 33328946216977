import KoalaTextBadge from 'koala/components/TextBadge';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import { AuditEvent } from 'types';
import { BlurDiv } from '.';
const UpdateRow = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;
interface Props {
  event: AuditEvent;
  canViewAudit: boolean;
}

function WorkspaceUpdated(props: Props) {
  const { event, canViewAudit } = props;
  const { t } = useTranslation();
  const { membership_cached_fullname, membership_cached_email, source_cached_title, membership_id, event_meta } = event;
  let name = membership_cached_fullname || membership_cached_email;
  if (!membership_id) {
    name += ` ${t('auditEvents.deleted')}`;
  }

  let eventData: any = [];
  try {
    const data = JSON.parse(event_meta);
    Object.keys(data).forEach(function (key) {
      let value: any = data[key];
      if (key === 'updated_at') {
        return;
      }
      const firstValue: string = value[0] ?? '';
      const secondValue: string = value[1] ?? '';

      eventData.push(
        <li>
          <UpdateRow>
            <Trans
              i18nKey="auditEvents.workspaceChanged"
              values={{ key, firstValue, secondValue }}
              components={{ b: <b />, label: <KoalaTextBadge variant="neutral-light">value</KoalaTextBadge> }}
            ></Trans>
          </UpdateRow>
        </li>,
      );
    });
  } catch (e) {}

  //  handle integrations installed
  const eventDetails = event.event_type.split('.');
  if (eventDetails[1] !== 'updated' && eventDetails.length === 3) {
    eventData.push(
      <li>
        <Trans
          i18nKey={
            eventDetails[2] === 'installed'
              ? 'auditEvents.workspaceIntegrationInstalled'
              : 'auditEvents.workspaceIntegrationUninstalled'
          }
          values={{ integration: eventDetails[1] }}
        ></Trans>
      </li>,
    );
  }

  return (
    <>
      <span>
        <Trans
          i18nKey="auditEvents.userUpdated"
          values={{ name, title: source_cached_title }}
          components={{ b: <b /> }}
        ></Trans>
      </span>
      <BlurDiv isBlurred={!canViewAudit}>{eventData.map((data: any) => data)}</BlurDiv>
    </>
  );
}

export default React.memo(WorkspaceUpdated);
