import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

import * as TabilityTypes from 'types';

// API
import * as remoteApi from 'api/remote';

import DropdownMenu from 'components/DropdownMenu';
import MarkdownContent from 'components/MarkdownContent';
import KoalaIconButton from 'koala/components/IconButton';
import Reactions from 'components/Reactions';
import { CachedReaction } from 'types';
import KoalaAvatar from 'koala/components/Avatar';
import BlockActions from 'components/BlockActions';
import { EmojiData, Picker } from 'emoji-mart';
import { CustomEmojisList } from 'components/Reactions/CustomEmojis';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowLocale } from 'utils/dateUtils';

const PickerContainer = styled.div`
  height: 0px;
  width: 0px;
  position: relative;
`;

const PickerInner = styled.div`
  width: 300px;
  height: fit-content;
  position: absolute;
  left: -270px;
  top: 15px;

  button:hover {
    background: ${theme.colors.N5};
  }
`;

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    '. content'
    '. footer';
  column-gap: ${theme.spacing.x1};
  row-gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  .block-actions {
    opacity: 0;
    top: ${theme.spacing.x1};
    right: ${theme.spacing.x1};
    padding: ${theme.spacing.half};
  }

  &:hover {
    background: ${theme.colors.N3};
    .block-actions {
      opacity: 1;
    }
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
  margin-top: 0.2rem;
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  min-height: 2.4rem;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.subtleText};
`;

const Content = styled.div`
  grid-area: content;
  .ql-editor {
    padding: 0 !important;
  }
`;

const Footer = styled.div`
  grid-area: footer;
  display: flex;
  align-items: center;
  color: ${theme.colors.subtleText};
`;

const ReactionsContainer = styled.div`
  .emoji-picker-inner-container {
    width: 280px;
  }
`;

interface Props {
  standup: TabilityTypes.Standup;
  setIsEditing: any;
  setShowNewComment: (value: boolean) => void;
}

function CommentBlock(props: Props) {
  const queryCache = useQueryCache();
  const { t, i18n } = useTranslation();
  const { standup, setIsEditing, setShowNewComment } = props;
  const user = standup.membership?.user;
  const emojiRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [reactions, setReactions] = useState<CachedReaction[]>([]);
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);

  const currentUserRole = useSelector((state: any) =>
    state.session.currentMembership ? state.session.currentMembership.role : null,
  );
  const currentUserId = useSelector((state: any) => (state.session.currentUser ? state.session.currentUser.id : null));

  // Review comment mutations
  const [deleteStandupMutation]: [any, any] = useMutation(remoteApi.deleteStandup, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.standups);
    },
  });

  let menu_items: any[] = [];

  if (user && currentUserId === user.id) {
    menu_items = [
      <span key="edit" data-action="edit" data-id={standup.id}>
        {t('shared.edit')}
      </span>,
      <span key="delete" data-action="delete" data-id={standup.id}>
        {t('shared.delete')}
      </span>,
    ];
  } else if (currentUserRole === 'owner' || currentUserRole === 'admin') {
    menu_items = [
      <span key="delete" data-action="delete" data-id={standup.id}>
        {t('shared.delete')}
      </span>,
    ];
  }

  const handleReaction = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const handleCreateComment = () => {
    setShowNewComment(true);
  };

  const handleMenuSelection = (value: ReactElement) => {
    const action = value.props['data-action'];
    const standupId = value.props['data-id'];
    switch (action) {
      case 'edit':
        setIsEditing(true);
        break;
      case 'delete':
        if (window.confirm('Delete this standup?')) {
          deleteStandupMutation(standupId);
        }
        break;
    }
  };

  useEffect(() => {
    if (standup.cached_reactions && typeof standup.cached_reactions !== 'string') {
      setReactions(standup.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [standup]);
  const [addReactionMutation] = useMutation(remoteApi.createStandupReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.standups);
    },
  });
  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.standups);
    },
  });

  const addEmoji = (emojiData: EmojiData | string, e: React.SyntheticEvent) => {
    e.stopPropagation();
    setShowEmojiPicker(false);
    if (typeof emojiData === 'string') {
      addReactionMutation({ standupId: standup.id, emotion: emojiData });
    } else if (typeof emojiData !== 'string' && emojiData.colons) {
      addReactionMutation({ standupId: standup.id, emotion: emojiData.colons });
    }
  };
  const userCustomEmojis = currentUser && currentUser.referrals_count ? CustomEmojisList : undefined;

  let standupClass = '';
  if (user && user.id === currentUserId) {
    standupClass = 'primary';
  }

  return (
    <Grid className={standupClass}>
      <Gutter>
        <KoalaAvatar user={user} size={2} hideTooltip />
      </Gutter>
      <Meta>
        <div>{user ? user.name : t('shared.deletedUser')}</div>
        <div className="time-block">{formatDistanceToNowLocale(standup.created_at, i18n, true)}</div>
      </Meta>
      <Content>
        <MarkdownContent source={standup.body} />
      </Content>
      <Footer>
        <ReactionsContainer>
          <Reactions
            reactions={reactions}
            size="small"
            addCallback={(emotion) => addReactionMutation({ standupId: standup.id, emotion: emotion })}
            removeCallback={(reactionId) => deleteReactionMutation(reactionId)}
            hideAddButton={true}
          />
        </ReactionsContainer>
      </Footer>
      <BlockActions className="block-actions">
        <KoalaIconButton edge="square" iconName="comment" onClick={handleCreateComment} size="small" />
        <KoalaIconButton edge="square" iconName="emoji" onClick={handleReaction} size="small" />
        <PickerContainer>
          <PickerInner ref={emojiRef} onClick={(e) => e.stopPropagation()} className="emoji-picker-inner-container">
            {showEmojiPicker ? (
              <Picker
                set="twitter"
                title="Pick emoji"
                emoji="point_up"
                style={{
                  position: 'relative',
                  zIndex: 99,
                  marginTop: `${theme.spacing.half}`,
                  width: '100%',
                }}
                onClick={addEmoji}
                custom={userCustomEmojis}
              />
            ) : null}
          </PickerInner>
        </PickerContainer>
        <DropdownMenu
          trigger={<KoalaIconButton edge="square" iconName="ellipsis" size="small" />}
          position="right"
          onSelection={handleMenuSelection}
          items={menu_items}
        />
      </BlockActions>
    </Grid>
  );
}

export default React.memo(CommentBlock);
