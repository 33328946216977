import KoalaButton from 'koala/components/Button';
import KoalaTextBadge from 'koala/components/TextBadge';
import React from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { Recommendation } from 'types';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { v4 as uuidv4 } from 'uuid';
import { nanoid } from 'nanoid';
import {
  editorCreateObjective,
  editorCreateOutcome,
  editorDeleteObjective,
  editorDeleteOutcome,
} from 'state/actions/workspaceEditorActions';

const RecommendationContainer = styled.div`
  border-radius: 4px;
  width: 100%;
  border: 2px solid #fff;
  padding: ${theme.spacing.x1};
  background: #fff;
  cursor: pointer;
  transition: all ease 0.2s;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);

  &:hover {
    border: 2px solid ${theme.colors.V30};
    background: #fff;
  }

  small {
    color: ${theme.colors.N60};
  }

  &.highlight {
    border: 2px solid ${theme.colors.V30} !important;

    .insight-description {
      max-height: 20rem;
    }
  }

  &.resolved {
    opacity: 0.5;

    &:hover {
      background: #fff;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
`;

const DescriptionSection = styled.div`
  transition: all ease 0.2s;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  max-height: 0;
  overflow: hidden;
  padding-top: 0.8rem;
`;

const RecommendationActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x1};
`;
const Label = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: #6a6a6a;
`;
const Content = styled.p`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  font-size: 1.3rem;
`;

interface Props {
  recommendation: Recommendation;
  selectedRecommendation: string;
  setSelectedRecomendationCallback: (newValue: string) => void;
}

function FeedbackCard(props: Props) {
  const { recommendation, selectedRecommendation, setSelectedRecomendationCallback } = props;
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const dispatch = useDispatch();

  const [updateRecommendationMutation, { isLoading: isAnalysisLoading }] = useMutation(remoteApi.updateRecommendation, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.planRecommendations, recommendation.plan_id]);
    },
  });

  const handleHighlight = (recommendation: Recommendation) => {
    // Remove previous highlights
    const highlightClass = 'ai-feedback-highlight';
    const highlighted = document.getElementsByClassName(highlightClass);
    Array.from(highlighted).forEach((element) => {
      element.classList.remove(highlightClass);
    });

    if (selectedRecommendation === recommendation.id) {
      setSelectedRecomendationCallback('');
    } else {
      setSelectedRecomendationCallback(recommendation.id);
      // Add highlight to the selected outcome
      let toHighlight = `${recommendation.source_type}:${recommendation.source_id}`;

      // if adding outcome, add highlight to the objective
      if (recommendation.source_type === 'outcome' && recommendation.action === 'add') {
        toHighlight = `objective:${recommendation.source_id}`;
      }
      const element = document.getElementsByClassName(toHighlight)[0];
      if (element) {
        element.classList.add(highlightClass);
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleAddOutcome = (recommendation: Recommendation) => {
    const newOutcome = {
      id: uuidv4(),
      nano_slug: nanoid(12),
      workspace_id: workspace.id,
      objective_id: recommendation.source_id,
      title: recommendation.suggestion,
      rank: '',
      from: 0,
      to: 100,
      score_format: '_number_%',
      membership_id: null,
    };
    dispatch(editorCreateOutcome(newOutcome));
  };

  const handleAddObjective = (recommendation: Recommendation) => {
    const newObjective: any = {
      id: uuidv4(),
      nano_slug: nanoid(12),
      workspace_id: workspace.id,
      plan_id: recommendation.plan_id,
      title: recommendation.suggestion,
      rank: '',
    };
    dispatch(editorCreateObjective(newObjective));
  };

  const handleDeleteObjective = (recommendation: Recommendation) => {
    const deletePlaceholder =
      t('workspacePlan.write.deleteConfirm', {
        label: translate(workspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
      }) ?? 'Are you sure that you want to delete this objective?';
    if (window.confirm(deletePlaceholder)) {
      dispatch(editorDeleteObjective(recommendation.source_id));
    }
  };

  const handleDeleteOutcome = (recommendation: Recommendation) => {
    const deletePlaceholder =
      t('workspacePlan.write.deleteConfirm', {
        label: translate(workspace, CustomTermKey.OUTCOME, 1).toLowerCase(),
      }) ?? 'Are you sure that you want to delete this outcome?';
    if (window.confirm(deletePlaceholder)) {
      dispatch(editorDeleteOutcome(recommendation.source_id));
    }
  };

  const handleImplement = (recommendation: Recommendation) => {
    const { action, source_type } = recommendation;
    switch (action) {
      case 'add':
        if (recommendation.source_type === 'outcome') {
          handleAddOutcome(recommendation);
        } else if (source_type === 'objective') {
          handleAddObjective(recommendation);
        }
        break;
      case 'remove':
        // Logic for removing an item
        if (recommendation.source_type === 'outcome') {
          handleDeleteOutcome(recommendation);
        } else if (recommendation.source_type === 'objective') {
          handleDeleteObjective(recommendation);
        }
        break;
      case 'update':
        // Logic for updating an existing item
        if (recommendation.source_type === 'outcome' || recommendation.source_type === 'objective') {
          const input = document.querySelector(
            `textarea[name="${recommendation.source_type}:${recommendation.source_id}"]`,
          ) as HTMLTextAreaElement | null;
          const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLTextAreaElement.prototype,
            'value',
          )?.set;
          if (input && nativeInputValueSetter) {
            nativeInputValueSetter.call(input, recommendation.suggestion);
            const event = new Event('input', { bubbles: true });
            input.dispatchEvent(event);
          }
        }
        break;
      default:
        console.warn(`Unhandled action type: ${recommendation.action}`);
    }
    updateRecommendationMutation({ recommendationId: recommendation.id, status: 'implemented' });
  };

  const handleDismiss = (recommendation: Recommendation) => {
    updateRecommendationMutation({ recommendationId: recommendation.id, status: 'dismissed' });
  };

  const selectedClass = recommendation.id === selectedRecommendation ? 'highlight' : '';
  const isResolved = recommendation.status !== 'pending';
  const resolvedClass = isResolved ? 'resolved' : '';

  const translatedStatus = t(`workspacePlan.write.aiFeedback.recommendationStatus.${recommendation.status}`);

  let sourceType = recommendation.source_type;
  switch (recommendation.source_type) {
    case 'objective':
      sourceType = translate(workspace, CustomTermKey.OBJECTIVE, 1);
      break;
    case 'outcome':
      sourceType = translate(workspace, CustomTermKey.OUTCOME, 1);
      break;
    case 'initiative':
      sourceType = translate(workspace, CustomTermKey.INITIATIVE, 1);
      break;
    case 'general':
      sourceType = t('workspacePlan.write.aiFeedback.generalSourceType');
      break;
    case 'analysis':
      sourceType = t('workspacePlan.write.aiFeedback.analysisSourceType');
      break;
  }

  const hideRecommendation =
    recommendation.source_type === 'general' ||
    recommendation.action === 'remove' ||
    recommendation.source_type === 'analysis';

  return (
    <RecommendationContainer
      key={recommendation.id}
      onClick={() => handleHighlight(recommendation)}
      className={`${selectedClass} ${resolvedClass}`}
    >
      <Header>
        <Label>{sourceType}</Label>
        {isResolved && (
          <KoalaTextBadge variant="neutral-outlined" maxWidth="fit-content">
            {translatedStatus}
          </KoalaTextBadge>
        )}
      </Header>

      <Content>{recommendation.description}</Content>
      {isAnalysisLoading && <KoalaLoader />}
      {!isResolved && (
        <DescriptionSection className="insight-description">
          {!hideRecommendation && <small>{recommendation.suggestion}</small>}
          <RecommendationActions>
            {recommendation.source_type !== 'general' && recommendation.source_type !== 'analysis' && (
              <KoalaButton
                size="small"
                appearance="subtle"
                onClick={() => handleImplement(recommendation)}
                loading={isAnalysisLoading}
                disabled={isAnalysisLoading}
              >
                {t(`workspacePlan.write.aiFeedback.${recommendation.action}Button`, { label: sourceType })}
              </KoalaButton>
            )}
            <KoalaButton
              size="small"
              appearance="secondary"
              onClick={() => handleDismiss(recommendation)}
              loading={isAnalysisLoading}
              disabled={isAnalysisLoading}
            >
              {t('workspacePlan.write.aiFeedback.dismissButton')}
            </KoalaButton>
          </RecommendationActions>
        </DescriptionSection>
      )}
    </RecommendationContainer>
  );
}

export default React.memo(FeedbackCard);
