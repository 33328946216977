import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as membershipUtils from 'utils/membershipUtils';
import styled from 'styled-components';
import theme from 'theme';

import CheckinPanel from 'panels/CheckinPanel';
import OutcomePanel from 'panels/OutcomePanel';
import OutcomeMapPanel from 'panels/OutcomeMapPanel';
import ObjectivePanel from 'panels/ObjectivePanel';
import EditOutcomePanel from 'panels/EditOutcomePanel';
import EditOutcomeRemoteSettingsPanel from 'panels/EditOutcomeRemoteSettingsPanel';
import EditInitiativePanel from 'panels/EditInitiativePanel';
import PlanArchives from 'panels/PlanArchives';
import WorkspaceArchives from 'panels/WorkspaceArchives';
import EditObjectivePanel from 'panels/EditObjectivePanel';
import TipsWritingOKRs from 'panels/TipsWritingOKRs';
import ViewUnsetInitiatives from 'panels/ViewUnsetInitiatives';
import InitiativePanel from 'panels/InitiativePanel';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';
import PreviewPlan from 'panels/PreviewPlan';
import CreateReview from 'panels/ReviewPanel';
import FollowingPanel from 'panels/FollowingPanel';
import FollowersPanel from 'panels/FollowersPanel';

const Mask = styled.div<{ showPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${(props) => (props.showPanel ? '70' : '-1')};
  opacity: ${(props) => (props.showPanel ? '1' : '0')};
  transition: 0.1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  display: ${(props) => (props.showPanel ? 'flex' : 'none')};
`;

const ContentWrapper = styled.div<{ showPanel: boolean }>`
  position: fixed;
  top: 5vh;
  left: 50%;
  width: 70rem;
  margin-left: -35rem;
  height: 90vh;
  z-index: 80;
  border-radius: 8px;
  transition: transform 0.2s ease-out;
  display: ${(props) => (props.showPanel ? 'initial' : 'none')};

  &.compact-panel {
    height: 60vh;
    top: 20vh;
  }

  &.narrow-global-panel {
    width: 65rem;
    margin-left: -30rem;
  }

  &.full-page-panel {
    height: 96vh;
    max-height: 96vh;
    width: 96%;
    max-width: 96%;

    top: 50%;
    left: 50%;

    margin-left: -48%;
    margin-top: -48vh;
  }

  &.outcome-panel {
    width: 120rem;
    margin-left: -60rem;
  }

  &.objective-panel {
    width: 120rem;
    margin-left: -60rem;
  }

  &.outcome-map {
    width: 90vw;
    margin-left: -45vw;
  }

  &.plan-map {
    width: 96vw;
    margin-left: -48vw;
  }

  &.initiative-panel {
    width: 100rem;
    margin-left: -50rem;
  }

  @media ${theme.devices.desktop} {
    &.outcome-panel {
      width: 90%;
      margin-left: -45%;
    }
  }

  @media ${theme.devices.laptop} {
    &.outcome-panel {
      width: 90%;
      margin-left: -45%;
    }
  }

  @media ${theme.devices.tablet} {
    &.narrow-global-panel {
      width: 90%;
      margin-left: -45%;
    }
    &.outcome-panel {
      width: 90%;
      margin-left: -45%;
    }
    width: 90%;
    margin-left: -45%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  box-shadow: rgba(21, 21, 21, 0.15) 0 0 10px 0;
  border-radius: 8px;

  background: #fff;
`;

export const PanelGrid = styled.div`
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'panel-header'
    'panel-content'
    'panel-actions';
  height: 100%;
`;

export const ModalPanelGrid = styled.div`
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'panel-header'
    'panel-content';
  height: 100%;
`;

export const PanelHeader = styled.div`
  grid-area: panel-header;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  border-radius: 8px 8px 0 0;

  .icon {
    transform: rotate(180deg);
  }

  @media ${theme.devices.mobile} {
    padding: ${theme.spacing.x2} ${theme.spacing.x2};
  }

  border-bottom: 1px solid ${theme.colors.blockBorder};
`;

export const PanelContent = styled.div`
  grid-area: panel-content;
  padding: ${theme.spacing.x2};
  overflow: auto;
  position: relative;
  &.outcome {
    padding: 0;
  }

  @media ${theme.devices.mobile} {
    padding: ${theme.spacing.x2};
  }
`;

export const PanelActions = styled.div`
  grid-area: panel-actions;
  padding: 1.2rem ${theme.spacing.x2};
  border-top: 1px solid ${theme.colors.blockBorder};
  display: flex;
  gap: ${theme.spacing.x2};
`;

function PanelControl() {
  const globalPanelContent: string = useSelector((state: any) => state.globalUI.globalPanelContent);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  let showPanel = false;
  let showMask = false;
  let content = null;
  let panelClassName = '';
  let hideOnBlur = true;

  // Check that we have the right params to display
  if (globalPanelContent && globalPanelContent.split(':').length >= 3) {
    const [entityType, entityId, action, option] = globalPanelContent.split(':');

    if (entityType === 'checkin' && action === 'show') {
      showPanel = true;
      content = <CheckinPanel checkinId={entityId} />;
    }

    if (entityType === 'outcome' && action === 'show') {
      showPanel = true;
      panelClassName = 'outcome-panel';
      content = <OutcomePanel outcomeId={entityId} />;
    }

    if (entityType === 'outcome' && action === 'map') {
      showPanel = true;
      panelClassName = 'outcome-map';
      content = <OutcomeMapPanel outcomeId={entityId} />;
    }

    if (entityType === 'objective' && action === 'show') {
      showPanel = true;
      panelClassName = 'objective-panel';
      content = <ObjectivePanel objectiveId={entityId} />;
    }
    if (entityType === 'objective' && action === 'edit' && !isReadOnly) {
      showPanel = true;
      panelClassName = 'objective-panel';
      content = <EditObjectivePanel objectiveId={entityId} />;
    }

    if (entityType === 'outcome' && action === 'edit') {
      showPanel = true;
      panelClassName = 'outcome-panel';
      hideOnBlur = false;
      content = <EditOutcomePanel outcomeId={entityId} selectedTab={option} />;
    }

    if (entityType === 'outcome' && action === 'edit_remote') {
      showPanel = true;
      panelClassName = 'narrow-global-panel';
      content = <EditOutcomeRemoteSettingsPanel outcomeId={entityId} />;
    }

    if (entityType === 'initiative' && action === 'edit') {
      showPanel = true;
      content = <EditInitiativePanel initiativeId={entityId} />;
    }
    if (entityType === 'initiative' && action === 'show') {
      showPanel = true;
      panelClassName = 'initiative-panel';

      content = <InitiativePanel initiativeId={entityId} />;
    }
    if (entityType === 'outcome' && action === 'view.unset') {
      showPanel = true;
      content = <ViewUnsetInitiatives outcomeId={entityId} />;
    }

    if (entityType === 'workspace' && action === 'archives') {
      showPanel = true;
      showMask = true;
      content = <WorkspaceArchives />;
    }

    if (entityType === 'plan' && action === 'archives') {
      showPanel = true;
      showMask = true;
      content = <PlanArchives planId={entityId} />;
    }
    if (entityType === 'plan' && action === 'preview') {
      showPanel = true;
      showMask = true;
      panelClassName = 'compact-panel';
      content = <PreviewPlan parentPlanId={entityId} />;
    }

    if (entityType === 'tips' && action === 'okrs') {
      showPanel = true;
      showMask = true;
      panelClassName = 'narrow-global-panel';
      content = <TipsWritingOKRs />;
    }

    if (entityType === 'review' && action === 'create') {
      showPanel = true;
      showMask = true;
      panelClassName = 'full-page-panel';
      content = <CreateReview />;
    }
    if (entityType === 'following' && action === 'show') {
      showPanel = true;
      showMask = true;
      panelClassName = 'compact-panel';
      content = <FollowingPanel />;
    }
    if (entityType === 'followers' && action === 'show') {
      showPanel = true;
      showMask = true;
      panelClassName = 'compact-panel';
      content = <FollowersPanel />;
    }
  }

  return (
    <Panel
      showPanel={showPanel}
      showMask={showMask}
      panelClassName={panelClassName}
      content={content}
      hideOnBlur={hideOnBlur}
    />
  );
}

interface Props {
  showPanel: boolean;
  hideOnBlur?: boolean;
  showMask: boolean;
  panelClassName?: string;
  content: any;
}
function Panel(props: Props) {
  const { showPanel, panelClassName, content } = props;
  const history = useHistory();
  const location = useLocation();
  const context = useHotkeysContext();
  useHotkeys(`escape`, () => handleClosePanel(), { scopes: ['panels'], enabled: !!content });

  // set scope so doesn't mess with page below
  useEffect(() => {
    if (content) {
      context.enableScope('panels');
      context.enableScope('*');
    } else {
      context.disableScope('panels');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const handleClosePanel = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    history.push(location.pathname);
  };

  const handleStopPropagation = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div>
      <Mask onClick={handleClosePanel} showPanel={showPanel} />
      <ContentWrapper showPanel={showPanel} className={panelClassName} onClick={handleStopPropagation}>
        <Content className="global-content">{content}</Content>
      </ContentWrapper>
    </div>
  );
}

export default React.memo(PanelControl);
