import CurrentGrowth from 'components/CurrentGrowth';
import KoalaAvatar from 'koala/components/Avatar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import { Outcome } from 'types';
import * as checkinUtils from 'utils/checkinUtils';
import * as outcomeUtils from 'utils/outcomeUtils';

const ContentContainer = styled.div<{ valueColor: string }>`
  display: flex;
  padding: 1.2rem ${theme.spacing.x2};
  width: 100%;
  z-index: 1;

  small {
    line-height: 19.2px;
    font-weight: 600;
    font-size: 1.6rem;
    color: ${(props) => props.valueColor};
  }
`;

const ScoreContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
`;

const MetricContainer = styled.div<{ valueColor: string }>`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
  flex: 1;

  big {
    font-size: 6rem;
    font-weight: 700;
    line-height: 55px;
    color: ${(props) => props.valueColor};
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${theme.spacing.x1};
`;

const ProgressBackground = styled.div<{ progress: number; color: string }>`
  width: ${(props) => props.progress}%;
  background: ${(props) => props.color};
  position: absolute;

  top: 0;
  left: 0;
  height: 100%;
`;

interface Props {
  outcome: Outcome;
}

function SummaryCard(props: Props) {
  const { outcome } = props;
  const { t } = useTranslation();

  const { current_checkin, outcome_type, score_format, membership } = outcome;

  let format = score_format ? score_format.split('_number_') : ['', ''];

  if (!format.includes('_number_')) {
    format = ['', ''];
  }

  const progressPrct = outcome.outcome_progress_prct * 100;
  const currentConfidence = current_checkin ? current_checkin.confidence : 'grey';

  let score = 'Pending';
  let showFormat = false;

  if (current_checkin && outcome_type === 'no_metric') {
    // if has current checkin and no metric, use confidence
    score = checkinUtils.confidenceToScore(current_checkin.confidence, t);
  } else if (current_checkin) {
    // if has current checkin and uses metric, get score
    score = outcomeUtils.nFormatter(current_checkin.score, 2);
    if (outcome_type === 'kpi') {
    }
    showFormat = true;
  } else if (!current_checkin && outcome_type !== 'no_metric') {
    // if no checkin yet and has metric, get starting value
    score = outcomeUtils.nFormatter(outcome.from || 0, 2);
    showFormat = true;
  }
  const confidenceBackground = checkinUtils.confidenceToBackgroundColor(currentConfidence);
  const confidenceProgress = checkinUtils.confidenceToProgressColor(currentConfidence);

  const confidenceText = checkinUtils.confidenceToValueColor(currentConfidence);

  const isLongPrefix = format[0].length > 3;
  const isLongSufix = format[1].length > 2;

  return (
    <ScoreContainer backgroundColor={confidenceBackground}>
      <ProgressBackground progress={progressPrct} color={confidenceProgress} />
      <ContentContainer valueColor={confidenceText}>
        <MetricContainer valueColor={confidenceText}>
          {showFormat && isLongPrefix && <small>{format[0]}</small>}
          <big>
            {showFormat && !isLongPrefix && <big>{format[0]}</big>}
            {score}
            {showFormat && !isLongSufix && <big>{format[1]}</big>}
          </big>
          {showFormat && isLongSufix && <small>{format[1]}</small>}
        </MetricContainer>
        <DetailsContainer>
          <KoalaAvatar user={membership ? membership.user : null} size={2} hideTooltip />
          <CurrentGrowth outcome={outcome} />
        </DetailsContainer>
      </ContentContainer>
    </ScoreContainer>
  );
}

export default SummaryCard;
