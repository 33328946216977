import React from 'react';
import styled from 'styled-components';
// import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import { KoalaAppearances } from 'koala/shared/global';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const StyledReadOnlyButton = styled.div`
  display: flex;
`;

interface Props {
  label: string;
  appearance?: (typeof KoalaAppearances)[number];
}

function ReadOnlyButton(props: Props) {
  const { label, appearance } = props;
  const { t } = useTranslation();
  return (
    <StyledReadOnlyButton>
      <KoalaButton appearance={appearance} disabled={true} dataFor={label}>
        {label}
      </KoalaButton>
      <ReactTooltip place="bottom" type="dark" id={label} className="tooltip" effect="solid" delayShow={200}>
        {t('role.readonlyRestricted')}
      </ReactTooltip>
    </StyledReadOnlyButton>
  );
}

export default ReadOnlyButton;
