import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import ChartMogulIcon from '../_assets/IconChartMogul.png';
import KoalaLoader from 'koala/components/Loader';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
}

function ChartMogulIntegration(props: Props) {
  const { workspace, integrations } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallChartMogulMutation, { isLoading: isUninstallingChartMogul }] = useMutation(
    remoteApi.uninstallChartMogul,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
      },
    },
  );

  const chartMogulIntegration = integrations.filter((i: Integration) => i.integration_type === 'chartmogul')[0];
  const uninstallChartMogulLabel = isUninstallingChartMogul ? (
    <KoalaLoader />
  ) : (
    t('workspaceSettingsIntegrations.remove')
  );

  const handleUninstallChartMogul = (e: React.MouseEvent) => {
    e.preventDefault();
    uninstallChartMogulMutation(workspace.slug);
  };
  const handleInstallChartMogul = (e: React.MouseEvent) => {
    dispatch(setGlobalModalContent('workspace::connect.chartmogul'));
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={ChartMogulIcon} alt="ChartMogul" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>
          {t('workspaceSettingsIntegrations.dataConnectorHeader', {
            integration: 'ChartMogul',
          })}
        </h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'ChartMogul' })}</p>
        <p>
          <a
            href="https://guides.tability.app/docs/integrations/data-sources"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {chartMogulIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: chartMogulIntegration.connected_to }}
              />
            </div>
            <KoalaTextButton size="small" onClick={handleUninstallChartMogul}>
              {uninstallChartMogulLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!chartMogulIntegration && (
          <KoalaButton onClick={handleInstallChartMogul}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'ChartMogul' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default ChartMogulIntegration;
