import React, { useEffect, useState } from 'react';
import * as textUtils from 'utils/textUtils';

import MarkdownContent from 'components/MarkdownContent';
import Reactions from 'components/Reactions';
import { CachedReaction } from 'types';
import { useMutation } from 'react-query';
import * as remoteApi from 'api/remote';

import { ContentAction, ContentComment } from './NewsItem';
import { Trans } from 'react-i18next';

interface Props {
  event: any;
}

function Block(props: Props) {
  const { event } = props;

  const { membership, source } = event;

  const [reactions, setReactions] = useState<CachedReaction[]>([]);
  useEffect(() => {
    if (source.cached_reactions && typeof source.cached_reactions !== 'string') {
      setReactions(source.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [source]);
  const [addReactionMutation] = useMutation(remoteApi.createReviewReplyReaction, {
    onSuccess: (response) => {
      setReactions(response.data);
    },
  });
  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: (response) => {
      setReactions(response.data);
    },
  });

  if (!membership) {
    return null;
  }

  const { user } = membership;
  const name = user.fullname || user.email;
  const hasBody = !textUtils.isEmptyHTMLText(source.body);

  return (
    <>
      <ContentAction>
        <Trans i18nKey="newsfeed.reviewReplyCreated" values={{ name }} components={{ b: <b /> }} />
      </ContentAction>
      {hasBody && (
        <ContentComment>
          <MarkdownContent source={source.body} />
        </ContentComment>
      )}

      <Reactions
        reactions={reactions}
        addCallback={(emotion) => addReactionMutation({ reviewReplyId: source.id, emotion })}
        removeCallback={(reactionId) => deleteReactionMutation(reactionId)}
      />
    </>
  );
}

export default React.memo(Block);
