/**
 * Objective component
 */
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
//import * as TabilityTypes from '../../../types';
import styled from 'styled-components';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import theme from 'theme';

// API
import * as remoteApi from 'api/remote';

// Actions
import {
  editorSetDisplayNewBlockForm,
  editorSelectBlock,
  editorDeleteObjective,
} from 'state/actions/workspaceEditorActions';

// UI Components
import BlockGrid, { BlockGutter, BlockContent, BlockMeta } from './BlockGrid';
import BlockLabel from './BlockLabel';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import DropdownMenu from '../shared/DropdownMenu';
import ObjectiveOrOutcomeForm from './ObjectiveOrOutcomeForm';
import ObjectiveTitle from './ObjectiveTitle';
import ObjectiveBlockMeta from './ObjectiveBlockMeta';
import OutcomeBlock from './OutcomeBlock';

import Loader from 'components/Loader';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';

interface Props {
  objectiveId: string;
  objectiveIndex: number;
}

const BlockWrapper = styled.div<{ isDragging: boolean }>`
  background-color: #fff;
  border-radius: 4px;
  font-size: 1.8rem;

  border: ${(props) => (props.isDragging ? '2px solid rgba(164, 220, 229, 1)' : '0px solid #fff')};
  position: relative;
`;

const InnerWrapper = styled.div`
  .add-menu {
    opacity: 0;
    transition: opacity ease 0.2s;
  }

  &:hover {
    .add-menu {
      opacity: 1;
    }
  }
`;

function ObjectiveBlock(props: Props) {
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const history = useHistory();
  const { objectiveId, objectiveIndex } = props;
  const blockId = `objective:${objectiveId}`;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();

  const [archiveObjectiveMutation, { isLoading: isArchiving }]: [any, any] = useMutation(remoteApi.archiveObjective, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.objectives);
    },
  });

  const archiveObjective = () => {
    const params = {
      archived: true,
    };
    const mutationParams = {
      objectiveId,
      objective: params,
    };
    archiveObjectiveMutation(mutationParams);
  };

  // This state param will be use to decide which form we should be displaying (objective or outcome)
  const [defaultFormEntityType, setDefaultFormEntityType] = useState('objective');

  // Check if we can find an objective
  const hasObjective = useSelector((state: any) => {
    return state.editorEntities.objectives[objectiveId];
  });
  const insightErrors = useSelector((state: any) => {
    if (!state.editorUI.scorecard) {
      return null;
    }

    return state.editorUI.scorecard.insights.blockInsights[blockId];
  });

  const isSelected = useSelector((state: any) => state.editorUI.selectedBlockId === blockId);

  // Block form if (1) the block is selected AND (2) the displayBlockForm flag is true
  const displayForm = useSelector((state: any) => {
    return isSelected && state.editorUI.displayNewBlockForm;
  });

  const outcomeIds = useSelector((state: any) => state.editorEntities.objectivesToOutcomesMapping[objectiveId] || []);

  // Extract the review comments
  const reviewCommentsCount = useSelector((state: any) => {
    if (state.editorEntities.reviewCommentsByBlockId[blockId]) {
      const reviewComments = state.editorEntities.reviewCommentsByBlockId[blockId];
      let count = 0;
      reviewComments.forEach((reviewComment: any) => {
        count = count + 1 + reviewComment.review_replies_count; // count the initial comment and all replies;
      });
      return count;
    }
    return 0;
  });

  if (!hasObjective) {
    return null;
  }

  const selectedClass = isSelected ? 'selected' : '';

  const handleClick = (e: React.MouseEvent) => {
    dispatch(editorSelectBlock(blockId));
  };

  const handleCreateComment = () => {
    const hashPath = `#editor:${blockId}:comment`;
    history.push(hashPath);
  };

  const handleEdit = () => {
    const hashPath = `#${blockId}:edit`;
    history.push(hashPath);
  };

  const handleDelete = () => {
    const deletePlaceholder =
      t('workspacePlan.write.deleteConfirm', {
        label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
      }) ?? 'Are you sure that you want to delete this objective?';
    if (window.confirm(deletePlaceholder)) {
      dispatch(editorDeleteObjective(objectiveId));
    }
  };

  const handleArchive = () => {
    const archivePlaceholder =
      t('workspacePlan.write.archiveConfirm', {
        label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
      }) ?? 'Are you sure that you want to archive this objective?';
    if (window.confirm(archivePlaceholder)) {
      archiveObjective();
    }
  };

  const handlePlusSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'show-objective-form':
        setDefaultFormEntityType('objective');
        dispatch(editorSelectBlock(blockId));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
      case 'show-outcome-form':
        setDefaultFormEntityType('outcome');
        dispatch(editorSelectBlock(blockId));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
    }
  };

  const handleDragSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'edit':
        handleEdit();
        break;
      case 'comment':
        handleCreateComment();
        break;
      case 'archive':
        handleArchive();
        break;
      case 'delete':
        handleDelete();
        break;
    }
  };

  const commentedClass = reviewCommentsCount > 0 ? 'commented' : '';
  const joyrideSelector = `objective-${objectiveIndex}`;

  const plusMenuItems = [
    <span data-action="show-objective-form">
      {t('workspacePlan.write.addObjective', {
        label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
      })}
    </span>,
    <span data-action="show-outcome-form">
      {t('workspacePlan.write.addObjective', {
        label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase(),
      })}
    </span>,
  ];

  const dragMenuItems = [
    <span data-action="edit">{t('shared.edit')}</span>,
    <span data-action="comment">{t('workspacePlan.write.addComment')}</span>,
    <span data-action="archive">{t('shared.archive')}</span>,
    <span data-action="delete">{t('shared.delete')}</span>,
  ];

  const objectiveIdClass = `objective:${objectiveId}`;

  return (
    <Draggable draggableId={blockId} index={props.objectiveIndex} key={blockId}>
      {(provided, snapshot) => {
        return (
          <BlockWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            className={`${joyrideSelector}-drag`}
          >
            <InnerWrapper>
              <BlockGrid
                onClick={handleClick}
                className={`editor-block ${objectiveIdClass} ${selectedClass} ${insightErrors} ${commentedClass} ${joyrideSelector}`}
              >
                <BlockGutter>
                  {isArchiving && <Loader size="small" />}
                  <DropdownMenu
                    trigger={
                      <KoalaIconButton
                        iconName="plus"
                        edge="square"
                        size="small"
                        className="icon-button"
                        iconColor={theme.colors.N40}
                        dataFor={`${blockId}-plus-menu`}
                        onClick={handleClick}
                      />
                    }
                    onSelection={handlePlusSelection}
                    items={plusMenuItems}
                  />
                  <DropdownMenu
                    trigger={
                      <KoalaIconButton
                        iconName="grab"
                        edge="square"
                        size="small"
                        className="icon-button"
                        dataFor={`${blockId}-drag-menu`}
                        onClick={handleClick}
                      />
                    }
                    onSelection={handleDragSelection}
                    items={dragMenuItems}
                  />
                  <ReactTooltip
                    place="bottom"
                    type="dark"
                    id={`${blockId}-plus-menu`}
                    className="tooltip"
                    effect="solid"
                    delayShow={200}
                  >
                    {t('workspacePlan.write.addTooltip')}
                  </ReactTooltip>
                  <ReactTooltip
                    place="bottom"
                    type="dark"
                    id={`${blockId}-drag-menu`}
                    className="tooltip"
                    effect="solid"
                    delayShow={200}
                    html={true}
                  >
                    {t('workspacePlan.write.dragTooltip')}
                  </ReactTooltip>
                  <BlockLabel className="objective">
                    {translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}
                  </BlockLabel>
                </BlockGutter>
                <BlockContent className="objective title">
                  <ObjectiveTitle objectiveId={objectiveId} />
                </BlockContent>
                <BlockMeta>
                  <ObjectiveBlockMeta objectiveId={objectiveId} reviewCommentsCount={reviewCommentsCount} />
                </BlockMeta>
              </BlockGrid>
            </InnerWrapper>

            <Droppable droppableId={`objective:${objectiveId}`} type="OUTCOMES">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    padding: '0.1rem 0',
                    backgroundColor: snapshot.isDraggingOver ? 'rgba(245, 192, 43, 0.4)' : '#fff',
                  }}
                >
                  {outcomeIds.map((outcomeId: string, index: number) => {
                    return (
                      <OutcomeBlock
                        outcomeId={outcomeId}
                        objectiveIndex={objectiveIndex}
                        outcomeIndex={index}
                        key={`outcome:${outcomeId}`}
                        objectiveId={objectiveId}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {displayForm && (
              <ObjectiveOrOutcomeForm defaultFormEntityType={defaultFormEntityType} selectedObjectiveId={objectiveId} />
            )}
          </BlockWrapper>
        );
      }}
    </Draggable>
  );
}

export default React.memo(ObjectiveBlock);
