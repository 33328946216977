import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

// Components
import Enable2FA from './Enable2FA';
import Disable2FA from './Disable2FA';

function Account2FA() {
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);

  if (currentUser.otp_required_for_login) {
    return <Disable2FA />;
  } else {
    return <Enable2FA />;
  }
}

export default Account2FA;
