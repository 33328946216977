/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';

// API
import { fetchPlans } from 'api/remote';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
// Components
import ChevronRightIcon from 'components/_assets/ChevronRightIcon';
import EmptyStatePanel from 'components/EmptyStatePanel';
import PlanOverview from 'components/PlanOverview';
import Loader from 'components/Loader';
import { Plan } from 'types';
import KoalaIcon from 'koala/components/Icons';
import { useTranslation } from 'react-i18next';
import { PlanSection, PlanSectionContent, PlanSectionHeader } from '.';

const ExpandButton = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  font-weight: 700;

  &:hover {
    cursor: pointer;
  }

  .icon {
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 1rem;
    }

    transition: all ease 0.2s;
  }
  &.expanded {
    .icon {
      transform: rotate(90deg);
    }
  }
`;

const LoaderContainer = styled.div`
  padding: ${theme.spacing.x1} 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: auto;
  gap: ${theme.spacing.x2};
`;

const CreateSubPlanCard = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  padding: ${theme.spacing.x1};
  font-weight: 500;
  overflow: hidden;

  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  color: ${theme.colors.subtleText};

  &:hover {
    background: ${theme.colors.N5};
    cursor: pointer;
  }
  &:focus,
  &:active {
    background: ${theme.colors.B5};
  }
`;

interface Props {
  plan: Plan;
}

function Plans(props: Props) {
  const dispatch = useDispatch();
  const { plan } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [childrenPlans, setChildrenPlans] = useState<Plan[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  const filter = {
    children_of: plan.id,
  };
  const params = {
    filter,
    order_attribute: 'title',
    order_direction: 'asc',
  };

  const { isLoading, isError } = useQuery([queryKeys.plans, workspaceSlug, params, 'nav'], fetchPlans, {
    onSuccess: (response) => setChildrenPlans(response.data),
  });

  //
  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader size="medium" />
      </LoaderContainer>
    );
  }

  if (isError) {
    return (
      <EmptyStatePanel>
        <p>
          <b>{t('workspacePlan.subPlan.error')}</b>
        </p>
        <p>{t('workspacePlan.subPlan.errorInfo')}</p>
      </EmptyStatePanel>
    );
  }

  const handleCreateSubPlan = (parent_id: string) => {
    dispatch(setGlobalModalContent(`plan:${parent_id}:create.plan`));
  };

  const headerClass = isExpanded ? 'expanded' : 'collapsed';

  return (
    <PlanSection>
      <PlanSectionHeader className={headerClass}>
        <ExpandButton className={`${isExpanded ? 'expanded' : 'collapsed'}`} onClick={() => setIsExpanded(!isExpanded)}>
          <div className="icon">
            <ChevronRightIcon />
          </div>
          <span className="title">{t('workspacePlan.subPlan.title')}</span>
        </ExpandButton>
      </PlanSectionHeader>
      {isExpanded && (
        <PlanSectionContent>
          <Grid>
            {childrenPlans.map((p: any) => {
              return (
                <Fragment key={p.id}>
                  <div>
                    <PlanOverview plan={p} />
                  </div>
                </Fragment>
              );
            })}
            <div>
              <CreateSubPlanCard onClick={() => handleCreateSubPlan(plan.id)}>
                <KoalaIcon iconName="plus" iconSize="small" />
                <span>{t('workspacePlan.subPlan.add')}</span>
              </CreateSubPlanCard>
            </div>
          </Grid>
        </PlanSectionContent>
      )}
    </PlanSection>
  );
}

export default Plans;
