// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { getTime, isBefore, isAfter, parseISO } from 'date-fns';
import * as planUtils from 'utils/planUtils';
import EmptyStatePanel from 'components/EmptyStatePanel';
import Chart from 'components/CheckinChart';
import { VictoryChart, VictoryArea, VictoryStack, VictoryVoronoiContainer, VictoryGroup } from 'victory';
import { useTranslation } from 'react-i18next';
// import { debounce } from 'lodash';
// import useResizeObserver from 'utils/resizeObserver';

const Container = styled.div`
  height: 25rem;
  display: flex;
  margin-bottom: ${theme.spacing.x1};
  width: 100%;

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

interface Props {
  trends: any[];
  plan: TabilityTypes.Plan;
}

interface ChartProps {
  greyOutcomesData: any;
  redOutcomesData: any;
  greenOutcomesData: any;
  yellowOutcomesData: any;
  xDomain: any;
  yDomain: TabilityTypes.Domain;
  isInsideTimeline: boolean;
  width: number;
}
function RenderChart(props: ChartProps) {
  const {
    width,
    greyOutcomesData,
    redOutcomesData,
    yellowOutcomesData,
    greenOutcomesData,
    yDomain,
    xDomain,
    isInsideTimeline,
  } = props;
  const { i18n, t } = useTranslation();

  return (
    <VictoryChart
      width={width}
      height={250}
      domainPadding={{ x: 15 }}
      padding={{ top: 40, bottom: 30, left: 50, right: 20 }}
      minDomain={{ y: 0 }}
      domain={{ x: xDomain }}
      containerComponent={
        <VictoryVoronoiContainer labels={({ datum }) => (datum.y === 0 ? null : datum.displayText)} />
      }
    >
      {Chart.XAxis(i18n)}
      {Chart.YAxis()}
      <VictoryGroup scale="time">
        <VictoryStack>
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.N20,
                fillOpacity: 0.7,
                strokeLinecap: 'round',
                stroke: 'none',
              },
            }}
            data={greyOutcomesData}
            interpolation="monotoneX"
          />
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.R40,
                fillOpacity: 0.7,
                strokeLinecap: 'round',
                stroke: 'none',
              },
            }}
            data={redOutcomesData}
            interpolation="monotoneX"
          />
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.Y40,
                fillOpacity: 0.7,
                stroke: 'none',
              },
            }}
            data={yellowOutcomesData}
            interpolation="monotoneX"
          />
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.G40,
                fillOpacity: 0.7,
                stroke: 'none',
              },
            }}
            data={greenOutcomesData}
            interpolation="monotoneX"
          />
        </VictoryStack>
      </VictoryGroup>
      {isInsideTimeline && Chart.TodayLine(yDomain, t)}
    </VictoryChart>
  );
}

function CheckinsChart(props: Props) {
  const { trends, plan } = props;
  let yDomain: TabilityTypes.Domain = { min: 0, max: 0 };

  // resize the chart to fit the container
  const chartRef: any = useRef(null);

  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = (ev: Event) => {
    if (chartRef && chartRef.current) {
      setWidth(chartRef.current.offsetWidth);
    }
  };

  // useEffect replaces `componentDidMount` and others
  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    // Removes listener on unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      setWidth(chartRef.current.offsetWidth);
    }
  }, [chartRef]);

  // If the plan doesn't have a start and end date, display a warning
  if (!planUtils.hasTimeline(plan)) {
    return (
      <EmptyStatePanel>
        <p>There's no confidence data to display yet.</p>
      </EmptyStatePanel>
    );
  }

  const greyOutcomesData: any[] = [];
  const redOutcomesData: any[] = [];
  const greenOutcomesData: any[] = [];
  const yellowOutcomesData: any[] = [];

  const startDate: Date = parseISO(plan.start_at);
  const finishDate: Date = parseISO(plan.finish_at);
  let chartFinishDate = finishDate;

  const firstPoint = {
    x: startDate,
    y: 0,
    timestamp: getTime(startDate),
  };

  const firstGreyPoint = {
    x: startDate,
    y: plan.total_outcomes_count,
    timestamp: getTime(startDate),
    displayText: `${plan.total_outcomes_count} pending`,
  };

  // By default we're going to use the first point from the target
  // We'll change that if we find a checkin prior to the first point.
  let useFirstPoint = true;

  trends.forEach((trend: any, index: number) => {
    const date = parseISO(trend.reference_date);
    if (isAfter(date, chartFinishDate)) {
      chartFinishDate = date;
    }
    // If the checkin was done prior to the first point we don't store
    // the first point in the list of current points
    if (isBefore(date, firstPoint.x)) {
      useFirstPoint = false;
    }

    // Create the point using the checkin data
    const greyPoint: any = {
      x: date,
      y: trend.grey_outcomes_count,
      timestamp: getTime(date),
      displayText: `${trend.grey_outcomes_count} pending`,
      trend,
    };
    greyOutcomesData.push(greyPoint);
    // Create the point using the checkin data

    const redPoint: any = {
      x: date,
      y: trend.red_outcomes_count,
      timestamp: getTime(date),
      displayText: `${trend.red_outcomes_count} off track`,
      trend,
    };
    redOutcomesData.push(redPoint);

    const yellowPoint: any = {
      x: date,
      y: trend.yellow_outcomes_count,
      displayText: `${trend.yellow_outcomes_count} at risk`,
      timestamp: getTime(date),
      trend,
    };
    yellowOutcomesData.push(yellowPoint);

    const greenPoint: any = {
      x: date,
      y: trend.green_outcomes_count,
      displayText: `${trend.green_outcomes_count} on track`,
      timestamp: getTime(date),
      trend,
    };
    greenOutcomesData.push(greenPoint);

    // Update domain
    yDomain.min = 0;
    yDomain.max = Math.max(trend.total_outcomes_count, yDomain.max);
  });

  if (yDomain.max === 0) {
    yDomain.max = 10;
  }

  if (useFirstPoint) {
    greyOutcomesData.push(firstGreyPoint);
    redOutcomesData.push(firstPoint);
    yellowOutcomesData.push(firstPoint);
    greenOutcomesData.push(firstPoint);
  }

  const xDomain = [startDate, chartFinishDate];
  const isInsideTimeline = planUtils.isInPlanTimeline(startDate, finishDate, new Date());

  // Now we can display the app with the Chrome
  return (
    <Container className="panel-checkins-chart" ref={chartRef}>
      <RenderChart
        width={width}
        greyOutcomesData={greyOutcomesData}
        redOutcomesData={redOutcomesData}
        yellowOutcomesData={yellowOutcomesData}
        greenOutcomesData={greenOutcomesData}
        xDomain={xDomain}
        yDomain={yDomain}
        isInsideTimeline={isInsideTimeline}
      />
    </Container>
  );
}

export default CheckinsChart;
