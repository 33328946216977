import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

// Components

import AsyncCreatableSelect from 'react-select/async-creatable';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaButton from 'koala/components/Button';

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: ${theme.spacing.x1};
  align-items: center;
  .tag-select {
    flex: 1;
    min-width: 20rem;
  }

  .tag-select-control {
    height: 30px;

    .tag-select-placeholder {
      line-height: 26px;
    }

    .tag-select-value {
      line-height: 26px !important;

      .tag-select-value-label {
        line-height: 26px;
      }
    }

    .tag-select-input {
      height: 26px;
    }
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
  setDisplayPicker: (displayPicker: boolean) => void;
}

function TagPicker(props: Props) {
  const queryCache = useQueryCache();

  const { plan, setDisplayPicker } = props;

  const [tag_list, setTagList] = useState(plan.cached_tag_list);
  const tagList = plan.cached_tag_list || '';
  const tagListArray = tagList.length > 0 ? tagList.split(',') : [];
  const currentTagsForSelect = tagListArray.map((tag: string) => {
    return {
      label: tag.trim(),
      value: tag.trim(),
    };
  });

  // Functions to update the plan
  const [updatePlanMutation]: [any, any] = useMutation(remoteApi.updatePlan, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.plans);
      queryCache.invalidateQueries(queryKeys.currentPlan);
      setDisplayPicker(false);
    },
  });

  const handleSave = (e: any) => {
    e.preventDefault();
    const params = {
      tag_list,
    };

    updatePlanMutation({
      planId: plan.id,
      plan: params,
    });
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    setDisplayPicker(false);
  };

  const handleLoadTags = (input: string) => {
    let tagParams;
    if (input) {
      tagParams = {
        search: input,
      };
    } else {
      tagParams = {};
    }
    return remoteApi.fetchTags(queryKeys.tags, plan.workspace_id, tagParams, 1).then((response) => {
      const tags = response.data;
      const options = tags.map((tag: any) => ({
        label: tag.name,
        value: tag.name,
      }));
      return options;
    });
  };

  const handleSelectChange = (newValue: any) => {
    const tagList = newValue.map((option: any) => option.value).join(',');
    setTagList(tagList);
  };

  const customStyles = {
    control: (base: any) => ({
      ...base,
      minHeight: 30,
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base: any) => ({
      ...base,
      padding: 4,
    }),
    multiValue: (base: any) => ({
      ...base,
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: '0px 6px',
    }),
    input: (base: any) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
  };

  return (
    <Container>
      <AsyncCreatableSelect
        className="tag-select"
        styles={customStyles}
        isClearable
        placeholder="Select or type..."
        isMulti
        defaultOptions
        allowCreateWhileLoading={false}
        loadOptions={handleLoadTags}
        onChange={handleSelectChange}
        defaultValue={currentTagsForSelect}
      />
      <KoalaButton size="small" onClick={handleSave}>
        Save
      </KoalaButton>
      <KoalaTextButton onClick={handleCancel}>Cancel</KoalaTextButton>
    </Container>
  );
}

export default React.memo(TagPicker);
