import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import CopyTextInput from 'components/CopyTextInput';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Outcome, Plan } from 'types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
`;

const RestrictedAccess = styled.div`
  margin-bottom: ${theme.spacing.x2};
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
`;
interface Props {
  outcome: Outcome;
  plan: Plan;
}

function DeletePlan(props: Props) {
  const { outcome, plan } = props;
  const { t } = useTranslation();
  const translationKey = 'modals.share';
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const embedUrl = `https://${process.env.REACT_APP_DOMAIN}/${workspaceSlug}/embed/outcome/${outcome.nano_slug}`;
  const iframeCode = `<iframe width="800" height="600" src="${embedUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;

  if (!plan.public_access_enabled) {
    return (
      <RestrictedAccess>
        <h3>{t(`${translationKey}.embed`)}</h3>
        {t(`${translationKey}.publicAccessRequired`)}
      </RestrictedAccess>
    );
  }

  return (
    <Container>
      <h3>{t(`${translationKey}.embed`)}</h3>
      <InputSection>
        <label>{t(`${translationKey}.iframeCode`)}</label>
        <p>
          {t(`${translationKey}.iframeDescription`, {
            label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase(),
          })}
        </p>
        <CopyTextInput text={iframeCode} />
      </InputSection>
      <InputSection>
        <label>{t(`${translationKey}.embed`)}</label>
        <p>{t(`${translationKey}.embedDescription`)}</p>
        <CopyTextInput text={embedUrl} />
        <small className="subtle">{t(`${translationKey}.embedSizeOutcome`)}</small>
      </InputSection>
    </Container>
  );
}

export default DeletePlan;
