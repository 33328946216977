import styled from 'styled-components';
import theme from 'theme';

export default styled.div<{ background?: string }>`
  text-align: center;
  color: ${theme.colors.subtleText};
  padding: ${theme.spacing.x4};
  margin: ${theme.spacing.x2} 0;
  background: ${({ background }) => background ?? 'transparent'};

  a {
    text-decoration: underline;
  }
`;
