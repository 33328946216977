import { ChromeContent } from 'components/Chrome';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';
import WorkspaceHeader from 'components/WorkspaceHeader';
import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import { Dashboard, DashboardWidget, Workspace } from 'types';
import queryKeys from 'config/queryKeys';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import { AxiosResponse } from 'axios';
import * as dashboardUtils from 'utils/dashboardUtils';
import DashboardSection from './DashboardSection';
import KoalaButton from 'koala/components/Button';
import EmptyStatePanel from 'components/EmptyStatePanel';
import { hasPremiumSubscription, hasTrial } from 'utils/workspaceUtils';
import UpgradeRequiredV4 from 'pages/WorkspaceDashboards/UpgradeRequiredV4';
import KoalaInlineTextInput from 'koala/components/InlineTextInput';
import KoalaTextButton from 'koala/components/TextButton';
import * as routes from 'routes';
import WidgetAddPanel from './WidgetPanel/WidgetAddPanel';
import WidgetEditPanel from './WidgetPanel/WidgetEditPanel';

const Mask = styled.div`
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
`;

const UpgradeModal = styled.div`
  position: absolute;
  left: 50%;
  z-index: 80;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  max-height: 25rem;
  height: 25rem;
  top: 50%;
  width: 60rem;
  max-width: 60rem;
  margin-left: -40rem;
  margin-top: -20rem;
  background: #fff;

  @media ${theme.devices.mobile} {
    width: 100%;
    margin: 20rem 0 0 0;
    margin-top: -20rem;
    left: unset;
    overflow: auto;
    height: 30rem;
    max-height: 30rem;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  .AriaMenuButton-menu {
    left: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px ${theme.spacing.x1};
`;

const ActionBlock = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  height: 32px;
`;

const Wrapper = styled.div`
  margin: 0 auto ${theme.spacing.x4} auto;
  max-width: 150rem;
  padding: 1.2rem ${theme.spacing.x2};
  display: flex;
  flex-direction: column;

  h3 {
    padding: ${theme.spacing.x2} 0;
  }

  .outcome-header {
    display: flex;
    flex-wrap: wrap;
  }
`;

const EndBlock = styled.div`
  padding: ${theme.spacing.x14} 0px;
`;

interface Props {
  workspace: Workspace;
}

interface Layout {
  w: number;
  h: number;
  x: number;
  y: number;
  i: string;
}

function WorkspaceDashboardEdit(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const history = useHistory();
  const [showPanelType, setShowPanelType] = useState('');
  const [dashboard, setDashboard] = useState<Dashboard>();
  const [layout, setLayout] = useState<Layout[]>([]);
  const [dashboardTitle, setDashboardTitle] = useState('');
  const [widget, setWidget] = useState<DashboardWidget>();
  const [resetTimer, setResetTimer] = useState<number>(0);
  const [dashboardWidgets, setDashboardWidgets] = useState<DashboardWidget[]>();
  const { dashboardId } = useParams<{
    dashboardId: string;
  }>();

  const dashboardEndRef = React.useRef<HTMLDivElement>(null);
  // Get the dashboard details
  const queryKey = [queryKeys.currentDashboard, dashboardId];
  const staleTime = 0;
  const { isLoading: dashboardIsLoading } = useQuery(queryKey, remoteApi.fetchDashboardDetails, {
    staleTime,
    onSuccess: (response: AxiosResponse<Dashboard>) => {
      const dashboard = response.data;
      setLayout(JSON.parse(dashboard.grid_layout));
      setDashboard(dashboard);
      setDashboardTitle(dashboard.title);
    },
  });

  const [updateDashboardMutation, { isLoading: dashboardIsUpdating }] = useMutation(remoteApi.updateDashboard, {
    onSuccess: () => {
      history.push(
        routes.WORKSPACE_DASHBOARD_ROUTE.replace(':workspaceSlug', workspace.slug).replace(':dashboardId', dashboardId),
      );
    },
  });

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const dashboardParams = {
      grid_layout: JSON.stringify(layout),
    };
    const mutationParams = {
      dashboardId,
      dashboard: dashboardParams,
    };
    updateDashboardMutation(mutationParams);
  };

  // get the widget details
  const dashboardWidgetsQueryKey = [queryKeys.dashboardWidgets, dashboardId];
  const { isLoading: dashboardWidgetIsLoading } = useQuery(dashboardWidgetsQueryKey, remoteApi.fetchDashboardWidgets, {
    staleTime,
    onSuccess: (response: AxiosResponse<DashboardWidget[]>) => {
      const data = response.data;
      setDashboardWidgets(data);
    },
  });

  // add widget to dashboard
  const [createDashboardWidget, { isLoading: isAddingWidget }] = useMutation(remoteApi.createDashboardWidget, {
    onSuccess: (response: AxiosResponse<DashboardWidget>) => {
      queryCache.invalidateQueries([queryKeys.dashboardWidgets, dashboardId]);
      const data = response.data;

      const defaultDimension = dashboardUtils.getDefaultWidgetDimensions(data.widget_type);
      const newWidget = {
        ...defaultDimension,
        x: 0,
        y: Infinity,
        i: data.id,
      };
      const updatedLayout = [...layout, newWidget];
      setLayout(updatedLayout);
      setResetTimer(Date.now());
    },
  });

  const [updateDashboard, { isLoading: dashboardUpdating }] = useMutation(remoteApi.updateDashboard, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.dashboards, workspace.slug]);
      queryCache.invalidateQueries([queryKeys.currentDashboard, dashboardId]);
    },
  });

  const isLoading = dashboardIsLoading || dashboardWidgetIsLoading;

  const handleAddWidget = (dashboardParams: {
    widget_type: string;
    source_id?: string;
    source_type: string;
    title?: string;
    widget_meta?: any;
  }) => {
    const params = {
      dashboardId,
      dashboard_widget: dashboardParams,
    };
    createDashboardWidget(params).then(() => {
      dashboardEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  };

  // Set upgrade required to true
  let upgradeRequired = true;

  // Only Premium workspaces should have access to insights
  if (hasPremiumSubscription(workspace) || hasTrial(workspace)) {
    upgradeRequired = false;
  }

  const updateDashboardTitle = (newTitle: string) => {
    const dashboard = {
      title: newTitle,
    };
    const params = {
      dashboardId,
      dashboard,
    };
    updateDashboard(params);
  };

  const handleGoBack = () => {
    history.push(
      routes.WORKSPACE_DASHBOARD_ROUTE.replace(':workspaceSlug', workspace.slug).replace(':dashboardId', dashboardId),
    );
  };

  const handleEditWidget = (widget: DashboardWidget) => {
    setShowPanelType('edit');
    setWidget(widget);
  };

  if (!dashboard) {
    return (
      <Fragment>
        <MobileReadyChromeHeader>
          <WorkspaceHeader workspace={workspace} useGreyBackground={true} />
        </MobileReadyChromeHeader>
        <ChromeContent isGreyBackground>
          <Wrapper>
            <KoalaLoader size="large" />
          </Wrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  const handleClosePanel = () => {
    setWidget(undefined);
    setShowPanelType('');
  };

  return (
    <Fragment>
      <WidgetAddPanel
        handleClosePanel={handleClosePanel}
        showPanel={showPanelType === 'add'}
        addWidgetCallback={handleAddWidget}
        isAddingWidget={isAddingWidget}
        resetTimer={resetTimer}
      />
      {widget && (
        <WidgetEditPanel
          handleClosePanel={handleClosePanel}
          showPanel={showPanelType === 'edit'}
          widget={widget}
          dashboardId={dashboardId}
          resetTimer={resetTimer}
          setResetTimer={setResetTimer}
        />
      )}

      <MobileReadyChromeHeader>
        <WorkspaceHeader
          workspace={workspace}
          useGreyBackground={true}
          title={
            <HeaderTitle>
              <KoalaInlineTextInput
                handleChangeText={updateDashboardTitle}
                placeholder={t('workspaceDashboards.titlePlaceholder') ?? 'Enter dashboard title'}
                text={dashboardTitle}
                canEdit={true}
                size="large"
                hideOverflow={true}
                isUpdating={dashboardUpdating}
              />
            </HeaderTitle>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeContent className="grey-bg">
        {upgradeRequired && (
          <Mask>
            <UpgradeModal>{<UpgradeRequiredV4 />}</UpgradeModal>
          </Mask>
        )}
        <Helmet>
          <title>
            {workspace.name} | {t('workspaceDashboards.title')} | Tability
          </title>
        </Helmet>
        <Wrapper>
          {isLoading && <KoalaLoader size="large" />}
          {!isLoading && (
            <>
              <Actions>
                <ActionBlock>
                  <KoalaButton appearance="subtle" onClick={() => setShowPanelType('add')}>
                    {t('workspaceDashboards.addWidget')}
                  </KoalaButton>
                  {dashboardIsUpdating && <KoalaLoader />}
                </ActionBlock>
                <ActionBlock>
                  <KoalaTextButton onClick={handleGoBack}>{t('shared.cancel')}</KoalaTextButton>
                  <KoalaButton onClick={handleSave}>{t('shared.save')}</KoalaButton>
                </ActionBlock>
              </Actions>

              {dashboardWidgets && (
                <DashboardSection
                  layout={layout}
                  widgets={dashboardWidgets}
                  setLayout={setLayout}
                  handleEditWidget={handleEditWidget}
                />
              )}
              {(!dashboardWidgets || dashboardWidgets.length === 0) && (
                <EmptyStatePanel>
                  <p>{t('workspaceDashboards.noWidgets')}</p>
                  <p>
                    <KoalaButton appearance="subtle" onClick={() => setShowPanelType('add')}>
                      {t('workspaceDashboards.addWidget')}
                    </KoalaButton>
                  </p>
                </EmptyStatePanel>
              )}
              <EndBlock ref={dashboardEndRef} />
            </>
          )}
        </Wrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceDashboardEdit;
