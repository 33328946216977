import KoalaAvatar from 'koala/components/Avatar';
import KoalaTextButton from 'koala/components/TextButton';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Membership, User, Workspace } from 'types';
import MembershipInsights from './MembershipInsights';
import ReportToSelection from './ReportToSelection';
import * as workspaceUtils from 'utils/workspaceUtils';
import TeamList from 'pages/WorkspaceProfile/TeamList';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';

const UserBlock = styled.div`
  border-bottom: 1px solid ${theme.colors.blockBorder};
  padding-bottom: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .insights {
  }
`;
const IdentityContainer = styled.div`
  display: grid;
  grid-template-areas: 'icon name' 'icon followers';
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr;
  column-gap: ${theme.spacing.x2};
  align-items: center;
  .identity-icon {
    grid-area: icon;
  }
  .identity-name {
    grid-area: name;
  }
`;
const FollowersSection = styled.div`
  grid-area: followers;
  display: flex;
  gap: ${theme.spacing.x1};
  color: ${theme.colors.N70};
`;

function Network() {
  const { t } = useTranslation();
  const history = useHistory();
  const currentUser: User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [following, setFollowing] = useState([]);
  const [followers, setFollowers] = useState([]);
  // Get the list of followed members
  const followingQueryKey = [queryKeys.following, currentMembership.id, {}];
  const { isLoading: isLoadingFollowing } = useQuery(followingQueryKey, remoteApi.fetchFollowing, {
    staleTime: 0,
    onSuccess: (response) => {
      const following = response.data;
      setFollowing(following);
    },
  });

  const followersQueryKey = [queryKeys.followers, currentMembership.id, {}];
  const { isLoading: isLoadingFollowers } = useQuery(followersQueryKey, remoteApi.fetchFollowers, {
    staleTime: 0,
    onSuccess: (response) => {
      const followers = response.data;
      setFollowers(followers);
    },
  });

  const showFollowing = () => {
    history.push(`#following::show`);
  };
  const showFollowers = () => {
    history.push(`#followers::show`);
  };

  const isLoading = isLoadingFollowing || isLoadingFollowers;

  const showTeamList =
    workspaceUtils.hasV4PlusSubscription(currentWorkspace) || workspaceUtils.hasPremiumSubscription(currentWorkspace);

  return (
    <>
      <UserBlock>
        <IdentityContainer>
          <KoalaAvatar user={currentUser} size={4} className="identity-icon" />
          <h3 className="identity-name">{currentUser.fullname}</h3>
          {isLoading && <KoalaLoader />}
          {!isLoading && (
            <FollowersSection>
              <KoalaTextButton onClick={showFollowing} appearance="subtle" size="small">
                {t('workspaceInbox.following', { count: following.length })}
              </KoalaTextButton>
              <small>|</small>
              <KoalaTextButton onClick={showFollowers} appearance="subtle" size="small">
                {t('workspaceInbox.followers', { count: followers.length })}
              </KoalaTextButton>
            </FollowersSection>
          )}
        </IdentityContainer>
      </UserBlock>
      <MembershipInsights currentMembership={currentMembership} />
      <ReportToSelection membershipId={currentMembership.id} />
      {showTeamList && <TeamList membership={currentMembership} />}
    </>
  );
}

export default Network;
