import amplitude from 'amplitude-js';

export const initAmplitude = () => {
  const amplitudeToken = process.env.REACT_APP_AMPLITUDE_TOKEN || '';
  amplitude.getInstance().init(amplitudeToken);
};

export const setAmplitudeUserDevice = (deviceId: any) => {
  amplitude.getInstance().setDeviceId(deviceId);
};

export const setAmplitudeUserId = (userId: any) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties: any) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType: any, eventProperties: any) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
