import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import * as amplitudeUtils from 'utils/amplitude';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as workspaceUtils from 'utils/workspaceUtils';
import * as planUtils from 'utils/planUtils';
import { CustomTermKey, translate } from 'utils/customTermUtils';

// API
import * as remoteApi from 'api/remote';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';
import PlanTitle from 'components/PlanTitle';
import PlanIconSelector from 'components/PlanIconSelector';
import ReactTooltip from 'react-tooltip';

// Components
import AccessDenied from 'components/AccessDenied';
import { ChromeContent, ChromeNav } from 'components/Chrome';
import Loader from 'components/Loader';
import NotFound from 'components/NotFound';
import PlanNav from 'components/PlanNav';
import UpgradePanel from 'components/UpgradePanel';
import KoalaButton from 'koala/components/Button';
import KoalaIcon from 'koala/components/Icons';

// Tracker component
import Tracker from './PlanContent';
import { useTranslation } from 'react-i18next';

const MapContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LargeEmptyState = styled.div`
  background: #fff;
  padding: ${theme.spacing.x4};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const EmptyState = styled.div`
  width: 50rem;
  max-width: 50rem;
  background: #fff;
  padding: ${theme.spacing.x4};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};

  img {
    width: 15rem;
  }

  p {
    b {
      font-size: 2.2rem;
    }
    text-align: center;

    &.tinyimg {
      img {
        width: 8rem;
      }
    }

    &.actions {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        margin: ${theme.spacing.x1};
      }
    }
  }
`;

const PlanTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CascadingInfo = styled.div`
  margin-top: ${theme.spacing.x2};
  a {
    color: ${theme.colors.blue};
  }
`;

const UpgradeBanner = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  background: ${theme.colors.V50};
  color: #fff;
  padding: ${theme.spacing.x1};
  font-weight: 600;
  border-radius: 4px;
  justify-content: center;
  margin: ${theme.spacing.x2};

  button {
    color: #fff;
  }

  .upgrade-icon {
    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

function PlanWrite() {
  const { planId } = useParams<{ planId: string; workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isOwner = currentMembership.role === 'owner';
  const { t } = useTranslation();
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plan.track.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, planId, 'track'];
  const staleTime = 0;

  // Get the plan details
  const {
    isError,
    error,
    data: planResponse,
    isLoading: planIsLoading,
  }: any = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
  });

  const plan = planResponse ? planResponse.data : null;
  const dataIsLoading = planIsLoading;

  // We display the loader until we have loaded the plan and objectives.
  // The reason why we wait for objectives to be loaded here is to check in the Track components
  // if we should display the empty state, or the tracking content
  if (!plan || dataIsLoading) {
    return (
      <Fragment>
        <ChromeContent>
          <LoadingContainer>
            <Loader size="big" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);

  // Display empty state if the plan is archived
  if (plan.archived) {
    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | {t('workspacePlan.title', { plan: plan.title })} | Tability
          </title>
        </Helmet>
        <MobileReadyChromeHeader className="no_border">
          <WorkspaceHeader
            workspace={currentWorkspace}
            useGreyBackground={true}
            title={
              <PlanTitleContainer>
                <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  id="planIconSelector"
                  className="tooltip"
                  effect="solid"
                  delayShow={200}
                >
                  {t('workspacePlan.planIcon')}
                </ReactTooltip>
                <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
              </PlanTitleContainer>
            }
          />
        </MobileReadyChromeHeader>
        <ChromeNav>
          <PlanNav plan={plan} />
        </ChromeNav>
        <ChromeContent className="mask" isGreyBackground>
          <Mask>
            <EmptyState>
              <h3>{t('workspacePlan.archived')}</h3>
            </EmptyState>
          </Mask>
        </ChromeContent>
      </Fragment>
    );
  }

  // Set upgrade required to true
  let upgradeRequired = true;
  let showUpgradeBanner = false;

  // Only Premium workspaces should have access to insights
  if (workspaceUtils.hasPremiumSubscription(currentWorkspace) || workspaceUtils.hasTrial(currentWorkspace)) {
    upgradeRequired = false;
  }

  if (workspaceUtils.hasV4Trial(currentWorkspace)) {
    showUpgradeBanner = true;
  }

  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase();

  // Displays the list of templates
  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspacePlan.title', { plan: plan.title })} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader className="no_border">
        <WorkspaceHeader
          workspace={currentWorkspace}
          useGreyBackground={true}
          title={
            <PlanTitleContainer>
              <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
              <ReactTooltip
                place="bottom"
                type="dark"
                id="planIconSelector"
                className="tooltip"
                effect="solid"
                delayShow={200}
              >
                {t('workspacePlan.planIcon')}
              </ReactTooltip>
              <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
            </PlanTitleContainer>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeNav>
        <PlanNav plan={plan} />
      </ChromeNav>
      <ChromeContent isGreyBackground>
        <MapContent>
          {showUpgradeBanner && (
            <UpgradeBanner>
              <KoalaIcon iconName="upgrade" className="upgrade-icon" />
              {t(`shared.availableDuringTrial`, { subscription: 'Premium' })}
              {isOwner && (
                <KoalaButton
                  size="small"
                  appearance="secondary"
                  isLink
                  to={`/${currentWorkspace.slug}/settings/billing`}
                >
                  {t('workspacePlan.map.upgrade.unlock')}
                </KoalaButton>
              )}
            </UpgradeBanner>
          )}
          {upgradeRequired && (
            <Mask>
              <LargeEmptyState>
                <h3>{t('workspacePlan.map.notAvailable')}</h3>
                <UpgradePanel
                  title={t('workspacePlan.map.upgradeHeader')}
                  description={t('workspacePlan.map.upgradeInfo')}
                />
                <CascadingInfo>
                  {t('workspacePlan.map.info', { outcomeLabel: outcomeLabel })} -{' '}
                  <a
                    href="https://guides.tability.app/docs/features/outcomes-key-results/cascading-map"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('shared.learnMore').toLowerCase()}
                  </a>
                </CascadingInfo>
              </LargeEmptyState>
            </Mask>
          )}
          <Tracker plan={plan} />
        </MapContent>
      </ChromeContent>
    </Fragment>
  );
}

export default PlanWrite;
