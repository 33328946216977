//@ flow
const sizes = {
  mobile: '576px',
  tablet: '768px',
  laptop: '1024px',
  smallDesktop: '1200px',
  desktop: '1440px',
  bigDesktop: '1600px',
};

export default {
  mobile: `(max-width: ${sizes.mobile})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  smallDesktop: `(max-width: ${sizes.smallDesktop})`,
  desktop: `(max-width: ${sizes.desktop})`,
  bigDesktop: `(max-width: ${sizes.bigDesktop})`,
  minMobile: `(min-width: ${sizes.mobile})`,
  minTablet: `(min-width: 769px)`,
  minLaptop: `(min-width: ${sizes.laptop})`,
  minDesktop: `(min-width: ${sizes.desktop})`,
};
