import FormField from 'components/FormField';
import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Outcome } from 'types';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';

interface CurrentValueProps {
  outcome: Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcome.id, `data_source:github:${outcome.data_source_type}`, 'current'];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null || currentValue === undefined) {
    return <FormField>Sorry, an error occurred while fetching the current value.</FormField>;
  }

  const dataTypeToLabel: any = {
    issues_created: '<number> issues created',
    issues_closed: '<number> issues closed',
    prs_created: '<number> PRs created',
    prs_closed: '<number> PRs closed',
    avg_build_time: '<number> avg build time (s)',
  };

  const label = dataTypeToLabel[outcome.data_source_type || ''];
  const labelWithNumber = label.replace('<number>', currentValue.toLocaleString());
  return (
    <FormField>
      <label>Preview value</label>
      <p>{labelWithNumber}</p>
    </FormField>
  );
}

interface Props {
  outcome: Outcome;
}

// Component that loads the current value of from the data source
function GithubSource(props: Props) {
  const { outcome } = props;
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');
  const [data_source_meta, setDataSourceMeta] = React.useState<any>(outcome.data_source_meta);
  const outcomeRepo = outcome.data_source_meta?.repo || '';

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const handleUpdate = (e: any) => {
    e.preventDefault();

    let body = {
      data_source_origin: 'github',
      data_source_type: data_source_type,
      data_source_meta,
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const availableDataSourceTypes = ['issues_created', 'issues_closed', 'prs_created', 'prs_closed', 'avg_build_time'];

  const repo = data_source_meta?.repo || '';

  const hasChanged = outcome.data_source_type !== data_source_type || outcomeRepo !== repo;

  return (
    <>
      <FormField>
        <label>Change Metric type</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">Select a metric</option>
          <option value="issues_created">Issues created</option>
          <option value="issues_closed">Issues closed</option>
          <option value="prs_created">PRs created</option>
          <option value="prs_closed">PRs closed</option>
          <option value="avg_build_time">Average build time (s)</option>
        </select>
      </FormField>
      <FormField>
        <label>Github Repo</label>
        <input
          value={repo}
          className="small"
          onChange={(e) => setDataSourceMeta({ repo: e.currentTarget.value })}
          placeholder="owner/repo"
        />
      </FormField>
      {hasChanged && (
        <FormField>
          <KoalaButton
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={!data_source_type || !repo || isUpdating}
            appearance="subtle"
          >
            Save & preview
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'github' &&
        outcome.data_source_type &&
        outcome.data_source_type === data_source_type && (
          <>{availableDataSourceTypes.includes(outcome.data_source_type) && <CurrentValue outcome={outcome} />}</>
        )}
    </>
  );
}

export default React.memo(GithubSource);
