import React from 'react';
//import * as TabilityTypes from 'types';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import CommentBlock from './CommentBlock';

import Loader from 'components/Loader';

interface Props {
  standup: any;
}

function StandupComments(props: Props) {
  const { standup } = props;

  // Get the standup comments

  // Construct the query key using the plan Id
  const queryKeyStandupComments = [queryKeys.standupComments, standup.id];
  const staleTime = queryKeys.staleTime;

  // Get the comments details
  const { data: standupCommentsResponse, isLoading }: any = useQuery(
    queryKeyStandupComments,
    remoteApi.fetchStandupComments,
    {
      staleTime,
    },
  );

  let standupComments = [];
  if (standupCommentsResponse) {
    standupComments = standupCommentsResponse.data;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {standupComments.map((standupComment: any) => {
        return <CommentBlock standupComment={standupComment} key={standupComment.id} />;
      })}
    </>
  );
}

export default React.memo(StandupComments);
