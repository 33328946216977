import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { getTime } from 'date-fns';

import theme from 'theme';
import { Outcome, Workspace, checkinType } from 'types';
import * as remoteApi from 'api/remote';
import { CONTEXT_STORAGE_KEY } from 'config/constants';
import queryKeys from 'config/queryKeys';
import FormField from 'components/FormField';
import DataSourceLabel from 'modals/CheckinComponents/DataSourceLabel';
import { confidenceToColor } from 'utils/checkinUtils';
import * as outcomeUtils from 'utils/outcomeUtils';
import RadioField from 'components/RadioField';
import MentionBox from 'components/MentionBox';
import CheckinsChart from 'components/Chart';
import ProgressBars from 'modals/CheckinComponents/ProgressBars';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaButton from 'koala/components/Button';
import KoalaTextBadge from 'koala/components/TextBadge';
import * as checkinUtils from 'utils/checkinUtils';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import CheckinBlock from 'components/CheckinBlock';

const ScoreField = styled.div`
  display: flex;
  align-items: center;
  input.tiny {
    min-width: 10rem;
    width: auto;
    margin-right: 0;
  }
  .prefix,
  .suffix {
    color: ${theme.colors.subtleText};
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    outline: none;
    transition: border 0.5s ease;
    box-sizing: border-box;
    font-size: 1.4rem;
    line-height: 1;
    height: 4rem;
    padding: 0;
    margin: 0;
  }
  .prefix {
    padding-right: ${theme.spacing.x1};
  }
  .suffix {
    padding-left: ${theme.spacing.x1};
  }
`;

const ChartColumn = styled.div`
  flex: 1;
  padding-left: ${theme.spacing.x2};

  @media ${theme.devices.mobile} {
    width: 100%;
    padding: 0;
    margin-bottom: ${theme.spacing.x4};
  }
`;

const UpdateColumn = styled.div`
  flex: 1;
  padding-right: ${theme.spacing.x2};

  @media ${theme.devices.mobile} {
    width: 100%;
    padding: 0px;

    input.small {
      min-width: 15rem;
    }

    .radio-group > label {
      padding: 0 ${theme.spacing.x1};
      white-space: normal;
      min-width: 7rem;
      text-align: center;
    }
  }
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 0;

  @media ${theme.devices.mobile} {
    flex-direction: column-reverse;
  }
`;

const CumulInput = styled.div`
  display: flex;

  input.cumul {
    border-radius: 4px 0 0 4px;
  }

  .cumul-result {
    height: 3.2rem;
    display: flex;
    align-items: center;
    border: 1px solid ${theme.colors.inputBorder};
    border-left: 0;
    padding: 0 ${theme.spacing.x2};
    color: ${theme.colors.subtleText};
    font-size: 1.4rem;
    border-radius: 0 4px 4px 0;
  }

  @media ${theme.devices.tablet} {
    width: 55%;
    min-width: 10rem;
    .cumul-result {
      width: inherit;
    }
  }
`;

const Header = styled.div`
  display: flex;
  gap: ${theme.spacing.half};
  padding-top: ${theme.spacing.x2};

  .collapsed {
    transform: rotate(-90deg);
  }
`;

const PreviousCheckinContainer = styled.div`
  border: 1px solid ${theme.colors.N20};
  border-radius: 4px;
  padding: 0px ${theme.spacing.x1};
`;

const Actions = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding-bottom: ${theme.spacing.x2};
  background: #fff;
  padding: ${theme.spacing.x2};
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;

  &.completed {
    background: ${theme.colors.G5};
    border: 1px solid ${theme.colors.G20};
  }
`;

const OutcomeCompleted = styled.div`
  display: inline-flex;
  gap: ${theme.spacing.x1};
`;

interface Props {
  outcome: Outcome;
  setErrorCheckinsLimitExceeded: (value: boolean) => void;
}

function BulkCheckinBlock(props: Props) {
  const { outcome, setErrorCheckinsLimitExceeded } = props;
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  // Form values
  const [checkin_date, setCheckinDate] = useState(new Date());
  const [score, setScore] = useState('');
  const [previousScore, setPreviousScore] = useState(0);
  const [confidence, setConfidence] = useState<string>('yellow');
  const [body, setBody] = useState('');
  const [checkins, setCheckins] = useState([]);
  const [isCumul, setIsCumul] = useState(false);
  const [cumulValue, setCumulValue] = useState<number | null>(null);
  const [placeholder, setPlaceholder] = useState<number | null>(null);
  const [showLastCheckin, setShowLastCheckin] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isSkipped, setIsSkipped] = useState(false);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const storageKey = `${CONTEXT_STORAGE_KEY}_checkin_${outcome.id}`;
  const translationKey = 'modals.checkin';

  const queryKey = [queryKeys.currentOutcome, outcome.id];
  const staleTime = 0;
  useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
    onSuccess: (response) => {
      const outcome = response.data;
      if (outcome.current_checkin) {
        const lastCheckin = outcome.current_checkin;
        setPreviousScore(lastCheckin.score);
        setConfidence(outcome.outcome_type !== 'kpi' ? lastCheckin.confidence : 'blue');
        setPlaceholder(lastCheckin.score);
        setCumulValue(lastCheckin.score);
      } else {
        setConfidence(outcome.outcome_type !== 'kpi' ? 'yellow' : 'blue');
        setCumulValue(outcome.from !== null ? outcome.from : outcome.to);
        setPlaceholder(outcome.from !== null ? outcome.from : outcome.to);
      }
    },
  });

  useEffect(() => {
    const savedContext = sessionStorage.getItem(storageKey) || '';
    setBody(savedContext);
  }, [storageKey]);

  // Construct the query key to get the
  const queryKeyCheckins = [queryKeys.checkins, outcome.id, { sort: 'checkin_date', order: 'asc' }];

  // Get the plan details
  useQuery(queryKeyCheckins, remoteApi.fetchCheckins, {
    staleTime,
    onSuccess: (response) => {
      const checkins = response.data;
      setCheckins(checkins);
    },
  });

  // Checkin mutations
  const [createCheckinMutation, { isLoading: isCreating }] = useMutation(remoteApi.createCheckin, {
    onSuccess: () => {
      setIsComplete(true);
      queryCache.invalidateQueries([queryKeys.checkins, 'pending']);
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcome.id]);
    },
    onError: (error: any) => {
      const errorText = error.response.data.error;
      if (errorText.includes('check-ins limit') && error.response.status === 401) {
        setErrorCheckinsLimitExceeded(true);
      }
    },
  });

  const [skipCheckinMutation] = useMutation(remoteApi.skipCheckin, {
    onSuccess: () => {
      setIsSkipped(true);
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcome.id]);
    },
  });

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'checkin_date':
        setCheckinDate(value);
        break;
      case 'score':
        setScore(value);
        break;
      case 'confidence':
        setConfidence(value);
        break;
      case 'body':
        setBody(value);
        break;
    }
  };

  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let value = e.target.value;
    value = value.replace(/[^-0-9.+]+/g, '');
    setScoreChanges(value);
  };

  const handleSkipCheckin = (e: any) => {
    e.preventDefault();
    skipCheckinMutation({
      outcomeId: outcome.id,
    });
  };

  const setScoreChanges = (value: string) => {
    setScore(value);

    let cumulValue = 0;

    // Detect if it's a cumulative update. If that's the case we set the state to true.
    // We can only do the cumulative update on new status
    if (value && value[0] === '+') {
      let valueToAdd = parseFloat(value.replace('+', ''));
      valueToAdd = isNaN(valueToAdd) ? 0 : valueToAdd;
      cumulValue = previousScore + valueToAdd;
      setIsCumul(true);
      setCumulValue(cumulValue);
    } else {
      let valueToAdd = parseFloat(value.replace('+', ''));
      valueToAdd = isNaN(valueToAdd) ? 0 : valueToAdd;
      cumulValue = valueToAdd;
      setIsCumul(false);
      setCumulValue(cumulValue);
    }
  };

  let progressValue;
  if (score !== '') {
    progressValue = isCumul ? cumulValue : score;
  } else {
    progressValue = placeholder;
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // Set score to cumul value if necessary
    const checkin = {
      checkin_date,
      score: cumulValue || 0,
      confidence,
      body,
    };
    createCheckinMutation({
      outcomeId: outcome.id,
      checkin,
    });
  };

  const handlePreviousCheckin = () => {
    setShowLastCheckin((prev) => !prev);
  };

  const draftCheckin: checkinType = {
    x: checkin_date,
    y: cumulValue,
    timestamp: getTime(checkin_date),
    color: confidenceToColor(confidence),
    checkin: { confidence },
  };

  let format: string[] = [];
  if (outcomeUtils.hasTarget(outcome) && outcome.score_format) {
    format = outcome.score_format.split('_number_');
  }

  const cumulClass = isCumul ? 'cumul' : '';

  const analysisPlaceholder = t(`${translationKey}.placeholder`);

  const formattedScore = draftCheckin
    ? checkinUtils.checkinScore({ score: cumulValue, confidence }, outcome, t)
    : 'Pending';

  if (isComplete) {
    return (
      <Container className="completed">
        <h3>{outcome.title}</h3>
        <OutcomeCompleted>
          <Trans
            i18nKey="modals.bulkCheckin.outcomeUpdated"
            values={{
              outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
              score: formattedScore,
            }}
            components={{
              status: (
                <KoalaTextBadge
                  isLowercase={true}
                  variant={checkinUtils.confidenceToVariant(confidence)}
                  edge="circle"
                  size="small"
                  style={{ border: 'none' }}
                  maxWidth="10rem"
                  dataFor={`progress-${outcome.nano_slug}`}
                >
                  {formattedScore}
                </KoalaTextBadge>
              ),
            }}
          />
        </OutcomeCompleted>
      </Container>
    );
  }

  if (isSkipped) {
    return (
      <Container className="completed">
        <h3>{outcome.title}</h3>
        <OutcomeCompleted>
          {t('modals.bulkCheckin.skipped', { outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1) })}
        </OutcomeCompleted>
      </Container>
    );
  }

  return (
    <Container>
      <h3>{outcome.title}</h3>
      <form>
        <Columns>
          <UpdateColumn>
            <DataSourceLabel outcome={outcome} setScoreChanges={setScoreChanges} />
            {
              // Only display the score field if the outcome has a target
              outcome.outcome_type !== 'no_metric' && (
                <FormField>
                  <label>{t(`${translationKey}.progress`)}</label>
                  <ScoreField>
                    {format[0] && (
                      <div className="prefix">
                        <span>{format[0]}</span>
                      </div>
                    )}
                    <CumulInput>
                      <input
                        className={`tiny ${cumulClass}`}
                        name="score"
                        autoComplete="off"
                        value={score}
                        placeholder={`${placeholder}`}
                        onChange={handleScoreChange}
                        required={true}
                      />
                      {isCumul === true && <div className="cumul-result">= {cumulValue}</div>}
                    </CumulInput>
                    {format[1] && (
                      <div className="suffix">
                        <span>{format[1]}</span>
                      </div>
                    )}
                  </ScoreField>
                  <p>
                    <small className="subtle">{t(`${translationKey}.progressInfo`)}</small>
                  </p>
                </FormField>
              )
            }
            {outcome.outcome_type !== 'kpi' && (
              <RadioField>
                <label>{t(`${translationKey}.confidence`)}</label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id={`${outcome.id}-green`}
                    checked={confidence === 'green'}
                    name="confidence"
                    value={'green'}
                    onChange={handleChange}
                  />
                  <label htmlFor={`${outcome.id}-green`} className="green">
                    {t('shared.confidence.onTrack')}
                  </label>
                  <input
                    type="radio"
                    id={`${outcome.id}-yellow`}
                    checked={confidence === 'yellow'}
                    name="confidence"
                    value={'yellow'}
                    onChange={handleChange}
                  />
                  <label htmlFor={`${outcome.id}-yellow`} className="yellow">
                    {t('shared.confidence.atRisk')}
                  </label>
                  <input
                    type="radio"
                    id={`${outcome.id}-red`}
                    checked={confidence === 'red'}
                    name="confidence"
                    value={'red'}
                    onChange={handleChange}
                  />
                  <label htmlFor={`${outcome.id}-red`} className="red">
                    {t('shared.confidence.offTrack')}
                  </label>
                </div>
              </RadioField>
            )}
            <FormField>
              <label>{t(`${translationKey}.analysis`)}</label>
              <MentionBox
                value={body}
                setValue={setBody}
                placeholder={analysisPlaceholder}
                cmdEnterCallback={handleSubmit}
                disableAutoFocus={true}
              />
            </FormField>
            <Actions>
              <KoalaButton loading={isCreating} disabled={isCreating} onClick={handleSubmit}>
                {t('shared.create')}
              </KoalaButton>
              <KoalaButton appearance="subtle" onClick={handleSkipCheckin}>
                {t(`shared.skipCheckin`)}
              </KoalaButton>
            </Actions>
          </UpdateColumn>

          <ChartColumn>
            <CheckinsChart checkins={checkins} outcome={outcome} draftCheckin={draftCheckin} />
            {checkin_date && outcome.outcome_type === 'improve_metric' && (
              <ProgressBars
                outcome={outcome}
                checkinDate={checkin_date}
                currentValue={progressValue ?? ''}
                confidence={confidence}
              />
            )}
            {outcome.current_checkin && outcome.current_checkin.body !== '' && (
              <FormField>
                <Header>
                  <KoalaIconButton
                    iconName="downChevron"
                    size="xsmall"
                    onClick={handlePreviousCheckin}
                    className={showLastCheckin ? 'expanded' : 'collapsed'}
                  />
                  <label>{t('modals.bulkCheckin.latestCheckin')}</label>
                </Header>
                {showLastCheckin && (
                  <PreviousCheckinContainer>
                    <CheckinBlock outcome={outcome} checkin={outcome.current_checkin} compact={true} hideActions />
                  </PreviousCheckinContainer>
                )}
              </FormField>
            )}
          </ChartColumn>
        </Columns>
      </form>
    </Container>
  );
}

export default BulkCheckinBlock;
