import React from 'react';

const LoveIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.54727 4.94514C6.49512 3.89299 4.78925 3.89299 3.7371 4.94514C2.68496 5.99729 2.68495 7.70315 3.73709 8.7553C3.7371 8.75531 3.73709 8.7553 3.73709 8.7553L4.83127 9.84947L10.0189 15.0371L16.2629 8.79308C17.315 7.74093 17.315 6.03505 16.2629 4.9829C15.2107 3.93075 13.5049 3.93076 12.4527 4.98291L10.0189 7.41673L7.54727 4.94514ZM10.0189 17.792L2.35967 10.1328C0.546778 8.31986 0.546778 5.38059 2.35967 3.5677C4.17255 1.75482 7.11182 1.75482 8.92471 3.5677L10.0189 4.66187L11.0753 3.60547C12.8882 1.79258 15.8274 1.79258 17.6403 3.60547C19.4532 5.41835 19.4532 8.35763 17.6403 10.1705L10.0189 17.792Z"
      fill="#737373"
    />
  </svg>
);
export default LoveIcon;
