import { ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';

import * as remoteApi from 'api/remote';
import { Objective, Outcome, Plan } from 'types';
import KoalaLoader from 'koala/components/Loader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as routes from 'routes';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaIcon from 'koala/components/Icons';
import PlanStatus from 'components/PlanStatus';
import { useTranslation } from 'react-i18next';
import OutcomeBlockCompact from 'components/PlanOverview/OutcomeBlockCompact';
import KoalaButton from 'koala/components/Button';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useHotkeys } from 'react-hotkeys-hook';

const ModalContent = styled.div`
  grid-area: modal-content;
  padding: ${theme.spacing.x2};
  overflow: auto;
  position: relative;

  &.hide-overflow {
    overflow: unset;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;
const HeaderSection = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;

  .superlocked {
    svg {
      path {
        fill: ${theme.colors.orange};
      }
    }
  }
`;

const PlanContent = styled.div`
  overflow: auto;
`;

const TextContainer = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

const ObjectiveContent = styled.div`
  margin-bottom: ${theme.spacing.x2};
  .progress-bar {
    margin: 0 ${theme.spacing.x1};
    width: auto;
  }
  .subtle {
    margin: ${theme.spacing.x1} ${theme.spacing.x2};
  }
`;

const ObjectiveHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${theme.spacing.x1} ${theme.spacing.x1} 0 ${theme.spacing.x1};
`;

const ObjectiveTitle = styled.div`
  padding: 4px 0;
  font-size: 1.4rem;
  font-weight: 600;
`;

interface Props {
  parentPlanId: string;
}

function PreviewPlan(props: Props) {
  const { parentPlanId } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [outcomesByObjective, setOutcomesByObjective] = useState<{ [key: string]: Outcome[] }>({});
  const [isPrivate, setIsPrivate] = useState(false);
  const location = useLocation();

  const handleCancel = () => {
    history.goBack();
  };

  useHotkeys(`escape`, () => handleCancel(), { scopes: ['panels'] });

  // Load the data for the nav
  // Get the objectives
  const { isLoading: objectivesAreLoading } = useQuery(
    [queryKeys.objectives, parentPlanId],
    remoteApi.fetchPlanObjectives,
    {
      staleTime: 0,
      onSuccess: (response: any) => {
        const objectives: Objective[] = response.data;
        setObjectives(objectives);
      },
      onError: (err: any) => {
        if (err.response.status === 401) {
          setIsPrivate(true);
        }
      },
    },
  );
  // Get the outcomes
  const { isLoading: outcomesAreLoading } = useQuery([queryKeys.outcomes, parentPlanId], remoteApi.fetchPlanOutcomes, {
    staleTime: 0,
    onSuccess: (response: any) => {
      const outcomes: Outcome[] = response.data;
      const outcomesByObjective: { [key: string]: Outcome[] } = {};
      // Iterate on each outcome and group items by objective_id in an array
      outcomes.forEach((outcome) => {
        if (outcomesByObjective[outcome.objective_id]) {
          outcomesByObjective[outcome.objective_id].push(outcome);
        } else {
          outcomesByObjective[outcome.objective_id] = [outcome];
        }
      });
      setOutcomesByObjective(outcomesByObjective);
    },
  });

  const queryKey = [queryKeys.currentPlan, parentPlanId];
  const staleTime = 0;
  const { data: planResponse, isLoading: planLoading } = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
  });

  if (isPrivate) {
    return (
      <ModalGrid>
        <ModalHeader>
          <h2>{t(`workspacePlan.parentShow`)}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </ModalHeader>
        <ModalContent>
          <p>{t(`workspacePlan.write.noAccess`)}</p>
        </ModalContent>
      </ModalGrid>
    );
  }

  const parentPlan: Plan | null = planResponse ? planResponse.data : null;

  if (!parentPlan) {
    return (
      <ModalGrid>
        <ModalHeader>
          <h2>{t(`workspacePlan.parentShow`)}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </ModalHeader>
        <ModalContent>{t(`workspacePlan.noParent`)}</ModalContent>
      </ModalGrid>
    );
  }
  const handleVisitPlan = () => {
    const planRoute = routeTarget.replace(':workspaceSlug', workspaceSlug).replace(':planId', parentPlan.nano_slug);
    history.push(planRoute);
  };

  const handleOutcomeClick = (e: React.MouseEvent<HTMLDivElement>, outcome: Outcome) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`#outcome:${outcome.nano_slug}:show`);
  };

  const isBlockSelected = (blockId: string) => {
    return location.hash.includes(blockId);
  };
  const routeTarget =
    parentPlan && parentPlan.state === 'published'
      ? routes.WORKSPACE_PLAN_TRACK_ROUTE
      : routes.WORKSPACE_PLAN_WRITE_ROUTE;
  const planLocked = parentPlan.global_permission_type !== 'edit';
  const superlockedClassName = parentPlan.global_permission_type === 'none' ? 'superlocked' : '';
  const isLoading = planLoading || outcomesAreLoading || objectivesAreLoading;

  return (
    <ModalGrid>
      <ModalHeader>
        <HeaderSection>
          <h2>
            <PlanIconLabel plan={parentPlan} size="small" />
          </h2>
          <PlanStatus plan={parentPlan} size="small" />
          {planLocked && <KoalaIcon iconName="locked" iconSize="small" className={superlockedClassName} />}
        </HeaderSection>
        <HeaderSection>
          <KoalaButton onClick={handleVisitPlan}>{t(`workspacePlan.visitPlan`)}</KoalaButton>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </HeaderSection>
      </ModalHeader>

      <ModalContent>
        {isLoading && <KoalaLoader />}
        {!isLoading && parentPlan && (
          <PlanContent>
            {objectives.length === 0 && (
              <TextContainer>
                <p className="subtle">{t('workspacePlan.empty')}</p>
              </TextContainer>
            )}
            {objectives.map((objective) => {
              const outcomesToDisplay = outcomesByObjective[objective.id] || [];
              return (
                <ObjectiveContent key={objective.id}>
                  <ObjectiveHeader>
                    <ObjectiveTitle className="title">{objective.title}</ObjectiveTitle>
                  </ObjectiveHeader>
                  <>
                    {outcomesToDisplay.length === 0 && (
                      <div className="subtle">
                        {t(`workspacePlan.objectiveEmpty`, {
                          objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
                          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                        })}
                      </div>
                    )}
                    {outcomesToDisplay.map((outcome) => (
                      <OutcomeBlockCompact
                        outcome={outcome}
                        isSelected={isBlockSelected(`outcome:${outcome.nano_slug}`)}
                        handleClick={(e) => handleOutcomeClick(e, outcome)}
                      />
                    ))}
                  </>
                </ObjectiveContent>
              );
            })}
          </PlanContent>
        )}
      </ModalContent>
    </ModalGrid>
  );
}

export default PreviewPlan;
