import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  margin-bottom: ${theme.spacing.x3};
  span {
    display: block;
    margin: ${theme.spacing.x1} 0;
  }
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: ${theme.spacing.x1};
    color: ${theme.colors.N70};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    display: block;
  }

  .form-label {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${theme.colors.subtleText};
    margin-bottom: ${theme.spacing.x1};
  }

  .radio-group {
    display: flex;
    overflow: hidden;
    border-radius: 4px;
    background-color: #fff;
    max-height: 3.8rem;
    height: 3.8rem;
    position: relative;
    border: 1px solid ${theme.colors.inputBorder};

    // hide radio button and put it behind label so not clickable
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    label {
      color: ${theme.colors.black};
      background-color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: 1.2rem;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 100%;
      height: 100%;
      border: 0;
      padding: 0 ${theme.spacing.x2};
      transition: all 0.2s ease;
      white-space: nowrap;

      &.disabled {
        cursor: not-allowed;
        filter: grayscale(0.7);
        opacity: 0.7;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        g,
        path,
        circle {
          stroke: ${theme.colors.subtleText};
        }
      }
    }

    input[type='radio']:checked + label {
      color: #fff;
      background: ${theme.colors.blue};
      &.red {
        background: ${theme.colors.red};
      }
      &.yellow {
        background: ${theme.colors.yellow};
        color: ${theme.colors.black};
      }
      &.green {
        background: ${theme.colors.green};
      }
      &.light {
        background: ${theme.colors.B10};
        color: ${theme.colors.black};
      }

      svg {
        g {
          stroke: #fff;
        }
      }
    }

    &.text {
      label {
        padding: 0 ${theme.spacing.x1};
      }
      input[type='radio']:checked + label {
      }
    }

    label + input[type='radio'] + label {
      border-left: solid 1px ${theme.colors.inputBorder};

      &:target {
        border: 2px solid ${theme.colors.blue};
      }
    }
  }

  &.icons-only {
    .radio-group {
      display: flex;
      align-items: center;
    }
    label {
      display: flex;
      align-items: center;
    }
  }

  &.compact {
    margin-bottom: 0;

    .radio-group {
      height: 2.4rem;
      label {
        font-size: 1rem;
      }
    }
  }
`;
