// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { getTime, isBefore, isAfter, parseISO, format, toDate } from 'date-fns';
import * as planUtils from 'utils/planUtils';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

import EmptyStatePanel from 'components/EmptyStatePanel';
import Chart from 'components/CheckinChart';
import { VictoryChart, VictoryArea, VictoryLegend, VictoryVoronoiContainer, VictoryGroup } from 'victory';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  height: 20rem;
  display: flex;
  margin-bottom: ${theme.spacing.x1};
  width: 100%;

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

interface Props {
  trends: any[];
  plan: TabilityTypes.Plan;
}

interface ChartProps {
  targetData: any;
  outcomesData: any;
  initiativesData: any;
  isInsideTimeline: boolean;
  width: number;
}
function RenderChart(props: ChartProps) {
  const { targetData, outcomesData, initiativesData, isInsideTimeline, width } = props;
  const { t, i18n } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const label = width / 2;
  return (
    <VictoryChart
      width={width}
      height={200}
      domainPadding={{ x: 15 }}
      padding={{ top: 50, bottom: 30, left: 50, right: 20 }}
      domain={{ y: [0, 100] }}
      containerComponent={<VictoryVoronoiContainer />}
    >
      <VictoryLegend
        x={label - 150}
        y={0}
        centerTitle
        orientation="horizontal"
        gutter={20}
        style={{ labels: { fontSize: 12, fontFamily: theme.font.fontFamily, fill: theme.colors.N80 } }}
        data={[
          {
            name: t('workspacePlan.insights.outcomeProgress', {
              outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
            }),
            symbol: { fill: theme.colors.T50 },
          },
          {
            name: t('workspacePlan.insights.initiativeProgress', {
              initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
            }),
            symbol: { fill: theme.colors.V50 },
          },
        ]}
      />
      {Chart.XAxis(i18n)}
      {Chart.YAxis()}
      <VictoryGroup scale="time">
        <VictoryArea
          style={{
            data: {
              fill: 'transparent',
              fillOpacity: 0.5,
              stroke: theme.colors.N10,
              strokeWidth: 2,
            },
          }}
          data={targetData}
        />
        <VictoryArea
          style={{
            data: {
              stroke: theme.colors.T50,
              fill: 'transparent',
              fillOpacity: 0.2,
              strokeWidth: 3,
              strokeAreacap: 'round',
            },
          }}
          data={outcomesData}
          interpolation="monotoneX"
          labels={({ datum }) => [`${format(toDate(datum.x), 'd MMM yyyy')}`, `${datum.displayText}`]}
          labelComponent={Chart.LabelTooltip()}
        />
        <VictoryArea
          style={{
            data: {
              stroke: theme.colors.V50,
              strokeWidth: 3,
              fill: 'transparent',
              fillOpacity: 0.2,
              strokeLinecap: 'round',
            },
          }}
          data={initiativesData}
          interpolation="monotoneX"
          labels={({ datum }) => [`${format(toDate(datum.x), 'd MMM yyyy')}`, `${datum.displayText}`]}
          labelComponent={Chart.LabelTooltip()}
        />
      </VictoryGroup>
      {isInsideTimeline && Chart.TodayLine({ min: 0, max: 100 }, t)}
    </VictoryChart>
  );
}

function CheckinsChart(props: Props) {
  const { trends, plan } = props;
  const { t } = useTranslation();

  // resize the chart to fit the container
  const chartRef: any = useRef(null);

  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = (ev: Event) => {
    if (chartRef && chartRef.current) {
      setWidth(chartRef.current.offsetWidth);
    }
  };

  // useEffect replaces `componentDidMount` and others
  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    // Removes listener on unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      setWidth(chartRef.current.offsetWidth);
    }
  }, [chartRef]);

  // If the plan doesn't have a start and end date, display a warning
  if (!planUtils.hasTimeline(plan)) {
    return (
      <EmptyStatePanel>
        <p>{t('workspacePlan.defineTimeline')}</p>
      </EmptyStatePanel>
    );
  }

  const targetData: any[] = [];
  const outcomesData: any[] = [];
  const initiativesData: any[] = [];

  const startDate: Date = parseISO(plan.start_at);
  const finishDate: Date = parseISO(plan.finish_at);

  const firstPoint = {
    x: startDate,
    y: 0,
    timestamp: getTime(startDate),
    displayText: '0%',
  };

  const lastPoint = {
    x: finishDate,
    y: 100,
    timestamp: getTime(finishDate),
  };

  // By default we're going to use the first point from the target
  // We'll change that if we find a checkin prior to the first point.
  let useFirstPoint = true;

  targetData.push(firstPoint);
  targetData.push(lastPoint);

  trends.forEach((trend: any, index: number) => {
    const date = parseISO(trend.reference_date);
    // If the checkin was done prior to the first point we don't store
    // the first point in the list of current points
    if (isBefore(date, firstPoint.x)) {
      useFirstPoint = false;
    }

    // Create the point using the checkin data
    const outcomesPoint: any = {
      x: date,
      y: trend.outcome_progress_prct * 100,
      timestamp: getTime(date),
      displayText: `${Math.round(trend.outcome_progress_prct * 100)}%`,
      trend,
    };
    outcomesData.push(outcomesPoint);

    // Create the point using the checkin data
    const initiativesPoint: any = {
      x: date,
      y: trend.initiative_progress_prct * 100,
      timestamp: getTime(date),
      displayText: `${Math.round(trend.initiative_progress_prct * 100)}%`,
      trend,
    };
    initiativesData.push(initiativesPoint);

    if (isAfter(date, lastPoint.x)) {
      targetData.push({
        x: date,
        y: 100,
        timestamp: getTime(date),
      });
    }
  });

  if (useFirstPoint) {
    outcomesData.push(firstPoint);
    initiativesData.push(firstPoint);
  }

  const isInsideTimeline = planUtils.isInPlanTimeline(startDate, finishDate, new Date());

  // Now we can display the app with the Chrome
  return (
    <Container className="panel-checkins-chart" ref={chartRef}>
      <RenderChart
        width={width}
        targetData={targetData}
        outcomesData={outcomesData}
        initiativesData={initiativesData}
        isInsideTimeline={isInsideTimeline}
      />
    </Container>
  );
}

export default CheckinsChart;
