import React from 'react';

const LockedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.90948 10.0105C1.90948 8.52111 3.1169 7.31369 4.60632 7.31369H15.3937C16.8831 7.31369 18.0905 8.52111 18.0905 10.0105V16.3032C18.0905 17.7926 16.8831 19 15.3937 19H4.60632C3.1169 19 1.90948 17.7926 1.90948 16.3032V10.0105ZM4.60632 9.11159C4.10985 9.11159 3.70737 9.51406 3.70737 10.0105V16.3032C3.70737 16.7996 4.10985 17.2021 4.60632 17.2021H15.3937C15.8902 17.2021 16.2926 16.7996 16.2926 16.3032V10.0105C16.2926 9.51406 15.8902 9.11159 15.3937 9.11159H4.60632Z"
      fill="#DA412E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50527 5.49473C5.50527 3.01236 7.51763 1 10 1C12.4824 1 14.4947 3.01236 14.4947 5.49473V9.09052H5.50527V5.49473ZM10 2.79789C8.51058 2.79789 7.30316 4.00531 7.30316 5.49473V7.29263H12.6968V5.49473C12.6968 4.00531 11.4894 2.79789 10 2.79789Z"
      fill="#DA412E"
    />
  </svg>
);

export default LockedIcon;
