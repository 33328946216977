import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useMutation } from 'react-query';
import theme from 'theme';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import KoalaButton from 'koala/components/Button';
import { Trans, useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingsDetails(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();

  const [slugCheck, setSlugCheck] = useState('');

  // Workspace mutations
  const [deleteWorkspaceMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.deleteWorkspace, {
    onSuccess: () => {
      window.location.replace(`/delete_successful`);
    },
  });

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'slugCheck':
        setSlugCheck(value);
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    deleteWorkspaceMutation(workspace.slug);
  };

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'name':
        return t('errors.workspaceName');
      case 'slug':
        return t('errors.workspaceID');
      default:
        return '';
    }
  };

  const isDisabled = isLoading || slugCheck !== workspace.slug;

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsDelete.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsDelete.title')}</h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <p>
                <Trans
                  i18nKey="workspaceSettingsDelete.enterWorkspace"
                  components={{ b: <b /> }}
                  values={{ slug: workspace.slug }}
                />
              </p>
              <p className="danger">
                <b>{t('workspaceSettingsDelete.action')}</b>
              </p>
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsDelete.idLabel')}</label>
              <input
                type="text"
                name="slugCheck"
                className="small"
                value={slugCheck}
                autoComplete="off"
                placeholder={t('workspaceSettingsDelete.idLabel') ?? 'Workspace ID'}
                required={false}
                onChange={handleChange}
              />
            </FormField>
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isDisabled} loading={isLoading} appearance="danger">
                {t('workspaceSettingsDelete.deleteButton')}
              </KoalaButton>
            </Actions>
          </Form>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
