// @flow

// This file contains helpers function to make requests to the API endpoints
import axios from 'axios';
import { store } from 'App';

declare module 'axios' {
  export interface AxiosRequestConfig {
    ignoreAuthHeaders?: boolean;
  }
}

// Constructs the Auth Headers by looking for an access token
// This function is async to make sure that we wait on the response from the
// auth server before dispatching the request.
const authHeaders = () => {
  // Otherwise, let's use the shared credentials
  const accessToken = store.getState().session.accessToken;

  if (accessToken) return { Authorization: `Bearer ${accessToken}` };
  else return {};
};

// Create our axios instance and sets the common JSON headers\
const instance = axios.create();

// Common Headers for all requests
const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Tability-Platform': 'web',
};

// We create the auth headers at the time of the request
instance.interceptors.request.use((config: any) => {
  let _authHeaders = {};
  // Some calls must not pass auth headers
  // uncomment for signed in calls.
  if (!config.ignoreAuthHeaders) {
    _authHeaders = authHeaders();
  }

  let _jsonHeaders = config.ignoreJsonHeaders ? {} : jsonHeaders;

  config.headers = { ...config.headers, ..._jsonHeaders, ..._authHeaders };

  return config;
});

export default instance;
