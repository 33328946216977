import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import * as membershipUtils from 'utils/membershipUtils';

import PlanIconLabel from 'components/PlanIconLabel';
import ChevronRightIcon from 'components/_assets/ChevronRightIcon';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import KoalaIcon from 'koala/components/Icons';
import { WORKSPACE_PLAN_TEMPLATE_ROUTE, WORKSPACE_PLAN_TRACK_ROUTE, WORKSPACE_PLAN_WRITE_ROUTE } from 'routes';
import theme from 'theme';
import { Plan, Workspace } from 'types';
import * as planUtils from 'utils/planUtils';
import KoalaNCSPie from 'koala/components/NCSPie';
import KoalaTextBadge from 'koala/components/TextBadge';
import { capitaliseFirst, formatLocale } from 'utils/dateUtils';
import PlanStatus from 'components/PlanStatus';
import KoalaButton from 'koala/components/Button';
import DropdownMenu from 'components/DropdownMenu';
import KoalaIconButton from 'koala/components/IconButton';
import * as workspaceUtils from 'utils/workspaceUtils';
import UpgradeIcon from 'components/_assets/UpgradeIcon';

import { MovedPlan } from '.';
import KoalaCheckbox from 'koala/components/Checkbox';

const PlanContainer = styled.div<{ isHover: boolean }>`
  border-bottom: 1px solid ${theme.colors.blockBorder};
  background-color: ${({ isHover }) => isHover && theme.colors.G5};

  &:last-of-type {
    border-bottom: 0;
  }

  &.item {
    .draft {
      opacity: 0.5;
    }
    &:hover {
      background: ${theme.colors.N3};
      opacity: 1;
      cursor: pointer;
      .draft {
        opacity: 1;
      }
      .show-actions {
        background: ${theme.colors.N3};
      }
    }

    &:active,
    &:focus {
      background: ${theme.colors.B5};
      .show-actions,
      .actions {
        background: ${theme.colors.B5};
      }
    }
  }
`;

const TableGrid = styled.div<{ level: number }>`
  display: grid;
  position: relative;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  grid-gap: ${theme.spacing.x1};
  padding: 1.2rem ${theme.spacing.x2};
  padding-left: ${({ level }) => level * 3 + 1.8}rem;

  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr 27rem;
  }
  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr 13rem;
    .hide-mobile {
      display: none;
    }
  }
  @media ${theme.devices.mobile} {
    grid-template-columns: 1fr 4rem;
  }

  .actions {
    display: none;
  }

  .show-actions {
    display: flex;
    background-color: white;
  }

  &:hover {
    .actions {
      display: flex;
      background: ${theme.colors.N3};
    }
    .status {
      display: none;
    }
  }

  &.readonly {
    &:hover {
      .status {
        display: block;
      }
    }
  }
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  gap: ${theme.spacing.x1};

  .plan-title {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 1rem;
    width: 100%;
    font-size: 14px;
    line-height: 2rem;
  }

  .superlocked {
    path {
      fill: ${theme.colors.orange};
    }
  }
  a {
    min-width: 1rem;
    :hover {
      text-decoration: underline;
    }
  }
`;
const MetaBlock = styled.div`
  display: grid;
  grid-template-columns: 9rem 9rem 9rem 10rem 10rem;
  justify-content: end;
  gap: ${theme.spacing.x2};
  align-items: center;
  @media ${theme.devices.laptop} {
    grid-template-columns: 4.5rem 4.5rem 4.5rem 11rem;
    gap: ${theme.spacing.x1};
  }
  @media ${theme.devices.tablet} {
    grid-template-columns: 11rem;
  }
`;
const StatBlock = styled.div`
  @media ${theme.devices.tablet} {
    display: none;
  }
`;
const TimelineBlock = styled.div`
  width: 10rem;
  div {
    width: fit-content;
  }

  @media ${theme.devices.laptop} {
    display: none;
  }
`;
const StatusBlock = styled.div`
  min-width: 8rem;
  @media ${theme.devices.mobile} {
    display: none;
  }
`;
const ActionBlock = styled.div`
  position: absolute;
  right: ${theme.spacing.x1};
  top: 1.8rem;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  padding-left: ${theme.spacing.x1};
`;

const ExpandButton = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  padding: 0 ${theme.spacing.x1};
  line-height: 2rem;

  svg {
    height: 1rem;
  }

  transition: all ease 0.2s;
  &.expanded {
    transform: rotate(90deg);
  }

  span {
    font-size: 2.4rem;
    line-height: 1.6rem;
    color: ${theme.colors.subtleText};
  }
`;

const UpgradeOption = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  plan: Plan;
  workspace: Workspace;
  level: number;
  expandedPlans: { [id: string]: boolean };
  toggleExpand: (e: any) => void;
  handleMenuSelection: (selection: any) => void;
  handleCreateSubPlan: (e: any) => void;
  movePlan: (movedPlan: MovedPlan, hoverId: string | null) => void;
  selectedPlans: Plan[];
  setSelectedPlans: (newPlans: Plan[]) => void;
}

function PlanRow(props: Props) {
  const {
    plan,
    workspace,
    level,
    expandedPlans,
    toggleExpand,
    handleMenuSelection,
    handleCreateSubPlan,
    movePlan,
    selectedPlans,
    setSelectedPlans,
  } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);
  const [isSelected, setIsSelected] = useState(false);
  const [{ isOver }, drop] = useDrop({
    accept: 'plan',
    collect: (monitor: DropTargetMonitor) => ({ isOver: monitor.isOver() }),
    drop({ id: draggedId, parent_id: draggedParentId }: Plan) {
      if (draggedId !== plan.id) {
        const movedPlan = {
          id: draggedId,
          parentId: draggedParentId,
        };
        movePlan(movedPlan, plan.id);
      }
    },
  });

  useEffect(() => {
    if (selectedPlans.includes(plan)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedPlans, plan]);

  const [, drag] = useDrag(
    () => ({
      type: 'plan',
      item: { id: plan.id, parent_id: plan.parent_id },
      end: (item, monitor) => {
        const { id: droppedId, parent_id: droppedParentId } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          const movedPlan = {
            id: droppedId,
            parentId: droppedParentId,
          };
          movePlan(movedPlan, null);
        }
      },
    }),
    [],
  );

  const handleItemClick = (e: any) => {
    e.preventDefault();
    const planId = e.currentTarget.dataset.id;
    const planState = e.currentTarget.dataset.state;

    const stateToRouteMapping: any = {
      draft: WORKSPACE_PLAN_WRITE_ROUTE,
      published: WORKSPACE_PLAN_TRACK_ROUTE,
      template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
    };

    let routeMappingState = planState || 'draft';
    const routeTarget = stateToRouteMapping[routeMappingState];
    const planRoute = routeTarget.replace(':workspaceSlug', workspace.slug).replace(':planId', planId);
    history.push(planRoute);
  };
  // Check if the workspace has the right subscription
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(workspace) || workspace.pricing_version >= 2;
  const planOptions = (p: Plan) => {
    const hasEditPermission = planUtils.hasEditPermission(p, currentMembership);
    if (hasEssentialsSubscription) {
      if (!hasEditPermission) {
        return [
          <span data-action="create-plan" data-plan-id={p.id}>
            {t('workspacePlans.options.subPlan')}
          </span>,
          <span data-action="copy-plan" data-plan-id={p.nano_slug}>
            {t('workspacePlans.options.copy')}
          </span>,
        ];
      }
      let options: JSX.Element[] = [
        <span data-action="edit-plan" data-plan-id={p.nano_slug}>
          {t('workspacePlans.options.settings')}
        </span>,
        <span data-action="create-plan" data-plan-id={p.id}>
          {t('workspacePlans.options.subPlan')}
        </span>,
        <span data-action="move-plan" data-plan-id={p.nano_slug}>
          {t('workspacePlans.options.move')}
        </span>,
        <span data-action="copy-plan" data-plan-id={p.nano_slug}>
          {t('workspacePlans.options.copy')}
        </span>,
        <span data-action="archive-plan" data-plan-id={p.nano_slug}>
          {t('workspacePlans.options.archive')}
        </span>,
        <span data-action="delete-plan" data-plan-id={p.nano_slug}>
          {t('workspacePlans.options.delete')}
        </span>,
      ];
      if (p.state === 'published') {
        options.push(
          <span data-action="unpublish-plan" data-plan-id={p.nano_slug}>
            {t('workspacePlans.options.unpublish')}
          </span>,
        );
      }
      return options;
    }

    if (!hasEditPermission) {
      return [
        <span data-action="create-plan" data-plan-id={p.id}>
          {t('workspacePlans.options.subPlan')}
        </span>,
      ];
    }

    return [
      <span data-action="edit-plan" data-plan-id={p.nano_slug}>
        {t('workspacePlans.options.settings')}
      </span>,
      <span data-action="create-plan" data-plan-id={p.id}>
        {t('workspacePlans.options.subPlan')}
      </span>,
      <span data-action="move-plan" data-plan-id={p.nano_slug}>
        {t('workspacePlans.options.move')}
      </span>,
      <UpgradeOption data-action="upgrade-copy-plan" data-plan-id={p.nano_slug}>
        <span> {t('workspacePlans.options.copy')}</span>
        <span>
          <UpgradeIcon />
        </span>
      </UpgradeOption>,
      <span data-action="archive-plan" data-plan-id={p.nano_slug}>
        {t('workspacePlans.options.archive')}
      </span>,
      <span data-action="delete-plan" data-plan-id={p.nano_slug}>
        {t('workspacePlans.options.delete')}
      </span>,
    ];
  };

  const confidenceData = planUtils.getConfidenceData(plan);
  const outcomeProgressData = planUtils.getOutcomeProgressData(plan);
  const initiativeProgressData = planUtils.getInitiativeProgressData(plan);
  const isExpanded = expandedPlans[plan.id] ? true : false;
  const expandState = isExpanded ? 'expanded' : '';

  const stateToRouteMapping: any = {
    draft: WORKSPACE_PLAN_WRITE_ROUTE,
    published: WORKSPACE_PLAN_TRACK_ROUTE,
    template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
  };

  let routeMappingState = plan.state || 'draft';
  const routeTarget = stateToRouteMapping[routeMappingState];

  const planRoute = routeTarget.replace(':workspaceSlug', workspace.slug).replace(':planId', plan.nano_slug);

  const planLocked = plan.global_permission_type !== 'edit';
  const superlockedClassName = plan.global_permission_type === 'none' ? 'superlocked' : '';

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    let newList = isSelected
      ? selectedPlans.filter((selectedPlan) => selectedPlan !== plan)
      : selectedPlans.concat(plan);
    setSelectedPlans(newList);
  };

  const showActionClassName = selectedPlans.length > 0 ? 'show-actions' : 'actions';

  let topSamplePlanClass = '';
  if (level === 0 && plan.is_dummy) {
    topSamplePlanClass = 'top-sample-plan';
  }

  const readonlyClass = isReadOnly ? 'readonly' : '';

  return (
    <PlanContainer className={`item ${topSamplePlanClass}`} ref={(node) => drag(drop(node))} isHover={isOver}>
      <TableGrid
        className={`${plan.state} ${readonlyClass}`}
        data-id={plan.nano_slug}
        data-dummy={plan.is_dummy}
        data-state={plan.state}
        key={plan.nano_slug}
        onClick={handleItemClick}
        level={level}
      >
        <TitleBlock>
          {plan.children_count > 0 && (
            <ExpandButton className={expandState} onClick={toggleExpand} data-plan={plan.id}>
              <ChevronRightIcon />
            </ExpandButton>
          )}
          {plan.children_count === 0 && (
            <ExpandButton>
              <span>&middot;</span>
            </ExpandButton>
          )}
          <Link to={planRoute} onClick={(e) => e.stopPropagation()}>
            <PlanIconLabel plan={plan} size="small" />
          </Link>
          {planLocked && <KoalaIcon iconName="locked" iconSize="small" className={superlockedClassName} />}
        </TitleBlock>
        <MetaBlock>
          <StatBlock>
            <KoalaCircularProgress data={outcomeProgressData} size="small" />
          </StatBlock>
          <StatBlock>
            <KoalaCircularProgress data={initiativeProgressData} size="small" />
          </StatBlock>
          <StatBlock>
            <KoalaNCSPie
              centerLabel={[Math.round(plan.ncs).toString(), t('shared.ncs.acronym')]}
              data={confidenceData}
              size="small"
            />
          </StatBlock>
          <TimelineBlock>
            {plan.start_at && plan.finish_at && (
              <div>
                <KoalaTextBadge isLowercase={true} variant="neutral-light" size="small">
                  {capitaliseFirst(formatLocale(plan.start_at, 'MMM', i18n))}
                  &nbsp;&#10142;&nbsp;
                  {capitaliseFirst(formatLocale(plan.finish_at, 'MMM yyyy', i18n))}
                </KoalaTextBadge>
              </div>
            )}
          </TimelineBlock>
          <StatusBlock className="status">
            <PlanStatus plan={plan} />
          </StatusBlock>
        </MetaBlock>
        {!isReadOnly && (
          <ActionBlock onClick={(e) => e.stopPropagation()} className={showActionClassName}>
            <KoalaCheckbox checked={isSelected} size="small" edge="square" handleChange={handleCheckbox} />
            <KoalaButton
              className="hide-mobile"
              size="small"
              onClick={() => handleCreateSubPlan(plan.id)}
              appearance="subtle"
            >
              {t('workspacePlans.addSubPlan')}
            </KoalaButton>
            <DropdownMenu
              trigger={<KoalaIconButton iconName="ellipsis" />}
              onSelection={handleMenuSelection}
              items={planOptions(plan)}
            />
          </ActionBlock>
        )}
      </TableGrid>
    </PlanContainer>
  );
}

export default PlanRow;
