import React, { useState, CSSProperties } from 'react';
import FormField from 'components/FormField';

import * as remoteApi from 'api/remote';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import Select from 'react-select';
import theme from 'theme';

const smallStyles = {
  container: (provided: CSSProperties) => ({
    ...provided,
    minWidth: '16rem',
    width: '50%',
    fontSize: '1.2rem',
  }),
  control: (base: CSSProperties) => ({
    ...base,
    minHeight: 30,
    borderColor: theme.colors.inputBorder,
  }),
  dropdownIndicator: (base: CSSProperties) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base: CSSProperties) => ({
    ...base,
    padding: 4,
  }),
  multiValue: (base: CSSProperties) => ({
    ...base,
  }),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    padding: '0px 6px',
  }),
  input: (base: CSSProperties) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};

interface Props {
  spreadsheetId: string;
  sheet: any | undefined;
  setSheet: (e: any) => void;
}

function SheetsList(props: Props) {
  // selected
  const { spreadsheetId, sheet, setSheet } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [sheets, setSheets] = useState<any[]>([]);

  const queryKey = ['googleSheets', workspaceSlug, spreadsheetId];

  useQuery(queryKey, remoteApi.fetchGoogleSpreadsheetDetails, {
    staleTime: 0,
    onSuccess: (response) => {
      const sheets = response.data.sheets.map((sheet: any) => {
        return {
          name: sheet.properties.title,
          id: sheet.properties.title,
        };
      });
      setSheets(sheets);
    },
  });

  // const debounceLoadOptions = useRef(_.throttle((input: string) => fetchData(input), 500));

  return (
    <>
      <FormField>
        <label>Sheet</label>

        <Select
          styles={smallStyles}
          defaultOptions
          value={sheet}
          options={sheets}
          onChange={(option) => setSheet(option)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      </FormField>
    </>
  );
}

export default SheetsList;
