import React from 'react';

const CopyIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.98072 3C7.42631 3 6.97687 3.44944 6.97687 4.00385L6.97687 4.97687L12.0193 4.97687C13.6783 4.97687 15.0231 6.32174 15.0231 7.98073L15.0231 13.0231L15.9961 13.0231C16.5506 13.0231 17 12.5737 17 12.0193L17 4.00385C17 3.44944 16.5506 3 15.9961 3L7.98072 3ZM15.0231 15.0231L15.9961 15.0231C17.6551 15.0231 19 13.6783 19 12.0193L19 4.00385C19 2.34487 17.6551 1 15.9961 1L7.98072 1C6.32174 1 4.97687 2.34487 4.97687 4.00385L4.97687 4.97687L4.00386 4.97687C2.34487 4.97687 1 6.32175 1 7.98073L1 15.9961C1 17.6551 2.34487 19 4.00386 19L12.0193 19C13.6783 19 15.0231 17.6551 15.0231 15.9961L15.0231 15.0231ZM4.00386 6.97687C3.44944 6.97687 3 7.42632 3 7.98073L3 15.9961C3 16.5506 3.44944 17 4.00386 17L12.0193 17C12.5737 17 13.0231 16.5506 13.0231 15.9961L13.0231 7.98073C13.0231 7.42632 12.5737 6.97687 12.0193 6.97687L4.00386 6.97687Z"
      fill="#737373"
    />
  </svg>
);
export default CopyIcon;
