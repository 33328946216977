import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const TitleInputForm = styled.textarea`
  border: none;
  width: 100%;
  overflow: hidden;
  overflow-wrap: break-word;
  outline: none;
  box-shadow: none;
  resize: none;
  padding: 0;
  margin: 0;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  background: transparent;

  &:focus {
    outline: 0;
  }

  &.plan {
    font-size: 2rem;
    font-weight: 600;
  }
`;

interface Props {
  text: string;
  type: string;
  placeholder: string;
  handleChange: any;
  handlePress: any;
  handleBlur?: any;
  entityId?: string;
  isNewInput?: boolean;
  isFocused?: boolean;
}

const TitleInput = (props: Props) => {
  const inputEl: any = useRef(null);
  useEffect(() => {
    if (inputEl && inputEl.current) {
      const textarea = inputEl.current;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }, []);

  // Here we determine if we need to focus on the block based on the selected value
  const { isFocused } = props;
  useEffect(() => {
    if (isFocused) {
      const textarea = inputEl.current;

      // Only focus if the textarea is not yet focused
      if (document.activeElement !== textarea) {
        textarea.focus();
        const value = textarea.value;
        textarea.value = '';
        textarea.value = value;
      }
    }
  }, [isFocused]);

  // When the type of field changes we need to:
  //   - resize the field to fit the proper object
  //   - put the cursor back at the beginning.
  useEffect(() => {
    if (inputEl && inputEl.current && props.isNewInput) {
      const textarea = inputEl.current;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';

      // Move cursor to the beginning
      // Modern browsers
      if (textarea.setSelectionRange) {
        textarea.focus();
        textarea.setSelectionRange(0, 0);

        // IE8 and below
      } else if (textarea.createTextRange) {
        const range = textarea.createTextRange();
        range.collapse(true);
        range.moveEnd('character', 0);
        range.moveStart('character', 0);
        range.select();
      }
    }
  }, [props.type, props.isNewInput]);

  const resize = (e: any) => {
    const textarea = e.currentTarget;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  return (
    <TitleInputForm
      rows={1}
      ref={inputEl}
      placeholder={props.placeholder}
      className={props.type}
      onKeyDown={props.handlePress}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      value={props.text}
      onInput={resize}
      data-entity-id={props.entityId}
      name={props.entityId}
    />
  );
};

export default TitleInput;
