import ConcernedTabbyPng from './assets/concerned_tabby.png';
import ContentTabbyPng from './assets/content_tabby.png';
import EatingTabbyPng from './assets/eating_tabby.png';
import HappyTabbyPng from './assets/happy_tabby.png';
// import SurfingTabbyPng from './assets/surfing_tabby.png';
import SurprisedTabbyPng from './assets/surprised_tabby.png';

const ConcernedTabbyEmoji = {
  id: 'concerned_tabby',
  name: 'Concerned Tabby',
  short_names: ['concerned_tabby'],
  keywords: ['tability'],
  imageUrl: ConcernedTabbyPng,
};

const ContentTabbyEmoji = {
  id: 'content_tabby',
  name: 'Content Tabby',
  short_names: ['content_tabby'],
  keywords: ['tability'],
  imageUrl: ContentTabbyPng,
};

const EatingTabbyEmoji = {
  id: 'eating_tabby',
  name: 'Eating Tabby',
  short_names: ['eating_tabby'],
  keywords: ['tability'],
  imageUrl: EatingTabbyPng,
};

const HappyTabbyEmoji = {
  id: 'happy_tabby',
  name: 'Happy Tabby',
  short_names: ['happy_tabby'],
  keywords: ['tability'],
  imageUrl: HappyTabbyPng,
};

const SurprisedTabbyEmoji = {
  id: 'surprised_tabby',
  name: 'Surprised Tabby',
  short_names: ['surprised_tabby'],
  keywords: ['tability'],
  imageUrl: SurprisedTabbyPng,
};

export const CustomEmojiMapping = {
  [`:${ConcernedTabbyEmoji.id}:`]: ConcernedTabbyEmoji,
  [`:${ContentTabbyEmoji.id}:`]: ContentTabbyEmoji,
  [`:${EatingTabbyEmoji.id}:`]: EatingTabbyEmoji,
  [`:${HappyTabbyEmoji.id}:`]: HappyTabbyEmoji,
  [`:${SurprisedTabbyEmoji.id}:`]: SurprisedTabbyEmoji,
};

export const CustomEmojisList = [
  ConcernedTabbyEmoji,
  ContentTabbyEmoji,
  EatingTabbyEmoji,
  HappyTabbyEmoji,
  SurprisedTabbyEmoji,
];
