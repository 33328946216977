import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as remoteApi from 'api/remote';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';

// Views
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import ShareEmail from './ShareEmail';
import { Initiative, Plan } from 'types';

const Container = styled.div`
  label {
    color: ${theme.colors.N70};
    margin-bottom: ${theme.spacing.x1};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    display: block;
  }
`;

interface Props {
  initiativeId: string;
}

function ShareInitiative(props: Props) {
  const { initiativeId } = props;
  const dispatch = useDispatch();
  const [initiative, setInitiative] = useState<Initiative>();
  const [plan, setPlan] = useState<Plan>();
  const { t } = useTranslation();
  const translationKey = 'modals.share';
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Get the outcome details
  const queryKey = [queryKeys.currentInitiative, initiativeId];
  const staleTime = 0;
  useQuery(queryKey, remoteApi.fetchInitiativeDetails, {
    staleTime,
    onSuccess: (response: any) => {
      const initiative: Initiative = response.data;
      setInitiative(initiative);
      setPlan(initiative.plan);
    },
  });

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  if (!initiative || !plan) {
    return null;
  }

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`, { label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1) })}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Container>
          <ShareEmail initiative={initiative} />
        </Container>
      </ModalContent>
    </ModalGrid>
  );
}

export default ShareInitiative;
