import { colors } from 'koala/shared/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TextButtonProps } from '.';

export const StyledTextButton = styled.button<TextButtonProps>`
  background: transparent;
  border: 1px solid transparent;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.1s ease 0s;
  white-space: nowrap;
  border-radius: 0;
  display: inline-block;

  &:hover {
    text-decoration: underline;
    background: transparent;
  }

  &:active {
    filter: brightness(80%);
  }
  &:focus {
    border: 2px solid ${colors.B50};
    outline-offset: 2px;
  }

  // used when needing to match surrounding styling (i.e. login page)
  &.text-btn--inline {
    color: ${colors.B50};
    margin: 0;
    padding: 0;
    border: 0;
    &:hover {
      filter: brightness(80%);
      text-decoration: underline;
      background: transparent;
    }
  }

  // size
  &.text-btn--small {
    padding: 0;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1;
    border: 1px solid transparent;
  }

  &.text-btn--medium {
    padding: 0;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  // STYLING
  &.text-btn--primary {
    color: ${colors.B50};
    text-decoration: underline;
    &:hover {
      color: #203287;
    }
    &:focus {
      text-decoration: underline;
      background: transparent;
    }
  }
  &.text-btn--secondary {
    color: ${colors.B50};
    text-decoration: none;
    font-weight: 400;
    &:hover {
      color: #203287;
      text-decoration: underline;
    }
    &:focus {
      text-decoration: underline;
      background: transparent;
    }
  }
  &.text-btn--subtle {
    color: ${colors.N70};
    text-decoration: none;
    font-weight: 400;
    &:hover {
      color: ${colors.B70};
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
      background: transparent;
    }
  }
  &.text-btn--disabled {
    color: ${colors.N70};
    cursor: not-allowed !important;
    text-decoration: none;
    &:hover {
      color: ${colors.N70};
      text-decoration: none;
    }
    &:focus {
      color: ${colors.N70};
      text-decoration: none;
      background: transparent;
    }
  }
`;

export const StyledLinkTextButton = styled(StyledTextButton).attrs({ as: 'a' })``;
export const StyledLinkToTextButton = styled(StyledTextButton).attrs({ as: Link })``;
