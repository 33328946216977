import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import { colors } from 'koala/shared/styles';
import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  enable-background: new 0 0 50 50;
`;
const SvgContainer = styled.div<Props>`
  width: 2rem;
  height: 2rem;
  color: ${colors.N100};

  &.loader--xsmall {
    width: 1rem;
    height: 1rem;
  }
  &.loader--small {
    width: 1.5rem;
    height: 1.5rem;
  }
  &.loader--medium {
    width: 2rem;
    height: 2rem;
  }
  &.loader--large {
    width: 2.5rem;
    height: 2.5rem;
  }

  &.loader--xlarge {
    width: 3rem;
    height: 3rem;
  }
  &.loader--customSize {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
  }

  &.loader--custom {
    color: ${(props) => props.appearance};
  }
  &.loader--primary {
    color: ${colors.N0};
  }
  &.loader--secondary {
    color: ${colors.N70};
  }
  &.loader--subtle {
    color: ${colors.B50};
  }
  &.loader--disabled {
    color: ${colors.N50};
  }

  svg {
    vertical-align: top;
    path {
      fill: currentColor;
    }
  }
`;

interface Props {
  size?: (typeof KoalaSizes)[number] | number;
  appearance?: string;
  className?: string;
  style?: React.CSSProperties;
}

const KoalaLoader: React.FunctionComponent<Props> = (props: Props) => {
  const { size, appearance, className, style } = props;

  let loaderColor = colors.N100;
  let loaderClass = className ? className : '';
  if (appearance && KoalaAppearances.some((v) => v === appearance)) {
    loaderClass += ` loader--${appearance}`;
  } else if (appearance) {
    loaderClass += ` loader--custom`;
    loaderColor = appearance;
  }

  if (typeof size === 'number') {
    loaderClass += ` loader--customSize`;
  } else {
    loaderClass += ` loader--${size}`;
  }

  return (
    <SvgContainer className={loaderClass} appearance={loaderColor} size={size}>
      <SVG version="1.1" id="loader-1" x="0px" y="0px" viewBox="0 0 50 50" style={style}>
        <path d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </SVG>
    </SvgContainer>
  );
};

KoalaLoader.defaultProps = {
  size: 'medium',
  appearance: 'secondary',
};

export default KoalaLoader;
