import React from 'react';

const GrabIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="4" r="2" fill="#737373" />
    <circle cx="7" cy="10" r="2" fill="#737373" />
    <circle cx="7" cy="16" r="2" fill="#737373" />
    <circle cx="13" cy="4" r="2" fill="#737373" />
    <circle cx="13" cy="10" r="2" fill="#737373" />
    <circle cx="13" cy="16" r="2" fill="#737373" />
  </svg>
);
export default GrabIcon;
