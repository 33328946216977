import React, { Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useHistory } from 'react-router-dom';

import * as outcomeUtils from 'utils/outcomeUtils';
import * as checkinUtils from 'utils/checkinUtils';

import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';

export const BlockContent = styled.div`
  grid-area: content;
  background: transparent;
  margin: 0;
  position: relative;

  .block-title {
    display: flex;
  }

  h4 {
    font-size: 1.8rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  p {
    margin-top: ${theme.spacing.x1};
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    -webkit-font-smoothing: antialised;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
`;
export const BlockMeta = styled.div`
  grid-area: meta;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
`;

const BlockGrid = styled.div`
  background: rgb(55, 55, 55);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'content'
    'meta';
  align-items: start;
  column-gap: 1.6rem;
  margin-bottom: ${theme.spacing.x2};
  padding: ${theme.spacing.x2};
  border-radius: 8px;

  .meta {
  }

  &:hover,
  &.selected,
  &:focus {
    .block-action {
      opacity: 1;
    }
    cursor: pointer;

    background: #222;
  }

  &.no-hover-bg {
    &:hover {
      background: transparent;
    }
  }

  .block-action {
    opacity: 0;
  }
`;

const ConfidenceCards = styled.div`
  position: relative;
`;

const ColorCard = styled.div`
  display: flex;
  align-items: center;
  color: rgb(148, 148, 148);
  font-size: 2rem;
  font-weight: 600;
  flex: 1;

  &.grey {
    color: ${theme.colors.subtleText};
  }
`;

const Meta = styled.div`
  margin-top: ${theme.spacing.x2};
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

const Progress = styled.div`
  display: flex;
  align-items: center;
  color: rgb(148, 148, 148);
  font-size: 2rem;
  font-weight: 600;
`;

const ProgressBarContainer = styled.div`
  height: 1.2rem;
  width: 10rem;
  background: rgb(21, 21, 21);
  color: #fff;
  border-radius: 4px;
  overflow: hidden;
  margin-left: ${theme.spacing.x1};
`;

const ProgressBar = styled.div<{ confidence: string; prct: number }>`
  height: 100%;
  background: ${theme.colors.subtleText};
  background-color: ${(props) => checkinUtils.confidenceToColor(props.confidence)};
  border-radius: 4px;
  width: ${(props) => props.prct}%;
`;

const ColorBlock = styled.div<{ confidence: string }>`
  background-color: ${(props) => checkinUtils.confidenceToColor(props.confidence)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.8rem;
  font-weight: 500;
  padding: ${theme.spacing.x3};
  min-height: 15rem;
  color: ${(props) => checkinUtils.confidenceToTextColor(props.confidence)};
  margin-right: ${theme.spacing.x1};
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  isSelected: boolean;
}

function OutcomeBlock(props: Props) {
  const history = useHistory();
  const { outcome, isSelected } = props;
  const { membership } = outcome;
  const { t } = useTranslation();

  const blockId = `outcome:${outcome.nano_slug}`;
  const hashPath = `#${blockId}:show`;

  const handleOutcomeClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashPath);
  };
  const currentCheckin = outcome.current_checkin;

  // Calculate the progress prct

  const progressPrct = outcome.outcome_progress_prct * 100;
  const hasTarget = outcomeUtils.hasTarget(outcome);

  const confidence = currentCheckin ? currentCheckin.confidence || 'grey' : 'grey';
  const formattedScore = currentCheckin ? checkinUtils.checkinScore(currentCheckin, outcome, t) : 'Pending';

  const className = isSelected ? 'selected' : '';
  const completedClassName = outcome.completed ? 'completed' : '';

  return (
    <BlockGrid className={`${className} ${completedClassName}`} onClick={handleOutcomeClick}>
      <BlockContent>
        {currentCheckin && (
          <ColorBlock confidence={currentCheckin.confidence}>
            {outcome.completed && <Fragment>&#10003;</Fragment>}
            {outcome.title}
          </ColorBlock>
        )}
        {!currentCheckin && (
          <ColorBlock confidence="">
            {outcome.completed && <Fragment>&#10003;</Fragment>}
            {outcome.title}
          </ColorBlock>
        )}
      </BlockContent>
      <BlockMeta>
        <ConfidenceCards className="confidence-card">
          <Meta>
            <ColorCard className={confidence}>{formattedScore}</ColorCard>
            <Progress>
              {hasTarget && (
                <Fragment>
                  <span>{Math.round(progressPrct)}%</span>
                  <ProgressBarContainer>
                    <ProgressBar prct={progressPrct} confidence={confidence} />
                  </ProgressBarContainer>
                </Fragment>
              )}
            </Progress>
            <KoalaAvatar user={membership ? membership.user : null} size={2.8} />
          </Meta>
        </ConfidenceCards>
      </BlockMeta>
    </BlockGrid>
  );
}

export default React.memo(OutcomeBlock);
