import React from 'react';
import { VictoryChart, VictoryGroup, VictoryVoronoiContainer } from 'victory';
import Chart from 'components/CheckinChart';
import { useHistory } from 'react-router-dom';
import { checkinType, closedInitiativePointType, Domain, DownloadDetails, Outcome, Plan } from 'types';

import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface Props {
  isInsideTimeline: boolean;
  currentData: checkinType[];
  closedInitiativesData: closedInitiativePointType[];
  outcome: Outcome;
  plan: Plan;
  yDomain: Domain;
  downloadDetails?: DownloadDetails;
  width: number;
}
function KpiChart(props: Props) {
  const { isInsideTimeline, closedInitiativesData, currentData, outcome, plan, yDomain, downloadDetails } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const handleClickCheckin = (params: any) => {
    const { datum } = params;
    if (datum.checkin) {
      const checkinPath = `#checkin:${datum.checkin.nano_slug}:show`;
      history.push(checkinPath);
    }
  };
  if (!plan.start_at || !plan.finish_at) {
    return <></>;
  }

  // get target data for grey area
  const startDate: Date = parseISO(plan.start_at);
  const finishDate: Date = parseISO(plan.finish_at);

  const showTodayLine = isInsideTimeline && !downloadDetails;

  const padding = downloadDetails
    ? { top: 60, bottom: 30, left: 30, right: 20 }
    : { top: 30, bottom: 60, left: 50, right: 10 };

  const showYAxis = !downloadDetails || downloadDetails.showMetrics;

  return (
    <VictoryChart
      width={downloadDetails?.width ? downloadDetails.width : props.width}
      height={downloadDetails ? downloadDetails.height - 10 : 270}
      padding={padding}
      domainPadding={{ y: 20 }}
      domain={{ y: [yDomain.min, yDomain.max], x: [startDate, finishDate] }}
      containerComponent={<VictoryVoronoiContainer voronoiBlacklist={['trendPoints', 'todayLine']} />}
    >
      {downloadDetails && downloadDetails.chartTitle && Chart.Title(downloadDetails.chartTitle, 310, 15)}
      {Chart.XAxis(i18n)}
      {showYAxis && Chart.YAxis()}
      {showTodayLine && Chart.TodayLine(yDomain, t)}

      <VictoryGroup
        style={{
          data: { strokeWidth: 4 },
        }}
        scale="time"
      >
        {Chart.CurrentLine(currentData)}
        {!downloadDetails && Chart.CheckinComments(currentData)}

        {downloadDetails && Chart.DownloadScatterPointsPrevious(currentData, outcome, downloadDetails)}
        {downloadDetails && Chart.DownloadScatterPointsLatest(currentData, outcome, downloadDetails, t)}
        {!downloadDetails && Chart.ScatterPoints(currentData, outcome, handleClickCheckin)}
        {!downloadDetails && Chart.ScatterInitiatives(closedInitiativesData, yDomain)}
      </VictoryGroup>
    </VictoryChart>
  );
}

export default KpiChart;
