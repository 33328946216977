import React from 'react';

const EmojiIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 10C7.55228 10 8 10.4477 8 11C8 12.1046 8.89543 13 10 13C11.1046 13 12 12.1046 12 11C12 10.4477 12.4477 10 13 10C13.5523 10 14 10.4477 14 11C14 13.2091 12.2091 15 10 15C7.79086 15 6 13.2091 6 11C6 10.4477 6.44772 10 7 10Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 6C7.55228 6 8 6.44772 8 7V7.88889C8 8.44117 7.55228 8.88889 7 8.88889C6.44772 8.88889 6 8.44117 6 7.88889V7C6 6.44772 6.44772 6 7 6Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 6C13.5523 6 14 6.44772 14 7V7.88889C14 8.44117 13.5523 8.88889 13 8.88889C12.4477 8.88889 12 8.44117 12 7.88889V7C12 6.44772 12.4477 6 13 6Z"
      fill="#737373"
    />
  </svg>
);

export default EmojiIcon;
