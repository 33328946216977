/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { sessionActions, uiActions } from 'state/actions/';
import theme from 'theme';

// Components
import { useLocation } from 'react-router-dom';
import Wordmark from 'components/Wordmark';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaButton from 'koala/components/Button';
import PasswordValidation from 'components/PasswordValidation';
import { Trans, useTranslation } from 'react-i18next';

// Components
const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;

  a.text {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const MetaContainer = styled.div`
  flex: 1;
  background-color: #0a5055;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 800px) {
    flex: unset;
  }
`;

const MetaContent = styled.div`
  padding: 0 12rem 8rem 12rem;
  height: 40rem;
  h1 {
    color: #fff;
    margin-top: 3.2rem;
    font-size: 6rem;
  }
  img {
    width: 15rem;
  }
  @media (max-width: 800px) {
    height: 25rem;
    padding: 4rem 12rem;

    h1 {
      font-size: 4rem;
    }
  }
`;

const Content = styled.div`
  padding: 0 12rem 8rem 12rem;
  height: 40rem;
  width: 100%;
  p {
    font-size: 2rem;
    line-height: 1.4;
  }

  span {
    border-top: 1px solid #d1d1d1;

    display: block;
    padding-top: 1.6rem;
    margin-top: 1.6rem;
    line-height: 1;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  margin-top: 3.2rem;
  flex-direction: column;
  input {
    margin-bottom: 1.6rem;
  }
`;

const ErrorList = styled.ul`
  margin-bottom: 1.6rem;
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 1.6rem;
  }
`;

const FormHelp = styled.div`
  color: ${theme.colors.subtleText};
  a {
    color: ${theme.colors.subtleText};
  }
  margin-top: ${theme.spacing.x3};
  padding-bottom: ${theme.spacing.x3};
`;

const PasswordValidationContainer = styled.div`
  width: 50%;
  margin-bottom: ${theme.spacing.x2};
`;

const SuccessMessage = styled.div`
  margin: ${theme.spacing.x2} 0;
  background: ${theme.colors.successBg};
  padding: ${theme.spacing.x2};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

function SessionNewPassword() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation(undefined, { useSuspense: false });

  // Reset Redux state for the UI
  useEffect(() => {
    dispatch(uiActions.resetPageState('NewPassword'));
  }, [dispatch]);

  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');
  const [allowPasswordSubmit, setAllowPasswordSubmit] = useState(false);

  const uiState = useSelector((state: any) => state.pageNewPassword);

  const { hasErrors, errors, isRequesting } = uiState;

  const handleSaveNewPassword = (e: any) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const reset_password_token = searchParams.get('token');
    const credentials = {
      password_confirmation,
      password,
      reset_password_token,
    };
    dispatch(sessionActions.saveNewPassword(credentials));
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'password':
        setPassword(value);
        break;
      case 'password_confirmation':
        setPasswordConfirmation(value);
        break;
    }
  };

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'password':
        return t('errors.password');
      case 'password_confirmation':
        return t('errors.confirmPassword');
      case 'reset_password_token':
        return t('errors.token');
      case 'email':
        return t('errors.email');
      default:
        return '';
    }
  };

  // Displays the list of templates
  return (
    <Container>
      <Helmet>
        <title>{t('sessionNewPassword.title')} | Tability</title>
      </Helmet>
      <MetaContainer>
        <MetaContent>
          <Wordmark type="light" />
          <h1>
            <Trans i18nKey="sessionNewPassword.largeText" components={{ br: <br /> }} />
          </h1>
        </MetaContent>
      </MetaContainer>
      <ContentContainer>
        <Content>
          <p>
            <b>Tability – {t('sessionNewPassword.login')}</b>
          </p>
          {uiState.hasNewPassword && (
            <SuccessMessage>
              <div>{t('sessionNewPassword.passwordUpdated')}</div>
              <div>
                <KoalaButton isLink to="/login">
                  {t('sessionNewPassword.login')}
                </KoalaButton>
              </div>
            </SuccessMessage>
          )}
          {!uiState.hasNewPassword && (
            <Form onSubmit={handleSaveNewPassword}>
              <input
                type="password"
                name="password"
                className={'small'}
                autoComplete="off"
                placeholder={t('sessionNewPassword.passwordPlaceholder') ?? 'Password'}
                required={true}
                onChange={handleChange}
              />
              <input
                type="password"
                name="password_confirmation"
                className={'small'}
                autoComplete="off"
                placeholder={t('sessionNewPassword.confirmPlaceholder') ?? 'Confirm your password'}
                required={true}
                onChange={handleChange}
              />

              {password && (
                <PasswordValidationContainer>
                  <PasswordValidation password={password} setAllowPasswordSubmit={setAllowPasswordSubmit} />
                </PasswordValidationContainer>
              )}

              {hasErrors && (
                <ErrorList>
                  {Object.keys(errors).map((errorKey: string) => {
                    // Get the first error for the key
                    // NOTE: there might be more! I'm just keeping the UI simpler right now.
                    const error: string = errors[errorKey][0];
                    return (
                      <li>
                        {errorKeyToString(errorKey)} {error.toLowerCase()}
                      </li>
                    );
                  })}
                </ErrorList>
              )}
              <Actions>
                <KoalaButton submit disabled={isRequesting || !allowPasswordSubmit} loading={isRequesting}>
                  {t('sessionNewPassword.resetButton')}
                </KoalaButton>
              </Actions>
            </Form>
          )}
          <FormHelp>
            {t('sessionNewPassword.loginText')}{' '}
            <KoalaTextButton isLink to="/login" isInlineText>
              {t('sessionNewPassword.loginButton')}
            </KoalaTextButton>
          </FormHelp>
        </Content>
      </ContentContainer>
    </Container>
  );
}

export default SessionNewPassword;
