import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Components
import { setRedirectLink } from 'state/actions/sessionActions';

function LoginWithRedirect() {
  const dispatch = useDispatch();
  const redirectLink = useSelector((state: any) => state.session.redirectLink, shallowEqual);

  // cleanup - clears redirect link after use to prevent continuous cycle
  useEffect(() => {
    return () => {
      dispatch(setRedirectLink(null));
    };
  }, [dispatch]);

  // redirect to path if there is one set
  if (redirectLink) {
    return <Redirect to={redirectLink} />;
  }

  return null;
}

export default LoginWithRedirect;
