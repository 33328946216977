import createReducer from './createReducer';
import * as TabilityTypes from '../../types';
import * as actionTypes from '../actions/actionTypes';
import { getRankAfterEntity, moveOutcome, reorderEntities, moveInitiative, sortEntityIdsByRank } from './utils';
import update from 'immutability-helper';

const INITIAL_STATE = {
  plans: {},
  objectives: {},
  outcomes: {},
  outcomeIds: [],
  initiatives: {},
  initiativeIds: [],
  reviewCommentsByBlockId: {},
  resolvedCommentsByBlockId: {},
  plansToObjectivesMapping: {},
  objectivesToOutcomesMapping: {},
  outcomesToInitiativesMapping: {},
  actionToSync: null, // Used to run background jobs
};

/**********************
 * NEW PLAN LOADING
 **********************/

const handlePlanLoading = (state: any, action: any) => {
  const { plan } = action;

  return {
    ...state,
    plans: {
      [plan.id]: plan,
    },
  };
};

const handleObjectivesLoading = (state: any, action: any) => {
  const objectivesResponse = action.objectives;
  const objectives: TabilityTypes.ObjectivesHash = {};
  const plansToObjectivesMapping: any = {};

  objectivesResponse.forEach((o: any) => {
    objectives[o.id] = o;

    if (plansToObjectivesMapping[o.plan_id]) {
      plansToObjectivesMapping[o.plan_id].push(o.id);
    } else {
      plansToObjectivesMapping[o.plan_id] = [o.id];
    }
  });

  return {
    ...state,
    objectives,
    plansToObjectivesMapping,
  };
};

const handleOutcomesLoading = (state: any, action: any) => {
  const outcomesResponse = action.outcomes;
  const outcomes: TabilityTypes.OutcomesHash = {};
  const outcomeIds: string[] = [];
  const objectivesToOutcomesMapping: any = {};

  outcomesResponse.forEach((o: any) => {
    outcomes[o.id] = o;
    outcomeIds.push(o.id);

    if (objectivesToOutcomesMapping[o.objective_id]) {
      objectivesToOutcomesMapping[o.objective_id].push(o.id);
    } else {
      objectivesToOutcomesMapping[o.objective_id] = [o.id];
    }
  });

  return {
    ...state,
    outcomes,
    outcomeIds,
    objectivesToOutcomesMapping,
  };
};

const handleInitiativesLoading = (state: any, action: any) => {
  const initiativesResponse = action.initiatives;
  const initiatives: TabilityTypes.InitiativesHash = {};
  const initiativeIds: string[] = [];
  const outcomesToInitiativesMapping: any = {};

  initiativesResponse.forEach((i: any) => {
    initiatives[i.id] = i;
    initiativeIds.push(i.id);
    if (outcomesToInitiativesMapping[i.outcome_id]) {
      outcomesToInitiativesMapping[i.outcome_id].push(i.id);
    } else {
      outcomesToInitiativesMapping[i.outcome_id] = [i.id];
    }
  });

  return {
    ...state,
    initiatives,
    initiativeIds,
    outcomesToInitiativesMapping,
  };
};

const editorEntitiesReducer = createReducer(INITIAL_STATE)({
  [actionTypes.WORKSPACE_EDITOR_RESET]: () => INITIAL_STATE,
  [actionTypes.WORKSPACE_EDITOR_LOAD_PLAN]: (state: any, action: any) => {
    //return handlePlanDetailsLoading(state, action);
    return handlePlanLoading(state, action);
  },
  [actionTypes.WORKSPACE_EDITOR_LOAD_OBJECTIVES]: (state: any, action: any) => {
    //return handlePlanDetailsLoading(state, action);
    return handleObjectivesLoading(state, action);
  },
  [actionTypes.WORKSPACE_EDITOR_LOAD_OUTCOMES]: (state: any, action: any) => {
    //return handlePlanDetailsLoading(state, action);
    return handleOutcomesLoading(state, action);
  },
  [actionTypes.WORKSPACE_EDITOR_LOAD_INITIATIVES]: (state: any, action: any) => {
    //return handlePlanDetailsLoading(state, action);
    return handleInitiativesLoading(state, action);
  },
  [actionTypes.WORKSPACE_EDITOR_LOAD_REVIEW_COMMENTS]: (state: any, action: any) => {
    const reviewComments = action.reviewComments;
    const reviewCommentsByBlockId: any = {};

    if (reviewComments.length === 0) {
      return {
        ...state,
        reviewCommentsByBlockId: {},
      };
    }

    reviewComments.forEach((reviewComment: any) => {
      if (!reviewCommentsByBlockId[reviewComment.block_id]) {
        reviewCommentsByBlockId[reviewComment.block_id] = [];
      }
      reviewCommentsByBlockId[reviewComment.block_id].push(reviewComment);
    });

    return {
      ...state,
      reviewCommentsByBlockId,
    };
  },
  [actionTypes.WORKSPACE_EDITOR_LOAD_RESOLVED_COMMENTS]: (state: any, action: any) => {
    const resolvedComments = action.resolvedComments;
    const resolvedCommentsByBlockId: any = {};

    if (resolvedComments.length === 0) {
      return {
        ...state,
        resolvedCommentsByBlockId: {},
      };
    }

    resolvedComments.forEach((resolvedComment: any) => {
      if (!resolvedCommentsByBlockId[resolvedComment.block_id]) {
        resolvedCommentsByBlockId[resolvedComment.block_id] = [];
      }
      resolvedCommentsByBlockId[resolvedComment.block_id].push(resolvedComment);
    });

    return {
      ...state,
      resolvedCommentsByBlockId,
    };
  },

  /**********************
   * PLANS REDUCERS
   **********************/

  // Saves a particular plan
  [actionTypes.WORKSPACE_EDITOR_UPDATE_PLAN]: (state: any, action: any) => {
    const { planId, planParams } = action;

    const oldPlan = state.plans[planId];
    const newPlan = update(oldPlan, {
      $merge: {
        ...planParams,
      },
    });

    const actionToSync = `update.plan:${planId}.${Date.now()}`;

    return {
      ...state,
      plans: {
        ...state.plans,
        [planId]: newPlan,
      },
      actionToSync,
    };
  },

  /***************************
   *  OBJECTIVE REDUCERS
   ***************************/

  // Creates a new objective
  [actionTypes.WORKSPACE_EDITOR_CREATE_OBJECTIVE]: (state: any, action: any) => {
    const { objective } = action;

    const objectiveIds = state.plansToObjectivesMapping[objective.plan_id] || [];
    // Get the proper rank if it's not set.
    if (!objective.rank) {
      objective.rank = getRankAfterEntity(action.afterObjective, objectiveIds, state.objectives);
    }

    // Add the objective to the state
    const newObjectives = update(state.objectives, {
      $merge: {
        [objective.id]: objective,
      },
    });

    // Copy the objectives mapping and add the new objective ID
    let objectiveIdsCopy = update(objectiveIds, {
      $push: [objective.id],
    });

    // Re-order copied objectives by rank
    objectiveIdsCopy = sortEntityIdsByRank({
      entityIds: objectiveIdsCopy,
      playgroundEntities: newObjectives,
    });

    const newPlansToObjectivesMapping = update(state.plansToObjectivesMapping, {
      $merge: {
        [objective.plan_id]: objectiveIdsCopy,
      },
    });

    const actionToSync = `create.objective:${objective.id}.${Date.now()}`;

    return {
      ...state,
      plansToObjectivesMapping: newPlansToObjectivesMapping,
      objectives: newObjectives,
      actionToSync: actionToSync,
    };
  },

  // Saves a particular objective
  // Currently we can only update the title here.
  // If you want to update the rank you have to use the MOVE and REORDER reducers
  [actionTypes.WORKSPACE_EDITOR_UPDATE_OBJECTIVE]: (state: any, action: any) => {
    const { objectiveId, objectiveParams } = action;

    const oldObjective = state.objectives[objectiveId];
    const newObjective = update(oldObjective, {
      $merge: {
        ...objectiveParams,
      },
    });

    const actionToSync = `update.objective:${objectiveId}.${Date.now()}`;

    return {
      ...state,
      objectives: {
        ...state.objectives,
        [objectiveId]: newObjective,
      },
      actionToSync: actionToSync,
    };
  },

  // Delete objective
  [actionTypes.WORKSPACE_EDITOR_DELETE_OBJECTIVE]: (state: any, action: any) => {
    const { objectiveId } = action;

    // Removes the objective from the list of objective Ids
    const objective = state.objectives[objectiveId];
    const objectiveIds = state.plansToObjectivesMapping[objective.plan_id] || [];
    const newObjectiveIds = Array.from(objectiveIds); // Make a copy to work on
    const objectiveIndex = newObjectiveIds.indexOf(objective.id);

    newObjectiveIds.splice(objectiveIndex, 1);

    const newPlansToObjectivesMapping = update(state.plansToObjectivesMapping, {
      $merge: {
        [objective.plan_id]: newObjectiveIds,
      },
    });

    const actionToSync = `delete.objective:${objectiveId}.${Date.now()}`;

    return {
      ...state,
      plansToObjectivesMapping: newPlansToObjectivesMapping,
      actionToSync,
    };
  },

  // Reorders an objective
  [actionTypes.WORKSPACE_EDITOR_REORDER_OBJECTIVE]: (state: any, action: any) => {
    const { orderData } = action;

    const newState = reorderEntities(state, orderData);

    const actionToSync = `update.${orderData.draggableId}.${Date.now()}`;
    return {
      ...newState,
      actionToSync,
    };
  },

  /**********************
   * OUTCOMES REDUCERS
   **********************/

  // Creates a new outcome
  [actionTypes.WORKSPACE_EDITOR_CREATE_OUTCOME]: (state: any, action: any) => {
    const { outcome } = action;

    const outcomeIds = state.objectivesToOutcomesMapping[outcome.objective_id] || [];
    // Get the proper rank if it's not set.
    if (!outcome.rank) {
      outcome.rank = getRankAfterEntity(action.afterOutcome, outcomeIds, state.outcomes);
    }

    // Add the outcome to the state
    const newOutcomes = update(state.outcomes, {
      $merge: {
        [outcome.id]: outcome,
      },
    });

    // Copy the objectives mapping and add the new objective ID
    let outcomeIdsCopy = update(outcomeIds, {
      $push: [outcome.id],
    });

    // Re-order copied objectives by rank
    outcomeIdsCopy = sortEntityIdsByRank({
      entityIds: outcomeIdsCopy,
      playgroundEntities: newOutcomes,
    });

    const newObjectivesToOutcomesMapping = update(state.objectivesToOutcomesMapping, {
      $merge: {
        [outcome.objective_id]: outcomeIdsCopy,
      },
    });

    const actionToSync = `create.outcome:${outcome.id}.${Date.now()}`;

    return {
      ...state,
      objectivesToOutcomesMapping: newObjectivesToOutcomesMapping,
      outcomes: newOutcomes,
      actionToSync,
    };
  },

  // Saves a particular outcome
  // Currently we can only update the title here.
  // If you want to update the rank you have to do it somewhere else
  [actionTypes.WORKSPACE_EDITOR_UPDATE_OUTCOME]: (state: any, action: any) => {
    const { outcomeId, outcomeParams } = action;

    const oldOutcome = state.outcomes[outcomeId];
    const newOutcome = update(oldOutcome, {
      $merge: {
        ...outcomeParams,
      },
    });

    const actionToSync = `update.outcome:${outcomeId}.${Date.now()}`;

    return {
      ...state,
      outcomes: {
        ...state.outcomes,
        [outcomeId]: newOutcome,
      },
      actionToSync,
    };
  },

  // Delete outcome
  [actionTypes.WORKSPACE_EDITOR_DELETE_OUTCOME]: (state: any, action: any) => {
    const { outcomeId } = action;

    // Removes the outcome from the list of outcome Ids
    const outcome = state.outcomes[outcomeId];
    const outcomeIds = state.objectivesToOutcomesMapping[outcome.objective_id] || [];
    const newOutcomeIds = Array.from(outcomeIds); // Make a copy to work on
    const outcomeIndex = newOutcomeIds.indexOf(outcome.id);

    newOutcomeIds.splice(outcomeIndex, 1);

    const newObjectivesToOutcomesMapping = update(state.objectivesToOutcomesMapping, {
      $merge: {
        [outcome.objective_id]: newOutcomeIds,
      },
    });

    const actionToSync = `delete.outcome:${outcomeId}.${Date.now()}`;

    return {
      ...state,
      actionToSync,
      objectivesToOutcomesMapping: newObjectivesToOutcomesMapping,
    };
  },

  // Reorders a outcome
  [actionTypes.WORKSPACE_EDITOR_REORDER_OUTCOME]: (state: any, action: any) => {
    const { orderData } = action;

    const newState = reorderEntities(state, orderData);

    const actionToSync = `update.${orderData.draggableId}.${Date.now()}`;

    return {
      ...newState,
      actionToSync,
    };
  },

  [actionTypes.WORKSPACE_EDITOR_MOVE_OUTCOME]: (state: any, action: any) => {
    const { orderData } = action;

    const newState = moveOutcome(state, orderData);

    const actionToSync = `update.${orderData.draggableId}.${Date.now()}`;

    return {
      ...newState,
      actionToSync,
    };
  },

  /**********************
   * TASKS REDUCERS
   **********************/

  // Creates a new initiative
  [actionTypes.WORKSPACE_EDITOR_CREATE_TASK]: (state: any, action: any) => {
    const { initiative } = action;

    const initiativeIds = state.outcomesToInitiativesMapping[initiative.outcome_id] || [];
    // Get the proper rank if it's not set.
    if (!initiative.rank) {
      initiative.rank = getRankAfterEntity(action.afterInitiative, initiativeIds, state.initiatives);
      const outcome = state.outcomes[initiative.outcome_id];
      initiative.column_rank = `${outcome.rank}${initiative.rank.replace('~', '')}`;
    }

    // Add the initiative to the state
    const newInitiatives = update(state.initiatives, {
      $merge: {
        [initiative.id]: initiative,
      },
    });

    // Copy the objectives mapping and add the new objective ID
    let initiativeIdsCopy = update(initiativeIds, {
      $push: [initiative.id],
    });

    // Re-order copied objectives by rank
    initiativeIdsCopy = sortEntityIdsByRank({
      entityIds: initiativeIdsCopy,
      playgroundEntities: newInitiatives,
    });

    const newOutcomesToInitiativesMapping = update(state.outcomesToInitiativesMapping, {
      $merge: {
        [initiative.outcome_id]: initiativeIdsCopy,
      },
    });

    const actionToSync = `create.initiative:${initiative.id}.${Date.now()}`;

    return {
      ...state,
      outcomesToInitiativesMapping: newOutcomesToInitiativesMapping,
      initiatives: newInitiatives,
      actionToSync,
    };
  },

  // Saves a particular initiative
  // Currently we can only update the title and state here.
  // If you want to update the rank you have to do it somewhere else
  [actionTypes.WORKSPACE_EDITOR_UPDATE_TASK]: (state: any, action: any) => {
    const { initiativeId, initiativeParams } = action;

    const oldInitiative = state.initiatives[initiativeId];
    const newInitiative = update(oldInitiative, {
      $merge: {
        ...initiativeParams,
      },
    });

    const actionToSync = `update.initiative:${initiativeId}.${Date.now()}`;

    return {
      ...state,
      initiatives: {
        ...state.initiatives,
        [initiativeId]: newInitiative,
      },
      actionToSync,
    };
  },

  // Delete initiative
  [actionTypes.WORKSPACE_EDITOR_DELETE_TASK]: (state: any, action: any) => {
    const { initiativeId } = action;

    // Removes the initiative from the list of initiative Ids
    const initiative = state.initiatives[initiativeId];
    const initiativeIds = state.outcomesToInitiativesMapping[initiative.outcome_id] || [];
    const newInitiativeIds = Array.from(initiativeIds); // Make a copy to work on
    const initiativeIndex = newInitiativeIds.indexOf(initiative.id);

    newInitiativeIds.splice(initiativeIndex, 1);

    const newOutcomesToInitiativesMapping = update(state.outcomesToInitiativesMapping, {
      $merge: {
        [initiative.outcome_id]: newInitiativeIds,
      },
    });

    // Update the list of global initiative Ids

    const newAllInitiativeIds = Array.from(state.initiativeIds);
    const allInitiativeIndex = newAllInitiativeIds.indexOf(initiative.id);

    newAllInitiativeIds.splice(allInitiativeIndex, 1);

    const newState = update(state, {
      initiativeIds: {
        $set: newAllInitiativeIds,
      },
    });

    const actionToSync = `delete.initiative:${initiativeId}.${Date.now()}`;

    return {
      ...newState,
      outcomesToInitiativesMapping: newOutcomesToInitiativesMapping,
      actionToSync,
    };
  },

  // Reorders a outcome
  [actionTypes.WORKSPACE_EDITOR_REORDER_TASK]: (state: any, action: any) => {
    const { orderData } = action;

    const newState = reorderEntities(state, orderData);

    const actionToSync = `update.${orderData.draggableId}.${Date.now()}`;

    return {
      ...newState,
      actionToSync,
    };
  },

  [actionTypes.WORKSPACE_EDITOR_MOVE_TASK]: (state: any, action: any) => {
    const { orderData } = action;

    const newState = moveInitiative(state, orderData);

    const actionToSync = `update.${orderData.draggableId}.${Date.now()}`;

    return {
      ...newState,
      actionToSync,
    };
  },
});

export default editorEntitiesReducer;
