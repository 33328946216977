import React, { Fragment } from 'react';
import * as TabilityTypes from 'types';
import { Helmet } from 'react-helmet';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import ContentTitle from 'components/ContentTitle';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import AuditTrail from './AuditTrail';
import { useTranslation } from 'react-i18next';

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingsAudit(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsAudit.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsAudit.title')}</h1>
          </ContentTitle>
          <AuditTrail />
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsAudit;
