import * as actionTypes from './actionTypes';

export const resetTemplate = () => ({
  type: actionTypes.YML_EDITOR_RESET,
});

export const loadTemplate = (template: any) => ({
  type: actionTypes.YML_EDITOR_LOAD_TEMPLATE,
  template,
});

export const reorderItems = (result: any) => ({
  type: actionTypes.YML_EDITOR_REORDER_ITEMS,
  result,
});

export const updateItem = (item: any, itemType: string) => ({
  type: actionTypes.YML_EDITOR_UPDATE_ITEM,
  item,
  itemType,
});

export const addItem = () => ({
  type: actionTypes.YML_EDITOR_ADD_ITEM,
});

export const addChildItem = () => ({
  type: actionTypes.YML_EDITOR_ADD_CHILD_ITEM,
});

export const removeItem = (blockId: string) => ({
  type: actionTypes.YML_EDITOR_REMOVE_ITEM,
  blockId,
});

export const setCursor = (cursor: string) => ({
  type: actionTypes.YML_EDITOR_SET_CURSOR,
  cursor,
});

export const moveCursorUp = () => ({
  type: actionTypes.YML_EDITOR_MOVE_CURSOR_UP,
});

export const moveCursorDown = () => ({
  type: actionTypes.YML_EDITOR_MOVE_CURSOR_DOWN,
});
