/**
  YML Editor

  This component is used to create and edit templates managed as YML file. Here's how it works:
  1. First, it loads the yml data and create the proper data structures
  2. Second it presents all the elemets as a editable UI
 */
import React, { useEffect, useRef } from 'react';
import ymlUtils from 'utils/ymlUtils';
import { useSelector } from 'react-redux';

interface YMLSyncProps {
  handleChange(ymlChanged: string): void;
}

function YMLSync(props: YMLSyncProps) {
  const { handleChange } = props;
  const initialRender = useRef(true);

  const data = useSelector((state: any) => state.ymlEditor.data);
  const editorData = JSON.parse(JSON.stringify(data));

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      const ymlPlan = ymlUtils.editorDataToYML(editorData);
      handleChange(ymlPlan);
    }
  }, [handleChange, editorData]);

  return null;
}

export default React.memo(YMLSync);
