import KoalaTextBadge from 'koala/components/TextBadge';
import React from 'react';
import { ContentAction } from './NewsItem';
import { Trans } from 'react-i18next';
interface Props {
  event: any;
}

function Block(props: Props) {
  const { event } = props;

  const { membership, source, event_meta } = event;

  if (!membership) {
    return null;
  }

  const { user } = membership;
  const name = user.fullname || user.email;

  let eventData: any = [];
  try {
    const data = JSON.parse(event_meta);
    Object.keys(data).forEach(function (key) {
      let value: any = data[key];
      if (key === 'updated_at') {
        return;
      }
      const firstValue: string = value[0] ?? '';
      const secondValue: string = value[1] ?? '';

      eventData.push(
        <p>
          <Trans
            i18nKey="newsfeed.workspaceChanged"
            values={{ key, firstValue, secondValue }}
            components={{ b: <b />, label: <KoalaTextBadge variant="neutral-light">value</KoalaTextBadge> }}
          ></Trans>
        </p>,
      );
    });
  } catch (e) {}
  return (
    <>
      <ContentAction>
        <Trans i18nKey="newsfeed.userUpdated" values={{ name, title: source.title }} components={{ b: <b /> }}></Trans>
        {eventData.map((data: any) => data)}
      </ContentAction>
    </>
  );
}

export default React.memo(Block);
