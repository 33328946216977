import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useMutation } from 'react-query';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import KoalaButton from 'koala/components/Button';
import { Trans, useTranslation } from 'react-i18next';

const Block = styled.div`
  margin-bottom: ${theme.spacing.x4};

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const PricingTable = styled.div`
  margin: ${theme.spacing.x4} 0;
`;

const PricingGridLine = styled.div`
  width: 100%;
  &.pricing-header {
    > div {
      display: grid;
      grid-template-columns: 3fr 2fr 1fr;
      border-bottom: 1px solid ${theme.colors.N10};
      grid-template-rows: auto;
      align-items: center;
      div {
        padding: ${theme.spacing.x2};
      }
    }
    .pricing-plan-name {
      font-size: 2rem;
      font-weight: 600;
    }
    .pricing-plan-price {
      big {
        font-size: 2.4rem;
        font-weight: 600;
      }
      small {
        display: inline-block;
        margin-left: ${theme.spacing.half};
      }
    }
    .pricing-plan-upgrade {
      padding: ${theme.spacing.x2} 0;
      display: flex;
      justify-content: center;
      button {
        flex: 1;
        justify-content: center;
      }
    }
  }

  &.pricing-grid-header {
    font-weight: 800;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-top: 1px solid ${theme.colors.N10};
  }
`;

declare global {
  interface Window {
    Stripe: any;
    Rewardful: any;
  }
}

interface Props {
  workspace: TabilityTypes.Workspace;
}

function PricingVersion(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();
  // Workspace mutations
  const [createWorkspaceCheckoutSessionMutation, { isLoading: isLoadingCheckout }]: [any, any] = useMutation(
    remoteApi.createWorkspaceCheckoutSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_id } = data;

        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        stripe
          .redirectToCheckout({
            sessionId: session_id,
          })
          .then((result: any) => {
            if (result.error) {
              alert(result.error.message);
            }
          });
      },
    },
  );

  // Workspace mutations
  const [createWorkspacePortalSessionMutation, { isLoading: isLoadingPortal }]: [any, any] = useMutation(
    remoteApi.createWorkspacePortalSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_url } = data;

        window.location.href = session_url;
      },
    },
  );

  const handleSubscribe = (price_id: string) => {
    const params = {
      workspaceSlug: workspace.slug,
      body: {
        price_id,
      },
    };
    createWorkspaceCheckoutSessionMutation(params);
  };

  const handleShowPortal = () => {
    const params = {
      workspaceSlug: workspace.slug,
    };
    createWorkspacePortalSessionMutation(params);
  };

  const hasPlan = workspaceUtils.hasSubscription(workspace);

  const disablePersonal = workspace.memberships_count > 1;
  const disable10seats = workspace.memberships_count > 10;
  const disable50seats = workspace.memberships_count > 50;
  const disable200seats = workspace.memberships_count > 200;
  return (
    <Fragment>
      <Helmet>
        <script src="https://js.stripe.com/v3/" />
        <title>
          {workspace.name} | {t('workspaceSettingsBilling.plans.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsBilling.plans.title')}</h1>
          </ContentTitle>
          <p>
            <Trans
              i18nKey="workspaceSettingsBilling.v3.currentPlan"
              values={{
                plan: workspaceUtils.billingSubscriptionToString(workspace),
                count: workspace.memberships_count,
              }}
              components={{ b: <b /> }}
            />
          </p>
          <p>{t('workspaceSettingsBilling.plans.contactEnterprise')}</p>
          {hasPlan && (
            <p>
              <KoalaButton
                onClick={() => handleShowPortal()}
                disabled={isLoadingPortal}
                loading={isLoadingPortal}
                appearance="subtle"
              >
                {t('workspaceSettingsBilling.manageSubscription')}
              </KoalaButton>
            </p>
          )}
          <p>
            <h2>{t('workspaceSettingsBilling.v3.header')}</h2>
          </p>
          <p>
            &bull; {t('workspaceSettingsBilling.v3.point1')}
            <br />
            &bull; {t('workspaceSettingsBilling.v3.point2', { limit: workspace.billing_checkins_limit })}
            <br />
            &bull; {t('workspaceSettingsBilling.v3.point3')}
          </p>
          <PricingTable>
            <PricingGridLine className="pricing-header">
              <div>
                <div className="pricing-plan-name">{t('workspaceSettingsBilling.v3.personal')}</div>
                <div className="pricing-plan-price">
                  <big>{t('workspaceSettingsBilling.plans.price', { price: '9' })}</big>
                  <small>{t('workspaceSettingsBilling.v3.currency')}</small>
                </div>
                {!hasPlan && (
                  <div className="pricing-plan-upgrade">
                    <KoalaButton
                      onClick={() => handleSubscribe('tability2-personal')}
                      disabled={isLoadingCheckout || disablePersonal}
                      loading={isLoadingCheckout}
                    >
                      {t('workspaceSettingsBilling.upgradeButton')}
                    </KoalaButton>
                  </div>
                )}
              </div>
              <div>
                <div className="pricing-plan-name">{t('workspaceSettingsBilling.v3.seats', { count: 10 })}</div>
                <div className="pricing-plan-price">
                  <big>{t('workspaceSettingsBilling.plans.price', { price: '39' })}</big>
                  <small>{t('workspaceSettingsBilling.v3.currency')}</small>
                </div>
                {!hasPlan && (
                  <div className="pricing-plan-upgrade">
                    <KoalaButton
                      onClick={() => handleSubscribe('tability2-10seats')}
                      disabled={isLoadingCheckout || disable10seats}
                      loading={isLoadingCheckout}
                    >
                      {t('workspaceSettingsBilling.upgradeButton')}
                    </KoalaButton>
                  </div>
                )}
              </div>
              <div>
                <div className="pricing-plan-name">{t('workspaceSettingsBilling.v3.seats', { count: 50 })}</div>
                <div className="pricing-plan-price">
                  <big>{t('workspaceSettingsBilling.plans.price', { price: '99' })}</big>
                  <small>{t('workspaceSettingsBilling.v3.currency')}</small>
                </div>
                {!hasPlan && (
                  <div className="pricing-plan-upgrade">
                    <KoalaButton
                      onClick={() => handleSubscribe('tability2-50seats')}
                      disabled={isLoadingCheckout || disable50seats}
                      loading={isLoadingCheckout}
                    >
                      {t('workspaceSettingsBilling.upgradeButton')}
                    </KoalaButton>
                  </div>
                )}
              </div>
              <div>
                <div className="pricing-plan-name">{t('workspaceSettingsBilling.v3.seats', { count: 200 })}</div>
                <div className="pricing-plan-price">
                  <big>{t('workspaceSettingsBilling.plans.price', { price: '399' })}</big>
                  <small>{t('workspaceSettingsBilling.v3.currency')}</small>
                </div>
                {!hasPlan && (
                  <div className="pricing-plan-upgrade">
                    <KoalaButton
                      onClick={() => handleSubscribe('tability2-200seats')}
                      disabled={isLoadingCheckout || disable200seats}
                      loading={isLoadingCheckout}
                    >
                      {t('workspaceSettingsBilling.upgradeButton')}
                    </KoalaButton>
                  </div>
                )}
              </div>
              <div>
                <div className="pricing-plan-name">{t('workspaceSettingsBilling.v3.seats', { count: 500 })}</div>
                <div className="pricing-plan-price">
                  <big>{t('workspaceSettingsBilling.plans.price', { price: '999' })}</big>
                  <small>{t('workspaceSettingsBilling.v3.currency')}</small>
                </div>
                {!hasPlan && (
                  <div className="pricing-plan-upgrade">
                    <KoalaButton
                      onClick={() => handleSubscribe('tability2-500seats')}
                      disabled={isLoadingCheckout}
                      loading={isLoadingCheckout}
                    >
                      {t('workspaceSettingsBilling.upgradeButton')}
                    </KoalaButton>
                  </div>
                )}
              </div>
              <div>
                <div className="pricing-plan-name">{t('workspaceSettingsBilling.v3.needMore')}</div>
                <div className="pricing-plan-price" />
                <div>
                  <KoalaButton appearance="secondary" onClick={() => window.Intercom('show')}>
                    {t('workspaceSettingsBilling.v3.contactUs')}
                  </KoalaButton>
                </div>
              </div>
              <div>
                <div className="pricing-plan-name">{t('workspaceSettingsBilling.v3.standups')}</div>
                <div className="pricing-plan-price">
                  <big>{t('workspaceSettingsBilling.v3.standupPrice', { price: '2' })}</big>
                  <small>{t('workspaceSettingsBilling.v3.currency')}</small>
                </div>
                <div>
                  <KoalaButton appearance="secondary" onClick={() => window.Intercom('show')}>
                    {t('workspaceSettingsBilling.v3.contactUs')}
                  </KoalaButton>
                </div>
              </div>
            </PricingGridLine>
          </PricingTable>
          <Block>
            <KoalaButton
              href="https://www.tability.io/pricing"
              target="_blank"
              rel="noopened noreferrer"
              appearance="subtle"
            >
              {t('workspaceSettingsBilling.compareButton')}
            </KoalaButton>
          </Block>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default PricingVersion;
