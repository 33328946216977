/**
 * Section component
 */
import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { nanoid } from 'nanoid';
import * as TabilityTypes from 'types';
import styled from 'styled-components';

// Actions
import {
  editorCreateOutcome,
  editorCreateInitiative,
  editorSetDisplayNewBlockForm,
  editorSelectBlockBelow,
} from 'state/actions/workspaceEditorActions';

// UI Components
import BlockGrid, { BlockGutter, BlockContent, BlockMeta } from './BlockGrid';
import BlockLabel from './BlockLabel';
import InitiativeLabel from './InitiativeLabel';
import TitleInput from './TitleInput';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaButton from 'koala/components/Button';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useTranslation } from 'react-i18next';

const InitiativeContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;

  .block-title {
    flex: 1;
  }
`;

interface Props {
  selectedOutcomeId: string;
  defaultFormEntityType: string;
}

function NewOutcomeForm(props: Props) {
  const dispatch = useDispatch();
  const { defaultFormEntityType, selectedOutcomeId } = props;
  const { t } = useTranslation();

  const selectedOutcome: TabilityTypes.Outcome = useSelector(
    (state: any) => state.editorEntities.outcomes[selectedOutcomeId],
  );
  const currentWorkspaceId = useSelector((state: any) => state.editorUI.currentWorkspaceId);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [title, setTitle] = useState('');
  const [entityType, setEntityType] = useState(defaultFormEntityType);

  // Reset the entity type if it changed from the parent.
  // This happens if you use the objective menu to show the objective or outcome form
  useEffect(() => {
    setEntityType(defaultFormEntityType);
  }, [defaultFormEntityType]);

  // Handles changes to the title
  const handleChange = (e: any) => {
    e.preventDefault();
    let newTitle = e.target.value || '';
    let newEntityType = entityType;
    const hasChangedEntityType = false; // Flag used for checks

    newTitle = newTitle.replace(/(\r\n|\n|\r)/gm, ''); // Remove new lines

    // Dashes change type as outcome
    if (!hasChangedEntityType && newTitle.substring(0, 1) === '-' && entityType !== 'outcome') {
      newTitle = newTitle.substring(1); // Remove the dash
      newEntityType = 'outcome';
    }

    // Brackets change type as initiative
    if (!hasChangedEntityType && newTitle.substring(0, 2) === '[]' && entityType !== 'initiative') {
      newTitle = newTitle.substring(2);
      newEntityType = 'initiative';
    }

    // Update entity type if it changed
    if (entityType !== newEntityType) {
      setEntityType(newEntityType);
    }

    setTitle(newTitle);
  };

  const addOutcome = (title: string) => {
    const newOutcome = {
      id: uuidv4(),
      nano_slug: nanoid(12),
      workspace_id: currentWorkspaceId,
      objective_id: selectedOutcome.objective_id,
      title,
      rank: '',
      from: 0,
      to: 100,
      score_format: '_number_%',
      membership_id: null,
    };
    dispatch(editorCreateOutcome(newOutcome, selectedOutcome));
  };

  const addInitiative = (title: string) => {
    const newInitiative = {
      id: uuidv4(),
      nano_slug: nanoid(12),
      workspace_id: currentWorkspaceId,
      outcome_id: selectedOutcome.id,
      title,
      rank: '',
      state: 'open',
    };
    dispatch(editorCreateInitiative(newInitiative));
  };

  const handleBlur = (e: any) => {
    if (title.length > 0) {
      if (entityType === 'outcome') {
        addOutcome(title);
      }
      if (entityType === 'initiative') {
        addInitiative(title);
      }
      dispatch(editorSetDisplayNewBlockForm(false));
    }
  };

  const handlePress = (e: any) => {
    if (e.keyCode === 27) {
      // Esc key
      e.preventDefault();
      if (title.length > 0) {
        if (entityType === 'outcome') {
          addOutcome(title);
        }
        if (entityType === 'initiative') {
          addInitiative(title);
        }
      }
      dispatch(editorSetDisplayNewBlockForm(false));
    }

    // Disable field if we press delete and no text is entered
    if (e.keyCode === 8) {
      // Delete key
      const value = e.target.value;
      if (!value) {
        e.preventDefault();
        dispatch(editorSetDisplayNewBlockForm(false));
      }
    }

    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault();
      if (title.length > 0) {
        if (entityType === 'outcome') {
          addOutcome(title);
        }
        if (entityType === 'initiative') {
          addInitiative(title);
        }
      } else {
        dispatch(editorSetDisplayNewBlockForm(false));
      }
    }

    if (e.keyCode === 38) {
      // Arrow up
      e.preventDefault();
      if (title.length > 0) {
        if (entityType === 'outcome') {
          addOutcome(title);
        }
        if (entityType === 'initiative') {
          addInitiative(title);
        }
      }

      dispatch(editorSetDisplayNewBlockForm(false));
    }

    if (e.keyCode === 40) {
      // Arrow down
      e.preventDefault();
      if (title.length > 0) {
        if (entityType === 'outcome') {
          addOutcome(title);
        }
        if (entityType === 'initiative') {
          addInitiative(title);
        }
      }

      dispatch(editorSelectBlockBelow());
    }

    if (e.keyCode === 9 && !e.shiftKey && false) {
      // Tab
      e.preventDefault();
      setEntityType('initiative');
    }

    if (e.shiftKey && e.keyCode === 9) {
      // shift Tab
      e.preventDefault();
      setEntityType('outcome');
    }
  };

  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();
  const initiativesLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase();
  const initiativeLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();
  let placeholder =
    t(`workspacePlan.write.addAnotherMetricPlaceholder`, { outcome: outcomeLabel, initiative: initiativesLabel }) ??
    `Do you have another measurable ${outcomeLabel}? (Or press tab to start adding ${initiativesLabel})`;
  const labelTitle = entityType === 'outcome' ? '#' : `\u00a0\u00a0`;

  if (entityType === 'initiative') {
    placeholder =
      t('workspacePlan.write.initiativePlaceholder', { label: initiativeLabel }) ??
      `What ${initiativeLabel} can help you make progress?`;
  }
  const generateInitiatives = () => {
    // const action = `outcome:${selectedOutcome.id}:populate.ai`;
    // dispatch(setGlobalModalContent(action));
    dispatch(setGlobalModalContent(`outcome:${selectedOutcome.id}:chat.ai.initiatives`));
  };

  return (
    <BlockGrid className={`${entityType}`}>
      <BlockGutter>
        {entityType === 'outcome' && <BlockLabel className={`${entityType} new`}>{labelTitle}</BlockLabel>}
      </BlockGutter>
      {entityType === 'outcome' && (
        <BlockContent className={entityType}>
          <TitleInput
            text={title}
            type={entityType}
            isNewInput={true}
            placeholder={placeholder}
            handleChange={handleChange}
            handlePress={handlePress}
            handleBlur={handleBlur}
          />
          <BlockMeta></BlockMeta>
        </BlockContent>
      )}
      {entityType === 'initiative' && (
        <>
          <BlockContent className={entityType}>
            <InitiativeContentWrapper>
              <InitiativeLabel>&#10003;</InitiativeLabel>
              <TitleInput
                text={title}
                type={entityType}
                isNewInput={true}
                placeholder={placeholder}
                handleChange={handleChange}
                handlePress={handlePress}
                handleBlur={handleBlur}
              />
            </InitiativeContentWrapper>
          </BlockContent>

          <BlockMeta>
            <KoalaButton appearance="subtle" size="small" onClick={generateInitiatives}>
              {t('workspacePlan.write.generateItem', { label: initiativesLabel })}
            </KoalaButton>
          </BlockMeta>
        </>
      )}
    </BlockGrid>
  );
}

export default NewOutcomeForm;
