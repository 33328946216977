import React, { useState } from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';
import theme from 'theme';

import TagPicker from './TagPicker';
import TagList from './TagList';

const InlineContainer = styled.div`
  label {
    color: ${theme.colors.subtleText};
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.2rem;
    display: block;
  }
`;

// Components
interface Props {
  plan: TabilityTypes.Plan;
  disabled?: boolean;
}

function TagPickerOutcome(props: Props) {
  const { plan, disabled } = props;
  const [displayPicker, setDisplayPicker] = useState(false);

  if (displayPicker) {
    return (
      <InlineContainer>
        <TagPicker plan={plan} setDisplayPicker={setDisplayPicker} />
      </InlineContainer>
    );
  }
  return (
    <InlineContainer>
      <TagList plan={plan} disabled={disabled} setDisplayPicker={setDisplayPicker} />
    </InlineContainer>
  );
}

export default React.memo(TagPickerOutcome);
