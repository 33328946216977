import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import * as workspaceUtils from 'utils/workspaceUtils';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// API
import * as remoteApi from 'api/remote';
import { useTranslation } from 'react-i18next';

// Components
import PlanPermissionSync from 'components/PlanPermissionSync';
import { PanelGrid, PanelHeader, PanelContent, PanelActions } from 'components/GlobalPanel';
import Loader from 'components/Loader';
import UpgradePanel from 'components/UpgradePanel';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Outcome, Plan, Workspace } from 'types';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import PlanIconLabel from 'components/PlanIconLabel';
import OutcomeSelection from './OutcomeSelection';
import ObjectiveSelection from './ObjectiveSelection';
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaIcon from 'koala/components/Icons';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};

  h4 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const OptionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const OptionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  > div {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x1};

    button {
      width: 50%;
      justify-content: center;
    }
  }
`;

const SelectHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x2};
`;

const PlanWithParent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
  .parent {
    color: ${theme.colors.subtleText};
    font-size: 1.2rem;
  }
`;

interface Props {
  outcomeId: string;
}

function EditOutcomePanel(props: Props) {
  const { outcomeId } = props;
  const { t } = useTranslation(undefined, { useSuspense: false });
  const translationKey = 'modals.addDependency';
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const [selectedPlan, setSelectedPlan] = useState<Plan | null>();
  const [selectedOutcome, setSelectedOutcome] = useState<KoalaSelectOption | null>(null);
  const [selectedObjective, setSelectedObjective] = useState<KoalaSelectOption | null>(null);
  const [relationshipType, setRelationshipType] = useState<string | null>(null);
  const [relationshipObjectType, setRelationshipObjectType] = useState<string | null>(null);

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const hasStarterSubscription =
    workspaceUtils.hasStarterSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;
  const [hasEditPermission, setHasEditPermission] = useState(false);

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = 60 * 1000 * 5;

  // Functions that creates the relationship and then closes the modal
  const [createRelationshipForOutcomeMutation, { isLoading }]: any = useMutation(
    remoteApi.createRelationshipForOutcome,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.relationships);
        queryCache.invalidateQueries([queryKeys.outcomes, 'search']); // Trick to force refrech of insights alignment
        dispatch(setGlobalModalContent(''));
      },
    },
  );

  // Functions that creates the relationship and then closes the modal
  const [createRelationshipForObjectiveMutation, { isLoading: isCreatingObjectiveRelationship }]: any = useMutation(
    remoteApi.createRelationshipForObjective,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.relationships);
        dispatch(setGlobalModalContent(''));
      },
    },
  );

  // Get the outcome details
  const { data: outcomeResponse } = useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
  });

  const outcome: Outcome = outcomeResponse ? outcomeResponse.data : null;

  const handleSave = () => {
    if (relationshipObjectType === 'outcome') {
      handleSaveOutcome();
    } else {
      handleSaveObjective();
    }
  };

  const handleSaveOutcome = () => {
    if (!selectedOutcome) {
      return;
    }

    let relationship_type, source_id, source_type, target_id, target_type;
    source_type = 'Outcome';
    if (relationshipType === 'add_parent') {
      source_id = outcome.id;
      target_id = selectedOutcome.meta.id;
      target_type = 'Outcome';
      relationship_type = 'contributes_to';
    }

    if (relationshipType === 'add_related') {
      source_id = outcome.id;
      target_id = selectedOutcome.meta.id;
      target_type = 'Outcome';
      relationship_type = 'relates_to';
    }

    if (relationshipType === 'add_dependency') {
      source_id = selectedOutcome.meta.id;
      target_id = outcome.id;
      target_type = 'Outcome';
      relationship_type = 'contributes_to';
    }
    const relationship = {
      source_id,
      source_type,
      target_id,
      target_type,
      relationship_type,
    };

    createRelationshipForOutcomeMutation({
      outcomeId: source_id,
      relationship,
    });
  };

  const handleSaveObjective = () => {
    if (!selectedObjective) {
      return;
    }

    let relationship_type, source_id, source_type, target_id, target_type;
    source_type = 'Outcome';
    if (relationshipType === 'add_parent') {
      source_id = outcome.id;
      target_id = selectedObjective.meta.id;
      target_type = 'Objective';
      relationship_type = 'contributes_to';
    }

    if (relationshipType === 'add_related') {
      source_id = outcome.id;
      target_id = selectedObjective.meta.id;
      target_type = 'Objective';
      relationship_type = 'relates_to';
    }

    if (relationshipType === 'add_dependency') {
      source_id = selectedObjective.meta.id;
      source_type = 'Objective';
      target_id = outcome.id;
      target_type = 'Outcome';
      relationship_type = 'contributes_to';
    }
    const relationship = {
      source_id,
      source_type,
      target_id,
      target_type,
      relationship_type,
    };

    if (source_type === 'Outcome') {
      createRelationshipForOutcomeMutation({
        outcomeId: source_id,
        relationship,
      });
    } else if (source_type === 'Objective') {
      createRelationshipForObjectiveMutation({
        objectiveId: source_id,
        relationship,
      });
    }
  };

  const handleCancel = (e: Event) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };
  // Now we can display the app with the Chrome
  if (!outcome) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  // If the user doesn't have a starter subscription, show the upgrade panel
  if (!hasStarterSubscription) {
    return (
      <PanelGrid>
        <PanelHeader>
          <Header>
            <h2>{t(`${translationKey}.title`)}</h2>
            <KoalaIconButton onClick={handleCancel} iconName="close" />
          </Header>
        </PanelHeader>
        <PanelContent>
          <UpgradePanel
            title={t(`${translationKey}.upgradePanelTitle`, {
              outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
            })}
            description={t(`${translationKey}.upgradePanelDescription`, {
              outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
            })}
          />
        </PanelContent>
      </PanelGrid>
    );
  }

  const { plan } = outcome;

  // If the user doesn't have edit permission, show the no permission warning
  if (!hasEditPermission) {
    return (
      <PanelGrid>
        <PlanPermissionSync planId={plan.id} setHasEditPermission={setHasEditPermission} />
        <PanelHeader>
          <Header>
            <h2>{t(`${translationKey}.title`)}</h2>
            <KoalaIconButton onClick={handleCancel} iconName="close" />
          </Header>
        </PanelHeader>
        <PanelContent>
          <p>{t(`${translationKey}.noEditPermission`)}</p>
        </PanelContent>
      </PanelGrid>
    );
  }

  // When the plan is changed, reset the selected outcome
  const handlePlanChanged = (option: any) => {
    setSelectedPlan(option);
    setSelectedOutcome(null);
  };

  // -------------- START PLAN SELECTION --------------

  // Fetch the plans
  const fetchData = (input: string) => {
    let planParams;
    if (input) {
      planParams = {
        filter: {
          title: input,
        },
        order_direction: 'asc',
        order_attribute: 'title',
        limit: 10,
      };
    } else {
      planParams = {
        filter: {
          status: 'active',
        },
        order_direction: 'asc',
        order_attribute: 'title',
        limit: 10,
      };
    }
    return remoteApi.fetchPlans(queryKeys.plans, currentWorkspace.slug, planParams).then((response) => {
      return response.data;
    });
  };

  const handleLoadPlanOptions = (input: string) => {
    return fetchData(input);
  };

  const handleAddParentOutcome = () => {
    if (plan && plan.parent) {
      setSelectedPlan(plan.parent);
      setSelectedOutcome(null);
    }
    setRelationshipType('add_parent');
    setRelationshipObjectType('outcome');
  };

  const handleAddDependentOutcome = () => {
    setSelectedPlan(null);
    setSelectedOutcome(null);
    setRelationshipType('add_dependency');
    setRelationshipObjectType('outcome');
  };

  const handleAddRelatedOutcome = () => {
    setSelectedPlan(null);
    setSelectedOutcome(null);
    setRelationshipType('add_related');
    setRelationshipObjectType('outcome');
  };

  const handleAddParentObjective = () => {
    if (plan && plan.parent) {
      setSelectedPlan(plan.parent);
      setSelectedObjective(null);
    }
    setRelationshipType('add_parent');
    setRelationshipObjectType('objective');
  };

  const handleAddDependentObjective = () => {
    setSelectedPlan(null);
    setSelectedObjective(null);
    setRelationshipType('add_dependency');
    setRelationshipObjectType('objective');
  };

  const handleAddRelatedObjective = () => {
    setSelectedPlan(null);
    setSelectedObjective(null);
    setRelationshipType('add_related');
    setRelationshipObjectType('objective');
  };

  // -------------- END PLAN SELECTION --------------

  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();
  const objectiveLabel = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase();
  const label = relationshipObjectType === 'outcome' ? outcomeLabel : objectiveLabel;
  const relationshipToInstruction: any = {
    add_parent: t(`${translationKey}.addRelatedParent`, { label }),
    add_dependency: t(`${translationKey}.addRelatedDependency`, { label }),
    add_related: t(`${translationKey}.addRelatedItem`, { label }),
  };

  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>{t(`${translationKey}.title`)}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        {!relationshipType && (
          <OptionsContainer>
            <OptionsBlock>
              <h4>{t(`${translationKey}.addParent`)}</h4>
              <div>
                <KoalaButton appearance="subtle" onClick={handleAddParentObjective}>
                  <KoalaIcon iconName="flag" iconAppearance={theme.colors.T80} />
                  {t(`${translationKey}.addRelatedParent`, { label: objectiveLabel })}
                </KoalaButton>
                <KoalaButton appearance="subtle" onClick={handleAddParentOutcome}>
                  <KoalaIcon iconName="metric" iconAppearance={theme.colors.Y80} />
                  {t(`${translationKey}.addRelatedParent`, { label: outcomeLabel })}
                </KoalaButton>
              </div>
            </OptionsBlock>

            <OptionsBlock>
              <h4>{t(`${translationKey}.addDependency`)}</h4>
              <div>
                <KoalaButton appearance="subtle" onClick={handleAddDependentObjective}>
                  <KoalaIcon iconName="flag" iconAppearance={theme.colors.T80} />
                  {t(`${translationKey}.addRelatedDependency`, { label: objectiveLabel })}
                </KoalaButton>
                <KoalaButton appearance="subtle" onClick={handleAddDependentOutcome}>
                  <KoalaIcon iconName="metric" iconAppearance={theme.colors.Y80} />
                  {t(`${translationKey}.addRelatedDependency`, { label: outcomeLabel })}
                </KoalaButton>
              </div>
            </OptionsBlock>

            <OptionsBlock>
              <h4>{t(`${translationKey}.addRelated`)}</h4>
              <div>
                <KoalaButton appearance="subtle" onClick={handleAddRelatedObjective}>
                  <KoalaIcon iconName="flag" iconAppearance={theme.colors.T80} />
                  {t(`${translationKey}.addRelatedItem`, { label: objectiveLabel })}
                </KoalaButton>
                <KoalaButton appearance="subtle" onClick={handleAddRelatedOutcome}>
                  <KoalaIcon iconName="metric" iconAppearance={theme.colors.Y80} />
                  {t(`${translationKey}.addRelatedItem`, { label: outcomeLabel })}
                </KoalaButton>
              </div>
            </OptionsBlock>
          </OptionsContainer>
        )}
        {relationshipType !== null && relationshipObjectType === 'outcome' && (
          <>
            <SelectHeader>
              <KoalaTextBadge variant="neutral-outlined" maxWidth="100%">
                {relationshipToInstruction[relationshipType]}
              </KoalaTextBadge>
              <KoalaTextButton onClick={() => setRelationshipType(null)}>
                {t(`${translationKey}.changeRelationship`)}
              </KoalaTextButton>
            </SelectHeader>
            <OptionsContainer>
              <OptionSection>
                <h4>{t(`${translationKey}.plan`)}</h4>
                <KoalaSelect
                  isMultiSelect={false}
                  height={52}
                  isClearable={true}
                  handleChange={(option) => handlePlanChanged(option)}
                  placeholder={t(`${translationKey}.selectPlan`)}
                  loadOptions={handleLoadPlanOptions}
                  labelFormat={(option) => {
                    if (option.parent) {
                      return (
                        <PlanWithParent>
                          <span className="parent">{option.parent.title}</span>
                          <PlanIconLabel plan={option} size="small" />
                        </PlanWithParent>
                      );
                    }
                    return (
                      <PlanWithParent>
                        <span className="parent">{currentWorkspace.name}</span>
                        <PlanIconLabel plan={option} size="small" />
                      </PlanWithParent>
                    );
                  }}
                  selectedOption={selectedPlan}
                />
              </OptionSection>
              <OptionSection>
                <h4>{outcomeLabel}</h4>
                {selectedPlan && (
                  <OutcomeSelection
                    plan={selectedPlan}
                    setSelectedOutcome={setSelectedOutcome}
                    selectedOutcome={selectedOutcome}
                    currentOutcome={outcome}
                    workspace={currentWorkspace}
                  />
                )}
                {!selectedPlan && <p className="subtle">{t(`${translationKey}.selectPlan`)}</p>}
              </OptionSection>
            </OptionsContainer>
          </>
        )}
        {relationshipType !== null && relationshipObjectType === 'objective' && (
          <>
            <SelectHeader>
              <KoalaTextBadge variant="neutral-outlined" maxWidth="100%">
                {relationshipToInstruction[relationshipType]}
              </KoalaTextBadge>
              <KoalaTextButton onClick={() => setRelationshipType(null)}>
                {t(`${translationKey}.changeRelationship`)}
              </KoalaTextButton>
            </SelectHeader>
            <OptionsContainer>
              <OptionSection>
                <h4>{t(`${translationKey}.plan`)}</h4>
                <KoalaSelect
                  isMultiSelect={false}
                  height={52}
                  isClearable={true}
                  handleChange={(option) => handlePlanChanged(option)}
                  placeholder={t(`${translationKey}.selectPlan`)}
                  loadOptions={handleLoadPlanOptions}
                  labelFormat={(option) => {
                    if (option.parent) {
                      return (
                        <PlanWithParent>
                          <span className="parent">{option.parent.title}</span>
                          <PlanIconLabel plan={option} size="small" />
                        </PlanWithParent>
                      );
                    }
                    return (
                      <PlanWithParent>
                        <span className="parent">{currentWorkspace.name}</span>
                        <PlanIconLabel plan={option} size="small" />
                      </PlanWithParent>
                    );
                  }}
                  selectedOption={selectedPlan}
                />
              </OptionSection>
              <OptionSection>
                <h4>{objectiveLabel}</h4>
                {selectedPlan && (
                  <ObjectiveSelection
                    planId={selectedPlan.id}
                    setSelectedObjective={setSelectedObjective}
                    selectedObjective={selectedObjective}
                    workspace={currentWorkspace}
                  />
                )}
                {!selectedPlan && <p className="subtle">{t(`${translationKey}.selectPlan`)}</p>}
              </OptionSection>
            </OptionsContainer>
          </>
        )}
      </PanelContent>
      {relationshipType !== null && relationshipObjectType !== null && (
        <PanelActions>
          <KoalaButton
            submit
            onClick={handleSave}
            disabled={isLoading || (!selectedOutcome && !selectedObjective) || isCreatingObjectiveRelationship}
            loading={isLoading || isCreatingObjectiveRelationship}
          >
            {t('shared.save')}
          </KoalaButton>
          <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
        </PanelActions>
      )}
    </PanelGrid>
  );
}

export default React.memo(EditOutcomePanel);
