import React from 'react';

const MenuIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 4C1.5 3.44772 1.94772 3 2.5 3H17.5C18.0523 3 18.5 3.44772 18.5 4C18.5 4.55228 18.0523 5 17.5 5H2.5C1.94772 5 1.5 4.55228 1.5 4ZM1.5 10C1.5 9.44772 1.94772 9 2.5 9H17.5C18.0523 9 18.5 9.44772 18.5 10C18.5 10.5523 18.0523 11 17.5 11H2.5C1.94772 11 1.5 10.5523 1.5 10ZM2.5 15C1.94772 15 1.5 15.4477 1.5 16C1.5 16.5523 1.94772 17 2.5 17H17.5C18.0523 17 18.5 16.5523 18.5 16C18.5 15.4477 18.0523 15 17.5 15H2.5Z"
      fill="#737373"
    />
  </svg>
);

export default MenuIcon;
