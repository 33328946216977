import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useParams, Link } from 'react-router-dom';
import theme from 'theme';
import parse from 'parse-link-header';
import { useTranslation } from 'react-i18next';

// API
import * as remoteApi from 'api/remote';

// Components
import Loader from 'components/Loader';
import PlanIconLabel from 'components/PlanIconLabel';

const InboxPanel = styled.div`
  h3 {
    margin-bottom: 1.2rem;
  }
`;

const PlansLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x3};

  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const PlansVertical = styled.div`
  display: flex;
  flex-direction: column;

  @media ${theme.devices.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const PlanCard = styled(Link)`
  background: #ffffff;
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  border-radius: 4px;
  padding: ${theme.spacing.x2};
  font-weight: 600;
  overflow: hidden;

  &:hover {
    background: ${theme.colors.N3};
    cursor: pointer;
  }
  &:focus,
  &:active {
    background: ${theme.colors.B5};
  }

  &:last_of_type {
    margin-right: 0;
  }
  .plan-title-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .plan-icon {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const PlanGridCard = styled(Link)`
  &:hover {
    background: ${theme.colors.N3};
    cursor: pointer;

    .plan-icon {
      background: ${theme.colors.N10};
    }
  }
  border-radius: 4px;
  padding: ${theme.spacing.x1};
  .plan-icon {
    background: ${theme.colors.N5};
    padding: ${theme.spacing.x1};
    border-radius: 4px;
  }
`;

interface Props {
  vertical?: boolean;
}

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

function RecentPlans(props: Props) {
  const { t } = useTranslation();

  // Query that fetches all the outcomes with pending checkins
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const recentVisitsQueryKey = [queryKeys.recentVisits, workspaceSlug];
  const { isLoading, data: visitsResponse }: any = useInfiniteQuery(recentVisitsQueryKey, remoteApi.fetchRecentVisits, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  //
  if (isLoading) {
    return (
      <Fragment>
        <Loader size="medium" />
      </Fragment>
    );
  }

  let recentVisits = [];

  if (visitsResponse) {
    recentVisits = visitsResponse;
  }

  // Display empty state if you're at inbox zero
  if (recentVisits.length === 0 || recentVisits[0].data.length === 0) {
    return (
      <InboxPanel>
        <h3>{t('shared.recentPlans.title')}</h3>
        <p className="subtle">{t('shared.recentPlans.empty')}</p>
      </InboxPanel>
    );
  }

  if (props.vertical) {
    return (
      <InboxPanel>
        <h3>{t('shared.recentPlans.title')}</h3>
        <PlansVertical className="plan-list">
          {recentVisits.map((group: any, i: number) => {
            return (
              <Fragment key={i}>
                {group.data.map((visit: any, index: number) => {
                  const plan = visit.visited;
                  if (!plan) {
                    return null;
                  }

                  const path =
                    plan.state === 'published'
                      ? `/${workspaceSlug}/plans/${plan.nano_slug}/outcomes`
                      : `/${workspaceSlug}/plans/${plan.nano_slug}/write`;
                  return (
                    <PlanGridCard key={plan.id} to={path}>
                      <PlanIconLabel
                        plan={plan}
                        // @ts-ignore
                        size="large"
                        crop={30}
                      />
                    </PlanGridCard>
                  );
                })}
              </Fragment>
            );
          })}
        </PlansVertical>
      </InboxPanel>
    );
  }

  return (
    <InboxPanel>
      <h3>{t('shared.recentPlans.title')}</h3>
      <PlansLine>
        {recentVisits.map((group: any, i: number) => {
          return (
            <Fragment key={i}>
              {group.data.map((visit: any, index: number) => {
                const plan = visit.visited;
                if (!plan) {
                  return null;
                }

                const path =
                  plan.state === 'published'
                    ? `/${workspaceSlug}/plans/${plan.nano_slug}/outcomes`
                    : `/${workspaceSlug}/plans/${plan.nano_slug}/write`;
                return (
                  <PlanCard key={plan.id} to={path}>
                    <PlanIconLabel
                      plan={plan}
                      // @ts-ignore
                      size="large"
                      crop={30}
                    />
                  </PlanCard>
                );
              })}
            </Fragment>
          );
        })}
      </PlansLine>
    </InboxPanel>
  );
}

export default RecentPlans;
