import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import theme from 'theme';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import _ from 'lodash';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';

// Components
import { ChromeContent } from 'components/Chrome';
import KoalaButton from 'koala/components/Button';
import TeamsListSearch from './TeamsListSearch';
import TeamsList from './TeamsList';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin: 0 auto ${theme.spacing.x4} auto;
  max-width: 120rem;
  padding: 1.2rem ${theme.spacing.x2};

  .outcome-header {
    display: flex;
    flex-wrap: wrap;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: ${theme.spacing.x2};
  display: flex;
`;

const FilterWrapper = styled.div`
  flex: 1;
`;

export const ListingLine = styled.div`
  border-bottom: 1px solid ${theme.colors.N10};
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
  padding: 1.2rem ${theme.spacing.x2};
  cursor: pointer;

  :hover {
    background: ${theme.colors.N3};
  }
  &.selected,
  :focus,
  :active {
    background: ${theme.colors.B5};
  }

  :first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  :last-of-type {
    border-bottom: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingsDetails(props: Props) {
  const { workspace } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [nameToSearch, setNameToSearch] = useState('');

  // START SEARCH LOGIC
  const performSearch = (newName: string) => {
    setNameToSearch(newName);
  };

  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );

  const handleSearch = (e: any) => {
    const newName = e.target.value;
    debouncePerformSearch.current(newName);
  };

  const handleCreateTeam = (e: any) => {
    e.preventDefault();
    const action = `workspace:${workspace.slug}:create.team`;
    dispatch(setGlobalModalContent(action));
  };

  return (
    <>
      <MobileReadyChromeHeader>
        <WorkspaceHeader workspace={workspace} useGreyBackground={true} title={<h1> {t('workspaceTeams.title')}</h1>} />
      </MobileReadyChromeHeader>
      <ChromeContent isGreyBackground>
        <Helmet>
          <title>
            {workspace.name} | {t('workspaceTeams.title')} | Tability
          </title>
        </Helmet>
        <Wrapper>
          <TitleContainer>
            <FilterWrapper>
              <input
                type="text"
                onChange={handleSearch}
                placeholder={t('workspaceTeams.filter') ?? 'Filter teams'}
                className="tiny"
              />
            </FilterWrapper>
            <KoalaButton onClick={handleCreateTeam}>{t('workspaceTeams.createButton')}</KoalaButton>
          </TitleContainer>
          {nameToSearch && <TeamsListSearch searchTerm={nameToSearch} workspace={workspace} />}
          {!nameToSearch && <TeamsList workspace={workspace} />}
        </Wrapper>
      </ChromeContent>
    </>
  );
}

export default WorkspaceSettingsDetails;
