import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaIconButton from 'koala/components/IconButton';
import * as routes from 'routes';
import { shallowEqual, useSelector } from 'react-redux';
import { Base64 } from 'js-base64';
import { useTranslation } from 'react-i18next';

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  gap: ${theme.spacing.x1};
`;

const TagButton = styled.div`
  color: ${theme.colors.subtleText};
  align-self: center;
`;
// Components
interface Props {
  outcome: TabilityTypes.Outcome;
  setDisplayPicker: (displayPicker: boolean) => void;
  disabled?: boolean;
}

function TagPickerOutcome(props: Props) {
  const { outcome, setDisplayPicker, disabled } = props;
  const tagList = outcome.cached_tag_list || '';
  const tags = tagList.length > 0 ? tagList.split(',') : [];
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  return (
    <TagContainer>
      {tags.length === 0 && <TagButton>{t('shared.none')}</TagButton>}
      {tags.length > 0 && (
        <>
          {tags.map((tag: string) => {
            const filterHash: { [key: string]: any } = { tag: [{ value: tag, label: tag }] };
            const encodedHash = Base64.encode(JSON.stringify(filterHash));
            const filterPath = routes.WORKSPACE_OUTCOMES_ROUTE_WITH_FILTERS.replace(
              ':workspaceSlug',
              currentWorkspace.slug,
            ).replace(':filter', encodedHash);
            return (
              <KoalaTextBadge
                isLowercase={true}
                key={tag}
                variant="neutral-outlined"
                edge="circle"
                linkTo={filterPath}
                size="small"
              >
                {tag}
              </KoalaTextBadge>
            );
          })}
        </>
      )}
      {!disabled && (
        <KoalaIconButton iconName="edit" edge="square" size="small" onClick={() => setDisplayPicker(true)} />
      )}
    </TagContainer>
  );
}
export default React.memo(TagPickerOutcome);
