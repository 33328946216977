import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import FormField from 'components/FormField';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import * as remoteApi from 'api/remote';
import request from 'state/utils/request';

import _ from 'lodash';
import { useParams } from 'react-router-dom';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { DashboardWidget } from 'types';
import { ValueType } from 'react-select';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;
`;

interface Props {
  handleSaveWidget: (type: string, source: string, source_id: string, widgetId?: string) => void;
  isLoading: boolean;
  isEditing?: boolean;
  widget?: DashboardWidget;
}

function OutcomeDistributionWidgetDetails(props: Props) {
  const { handleSaveWidget, isLoading, isEditing, widget } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const [selectedSegment, setSelectedSegment] = useState<KoalaSelectOption | null>(null);
  const workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const handleSelectSegment = (option: ValueType<KoalaSelectOption, boolean>) => {
    if (option) {
      setSelectedSegment(option as KoalaSelectOption);
    } else {
      setSelectedSegment(null);
    }
  };

  useEffect(() => {
    if (widget?.source) {
      setSelectedSegment({ value: widget.source_id, label: widget.source.title });
    }
  }, [isEditing, widget]);

  const loadOptions = (inputValue: string, callback: any) => {
    const url = remoteApi.SEGMENTS_API.replace(':workspaceSlug', workspaceSlug).replace(':segmentable_type', 'outcome');

    const params = inputValue
      ? {
          filter: {
            title: inputValue,
            archived: false,
          },
          per_page: 10,
        }
      : {
          filter: {
            archived: false,
          },
          per_page: 10,
        };
    request
      .get(url, { params })
      .then((response) => {
        const plans = response.data;
        if (plans && plans.length > 0) {
          const options = plans.map((plan: any) => {
            return {
              value: plan.id,
              label: plan.title,
            };
          });
          return options;
        } else {
          return [];
        }
      })
      .then(callback);
  };

  const debouncedLoadOptions: any = useRef(
    _.debounce((inputValue: string, callback: any) => loadOptions(inputValue, callback), 200, {
      maxWait: 2000,
    }),
  );

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedSegment) {
      const value = selectedSegment.value as string;
      handleSaveWidget('outcome-distribution', 'segment', value, widget?.id);
    }
  };

  return (
    <Container>
      <FormField className="outcome-segment-select">
        <label>
          {t('workspaceDashboards.outcomeSegment', { outcome: translate(workspace, CustomTermKey.OUTCOME, 1) })}
        </label>
        <KoalaSelect
          handleChange={handleSelectSegment}
          placeholder={
            t('workspaceDashboards.outcomeSegmentPlaceholder', {
              outcome: translate(workspace, CustomTermKey.OUTCOME, 1).toLowerCase(),
            }) ?? 'outcome'
          }
          selectedOption={selectedSegment}
          isClearable
          loadOptions={debouncedLoadOptions.current}
          size="small"
          id="segment"
        />
      </FormField>
      <div>
        <KoalaButton onClick={handleSave} loading={isLoading} disabled={!selectedSegment}>
          {isEditing ? t('shared.save') : t('shared.add')}
        </KoalaButton>
      </div>
    </Container>
  );
}

export default OutcomeDistributionWidgetDetails;
