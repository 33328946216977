import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../shared/styles';
import Icons from '../../shared/images/icons/IconList';

export interface IconProps {
  iconName: string;
  /** Can either be KoalaSizes value or a number for the rem value. Default is medium */
  iconSize?: (typeof KoalaSizes)[number] | number;
  iconAppearance?: string;
  className?: string;
  htmlFor?: string;
  style?: React.CSSProperties;
}

const IconContainer = styled.div<IconProps>`
  display: inline-flex;
  height: auto;
  align-items: center;
  justify-content: center;

  &.icon--custom {
    color: ${(props) => props.iconAppearance};
  }
  &.icon--primary {
    color: ${colors.N0};
  }
  &.icon--secondary {
    color: ${colors.N70};
  }
  &.icon--subtle {
    color: ${colors.B50};
  }
  *.icon--disabled {
    color: ${colors.N50};
  }
  &.icon--upgrade {
    color: ${colors.V50};
  }

  /* SIZES */
  &.icon--xsmall {
    width: 1.4rem;
    height: 1.4rem;
  }
  &.icon--small {
    width: 1.8rem;
    height: 1.8rem;
  }
  &.icon--medium {
    width: 2rem;
    height: 2rem;
    /* svg {
      width: 2rem;
      height: 2rem;
    } */
  }
  &.icon--large {
    width: 4rem;
    height: 4rem;
  }
  &.icon--custom-size {
    width: ${(props) => props.iconSize}rem;
    svg {
      width: ${(props) => props.iconSize}rem;
      height: ${(props) => props.iconSize}rem;
    }
  }

  svg {
    path {
      fill: currentColor;
    }
    rect {
      fill: currentColor;
    }
  }
`;

const KoalaIcon: React.FunctionComponent<IconProps> = (props: IconProps) => {
  const { iconName, iconAppearance, iconSize, className } = props;

  if (Icons[iconName] === undefined) return null;
  const Icon: () => JSX.Element = Icons[iconName];

  let iconClass = className ? className : '';
  let iconColor = colors.N100;
  if (iconName === 'upgrade') {
    iconClass += ` icon--upgrade`;
  } else if (iconAppearance && KoalaAppearances.some((v) => v === iconAppearance)) {
    iconClass += ` icon--${iconAppearance}`;
  } else if (iconAppearance) {
    iconClass += ` icon--custom`;
    iconColor = iconAppearance;
  }

  if (typeof iconSize === 'string') {
    iconClass += ` icon--${iconSize}`;
  } else if (typeof iconSize === 'number') {
    iconClass += ` icon--custom-size`;
  }

  return (
    <IconContainer {...props} iconAppearance={iconColor} className={iconClass}>
      <Icon />
    </IconContainer>
  );
};

KoalaIcon.defaultProps = {
  iconSize: 'medium',
  iconAppearance: colors.N60,
};

export default KoalaIcon;
