import React, { useEffect, useState } from 'react';
import * as textUtils from 'utils/textUtils';

import MarkdownContent from 'components/MarkdownContent';

import * as checkinUtils from 'utils/checkinUtils';
import * as outcomeUtils from 'utils/outcomeUtils';
import Reactions from 'components/Reactions';
import { CachedReaction } from 'types';
import { useMutation } from 'react-query';
import * as remoteApi from 'api/remote';
import KoalaTextBadge from 'koala/components/TextBadge';

import { ContentAction, ContentComment } from './NewsItem';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  event: any;
}

function Block(props: Props) {
  const { event } = props;
  const { t } = useTranslation();

  const { membership, source } = event;

  const formattedScore =
    source && outcomeUtils.hasTarget(source.outcome) ? `${checkinUtils.checkinScore(source, source.outcome, t)}` : null;

  const [reactions, setReactions] = useState<CachedReaction[]>([]);
  useEffect(() => {
    if (source.cached_reactions && typeof source.cached_reactions !== 'string') {
      setReactions(source.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [source]);
  const [addReactionMutation] = useMutation(remoteApi.createCheckinReaction, {
    onSuccess: (response) => {
      setReactions(response.data);
    },
  });
  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: (response) => {
      setReactions(response.data);
    },
  });

  if (!membership) {
    return null;
  }

  const { user } = membership;
  const name = user.fullname || user.email;
  const hasBody = !textUtils.isEmptyHTMLText(source.body);

  return (
    <>
      <ContentAction>
        <Trans
          i18nKey="newsfeed.checkinCreated"
          values={{ name, title: source.outcome.title }}
          components={{ b: <b /> }}
        />{' '}
        <KoalaTextBadge
          className="badge"
          variant={checkinUtils.confidenceToVariant(source.confidence, false)}
          size="small"
        >
          {checkinUtils.confidenceToScore(source.confidence, t)}
        </KoalaTextBadge>
        {formattedScore && (
          <KoalaTextBadge className="badge" size="small" variant="neutral-light">
            {formattedScore}
          </KoalaTextBadge>
        )}
      </ContentAction>
      {hasBody && (
        <ContentComment>
          <MarkdownContent source={source.body} />
        </ContentComment>
      )}

      <Reactions
        reactions={reactions}
        addCallback={(emotion) => addReactionMutation({ checkinId: source.id, emotion: emotion })}
        removeCallback={(reactionId) => deleteReactionMutation(reactionId)}
      />
    </>
  );
}

export default React.memo(Block);
