import React from 'react';

const LabelCommentIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C2.68629 0 0 2.68629 0 6C0 6.91796 0.206798 7.78987 0.576818 8.56965L0.0702877 10.5958C-0.131115 11.4014 0.59861 12.1311 1.40423 11.9297L3.43036 11.4232C4.21014 11.7932 5.08204 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0ZM4 6C4 6.55228 3.55228 7 3 7C2.44772 7 2 6.55228 2 6C2 5.44772 2.44772 5 3 5C3.55228 5 4 5.44772 4 6ZM7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6ZM9 7C9.55229 7 10 6.55228 10 6C10 5.44772 9.55229 5 9 5C8.44771 5 8 5.44772 8 6C8 6.55228 8.44771 7 9 7Z"
      fill="#324DD3"
    />
  </svg>
);
export default LabelCommentIcon;
