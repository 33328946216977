import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useLocation } from 'react-router-dom';

import OutcomeBlock from './OutcomeBlock';
import { parseISO, differenceInCalendarDays } from 'date-fns';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import KoalaConfidencePie from 'koala/components/ConfidencePie';
import { getOutcomeConfidenceData } from 'utils/outcomeUtils';
import { useTranslation } from 'react-i18next';

const PlanWrapper = styled.div`
  padding-bottom: ${theme.spacing.x6};
`;

const ObjectiveTitleCompact = styled.div<{ isDarkMode: boolean }>`
  font-size: 2.8rem;
  font-weight: 700;
  color: ${(props) => (props.isDarkMode ? theme.colors.N0 : theme.colors.N90)};
`;

const ObjectiveBlock = styled.div`
  margin: 0 0 ${theme.spacing.x6} 0;
`;

const SampleBlock = styled.div`
  padding: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x4};
  background: ${theme.colors.upgradeBg};
  color: ${theme.colors.subtleText};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 600;
  button {
    margin-left: ${theme.spacing.x2};
  }
`;

const ObjectiveStats = styled.div`
  display: flex;
  margin: 0;

  margin: ${theme.spacing.x2} 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing.x3};
  margin-bottom: ${theme.spacing.x1};
  justify-content: space-between;
`;

const SideStats = styled.div`
  display: flex;
  align-items: center;
  div:first-of-type {
    margin-right: ${theme.spacing.x1};
  }
`;

const TaskIcon = styled.div`
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  color: ${theme.colors.subtleText};
  background: #d1d1d1;
  font-weight: 800;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressStat = styled.div`
  display: flex;
  flex-direction: column;
`;

const ObjectiveStatsCard = styled.div<{ isDarkMode: boolean }>`
  padding: ${theme.spacing.x2} ${theme.spacing.x3};
  border-radius: 4px;
  border: 2px solid ${(props) => (props.isDarkMode ? theme.colors.N5 : theme.colors.N20)};
  margin-right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-weight: 800;
    font-size: 2.4rem;
    display: flex;
    color: ${(props) => (props.isDarkMode ? theme.colors.N20 : theme.colors.N90)};
  }

  label {
    font-size: 1rem;
    letter-spacing: 1px;
    color: ${(props) => (props.isDarkMode ? theme.colors.N20 : theme.colors.N90)};
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    font-weight: 900;
  }
`;

const ObjectiveHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing.x2};
  gap: ${theme.spacing.x3};

  @media ${theme.devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TVContainer = styled.div<{ isDarkMode: boolean }>`
  background: ${(props) => (props.isDarkMode ? '#000' : '#FFF')};
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x3};
`;

const TVContent = styled.div`
  flex: 1;
  padding-bottom: ${theme.spacing.x3};
`;

const TVOutcomes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 3.2rem;
  row-gap: 3.2rem;
  @media ${theme.devices.desktop} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${theme.devices.mobile} {
    grid-template-columns: 1fr;
  }
`;

const EmptyObj = styled.div`
  font-size: 2.8rem;
  font-weight: 500;
`;

interface Props {
  plan: TabilityTypes.Plan;
  objectives: TabilityTypes.Objective[];
  outcomes: TabilityTypes.Outcome[];
  outcomesByObjective: any;
  isDarkMode: boolean;
}

function Tracker(props: Props) {
  const location = useLocation();
  const { objectives, outcomesByObjective, plan, isDarkMode } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const planFinishAt = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  let differenceInDays = planFinishAt ? differenceInCalendarDays(planFinishAt, new Date()) : 0;
  if (differenceInDays < 0) {
    differenceInDays = 0;
  }

  const isBlockSelected = (blockId: string) => {
    return location.hash.includes(blockId);
  };

  return (
    <TVContainer isDarkMode={isDarkMode}>
      <TVContent>
        {plan.is_dummy && <SampleBlock>{t('publicPlan.sample')}</SampleBlock>}
        <PlanWrapper className={`plan-content`}>
          {objectives.map((objective) => {
            const outcomesToDisplay = outcomesByObjective[objective.id] || [];
            let objectiveProgress = <span>&mdash;</span>;
            if (objective.outcome_progress_prct !== null) {
              objectiveProgress = <span>{Math.round(objective.outcome_progress_prct * 100)}%</span>;
            }
            const objectiveInitiativeProgress = Math.round((objective.initiative_progress_prct || 0) * 100);
            const confidenceData = getOutcomeConfidenceData(outcomesToDisplay);

            return (
              <ObjectiveBlock key={objective.id}>
                <ObjectiveHeader>
                  <ObjectiveTitleCompact isDarkMode={isDarkMode}>{objective.title}</ObjectiveTitleCompact>
                  <ObjectiveStats>
                    <ObjectiveStatsCard isDarkMode={isDarkMode}>
                      <SideStats>
                        <KoalaConfidencePie data={confidenceData} size="small" />
                        <ProgressStat>
                          <label>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</label>
                          <span>{objectiveProgress}</span>
                        </ProgressStat>
                      </SideStats>
                    </ObjectiveStatsCard>
                    <ObjectiveStatsCard isDarkMode={isDarkMode}>
                      <label>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</label>
                      <SideStats>
                        <TaskIcon>&#10003;</TaskIcon>
                        {objective.total_initiatives_count > 0 && <span>{objectiveInitiativeProgress}%</span>}
                        {objective.total_initiatives_count === 0 && <span>&mdash;</span>}
                      </SideStats>
                    </ObjectiveStatsCard>
                  </ObjectiveStats>
                </ObjectiveHeader>
                <TVOutcomes>
                  {outcomesToDisplay.length === 0 && (
                    <EmptyObj className="subtle">
                      {t('publicPlan.noOutcomes', {
                        objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
                        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                      })}
                    </EmptyObj>
                  )}
                  {outcomesToDisplay.map((outcome: any) => {
                    //const outcome = outcomes.find((o) => o.id === outcomeId);
                    if (outcome) {
                      const blockId = `outcome:${outcome.nano_slug}`;
                      const isSelected = isBlockSelected(blockId);
                      return (
                        <OutcomeBlock
                          outcome={outcome}
                          key={outcome.id}
                          isSelected={isSelected}
                          isDarkMode={isDarkMode}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </TVOutcomes>
              </ObjectiveBlock>
            );
          })}
        </PlanWrapper>
      </TVContent>
    </TVContainer>
  );
}

export default React.memo(Tracker);
