import React from 'react';

const PlusIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 12.5C4.5 11.9477 4.94772 11.5 5.5 11.5H18.5C19.0523 11.5 19.5 11.9477 19.5 12.5C19.5 13.0523 19.0523 13.5 18.5 13.5H5.5C4.94772 13.5 4.5 13.0523 4.5 12.5Z"
      fill="#737373"
    />
  </svg>
);

export default PlusIcon;
