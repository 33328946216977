import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import Cookies from 'universal-cookie';
import styled from 'styled-components';
import theme from 'theme';

// API
import * as remoteApi from 'api/remote';

// Components
import LoadingScreen from 'components/LoadingScreen';
import KoalaButton from 'koala/components/Button';

// Components
const Container = styled.div`
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;
`;

const Warning = styled.div`
  background: ${theme.colors.warningBg};
  padding: ${theme.spacing.x3};
  border-radius: 8px;
  p {
    padding: ${theme.spacing.x2} 0;
  }
`;

interface Props {
  invite_link_token: string;
}

function JoinWorkspace(props: Props) {
  const { invite_link_token } = props;
  const [errorUserLimitExceeded, setErrorUserLimitExceeded] = useState(false);

  const removeInviteCookie = () => {
    const cookies = new Cookies();

    // Remove invite link cookie otherwise we'll get a permanent redirect!
    cookies.remove('_tbty_inv', {
      path: '/',
      secure: true,
    });
  };

  const [joinMembershipMutation, { isError }]: [any, any] = useMutation(remoteApi.joinMembership, {
    onSuccess: (response) => {
      removeInviteCookie();

      const membership = response.data;
      const path = `/${membership.workspace.slug}`;
      window.location.replace(path);
    },
    onError: (error: any) => {
      removeInviteCookie();
      const errorText = error.response.data.error;
      if (errorText.includes('user limit') && error.response.status === 401) {
        setErrorUserLimitExceeded(true);
      }
    },
  });

  useEffect(() => {
    const params = {
      invite_link_token,
    };
    joinMembershipMutation(params);
  }, [invite_link_token, joinMembershipMutation]);

  if (isError) {
    return (
      <Container>
        {!errorUserLimitExceeded && (
          <Warning>
            <h3>An error occurred</h3>
            <p>Sorry, we couldn't process your invitation request.</p>
            <KoalaButton onClick={() => window.location.replace('/')}>Back to home</KoalaButton>
          </Warning>
        )}
        {errorUserLimitExceeded && (
          <Warning>
            <h3>
              <span role="img" aria-label="yikes!">
                😬
              </span>{' '}
              User quota reached
            </h3>
            <p>Sorry, but this workspace has reached its user limit.</p>
            <KoalaButton onClick={() => window.location.replace('/')}>Back to home</KoalaButton>
          </Warning>
        )}
      </Container>
    );
  }

  return <LoadingScreen />;
}

export default JoinWorkspace;
