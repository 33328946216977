import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import React from 'react';
import ToggleContainer from './ToggleButton.styles';

export interface ToggleButtonProps {
  /** Action triggered when toggle is changed */
  handleChange: (e: any) => void;
  /** Flag deciding if the toggle is initially checked */
  defaultChecked: boolean;
  /** Show disabled state and prevent interaction */
  disabled?: boolean;
  /** Reference string for tooltip */
  dataFor?: string;
  /** Component id */
  id: string;
  /** Optional appearance type from KoalaAppearances */
  appearance?: (typeof KoalaAppearances)[number];
  /** Optional size from KoalaSizes */
  size?: (typeof KoalaSizes)[number];
  /** Optional class name for Toggle */
  className?: string;
  /** Optional custom styling for Toggle */
  style?: React.CSSProperties;
}

function KoalaToggleButton(props: ToggleButtonProps) {
  const { handleChange, defaultChecked, disabled, dataFor, id, size, appearance, className, style } = props;

  const inputId = `koala-toggle-${id}`;
  let toggleClass = `toggle-btn--${size} toggle-btn--${appearance} ${className}`;

  return (
    <ToggleContainer className={toggleClass} style={style}>
      <input
        className="tgl"
        id={inputId}
        type="checkbox"
        name={inputId}
        defaultChecked={defaultChecked}
        onChange={handleChange}
        disabled={disabled}
        tabIndex={0}
      />
      <label className="tgl-btn" htmlFor={inputId} data-tip={dataFor ? true : false} data-for={dataFor} />
    </ToggleContainer>
  );
}

KoalaToggleButton.defaultProps = {
  id: 'default',
  defaultChecked: false,
  size: 'medium',
  appearance: 'primary',
  disabled: false,
  dataFor: 'default-tooltip',
};

export default KoalaToggleButton;
