import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import SentryIcon from 'components/_assets/IconSentry.png';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
}

function SentryIntegration(props: Props) {
  const { workspace, integrations } = props;
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallSentryMutation, { isLoading: isUninstallingSentry }] = useMutation(remoteApi.uninstallSentry, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const sentryIntegration = integrations.filter((i: Integration) => i.integration_type === 'sentry')[0];

  const handleUninstallSentry = (e: React.MouseEvent) => {
    e.preventDefault();
    uninstallSentryMutation(workspace.slug);
  };

  const handleInstallSentry = () => {
    dispatch(setGlobalModalContent('workspace::connect.sentry'));
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={SentryIcon} alt="SentryIcon" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'Sentry' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Sentry' })}</p>
        <p>
          <a href="https://guides.tability.app/docs/integrations/sentry" target="_blank" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {sentryIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: sentryIntegration.connected_to }}
              />
            </div>
            <KoalaTextButton size="small" onClick={handleUninstallSentry}>
              {isUninstallingSentry ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove')}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!sentryIntegration && (
          <KoalaButton onClick={handleInstallSentry}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Sentry' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default SentryIntegration;
