import { differenceInDays, getTime, isBefore, parseISO } from 'date-fns';
import { TFunction } from 'i18next';
import theme from 'theme';
import { Domain, Outcome, Plan } from 'types';
import * as outcomeUtils from './outcomeUtils';
import { isInPlanTimeline } from './planUtils';

// Turn confidence into color
export const confidenceToColor = (confidence?: string) => {
  switch (confidence) {
    case 'red':
      return theme.colors.R40;
    case 'yellow':
      return theme.colors.Y40;
    case 'green':
      return theme.colors.G40;
    case 'blue':
      return theme.colors.B40;
    default:
      return theme.colors.N20;
  }
};

export const confidenceToDarkColor = (confidence?: string) => {
  switch (confidence) {
    case 'red':
      return theme.colors.R70;
    case 'yellow':
      return theme.colors.Y80;
    case 'green':
      return theme.colors.G70;
    case 'blue':
      return theme.colors.B70;
    default:
      return theme.colors.N50;
  }
};

export const confidenceToBackgroundColor = (confidence?: string) => {
  switch (confidence) {
    case 'red':
      return theme.colors.P5;
    case 'yellow':
      return theme.colors.Y5;
    case 'green':
      return theme.colors.G5;
    case 'blue':
      return theme.colors.B5;
    default:
      return theme.colors.N5;
  }
};

export const confidenceToProgressColor = (confidence?: string) => {
  switch (confidence) {
    case 'red':
      return theme.colors.R10;
    case 'yellow':
      return theme.colors.Y20;
    case 'green':
      return theme.colors.G10;
    default:
      return theme.colors.N10;
  }
};

export const confidenceToValueColor = (confidence?: string) => {
  switch (confidence) {
    case 'red':
      return theme.colors.R70;
    case 'yellow':
      return theme.colors.Y70;
    case 'green':
      return theme.colors.G50;
    case 'blue':
      return theme.colors.B50;
    default:
      return theme.colors.N70;
  }
};

// Turn confidence into color
export const confidenceToColorLight = (confidence: string) => {
  switch (confidence) {
    case 'red':
      return '#F5826A';
    case 'yellow':
      return '#F6C94A';
    case 'green':
      return '#8CC1B5';
    default:
      return theme.colors.grey;
  }
};

// turn confidence into color
export const confidenceToColorLetter = (confidence: string) => {
  switch (confidence) {
    case 'red':
      return 'R';
    case 'yellow':
      return 'Y';
    case 'green':
      return 'G';
    case 'blue':
      return 'B';
    default:
      return 'N';
  }
};

export const confidenceToVariant = (confidence: string, isDark?: boolean) => {
  switch (confidence) {
    case 'red':
      return isDark ? 'red' : 'red-light';
    case 'yellow':
      return isDark ? 'yellow' : 'yellow-light';
    case 'green':
      return isDark ? 'green' : 'green-light';
    case 'blue':
      return isDark ? 'blue' : 'blue-light';
    default:
      return isDark ? 'neutral' : 'neutral-light';
  }
};

// Turn confidence into color
export const confidenceToTextColor = (confidence: string) => {
  switch (confidence) {
    case 'red':
      return '#fff';
    case 'yellow':
      return theme.colors.black;
    case 'green':
      return '#fff';
    case 'blue':
      return '#fff';
    default:
      return theme.colors.subtleText;
  }
};

// Turn confidence into color
export const confidenceToScore = (confidence: string, t: TFunction) => {
  switch (confidence) {
    case 'red':
      return t('shared.confidence.offTrack');
    case 'yellow':
      return t('shared.confidence.atRisk');
    case 'green':
      return t('shared.confidence.onTrack');
    default:
      return t('shared.confidence.pending');
  }
};

export const formattedScore = (score_format: any, score: any) => {
  const humanScore = outcomeUtils.nFormatter(parseFloat(score), 2);
  return applyFormat(score_format, humanScore);
};

export const applyFormat = (score_format: any, scoreString: any) => {
  if (!score_format) {
    return '–';
  }
  return score_format.replace('_number_', scoreString);
};

export const checkinScore = (checkin: any, outcome: Outcome, t: TFunction) => {
  // Check if the outcome has a score_format
  if (outcome.outcome_type !== 'no_metric') {
    const humanScore = outcomeUtils.nFormatter(parseFloat(checkin.score), 2);
    return applyFormat(outcome.score_format, humanScore);
  }
  // If there's no target use the confidence instead of the score
  return confidenceToScore(checkin.confidence, t);
};

export const checkinScoreElements = (checkin: any, outcome: Outcome, t: TFunction) => {
  // Check if the outcome has a score_format
  if (outcomeUtils.hasTarget(outcome)) {
    const humanScore = outcomeUtils.nFormatter(parseFloat(checkin.score), 2);
    let format = '-';
    if (outcome.score_format) {
      format = outcome.score_format.replace('_number_', '');
    }
    return {
      score: humanScore,
      format,
    };
  }
  // If there's no target use the confidence instead of the score
  return {
    score: confidenceToScore(checkin.confidence, t),
    format: '',
  };
};

interface checkinType {
  x: Date;
  y: any;
  timestamp: number;
  color?: string;
  checkin?: any;
}

export const getTrendline = (currentData: checkinType[], finishDate: Date, type: string): checkinType[] => {
  // get trend data and sort by the date
  const checkinDataCopy = Array.from(currentData);
  checkinDataCopy.sort(function (a, b) {
    return a.x.getTime() - b.x.getTime();
  });
  // get most recent checkin
  const trendLast = checkinDataCopy[checkinDataCopy.length - 1];
  const trendLastDate = trendLast && trendLast.x;

  if (trendLastDate >= finishDate) {
    return [];
  }

  const trendData: checkinType[] = [];

  let n = 0;
  let xy = 0;
  let sum_x = 0;
  let sum_y = 0;
  let x2 = 0;
  let firstInRange: checkinType | undefined;
  // Use up to 5 weeks of data for weekly check-ins, and up to 4 months of data for monthly check-ins
  let daysToInclude: number = type === 'week' ? 35 : 120;
  checkinDataCopy.forEach((checkin: checkinType) => {
    // // Check that the current checkin date is within 28 days of the last checkin.
    const difference = differenceInDays(trendLastDate, checkin.x);
    if (difference < daysToInclude) {
      // if the first value in range has no value, use checkin
      if (!firstInRange) {
        firstInRange = checkin;
      }
      // Get the days different between this checkin and the first within the range
      const x = differenceInDays(checkin.x, firstInRange.x);

      n++;
      xy += x * checkin.y;
      sum_x += x;
      sum_y += checkin.y;
      x2 += x * x;
    }
  });
  // if has enough checkpoints, calculate equation for slope
  if (n > 2 && firstInRange) {
    // slope
    const slope = (n * xy - sum_x * sum_y) / (n * x2 - sum_x * sum_x);
    const offset = (sum_y - slope * sum_x) / n;

    // get the projected value at the end
    const last_x = differenceInDays(finishDate, firstInRange.x);
    let last_y = slope * last_x + offset;

    trendData.push(trendLast);
    trendData.push({
      x: finishDate,
      y: last_y,
      timestamp: getTime(finishDate),
    });
  }
  return trendData;
};

export const getChartDatapoints = (
  plan: Plan,
  outcome: Outcome,
  checkins: any[],
  outcomeHasTarget = true,
): {
  currentData: checkinType[];
  targetData: checkinType[];
  finishDate: Date | undefined;
  yDomain: Domain;
  isInsideTimeline: boolean;
} => {
  const currentData: checkinType[] = []; // This data is used to trace the current line as a solid line
  const targetData: checkinType[] = []; // This data is used to trace the target area
  let yDomain = { min: 0, max: 0 };
  let finishDate: Date | undefined;
  let isInsideTimeline = false;
  if (!plan.start_at || !plan.finish_at) {
    return { currentData, targetData, finishDate, yDomain, isInsideTimeline };
  }

  const startDate: Date = parseISO(plan.start_at);
  finishDate = parseISO(plan.finish_at);

  isInsideTimeline = isInPlanTimeline(startDate, finishDate, new Date());

  // Set the points required to create the target line
  const firstPoint: checkinType = {
    x: startDate,
    y: outcomeHasTarget ? outcome.from : 1,
    timestamp: getTime(startDate),
    color: theme.colors.grey,
  };

  const lastPoint: checkinType = {
    x: finishDate,
    y: outcome.to,
    timestamp: getTime(finishDate),
  };

  targetData.push(firstPoint);
  targetData.push(lastPoint);

  yDomain.min = Math.min(firstPoint.y, lastPoint.y);
  yDomain.max = Math.max(firstPoint.y, lastPoint.y);

  // By default we're going to use the first point from the target
  // We'll change that if we find a checkin prior to the first point.
  let useFirstPoint = true;

  checkins.forEach((checkin: any, index: number) => {
    const date = parseISO(checkin.checkin_date);

    // If the checkin was done prior to the first point we don't store
    // the first point in the list of current points
    if (isBefore(date, firstPoint.x)) {
      useFirstPoint = false;
    }

    // Create the point using the checkin data
    const point: checkinType = {
      x: date,
      y: outcomeHasTarget ? checkin.score : 1,
      timestamp: getTime(date),
      color: confidenceToColor(checkin.confidence),
      checkin,
    };
    currentData.push(point);

    // Update domain
    yDomain.min = Math.min(point.y, yDomain.min);
    yDomain.max = Math.max(point.y, yDomain.max);
  });

  if (useFirstPoint) {
    currentData.push(firstPoint);
  }

  return { currentData, targetData, finishDate, yDomain, isInsideTimeline };
};
