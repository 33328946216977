// This components can be used anytime you want to also have mentions.
import React, { useRef } from 'react';
import request from 'state/utils/request';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';

import PlanIconLabel from 'components/PlanIconLabel';
import KoalaSelect from 'koala/components/Select';
import { useTranslation } from 'react-i18next';

interface Props {
  value: any;
  handleChange: any;
}
function MembershipFilter(props: Props) {
  const { value, handleChange } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const debouncedLoadOptions: any = useRef(
    _.debounce((inputValue: string, callback: any) => loadOptions(inputValue, callback), 200, {
      maxWait: 2000,
    }),
  );

  // This function is going to fetch the list of plan using the value of the input as a starting point.
  // It's using the request object from the state/utils which takes care of fetching auth automatically.
  const loadOptions: any = (inputValue: string, callback: any) => {
    // Get default users if nothing selected yet
    const url = `${remoteApi.WORKSPACE_DETAILS_API.replace(':workspaceSlug', workspaceSlug)}/search/plans`;
    if (!inputValue) {
      const planParams = {
        filter: {
          title: inputValue,
          archived: false,
        },
        per_page: 10,
      };
      request
        .post(url, { params: planParams })
        .then((response) => {
          const plans = response.data;
          if (plans && plans.length > 0) {
            const options = plans.map((plan: any) => {
              return {
                value: plan.id,
                label: plan.title,
                icon: plan.plan_icon,
              };
            });
            return options;
          } else {
            return [];
          }
        })
        .then(callback);
      return;
    }

    // If we have a search param, use it
    const planParams = inputValue
      ? {
          filter: {
            title: inputValue,
            archived: false,
          },
          per_page: 10,
        }
      : null;
    request
      .post(url, planParams)
      .then((response) => {
        const plans = response.data;
        if (plans && plans.length > 0) {
          const options = plans.map((plan: any) => {
            return {
              id: 'plan',
              value: plan.id,
              label: plan.title,
              icon: plan.plan_icon,
            };
          });
          return options;
        } else {
          return [];
        }
      })
      .then(callback);
  };

  const formatOption = (option: any) => {
    return <PlanIconLabel planIcon={option.icon} planTitle={option.label} size="xsmall" />;
  };

  return (
    <KoalaSelect
      handleChange={(options: any) => handleChange('plan', options)}
      placeholder={t('workspaceFilters.placeholder')}
      selectedOption={value}
      isClearable
      isMultiSelect
      loadOptions={debouncedLoadOptions.current}
      size="xsmall"
      id="plan"
      labelFormat={formatOption}
    />
  );
}

export default React.memo(MembershipFilter);
