import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  display: grid;
  grid-template-columns: 11rem 1fr 40rem;
  grid-template-rows: auto;
  column-gap: ${theme.spacing.x1};
  align-items: center;
  margin-bottom: ${theme.spacing.x1};

  > div {
    display: flex;
    &:first-of-type {
      justify-content: flex-end;
    }

    &:nth-child(2) {
      font-family: monospace, 'Courier New';
    }
  }

  input {
    font-family: monospace, 'Courier New' !important;
    border: 0;
    height: 2.8rem;
  }

  border-left: 2px solid transparent;
  &.selected {
    border-left: 2px solid ${theme.colors.blue};
  }
`;

export const Label = styled.div`
  font-size: 1rem;
  height: 2.8rem;
  line-height: 2.8rem;
  letter-spacing: 1px;
  color: #fff;
  background-color: #6096ec;
  border: 0;
  border-radius: 20px;
  padding: 0 1.6rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;

  &.objective {
    background-color: #60aeb9;
  }

  &.outcome {
    background-color: #f5c02b;
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0;
    padding: 0 ${theme.spacing.x2};
  }

  @media ${theme.devices.desktop} {
    height: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: 0px;
    border: 0;
    border-radius: 16px;
    padding: 0 0.8rem;

    &.outcome {
      font-size: 1.2rem;
      padding: 0 ${theme.spacing.x2};
    }
  }
`;

export const TaskLabel = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.4rem;
  min-width: 2.8rem;
  min-height: 2.8rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.subtleText};
  color: #fff;
  background-color: #d1d1d1;
  border-radius: 50%;
  font-weight: 800;
  position: relative;

  margin-right: ${theme.spacing.x2};

  @media ${theme.devices.desktop} {
    min-width: 2.4rem;
    min-height: 2.4rem;
    font-size: 1rem;
    border: 0;
    border-radius: 16px;
    padding: 0 0.8rem;
  }
`;
