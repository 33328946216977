import React from 'react';

const EditIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3479 4.13247L15.8675 5.65209C16.1121 5.22678 16.0526 4.6744 15.6891 4.3109C15.3256 3.94741 14.7732 3.88793 14.3479 4.13247ZM16.5964 7.78458L16.6 7.78101L17.0927 7.28832C18.3024 6.07855 18.3024 4.11711 17.0927 2.90733C15.8829 1.69756 13.9214 1.69755 12.7117 2.90733L12.2154 3.40357L12.2119 3.4071L3.28345 12.3356C3.28336 12.3357 3.28326 12.3358 3.28316 12.3358C3.28306 12.3359 3.28297 12.336 3.28287 12.3361L2.29069 13.3283C2.10456 13.5144 2 13.7669 2 14.0301V17.0075C2 17.5557 2.44435 18 2.99247 18H5.96989C6.23311 18 6.48555 17.8954 6.67168 17.7093L7.6636 16.7174C7.66378 16.7172 7.66397 16.717 7.66415 16.7168C7.66434 16.7167 7.66452 16.7165 7.66471 16.7163L16.5964 7.78458ZM6.96237 14.6115L14.4911 7.08279L12.9172 5.50894L5.38852 13.0376L6.96237 14.6115ZM3.98495 14.4412V16.0151H5.5588L3.98495 14.4412Z"
      fill="#737373"
    />
  </svg>
);

export default EditIcon;
