import Toggle from 'components/Toggle';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { Plan, Workspace } from 'types';
import * as workspaceUtils from 'utils/workspaceUtils';
import * as planUtils from 'utils/planUtils';
import queryKeys from 'config/queryKeys';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import Loader from 'components/Loader';
import { Link, useLocation, useParams } from 'react-router-dom';
import KoalaIcon from 'koala/components/Icons';
import CopyTextInput from 'components/CopyTextInput';

const Container = styled.div`
  border-top: 1px solid ${theme.colors.N20};
  padding: ${theme.spacing.x2} 0px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Help = styled.p`
  background: ${theme.colors.warningBg};
  padding: ${theme.spacing.x1};
  border-radius: 4px;
  a {
    color: ${theme.colors.blue};
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const PublicInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

interface Props {
  plan: Plan;
  setPlan: (plan: Plan) => void;
}

function PublicAccess(props: Props) {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { plan, setPlan } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const location = useLocation();
  const translationKey = 'modals.share';
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const hasStarterSubscription =
    workspaceUtils.hasStarterSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  // Get the plan details
  const queryKey = [queryKeys.currentPlan, plan.id];
  const staleTime = 0;
  useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
    onSuccess: (response) => {
      const plan: Plan = response.data;
      setPlan(plan);
    },
  });

  // Mutation that will update the plan in the backend
  const [updatePlanMutation, { isLoading }] = useMutation(remoteApi.updatePlan, {
    onSuccess: (planResponse) => {
      queryCache.setQueryData(queryKey, planResponse);
    },
  });

  const handleTogglePublicSharing = (e: any) => {
    const public_access_enabled = e.target.checked;
    let planParams = {
      public_access_enabled,
    };

    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  const v4_5_RequireUpgrade =
    [4, 5].includes(currentWorkspace.pricing_version) && !workspaceUtils.hasBasicSubscription(currentWorkspace);
  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);

  const publicAccessClass = hasStarterSubscription && !v4_5_RequireUpgrade ? '' : 'disabled';
  const missingPermissions = v4_5_RequireUpgrade || !hasStarterSubscription || !hasEditPermission;
  const { public_access_enabled } = plan;

  const url = `https://${process.env.REACT_APP_DOMAIN}${location.pathname}`;

  if (missingPermissions) {
    return (
      <Container>
        <h4>{t(`${translationKey}.publicAccessLabel`)}</h4>
        {v4_5_RequireUpgrade && (
          <Help>
            <>
              {currentMembership && ['owner'].includes(currentMembership.role) && (
                <p>
                  <Trans
                    i18nKey={`${translationKey}.upgradeLink`}
                    components={{ plan: <Link to={`/${workspaceSlug}/settings/billing`} /> }}
                    values={{ planLabel: 'Basic subscription' }}
                  />
                </p>
              )}
              {currentMembership && !['owner'].includes(currentMembership.role) && (
                <p>
                  <Trans
                    i18nKey={`${translationKey}.upgradeLink`}
                    components={{ plan: <b /> }}
                    values={{ planLabel: 'Basic subscription' }}
                  />
                </p>
              )}
            </>
          </Help>
        )}
        {!hasStarterSubscription && (
          <Help>
            <>
              {currentMembership && ['owner'].includes(currentMembership.role) && (
                <p>
                  <Trans
                    i18nKey={`${translationKey}.upgradeLink`}
                    components={{ plan: <Link to={`/${workspaceSlug}/settings/billing`} /> }}
                    values={{ planLabel: 'Starter plan' }}
                  />
                </p>
              )}
              {currentMembership && !['owner'].includes(currentMembership.role) && (
                <p>
                  <Trans
                    i18nKey={`${translationKey}.upgradeLink`}
                    components={{ plan: <b /> }}
                    values={{ planLabel: 'Starter plan' }}
                  />
                </p>
              )}
            </>
          </Help>
        )}
        <ToggleContainer className={publicAccessClass}>
          <Toggle>
            <input
              className="tgl tgl-light"
              id="update_password"
              type="checkbox"
              name="update_password"
              disabled={true}
              defaultChecked={public_access_enabled}
              onChange={handleTogglePublicSharing}
            />
            <label className="tgl-btn" htmlFor="update_password" data-tip data-for="toggleContainer" />
            <div>{t(`${translationKey}.enablePublicPlan`)}</div>
          </Toggle>
          {!public_access_enabled && (
            <PublicInfo>
              <KoalaIcon iconName="team" iconSize={2} />
              <small className="subtle">{t(`${translationKey}.onlyLoggedIn`)}</small>
            </PublicInfo>
          )}
          {public_access_enabled && (
            <>
              <PublicInfo>
                <KoalaIcon iconName="public" iconSize={2} />
                <small className="subtle">{t(`${translationKey}.publicAccessDescription`)}</small>
              </PublicInfo>
              <small className="subtle">
                <Trans
                  i18nKey={`${translationKey}.managePassword`}
                  components={{
                    Link: <Link to={`/${currentWorkspace.slug}/plans/${plan.nano_slug}/settings/sharing`} />,
                  }}
                  values={{ planLabel: 'Starter plan' }}
                />
              </small>
            </>
          )}
          <CopyTextInput text={url} />
        </ToggleContainer>
      </Container>
    );
  }

  return (
    <Container>
      <h4>{t(`${translationKey}.publicAccessLabel`)}</h4>
      <ToggleContainer className={publicAccessClass}>
        <Toggle>
          <input
            className="tgl tgl-light"
            id="update_password"
            type="checkbox"
            name="update_password"
            defaultChecked={public_access_enabled}
            onChange={handleTogglePublicSharing}
          />
          <label className="tgl-btn" htmlFor="update_password" data-tip data-for="toggleContainer" />
          <div>{t(`${translationKey}.enablePublicPlan`)}</div>
          {isLoading && <Loader />}
        </Toggle>
        {!public_access_enabled && <small className="subtle">{t(`${translationKey}.onlyLoggedIn`)}</small>}
        {public_access_enabled && <small className="subtle">{t(`${translationKey}.publicAccessDescription`)}</small>}
        {public_access_enabled && (
          <small className="subtle">
            <Trans
              i18nKey={`${translationKey}.managePassword`}
              components={{
                Link: <Link to={`/${currentWorkspace.slug}/plans/${plan.nano_slug}/settings/sharing`} />,
              }}
              values={{ planLabel: 'Starter plan' }}
            />
          </small>
        )}
        <CopyTextInput text={url} />
      </ToggleContainer>
    </Container>
  );
}

export default PublicAccess;
