import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Outcome } from 'types';

import * as outcomeUtils from 'utils/outcomeUtils';
import * as checkinUtils from 'utils/checkinUtils';
import CurrentGrowth from 'components/CurrentGrowth';
import KoalaCircularProgress, { ProgressData } from 'koala/components/CircularProgress';
import ReactTooltip from 'react-tooltip';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';

const ScoreContainer = styled.div<{ backgroundColor: string; valueColor: string }>`
  padding: ${theme.spacing.x2};
  display: flex;
  gap: ${theme.spacing.x2};
  justify-content: space-between;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;

  .score {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.half};
  }

  .growth {
    display: flex;
    gap: ${theme.spacing.x1};
    flex-direction: column;
    margin-top: ${theme.spacing.half};
    justify-content: space-between;
  }

  big {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1;
    color: ${(props) => props.valueColor};
  }

  small {
    line-height: 1;
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: ${theme.spacing.half};
  }
`;

interface Props {
  outcome: Outcome;
}

function MetricsCard(props: Props) {
  const { outcome } = props;
  const { t } = useTranslation();

  const { current_checkin, outcome_type, score_format, membership } = outcome;

  let format = score_format ? score_format.split('_number_') : ['', ''];

  // Calculate the progress prct
  const progressPrct = outcome.outcome_progress_prct * 100;

  const currentConfidence = current_checkin ? current_checkin.confidence : 'grey';
  const confidenceLetter = checkinUtils.confidenceToColorLetter(currentConfidence);

  const progressData: ProgressData = {
    progressPercentage: progressPrct,
    colorType: confidenceLetter,
    centerLabel: '',
  };

  let score = 'Pending';
  let showFormat = false;

  if (current_checkin && outcome_type === 'no_metric') {
    // if has current checkin and no metric, use confidence
    score = checkinUtils.confidenceToScore(current_checkin.confidence, t);
  } else if (current_checkin) {
    // if has current checkin and uses metric, get score
    score = outcomeUtils.nFormatter(current_checkin.score, 2);
    if (outcome_type === 'kpi') {
    }
    showFormat = true;
  } else if (!current_checkin && outcome_type !== 'no_metric') {
    // if no checkin yet and has metric, get starting value
    score = outcomeUtils.nFormatter(outcome.from || 0, 2);
    showFormat = true;
  }
  const confidenceBackground = checkinUtils.confidenceToBackgroundColor(currentConfidence);
  const confidenceText = checkinUtils.confidenceToValueColor(currentConfidence);

  return (
    <ScoreContainer className="outcome-metrics-card" backgroundColor={confidenceBackground} valueColor={confidenceText}>
      <div className="score">
        <big>
          {showFormat && <>{format[0]}</>}
          {score}
          {showFormat && format[1] && <small>{format[1]}</small>}
        </big>
        <CurrentGrowth outcome={outcome} />
      </div>
      <div className="growth">
        <div>
          {outcome.outcome_type === 'improve_metric' && (
            <>
              <KoalaCircularProgress data={progressData} size="xsmall" dataFor={`${outcome.id}-progress-prct`} />
              <ReactTooltip id={`${outcome.id}-progress-prct`} place="bottom" type="dark" effect="solid">
                {progressPrct}%
              </ReactTooltip>
            </>
          )}
        </div>
        <KoalaAvatar user={membership ? membership.user : null} size={2} />
      </div>
    </ScoreContainer>
  );
}

export default React.memo(MetricsCard);
