import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import CheckinBlock from 'components/CheckinBlock';
import UpgradePanel from 'components/UpgradePanel';
import * as workspaceUtils from 'utils/workspaceUtils';
import styled from 'styled-components';
import theme from 'theme';
import { Checkin, Outcome } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
  checkins: Checkin[];
  outcome: Outcome;
}

const CheckinList = styled.div`
  padding-bottom: ${theme.spacing.x2};
  .checkin {
    padding-bottom: ${theme.spacing.x1};
    margin-bottom: ${theme.spacing.x1};

    border-bottom: 1px solid ${theme.colors.N10};
    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`;

function CheckinsTab(props: Props) {
  const { checkins, outcome } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const hasStarterSubscription =
    workspaceUtils.hasStarterSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  if (!hasStarterSubscription) {
    return (
      <UpgradePanel
        title={t('panels.outcome.upgradeCheckinHistory') ?? 'Upgrade to the Starter plan to see the check-ins history'}
        description={
          t('panels.outcome.upgradeCheckinHistoryDescr') ??
          'Check-ins history is not available on the Free plan—but you can start a trial to have access to this feature.'
        }
      />
    );
  }

  return (
    <CheckinList>
      {checkins.map((checkin) => {
        return <CheckinBlock outcome={outcome} checkin={checkin} key={checkin.id} compact />;
      })}
    </CheckinList>
  );
}

export default React.memo(CheckinsTab);
