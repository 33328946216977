import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 5.03283V7.22228H3.12667L0 5.03283ZM2.59111 17.2223H7.22222V13.9795L2.59111 17.2223ZM12.7778 13.9801V17.2223H17.4083L12.7778 13.9801ZM0 12.7778V14.9673L3.12778 12.7778H0ZM17.4094 2.77783H12.7778V6.02061L17.4094 2.77783ZM20 14.9678V12.7778H16.8717L20 14.9678ZM20 7.22228V5.03283L16.8728 7.22228H20ZM7.22222 2.77783H2.59111L7.22222 6.02061V2.77783Z"
      fill="#00247D"
    />
    <path
      d="M13.9666 12.7779L19.3622 16.5562C19.6238 16.2901 19.811 15.9551 19.9122 15.5846L15.9038 12.7779H13.9666ZM7.22216 12.7779H6.03271L0.637714 16.5557C0.927158 16.8501 1.29827 17.0607 1.71438 17.1585L7.22216 13.3018V12.7779ZM12.7777 7.22235H13.9672L19.3622 3.44457C19.0727 3.15013 18.7022 2.93957 18.286 2.8418L12.7777 6.69846V7.22235ZM6.03271 7.22235L0.637714 3.44457C0.376047 3.71124 0.188825 4.04569 0.0871582 4.41624L4.09549 7.22235H6.03271Z"
      fill="#CF1B2B"
    />
    <path
      d="M20 11.6667H11.6667V17.2223H12.7778V13.9801L17.4083 17.2223H17.7778C18.3983 17.2223 18.9589 16.9662 19.3622 16.5562L13.9667 12.7778H15.9039L19.9122 15.5845C19.9639 15.3973 20 15.2034 20 15.0001V14.9678L16.8717 12.7778H20V11.6667ZM0 11.6667V12.7778H3.12778L0 14.9673V15.0001C0 15.6062 0.243889 16.1545 0.637778 16.5556L6.03278 12.7778H7.22222V13.3017L1.71444 17.1578C1.87778 17.1967 2.04667 17.2223 2.22222 17.2223H2.59111L7.22222 13.9795V17.2223H8.33333V11.6667H0ZM20 5.00005C20 4.39394 19.7561 3.84561 19.3622 3.4445L13.9672 7.22228H12.7778V6.69839L18.2861 2.84172C18.1222 2.80339 17.9533 2.77783 17.7778 2.77783H17.4094L12.7778 6.02061V2.77783H11.6667V8.33339H20V7.22228H16.8728L20 5.03283V5.00005ZM7.22222 2.77783V6.02061L2.59111 2.77783H2.22222C1.60111 2.77783 1.04111 3.03394 0.637778 3.4445L6.03278 7.22228H4.09556L0.0872222 4.41617C0.0361111 4.60339 0 4.79672 0 5.00005V5.03283L3.12667 7.22228H0V8.33339H8.33333V2.77783H7.22222Z"
      fill="#EEEEEE"
    />
    <path
      d="M11.6667 8.33339V2.77783H8.33333V8.33339H0V11.6667H8.33333V17.2223H11.6667V11.6667H20V8.33339H11.6667Z"
      fill="#CF1B2B"
    />
  </svg>
);
