import { EmojiData, Picker } from 'emoji-mart';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { CachedReaction } from 'types';
import * as reactionUtils from 'utils/reactionUtils';
import ReactionEmoji from './ReactionEmoji';
import { CustomEmojisList } from './CustomEmojis';
import { KoalaSizes } from 'koala/shared/global';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
interface Props {
  reactions: CachedReaction[];
  addCallback?: (emotion: string) => void;
  removeCallback?: (reactionId: string) => void;
  showDefaultEmojis?: boolean;
  size?: (typeof KoalaSizes)[number];
  hideAddButton?: boolean;
  addButtonType?: 'emoji' | 'like';
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  flex-wrap: wrap;
`;
const ReactionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const PickerContainer = styled.div`
  height: 0px;
  width: 0px;
`;

const PickerInner = styled.div`
  width: 320px;
  height: fit-content;

  button:hover {
    background: ${theme.colors.N5};
  }
`;

function Reactions(props: Props) {
  const { reactions, addCallback, removeCallback, showDefaultEmojis, size, hideAddButton, addButtonType } = props;
  const { t } = useTranslation();
  let emojiList = reactionUtils.reactionHelper(reactions);
  const buttonRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const emojiRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = !addCallback && !removeCallback;

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (buttonRef.current && buttonRef.current.contains(event.target)) {
        return;
      }
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const addEmoji = (emojiData: EmojiData | string, e: React.SyntheticEvent) => {
    e.stopPropagation();
    setShowEmojiPicker(false);
    if (!addCallback) {
      return;
    }
    if (typeof emojiData === 'string') {
      addCallback(emojiData);
    } else if (typeof emojiData !== 'string' && emojiData.colons) {
      addCallback(emojiData.colons);
    }
  };

  const handleToggleEmoji = (emoji: string, e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (!emojiList || !removeCallback) {
      return;
    }
    const emojiDetails = emojiList[emoji];
    const reaction = emojiDetails
      ? emojiDetails.reactions.find((reaction) => reaction.membershipId === currentMembership.id)
      : null;

    if (reaction) {
      removeCallback(reaction.reactionId);
    } else {
      addEmoji(emoji, e);
    }
  };

  const togglePicker = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowEmojiPicker(!showEmojiPicker);
  };

  const userCustomEmojis = currentUser && currentUser.referrals_count ? CustomEmojisList : undefined;

  const thumbsUp = ':+1:';
  const thumbsUpCount = emojiList[thumbsUp] ? emojiList[thumbsUp].count : 0;
  return (
    <ReactionContainer>
      <ButtonContainer>
        {!isReadOnly && !hideAddButton && (
          <div ref={buttonRef}>
            {addButtonType === 'like' ? (
              <KoalaButton size={size} onClick={togglePicker} appearance="secondary">
                {t('shared.like')}
              </KoalaButton>
            ) : (
              <KoalaIconButton iconName="emoji" size={size} onClick={togglePicker} />
            )}
          </div>
        )}
        {showDefaultEmojis && thumbsUpCount === 0 && (
          <ReactionEmoji
            emoji={thumbsUp}
            reactions={[]}
            size={size}
            currentMembership={currentMembership}
            handleToggleEmoji={(key, e: React.SyntheticEvent) => handleToggleEmoji(key, e)}
            isReadOnly={isReadOnly}
            defaultShow={true}
            customClassName={'suggestion'}
            isSquare={addButtonType === 'like'}
          />
        )}
        {emojiList &&
          Object.keys(emojiList).map((emoji, i) => {
            return (
              <ReactionEmoji
                emoji={emoji}
                reactions={emojiList[emoji] ? emojiList[emoji].reactions : []}
                size={size}
                currentMembership={currentMembership}
                handleToggleEmoji={(key, e: React.SyntheticEvent) => handleToggleEmoji(key, e)}
                key={i}
                isReadOnly={isReadOnly}
                isSquare={addButtonType === 'like'}
              />
            );
          })}
      </ButtonContainer>

      <PickerContainer>
        <PickerInner ref={emojiRef} onClick={(e) => e.stopPropagation()} className="emoji-picker-inner-container">
          {showEmojiPicker ? (
            <Picker
              set="twitter"
              title="Pick emoji"
              emoji="point_up"
              style={{
                position: 'relative',
                zIndex: 99,
                marginTop: `${theme.spacing.half}`,
                width: '100%',
              }}
              onClick={addEmoji}
              custom={userCustomEmojis}
            />
          ) : null}
        </PickerInner>
      </PickerContainer>
    </ReactionContainer>
  );
}

export default Reactions;
