import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.03249 8H20.9999M3.00006 16H18.9675"
      stroke="#6D6D6D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99994 4.00002L6.99994 20M16.9999 4.00006L13.9999 20"
      stroke="#6D6D6D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
