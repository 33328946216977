import React from 'react';
import * as TabilityTypes from 'types';

import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

// Components
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  follower: TabilityTypes.Follower;
}

function FollowButton(props: Props) {
  const { follower } = props;
  const { t } = useTranslation();

  const queryCache = useQueryCache();

  const queryKey = [queryKeys.following];
  const [deleteFollowerMutation, { isLoading: isDeletingFollower }]: [any, any] = useMutation(
    remoteApi.deleteFollower,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
      },
    },
  );

  const handleButtonClick = () => {
    deleteFollowerMutation(follower.id);
  };

  const followerLoading = isDeletingFollower;

  return (
    <KoalaButton size="small" onClick={handleButtonClick} loading={followerLoading} appearance="secondary">
      {t('workspaceStandups.unfollow')}
    </KoalaButton>
  );
}

export default FollowButton;
