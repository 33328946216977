import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  padding: ${theme.spacing.x2} ${theme.spacing.x4};
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 1;
  }

  @media ${theme.devices.tablet} {
    padding-left: ${theme.spacing.x2};
  }
`;

const GoBack = styled.div``;

function PlaygroundHeader() {
  const { t } = useTranslation();

  return (
    <Container>
      <GoBack>
        <KoalaButton isLink to="/">
          &larr; {t('account.backToWorkspace')}
        </KoalaButton>
      </GoBack>
    </Container>
  );
}

export default React.memo(PlaygroundHeader);
