import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import parse from 'parse-link-header';
import { useSelector, shallowEqual } from 'react-redux';
// API
import * as remoteApi from 'api/remote';

// Components

import KoalaButton from 'koala/components/Button';
import { AxiosResponse } from 'axios';
import OutcomeExpandable from 'components/OutcomeExpandable';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const Container = styled.div`
  margin-top: ${theme.spacing.x1};
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  border-bottom: 0;
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.blockBorder};
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

export const BlockContent = styled.div`
  font-weight: 700;
  grid-area: content;
  background: transparent;
  margin: 0;
  height: 100%;

  display: flex;
  align-items: center;
`;

export const BlockMeta = styled.div`
  font-weight: 700;
  grid-area: meta;
  display: flex;
  align-items: center;
  height: 100%;
`;

const OutcomeContainer = styled.div`
  .outcome-expanded {
    border-bottom: 1px solid ${theme.colors.N10};
  }
  .outcome-expanded:last-of-type {
    border-bottom: 0;
  }
`;

interface Props {
  membership: TabilityTypes.Membership;
  setOutcomesLoading: Function;
}

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

function Inbox(props: Props) {
  // Query that fetches all the outcomes with pending checkins
  const { membership, setOutcomesLoading } = props;
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );
  const activeOutcomeQueryKey = [queryKeys.outcomes, membership.id, false, { limit: 5 }];
  const {
    isLoading,
    isFetchingMore,
    data: outcomesResponse,
    fetchMore,
    canFetchMore,
  }: any = useInfiniteQuery(activeOutcomeQueryKey, remoteApi.fetchMembershipActiveOutcomes, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });
  const { t } = useTranslation();

  useEffect(() => {
    setOutcomesLoading(isLoading);
  }, [setOutcomesLoading, isLoading]);

  if (isLoading) {
    return null;
  }

  let activeOutcome: AxiosResponse[] = [];

  if (outcomesResponse) {
    activeOutcome = outcomesResponse;
  }

  // Display empty state if you're at inbox zero
  if (activeOutcome.length === 0 || activeOutcome[0].data.length === 0) {
    return null;
  }

  return (
    <Container>
      <HeaderRow>
        {t('workspaceInbox.activeOutcomes', {
          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
        })}
      </HeaderRow>
      <OutcomeContainer>
        {activeOutcome.map((group, i: number) => {
          return (
            <Fragment key={i}>
              {group.data.map((outcome: TabilityTypes.Outcome, index: number) => {
                return <OutcomeExpandable outcome={outcome} key={index} hideExpand={true} />;
              })}
            </Fragment>
          );
        })}
      </OutcomeContainer>

      {canFetchMore && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMore()}
            loading={isFetchingMore}
            disabled={isFetchingMore}
            appearance="secondary"
          >
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
    </Container>
  );
}

export default Inbox;
