import { KoalaSizes } from 'koala/shared/global';
import { colors } from 'koala/shared/styles';
import React from 'react';
import styled from 'styled-components';

import { UXPREFERENCES } from 'config/constants';

import RedBG from '../../shared/images/assets/colorblind_red.svg';
import GreenBG from '../../shared/images/assets/colorblind_green.svg';
import YellowBG from '../../shared/images/assets/colorblind_yellow.svg';
import BlueBG from '../../shared/images/assets/colorblind_blue.svg';
import GreyBG from '../../shared/images/assets/colorblind_grey.svg';

const storedUXPreferences = localStorage.getItem(UXPREFERENCES);
const UXPreferences = storedUXPreferences ? JSON.parse(storedUXPreferences) : {};
const useColorBlind = UXPreferences.useColorBlind ?? false;

const ConfidenceContainer = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const completedColorToBackgroundColor = {
  [colors.R40]: colors.R5,
  [colors.Y40]: colors.Y10,
  [colors.G40]: colors.G5,
  [colors.N20]: colors.N5,
};

const blindColor = {
  [colors.R40]: colors.R20,
  [colors.Y40]: colors.Y20,
  [colors.G40]: colors.G20,
  [colors.N20]: colors.N20,
  [colors.B40]: colors.B20,
};

const ColorBlock = styled.div<ColorBlockProps>`
  background-color: ${({ color, isCompleted }) => (isCompleted ? completedColorToBackgroundColor[color] : color)};
  height: ${(props) => (props.width ? props.width : '2rem')};
  width: ${(props) => (props.width ? props.width : '2rem')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '20%')};

  &.colorblind {
    background-color: ${(props) => blindColor[props.color]};

    background-image: url(${(props) => {
      switch (props.color) {
        case colors.R40:
          return RedBG;
        case colors.Y40:
          return YellowBG;
        case colors.G40:
          return GreenBG;
        case colors.N20:
          return GreyBG;
        case colors.B40:
          return BlueBG;
        default:
          return '';
      }
    }});
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
    position: relative;
  }

  &.color-block--xsmall {
    height: 1.2rem;
    width: 1.2rem;
  }
  &.color-block--small {
    height: 2rem;
    width: 2rem;
  }
  &.color-block--medium {
    height: 3rem;
    width: 3rem;
  }
  &.color-block--large {
    height: 4rem;
    width: 4rem;
  }
  &.clickable {
    :hover {
      filter: brightness(0.9);
      cursor: pointer;
    }
  }
`;

const PendingFlag = styled.div`
  height: 0.8rem;
  width: 0.8rem;
  background: ${colors.O50};
  border-radius: 50%;
  position: absolute;
  right: -0.2rem;
  top: -0.2rem;
  z-index: 1;
`;

const completedColorToTextColor = {
  [colors.R40]: colors.R60,
  [colors.Y40]: colors.Y80,
  [colors.G40]: colors.G60,
  [colors.N20]: colors.N60,
};

const CompletedFlag = styled.div<ColorBlockProps>`
  font-weight: 1000;
  font-size: 1.1rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => completedColorToTextColor[color]};
`;

const CompletedColourBlindFlag = styled.div<ColorBlockProps>`
  font-weight: 1000;
  font-size: 1.1rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -6px;
  right: -6px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid ${({ color }) => blindColor[color]};
  color: ${({ color }) => completedColorToTextColor[color]};
`;

interface ColorBlockProps {
  /** Main color for the block */
  color: string;
  /** Optional size from KoalaSizes */
  size?: (typeof KoalaSizes)[number];
  /** Optional width for block (Note: will get overwritten by size if set) */
  width?: string;
  /** Optional border radius controll for block (Note: will get overwritten by size if set) */
  borderRadius?: string;
  isPending?: boolean;
  isCompleted?: boolean;
  id?: string;
  dataFor?: string;
  /** Optional className for ColorBlock */
  className?: string;
  /** Optional styling for ColorBlock */
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
}

function KoalaColorBlock(props: ColorBlockProps) {
  const { className, size, isPending, isCompleted, id, dataFor, onClick } = props;

  let colorBlockClass = `${className ?? ''}`;
  if (size) {
    colorBlockClass += ` color-block--${size}`;
  }

  if (onClick) {
    colorBlockClass += ` clickable`;
  }

  let colorblindClass = useColorBlind ? 'colorblind' : '';

  return (
    <ConfidenceContainer id={id} data-tip data-for={dataFor} className="koala-color-block--container" onClick={onClick}>
      {isPending && <PendingFlag />}
      <ColorBlock {...props} className={`${colorBlockClass} ${colorblindClass}`}>
        {isCompleted && useColorBlind && (
          <CompletedColourBlindFlag className="flag" {...props}>
            &#10003;
          </CompletedColourBlindFlag>
        )}
        {isCompleted && !useColorBlind && (
          <CompletedFlag className="flag" {...props}>
            &#10003;
          </CompletedFlag>
        )}
      </ColorBlock>
    </ConfidenceContainer>
  );
}

KoalaColorBlock.defaultProps = {
  color: colors.N50,
};

export default KoalaColorBlock;
