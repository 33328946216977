import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.x3};
  &.with-tabs {
    margin-top: ${theme.spacing.x4};
    border-bottom: 1px solid ${theme.colors.blockBorder};
  }
`;
