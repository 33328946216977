import React from 'react';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { Workspace } from 'types';

import { ContentAction } from './NewsItem';
import { Trans } from 'react-i18next';
interface Props {
  event: any;
}

function Block(props: Props) {
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { event } = props;

  const { membership, source } = event;

  if (!membership) {
    return null;
  }

  const { user } = membership;
  const name = user.fullname || user.email;

  return (
    <>
      <ContentAction>
        <Trans
          i18nKey="newsfeed.outcomeCreated"
          values={{ name, title: source.title, outcome: translate(workspace, CustomTermKey.OUTCOME, 1).toLowerCase() }}
          components={{ b: <b /> }}
        />
      </ContentAction>
    </>
  );
}

export default React.memo(Block);
