import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as remoteApi from 'api/remote';
import { Objective, Plan } from 'types';
import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import AsyncSelect from 'react-select/async';
import PlanIconLabel from 'components/PlanIconLabel';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  objectiveId: string;
}

function MoveObjective(props: Props) {
  const queryCache = useQueryCache();
  const { objectiveId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translationKey = 'modals.moveObjective';
  const [objective, setObjective] = useState<Objective>();
  const [plan, setPlan] = useState<Plan>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  // Get the outcome details
  const queryKey = [queryKeys.currentObjective, objectiveId];
  const staleTime = 0;
  useQuery(queryKey, remoteApi.fetchObjectiveDetails, {
    staleTime,
    onSuccess: (response: any) => {
      const objective: Objective = response.data;
      setPlan(objective.plan);
      setObjective(objective);
    },
  });

  const [updateObjectiveMutation] = useMutation(remoteApi.updateObjective, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.objectives);
      queryCache.invalidateQueries(queryKeys.currentObjective);
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = () => {
    if (!plan || !objective) {
      return;
    }
    const params = {
      plan_id: plan.id,
    };
    updateObjectiveMutation({
      objectiveId: objective.id,
      objective: params,
    });
    dispatch(setGlobalModalContent(''));
  };

  const handleSelection = (selected: any) => {
    setPlan(selected);
  };

  const fetchData = (input: string) => {
    let planParams;
    if (input) {
      planParams = {
        filter: {
          title: input,
        },
        order_direction: 'asc',
        order_attribute: 'title',
        limit: 5,
      };
    } else {
      planParams = {
        order_direction: 'asc',
        order_attribute: 'title',
        limit: 5,
      };
    }
    // @ts-ignore
    return remoteApi.fetchPlans(queryKeys.plans, currentWorkspace.slug, planParams, undefined).then((response) => {
      const plans = response.data;
      return plans;
    });
  };

  const handleLoadOptions = (input: any) => {
    return fetchData(input);
  };

  const allowSave = objective && plan && plan.id !== objective.plan_id;

  const label = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1);
  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`, { label })}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        {objective && (
          <>
            <FormField>
              <Trans
                components={{ b: <b /> }}
                i18nKey={`${translationKey}.description`}
                values={{ title: objective.title }}
              />
            </FormField>
            <FormField>
              <label>{t(`${translationKey}.plan`)}</label>
              <AsyncSelect
                defaultOptions
                value={plan}
                loadOptions={handleLoadOptions}
                onChange={(option) => handleSelection(option)}
                getOptionLabel={(option) => option.title}
                getOptionValue={(option) => option.title}
                formatOptionLabel={(option) => <PlanIconLabel plan={option} size="small" />}
              />
            </FormField>
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!allowSave}>
          {t(`shared.save`)}
        </KoalaButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default MoveObjective;
