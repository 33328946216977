import React, { Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import Wordmark from 'components/Wordmark';

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x8};
`;

const Container = styled.div`
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    margin-bottom: ${theme.spacing.x4};
  }
`;

const MessageContainer = styled.div`
  min-height: 50%;
`;

interface Props {
  membership: any;
}

function Suspended(props: Props) {
  const { membership } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const isAdmin = ['admin', 'owner'].includes(membership.role);

  // Displays the list of templates
  return (
    <Container>
      <Helmet>
        <title>Your workspace is suspended | Tability</title>
      </Helmet>

      <WordmarkWrapper>
        <Wordmark type="dark" />
      </WordmarkWrapper>
      <MessageContainer>
        <h2>Your workspace has been suspended</h2>
        <p>This workspace has been suspended to a missing subscription payment.</p>
        {isAdmin && (
          <Fragment>
            <p>Please update your billing information to resolve this issue.</p>
            <p>
              <Link to={`/${workspaceSlug}/settings/billing`} className="button primary">
                Update billing info
              </Link>
            </p>
          </Fragment>
        )}
        {!isAdmin && <p>Please contact the owner of this workspace to update the billing information.</p>}
      </MessageContainer>
    </Container>
  );
}

export default Suspended;
