import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import * as routes from 'routes';
import * as amplitudeUtils from 'utils/amplitude';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';

// Components
import PlanPrezNav from 'components/PlanPrezNav';

import AccessDenied from 'components/AccessDenied';
import { ChromeContent } from 'components/Chrome';
import Loader from 'components/Loader';
import NotFound from 'components/NotFound';
import TabbyGood from './tabby_good.png';

// Tracker component
import Tracker from './Tracker';
import { useTranslation } from 'react-i18next';
import KoalaButton from 'koala/components/Button';

const DashboardWrapper = styled.div<{ isDarkMode: boolean }>`
  background: ${(props) => (props.isDarkMode ? '#000' : '#FFF')};
  min-height: 100vh;
  width: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupBanner = styled.div`
  width: 50rem;
  max-width: 50rem;
  background: #fff;
  padding: ${theme.spacing.x4};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};

  img {
    width: 15rem;
  }

  p {
    b {
      font-size: 2.2rem;
    }
    text-align: center;

    &.tinyimg {
      img {
        width: 8rem;
      }
    }

    &.actions {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        margin: ${theme.spacing.x1};
      }
    }
  }
`;

const EmptyState = styled.div`
  padding: ${theme.spacing.x3} ${theme.spacing.x4} 0 ${theme.spacing.x8};
`;

const EmptySection = styled.div`
  margin-bottom: ${theme.spacing.x6};
  background: #f3f3f3;
  border-radius: 16px;
  padding: ${theme.spacing.x3};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  a {
    color: ${theme.colors.blue};
  }
`;

function WorkspacePlanPrezDashboard() {
  const history = useHistory();
  const { planId, workspaceSlug } = useParams<{ planId: string; workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plan.present.dashboard.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, planId, 'track'];
  const staleTime = 0;
  const [outcomesByObjective, setOutcomesByObjective]: [any, any] = useState({});

  // Get the plan details
  const {
    isError,
    error,
    data: planResponse,
    isLoading: planIsLoading,
  }: any = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
  });

  // Get the objectives
  const { data: objectivesResponse, isLoading: objectivesAreLoading } = useQuery(
    [queryKeys.objectives, planId],
    remoteApi.fetchPlanObjectives,
    {
      staleTime,
    },
  );

  // Get the outcomes
  const { data: outcomesResponse, isLoading: outcomesAreLoading } = useQuery(
    [queryKeys.outcomes, planId],
    remoteApi.fetchPlanOutcomes,
    {
      staleTime,
      onSuccess: (response: any) => {
        const outcomes: any = response.data;
        const outcomesByObjective: any = {};
        // Iterate on each outcome and group items by objective_id in an array
        outcomes.forEach((outcome: any) => {
          if (outcomesByObjective[outcome.objective_id]) {
            outcomesByObjective[outcome.objective_id].push(outcome);
          } else {
            outcomesByObjective[outcome.objective_id] = [outcome];
          }
        });
        setOutcomesByObjective(outcomesByObjective);
      },
    },
  );

  const plan = planResponse ? planResponse.data : null;
  const objectives = objectivesResponse ? objectivesResponse.data : [];
  const outcomes = outcomesResponse ? outcomesResponse.data : [];

  const dataIsLoading = planIsLoading || objectivesAreLoading || outcomesAreLoading;

  // We display the loader until we have loaded the plan and objectives.
  // The reason why we wait for objectives to be loaded here is to check in the Track components
  // if we should display the empty state, or the tracking content
  if (!plan || dataIsLoading) {
    return (
      <Fragment>
        <ChromeContent>
          <LoadingContainer>
            <Loader size="big" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  const writeRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    planId,
  );

  // Display empty state if we don't have objectives
  if (outcomes.length === 0) {
    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | {plan.title} dashboard | Tability
          </title>
        </Helmet>
        <ChromeContent style={{ position: 'relative' }}>
          <DashboardWrapper isDarkMode={isDarkMode}>
            <PlanPrezNav plan={plan} isDashboard isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
            <EmptyState>
              <EmptySection>
                <h3>{t('workspacePlan.empty')}</h3>
                <p>{t('workspacePlan.insights.emptyInfo')}</p>
                <div>
                  <KoalaButton onClick={() => history.push(writeRoute)}>
                    {t('workspacePlan.insights.emptyEdit')}
                  </KoalaButton>
                </div>
              </EmptySection>
            </EmptyState>
          </DashboardWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  // Display empty state if plan is still in draft mode
  if (plan.state === 'draft') {
    const writeRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
      ':planId',
      planId,
    );

    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | {plan.title} dashboard | Tability
          </title>
        </Helmet>
        <ChromeContent style={{ position: 'relative' }} isGreyBackground={true}>
          <DashboardWrapper isDarkMode={isDarkMode}>
            <PlanPrezNav plan={plan} isDashboard isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
            <Tracker
              plan={plan}
              objectives={objectives}
              outcomes={outcomes}
              outcomesByObjective={outcomesByObjective}
              isDarkMode={isDarkMode}
            />
            <Mask>
              <PopupBanner>
                <p>
                  <img src={TabbyGood} alt="Your plan ready to track" />
                </p>
                <p>
                  <b>{t('workspacePlan.draft.title')}</b>
                </p>
                <p className="subtle">{t('workspacePlan.draft.description')}</p>
                <p className="actions">
                  <button onClick={() => history.push(writeRoute)} className="primary">
                    {t('workspacePlan.draft.edit')}
                  </button>
                </p>
              </PopupBanner>
            </Mask>
          </DashboardWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  // Displays the list of templates
  return (
    <>
      <Helmet>
        <title>
          {currentWorkspace.name} | {plan.title} dashboard | Tability
        </title>
      </Helmet>
      <ChromeContent isGreyBackground={true}>
        <DashboardWrapper isDarkMode={isDarkMode}>
          <PlanPrezNav plan={plan} isDashboard isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
          <Tracker
            plan={plan}
            objectives={objectives}
            outcomes={outcomes}
            outcomesByObjective={outcomesByObjective}
            isDarkMode={isDarkMode}
          />
        </DashboardWrapper>
      </ChromeContent>
    </>
  );
}

export default WorkspacePlanPrezDashboard;
