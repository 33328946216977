/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useState, useEffect } from 'react';
import * as amplitudeUtils from 'utils/amplitude';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { generateSlug } from 'utils/naming';
import { webmailDomains } from 'utils/emailDomains';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { USER_REFERRAL_TOKEN } from 'config/constants';

// API
import { createWorkspace } from 'api/remote';

// Components
import FormField from 'components/FormField';
import { useHistory } from 'react-router-dom';

// Actions
import { sessionActions } from 'state/actions/';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { Trans, useTranslation } from 'react-i18next';
import KoalaLoader from 'koala/components/Loader';

// Components

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${theme.spacing.x2};
  align-items: center;
  height: 100%;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${theme.spacing.x8};

  width: 100%;
  gap: ${theme.spacing.x8};

  h4 {
    margin-bottom: ${theme.spacing.x1};
    font-weight: 800;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: ${theme.colors.subtleText};
  }

  @media ${theme.devices.laptop} {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};
  }
`;

const About = styled.div`
  flex: 1;
  width: 50rem;
  img {
    height: 50rem;
  }
  @media ${theme.devices.laptop} {
    display: none;
  }
`;
const Content = styled.div`
  width: 50rem;
  justify-self: flex-end;

  @media ${theme.devices.laptop} {
    margin-top: 0;
  }
`;

const Progress = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${theme.spacing.x1} 0;
  gap: ${theme.spacing.x2};
`;

const Circle = styled.div`
  height: 0.8rem;
  width: 0.8rem;

  border-radius: 50%;
  background: ${theme.colors.B10};

  &.active {
    background: ${theme.colors.B50};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  margin-top: ${theme.spacing.x2};

  @media (max-width: 800px) {
    margin-top: ${theme.spacing.x4};
    margin-left: 0;
    input,
    select {
      &.small {
        width: 100%;
      }

      &.tiny {
        width: 50%;
      }
    }
  }
`;

const ErrorList = styled.ul`
  margin-bottom: 1.6rem;
  color: ${theme.colors.R60};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${theme.spacing.x2};
`;

const Footer = styled.div`
  padding-bottom: ${theme.spacing.x3};

  button.secondary {
    color: ${theme.colors.subtleText};
    text-decoration: none;
    font-weight: normal;
  }
`;

const FormLine = styled.div`
  display: flex;
  align-items: center;
  input {
    margin-right: ${theme.spacing.x1};

    &.spacer {
      margin-left: ${theme.spacing.x1};
    }
  }

  &.space-bottom {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const RadioField = styled.div`
  display: flex;
  flex-direction: column;

  .form-label {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${theme.colors.subtleText};
    margin-bottom: ${theme.spacing.x1};
  }

  .radio-group {
    display: flex;
    gap: ${theme.spacing.x1};
    position: relative;
    // hide radio button and put it behind label so not clickable
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    &.vertical {
      flex-direction: column;
    }

    &.wrap,
    &.wrap-long {
      flex-wrap: wrap;
    }

    input[type='radio']:focus + label {
      border: 1px solid ${theme.colors.blue};
    }

    label {
      color: ${theme.colors.black};
      background-color: #fff;
      cursor: pointer;
      flex: 1;
      padding: ${theme.spacing.x2};
      transition: all 0.2s ease;
      border: solid 1px ${theme.colors.blockBorder};
      border-radius: 4px;
      &:hover {
        background: ${theme.colors.N3};
        cursor: pointer;
      }

      span {
        display: inline-block;
        padding: ${theme.spacing.half} ${theme.spacing.x1};
        border-radius: 4px;
        background: ${theme.colors.N5};
        font-size: 1.1rem;
        font-weight: 500;
        color: ${theme.colors.N70};
        margin-bottom: ${theme.spacing.x2};
      }

      &.disabled {
        cursor: not-allowed;
        filter: grayscale(0.7);
        opacity: 0.7;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        g,
        path,
        circle {
          stroke: ${theme.colors.subtleText};
        }
      }
    }

    input[type='radio']:checked + label {
      color: #fff;
      background: ${theme.colors.B5};
      border: 1px solid ${theme.colors.B30};
      color: ${theme.colors.N100};

      span {
        background: ${theme.colors.B50};
        font-size: 1.1rem;
        font-weight: 500;
        color: ${theme.colors.N0};
      }
    }

    &.compact {
      label {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 1.2rem;
        padding: ${theme.spacing.x1} ${theme.spacing.x1};
      }

      input[type='radio']:checked + label {
        color: ${theme.colors.B50};
      }
    }

    &.wrap {
      label {
        min-width: 10rem;
      }
    }

    &.wrap-long {
      label {
        min-width: 20rem;
      }
    }
  }

  &.icons-only {
    .radio-group {
      display: flex;
      align-items: center;
    }
    label {
      display: flex;
      align-items: center;
    }
  }
`;

function DashboardWorkspacesNew() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [creator_team_size, setCreatorTeamSize] = useState('');
  const [use_accepted_domain, setUseAcceptedDomain] = useState(false);

  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const emailDomain = currentUser.email.split('@')[1];

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.onboarding.new_workspace', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Mutation that will create the workspace in the backend
  const [createWorkspaceMutation, { isError, isLoading, error }]: [any, any] = useMutation(createWorkspace, {
    onSuccess: (response: any) => {
      const workspace = response.data;
      queryCache.invalidateQueries(queryKeys.currentUserMemberships); // Reload the current user workspaces
      history.push(`/${workspace.slug}/onboarding/about`);
    },
  });

  const queryCache = useQueryCache();

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: ['Sorry, an error occurred on the remote server.'],
      };
    } else {
      errors = error.response ? error.response.data.errors : {};
    }
  }

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;
    let slug;

    switch (name) {
      case 'creator_team_size':
        setCreatorTeamSize(value);
        break;
      case 'name':
        setName(value);
        slug = generateSlug(value);
        setSlug(slug);
        break;
      case 'slug':
        slug = generateSlug(value);
        setSlug(slug);
        break;
      case 'use_accepted_domain':
        setUseAcceptedDomain(value);
        break;
    }
  };

  const handleLogout = (e: any) => {
    dispatch(sessionActions.logout());
  };

  function getReferralId() {
    return (window.Rewardful && window.Rewardful.referral) || 'checkout_' + new Date().getTime();
  }

  const handleCreate = (e: any) => {
    e.preventDefault();
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let accepted_domains: any = [];
    let discovery_enabled = false;
    if (use_accepted_domain) {
      accepted_domains = [emailDomain];
      discovery_enabled = true;
    }

    // Get user referral token if it exists
    const storedUserReferralToken = localStorage.getItem(USER_REFERRAL_TOKEN);
    const user_referral_token = storedUserReferralToken ? JSON.parse(storedUserReferralToken) : null;

    const workspaceParams = {
      name,
      slug,
      creator_team_size,
      time_zone,
      accepted_domains,
      discovery_enabled,
      referral: getReferralId(),
      user_referral_token,
    };
    createWorkspaceMutation(workspaceParams);
  };

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'name':
        return t('errors.workspaceName');
      case 'slug':
        return t('errors.workspaceID');
      default:
        return '';
    }
  };

  // Error classnames used to highlight input fields that have errors
  const nameClassname = errors['name'] ? 'error' : '';
  const slugClassname = errors['slug'] ? 'error' : '';

  const isPrivateEmail = !webmailDomains.includes(emailDomain);

  return (
    <Wrapper>
      <Helmet>
        <title>{t('dashboardWorkspacesNew.title')} | Tability</title>
      </Helmet>
      <Container>
        <Content>
          <h2>Let's start by creating your workspace</h2>
          <p>This is where you will be collaborating with your team</p>
          <Form onSubmit={handleCreate}>
            <FormField>
              <h4>Workspace name</h4>
              <input
                type="text"
                name="name"
                className={`${nameClassname}`}
                autoComplete="off"
                placeholder={t('dashboardWorkspacesNew.nameLabel') ?? 'Workspace name'}
                value={name}
                required={false}
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <h4>Workspace ID</h4>
              <input
                type="text"
                name="slug"
                value={slug}
                className={`${slugClassname}`}
                autoComplete="off"
                placeholder={t('dashboardWorkspacesNew.idLabel') ?? 'Workspace ID'}
                required={true}
                onChange={handleChange}
              />
            </FormField>
            {isPrivateEmail && (
              <FormLine className="space-bottom">
                <input
                  type="checkbox"
                  name="use_accepted_domain"
                  onChange={handleChange}
                  checked={use_accepted_domain}
                  id="use_accepted_domain"
                />
                <label htmlFor="use_accepted_domain">
                  <Trans i18nKey="dashboardWorkspacesNew.domain" components={{ b: <b /> }} values={{ emailDomain }} />
                </label>
              </FormLine>
            )}
            <FormField>
              <h4>Company size</h4>
              <>
                <RadioField>
                  <div className="radio-group compact">
                    <>
                      <input
                        type="radio"
                        id="mon"
                        required
                        checked={creator_team_size === '1'}
                        name="creator_team_size"
                        value="1"
                        onChange={handleChange}
                      />
                      <label htmlFor="mon">1</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="tue"
                        required
                        checked={creator_team_size === '2-10'}
                        name="creator_team_size"
                        value="2-10"
                        onChange={handleChange}
                      />
                      <label htmlFor="tue">2-10</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="wed"
                        required
                        checked={creator_team_size === '11-50'}
                        name="creator_team_size"
                        value="11-50"
                        onChange={handleChange}
                      />
                      <label htmlFor="wed">11-50</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="thu"
                        required
                        checked={creator_team_size === '51-250'}
                        name="creator_team_size"
                        value="51-250"
                        onChange={handleChange}
                      />
                      <label htmlFor="thu">51-250</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="fri"
                        required
                        checked={creator_team_size === '251-1k'}
                        name="creator_team_size"
                        value="251-1k"
                        onChange={handleChange}
                      />
                      <label htmlFor="fri">251-1k</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="sat"
                        required
                        checked={creator_team_size === '1k-5k'}
                        name="creator_team_size"
                        value="1k-5k"
                        onChange={handleChange}
                      />
                      <label htmlFor="sat">1k-5k</label>
                    </>
                    <>
                      <input
                        type="radio"
                        id="sun"
                        required
                        checked={creator_team_size === '5k+'}
                        name="creator_team_size"
                        value="5k+"
                        onChange={handleChange}
                      />
                      <label htmlFor="sun">5k+</label>
                    </>
                  </div>
                </RadioField>
              </>
            </FormField>
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <Progress>
                <Circle className="active" />
                <Circle className="active" />
                <Circle />
                <Circle />
                <Circle />
              </Progress>
              <KoalaButton submit disabled={isLoading}>
                {isLoading && (
                  <>
                    <KoalaLoader />
                    &nbsp;Adding sample data to your workspace
                  </>
                )}
                {!isLoading && <>{t('dashboardWorkspacesNew.next')}</>}
              </KoalaButton>
            </Actions>
          </Form>
        </Content>
        <About>
          <div>
            <img
              src="https://res.cloudinary.com/tability/image/upload/v1681537799/static_assets/welcome_p034pw.png"
              alt="Welcome to Tability"
            />
          </div>
        </About>
      </Container>
      <Footer>
        <KoalaTextButton onClick={handleLogout} appearance="subtle">
          {t('shared.logout')}
        </KoalaTextButton>
      </Footer>
    </Wrapper>
  );
}

export default DashboardWorkspacesNew;
