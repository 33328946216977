import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import { useTranslation } from 'react-i18next';
import { DashboardWidget, Membership } from 'types';
import KoalaAvatar from 'koala/components/Avatar';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import { AxiosResponse } from 'axios';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;
  gap: ${theme.spacing.x2};
`;

interface Props {
  handleSaveWidget: (type: string, source: string, source_id: string, widgetId?: string) => void;
  isLoading: boolean;
  isEditing?: boolean;
  widget?: DashboardWidget;
}

function UserProfileWidgetDetails(props: Props) {
  const { handleSaveWidget, isLoading, widget } = props;
  const [membershipId, setMembershipId] = useState<string>('');
  const [membership, setMembership] = useState<Membership | null>(widget?.source);
  const { t } = useTranslation();

  const queryKey = [queryKeys.currentMembership, membershipId];
  const { isLoading: isMembershipLoading } = useQuery(queryKey, remoteApi.fetchMembershipDetails, {
    staleTime: 0,
    onSuccess: (response: AxiosResponse<Membership>) => {
      const membership = response.data;
      setMembership(membership);
    },
    enabled: !!membershipId,
  });

  const handleSelectMembership = (value: string) => {
    setMembershipId(value);
  };

  const handleSave = () => {
    handleSaveWidget('user-profile', 'membership', membershipId, widget?.id);
  };

  return (
    <Container>
      {membership && (
        <UserSearchableDropdown
          trigger={
            <KoalaButton loading={isLoading} appearance="subtle">
              <KoalaAvatar size="small" user={membership.user} />
              <span className="owner-option-name">
                {membership.cached_fullname ?? membership.cached_email} {t('shared.clickToChange')}
              </span>
            </KoalaButton>
          }
          callback={handleSelectMembership}
          position="left"
        />
      )}
      {!membership && (
        <UserSearchableDropdown
          trigger={
            <KoalaButton loading={isLoading} prefixIcon="personPlus">
              <span className="owner-option-name">{t('workspaceDashboards.selectUser')}</span>
            </KoalaButton>
          }
          callback={handleSelectMembership}
          position="left"
        />
      )}
      {membership && (
        <div>
          <KoalaButton loading={isMembershipLoading} onClick={handleSave}>
            Save
          </KoalaButton>
        </div>
      )}
    </Container>
  );
}

export default UserProfileWidgetDetails;
