import React from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Translations
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

import { OverviewCard, OverviewLine } from './';
import KoalaIcon from 'koala/components/Icons';
import ReactTooltip from 'react-tooltip';
import * as routes from 'routes';

interface Props {
  plansStats: any;
  filterParams: any;
}

function Component(props: Props) {
  const { plansStats, filterParams } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  const totalPlansWarning = plansStats.outcomes_warning_plans_count + plansStats.empty_plans_count;

  const prctPlans = (plansCount: number) => {
    if (plansStats.total_plans_count === 0) {
      return <span>0%</span>;
    }

    const prct = (plansCount / plansStats.total_plans_count) * 100;
    return <span>{Math.round(prct)}%</span>;
  };

  // Function to navigate to the plans page with a filter
  const goToPlansFilter = (filter: string) => {
    let encodedTeamFilter = '';
    if (filterParams?.team?.length > 0) {
      encodedTeamFilter = `&team=${encodeURIComponent(JSON.stringify(filterParams.team))}`;
    }
    history.push(
      `${routes.WORKSPACE_PLANS_ROUTE.replace(
        ':workspaceSlug',
        currentWorkspace.slug,
      )}?insight=${filter}${encodedTeamFilter}`,
    );
  };

  return (
    <>
      <h3>{t('workspaceInsights.plansOverviewHeader')}</h3>
      <OverviewCard>
        <OverviewLine className="header">
          <span>{t('workspaceInsights.plansOverview.totalActivePlans')}</span>
          <span>{plansStats.total_plans_count}</span>
        </OverviewLine>
        <OverviewLine role="button" tabIndex={0} className="clickable" onClick={() => goToPlansFilter('positive_ncs')}>
          <span>{t('workspaceInsights.plansOverview.withPositiveConfidence')}</span>
          <span>{plansStats.positive_ncs_plans_count}</span>
          {prctPlans(plansStats.positive_ncs_plans_count)}
        </OverviewLine>
        <OverviewLine role="button" tabIndex={0} className="clickable" onClick={() => goToPlansFilter('negative_ncs')}>
          <span>{t('workspaceInsights.plansOverview.withNegativeConfidence')}</span>
          <span className={plansStats.negative_ncs_plans_count > 0 ? 'red' : ''}>
            {plansStats.negative_ncs_plans_count}
          </span>
          {prctPlans(plansStats.negative_ncs_plans_count)}
        </OverviewLine>
        {false && (
          <>
            <OverviewLine>
              <span>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase()} progress = 0%</span>
              <span className={plansStats.zero_outcome_progress_plans_count > 0 ? 'red' : ''}>
                {plansStats.zero_outcome_progress_plans_count}
              </span>
            </OverviewLine>
            <OverviewLine>
              <span>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase()} progress = 0%</span>
              <span className={plansStats.zero_initiative_progress_plans_count > 0 ? 'red' : ''}>
                {plansStats.zero_initiative_progress_plans_count}
              </span>
            </OverviewLine>
          </>
        )}
        <OverviewLine className="header">
          <span>{t('workspaceInsights.goalSettingWarnings')}</span>
          <span>{totalPlansWarning}</span>
          {prctPlans(totalPlansWarning)}
        </OverviewLine>
        <OverviewLine role="button" tabIndex={0} className="clickable" onClick={() => goToPlansFilter('no_checkins')}>
          <span>{t('workspaceInsights.plansOverview.withouthCheckins')}</span>
          <span className={plansStats.no_checkins_plans_count > 0 ? 'yellow' : ''}>
            {plansStats.no_checkins_plans_count}
          </span>
          {prctPlans(plansStats.no_checkins_plans_count)}
        </OverviewLine>

        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() => goToPlansFilter('too_many_outcomes')}
        >
          <span>
            {t('workspaceInsights.plansOverview.withTooManyOutcomes', {
              label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
            })}
            <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-too-many-outcomes" />
          </span>
          <span className={plansStats.outcomes_warning_plans_count > 0 ? 'yellow' : ''}>
            {plansStats.outcomes_warning_plans_count}
          </span>
          <ReactTooltip
            place="bottom"
            type="dark"
            className="tooltip"
            effect="solid"
            id="explain-too-many-outcomes"
            delayHide={500}
            clickable={true}
          >
            {t('shared.tooManyOutcomes.tooltip')}{' '}
            <a
              href="https://guides.tability.app/docs/about-tability/best-practices#limit-the-number-of-goals-per-plan"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {t('shared.learnMore')}
            </a>
          </ReactTooltip>
          {prctPlans(plansStats.outcomes_warning_plans_count)}
        </OverviewLine>
        <OverviewLine role="button" tabIndex={0} className="clickable" onClick={() => goToPlansFilter('no_outcomes')}>
          <span>{t('workspaceInsights.plansOverview.emptyPlans')}</span>
          <span className={plansStats.empty_plans_count > 0 ? 'red' : ''}>{plansStats.empty_plans_count}</span>
          {prctPlans(plansStats.empty_plans_count)}
        </OverviewLine>
      </OverviewCard>
    </>
  );
}

export default Component;
