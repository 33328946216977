export const setupScore = (data: any) => {
  const { plansStats, outcomesStats, initiativesStats, membershipsStats } = data;

  const { membershipScore, membershipAuditIssues } = getMembershipsInsights(membershipsStats);
  const { planScore, planAuditIssues } = getPlansInsights(plansStats);
  const { outcomeScore, outcomeAuditIssues } = getOutcomesInsights(outcomesStats);
  const { initiativeScore, initiativeAuditIssues } = getInitiativesInsights(initiativesStats);

  const totalScore = Math.round((membershipScore + planScore + outcomeScore + initiativeScore) / 4);

  return {
    totalScore,
    membershipScore,
    membershipAuditIssues,
    planScore,
    planAuditIssues,
    outcomeScore,
    outcomeAuditIssues,
    initiativeScore,
    initiativeAuditIssues,
  };
};

const getMembershipsInsights = (membershipsStats: any) => {
  let membershipScore = 100;
  let membershipAuditIssues = {
    not_enough_members: false,
    not_enough_active_users: false, // TODO: swap out for admins count
    not_enough_with_outcomes: false,
    with_too_many_outcomes: false,
    // TODO: not enough admins?
  };
  const { total_memberships_count, active_users_count, too_many_outcomes_count, with_outcomes_count } =
    membershipsStats;

  if (total_memberships_count === 0) {
    membershipScore = 0;
    membershipAuditIssues = {
      not_enough_members: true,
      not_enough_active_users: false,
      not_enough_with_outcomes: false,
      with_too_many_outcomes: false,
    };
  } else {
    // Take out 15 if we have less than 5 members
    if (total_memberships_count < 2) {
      membershipScore -= 15;
      membershipAuditIssues.not_enough_members = true;
    }

    // Remove 25 points if we have less than 20% active users
    if (active_users_count / total_memberships_count < 0.2) {
      membershipScore -= 25;
      membershipAuditIssues.not_enough_active_users = true;
    }

    // Remove 25 points if we don't have anyone with outcomes
    if (with_outcomes_count === 0) {
      membershipScore -= 25;
      membershipAuditIssues.not_enough_with_outcomes = true;
    }

    // Remove 25 points if we have users with too many outcomes
    if (too_many_outcomes_count > 0) {
      membershipScore -= 25;
      membershipAuditIssues.with_too_many_outcomes = true;
    }
  }

  return {
    membershipScore,
    membershipAuditIssues,
  };
};
const getPlansInsights = (plansStats: any) => {
  let planScore = 100;
  let planAuditIssues = {
    not_enough_plans: false,
    too_many_empty_plans: false,
    too_many_without_checkins: false,
    with_too_many_outcomes: false,
  };
  const { empty_plans_count, no_checkins_plans_count, outcomes_warning_plans_count, total_plans_count } = plansStats;

  if (total_plans_count === 0) {
    planScore = 0;
    planAuditIssues = {
      not_enough_plans: true,
      too_many_empty_plans: false,
      too_many_without_checkins: false,
      with_too_many_outcomes: false,
    };
  } else {
    // Take out 15 if 10%+ of plans are empty
    if (empty_plans_count / total_plans_count > 0.1) {
      planScore -= 25;
      planAuditIssues.too_many_empty_plans = true;
    }

    // Remove 25 points if 10%+ of plans have no checkins
    if (no_checkins_plans_count / total_plans_count > 0.1) {
      planScore -= 25;
      planAuditIssues.too_many_without_checkins = true;
    }

    // Remove 25 points if we have plans with too many outcomes
    if (outcomes_warning_plans_count > 0) {
      planScore -= 25;
      planAuditIssues.with_too_many_outcomes = true;
    }
  }

  return {
    planScore,
    planAuditIssues,
  };
};

const getOutcomesInsights = (outcomesStats: any) => {
  let outcomeScore = 100;
  let outcomeAuditIssues = {
    not_enough_outcomes: false,
    without_owners: false,
    too_many_not_measurable: false,
    too_many_with_default_targets: false,
    with_overdue_checkins: false,
  };
  const {
    pnone_outcomes_count,
    default_target_outcomes_count,
    pending_checkin_overdue_count,
    without_owner_outcomes_count,
    total_outcomes_count,
  } = outcomesStats;

  if (total_outcomes_count === 0) {
    outcomeScore = 0;
    outcomeAuditIssues = {
      not_enough_outcomes: true,
      without_owners: false,
      too_many_not_measurable: false,
      too_many_with_default_targets: false,
      with_overdue_checkins: false,
    };
  } else {
    // Take out 20 if we don't have enough outcomes
    if (total_outcomes_count < 4) {
      outcomeScore -= 20;
      outcomeAuditIssues.not_enough_outcomes = true;
    }

    // Take out 20 if we have outcomes without owners
    if (without_owner_outcomes_count > 0) {
      outcomeScore -= 20;
      outcomeAuditIssues.without_owners = true;
    }

    // Remove 20 points if 25%+ of outcomes have no checkins
    if (pnone_outcomes_count / total_outcomes_count > 0.25) {
      outcomeScore -= 20;
      outcomeAuditIssues.too_many_not_measurable = true;
    }

    // Remove 20 points if we have too many outcomes with a default target
    if (default_target_outcomes_count / total_outcomes_count > 0.25) {
      outcomeScore -= 20;
      outcomeAuditIssues.too_many_with_default_targets = true;
    }

    // Remove 20 points if we have outcomes with overdue checkins
    if (pending_checkin_overdue_count > 0) {
      outcomeScore -= 20;
      outcomeAuditIssues.with_overdue_checkins = true;
    }
  }

  return {
    outcomeScore,
    outcomeAuditIssues,
  };
};

const getInitiativesInsights = (initiativesStats: any) => {
  let initiativeScore = 100;
  let initiativeAuditIssues = {
    not_enough_initiatives: false,
    with_overdue_initiatives: false,
    too_many_backlog_initiatives: false, // TODO: don't count integrations
    not_enough_in_progress: false, // TODO: don't count integrations
  };
  const {
    backlog_initiatives_count,
    in_progress_initiatives_count,
    overdue_initiatives_count,
    total_initiatives_count,
  } = initiativesStats;

  if (total_initiatives_count === 0) {
    initiativeScore = 0;
    initiativeAuditIssues = {
      not_enough_initiatives: true,
      with_overdue_initiatives: false,
      too_many_backlog_initiatives: false,
      not_enough_in_progress: false,
    };
  } else {
    // Take out 25 if we don't have enough initiatives
    if (total_initiatives_count < 4) {
      if (total_initiatives_count < 2) {
        initiativeScore -= 25;
      } else {
        initiativeScore -= 15;
      }
      initiativeAuditIssues.not_enough_initiatives = true;
    }

    if (total_initiatives_count < 2) {
      initiativeScore -= 25;
      initiativeAuditIssues.not_enough_initiatives = true;
    }

    // Remove 25 points if we have overdue initiatives
    if (overdue_initiatives_count > 0) {
      initiativeScore -= 25;
      initiativeAuditIssues.with_overdue_initiatives = true;
    }

    // Remove 25 points if we have 100% of initiative are in backlog
    if (backlog_initiatives_count / total_initiatives_count > 0.95) {
      if (backlog_initiatives_count / total_initiatives_count > 0.99) {
        initiativeScore -= 25;
      } else {
        initiativeScore -= 15;
      }
      initiativeAuditIssues.too_many_backlog_initiatives = true;
    }

    // Remove 25 points if we have 0 initiatives in progress
    if (in_progress_initiatives_count === 0) {
      initiativeScore -= 25;
      initiativeAuditIssues.not_enough_in_progress = true;
    }
  }

  return {
    initiativeScore,
    initiativeAuditIssues,
  };
};
