import styled from 'styled-components';
import theme from 'theme';

export const ChromeWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'content';
  height: 100%;
  min-height: 100%;
`;

export const ChromeWrapperWithSettingsNav = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'side-nav header'
    'side-nav content';
  height: 100%;
  min-height: 100%;
`;

export const ChromeHeader = styled.div`
  grid-area: header;
  background: #fff;
  border-bottom: 1px solid #fff;
  display: flex;
  flex-direction: column;

  &.white-bg {
    background: #fff;
    border-bottom: 1px solid #fff;
  }
`;

export const ChromeNav = styled.div`
  grid-area: nav;
  background: ${theme.colors.N5};
`;
export const ChromeSideNav = styled.div`
  grid-area: side-nav;
  overflow: hidden;
  background: #fff;
`;

interface contentProps {
  isGreyBackground?: boolean;
}

export const ChromeContent = styled.div<contentProps>`
  grid-area: content;
  overflow: auto;
  background: #fff;
  position: relative;

  &.white-bg {
    background: #fff;
  }

  &.grey-bg {
    background: ${theme.colors.N5};
  }

  &.mask {
    position: relative;
    overflow: hidden;
  }
`;
