import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import * as amplitudeUtils from 'utils/amplitude';
import { Helmet } from 'react-helmet';
import Cookies from 'universal-cookie';
import theme from 'theme';

// API
import * as remoteApi from 'api/remote';

// Routes
import * as routes from 'routes';

// Components
import AccessDenied from 'components/AccessDenied';
import Loader from 'components/Loader';
import { ChromeContent } from 'components/Chrome';
import NotFound from 'components/NotFound';
import FormField from 'components/FormField';
import Wordmark from 'components/Wordmark';
import UpgradeIcon from 'components/_assets/UpgradeIcon';

// Tracker component
// import Tracker from './Tracker';
import KoalaButton from 'koala/components/Button';
import Overview from './Overview';
import { useTranslation } from 'react-i18next';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
`;

const AccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: ${theme.spacing.x3};
  }

  form {
    padding-bottom: ${theme.spacing.x8};
    width: 40rem;

    button {
      margin-right: ${theme.spacing.x2};
    }
    .danger {
      color: ${theme.colors.red};
    }
  }
`;

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x8};
`;

const UpgradeBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
  background: rgba(197, 165, 239, 0.25);
  border-radius: 8px;

  max-width: 60rem;
  margin: ${theme.spacing.x3} auto;

  p {
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

function PublicPlanPresentation() {
  const cookies = new Cookies();
  const { planId, workspaceSlug } = useParams<{ planId: string; workspaceSlug: string }>();
  const location = useLocation();
  const { t } = useTranslation(undefined, { useSuspense: false });
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.plan.embed.view', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const COOKIENAME = '_tbty_pp';
  const COOKIEPATH = location.pathname;

  const public_access_password = cookies.get(COOKIENAME);

  const params = {
    public_access_password,
  };

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, planId, params];
  const staleTime = 0;
  const [upgradeRequired, setUpgradeRequired] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [password, setPassword] = useState('');

  // Get the plan details
  const {
    isError,
    error,
    data: planResponse,
    isLoading: planIsLoading,
  }: any = useQuery(queryKey, remoteApi.fetchPublicPlanDetails, {
    staleTime,
    onError: (error: any) => {
      const errorString = error.response.data.error;
      const upgradeRequired = error.response.status === 402;
      setUpgradeRequired(upgradeRequired);
      const accessDenied = errorString.includes('Public access is disabled');
      setAccessDenied(accessDenied);
      const passwordRequired = errorString.includes('Password is missing');
      setPasswordRequired(passwordRequired);
      if (public_access_password) {
        setPasswordError(true);
      }
    },
  });

  const planPath = routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    planId,
  );

  const plan = planResponse ? planResponse.data : null;
  const dataIsLoading = planIsLoading;

  // We display the loader until we have loaded the plan and objectives.
  // The reason why we wait for objectives to be loaded here is to check in the Track components
  // if we should display the empty state, or the tracking content
  if (dataIsLoading) {
    return (
      <Fragment>
        <ChromeContent>
          <LoadingContainer>
            <Loader size="big" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (upgradeRequired) {
    return (
      <Fragment>
        <Helmet>
          <title>{t('publicPlan.upgrade.title')} | Tability</title>
        </Helmet>
        <AccessContainer>
          <WordmarkWrapper>
            <Wordmark type="dark" />
          </WordmarkWrapper>

          <h2>{t('publicPlan.upgrade.header')}</h2>
          <UpgradeBanner>
            <p>
              <UpgradeIcon />
              <b>{t('publicPlan.upgrade.limited')}</b>
            </p>
            <p>{t('publicPlan.upgrade.upgradeToEnable')}</p>
          </UpgradeBanner>
          <KoalaButton isLink to={planPath} appearance="subtle">
            {t('publicPlan.viewButton')}
          </KoalaButton>
        </AccessContainer>
      </Fragment>
    );
  }

  if (accessDenied) {
    return (
      <Fragment>
        <Helmet>
          <title>{t('publicPlan.restricted.title')} | Tability</title>
        </Helmet>
        <AccessContainer>
          <WordmarkWrapper>
            <Wordmark type="dark" />
          </WordmarkWrapper>

          <h2>{t('publicPlan.restricted.header')}</h2>
          <p>{t('publicPlan.restricted.infoPresentation')}</p>
          <p>
            <KoalaButton isLink to={planPath} appearance="subtle">
              {t('publicPlan.viewButton')}
            </KoalaButton>
          </p>
        </AccessContainer>
      </Fragment>
    );
  }

  const handlePasswordChange = (e: any) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleSavePassword = (e: any) => {
    e.preventDefault();
    cookies.set(COOKIENAME, password, {
      path: COOKIEPATH,
      secure: true,
    });
    window.location.reload();
  };

  if (passwordRequired) {
    return (
      <Fragment>
        <Helmet>
          <title>{t('publicPlan.password.title')} | Tability</title>
        </Helmet>
        <AccessContainer>
          <WordmarkWrapper>
            <Wordmark type="dark" />
          </WordmarkWrapper>

          <h2>{t('publicPlan.password.header')}</h2>
          <form onSubmit={handleSavePassword}>
            <FormField>
              <label>{t('publicPlan.password.passwordLabel')}</label>
              <input type="password" value={password} onChange={handlePasswordChange} />
            </FormField>
            {passwordError && (
              <FormField>
                <span className="danger">{t('publicPlan.password.error')}</span>
              </FormField>
            )}
            <FormField>
              <KoalaButton submit>{t('shared.submit')}</KoalaButton>
              <KoalaButton isLink to="/" appearance="subtle">
                {t('publicPlan.password.return')}
              </KoalaButton>
            </FormField>
          </form>
        </AccessContainer>
      </Fragment>
    );
  }

  if (!plan) {
    return (
      <Fragment>
        <ChromeContent>
          <LoadingContainer>
            <Loader size="big" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  const { workspace } = plan;

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('publicPlan.title', { plan: plan.title })} | Tability
        </title>
      </Helmet>
      <Container>
        <Overview plan={plan} />
      </Container>
    </Fragment>
  );
}

export default PublicPlanPresentation;
