import React, { useState, useEffect, Fragment } from 'react';
import * as TabilityTypes from 'types';
import { useDispatch } from 'react-redux';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as amplitudeUtils from 'utils/amplitude';
import yaml from 'js-yaml';

import { REFERENCE_TEMPLATE_STORAGE_KEY } from 'config/constants';

import { setReferenceTemplate } from 'state/actions/sessionActions';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import Loader from 'components/Loader';
import Wordmark from 'components/Wordmark';

// API
import * as remoteApi from 'api/remote';

// Actions
import { sessionActions } from 'state/actions/';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: ${theme.spacing.x8};

  min-width: 60rem;
  width: 60rem;

  a {
    color: ${theme.colors.blue};
  }

  @media (max-width: 800px) {
    width: 100%;
    min-width: initial;
    margin-top: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};

    .hide-mobile {
      display: none;
    }

    .special-mobile {
      position: relative;
      bottom: ${theme.spacing.x3};
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${theme.spacing.x4} 0 ${theme.spacing.x2} 0;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Progress = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 800px) {
    padding: ${theme.spacing.x2} 0;
  }
`;

const Circle = styled.div`
  height: 0.8rem;
  width: 0.8rem;

  border-radius: 50%;
  background: #8cc1b5;

  margin-left: ${theme.spacing.x1};

  &.active {
    background: #0a5055;
  }
`;

const Footer = styled.div`
  margin-bottom: ${theme.spacing.x3};

  button.secondary {
    color: ${theme.colors.subtleText};
    text-decoration: none;
    font-weight: normal;
  }
`;

const Instruction = styled.p`
  padding: ${theme.spacing.x2} 0;

  font-size: 2.2rem;
`;

const PlanContent = styled.div`
  background: #f3f3f3;
  padding: ${theme.spacing.x3};
  border-radius: 8px;
  margin: ${theme.spacing.x2} 0;
`;

const LineGrid = styled.div`
  display: grid;
  grid-template-columns: 11rem 1fr;
  grid-template-rows: auto;
  column-gap: ${theme.spacing.x2};
  align-items: center;
  margin-bottom: ${theme.spacing.x1};

  > div {
    display: flex;
    &:first-of-type {
      justify-content: flex-end;
    }

    &:nth-child(2) {
      font-family: monospace, 'Courier New';
    }
  }
`;

const Label = styled.div`
  font-size: 1rem;
  height: 2.8rem;
  line-height: 2.8rem;
  letter-spacing: 1px;
  color: #fff;
  background-color: #6096ec;
  border: 0;
  border-radius: 20px;
  padding: 0 1.6rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;

  &.objective {
    background-color: #60aeb9;
  }

  &.outcome {
    background-color: #f5c02b;
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0;
    padding: 0 ${theme.spacing.x2};
  }
`;

const TaskLabel = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.4rem;
  min-width: 2.8rem;
  min-height: 2.8rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.subtleText};
  color: #fff;
  background-color: #d1d1d1;
  border-radius: 50%;
  font-weight: 800;
  position: relative;

  margin-right: ${theme.spacing.x2};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.x2};
  button {
    margin-right: 1.6rem;
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function Inbox(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { workspace } = props;

  const [template, setTemplate]: any = useState(null);

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.onboarding.template', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const handleSkip = (e: any) => {
    e.preventDefault();
    history.push(`/${workspace.slug}/plans/${workspace.dummy_plan_nano_slug}/outcomes`);
  };

  // Check if we have a template in local storage
  const storedReferenceTemplateValue = localStorage.getItem(REFERENCE_TEMPLATE_STORAGE_KEY);
  const storedReferenceTemplate = storedReferenceTemplateValue ? JSON.parse(storedReferenceTemplateValue) : null;

  // Construct the query key using the plan Id
  const queryKeyTemplate = [queryKeys.publicTemplates, storedReferenceTemplate];
  const staleTime = 0;

  // Get the template details
  const { isFetching } = useQuery(queryKeyTemplate, remoteApi.fetchPublicTemplateDetails, {
    staleTime,
    onSuccess: (response) => {
      setTemplate(response.data);
    },
    onError: () => {
      // Clean up all existing template references once a plan is created
      localStorage.removeItem(REFERENCE_TEMPLATE_STORAGE_KEY);
      // @ts-ignore
      dispatch(setReferenceTemplate(null));
      history.push(`/${workspace.slug}/onboarding/team`);
    },
  });

  const handleLogout = (e: any) => {
    dispatch(sessionActions.logout());
  };

  const handleCreateFromTemplate = () => {
    dispatch(setReferenceTemplate(template.nano_slug));
    dispatch(setGlobalModalContent(`plan::create.plan`));
  };

  let objectives = [];
  try {
    const content: any = yaml.load(template.yaml_content);
    objectives = content.objectives;
  } catch (e) {}

  return (
    <Wrapper>
      <Helmet>
        <title>{workspace.name} | Create your first plan | Tability</title>
      </Helmet>
      <Container>
        <Wordmark type="dark" />
        <Header>
          <h1>Create your first plan to track your goals</h1>
          <Progress>
            <Circle />
            <Circle />
            <Circle />
            <Circle />
            <Circle className="active" />
          </Progress>
        </Header>
        <Instruction>
          We've detected a template!
          {isFetching && <Loader />}
        </Instruction>
        {template && (
          <div>
            <h3>OKRs to {template.title}</h3>
            <PlanContent>
              {objectives.map((objective: any) => {
                const outcomes = objective.outcomes || [];
                return (
                  <Fragment>
                    <LineGrid>
                      <div>
                        <Label className="objective">{translate(workspace, CustomTermKey.OBJECTIVE, 1)}</Label>
                      </div>
                      <div>{objective.title}</div>
                    </LineGrid>
                    {outcomes.map((outcome: any) => {
                      const initiatives = outcome.initiatives || [];
                      return (
                        <Fragment>
                          <LineGrid>
                            <div>
                              <Label className="outcome">#</Label>
                            </div>
                            <div>{outcome.title}</div>
                          </LineGrid>
                          {initiatives.map((initiative: any) => {
                            return (
                              <Fragment>
                                <LineGrid>
                                  <div></div>
                                  <div>
                                    <div>
                                      <TaskLabel>&#10003;</TaskLabel>
                                    </div>
                                    {initiative.title}
                                  </div>
                                </LineGrid>
                              </Fragment>
                            );
                          })}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              })}
            </PlanContent>
          </div>
        )}
        <Actions>
          <KoalaButton onClick={handleCreateFromTemplate} disabled={isFetching} loading={isFetching}>
            Create a plan using this template
          </KoalaButton>
          <KoalaButton onClick={handleSkip} appearance="subtle">
            Take me to the app
          </KoalaButton>
        </Actions>
      </Container>
      <Footer>
        <KoalaTextButton onClick={handleLogout} appearance="subtle">
          Logout
        </KoalaTextButton>
      </Footer>
    </Wrapper>
  );
}

export default Inbox;
