import React from 'react';

const FireIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3369_114914)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.71001 4.72308C8.82813 4.65252 8.96316 4.61526 9.10075 4.61526C9.22935 4.61526 9.35571 4.64781 9.46812 4.70967C12.8852 6.29727 14.8202 9.59388 14.7007 12.3501C14.6498 13.5085 14.235 14.5802 13.4315 15.3632C12.628 16.1462 11.4736 16.604 10.0121 16.6069C9.42498 16.643 8.8365 16.5624 8.28073 16.3694C7.72055 16.175 7.20491 15.8706 6.76416 15.4739C6.32342 15.0772 5.96646 14.5964 5.71432 14.0597C5.46216 13.523 5.31991 12.9413 5.29591 12.3488L5.29566 12.3425C5.27815 11.6569 5.44884 10.9796 5.78909 10.3842C6.12933 9.78873 6.6262 9.29788 7.22573 8.96491C7.33108 8.9064 7.45619 8.89494 7.57041 8.93334C7.68463 8.97175 7.7774 9.05647 7.826 9.16675C8.03491 9.64082 8.32749 10.0721 8.68862 10.4404C9.11238 9.88756 9.30733 9.17329 9.30157 8.3885C9.29471 7.45368 9.00105 6.45233 8.50951 5.59968C8.42904 5.46538 8.39965 5.30647 8.42689 5.15211C8.45456 4.99526 8.5388 4.85401 8.66364 4.7551C8.67838 4.74342 8.69387 4.73273 8.71001 4.72308Z"
        fill="#FF8819"
      />
    </g>
    <defs>
      <clipPath id="clip0_3369_114914">
        <rect width="12" height="12" fill="white" transform="translate(4 4.61526)" />
      </clipPath>
    </defs>
  </svg>
);

export default FireIcon;
