import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import { useSelector, shallowEqual } from 'react-redux';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';

import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import AccountSettingsNav from 'components/AccountSettingsNav';
import KoalaButton from 'koala/components/Button';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  h2,
  h3 {
    margin: ${theme.spacing.x2} 0 ${theme.spacing.x1} 0;
  }
  p {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const StepsContainer = styled.div`
  @media ${theme.devices.tablet} {
    input {
      width: 100%;
    }
  }
`;

const ConfirmMessage = styled.div`
  padding: ${theme.spacing.x2};
  background: ${theme.colors.successBg};
  margin: ${theme.spacing.x2} 0;
`;

const ErrorMessage = styled.div`
  padding: ${theme.spacing.x2};
  background: ${theme.colors.dangerBg};
  margin: ${theme.spacing.x2} 0;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const FormLine = styled.div`
  display: flex;
  align-items: center;
  label {
    margin: 0;
    padding: 0;
  }
  input {
    margin-right: ${theme.spacing.x1};
  }
`;

const AppDownloads = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  flex-wrap: wrap;
`;

function AccountPassword() {
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const { t } = useTranslation();
  const [current_password, setCurrentPassword] = useState('');
  const [otp_validation, setOTPValidation] = useState('');
  const [otp_required_for_login, setOTPRequiredForLogin] = useState(currentUser.otp_required_for_login);
  const [url2FA, setURL2FA] = useState(null);

  const [showConfirm, setShowConfirm] = useState(false);
  const [showError, setShowError] = useState(false);

  const [update2FAMutation, { isLoading }] = useMutation(remoteApi.update2FA, {
    onMutate: () => {
      setShowConfirm(false);
      setShowError(false);
    },
    onSuccess: (response) => {
      setShowConfirm(true);
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    },
    onError: (response) => {
      setShowError(true);
    },
  });

  useQuery('create2FA', remoteApi.create2FA, {
    onSuccess: (response) => {
      setURL2FA(response.data.url_2fa);
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'current_password':
        // @ts-ignore
        setCurrentPassword(value);
        break;
      case 'otp_required_for_login':
        // @ts-ignore
        setOTPRequiredForLogin(value);
        break;
      case 'otp_validation':
        // @ts-ignore
        setOTPValidation(value);
        break;
    }
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    const params = {
      current_password,
      otp_validation,
      otp_required_for_login,
    };
    update2FAMutation({
      user: params,
    });
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t('account2FA.title')} | Tability</title>
      </Helmet>
      <ChromeSideNav>
        <AccountSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('account2FA.enable.title')} </h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormLine>
              <input
                type="checkbox"
                name="otp_required_for_login"
                id="otp_required_for_login"
                value={otp_required_for_login}
                required={true}
                onChange={handleChange}
              />
              <label htmlFor="otp_required_for_login">{t('account2FA.enable.checkbox')}</label>
            </FormLine>
            {otp_required_for_login && url2FA && (
              <StepsContainer>
                <h2>{t('account2FA.enable.step1Title')}</h2>
                <p>{t('account2FA.enable.step1Information')}</p>
                <AppDownloads>
                  <a
                    href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="button download-button"
                  >
                    {t('account2FA.enable.downloadAppStore')}
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="button download-button"
                  >
                    {t('account2FA.enable.downloadGooglePlay')}
                  </a>
                </AppDownloads>
                <h2>{t('account2FA.enable.step2Title')}</h2>
                <p>{t('account2FA.enable.step2Information')}</p>
                <QRCode
                  // @ts-ignore
                  value={url2FA}
                />
                <h2>{t('account2FA.enable.step3Title')}</h2>
                <p>{t('account2FA.enable.step3Information')}</p>
                <FormField>
                  <label>{t('account2FA.passwordLabel')}</label>
                  <input
                    type="password"
                    name="current_password"
                    className={`small`}
                    autoComplete="off"
                    placeholder={t('account2FA.passwordPlaceholder') ?? 'Your current password'}
                    value={current_password}
                    required={true}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <label>{t('account2FA.validationLabel')}</label>
                  <input
                    type="text"
                    name="otp_validation"
                    className="tiny"
                    autoComplete="off"
                    placeholder={t('account2FA.validationPlaceholder') ?? 'OTP Code'}
                    value={otp_validation}
                    required={true}
                    onChange={handleChange}
                  />
                </FormField>
                <Actions>
                  <KoalaButton submit disabled={isLoading} loading={isLoading}>
                    {t('shared.save')}
                  </KoalaButton>
                </Actions>
              </StepsContainer>
            )}
          </Form>
          {showConfirm && <ConfirmMessage>{t('account2FA.enable.confirmMessage')}</ConfirmMessage>}
          {showError && <ErrorMessage>{t('account2FA.enable.error')}</ErrorMessage>}
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default AccountPassword;
