import React, { Fragment, useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
//import * as TabilityTypes from 'types';
import parse from 'parse-link-header';
import { useParams } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';
import Loader from 'components/Loader';

import NotificationBlock from './_Notifications/NotificationBlock';
import KoalaButton from 'koala/components/Button';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

const ContentWrapper = styled.div`
  position: absolute;
  top: 8rem;
  max-height: 60rem;
  right: 8rem;
  width: 44rem;
  z-index: 60;
  box-shadow: 0px 0px 8px rgba(21, 21, 21, 0.25);
  border-radius: 8px;
  background: #fff;
  transition: 0.2s ease-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media ${theme.devices.mobile} {
    width: 28rem;
  }
`;

const Header = styled.div`
  padding: ${theme.spacing.x2};
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  padding: ${theme.spacing.x1};
  overflow: auto;
  background: #fff;
  flex: 1;
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
`;

interface Props {
  setShowNotifications: any;
}

function Panel(props: Props) {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const queryCache = useQueryCache();
  const { setShowNotifications } = props;
  const { t } = useTranslation();
  const translationKey = 'components.workspaceHeader';
  const [notificationGroups, setNotificationGroups] = useState<AxiosResponse[]>([]);

  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  const params = {};

  const notificationsQueryKey = [queryKeys.notifications, workspaceSlug, params];
  const { isFetching, canFetchMore, fetchMore, isFetchingMore } = useInfiniteQuery(
    notificationsQueryKey,
    remoteApi.fetchNotifications,
    {
      staleTime: 0,
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
      onSuccess: (response) => {
        setNotificationGroups(response);
        handleMarkAsSeen();
        const workspacePingQueryKey = [queryKeys.currentWorkspace, workspaceSlug, 'stats'];
        queryCache.invalidateQueries(workspacePingQueryKey);
      },
    },
  );

  const [bulkUpdateNotificationsMutation]: [any, any] = useMutation(remoteApi.bulkUpdateNotifications, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.currentPing]);
    },
  });

  const handleMarkAsSeen = () => {
    const params = {
      workspaceSlug: workspaceSlug,
      bulk_action: 'mark_as_seen',
    };
    bulkUpdateNotificationsMutation(params);
  };

  // Close the panel on click outside of the panel
  useEffect(() => {
    const handleCloseFromClick = (e: any) => {
      setShowNotifications(false);
    };
    window.addEventListener('click', handleCloseFromClick, false);
    return () => window.removeEventListener('click', handleCloseFromClick, false);
  }, [setShowNotifications]);

  const handleStopPropagation = (e: any) => {
    e.stopPropagation();
  };

  if (isFetching && !canFetchMore) {
    return (
      <ContentWrapper onClick={handleStopPropagation}>
        <Content>
          <Loader />
        </Content>
      </ContentWrapper>
    );
  }

  let notificationsCount = 0;
  if (notificationGroups && notificationGroups[0] && notificationGroups[0].headers) {
    notificationsCount = parseInt(notificationGroups[0].headers['x-total']);
  }

  const isEmpty = notificationsCount === 0;

  if (isEmpty) {
    return (
      <ContentWrapper onClick={handleStopPropagation}>
        <Content>
          <p>{t(`${translationKey}.noNotifications`)}</p>
        </Content>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper onClick={handleStopPropagation}>
      <Header>
        <div>
          <b>{t(`${translationKey}.latest`)}</b>
        </div>
      </Header>
      <Content>
        {notificationGroups.map((group: any, i: number) => {
          const notifications = group.data;
          return (
            <Fragment key={i}>
              {notifications.map((notification: any) => {
                return <NotificationBlock key={notification.id} notification={notification} />;
              })}
            </Fragment>
          );
        })}
        {canFetchMore && (
          <LoadMore>
            <KoalaButton onClick={() => fetchMore()} appearance="secondary" loading={!!isFetchingMore}>
              {t('shared.loadMore')}
            </KoalaButton>
            {isFetchingMore && <Loader size="small" />}
          </LoadMore>
        )}
      </Content>
    </ContentWrapper>
  );
}

export default Panel;
