import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import ReactTooltip from 'react-tooltip';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import { parseISO, differenceInCalendarDays, isBefore } from 'date-fns';
import OutcomeBlockCompactAlign from './OutcomeBlockCompactAlign';
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaAvatar from 'koala/components/Avatar';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import KoalaIcon from 'koala/components/Icons';
import KoalaProgressBar from 'koala/components/ProgressBar';

import DataContainer from 'components/DataContainer';

import ProgressChart from './ProgressChart';
import CheckinsTrendsChart from './CheckinsTrendsChart';
import ConfidenceChart from './ConfidenceChart';
import NCSChart from './NCSChart';
import InitiativeRow from 'components/InitiativeRow';
import { Trans, useTranslation } from 'react-i18next';
import { formatLocale } from 'utils/dateUtils';
import OutcomeExpandable from 'components/OutcomeExpandable';

// Routes
const PlanWrapper = styled.div`
  max-width: 140rem;
  margin: 0 auto;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  min-height: 100%;
  justify-content: center;
`;

const PlanContainer = styled.div`
  flex: 1;
  position: relative;
`;

const PlanScroll = styled.div`
  padding: ${theme.spacing.x2};

  overflow: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
`;

const WarningBlock = styled.div`
  padding: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  background: ${theme.colors.warningBg};
  border-radius: 4px;
`;

const InsightsLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${theme.spacing.x2};

  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr;
  }
`;

const InsightsLineThree = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: ${theme.spacing.x2};

  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr;
  }
`;

const BlockPanel = styled.div`
  margin-bottom: ${theme.spacing.x1};
  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  border-radius: 4px;

  .outcome-row {
    grid-template-columns: 1fr 26rem;
    grid-template-areas: 'left right';
  }
  .outcome-row--right {
    grid-template-columns: auto auto 6.5rem;
    grid-template-areas: 'progress percentage owners';
  }
  .outcome-row--tags {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem ${theme.spacing.x2};
  min-height: 5.2rem;
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.2rem ${theme.spacing.x2};
`;

const OwnershipBlock = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr 10rem;
  padding: 1.2rem ${theme.spacing.x2};
  span {
    margin: 0 ${theme.spacing.x2};
  }
`;

const OwnerCount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing.x1};
  width: 10rem;
  b {
    text-align: right;
    flex: 1;
  }
  .progress {
    width: 7rem;
  }
`;

const InitiativeList = styled.div`
  .item {
    padding: 1.2rem ${theme.spacing.x2};
    display: grid;
    grid-template-columns: auto 14.5rem;
  }

  .initiative-state {
    display: none;
  }
  .initiative-details {
    a {
      overflow: auto;
      white-space: normal;
    }
    max-width: none;
  }
  .block-actions {
    top: 1.2rem;
    right: 1.6rem;
  }
`;

const ChartsWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  > div {
    flex: 1;
  }
  @media ${theme.devices.tablet} {
    flex-direction: column;
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
  objectives: TabilityTypes.Objective[];
  outcomes: TabilityTypes.Outcome[];
  initiatives: TabilityTypes.Initiative[];
}

const getOutcomesStats = (outcomes: TabilityTypes.Outcome[], plan: TabilityTypes.Plan) => {
  let offTrack: any = [];
  let atRisk: any = [];
  let improved: any = [];
  let memberships: any = {};
  let membershipIds: any = [];

  outcomes.forEach((outcome: any) => {
    if (outcome.membership) {
      const { membership } = outcome;
      if (!memberships[membership.id]) {
        memberships[membership.id] = {
          data: membership,
          outcomesCount: 0,
        };
        membershipIds.push(membership.id);
      }
      memberships[membership.id].outcomesCount++;
    }

    // Ignore outcomes that are completed
    if (!outcome.completed) {
      const currentCheckin = outcome.current_checkin;
      const previousCheckin = outcome.previous_checkin;
      if (currentCheckin) {
        if (currentCheckin.confidence === 'red') {
          offTrack.push(outcome);
        }
        if (previousCheckin) {
          if (currentCheckin.confidence === 'green' && ['red', 'yellow'].includes(previousCheckin.confidence)) {
            improved.push(outcome);
          }
        }
      }
    }
  });

  return {
    offTrack,
    atRisk,
    improved,
    memberships,
    membershipIds,
  };
};

const getInitiativesStats = (initiatives: any) => {
  let closedTotal = 0;
  let overdue: any = [];
  let upcoming: any = [];
  let recentlyCompleted: any = [];
  let memberships: any = {};
  let membershipIds: any = [];

  const today = new Date();
  initiatives.forEach((initiative: any) => {
    if (initiative.membership) {
      const { membership } = initiative;
      if (!memberships[membership.id]) {
        memberships[membership.id] = {
          data: membership,
          initiativesCount: 0,
          initiativesCompletedCount: 0,
        };
        membershipIds.push(membership.id);
      }
      memberships[membership.id].initiativesCount++;
      if (initiative.state === 'closed') {
        memberships[membership.id].initiativesCompletedCount++;
      }
    }

    if (initiative.state === 'closed') {
      closedTotal++;
      const diffClosed = differenceInCalendarDays(today, parseISO(initiative.closed_at));
      if (diffClosed < 7) {
        recentlyCompleted.push(initiative);
      }
    } else {
      // Check if upcoming
      if (['now', 'later'].includes(initiative.roadmap_state)) {
        upcoming.push(initiative);
      }

      // Check if overdue
      if (initiative.due_at) {
        const dueDate = parseISO(initiative.due_at);
        if (isBefore(dueDate, new Date())) {
          overdue.push(initiative);
        }
      }
    }
  });

  return {
    closedTotal,
    overdue,
    upcoming,
    recentlyCompleted,
    memberships,
    membershipIds,
  };
};

function Tracker(props: Props) {
  const { plan, outcomes, initiatives } = props;
  const history = useHistory();
  const [trends, setTrends] = useState([]);
  const [checkinsTrends, setCheckinsTrends] = useState([]);
  const [unalignedOutcomes, setUnalignedOutcomes] = useState([]);
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t, i18n } = useTranslation();
  // Fetch trends data
  useQuery([queryKeys.currentPlan, plan.id, 'trends'], remoteApi.fetchPlanTrends, {
    staleTime: 0,
    onSuccess: (response) => {
      setTrends(response.data);
    },
  });

  // Fetch trends data
  useQuery([queryKeys.currentPlan, plan.id, 'checkin_trends'], remoteApi.fetchPlanCheckinsTrends, {
    staleTime: 0,
    onSuccess: (response) => {
      setCheckinsTrends(response.data);
    },
  });

  // Fetch misaligned data
  const queryKey = [
    queryKeys.outcomes,
    'search',
    {
      workspaceSlug,
      filter: {
        plan_id: plan.id,
        not_aligned: true,
      },
    },
  ];
  useQuery(queryKey, remoteApi.searchOutcomes, {
    staleTime: 0,
    onSuccess: (response: any) => {
      setUnalignedOutcomes(response.data);
    },
  });

  const planFinishAt = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  const planFinishAtWithYear = planFinishAt ? formatLocale(planFinishAt, 'MMM do, yyyy', i18n) : null;
  const location = useLocation();
  let differenceInDays = planFinishAt ? differenceInCalendarDays(planFinishAt, new Date()) : 0;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  if (differenceInDays < 0) {
    differenceInDays = 0;
  }

  const isBlockSelected = (blockId: string) => {
    return location.hash.includes(blockId);
  };

  const ExpiredBlock = () => {
    if (!plan.expired) {
      return null;
    }

    return (
      <WarningBlock>
        <Trans
          i18nKey="workspacePlan.insights.ended"
          components={{ b: <b /> }}
          values={{ time: planFinishAtWithYear }}
        />
      </WarningBlock>
    );
  };

  const outcomesStats = getOutcomesStats(outcomes, plan);
  const initiativesStats = getInitiativesStats(initiatives);
  const outcomesToWatchCount = outcomesStats.atRisk.length + outcomesStats.offTrack.length;

  return (
    <ColumnsWrapper>
      <PlanContainer>
        <PlanScroll>
          <PlanWrapper className={`plan-content`}>
            <ExpiredBlock />
            <ChartsWrapper>
              <DataContainer>
                <h4>
                  {t('workspacePlan.insights.compareProgress', {
                    outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
                    initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                  })}
                </h4>
                <ProgressChart trends={trends} plan={plan} />
              </DataContainer>
              <DataContainer>
                <h4>{t('workspacePlan.insights.checkin')}</h4>
                <CheckinsTrendsChart trends={checkinsTrends} plan={plan} />
              </DataContainer>
            </ChartsWrapper>
            <ChartsWrapper>
              <DataContainer>
                <h4>{t('workspacePlan.insights.confidence')}</h4>
                <ConfidenceChart trends={trends} plan={plan} />
              </DataContainer>
              <DataContainer>
                <h4>
                  {t('shared.ncs.title')}
                  <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs2" />
                </h4>
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  className="tooltip"
                  effect="solid"
                  id="explain-ncs2"
                  delayHide={500}
                  clickable={true}
                >
                  <Trans i18nKey="shared.ncs.tooltip" components={{ br: <br /> }} />{' '}
                  <a
                    href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('shared.learnMore')}
                  </a>
                </ReactTooltip>
                <NCSChart trends={trends} plan={plan} />
              </DataContainer>
            </ChartsWrapper>
            <InsightsLine>
              <div>
                <Header>
                  <h3>
                    {t('workspacePlan.insights.toWatch', {
                      outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
                    })}
                  </h3>
                </Header>
                <BlockPanel>
                  {outcomesToWatchCount > 0 && (
                    <Fragment>
                      {outcomesStats.offTrack.map((outcome: TabilityTypes.Outcome, i: number) => {
                        return <OutcomeExpandable outcome={outcome} hideExpand={true} key={i} />;
                      })}
                    </Fragment>
                  )}
                  {outcomesToWatchCount === 0 && (
                    <EmptyState>
                      <p>
                        {t('workspacePlan.insights.toWatchEmpty')}
                        <span role="img" aria-label="all good!">
                          👍
                        </span>
                      </p>
                      <p className="subtle">
                        {t('workspacePlan.insights.toWatchEmptyInfo', {
                          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                        })}
                      </p>
                    </EmptyState>
                  )}
                </BlockPanel>
              </div>
              <div>
                <Header>
                  <h3>
                    {t('workspacePlan.insights.improved', {
                      outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                    })}
                    <span role="img" aria-label="celebration">
                      👏
                    </span>
                  </h3>
                </Header>
                <BlockPanel>
                  {outcomesStats.improved.length > 0 && (
                    <Fragment>
                      {outcomesStats.improved.map((outcome: any, i: number) => {
                        return <OutcomeExpandable outcome={outcome} hideExpand={true} key={i} />;
                      })}
                    </Fragment>
                  )}
                  {outcomesStats.improved.length === 0 && (
                    <EmptyState>
                      <p>{t('workspacePlan.insights.improvedEmpty')}</p>
                      <p className="subtle">{t('workspacePlan.insights.improvedEmptyInfo')}</p>
                    </EmptyState>
                  )}
                </BlockPanel>
              </div>
            </InsightsLine>
            <InsightsLine>
              <div>
                <Header>
                  <h3>
                    {t('workspacePlan.insights.overdue', {
                      initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                    })}
                  </h3>
                </Header>
                <BlockPanel>
                  {initiativesStats.overdue.length > 0 && (
                    <InitiativeList>
                      {initiativesStats.overdue.map((initiative: TabilityTypes.Initiative, i: number) => {
                        const isSelected = isBlockSelected(`#initiative:${initiative.nano_slug}`);
                        const blockId = `initiative:${initiative.nano_slug}`;
                        const showHashRoute = `#${blockId}:show`;
                        const handleTaskClick = () => {
                          history.push(showHashRoute);
                        };
                        return (
                          <InitiativeRow
                            key={initiative.id}
                            initiative={initiative}
                            isSelected={isSelected}
                            showMeta={true}
                            handleClick={handleTaskClick}
                          />
                        );
                      })}
                    </InitiativeList>
                  )}
                  {initiativesStats.overdue.length === 0 && (
                    <EmptyState>
                      <p>
                        {t('workspacePlan.insights.overdueEmpty')}
                        <span role="img" aria-label="all good!">
                          👍
                        </span>
                      </p>
                      <p className="subtle">
                        {t('workspacePlan.insights.overdueEmptyInfo', {
                          initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                        })}
                      </p>
                    </EmptyState>
                  )}
                </BlockPanel>
              </div>
              <div>
                <Header>
                  <h3>
                    {t('workspacePlan.insights.completed', {
                      initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                    })}{' '}
                    <span role="img" aria-label="celebration">
                      🎉
                    </span>
                  </h3>
                </Header>
                <BlockPanel>
                  {initiativesStats.recentlyCompleted.length > 0 && (
                    <InitiativeList>
                      {initiativesStats.recentlyCompleted.map((initiative: any, i: number) => {
                        const isSelected = isBlockSelected(`#initiative:${initiative.nano_slug}`);
                        const blockId = `initiative:${initiative.nano_slug}`;
                        const showHashRoute = `#${blockId}:show`;
                        const handleTaskClick = () => {
                          history.push(showHashRoute);
                        };
                        return (
                          <InitiativeRow
                            initiative={initiative}
                            key={initiative.id}
                            handleClick={handleTaskClick}
                            isSelected={isSelected}
                            showMeta={true}
                          />
                        );
                      })}
                    </InitiativeList>
                  )}
                  {initiativesStats.recentlyCompleted.length === 0 && (
                    <EmptyState>
                      <p>{t('workspacePlan.insights.completedEmpty')}</p>
                      <p className="subtle">{t('workspacePlan.insights.completedEmptyInfo')}</p>
                    </EmptyState>
                  )}
                </BlockPanel>
              </div>
            </InsightsLine>
            <InsightsLineThree>
              <div>
                <Header>
                  <h3>
                    {t('workspacePlan.insights.distribution', {
                      outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
                    })}
                  </h3>
                </Header>
                <BlockPanel>
                  {outcomesStats.membershipIds.length > 0 && (
                    <Fragment>
                      {outcomesStats.membershipIds.map((membershipId: any, i: number) => {
                        const membership = outcomesStats.memberships[membershipId];
                        if (!membership) {
                          return null;
                        }
                        const { user } = membership.data;
                        const { outcomesCount } = membership;
                        const prct = outcomesCount / plan.total_outcomes_count;
                        return (
                          <OwnershipBlock key={i}>
                            <KoalaAvatar user={user} size={2.2} />
                            <span>{user.fullname}</span>
                            <OwnerCount>
                              <b>{outcomesCount}</b>
                              <KoalaProgressBar className="progress" value={prct * 100} appearance="secondary" />
                            </OwnerCount>
                          </OwnershipBlock>
                        );
                      })}
                    </Fragment>
                  )}
                  {outcomesStats.membershipIds.length === 0 && (
                    <EmptyState>
                      <p>{t('workspacePlan.insights.noUsers')}</p>
                      <p className="subtle">
                        {t('workspacePlan.insights.noUserInfo', {
                          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                          initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                        })}
                      </p>
                    </EmptyState>
                  )}
                </BlockPanel>
              </div>
              <div>
                <Header>
                  <h3>
                    {t('workspacePlan.insights.ownership', {
                      initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                    })}
                  </h3>
                </Header>
                <BlockPanel>
                  {initiativesStats.membershipIds.length > 0 && (
                    <Fragment>
                      {initiativesStats.membershipIds.map((membershipId: any, i: number) => {
                        const membership = initiativesStats.memberships[membershipId];
                        if (!membership) {
                          return null;
                        }
                        const { user } = membership.data;
                        const { initiativesCount } = membership;
                        const prct = initiativesCount / plan.total_initiatives_count;
                        return (
                          <OwnershipBlock key={i}>
                            <KoalaAvatar user={user} size={2.2} />
                            <span>{user.fullname}</span>
                            <OwnerCount>
                              <b>{initiativesCount}</b>
                              <KoalaProgressBar className="progress" value={prct * 100} appearance="secondary" />
                            </OwnerCount>
                          </OwnershipBlock>
                        );
                      })}
                    </Fragment>
                  )}
                  {initiativesStats.membershipIds.length === 0 && (
                    <EmptyState>
                      <p>{t('workspacePlan.insights.noUsers')}</p>
                      <p className="subtle">
                        {t('workspacePlan.insights.noUserInfo', {
                          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                          initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                        })}
                      </p>
                    </EmptyState>
                  )}
                </BlockPanel>
              </div>
              {plan.parent && (
                <div>
                  <Header>
                    <h3>{t('workspacePlan.insights.alignment')}</h3>
                    {unalignedOutcomes.length > 0 && (
                      <KoalaTextBadge variant="neutral" edge="circle" size="large">
                        {unalignedOutcomes.length}
                      </KoalaTextBadge>
                    )}
                  </Header>
                  <BlockPanel>
                    {unalignedOutcomes.length > 0 && (
                      <Fragment>
                        {unalignedOutcomes.map((outcome: TabilityTypes.Outcome, i: number) => {
                          const isSelected = isBlockSelected(`#outcome:${outcome.nano_slug}`);
                          return <OutcomeBlockCompactAlign outcome={outcome} key={i} isSelected={isSelected} />;
                        })}
                      </Fragment>
                    )}
                    {unalignedOutcomes.length === 0 && (
                      <EmptyState>
                        <p>
                          {t('workspacePlan.insights.allAligned', {
                            outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                          })}
                        </p>
                        <p className="subtle">
                          {t('workspacePlan.insights.allAlignedInfo', {
                            outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                          })}
                        </p>
                      </EmptyState>
                    )}
                  </BlockPanel>
                </div>
              )}
              {!plan.parent && (
                <div>
                  <Header>
                    <h3>{t('workspacePlan.insights.alignment')}</h3>
                  </Header>
                  <BlockPanel>
                    <EmptyState>
                      <p>{t('workspacePlan.insights.topLevel')}</p>
                      <p className="subtle">
                        {t('workspacePlan.insights.topLevelInfo', {
                          outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
                        })}
                      </p>
                    </EmptyState>
                  </BlockPanel>
                </div>
              )}
            </InsightsLineThree>
          </PlanWrapper>
        </PlanScroll>
      </PlanContainer>
    </ColumnsWrapper>
  );
}

export default React.memo(Tracker);
