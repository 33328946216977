import React from 'react';

const ValidationError = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="6" fill="#C23A29" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.21967 7.71967C2.92678 8.01256 2.92678 8.48744 3.21967 8.78033C3.51256 9.07322 3.98744 9.07322 4.28033 8.78033L6 7.06066L7.71967 8.78033C8.01256 9.07322 8.48744 9.07322 8.78033 8.78033C9.07322 8.48744 9.07322 8.01256 8.78033 7.71967L7.06066 6L8.78033 4.28033C9.07322 3.98744 9.07322 3.51256 8.78033 3.21967C8.48744 2.92678 8.01256 2.92678 7.71967 3.21967L6 4.93934L4.28033 3.21967C3.98744 2.92678 3.51256 2.92678 3.21967 3.21967C2.92678 3.51256 2.92678 3.98744 3.21967 4.28033L4.93934 6L3.21967 7.71967Z"
      fill="white"
    />
  </svg>
);

export default ValidationError;
