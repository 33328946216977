import React from 'react';

const PersonPlusIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.57894 2.89474C7.00929 2.89474 5.73684 4.16719 5.73684 5.73684C5.73684 7.30649 7.00929 8.57895 8.57894 8.57895C10.1486 8.57895 11.421 7.30649 11.421 5.73684C11.421 4.16719 10.1486 2.89474 8.57894 2.89474ZM3.8421 5.73684C3.8421 3.12076 5.96286 1 8.57894 1C11.195 1 13.3158 3.12076 13.3158 5.73684C13.3158 8.35293 11.195 10.4737 8.57894 10.4737C5.96286 10.4737 3.8421 8.35293 3.8421 5.73684Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.94737 17.2L1.58211 18.0741C1.22955 17.9268 1 17.5821 1 17.2C0.999999 14.0777 1.75866 11.8676 3.19989 10.4497C4.6396 9.03322 6.58652 8.57895 8.57895 8.57895C9.10216 8.57895 9.52631 9.0031 9.52631 9.52632C9.52631 10.0495 9.10216 10.4737 8.57895 10.4737C6.84111 10.4737 5.47224 10.872 4.52872 11.8003C3.66558 12.6495 3.01234 14.0864 2.90902 16.5106C3.12201 16.5671 3.39302 16.6316 3.72429 16.6968C4.74579 16.8976 6.34238 17.1053 8.57895 17.1053C10.8155 17.1053 12.4121 16.8976 13.4336 16.6968C13.9445 16.5963 14.3121 16.4974 14.5445 16.4268C14.6607 16.3915 14.7432 16.3632 14.793 16.3453C14.8179 16.3363 14.8346 16.3299 14.8433 16.3266L14.8492 16.3242L14.8476 16.3249L14.8465 16.3253C14.8479 16.3248 14.8479 16.3248 14.8492 16.3242C15.3309 16.1259 15.8836 16.3535 16.0846 16.8347C16.2864 17.3175 16.0586 17.8724 15.5758 18.0741L15.2105 17.2C15.5758 18.0741 15.5761 18.074 15.5758 18.0741L15.5743 18.0747L15.5727 18.0754L15.5688 18.077L15.5588 18.0811L15.5297 18.0926C15.5063 18.1017 15.4746 18.1136 15.4348 18.128C15.355 18.1567 15.2422 18.195 15.0954 18.2397C14.8018 18.3289 14.3723 18.4432 13.7992 18.5559C12.6527 18.7813 10.9335 19 8.57895 19C6.22442 19 4.50522 18.7813 3.35871 18.5559C2.78559 18.4432 2.35612 18.3289 2.06247 18.2397C1.91566 18.195 1.80287 18.1567 1.72312 18.128C1.68325 18.1136 1.65164 18.1017 1.62818 18.0926L1.59908 18.0811L1.58908 18.077L1.58522 18.0754L1.58357 18.0747C1.58321 18.0746 1.58211 18.0741 1.94737 17.2Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2105 7.63158C15.7337 7.63158 16.1579 8.05573 16.1579 8.57895V14.2632C16.1579 14.7864 15.7337 15.2105 15.2105 15.2105C14.6873 15.2105 14.2632 14.7864 14.2632 14.2632V8.57895C14.2632 8.05573 14.6873 7.63158 15.2105 7.63158Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4211 11.4211C11.4211 10.8978 11.8452 10.4737 12.3684 10.4737L18.0526 10.4737C18.5758 10.4737 19 10.8978 19 11.4211C19 11.9443 18.5758 12.3684 18.0526 12.3684L12.3684 12.3684C11.8452 12.3684 11.4211 11.9443 11.4211 11.4211Z"
      fill="#737373"
    />
  </svg>
);

export default PersonPlusIcon;
