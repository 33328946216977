import React from 'react';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

// Components
import InitiativeLinkBlock from 'components/InitiativeLinkBlock';
import Loader from 'components/Loader';
import NewInitiativeLinkForm from './NewInitiativeLinkForm';

interface Props {
  initiative: any;
  isReadOnly?: boolean;
}

function InitiativesTab(props: Props) {
  const { initiative, isReadOnly } = props;

  // Construct the query key using the plan Id
  const queryKeyLinks = [queryKeys.initiativeLinks, initiative.id];
  const staleTime = 0;

  // Get the plan details
  const { data: initiativeLinksResponse, isLoading }: any = useQuery(queryKeyLinks, remoteApi.fetchInitiativeLinks, {
    staleTime,
  });

  let initiativeLinks = [];
  if (initiativeLinksResponse) {
    initiativeLinks = initiativeLinksResponse.data;
  }

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="panel-tasks">
      {initiativeLinks.map((initiativeLink: any, i: number) => {
        return <InitiativeLinkBlock initiativeLink={initiativeLink} key={i} />;
      })}
      {!isReadOnly && <NewInitiativeLinkForm initiative={initiative} />}
    </div>
  );
}

export default React.memo(InitiativesTab);
