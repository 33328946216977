import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import GitlabIcon from 'components/_assets/IconGitlab.png';
import KoalaButton from 'koala/components/Button';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import queryKeys from 'config/queryKeys';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
  state: string;
}

function GitlabIntegration(props: Props) {
  const { workspace, integrations, state } = props;
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallGitlabMutation, { isLoading: isUninstallingGitlab }] = useMutation(remoteApi.uninstallGitlab, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const gitlabIntegration = integrations.filter((i: Integration) => i.integration_type === 'gitlab')[0];

  const gitlabRedirectURL = `https://${process.env.REACT_APP_DOMAIN}/install/gitlab`;
  const gitlabOAuthURL =
    `https://gitlab.com/oauth/authorize` +
    `?client_id=${process.env.REACT_APP_GITLAB_CLIENT_ID}` +
    `&redirect_uri=${gitlabRedirectURL}` +
    `&response_type=code&scope=read_api&state=${state}`;

  const uninstallGitlabLabel = isUninstallingGitlab ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove');

  const handleUninstallGitlab = (e: any) => {
    e.preventDefault();
    uninstallGitlabMutation(workspace.slug);
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={GitlabIcon} alt="Gitlab" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'Gitlab' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Gitlab' })}</p>
        <p>
          <a
            href="https://guides.tability.app/docs/integrations/data-sources"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {gitlabIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: gitlabIntegration.connected_to }}
              />
            </div>
            <KoalaButton href={gitlabOAuthURL} appearance="secondary" size="small">
              {t('workspaceSettingsIntegrations.reinstall')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={handleUninstallGitlab}>
              {uninstallGitlabLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!gitlabIntegration && (
          <KoalaButton href={gitlabOAuthURL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Gitlab' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default GitlabIntegration;
