import AlignIcon from './AlignIcon';
import BellIcon from './BellIcon';
import CloseIcon from './CloseIcon';
import CommentIcon from './CommentIcon';
import CopyIcon from './CopyIcon';
import EllipsisIcon from './EllipsisIcon';
import GiftIcon from './GiftIcon';
import InboxIcon from './InboxIcon';
import ArrowLeftIcon from './ArrowLeftIcon';
import LockedIcon from './LockedIcon';
import LoveIcon from './LoveIcon';
import MapIcon from './MapIcon';
import MenuIcon from './MenuIcon';
import MetricIcon from './MetricIcon';
import PersonIcon from './PersonIcon';
import PersonPlusIcon from './PersonPlusIcon';
import PlanIcon from './PlanIcon';
import PublicIcon from './PublicIcon';
import ArrowRightIcon from './ArrowRightIcon';
import SearchIcon from './SearchIcon';
import SelectIcon from './SelectIcon';
import SettingsIcon from './SettingsIcon';
import SparklesIcon from './SparklesIcon';
import UnlockedIcon from './UnlockedIcon';
import UpgradeIcon from './UpgradeIcon';
import WatchActiveIcon from './WatchActiveIcon';
import WatchInactiveIcon from './WatchInactiveIcon';
import PlusIcon from './PlusIcon';
import EditIcon from './EditIcon';
import DownChevronIcon from './DownChevronIcon';
import LoveFilledIcon from './LoveFilledIcon';
import HelpIcon from './HelpIcon';
import ClockIcon from './ClockIcon';
import CalendarIcon from './CalendarIcon';
import CardsIcon from './CardsIcon';
import GoalsIcon from './GoalsIcon';
import InfoIcon from './InfoIcon';
import TaskIcon from './TaskIcon';
import InsightIcon from './InsightIcon';
import ShareIcon from './ShareIcon';
import ConciergeIcon from './ConciergeIcon';
import DownloadIcon from './DownloadIcon';
import TrashIcon from './TrashIcon';
import ArrowUpIcon from './ArrowUpIcon';
import ArrowDownIcon from './ArrowDownIcon';
import StarIcon from './StarIcon';
import TvIcon from './TvIcon';
import PresentIcon from './PresentIcon';
import LinkIcon from './LinkIcon';
import EmojiIcon from './EmojiIcon';
import AlignmentArrowIcon from './AlignmentArrowIcon';
import TooltipIcon from './TooltipIcon';
import FlagIcon from './FlagIcon';
import StandupIcon from './StandupIcon';
import TeamIcon from './TeamIcon';
import RightChevronIcon from './RightChevronIcon';
import LeftChevronIcon from './LeftChevronIcon';
import PinIcon from './PinIcon';
import WriteUpdateIcon from './WriteUpdateIcon';
import SnoozeIcon from './SnoozeIcon';
import GrabIcon from './GrabIcon';
import TextIcon from './TextIcon';
import LabelTaskIcon from './LabelTaskIcon';
import LabelAlignmentArrowIcon from './LabelAlignmentArrowIcon';
import LabelPlusIcon from './LabelPlusIcon';
import LabelCommentIcon from './LabelCommentIcon';
import LabelCalendarIcon from './LabelCalendarIcon';
import LabelCheckActionIcon from './LabelCheckActionIcon';
import CompassIcon from './CompassIcon';
import HomeIcon from './HomeIcon';
import MinusIcon from './MinusIcon';
import FireIcon from './FireIcon';
import LikeIcon from './LikeIcon';
import EmailIcon from './EmailIcon';

const IconList: { [key: string]: () => JSX.Element } = {
  align: AlignIcon,
  copy: CopyIcon,
  inbox: InboxIcon,
  locked: LockedIcon,
  love: LoveIcon,
  loveFilled: LoveFilledIcon,
  map: MapIcon,
  metric: MetricIcon,
  person: PersonIcon,
  personPlus: PersonPlusIcon,
  plan: PlanIcon,
  public: PublicIcon,
  unlocked: UnlockedIcon,
  watchActive: WatchActiveIcon,
  watchInactive: WatchInactiveIcon,
  settings: SettingsIcon,
  upgrade: UpgradeIcon,
  ellipsis: EllipsisIcon,
  menu: MenuIcon,
  leftArrow: ArrowLeftIcon,
  rightArrow: ArrowRightIcon,
  upArrow: ArrowUpIcon,
  downArrow: ArrowDownIcon,
  select: SelectIcon,
  comment: CommentIcon,
  search: SearchIcon,
  bell: BellIcon,
  gift: GiftIcon,
  sparkles: SparklesIcon,
  close: CloseIcon,
  plus: PlusIcon,
  edit: EditIcon,
  downChevron: DownChevronIcon,
  rightChevron: RightChevronIcon,
  leftChevron: LeftChevronIcon,
  help: HelpIcon,
  clock: ClockIcon,
  calendar: CalendarIcon,
  cards: CardsIcon,
  goals: GoalsIcon,
  info: InfoIcon,
  task: TaskIcon,
  insights: InsightIcon,
  share: ShareIcon,
  concierge: ConciergeIcon,
  download: DownloadIcon,
  trash: TrashIcon,
  star: StarIcon,
  tv: TvIcon,
  present: PresentIcon,
  link: LinkIcon,
  emoji: EmojiIcon,
  alignmentArrow: AlignmentArrowIcon,
  // V2 often used for labels and are smaller
  taskV2: LabelTaskIcon,
  commentV2: LabelCommentIcon,
  alignmentArrowV2: LabelAlignmentArrowIcon,
  plusV2: LabelPlusIcon,
  calendarV2: LabelCalendarIcon,
  checkAction: LabelCheckActionIcon,
  tooltip: TooltipIcon,
  flag: FlagIcon,
  standup: StandupIcon,
  team: TeamIcon,
  pin: PinIcon,
  writeUpdate: WriteUpdateIcon,
  snooze: SnoozeIcon,
  grab: GrabIcon,
  text: TextIcon,
  compass: CompassIcon,
  home: HomeIcon,
  minus: MinusIcon,
  fire: FireIcon,
  like: LikeIcon,
  email: EmailIcon,
};

export default IconList;
