import React from 'react';
import styled from 'styled-components';

// Components
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Login() {
  // Displays the list of templates
  return <Container>Access to this workspace is restricted.</Container>;
}

export default Login;
