import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

// Components

import AsyncCreatableSelect from 'react-select/async-creatable';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: ${theme.spacing.x1};
  align-items: center;
  .tag-select {
    flex: 1;
  }

  .tag-select-control {
    height: 30px;

    .tag-select-placeholder {
      line-height: 26px;
    }

    .tag-select-value {
      line-height: 26px !important;

      .tag-select-value-label {
        line-height: 26px;
      }
    }

    .tag-select-input {
      height: 26px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  justify-content: start;
`;

interface Props {
  initiative: TabilityTypes.Initiative;
  setDisplayPicker: (displayPicker: boolean) => void;
}

function TagPicker(props: Props) {
  const queryCache = useQueryCache();

  const { initiative, setDisplayPicker } = props;
  const { t } = useTranslation();
  const [tag_list, setTagList] = useState(initiative.cached_tag_list);
  const tagList = initiative.cached_tag_list || '';
  const tagListArray = tagList.length > 0 ? tagList.split(',') : [];
  const currentTagsForSelect = tagListArray.map((tag) => {
    return {
      label: tag.trim(),
      value: tag.trim(),
    };
  });

  // Functions to update the initiative
  const [updateInitiativeMutation]: [any, any] = useMutation(remoteApi.updateInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      queryCache.invalidateQueries(queryKeys.currentInitiative);
      setDisplayPicker(false);
    },
  });

  const handleSave = (e: any) => {
    e.preventDefault();
    const params = {
      tag_list,
    };

    updateInitiativeMutation({
      initiativeId: initiative.id,
      initiative: params,
    });
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    setDisplayPicker(false);
  };

  const handleLoadTags = (input: string) => {
    let tagParams;
    if (input) {
      tagParams = {
        search: input,
      };
    } else {
      tagParams = {};
    }
    return remoteApi.fetchTags(queryKeys.tags, initiative.workspace_id, tagParams, 1).then((response) => {
      const tags = response.data;
      const options = tags.map((tag: any) => ({
        label: tag.name,
        value: tag.name,
      }));
      return options;
    });
  };

  const handleSelectChange = (newValue: any) => {
    const tagList = newValue.map((option: any) => option.value).join(',');
    setTagList(tagList);
  };

  const customStyles = {
    control: (base: any) => ({
      ...base,
      minHeight: 30,
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base: any) => ({
      ...base,
      padding: 4,
    }),
    multiValue: (base: any) => ({
      ...base,
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: '0px 6px',
    }),
    input: (base: any) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
  };

  const placeholder = t('shared.addTag') ?? 'Select or type to create a new tag...';
  return (
    <Container className="tag-picker">
      <AsyncCreatableSelect
        className="tag-select"
        styles={customStyles}
        isClearable
        placeholder={placeholder}
        isMulti
        defaultOptions
        allowCreateWhileLoading={false}
        loadOptions={handleLoadTags}
        onChange={handleSelectChange}
        defaultValue={currentTagsForSelect}
      />
      <Actions>
        <KoalaButton onClick={handleSave} size="small">
          Save
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel} size="small">
          Cancel
        </KoalaTextButton>
      </Actions>
    </Container>
  );
}

export default React.memo(TagPicker);
