import React from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';

import Comments from './panels/Comments';
import EditPlanTimeline from './panels/EditPlanTimeline';
import EditPlanReviewers from './panels/EditPlanReviewers';

const Mask = styled.div<{ showPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: ${(props) => (props.showPanel ? '50' : '-1')};
  opacity: ${(props) => (props.showPanel ? '1' : '0')};
  transition: 0.2s ease-out;
`;

const ContentWrapper = styled.div<{ showPanel: boolean; width: string }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: ${(props) => props.width};
  z-index: 60;
  transform: translateX(${(props) => (props.showPanel ? '0%' : '100%')});
  transition: transform 0.2s ease-out;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 3.2rem;

  background: #fff;
`;

const sanitizedHash = (hash: string) => {
  return hash.replace('#', '');
};
function Panel() {
  const history = useHistory();
  const location = useLocation();
  const { hash } = location;

  let showPanel = false;
  let content = null;
  let entityType = null;
  let entityId = null;
  let action = null;
  let width = '80rem';

  if (hash && sanitizedHash(hash).split(':').length === 4) {
    const splittedHash = sanitizedHash(hash).split(':');
    if (splittedHash[0] === 'editor') {
      // check that the first hash element is the editor
      [, entityType, entityId, action] = splittedHash;
    }
  }

  // Check that we have the right params to display
  if (entityType && entityId && action) {
    if (entityType === 'plan' && action === 'edit.timeline') {
      showPanel = true;
      content = <EditPlanTimeline planId={entityId} />;
    }

    if (entityType === 'plan' && action === 'edit.reviewers') {
      showPanel = true;
      content = <EditPlanReviewers planId={entityId} />;
    }

    if (action === 'comment') {
      showPanel = true;
      content = <Comments entityId={entityId} entityType={entityType} />;
    }
    /*
    if (entityType === 'plan' && action === 'learn.scorecard') {
      showPanel = true;
      content = <LearnScorecard />;
    }
    */
  }

  const handleClosePanel = () => {
    history.push(location.pathname);
  };

  return (
    <div>
      <Mask onClick={handleClosePanel} showPanel={showPanel} />
      <ContentWrapper showPanel={showPanel} width={width}>
        <Content>{content}</Content>
      </ContentWrapper>
    </div>
  );
}

export default Panel;
