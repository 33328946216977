import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import globalUIReducer from './globalUIReducer';

import sessionReducer from './sessionReducer';

// Workspace editor
import editorUIReducer from './editorUIReducer';
import editorEntitiesReducer from './editorEntitiesReducer';

// Pages
import pageConfirmReducer from './pageConfirmReducer';
import pageLoginReducer from './pageLoginReducer';
import pageNewPasswordReducer from './pageNewPasswordReducer';
import pageSignupReducer from './pageSignupReducer';

// YML Editor
import ymlEditorReducer from './ymlEditorReducer';

const sessionPersistConfig = {
  key: 'tabilitySession',
  storage,
  whitelist: ['accessToken', 'refreshAttempts', 'redirectLink'],
};

const rootReducer = combineReducers({
  globalUI: globalUIReducer,
  session: persistReducer(sessionPersistConfig, sessionReducer),
  pageConfirm: pageConfirmReducer,
  pageLogin: pageLoginReducer,
  pageNewPassword: pageNewPasswordReducer,
  pageSignup: pageSignupReducer,
  editorUI: editorUIReducer,
  editorEntities: editorEntitiesReducer,
  ymlEditor: ymlEditorReducer,
});

export default rootReducer;
