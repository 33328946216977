import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import * as remoteApi from 'api/remote';
import KoalaTextButton from 'koala/components/TextButton';
import { PanelContent, PanelGrid, PanelHeader } from 'components/GlobalPanel';
import { useHistory, useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  height: 100%;

  .form-frame {
    width: 100%;
    height: 100%;
  }
`;
const Actions = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function CreateReview() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const [showForm, setShowForm] = useState(false);
  const queryKeyReview = [queryKeys.review, currentMembership.id];

  const { data: reviewResponse, isLoading: linkIsLoading } = useQuery(
    queryKeyReview,
    remoteApi.fetchMembershipReviewLink,
    {
      staleTime: 0,
    },
  );
  const reviewLink = reviewResponse?.data;

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(location.pathname);
  };

  const handleReview = () => {
    setShowForm(true);
  };

  const translationKey = 'modals.createReview';

  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>{t(`${translationKey}.title`)}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        <Wrapper>
          {!showForm && (
            <>
              <h3>{t(`${translationKey}.header`)}</h3>
              <p>
                <Trans
                  i18nKey={`${translationKey}.info`}
                  components={{
                    privacy: <KoalaTextButton href="https://legal.g2.com/privacy-policy">Text</KoalaTextButton>,
                    terms: <KoalaTextButton href="https://legal.g2.com/terms-of-use">Text</KoalaTextButton>,
                  }}
                />
              </p>
              <Actions>
                <KoalaButton onClick={handleReview} loading={linkIsLoading}>
                  {t(`${translationKey}.create`)}
                </KoalaButton>
                <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
              </Actions>
            </>
          )}
          {showForm && reviewLink && <iframe title="Review link" src={reviewLink} className="form-frame"></iframe>}
        </Wrapper>
      </PanelContent>
    </PanelGrid>
  );
}

export default CreateReview;
