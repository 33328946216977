import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import yaml from 'js-yaml';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { setIsEmptyWorkspace } from 'state/actions/sessionActions';

import queryKeys from 'config/queryKeys';
import { useQuery, useMutation } from 'react-query';
import * as remoteApi from 'api/remote';
import CategoryLabel from './CategoryLabel';

import Loader from 'components/Loader';
import KoalaButton from 'koala/components/Button';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import { startOfQuarter, endOfQuarter } from 'date-fns';

import { useHistory } from 'react-router-dom';

import * as routes from 'routes';

import * as dateUtils from 'utils/dateUtils';
// Custom components

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;

  > div {
    &:first-of-type {
      flex: 1;
    }
  }
`;

const Meta = styled.div`
  display: flex;
  margin-top: ${theme.spacing.x1};
  color: ${theme.colors.subtleText};
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  span {
    display: inline-block;
    margin-right: ${theme.spacing.x1};
  }
`;
const Content = styled.div`
  margin: ${theme.spacing.x2} 0;
  flex: 1;
`;

const PlanContent = styled.div`
  background: #f3f3f3;
  padding: ${theme.spacing.x2};
  border-radius: 8px;
  margin-bottom: ${theme.spacing.x2};
`;

const LineGrid = styled.div`
  display: grid;
  grid-template-columns: 9rem 1fr;
  grid-template-rows: auto;
  column-gap: ${theme.spacing.x2};
  align-items: center;
  margin-bottom: ${theme.spacing.x1};

  > div {
    display: flex;
    &:first-of-type {
      justify-content: flex-end;
    }

    &:nth-child(2) {
      font-family: monospace, 'Courier New';
      font-size: 1.2rem;
    }
  }
`;

const Categories = styled.div`
  > div {
    margin-right: ${theme.spacing.x1};
  }
`;

const Label = styled.div`
  font-size: 1rem;
  height: 2rem;
  line-height: 2rem;
  color: #fff;
  background-color: #6096ec;
  border: 0;
  border-radius: 20px;
  padding: 0 ${theme.spacing.x1};
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;

  &.objective {
    background-color: #60aeb9;
  }

  &.outcome {
    background-color: #f5c02b;
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0;
    padding: 0 ${theme.spacing.x1};
  }
`;

const TaskLabel = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.2rem;
  min-width: 2rem;
  min-height: 2rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.subtleText};
  color: #fff;
  background-color: #d1d1d1;
  border-radius: 50%;
  font-weight: 800;
  position: relative;

  margin-right: ${theme.spacing.x2};
`;

const Actions = styled.div``;

interface Props {
  selectedTemplateId: string;
  setSelectedTemplateId: any;
  handleSelectTag: any;
}
function TemplateDirectory(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const translationKey = 'modals.templatesDirectory';

  const { selectedTemplateId, handleSelectTag, setSelectedTemplateId } = props;
  const [template, setTemplate]: any = useState(null);
  const isEmptyWorkspace = useSelector((state: any) => state.session.isEmptyWorkspace);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Construct the query key using the plan Id
  const queryKeyTemplate = [queryKeys.publicTemplates, selectedTemplateId];
  const staleTime = 0;

  // Get the template2 details
  useQuery(queryKeyTemplate, remoteApi.fetchTemplateDetails, {
    staleTime,
    onSuccess: (response) => setTemplate(response.data),
  });

  // Functions to update the outcome
  const [populatePlanMutation, { isLoading }]: [any, any] = useMutation(remoteApi.populatePlanV2, {
    onSuccess: (_, params) => {
      const { planId } = params;
      const planRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', currentWorkspace.slug).replace(
        ':planId',
        planId,
      );
      history.push(`${planRoute}?onboarding=1`);
      if (isEmptyWorkspace) {
        dispatch(setIsEmptyWorkspace(false));
      }
      dispatch(setGlobalModalContent(''));
    },
    onError: (_, params) => {
      const { planId } = params;
      const planRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', currentWorkspace.slug).replace(
        ':planId',
        planId,
      );
      history.push(`${planRoute}?onboarding=1`);
      if (isEmptyWorkspace) {
        dispatch(setIsEmptyWorkspace(false));
      }
    },
  });

  // Mutation that will create the workspace in the backend
  const [createPlanMutation, { isLoading: isCreatingPlan }]: [any, any] = useMutation(remoteApi.createPlan, {
    onSuccess: (response: any) => {
      const createdPlan = response.data;
      const params = {
        planId: createdPlan.nano_slug,
        template: template.nano_slug,
      };
      populatePlanMutation(params);
    },
  });

  // Get the starting date from the plan start_at and reminderConfig setting
  const getRemindersStartAt = (start_at: Date) => {
    if (!start_at) {
      return null;
    }

    // Clone the starting date
    const date = new Date(start_at.getTime());

    // The date offset is the default weekly_reminder_day set at the workspace level.
    const dateOffset = currentWorkspace.weekly_reminder_day;

    // Calculate difference between current date and default reminder day.
    const daysUntilOffset = (dateOffset - date.getDay()) % 7;

    // Return the first "weekly_reminder_day" after the start_at date.
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + daysUntilOffset + 7);
  };

  const handleUseTemplate = (e: any) => {
    // Don't trigger if already loading the template.
    if (isLoading) {
      return;
    }
    const refDate = Date.now();
    const start_at = startOfQuarter(refDate);
    const finish_at = endOfQuarter(refDate);
    const title = `${currentWorkspace.name} OKRs`;
    const reminders_start_at = getRemindersStartAt(start_at);
    const mutationParams = {
      workspaceSlug: currentWorkspace.slug,
      plan: {
        state: 'published',
        title,
        reminders_start_at,
        reminders_period: 'week',
        reminders_interval: 1,
        reminders_hour: currentWorkspace.weekly_reminder_hour,
        start_at,
        finish_at,
      },
    };
    createPlanMutation(mutationParams);
  };

  const handleClosePreview = () => {
    setSelectedTemplateId('');
  };

  if (!template) {
    return (
      <Wrapper>
        <Loader size="big" />
      </Wrapper>
    );
  }

  let objectives = [];
  try {
    // @ts-ignore
    const content = yaml.load(template.yaml_content);
    // @ts-ignore
    objectives = content.objectives;
  } catch (e) {}

  // @ts-ignore
  const tagList = template.cached_tag_list || '';
  const tags = tagList.length > 0 ? tagList.split(',') : [];

  const isDisabled = isCreatingPlan || isLoading;

  return (
    <Wrapper>
      <Header>
        <div>
          <h3>{template.title}</h3>
        </div>
        <Actions>
          <KoalaButton onClick={handleUseTemplate} loading={isDisabled} disabled={isDisabled}>
            {t(`${translationKey}.useTemplate`)}
          </KoalaButton>
        </Actions>
      </Header>
      <Meta>
        <span>
          {t(`${translationKey}.updated`, {
            date: dateUtils.formatDistanceToNowLocale(template.updated_at, i18n, true),
          })}
        </span>
        <KoalaButton appearance="subtle" size="small" onClick={handleClosePreview}>
          {t(`${translationKey}.closePreview`)}
        </KoalaButton>
      </Meta>
      <Content>
        <PlanContent>
          {objectives.map((objective: any) => {
            const outcomes = objective.outcomes || [];
            return (
              <Fragment>
                <LineGrid>
                  <div>
                    <Label className="objective">{translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}</Label>
                  </div>
                  <div>{objective.title}</div>
                </LineGrid>
                {outcomes.map((outcome: any) => {
                  const initiatives = outcome.initiatives || [];
                  return (
                    <Fragment>
                      <LineGrid>
                        <div>
                          <Label className="outcome">#</Label>
                        </div>
                        <div>{outcome.title}</div>
                      </LineGrid>
                      {initiatives.map((initiative: any) => {
                        return (
                          <Fragment>
                            <LineGrid>
                              <div></div>
                              <div>
                                <div>
                                  <TaskLabel>&#10003;</TaskLabel>
                                </div>
                                {initiative.title}
                              </div>
                            </LineGrid>
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
        </PlanContent>
        <Categories>
          {tags.map((tag: any) => (
            <CategoryLabel onClick={handleSelectTag} data-tag={tag.trim()}>
              {tag.trim()}
            </CategoryLabel>
          ))}
        </Categories>
      </Content>
    </Wrapper>
  );
}

export default TemplateDirectory;
