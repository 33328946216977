//@flow
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Wrapper, Button, Menu, MenuItem } from 'react-aria-menubutton';

const StyledWrapper = styled(Wrapper)<{ absoluteposition: string }>`
  display: flex;
  position: relative;

  .AriaMenuButton-trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    color: #bdbdbd;
    border: 0;
    border-radius: 4px;
    font-weight: 400;
    line-height: 2rem;
    font-size: 1.4rem;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .AriaMenuButton-trigger:hover,
  .AriaMenuButton-trigger:focus,
  .AriaMenuButton-trigger.is-open {
  }

  .AriaMenuButton-trigger:active,
  .AriaMenuButton-trigger.is-open {
    background-color: #dcdcdc;
    background-image: none;
    border-color: #b5b5b5;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
  }

  .AriaMenuButton-menu {
    background: #fff;
    border: 1px solid rgba(200, 200, 200, 0.4);
    list-style-type: none;

    position: absolute;
    top: 100%;
    ${(props) => props.absoluteposition}: 0;
    z-index: 99;
    padding-left: 0;
    border-radius: 3px;
    margin: 4px 0 0 0;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    width: 215px;
  }

  .AriaMenuButton-menu--flushRight {
    right: 0;
  }

  .AriaMenuButton-menuItem {
    cursor: pointer;
    padding: 8px;
    border-bottom: 1px solid #eee;
    font-size: 1.4rem;
  }

  .AriaMenuButton-menuItemWrapper:first-of-type .AriaMenuButton-menuItem {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .AriaMenuButton-menuItemWrapper:last-of-type .AriaMenuButton-menuItem {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .AriaMenuButton-menuItem:hover,
  .AriaMenuButton-menuItem:focus {
    background-color: #6e7fbf;
    color: #fff;
  }

  .AriaMenuButton-menuItem.is-selected {
    cursor: default;
    font-weight: bold;
  }
`;

type Props = {
  items: Array<any>;
  onSelection?: (value: any) => void;
  trigger: ReactElement;
  position?: string;
};

function DropdownMenu(props: Props) {
  const { items, trigger, position } = props;

  // We have to invert the absolutePosition
  let absolutePosition = 'left';
  if (position === 'left') {
    absolutePosition = 'right';
  }
  return (
    <StyledWrapper
      className={`AriaMenuButton`}
      {...props}
      onClick={(e) => e.stopPropagation()}
      closeOnBlur={true}
      absoluteposition={absolutePosition}
    >
      <Button className={`AriaMenuButton-trigger`}>{trigger}</Button>
      <Menu>
        <ul className="AriaMenuButton-menu">
          {items.map((item, index) => (
            <li className="AriaMenuButton-menuItemWrapper" key={index}>
              <MenuItem className="AriaMenuButton-menuItem">{item}</MenuItem>
            </li>
          ))}
        </ul>
      </Menu>
    </StyledWrapper>
  );
}

export default DropdownMenu;
