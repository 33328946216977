import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BlurDiv } from '.';
import { AuditEvent, Workspace } from 'types';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
interface Props {
  event: AuditEvent;
  canViewAudit: boolean;
}

function InitiativeDeleted(props: Props) {
  const { event, canViewAudit } = props;
  const { t } = useTranslation();

  const { membership_cached_fullname, membership_cached_email, source_cached_title, membership_id } = event;
  let name = membership_cached_fullname || membership_cached_email;
  if (!membership_id) {
    name += ` ${t('auditEvents.deleted')}`;
  }

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const initiativeLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();

  return (
    <span>
      <Trans
        i18nKey="auditEvents.initiativeDeleted"
        values={{ name, title: source_cached_title, initiative: initiativeLabel }}
        components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
      />
    </span>
  );
}

export default React.memo(InitiativeDeleted);
