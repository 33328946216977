import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { StatsResult } from './index';

import { VictoryChart, VictoryBar, VictoryAxis, VictoryVoronoiContainer, VictoryLabel } from 'victory';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

interface Props {
  outcomesStats: StatsResult | null;
  updateURL: any;
  customFilterHash: any;
}

function Tracker(props: Props) {
  const { outcomesStats, customFilterHash, updateURL } = props;
  const chartRef: any = useRef(null);

  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = (ev: Event) => {
    if (chartRef && chartRef.current) {
      setWidth(chartRef.current.offsetWidth);
    }
  };

  // useEffect replaces `componentDidMount` and others
  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    // Removes listener on unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      setWidth(chartRef.current.offsetWidth);
    }
  }, [chartRef]);

  if (!outcomesStats) {
    return null;
  }

  const data = [
    {
      x: '0-10%',
      y: outcomesStats.p10_outcomes_count,
      progress_above_equal: 0,
      progress_below: 10,
    },
    {
      x: '10-20%',
      y: outcomesStats.p20_outcomes_count,
      progress_above_equal: 10,
      progress_below: 20,
    },
    {
      x: '20-30%',
      y: outcomesStats.p30_outcomes_count,
      progress_above_equal: 20,
      progress_below: 30,
    },
    {
      x: '30-40%',
      y: outcomesStats.p40_outcomes_count,
      progress_above_equal: 30,
      progress_below: 40,
    },
    {
      x: '40-50%',
      y: outcomesStats.p50_outcomes_count,
      progress_above_equal: 40,
      progress_below: 50,
    },
    {
      x: '50-60%',
      y: outcomesStats.p60_outcomes_count,
      progress_above_equal: 50,
      progress_below: 60,
    },
    {
      x: '60-70%',
      y: outcomesStats.p70_outcomes_count,
      progress_above_equal: 60,
      progress_below: 70,
    },
    {
      x: '70-90%',
      y: outcomesStats.p80_outcomes_count,
      progress_above_equal: 70,
      progress_below: 80,
    },
    {
      x: '80-90%',
      y: outcomesStats.p90_outcomes_count,
      progress_above_equal: 80,
      progress_below: 90,
    },
    {
      x: '90-100%',
      y: outcomesStats.p100_outcomes_count,
      progress_above_equal: 90,
      progress_below: null,
    },
  ];

  const handleClick = (e: any) => {
    const filterHash = { ...customFilterHash };

    if (e.datum.progress_above_equal || e.datum.progress_above_equal === 0) {
      filterHash['progress_above_equal'] = e.datum.progress_above_equal;
    }
    if (e.datum.progress_below) {
      filterHash['progress_below'] = e.datum.progress_below;
    }
    updateURL(filterHash);
  };

  return (
    <Container ref={chartRef}>
      <VictoryChart
        containerComponent={<VictoryVoronoiContainer />}
        width={width}
        height={190}
        padding={{ top: 30, bottom: 30, left: 40, right: 40 }}
        domainPadding={{ x: 40 }}
      >
        <VictoryAxis
          style={{
            tickLabels: {
              fontWeight: '400',
              fill: theme.colors.subtleText,
              fontSize: 12,
              fontFamily: theme.font.fontFamily,
            },
          }}
        />
        <VictoryBar
          style={{
            data: {
              fill: ({ active }) => (active ? theme.colors.B70 : theme.colors.B40),
              cursor: 'pointer',
            },
          }}
          data={data}
          alignment="middle"
          labels={(d) => {
            return d.datum.y;
          }}
          barRatio={1}
          events={[
            {
              target: 'data',
              eventHandlers: {
                onClick: () => {
                  return [
                    {
                      target: 'labels',
                      mutation: (props) => {
                        handleClick(props);
                      },
                    },
                  ];
                },
              },
            },
          ]}
          labelComponent={
            <VictoryLabel
              style={{
                fontWeight: '400',
                fontSize: 12,
                marginBottom: 4,
                fontFamily: theme.font.fontFamily,
              }}
            />
          }
        />
      </VictoryChart>
    </Container>
  );
}

export default React.memo(Tracker);
