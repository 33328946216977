import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as amplitudeUtils from 'utils/amplitude';
import { Initiative, Objective, Outcome, Plan } from 'types';
import ObjectiveSection from './ObjectiveSection';
import { addMonths, addWeeks, isBefore, startOfWeek } from 'date-fns';
import styled from 'styled-components';

const ScrollContainer = styled.div`
  overflow: auto;

  scroll-behavior: smooth;
`;
interface Props {
  plan: Plan;
}

function CalendarView(props: Props) {
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plan.work.calendar.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);
  const { plan } = props;
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const objectives: { [key: string]: Objective } = useSelector((state: any) => state.editorEntities.objectives);
  const objectiveIds: string[] = useSelector(
    (state: any) => state.editorEntities.plansToObjectivesMapping[plan.id] || [],
  );
  const outcomes: { [key: string]: Outcome } = useSelector((state: any) => state.editorEntities.outcomes);
  const initiatives: { [key: string]: Initiative } = useSelector((state: any) => state.editorEntities.initiatives);
  const initiativeIds: string[] = useSelector((state: any) => state.editorEntities.initiativeIds);

  const initiativesByObjective: { [objectiveId: string]: Initiative[] } = {};
  initiativeIds.forEach((id: string) => {
    const initiative: Initiative = initiatives[id];
    if (initiative) {
      const outcome = outcomes ? outcomes[initiative.outcome_id] : null;
      if (outcome) {
        if (initiativesByObjective[outcome.objective_id]) {
          initiativesByObjective[outcome.objective_id].push(initiative);
        } else {
          initiativesByObjective[outcome.objective_id] = [initiative];
        }
      }
    }
  });

  const outcomesByObjective: { [objectiveId: string]: Outcome[] } = {};
  Object.keys(outcomes).forEach((outcomeId) => {
    const outcome = outcomes ? outcomes[outcomeId] : null;
    if (outcome) {
      if (outcomesByObjective[outcome.objective_id]) {
        outcomesByObjective[outcome.objective_id].push(outcome);
      } else {
        outcomesByObjective[outcome.objective_id] = [outcome];
      }
    }
  });

  if (!plan.start_at || !plan.finish_at) {
    return <></>;
  }

  const weeks: Date[] = [];
  const startWeek = addMonths(new Date(plan.start_at), -3);
  const endWeek = addMonths(new Date(plan.finish_at), 3);
  let tempWeek = startOfWeek(startWeek, { weekStartsOn: 1 });

  while (isBefore(tempWeek, endWeek)) {
    weeks.push(tempWeek);
    tempWeek = addWeeks(tempWeek, 1);
  }

  return (
    <ScrollContainer ref={scrollContainerRef}>
      {objectiveIds.map((objectiveId, i) => {
        const objective = objectives[objectiveId];
        if (objective) {
          return (
            <ObjectiveSection
              objective={objective}
              initiatives={initiativesByObjective[objectiveId]}
              outcomes={outcomesByObjective[objectiveId]}
              weeks={weeks}
              key={objectiveId}
              isFirst={i === 0}
              scrollContainerRef={scrollContainerRef}
            />
          );
        }
        return <></>;
      })}
    </ScrollContainer>
  );
}

export default CalendarView;
