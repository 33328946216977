import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import styled from 'styled-components';
import React from 'react';
import { VictoryPie } from 'victory';
import { ReactElement } from 'react-markdown';
import { spacing } from 'koala/shared/styles';

interface ProgressData {
  x: number;
  y: number;
  color: string;
  legendTitle?: string;
  label?: string;
}

interface ProgressPieProps {
  /** data for ProgressPie */
  data: ProgressData[];
  /** Optional element for the legend */
  legendElement?: ReactElement;
  /** Optional size from KoalaSizes */
  size?: (typeof KoalaSizes)[number];
  /** Optional appearance from KoalaAppearances */
  appearance?: (typeof KoalaAppearances)[number];
  tooltipComponent?: JSX.Element;
  dataFor?: string;
  /** Optional class name for Progress Pie */
  className?: string;
  /** Optional custom styling for Progress Pie */
  style?: React.CSSProperties;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing.x4};

  &.confidence-pie--xsmall {
    .confidence-pie-chart {
      width: 2rem;
      height: 2rem;
    }
  }
  &.confidence-pie--small {
    .confidence-pie-chart {
      width: 4.5rem;
      height: 4.5rem;
    }
  }
  &.confidence-pie--medium {
    .confidence-pie-chart {
      width: 6rem;
      height: 6rem;
    }
  }
  &.confidence-pie--large {
    .confidence-pie-chart {
      width: 10rem;
      height: 10rem;
    }
  }
`;
const ChartContainer = styled.div`
  margin: 0;
`;

function KoalaConfidencePie(props: ProgressPieProps) {
  const { data, legendElement, size, tooltipComponent, dataFor, className, style } = props;

  const pieClass = `confidence-pie ${className ?? ''} confidence-pie--${size ? size : 'medium'}`;

  let pieArguments = {};
  switch (size) {
    case 'xsmall':
      pieArguments = { width: 20, height: 20, innerRadius: 6 };
      break;
    case 'small':
      pieArguments = { width: 45, height: 45, innerRadius: 18 };
      break;
    case 'medium':
      pieArguments = { width: 60, height: 60, innerRadius: 24 };
      break;
    case 'large':
      pieArguments = { width: 100, height: 100, innerRadius: 42 };
      break;
    default:
      pieArguments = { width: 100, height: 100, innerRadius: 42 };
      break;
  }

  return (
    <Container style={style} className={pieClass} data-tip={dataFor ? true : false} data-for={dataFor}>
      <ChartContainer className="confidence-pie-chart">
        <VictoryPie
          style={{
            data: {
              fill: (data: any) => {
                return data.datum.color;
              },
            },
          }}
          padding={0}
          padAngle={5}
          cornerRadius={0}
          data={data}
          labels={() => null}
          labelComponent={tooltipComponent}
          {...pieArguments}
        />
      </ChartContainer>
      {legendElement}
    </Container>
  );
}

KoalaConfidencePie.defaultProps = {
  size: 'medium',
  appearance: 'primary',
};

export default KoalaConfidencePie;
