import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import * as membershipUtils from 'utils/membershipUtils';

// This component will load the memberships from the user and redirect to the appropriate workspace if it exists.
// If no workspace has been found, will redirect to the workspace creation screen

function RequireAdminStatus() {
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  if (isReadOnly) {
    return <Redirect to={`/${currentWorkspace.slug}`} />;
  }

  return null;
}

export default RequireAdminStatus;
