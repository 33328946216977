import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import * as planUtils from 'utils/planUtils';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// API
import * as remoteApi from 'api/remote';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Routes
import * as routes from 'routes';

import KoalaButton from 'koala/components/Button';
import DropdownMenu from 'components/DropdownMenu';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextButton from 'koala/components/TextButton';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin: ${theme.spacing.x1} ${theme.spacing.x4} ${theme.spacing.x1} 0;
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
  justify-content: flex-end;
`;

const PublishAction = styled.div``;

interface Props {
  plan: TabilityTypes.Plan;
  hasOutcomes: boolean;
}

function PlanAction(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'workspacePlan.write';

  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );
  const { plan, hasOutcomes } = props;
  const queryKey = [queryKeys.currentPlan, plan.nano_slug];
  const blockId = `plan:${plan.id}`;
  const isPublished = plan.state === 'published';
  const isTemplate = plan.state === 'template';

  const params = new URLSearchParams(location.search);
  const isOnboarding = params.get('onboarding');

  const trackRoute = routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    plan.nano_slug,
  );
  const templateRoute = routes.WORKSPACE_PLAN_TEMPLATE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    plan.nano_slug,
  );
  // const plansRoute = routes.WORKSPACE_PLANS_ROUTE.replace(':workspaceSlug', workspaceSlug);

  // Mutation that will update the plan in the backend
  const [updatePlanMutation, { isLoading: isPublishing }]: [any, any] = useMutation(remoteApi.updatePlan, {
    onSuccess: (planResponse) => {
      queryCache.invalidateQueries(queryKeys.currentWorkspace);
      queryCache.setQueryData(queryKey, planResponse);
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.objectives);
      queryCache.invalidateQueries(queryKeys.initiatives);

      // Redirect if we have a published plan
      if (plan.state !== 'published' && planResponse.data.state === 'published') {
        history.push(`${trackRoute}?onboarding=1`);
        if (false && currentWorkspace.published_plans_count > 0) {
          dispatch(setGlobalModalContent(`${blockId}:published`));
        }
      }
    },
  });

  // Publish the plan and navigate to track view
  const handlePublish = (e: any) => {
    e.preventDefault();
    const planParams = {
      state: 'published',
    };
    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  const handleUnpublish = () => {
    const planParams = {
      state: 'draft',
    };
    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  const handleEditTimeline = () => {
    history.push(`#editor:${blockId}:edit.timeline`);
  };

  const handleKeepDraft = () => {
    history.push(trackRoute);
    queryCache.invalidateQueries(queryKeys.outcomes);
    queryCache.invalidateQueries(queryKeys.objectives);
    queryCache.invalidateQueries(queryKeys.initiatives);
  };

  const handleEditPermissions = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const nanoBlockId = `plan:${plan.nano_slug}`;
    dispatch(setGlobalModalContent(`${nanoBlockId}:edit.permissions`));
  };

  // Not really saving as saving is auto – just take you to the right plan
  const handleSave = () => {
    history.push(`${trackRoute}`);
    queryCache.invalidateQueries(queryKeys.outcomes);
    queryCache.invalidateQueries(queryKeys.objectives);
    queryCache.invalidateQueries(queryKeys.initiatives);
  };

  const handleSaveTemplate = () => {
    history.push(templateRoute);
    queryCache.invalidateQueries(queryKeys.outcomes);
    queryCache.invalidateQueries(queryKeys.objectives);
    queryCache.invalidateQueries(queryKeys.initiatives);
  };

  // Return template actions if it's a template
  if (isTemplate) {
    return (
      <Container>
        <PublishAction>
          <KoalaTextButton onClick={handleSaveTemplate}>{t('shared.close')}</KoalaTextButton>
        </PublishAction>
        <PublishAction>
          <KoalaButton onClick={handleSaveTemplate} className="publish-action">
            {t('shared.save')}
          </KoalaButton>
        </PublishAction>
      </Container>
    );
  }

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'edit-timeline':
        handleEditTimeline();
        break;
      case 'unpublish-plan':
        handleUnpublish();
        break;
    }
  };

  const menuItems: any = [
    <span data-action="edit-timeline">{t(`${translationKey}.editTimeline`)}</span>,
    <span data-action="unpublish-plan">{t(`${translationKey}.unpublishPlan`)}</span>,
  ];

  const hasTimeline = planUtils.hasTimeline(plan);
  const canPublish = hasTimeline;
  const publishDisabled = !canPublish || isPublishing;
  const planLocked = plan.global_permission_type !== 'edit';
  const lockedButtonIcon = planLocked ? 'locked' : 'unlocked';
  const superlockedClassName = plan.global_permission_type === 'none' ? 'superlocked' : '';
  const buttonTipMapping: any = {
    edit: t('workspacePlan.planNav.permissionEdit'),
    view: t('workspacePlan.planNav.permissionView'),
    none: t('workspacePlan.planNav.permissionNone'),
  };
  let lockedButtonTip: string = buttonTipMapping[plan.global_permission_type];
  return (
    <Container>
      <div data-tip data-for="lockedButton" className={superlockedClassName}>
        <KoalaIconButton className="hide-mobile" onClick={handleEditPermissions} iconName={lockedButtonIcon} />
      </div>
      <ReactTooltip place="bottom" type="dark" id="lockedButton" className="tooltip" effect="solid" delayShow={200}>
        {lockedButtonTip}
      </ReactTooltip>

      {!isPublished && !isOnboarding && <KoalaTextButton onClick={handleKeepDraft}>Keep as draft</KoalaTextButton>}
      {isPublished && (
        <PublishAction>
          <KoalaTextButton onClick={handleSave}>{t(`shared.close`)}</KoalaTextButton>
        </PublishAction>
      )}
      {isPublished && (
        <PublishAction>
          <KoalaButton onClick={handleSave} className="publish-action">
            {t('workspacePlan.write.saveButtonLabel')}
          </KoalaButton>
        </PublishAction>
      )}
      {!isPublished && !hasTimeline && hasOutcomes && (
        <PublishAction>
          <KoalaButton className="publish-action" onClick={handleEditTimeline}>
            {t(`${translationKey}.setTimelineButton`)}
          </KoalaButton>
        </PublishAction>
      )}
      {!isPublished && hasTimeline && (
        <PublishAction>
          <KoalaButton
            className="publish-action"
            disabled={publishDisabled}
            onClick={handlePublish}
            loading={isPublishing}
          >
            {t(`${translationKey}.publish`)}
          </KoalaButton>
        </PublishAction>
      )}
      {isPublished && (
        <DropdownMenu
          trigger={<KoalaIconButton iconName="ellipsis" />}
          onSelection={handleMenuSelection}
          items={menuItems}
        />
      )}
    </Container>
  );
}

export default React.memo(PlanAction);
