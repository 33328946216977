import FormField from 'components/FormField';
import React, { useEffect, useState } from 'react';
import * as remoteApi from 'api/remote';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { Objective, Outcome } from 'types';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';

interface Props {
  workspaceSlug: string;
  planId: string;
  setOutcomeId: (value: string) => void;
  existingOutcome?: Outcome;
}

function OutcomeSelect(props: Props) {
  const { setOutcomeId, planId, existingOutcome } = props;
  const { t } = useTranslation();
  const [selectedOutcomeOption, setSelectedOutcomeOption] = useState<KoalaSelectOption | null>(null);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const handleSelection = (selected: any) => {
    setSelectedOutcomeOption(selected);
    setOutcomeId(selected.value);
  };

  useEffect(() => {
    setSelectedOutcomeOption(null);
  }, [planId]);

  useEffect(() => {
    if (existingOutcome) {
      setSelectedOutcomeOption({ label: existingOutcome.title, value: existingOutcome.id });
    }
  }, [existingOutcome]);

  // Get the objectives
  const { isLoading: outcomesAreLoading, data: outcomesResponse } = useQuery(
    [queryKeys.outcomes, planId],
    remoteApi.fetchPlanOutcomes,
    {
      staleTime: 0,
    },
  );

  const outcomeOptions: KoalaSelectOption[] = outcomesResponse?.data.map((obj: Objective) => ({
    label: obj.title,
    value: obj.id,
  }));

  if (outcomesAreLoading) {
    return (
      <FormField>
        <label>{translate(currentWorkspace, CustomTermKey.OUTCOME, 1)}</label>
        <KoalaLoader />
      </FormField>
    );
  }

  const label = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();
  const placeholder = t(`workspaceDashboards.placeholder`, { item: label });

  return (
    <FormField>
      <label>{translate(currentWorkspace, CustomTermKey.OUTCOME, 1)}</label>
      <KoalaSelect
        handleChange={handleSelection}
        placeholder={placeholder ?? `Select ${label} or type to search`}
        selectedOption={selectedOutcomeOption}
        isClearable
        options={outcomeOptions}
        size="small"
        id="outcome"
      />
    </FormField>
  );
}
export default OutcomeSelect;
