import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';

// Actions
import { sessionActions } from 'state/actions/';

import { Link, useHistory, useParams } from 'react-router-dom';

import * as routes from 'routes';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import KoalaToggleButton from 'koala/components/ToggleButton';
import { UXPREFERENCES } from 'config/constants';

const Mask = styled.div<{ showAccountPanel: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${(props) => (props.showAccountPanel ? '50' : '-1')};
  opacity: ${(props) => (props.showAccountPanel ? '1' : '0')};
  transition: 0.2s ease-out;
`;

const ContentWrapper = styled.div<{ showAccountPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40rem;
  z-index: 60;
  transform: translateX(${(props) => (props.showAccountPanel ? '0%' : '100%')});
  transition: 0.2s ease-out;

  @media ${theme.devices.mobile} {
    max-width: 40rem;
    width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 3.2rem;

  background: #fff;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 6rem;
  grid-template-rows: auto;
  grid-template-areas: 'sp-content sp-sidebar ';
  height: 100%;
`;

const GridSidebar = styled.div`
  grid-area: sp-sidebar;
`;

const GridContent = styled.div`
  grid-area: sp-content;
  display: flex;
  flex-direction: column;

  b {
    color: ${theme.colors.subtleText};
    padding: ${theme.spacing.x4} 0 ${theme.spacing.x2} 0;
  }

  .sp-item {
    color: ${theme.colors.black};
    margin: ${theme.spacing.x2} 0;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  hr {
    border: 0px;
    width: 100%;
    height: 1px;
    background: ${theme.colors.blockBorder};
  }

  .toggle-container {
    margin-top: ${theme.spacing.x2};
    display: flex;
    gap: ${theme.spacing.x1};
    align-items: center;
  }
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  color: #151515;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: ${theme.spacing.x3};

  div {
    margin-right: ${theme.spacing.x1};
  }
`;

interface Props {
  showAccountPanel: boolean;
  setShowAccountPanel: any;
  currentUser: TabilityTypes.User;
}

function Panel(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { showAccountPanel, setShowAccountPanel, currentUser } = props;
  const name = currentUser.fullname || currentUser.email;
  const storedUXPreferences = localStorage.getItem(UXPREFERENCES);
  const UXPreferences = storedUXPreferences ? JSON.parse(storedUXPreferences) : {};
  const useColorBlind = UXPreferences.useColorBlind ? UXPreferences.useColorBlind : false;
  const { t } = useTranslation();
  const handleClosePanel = (e: React.MouseEvent) => {
    setShowAccountPanel(false);
  };

  const handleLogout = (e: React.MouseEvent) => {
    dispatch(sessionActions.logout());
  };

  const handleToggleBlindMode = (e: React.MouseEvent) => {
    localStorage.setItem(UXPREFERENCES, JSON.stringify({ ...UXPreferences, useColorBlind: !useColorBlind }));
  };

  const handleReview = (e: React.MouseEvent) => {
    history.push(`#review::create`);
  };

  return (
    <Fragment>
      <Mask onClick={handleClosePanel} showAccountPanel={showAccountPanel} />
      <ContentWrapper showAccountPanel={showAccountPanel}>
        <Content>
          <Grid>
            <GridSidebar>
              <KoalaIconButton onClick={handleClosePanel} iconName="rightArrow" />
            </GridSidebar>
            <GridContent>
              <UserProfile>
                <KoalaAvatar user={currentUser} />
                {name}
              </UserProfile>
              <Link className="sp-item" target="blank" to={routes.ACCOUNT_ROOT_ROUTE} onClick={handleClosePanel}>
                {t('workspaceHeader.manageAccount')}
              </Link>
              <hr />
              <div className="sp-item" role="button" onClick={handleReview}>
                {t('workspaceHeader.review')}
              </div>
              <Link className="sp-item" to={`/${workspaceSlug}/account/notifications`} onClick={handleClosePanel}>
                {t('workspaceHeader.manageNotifications')}
              </Link>
              <Link className="sp-item" to={`/${workspaceSlug}/account/standups`} onClick={handleClosePanel}>
                {t('workspaceHeader.manageStandups')}
              </Link>
              <Link className="sp-item" to={`/${workspaceSlug}/account/integrations`} onClick={handleClosePanel}>
                {t('workspaceHeader.personalIntegrations')}
              </Link>
              <Link className="sp-item" to={`/${workspaceSlug}/account/delete`} onClick={handleClosePanel}>
                {t('workspaceHeader.leave')}
              </Link>
              <hr />
              <div className="sp-item" role="button" onClick={handleLogout}>
                {t('shared.logout')}
              </div>
              <hr />
              <div className="toggle-container">
                <KoalaToggleButton
                  id="activate-blind-mode"
                  handleChange={handleToggleBlindMode}
                  size="small"
                  defaultChecked={useColorBlind}
                />
                {t('workspaceHeader.activateBlindMode')}
              </div>
            </GridContent>
          </Grid>
        </Content>
      </ContentWrapper>
    </Fragment>
  );
}

export default Panel;
