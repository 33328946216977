import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

// API
import * as remoteApi from 'api/remote';

// Components
import InitiativesTab from './InitiativesTab';
import OutcomesTab from './OutcomesTab';
import ObjectivesTab from './ObjectivesTab';
import Loader from 'components/Loader';
import { PanelGrid, PanelHeader, PanelContent } from 'components/GlobalPanel';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTabs from 'koala/components/Tabs';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const Tabs = styled.div`
  margin-bottom: ${theme.spacing.x2};
`;

interface Props {
  planId: string;
}

function MovePlan(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const { planId } = props;
  const [tab, setTab] = useState('objectives');
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const translationKey = 'panels.archive';

  const closePanel = () => {
    history.push(location.pathname);
  };

  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.push(location.pathname);
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  // Get the plan details
  const { data: planResponse }: any = useQuery([queryKeys.currentPlan, planId], remoteApi.fetchPlanDetails, {
    staleTime: 0,
  });

  const plan = planResponse ? planResponse.data : null;

  const handleCancel = (e: any) => {
    e.preventDefault();
    closePanel();
  };

  // Now we can display the app with the Chrome
  if (!plan) {
    return (
      <LoadingContainer>
        <Loader size="medium" />
      </LoadingContainer>
    );
  }

  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>{t(`${translationKey}.title`, { title: plan.title })}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        <Tabs>
          <KoalaTabs
            tabs={[
              { label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 2), key: 'objectives' },
              { label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2), key: 'outcomes' },
              { label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2), key: 'initiatives' },
            ]}
            setActiveTab={setTab}
            activeTab={tab}
          />
        </Tabs>
        {tab === 'objectives' && <ObjectivesTab planId={plan.id} />}
        {tab === 'outcomes' && <OutcomesTab planId={plan.id} />}
        {tab === 'initiatives' && <InitiativesTab planId={plan.id} />}
      </PanelContent>
    </PanelGrid>
  );
}

export default MovePlan;
