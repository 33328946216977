import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { setRedirectLink } from 'state/actions/sessionActions';
import { LOGIN_ROUTE } from 'routes';

function LogoutWithStoredPath() {
  const dispatch = useDispatch();
  const location = useLocation();

  const forceLogout = useSelector((state: any) => state.session.forceLogout);

  // stores redirect path
  useEffect(() => {
    // Only store the redirect if we're not forcing a logout.
    // This prevents the redirect link to be set for a second user after the user decides to logout.
    if (!forceLogout) {
      dispatch(setRedirectLink(location.pathname));
    }
  }, [dispatch, location, forceLogout]);

  return <Redirect to={LOGIN_ROUTE} />;
}

export default LogoutWithStoredPath;
