import React from 'react';

export default () => (
  <svg width="109" height="28" viewBox="0 0 109 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4234_5427)">
      <path
        d="M22.1245 28H6.2242C2.7862 28 0 25.2468 0 21.8524V6.14765C0 2.75193 2.7862 0 6.2242 0H22.1232C25.5612 0 28.3474 2.75193 28.3474 6.14765V21.8511C28.3487 25.2468 25.5612 28 22.1245 28Z"
        fill="#151515"
      />
      <path
        d="M15.6896 11.0141C15.1072 10.4376 14.7478 9.64687 14.7478 8.76775V6.39062H13.6006V8.76775V17.3C13.6006 18.4917 14.0897 19.5688 14.8802 20.3495C15.6694 21.1278 16.76 21.6097 17.964 21.6097H20.3707V20.4765H17.964C17.0739 20.4765 16.2733 20.1217 15.6896 19.5464C15.1072 18.9698 14.7478 18.1791 14.7478 17.3V11.6591C15.0504 11.9879 15.4022 12.2631 15.7943 12.4872V17.2091C15.7943 18.4468 16.8104 19.4505 18.0636 19.4505H20.372V18.3173H18.0636C17.7522 18.3173 17.4748 18.1941 17.2706 17.9923C17.0663 17.7894 16.9415 17.5167 16.9415 17.2078V12.9455C17.2718 13.0239 17.611 13.0774 17.9652 13.0774H20.372V11.9443H17.9652C17.0739 11.9443 16.2733 11.5907 15.6896 11.0141Z"
        fill="white"
      />
      <path
        d="M18.0623 9.77887C17.7509 9.77887 17.4735 9.65559 17.2693 9.45386C17.065 9.25089 16.9402 8.97819 16.9402 8.66938V6.39062H15.793V8.67062C15.793 9.90837 16.8091 10.912 18.0623 10.912H20.3707V9.77887H18.0623Z"
        fill="white"
      />
      <path
        d="M12.582 17.7034C12.5656 17.5714 12.5543 17.437 12.5543 17.3V8.67062V6.39062H11.407V8.67062C11.407 8.97819 11.2822 9.25214 11.0779 9.45511C10.8724 9.65683 10.5964 9.78011 10.2837 9.78011H7.97656V10.9133H10.285C10.6959 10.9133 11.0754 10.7975 11.407 10.6082V11.7625C11.0843 11.8708 10.7451 11.9455 10.3833 11.9455H7.97656V13.0787H10.3833C10.735 13.0787 11.0779 13.0339 11.407 12.9554V17.3C11.407 19.0308 12.4408 20.5251 13.931 21.2087C14.1264 21.2984 14.3331 21.3694 14.5437 21.4304C14.405 21.3183 14.2714 21.2 14.1453 21.0755C13.259 20.2013 12.6816 19.0147 12.582 17.7034Z"
        fill="white"
      />
      <path
        d="M38.1687 12.6722V19.6206H41.2612V21.7424H38.9012C37.0126 21.7424 35.762 20.5072 35.762 18.6418V12.6735H33.3779V10.5504H35.762V7.12354H38.17V10.5504H41.6168V12.6722H38.1687Z"
        fill="white"
      />
      <path
        d="M54.7638 10.5507V21.7428H52.3558V19.4341C51.5767 21.0429 50.1609 21.9756 48.2723 21.9756C45.0146 21.9756 42.9849 19.6446 42.9849 16.1467C42.9849 12.6489 44.9919 10.3179 48.1778 10.3179C50.113 10.3179 51.5767 11.2505 52.3558 12.9054V10.5507H54.7638ZM52.4731 16.1467C52.4731 13.6513 51.3397 12.4858 48.9317 12.4858C46.5237 12.4858 45.4143 13.6513 45.4143 16.1467C45.4143 18.6409 46.5237 19.8077 48.9317 19.8077C51.3409 19.8077 52.4731 18.6422 52.4731 16.1467Z"
        fill="white"
      />
      <path
        d="M69.5856 16.1465C69.5856 19.6443 67.5559 21.9753 64.2981 21.9753C62.3856 21.9753 60.9698 21.019 60.1907 19.3878V21.7425H57.7827V5.42139H60.1907V12.9288C60.9698 11.2739 62.4335 10.3176 64.3688 10.3176C67.5798 10.3176 69.5856 12.6486 69.5856 16.1465ZM67.155 16.1465C67.155 13.651 66.0216 12.4855 63.6375 12.4855C61.2295 12.4855 60.0961 13.651 60.0961 16.1465C60.0961 18.6406 61.2295 19.8074 63.6375 19.8074C66.0216 19.8074 67.155 18.6419 67.155 16.1465Z"
        fill="white"
      />
      <path
        d="M71.2136 6.63431C71.1896 5.6319 71.8036 5.09521 72.8664 5.09521C73.9052 5.09521 74.4953 5.6319 74.5192 6.63431C74.5432 7.66037 73.9531 8.24313 72.8664 8.24313C71.8275 8.24313 71.2375 7.65912 71.2136 6.63431ZM71.6384 10.5505H74.0464V21.7426H71.6384V10.5505Z"
        fill="white"
      />
      <path d="M77.0459 5.5459H79.4539V21.867H77.0459V5.5459Z" fill="white" />
      <path
        d="M82.028 6.63431C82.0041 5.6319 82.618 5.09521 83.6808 5.09521C84.7197 5.09521 85.3097 5.6319 85.3336 6.63431C85.3576 7.66037 84.7676 8.24313 83.6808 8.24313C82.642 8.24313 82.052 7.65912 82.028 6.63431ZM82.4529 10.5505H84.8609V21.7426H82.4529V10.5505Z"
        fill="white"
      />
      <path
        d="M91.8955 12.6722V19.6206H94.9881V21.7424H92.628C90.7394 21.7424 89.4888 20.5072 89.4888 18.6418V12.6735H87.1035V10.5504H89.4875V7.12354H91.8955V10.5504H95.3423V12.6722H91.8955Z"
        fill="white"
      />
      <path
        d="M108.387 10.5506L103.289 23.3278C102.604 25.03 101.708 25.7061 99.6302 25.7061H97.1983V23.5843H101.306L101.447 23.1186L101.399 23.1646L96.4658 10.5506H99.1335L101.588 17.6147L102.391 20.2259L103.241 17.591L105.72 10.5493H108.387V10.5506Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4234_5427">
        <rect width="108.387" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
