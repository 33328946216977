import React from 'react';

const FlagIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.85022 1.97331C4.67384 1.68919 4.35901 1.5 4 1.5C3.44772 1.5 3 1.94772 3 2.5V4.06409V11.5478V17.5C3 18.0523 3.44772 18.5 4 18.5C4.55228 18.5 5 18.0523 5 17.5V12.0226C5.03268 12.0035 5.069 11.9835 5.10915 11.9631C5.42786 11.8012 6.02113 11.5953 7 11.5953C7.84948 11.5953 8.57942 11.9478 9.5471 12.4393L9.6138 12.4732C10.5103 12.9291 11.6336 13.5002 13 13.5002C14.2711 13.5002 15.1779 13.2299 15.7966 12.9156C16.1037 12.7596 16.3322 12.5967 16.4938 12.4598C16.5745 12.3915 16.638 12.3301 16.6862 12.2798C16.7102 12.2546 16.7304 12.2323 16.7469 12.2132C16.7552 12.2037 16.7625 12.1949 16.769 12.1871L16.778 12.1761L16.782 12.1711L16.7839 12.1687L16.7848 12.1676C16.7848 12.1676 16.7857 12.1664 16 11.5478L16.7857 12.1664C16.9245 11.9901 17 11.7722 17 11.5478V4.06409C17 3.72414 16.8273 3.40748 16.5415 3.22339C16.2632 3.04413 15.9148 3.0145 15.6109 3.14289C15.6042 3.14532 15.5869 3.15142 15.5579 3.16022C15.4917 3.18027 15.3649 3.2142 15.165 3.25051C14.7647 3.32324 14.0755 3.40472 13 3.40472C12.1505 3.40472 11.4205 3.05231 10.4528 2.56087L10.3861 2.52699C9.48958 2.07123 8.36636 1.50024 7 1.50024C6.1255 1.50024 5.41456 1.69583 4.85022 1.97331ZM5 4.35571V9.86429C5.53283 9.70428 6.19297 9.59531 7 9.59531C8.36642 9.59531 9.48966 10.1664 10.3862 10.6223L10.4529 10.6562C11.4206 11.1478 12.1505 11.5002 13 11.5002C13.9789 11.5002 14.5721 11.2943 14.8909 11.1324C14.931 11.1121 14.9673 11.0921 15 11.073V5.29706C14.4985 5.35897 13.8429 5.40472 13 5.40472C11.6336 5.40472 10.5104 4.83373 9.61387 4.37797L9.54719 4.34409C8.57952 3.85265 7.84954 3.50024 7 3.50024C6.12963 3.50024 5.60218 3.79538 5.29676 4.05012C5.16386 4.16096 5.06642 4.27008 5 4.35571Z"
      fill="#737373"
    />
  </svg>
);

export default FlagIcon;
