import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import * as TabilityTypes from 'types';
import { useMutation } from 'react-query';

// API
import { updatePlan } from 'api/remote';

import { useParams, Link } from 'react-router-dom';
import SlackIcon from 'components/_assets/IconSlack.png';
import KoalaIconButton from 'koala/components/IconButton';
import { Trans, useTranslation } from 'react-i18next';

// Components

const MetaBlock = styled.div`
  background: ${theme.colors.N0};
  box-sizing: border-box;
  border: 1px solid ${theme.colors.N10};
  padding: ${theme.spacing.half};
  border-radius: 4px;
  margin-bottom: ${theme.spacing.x2};
  font-size: 1.3rem;

  &:hover {
    background: ${theme.colors.B5};
  }

  display: flex;
  align-items: center;
  a {
    color: ${theme.colors.blue};
    flex: 1;
  }
  img {
    display: inline-block;
    width: 1.6rem;
    margin-right: ${theme.spacing.x1};
  }

  big {
    font-size: 3rem;
    font-weight: 600;
    flex: 1;
    display: flex;
    align-items: center;
  }

  .icon {
    width: 1.2rem;
    height: 1.2rem;
    display: inline-block;
    line-height: 1.2rem;
    padding: 0;
    &:hover {
      background: none;
    }
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

const PlaceholderMetaBlock = styled.div`
  display: none;

  @media ${theme.devices.laptop} {
    display: flex;
    margin: 0;
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
}

const SlackIntegration = (props: Props) => {
  const { plan } = props;
  const [dismiss_messenger_integration, setDismissMessengerIntegration] = useState(plan.dismiss_messenger_integration);
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  // Mutation that will update the plan in the backend
  const [updatePlanMutation]: [any, any] = useMutation(updatePlan, {
    onSuccess: (planResponse) => {
      setDismissMessengerIntegration(true);
    },
  });

  const dismissIntegrationAd = (e: any) => {
    e.preventDefault();
    const planParams = {
      dismiss_messenger_integration: true,
    };
    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  if (plan.slack_channel_id) {
    const channelName = plan.slack_channel_name || 'Slack';
    return (
      <MetaBlock>
        <img src={SlackIcon} alt="Slack" />
        <span>
          <Trans i18nKey="workspacePlan.slackChannel" components={{ b: <b /> }} values={{ name: channelName }} />
        </span>
      </MetaBlock>
    );
  }
  if (!plan.slack_channel_id && !dismiss_messenger_integration) {
    return (
      <MetaBlock>
        <img src={SlackIcon} alt="Slack" />
        <Link to={`/${workspaceSlug}/plans/${plan.nano_slug}/settings/notifications`}>
          <small>{t('workspacePlan.connectSlack')}</small>
        </Link>
        <KoalaIconButton iconName="close" onClick={dismissIntegrationAd} size="xsmall" />
      </MetaBlock>
    );
  }

  return <PlaceholderMetaBlock></PlaceholderMetaBlock>;
};

export default SlackIntegration;
