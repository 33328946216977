import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import * as amplitudeUtils from 'utils/amplitude';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import { Base64 } from 'js-base64';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import ContentTitle from 'components/ContentTitle';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import Loader from 'components/Loader';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import { useTranslation } from 'react-i18next';
import OpenAIIntegration from './Integrations/OpenAIIntegration';
import NotionIntegration from './Integrations/NotionIntegration';
import ConfluenceIntegration from './Integrations/ConfluenceIntegration';
import SlackIntegration from './Integrations/SlackIntegration';
import JiraIntegration from './Integrations/JiraIntegration';
import ClickupIntegration from './Integrations/ClickupIntegration';
import AsanaIntegration from './Integrations/AsanaIntegration';
import LinearIntegration from './Integrations/LinearIntegration';
import ZapierIntegration from './Integrations/ZapierIntegration';
import AmplitudeIntegration from './Integrations/AmplitudeIntegration';
import AsanaDataConnectorIntegration from './Integrations/AsanaDataConnectorIntegration';
import BigQueryIntegration from './Integrations/BigQueryIntegration';
import ChartMogulIntegration from './Integrations/ChartMogulIntegration';
import GithubIntegration from './Integrations/GithubIntegration';
import GitlabIntegration from './Integrations/GitlabIntegration';
import GoogleSheetIntegration from './Integrations/GoogleSheetIntegration';
import HubspotIntegration from './Integrations/HubspotIntegration';
import JiraDataConnectorIntegration from './Integrations/JiraDataConnectorIntegration';
import MondayIntegration from './Integrations/MondayIntegration';
import MariaDBIntegration from './Integrations/MariaDBIntegration';
import MySQLIntegration from './Integrations/MySQLIntegration';
import PostgreSQLIntegration from './Integrations/PostgreSQLIntegration';
import SalesforceIntegration from './Integrations/SalesforceIntegration';
import TableauIntegration from './Integrations/TableauIntegration';
import TrelloIntegration from './Integrations/TrelloIntegration';
import ZendeskSupportIntegration from './Integrations/ZendeskSupportIntegration';
import ZendeskSalesIntegration from './Integrations/ZendeskSalesIntegration';
import HelpScoutIntegration from './Integrations/HelpScoutIntegration';
import FavroIntegration from './Integrations/FavroIntegration';
import ChargebeeIntegration from './Integrations/ChargebeeIntegration';
import PagerDutyIntegration from './Integrations/PagerDutyIntegration';
import SentryIntegration from './Integrations/SentryIntegration';

export const IntegrationBlock = styled.div`
  gap: ${theme.spacing.x2};
  display: flex;
  flex: 1;
  margin: 0;
  position: relative;
  overflow: hidden;
  border: 1px solid ${theme.colors.blockBorder};
  padding: ${theme.spacing.x1};
  border-radius: 8px;
  font-size: 1.2rem;

  h3 {
    font-size: 1.4rem;
  }

  &:hover {
    background: #f3f3f3;
  }

  a {
    color: ${theme.colors.blue};
  }

  @media ${theme.devices.laptop} {
    grid-template-columns: 6rem 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'side content' '. action';
  }
  @media ${theme.devices.mobile} {
    grid-template-columns: 3rem 1fr;
  }
`;

export const IntegrationLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 3.2rem;
    display: block;
  }
  @media ${theme.devices.mobile} {
    align-items: flex-start;
  }
`;

export const IntegrationContent = styled.div`
  grid-area: content;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing.half};

  h3 {
    display: flex;
    align-items: center;
    svg {
      margin-right: ${theme.spacing.x1};
    }
  }

  p + p {
    margin: 0;
  }
`;

export const IntegrationAction = styled.div`
  display: flex;
  align-items: center;

  p {
    display: flex;
    align-items: center;
    svg {
      margin-right: ${theme.spacing.x1};
    }
  }

  @media ${theme.devices.laptop} {
    justify-content: flex-start;
    margin-top: ${theme.spacing.x2};
  }
`;

export const ActionBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing.half};
`;

const IntegrationsListing = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  margin: ${theme.spacing.x2} 0 ${theme.spacing.x4} 0;
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

const staleTime = 0;

function WorkspaceSettingsDetails(props: Props) {
  const { workspace } = props;
  const queryKey = [queryKeys.integrations, workspace.slug];
  const { t } = useTranslation();

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.settings.integrations.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const [integrations, setIntegrations]: any = useState([]);

  const { isFetching: isInitialFetch }: any = useQuery(queryKey, remoteApi.fetchIntegrations, {
    staleTime,
    onSuccess: (response: any) => {
      setIntegrations(response.data);
    },
  });

  // tabilityRedirect is the redirection to use after the app is installed
  const tabilityRedirect = `${workspace.url}/settings/integrations`;
  const oauthState = {
    redirect_after: tabilityRedirect,
    slug: workspace.slug,
    currentDate: new Date(),
  };
  const state = Base64.encode(JSON.stringify(oauthState));

  if (isInitialFetch) {
    return (
      <Fragment>
        <ChromeSideNav>
          <WorkspaceSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <ContentTitle>
              <h1>{t('workspaceSettingsIntegrations.title')}</h1>
            </ContentTitle>
            <Loader size="medium" />
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsIntegrations.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsIntegrations.title')}</h1>
          </ContentTitle>

          <h2>{t('workspaceSettingsIntegrations.AIGroupTitle')}</h2>
          <IntegrationsListing>
            <OpenAIIntegration workspace={workspace} />
          </IntegrationsListing>

          <h2>{t('workspaceSettingsIntegrations.DocsGroupTitle')}</h2>
          <IntegrationsListing>
            <NotionIntegration />
            <ConfluenceIntegration />
          </IntegrationsListing>
          <h2>{t('workspaceSettingsIntegrations.ChatGroupTitle')}</h2>
          <IntegrationsListing>
            <SlackIntegration integrations={integrations} state={state} workspace={workspace} />
          </IntegrationsListing>
          <h2>{t('workspaceSettingsIntegrations.ProjectManagementGroupTitle')}</h2>
          <IntegrationsListing>
            <JiraIntegration integrations={integrations} workspace={workspace} />
            <ClickupIntegration integrations={integrations} workspace={workspace} />
            <AsanaIntegration integrations={integrations} workspace={workspace} />
            <LinearIntegration integrations={integrations} state={state} workspace={workspace} />
          </IntegrationsListing>
          <h2>{t('workspaceSettingsIntegrations.DataConnectorGroupTitle')}</h2>
          <IntegrationsListing>
            <ZapierIntegration workspace={workspace} />
            <AmplitudeIntegration integrations={integrations} workspace={workspace} />
            <AsanaDataConnectorIntegration integrations={integrations} workspace={workspace} />
            <BigQueryIntegration workspace={workspace} />
            <ChargebeeIntegration integrations={integrations} workspace={workspace} />
            <ChartMogulIntegration integrations={integrations} workspace={workspace} />
            <FavroIntegration integrations={integrations} workspace={workspace} />
            <GithubIntegration integrations={integrations} workspace={workspace} state={state} />
            <GitlabIntegration integrations={integrations} workspace={workspace} state={state} />
            <GoogleSheetIntegration workspace={workspace} />
            <HelpScoutIntegration integrations={integrations} workspace={workspace} state={state} />
            <HubspotIntegration integrations={integrations} workspace={workspace} state={state} />
            <JiraDataConnectorIntegration integrations={integrations} workspace={workspace} />
            <MondayIntegration integrations={integrations} workspace={workspace} state={state} />
            <MariaDBIntegration integrations={integrations} workspace={workspace} />
            <MySQLIntegration integrations={integrations} workspace={workspace} />
            <PagerDutyIntegration integrations={integrations} workspace={workspace} state={state} />
            <PostgreSQLIntegration integrations={integrations} workspace={workspace} />
            <SalesforceIntegration integrations={integrations} workspace={workspace} />
            <SentryIntegration integrations={integrations} workspace={workspace} />
            <TableauIntegration integrations={integrations} workspace={workspace} />
            <TrelloIntegration integrations={integrations} workspace={workspace} />
            {false && <ZendeskSupportIntegration integrations={integrations} workspace={workspace} />}
            {false && <ZendeskSalesIntegration integrations={integrations} workspace={workspace} state={state} />}
          </IntegrationsListing>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
