import queryKeys from 'config/queryKeys';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { DragHandle, SourceTitle, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import { Link } from 'react-router-dom';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaLoader from 'koala/components/Loader';
import { WORKSPACE_PLAN_TEMPLATE_ROUTE, WORKSPACE_PLAN_TRACK_ROUTE, WORKSPACE_PLAN_WRITE_ROUTE } from 'routes';
import { AxiosError, AxiosResponse } from 'axios';
import * as planUtils from 'utils/planUtils';
import { DashboardWidget, Domain, Plan } from 'types';
import Chart from 'components/CheckinChart';
import { getTime, isAfter, isBefore, parseISO } from 'date-fns';
import { VictoryArea, VictoryChart, VictoryGroup, VictoryStack, VictoryVoronoiContainer } from 'victory';
import KoalaIcon from 'koala/components/Icons';

const Container = styled.div`
  height: 18rem;
  display: flex;
  margin-bottom: ${theme.spacing.x1};
  width: 100%;
  height: 90%;
  max-height: 100%;

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

interface ChartProps {
  greyOutcomesData: any;
  redOutcomesData: any;
  greenOutcomesData: any;
  yellowOutcomesData: any;
  xDomain: any;
  yDomain: Domain;
  isInsideTimeline: boolean;
  dimensions: any;
}
function RenderChart(props: ChartProps) {
  const {
    greyOutcomesData,
    redOutcomesData,
    yellowOutcomesData,
    greenOutcomesData,
    yDomain,
    xDomain,
    isInsideTimeline,
    dimensions,
  } = props;
  const { i18n, t } = useTranslation();

  return (
    <VictoryChart
      width={dimensions.width}
      height={dimensions.height - 20}
      domainPadding={{ x: 15 }}
      padding={{ top: 10, bottom: 20, left: 35, right: 20 }}
      minDomain={{ y: 0 }}
      domain={{ x: xDomain }}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={({ datum }) => (datum.y === 0 ? null : datum.displayText)}
          labelComponent={Chart.LabelTooltipStack()}
        />
      }
    >
      {Chart.XAxis(i18n)}
      {Chart.YAxis()}
      <VictoryGroup scale="time">
        <VictoryStack>
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.N20,
                fillOpacity: 0.7,
                strokeLinecap: 'round',
                stroke: 'none',
              },
            }}
            data={greyOutcomesData}
            interpolation="linear"
          />
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.R40,
                fillOpacity: 0.7,
                strokeLinecap: 'round',
                stroke: 'none',
              },
            }}
            data={redOutcomesData}
            interpolation="linear"
          />
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.Y40,
                fillOpacity: 0.7,
                stroke: 'none',
              },
            }}
            data={yellowOutcomesData}
            interpolation="linear"
          />
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.G40,
                fillOpacity: 0.7,
                stroke: 'none',
              },
            }}
            data={greenOutcomesData}
            interpolation="linear"
          />
        </VictoryStack>
      </VictoryGroup>
      {isInsideTimeline && Chart.TodayLine(yDomain, t)}
    </VictoryChart>
  );
}

interface Props {
  widget: DashboardWidget;
}

function PlanConfidenceGraphWidget(props: Props) {
  const { widget } = props;
  const planId = widget.source_id;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [error, setError] = useState('');
  const queryKey = [queryKeys.currentPlan, planId];
  const [trends, setTrends] = useState([]);
  const [plan, setPlan] = useState<Plan>();
  let yDomain: Domain = { min: 0, max: 0 };
  const widgetTitle: string = widget.title ? widget.title : t('workspaceDashboards.PlanConfidenceGraph');

  const chartRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({ width: 200, height: 200 });

  useEffect(() => {
    const handleResize = (entries: any) => {
      for (let entry of entries) {
        if (entry.target === chartRef.current) {
          setDimensions({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (chartRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(chartRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef.current]);

  // Get the plan details
  const { isLoading } = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    onSuccess: (response: AxiosResponse) => {
      const data = response.data;
      setPlan(data);
    },
    onError: (error: AxiosError) => {
      const errorText = error.response?.data.error;
      if (errorText.includes('not allowed') && error.response?.status === 401) {
        setError(
          t('workspaceDashboards.permissionError') ?? 'You do not have permission to view the content in this widget',
        );
      } else {
        setError(t('workspaceDashboards.widgetError') ?? 'Unable to fetch widget');
      }
    },
  });

  // Fetch trends data
  useQuery([queryKeys.currentPlan, plan?.id, 'trends'], remoteApi.fetchPlanTrends, {
    staleTime: 0,
    onSuccess: (response) => {
      setTrends(response.data);
    },
    enabled: !!plan,
  });

  if (isLoading) {
    return (
      <WidgetContainer>
        <KoalaLoader />
        <Container className="panel-checkins-chart" ref={chartRef} />
      </WidgetContainer>
    );
  }

  if (!plan) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <WidgetContent>
          <p>{error ? t('workspaceDashboards.widgetError') : 'Unable to load plan'}</p>
        </WidgetContent>
      </WidgetContainer>
    );
  }

  if (!plan.start_at || !plan.finish_at) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <WidgetContent>
          <p>Missing plan timeline</p>
        </WidgetContent>
      </WidgetContainer>
    );
  }

  const greyOutcomesData: any[] = [];
  const redOutcomesData: any[] = [];
  const greenOutcomesData: any[] = [];
  const yellowOutcomesData: any[] = [];

  const startDate: Date = parseISO(plan.start_at);
  const finishDate: Date = parseISO(plan.finish_at);
  let chartFinishDate = finishDate;

  const firstPoint = {
    x: startDate,
    y: 0,
    timestamp: getTime(startDate),
  };

  const firstGreyPoint = {
    x: startDate,
    y: plan.total_outcomes_count,
    timestamp: getTime(startDate),
    displayText: `${plan.total_outcomes_count} pending`,
  };

  // By default we're going to use the first point from the target
  // We'll change that if we find a checkin prior to the first point.
  let useFirstPoint = true;

  trends.forEach((trend: any, index: number) => {
    const date = parseISO(trend.reference_date);
    if (isAfter(date, chartFinishDate)) {
      chartFinishDate = date;
    }
    // If the checkin was done prior to the first point we don't store
    // the first point in the list of current points
    if (isBefore(date, firstPoint.x)) {
      useFirstPoint = false;
    }

    // Create the point using the checkin data
    const greyPoint: any = {
      x: date,
      y: trend.grey_outcomes_count,
      timestamp: getTime(date),
      displayText: `${trend.grey_outcomes_count} pending`,
      trend,
    };
    greyOutcomesData.push(greyPoint);
    // Create the point using the checkin data

    const redPoint: any = {
      x: date,
      y: trend.red_outcomes_count,
      timestamp: getTime(date),
      displayText: `${trend.red_outcomes_count} off track`,
      trend,
    };
    redOutcomesData.push(redPoint);

    const yellowPoint: any = {
      x: date,
      y: trend.yellow_outcomes_count,
      displayText: `${trend.yellow_outcomes_count} at risk`,
      timestamp: getTime(date),
      trend,
    };
    yellowOutcomesData.push(yellowPoint);

    const greenPoint: any = {
      x: date,
      y: trend.green_outcomes_count,
      displayText: `${trend.green_outcomes_count} on track`,
      timestamp: getTime(date),
      trend,
    };
    greenOutcomesData.push(greenPoint);

    // Update domain
    yDomain.min = 0;
    yDomain.max = Math.max(trend.total_outcomes_count, yDomain.max);
  });

  if (yDomain.max === 0) {
    yDomain.max = 10;
  }

  if (useFirstPoint) {
    greyOutcomesData.push(firstGreyPoint);
    redOutcomesData.push(firstPoint);
    yellowOutcomesData.push(firstPoint);
    greenOutcomesData.push(firstPoint);
  }

  const xDomain = [startDate, chartFinishDate];
  const isInsideTimeline = planUtils.isInPlanTimeline(startDate, finishDate, new Date());

  const stateToRouteMapping: { [key: string]: string } = {
    draft: WORKSPACE_PLAN_WRITE_ROUTE,
    published: WORKSPACE_PLAN_TRACK_ROUTE,
    template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
  };
  let routeMappingState = plan.state || 'draft';
  const routeTarget = stateToRouteMapping[routeMappingState];
  const planRoute = routeTarget.replace(':workspaceSlug', currentWorkspace.slug).replace(':planId', plan.nano_slug);

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <WidgetContent>
        <SourceTitle>
          <Link to={planRoute} className="widget-source">
            <PlanIconLabel plan={plan} size="small" />
          </Link>
        </SourceTitle>
        <Container className="panel-checkins-chart" ref={chartRef}>
          <RenderChart
            dimensions={dimensions}
            greyOutcomesData={greyOutcomesData}
            redOutcomesData={redOutcomesData}
            yellowOutcomesData={yellowOutcomesData}
            greenOutcomesData={greenOutcomesData}
            xDomain={xDomain}
            yDomain={yDomain}
            isInsideTimeline={isInsideTimeline}
          />
        </Container>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default PlanConfidenceGraphWidget;
