import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';
import theme from 'theme';
import parse from 'parse-link-header';
// API
import * as remoteApi from 'api/remote';

// Components
import Loader from 'components/Loader';

import KoalaButton from 'koala/components/Button';

import NewsItem from 'components/Newsblocks/NewsItem';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  h2 {
    padding: ${theme.spacing.x2};
  }
`;

const InboxPanel = styled.div`
  margin-bottom: ${theme.spacing.x4};
  > p {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

const EventList = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  planId: string;
}

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

function Inbox(props: Props) {
  const { planId } = props;
  // Query that fetches all the outcomes with pending checkins
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const eventsQueryKey = [queryKeys.events, workspaceSlug, planId];
  const { t } = useTranslation();
  const {
    isLoading,
    isFetchingMore,
    data: outcomesResponse,
    fetchMore,
    canFetchMore,
  }: any = useInfiniteQuery(eventsQueryKey, remoteApi.fetchEvents, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  //
  if (isLoading) {
    return (
      <Fragment>
        <Loader size="medium" />
      </Fragment>
    );
  }

  let events = [];

  if (outcomesResponse) {
    events = outcomesResponse;
  }

  // Display empty state if you're at inbox zero
  if (events.length === 0 || events[0].data.length === 0) {
    return null;
  }

  const supportedEvents = [
    'checkin_comment.created',
    'review_comment.created',
    'review_reply.created',
    'outcome.confidence_changed',
    'checkin.created',
    'initiative.created',
    'initiative.closed',
    'initiative.moved_now',
    'initiative.moved_next',
    'plan.created',
    'plan.published',
    'outcome.created',
  ];

  return (
    <Container>
      <h2>{t('workspacePlan.activity.title')}</h2>
      <InboxPanel>
        {events.map((group: any, i: number) => {
          return (
            <EventList key={i}>
              {group.data.map((event: any, index: number) => {
                if (!event.source) {
                  return null;
                }

                if (!supportedEvents.includes(event.event_type)) {
                  return null;
                }

                return <NewsItem event={event} key={event.id} />;
              })}
            </EventList>
          );
        })}
        {canFetchMore && (
          <LoadMore>
            <KoalaButton
              onClick={() => fetchMore()}
              loading={isFetchingMore}
              disabled={isFetchingMore}
              appearance="secondary"
            >
              {t('shared.loadMore')}
            </KoalaButton>
          </LoadMore>
        )}
      </InboxPanel>
    </Container>
  );
}

export default Inbox;
