import React from 'react';

const AlignmentArrowIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2926 6.29278C10.6832 5.90235 11.3164 5.90251 11.7068 6.29313L16.7044 11.2931C17.0946 11.6836 17.0946 12.3165 16.7044 12.707L11.7068 17.707C11.3164 18.0976 10.6832 18.0978 10.2926 17.7073C9.90196 17.3169 9.9018 16.6837 10.2922 16.2931L14.5832 12.0001L10.2922 7.707C9.9018 7.31638 9.90196 6.68321 10.2926 6.29278Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1.99994C4.55228 1.99994 5 2.44765 5 2.99994V9.00024C5 9.84622 5.21073 10.297 5.45691 10.5433C5.70301 10.7895 6.15329 11.0002 6.99854 11.0002H16C16.5523 11.0002 17 11.448 17 12.0002C17 12.5525 16.5523 13.0002 16 13.0002H6.99854C5.84476 13.0002 4.79577 12.711 4.04235 11.9572C3.28903 11.2035 3 10.1543 3 9.00024V2.99994C3 2.44765 3.44772 1.99994 4 1.99994Z"
      fill="#737373"
    />
  </svg>
);

export default AlignmentArrowIcon;
