import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { KoalaSizes } from 'koala/shared/global';
import { IntegrationUser, User } from 'types';
import KoalaIcon from '../Icons';
import Icon, { IconButton } from './Avatar.styles';
import { ReactElement } from 'react-markdown';
import theme from 'theme';

interface iconItems {
  name: string;
  tooltipId: string;
  icon: ReactElement;
}
interface Props {
  user?: User | null;
  /** either the KoalaSize or the rem value */
  size?: (typeof KoalaSizes)[number] | number;
  handleClick?: (e: any) => void;
  hideTooltip?: boolean;
  /** added after the users name  */
  tooltipText?: string;
  addBorder?: boolean;
  integrationAssignee?: IntegrationUser | null;
  integrationType?: string;
  className?: string;
  style?: React.CSSProperties;
}

const Container = styled.div``;
const bgVariants = ['purple', 'red', 'green', 'yellow', 'blue', 'orange', 'teal'] as const;

function JiraIcon(props: Props): iconItems {
  const { handleClick, integrationAssignee, className, size } = props;

  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-jira-${randomIndex}`;

  let avatarImg = integrationAssignee?.avatarImage;
  let name = integrationAssignee ? integrationAssignee.name : 'Not assigned';
  let color = integrationAssignee ? integrationAssignee.color : 'blue';
  let avatarInitials = integrationAssignee ? integrationAssignee.initials : 'NA';

  let avatarClass = className ?? '';
  avatarClass += avatarImg ? '' : ` avatar--${color}`;
  const icon = !!handleClick ? (
    <IconButton
      className={avatarClass}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      type="button"
    >
      {avatarInitials}
    </IconButton>
  ) : (
    <Icon
      className={avatarClass}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
    >
      {avatarInitials}
    </Icon>
  );

  return {
    name,
    tooltipId,
    icon,
  };
}

function ClickupIcon(props: Props): iconItems {
  const { handleClick, integrationAssignee, className, size } = props;
  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-clickup-${randomIndex}`;
  const avatarImg = integrationAssignee?.avatarImage;
  const name = integrationAssignee ? integrationAssignee.name : '';
  const avatarInitials = integrationAssignee?.initials;
  const color = integrationAssignee ? integrationAssignee.color : theme.colors.N40;
  const icon = !!handleClick ? (
    <IconButton
      className={className}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      type="button"
      style={{ backgroundColor: color, color: 'white' }}
    >
      {avatarInitials}
    </IconButton>
  ) : (
    <Icon
      className={className}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      style={{ backgroundColor: color, color: 'white' }}
    >
      {avatarInitials}
    </Icon>
  );

  return {
    name,
    tooltipId,
    icon,
  };
}

function AsanaIcon(props: Props): iconItems {
  const { handleClick, integrationAssignee, className, size } = props;
  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-clickup-${randomIndex}`;
  const name = integrationAssignee ? integrationAssignee.name : '';
  const avatarInitials = integrationAssignee?.initials;
  const color = integrationAssignee ? integrationAssignee.color : theme.colors.N40;
  const icon = !!handleClick ? (
    <IconButton
      className={className}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      type="button"
      style={{ backgroundColor: color, color: 'white' }}
    >
      {avatarInitials}
    </IconButton>
  ) : (
    <Icon
      className={className}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      style={{ backgroundColor: color, color: 'white' }}
    >
      {avatarInitials}
    </Icon>
  );

  return {
    name,
    tooltipId,
    icon,
  };
}

function UserIcon(props: Props): iconItems {
  const { user, className, size, handleClick } = props;
  if (!user) {
    return { name: '', tooltipId: '', icon: <></> };
  }
  let avatarImg = null;
  let name = 'Not assigned';
  let avatarInitials = '';
  let hash = 10;
  let avatarClass = className ?? '';

  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-${user ? user.id : 'notassigned'}-${randomIndex}`;
  avatarImg = user && user.avatar_url ? `url(${user.avatar_url})` : null;
  name = user ? user.fullname || user.email : 'Account deleted';
  hash = user.id.charCodeAt(0) + user.id.charCodeAt(10) + user.id.charCodeAt(1) + user.id.charCodeAt(2);
  avatarClass += avatarImg ? '' : ` avatar--${bgVariants[hash % bgVariants.length]}`;
  if (name) {
    avatarInitials = name
      .trim()
      .split(' ')
      .map((x) => x.charAt(0).toUpperCase())
      .slice(0, 2)
      .join('');
  } else if (!user.fullname && !user.avatar_url && user.email) {
    avatarInitials = user.email.trim().charAt(0).toUpperCase();
  }
  const icon = !!handleClick ? (
    <IconButton
      className={avatarClass}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      type="button"
    >
      {avatarInitials}
    </IconButton>
  ) : (
    <Icon
      className={avatarClass}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
    >
      {avatarInitials}
    </Icon>
  );

  return {
    name,
    tooltipId,
    icon,
  };
}

function UnassignedIcon(props: Props): iconItems {
  const { className, size, handleClick } = props;
  let avatarClass = className ?? '';

  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-notassigned-${randomIndex}`;
  const icon = !!handleClick ? (
    <IconButton
      className={avatarClass}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      type="button"
    >
      <KoalaIcon iconName="person" iconSize={size} />
    </IconButton>
  ) : (
    <Icon
      className={avatarClass}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
    >
      <KoalaIcon iconName="person" iconSize={size} />
    </Icon>
  );
  return { name: 'Not assigned', tooltipId, icon };
}

function AvatarIcon(props: Props): iconItems {
  const { user, integrationAssignee, integrationType, size, className, addBorder } = props;
  let avatarClass = `avatar-icon ${className ?? ''}`;
  if (typeof size === 'string') {
    avatarClass += ` avatar--${size}`;
  } else if (typeof size === 'number') {
    avatarClass += ` avatar--custom-size`;
  }
  if (addBorder) {
    avatarClass += ` avatar--border`;
  }

  if (user) {
    return UserIcon({ ...props, className: avatarClass });
  } else if (integrationAssignee && integrationType === 'jira_cloud') {
    return JiraIcon({ ...props, className: avatarClass });
  } else if (integrationAssignee && integrationType === 'clickup') {
    return ClickupIcon({ ...props, className: avatarClass });
  } else if (integrationAssignee && integrationType === 'asana') {
    return AsanaIcon({ ...props, className: avatarClass });
  } else {
    return UnassignedIcon({ ...props, className: avatarClass });
  }
}

const KoalaAvatar: React.FunctionComponent<Props> = (props: Props) => {
  const { hideTooltip, tooltipText, className, style } = props;

  const { name, tooltipId, icon } = AvatarIcon(props);

  return (
    <Container style={style} className={`avatar ${className ?? ''}`}>
      {icon}
      {!hideTooltip && (
        <ReactTooltip place="bottom" type="dark" id={tooltipId} effect="solid" delayShow={200}>
          {name} {tooltipText}
        </ReactTooltip>
      )}
    </Container>
  );
};

KoalaAvatar.defaultProps = {
  hideTooltip: false,
  size: 'medium',
  addBorder: false,
};

export default KoalaAvatar;
