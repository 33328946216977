import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import PlanSelect from './PlanSelect';
import { useTranslation } from 'react-i18next';
import InitiativeSelect from './InitiativeSelect';
import { DashboardWidget, Initiative } from 'types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;

  .plan-select {
    width: 100%;
  }
`;

interface Props {
  handleSaveWidget: (type: string, source: string, source_id: string, widgetId?: string) => void;
  isLoading: boolean;
  isEditing?: boolean;
  widget?: DashboardWidget;
}

function InitiativeSummaryWidgetDetails(props: Props) {
  const { handleSaveWidget, isLoading, isEditing, widget } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const originalInitiative: Initiative | undefined = widget?.source;
  const [planId, setPlanId] = useState<string | null>(originalInitiative ? originalInitiative.plan_id : null);
  const [initiativeId, setInitiativeId] = useState<string | null>(originalInitiative ? originalInitiative.id : null);

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (!initiativeId) {
      return;
    }
    handleSaveWidget('initiative-summary', 'initiative', initiativeId, widget?.id);
  };

  const changePlan = (value: string) => {
    setPlanId(value);
    setInitiativeId(null);
  };

  return (
    <Container>
      <PlanSelect workspaceSlug={workspaceSlug} setPlanId={changePlan} existingPlan={originalInitiative?.plan} />
      {planId && (
        <InitiativeSelect
          workspaceSlug={workspaceSlug}
          setInitiativeId={setInitiativeId}
          planId={planId}
          initiative={originalInitiative}
        />
      )}
      <div>
        <KoalaButton onClick={handleSave} loading={isLoading} disabled={!initiativeId}>
          {isEditing ? t('shared.save') : t('shared.add')}
        </KoalaButton>
      </div>
    </Container>
  );
}

export default InitiativeSummaryWidgetDetails;
