import React, { useState } from 'react';

import DisplayCommentBlock from './_DisplayCommentBlock';
import EditCommentBlock from './_EditCommentBlock';
import { Checkin, CheckinComment } from 'types';

interface Props {
  checkinComment: CheckinComment;
  checkin: Checkin;
}

function CommentBlock(props: Props) {
  const { checkinComment, checkin } = props;

  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return <EditCommentBlock checkinComment={checkinComment} setIsEditing={setIsEditing} />;
  }

  return <DisplayCommentBlock checkinComment={checkinComment} checkin={checkin} setIsEditing={setIsEditing} />;
}

export default React.memo(CommentBlock);
