import { KoalaSizes } from 'koala/shared/global';
import React, { CSSProperties } from 'react';
import Select, { ValueType } from 'react-select';
import AsyncSelect from 'react-select/async';
import theme from 'theme';

export interface KoalaSelectOption {
  label: string;
  value: string | boolean | number;
  meta?: any;
}

interface Props {
  isMultiSelect?: boolean;
  isClearable?: boolean;
  options?: KoalaSelectOption[];
  selectedOption?: any;
  placeholder: string;
  id?: string;
  size?: (typeof KoalaSizes)[number];
  handleChange: (option: ValueType<KoalaSelectOption, boolean>) => void;
  loadOptions?: (input: string, callback: any) => void;
  labelFormat?: (option: any) => React.ReactNode;
  getOptionValue?: any;
  className?: string;
  style?: React.CSSProperties;
  height?: number;
}

function KoalaSelect(props: Props) {
  const {
    options,
    handleChange,
    loadOptions,
    labelFormat,
    getOptionValue,
    size,
    selectedOption,
    isMultiSelect,
    isClearable,
    placeholder,
    id,
    className,
    height,
  } = props;

  const containerHeight = height ? height : 32;

  const xsmallStyles = {
    container: (provided: CSSProperties) => ({
      ...provided,
      minWidth: '16rem',
      fontSize: '1.2rem',
    }),
    control: (base: CSSProperties) => ({
      ...base,
      height: '3rem',
      minHeight: '3rem',
      borderColor: theme.colors.inputBorder,
    }),
    dropdownIndicator: (base: CSSProperties) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base: CSSProperties) => ({
      ...base,
      padding: 4,
    }),
    multiValue: (base: CSSProperties) => ({
      ...base,
      borderRadius: '4px',
    }),
    valueContainer: (base: CSSProperties) => ({
      ...base,
      padding: '0px 6px',
      maxHeight: '2.8rem',
      display: 'flex',
      alignContent: 'center',
    }),
    menu: (base: CSSProperties) => ({
      ...base,
      marginTop: '2px',
    }),
    menuList: (base: CSSProperties) => ({
      ...base,
      padding: 0,
      borderRadius: '4px',
    }),
    option: (base: CSSProperties) => ({
      ...base,
      padding: '4px 8px',
    }),
  };

  const smallStyles = {
    container: (provided: CSSProperties) => ({
      ...provided,
      minWidth: '16rem',
      fontSize: '1.2rem',
    }),
    control: (base: CSSProperties) => ({
      ...base,
      minHeight: containerHeight,
      borderColor: theme.colors.inputBorder,
    }),
    dropdownIndicator: (base: CSSProperties) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base: CSSProperties) => ({
      ...base,
      padding: 4,
    }),
    multiValue: (base: CSSProperties) => ({
      ...base,
    }),
    valueContainer: (base: CSSProperties) => ({
      ...base,
      padding: '0px 6px',
      maxHeight: '3rem',
      display: 'flex',
      alignContent: 'center',
    }),
    input: (base: CSSProperties) => ({
      ...base,
      margin: 0,
      padding: 0,
      maxHeight: '3.2rem',
    }),
  };

  const normallStyles = {
    container: (provided: CSSProperties) => ({
      ...provided,
      minWidth: '24rem',
      fontSize: '1.4rem',
    }),
    control: (base: CSSProperties) => ({
      ...base,
      minHeight: containerHeight,
      height: containerHeight,
      borderColor: theme.colors.inputBorder,
    }),
    dropdownIndicator: (base: CSSProperties) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base: CSSProperties) => ({
      ...base,
      padding: 4,
    }),
    multiValue: (base: CSSProperties) => ({
      ...base,
    }),
    valueContainer: (base: CSSProperties) => ({
      ...base,
      height: containerHeight,
      padding: '0px 6px',
      alignContent: 'center',
    }),
    placeholder: (base: CSSProperties) => ({
      ...base,
      color: theme.colors.subtleText,
      height: 19,
    }),
    input: (base: CSSProperties) => ({
      ...base,
      margin: 0,
      padding: 0,
      maxHeight: '3rem',
    }),
  };

  let selectedStyle = undefined;
  switch (size) {
    case 'xsmall':
      selectedStyle = xsmallStyles;
      break;
    case 'small':
      selectedStyle = smallStyles;
      break;
    default:
      selectedStyle = normallStyles;
      break;
  }

  // if async
  if (loadOptions) {
    return (
      <AsyncSelect
        isMulti={isMultiSelect}
        isClearable={isClearable}
        closeMenuOnSelect={!isMultiSelect}
        styles={selectedStyle}
        placeholder={placeholder}
        value={selectedOption}
        className={className}
        loadOptions={loadOptions}
        defaultOptions={true}
        classNamePrefix="react-filter"
        onChange={(options) => handleChange(options)}
        formatOptionLabel={labelFormat}
        getOptionValue={(option) => (getOptionValue ? getOptionValue(option) : option)}
      />
    );
  }

  return (
    <Select
      isMulti={isMultiSelect}
      closeMenuOnSelect={!isMultiSelect}
      isClearable={isClearable}
      styles={selectedStyle}
      placeholder={placeholder}
      name={id ?? 'select'}
      value={selectedOption}
      options={options}
      className={className}
      classNamePrefix="react-filter"
      onChange={(options) => handleChange(options)}
      formatOptionLabel={labelFormat}
    />
  );
}

KoalaSelect.defaultProps = {
  isMultiSelect: false,
  isClearable: false,
  size: 'medium',
};

export default KoalaSelect;
