import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as workspaceUtils from 'utils/workspaceUtils';

//import * as TabilityTypes from 'types';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { useParams, Prompt, useHistory } from 'react-router-dom';
// API
import * as remoteApi from 'api/remote';
import { Helmet } from 'react-helmet';
import { useSelector, shallowEqual } from 'react-redux';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import KoalaButton from 'koala/components/Button';
import KoalaAvatar from 'koala/components/Avatar';
import { Membership } from 'types';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowLocale } from 'utils/dateUtils';
import { translateRole } from 'utils/translationUtils';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const DangerZone = styled.div`
  padding-top: ${theme.spacing.x4};
  margin-top: ${theme.spacing.x4};
  border-top: 1px solid ${theme.colors.blockBorder};
  h3 {
    margin-bottom: ${theme.spacing.x3};
  }
`;

const InlineDivs = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  a.btn--secondary {
    background: ${theme.colors.V50};
    color: #fff;

    &:hover {
      background: ${theme.colors.V60};
      color: #fff;
    }
  }
  .disabled {
    cursor: not-allowed;
  }
`;

const staleTime = 0;

function WorkspaceSettingsDetails() {
  const queryCache = useQueryCache();
  const { workspaceSlug, membershipId } = useParams<{ workspaceSlug: string; membershipId: string }>();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const queryKey = [queryKeys.memberships, membershipId];
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const hasPremiumSubscription = workspaceUtils.hasPremiumSubscription(currentWorkspace);

  const [membership, setMembership] = useState<Membership>();
  const [role, setRole] = useState('');
  const [public_api_enabled, setPublicApiEnabled] = useState('false');
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isConfirmationSent, setIsConfirmationSent] = useState(false);

  const { isFetching: isInitialFetch } = useQuery(queryKey, remoteApi.fetchMembershipDetails, {
    staleTime,
    onSuccess: (response) => {
      const membership: Membership = response.data;
      setMembership(membership);
      setRole(membership.role);
      setPublicApiEnabled(membership.public_api_enabled?.toString());
    },
  });

  // Workspace mutations
  const [updateMembershipMutation, { isError, isLoading, error }]: [any, any] = useMutation(
    remoteApi.updateMembership,
    {
      onMutate: () => {
        setIsFormSaved(false);
      },
      onSuccess: () => {
        setIsFormSaved(true);
        setIsFormDirty(false);
      },
    },
  );

  // Workspace mutations
  const [resendConfirmationMutation, { isLoading: resendIsLoading }]: [any, any] = useMutation(
    remoteApi.resendConfirmation,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
        setIsConfirmationSent(true);
      },
      onMutate: () => {
        setIsConfirmationSent(false);
      },
    },
  );

  const [deleteMembershipMutation, { isLoading: isRemovingMembership }]: [any, any] = useMutation(
    remoteApi.deleteMembership,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
        history.push(`/${workspaceSlug}/settings/users`);
      },
    },
  );

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;
    setIsFormDirty(true);
    setIsFormSaved(false);

    switch (name) {
      case 'public_api_enabled':
        setPublicApiEnabled(value);
        break;
      case 'role':
        setRole(value);
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!membership) return;
    const params = {
      role,
      public_api_enabled,
    };
    updateMembershipMutation({
      membershipId: membership.id,
      membership: params,
    });
  };

  const handleRemoveFromWorkspace = (e: any) => {
    if (
      membership &&
      window.confirm(t('workspaceSettingsMemberships.confirmRemove') ?? 'Are you sure you want to remove this user?')
    ) {
      deleteMembershipMutation(membership.id);
    }
  };

  // Display loader if fetching
  if (!membership) {
    return (
      <Fragment>
        <ChromeSideNav>
          <WorkspaceSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <Loader />
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const { user } = membership;

  const handleResendConfirmation = (e: any) => {
    e.preventDefault();
    resendConfirmationMutation(user.email);
  };

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'role':
        return t('errors.role');
      default:
        return '';
    }
  };

  const name = user.fullname || user.email;

  // can edit if owner, or if admin and member they are editing isn't owner
  const isOwner = currentMembership.role === 'owner';
  const canEdit = isOwner || (currentMembership.role === 'admin' && membership.role !== 'owner');

  const roleClassname = errors['role'] ? 'error' : '';
  const canUseApi = hasPremiumSubscription;

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspaceSettingsMemberships.details.title', { user: name })} | Tability
        </title>
      </Helmet>
      <Prompt
        message={t('shared.confirmLeave') ?? 'Your changes are not saved. Are you sure you want to leave?'}
        when={isFormDirty}
      />
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>
              {t('workspaceSettingsMemberships.details.title', { user: name })}
              {isInitialFetch && <Loader size="small" />}
            </h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <KoalaAvatar user={user} />
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsMemberships.email')}</label>
              <p>{user.email}</p>
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsMemberships.confirmed')}</label>
              {user.confirmed_at && <p>{t('workspaceSettingsMemberships.details.yes')}</p>}
              {!user.confirmed_at && (
                <p>
                  {t('workspaceSettingsMemberships.details.no')} &nbsp;
                  <KoalaButton
                    onClick={handleResendConfirmation}
                    disabled={resendIsLoading}
                    loading={resendIsLoading}
                    appearance="subtle"
                  >
                    {isConfirmationSent
                      ? t('workspaceSettingsMemberships.details.sentConfirmation')
                      : t('workspaceSettingsMemberships.details.resendConfirmation')}
                  </KoalaButton>
                </p>
              )}
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsMemberships.joined')}</label>
              <p>{formatDistanceToNowLocale(membership.created_at, i18n, true)}</p>
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsMemberships.role')}</label>
              {!canEdit && <p>{translateRole(role, t)}</p>}
              {canEdit && (
                <select value={role} onChange={handleChange} className={`${roleClassname}`} name="role">
                  <option value={'readonly'}>{t('role.readonly')}</option>
                  <option value={'user'}>{t('role.user')}</option>
                  <option value={'admin'}>{t('role.admin')}</option>
                  {isOwner && <option value={'owner'}>{t('role.owner')}</option>}
                </select>
              )}
            </FormField>
            {canUseApi && (
              <FormField>
                <label>{t('workspaceSettingsMemberships.publicApiEnabled')}</label>
                <select value={public_api_enabled} onChange={handleChange} name="public_api_enabled">
                  <option value="true">{t('workspaceSettingsMemberships.details.yes')}</option>
                  <option value="false">{t('workspaceSettingsMemberships.details.no')}</option>
                </select>
              </FormField>
            )}
            {!canUseApi && (
              <FormField>
                <label>{t('workspaceSettingsMemberships.publicApiEnabled')}</label>
                <InlineDivs>
                  <select disabled className="disabled" name="public_api_enabled">
                    <option value="false">{t('workspaceSettingsMemberships.details.no')}</option>
                  </select>
                  <span>{t('workspaceSettingsMemberships.premiumRequiredForPublicAPI')}</span>
                  {isOwner && (
                    <KoalaButton
                      size="small"
                      appearance="secondary"
                      isLink
                      to={`/${currentWorkspace.slug}/settings/billing`}
                    >
                      {t('workspaceInsights.upgrade.unlock')}
                    </KoalaButton>
                  )}
                </InlineDivs>
              </FormField>
            )}
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            {canEdit && (
              <Actions>
                <KoalaButton submit disabled={isLoading} loading={isLoading}>
                  {t('shared.save')}
                </KoalaButton>
                {isFormSaved && <div>{t('shared.saved')}</div>}
              </Actions>
            )}
          </Form>
          {canEdit && (
            <DangerZone>
              <h3> {t('workspaceSettingsMemberships.details.danger')}</h3>
              <FormField>
                <KoalaButton
                  onClick={handleRemoveFromWorkspace}
                  disabled={isRemovingMembership}
                  loading={isRemovingMembership}
                  appearance="danger"
                >
                  {t('workspaceSettingsMemberships.details.removeButton', { count: 1 })}
                </KoalaButton>
              </FormField>
            </DangerZone>
          )}
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
