import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Outcome, Objective } from 'types';
import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaIcon from 'koala/components/Icons';
import KoalaLoader from 'koala/components/Loader';
import { AxiosResponse } from 'axios';
import RelatedOutcomeItem from 'components/RelatedOutcomeItem';
import RelatedObjectivesForParentObjective from 'components/RelatedObjectivesForParentObjective';
import RelatedOutcomesForParentObjective from 'components/RelatedOutcomesForParentObjective';

const OutcomeListItem = styled.li``;
const OutcomeChildContainer = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: 1fr;
  grid-template-areas: 'left';
  align-items: start;
  column-gap: ${theme.spacing.x1};

  &:hover {
    background: ${theme.colors.N3};
  }
  &.selected,
  &:active,
  &:focus {
    background: ${theme.colors.B5};
  }
`;

const BlockLeft = styled.div`
  grid-area: left;
  display: flex;

  > div {
    &:first-of-type {
      padding-left: 1.2rem;
    }
  }
`;

const InitiativesContainer = styled.ul`
  margin-left: 4.4rem;

  transition: all ease 0.4s;

  .initiative-row {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
  }
`;

const ExpandButton = styled.div`
  display: inline-flex;
  width: 3.6rem;
  justify-content: center;
  padding: 1.2rem;
  line-height: 2rem;
  margin-right: ${theme.spacing.x1};

  align-self: flex-start;
  position: relative;
  top: 0.2rem;

  div {
    transition: all ease 0.2s;
  }
  .expanded {
    transform: rotate(90deg);
  }

  &:hover {
    background: ${theme.colors.N10};
  }
`;

const OutcomeTitle = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  padding: 1.2rem 0;

  .plan-title {
    color: ${theme.colors.subtleText};
    font-size: 1.2rem;
  }
`;

interface Props {
  objective: Objective;
  hideClosedInitiatives: boolean;
  isInitial?: boolean;
}

function RelatedObjectiveItem(props: Props) {
  const { objective, hideClosedInitiatives, isInitial } = props;
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const [outcomes, setOutcomes] = useState<Outcome[]>([]);
  const hashPath = `#objective:${objective.nano_slug}:show`;

  // get the outcomes
  const queryKeyOutcomes = [queryKeys.outcomes, objective.id];
  const { isLoading: isOutcomesLoading } = useQuery(queryKeyOutcomes, remoteApi.fetchOutcomes, {
    onSuccess: (response: AxiosResponse) => {
      setOutcomes(response.data);
    },
  });

  const handleObjectiveClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashPath);
  };

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  if (isInitial) {
    return (
      <OutcomeListItem className="objective" key={objective.id}>
        <OutcomeChildContainer onClick={handleObjectiveClick}>
          <BlockLeft className="outcome-row--left">
            <ExpandButton className="expand-gutter" onClick={toggleExpand} data-outcome={objective.id}>
              <KoalaIcon iconName="rightChevron" iconSize={1.6} className={isExpanded ? 'expanded' : 'collapsed'} />
            </ExpandButton>
            <OutcomeTitle>
              <KoalaIcon iconName="flag" iconAppearance={theme.colors.T80} />
              <Link to={hashPath}>
                <span className="plan-title">{objective.plan.title} / </span>
                {objective.title}
              </Link>
            </OutcomeTitle>
          </BlockLeft>
        </OutcomeChildContainer>
        {isExpanded && (
          <InitiativesContainer className={isExpanded ? 'expanded' : 'collapsed'}>
            {isOutcomesLoading && <KoalaLoader />}
            {outcomes.map((outcome: Outcome) => (
              <RelatedOutcomeItem key={outcome.id} outcome={outcome} hideClosedInitiatives={hideClosedInitiatives} />
            ))}
            <RelatedObjectivesForParentObjective
              objectiveId={objective.id}
              hideClosedInitiatives={hideClosedInitiatives}
            />
          </InitiativesContainer>
        )}
      </OutcomeListItem>
    );
  }

  return (
    <OutcomeListItem className="objective tree-item" key={objective.id}>
      <OutcomeChildContainer onClick={handleObjectiveClick}>
        <BlockLeft className="outcome-row--left">
          <ExpandButton className="expand-gutter" onClick={toggleExpand} data-outcome={objective.id}>
            <KoalaIcon iconName="rightChevron" iconSize={1.2} className={isExpanded ? 'expanded' : 'collapsed'} />
          </ExpandButton>
          <OutcomeTitle>
            <KoalaIcon iconName="flag" iconAppearance={theme.colors.T80} />
            <Link to={hashPath}>
              <span className="plan-title">{objective.plan.title} / </span>
              {objective.title}
            </Link>
          </OutcomeTitle>
        </BlockLeft>
      </OutcomeChildContainer>
      {isExpanded && (
        <InitiativesContainer className={isExpanded ? 'expanded' : 'collapsed'}>
          {isOutcomesLoading && <KoalaLoader />}
          {outcomes.map((outcome: Outcome) => (
            <RelatedOutcomeItem key={outcome.id} outcome={outcome} hideClosedInitiatives={hideClosedInitiatives} />
          ))}
          <RelatedObjectivesForParentObjective
            objectiveId={objective.id}
            hideClosedInitiatives={hideClosedInitiatives}
          />
          <RelatedOutcomesForParentObjective objectiveId={objective.id} hideClosedInitiatives={hideClosedInitiatives} />
        </InitiativesContainer>
      )}
    </OutcomeListItem>
  );
}

export default React.memo(RelatedObjectiveItem);
