import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BlurDiv } from '.';
import { AuditEvent } from 'types';

interface Props {
  event: AuditEvent;
  canViewAudit: boolean;
}

function PlanArchived(props: Props) {
  const { event, canViewAudit } = props;
  const { t } = useTranslation();

  const { membership_cached_fullname, membership_cached_email, source_cached_title, membership_id, event_meta } = event;
  let name = membership_cached_fullname || membership_cached_email;
  if (!membership_id) {
    name += ` ${t('auditEvents.deleted')}`;
  }

  const meta = JSON.parse(event_meta);
  const isArchived = meta['archived'] && meta['archived'][1];

  return (
    <span>
      {isArchived && (
        <Trans
          i18nKey="auditEvents.planArchived"
          values={{ name, title: source_cached_title }}
          components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
        />
      )}
      {!isArchived && (
        <Trans
          i18nKey="auditEvents.planUnarchived"
          values={{ name, title: source_cached_title }}
          components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
        />
      )}
    </span>
  );
}

export default React.memo(PlanArchived);
