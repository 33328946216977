import React from 'react';

const GoalsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.875 10C5.875 9.44772 6.32272 9 6.875 9H17.125C17.6773 9 18.125 9.44772 18.125 10C18.125 10.5523 17.6773 11 17.125 11H6.875C6.32272 11 5.875 10.5523 5.875 10Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.875 5C5.875 4.44772 6.32272 4 6.875 4L17.125 4C17.6773 4 18.125 4.44772 18.125 5C18.125 5.55228 17.6773 6 17.125 6H6.875C6.32272 6 5.875 5.55228 5.875 5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.875 15C5.875 14.4477 6.32272 14 6.875 14H16.875C17.4273 14 17.875 14.4477 17.875 15C17.875 15.5523 17.4273 16 16.875 16H6.875C6.32272 16 5.875 15.5523 5.875 15Z"
      fill="#737373"
    />
    <path
      d="M4 5C4 5.55228 3.55228 6 3 6C2.44772 6 2 5.55228 2 5C2 4.44772 2.44772 4 3 4C3.55228 4 4 4.44772 4 5Z"
      fill="#737373"
    />
    <path
      d="M4 10C4 10.5523 3.55228 11 3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9C3.55228 9 4 9.44772 4 10Z"
      fill="#737373"
    />
    <path
      d="M3.875 15C3.875 15.5523 3.42728 16 2.875 16C2.32272 16 1.875 15.5523 1.875 15C1.875 14.4477 2.32272 14 2.875 14C3.42728 14 3.875 14.4477 3.875 15Z"
      fill="#737373"
    />
  </svg>
);
export default GoalsIcon;
