import React, { useState } from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';

import { useSelector, shallowEqual } from 'react-redux';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import { useParams } from 'react-router-dom';
import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import theme from 'theme';

const ErrorContainer = styled.small`
  color: ${theme.colors.R50};
`;

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();
  const translationKey = 'modals.dataConnectors';

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcome.id, `data_source:google_tasks`, 'current'];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null) {
    return <FormField>{t(`${translationKey}.errorFetching`)}</FormField>;
  }

  return (
    <FormField>
      <label>{t(`${translationKey}.preview`)}</label>
      <p>{currentValue}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  integration: any;
}

function GoogleTasksSource(props: Props) {
  const { outcome } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [taskListOptions, setTaskListOptions] = useState<KoalaSelectOption[]>([]);
  const [taskList, setTaskList] = useState<string>(outcome.data_source_meta ? outcome.data_source_meta.task_list : '');
  const [listErrors, setListErrors] = useState('');
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');
  const { t } = useTranslation();
  const translationKey = 'modals.dataConnectors';
  const tasksKey = 'modals.dataConnectors.googleTasks';
  const currentMembership: TabilityTypes.Membership = useSelector(
    (state: any) => state.session.currentMembership,
    shallowEqual,
  );
  const queryCache = useQueryCache();

  const staleTime = 0;
  const queryKeyTaskList = ['google_task_list', workspaceSlug];
  const { isLoading: isListLoading } = useQuery(queryKeyTaskList, remoteApi.listGoogleTasksLists, {
    staleTime,
    onSuccess: (response) => {
      if (!response.data?.task_lists) {
        setTaskListOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.task_lists.map((taskList: any) => ({
        label: taskList.title,
        value: taskList.id,
      }));
      setTaskListOptions(options);
    },
    onError: (error: any) => {
      const errorString = error.response.data.error;
      setListErrors(errorString);
    },
  });

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }]: [any, any] = useMutation(
    remoteApi.updateOutcomeDataSource,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.currentOutcome);
      },
    },
  );

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const data_source_meta = {
      task_list: taskList,
      membership_id: currentMembership.id, // need the membership id to know which google integration to use
    };

    let body = {
      data_source_origin: 'google_tasks',
      data_source_type,
      data_source_meta,
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const onProjectChange = (option: any) => {
    const value = option ? option.value : null;
    setTaskList(value);
  };

  const selectedListOption = taskListOptions.find((option) => option.value === taskList);
  const projectPlaceholder = t(`${tasksKey}.listPlaceholder`) ?? 'Select task list';

  return (
    <>
      <FormField>
        <label>{t(`${tasksKey}.taskList`)}</label>
        {isListLoading && <KoalaLoader />}
        {!isListLoading && (
          <div style={{ width: '50%' }}>
            <KoalaSelect
              handleChange={(options) => onProjectChange(options)}
              placeholder={projectPlaceholder}
              selectedOption={selectedListOption}
              options={taskListOptions}
              className="small"
            />
            {listErrors && <ErrorContainer>{listErrors}</ErrorContainer>}
          </div>
        )}
      </FormField>
      <FormField>
        <label>{t(`${translationKey}.changeMetric`)}</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">{t(`panels.editOutcome.selectMetric`)}</option>
          <option value="task_count">{t(`${tasksKey}.currentTaskCount`)}</option>
          <option value="tasks_completed">{t(`${tasksKey}.taskCompleted`)}</option>
        </select>
      </FormField>
      <FormField>
        <KoalaButton
          onClick={handleUpdate}
          loading={isUpdating}
          disabled={!data_source_type || isUpdating || !taskList}
          appearance="subtle"
        >
          {t(`panels.editOutcome.savePreview`)}
        </KoalaButton>
      </FormField>
      {outcome.data_source_origin === 'google_tasks' && (
        <>
          <CurrentValue outcome={outcome} />
        </>
      )}
    </>
  );
}

export default React.memo(GoogleTasksSource);
