import React from 'react';

const PlanIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.8 3.52C2.8 2.15231 3.88437 1 5.275 1H11.8C12.0387 1 12.2676 1.09482 12.4364 1.2636L16.9364 5.7636C17.1052 5.93239 17.2 6.16131 17.2 6.4V16.48C17.2 17.8477 16.1156 19 14.725 19H5.275C3.88437 19 2.8 17.8477 2.8 16.48V3.52ZM5.275 2.8C4.92594 2.8 4.6 3.09829 4.6 3.52V16.48C4.6 16.9017 4.92594 17.2 5.275 17.2H14.725C15.0741 17.2 15.4 16.9017 15.4 16.48V6.77279L11.4272 2.8H5.275Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8 1C12.2971 1 12.7 1.40294 12.7 1.9V5.5H16.3C16.7971 5.5 17.2 5.90294 17.2 6.4C17.2 6.89706 16.7971 7.3 16.3 7.3H11.8C11.3029 7.3 10.9 6.89706 10.9 6.4V1.9C10.9 1.40294 11.3029 1 11.8 1Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4 10.9C6.4 10.4029 6.80295 10 7.3 10H12.7C13.1971 10 13.6 10.4029 13.6 10.9C13.6 11.3971 13.1971 11.8 12.7 11.8H7.3C6.80295 11.8 6.4 11.3971 6.4 10.9Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4 14.5C6.4 14.0029 6.80295 13.6 7.3 13.6L12.7 13.6C13.1971 13.6 13.6 14.0029 13.6 14.5C13.6 14.9971 13.1971 15.4 12.7 15.4L7.3 15.4C6.80295 15.4 6.4 14.9971 6.4 14.5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4 7.29999C6.4 6.80294 6.80295 6.39999 7.3 6.39999H8.2C8.69706 6.39999 9.1 6.80294 9.1 7.29999C9.1 7.79705 8.69706 8.19999 8.2 8.19999H7.3C6.80295 8.19999 6.4 7.79705 6.4 7.29999Z"
      fill="#737373"
    />
  </svg>
);

export default PlanIcon;
