import { KoalaAppearances, KoalaSizes } from 'koala/shared/global';
import styled from 'styled-components';
import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import { ReactElement } from 'react-markdown';
import { colors, spacing, typography } from 'koala/shared/styles';

interface ProgressData {
  x: number;
  y: number;
  color: string;
  legendTitle: string;
}

interface ProgressPieProps {
  /** data for ProgressPie */
  data: ProgressData[];
  /** Optional element for the legend */
  legendElement?: ReactElement;
  /** Optional size from KoalaSizes */
  size?: (typeof KoalaSizes)[number];
  /** Optional appearance from KoalaAppearances */
  appearance?: (typeof KoalaAppearances)[number];
  dataFor?: string;
  /** Optional class name for Progress Pie */
  className?: string;
  /** Optional custom styling for Progress Pie */
  style?: React.CSSProperties;
  /** Label at the center */
  centerLabel: string | string[];
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing.x4};

  &.confidence-pie--xsmall {
    .confidence-pie-chart {
      width: 2rem;
      height: 2rem;
    }
  }
  &.confidence-pie--small {
    .confidence-pie-chart {
      width: 4.5rem;
      height: 4.5rem;
    }
  }
  &.confidence-pie--medium {
    .confidence-pie-chart {
      width: 6rem;
      height: 6rem;
    }
  }
  &.confidence-pie--large {
    .confidence-pie-chart {
      width: 10rem;
      height: 10rem;
    }
  }
`;
const ChartContainer = styled.div`
  margin: 0;
`;

function KoalaConfidencePie(props: ProgressPieProps) {
  const { data, legendElement, size, dataFor, className, style, centerLabel } = props;

  const pieClass = `confidence-pie ${className ?? ''} confidence-pie--${size ? size : 'medium'}`;

  let pieArguments: any = {};
  let labelStyleArguments: any = [];
  let dyArgument = null;
  let labelColor = colors.G50;
  if (Array.isArray(centerLabel) && parseInt(centerLabel[0]) <= 30) {
    labelColor = colors.Y90;
  }
  if (Array.isArray(centerLabel) && parseInt(centerLabel[0]) < 0) {
    labelColor = colors.R50;
  }
  const font = typography.fontFamily;
  switch (size) {
    case 'xsmall':
      pieArguments = { width: 20, height: 20, innerRadius: 6 };
      break;
    case 'small':
      pieArguments = { width: 45, height: 45, innerRadius: 18 };
      labelStyleArguments.push({ fontSize: 10, fontWeight: 600, fill: labelColor, fontFamily: font });
      if (Array.isArray(centerLabel)) {
        labelStyleArguments.push({ fontSize: 9, fontWeight: 400, fill: labelColor, fontFamily: font });
        dyArgument = 4;
      }
      break;
    case 'medium':
      pieArguments = { width: 60, height: 60, innerRadius: 24 };
      labelStyleArguments.push({ fontSize: 16, fontWeight: 500, fill: labelColor, fontFamily: font });
      if (Array.isArray(centerLabel)) {
        labelStyleArguments.push({
          fontSize: 10,
          fontWeight: 400,
          fill: labelColor,
          fontFamily: font,
        });
        dyArgument = 2;
      }
      break;
    case 'large':
      pieArguments = { width: 100, height: 100, innerRadius: 42 };
      labelStyleArguments.push({ fontSize: 24, fontWeight: 500, fill: labelColor, fontFamily: font });
      if (Array.isArray(centerLabel)) {
        labelStyleArguments.push({
          fontSize: 16,
          fontWeight: 400,
          fill: labelColor,
          fontFamily: font,
        });
        dyArgument = 6;
      }
      break;
    default:
      pieArguments = { width: 100, height: 100, innerRadius: 42 };
      labelStyleArguments.push({ fontSize: 24, fontWeight: 500, fill: labelColor, fontFamily: font });
      break;
  }

  return (
    <Container style={style} className={pieClass} data-tip={dataFor ? true : false} data-for={dataFor}>
      <ChartContainer className="confidence-pie-chart">
        <svg viewBox={`0 0 ${pieArguments.width} ${pieArguments.height}`} width="100%" height="100%">
          <VictoryPie
            standalone={false}
            cornerRadius={4}
            style={{
              data: {
                fill: (data: any) => {
                  return data.datum.color;
                },
              },
            }}
            padding={0}
            padAngle={4}
            data={data}
            labels={() => null}
            {...pieArguments}
          />
          {dyArgument && (
            <VictoryLabel
              textAnchor="middle"
              verticalAnchor="middle"
              x={pieArguments.width / 2}
              y={pieArguments.height / 2}
              text={centerLabel}
              style={labelStyleArguments}
              dy={dyArgument}
            />
          )}
          {!dyArgument && (
            <VictoryLabel
              textAnchor="middle"
              verticalAnchor="middle"
              x={pieArguments.width / 2}
              y={pieArguments.height / 2}
              text={centerLabel}
              style={labelStyleArguments}
            />
          )}
        </svg>
      </ChartContainer>
      {legendElement}
    </Container>
  );
}

KoalaConfidencePie.defaultProps = {
  size: 'medium',
  appearance: 'primary',
};

export default KoalaConfidencePie;
