import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  display: grid;
  position: relative;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'avatar header meta'
    'avatar content content'
    'avatar footer footer';
  column-gap: ${theme.spacing.x2};
  row-gap: ${theme.spacing.x1};

  padding: 1.2rem ${theme.spacing.x2};
  border-radius: 8px;
  cursor: pointer;

  .comment {
    background: #f3f3f3;
  }

  &:hover {
    background: #f3f3f3;

    .comment {
      background: #e7e7e7;
    }
  }
`;

export const Header = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.blockBorder};
`;

export const Gutter = styled.div`
  grid-area: avatar;
`;

export const Meta = styled.div`
  grid-area: meta;
`;

export const Content = styled.div`
  grid-area: content;
  flex: 1;

  .header {
    margin-bottom: ${theme.spacing.x1};
    font-weight: 600;
  }

  .content {
    display: flex;
    align-items: center;
  }

  b {
  }

  em {
    font-style: italic;
  }
`;

export const Footer = styled.div`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.subtleText};
  font-size: 1.2rem;
  font-weight: 600;
  > div {
    display: flex;
    align-items: center;
  }
`;
