import React, { useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

import Map from './Map';

import { Edge, Node, Position, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';

// API
import * as remoteApi from 'api/remote';

// Components
import Loader from 'components/Loader';
import { AxiosResponse } from 'axios';
import theme from 'theme';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

interface Props {
  plan: TabilityTypes.Plan;
}

function PlanContent(props: Props) {
  const { plan } = props;
  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);
  const [initialNodes, setInitialNodes] = useState<Node[]>([]);

  // Starting to use staletime to reduce the # of queries
  const staleTime = 0;

  // Load the initial outcomes that we will use to populate the map
  const { isLoading: outcomesAreLoading } = useQuery(
    [queryKeys.outcomes, plan.nano_slug],
    remoteApi.fetchPlanOutcomes,
    {
      staleTime,
      onSuccess: (response: AxiosResponse<TabilityTypes.Outcome[]>) => {
        const outcomes: TabilityTypes.Outcome[] = response.data;
        const position = { x: 0, y: 0 };
        const nodes: Node[] = outcomes.map((outcome) => ({
          id: outcome.nano_slug,
          type: 'outcome',
          data: { outcome: { ...outcome, plan }, expanded: false },
          position,
          sourcePosition: Position.Bottom,
          targetPosition: Position.Top,
        }));

        // Add the plan node
        nodes.push({
          id: plan.nano_slug,
          type: 'plan',
          data: { plan, expanded: true },
          position,
          sourcePosition: Position.Bottom,
          targetPosition: Position.Top,
        });

        // Create all the connections between the plan and the outcomes
        const edges: Edge[] = outcomes.map((outcome) => ({
          id: `${plan.nano_slug} -> ${outcome.nano_slug}`,
          target: outcome.nano_slug,
          source: plan.nano_slug,
          targetHandle: 'in',
          sourceHandle: 'out',
          animated: true,
          pathOptions: { offset: 5 },
          style: {
            strokeWidth: 1,
            stroke: theme.colors.B30,
          },
        }));
        setInitialEdges(edges);
        setInitialNodes(nodes);
      },
    },
  );

  // Wait until plan and outcomes are loaded to display the plan map
  if (!plan || outcomesAreLoading) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  return (
    <ReactFlowProvider>
      <Map plan={plan} initialEdges={initialEdges} initialNodes={initialNodes} />
    </ReactFlowProvider>
  );
}

export default React.memo(PlanContent);
