import React from 'react';
import { Trans } from 'react-i18next';
import { ContentAction } from './NewsItem';
interface Props {
  event: any;
}

function Block(props: Props) {
  const { event } = props;
  const { membership, source } = event;
  if (!membership) {
    return null;
  }

  const { user } = membership;
  const name = user.fullname || user.email;

  return (
    <>
      <ContentAction>
        <Trans i18nKey="newsfeed.initativeMovedNow" values={{ name, title: source.title }} components={{ b: <b /> }} />
      </ContentAction>
    </>
  );
}

export default React.memo(Block);
