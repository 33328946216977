//flow

interface Props {
  dispatch: any;
  getState: any;
}

const callAPIMiddleware = ({ dispatch, getState }: Props) => {
  return (next: any) => (action: any) => {
    const { types, callAPI, shouldCallAPI = () => true, payload = {} } = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (!Array.isArray(types) || types.length !== 3 || !types.every((type) => typeof type === 'string')) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (!shouldCallAPI(getState())) {
      return;
    }

    const [requestType, successType, failureType] = types;

    dispatch(
      Object.assign({}, payload, {
        type: requestType,
      }),
    );

    return callAPI().then(
      (response: any) =>
        dispatch(
          Object.assign({}, payload, {
            response,
            type: successType,
          }),
        ),
      (error: any) =>
        dispatch(
          Object.assign({}, payload, {
            error,
            type: failureType,
          }),
        ),
    );
  };
};

export default callAPIMiddleware;
