import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';

import { useDispatch } from 'react-redux';
import { ModalGrid, ModalHeader, ModalContent } from 'components/GlobalModal';
import { Membership, Workspace } from 'types';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useSelector, shallowEqual } from 'react-redux';
import * as workspaceUtils from 'utils/workspaceUtils';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

import KoalaIconButton from 'koala/components/IconButton';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import AddPersonIcon from 'components/_assets/AddPersonIcon';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaLoader from 'koala/components/Loader';

import UpgradePanel from 'components/UpgradePanel';

import { MarkdownWrapper } from 'components/MarkdownContent';
import { useTranslation } from 'react-i18next';

const OwnerLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  &.header {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const NameContainer = styled.div`
  min-width: 30rem;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background: #f3f3f3;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }

  &.active {
    background: #f3f3f3;
    &:hover {
      cursor: initial;
      background: #f3f3f3;
    }
  }

  &.not-assigned {
    opacity: 0.5;
  }

  &.option-button {
    background: #f3f3f3;
    svg {
      height: 1.8rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
    &:hover {
      cursor: pointer;
      background: #e7e7e7;
    }
  }
`;

const ManagingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x1};

  label {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 800;
  }
`;
const ErrorLine = styled.div`
  color: #da412e;
  font-size: 1.2rem;
`;

const Teaser = styled.div`
  margin-top: ${theme.spacing.x2};
`;

interface Props {
  membershipId: string;
}

function EditReporting(props: Props) {
  const { membershipId } = props;
  const dispatch = useDispatch();

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [manager, setManager] = useState<Membership | null>();
  const [direct_reports, setDirectReports] = useState<Membership[]>([]);
  const [errors, setErrors] = useState({ manager: '', direct_reports: '' });
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.editReporting';

  useEffect(() => {
    if (
      !workspaceUtils.hasV4PlusSubscription(currentWorkspace) &&
      !workspaceUtils.hasPremiumSubscription(currentWorkspace)
    ) {
      amplitudeUtils.sendAmplitudeData('app.reporting.upgrade_needed', {
        'Tability Platform': 'web',
        'Tability Version': 2,
      });
    } else {
      amplitudeUtils.sendAmplitudeData('app.reporting.edit', {
        'Tability Platform': 'web',
        'Tability Version': 2,
      });
    }
  }, [currentWorkspace]);

  // get manager
  const staleTime = 0;
  const managerQueryKey = [queryKeys.reporting, 'manager', membershipId];
  const { isLoading: isManagerLoading } = useQuery(managerQueryKey, remoteApi.fetchMembershipManager, {
    staleTime,
    onSuccess: (response) => {
      const membership = response.data;
      setManager(membership);
    },
  });

  const direct_reportsQueryKey = [queryKeys.reporting, 'direct_reports', membershipId];
  const { isLoading: isDirectReportsLoading } = useQuery(
    direct_reportsQueryKey,
    remoteApi.fetchMembershipDirectReports,
    {
      staleTime,
      onSuccess: (response) => {
        const memberships = response.data;
        setDirectReports(memberships);
      },
    },
  );

  const [updateManagerMutation] = useMutation(remoteApi.updateManager, {
    onSuccess: (response: any) => {
      setErrors({ manager: '', direct_reports: '' });
      queryCache.invalidateQueries(managerQueryKey);
      queryCache.invalidateQueries(direct_reportsQueryKey);
    },
  });

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleRemoveManager = () => {
    updateManagerMutation({
      membershipId: membershipId,
      membership: { manager_id: null },
    });
  };

  const handleAssignManager = (value: string) => {
    if (value === membershipId) {
      setErrors((e) => ({ ...e, manager: t(`${translationKey}.managerError`) }));
      return;
    }
    updateManagerMutation({
      membershipId: membershipId,
      membership: { manager_id: value },
    });
  };

  const handleAssignReporter = (value: string) => {
    if (value === membershipId) {
      setErrors((e) => ({ ...e, direct_reports: t(`${translationKey}.managerError`) }));
      return;
    }
    updateManagerMutation({
      membershipId: value,
      membership: { manager_id: membershipId },
    });
  };
  const handleRemoveReporter = (value: string) => {
    updateManagerMutation({
      membershipId: value,
      membership: { manager_id: null },
    });
  };

  const ignoredMemberships = [membershipId];

  // If we don't have the plus subscription, we display and upgrade panel
  if (
    !workspaceUtils.hasV4PlusSubscription(currentWorkspace) &&
    !workspaceUtils.hasPremiumSubscription(currentWorkspace)
  ) {
    return (
      <ModalGrid>
        <ModalHeader>
          <h2>{t(`${translationKey}.title`)}</h2>
          <KoalaIconButton iconName="close" onClick={handleCancel} />
        </ModalHeader>
        <ModalContent>
          <UpgradePanel
            title={t(`${translationKey}.upgradeRequired`)}
            description={t(`${translationKey}.upgradePlusPlan`)}
          />
          <Teaser>
            <MarkdownWrapper>
              <p>{t(`${translationKey}.upgradeInfo`)}</p>
              <ul>
                <li>{t(`${translationKey}.upgradeInfo1`)}</li>
                <li>{t(`${translationKey}.upgradeInfo2`)}</li>
                <li>{t(`${translationKey}.upgradeInfo3`)}</li>
              </ul>
            </MarkdownWrapper>
          </Teaser>
        </ModalContent>
      </ModalGrid>
    );
  }

  return (
    <ModalGrid className="">
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton iconName="close" onClick={handleCancel} />
      </ModalHeader>
      <ModalContent className="hide-overflow">
        <ManagingContainer>
          <Section>
            <label>{t(`${translationKey}.manager`)}</label>
            {isManagerLoading && <KoalaLoader />}
            {!isManagerLoading && (
              <div>
                {!manager && (
                  <UserSearchableDropdown
                    ignoredMemberships={ignoredMemberships}
                    position="left"
                    trigger={
                      <OwnerOption className="active option-button">
                        <AddPersonIcon />
                        <span className="owner-option-name">{t(`${translationKey}.selectManager`)}</span>
                      </OwnerOption>
                    }
                    callback={handleAssignManager}
                  />
                )}

                {manager && (
                  <OwnerLine>
                    <NameContainer>
                      <OwnerOption className="active">
                        <KoalaAvatar user={manager.user} size={2.2} />
                        <span className="owner-option-name">{manager.cached_fullname ?? manager.cached_email}</span>
                      </OwnerOption>
                    </NameContainer>

                    <div data-tip data-for="remove-disabled">
                      <KoalaTextButton onClick={handleRemoveManager}>{t('shared.remove')}</KoalaTextButton>
                    </div>
                  </OwnerLine>
                )}
              </div>
            )}

            <ErrorLine>{errors.manager}</ErrorLine>
          </Section>
          <Section>
            <label>{t(`${translationKey}.directReports`)}</label>
            <div>
              <UserSearchableDropdown
                ignoredMemberships={ignoredMemberships}
                position="left"
                trigger={
                  <OwnerOption className="active option-button">
                    <AddPersonIcon /> <span className="owner-option-name">{t(`${translationKey}.selectDirect`)}</span>
                  </OwnerOption>
                }
                callback={handleAssignReporter}
              />
            </div>
            {isDirectReportsLoading && <KoalaLoader />}
            {!isDirectReportsLoading &&
              direct_reports.map((direct_report, i) => (
                <OwnerLine key={i}>
                  <NameContainer>
                    <OwnerOption className="active">
                      <KoalaAvatar user={direct_report.user} size={2.2} />
                      <span className="owner-option-name">
                        {direct_report.cached_fullname ?? direct_report.cached_email}
                      </span>
                    </OwnerOption>
                  </NameContainer>

                  <div data-tip data-for="remove-disabled">
                    <KoalaTextButton onClick={() => handleRemoveReporter(direct_report.id)}>
                      {t('shared.remove')}
                    </KoalaTextButton>
                  </div>
                </OwnerLine>
              ))}
            <ErrorLine>{errors.direct_reports}</ErrorLine>
          </Section>
        </ManagingContainer>
      </ModalContent>
    </ModalGrid>
  );
}

export default React.memo(EditReporting);
