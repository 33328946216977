import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 5H4C1.791 5 0 6.791 0 9V27C0 29.209 1.791 31 4 31H32C34.209 31 36 29.209 36 27V9C36 6.791 34.209 5 32 5Z"
      fill="#DA251D"
    />
    <path
      d="M19.7532 16.0366L18.0002 10.6416L16.2472 16.0366H10.5752L15.1642 19.3696L13.4112 24.7646L18.0002 21.4306L22.5892 24.7646L20.8362 19.3696L25.4252 16.0366H19.7532Z"
      fill="#FFFF00"
    />
  </svg>
);
