import React from 'react';

import styled from 'styled-components';
import theme from 'theme';
// Custom components

const Description = styled.a`
  background: #f3f3f3;
  padding: ${theme.spacing.x3};

  border-radius: 8px;
  display: flex;
  flex-direction: column;

  &:hover {
    background: #e7e7e7;
  }

  small {
    padding-top: ${theme.spacing.x1};
  }
`;

interface Props {
  template: any;
}
function TemplateDirectory(props: Props) {
  const { template } = props;

  return (
    <Description href={`/templates/t/${template.id}`}>
      <b>{template.title}</b>
      <small className="subtle">{template.excerpt}</small>
    </Description>
  );
}

export default TemplateDirectory;
