import React from 'react';

const BellIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9967 2.5824C10.9989 2.55523 11 2.52774 11 2.5C11 1.94772 10.5523 1.5 10 1.5C9.44772 1.5 9 1.94772 9 2.5C9 2.52774 9.00113 2.55523 9.00335 2.5824C6.16399 3.0571 4 5.52584 4 8.5V8.60169C4 9.21518 3.86238 9.82086 3.59728 10.3741L2.55849 12.542C2.19085 13.3093 2 14.1492 2 15H2.00004L2 15.0128C2 15.7789 2.39009 16.3878 2.89452 16.8257C3.38562 17.252 4.03384 17.5658 4.73316 17.7996C6.13868 18.2695 8.00603 18.5 10 18.5C11.994 18.5 13.8613 18.2695 15.2668 17.7996C15.9662 17.5658 16.6144 17.252 17.1055 16.8257C17.6099 16.3878 18 15.7789 18 15.0128L18 15H18C18 14.1492 17.8092 13.3093 17.4415 12.542L16.4027 10.3741C16.1376 9.82086 16 9.21518 16 8.60169V8.5C16 5.52584 13.836 3.0571 10.9967 2.5824ZM10 4.5C7.79086 4.5 6 6.29086 6 8.5V8.60169C6 9.51432 5.79528 10.4153 5.40091 11.2384L4.9706 12.1364C6.34581 11.7114 8.11594 11.5 10 11.5C11.8841 11.5 13.6542 11.7114 15.0294 12.1364L14.5991 11.2384C14.2047 10.4153 14 9.51432 14 8.60169V8.5C14 6.29086 12.2091 4.5 10 4.5ZM4.20858 14.7037C4.00272 14.8838 4 14.9849 4 15.0128C4 15.0397 4.00166 15.1383 4.20567 15.3154C4.42301 15.5041 4.79992 15.7131 5.36735 15.9028C6.49533 16.2799 8.12797 16.5 10 16.5C11.872 16.5 13.5047 16.2799 14.6327 15.9028C15.2001 15.7131 15.577 15.5041 15.7943 15.3154C15.9983 15.1383 16 15.0397 16 15.0128C16 14.9849 15.9973 14.8838 15.7914 14.7037C15.573 14.5126 15.1953 14.301 14.6283 14.1086C13.9259 13.8702 13.0276 13.6926 11.9979 13.5926C11.9495 14.6542 11.0735 15.5 10 15.5C8.92645 15.5 8.05047 14.6542 8.00211 13.5926C6.97241 13.6926 6.07412 13.8702 5.37166 14.1086C4.80467 14.301 4.42696 14.5126 4.20858 14.7037Z"
      fill="#737373"
    />
  </svg>
);

export default BellIcon;
