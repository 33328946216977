import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import ReactTooltip from 'react-tooltip';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import { parseISO, differenceInCalendarDays } from 'date-fns';

import KoalaIcon from 'koala/components/Icons';

import ProgressChart from './ProgressChart';
import ConfidenceChart from './ConfidenceChart';
import NCSChart from './NCSChart';
import { Trans, useTranslation } from 'react-i18next';

import { PlanSection, PlanSectionContent, PlanSectionHeader } from '..';

// Routes

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &.with-border-bottom {
    border-bottom: 1px solid ${theme.colors.blockBorder};
    margin-bottom: ${theme.spacing.x1};
  }
  &.with-border-right {
    border-right: 1px solid ${theme.colors.blockBorder};
    padding-right: ${theme.spacing.x1};
  }
  &.with-border-left {
    margin-left: ${theme.spacing.x1};
  }
  h4 {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
    min-height: 2.4rem;
    margin-bottom: ${theme.spacing.x1};
  }
`;

const ChartsWrapper = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.x1};
  > div {
    flex: 1;
  }
  @media ${theme.devices.tablet} {
    flex-direction: column;
  }
`;

const InsightsMask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const NoCheckinsBlock = styled.div`
  padding: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  border-radius: 8px;
  display: flex;
  background: ${theme.colors.N5};
  border: 1px solid ${theme.colors.N10};
  max-width: 50rem;
  align-items: center;

  > div {
    &:first-child {
      img {
        width: 4rem;
      }
      margin-right: ${theme.spacing.x3};
    }
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
  objectives: TabilityTypes.Objective[];
  outcomes: TabilityTypes.Outcome[];
  initiatives: TabilityTypes.Initiative[];
}

function Tracker(props: Props) {
  const { plan } = props;
  const [trends, setTrends] = useState([]);
  // const [checkinsTrends, setCheckinsTrends] = useState([]);
  const { t } = useTranslation();
  // Fetch trends data
  useQuery([queryKeys.currentPlan, plan.id, 'trends'], remoteApi.fetchPlanTrends, {
    staleTime: 0,
    onSuccess: (response) => {
      setTrends(response.data);
    },
  });

  // Fetch trends data
  // useQuery([queryKeys.currentPlan, plan.id, 'checkin_trends'], remoteApi.fetchPlanCheckinsTrends, {
  //   staleTime: 0,
  //   onSuccess: (response) => {
  //     setCheckinsTrends(response.data);
  //   },
  // });

  const planFinishAt = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  let differenceInDays = planFinishAt ? differenceInCalendarDays(planFinishAt, new Date()) : 0;
  if (differenceInDays < 0) {
    differenceInDays = 0;
  }

  return (
    <>
      <PlanSection className="plan-insights">
        {!plan.checked_in && (
          <InsightsMask>
            <NoCheckinsBlock>
              <div>
                <img
                  src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_surprised_tranev.png"
                  alt="Surprised Tabby"
                />
              </div>
              <div>
                <p>{t('shared.checkin.noCheckinsInPlan')}</p>
              </div>
            </NoCheckinsBlock>
          </InsightsMask>
        )}
        <PlanSectionHeader>
          <span className="title">{t('workspacePlan.planTrendsTitle')}</span>
        </PlanSectionHeader>
        <PlanSectionContent>
          <ChartsWrapper>
            <DataContainer className="with-border-bottom">
              <h4>{t('workspacePlan.insights.simplifiedProgress')}</h4>
              <ProgressChart trends={trends} plan={plan} />
            </DataContainer>
          </ChartsWrapper>
          {plan.checked_in && (
            <ChartsWrapper>
              <DataContainer className="with-border-right">
                <h4>{t('workspacePlan.insights.confidence')}</h4>
                <ConfidenceChart trends={trends} plan={plan} />
              </DataContainer>
              <DataContainer className="with-border-left">
                <h4>
                  {t('shared.ncs.full')}
                  <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs2" />
                </h4>
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  className="tooltip"
                  effect="solid"
                  id="explain-ncs2"
                  delayHide={500}
                  clickable={true}
                >
                  <Trans i18nKey="shared.ncs.tooltip" components={{ br: <br /> }} />{' '}
                  <a
                    href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('shared.learnMore')}
                  </a>
                </ReactTooltip>
                <NCSChart trends={trends} plan={plan} />
              </DataContainer>
            </ChartsWrapper>
          )}
        </PlanSectionContent>
      </PlanSection>
    </>
  );
}

export default React.memo(Tracker);
