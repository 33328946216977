import React, { Fragment, useState, useRef } from 'react';
import { jsPDF } from 'jspdf';
import queryKeys from 'config/queryKeys';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import * as remoteApi from 'api/remote';
import { Retrospective, RetrospectiveSection } from 'types';
import KoalaButton from 'koala/components/Button';
import KoalaTextBadge from 'koala/components/TextBadge';
import { AxiosResponse } from 'axios';
import KoalaLoader from 'koala/components/Loader';
import KoalaIconButton from 'koala/components/IconButton';
import * as routes from 'routes';
import RetrospectiveSections from './RetrospectiveSections';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'components/DropdownMenu';
import RetrospectiveComments from './RetrospectiveComments';
import { formatLocale } from 'utils/dateUtils';
import CommentPanel from './CommentPanel';
import RetrospectiveListing from './RetrospectiveListing';
import { generateSlug } from 'utils/naming';
import ShareButton from 'components/ShareButton';

const GridLayout = styled.div`
  position: relative;
  display: grid;
  grid-template-areas:
    'listing header'
    'listing content';
  height: 100%;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  width: 100%;
  min-height: 100%;
`;

const ListingContainer = styled.div`
  grid-area: listing;
  min-height: 100%;
  border-right: 1px solid ${theme.colors.blockBorder};
`;

const Header = styled.div`
  grid-area: header;
  background-color: ${theme.colors.N0};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing.x1};

  h4 {
    font-size: 16px;
    font-weight: 600;
  }
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};
  flex: 1;
`;
const RightHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing.x1};
  flex: 1;
`;
const ContentContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  grid-area: content;
  width: 100%;
`;
const PageContainer = styled.div`
  padding: ${theme.spacing.x3} ${theme.spacing.x10};
  max-width: 90rem;
  margin: ${theme.spacing.x1} auto;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};

  @media ${theme.devices.laptop} {
    width: 100%;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  h1 {
    font-size: 2.8rem;
    font-weight: 700;
  }
`;

const EmptyState = styled.div`
  background-color: ${theme.colors.N0};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

function WorkspaceRetrospective() {
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t, i18n } = useTranslation();
  const translationKey = 'workspaceRetrospective';
  const [showRetrospectiveCommentPanel, setShowRetrospectiveCommentPanel] = useState<RetrospectiveSection | null>(null);
  const [retrospective, setRetrospective] = useState<Retrospective | null>(null);
  const {
    workspaceSlug,
    planId,
    retrospectiveId: retrospectiveSlug,
  } = useParams<{
    workspaceSlug: string;
    planId: string;
    retrospectiveId: string;
  }>();

  const pdfRef = useRef(null);

  // Get the retrospective details
  const queryKey = [queryKeys.currentRetrospective, retrospectiveSlug];
  const staleTime = 0;
  const { isLoading } = useQuery(queryKey, remoteApi.fetchRetrospectiveDetails, {
    staleTime,
    onSuccess: (response: AxiosResponse<Retrospective>) => {
      const retrospective = response.data;
      setRetrospective(retrospective);
    },
  });

  const [updateRetrospectiveMutation, { isLoading: isUpdatingRetrospective }] = useMutation(
    remoteApi.updateRetrospective,
    {
      onSuccess: () => {
        queryCache.invalidateQueries([queryKeys.currentRetrospective, retrospectiveSlug]);
      },
    },
  );
  const [deleteRetrospectiveMutation, { isLoading: isDeleting }] = useMutation(remoteApi.deleteRetrospective, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.retrospectives, planId]);
      const path = routes.WORKSPACE_PLAN_RETROSPECTIVES_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
        ':planId',
        planId,
      );
      history.push(path);
    },
  });

  if (isLoading || isDeleting) {
    return (
      <EmptyState>
        <KoalaLoader size="xlarge" />
      </EmptyState>
    );
  }
  const plan = retrospective?.plan;

  const handleDownload = () => {
    const content = pdfRef.current;
    if (!content || !retrospective || !plan) {
      return null;
    }
    const doc = new jsPDF({
      format: 'a4',
      unit: 'px',
    });
    // Adding the fonts.
    doc.setFont('Inter-Regular', 'normal');
    const filename = `${generateSlug(plan.title)}-${generateSlug(retrospective.title)}.pdf`;
    doc.html(content, {
      margin: [40, 60, 40, 60],
      html2canvas: { scale: 0.4 },
      callback: function (doc) {
        doc.save(filename);
      },
    });
  };

  if (!retrospective || !plan) {
    return <EmptyState>{t(`${translationKey}.retrospectiveNotFound`)}</EmptyState>;
  }

  const handleEdit = () => {
    const path = routes.WORKSPACE_RETROSPECTIVE_EDIT_ROUTE.replace(':workspaceSlug', workspaceSlug)
      .replace(':planId', planId)
      .replace(':retrospectiveId', retrospectiveSlug);
    history.push(path);
  };

  const handlePublish = () => {
    const mutationParams = {
      retrospectiveId: retrospective.id,
      retrospective: { published: true, published_date: new Date() },
    };
    updateRetrospectiveMutation(mutationParams);
  };

  const handleUnpublish = () => {
    const mutationParams = {
      retrospectiveId: retrospective.id,
      retrospective: { published: false, published_date: null },
    };
    updateRetrospectiveMutation(mutationParams);
  };
  const handleShare = () => {
    dispatch(setGlobalModalContent(`retrospective:${retrospectiveSlug}:share`));
  };

  const handleDelete = () => {
    if (window.confirm('Delete this retrospective?')) {
      setRetrospective(null);
      deleteRetrospectiveMutation(retrospective.id);
    }
  };
  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'delete':
        handleDelete();
        break;
      case 'unpublish':
        handleUnpublish();
        break;
      case 'publish':
        handlePublish();
        break;
    }
  };
  const getRetrospectiveOptions = () => {
    const options = [<span data-action="delete">{t(`shared.delete`)}</span>];
    if (retrospective.published) {
      options.push(<span data-action="unpublish">{t(`shared.unpublish`)}</span>);
    } else {
      options.push(<span data-action="publish">{t(`shared.publish`)}</span>);
    }
    return options;
  };

  const retrospectiveLink = `https://${process.env.REACT_APP_DOMAIN}${routes.WORKSPACE_RETROSPECTIVE_ROUTE.replace(
    ':workspaceSlug',
    workspaceSlug,
  )
    .replace(':planId', plan.nano_slug)
    .replace(':retrospectiveId', retrospective.nano_slug)}`;

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {retrospective.title} | Tability
        </title>
      </Helmet>
      <GridLayout>
        <ListingContainer>
          <RetrospectiveListing plan={plan} />
        </ListingContainer>
        <ContentContainer>
          <CommentPanel
            showRetrospectiveCommentPanel={showRetrospectiveCommentPanel}
            setShowRetrospectiveCommentPanel={setShowRetrospectiveCommentPanel}
            retrospective={retrospective}
          />

          <PageContainer>
            <Header>
              <LeftHeader>
                {retrospective.published_date && (
                  <>
                    <KoalaTextBadge variant="neutral-light" edge="circle" isLowercase size="small">
                      {formatLocale(retrospective.published_date, 'dd MMM yyyy', i18n)}
                    </KoalaTextBadge>
                  </>
                )}
                {!retrospective.published && (
                  <KoalaTextBadge variant="yellow-light" isLowercase={true}>
                    {t('shared.status.draft')}
                  </KoalaTextBadge>
                )}
              </LeftHeader>
              <RightHeader>
                <KoalaButton appearance="secondary" onClick={handleEdit}>
                  {t('shared.edit')}
                </KoalaButton>
                {!retrospective.published && (
                  <KoalaButton onClick={handlePublish} loading={isUpdatingRetrospective}>
                    {t('workspaceRetrospective.publish')}
                  </KoalaButton>
                )}
                {retrospective.published && (
                  <>
                    <KoalaButton onClick={handleDownload} appearance="secondary">
                      {t('workspacePlans.options.downloadPDF')}
                    </KoalaButton>
                    <ShareButton shareCallback={handleShare} id={retrospective.id} link={retrospectiveLink} />
                  </>
                )}
                <DropdownMenu
                  trigger={<KoalaIconButton iconName="ellipsis" />}
                  onSelection={handleMenuSelection}
                  items={getRetrospectiveOptions()}
                />
              </RightHeader>
            </Header>
            <div ref={pdfRef}>
              <Title>
                <h1>{retrospective.title}</h1>
              </Title>

              <RetrospectiveSections
                retrospective={retrospective}
                setShowCommentPanel={setShowRetrospectiveCommentPanel}
              />
            </div>
            <RetrospectiveComments retrospective={retrospective} />
          </PageContainer>
        </ContentContainer>
      </GridLayout>
    </Fragment>
  );
}

export default WorkspaceRetrospective;
