import styled from 'styled-components';
import { colors, spacing } from '../../shared/styles';
import { Link } from 'react-router-dom';

const StyledButton = styled.button`
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  transition: all 0.1ms ease;
  white-space: nowrap;
  align-items: center;
  border: none;

  &:focus {
    border: 2px solid ${colors.B50};
    outline-offset: 2px;
  }

  &.btn--primary {
    background: ${colors.B50};
    color: ${colors.N0};
    border: none;
    text-decoration: none;
    &:hover {
      background: ${colors.B60};
    }
    &:active {
      background: ${colors.B80};
    }
  }
  &.btn--secondary {
    background: ${colors.N5};
    color: ${colors.N70};
    border: none;
    text-decoration: none;
    &:hover {
      background: ${colors.N20};
    }
    &:active {
      background: ${colors.N40};
    }
  }
  &.btn--subtle {
    background: transparent;
    color: ${colors.B50};
    border: 1px solid ${colors.B50};
    text-decoration: none;
    &:hover,
    &:active {
      background: rgba(50, 77, 211, 0.15);
    }
  }
  &.btn--disabled {
    cursor: not-allowed !important;
    background: ${colors.N10};
    color: ${colors.N50};
    border: none;
    opacity: 1;
  }
  &.btn--danger {
    background: ${colors.R50};
    color: ${colors.N0};
    &:hover {
      background: ${colors.R60};
    }
    &:active {
      background: ${colors.R80};
    }
  }
  &.btn--special {
    background: ${colors.V50};
    color: ${colors.N0};
    &:hover {
      background: ${colors.V60};
    }
    &:active {
      background: ${colors.V80};
    }
  }

  img {
    height: 2rem;

    &.margin-right {
      margin: 0 ${spacing.x1} 0 0;
    }
  }

  .btn-icon-prefix {
    margin-right: ${spacing.x1};
  }
  .btn-icon-suffix {
    margin-left: ${spacing.x1};
  }

  /* SIZES */
  &.btn--xsmall {
    padding: 0.4rem 0.8rem;
    height: 2rem;
    font-size: 1rem;
    font-weight: 500;
    border-radius: ${spacing.half};
  }
  &.btn--small {
    padding: 0.8rem 1.2rem;
    line-height: 1.6rem;
    height: 2.4rem;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: ${spacing.half};

    img,
    svg {
      height: 1.6rem;
    }
    .btn-icon-prefix {
      margin-right: ${spacing.half};
    }
  }
  &.btn--medium {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    padding: 0.8rem 1.6rem;
    height: 3.2rem;
  }

  &.btn--large {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1;
    padding: 0.8rem 1.6rem;
    height: 3.8rem;
  }
`;

export const StyledLinkButton = styled(StyledButton).attrs({ as: 'a' })`
  &.btn--primary:hover,
  &.btn--subtle:hover {
    text-decoration: none;
  }
`;

export const StyledLinkToButton = styled(StyledButton).attrs({ as: Link })`
  &.btn--primary:hover,
  &.btn--subtle:hover {
    text-decoration: none;
  }
`;

export default StyledButton;
