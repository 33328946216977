import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector, shallowEqual } from 'react-redux';
import queryKeys from 'config/queryKeys';
import * as planUtils from 'utils/planUtils';

// API
import * as remoteApi from 'api/remote';

interface Props {
  planId: string;
  setHasEditPermission: (permission: boolean) => void;
}

function PlanPermissionSync(props: Props) {
  const { planId, setHasEditPermission } = props;

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, planId, 'permission'];
  const staleTime = 90;

  // Get the plan details
  const { data: planResponse }: any = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
  });

  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const plan = planResponse ? planResponse.data : null;

  useEffect(() => {
    if (plan) {
      setHasEditPermission(planUtils.hasEditPermission(plan, currentMembership));
    }
  }, [plan, currentMembership, setHasEditPermission]);

  return null;
}

export default PlanPermissionSync;
