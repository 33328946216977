import React, { useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();

  // Query keys and query params
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:pagerduty:${outcome.data_source_type}`,
    'current',
  ];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null || currentValue === undefined) {
    return <FormField>{t(`panels.editOutcome.errorFetching`)}</FormField>;
  }
  const pagerDutyKey = `modals.dataConnectors.pagerDutyData`;

  const dataTypeToLabel: any = {
    incident_count: t(`${pagerDutyKey}.incident_count_label`),
    acknowledge_time: t(`${pagerDutyKey}.acknowledge_time_label`),
    resolve_time: t(`${pagerDutyKey}.resolve_time_label`),
    high_urgency_count: t(`${pagerDutyKey}.high_urgency_count_label`),
    resolved_count: t(`${pagerDutyKey}.resolved_count_label`),
    unacknowledged_count: t(`${pagerDutyKey}.unacknowledged_count_label`),
  };

  const label = dataTypeToLabel[outcome.data_source_type || ''];
  const labelWithNumber = label.replace('<number>', currentValue.toLocaleString());
  return (
    <FormField>
      <label>{t('modals.dataConnectors.preview')}</label>
      <p>{labelWithNumber}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  workspaceSlug: string;
}

function PagerDutySource(props: Props) {
  const { outcome } = props;
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');
  const { t } = useTranslation();

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }]: [any, any] = useMutation(
    remoteApi.updateOutcomeDataSource,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.currentOutcome);
      },
    },
  );

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'pagerduty',
      data_source_type: data_source_type,
      data_source_meta: {},
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const availableDataSourceTypes = [
    'incident_count',
    'acknowledge_time',
    'resolve_time',
    'high_urgency_count',
    'resolved_count',
    'unacknowledged_count',
  ];
  const connectorKey = `modals.dataConnectors`;
  const pagerDutyKey = `modals.dataConnectors.pagerDutyData`;
  return (
    <>
      <FormField>
        <label>{t(`${connectorKey}.changeMetric`)}</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">{t(`${connectorKey}.selectMetric`)}</option>
          <option value="incident_count">{t(`${pagerDutyKey}.incident_count`)}</option>
          <option value="acknowledge_time">{t(`${pagerDutyKey}.acknowledge_time`)}</option>
          <option value="resolve_time">{t(`${pagerDutyKey}.resolve_time`)}</option>
          <option value="high_urgency_count">{t(`${pagerDutyKey}.high_urgency_count`)}</option>
          <option value="resolved_count">{t(`${pagerDutyKey}.resolved_count`)}</option>
          <option value="unacknowledged_count">{t(`${pagerDutyKey}.unacknowledged_count`)}</option>
        </select>
      </FormField>
      {outcome.data_source_type !== data_source_type && (
        <FormField>
          <KoalaButton
            onClick={handleUpdate}
            loading={isUpdating}
            disabled={!data_source_type || isUpdating}
            appearance="subtle"
          >
            {t(`panels.editOutcome.savePreview`)}
          </KoalaButton>
        </FormField>
      )}
      {outcome.data_source_origin === 'pagerduty' &&
        outcome.data_source_type &&
        outcome.data_source_type === data_source_type && (
          <>{availableDataSourceTypes.includes(outcome.data_source_type) && <CurrentValue outcome={outcome} />}</>
        )}
    </>
  );
}

export default React.memo(PagerDutySource);
