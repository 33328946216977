import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import theme from 'theme';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';

import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import AccountSettingsNav from 'components/AccountSettingsNav';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  h2,
  h3 {
    margin: ${theme.spacing.x2} 0 ${theme.spacing.x1} 0;
  }
  p {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const BackupCodes = styled.div`
  h2,
  h3 {
    margin: ${theme.spacing.x2} 0 ${theme.spacing.x1} 0;
  }
  p,
  ul {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const ConfirmMessage = styled.div`
  padding: ${theme.spacing.x2};
  background: ${theme.colors.successBg};
  margin: ${theme.spacing.x2} 0;
`;

const ErrorMessage = styled.div`
  padding: ${theme.spacing.x2};
  background: ${theme.colors.dangerBg};
  margin: ${theme.spacing.x2} 0;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

function AccountPassword() {
  const { t } = useTranslation();
  const [current_password, setCurrentPassword] = useState('');
  const [current_password_codes, setCurrentPasswordCodes] = useState('');
  const [otp_validation, setOTPValidation] = useState('');
  const [backup_codes, setBackupCodes] = useState(null);

  const [showConfirm, setShowConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showBackupCodeError, setShowBackupCodeError] = useState(false);

  const [create2FABackupCodesMutation] = useMutation(remoteApi.create2FABackupCodes, {
    onMutate: () => {
      setShowBackupCodeError(false);
    },
    onSuccess: (response) => {
      setBackupCodes(response.data.backup_codes);
    },
    onError: (response) => {
      setShowBackupCodeError(true);
    },
  });

  const [update2FAMutation, { isLoading }] = useMutation(remoteApi.update2FA, {
    onMutate: () => {
      setShowConfirm(false);
      setShowError(false);
    },
    onSuccess: () => {
      setShowConfirm(true);
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    },
    onError: () => {
      setShowError(true);
    },
  });

  useQuery('create2FA', remoteApi.create2FA);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'current_password_codes':
        // @ts-ignore
        setCurrentPasswordCodes(value);
        break;
      case 'current_password':
        // @ts-ignore
        setCurrentPassword(value);
        break;
      case 'otp_validation':
        // @ts-ignore
        setOTPValidation(value);
        break;
    }
  };

  const handleCreate2FABackupCodes = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    const params = {
      current_password: current_password_codes,
    };
    create2FABackupCodesMutation({
      user: params,
    });
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    const params = {
      current_password,
      otp_validation,
      otp_required_for_login: false,
    };
    update2FAMutation({
      user: params,
    });
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t('account2FA.title')} | Tability</title>
      </Helmet>
      <ChromeSideNav>
        <AccountSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>
              {t('account2FA.disable.title')}{' '}
              <span role="img" aria-label="confirmed">
                &#x2714;
              </span>
            </h1>
          </ContentTitle>
          <Form onSubmit={handleCreate2FABackupCodes}>
            <p>{t('account2FA.disable.backupCodes1')}</p>
            <p>{t('account2FA.disable.backupCodes2')}</p>
            <p>
              <b>{t('account2FA.disable.backupCodes3')}</b>
            </p>
            <FormField>
              <label>{t('account2FA.passwordLabel')}</label>
              <input
                type="password"
                name="current_password_codes"
                className={`small`}
                autoComplete="off"
                placeholder={t('account2FA.passwordPlaceholder') ?? 'Your current password'}
                value={current_password_codes}
                required={true}
                onChange={handleChange}
              />
            </FormField>
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading}>
                {t('account2FA.disable.generateCodesButton')}
              </KoalaButton>
            </Actions>
          </Form>
          {showBackupCodeError && <ErrorMessage>{t('account2FA.disable.codeError')} </ErrorMessage>}
          {backup_codes && (
            <BackupCodes>
              <h2>{t('account2FA.disable.backupCodesTitle')}</h2>
              <p>{t('account2FA.disable.backupCodesInformation')}</p>
              <ul>
                {
                  // @ts-ignore
                  backup_codes.map((code, index) => {
                    return <li key={index}>{code}</li>;
                  })
                }
              </ul>
            </BackupCodes>
          )}
          <hr />
          <Form onSubmit={handleSubmit}>
            <h2>{t('account2FA.disable.disableTitle')}</h2>
            <p>{t('account2FA.disable.disableInformation1')}</p>
            <p>{t('account2FA.disable.disableInformation2')}</p>
            <FormField>
              <label>{t('account2FA.passwordLabel')}</label>
              <input
                type="password"
                name="current_password"
                className={`small`}
                autoComplete="off"
                placeholder={t('account2FA.passwordPlaceholder') ?? 'Your current password'}
                value={current_password}
                required={true}
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <label>{t('account2FA.validationLabel')}</label>
              <input
                type="text"
                name="otp_validation"
                className="tiny"
                autoComplete="off"
                placeholder={t('account2FA.validationPlaceholder') ?? 'OTP Code'}
                value={otp_validation}
                required={true}
                onChange={handleChange}
              />
            </FormField>
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading} appearance="subtle">
                {t('account2FA.disable.disable2FAButton')}
              </KoalaButton>
            </Actions>
          </Form>
          {showConfirm && <ConfirmMessage>{t('account2FA.disable.confirmMessage')}</ConfirmMessage>}
          {showError && <ErrorMessage>{t('account2FA.disable.error')}</ErrorMessage>}
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default AccountPassword;
