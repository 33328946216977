import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import parse from 'parse-link-header';

import KoalaIconButton from 'koala/components/IconButton';
import { Retrospective, RetrospectiveComment, RetrospectiveSection } from 'types';
import KoalaAvatar from 'koala/components/Avatar';
import { shallowEqual, useSelector } from 'react-redux';
import MentionBox from 'components/MentionBox';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import RetrospectiveCommentBlock from './RetrospectiveComment';
import { AxiosResponse } from 'axios';
import { useInfiniteQuery, useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useTranslation } from 'react-i18next';

const Mask = styled.div<{ showPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${(props) => (props.showPanel ? '50' : '-1')};
  opacity: ${(props) => (props.showPanel ? '1' : '0')};
  transition: 0.2s ease-out;
`;
const NewComment = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
  display: grid;
  grid-template-areas: 'avatar input';
  grid-template-columns: auto 1fr;
  gap: ${theme.spacing.x1};
`;
const ContentWrapper = styled.div<{ showPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40rem;
  z-index: 60;
  transform: translateX(${(props) => (props.showPanel ? '0%' : '100%')});
  transition: 0.2s ease-out;
  box-shadow: 0 0 5px rgba(15, 15, 15, 0.2);

  @media ${theme.devices.mobile} {
    max-width: 40rem;
    width: 100%;
  }
`;
const InputActions = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  margin-top: ${theme.spacing.x1};
`;
const ReplyPlaceholder = styled.input`
  background: #fff;
  border: 1px solid ${theme.colors.blockBorder};
`;
const InputBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const AvatarBox = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;

  background: #fff;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #151515;
  font-weight: 700;
  font-size: 2rem;
  margin: 1.2rem ${theme.spacing.x2};
`;

function getNextPage(response: any) {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
}

interface Props {
  showRetrospectiveCommentPanel: RetrospectiveSection | null;
  setShowRetrospectiveCommentPanel: (value: RetrospectiveSection | null) => void;
  retrospective: Retrospective;
}

function CommentPanel(props: Props) {
  const { showRetrospectiveCommentPanel, setShowRetrospectiveCommentPanel, retrospective } = props;
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const [maskEditor, setMaskEditor] = useState(true);
  const [body, setBody] = useState('');
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'workspaceRetrospective';

  const [commentGroups, setCommentGroups] = useState<AxiosResponse<RetrospectiveComment[]>[]>([]);

  // create comment mutations
  const retrospective_section_id = showRetrospectiveCommentPanel ? showRetrospectiveCommentPanel.id : null;
  const queryKey = [queryKeys.retrospectiveComments, retrospective.id, { retrospective_section_id }];
  const [createCommentMutation, { isLoading: isCreating }] = useMutation(remoteApi.createRetrospectiveComment, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
      queryCache.invalidateQueries([queryKeys.retrospectiveSections, retrospective.id]);
      setBody('');
      setMaskEditor(true);
    },
  });

  // Get the retrospective comments
  const staleTime = 0;
  const {
    isLoading: isCommentsLoading,
    fetchMore,
    canFetchMore,
    isFetchingMore,
  } = useInfiniteQuery(queryKey, remoteApi.fetchRetrospectiveComments, {
    staleTime,
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    onSuccess: (response: AxiosResponse<RetrospectiveComment[]>[]) => {
      setCommentGroups(response);
    },
  });
  const handleClosePanel = () => {
    setShowRetrospectiveCommentPanel(null);
  };

  const handleSubmit = () => {
    const retrospective_comment = {
      retrospective_section_id,
      body,
    };
    createCommentMutation({
      retrospectiveId: retrospective.id,
      retrospective_comment,
    });
  };

  if (isCommentsLoading) {
    return <KoalaLoader />;
  }

  const showPanel = !!showRetrospectiveCommentPanel;

  return (
    <Fragment>
      <Mask onClick={handleClosePanel} showPanel={showPanel} />
      <ContentWrapper showPanel={showPanel}>
        <Content>
          <SearchHeader>
            <p>{t(`${translationKey}.commentHeader`)}</p>
            <KoalaIconButton onClick={handleClosePanel} iconName="close" />
          </SearchHeader>
          <Container>
            <NewComment>
              <AvatarBox>
                <KoalaAvatar user={currentUser} />
              </AvatarBox>
              <InputBox>
                {maskEditor && (
                  <ReplyPlaceholder
                    type="text"
                    placeholder={t(`${translationKey}.addComment`) ?? 'Add a comment...'}
                    onFocus={() => {
                      setMaskEditor(false);
                    }}
                  />
                )}
                {!maskEditor && (
                  <>
                    <MentionBox
                      setValue={setBody}
                      value={body}
                      placeholder={t(`${translationKey}.addComment`) ?? 'Add a comment...'}
                      comment={true}
                      cmdEnterCallback={handleSubmit}
                    />
                    <InputActions>
                      <KoalaButton onClick={handleSubmit} loading={isCreating}>
                        {t('shared.save')}
                      </KoalaButton>
                      <KoalaTextButton onClick={() => setMaskEditor(true)}>{t('shared.cancel')}</KoalaTextButton>
                    </InputActions>
                  </>
                )}
              </InputBox>
            </NewComment>
            {commentGroups.map((group, i) => (
              <Fragment key={i}>
                {group.data.map((comment) => (
                  <RetrospectiveCommentBlock retrospectiveComment={comment} key={comment.id} />
                ))}
              </Fragment>
            ))}
            {canFetchMore && (
              <LoadMore>
                <KoalaButton onClick={() => fetchMore()} appearance="secondary" loading={!!isFetchingMore}>
                  {t('shared.loadMore')}
                </KoalaButton>
              </LoadMore>
            )}
          </Container>
        </Content>
      </ContentWrapper>
    </Fragment>
  );
}

export default CommentPanel;
