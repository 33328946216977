import React from 'react';
import styled from 'styled-components';

import { KoalaSizes } from 'koala/shared/global';
import { colors } from 'koala/shared/styles';
import { User } from 'types';
import KoalaAvatar from '../Avatar';

interface Props {
  owner: User | null;
  contributors?: any[];
  direction?: 'left' | 'right';
  size?: (typeof KoalaSizes)[number] | number;
  hideOwner?: boolean;
  showNumber?: boolean;
  gap?: string;
  hideTooltip?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const StackedContainer = styled.div<{ gap: string }>`
  display: flex;
  justify-content: flex-end;
  padding-left: ${(props) => props.gap};

  .avatar {
    margin-left: -${(props) => props.gap};
  }
  .avatar-icon {
    position: relative;
    z-index: 0;
  }

  &.stacked-avatars--right {
    flex-direction: row-reverse;
  }
`;

const AdditionalAvatars = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${colors.N60};
  &.stacked-avatar-additional--small {
    font-size: 11px;
  }
  &.stacked-avatar-additional--medium {
    font-size: 12px;
  }
  &.stacked-avatar-additional--large {
    font-size: 16px;
  }
`;

const KoalaStackedAvatar: React.FunctionComponent<Props> = (props: Props) => {
  const { owner, contributors, gap, showNumber, direction, hideTooltip, size, className, style, hideOwner } = props;

  const maxAvatars = 2;
  const stackedClass = `stacked-avatars--${direction ?? 'right'} ${className ?? ''}`;

  const notShown = contributors ? contributors.length - maxAvatars : null;
  return (
    <StackedContainer style={style} className={stackedClass} gap={gap ?? '8px'}>
      {showNumber && notShown !== null && notShown > 0 && (
        <AdditionalAvatars
          className={`stacked-avatar-additional stacked-avatar-additional--${
            size && typeof size === 'string' ? size : 'medium'
          }`}
        >
          +{notShown}
        </AdditionalAvatars>
      )}
      {contributors &&
        contributors.map((contributor, index) => {
          if (index >= maxAvatars) {
            return null;
          }
          return (
            <KoalaAvatar
              key={index}
              user={contributor.membership.user}
              addBorder={true}
              size={size}
              hideTooltip={hideTooltip}
            />
          );
        })}
      {!hideOwner && <KoalaAvatar user={owner} addBorder={true} size={size} hideTooltip={hideTooltip} />}
    </StackedContainer>
  );
};

KoalaStackedAvatar.defaultProps = {
  direction: 'right',
  gap: '8px',
  size: 'medium',
  hideTooltip: false,
  showNumber: false,
};

export default KoalaStackedAvatar;
