import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';

// API
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import { fetchInitiatives } from 'api/remote';

import InitiativeRow from 'components/InitiativeRow';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const BlockInitiative = styled.div`
  grid-area: initiative;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  max-height: 50rem;
  overflow: auto;
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  hideClosedInitiatives: boolean;
}

function OutcomeBlock(props: Props) {
  const { outcome, hideClosedInitiatives } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const staleTime = 60 * 1000 * 5;
  const queryKeyInitiatives = [queryKeys.initiatives, outcome.id];
  const { data: initiativesResponse }: any = useQuery(queryKeyInitiatives, fetchInitiatives, {
    staleTime,
  });
  let initiatives: TabilityTypes.Initiative[] = initiativesResponse ? initiativesResponse.data : [];
  if (hideClosedInitiatives) {
    initiatives = initiatives.filter((initiative) => initiative.state !== 'closed');
  }

  if (initiatives.length === 0) {
    return (
      <BlockInitiative>
        <p className="subtle">
          {t('panels.noInitiatives', {
            outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase(),
            initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
          })}
        </p>
      </BlockInitiative>
    );
  }
  return (
    <BlockInitiative className="nowheel">
      {initiatives.map((initiative) => {
        return (
          <InitiativeRow
            showHeirarchy={false}
            initiative={initiative}
            hideOwners={true}
            hideDueDate={true}
            key={initiative.id}
            showMeta={true}
            compact={true}
            allowLink={true}
            handleClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
          />
        );
      })}
    </BlockInitiative>
  );
}

export default React.memo(OutcomeBlock);
