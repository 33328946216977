import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { sessionActions } from 'state/actions/';

function RedirectToLoginIfNotAuthed() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: any) => state.session.isAuthenticated);

  // Try to refresh the auth on mount
  useEffect(() => {
    dispatch(sessionActions.refreshTokens());
  }, [dispatch]);

  // This function will refresh the auth silently every other minute. It is only triggered after the user is authed
  useEffect(() => {
    let refreshTokenInterval: any;

    const stopInterval = () => {
      if (refreshTokenInterval) {
        clearInterval(refreshTokenInterval);
      }
    };

    // Make sure that the user is authenticated before we set the refresh interval
    if (isAuthenticated) {
      // Refresh the token every 90s
      refreshTokenInterval = setInterval(() => {
        dispatch(sessionActions.refreshTokens());
      }, 90 * 1000);
    } else {
      // If we're not authenticated, make sure we have cleared the loop
      stopInterval();
    }
    return () => {
      // Clear the loop on unmount
      stopInterval();
    };
  }, [dispatch, isAuthenticated]);

  return null;
}

export default RedirectToLoginIfNotAuthed;
