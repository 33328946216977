import React from 'react';

const LeftChevronIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00001 9.93158C6.00141 9.66636 6.10811 9.41255 6.29664 9.22601L12.2966 3.28916C12.6892 2.90071 13.3224 2.90406 13.7108 3.29664C14.0993 3.68923 14.0959 4.32239 13.7034 4.71084L8.41423 9.94429L13.7108 15.2966C14.0993 15.6892 14.096 16.3223 13.7034 16.7108C13.3108 17.0993 12.6777 17.096 12.2892 16.7034L6.2892 10.6402C6.10264 10.4517 5.99862 10.1968 6.00001 9.93158Z"
      fill="#737373"
    />
  </svg>
);

export default LeftChevronIcon;
