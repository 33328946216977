import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
//import * as TabilityTypes from 'types';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory, useLocation } from 'react-router-dom';

import BlockLabel from '../BlockLabel';
import ChevronRightIcon from 'components/_assets/ChevronRightIcon';
import CommentBlock from './comments/CommentBlock';
import NewCommentBlock from './comments/NewCommentBlock';
import InitiativeLabel from '../InitiativeLabel';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';

const Container = styled.div``;

const Header = styled.div`
  margin-bottom: 5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BlockPreview = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.x3};

  background: ${theme.colors.warningBg};
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;
const Title = styled.div`
  margin-left: ${theme.spacing.x2};
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;

  &.objective {
    font-weight: 600;
  }

  &.initiative {
    color: ${theme.colors.subtleText};
  }
`;

const ResolvedCommentsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x2} 0;
  margin-top: ${theme.spacing.x2};
`;

const ExpandCommentsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 8px;
  background: #fff;
  margin-right: 0.4rem;

  &:hover {
    cursor: pointer;
    background: #f3f3f3;
  }

  svg {
    height: 1rem;
  }

  transition: all ease 0.2s;
  transform: rotate(0deg);
  &.expanded {
    transform: rotate(90deg);
  }

  span {
    font-size: 1.8rem;
    color: ${theme.colors.subtleText};
  }
`;

interface Props {
  entityId: string;
  entityType: string;
}

function BlockComment(props: Props) {
  const history = useHistory();
  const location = useLocation();

  const { entityId, entityType } = props;
  const { t } = useTranslation();
  const translationKey = 'workspacePlan.write';
  const blockId = `${entityType}:${entityId}`;
  const [showResolvedComments, setShowResolvedComments] = useState(false);

  // Get the data needed to build the component
  let label;
  const entity: any = useSelector((state: any) => {
    switch (entityType) {
      case 'objective':
        label = 'objective';
        return state.editorEntities.objectives[entityId];
      case 'outcome':
        label = '#';
        return state.editorEntities.outcomes[entityId];
      case 'initiative':
        label = String.fromCharCode(0x2713);
        return state.editorEntities.initiatives[entityId];
    }
  });

  const plan: any = useSelector((state: any) => {
    const planId = state.editorUI.currentPlanId;
    if (planId) {
      return state.editorEntities.plans[planId];
    }
    return null;
  });

  // Extract the review comments
  const reviewComments = useSelector((state: any) => {
    if (state.editorEntities.reviewCommentsByBlockId[blockId]) {
      return state.editorEntities.reviewCommentsByBlockId[blockId] || [];
    }
    return [];
  });

  // Extract the resolved review comments
  const resolvedComments = useSelector((state: any) => {
    if (state.editorEntities.resolvedCommentsByBlockId[blockId]) {
      return state.editorEntities.resolvedCommentsByBlockId[blockId] || [];
    }
    return [];
  });

  if (!entity) {
    return <div>{t(`${translationKey}.commentUnavailable`)}</div>;
  }

  const closePanel = () => {
    history.push(location.pathname);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    closePanel();
  };

  const toggleExpandComments = (e: any) => {
    setShowResolvedComments(!showResolvedComments);
  };

  // We'll use this flag to decide if we display the review comments or a new form
  const hasReviewComments = reviewComments.length > 0;
  const hasResolvedComments = resolvedComments.length > 0;

  const expandState = showResolvedComments ? 'expanded' : '';

  return (
    <Container>
      <Header>
        <h3>{t(`${translationKey}.reviewComments`)}</h3>
        <KoalaIconButton onClick={handleCancel} iconName="rightArrow" />
      </Header>
      <BlockPreview>
        {entityType !== 'initiative' && <BlockLabel className={entityType}>{label}</BlockLabel>}
        {entityType === 'initiative' && <InitiativeLabel className={entityType}>{label}</InitiativeLabel>}
        <Title className={entityType}>{entity.title}</Title>
      </BlockPreview>
      {hasReviewComments && (
        <Fragment>
          {reviewComments.map((reviewComment: any) => {
            return <CommentBlock key={reviewComment.id} reviewComment={reviewComment} />;
          })}
        </Fragment>
      )}
      {!hasReviewComments && <NewCommentBlock blockId={blockId} plan={plan} />}

      {hasResolvedComments && (
        <>
          <ResolvedCommentsHeader>
            <ExpandCommentsButton className={expandState} onClick={toggleExpandComments}>
              <ChevronRightIcon />
            </ExpandCommentsButton>
            <span>{t(`${translationKey}.resolved`, { count: resolvedComments.length })}</span>
          </ResolvedCommentsHeader>
        </>
      )}
      {showResolvedComments && (
        <>
          {hasResolvedComments && (
            <Fragment>
              {resolvedComments.map((resolvedComment: any) => {
                return <CommentBlock key={resolvedComment.id} reviewComment={resolvedComment} />;
              })}
            </Fragment>
          )}
        </>
      )}
    </Container>
  );
}

export default React.memo(BlockComment);
