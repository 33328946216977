import queryKeys from 'config/queryKeys';
import React, { Fragment, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import parse from 'parse-link-header';
import { Team, Workspace } from 'types';

import * as remoteApi from 'api/remote';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import theme from 'theme';
import EmptyStatePanel from 'components/EmptyStatePanel';
import { toInteger } from 'lodash';
import Loader from 'components/Loader';
import KoalaButton from 'koala/components/Button';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useTranslation } from 'react-i18next';
import TeamCard from './TeamCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const EmptyState = styled(EmptyStatePanel)`
  margin: 0;
  padding: ${theme.spacing.x2};
`;

const EmptyText = styled.div`
  margin-bottom: ${theme.spacing.x2};
`;
const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
`;

interface Props {
  workspace: Workspace;
}

function TeamsList(props: Props) {
  const { workspace } = props;
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  const [userTeams, setUserTeams] = useState<AxiosResponse[]>([]);
  const [userTeamCount, setUserTeamCount] = useState<number | null>(null);
  const [otherTeams, setOtherTeams] = useState<AxiosResponse[]>([]);
  const [otherTeamCount, setOtherTeamCount] = useState<number | null>(null);
  const dispatch = useDispatch();

  // Search query attributes

  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  // fetch users teams
  const searchTeamsQueryKey = [
    queryKeys.teams,
    workspace.id,
    {
      workspaceSlug: workspace.slug,
      limit: 25,
      filter: { team_member: currentMembership.id },
    },
  ];
  const {
    isLoading: isLoadingUser,
    canFetchMore: canFetchMoreUser,
    fetchMore: fetchMoreUser,
    isFetchingMore: isFetchingMoreUser,
  } = useInfiniteQuery(searchTeamsQueryKey, remoteApi.searchTeams, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    onSuccess: (response) => {
      const resultsCount = response[0].headers['x-total'];
      setUserTeamCount(toInteger(resultsCount));
      setUserTeams(response);
    },
  });

  // fetch other teams
  const searchOtherTeamsQueryKey = [
    queryKeys.teams,
    workspace.id,
    {
      workspaceSlug: workspace.slug,
      limit: 25,
      filter: { not_team_member: currentMembership.id },
    },
  ];
  const {
    isLoading: isLoadingOther,
    canFetchMore: canFetchMoreOther,
    fetchMore: fetchMoreOther,
    isFetchingMore: isFetchingMoreOther,
  } = useInfiniteQuery(searchOtherTeamsQueryKey, remoteApi.searchTeams, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    onSuccess: (response) => {
      const resultsCount = response[0].headers['x-total'];
      setOtherTeamCount(toInteger(resultsCount));
      setOtherTeams(response);
    },
  });

  const handleCreateTeam = (e: any) => {
    e.preventDefault();
    const action = `workspace:${workspace.slug}:create.team`;
    dispatch(setGlobalModalContent(action));
  };

  // if (true) {
  if (userTeamCount === 0 && otherTeamCount === 0) {
    return (
      <EmptyStatePanel>
        <EmptyText>{t('workspaceTeams.noTeams')}</EmptyText>
        <KoalaButton onClick={handleCreateTeam}>{t('workspaceTeams.createFirst')}</KoalaButton>
      </EmptyStatePanel>
    );
  }

  return (
    <Container>
      <h3>{t('workspaceTeams.myTeams')}</h3>
      {isLoadingUser && <Loader />}

      {!isLoadingUser && (
        <TeamContainer>
          {userTeams.map((group, i: number) => {
            return (
              <Fragment key={i}>
                {group.data.map((team: Team) => (
                  <TeamCard team={team} workspace={workspace} key={team.id} />
                ))}
              </Fragment>
            );
          })}
          {canFetchMoreUser && (
            <KoalaButton appearance="secondary" onClick={() => fetchMoreUser()} loading={!!isFetchingMoreUser}>
              {t('shared.loadMore')}
            </KoalaButton>
          )}
          {userTeamCount === 0 && <EmptyState>{t('workspaceTeams.noUserTeam')}</EmptyState>}
        </TeamContainer>
      )}

      <h3>{t('workspaceTeams.allTeams')}</h3>
      {isLoadingOther && <Loader />}
      {!isLoadingOther && (
        <TeamContainer>
          {otherTeams.map((group, i: number) => {
            return (
              <Fragment key={i}>
                {group.data.map((team: Team) => (
                  <TeamCard team={team} workspace={workspace} key={team.id} />
                ))}
              </Fragment>
            );
          })}
          {canFetchMoreOther && (
            <KoalaButton appearance="secondary" onClick={() => fetchMoreOther()} loading={!!isFetchingMoreOther}>
              {t('shared.loadMore')}
            </KoalaButton>
          )}
          {otherTeamCount === 0 && <EmptyState>{t('workspaceTeams.noOtherTeams')}</EmptyState>}
        </TeamContainer>
      )}
    </Container>
  );
}

export default TeamsList;
