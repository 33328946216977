import queryKeys from 'config/queryKeys';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import React from 'react';
import { useQuery } from 'react-query';
import { Objective, Workspace } from 'types';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import KoalaIcon from 'koala/components/Icons';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import styled from 'styled-components';
import theme from 'theme';

const Row = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;

interface Props {
  planId: string;
  selectedObjective: KoalaSelectOption | null;
  setSelectedObjective: (value: any) => void;
  workspace: Workspace;
}

function ObjectiveSelection(props: Props) {
  const { planId, selectedObjective, setSelectedObjective, workspace } = props;
  const { t } = useTranslation(undefined, { useSuspense: false });

  // Get the objectives
  const staleTime = 0;
  const { data: objectivesResponse, isLoading: objectivesAreLoading } = useQuery(
    [queryKeys.objectives, planId],
    remoteApi.fetchPlanObjectives,
    {
      staleTime,
    },
  );
  const filteredObjectives: Objective[] = objectivesResponse ? objectivesResponse.data : [];
  let objectives: KoalaSelectOption[] = filteredObjectives.map((objective: Objective) => {
    return { label: objective.title, value: objective.id, meta: objective };
  });

  if (objectivesAreLoading) {
    return <KoalaLoader />;
  }
  const label = translate(workspace, CustomTermKey.OBJECTIVE, 1).toLowerCase();

  return (
    <KoalaSelect
      isClearable={true}
      handleChange={setSelectedObjective}
      placeholder={t(`modals.addDependency.selectOutcome`, { label })}
      selectedOption={selectedObjective}
      options={objectives}
      labelFormat={(option) => {
        return (
          <Row>
            <KoalaIcon iconName="flag" iconAppearance={theme.colors.T80} />
            {option.label}
          </Row>
        );
      }}
    />
  );
}

export default ObjectiveSelection;
