import createReducer from './createReducer';
import * as actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  error: null,
  hasErrors: false,
  isRequesting: false,
  reconfirmErrors: [],
  hasReconfirmErrors: false,
  isResendingConfirm: false,
  hasSentNewConfirm: false,
};

const reducer = createReducer(INITIAL_STATE)({
  [actionTypes.RESET_PAGE_STATE]: (state: any, action: any) => {
    if (action.page === 'Login') {
      return INITIAL_STATE;
    } else {
      return state;
    }
  },
  // When we load the initial plan we set the current plan ID for reference.
  [actionTypes.LOGIN_FAILED]: (state: any, action: any) => {
    const error = action.error ? action.error.response.data.error : null;
    return {
      ...state,
      error,
      hasErrors: true,
      isRequesting: false,
    };
  },
  [actionTypes.LOGIN_REQUESTED]: (state: any) => ({
    ...state,
    isRequesting: true,
  }),
  [actionTypes.LOGIN_COMPLETED]: (state: any) => ({
    ...state,
    isRequesting: false,
  }),
  [actionTypes.RESEND_CONFIRM_FAILED]: (state: any, action: any) => {
    const reconfirmErrors = action.error ? action.error.response.data.errors : [];
    return {
      ...state,
      reconfirmErrors,
      hasReconfirmErrors: true,
      isResendingConfirm: false,
      hasSentNewConfirm: false,
    };
  },
  [actionTypes.RESEND_CONFIRM_REQUESTED]: (state: any) => ({
    ...state,
    isResendingConfirm: true,
    hasReconfirmErrors: false,
    reconfirmErrors: [],
    hasSentNewConfirm: false,
  }),
  [actionTypes.RESEND_CONFIRM_COMPLETED]: (state: any) => ({
    ...state,
    isResendingConfirm: false,
    hasSentNewConfirm: true,
    hasErrors: false,
  }),
});

export default reducer;
