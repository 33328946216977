import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MarkdownContent from 'components/MarkdownContent';
import { Workspace } from 'types';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { dataSourceOriginToLabel } from 'utils/dataSourceUtils';
import { BlurDiv, ContentComment } from '.';
interface Props {
  event: any;
  canViewAudit: boolean;
}

function ObjectiveCreated(props: Props) {
  const { event, canViewAudit } = props;
  const { t } = useTranslation();

  const {
    membership_cached_fullname,
    membership_cached_email,
    source_cached_title,
    membership_id,
    event_meta,
    event_type,
  } = event;
  let name = membership_cached_fullname || membership_cached_email;
  if (!membership_id) {
    name += ` ${t('auditEvents.deleted')}`;
  }

  const meta = JSON.parse(event_meta);

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();

  return (
    <>
      <span>
        <Trans
          i18nKey="auditEvents.outcomeUpdated"
          values={{ name, title: source_cached_title, outcome: outcomeLabel }}
          components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
        />
      </span>
      <BlurDiv isBlurred={!canViewAudit}>
        {meta['title'] && (
          <li>
            <Trans
              i18nKey="auditEvents.titleUpdated"
              values={{ previous: meta['title'][0], new: meta['title'][1] }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['owner'] && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomeOwnerUpdated"
              values={{ previous: meta['owner'][0], new: meta['owner'][1] }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['plan'] && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomePlanUpdated"
              values={{ previous: meta['plan'][0], new: meta['plan'][1] }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['objective'] && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomeObjectiveUpdated"
              values={{ previous: meta['objective'][0], new: meta['objective'][1] }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['to'] && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomeToUpdated"
              values={{ previous: meta['to'][0], new: meta['to'][1] }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['from'] && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomeFromUpdated"
              values={{ previous: meta['from'][0], new: meta['from'][1] }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['score_format'] && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomeScoreFormatUpdated"
              values={{ previous: meta['score_format'][0], new: meta['score_format'][1] }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['outcome_type'] && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomeOutcomeTypeUpdated"
              values={{ previous: meta['outcome_type'][0], new: meta['outcome_type'][1] }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['weight'] && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomeWeightUpdated"
              values={{ previous: meta['weight'][0], new: meta['weight'][1] }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['archived'] && meta['archived'][1] && (
          <li>
            <Trans i18nKey="auditEvents.outcomeArchived" components={{ b: <b /> }} />
          </li>
        )}
        {meta['archived'] && !meta['archived'][1] && (
          <li>
            <Trans i18nKey="auditEvents.outcomeUnarchived" components={{ b: <b /> }} />
          </li>
        )}
        {meta['description'] && (
          <li>
            <Trans i18nKey="auditEvents.outcomeDescriptionUpdated" />
            <ContentComment>
              <MarkdownContent source={meta['description'][1]} />
            </ContentComment>
          </li>
        )}
        {event_type === 'outcome.contributor_deleted' && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomeContributorDeleted"
              values={{
                user:
                  meta['cached_firstname'] && meta['cached_lastname']
                    ? `${meta['cached_firstname']} ${meta['cached_lastname']}`
                    : meta['cached_email'],
              }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {event_type === 'outcome.contributor_created' && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomeContributorCreated"
              values={{
                user:
                  meta['cached_firstname'] && meta['cached_lastname']
                    ? `${meta['cached_firstname']} ${meta['cached_lastname']}`
                    : meta['cached_email'],
              }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['data_source_origin'] && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomeDataSourceOriginUpdated"
              values={{
                previous: dataSourceOriginToLabel(meta['data_source_origin'][0], t) ?? '-',
                new: dataSourceOriginToLabel(meta['data_source_origin'][1], t) ?? '-',
              }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['data_source_type'] && (
          <li>
            <Trans
              i18nKey="auditEvents.outcomeDataSourceTypeUpdated"
              values={{
                previous: meta['data_source_type'][0] ?? '-',
                new: meta['data_source_type'][1] ?? '-',
              }}
              components={{ b: <b /> }}
            />
          </li>
        )}

        {meta['remote_checkins_enabled'] && (
          <li>
            <Trans
              i18nKey={
                meta['remote_checkins_enabled'][1]
                  ? 'auditEvents.outcomeRemoteCheckinEnabled'
                  : 'auditEvents.outcomeRemoteCheckinDisabled'
              }
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['data_source_sync_enabled'] && (
          <li>
            <Trans
              i18nKey={
                meta['data_source_sync_enabled'][1]
                  ? 'auditEvents.outcomeDataSourceSyncEnabled'
                  : 'auditEvents.outcomeDataSourceSyncDisabled'
              }
              components={{ b: <b /> }}
            />
          </li>
        )}
      </BlurDiv>
    </>
  );
}

export default React.memo(ObjectiveCreated);
