import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

import { midString } from 'state/reducers/utils';

// API
import * as remoteApi from 'api/remote';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHotkeys } from 'react-hotkeys-hook';

const BlockIcon = styled.div`
  grid-area: icon;
  display: flex;
  height: 3.2rem;
  align-items: center;
`;

const BlockContent = styled.div`
  grid-area: content;
  display: flex;
  align-items: flex-start;
  &.closed {
    text-decoration: strike-through;
  }

  .hide {
    display: none;
  }
`;

const TitleInput = styled.input`
  border: none;
  width: 100%;
  outline: none;
  box-shadow: none;
  resize: none;
  padding: 0;
  margin: 0;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.4rem;
  line-height: 1.4;
  background: transparent;

  &:focus {
    outline: 0;
  }
`;

const BlockActions = styled.div`
  grid-area: actions;
  display: flex;
  align-items: center;

  button {
    margin-left: ${theme.spacing.x1};
  }
`;

const Wrapper = styled.div`
  &.new-initiative-actions {
    display: flex;
    gap: ${theme.spacing.x1};

    .ai-button {
      background: ${theme.colors.V5};
      color: ${theme.colors.V70};
      display: flex;
      gap: ${theme.spacing.half};
      align-items: center;
      &:hover {
        background: ${theme.colors.V10};
      }
    }
  }
`;

const BlockGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  grid-template-areas: 'icon content actions';
  align-items: center;
  column-gap: ${theme.spacing.x1};
  min-height: 3.2rem;
  padding: 0 0 ${theme.spacing.x1} 0;

  &.closed {
    opacity: 0.5;
  }

  &:hover,
  &.selected,
  &:focus {
    opacity: 1;
    .block-actions {
      opacity: 1;
    }
  }

  .block-actions {
    opacity: 0;
  }
`;
const CheckBox = styled.div`
  line-height: 1;
  box-sizing: border-box;
  [type='checkbox'],
  [type='checkbox'] {
    position: absolute;
    visibility: hidden;
    display: none;
  }
  [type='checkbox'] + label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    border: 2px solid #bdbdbd;
    background: #fff;
    color: #a9a9a9;
    font-weight: 600;
    font-size: 1.4rem;
    border-radius: 50%;
    transition: all ease 0.2s;
  }

  [type='checkbox']:not(:checked) + label {
    color: #fff;
  }
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  lastInitiative: TabilityTypes.Initiative;
}

function NewInitiativeForm(props: Props) {
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const inputEl = useRef<HTMLInputElement>(null);
  const { outcome, lastInitiative } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const [title, setTitle] = useState('');

  const [displayForm, setDisplayForm] = useState(false);
  useHotkeys(
    'I',
    () => {
      showForm();
    },
    { scopes: ['panels'], keyup: true },
  );

  useEffect(() => {
    if (inputEl && inputEl.current) {
      const textarea = inputEl.current;

      const value = textarea.value;
      textarea.value = '';
      textarea.value = value;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }, [inputEl]);

  // Initiative mutations
  const [createInitiativeMutation, { isLoading: isCreating }] = useMutation(remoteApi.createInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      setTitle('');
      inputEl.current?.scrollIntoView({ block: 'start', inline: 'nearest' });
      inputEl.current?.focus();
    },
  });

  const showForm = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setDisplayForm(true);
    if (inputEl.current) {
      inputEl.current.scrollIntoView();
      inputEl.current.focus();
    }
  };

  const hideForm = (e: any) => {
    e.preventDefault();
    setDisplayForm(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const lastRank = lastInitiative ? lastInitiative.rank : '';

    const rank = midString(lastRank, '');

    const params = {
      title,
      state: 'open',
      rank,
    };

    createInitiativeMutation({
      outcomeId: outcome.id,
      initiative: params,
    });
  };

  const handleTitleChange = (e: any) => {
    e.preventDefault();
    const title = e.target.value;
    setTitle(title);
  };

  const handleGenerateInitiatives = (e: React.MouseEvent) => {
    e.preventDefault();
    //dispatch(setGlobalModalContent(`outcome:${outcome.id}:populate.ai`));
    dispatch(setGlobalModalContent(`outcome:${outcome.id}:chat.ai.initiatives`));
  };

  const isFetching = isCreating;

  if (!displayForm) {
    return (
      <Wrapper className="new-initiative-container new-initiative-actions">
        <KoalaButton onClick={showForm} size="small" appearance="subtle">
          {t('publicPlan.addInitiative', {
            initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
          })}
        </KoalaButton>
        {currentWorkspace.open_ai_enabled && (
          <KoalaButton size="small" appearance="secondary" className="ai-button" onClick={handleGenerateInitiatives}>
            {t('shared.generateInitiatives', {
              initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
            })}
            <span role="img">✨</span>
          </KoalaButton>
        )}
      </Wrapper>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <BlockGrid>
        <BlockIcon>
          <CheckBox>
            <input type="checkbox" id={`new-initiative-checkbox`} checked={false} />
            <label htmlFor={`new-initiative-checkbox`}>&#10003;</label>
          </CheckBox>
        </BlockIcon>

        <BlockContent>
          <TitleInput
            ref={inputEl}
            value={title}
            onChange={handleTitleChange}
            placeholder={t('shared.addInitiativePlaceholder') ?? `What can help you achieve this goal?`}
          />
        </BlockContent>
        <BlockActions>
          <KoalaButton size="small" submit disabled={isFetching} className="compact" loading={isFetching}>
            {t('shared.add')}
          </KoalaButton>
          <KoalaTextButton size="small" onClick={hideForm}>
            {t('shared.cancel')}
          </KoalaTextButton>
        </BlockActions>
      </BlockGrid>
    </form>
  );
}

export default React.memo(NewInitiativeForm);
