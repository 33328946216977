import queryKeys from 'config/queryKeys';
import React, { Fragment, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import { DashboardWidget, Membership, Segment, Workspace } from 'types';
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import { DragHandle, SourceTitle, WidgetContainer, WidgetHeader } from '.';
import { Link, useParams } from 'react-router-dom';
import * as filterUtils from 'utils/filterUtils';
import * as workspaceUtils from 'utils/workspaceUtils';
import { Base64 } from 'js-base64';
import parse from 'parse-link-header';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaButton from 'koala/components/Button';
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaLoader from 'koala/components/Loader';
import OutcomeExpandable from 'components/OutcomeExpandable';
import KoalaIcon from 'koala/components/Icons';
import { WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS } from 'routes';

const NoRows = styled.div`
  padding: ${theme.spacing.x1};
  text-align: center;
  color: ${theme.colors.subtleText};
`;

const UpgradeBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
  background: rgba(197, 165, 239, 0.25);
  max-width: 60rem;
  margin: ${theme.spacing.x1} auto;

  p {
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

const OutcomeContainer = styled.div`
  padding: ${theme.spacing.x2};
  display: flex;
  gap: ${theme.spacing.x1};
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  background: #fff;

  .item:last-of-type {
    border: none;
  }
  .outcome-expanded {
    border-bottom: 1px solid ${theme.colors.N10};
  }
`;

const GroupSeparator = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  background: ${theme.colors.N5};
  font-weight: 900;
  font-size: 1rem;
  text-transform: uppercase;
  border-bottom: 1px solid ${theme.colors.blockBorder};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const ListContainer = styled.div`
  border: 1px solid ${theme.colors.N10};
  border-radius: 4px;

  .item:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .outcome-expanded:last-of-type,
  .outcome-row:last-of-type {
    border-bottom: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

interface Props {
  widget: DashboardWidget;
}

function OutcomeListWidget(props: Props) {
  const { widget } = props;
  const segmentId = widget.source_id;
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  // Query keys and query params
  const [outcomeGroups, setOutcomeGroups]: any = useState([]);
  const [resultsCount, setResultsCount]: any = useState(0);

  const widgetTitle: string = widget.title
    ? widget.title
    : t('workspaceDashboards.outcomeList', {
        outcome: translate(workspace, CustomTermKey.OUTCOME, 1),
      });

  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();

  const { data: segmentResponse } = useQuery([queryKeys.segments, segmentId], remoteApi.fetchSegmentDetails);

  const segment: Segment = segmentResponse?.data;

  let filterParams = {};
  const customFilterHash = segment?.filter_hash ? JSON.parse(Base64.decode(segment.filter_hash)) : {};
  if (segment) {
    filterParams = filterUtils.getFilterHash(customFilterHash, segment.membership);
  }

  // Get the outcomes
  const queryKey = [
    queryKeys.outcomes,
    'search',
    {
      workspaceSlug,
      filter: filterParams,
    },
  ];
  const {
    isLoading: outcomesLoading,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(queryKey, remoteApi.searchOutcomes, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    onSuccess: (data: any) => {
      const resultsCount = parseInt(data[0].headers['x-total']);
      setResultsCount(resultsCount);
      setOutcomeGroups(data);
    },
  });

  const hasItems = outcomeGroups && outcomeGroups.length > 0 ? outcomeGroups[0].data.length > 0 : false;

  let indexCount = 0;
  const sortToGroupMapping: any = {
    plan: 'plan',
    owner: 'membership',
    state: 'state',
  };
  let currentGroup: any = null;
  let limitResultsForUpgrade = false;
  let freeLimit = 2;
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(workspace) || workspace.pricing_version >= 2;

  if (workspace.pricing_version >= 4) {
    limitResultsForUpgrade = false;
  } else {
    limitResultsForUpgrade = !hasEssentialsSubscription;
  }
  const unseenResults = resultsCount - freeLimit;

  if (!segment) {
    return <WidgetContainer>{t('workspaceDashboards.widgetError')}</WidgetContainer>;
  }

  const segmentRoute = WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspace.slug)
    .replace(':segmentId', segment.id)
    .replace(':filter', segment.filter_hash);
  const isLoading = outcomesLoading || isFetching;

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <OutcomeContainer>
        <SourceTitle>
          <Link to={segmentRoute} className="widget-source">
            {segment.title}
          </Link>
        </SourceTitle>
        {isLoading && <KoalaLoader />}
        {!isLoading && !hasItems && (
          <NoRows>
            {t('workspaceFilters.emptyState', {
              type: translate(workspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
            })}
          </NoRows>
        )}
        {hasItems && (
          <ListContainer>
            {outcomeGroups.map((group: any, i: number) => {
              return (
                <Fragment key={i}>
                  {group.data.map((outcome: any, index: number) => {
                    indexCount++;

                    let displayCurrentGroup = false;
                    let comparedGroup = null;
                    // Get the grouping key
                    const groupKey = sortToGroupMapping[customFilterHash.sorting];
                    if (groupKey) {
                      comparedGroup = outcome[groupKey];
                      const currentGroupId = currentGroup ? currentGroup.id : null;
                      const comparedGroupId = comparedGroup ? comparedGroup.id : null;

                      if (currentGroupId !== comparedGroupId) {
                        currentGroup = comparedGroup;
                        displayCurrentGroup = true;
                      }
                    }

                    if (limitResultsForUpgrade && indexCount > freeLimit) {
                      return null;
                    }
                    return (
                      <Fragment key={outcome.id}>
                        {displayCurrentGroup && customFilterHash.sorting === 'plan' && (
                          <GroupSeparator className="item">
                            <PlanIconLabel size="xsmall" plan={outcome.plan} />
                          </GroupSeparator>
                        )}
                        <OutcomeExpandable
                          outcome={outcome}
                          defaultExpanded={false}
                          hideClosedInitiatives={true}
                          showPlan={false}
                          hideTags={true}
                          hideActions={true}
                        />
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
            {canFetchMore && !limitResultsForUpgrade && (
              <LoadMore>
                <KoalaButton loading={!!isFetchingMore} onClick={() => fetchMore()} appearance="secondary">
                  {t('shared.loadMore')}
                </KoalaButton>
              </LoadMore>
            )}
            {limitResultsForUpgrade && resultsCount > freeLimit && (
              <>
                {workspace.pricing_version < 4 && (
                  <UpgradeBanner>
                    <p>
                      <UpgradeIcon /> <b> {t('workspaceFilters.upgrade.moreResults', { count: unseenResults })}</b>
                    </p>
                    <p>
                      {t('workspaceFilters.upgrade.information', {
                        count: unseenResults,
                        type: translate(workspace, CustomTermKey.OUTCOME, unseenResults).toLowerCase(),
                      })}
                    </p>
                    {currentMembership && ['owner'].includes(currentMembership.role) && (
                      <p>
                        <KoalaButton isLink to={`/${workspaceSlug}/settings/billing`}>
                          {t('shared.upgrade.startTrial')}
                        </KoalaButton>
                      </p>
                    )}
                    {currentMembership && !['owner'].includes(currentMembership.role) && (
                      <p>
                        <KoalaTextButton href="https://tability.io/pricing" target="_blank" rel="noreferrer">
                          {t('shared.upgrade.learnMore')}
                        </KoalaTextButton>
                      </p>
                    )}
                  </UpgradeBanner>
                )}
                {workspace.pricing_version >= 4 && (
                  <UpgradeBanner>
                    <p>
                      <UpgradeIcon /> <b> {t('workspaceFilters.upgradeV4.moreResults', { count: unseenResults })}</b>
                    </p>
                    <p>
                      {t('workspaceFilters.upgradeV4.information', {
                        count: unseenResults,
                        type: translate(workspace, CustomTermKey.OUTCOME, unseenResults).toLowerCase(),
                      })}
                    </p>
                    {currentMembership && ['owner'].includes(currentMembership.role) && (
                      <p>
                        <KoalaButton isLink to={`/${workspaceSlug}/settings/billing`}>
                          {t('workspaceFilters.upgradeV4.upgrade')}
                        </KoalaButton>
                      </p>
                    )}
                    {currentMembership && !['owner'].includes(currentMembership.role) && (
                      <p>
                        <KoalaTextButton href="https://tability.io/pricing" target="_blank" rel="noreferrer">
                          {t('shared.upgradeV4.learnMore')}
                        </KoalaTextButton>
                      </p>
                    )}
                  </UpgradeBanner>
                )}
              </>
            )}
          </ListContainer>
        )}
      </OutcomeContainer>
    </WidgetContainer>
  );
}

export default OutcomeListWidget;
