import React from 'react';

export default (props: any) => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.00003 21L16.9961 9L10 9L12 1L1.00394 13L8.00003 13L6.00003 21Z"
      fill="#A473E7"
      stroke="#A473E7"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
