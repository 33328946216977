import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import PostgreSQLIcon from 'components/_assets/IconPostgreSQL.png';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
}

function PostgreSQLIntegration(props: Props) {
  const { workspace, integrations } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const queryKey = [queryKeys.integrations, workspace.slug];

  const [uninstallPostgreSQLMutation, { isLoading: isUninstallingPostgreSQL }] = useMutation(
    remoteApi.uninstallPostgreSQL,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
      },
    },
  );
  const postgreSQLIntegration = integrations.filter((i: Integration) => i.integration_type === 'postgresql')[0];

  const handleUninstallPostgreSQL = () => {
    uninstallPostgreSQLMutation(workspace.slug);
  };

  const handleInstallPostgreSQL = () => {
    dispatch(setGlobalModalContent('workspace::connect.postgreSQL'));
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={PostgreSQLIcon} alt="PostgreSQL" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'PostgreSQL' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'PostgreSQL' })}</p>
      </IntegrationContent>
      <IntegrationAction>
        {postgreSQLIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: postgreSQLIntegration.connected_to }}
              />
            </div>
            <KoalaTextButton size="small" onClick={handleUninstallPostgreSQL}>
              {isUninstallingPostgreSQL ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove')}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!postgreSQLIntegration && (
          <KoalaButton onClick={handleInstallPostgreSQL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'PostgreSQL' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default PostgreSQLIntegration;
