import React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';

// API
import * as remoteApi from 'api/remote';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Routes

// Components
import { ModalGrid, ModalHeader, ModalContent, ModalFooter } from 'components/GlobalModal';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import { SELECTED_PLANS_KEY } from 'config/constants';

const PlanSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x2};
`;

interface Props {}

function ArchivePlans(props: Props) {
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.archivePlans';
  const storedSelectedPlansValue = localStorage.getItem(SELECTED_PLANS_KEY);
  const selectedPlans: { id: string; title: string }[] = storedSelectedPlansValue
    ? JSON.parse(storedSelectedPlansValue)
    : [];

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  //api
  const archivePlanAsync = async (plan: { id: string; title: string }) => {
    try {
      const response = await remoteApi.archivePlan({ planId: plan.id, plan: { archived: true } });
      queryCache.invalidateQueries([queryKeys.currentPlan, plan.id]);
      return response.data;
    } catch (error: any) {
      // If an error occurs during archiving, return an object with the error information
      return {
        error: true,
        statusText: error.response?.statusText || 'Failed to archive',
        ...plan,
      };
    }
  };

  // mutation
  const [archivePlansMutation, { isLoading }] = useMutation(
    async (plans: any[]) => {
      const archivePromises = plans.map((plan) => archivePlanAsync(plan));
      return Promise.all(archivePromises);
    },
    {
      onSuccess: (results) => {
        // Filter out the plans that failed to archive
        const failedPlans = results.filter((result) => result.error);

        if (failedPlans.length > 0) {
          // Some plans failed to archive
          const failedPlansMessage = failedPlans
            .map((failedPlan) => `${failedPlan.title} (${failedPlan.statusText})`)
            .join('\n');
          const failedMessage = `${t(`${translationKey}.failed`, {
            count: failedPlans.length,
            plans: failedPlansMessage,
          })}`;
          window.alert(failedMessage);
        }

        if (results.length !== failedPlans.length) {
          // if there's at least one success, clear cache
          queryCache.invalidateQueries(queryKeys.plans);
          queryCache.invalidateQueries([queryKeys.currentWorkspace]);
        }

        localStorage.removeItem(SELECTED_PLANS_KEY);
        dispatch(setGlobalModalContent(''));
      },
    },
  );

  const handleArchivePlans = (e: React.FormEvent) => {
    e.preventDefault();
    archivePlansMutation(selectedPlans);
  };

  const planCount = selectedPlans.length;
  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <p>{t(`${translationKey}.label`, { count: planCount })}</p>
        <PlanSection>
          {selectedPlans.map((plan, i) => (
            <b key={i}>{plan.title}</b>
          ))}
        </PlanSection>
        <p>{t(`${translationKey}.label2`)}</p>
      </ModalContent>
      <ModalFooter>
        <KoalaButton loading={isLoading} onClick={handleArchivePlans}>
          {t('shared.archive')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default ArchivePlans;
