import React, { useEffect } from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';
import theme from 'theme';

import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';

import FormField from 'components/FormField';
import KoalaTextBadge from 'koala/components/TextBadge';

const InlineItems = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
  setScoreChanges: (value: string) => void;
}

function CurrentValue(props: CurrentValueProps) {
  const { outcome, setScoreChanges } = props;

  // Query keys and query params
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:zendesk_sales:${outcome.data_source_type}`,
    'current',
  ];
  const staleTime = 300;

  const { data, isFetching } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  const currentValue = data?.data.result;

  useEffect(() => {
    if (currentValue) {
      setScoreChanges(currentValue.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const dataTypeToLabel: any = {
    deals_created: 'Deals created',
    leads_created: 'Leads created',
    contacts_created: 'Contacts created',
    tasks_created: 'Tasks created',
    deals_won: 'Deals won',
    deals_lost: 'Deals lost',
    hot_deals: 'Hot deals',
    total_deals_created: 'Total $ deals created',
    total_deals_won: 'Total $ deals won',
    total_deals_lost: 'Total $ deals lost',
    avg_deals_created: 'Avg $ deals created',
    avg_deals_won: 'Avg $ deals won',
    avg_deals_lost: 'Avg $ deals lost',
  };

  if (!outcome.data_source_type) {
    return null;
  }

  return (
    <FormField>
      <label>Data source</label>
      <InlineItems>
        <KoalaTextBadge edge="circle" size="small" maxWidth="30rem" variant="neutral-light">
          Zendesk Sales ({dataTypeToLabel[outcome.data_source_type]})
        </KoalaTextBadge>
        {isFetching && <KoalaLoader size="small" />}
      </InlineItems>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  setScoreChanges: (value: string) => void;
}

function ZendeskSource(props: Props) {
  const { outcome, setScoreChanges } = props;

  const availableDataSourceTypes = [
    'deals_created',
    'leads_created',
    'contacts_created',
    'tasks_created',
    'deals_won',
    'deals_lost',
    'hot_deals',
    'total_deals_created',
    'total_deals_won',
    'total_deals_lost',
    'avg_deals_created',
    'avg_deals_won',
    'avg_deals_lost',
  ];

  if (!outcome.data_source_type || outcome.data_source_origin !== 'zendesk_sales') {
    return null;
  }

  if (availableDataSourceTypes.includes(outcome.data_source_type)) {
    return <CurrentValue outcome={outcome} setScoreChanges={setScoreChanges} />;
  }

  return null;
}

export default React.memo(ZendeskSource);
