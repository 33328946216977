/**
 * Initiative component
 */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory } from 'react-router-dom';

// UI Components
import AddPersonIcon from 'components/_assets/AddPersonIcon';
import CommentButton from './CommentButton';
import CommentIcon from 'components/_assets/CommentIcon';
import PanelLabelButton from './PanelLabelButton';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import ClockIcon from 'components/_assets/ClockIcon';

import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaAvatar from 'koala/components/Avatar';
import { formatLocale } from 'utils/dateUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  initiativeId: string;
  reviewCommentsCount: number;
}

const AddOwnerButton = styled.div`
  height: 2rem;
  width: 2rem;
  cursor: pointer;

  background-color: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${theme.spacing.x3};
  border-radius: 50%;
  svg {
    width: 1.8rem;
    height: 1.8rem;
    g {
      stroke: ${theme.colors.subtleText};
    }
  }

  &:hover {
    background: #d1d1d1;
  }

  @media ${theme.devices.desktop} {
    margin-right: 1.6rem;
  }
`;

function Initiative(props: Props) {
  const history = useHistory();
  const { initiativeId, reviewCommentsCount } = props;
  const blockId = `initiative:${initiativeId}`;
  const queryCache = useQueryCache();
  const { i18n } = useTranslation();
  const initiative = useSelector((state: any) => state.editorEntities.initiatives[initiativeId]);

  const [updateInitiativeMutation]: [any, any] = useMutation(remoteApi.updateInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
    },
  });

  if (!initiative) {
    return null;
  }

  const handleEdit = () => {
    const hashPath = `#initiative:${initiative.nano_slug}:edit`;
    history.push(hashPath);
  };

  const handleAssignOwner = (e: any) => {
    const membership_id = e;

    if (membership_id) {
      const params = {
        membership_id,
      };

      updateInitiativeMutation({
        initiativeId,
        initiative: params,
      });
    }
  };

  const handleCreateComment = () => {
    const hashPath = `#editor:${blockId}:comment`;
    history.push(hashPath);
  };

  const { membership, due_at } = initiative;

  const commentClass = reviewCommentsCount > 0 ? 'has-comments' : 'masked-icon';

  return (
    <Fragment>
      {membership && (
        <UserSearchableDropdown
          trigger={
            <AddOwnerButton>
              <KoalaAvatar user={membership ? membership.user : null} size={2} />
            </AddOwnerButton>
          }
          callback={handleAssignOwner}
        />
      )}
      {!membership && (
        <UserSearchableDropdown
          trigger={
            <AddOwnerButton>
              <AddPersonIcon />
            </AddOwnerButton>
          }
          callback={handleAssignOwner}
        />
      )}
      {due_at && (
        <PanelLabelButton className="due-date" onClick={handleEdit}>
          <ClockIcon />
          {formatLocale(due_at, 'dd MMM', i18n)}
        </PanelLabelButton>
      )}
      {!due_at && (
        <PanelLabelButton className="due-date" onClick={handleEdit}>
          <ClockIcon />–
        </PanelLabelButton>
      )}
      <div className="spacer" />
      <CommentButton className={commentClass} onClick={handleCreateComment}>
        <div className="icon">
          <CommentIcon />
        </div>
        <div className="value">{reviewCommentsCount > 0 && <Fragment>{reviewCommentsCount}</Fragment>}</div>
      </CommentButton>
    </Fragment>
  );
}

export default React.memo(Initiative);
