//@flow
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Wrapper, Button, Menu, MenuItem } from 'react-aria-menubutton';

const StyledWrapper = styled(Wrapper)`
  display: inline-block;
  position: relative;

  .AriaMenuButton-trigger {
    cursor: pointer;
    background-color: transparent;
  }

  .AriaMenuButton-trigger:hover,
  .AriaMenuButton-trigger:focus,
  .AriaMenuButton-trigger.is-open {
    outline: none;
  }

  .AriaMenuButton-trigger:active,
  .AriaMenuButton-trigger.is-open {
    background-color: #dcdcdc;
    background-image: none;
    border-color: #b5b5b5;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
  }

  .AriaMenuButton-menu {
    background: #fff;
    border: 1px solid rgba(200, 200, 200, 0.4);
    list-style-type: none;

    position: absolute;
    top: 100%;
    ${(props: any) => props.position || 'right'}: 0;
    z-index: 99;
    padding-left: 0;
    border-radius: 3px;
    margin: 4px 0 0 0;
    border: 1px solid #d1d1d1;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    width: 215px;
  }

  .AriaMenuButton-menu--flushRight {
    right: 0;
  }

  .AriaMenuButton-menuItem {
    cursor: pointer;
    padding: 8px;
    border-bottom: 1px solid #eee;
  }

  .AriaMenuButton-menuItemWrapper:first-of-type .AriaMenuButton-menuItem {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .AriaMenuButton-menuItemWrapper:last-of-type .AriaMenuButton-menuItem {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .AriaMenuButton-menuItem:hover,
  .AriaMenuButton-menuItem:focus {
    background-color: ${theme.colors.N3};
    color: ${theme.colors.N100};
  }

  .AriaMenuButton-menuItem.is-selected {
    cursor: default;
    font-weight: bold;
  }
`;

type Props = {
  items: Array<any>;
  onSelection?: (element: ReactElement) => void;
  position?: string;
  trigger: any;
};

function DropdownMenu(props: Props) {
  const { items, trigger } = props;

  return (
    <StyledWrapper
      className={`AriaMenuButton`}
      {...props}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Button className={`AriaMenuButton-trigger`}>{trigger}</Button>
      <Menu>
        <ul className="AriaMenuButton-menu">
          {items.map((item, index) => (
            <li className="AriaMenuButton-menuItemWrapper" key={index}>
              <MenuItem className="AriaMenuButton-menuItem">{item}</MenuItem>
            </li>
          ))}
        </ul>
      </Menu>
    </StyledWrapper>
  );
}

export default DropdownMenu;
