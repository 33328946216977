import React, { useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useSelector, shallowEqual } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Trans, useTranslation } from 'react-i18next';

import { getConfidenceData, getOutcomeProgressData, getInitiativeProgressData } from 'utils/membershipUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import theme from 'theme';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import KoalaNCSPie from 'koala/components/NCSPie';

import KoalaIcon from 'koala/components/Icons';
import ReactTooltip from 'react-tooltip';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x2};
  padding-bottom: ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.blockBorder};
`;

const DataContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.x1};
  position: relative;

  h4 {
    font-weight: 600;
  }
  small {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
  }
`;

interface Props {
  currentMembership: TabilityTypes.Membership;
}

function MembershipInsights(props: Props) {
  const { currentMembership } = props;
  const { t } = useTranslation();

  const [membership, setMembership] = useState<TabilityTypes.Membership>(currentMembership);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const queryKeyMembership = [queryKeys.currentMembership, props.currentMembership.id];

  useQuery(queryKeyMembership, remoteApi.fetchMembershipDetails, {
    staleTime: 0,
    onSuccess: (response) => {
      const membership = response.data;
      setMembership(membership);
    },
  });

  const confidenceData = getConfidenceData(membership);
  const outcomeProgressData = getOutcomeProgressData(membership);
  const initiativeProgressData = getInitiativeProgressData(membership);

  let ncsColor = theme.colors.G50;
  if (membership.ncs <= 30) {
    ncsColor = theme.colors.Y90;
  }
  if (membership.ncs < 0) {
    ncsColor = theme.colors.R50;
  }

  const outcomeCenterLabel = membership.total_outcomes_count === 0 ? '-' : `${outcomeProgressData.centerLabel}`;

  return (
    <Container>
      <DataContainer>
        <KoalaCircularProgress data={outcomeProgressData} size="small" useIcon />
        <h4>{outcomeCenterLabel}</h4>
        <small className="subtle">
          {t('workspaceInsights.progressLabel', {
            label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
          })}
        </small>
      </DataContainer>
      <DataContainer>
        <KoalaCircularProgress data={initiativeProgressData} size="small" useIcon />
        <h4>{initiativeProgressData.centerLabel}</h4>
        <small className="subtle">
          {t(`workspacePlan.tasksCompleted`, {
            initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
          })}
        </small>
      </DataContainer>
      <DataContainer>
        <KoalaNCSPie centerLabel={[]} data={confidenceData} size="small" />
        <h4 style={{ color: ncsColor }}>
          {Math.round(membership.ncs)} {t('shared.ncs.acronym')}
        </h4>
        <small className="subtle">
          {t('shared.confidence.label')}
          <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
        </small>
        <ReactTooltip
          place="bottom"
          type="dark"
          className="tooltip"
          effect="solid"
          id="explain-ncs"
          delayHide={500}
          clickable={true}
        >
          <Trans i18nKey="shared.ncs.tooltip" components={{ br: <br /> }} />{' '}
          <a
            href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('shared.learnMore')}
          </a>
        </ReactTooltip>
      </DataContainer>
    </Container>
  );
}

export default MembershipInsights;
