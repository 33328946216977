import React from 'react';

const InboxIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6 2.80005C4.23997 2.80005 3.91459 3.01461 3.77277 3.34552L1.08061 9.62723C1.02884 9.74083 1 9.86707 1 10V14.5C1 15.8996 2.04139 17.2 3.52 17.2H16.48C17.9586 17.2 19 15.8996 19 14.5V10C19 9.98757 18.9997 9.9751 18.9992 9.96265C18.9943 9.8434 18.9663 9.73014 18.9194 9.62725L16.2272 3.34552C16.0854 3.01461 15.76 2.80005 15.4 2.80005H4.6ZM16.7351 9.10005L14.8065 4.60005H5.19346L3.26488 9.10005H5.5C5.7387 9.10005 5.96763 9.19488 6.13641 9.36367L7.6728 10.9001H12.3272L13.8636 9.36367C14.0324 9.19488 14.2613 9.10005 14.5 9.10005H16.7351ZM2.8 10.9V14.5C2.8 15.0886 3.2092 15.4 3.52 15.4H16.48C16.7908 15.4 17.2 15.0886 17.2 14.5V10.9H14.8728L13.3364 12.4365C13.1676 12.6053 12.9387 12.7001 12.7 12.7001H7.3C7.0613 12.7001 6.83237 12.6053 6.66359 12.4365L5.1272 10.9H2.8Z"
      fill="#737373"
    />
  </svg>
);

export default InboxIcon;
