import React, { useEffect, useMemo, useState } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';

import styled from 'styled-components';
import theme from 'theme';
import { DashboardWidget } from 'types';
import * as remoteApi from 'api/remote';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';
import DashboardWidgetBlock from 'components/DashboardWidgetBlock';
import KoalaIconButton from 'koala/components/IconButton';
import { ActionButtons, WidgetBlock } from '../View';

const ReactGridLayout = WidthProvider(RGL);

const WidgetContent = styled.div`
  position: relative;
  flex: 1;
  min-width: 3rem;
  overflow: hidden;
`;

const DashboardContent = styled.div`
  height: 100%;
  position: relative;
  .grid-layout {
    height: 100%;
  }

  .block {
    background: skyblue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .static {
    background: blue;
  }
  .droppable-element {
    height: 50px;
    width: 250px;
    background: skyblue;
    margin: 10px;
    border: 2px dashed;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-grid-item.react-grid-placeholder {
    background: ${theme.colors.R10};
  }
`;

interface Props {
  layout: ReactGridLayout.Layout[];
  widgets: DashboardWidget[];
  setLayout: (value: ReactGridLayout.Layout[]) => void;
  handleEditWidget: (value: DashboardWidget) => void;
}

function DashboardSection(props: Props) {
  const { layout, widgets, setLayout, handleEditWidget } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const queryCache = useQueryCache();
  const { dashboardId } = useParams<{
    dashboardId: string;
  }>();

  const [deleteDashboardWidget] = useMutation(remoteApi.deleteDashboardWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.dashboardWidgets, dashboardId]);
    },
  });

  const gridItems = useMemo(
    () =>
      widgets.map((widget) => {
        let widgetLayout = layout.find((l) => l.i === widget.id) ?? { x: 0, y: 0, h: 2, w: 2, i: widget.id };

        const handleRemove = (e: React.MouseEvent<HTMLButtonElement>, item: DashboardWidget) => {
          e.stopPropagation();
          e.preventDefault();
          deleteDashboardWidget(item.id);
        };
        const handleEdit = (e: React.MouseEvent<HTMLButtonElement>, item: DashboardWidget) => {
          e.stopPropagation();
          e.preventDefault();
          handleEditWidget(widget);
        };
        const widgetClass = `edit ${widget.widget_type}`;
        return (
          <WidgetBlock className={widgetClass} key={widget.id} data-grid={{ ...widgetLayout }}>
            <WidgetContent>
              <DashboardWidgetBlock widget={widget} />
            </WidgetContent>
            <ActionButtons>
              <KoalaIconButton iconName="edit" onClick={(e) => handleEdit(e, widget)} size="small" />
              <KoalaIconButton iconName="close" onClick={(e) => handleRemove(e, widget)} size="small" />
            </ActionButtons>
          </WidgetBlock>
        );
      }),
    [deleteDashboardWidget, layout, widgets, handleEditWidget],
  );

  const handleLayoutChange = (currentLayout: ReactGridLayout.Layout[]) => {
    setLayout(currentLayout);
  };

  return (
    <DashboardContent>
      <ReactGridLayout
        onLayoutChange={handleLayoutChange}
        isDraggable={true}
        isResizable={true}
        cols={12}
        rowHeight={30}
        margin={[8, 8]}
        measureBeforeMount={false}
        useCSSTransforms={mounted}
        compactType={'vertical'}
        draggableHandle=".drag-handle"
      >
        {gridItems}
      </ReactGridLayout>
    </DashboardContent>
  );
}
export default DashboardSection;
