// This file contains the filters for the app

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { startOfYear, endOfYear, subDays, addDays } from 'date-fns';

import 'theme/DatePicker.css';
import DatePicker from 'react-datepicker';
import PlanFilter from '../WorkspaceOutcomes/PlanFilter';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import DropdownMenu from 'components/DropdownMenu';
import KoalaButton from 'koala/components/Button';
import KoalaSelect from 'koala/components/Select';
import TagFilter from 'pages/WorkspaceOutcomes/TagFilter';
import TeamFilter from 'pages/WorkspaceOutcomes/TeamFilter';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  gap: ${theme.spacing.x1};
  display: flex;
  flex-wrap: wrap;
`;

const FilterWrapper = styled.div`
  display: grid;
  grid-template-areas: 'label label' 'select button';
  align-items: center;
  column-gap: ${theme.spacing.half};
  label {
    grid-area: label;
    color: ${theme.colors.subtleText};
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1rem;
    display: block;
    margin-bottom: 0.4rem;
  }
`;

const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.x1};
  font-size: 1.4rem;
  span {
    display: block;
    margin-right: ${theme.spacing.x1};
  }

  > div {
    margin-right: ${theme.spacing.x1};
  }
`;

interface Props {
  updateURL: any;
  customFilterHash: { [key: string]: any };
}

function Filters(props: Props) {
  const { customFilterHash, updateURL } = props;
  const { t } = useTranslation();
  const filterHash = { ...customFilterHash };
  const [displayedFilters, setDisplayedFilters] = useState(Object.keys(customFilterHash));

  useEffect(() => {
    setDisplayedFilters(Object.keys(customFilterHash));
  }, [customFilterHash]);

  const updateFilters = (attribute: string, value: any) => {
    filterHash[attribute] = value;

    if (attribute === 'timeline' && value === 'custom') {
      const refDate = Date.now();
      const start_at = subDays(startOfYear(refDate), 1).toISOString();
      const finish_at = addDays(endOfYear(refDate), 1).toISOString();
      filterHash.start_at = start_at;
      filterHash.finish_at = finish_at;
    } else if (attribute === 'duedate' && value === 'custom') {
      const refDate = Date.now();
      const due_after = subDays(startOfYear(refDate), 1).toISOString();
      const due_before = addDays(endOfYear(refDate), 1).toISOString();
      filterHash.due_after = due_after;
      filterHash.due_before = due_before;
    }

    if (filterHash.timeline === null) {
      filterHash.start_at = null;
      filterHash.finish_at = null;
    }
    if (filterHash.duedate === null) {
      filterHash.due_after = null;
      filterHash.due_before = null;
    }

    updateURL(filterHash);
  };

  /*
  const getSelectedOptionsFromFilter = (id: string, options: any) => {
    return options.filter((o: any) => {
      if (filterHash[id]) {
        return filterHash[id].indexOf(o.value) >= 0;
      }
      return false;
    });
  };
  */

  const getSelectedSingleOptionFromFilter = (id: string, options: any) => {
    const op = options.filter((o: any) => {
      if (typeof filterHash[id] !== 'undefined') {
        return filterHash[id] === o.value;
      }
      return false;
    });
    return op;
  };

  /*
  const handleMultiOptionSelect = (selectId: string, options: any) => {
    if (options) {
      const values = options.map((option: any) => option.value);
      updateFilters(selectId, values);
    }
  };
  */

  const handleSingleOptionSelect = (selectId: string, option: any) => {
    const value = option ? option.value : null;
    updateFilters(selectId, value);
  };

  const handleAsyncFilterSelect = (selectId: string, options: any) => {
    if (options) {
      updateFilters(selectId, options);
    }
  };

  const handleSelectDate = (selectId: string, date: any) => {
    if (date) {
      updateFilters(selectId, date.toISOString());
    }
  };

  const handleAddFilter = (value: any) => {
    filterHash[value.key] = null;
    updateURL(filterHash);
  };

  const progressOptions = [
    { label: '0%', value: 0 },
    { label: '10%', value: 10 },
    { label: '20%', value: 20 },
    { label: '30%', value: 30 },
    { label: '40%', value: 40 },
    { label: '50%', value: 50 },
    { label: '60%', value: 60 },
    { label: '70%', value: 70 },
    { label: '80%', value: 80 },
    { label: '90%', value: 90 },
    { label: '100%', value: 100 },
  ];

  const ncs = t('shared.ncs.acronym');
  const confidenceOptions = [
    { label: `-100 ${ncs}`, value: -100 },
    { label: `-90 ${ncs}`, value: -90 },
    { label: `-80 ${ncs}`, value: -80 },
    { label: `-70 ${ncs}`, value: -70 },
    { label: `-60 ${ncs}`, value: -60 },
    { label: `-50 ${ncs}`, value: -50 },
    { label: `-40 ${ncs}`, value: -40 },
    { label: `-30 ${ncs}`, value: -30 },
    { label: `-20 ${ncs}`, value: -20 },
    { label: `-10 ${ncs}`, value: -10 },
    { label: `0 ${ncs}`, value: 0 },
    { label: `10 ${ncs}`, value: 10 },
    { label: `20 ${ncs}`, value: 20 },
    { label: `30 ${ncs}`, value: 30 },
    { label: `40 ${ncs}`, value: 40 },
    { label: `50 ${ncs}`, value: 50 },
    { label: `60 ${ncs}`, value: 60 },
    { label: `70 ${ncs}`, value: 70 },
    { label: `80 ${ncs}`, value: 80 },
    { label: `90 ${ncs}`, value: 90 },
    { label: `100 ${ncs}`, value: 100 },
  ];

  const planStatusOptions = [
    { label: t('shared.status.inProgress'), value: 'is_in_progress' },
    { label: t('shared.status.draft'), value: 'is_draft' },
  ];

  const timelineOptions = [
    { label: t('shared.timelineThisQuarter'), value: 'current_quarter' },
    { label: t('shared.timelineLastQuarter'), value: 'last_quarter' },
    { label: t('shared.timelineNextQuarter'), value: 'next_quarter' },
    { label: t('shared.timelineCustom'), value: 'custom' },
  ];

  const selectedTimeline = getSelectedSingleOptionFromFilter('timeline', timelineOptions);
  const selectedTeams = filterHash['team'];
  const selectedPlans = filterHash['plan'];
  const selectedTags = filterHash['tag'];

  const start_at: any = filterHash['start_at'] ? Date.parse(filterHash['start_at']) : Date.now();
  const finish_at: any = filterHash['finish_at'] ? Date.parse(filterHash['finish_at']) : Date.now();

  const defaultFilter = {
    timeline: 'current_quarter',
  };
  const isDefaultFilter = JSON.stringify(customFilterHash) === JSON.stringify(defaultFilter);

  const filterItems = [
    <span key="plan_status">{t('workspaceFilters.planStatus')}</span>,
    <span key="plan">{t('workspaceFilters.plans')}</span>,
    <span key="team">{t('workspaceFilters.teams')}</span>,
    // <span key="tag">Tag</span>,
    <span key="progress_above_equal">{t('workspaceFilters.progressAbove')}</span>,
    <span key="progress_below">{t('workspaceFilters.progressBelow')}</span>,
    <span key="confidence_above_equal">{t('workspaceFilters.confidenceAbove')}</span>,
    <span key="confidence_below">{t('workspaceFilters.confidenceBelow')}</span>,
    <span key="tag">{t('workspaceFilters.tags')}</span>,
    <span key="timeline">{t('workspaceFilters.timeline')}</span>,
  ];

  const handleResetFilters = () => {
    updateURL(defaultFilter);
  };

  const handleRemoveActiveFilter = (filter: string) => {
    if (filterHash[filter] !== undefined) {
      delete filterHash[filter];
      updateURL(filterHash);
    }
  };

  return (
    <Container>
      <FilterWrapper>
        <label>&nbsp;</label>
        <DropdownMenu
          position="left"
          onSelection={handleAddFilter}
          items={filterItems.filter((item) => !displayedFilters.includes(item.key as string))}
          trigger={
            <KoalaButton appearance="subtle" className="add-filter-button">
              {t('workspaceFilters.filterButton')}
            </KoalaButton>
          }
        />
      </FilterWrapper>
      {displayedFilters.includes('plan_status') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.planStatus')}</label>
          <KoalaSelect
            isClearable
            size="xsmall"
            id="plan_status"
            placeholder={t('workspaceFilters.placeholder')}
            options={planStatusOptions}
            handleChange={(options) => handleSingleOptionSelect('plan_status', options)}
            selectedOption={getSelectedSingleOptionFromFilter('plan_status', planStatusOptions)}
          />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('plan_status')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('plan') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.plans')}</label>
          <PlanFilter value={selectedPlans} handleChange={handleAsyncFilterSelect} />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('plan')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('team') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.teams')}</label>
          <TeamFilter value={selectedTeams} handleChange={handleAsyncFilterSelect} />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('team')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('progress_above_equal') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.progressAbove')}</label>
          <KoalaSelect
            isClearable
            size="xsmall"
            id="progress_above_equal"
            placeholder={t('workspaceFilters.placeholder')}
            options={progressOptions}
            handleChange={(options) => handleSingleOptionSelect('progress_above_equal', options)}
            selectedOption={getSelectedSingleOptionFromFilter('progress_above_equal', progressOptions)}
          />
          <KoalaIconButton
            iconName="close"
            size="small"
            onClick={() => handleRemoveActiveFilter('progress_above_equal')}
          />
        </FilterWrapper>
      )}
      {displayedFilters.includes('progress_below') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.progressBelow')}</label>
          <KoalaSelect
            isClearable
            size="xsmall"
            id="progress_below"
            placeholder={t('workspaceFilters.placeholder')}
            options={progressOptions}
            handleChange={(options) => handleSingleOptionSelect('progress_below', options)}
            selectedOption={getSelectedSingleOptionFromFilter('progress_below', progressOptions)}
          />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('progress_below')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('confidence_above_equal') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.confidenceAbove')}</label>
          <KoalaSelect
            isClearable
            size="xsmall"
            id="confidence_above_equal"
            placeholder={t('workspaceFilters.placeholder')}
            options={confidenceOptions}
            handleChange={(options) => handleSingleOptionSelect('confidence_above_equal', options)}
            selectedOption={getSelectedSingleOptionFromFilter('confidence_above_equal', confidenceOptions)}
          />
          <KoalaIconButton
            iconName="close"
            size="small"
            onClick={() => handleRemoveActiveFilter('confidence_above_equal')}
          />
        </FilterWrapper>
      )}
      {displayedFilters.includes('confidence_below') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.confidenceBelow')}</label>
          <KoalaSelect
            isClearable
            size="xsmall"
            id="confidence_below"
            placeholder={t('workspaceFilters.placeholder')}
            options={confidenceOptions}
            handleChange={(options) => handleSingleOptionSelect('confidence_below', options)}
            selectedOption={getSelectedSingleOptionFromFilter('confidence_below', confidenceOptions)}
          />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('confidence_below')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('tag') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.tags')}</label>
          <TagFilter value={selectedTags} handleChange={handleAsyncFilterSelect} />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('tag')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('timeline') && (
        <>
          <FilterWrapper>
            <label>{t('workspaceFilters.timeline')}</label>
            <KoalaSelect
              isClearable
              size="xsmall"
              id="timeline"
              placeholder={t('workspaceFilters.placeholder')}
              options={timelineOptions}
              handleChange={(options) => handleSingleOptionSelect('timeline', options)}
              selectedOption={selectedTimeline}
            />
            <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('timeline')} />
          </FilterWrapper>
          {selectedTimeline && selectedTimeline[0] && selectedTimeline[0].value === 'custom' && (
            <FilterWrapper>
              <label>{t('workspaceFilters.customTimeline')}</label>
              <DatesWrapper>
                <span>{t('workspaceFilters.customTimelineStart')}</span>
                <DatePicker
                  selected={start_at}
                  onChange={(date: any) => handleSelectDate('start_at', date)}
                  dateFormat="d MMM yyyy"
                />
                <span>{t('workspaceFilters.customTimelineEnd')}</span>
                <DatePicker
                  selected={finish_at}
                  onChange={(date: any) => handleSelectDate('finish_at', date)}
                  dateFormat="d MMM yyyy"
                />
              </DatesWrapper>
            </FilterWrapper>
          )}
        </>
      )}
      {!isDefaultFilter && (
        <FilterWrapper>
          <label>&nbsp;</label>
          <KoalaTextButton onClick={handleResetFilters}>{t('workspaceFilters.resetFilters')}</KoalaTextButton>
        </FilterWrapper>
      )}
    </Container>
  );
}

export default Filters;
