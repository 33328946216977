import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQuery, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import { VictoryAxis, VictoryChart, VictoryHistogram } from 'victory';
import { parseISO, startOfWeek, subWeeks, addWeeks, addMonths, startOfMonth } from 'date-fns';
import { format } from 'date-fns';
import download from 'downloadjs';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import ContentTitle from 'components/ContentTitle';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import Loader from 'components/Loader';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import { getLocaleMonth } from 'utils/dateUtils';
import KoalaButton from 'koala/components/Button';

const StatsContainer = styled.div`
  max-width: 60rem;
  margin-bottom: ${theme.spacing.x3};
  h3 {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  margin-top: ${theme.spacing.x2};
`;

const Usage = styled.div`
  white-space: nowrap;
  margin-right: ${theme.spacing.x2};
`;

const Quota = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.x1};
`;

const ProgressBarContainer = styled.div`
  height: 1.2rem;
  flex: 1;
  background: ${theme.colors.blockBorder};
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ prct: number }>`
  height: 100%;
  background-color: ${theme.colors.blue};
  border-radius: 4px;
  width: ${(props) => props.prct}%;
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

const staleTime = 0;

interface CheckinActivity {
  checkins: number;
  week: string;
}
interface InitiativeActivity {
  initiatives: number;
  week: string;
}
interface GraphData {
  x: Date;
}

function WorkspaceSettingsUsage(props: Props) {
  const { workspace } = props;
  const queryKeyCheckins = [queryKeys.currentWorkspace, workspace.slug, 'checkins_activity'];
  const queryKeyInitiativesCreated = [queryKeys.currentWorkspace, workspace.slug, 'initiatives_created_activity'];
  const { t, i18n } = useTranslation();
  const [report, setReport] = useState<string | undefined>(undefined);
  const [checkinsActivity, setCheckinsActivity] = useState<CheckinActivity[]>([]);
  const [initiativesCreatedActivity, setInitiativesCreatedActivity] = useState<InitiativeActivity[]>([]);

  const { isFetching: isFetchinCheckins } = useQuery(queryKeyCheckins, remoteApi.fetchWorkspaceCheckinsActivity, {
    staleTime,
    onSuccess: (response) => {
      setCheckinsActivity(response.data);
    },
  });

  const { isFetching: isFetchinInitiativesCreated } = useQuery(
    queryKeyInitiativesCreated,
    remoteApi.fetchWorkspaceInitiativesCreatedActivity,
    {
      staleTime,
      onSuccess: (response) => {
        setInitiativesCreatedActivity(response.data);
      },
    },
  );

  const checkinsData: GraphData[] = [];
  checkinsActivity.forEach((activity) => {
    const checkinsCount = activity.checkins || 0;
    for (let i = 0; i < checkinsCount; i++) {
      checkinsData.push({
        x: parseISO(activity.week),
      });
    }
  });

  const initiativesData: GraphData[] = [];
  initiativesCreatedActivity.forEach((activity) => {
    const initiativesCreatedCount = activity.initiatives || 0;
    for (let i = 0; i < initiativesCreatedCount; i++) {
      initiativesData.push({
        x: parseISO(activity.week),
      });
    }
  });

  const refDate = Date.now();
  const bins: Date[] = [];

  // Create the bins for the VictoryHistogram component.
  // This loop will create 24 bins for weeks going from W-24 to last week
  for (let i = 24; i >= 0; i--) {
    const bin = subWeeks(startOfWeek(refDate), i);
    bins.push(bin);
  }

  // Add an extra bin as the startOfWeek of refDate might not capture the latest data
  const lastBin = addWeeks(startOfWeek(refDate), 1);
  bins.push(lastBin);

  // get months for the axis
  const firstBin = startOfMonth(subWeeks(startOfWeek(refDate), 24));
  const months: Date[] = [
    addMonths(firstBin, 1),
    addMonths(firstBin, 2),
    addMonths(firstBin, 3),
    addMonths(firstBin, 4),
    addMonths(firstBin, 5),
    addMonths(firstBin, 6),
  ];

  let quotaProgress = 0;
  if (workspace.billing_user_limit) {
    quotaProgress = Math.round((workspace.memberships_count / workspace.billing_user_limit) * 100);
  }

  // Export features

  // Mutation that will create the workspace in the backend
  const [exportPeopleMutation, { isLoading: isExportingPeople }]: [any, any] = useMutation(
    remoteApi.exportWorkspaceMemberships,
    {
      onSuccess: (response: any) => {
        const content = response.headers['content-type'];
        const fileName = `people-${format(new Date(), 'Y-MM-dd')}.csv`;
        download(response.data, fileName, content);
      },
    },
  );

  const handleExportPeople = () => {
    const mutationParams = {
      workspaceSlug: workspace.slug,
    };
    exportPeopleMutation(mutationParams);
  };

  // Mutation that will create the workspace in the backend
  const [exportOutcomesMutation, { isLoading: isExportingOutcomes }]: [any, any] = useMutation(
    remoteApi.exportOutcomes,
    {
      onSuccess: (response: any) => {
        const content = response.headers['content-type'];
        const fileName = `outcomes-${format(new Date(), 'Y-MM-dd')}.csv`;
        download(response.data, fileName, content);
      },
    },
  );

  const handleExportOutcomes = () => {
    const mutationParams = {
      workspaceSlug: workspace.slug,
      filter: {
        sorting: 'created_at',
      },
    };
    exportOutcomesMutation(mutationParams);
  };

  // Mutation that will create the workspace in the backend
  const [exportTaskMutation, { isLoading: isExportingTasks }]: [any, any] = useMutation(remoteApi.exportInitiatives, {
    onSuccess: (response: any) => {
      const content = response.headers['content-type'];
      const fileName = `initiatives-${format(new Date(), 'Y-MM-dd')}.csv`;
      download(response.data, fileName, content);
    },
  });

  const handleExportTasks = () => {
    const mutationParams = {
      workspaceSlug: workspace.slug,
      filter: {
        sorting: 'created_at',
      },
    };
    exportTaskMutation(mutationParams);
  };

  // Mutation that will export the objectives
  const [exportObjectivesMutation, { isLoading: isExportingObjectives }]: [any, any] = useMutation(
    remoteApi.exportObjectives,
    {
      onSuccess: (response: any) => {
        const content = response.headers['content-type'];
        const fileName = `objectives-${format(new Date(), 'Y-MM-dd')}.csv`;
        download(response.data, fileName, content);
      },
    },
  );
  const handleExportObjectives = () => {
    const mutationParams = {
      workspaceSlug: workspace.slug,
      filter: {
        sorting: 'created_at',
      },
    };
    exportObjectivesMutation(mutationParams);
  };

  // Mutation that will export the objectives
  const [exportPlansMutation, { isLoading: isExportingPlans }]: [any, any] = useMutation(remoteApi.exportPlans, {
    onSuccess: (response: any) => {
      const content = response.headers['content-type'];
      const fileName = `plans-${format(new Date(), 'Y-MM-dd')}.csv`;
      download(response.data, fileName, content);
    },
  });
  const handleExportPlans = () => {
    const mutationParams = {
      workspaceSlug: workspace.slug,
    };
    exportPlansMutation(mutationParams);
  };

  // Mutation that will export the objectives
  const [exportCheckinsMutation, { isLoading: isExportingCheckins }]: [any, any] = useMutation(
    remoteApi.exportCheckins,
    {
      onSuccess: (response: any) => {
        const content = response.headers['content-type'];
        const fileName = `checkins-${format(new Date(), 'Y-MM-dd')}.csv`;
        download(response.data, fileName, content);
      },
    },
  );
  const handleExportCheckins = () => {
    const mutationParams = {
      workspaceSlug: workspace.slug,
    };
    exportCheckinsMutation(mutationParams);
  };

  const handleDowload = () => {
    if (report === undefined) {
      return;
    }

    switch (report) {
      case 'checkins':
        handleExportCheckins();
        break;
      case 'users':
        handleExportPeople();
        break;
      case 'plans':
        handleExportPlans();
        break;
      case 'objectives':
        handleExportObjectives();
        break;
      case 'outcomes':
        handleExportOutcomes();
        break;
      case 'initiatives':
        handleExportTasks();
        break;
    }
  };

  const isExporting =
    isExportingCheckins ||
    isExportingObjectives ||
    isExportingOutcomes ||
    isExportingPeople ||
    isExportingPlans ||
    isExportingTasks;

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsUsage.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsUsage.title')}</h1>
          </ContentTitle>
          <StatsContainer>
            <h3>{t('workspaceSettingsUsage.reportsHeader')}</h3>
            <p>{t('workspaceSettingsUsage.reportsInfo')}</p>
            <SelectContainer>
              <div>
                <select
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setReport(e.currentTarget.value)}
                  value={report}
                >
                  <option value={undefined}>{t('workspaceSettingsUsage.reportOptionNone')}</option>
                  <option value="checkins">{t('workspaceSettingsUsage.reportOptionCheckins')}</option>
                  <option value="plans">{t('workspaceSettingsUsage.reportOptionPlans')}</option>
                  <option value="objectives">
                    {t('workspaceSettingsUsage.reportOptionObjectives', {
                      objectives: translate(workspace, CustomTermKey.OBJECTIVE, 2),
                    })}
                  </option>
                  <option value="outcomes">
                    {t('workspaceSettingsUsage.reportOptionOutcomes', {
                      outcomes: translate(workspace, CustomTermKey.OUTCOME, 2),
                    })}
                  </option>
                  <option value="initiatives">
                    {t('workspaceSettingsUsage.reportOptionInitiatives', {
                      initiatives: translate(workspace, CustomTermKey.INITIATIVE, 2),
                    })}
                  </option>
                  <option value="users">{t('workspaceSettingsUsage.reportOptionUsers')}</option>
                </select>
              </div>
              <div>
                <KoalaButton
                  appearance="subtle"
                  disabled={!report || isExporting}
                  loading={isExporting}
                  onClick={handleDowload}
                >
                  {t('shared.download')}
                </KoalaButton>
              </div>
            </SelectContainer>
          </StatsContainer>
          {workspace.billing_user_limit !== undefined && workspace.billing_user_limit > 0 && (
            <StatsContainer>
              <h3>{t('workspaceSettingsUsage.usersHeader')}</h3>
              <Quota>
                <Usage>
                  <b>{workspace.memberships_count}</b> /{' '}
                  {t('workspaceSettingsUsage.users', { count: workspace.billing_user_limit })}
                </Usage>
                <ProgressBarContainer>
                  <ProgressBar prct={quotaProgress} />
                </ProgressBarContainer>
              </Quota>
            </StatsContainer>
          )}

          {workspace.billing_user_limit === 0 && (
            <StatsContainer>
              <h3>{t('workspaceSettingsUsage.usersHeader')}</h3>
              <Quota>
                <Usage>{t('workspaceSettingsUsage.users', { count: workspace.memberships_count })}</Usage>
              </Quota>
            </StatsContainer>
          )}

          <StatsContainer>
            <h3>{t('workspaceSettingsUsage.checkinsHeader')}</h3>
            <p>{t('workspaceSettingsUsage.checkinsInfo')}</p>
            {isFetchinCheckins && <Loader />}
            {!isFetchinCheckins && checkinsData.length > 0 && (
              <VictoryChart width={600} height={200}>
                <VictoryHistogram data={checkinsData} bins={bins} labels={({ datum }) => datum.y} />
                <VictoryAxis
                  tickValues={months}
                  tickCount={months.length}
                  tickFormat={(x) => {
                    return getLocaleMonth(x, i18n);
                  }}
                />
                <VictoryAxis dependentAxis />
              </VictoryChart>
            )}
            {!isFetchinCheckins && checkinsData.length === 0 && (
              <p className="subtle">{t('workspaceSettingsUsage.noActivity')}</p>
            )}
          </StatsContainer>
          <StatsContainer>
            <h3>
              {t('workspaceSettingsUsage.initiativesHeader', {
                initiatives: translate(workspace, CustomTermKey.INITIATIVE, 2),
              })}
            </h3>
            <p>
              {t('workspaceSettingsUsage.initiativesInfo', {
                initiatives: translate(workspace, CustomTermKey.INITIATIVE, 2),
              })}
            </p>
            {isFetchinInitiativesCreated && <Loader />}
            {!isFetchinInitiativesCreated && initiativesData.length > 0 && (
              <VictoryChart width={600} height={200}>
                <VictoryHistogram data={initiativesData} bins={bins} labels={({ datum }) => datum.y} />
                <VictoryAxis
                  tickValues={months}
                  tickCount={months.length}
                  tickFormat={(x) => {
                    return getLocaleMonth(x, i18n);
                  }}
                />
                <VictoryAxis dependentAxis />
              </VictoryChart>
            )}
            {!isFetchinInitiativesCreated && initiativesData.length === 0 && (
              <p className="subtle">{t('workspaceSettingsUsage.noActivity')}</p>
            )}
          </StatsContainer>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsUsage;
