/**
 * Outcome component
 */
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
//import * as TabilityTypes from '../../../types';
import styled from 'styled-components';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import theme from 'theme';
// API
import * as remoteApi from 'api/remote';

// Actions
import {
  editorSetDisplayNewBlockForm,
  editorSelectBlock,
  editorDeleteOutcome,
  setLoadingOutcomeMetric,
} from 'state/actions/workspaceEditorActions';

// UI Components
import BlockGrid, { BlockGutter, BlockContent, BlockMeta } from './BlockGrid';
import BlockLabel from './BlockLabel';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import DropdownMenu from '../shared/DropdownMenu';

import InitiativeBlock from './InitiativeBlock';
import OutcomeBlockMeta from './OutcomeBlockMeta';
import OutcomeTitle from './OutcomeTitle';
import OutcomeOrInitiativeForm from './OutcomeOrInitiativeForm';

import Loader from 'components/Loader';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

interface Props {
  outcomeId: string;
  objectiveId: string;
  outcomeIndex: number;
  objectiveIndex: number;
}

const OutcomeWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  font-size: 1.8rem;

  border: ${(props: any) => (props.isDragging ? '2px solid rgba(245, 192, 43, 1)' : '0px solid #fff')};
  position: relative;

  .ai-button {
    background: ${theme.colors.V5};

    &:hover {
      background: ${theme.colors.V10};
    }
  }

  .auto-detect-button {
    width: 20px;
    height: 20px;
    svg {
      width: 12px;
      height: 12px;
      path {
        fill: ${theme.colors.V50};
      }
    }
    span {
      font-size: 15px;
    }
  }
`;

const InnerWrapper = styled.div`
  .add-menu {
    opacity: 0;
    transition: opacity ease 0.2s;
  }

  &:hover {
    .add-menu {
      opacity: 1;
    }
  }
`;

function Outcome(props: Props) {
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { outcomeId, objectiveIndex, outcomeIndex, objectiveId } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [archiveOutcomeMutation, { isLoading: isArchiving }] = useMutation(remoteApi.archiveOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
    },
  });

  const [adjustOutcomeMetricMutation, { isLoading: metricLoading }] = useMutation(remoteApi.updateOutcomeMetric, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.currentPing);
      dispatch(setLoadingOutcomeMetric(outcomeId, false));
    },
    onError: (e: AxiosError) => {
      dispatch(setLoadingOutcomeMetric(outcomeId, false));
      const message = t(`workspacePlan.write.metricError`) ?? 'Unable to detect metric';
      window.alert(message);
    },
  });

  const archiveOutcome = () => {
    const params = {
      archived: true,
    };
    const mutationParams = {
      outcomeId,
      outcome: params,
    };
    archiveOutcomeMutation(mutationParams);
  };

  const hasOutcome = useSelector((state: any) => state.editorEntities.outcomes[outcomeId]);

  const initiativeIds = useSelector((state: any) => state.editorEntities.outcomesToInitiativesMapping[outcomeId] || []);

  const blockId = `outcome:${outcomeId}`;
  const isSelected = useSelector((state: any) => state.editorUI.selectedBlockId === blockId);
  // Block form if (1) the block is selected AND (2) the displayBlockForm flag is true
  const displayForm = useSelector((state: any) => {
    return isSelected && state.editorUI.displayNewBlockForm;
  });

  const insightErrors = useSelector((state: any) => {
    if (!state.editorUI.scorecard) {
      return null;
    }

    return state.editorUI.scorecard.insights.blockInsights[blockId];
  });

  const [defaultFormEntityType, setDefaultFormEntityType] = useState('outcome');

  // Extract the review comments
  const reviewCommentsCount = useSelector((state: any) => {
    if (state.editorEntities.reviewCommentsByBlockId[blockId]) {
      const reviewComments = state.editorEntities.reviewCommentsByBlockId[blockId];
      let count = 0;
      reviewComments.forEach((reviewComment: any) => {
        count = count + 1 + reviewComment.review_replies_count; // count the initial comment and all replies;
      });
      return count;
    }
    return 0;
  });

  if (!hasOutcome) {
    return null;
  }

  const selectedClass = isSelected ? 'selected' : '';

  const handleClick = (e: any) => {
    dispatch(editorSelectBlock(blockId));
  };

  const handleDelete = () => {
    const confirmation =
      t('workspacePlan.write.deleteConfirm', { label: outcomeLabel }) ??
      `Are you sure that you want to delete this ${outcomeLabel}?`;

    if (window.confirm(confirmation)) {
      dispatch(editorDeleteOutcome(outcomeId));
    }
  };

  const handleArchive = () => {
    const confirmation =
      t('workspacePlan.write.archiveConfirm', { label: outcomeLabel }) ??
      `Are you sure that you want to archive this ${outcomeLabel}?`;

    if (window.confirm(confirmation)) {
      archiveOutcome();
    }
  };

  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();

  const handlePlusSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'show-objective-form':
        dispatch(editorSelectBlock(`objective:${objectiveId}`));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
      case 'show-outcome-form':
        setDefaultFormEntityType('outcome');
        dispatch(editorSelectBlock(blockId));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
      case 'show-initiative-form':
        setDefaultFormEntityType('initiative');
        dispatch(editorSelectBlock(blockId));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
    }
  };

  const handleDragSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'edit':
        handleEdit();
        break;
      case 'detect':
        handleAutoDetectMetric();
        break;
      case 'comment':
        handleCreateComment();
        break;
      case 'archive':
        handleArchive();
        break;
      case 'delete':
        handleDelete();
        break;
    }
  };

  const handleEdit = () => {
    const hashPath = `#outcome:${hasOutcome.nano_slug}:edit`;
    history.push(hashPath);
  };

  const handleCreateComment = () => {
    const hashPath = `#editor:${blockId}:comment`;
    history.push(hashPath);
  };

  const handleAutoDetectMetric = () => {
    dispatch(setLoadingOutcomeMetric(outcomeId, true));
    adjustOutcomeMetricMutation({ outcomeId });
  };

  const plusMenuItems = [
    <span data-action="show-objective-form">
      {t('workspacePlan.write.addObjective', {
        label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
      })}
    </span>,
    <span data-action="show-outcome-form">
      {t('workspacePlan.write.addObjective', {
        label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase(),
      })}
    </span>,
    <span data-action="show-initiative-form">
      {t('workspacePlan.write.addObjective', {
        label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
      })}
    </span>,
  ];

  const dragMenuItems = [
    <span data-action="edit">{t('shared.edit')}</span>,
    <span data-action="detect">{t('workspacePlan.write.autoDetectMetric')} ✨</span>,
    <span data-action="comment">{t('workspacePlan.write.addComment')}</span>,
    <span data-action="archive">{t('shared.archive')}</span>,
    <span data-action="delete">{t('shared.delete')}</span>,
  ];

  const commentedClass = reviewCommentsCount > 0 ? 'commented' : '';
  const joyrideSelector = `outcome-${objectiveIndex}-${outcomeIndex}`;
  const outcomeIdClass = `outcome:${outcomeId}`;
  return (
    <Draggable draggableId={blockId} index={props.outcomeIndex} key={blockId}>
      {(provided, snapshot) => {
        return (
          <OutcomeWrapper ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <InnerWrapper>
              <BlockGrid
                className={`editor-block  ${selectedClass} ${insightErrors} ${commentedClass} ${joyrideSelector} ${outcomeIdClass}`}
                onClick={handleClick}
              >
                <BlockGutter>
                  {isArchiving && <Loader size="small" />}
                  <DropdownMenu
                    trigger={
                      <KoalaIconButton
                        iconName="plus"
                        edge="square"
                        size="small"
                        className="icon-button"
                        iconColor={theme.colors.N40}
                        dataFor={`${blockId}-plus-menu`}
                        onClick={handleClick}
                      />
                    }
                    onSelection={handlePlusSelection}
                    items={plusMenuItems}
                  />
                  <DropdownMenu
                    trigger={
                      <KoalaIconButton
                        iconName="grab"
                        edge="square"
                        size="small"
                        className="icon-button"
                        dataFor={`${blockId}-drag-menu`}
                        onClick={handleClick}
                      />
                    }
                    onSelection={handleDragSelection}
                    items={dragMenuItems}
                  />
                  <ReactTooltip
                    place="bottom"
                    type="dark"
                    id={`${blockId}-plus-menu`}
                    className="tooltip"
                    effect="solid"
                    delayShow={200}
                  >
                    {t('workspacePlan.write.addTooltip')}
                  </ReactTooltip>
                  <ReactTooltip
                    place="bottom"
                    type="dark"
                    id={`${blockId}-drag-menu`}
                    className="tooltip"
                    effect="solid"
                    html={true}
                    delayShow={200}
                  >
                    {t('workspacePlan.write.dragTooltip')}
                  </ReactTooltip>
                  <BlockLabel className="outcome">#</BlockLabel>
                </BlockGutter>
                <BlockContent className="outcome">
                  <OutcomeTitle outcomeId={outcomeId} />
                </BlockContent>
                <BlockMeta className="meta">
                  <OutcomeBlockMeta
                    outcomeId={outcomeId}
                    reviewCommentsCount={reviewCommentsCount}
                    joyrideSelector={joyrideSelector}
                    metricLoading={metricLoading}
                  />
                </BlockMeta>
              </BlockGrid>
              <Droppable droppableId={`outcome:${outcomeId}`} type="TASKS">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      padding: '0.1rem 0',
                      backgroundColor: snapshot.isDraggingOver ? 'rgba(244, 205, 201, 0.4)' : '#fff',
                    }}
                  >
                    {initiativeIds.map((initiativeId: string, index: number) => {
                      return (
                        <InitiativeBlock
                          initiativeId={initiativeId}
                          initiativeIndex={index}
                          outcomeIndex={outcomeIndex}
                          objectiveIndex={objectiveIndex}
                          key={`initiative:${initiativeId}`}
                          outcomeId={outcomeId}
                          objectiveId={objectiveId}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </InnerWrapper>
            {displayForm && (
              <OutcomeOrInitiativeForm defaultFormEntityType={defaultFormEntityType} selectedOutcomeId={outcomeId} />
            )}
          </OutcomeWrapper>
        );
      }}
    </Draggable>
  );
}

export default React.memo(Outcome);
