import theme from 'theme';
import { Workspace } from 'types';

export const getWorkspaceDefaultColor = (workspace: Workspace) => {
  const buttonBg = ['#C5A5EF', '#6ECAD6', '#FF9A3B', '#F4CDC9', '#7E98E6', '#F5C02B', '#8CC1B5'];

  const currentHash = workspace.id.charCodeAt(0) + workspace.id.charCodeAt(1) + workspace.id.charCodeAt(2);
  const colorIndex = currentHash % buttonBg.length;
  return buttonBg[colorIndex];
};

export enum WorkspaceThemeKey {
  'workspaceIconBg',
  'sidebarBg',
  'sidebarActiveBg',
  'sidebarIcon',
  'sidebarText',
  'sidebarBadgeBg',
  'sidebarBadgeText',
  'sidebarAltBg',
}

export type WorkspaceThemeType = { [key in WorkspaceThemeKey]: string | null };

export const getWorkspaceDefaultTheme = (workspace: Workspace) => {
  const color = getWorkspaceDefaultColor(workspace);

  let defaultTheme: { [key in WorkspaceThemeKey]: string } = {
    [WorkspaceThemeKey.workspaceIconBg]: color,
    [WorkspaceThemeKey.sidebarBg]: `#0a5055`,
    [WorkspaceThemeKey.sidebarActiveBg]: `#0b474b`,
    [WorkspaceThemeKey.sidebarIcon]: color,
    [WorkspaceThemeKey.sidebarText]: `${theme.colors.N0}`,
    [WorkspaceThemeKey.sidebarBadgeBg]: `#2b3435`,
    [WorkspaceThemeKey.sidebarBadgeText]: `${theme.colors.N0}`,
    [WorkspaceThemeKey.sidebarAltBg]: `#2e6a6e`,
  };

  return defaultTheme;
};
