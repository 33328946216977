import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import OpenAIIcon from '../_assets/IconOpenAI.png';
import { Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';

interface Props {
  workspace: Workspace;
}

function OpenAIIntegration(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={OpenAIIcon} alt="OpenAI" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.openAIHeader')}</h3>
        <p>{t('workspaceSettingsIntegrations.openAIInfo')}</p>
        <p>
          <a href="https://guides.tability.app/docs/integrations/openai" target="_blank" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        {workspace.open_ai_enabled && (
          <ActionBlock>
            <div>{t('workspaceSettingsIntegrations.openAIEnabled')}</div>
            <KoalaTextButton size="small" to={`/${workspace.slug}/settings/privacy`} isLink>
              {t('workspaceSettingsIntegrations.manageIntegration')}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!workspace.open_ai_enabled && (
          <ActionBlock>
            <div>{t('workspaceSettingsIntegrations.openAIDisabled')}</div>
            <KoalaTextButton size="small" to={`/${workspace.slug}/settings/privacy`} isLink>
              {t('workspaceSettingsIntegrations.manageIntegration')}
            </KoalaTextButton>
          </ActionBlock>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default OpenAIIntegration;
