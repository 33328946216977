import ShareSelection from 'components/ShareSelection';
import KoalaButton from 'koala/components/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { Plan } from 'types';
import * as remoteApi from 'api/remote';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};

  .send-btn {
    width: fit-content;
  }

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    line-height: 1.4;
    font-size: 1.4rem;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    border-radius: 4px;
    border: 1px solid ${theme.colors.N20};
    width: 100%;
    resize: none;
  }

  .success {
    color: ${theme.colors.G80};
    background: ${theme.colors.G10};
    padding: ${theme.spacing.x1};
    border-radius: 4px;
  }
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

interface Props {
  plan: Plan;
}

function ShareEmail(props: Props) {
  const { plan } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [userList, setUserList] = useState<string[]>([]);
  const [message, setMessage] = useState('');
  const [shareSuccessful, setShareSuccessful] = useState(false);

  useEffect(() => {
    setShareSuccessful(false);
  }, [userList]);

  const [sharePlanMutation, { isLoading: isShareByEmailsLoading }] = useMutation(remoteApi.sharePlan, {
    onSuccess: () => {
      setShareSuccessful(true);
    },
  });

  const planShareLink = `https://${process.env.REACT_APP_DOMAIN}${location.pathname}`;

  const handleShareByEmail = () => {
    const mutationParams = {
      planId: plan.nano_slug,
      share: {
        list: userList,
        message,
        link: planShareLink,
      },
    };
    sharePlanMutation(mutationParams);
  };

  const emailInputPlaceholder = t('modals.share.emailInputPlaceholder') ?? 'e.g Ava Andrews or ava@acme.co';
  const emailMessagePlaceholder = t('modals.share.emailMessagePlaceholder') ?? 'Anything else they should know';

  return (
    <Container>
      <h3>{t('modals.share.emailTitle')}</h3>

      <InputSection>
        <label>{t('modals.share.emailInput')}</label>
        <ShareSelection userList={userList} setUserList={setUserList} placeholder={emailInputPlaceholder} />
      </InputSection>

      <InputSection>
        <label>{t('modals.share.emailMessage')}</label>
        <textarea
          placeholder={emailMessagePlaceholder}
          rows={3}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </InputSection>
      {!shareSuccessful && (
        <Actions>
          <KoalaButton
            className="send-btn"
            disabled={userList.length === 0 || isShareByEmailsLoading}
            onClick={handleShareByEmail}
            loading={isShareByEmailsLoading}
          >
            {t('shared.share')}
          </KoalaButton>
        </Actions>
      )}
      {shareSuccessful && <div className="success">{t('shared.shared')}</div>}
    </Container>
  );
}

export default ShareEmail;
