import React from 'react';
import { useParams } from 'react-router-dom';

// Components
import CopyTextInput from 'components/CopyTextInput';
import FormField from 'components/FormField';
import { Plan } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
  plan: Plan;
}

function DeletePlan(props: Props) {
  const { plan } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const translationKey = 'modals.share';
  const embedUrl = `https://${process.env.REACT_APP_DOMAIN}/${workspaceSlug}/embed/plan/${plan.nano_slug}`;
  const iframeCode = `<iframe width="700" height="800" src="${embedUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;

  if (!plan.public_access_enabled) {
    return <>{t(`${translationKey}.publicAccessRequired`)}</>;
  }

  return (
    <>
      <FormField>
        <label>{t(`${translationKey}.iframeCode`)}</label>
        <p className="subtle">{t(`${translationKey}.iframeDescription`, { label: 'plan' })}</p>
        <CopyTextInput text={iframeCode} />
      </FormField>
      <FormField>
        <label>{t(`${translationKey}.embed`)}</label>
        <p className="subtle">{t(`${translationKey}.embedDescription`)}</p>
        <CopyTextInput text={embedUrl} />
        <small className="subtle">{t(`${translationKey}.embedSizePlan`)}</small>
      </FormField>
    </>
  );
}

export default DeletePlan;
