import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import * as routes from 'routes';
import * as amplitudeUtils from 'utils/amplitude';
import { Helmet } from 'react-helmet';
import * as planUtils from 'utils/planUtils';
import { DEFAULT_PLAN_HIDE_COMPLETED } from 'config/constants';

// API
import * as remoteApi from 'api/remote';
import { workspaceEditorActions } from 'state/actions';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';
import PlanTitle from 'components/PlanTitle';
import PlanIconSelector from 'components/PlanIconSelector';
import ReactTooltip from 'react-tooltip';

// Components
import AccessDenied from 'components/AccessDenied';
import { ChromeContent, ChromeNav } from 'components/Chrome';
import Loader from 'components/Loader';
import NotFound from 'components/NotFound';
import PlanNav from 'components/PlanNav';
import TabbyGood from './tabby_good.png';
import KoalaToggleButton from 'koala/components/ToggleButton';

// Editor components
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import CalendarView from './CalendarView';
import RadioField from 'components/RadioField';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import KanbanView from './KanbanView';
import ListingView from './ListingView';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const EmptyState = styled.div`
  padding: ${theme.spacing.x3} ${theme.spacing.x4} 0 ${theme.spacing.x8};
`;

const EmptySection = styled.div`
  margin-bottom: ${theme.spacing.x6};
  background: #f3f3f3;
  border-radius: 4px;
  padding: ${theme.spacing.x3};
  text-align: center;
  h3 {
    margin: 0 0 ${theme.spacing.x1} 0;
  }

  a {
    color: ${theme.colors.blue};
  }
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupBanner = styled.div`
  width: 50rem;
  max-width: 50rem;
  background: #fff;
  padding: ${theme.spacing.x4};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};

  img {
    width: 15rem;
  }

  p {
    b {
      font-size: 2.2rem;
    }
    text-align: center;

    &.tinyimg {
      img {
        width: 8rem;
      }
    }

    &.actions {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        margin: ${theme.spacing.x1};
      }
    }
  }
`;

const PlanTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ViewToggle = styled.div`
  display: flex;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  gap: ${theme.spacing.x2};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.N10};

  > div {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x2};
  }
`;

const Content = styled(ChromeContent)`
  overflow-x: hidden;
`;

const CollapseActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  justify-content: flex-end;
`;

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  height: 2.4rem;
  line-height: 1.6rem;
  color: ${theme.colors.N70};
  margin-right: ${theme.spacing.x1};
`;

function PlanWrite() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { planId, workspaceSlug, weekView } = useParams<{ planId: string; workspaceSlug: string; weekView: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  const storedDefaultHideClosedInitiatives: boolean = localStorage.getItem(DEFAULT_PLAN_HIDE_COMPLETED)
    ? localStorage.getItem(DEFAULT_PLAN_HIDE_COMPLETED) === 'true'
    : true;

  const [hideClosedInitiatives, setHideClosedInitiatives] = useState(storedDefaultHideClosedInitiatives);
  const { t } = useTranslation();

  const toggleHideInitiatives = () => {
    setHideClosedInitiatives((prev) => !prev);
  };

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plan.work.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  useEffect(() => {
    localStorage.setItem(DEFAULT_PLAN_HIDE_COMPLETED, JSON.stringify(hideClosedInitiatives));
  }, [hideClosedInitiatives]);

  // Construct the query key using the plan Id
  const staleTime = 1000 * 60 * 5; // 5 minutes
  useEffect(() => {
    dispatch(workspaceEditorActions.resetPlan());
    return () => {
      dispatch(workspaceEditorActions.resetPlan());
    };
  }, [dispatch]);

  // Get the plan details
  const {
    isError,
    error,
    data: planResponse,
  }: any = useQuery([queryKeys.currentPlan, planId], remoteApi.fetchPlanDetails, {
    staleTime: 0,
  });

  useEffect(() => {
    if (planResponse && planResponse.data) {
      dispatch(workspaceEditorActions.loadPlan(planResponse.data));
    }
  }, [planResponse, dispatch]);

  // Get the objectives
  const { data: objectivesResponse } = useQuery([queryKeys.objectives, planId], remoteApi.fetchPlanObjectives, {
    staleTime,
  });

  useEffect(() => {
    if (objectivesResponse && objectivesResponse.data) {
      dispatch(workspaceEditorActions.loadObjectives(objectivesResponse.data));
    }
  }, [objectivesResponse, dispatch]);

  // Get the outcomes
  const { data: outcomesResponse } = useQuery([queryKeys.outcomes, planId], remoteApi.fetchPlanOutcomes, {
    staleTime,
  });

  useEffect(() => {
    if (outcomesResponse && outcomesResponse.data) {
      dispatch(workspaceEditorActions.loadOutcomes(outcomesResponse.data));
    }
  }, [outcomesResponse, dispatch]);

  const { data: initiativesResponse } = useQuery([queryKeys.initiatives, planId], remoteApi.fetchPlanInitiatives, {
    staleTime,
  });

  useEffect(() => {
    if (initiativesResponse && initiativesResponse.data) {
      dispatch(workspaceEditorActions.loadInitiatives(initiativesResponse.data));
    }
  }, [initiativesResponse, dispatch]);

  const plan = planResponse ? planResponse.data : null;
  const outcomesCount = outcomesResponse ? outcomesResponse.data.length : null;

  // Now we can display the app with the Chrome
  if (!plan || outcomesCount === null) {
    return (
      <Fragment>
        <ChromeContent>
          <LoadingContainer>
            <Loader size="big" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);

  // Display empty state if we don't have objectives
  if (plan.archived) {
    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | {t('workspacePlan.initiatives.title', { title: plan.title })} | Tability
          </title>
        </Helmet>
        <MobileReadyChromeHeader className="no_border">
          <WorkspaceHeader
            workspace={currentWorkspace}
            useGreyBackground={true}
            title={
              <PlanTitleContainer>
                <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  id="planIconSelector"
                  className="tooltip"
                  effect="solid"
                  delayShow={200}
                >
                  {t('workspacePlan.planIcon')}
                </ReactTooltip>
                <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
              </PlanTitleContainer>
            }
          />
        </MobileReadyChromeHeader>
        <ChromeNav>
          <PlanNav plan={plan} />
        </ChromeNav>
        <ChromeContent>
          <EmptyState>
            <EmptySection>
              <h3>{t('workspacePlan.archived')}</h3>
            </EmptySection>
          </EmptyState>
        </ChromeContent>
      </Fragment>
    );
  }

  // Display empty state if we don't have objectives
  if (outcomesCount === 0) {
    const writeRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
      ':planId',
      planId,
    );
    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | {t('workspacePlan.initiatives.title', { title: plan.title })} | Tability
          </title>
        </Helmet>
        <MobileReadyChromeHeader className="no_border">
          <WorkspaceHeader
            workspace={currentWorkspace}
            useGreyBackground={true}
            title={
              <PlanTitleContainer>
                <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  id="planIconSelector"
                  className="tooltip"
                  effect="solid"
                  delayShow={200}
                >
                  {t('workspacePlan.planIcon')}
                </ReactTooltip>
                <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
              </PlanTitleContainer>
            }
          />
        </MobileReadyChromeHeader>
        <ChromeNav>
          <PlanNav plan={plan} />
        </ChromeNav>
        <ChromeContent isGreyBackground>
          <EmptyState>
            <EmptySection>
              <h3>{t('workspacePlan.empty')}</h3>
              {hasEditPermission && (
                <p>
                  <Link to={writeRoute}>{t('workspacePlan.addGoals')}</Link>
                </p>
              )}
            </EmptySection>
          </EmptyState>
        </ChromeContent>
      </Fragment>
    );
  }

  // Display empty state if plan is still in draft mode
  if (plan.state === 'draft') {
    const writeRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
      ':planId',
      planId,
    );

    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | {t('workspacePlan.initiatives.title', { title: plan.title })} | Tability
          </title>
        </Helmet>
        <MobileReadyChromeHeader className="no_border">
          <WorkspaceHeader
            workspace={currentWorkspace}
            useGreyBackground={true}
            title={
              <PlanTitleContainer>
                <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  id="planIconSelector"
                  className="tooltip"
                  effect="solid"
                  delayShow={200}
                >
                  {t('workspacePlan.planIcon')}
                </ReactTooltip>
                <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
              </PlanTitleContainer>
            }
          />
        </MobileReadyChromeHeader>
        <ChromeNav>
          <PlanNav plan={plan} />
        </ChromeNav>
        <ChromeContent className="mask">
          <KanbanView plan={plan} />
          <Mask>
            <PopupBanner>
              <p>
                <img src={TabbyGood} alt="Your plan ready to track" />
              </p>
              <p>
                <b>{t('workspacePlan.draft.title')}</b>
              </p>
              <p className="subtle">{t('workspacePlan.draft.description')}</p>
              {hasEditPermission && (
                <p className="actions">
                  <KoalaButton onClick={() => history.push(writeRoute)}>{t('workspacePlan.draft.edit')}</KoalaButton>
                </p>
              )}
            </PopupBanner>
          </Mask>
        </ChromeContent>
      </Fragment>
    );
  }

  // Displays the list of templates
  const viewType = weekView ?? 'kanban';
  const changeView = (type: string) => {
    let route;
    if (type === 'kanban') {
      route = `/${workspaceSlug}/plans/${planId}/tasks`;
    } else if (type === 'listing') {
      route = `/${workspaceSlug}/plans/${planId}/tasks/listing`;
    } else {
      route = `/${workspaceSlug}/plans/${planId}/tasks/timeline`;
    }
    history.push(route);
  };
  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspacePlan.initiatives.title', { title: plan.title })} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader className="no_border">
        <WorkspaceHeader
          workspace={currentWorkspace}
          useGreyBackground={true}
          title={
            <PlanTitleContainer>
              <PlanIconSelector plan={plan} id="planIconSelector" hasEditPermission={hasEditPermission} />
              <ReactTooltip
                place="bottom"
                type="dark"
                id="planIconSelector"
                className="tooltip"
                effect="solid"
                delayShow={200}
              >
                {t('workspacePlan.planIcon')}
              </ReactTooltip>
              <PlanTitle plan={plan} hasEditPermission={hasEditPermission} />
            </PlanTitleContainer>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeNav>
        <PlanNav plan={plan} />
      </ChromeNav>

      <Content>
        <ViewToggle>
          <div>
            <h3>
              {viewType === 'kanban' &&
                t('workspacePlan.initiatives.board', {
                  initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                })}
              {viewType === 'listing' &&
                t('workspacePlan.initiatives.listing', {
                  initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                })}
              {viewType === 'timeline' &&
                t('workspacePlan.initiatives.timeline', {
                  initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                })}
            </h3>
            <RadioField className="compact">
              <div className="radio-group compact">
                <>
                  <input
                    type="radio"
                    id="kanban-view"
                    checked={viewType === 'kanban'}
                    name="kanban-view"
                    value={'false'}
                    onChange={() => changeView('kanban')}
                  />
                  <label htmlFor="kanban-view" className="light" data-tip data-for="kanban-view">
                    {t('workspacePlan.initiatives.radioBoard')}
                  </label>
                </>
                <>
                  <input
                    type="radio"
                    id="listing-view"
                    checked={viewType === 'listing'}
                    name="listing-view"
                    value={'false'}
                    onChange={() => changeView('listing')}
                  />
                  <label htmlFor="listing-view" className="light" data-tip data-for="listing-view">
                    {t('workspacePlan.initiatives.radioListing')}
                  </label>
                </>
                <>
                  <input
                    type="radio"
                    id="timeline-view"
                    checked={viewType === 'timeline'}
                    name="timeline-view"
                    value={'true'}
                    onChange={() => changeView('timeline')}
                  />
                  <label htmlFor="timeline-view" className="light" data-tip data-for="timeline-view">
                    {t('workspacePlan.initiatives.radioTimeline')}
                  </label>
                </>
              </div>
            </RadioField>
            {viewType === 'listing' && (
              <CollapseActions>
                <ToggleButton>
                  <KoalaToggleButton
                    handleChange={toggleHideInitiatives}
                    size="small"
                    defaultChecked={hideClosedInitiatives}
                    id="hide-completed-initiatives-plan-work"
                  />
                  {t('workspacePlan.hideCompleted', {
                    initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                  })}
                </ToggleButton>
              </CollapseActions>
            )}
          </div>
        </ViewToggle>

        {viewType === 'kanban' && <KanbanView plan={plan} />}
        {viewType === 'listing' && <ListingView plan={plan} hideClosedInitiatives={hideClosedInitiatives} />}
        {viewType === 'timeline' && <CalendarView plan={plan} />}
      </Content>
    </Fragment>
  );
}

export default PlanWrite;
