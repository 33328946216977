import queryKeys from 'config/queryKeys';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import React from 'react';
import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import { Outcome, Plan, Workspace } from 'types';
import { ValueType } from 'react-select';
import KoalaColorBlock from 'koala/components/ColorBlock';
import { confidenceToColor } from 'utils/checkinUtils';
import styled from 'styled-components';
import theme from 'theme';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const OutcomeRow = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;

interface Props {
  setSelectedOutcome: (value: any) => void;
  selectedOutcome: KoalaSelectOption | null;
  currentOutcome: Outcome;
  workspace: Workspace;
  plan: Plan;
}

function OutcomeSelection(props: Props) {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { plan, setSelectedOutcome, selectedOutcome, currentOutcome, workspace } = props;
  // Get the objectives
  const staleTime = 0;
  const { data: outcomesResponse, isLoading: outcomesAreLoading } = useQuery(
    [queryKeys.outcomes, plan.id],
    remoteApi.fetchPlanOutcomes,
    {
      staleTime,
    },
  );
  const filteredOutcomes: Outcome[] = outcomesResponse ? outcomesResponse.data : [];
  let outcomes: KoalaSelectOption[] = filteredOutcomes
    .filter((outcome) => outcome.id !== currentOutcome.id)
    .map((outcome: Outcome) => {
      return { label: outcome.title, value: outcome.id, meta: outcome };
    });

  const handleSelection = (selected: ValueType<KoalaSelectOption, boolean>) => {
    setSelectedOutcome(selected as KoalaSelectOption);
  };

  if (outcomesAreLoading) {
    return <KoalaLoader />;
  }

  const label = translate(workspace, CustomTermKey.OUTCOME, 1).toLowerCase();

  return (
    <KoalaSelect
      isClearable={true}
      handleChange={handleSelection}
      placeholder={t(`modals.addDependency.selectOutcome`, { label })}
      selectedOption={selectedOutcome}
      options={outcomes}
      labelFormat={(option) => {
        const outcome: Outcome = option.meta;
        const current = outcome.current_checkin;
        return (
          <OutcomeRow>
            <KoalaColorBlock color={confidenceToColor(current?.confidence)} />
            {option.label}
          </OutcomeRow>
        );
      }}
    />
  );
}

export default OutcomeSelection;
