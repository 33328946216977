import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useSelector, shallowEqual } from 'react-redux';

// API
import * as remoteApi from 'api/remote';

// Components
import AccessDenied from 'components/AccessDenied';
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import Loader from 'components/Loader';
import NotFound from 'components/NotFound';
import PlanSettingsNav from 'components/PlanSettingsNav';
import Settings from './Settings';
import { useTranslation } from 'react-i18next';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

function PlanWrite() {
  const { planId } = useParams<{ planId: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, planId];
  const staleTime = 0;

  // Get the plan details
  const {
    isError,
    error,
    data: planResponse,
  }: any = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
  });

  const plan = planResponse ? planResponse.data : null;

  // Now we can display the app with the Chrome
  if (!plan) {
    return (
      <Fragment>
        <ChromeContent>
          <LoadingContainer>
            <Loader size="medium" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  // Displays the list of templates
  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('planSettings.title', { title: plan.title })} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <PlanSettingsNav plan={plan} />
      </ChromeSideNav>
      <ChromeContent>
        <Settings plan={plan} />
      </ChromeContent>
    </Fragment>
  );
}

export default PlanWrite;
