import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

// Components

import AsyncCreatableSelect from 'react-select/async-creatable';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaButton from 'koala/components/Button';
import { Objective } from 'types';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  align-items: center;
  .tag-select {
    flex: 1;
  }

  .tag-select-control {
    height: 30px;

    .tag-select-placeholder {
      line-height: 26px;
    }

    .tag-select-value {
      line-height: 26px !important;

      .tag-select-value-label {
        line-height: 26px;
      }
    }

    .tag-select-input {
      height: 26px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  width: 100%;
  align-items: center;
`;

interface Props {
  objective: Objective;
  setDisplayPicker: (displayPicker: boolean) => void;
}

function TagPicker(props: Props) {
  const queryCache = useQueryCache();

  const { objective, setDisplayPicker } = props;
  const { t } = useTranslation();
  const [tag_list, setTagList] = useState(objective.cached_tag_list);
  const tagList = objective.cached_tag_list || '';
  const tagListArray = tagList.length > 0 ? tagList.split(',') : [];
  const currentTagsForSelect = tagListArray.map((tag) => {
    return {
      label: tag.trim(),
      value: tag.trim(),
    };
  });

  // Functions to update the initiative
  const [updateObjectiveMutation]: [any, any] = useMutation(remoteApi.updateObjective, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.objectives);
      queryCache.invalidateQueries(queryKeys.currentObjective);
      setDisplayPicker(false);
    },
  });

  const handleSave = (e: any) => {
    e.preventDefault();
    const params = {
      tag_list,
    };

    updateObjectiveMutation({
      objectiveId: objective.id,
      objective: params,
    });
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    setDisplayPicker(false);
  };

  const handleLoadTags = (input: string) => {
    let tagParams;
    if (input) {
      tagParams = {
        search: input,
      };
    } else {
      tagParams = {};
    }
    return remoteApi.fetchTags(queryKeys.tags, objective.workspace_id, tagParams, 1).then((response) => {
      const tags = response.data;
      const options = tags.map((tag: any) => ({
        label: tag.name,
        value: tag.name,
      }));
      return options;
    });
  };

  const handleSelectChange = (newValue: any) => {
    const tagList = newValue.map((option: any) => option.value).join(',');
    setTagList(tagList);
  };

  const customStyles = {
    control: (base: any) => ({
      ...base,
      minHeight: 30,
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base: any) => ({
      ...base,
      padding: 4,
    }),
    multiValue: (base: any) => ({
      ...base,
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: '0px 6px',
    }),
    input: (base: any) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
  };

  const placeholder = t('shared.addTag');
  return (
    <Container>
      <AsyncCreatableSelect
        className="tag-select"
        styles={customStyles}
        isClearable
        placeholder={placeholder}
        isMulti
        defaultOptions
        allowCreateWhileLoading={false}
        loadOptions={handleLoadTags}
        onChange={handleSelectChange}
        defaultValue={currentTagsForSelect}
      />
      <Actions>
        <KoalaButton onClick={handleSave} size="small">
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel} size="small">
          {t('shared.cancel')}
        </KoalaTextButton>
      </Actions>
    </Container>
  );
}

export default React.memo(TagPicker);
