import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

// This component will load the memberships from the user and redirect to the appropriate workspace if it exists.
// If no workspace has been found, will redirect to the workspace creation screen

function RequireAdminStatus() {
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  if (!currentMembership || currentMembership.role !== 'owner') {
    return <Redirect to={`/${currentWorkspace.slug}`} />;
  }

  return null;
}

export default RequireAdminStatus;
