/**
 * Outcome component
 */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

// UI Components
import AddPersonIcon from 'components/_assets/AddPersonIcon';

import CommentButton from './CommentButton';
import CommentIcon from 'components/_assets/CommentIcon';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaAvatar from 'koala/components/Avatar';
import OutcomeBlockTarget from './OutcomeBlockTarget';
import OutcomeWeight from 'components/OutcomeWeight';

interface Props {
  outcomeId: string;
  joyrideSelector: string;
  reviewCommentsCount: number;
  metricLoading: boolean;
}

const AddOwnerButton = styled.div`
  height: 2rem;
  width: 2rem;
  position: relative;
  bottom: 4px;

  background-color: #e7e7e7;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  svg {
    width: 2rem;
    height: 2rem;
    g {
      stroke: ${theme.colors.subtleText};
    }
  }

  &:hover {
    background: #d1d1d1;
  }

  @media ${theme.devices.desktop} {
    margin-right: 1.6rem;
  }
`;

function Outcome(props: Props) {
  const history = useHistory();
  const { outcomeId, reviewCommentsCount, joyrideSelector, metricLoading } = props;
  const blockId = `outcome:${outcomeId}`;
  const queryCache = useQueryCache();

  const outcome = useSelector((state: any) => state.editorEntities.outcomes[outcomeId]);

  const [updateOutcomeMutation]: [any, any] = useMutation(remoteApi.updateOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
    },
  });

  if (!outcome) {
    return null;
  }

  const handleAssignOwner = (e: any) => {
    const membership_id = e;

    if (membership_id) {
      const params = {
        membership_id,
      };

      updateOutcomeMutation({
        outcomeId,
        outcome: params,
      });
    }
  };

  const handleCreateComment = () => {
    const hashPath = `#editor:${blockId}:comment`;
    history.push(hashPath);
  };

  const { membership } = outcome;

  const commentClass = reviewCommentsCount > 0 ? 'has-comments' : 'masked-icon';
  return (
    <Fragment>
      <div className={`${joyrideSelector}-owner`}>
        {membership && (
          <UserSearchableDropdown
            trigger={
              <AddOwnerButton>
                <KoalaAvatar user={membership.user} size={2} />
              </AddOwnerButton>
            }
            callback={handleAssignOwner}
          />
        )}
        {!membership && (
          <UserSearchableDropdown
            trigger={
              <AddOwnerButton>
                <AddPersonIcon />
              </AddOwnerButton>
            }
            callback={handleAssignOwner}
          />
        )}
      </div>
      <OutcomeBlockTarget outcome={outcome} selector={joyrideSelector} metricLoading={metricLoading} />
      <OutcomeWeight outcome={outcome} />
      <div className="spacer" />
      <CommentButton className={commentClass} onClick={handleCreateComment}>
        <div className="icon">
          <CommentIcon />
        </div>
        <div className="value">{reviewCommentsCount > 0 && <Fragment>{reviewCommentsCount}</Fragment>}</div>
      </CommentButton>
    </Fragment>
  );
}

export default React.memo(Outcome);
