import React from 'react';
import styled from 'styled-components';
import { Outcome } from 'types';
import * as checkinUtils from 'utils/checkinUtils';
import * as outcomeUtils from 'utils/outcomeUtils';
import theme from 'theme';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  flex-direction: column;
`;
const LargeText = styled.div<{ fontColor: string }>`
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 1.2;
  align-items: center;
  justify-content: center;
  color: ${({ fontColor }) => fontColor};
`;

const ValueLabel = styled.div`
  color: ${theme.colors.subtleText};
  font-size: 1.8rem;
`;

interface Props {
  outcome: Outcome;
}

function OutcomeValue(props: Props) {
  const { outcome } = props;
  const { current_checkin, outcome_type, score_format } = outcome;
  const { t } = useTranslation();

  let format = ['', ''];
  if (score_format) {
    format = score_format?.split('_number_');
  }

  let score = 'Pending';
  let showFormat = false;
  let confidenceColor = theme.colors.N70;

  if (current_checkin && outcome_type === 'no_metric') {
    // if has current checkin and no metric, use confidence
    score = checkinUtils.confidenceToScore(current_checkin.confidence, t);
    confidenceColor = checkinUtils.confidenceToValueColor(current_checkin.confidence);
  } else if (current_checkin) {
    // if has current checkin and uses metric, get score
    score = outcomeUtils.nFormatter(current_checkin.score, 2);
    confidenceColor = checkinUtils.confidenceToValueColor(current_checkin.confidence);
    if (outcome_type === 'kpi') {
      confidenceColor = 'blue';
    }
    showFormat = true;
  } else if (!current_checkin && outcome_type !== 'no_metric') {
    // if no checkin yet and has metric, get starting value
    score = outcomeUtils.nFormatter(outcome.from || 0, 2);
    showFormat = true;
  }

  return (
    <Container>
      <LargeText fontColor={confidenceColor}>
        {showFormat && format[0]}
        {score}
      </LargeText>
      {showFormat && format[1] && <ValueLabel>{format[1]}</ValueLabel>}
    </Container>
  );
}

export default OutcomeValue;
