import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Workspace } from 'types';
import * as workspaceThemeUtils from 'utils/workspaceThemeUtils';

const Container = styled.div<{ bgImage: string | null }>`
  color: ${theme.colors.N0};
  background-color: ${(props: any) => props.color || '#000'};
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 4px;

  background-image: ${(props) => props.bgImage};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  &.theme_icon--small {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    border-radius: 4px;
  }
  &.theme_icon--large {
    width: 6.4rem;
    min-width: 6.4rem;
    height: 6.4rem;
    border-radius: 8px;
  }

  &.theme_icon--xlarge {
    width: 8rem;
    min-width: 8rem;
    height: 8rem;
    border-radius: 8px;
    font-size: 3rem;
  }
`;

interface Props {
  workspace: Workspace;
  overrideColor?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  onClick?: () => void;
}

function WorkspaceAvatar(props: Props) {
  const { workspace, size, overrideColor } = props;

  // change the workspace image
  const bgImage = workspace.theme_icon_url ? `url(${workspace.theme_icon_url})` : null;

  const currentInitial = !bgImage && workspace.name ? workspace.name.charAt(0).toUpperCase() : '';

  let color = workspaceThemeUtils.getWorkspaceDefaultColor(workspace);
  if (overrideColor) {
    color = overrideColor;
  } else if (workspace.theme_icon_bg) {
    color = workspace.theme_icon_bg;
  }

  const containerClass = `theme_icon--${size ?? 'medium'}`;

  return (
    <Container bgImage={bgImage} color={color} className={containerClass}>
      {currentInitial}
    </Container>
  );
}

export default WorkspaceAvatar;
