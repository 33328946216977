import React from 'react';

const ArrowDownIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 19.25C9.44772 19.25 9 18.8023 9 18.25L9 2.25C9 1.69771 9.44772 1.25 10 1.25C10.5523 1.25 11 1.69771 11 2.25L11 18.25C11 18.8023 10.5523 19.25 10 19.25Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7071 18.9571C10.3166 19.3476 9.68342 19.3476 9.29289 18.9571L1.29289 10.9571C0.902369 10.5666 0.902369 9.93342 1.29289 9.54289C1.68342 9.15237 2.31658 9.15237 2.70711 9.54289L10 16.8358L17.2929 9.54289C17.6834 9.15237 18.3166 9.15237 18.7071 9.54289C19.0976 9.93342 19.0976 10.5666 18.7071 10.9571L10.7071 18.9571Z"
      fill="#737373"
    />
  </svg>
);

export default ArrowDownIcon;
