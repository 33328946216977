import React from 'react';

const HelpIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.99998C6.13402 2.99998 3.00001 6.13399 3.00001 9.99998C3.00001 11.2767 3.34087 12.4707 3.93588 13.4993C4.06876 13.729 4.10488 14.0021 4.03629 14.2585L3.40143 16.6312L5.88785 16.0283C6.13185 15.9691 6.38968 16.0038 6.60921 16.1256C7.61281 16.6825 8.76783 17 10 17C13.866 17 17 13.866 17 9.99998C17 6.13399 13.866 2.99998 10 2.99998ZM5.993 18.0607C7.20063 18.6621 8.5621 19 10 19C14.9706 19 19 14.9705 19 9.99998C19 5.02942 14.9706 0.999985 10 0.999985C5.02945 0.999985 1.00001 5.02942 1.00001 9.99998C1.00001 11.4863 1.36108 12.8909 2.00078 14.1282L1.03399 17.7415C0.942771 18.0825 1.03802 18.4462 1.28463 18.6987C1.53124 18.9512 1.89267 19.055 2.23567 18.9718L5.993 18.0607Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7C9.74574 7 9.51577 7.09359 9.33889 7.24968C9.12959 7.43438 9 7.70144 9 8C9 8.55228 8.55228 9 8 9C7.44772 9 7 8.55228 7 8C7 7.10374 7.39428 6.29833 8.01556 5.75008L8.67723 6.49988L8.01556 5.75008C8.54378 5.28395 9.23995 5 10 5C11.6569 5 13 6.34315 13 8C13 9.13705 12.367 10.1248 11.439 10.6328C11.2649 10.7281 11.1336 10.8316 11.0569 10.9211C11.017 10.9675 11.0038 10.9953 11 11.0061C10.9967 11.5555 10.5502 11.9999 10 11.9999C9.44774 11.9999 9.00002 11.5522 9.00001 10.9999C9 10.4196 9.25275 9.9525 9.53871 9.61907C9.82032 9.29072 10.1685 9.0482 10.4787 8.87838C10.7915 8.70718 11 8.37722 11 8C11 7.44772 10.5523 7 10 7ZM10.9987 11.0113C10.9985 11.0114 10.9987 11.01 10.9996 11.0073C10.9992 11.01 10.9988 11.0113 10.9987 11.0113Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15Z"
      fill="#737373"
    />
  </svg>
);

export default HelpIcon;
