import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextBadge from 'koala/components/TextBadge';
import React from 'react';
import theme from 'theme';
import { Team, Workspace } from 'types';

interface Props {
  team: Team;
  workspace: Workspace;
  isLink?: boolean;
  handleUnassign?: (e: React.MouseEvent) => void;
}

function TeamLabel(props: Props) {
  const { team, workspace, isLink, handleUnassign } = props;

  const background = team.label_bg_color ?? theme.colors.N5;
  const text = team.label_text_color ?? theme.colors.N100;

  return (
    <KoalaTextBadge
      backgroundColor={background}
      textColor={text}
      linkTo={isLink ? `/${workspace.slug}/teams/${team.nano_slug}` : undefined}
      suffixIcon={
        handleUnassign ? (
          <KoalaIconButton iconName="close" size="xsmall" onClick={handleUnassign} iconColor={text} />
        ) : undefined
      }
    >
      {team.name}
    </KoalaTextBadge>
  );
}

export default TeamLabel;
