import { AxiosResponse } from 'axios';
import PlanIconLabel from 'components/PlanIconLabel';
import PlanStatus from 'components/PlanStatus';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { WORKSPACE_PLAN_TEMPLATE_ROUTE, WORKSPACE_PLAN_TRACK_ROUTE, WORKSPACE_PLAN_WRITE_ROUTE } from 'routes';
import styled from 'styled-components';
import theme from 'theme';
import { Plan, Workspace } from 'types';
import KoalaNCSPie from 'koala/components/NCSPie';
import * as planUtils from 'utils/planUtils';
import { Trans, useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const PlansLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${theme.spacing.x1};

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const PlanCard = styled(Link)`
  background: #ffffff;
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  border-radius: 4px;
  padding: ${theme.spacing.x2};
  font-weight: 600;
  overflow: hidden;

  &:hover {
    background: ${theme.colors.N3};
    cursor: pointer;
  }
  &:focus,
  &:active {
    background: ${theme.colors.B5};
  }

  &:last_of_type {
    margin-right: 0;
  }
  .plan-title-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .plan-icon {
    margin-bottom: ${theme.spacing.x1};
  }
  position: relative;
  .plan-status {
    position: absolute;
    top: ${theme.spacing.x2};
    right: ${theme.spacing.x2};
  }
`;

const ProgressBlock = styled.div`
  padding-top: ${theme.spacing.x2};
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x2};
  align-items: center;
  justify-content: center;
`;
interface Props {
  plans: AxiosResponse<Plan[]>[];
  workspace: Workspace;
}

function PlanViewCards(props: Props) {
  const { plans, workspace } = props;
  const { t } = useTranslation();

  return (
    <PlansLine>
      {plans.map((group: any, i: number) => {
        return (
          <Fragment key={i}>
            {group.data.map((p: Plan, index: number) => {
              const stateToRouteMapping: any = {
                draft: WORKSPACE_PLAN_WRITE_ROUTE,
                published: WORKSPACE_PLAN_TRACK_ROUTE,
                template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
              };

              const confidenceData = planUtils.getConfidenceData(p);
              const outcomeProgressData = planUtils.getOutcomeProgressData(p);
              const initiativeProgressData = planUtils.getInitiativeProgressData(p);

              let routeMappingState = p.state || 'draft';
              const routeTarget = stateToRouteMapping[routeMappingState];

              const planRoute = routeTarget.replace(':workspaceSlug', workspace.slug).replace(':planId', p.nano_slug);

              return (
                <PlanCard key={p.id} to={planRoute}>
                  <PlanIconLabel
                    plan={p}
                    // @ts-ignore
                    size="large"
                    crop={30}
                  />
                  <div className="plan-status">
                    <PlanStatus plan={p} />
                  </div>
                  <ProgressBlock>
                    <KoalaCircularProgress
                      data={outcomeProgressData}
                      size="small"
                      dataFor={`outcome-progress-${p.nano_slug}`}
                    />
                    <KoalaCircularProgress
                      data={initiativeProgressData}
                      size="small"
                      dataFor={`tasks-completed-${p.nano_slug}`}
                    />
                    <KoalaNCSPie
                      centerLabel={[Math.round(p.ncs).toString(), t('shared.ncs.acronym')]}
                      data={confidenceData}
                      size="small"
                      dataFor={`ncs-${p.nano_slug}`}
                    />
                  </ProgressBlock>
                  <ReactTooltip
                    type="dark"
                    id={`outcome-progress-${p.nano_slug}`}
                    className="tooltip"
                    effect="solid"
                    delayShow={400}
                  >
                    {t('workspacePlan.overallProgress')}
                  </ReactTooltip>
                  <ReactTooltip
                    type="dark"
                    id={`tasks-completed-${p.nano_slug}`}
                    className="tooltip"
                    effect="solid"
                    delayShow={400}
                  >
                    {t('workspacePlan.tasksCompleted', {
                      initiatives: translate(workspace, CustomTermKey.INITIATIVE, 2),
                    })}
                  </ReactTooltip>
                  <ReactTooltip
                    type="dark"
                    id={`ncs-${p.nano_slug}`}
                    className="tooltip"
                    effect="solid"
                    delayShow={400}
                  >
                    <Trans i18nKey="shared.ncs.tooltip" />
                  </ReactTooltip>
                </PlanCard>
              );
            })}
          </Fragment>
        );
      })}
    </PlansLine>
  );
}

export default PlanViewCards;
