import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import ReactTooltip from 'react-tooltip';
import PersonIcon from 'components/_assets/PersonIcon';

const avatarBg = ['#C5A5EF', '#6ECAD6', '#FF9A3B', '#F4CDC9', '#7E98E6', '#F4715D', '#F5C02B', '#8CC1B5'];

const Container = styled.div<{ bgColor: string; bgImage?: string; fontSize: number; avatarSize: number }>`
  background-color: ${(props) => props.bgColor};
  background-image: ${(props) => props.bgImage};
  transition: all 0.1s ease;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: ${(props) => props.fontSize}rem;
  min-height: ${(props) => props.avatarSize}rem;
  height: ${(props) => props.avatarSize}rem;
  width: ${(props) => props.avatarSize}rem;
  min-width: ${(props) => props.avatarSize}rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  ${(props) => (props.onClick ? 'cursor: pointer;' : '')}

  span {
    color: #fff;
    margin: 0;
  }

  &.avatarButton {
    &:hover {
      filter: brightness(0.8);
    }
  }
`;
interface Props {
  className?: string;
  user: TabilityTypes.User;
  size?: number;
  onClick?: any;
}

function AppSidebar(props: Props) {
  const { user, size } = props;

  const avatarSize = size ? size / 10 : 2.8;

  // Setting the font size for the initials
  const fontSize = 0.4 * avatarSize;

  // Setting the defaults
  let avatarImg;
  let avatarInitials = '';
  let name = 'Account deleted';

  if (!user) {
    return (
      <Fragment>
        <Container
          bgColor="#fff"
          bgImage=""
          fontSize={fontSize}
          avatarSize={avatarSize}
          aria-label={name}
          className={props.className}
          onClick={props.onClick}
          date-tip
          data-for="avatar-deleted"
        >
          <PersonIcon />
        </Container>
        <ReactTooltip type="dark" id={`avatar-deleted`} className="tooltip" effect="solid" delayShow={400}>
          {name}
        </ReactTooltip>
      </Fragment>
    );
  }

  if (user && user.avatar_url) {
    avatarImg = `url(${user.avatar_url})`;
  }

  if (user) {
    name = user.fullname || user.email;
  }

  // Use the email to create the initials if the fullname is not define
  if (user && !user.fullname && !user.avatar_url) {
    avatarInitials = user.email.trim().charAt(0).toUpperCase();
  }
  if (user && user.fullname && !user.avatar_url) {
    avatarInitials = user.fullname
      .trim()
      .split(' ')
      .map((x) => x.charAt(0).toUpperCase())
      .slice(0, 2)
      .join('');
  }

  const hash = user
    ? user.id.charCodeAt(0) + user.id.charCodeAt(10) + user.id.charCodeAt(1) + user.id.charCodeAt(2)
    : 10;
  const colorIndex = hash % avatarBg.length;
  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-${user.id}-${randomIndex}`;

  return (
    <Fragment>
      <Container
        bgColor={avatarBg[colorIndex]}
        bgImage={avatarImg}
        fontSize={fontSize}
        avatarSize={avatarSize}
        aria-label={name}
        className={props.className}
        onClick={props.onClick}
        data-tip
        data-for={tooltipId}
      >
        {avatarInitials}
      </Container>
      <ReactTooltip type="dark" id={tooltipId} className="tooltip" effect="solid" delayShow={100}>
        {name}
      </ReactTooltip>
    </Fragment>
  );
}

export default React.memo(AppSidebar);
