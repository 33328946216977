import React from 'react';
import styled from 'styled-components';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import * as ymlEditorActions from 'state/actions/ymlEditorActions';

const parseValueWithFormat = (valueWithFormat: string) => {
  // Here we're parsing the valueWithFormat input to extract the format
  // and the actual target.
  const targetRegex = /^[^-0-9]*((?:-{0,1})\d+(?:,{1}\d+)*(?:\.{1}\d+){0,1}).*$/;

  const matches = valueWithFormat.match(targetRegex);

  let target, score_format;
  if (matches) {
    target = matches[1];
    score_format = matches[0].replace(target, '_number_');
    target = target.replace(',', '');
    target = parseFloat(target);
  } else {
    target = null;
    score_format = null;
  }

  return { target, score_format };
};

const Input = styled.input`
  background: #f3f3f3;

  &:first-of-type {
    width: 30%;
  }
`;

type Props = {
  outcomeId: string;
};

function OutcomeMetrics(props: Props) {
  const dispatch = useDispatch();
  const { outcomeId } = props;

  const outcome = useSelector((state: any) => state.ymlEditor.data.outcomes[outcomeId]);
  const valueWithFormat =
    outcome.score_format !== null && outcome.to !== null ? outcome.score_format.replace('_number_', outcome.to) : '';

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    const newOutcome = {
      ...outcome,
    };

    switch (name) {
      case 'valueWithFormat':
        const { target, score_format } = parseValueWithFormat(value);

        newOutcome.to = target;
        newOutcome.score_format = score_format;
        break;
      case 'from':
        newOutcome.from = value;
        break;
    }

    dispatch(ymlEditorActions.updateItem(newOutcome, 'outcomes'));
  };

  return (
    <div>
      <Input
        className="editor"
        placeholder={`target`}
        value={outcome.from}
        name="from"
        type="number"
        autoComplete="off"
        onChange={handleChange}
      />
      &rarr;
      <Input
        className="editor"
        placeholder={`target`}
        value={valueWithFormat}
        autoComplete="off"
        name="valueWithFormat"
        onChange={handleChange}
      />
    </div>
  );
}

export default React.memo(OutcomeMetrics);
