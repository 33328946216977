import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.2rem;
  min-width: 2rem;
  min-height: 2rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.subtleText};
  background-color: ${theme.colors.N20};
  border-radius: 50%;
  font-weight: 800;
  position: relative;

  margin-right: ${theme.spacing.x2};
`;
