import FormField from 'components/FormField';
import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextButton from 'koala/components/TextButton';
import React, { ChangeEvent, useState } from 'react';
import { useMutation, useQueryCache, useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import { Outcome, Workspace } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import * as remoteApi from '../api/remote';
import { Trans, useTranslation } from 'react-i18next';

const WritingImg = 'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-typing_vjitqx.gif';
const NoCreditsImg =
  'https://res.cloudinary.com/tability/image/upload/v1670996158/static_assets/tabby-sleeping_tvsdtw.gif';

const TipsContent = styled.div`
  font-size: 1.5rem;
  position: absolute;
  bottom: ${theme.spacing.x2};
  right: ${theme.spacing.x2};
  h3 {
    font-size: 2.4rem;
    margin: ${theme.spacing.x4} 0 ${theme.spacing.x1} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
  h4 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-top: ${theme.spacing.x3};
    margin-bottom: ${theme.spacing.x1};
  }
  a {
    color: ${theme.colors.B50};
    text-decoration: underline;
  }
  ul {
    list-style-type: disc;
    margin-left: 3rem;
    margin-top: ${theme.spacing.half};
    margin-bottom: ${theme.spacing.half};

    li {
      padding-bottom: ${theme.spacing.half};
      color: ${theme.colors.N90};
    }
  }
  p {
    color: ${theme.colors.N90};
  }
`;

const TabbyThinking = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x2};
  img {
    width: 16rem;
  }
`;

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const UpgradeContent = styled.div`
  a {
    color: ${theme.colors.blue};
    text-decoration: underline;
  }
`;

interface Props {
  outcomeId: string;
}

function PopulateOutcomeAI(props: Props) {
  const { outcomeId } = props;
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [outcome, setOutcome] = useState<Outcome | null>(null);
  const [outcomeDescription, setOutcomeDescription] = useState('');
  const { t } = useTranslation();
  const translationKey = 'modals.AI';

  // Get the outcome details
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = 0;
  useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
    onSuccess: (response: any) => {
      const outcome: Outcome = response.data;
      setOutcome(outcome);
      setOutcomeDescription(outcome.title);
    },
  });

  const currentPing = useSelector((state: any) => state.session.currentPing, shallowEqual);
  const { usage } = currentPing;

  const [populateAIOutcomeMutation, { isLoading, isError }]: [any, any] = useMutation(remoteApi.populateAIOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentPlan);
      queryCache.invalidateQueries(queryKeys.objectives);
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.initiatives);
      queryCache.invalidateQueries(queryKeys.currentPing);
      dispatch(setGlobalModalContent(''));
    },
  });

  let ai_credits_used = 0;
  let billing_ai_credits_limit = 0;

  if (usage) {
    billing_ai_credits_limit = usage.billing_ai_credits_limit;
    ai_credits_used = usage.ai_credits_used;
  }

  const hasCredit = billing_ai_credits_limit > ai_credits_used;
  const needsUpgrade = !currentWorkspace.billing_subscription_plan || currentWorkspace.pricing_version !== 3;
  const isOwner = currentMembership && ['owner'].includes(currentMembership.role);

  const handleCancel = () => {
    dispatch(setGlobalModalContent(''));
  };

  const handlePopulate = (e: any) => {
    e.preventDefault();
    // Don't trigger if already loading the template.
    if (isLoading) {
      return;
    }
    const params = {
      outcomeId,
      outcomeDescription,
    };

    populateAIOutcomeMutation(params);
  };

  const handleOutcomeDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputName = e.target.value;
    setOutcomeDescription(inputName);
  };

  if (!outcome) {
    return null;
  }

  const placeholder =
    t(`${translationKey}.outcomePlaceholder`, { label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1) }) ??
    `Describe your ${translate(currentWorkspace, CustomTermKey.OUTCOME, 1)} in one sentence`;
  return (
    <ModalGrid>
      <ModalHeader>
        <TitleContainer>
          <h2>
            {t(`${translationKey}.initiativeTitle`, {
              label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
            })}
          </h2>
        </TitleContainer>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        {!isLoading && hasCredit && (
          <Form onSubmit={handlePopulate}>
            <FormField>
              <p>{t(`${translationKey}.info`)}</p>
            </FormField>
            <FormField>
              <label>
                {t(`${translationKey}.outcome`, { label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1) })}
              </label>
              <input
                placeholder={placeholder}
                className="segment-input"
                value={outcomeDescription}
                onChange={handleOutcomeDescriptionChange}
              />
            </FormField>
          </Form>
        )}

        {!hasCredit && (
          <UpgradeContent>
            <TabbyThinking>
              <img src={NoCreditsImg} alt="Tabby sleeping" />
            </TabbyThinking>
            <p>{t(`${translationKey}.noCredits`)}</p>
            <p className="subtle">
              {needsUpgrade && (
                <>
                  {isOwner && (
                    <Trans
                      i18nKey={`${translationKey}.upgradeCredits`}
                      components={{
                        Link: <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel}></Link>,
                      }}
                    />
                  )}
                  {!isOwner && (
                    <Trans
                      i18nKey={`${translationKey}.upgradeCredits`}
                      components={{
                        Link: <b />,
                      }}
                    />
                  )}
                  <Trans
                    i18nKey={`${translationKey}.writeReview`}
                    components={{
                      a: (
                        <a
                          href="https://guides.tability.app/docs/other/how-to-get-more-ai-credits"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          text
                        </a>
                      ),
                    }}
                  />
                </>
              )}
            </p>
          </UpgradeContent>
        )}
        {hasCredit && (
          <>
            {!isLoading && (
              <TipsContent>
                {isError && (
                  <p>
                    <b>{t(`${translationKey}.error`)}</b>
                  </p>
                )}
                <p className="subtle"></p>
              </TipsContent>
            )}
            {isLoading && (
              <TabbyThinking>
                <h3>
                  {t(`${translationKey}.loading`, { label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2) })}
                </h3>
                <img src={WritingImg} alt="Tabby thinking" />
              </TabbyThinking>
            )}
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <KoalaButton
          onClick={handlePopulate}
          disabled={isLoading || !outcomeDescription || !hasCredit}
          loading={isLoading}
        >
          {t('shared.generate')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <small>
            {t(`${translationKey}.creditsUsed`, {
              used: ai_credits_used.toLocaleString(),
              limit: billing_ai_credits_limit.toLocaleString(),
            })}
          </small>
          <br />
          {needsUpgrade && (
            <>
              {isOwner && (
                <small>
                  <Trans
                    i18nKey={`${translationKey}.upgradeCredits`}
                    components={{
                      Link: <Link to={`/${currentWorkspace.slug}/settings/billing`} onClick={handleCancel} />,
                    }}
                  />
                </small>
              )}
              {!isOwner && (
                <small>
                  <Trans
                    i18nKey={`${translationKey}.upgradeCredits`}
                    components={{
                      Link: <b />,
                    }}
                  />
                </small>
              )}
            </>
          )}
        </div>
      </ModalFooter>
    </ModalGrid>
  );
}

export default PopulateOutcomeAI;
