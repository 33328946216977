import React, { ChangeEvent, useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { Trans, useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import KoalaInputValidation from 'koala/components/InputValidation';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();
  // Query keys and query params
  const outcomeSQL = outcome.data_source_meta?.query || '';
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:power_bi:${encodeURIComponent(outcomeSQL)}`,
    'current',
  ];
  const staleTime = 300;

  const { data: queryData, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  if (queryData?.data.result?.error) {
    return <FormField>{queryData?.data.result?.error}</FormField>;
  }

  const currentValue = toNumber(queryData?.data.result);

  if (currentValue === null || currentValue === undefined) {
    return <FormField>{t(`panels.editOutcome.errorFetching`)}</FormField>;
  }

  return (
    <FormField>
      <label>{t('modals.dataConnectors.preview')}</label>
      <p>{currentValue}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  workspaceSlug: string;
}

function PostgresqlSource(props: Props) {
  const { outcome, workspaceSlug } = props;
  const { t } = useTranslation();
  const [query, setQuery] = useState((outcome.data_source_meta && outcome.data_source_meta.query) ?? '');
  const [error, setError] = useState('');
  const [groupOptions, setGroupOptions] = useState<KoalaSelectOption[]>([]);
  const [datasetOptions, setDatasetOptions] = useState<KoalaSelectOption[]>([]);

  const [selectedGroup, setSelectedGroup] = useState(
    (outcome.data_source_meta && outcome.data_source_meta.group_id) ?? '',
  );
  const [selectedDataset, setSelectedDataset] = useState(
    (outcome.data_source_meta && outcome.data_source_meta.dataset_id) ?? '',
  );

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const staleTime = 0;

  const queryKeyGroups = ['power_bi_groups', workspaceSlug];
  const { isLoading: isLoadingGroups } = useQuery(queryKeyGroups, remoteApi.listPowerBIGroups, {
    staleTime,
    onSuccess: (response) => {
      setError('');
      if (!response.data?.groups) {
        setGroupOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.groups.map((r: any) => ({
        label: `${r.name}`,
        value: r.id,
      }));
      setGroupOptions(options);
    },
    onError: (error: any) => {
      setError(error.response.data.error);
    },
  });

  const queryKeyDatasets = ['power_bi_datasets', workspaceSlug, selectedGroup];
  const { isLoading: isLoadingDatasets } = useQuery(queryKeyDatasets, remoteApi.listPowerBIDatasets, {
    staleTime,
    onSuccess: (response) => {
      setError('');

      if (!response.data?.datasets) {
        setDatasetOptions([]);
        return;
      }

      const options: KoalaSelectOption[] = response.data.datasets.map((r: any) => ({
        label: `${r.name}`,
        value: r.id,
      }));
      setDatasetOptions(options);
    },
    onError: (error: any) => {
      setError(error.response.data.error);
    },
  });

  const handleUpdate = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'power_bi',
      data_source_type: 'query',
      data_source_meta: {
        query,
        groupId: selectedGroup,
        datasetId: selectedDataset,
      },
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const handleUpdateQuery = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setQuery(value);
    setError('');
  };

  const onGroupChange = (option: any) => {
    const value = option ? option.value : null;
    setSelectedGroup(value);
  };

  const onDatasetChange = (option: any) => {
    const value = option ? option.value : null;
    setSelectedDataset(value);
  };

  const selectedGroupOption = groupOptions.find((option) => option.value === selectedGroup);
  const selectedDatasetOption = datasetOptions.find((option) => option.value === selectedDataset);
  const powerBIKey = `modals.dataConnectors.powerBI`;
  const placeholder = t(`${powerBIKey}.placeholder`) ?? 'Write your DAX query here';

  return (
    <>
      <FormField>
        <label>
          {t(`${powerBIKey}.workspace`)}
          {isLoadingGroups && <KoalaLoader />}
        </label>
        {!isLoadingGroups && (
          <div style={{ width: '50%' }}>
            <KoalaSelect
              handleChange={(options) => onGroupChange(options)}
              placeholder={t(`${powerBIKey}.workspace`) ?? 'Select Workspace'}
              selectedOption={selectedGroupOption}
              options={groupOptions}
              className="small"
            />
          </div>
        )}
      </FormField>

      {selectedGroup && (
        <FormField>
          <label>
            {t(`${powerBIKey}.dataset`)}
            {isLoadingDatasets && <KoalaLoader />}
          </label>
          {!isLoadingDatasets && (
            <div style={{ width: '50%' }}>
              <KoalaSelect
                handleChange={(options) => onDatasetChange(options)}
                placeholder={t(`${powerBIKey}.dataset`) ?? 'Select Dataset'}
                selectedOption={selectedDatasetOption}
                options={datasetOptions}
                className="small"
              />
            </div>
          )}
        </FormField>
      )}

      <FormField>
        <label>{t(`${powerBIKey}.query`)}</label>
        <textarea value={query} onChange={handleUpdateQuery} placeholder={placeholder} />

        <small>
          <Trans
            i18nKey={`${powerBIKey}.queryInfo`}
            components={{
              a: (
                <a
                  href="https://daxstudio.org/docs/tutorials/writing-dax-queries/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="primary"
                >
                  text
                </a>
              ),
            }}
          />
        </small>
        {error && <KoalaInputValidation state="error" text={error} />}
      </FormField>
      <FormField>
        <KoalaButton
          onClick={handleUpdate}
          loading={isUpdating}
          disabled={!query || isUpdating || !!error}
          appearance="subtle"
          type="button"
        >
          {t(`panels.editOutcome.savePreview`)}
        </KoalaButton>
      </FormField>
      {outcome.data_source_origin === 'power_bi' && <CurrentValue outcome={outcome} />}
    </>
  );
}

export default React.memo(PostgresqlSource);
