/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as amplitudeUtils from 'utils/amplitude';
import theme from 'theme';

// Components
import { Helmet } from 'react-helmet';
import Wordmark from 'components/Wordmark';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';

// Components
const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;

  a.text {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x8};
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-top: ${theme.spacing.x4};
`;

function SessionLoginJira() {
  const { t } = useTranslation(undefined, { useSuspense: false });
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.session.jiraLogin.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const loginUrl = `https://${process.env.REACT_APP_DOMAIN}/login`;
  return (
    <Container>
      <Helmet>
        <title>{t('sessionLoginJira')} | Tability</title>
      </Helmet>
      <ContentContainer>
        <WordmarkWrapper>
          <Wordmark type="dark" />
        </WordmarkWrapper>
        <p>{t('sessionLoginJira.line1')}</p>
        <p>
          <KoalaButton href={loginUrl} target="_blank" rel="noopener noreferrer">
            {t('sessionLoginJira.button')}
          </KoalaButton>
        </p>
        <p></p>
        <p>{t('sessionLoginJira.line2')}</p>
      </ContentContainer>
    </Container>
  );
}

export default SessionLoginJira;
