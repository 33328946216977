import React from 'react';

const TvIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2.5H15C16.6569 2.5 18 3.84315 18 5.5V11.5C18 13.1569 16.6569 14.5 15 14.5H5C3.34314 14.5 2 13.1569 2 11.5V5.5C2 3.84315 3.34315 2.5 5 2.5ZM5 4.5C4.44772 4.5 4 4.94772 4 5.5V11.5C4 12.0523 4.44772 12.5 5 12.5H15C15.5523 12.5 16 12.0523 16 11.5V5.5C16 4.94772 15.5523 4.5 15 4.5H5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 12.5C10.5523 12.5 11 12.9477 11 13.5V16.5C11 17.0523 10.5523 17.5 10 17.5C9.44772 17.5 9 17.0523 9 16.5V13.5C9 12.9477 9.44772 12.5 10 12.5Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 16.5C5 15.9477 5.44772 15.5 6 15.5H14C14.5523 15.5 15 15.9477 15 16.5C15 17.0523 14.5523 17.5 14 17.5H6C5.44772 17.5 5 17.0523 5 16.5Z"
      fill="#737373"
    />
  </svg>
);

export default TvIcon;
