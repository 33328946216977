/**
 * Objective component
 */
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { setHasSyncError, setLoadingOutcomeMetric, setSyncErrorText } from 'state/actions/workspaceEditorActions';

// API
import * as remoteApi from 'api/remote';

import SyncError from './SyncError';

function RemoteSync() {
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // get the last action to sync
  const actionToSync = useSelector((state: any) => state.editorEntities.actionToSync);

  const [action, entity] = actionToSync ? actionToSync.split('.') : [null, null, null];
  const [entityType, entityId] = entity ? entity.split(':') : [null, null];

  const resource = useSelector((state: any) => {
    if (!entityId) {
      return null;
    }
    switch (entityType) {
      case 'plan':
        return state.editorEntities.plans[entityId];
      case 'objective':
        return state.editorEntities.objectives[entityId];
      case 'outcome':
        return state.editorEntities.outcomes[entityId];
      case 'initiative':
        return state.editorEntities.initiatives[entityId];
    }
  });

  // Plan mutations
  const [updatePlanMutation] = useMutation(remoteApi.updatePlan, {
    onError: () => {
      dispatch(setHasSyncError(true));
    },
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentPlan);
    },
  });

  // Objective mutations
  const [createObjectiveMutation]: [any, any] = useMutation(remoteApi.createObjective, {
    onError: () => {
      dispatch(setHasSyncError(true));
    },
  });

  const [updateObjectiveMutation]: [any, any] = useMutation(remoteApi.updateObjective, {
    onError: () => {
      dispatch(setHasSyncError(true));
    },
  });

  const [deleteObjectiveMutation]: [any, any] = useMutation(remoteApi.deleteObjective, {
    onError: (e: any) => {
      const error = e.response.data.error;
      dispatch(setHasSyncError(true));
      dispatch(setSyncErrorText(error));
    },
  });

  const [adjustOutcomeMetricMutation] = useMutation(remoteApi.updateOutcomeMetric, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.currentPing);
      dispatch(setLoadingOutcomeMetric(resource.id, false));
    },
    onError: () => {
      dispatch(setLoadingOutcomeMetric(resource.id, false));
    },
  });

  // Outcomoe mutations
  const [createOutcomeMutation]: [any, any] = useMutation(remoteApi.createOutcome, {
    onError: () => {
      dispatch(setHasSyncError(true));
    },
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      const wordCount = resource.title ? resource.title.split(' ').length : 0;
      // check if workspace allows AI and only do if longer title
      if (currentWorkspace.ai_metrics_enabled && wordCount > 2) {
        dispatch(setLoadingOutcomeMetric(resource.id, true));
        adjustOutcomeMetricMutation({ outcomeId: resource.id });
      }
    },
  });

  const [updateOutcomeMutation]: [any, any] = useMutation(remoteApi.updateOutcome, {
    onError: () => {
      dispatch(setHasSyncError(true));
    },
  });

  const [deleteOutcomeMutation]: [any, any] = useMutation(remoteApi.deleteOutcome, {
    onError: (e: any) => {
      const error = e.response.data.error;
      dispatch(setHasSyncError(true));
      dispatch(setSyncErrorText(error));
    },
  });

  // Initiative mutations
  const [createInitiativeMutation]: [any, any] = useMutation(remoteApi.createInitiative, {
    onError: () => {
      dispatch(setHasSyncError(true));
    },
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
    },
  });

  const [updateInitiativeMutation]: [any, any] = useMutation(remoteApi.updateInitiative, {
    onError: () => {
      dispatch(setHasSyncError(true));
    },
  });

  const [deleteInitiativeMutation]: [any, any] = useMutation(remoteApi.deleteInitiative, {
    onError: () => {
      dispatch(setHasSyncError(true));
    },
  });

  useEffect(() => {
    if (actionToSync) {
      if (entityType === 'plan') {
        switch (action) {
          case 'update':
            updatePlanMutation({
              planId: resource.id,
              plan: resource,
            });
            break;
        }
      }
      if (entityType === 'objective') {
        switch (action) {
          case 'create':
            createObjectiveMutation({
              planId: resource.plan_id,
              objective: resource,
            });
            break;
          case 'update':
            updateObjectiveMutation({
              objectiveId: resource.id,
              objective: resource,
            });
            break;
          case 'delete':
            deleteObjectiveMutation(resource.id);
            break;
        }
      }
      if (entityType === 'outcome') {
        switch (action) {
          case 'create':
            createOutcomeMutation({
              objectiveId: resource.objective_id,
              outcome: resource,
            });
            break;
          case 'update':
            updateOutcomeMutation({
              outcomeId: resource.id,
              outcome: resource,
            });
            break;
          case 'delete':
            deleteOutcomeMutation(resource.id);
            break;
        }
      }
      if (entityType === 'initiative') {
        switch (action) {
          case 'create':
            createInitiativeMutation({
              outcomeId: resource.outcome_id,
              initiative: resource,
            });
            break;
          case 'update':
            updateInitiativeMutation({
              initiativeId: resource.id,
              initiative: resource,
            });
            break;
          case 'delete':
            deleteInitiativeMutation(resource.id);
            break;
        }
      }
    }
  }, [actionToSync]); // eslint-disable-line react-hooks/exhaustive-deps

  return <SyncError />;
}

export default RemoteSync;
