import createReducer from './createReducer';
import * as actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  errors: [],
  hasErrors: false,
  isSignedUp: false,
  isRequesting: false,
};

const reducer = createReducer(INITIAL_STATE)({
  [actionTypes.RESET_PAGE_STATE]: (state: any, action: any) => {
    if (action.page === 'Signup') {
      return INITIAL_STATE;
    } else {
      return state;
    }
  },
  [actionTypes.SIGNUP_FAILED]: (state: any, action: any) => {
    const errors = action.error.response.data.errors;
    return {
      ...state,
      errors,
      hasErrors: true,
      isRequesting: false,
    };
  },
  [actionTypes.SIGNUP_REQUESTED]: () => ({
    ...INITIAL_STATE,
    isRequesting: true,
  }),
  [actionTypes.SIGNUP_COMPLETED]: (state: any) => ({
    ...state,
    isSignedUp: true,
    isRequesting: false,
  }),
});

export default reducer;
