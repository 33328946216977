import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { isAppInIframe } from 'utils/appUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import LoadingScreen from 'components/LoadingScreen';
import KoalaButton from 'koala/components/Button';

// Components
const Container = styled.div`
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;
`;

const Warning = styled.div`
  background: ${theme.colors.warningBg};
  padding: ${theme.spacing.x3};
  border-radius: 8px;
  p,
  button {
    margin-top: ${theme.spacing.x2};
  }
`;

interface Props {
  workspaceSlug: string;
}

function JoinWorkspaceByDomain(props: Props) {
  const { workspaceSlug } = props;
  const [errorUserLimitExceeded, setErrorUserLimitExceeded] = useState(false);

  const [joinWorkspaceMutation, { isError }]: [any, any] = useMutation(remoteApi.joinWorkspace, {
    onSuccess: (response) => {
      const membership = response.data;
      const path = `/${membership.workspace.slug}`;
      window.location.replace(path);
    },
    onError: (error: any) => {
      const errorText = error.response.data.error;
      if (errorText && errorText.includes('user limit') && error.response.status === 401) {
        setErrorUserLimitExceeded(true);
      }
    },
  });

  useEffect(() => {
    joinWorkspaceMutation(workspaceSlug);
  }, [joinWorkspaceMutation, workspaceSlug]);

  const inIframe = isAppInIframe();

  if (isError) {
    return (
      <Container>
        {!errorUserLimitExceeded && (
          <Warning>
            <h3>
              <span role="img" aria-label="yikes!">
                😬
              </span>{' '}
              You're not allowed in <b>"{workspaceSlug}"</b>
            </h3>
            <p>Sorry, but you do not have access to this workspace.</p>
            {!inIframe && <KoalaButton onClick={() => window.location.replace('/')}>Back to home</KoalaButton>}
            {inIframe && <p>Perhaps you're logged in with the wrong account?</p>}
          </Warning>
        )}
        {errorUserLimitExceeded && (
          <Warning>
            <h3>
              <span role="img" aria-label="yikes!">
                😬
              </span>{' '}
              User quota reached
            </h3>
            <p>Sorry, but this workspace has reached its user limit.</p>
            <KoalaButton onClick={() => window.location.replace('/')}>Back to home</KoalaButton>
          </Warning>
        )}
      </Container>
    );
  }

  return <LoadingScreen />;
}

export default JoinWorkspaceByDomain;
