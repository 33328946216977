import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Checkin, View, Workspace } from 'types';

import * as remoteApi from 'api/remote';
import { useQuery } from 'react-query';
import ReactTooltip from 'react-tooltip';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
`;

interface Props {
  checkin: Checkin;
}

function CheckinViews(props: Props) {
  const { checkin } = props;
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  const [viewers, setViewers] = useState<View[]>([]);

  // get viewers if setting is public
  const queryKeyCheckins = [queryKeys.checkinViewers, checkin.id];
  const staleTime = queryKeys.staleTime;
  const { isLoading } = useQuery(queryKeyCheckins, remoteApi.fetchCheckinViewers, {
    staleTime,
    enabled: currentWorkspace.viewers_name_enabled && checkin.cached_views_count !== 0,
    onSuccess: (response) => {
      setViewers(response.data);
    },
  });

  if (isLoading) {
    return (
      <Container>
        <KoalaLoader />
      </Container>
    );
  }

  const viewCount = checkin.cached_views_count;
  if (viewCount === 0 || viewers.length === 0) {
    return (
      <Container>
        <div />
      </Container>
    );
  }

  //viewers to string format
  let tooltipText = '';
  if (viewers.length > 0) {
    if (viewCount === 1) {
      tooltipText =
        viewers[0].membership.id === currentMembership.id ? t('views.you') : viewers[0].membership.cached_fullname;
    } else if (viewCount > 0 && viewCount <= 4) {
      const names = viewers.map((viewer) =>
        viewer.membership.id === currentMembership.id ? t('views.you') : viewer.membership.cached_fullname,
      );
      const last = names.pop();
      tooltipText = `${names.join(', ')} ${t('views.andLast', { last })}`;
    } else if (viewCount > 4) {
      const names = viewers
        .slice(0, 4)
        .map((viewer) =>
          viewer.membership.id === currentMembership.id ? t('views.you') : viewer.membership.cached_fullname,
        );
      const remaining = viewCount - 4;
      tooltipText = `${names.join(', ')} ${t('views.andMore', { number: remaining })}`;
    }
  }

  const tooltipId = `viewers-${checkin.id}`;

  return (
    <Container>
      <div data-for={tooltipId} data-tip>
        {t('views.viewers', { count: viewCount })}
      </div>
      {viewers.length > 0 && (
        <ReactTooltip place="bottom" type="dark" id={tooltipId} effect="solid" delayShow={200}>
          {tooltipText}
        </ReactTooltip>
      )}
    </Container>
  );
}

export default CheckinViews;
