import React, { useState } from 'react';
import * as TabilityTypes from 'types';
import { useMutation, useQueryCache, useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';

// API
import * as remoteApi from 'api/remote';

// Components
import SlackIcon from '../WorkspaceSettingsIntegrations/_assets/IconSlack.png';
import FormField from 'components/FormField';
import { Link, useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import Select from 'react-select';

import 'theme/DatePicker.css';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';

const BackLink = styled(Link)`
  color: ${theme.colors.blue};
  margin-bottom: ${theme.spacing.x2};
  font-size: 1.8rem;
  font-weight: 600;
`;

const Form = styled.form`
  h2 {
    margin-bottom: ${theme.spacing.x3};
  }
  h3 {
    margin-bottom: ${theme.spacing.x2};
  }

  small {
    display: block;
    margin-top: ${theme.spacing.x1};
  }

  margin-bottom: ${theme.spacing.x6};
  button {
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const UpgradeBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
  background: rgba(197, 165, 239, 0.25);
  border-radius: 4px;

  margin: ${theme.spacing.x3} auto;

  p {
    display: flex;
    align-items: center;
  }
  a {
    display: flex;
    align-items: center;
    img {
      width: 2.2rem;
      margin-right: ${theme.spacing.x1};
    }
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
}

function Settings(props: Props) {
  const queryCache = useQueryCache();
  const { plan } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const slackIntegration = useSelector((state: any) =>
    state.session.currentIntegrations.find((i: any) => i.integration_type === 'slack'),
  );
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  const [slack_channel_id, setSlackChannelId] = useState(plan.slack_channel_id);
  const [slack_channel_name, setSlackChannelName] = useState(plan.slack_channel_name);
  const [slackChannels, setSlackChannels] = useState([]);
  const [isSaved, setIsSaved] = useState(false);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, plan.nano_slug];

  // Mutation that will update the plan in the backend
  const [updatePlanMutation, { isLoading }]: [any, any] = useMutation(remoteApi.updatePlan, {
    onMutate: () => {
      setIsSaved(false);
    },
    onSuccess: (planResponse) => {
      queryCache.setQueryData(queryKey, planResponse);
      setIsSaved(true);
    },
  });

  /******** LOADING THE SLACK CHANNELS */

  // Construct the query key using the plan Id
  const queryKeySlack = ['slack_channels', workspaceSlug];
  const staleTime = 0;

  // Get the plan details
  const { isFetching: isLoadingChannels } = useQuery(queryKeySlack, remoteApi.listSlackChannels, {
    staleTime,
    onSuccess: (response: any) => {
      const channels = response.data.slack_channels;
      const slackChannels = channels.map((channel: any) => {
        return {
          value: channel.id,
          label: `#${channel.name}`,
        };
      });
      slackChannels.sort(function (a: any, b: any) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      setSlackChannels(slackChannels);
    },
  });

  /******** END LOADING THE SLACK CHANNELS */

  const handleSave = (e: any) => {
    e.preventDefault();
    const planParams = {
      slack_channel_id,
      slack_channel_name,
    };
    const mutationParams = {
      planId: plan.nano_slug,
      plan: planParams,
    };
    updatePlanMutation(mutationParams);
  };

  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      minWidth: '20rem',
      width: '50%',
      fontSize: '1.4rem',
    }),
    input: (provided: any) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),
  };

  const handleSlackChannelChange = (option: any) => {
    if (option) {
      setSlackChannelId(option.value);
      setSlackChannelName(option.label);
    } else {
      setSlackChannelId('');
      setSlackChannelName('');
    }
  };

  const selectedSlackOption = slackChannels.find((channel: any) => channel.value === slack_channel_id);

  // Displays the list of templates
  return (
    <SettingsNarrowWrapper>
      <BackLink to={`/${workspaceSlug}/plans/${plan.nano_slug}/outcomes`}>
        &larr; {t('planSettings.back', { title: plan.title })}
      </BackLink>
      <Form onSubmit={handleSave}>
        <h2>{t('planSettings.planNotifications')}</h2>

        <h3>{t('planSettings.notifications')}</h3>
        <FormField>
          <p>{t('planSettings.weeklyDigest')}</p>
        </FormField>
        {!slackIntegration && (
          <UpgradeBanner>
            <p>{t('planSettings.connectSlack')}</p>
            {currentMembership && ['admin', 'owner'].includes(currentMembership.role) && (
              <p>
                <KoalaButton isLink to={`/${workspaceSlug}/settings/integrations`} appearance="subtle">
                  <img src={SlackIcon} alt="Slack" />
                  {t('planSettings.slackButton')}
                </KoalaButton>
              </p>
            )}
          </UpgradeBanner>
        )}
        {slackIntegration && (
          <FormField>
            <label>{t('planSettings.slackChannel')}</label>
            {isLoadingChannels && <Loader size="big" />}
            {!isLoadingChannels && (
              <Select
                isClearable
                styles={customStyles}
                placeholder={t('planSettings.slackChannel') ?? 'Slack channel'}
                name="slack channel"
                options={slackChannels}
                value={selectedSlackOption}
                className="basic-multi-select"
                classNamePrefix="react-filter"
                onChange={(option: any) => handleSlackChannelChange(option)}
              />
            )}
            <small>{t('planSettings.publicSlack')}</small>
          </FormField>
        )}

        <Actions>
          <KoalaButton submit disabled={isLoading} loading={isLoading}>
            {t('shared.save')}
          </KoalaButton>
          {isSaved && <span>{t('shared.saved')}</span>}
        </Actions>
      </Form>
    </SettingsNarrowWrapper>
  );
}

export default Settings;
