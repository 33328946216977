/**
  This is the playground editor. It loads the state from local storage and saves there too
 */

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import * as workspaceUtils from 'utils/workspaceUtils';
import UpgradeIcon from 'components/_assets/UpgradeIcon';

// Routes
import * as routes from 'routes';

// Components
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Plan } from 'types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x4} ${theme.spacing.x4};
  align-items: flex-start;
  width: 25rem;
  justify-content: flex-start;
  height: 100%;

  a {
    width: 100%;
    color: ${theme.colors.subtleText};
    margin: 0;
    margin-bottom: ${theme.spacing.x2};
    border-bottom: 2px solid transparent;
    padding-bottom: 0.4rem;
    font-weight: 400;

    &:hover,
    &.active {
      color: #151515;
      text-decoration: none;
      font-weight: 600;
    }
  }
`;

const Header = styled.div`
  margin-bottom: ${theme.spacing.x2};
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #0d383b;
  font-size: 1rem;
  font-weight: 800;
`;

const UpgradeOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  plan: Plan;
}

function PlanSettingsNav(props: Props) {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const { plan } = props;
  const planId = plan.nano_slug;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  return (
    <Container>
      <Header>{t('planSettings.title', { title: plan.title })}</Header>
      <NavLink
        to={routes.WORKSPACE_PLAN_SETTINGS_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(':planId', planId)}
        exact={true}
      >
        {t('planSettings.details')}
      </NavLink>
      <NavLink
        to={routes.WORKSPACE_PLAN_SETTINGS_NOTIFICATIONS_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
          ':planId',
          planId,
        )}
        exact={true}
      >
        {t('planSettings.notifications')}
      </NavLink>
      <NavLink
        to={routes.WORKSPACE_PLAN_SETTINGS_SHARING_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
          ':planId',
          planId,
        )}
        exact={true}
      >
        <UpgradeOption>
          <span>{t('planSettings.sharing')}</span>
          {!hasEssentialsSubscription && <UpgradeIcon />}
        </UpgradeOption>
      </NavLink>
    </Container>
  );
}

export default PlanSettingsNav;
