import styled from 'styled-components'; // Fixing the build
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
// import { useMutation, useQuery, useQueryCache } from 'react-query';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

function ConnectAmplitude() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translationKey = 'modals.connectAmplitude';
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();

  const [apiKey, setApiKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const queryCache = useQueryCache();

  const [installAmplitudeMutation, { isLoading }]: [any, any] = useMutation(remoteApi.installAmplitude, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.integrations]);
      dispatch(setGlobalModalContent(''));
    },
  });

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    const params = {
      body: {
        api_key: apiKey,
        secret_key: secretKey,
      },
      workspaceSlug: workspaceSlug,
    };
    installAmplitudeMutation(params);
  };

  const allowSave = apiKey && secretKey && !isLoading;

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleSave}>
          <FormField>
            <label>{t(`${translationKey}.amplitudeKey`)}</label>
            <input
              placeholder={t(`${translationKey}.amplitudeKeyPlaceholder`) ?? 'API Key'}
              className="segment-input"
              value={apiKey}
              onChange={(e) => setApiKey(e.currentTarget.value)}
            />
          </FormField>
          <FormField>
            <label>{t(`${translationKey}.secretKey`)}</label>
            <input
              placeholder={t(`${translationKey}.secretKey`) ?? 'Secret Key'}
              className="segment-input"
              value={secretKey}
              onChange={(e) => setSecretKey(e.currentTarget.value)}
            />
            <small className="subtle">
              <Trans
                i18nKey={`${translationKey}.link`}
                components={{
                  a: (
                    <a
                      style={{ color: theme.colors.blue }}
                      href="https://www.docs.developers.amplitude.com/analytics/find-api-credentials/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      text
                    </a>
                  ),
                }}
              />
            </small>
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} disabled={!allowSave} loading={isLoading}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default ConnectAmplitude;
