import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as amplitudeUtils from 'utils/amplitude';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative, Objective, Outcome, Plan } from 'types';
import KanbanObjectiveSection from './KanbanObjectiveSection';

const Content = styled.div`
  padding: ${theme.spacing.x2};
  overflow-x: auto;
  scroll-behavior: smooth;
  background: ${theme.colors.N3};
  min-height: 100%;
`;

interface Props {
  plan: Plan;
}

function KanbanView(props: Props) {
  const { plan } = props;

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plan.work.kanban.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const objectives: { [key: string]: Objective } = useSelector((state: any) => state.editorEntities.objectives);
  const objectiveIds: string[] = useSelector(
    (state: any) => state.editorEntities.plansToObjectivesMapping[plan.id] || [],
  );
  const outcomes: { [key: string]: Outcome } = useSelector((state: any) => state.editorEntities.outcomes);
  const initiatives: { [key: string]: Initiative } = useSelector((state: any) => state.editorEntities.initiatives);
  const initiativeIds: string[] = useSelector((state: any) => state.editorEntities.initiativeIds);

  const initiativesByObjective: { [objectiveId: string]: Initiative[] } = {};
  initiativeIds.forEach((id: string) => {
    const initiative: Initiative = initiatives[id];
    if (initiative) {
      const outcome = outcomes ? outcomes[initiative.outcome_id] : null;
      if (outcome) {
        if (initiativesByObjective[outcome.objective_id]) {
          initiativesByObjective[outcome.objective_id].push(initiative);
        } else {
          initiativesByObjective[outcome.objective_id] = [initiative];
        }
      }
    }
  });

  const outcomesByObjective: { [objectiveId: string]: Outcome[] } = {};
  Object.keys(outcomes).forEach((outcomeId) => {
    const outcome = outcomes ? outcomes[outcomeId] : null;
    if (outcome) {
      if (outcomesByObjective[outcome.objective_id]) {
        outcomesByObjective[outcome.objective_id].push(outcome);
      } else {
        outcomesByObjective[outcome.objective_id] = [outcome];
      }
    }
  });

  if (!plan.start_at || !plan.finish_at) {
    return <></>;
  }

  return (
    <Content>
      {objectiveIds.map((objectiveId, i) => {
        const objective = objectives[objectiveId];
        if (objective) {
          return (
            <KanbanObjectiveSection
              objective={objective}
              initiatives={initiativesByObjective[objectiveId]}
              outcomes={outcomesByObjective[objectiveId]}
              key={objectiveId}
            />
          );
        }
        return <></>;
      })}
    </Content>
  );
}

export default KanbanView;
