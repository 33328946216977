import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import theme from 'theme';

interface Props {
  id: string;
  link: string;
  edge?: 'circle' | 'square';
  size?: 'small' | 'medium';
}

function ShareIconButton(props: Props) {
  const { id, link, edge, size } = props;
  const { t } = useTranslation();
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(link);
    setHasBeenClicked(true);

    setTimeout(() => {
      setHasBeenClicked(false);
    }, 3000);
  };

  if (!link) {
    return <></>;
  }

  if (hasBeenClicked) {
    return (
      <>
        <KoalaIconButton
          iconName="link"
          onClick={handleClick}
          iconColor={theme.colors.B50}
          key={`has-been-clicked-${id}`}
          dataFor={`has-been-clicked-${id}`}
          className="share-link-btn"
          edge={edge}
          size={size}
        />
        <ReactTooltip id={`has-been-clicked-${id}`} type="dark" delayHide={3000} place="bottom" effect="solid">
          {t('shared.copiedToClipboard')}
        </ReactTooltip>
      </>
    );
  }
  return <KoalaIconButton className="share-link-btn" iconName="link" onClick={handleClick} edge={edge} size={size} />;
}

export default ShareIconButton;
