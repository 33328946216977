import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import * as routes from 'routes';
import * as amplitudeUtils from 'utils/amplitude';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';

// Components
import AccessDenied from 'components/AccessDenied';
import { ChromeContent, ChromeNav } from 'components/Chrome';
import Loader from 'components/Loader';
import NotFound from 'components/NotFound';
import TemplateNav from 'components/TemplateNav';

// Tracker component
import Tracker from './Tracker';
import { Outcome } from 'types';
import { useTranslation } from 'react-i18next';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const EmptyState = styled.div`
  padding: ${theme.spacing.x3} ${theme.spacing.x4} 0 ${theme.spacing.x8};
`;

const EmptySection = styled.div`
  margin-bottom: ${theme.spacing.x6};
  background: #f3f3f3;
  border-radius: 4px;
  padding: ${theme.spacing.x3};
  text-align: center;

  h3 {
    margin: 0 0 ${theme.spacing.x1} 0;
  }

  a {
    color: ${theme.colors.blue};
  }
`;

function PlanWrite() {
  const { planId, workspaceSlug } = useParams<{ planId: string; workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plan.track.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentPlan, planId, 'track'];
  const staleTime = 0;
  const [outcomesByObjective, setOutcomesByObjective] = useState<{ [objectiveId: string]: Outcome[] }>({});

  // Get the plan details
  const {
    isError,
    error,
    data: planResponse,
    isLoading: planIsLoading,
  }: any = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
  });

  // Get the objectives
  const { data: objectivesResponse, isLoading: objectivesAreLoading } = useQuery(
    [queryKeys.objectives, planId],
    remoteApi.fetchPlanObjectives,
    {
      staleTime,
    },
  );

  // Get the outcomes
  const {
    data: outcomesResponse,
    isLoading: outcomesAreLoading,
    isFetching: outcomesAreFetching,
  } = useQuery([queryKeys.outcomes, planId], remoteApi.fetchPlanOutcomes, {
    staleTime,
    onSuccess: (response: any) => {
      const outcomes: Outcome[] = response.data;
      const outcomesByObjective: { [objectiveId: string]: Outcome[] } = {};
      // Iterate on each outcome and group items by objective_id in an array
      outcomes.forEach((outcome) => {
        if (outcomesByObjective[outcome.objective_id]) {
          outcomesByObjective[outcome.objective_id].push(outcome);
        } else {
          outcomesByObjective[outcome.objective_id] = [outcome];
        }
      });
      setOutcomesByObjective(outcomesByObjective);
    },
  });

  const plan = planResponse ? planResponse.data : null;
  const objectives = objectivesResponse ? objectivesResponse.data : [];
  const outcomes = outcomesResponse ? outcomesResponse.data : [];

  const dataIsLoading = planIsLoading || objectivesAreLoading || outcomesAreLoading;

  // We display the loader until we have loaded the plan and objectives.
  // The reason why we wait for objectives to be loaded here is to check in the Track components
  // if we should display the empty state, or the tracking content
  if (!plan || dataIsLoading) {
    return (
      <Fragment>
        <ChromeContent isGreyBackground>
          <LoadingContainer>
            <Loader size="big" />
          </LoadingContainer>
        </ChromeContent>
      </Fragment>
    );
  }

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401) {
      return <AccessDenied />;
    }
    return <NotFound />;
  }

  const writeRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    planId,
  );

  // Display empty state if we don't have objectives
  if (plan.archived) {
    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | Track {plan.title} | Tability
          </title>
        </Helmet>
        <ChromeNav>
          <TemplateNav plan={plan} />
        </ChromeNav>
        <ChromeContent style={{ position: 'relative' }} isGreyBackground>
          <EmptyState>
            <EmptySection>
              <h3>This plan is archived</h3>
            </EmptySection>
          </EmptyState>
        </ChromeContent>
      </Fragment>
    );
  }

  // Display empty state if we don't have objectives
  if (outcomes.length === 0) {
    return (
      <Fragment>
        <Helmet>
          <title>
            {currentWorkspace.name} | Track {plan.title} | Tability
          </title>
        </Helmet>
        <ChromeNav>
          <TemplateNav plan={plan} />
        </ChromeNav>
        <ChromeContent style={{ position: 'relative' }} isGreyBackground>
          <EmptyState>
            <EmptySection>
              <h3>{t('workspacePlan.empty')}</h3>
              <p>
                <Link to={writeRoute}>Edit this plan</Link> to add your OKRs or team goals.
              </p>
            </EmptySection>
          </EmptyState>
        </ChromeContent>
      </Fragment>
    );
  }

  // Displays the list of templates
  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | Template: {plan.title} | Tability
        </title>
      </Helmet>
      <ChromeNav>
        <TemplateNav plan={plan} />
      </ChromeNav>
      <ChromeContent isGreyBackground>
        <Tracker
          plan={plan}
          objectives={objectives}
          outcomes={outcomes}
          outcomesByObjective={outcomesByObjective}
          outcomesLoading={outcomesAreFetching}
        />
      </ChromeContent>
    </Fragment>
  );
}

export default PlanWrite;
