import { addDays, format, formatDistanceToNowStrict, parseISO, startOfWeek } from 'date-fns';
import { i18n } from 'i18next';
import { enAU, fr, es, pt, vi } from 'date-fns/locale';

const locales: { [key: string]: Locale } = { en: enAU, fr, es, pt, vi };

const getLocale = (i18n: i18n) => {
  const language = i18n.language;
  const approvedLanguages = ['en', 'fr', 'es', 'pt', 'vi'];
  return approvedLanguages.includes(language) ? locales[language] : locales['en'];
};

export const capitaliseFirst = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const formatDistanceToNowLocale = (
  date: string | Date,
  i18n: i18n,
  addSuffix?: boolean,
  showAbout?: boolean,
) => {
  const locale = getLocale(i18n);
  const dateLabel = typeof date === 'string' ? parseISO(date) : date;
  let label = formatDistanceToNowStrict(dateLabel, { locale, addSuffix });
  if (!showAbout) {
    label = label.replace('about', '');
  }
  return label;
};

export const formatLocale = (date: string | Date, formatString: string, i18n: i18n) => {
  const locale = getLocale(i18n);
  const dateLabel = typeof date === 'string' ? parseISO(date) : date;

  return format(dateLabel, formatString, { locale });
};

export const getLocaleMonth = (date: Date, i18n: i18n) => {
  const locale = getLocale(i18n);
  return capitaliseFirst(format(date, 'MMM', { locale }));
};

export const getLocaleDaysOfWeek = (formatString: string, i18n: i18n) => {
  const locale = getLocale(i18n);
  const firstDOW = startOfWeek(new Date());
  const shortWeekDaysArray = Array.from(Array(7)).map((e, i) => format(addDays(firstDOW, i), formatString, { locale }));

  return shortWeekDaysArray;
};
