import styled from 'styled-components';
import theme from 'theme';

export default styled.div`
  font-size: 1rem;
  height: 2rem;
  line-height: 2rem;
  letter-spacing: 1px;
  color: #fff;
  background-color: #6096ec;
  border: 0;
  border-radius: 10px;
  padding: 0 ${theme.spacing.x1};
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;

  &.objective {
    background-color: ${theme.colors.T50};
    color: ${theme.colors.N100};
  }

  &.outcome {
    background-color: ${theme.colors.Y50};
    font-size: 1.4rem;
    color: ${theme.colors.N100};
    letter-spacing: 0;
    padding: 0 ${theme.spacing.x2};
  }

  &.initiative {
    color: #bdbdbd;
    background-color: transparent;
    text-decoration: line-through;
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 0;
    text-align: right;
  }

  &.new {
    opacity: 0.5;
  }
`;
