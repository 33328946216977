import queryKeys from 'config/queryKeys';
import React, { Fragment, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import parse from 'parse-link-header';
import { Team, Workspace } from 'types';

import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import { AxiosResponse } from 'axios';
import EmptyStatePanel from 'components/EmptyStatePanel';
import { toInteger } from 'lodash';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TeamCard from './TeamCard';
import theme from 'theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  margin-top: ${theme.spacing.x3};
`;
const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x2};
  flex-wrap: wrap;
`;
const LoadMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface Props {
  searchTerm: string;
  workspace: Workspace;
}

function TeamsListSearch(props: Props) {
  const { searchTerm, workspace } = props;
  const { t } = useTranslation();
  const [searchedTeams, setSearchedTeams] = useState<AxiosResponse[]>([]);
  const [resultCount, setResultCount] = useState<number | null>(null);

  // Search query attributes
  const searchTeamsQueryKey = [
    queryKeys.teams,
    workspace.id,
    {
      name: searchTerm,
      limit: 5,
    },
  ];
  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  const { isLoading, isFetchingMore, fetchMore, canFetchMore } = useInfiniteQuery(
    searchTeamsQueryKey,
    remoteApi.fetchWorkspaceTeams,
    {
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
      onSuccess: (response) => {
        const resultsCount = response[0].headers['x-total'];
        setResultCount(toInteger(resultsCount));
        setSearchedTeams(response);
      },
    },
  );

  if (isLoading) {
    return <KoalaLoader />;
  }

  if (resultCount === 0) {
    return <EmptyStatePanel>{t('workspaceTeams.emptyState')}</EmptyStatePanel>;
  }
  return (
    <Container>
      <TeamContainer>
        {searchedTeams.map((group: any, i: number) => {
          return (
            <Fragment key={i}>
              {group.data.map((team: Team) => (
                <TeamCard team={team} workspace={workspace} key={team.id} />
              ))}
            </Fragment>
          );
        })}
      </TeamContainer>
      <LoadMoreContainer>
        {canFetchMore && (
          <KoalaButton appearance="secondary" onClick={() => fetchMore()} loading={!!isFetchingMore}>
            {t('shared.loadMore')}
          </KoalaButton>
        )}
      </LoadMoreContainer>
    </Container>
  );
}

export default TeamsListSearch;
