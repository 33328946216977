import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import { Integration, Workspace } from 'types';
import KoalaTextButton from 'koala/components/TextButton';
import TrelloIcon from 'components/_assets/IconTrello.png';
import KoalaButton from 'koala/components/Button';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import queryKeys from 'config/queryKeys';

interface Props {
  workspace: Workspace;
  integrations: Integration[];
}

function TrelloIntegration(props: Props) {
  const { workspace, integrations } = props;
  const { t } = useTranslation();
  const queryKey = [queryKeys.integrations, workspace.slug];
  const queryCache = useQueryCache();

  const [uninstallTrelloMutation, { isLoading: isUninstallingTrello }] = useMutation(remoteApi.uninstallTrello, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const trelloIntegration = integrations.filter((i: Integration) => i.integration_type === 'trello')[0];

  const trelloRedirectURL = `https://${process.env.REACT_APP_DOMAIN}/install/trello`;
  const trelloOAuthURL =
    `https://trello.com/1/authorize?` +
    `name=TabilityToken&scope=read&response_type=token&expiration=never` +
    `&return_url=${encodeURIComponent(trelloRedirectURL)}` +
    `&key=${process.env.REACT_APP_TRELLO_API_KEY}`;
  const uninstallTrelloLabel = isUninstallingTrello ? <KoalaLoader /> : t('workspaceSettingsIntegrations.remove');

  const handleUninstallTrello = (e: any) => {
    e.preventDefault();
    uninstallTrelloMutation(workspace.slug);
  };

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={TrelloIcon} alt="Trello" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.dataConnectorHeader', { integration: 'Trello' })}</h3>
        <p>{t('workspaceSettingsIntegrations.dataConnectorInfo', { integration: 'Trello' })}</p>
      </IntegrationContent>
      <IntegrationAction>
        {trelloIntegration && (
          <ActionBlock>
            <div>
              <Trans
                i18nKey="workspaceSettingsIntegrations.connected"
                components={{ b: <b /> }}
                values={{ value: trelloIntegration.connected_to }}
              />
            </div>
            <KoalaButton href={trelloOAuthURL} appearance="secondary" size="small">
              {t('workspaceSettingsIntegrations.reinstall')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={handleUninstallTrello}>
              {uninstallTrelloLabel}
            </KoalaTextButton>
          </ActionBlock>
        )}
        {!trelloIntegration && (
          <KoalaButton href={trelloOAuthURL}>
            {t('workspaceSettingsIntegrations.connectTo', { integration: 'Trello' })}
          </KoalaButton>
        )}
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default TrelloIntegration;
