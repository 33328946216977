import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as checkinUtils from 'utils/checkinUtils';

import CommentIcon from 'components/_assets/CommentIcon';
import MarkdownContent from 'components/MarkdownContent';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowLocale } from 'utils/dateUtils';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 15rem;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    'content content'
    'footer footer';
  gap: ${theme.spacing.x2};

  padding: ${theme.spacing.x2};

  .actions {
    opacity: 0;
  }

  background: #f3f3f3;
  border-radius: 4px;
  padding: ${theme.spacing.x3};

  border-bottom: 0;
  margin-bottom: ${theme.spacing.x2};

  .actions {
    opacity: 1;
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
  display: flex;
  align-items: center;
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 3.8rem;
`;

const Score = styled.div`
  font-weight: 700;
  font-size: 2.2rem;
  margin-left: ${theme.spacing.x2};
`;

const Content = styled.div`
  grid-area: content;
`;

const Footer = styled.div`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.subtleText};

  > div {
    display: flex;
    align-items: center;
  }

  .comments {
    display: flex;
    align-items: center;
    svg {
      width: 1.8rem;
      height: 1.8rem;
      g,
      circle {
        stroke: ${theme.colors.subtleText};
      }
      margin-right: ${theme.spacing.x1};
    }
  }
`;

interface Props {
  className?: string;
  outcome: any;
  checkin: any;
}

function CheckinBlock(props: Props) {
  const { checkin, outcome } = props;
  const { t, i18n } = useTranslation();
  const user = checkin.membership ? checkin.membership.user : null;

  const confidenceText = checkin ? checkinUtils.confidenceToScore(checkin.confidence, t) : 'Pending';

  const formattedScore = checkin ? checkinUtils.checkinScore(checkin, outcome, t) : 'Pending';

  const displayScore = confidenceText !== formattedScore;

  return (
    <Grid className={props.className}>
      <Gutter>
        <KoalaAvatar user={user} />
        {displayScore && <Score>{formattedScore}</Score>}
      </Gutter>
      <Meta>
        {outcome.outcome_type !== 'kpi' && (
          <KoalaTextBadge variant={checkinUtils.confidenceToVariant(checkin.confidence)}>
            {checkinUtils.confidenceToScore(checkin.confidence, t)}
          </KoalaTextBadge>
        )}
      </Meta>
      <Content>
        {checkin.body && <MarkdownContent source={checkin.body} />}
        {!checkin.body && (
          <p className="subtle">
            <em>{t('shared.noComment')}</em>
          </p>
        )}
      </Content>
      <Footer>
        <div>{formatDistanceToNowLocale(checkin.checkin_date, i18n, true)}</div>
        {checkin.checkin_comments_count > 0 && (
          <div className="comments">
            <CommentIcon /> <span>{checkin.checkin_comments_count}</span>
          </div>
        )}
      </Footer>
    </Grid>
  );
}

export default React.memo(CheckinBlock);
