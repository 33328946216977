import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import * as remoteApi from 'api/remote';
import { Plan, Retrospective } from 'types';
import { AxiosResponse } from 'axios';

// Components
import ShareSelection from 'components/ShareSelection';
import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';

// Routes
import * as routes from 'routes';
import Toggle from 'components/Toggle';
import KoalaLoader from 'koala/components/Loader';
import CopyTextInput from 'components/CopyTextInput';

const TitleContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const PublicAccessSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x2} 0px;
  border-top: 1px solid ${theme.colors.N20};
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing.x2};

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    line-height: 1.4;
    font-size: 1.4rem;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    border-radius: 4px;
    border: 1px solid ${theme.colors.N20};
    width: 100%;
    resize: none;
  }

  label {
    color: ${theme.colors.N70};
    margin-bottom: ${theme.spacing.x1};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    display: block;
  }
`;

const SuccessContainer = styled.div`
  color: ${theme.colors.G80};
  background: ${theme.colors.G10};
  padding: ${theme.spacing.x1};
  border-radius: 4px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x2};
  align-items: center;
  margin-bottom: ${theme.spacing.x2};
`;

interface Props {
  retrospectiveId: string;
}

function ShareRetrospective(props: Props) {
  const { retrospectiveId } = props;
  const { t } = useTranslation();
  const translationKey = 'modals.shareRetrospective';
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const [retrospective, setRetrospective] = useState<Retrospective | null>(null);
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
    planId: string;
  }>();
  const [userList, setUserList] = useState<string[]>([]);
  const [message, setMessage] = useState('');
  const [plan, setPlan] = useState<Plan>();
  const [shareSuccessful, setShareSuccessful] = useState(false);

  useEffect(() => {
    setShareSuccessful(false);
  }, [userList]);

  // Get the retrospective details
  const queryKey = [queryKeys.currentRetrospective, retrospectiveId];
  const staleTime = 0;
  useQuery(queryKey, remoteApi.fetchRetrospectiveDetails, {
    staleTime,
    onSuccess: (response: AxiosResponse<Retrospective>) => {
      const retrospective: Retrospective = response.data;
      setRetrospective(retrospective);
      setPlan(retrospective.plan);
    },
  });

  const [shareRetrospectiveMutation, { isLoading: isShareByEmailsLoading }] = useMutation(
    remoteApi.shareRetrospective,
    {
      onSuccess: () => {
        setShareSuccessful(true);
      },
    },
  );

  // Mutation that will update the plan in the backend
  const [updateRetrospectiveMutation, { isLoading }] = useMutation(remoteApi.updateRetrospective, {
    onSuccess: (response: AxiosResponse<Retrospective>) => {
      queryCache.setQueryData(queryKey, response);
    },
  });

  const handleCancel = () => {
    dispatch(setGlobalModalContent(''));
  };

  if (!retrospective || !plan) {
    return null;
  }

  const handleShare = () => {
    const mutationParams = {
      retrospectiveId: retrospective.nano_slug,
      share: {
        list: userList,
        message,
      },
    };
    shareRetrospectiveMutation(mutationParams);
  };

  const { public_access_enabled } = retrospective;
  const handleTogglePublicSharing = (e: React.ChangeEvent<HTMLInputElement>) => {
    const public_access_enabled = e.target.checked;
    let retrospectiveParams = {
      public_access_enabled,
    };

    updateRetrospectiveMutation({ retrospectiveId, retrospective: retrospectiveParams });
  };

  const publicAccessPath = routes.PUBLIC_RETROSPECTIVE_ROUTE.replace(':workspaceSlug', workspaceSlug)
    .replace(':planId', plan.nano_slug)
    .replace(':retrospectiveId', retrospectiveId);

  const privateAccessPath = routes.WORKSPACE_RETROSPECTIVE_ROUTE.replace(':workspaceSlug', workspaceSlug)
    .replace(':planId', plan.nano_slug)
    .replace(':retrospectiveId', retrospectiveId);

  const publicAccessLink = `https://${process.env.REACT_APP_DOMAIN}${publicAccessPath}`;
  const url = `https://${process.env.REACT_APP_DOMAIN}${privateAccessPath}`;

  const emailInputPlaceholder = t('modals.share.emailInputPlaceholder') ?? 'e.g Ava Andrews or ava@acme.co';
  const emailMessagePlaceholder = t('modals.share.emailMessagePlaceholder') ?? 'Anything else they should know';

  return (
    <ModalGrid>
      <ModalHeader>
        <TitleContainer>
          <h2>{t(`${translationKey}.title`)}</h2>
        </TitleContainer>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <InputSection>
          <label>{t('modals.share.emailInput')}</label>
          <ShareSelection userList={userList} setUserList={setUserList} placeholder={emailInputPlaceholder} />
        </InputSection>

        <InputSection>
          <label>{t('modals.share.emailMessage')}</label>
          <textarea
            placeholder={emailMessagePlaceholder}
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </InputSection>
        {!shareSuccessful && (
          <Actions>
            <KoalaButton
              className="send-btn"
              disabled={userList.length === 0 || isShareByEmailsLoading}
              onClick={handleShare}
              loading={isShareByEmailsLoading}
            >
              {t('shared.share')}
            </KoalaButton>
          </Actions>
        )}
        {shareSuccessful && <SuccessContainer>{t('shared.shared')}</SuccessContainer>}

        <PublicAccessSection>
          <h4>{t(`${translationKey}.publicAccessLabel`)}</h4>
          <Toggle>
            <input
              className="tgl tgl-light"
              id="update_password"
              type="checkbox"
              name="update_password"
              defaultChecked={public_access_enabled}
              onChange={handleTogglePublicSharing}
            />
            <label className="tgl-btn" htmlFor="update_password" data-tip data-for="toggleContainer" />
            <div>{t(`${translationKey}.enablePublic`)}</div>
            {isLoading && <KoalaLoader />}
          </Toggle>
          {!public_access_enabled && <small className="subtle">{t(`modals.share.onlyLoggedIn`)}</small>}
          {public_access_enabled && <small className="subtle">{t(`modals.share.publicAccessDescription`)}</small>}
          <CopyTextInput text={url} />
        </PublicAccessSection>
        <KoalaButton href={publicAccessLink} target="_blank" rel="noopener noreferrer" appearance="subtle" size="small">
          {t(`modals.share.newTab`)}
        </KoalaButton>
      </ModalContent>
    </ModalGrid>
  );
}

export default ShareRetrospective;
