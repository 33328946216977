import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import KoalaButton from 'koala/components/Button';
import AchievedTabby from './achieved_tabby.png';

// Custom components

const Mask = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 90;
  opacity: 1;
  transition: opacity 0.1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  height: 42rem;
  margin-top: -21rem;
  margin-left: -25rem;
  width: 50rem;
  z-index: 80;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  display: block;
  transition: display 0.1s ease-out;
`;

const ModalContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;

  background: #fff;
`;

const Content = styled.div`
  padding: ${theme.spacing.x4};
  flex: 1;

  h2 {
    margin-bottom: ${theme.spacing.x3};
  }
`;

const TplAd = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  justify-content: center;
  h1 {
    font-size: 2.8rem !important;
    font-weight: 800 !important;
    margin-bottom: 0 !important;
  }
  h2 {
    font-size: 2rem !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
  }

  h3 {
    font-size: 1.6rem !important;
    font-weight: 700 !important;
    margin-bottom: 0 !important;
  }
  li {
    font-size: 1.6rem !important;
  }
  img {
    width: 15rem;
  }
  b {
    font-weight: 900;
    color: ${theme.colors.O60};
  }
  button {
    font-size: 18px !important;
    padding: 8px 16px !important;
    height: initial !important;
  }
`;

function TemplateV2Content() {
  const [hideModal, setHideModal] = useState(false);

  if (hideModal) {
    return null;
  }

  const handleHideModal = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setHideModal(true);
  };

  return (
    <Mask onClick={handleHideModal}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <ModalContent>
          <Content>
            <TplAd>
              <img src={AchievedTabby} alt="Tability" />
              <h1>You're in the right place!</h1>
              <h2>
                Create an account to build and track your own strategies – just like the template that you're coming
                from.
              </h2>
              <div>
                <KoalaButton appearance="primary" onClick={handleHideModal}>
                  Get started
                </KoalaButton>
              </div>
            </TplAd>
          </Content>
        </ModalContent>
      </ContentWrapper>
    </Mask>
  );
}

export default TemplateV2Content;
