import FormField from 'components/FormField';
import React from 'react';
import { Plan } from 'types';

import * as remoteApi from 'api/remote';
import { useParams } from 'react-router-dom';

import queryKeys from 'config/queryKeys';
import AsyncSelect from 'react-select/async';
import PlanIconLabel from 'components/PlanIconLabel';
import { useTranslation } from 'react-i18next';

interface Props {
  plan: Plan | undefined;
  setPlan: (e: any) => void;
}

function PlansList(props: Props) {
  // selected
  const { plan, setPlan } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const handleSelection = (selected: any) => {
    setPlan(selected);
  };

  const fetchData = (input: string) => {
    let planParams;
    if (input) {
      planParams = {
        filter: {
          title: input,
        },
        order_direction: 'asc',
        order_attribute: 'title',
        limit: 5,
      };
    } else {
      planParams = {
        filter: {
          status: 'active',
        },
        order_direction: 'asc',
        order_attribute: 'title',
        limit: 5,
      };
    }
    // @ts-ignore
    return remoteApi.fetchPlans(queryKeys.plans, workspaceSlug, planParams, undefined).then((response) => {
      const plans = response.data;
      return plans;
    });
  };

  const handleLoadOptions = (input: any) => {
    // return debounceLoadOptions.current(input);
    return fetchData(input);
  };

  // const debounceLoadOptions = useRef(_.throttle((input: string) => fetchData(input), 500));

  return (
    <>
      <FormField>
        <label>{t('modals.moveOutcome.plan')}</label>

        <AsyncSelect
          defaultOptions
          value={plan}
          loadOptions={handleLoadOptions}
          onChange={(option) => handleSelection(option)}
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.title}
          formatOptionLabel={(option) => <PlanIconLabel plan={option} size="small" />}
        />
      </FormField>
    </>
  );
}

export default PlansList;
