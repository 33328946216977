import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useQuery, useQueryCache, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';

import { useParams } from 'react-router-dom';
// Components
import FormField from 'components/FormField';
import QuillBodyEditor from 'components/QuillBodyEditor';
import YMLEditor from 'components/YMLEditor';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaButton from 'koala/components/Button';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  padding: ${theme.spacing.x4};
  max-width: 120rem;
  margin: 0 auto;

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 15rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftColumn = styled.div`
  flex: 3;
  padding: ${theme.spacing.x3};
`;

const RightColumn = styled.div`
  flex: 2;
  padding: ${theme.spacing.x3};
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: ${theme.spacing.x2};
  }
`;

export default function PublicTemplates() {
  const { publicTemplateId } = useParams<{ publicTemplateId: string }>();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const queryKeyTemplate = [queryKeys.publicTemplates, publicTemplateId];
  const staleTime = 0;

  const [template, setTemplate]: any = useState(null);
  const [title, setTitle] = useState('');
  const [excerpt, setExcerpt] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  const [published, setPublished] = useState(false);
  const [author_name, setAuthorName] = useState('');
  const [author_url, setAuthorUrl] = useState('');
  const [org_name, setOrgName] = useState('');
  const [org_url, setOrgUrl] = useState('');
  const [yaml_content, setYAMLContent] = useState('');
  const [template_type, setTemplateType] = useState('');

  const [saved, setSaved] = useState(false);

  // Get the template2 details
  useQuery(queryKeyTemplate, remoteApi.fetchPublicTemplateDetails, {
    staleTime,
    onSuccess: (response) => {
      const template = response.data;
      setTemplate(template);
      setTitle(template.title);
      setDescription(template.description);
      setYAMLContent(template.yaml_content);
      setExcerpt(template.excerpt);
      setTags(template.tags);
      setPublished(template.published);
      setAuthorUrl(template.author_url);
      setAuthorName(template.author_name);
      setOrgUrl(template.org_url);
      setOrgName(template.org_name);
      setTemplateType(template.template_type);
    },
  });

  // Mutation that will update the plan in the backend
  const [updatePublicTemplateMutation, { isLoading }]: [any, any] = useMutation(remoteApi.updatePublicTemplate, {
    onMutate: () => {
      setSaved(false);
    },
    onSuccess: (planResponse) => {
      queryCache.invalidateQueries(queryKeyTemplate);
      setSaved(true);
    },
  });

  const updatePublicTemplate = (e: any) => {
    e.preventDefault();
    const publicTemplateParams = {
      title,
      description,
      yaml_content,
      excerpt,
      tags,
      published,
      author_url,
      author_name,
      org_url,
      org_name,
      template_type,
    };
    const mutationParams = {
      publicTemplateId,
      public_template: publicTemplateParams,
    };
    updatePublicTemplateMutation(mutationParams);
  };

  const memoizedHandleChangeYML = useCallback((ymlChanged: string) => {
    setYAMLContent(ymlChanged);
  }, []);

  if (!template) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <Container>
      <h2>{t('publicTemplates.edit.title')}</h2>
      <Nav>
        <KoalaTextButton isLink to="/public_templates/">
          {t('publicTemplates.back')}
        </KoalaTextButton>
        <KoalaButton isLink to={`/public_templates/${template.nano_slug}`} appearance="subtle">
          {t('publicTemplates.edit.view')}
        </KoalaButton>
        <KoalaButton onClick={updatePublicTemplate} disabled={isLoading} loading={isLoading}>
          {t('shared.save')}
        </KoalaButton>
        {saved && <span>{t('shared.saved')}</span>}
      </Nav>
      <div>{t('publicTemplates.edit.editContent')}</div>
      <Columns>
        <LeftColumn>
          <YMLEditor template={template} handleChange={memoizedHandleChangeYML} />
        </LeftColumn>
        <RightColumn>
          <FormField>
            <label>{t('publicTemplates.titleLabel')}</label>
            <input value={title} onChange={(e: any) => setTitle(e.target.value)} />
          </FormField>
          <FormField>
            <label>{t('publicTemplates.type')}</label>
            <input value={template_type} onChange={(e: any) => setTemplateType(e.target.value)} />
          </FormField>
          <FormField>
            <label>{t('publicTemplates.published')}</label>
            <select value={published.toString()} onChange={(e: any) => setPublished(e.target.value)}>
              <option value={false.toString()}>{t('shared.false')}</option>
              <option value={true.toString()}>{t('shared.true')}</option>
            </select>
          </FormField>
          <FormField>
            <label>{t('publicTemplates.tags')}</label>
            <input value={tags} onChange={(e: any) => setTags(e.target.value)} />
          </FormField>
          <FormField>
            <label>{t('publicTemplates.excerpt')}</label>
            <input value={excerpt} onChange={(e: any) => setExcerpt(e.target.value)} />
          </FormField>
          <FormField>
            <label>{t('publicTemplates.description')}</label>
            <QuillBodyEditor
              value={description}
              onChange={setDescription}
              placeholder={t('publicTemplates.edit.descriptionPlaceholder') ?? 'Template description'}
              disableMentions={true}
              quillClassName="ql-notes"
            />
          </FormField>
          <FormField>
            <label>{t('publicTemplates.edit.authorName')}</label>
            <input value={author_name} onChange={(e: any) => setAuthorName(e.target.value)} />
            <label>{t('publicTemplates.edit.authorURL')}</label>
            <input value={author_url} onChange={(e: any) => setAuthorUrl(e.target.value)} />
          </FormField>
          <FormField>
            <label>{t('publicTemplates.edit.orgName')}</label>
            <input value={org_name} onChange={(e: any) => setOrgName(e.target.value)} />
            <label>{t('publicTemplates.edit.orgURL')}</label>
            <input value={org_url} onChange={(e: any) => setOrgUrl(e.target.value)} />
          </FormField>
        </RightColumn>
      </Columns>
    </Container>
  );
}
