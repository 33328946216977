import React, { useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { t } from 'i18next';

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;

  // Query keys and query params
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:chargebee:${outcome.data_source_type}`,
    'current',
  ];
  const staleTime = 300;

  const { data, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = data?.data.result;

  if (currentValue === null || currentValue === undefined) {
    return <FormField>Sorry, an error occurred while fetching the current value.</FormField>;
  }

  const dataTypeToLabel: any = {
    customer_churn: '<number> customers churned',
    new_customers: '<number> new customers',
    payment_due: '<number> invoices',
    active_subscriptions: '<number> active subscriptions',
    mrr: '<number> mrr',
  };

  const label = dataTypeToLabel[outcome.data_source_type || ''];
  const labelWithNumber = label.replace('<number>', currentValue.toLocaleString());
  return (
    <FormField>
      <label>Preview value</label>
      <p>{labelWithNumber}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
}

function ChargebeeSource(props: Props) {
  const { outcome } = props;
  const [data_source_type, setDataSourceType] = useState(outcome.data_source_type || '');

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }]: [any, any] = useMutation(
    remoteApi.updateOutcomeDataSource,
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries(queryKeys.currentOutcome);
      },
    },
  );

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'chargebee',
      data_source_type: data_source_type,
      data_source_meta: {},
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const availableDataSourceTypes = ['customer_churn', 'new_customers', 'payment_due', 'active_subscriptions', 'mrr'];
  const translationKey = 'modals.dataConnectors.chargebeeData';
  return (
    <>
      <FormField>
        <label>{t('panels.editOutcome.changeMetric')}</label>
        <select value={data_source_type} onChange={(e) => setDataSourceType(e.currentTarget.value)}>
          <option value="">{t('panels.editOutcome.selectMetric')}</option>
          <option value="customer_churn">{t(`${translationKey}.customer_churn`)}</option>
          <option value="new_customers">{t(`${translationKey}.new_customers`)}</option>
          <option value="payment_due">{t(`${translationKey}.payment_due`)}</option>
          <option value="active_subscriptions">{t(`${translationKey}.active_subscriptions`)}</option>
          <option value="mrr">{t(`${translationKey}.mrr`)}</option>
        </select>
      </FormField>
      {/* {outcome.data_source_type !== data_source_type && ( */}
      <FormField>
        <KoalaButton
          onClick={handleUpdate}
          loading={isUpdating}
          disabled={!data_source_type || isUpdating}
          appearance="subtle"
        >
          Save & preview
        </KoalaButton>
      </FormField>
      {/* )} */}
      {outcome.data_source_origin === 'chargebee' &&
        outcome.data_source_type &&
        outcome.data_source_type === data_source_type && (
          <>{availableDataSourceTypes.includes(outcome.data_source_type) && <CurrentValue outcome={outcome} />}</>
        )}
    </>
  );
}

export default React.memo(ChargebeeSource);
