import React from 'react';

const MapIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.55874 1.15336C6.8663 0.960038 7.25452 0.948714 7.57283 1.12378L12.8753 4.04012L17.4678 1.15336C17.776 0.959662 18.1651 0.948697 18.4836 1.12474C18.8022 1.30078 19 1.63602 19 2V14.8C19 15.1439 18.8233 15.4636 18.5322 15.6466L13.4413 18.8466C13.1337 19.04 12.7455 19.0513 12.4272 18.8762L7.12474 15.9599L2.53217 18.8466C2.22401 19.0403 1.83494 19.0513 1.51635 18.8753C1.19777 18.6992 1 18.364 1 18V5.2C1 4.85612 1.17669 4.53637 1.46783 4.35336L6.55874 1.15336ZM7.12474 3.15988L3 5.75257V16.1903L6.55874 13.9534C6.8663 13.76 7.25452 13.7487 7.57283 13.9238L12.8753 16.8401L17 14.2474V3.80972L13.4413 6.04664C13.1337 6.23996 12.7455 6.25129 12.4272 6.07622L7.12474 3.15988Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.09091 1C7.6432 1 8.09091 1.44772 8.09091 2V14.8C8.09091 15.3523 7.6432 15.8 7.09091 15.8C6.53863 15.8 6.09091 15.3523 6.09091 14.8V2C6.09091 1.44772 6.53863 1 7.09091 1Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9091 4.2C13.4614 4.2 13.9091 4.64771 13.9091 5.2V18C13.9091 18.5523 13.4614 19 12.9091 19C12.3568 19 11.9091 18.5523 11.9091 18V5.2C11.9091 4.64771 12.3568 4.2 12.9091 4.2Z"
      fill="#737373"
    />
  </svg>
);

export default MapIcon;
