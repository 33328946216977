import React from 'react';
import { RetrospectiveSection } from 'types';
import MarkdownContent from 'components/MarkdownContent';
import styled from 'styled-components';
import theme from 'theme';

const ViewContainer = styled.div`
  width: 100%;
  padding: ${theme.spacing.x1} 0;
  border-radius: 8px;
  .ql-display-only {
    padding: 0 !important;
  }
`;

interface Props {
  section: RetrospectiveSection;
  hideEmpty?: boolean;
}

function TextSectionView(props: Props) {
  const { section, hideEmpty } = props;

  // Strip HTML from text to see if we have an empty field.
  let doc = new DOMParser().parseFromString(section.body, 'text/html');
  const sectionIsEmpty = !section.body || !doc.body.textContent || doc.body.textContent === '';

  if (sectionIsEmpty && hideEmpty) {
    return <></>;
  }

  return (
    <ViewContainer>
      {sectionIsEmpty ? (
        <p className="subtle">Click to edit this section</p>
      ) : (
        <MarkdownContent source={section.body} />
      )}
    </ViewContainer>
  );
}
export default React.memo(TextSectionView);
