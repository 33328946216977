import RadioField from 'components/RadioField';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  type: string;
  setType: (value: string) => void;
}

function OutcomeButtons(props: Props) {
  const { setType, type } = props;
  const { t } = useTranslation();
  const translationKey = 'panels.editOutcome';
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };

  return (
    <>
      <RadioField className="compact">
        <div className="radio-group">
          <input
            type="radio"
            id="improve_metric"
            checked={type === 'improve_metric'}
            name="type"
            value={'improve_metric'}
            onChange={handleChange}
          />
          <label htmlFor="improve_metric" className="improve_metric">
            {t(`${translationKey}.toggleImprove`)}
          </label>

          <input
            type="radio"
            id="stay_above"
            checked={type === 'stay_above'}
            name="timeline"
            value={'stay_above'}
            onChange={handleChange}
          />
          <label htmlFor="stay_above" className="stay_above">
            {t(`${translationKey}.toggleAbove`)}
          </label>

          <input
            type="radio"
            id="stay_below"
            checked={type === 'stay_below'}
            name="timeline"
            value={'stay_below'}
            onChange={handleChange}
          />
          <label htmlFor="stay_below" className="stay_below">
            {t(`${translationKey}.toggleBelow`)}
          </label>

          <input type="radio" id="kpi" checked={type === 'kpi'} name="timeline" value={'kpi'} onChange={handleChange} />
          <label htmlFor="kpi" className="kpi">
            {t(`${translationKey}.toggleNoTarget`)}
          </label>
          <input
            type="radio"
            id="no_metric"
            checked={type === 'no_metric'}
            name="type"
            value={'no_metric'}
            onChange={handleChange}
          />
          <label htmlFor="no_metric" className="no_metric">
            {t(`${translationKey}.toggleConfidence`)}
          </label>
        </div>
      </RadioField>
    </>
  );
}
export default OutcomeButtons;
