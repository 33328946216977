import React, { Fragment } from 'react';
//import * as TabilityTypes from 'types';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import NewCommentBlock from './NewCommentBlock';
import CommentBlock from './CommentBlock';

import Loader from 'components/Loader';
import { Checkin, CheckinComment } from 'types';

const Separator = styled.div`
  border-bottom: 1px solid ${theme.colors.N10};
  margin: ${theme.spacing.x1} 0 ${theme.spacing.x2} 0;
`;

interface Props {
  checkin: Checkin;
  hideNewComment?: boolean;
}

function CheckinComments(props: Props) {
  const { checkin, hideNewComment } = props;

  // Construct the query key using the plan Id
  const queryKeyCheckinComments = [queryKeys.checkinComments, checkin.id];
  const staleTime = queryKeys.staleTime;

  // Get the comments details
  const { data: checkinCommentsResponse, isLoading } = useQuery(
    queryKeyCheckinComments,
    remoteApi.fetchCheckinComments,
    {
      staleTime,
    },
  );

  let checkinComments: CheckinComment[] = [];
  if (checkinCommentsResponse) {
    checkinComments = checkinCommentsResponse.data;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      {checkinComments.length > 0 && <Separator />}
      {checkinComments.map((checkinComment) => {
        return <CommentBlock checkinComment={checkinComment} checkin={checkin} key={checkinComment.id} />;
      })}
      {!hideNewComment && <NewCommentBlock checkin={checkin} />}
    </Fragment>
  );
}

export default React.memo(CheckinComments);
