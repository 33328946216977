import WorkspaceAvatar from 'components/WorkspaceAvatar';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaIcon from 'koala/components/Icons';
import KoalaTextBadge from 'koala/components/TextBadge';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import { Workspace } from 'types';
import { WorkspaceThemeKey, WorkspaceThemeType } from 'utils/workspaceThemeUtils';

const Container = styled.div<{ selectedTheme: WorkspaceThemeType; defaultTheme: WorkspaceThemeType }>`
  background: ${({ selectedTheme, defaultTheme }) =>
    selectedTheme[WorkspaceThemeKey.sidebarBg] ?? defaultTheme[WorkspaceThemeKey.sidebarBg]};
  width: 24rem;
  padding: ${theme.spacing.x3};
  margin-bottom: ${theme.spacing.x2};

  .link,
  .workspace-link {
    color: ${({ selectedTheme, defaultTheme }) =>
      selectedTheme[WorkspaceThemeKey.sidebarText] ?? defaultTheme[WorkspaceThemeKey.sidebarText]};
  }

  .link:hover,
  .is-active {
    background: ${({ selectedTheme, defaultTheme }) =>
      selectedTheme[WorkspaceThemeKey.sidebarActiveBg] ?? defaultTheme[WorkspaceThemeKey.sidebarActiveBg]};
  }
  .workspace-link {
    background: ${({ selectedTheme, defaultTheme }) =>
      selectedTheme[WorkspaceThemeKey.sidebarAltBg] ?? defaultTheme[WorkspaceThemeKey.sidebarAltBg]};
  }
`;

const LinkExample = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  height: 4.8rem;
  width: 100%;
  padding: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x2};
  border-radius: 8px;
`;

const LinkText = styled.div<{ color?: string }>`
  margin-left: ${theme.spacing.x2};
  color: ${(props) => props.color};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const ButtonExample = styled.div<{ color?: string }>`
  display: flex;
  border-radius: 8px;
  background: transparent;
  border: 2px solid ${(props) => props.color};
  padding: ${theme.spacing.x1};
`;

const WorkspaceSelector = styled.div`
  padding: ${theme.spacing.x1};
  display: flex;
  background: #fff;
  margin-bottom: ${theme.spacing.x3};
  border-radius: 8px;
`;

interface Props {
  selectedTheme: WorkspaceThemeType;
  defaultTheme: { [key in WorkspaceThemeKey]: string };
  workspace: Workspace;
}

function SidebarPreview(props: Props) {
  const { selectedTheme, defaultTheme, workspace } = props;
  const { t } = useTranslation();

  const avatarColor =
    selectedTheme[WorkspaceThemeKey.workspaceIconBg] ?? defaultTheme[WorkspaceThemeKey.workspaceIconBg];
  const iconColor = selectedTheme[WorkspaceThemeKey.sidebarIcon] ?? defaultTheme[WorkspaceThemeKey.sidebarIcon];
  const badgeBg = selectedTheme[WorkspaceThemeKey.sidebarBadgeBg] ?? defaultTheme[WorkspaceThemeKey.sidebarBadgeBg];
  const badgeText =
    selectedTheme[WorkspaceThemeKey.sidebarBadgeText] ?? defaultTheme[WorkspaceThemeKey.sidebarBadgeText];

  return (
    <div>
      <Container selectedTheme={selectedTheme} defaultTheme={defaultTheme}>
        <WorkspaceSelector className="workspace-link">
          <WorkspaceAvatar
            workspace={workspace}
            overrideColor={avatarColor ?? defaultTheme[WorkspaceThemeKey.workspaceIconBg]}
          />
          <LinkText>
            {workspace.name}
            <KoalaIconButton iconName="select" iconColor={iconColor} />
          </LinkText>
        </WorkspaceSelector>
        <LinkExample className="link is-active">
          <KoalaIcon iconName="home" iconAppearance={iconColor} />
          <LinkText>
            {t('sidebar.dashboard')}
            <KoalaTextBadge backgroundColor={badgeBg} textColor={badgeText} size="small" edge="circle">
              2
            </KoalaTextBadge>
          </LinkText>
        </LinkExample>
        <LinkExample className="link">
          <KoalaIcon iconName="plan" iconAppearance={iconColor} />
          <LinkText>{t('sidebar.plans')}</LinkText>
        </LinkExample>
        <ButtonExample className="link" color={iconColor}>
          <KoalaIcon iconName="personPlus" iconAppearance={iconColor} />
          <LinkText>{t('sidebar.inviteTeam')}</LinkText>
        </ButtonExample>
      </Container>
    </div>
  );
}

export default SidebarPreview;
