import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MarkdownContent from 'components/MarkdownContent';
import styled from 'styled-components';
import { BlurDiv, ContentComment } from '.';
import { AuditEvent } from 'types';
import { Workspace } from 'types';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import TagList from 'components/TagPickerObjective/TagList';
import InitiativeWorkStatus from 'components/InitiativeWorkStatus';
import theme from 'theme';
import { formatLocale } from 'utils/dateUtils';
const RowSection = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

interface Props {
  event: AuditEvent;
  canViewAudit: boolean;
}

function InitiativeUpdated(props: Props) {
  const { event, canViewAudit } = props;
  const { t, i18n } = useTranslation();

  const { membership_cached_fullname, membership_cached_email, source_cached_title, membership_id, event_meta } = event;
  let name = membership_cached_fullname || membership_cached_email;
  if (!membership_id) {
    name += ` ${t('auditEvents.deleted')}`;
  }

  const meta = JSON.parse(event_meta);

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const initiativeLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();

  return (
    <>
      <span>
        <Trans
          i18nKey="auditEvents.initiativeUpdated"
          values={{ name, title: source_cached_title, initiative: initiativeLabel }}
          components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
        />
      </span>
      <BlurDiv isBlurred={!canViewAudit}>
        {meta['outcome'] && (
          <li>
            <Trans
              i18nKey="auditEvents.initiativeOutcomeUpdated"
              values={{
                previous: meta['outcome'] ? meta['outcome'][0] : meta['outcome_id'][0],
                new: meta['outcome'] ? meta['outcome'][1] : meta['outcome_id'][1],
              }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['owner'] && (
          <li>
            <Trans
              i18nKey="auditEvents.initiativeOwnerUpdated"
              values={{
                previous: meta['owner'] ? meta['owner'][0] : meta['membership_id'][0],
                new: meta['owner'] ? meta['owner'][1] : meta['membership_id'][1],
              }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['title'] && (
          <li>
            <Trans
              i18nKey="auditEvents.titleUpdated"
              values={{ previous: meta['title'][0], new: meta['title'][1] }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['state'] && (
          <li>
            <Trans
              i18nKey={
                meta['state'][1] === 'closed'
                  ? 'auditEvents.initiativeStateClosed'
                  : 'auditEvents.initiativeStateOpened'
              }
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['work_state'] && (
          <li>
            <RowSection>
              <Trans
                i18nKey="auditEvents.initiativeWorkStateUpdated"
                components={{
                  previous: <InitiativeWorkStatus providedState={meta['work_state'][0]} showArrow={false} />,
                  new: <InitiativeWorkStatus providedState={meta['work_state'][1]} showArrow={false} />,
                }}
              />
            </RowSection>
          </li>
        )}

        {meta['body'] && (
          <li>
            <Trans i18nKey="auditEvents.initiativeBodyUpdated" />
            <ContentComment>
              <MarkdownContent source={meta['body'][1]} />
            </ContentComment>
          </li>
        )}
        {meta['due_at'] && (
          <li>
            <Trans
              i18nKey="auditEvents.initiativeDueUpdated"
              values={{
                previous: meta['due_at'][0] ? formatLocale(meta['due_at'][0], 'dd MMM', i18n) : '-',
                new: meta['due_at'][1] ? formatLocale(meta['due_at'][1], 'dd MMM', i18n) : '-',
              }}
              components={{ b: <b /> }}
            />
          </li>
        )}
        {meta['tag_list'] && (
          <li>
            <RowSection>
              <Trans
                i18nKey="auditEvents.initiativeTagsUpdated"
                components={{
                  previous: <TagList tagList={meta['tag_list'][0].join(',')} disabled={true} />,
                  new: <TagList tagList={meta['tag_list'][1].join(',')} disabled={true} />,
                }}
              />
            </RowSection>
          </li>
        )}
        {meta['archived'] && (
          <li>
            <Trans
              i18nKey={meta['archived'][1] ? 'auditEvents.initiativeArchived' : 'auditEvents.initiativeUnarchived'}
              components={{ b: <b /> }}
            />
          </li>
        )}
      </BlurDiv>
    </>
  );
}

export default React.memo(InitiativeUpdated);
