import React from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';
import theme from 'theme';

import FormField from 'components/FormField';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useTranslation } from 'react-i18next';

const InlineItems = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2.2rem;
    max-width: 30rem;
  }
`;

interface Props {
  outcome: TabilityTypes.Outcome;
}

function LinkSource(props: Props) {
  const { outcome } = props;
  const { t } = useTranslation();
  const translationKey = 'modals.dataConnectors';

  if (outcome.data_source_type !== 'url' || outcome.data_source_origin !== 'link') {
    return null;
  }

  const outcomeURL = outcome.data_source_meta?.url || '';

  if (!outcomeURL) {
    return null;
  }

  return (
    <FormField>
      <label>{t(`${translationKey}.title`)}</label>
      <InlineItems>
        <KoalaTextBadge edge="circle" size="small" maxWidth="30rem" variant="neutral-light">
          {t(`${translationKey}.link`)}
        </KoalaTextBadge>
        <a href={outcomeURL} target="_blank" className="primary" rel="nofollower noreferrer" title={outcomeURL}>
          {outcomeURL}
        </a>
      </InlineItems>
    </FormField>
  );

  return null;
}

export default React.memo(LinkSource);
