import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';

// Components
import AccountPanel from './AccountPanel';
import KoalaAvatar from 'koala/components/Avatar';

const Container = styled.div`
  display: flex;
  padding: 0.8rem 3.2rem;
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 1;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .space-right {
    margin-right: ${theme.spacing.x4};
  }

  .invite {
    margin-right: ${theme.spacing.x2};
  }
`;

const Notice = styled.div`
  color: #6a6a6a;
`;

interface Props {
  title?: any;
}

function PlaygroundHeader(props: Props) {
  const [showAccountPanel, setShowAccountPanel] = useState(false); // Used to display the account panel

  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  const handleShowAccount = (e: any) => {
    e.preventDefault();
    setShowAccountPanel(true);
  };

  return (
    <Container>
      {props.title}
      <AccountPanel
        showAccountPanel={showAccountPanel}
        setShowAccountPanel={setShowAccountPanel}
        currentUser={currentUser}
        currentMembership={currentMembership}
      />
      <Notice />
      <Actions>
        <KoalaAvatar user={currentUser} size={2.8} handleClick={handleShowAccount} />
      </Actions>
    </Container>
  );
}

export default React.memo(PlaygroundHeader);
