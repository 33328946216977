import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Objective } from 'types';
import TagList from './TagList';
import TagPicker from './TagPicker';

const InlineContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
  margin-bottom: ${theme.spacing.x2};

  .tag-select {
    width: 100%;
  }
`;

// Components
interface Props {
  objective: Objective;
  disabled?: boolean;
}

function TagPickerObjective(props: Props) {
  const { objective, disabled } = props;
  const [displayPicker, setDisplayPicker] = useState(false);

  if (displayPicker) {
    return (
      <InlineContainer>
        <TagPicker objective={objective} setDisplayPicker={setDisplayPicker} />
      </InlineContainer>
    );
  }
  return (
    <InlineContainer>
      <TagList tagList={objective.cached_tag_list ?? ''} disabled={disabled} setDisplayPicker={setDisplayPicker} />
    </InlineContainer>
  );
}

export default React.memo(TagPickerObjective);
