import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { BlurDiv } from 'components/AuditEventItem';
interface Props {
  event: any;
  canViewAudit: boolean;
}

function OutcomeContributorChanged(props: Props) {
  const { event, canViewAudit } = props;

  const { t } = useTranslation();

  const { membership_cached_fullname, membership_cached_email, source_cached_title, membership_id, event_meta } = event;
  let name = membership_cached_fullname || membership_cached_email;
  if (!membership_id) {
    name += ` ${t('auditEvents.deleted')}`;
  }

  const isAdding = event.event_type === 'outcome.contributor_created';

  const meta = JSON.parse(event_meta);

  const user =
    meta['cached_firstname'] && meta['cached_firstname'].length > 0
      ? `${meta['cached_firstname']} ${meta['cached_lastname']}`
      : meta['cached_email'];

  return (
    <span>
      <Trans
        i18nKey={isAdding ? 'auditEvents.contributorAdded' : 'auditEvents.contributorRemoved'}
        values={{
          user,
          name,
          title: source_cached_title,
        }}
        components={{ b: <b />, blur: <BlurDiv isBlurred={!canViewAudit} /> }}
      />
    </span>
  );
}

export default React.memo(OutcomeContributorChanged);
