/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation } from 'react-query';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';

// Components
import { Helmet } from 'react-helmet';
import FormField from 'components/FormField';
import Wordmark from 'components/Wordmark';
import ReactTooltip from 'react-tooltip';

// Actions
import { sessionActions } from 'state/actions/';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { Trans, useTranslation } from 'react-i18next';

// Components

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: ${theme.spacing.x8};
  min-width: 60rem;
  width: 60rem;

  @media (max-width: 800px) {
    width: 100%;
    min-width: initial;
    margin-top: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};
  }

  a {
    color: ${theme.colors.blue};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${theme.spacing.x4} 0 ${theme.spacing.x2} 0;
`;

const Progress = styled.div`
  display: flex;
  padding: ${theme.spacing.x4} 0px ${theme.spacing.x2};
  gap: ${theme.spacing.x2};

  @media (max-width: 800px) {
    padding: ${theme.spacing.x2} 0;
  }
`;

const Circle = styled.div`
  height: 0.8rem;
  width: 0.8rem;

  border-radius: 50%;
  background: ${theme.colors.B10};

  &.active {
    background: ${theme.colors.B50};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  margin-top: ${theme.spacing.x8};

  @media (max-width: 800px) {
    margin-top: ${theme.spacing.x4};

    input,
    select {
      &.small {
        width: 100%;
      }

      &.tiny {
        width: 50%;
      }
    }
  }
`;

const ErrorList = styled.ul`
  margin-bottom: 1.6rem;
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.x2};
`;

const Footer = styled.div`
  margin-bottom: ${theme.spacing.x3};

  button.secondary {
    color: ${theme.colors.subtleText};
    text-decoration: none;
    font-weight: normal;
  }
`;

const LineForm = styled.div`
  margin-bottom: ${theme.spacing.x1};
  display: flex;
  label {
    margin-left: ${theme.spacing.x1};
  }
`;

function Login() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);

  const [firstname, setFirstname] = useState(currentUser.firstname || '');
  const [lastname, setLastname] = useState(currentUser.lastname || '');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [notify_marketing_content, setNotifyMarketingContent] = useState(currentUser.notify_marketing_content);

  const [updateAccountMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.updateAccount, {
    onSuccess: (response) => {
      const currentUser = response.data;
      dispatch(sessionActions.setCurrentUser(currentUser));
      history.push('/');
    },
  });

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'firstname':
        return t('errors.firstName');
      case 'lastname':
        return t('errors.lastName');
      default:
        return '';
    }
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'firstname':
        setFirstname(value);
        break;
      case 'lastname':
        setLastname(value);
        break;
      case 'notify_marketing_content':
        setNotifyMarketingContent(value);
        break;
      case 'accept_terms':
        setTermsAccepted(value);
        break;
    }
  };

  const handleLogout = (e: any) => {
    dispatch(sessionActions.logout());
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const legals_accepted_at = new Date();
    const params = {
      firstname,
      lastname,
      notify_marketing_content,
      legals_accepted_at: legals_accepted_at,
    };
    updateAccountMutation({
      user: params,
    });
  };

  // Error classnames used to highlight input fields that have errors
  const firstnameClassname = errors['firstname'] ? 'error' : '';
  const lastnameClassname = errors['lastname'] ? 'error' : '';

  return (
    <Wrapper>
      <Helmet>
        <title>{t('dashboardTerms.title')} | Tability</title>
      </Helmet>
      <Container>
        <Wordmark type="dark" />
        <Header>
          <h1>{t('dashboardTerms.title')}</h1>
        </Header>
        <p>{t('dashboardTerms.information')}</p>
        <Form onSubmit={handleSubmit}>
          <FormField>
            <small>{t('dashboardTerms.firstnameLabel')}</small>
            <input
              type="text"
              name="firstname"
              className={`${firstnameClassname} tiny`}
              autoComplete="off"
              placeholder={t('dashboardTerms.firstnameLabel') ?? 'Your first name'}
              value={firstname}
              required={true}
              onChange={handleChange}
            />
          </FormField>
          <FormField>
            <small>{t('dashboardTerms.lastnameLabel')}</small>
            <input
              type="text"
              name="lastname"
              className={`${lastnameClassname} tiny`}
              autoComplete="off"
              placeholder={t('dashboardTerms.lastnameLabel') ?? 'Your last name'}
              value={lastname}
              required={true}
              onChange={handleChange}
            />
          </FormField>
          <div className="profile_detail">
            <LineForm>
              <input
                id="accept_terms"
                name="accept_terms"
                type="checkbox"
                required={true}
                checked={termsAccepted}
                onChange={handleChange}
              />
              <label htmlFor="accept_terms">
                <Trans
                  i18nKey="dashboardTerms.terms"
                  components={{
                    privacy: (
                      <a
                        href="https://guides.tability.app/legals/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacy
                      </a>
                    ),
                    terms: (
                      <a href="https://guides.tability.app/legals/" target="_blank" rel="noopener noreferrer">
                        terms
                      </a>
                    ),
                  }}
                />
              </label>
            </LineForm>
            <LineForm>
              <input
                type="checkbox"
                checked={notify_marketing_content}
                onChange={handleChange}
                id="notify_marketing_content"
                name="notify_marketing_content"
              />
              <label htmlFor="notify_marketing_content">{t('dashboardTerms.marketing')}</label>
            </LineForm>
          </div>

          {isError && (
            <ErrorList>
              {Object.keys(errors).map((errorKey: string) => {
                // Get the first error for the key
                // NOTE: there might be more! I'm just keeping the UI simpler right now.
                const error: string = errors[errorKey][0];
                return (
                  <li>
                    {errorKeyToString(errorKey)} {error.toLowerCase()}
                  </li>
                );
              })}
            </ErrorList>
          )}

          <Progress>
            <Circle className="active" />
            <Circle />
            <Circle />
            <Circle />
            <Circle />
          </Progress>
          <Actions>
            <div data-tip data-for="submit_action">
              <KoalaButton submit disabled={isLoading || !termsAccepted} loading={isLoading}>
                {t('dashboardTerms.next')}
              </KoalaButton>
            </div>
          </Actions>
          {!termsAccepted && (
            <ReactTooltip
              place="bottom"
              type="dark"
              id="submit_action"
              className="tooltip"
              effect="solid"
              delayShow={200}
            >
              {t('dashboardTerms.acceptTerms')}
            </ReactTooltip>
          )}
        </Form>
      </Container>
      <Footer>
        <KoalaTextButton onClick={handleLogout} appearance="subtle">
          {t('shared.logout')}
        </KoalaTextButton>
      </Footer>
    </Wrapper>
  );
}

export default Login;
