import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
//import * as TabilityTypes from 'types';
import { useMutation } from 'react-query';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { Helmet } from 'react-helmet';
// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import MembershipSettingsNav from 'components/MembershipSettingsNav';
import KoalaButton from 'koala/components/Button';
import KoalaAvatar from 'koala/components/Avatar';
import { Trans, useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

function WorkspaceSettingsDetails() {
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const { t } = useTranslation();
  // Form values
  const [confirmText, setConfirmText] = useState('');

  const [deleteMembershipMutation, { isLoading: isRemovingMembership }]: [any, any] = useMutation(
    remoteApi.deleteMembership,
    {
      onSuccess: () => {
        window.location.replace('/');
      },
    },
  );

  const handleChange = (e: any) => {
    setConfirmText(e.target.value);
  };
  const leaveText = t('workspaceMembershipDelete.leaveText') ?? 'LEAVE';

  const isDisabled = confirmText !== leaveText;

  const handleRemoveFromWorkspace = (e: any) => {
    e.preventDefault();
    if (isDisabled) {
      return false;
    }
    deleteMembershipMutation(currentMembership.id);
  };

  // Display loader if fetching
  if (!currentMembership) {
    return (
      <Fragment>
        <ChromeSideNav>
          <MembershipSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <Loader />
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspaceMembershiptDelete.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <MembershipSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceMembershipDelete.title')}</h1>
          </ContentTitle>
          <Form onSubmit={handleRemoveFromWorkspace}>
            <FormField>
              <KoalaAvatar user={currentUser} />
            </FormField>
            <FormField>
              <p>
                <Trans
                  i18nKey="workspaceMembershipDelete.type"
                  values={{ text: leaveText }}
                  components={{ b: <b /> }}
                />
              </p>
              <p className="danger">
                <b>{t('workspaceMembershipDelete.action')}</b>
              </p>
            </FormField>
            <FormField>
              <label>{t('workspaceMembershipDelete.label')}</label>
              <input
                onChange={handleChange}
                value={confirmText}
                className="small"
                placeholder={t('workspaceMembershipDelete.confirm', { text: leaveText }) ?? 'type LEAVE to confirm'}
              />
            </FormField>
            <Actions>
              <KoalaButton
                submit
                disabled={isDisabled || isRemovingMembership}
                loading={isRemovingMembership}
                appearance="danger"
              >
                {t('workspaceMembershipDelete.leaveButton')}
              </KoalaButton>
            </Actions>
          </Form>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
