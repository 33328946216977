import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
// API
import * as remoteApi from 'api/remote';
import Loader from 'components/Loader';
import styled from 'styled-components';

import QuillBodyEditor from 'components/QuillBodyEditor';
import MarkdownContent from 'components/MarkdownContent';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import _ from 'lodash';
import { Team } from 'types';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  flex: 3;
  margin-bottom: ${theme.spacing.x3};
`;

const ViewMode = styled.div`
  background: ${theme.colors.N0};
  border-radius: 4px;
  /* border: 1px solid ${theme.colors.blockBorder}; */
  padding: ${theme.spacing.half} ${theme.spacing.x1};

  :hover {
    background: ${theme.colors.N3};
    cursor: text;
  }
  :active,
  :focus {
    background: ${theme.colors.B5};
  }
  .ql-notes {
    img {
      max-width: 100%;
    }
  }
`;
const EditMode = styled.div`
  .ql-notes {
    margin: 0 0 ${theme.spacing.x1} 0;
    background: ${theme.colors.N0};
  }

  label {
    color: #6a6a6a;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
    display: block;
  }
`;

const EditorActions = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

interface Props {
  team: Team;
}

function TeamDescription(props: Props) {
  const { team } = props;
  const { t } = useTranslation();
  const [originalText, setOriginalText] = useState(team.description);
  const [currentText, setCurrentText] = useState(originalText);

  const [showEdit, setShowEdit] = useState(false);
  const wrapperRef: any = useRef<HTMLDivElement>();

  useEffect(() => {
    // if in text editor and click outside, will save progress and switch back to view mode
    function handleClickOutside(event: any) {
      if (showEdit && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowEdit(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEdit, wrapperRef, currentText]);

  // Mutation that will update the description in the backend
  const [updateTeamMutation, { isLoading }] = useMutation(remoteApi.updateTeam, {
    onSuccess: (response) => {
      // Update originalText once saving is confirmed
      setOriginalText(response.data.description);
    },
  });

  // Function that updates the description
  const updatDescription = (newDescription: string) => {
    const teamParams = {
      description: newDescription,
    };
    const mutationParams = {
      teamId: team.id,
      team: teamParams,
    };
    updateTeamMutation(mutationParams);
  };

  // We use a debounce function to avoid saving the text on every character change.
  // This function waits 500ms to see if there's another character changed, and a max of 2s before saving.
  const debounceUpdateDescription = useRef(
    _.debounce((newDescription: string) => updatDescription(newDescription), 500, {
      maxWait: 2000,
    }),
  );

  useEffect(() => {
    // Make sure that we don't do useless update if text hasn't changed.
    if (currentText !== originalText) {
      debounceUpdateDescription.current(currentText);
    }
  }, [currentText, originalText]);

  const handleTextClicked = () => {
    setShowEdit(true);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    debounceUpdateDescription.current(currentText);
    setShowEdit(false);
  };

  // Strip HTML from text to see if we have an empty field.
  let doc = new DOMParser().parseFromString(originalText, 'text/html');
  const descriptionIsEmpty = !originalText || !doc.body.textContent || doc.body.textContent === '';

  return (
    <Container>
      <h3>{t('workspaceTeams.teamMember.about')}</h3>
      {!showEdit && (
        <ViewMode onClick={handleTextClicked}>
          {!descriptionIsEmpty && <MarkdownContent source={originalText} />}
          {descriptionIsEmpty && (
            <p className="subtle">
              <em>{t('workspaceTeams.teamMember.updateDescription')}</em>
            </p>
          )}
        </ViewMode>
      )}
      {showEdit && (
        <EditMode ref={wrapperRef}>
          <QuillBodyEditor
            value={currentText}
            onChange={(e: string) => {
              setCurrentText(e);
            }}
            disableMentions={true}
            quillClassName="ql-notes"
            allowHeaders={true}
          />
          <EditorActions>
            <KoalaButton onClick={handleSave}>{t('shared.save')}</KoalaButton>
            {isLoading && <Loader />}
          </EditorActions>
        </EditMode>
      )}
    </Container>
  );
}

export default React.memo(TeamDescription);
