import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionBlock, IntegrationAction, IntegrationBlock, IntegrationContent, IntegrationLogo } from '..';
import ConfluenceIcon from '../_assets/IconConfluence.png';

function ConfluenceIntegration() {
  const { t } = useTranslation();

  return (
    <IntegrationBlock>
      <IntegrationLogo>
        <img src={ConfluenceIcon} alt="Confluence" />
      </IntegrationLogo>
      <IntegrationContent>
        <h3>{t('workspaceSettingsIntegrations.confluenceHeader')}</h3>
        <p>{t('workspaceSettingsIntegrations.confluenceInfo')}</p>
        <p>
          <a href="https://guides.tability.app/docs/integrations/confluence" target="_blank" rel="noopener noreferrer">
            {t('workspaceSettingsIntegrations.integrationLink')}
          </a>
        </p>
      </IntegrationContent>
      <IntegrationAction>
        <ActionBlock>
          <div>{t('workspaceSettingsIntegrations.confluenceEnabled')}</div>
        </ActionBlock>
      </IntegrationAction>
    </IntegrationBlock>
  );
}

export default ConfluenceIntegration;
