import React from 'react';

export default () => (
  <svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g
      id="Icon-dump"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="user-plus" transform="translate(1.000000, 1.000000)" stroke="#ABABAB" strokeWidth="2">
        <path
          d="M15,18 L15,16 C15,13.790861 13.209139,12 11,12 L4,12 C1.790861,12 0,13.790861 0,16 L0,18"
          id="Shape"
        ></path>
        <circle id="Oval" cx="7.5" cy="4" r="4"></circle>
        <line x1="19" y1="5" x2="19" y2="11" id="Shape"></line>
        <line x1="22" y1="8" x2="16" y2="8" id="Shape"></line>
      </g>
    </g>
  </svg>
);
