import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

// Custom components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  width: 20rem;
  header {
    margin-bottom: ${theme.spacing.x1};
    text-transform: uppercase;
    color: ${theme.colors.green};
    letter-spacing: 1px;
    font-size: 1rem;
    font-weight: 800;
  }
  a {
    color: ${theme.colors.subtleText};
    font-size: 1.6rem;
  }
  .active {
    color: ${theme.colors.N100};
    font-weight: 700;
  }
`;

const FilterContainer = styled.div`
  margin-bottom: ${theme.spacing.x2};
  input {
    border: 1px solid ${theme.colors.N10};
    width: 100%;
  }
`;

interface Props {
  setKeyword: Function;
}

function TemplateSidenav(props: Props) {
  const { setKeyword } = props;
  const [search, setSearch] = useState<string>('');
  // START SEARCH LOGIC
  const performSearch = (newName: string) => {
    setKeyword(newName);
  };
  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    debouncePerformSearch.current(e.target.value);
  };
  return (
    <Container>
      <FilterContainer>
        <input placeholder="Filter" value={search} onChange={handleSearch} />
      </FilterContainer>
      <header>Browse by category</header>
      <NavLink
        exact={true}
        to="/templates"
        onClick={() => {
          setSearch('');
        }}
      >
        All
      </NavLink>
      <NavLink
        exact={true}
        to="/templates/product"
        onClick={() => {
          setSearch('');
        }}
      >
        Product
      </NavLink>
      <NavLink
        exact={true}
        to="/templates/marketing"
        onClick={() => {
          setSearch('');
        }}
      >
        Marketing
      </NavLink>
      <NavLink
        exact={true}
        to="/templates/engineering"
        onClick={() => {
          setSearch('');
        }}
      >
        Engineering
      </NavLink>
      <NavLink
        exact={true}
        to="/templates/growth"
        onClick={() => {
          setSearch('');
        }}
      >
        Growth
      </NavLink>
      <NavLink
        exact={true}
        to="/templates/sales"
        onClick={() => {
          setSearch('');
        }}
      >
        Sales
      </NavLink>
      <NavLink
        exact={true}
        to="/templates/design"
        onClick={() => {
          setSearch('');
        }}
      >
        Design
      </NavLink>
      <NavLink
        exact={true}
        to="/templates/hr"
        onClick={() => {
          setSearch('');
        }}
      >
        HR
      </NavLink>
    </Container>
  );
}

export default TemplateSidenav;
