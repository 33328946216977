import React from 'react';
import styled from 'styled-components';
import { Outcome } from 'types';
import * as checkinUtils from 'utils/checkinUtils';
import * as outcomeUtils from 'utils/outcomeUtils';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import KoalaTextBadge from 'koala/components/TextBadge';

const ScoreContainer = styled.div`
  .score-summary {
    border-top: 1px solid #fff;
    padding-top: ${theme.spacing.half};
    margin-top: ${theme.spacing.half};
  }

  div {
    display: flex;
    width: 100%;
    gap: ${theme.spacing.x2};
    justify-content: space-between;
    b {
    }
  }
`;

interface Props {
  outcome: Outcome;
  size?: 'small' | 'medium' | 'large';
}

function OutcomeCurrentValueLabel(props: Props) {
  const { outcome, size } = props;
  const { current_checkin } = outcome;
  const { t } = useTranslation();

  const labelSize = size || 'medium';
  const formattedGoal = outcomeUtils.formattedGoal(outcome);
  const currentConfidence = current_checkin ? current_checkin.confidence : 'grey';
  const formattedScore = current_checkin
    ? checkinUtils.checkinScore(current_checkin, outcome, t)
    : outcomeUtils.startingScore(outcome, t);

  return (
    <>
      <KoalaTextBadge
        isLowercase={true}
        variant={checkinUtils.confidenceToVariant(currentConfidence)}
        edge="circle"
        style={{ border: 'none', width: 'fit-content' }}
        maxWidth="10rem"
        dataFor={`progress-${outcome.nano_slug}`}
        size={labelSize}
      >
        {formattedScore}
      </KoalaTextBadge>
      <ReactTooltip place="bottom" type="dark" className="tooltip" effect="solid" id={`progress-${outcome.nano_slug}`}>
        <ScoreContainer>
          <>
            <div>
              <span>{t('shared.current')}:</span>
              <b>{formattedScore}</b>
            </div>
            {['improve_metric', 'stay_above', 'stay_below'].includes(outcome.outcome_type) && (
              <div>
                <span>{t('shared.goal')}:</span>
                <b>{formattedGoal}</b>
              </div>
            )}
            {outcome.outcome_type === 'improve_metric' && (
              <div className="score-summary">
                <span>{t('shared.progress')}:</span>
                <b>{Math.round(outcome.outcome_progress_prct * 100)}%</b>
              </div>
            )}
          </>
        </ScoreContainer>
      </ReactTooltip>
    </>
  );
}

export default OutcomeCurrentValueLabel;
