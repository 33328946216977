import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import DropdownMenu from 'components/DropdownMenu';
import MarkdownContent from 'components/MarkdownContent';
import KoalaIconButton from 'koala/components/IconButton';
import Reactions from 'components/Reactions';
import { CachedReaction, Checkin, CheckinComment } from 'types';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowLocale } from 'utils/dateUtils';
import ShareIconButton from 'components/ShareIconButton';
import { useParams } from 'react-router-dom';
import * as routes from 'routes';

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'gutter meta'
    'gutter content'
    'gutter footer';
  column-gap: ${theme.spacing.x2};

  margin-bottom: ${theme.spacing.x2};

  &.primary {
    background: #f3f3f3;
    border-radius: 8px;
    padding: ${theme.spacing.x3};

    border-bottom: 0;
  }

  .comment-actions {
    opacity: 0;
  }

  &:hover {
    .comment-actions {
      opacity: 1;
    }
  }
`;

const Gutter = styled.div`
  grid-area: gutter;
`;

const Content = styled.div`
  grid-area: content;
  .ql-editor.ql-display-only {
    padding: 0;
  }
`;

const Footer = styled.div`
  grid-area: footer;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: ${theme.spacing.x1};
  color: ${theme.colors.subtleText};

  .time-block {
    height: 3.2rem;
  }

  > div {
    display: flex;
    align-items: center;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: ${theme.spacing.half};
`;

interface Props {
  checkinComment: CheckinComment;
  checkin: Checkin;
  setIsEditing: any;
}

function CommentBlock(props: Props) {
  const queryCache = useQueryCache();
  const { t, i18n } = useTranslation();
  const currentUserRole = useSelector((state: any) =>
    state.session.currentMembership ? state.session.currentMembership.role : null,
  );
  const currentUserId = useSelector((state: any) => (state.session.currentUser ? state.session.currentUser.id : null));
  const { checkinComment, setIsEditing, checkin } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  // Checkin comment mutations
  const [deleteCheckinCommentMutation] = useMutation(remoteApi.deleteCheckinComment, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.checkinComments);
    },
  });

  const user = checkinComment.membership ? checkinComment.membership.user : null;

  // reactions
  const [reactions, setReactions] = useState<CachedReaction[]>([]);
  useEffect(() => {
    if (checkinComment.cached_reactions && typeof checkinComment.cached_reactions !== 'string') {
      setReactions(checkinComment.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [checkinComment]);

  const [addReactionMutation] = useMutation(remoteApi.createCheckinCommentReaction, {
    onSuccess: (response) => {
      queryCache.invalidateQueries(queryKeys.checkinComments);
      // queryCache.invalidateQueries(queryKeys.checkins);
      // queryCache.invalidateQueries(queryKeys.currentCheckin);
    },
  });
  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: (response) => {
      queryCache.invalidateQueries(queryKeys.checkinComments);
      // queryCache.invalidateQueries(queryKeys.checkins);
      // queryCache.invalidateQueries(queryKeys.currentCheckin);
    },
  });
  const addReaction = (emotion: string) => {
    addReactionMutation({ checkinCommentId: checkinComment.id, emotion: emotion });
  };
  const removeReaction = (reactionId: string) => {
    deleteReactionMutation(reactionId);
  };

  const handleMenuSelection = (value: ReactElement) => {
    const action = value.props['data-action'];
    const checkinCommentId = value.props['data-id'];
    switch (action) {
      case 'edit':
        setIsEditing(true);
        break;
      case 'delete':
        if (window.confirm(t('comments.deleteComment') ?? 'Delete this comment?')) {
          deleteCheckinCommentMutation(checkinCommentId);
        }
        break;
    }
  };

  let menu_items: ReactElement[] = [];

  if (user && currentUserId === user.id) {
    menu_items = [
      <span key="edit" data-action="edit" data-id={checkinComment.id}>
        {t('shared.edit')}
      </span>,
      <span key="delete" data-action="delete" data-id={checkinComment.id}>
        {t('shared.delete')}
      </span>,
    ];
  } else if (currentUserRole === 'owner' || currentUserRole === 'admin') {
    menu_items = [
      <span key="delete" data-action="delete" data-id={checkinComment.id}>
        {t('shared.delete')}
      </span>,
    ];
  }
  let checkinLink = checkinComment.plan_nano_slug
    ? `https://${process.env.REACT_APP_DOMAIN}${routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(
        ':workspaceSlug',
        workspaceSlug,
      ).replace(':planId', checkinComment.plan_nano_slug)}#checkin:${checkin.nano_slug}:show`
    : '';

  return (
    <Grid>
      <Gutter>
        <KoalaAvatar user={user} size={2.4} />
      </Gutter>
      <Content>
        <MarkdownContent source={checkinComment.body} />
      </Content>
      <Footer>
        <div className="time-block">{formatDistanceToNowLocale(checkinComment.created_at, i18n, true, true)}</div>
        <div>
          <Reactions reactions={reactions} addCallback={addReaction} removeCallback={removeReaction} />
        </div>
        <Actions className="comment-actions">
          <ShareIconButton id={checkinComment.id} link={checkinLink} />
          {menu_items.length > 0 && (
            <DropdownMenu
              trigger={<KoalaIconButton iconName="ellipsis" />}
              onSelection={handleMenuSelection}
              items={menu_items}
            />
          )}
        </Actions>
      </Footer>
    </Grid>
  );
}

export default React.memo(CommentBlock);
