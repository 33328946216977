import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
//import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import MembershipSettingsNav from 'components/MembershipSettingsNav';
import KoalaButton from 'koala/components/Button';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import { Membership } from 'types';
import { getLocaleDaysOfWeek } from 'utils/dateUtils';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  h3 {
    margin: ${theme.spacing.x2} 0;
  }
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const FormLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  label {
    margin: 0;
    padding: 0;
  }
`;

const SelectLine = styled.div`
  display: flex;
  align-items: center;
  span {
    margin: 0 ${theme.spacing.x2};
  }
`;

function WorkspaceSettingsDetails() {
  const queryCache = useQueryCache();
  const { t, i18n } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);

  const queryKey = queryKeys.currentUserMemberships;

  const [standup_frequency, setStandupFrequency] = useState<string>(currentMembership.standup_frequency);
  const [standup_hour, setStandupHour] = useState<number>(currentMembership.standup_hour);
  const [weekly_standup_day, setWeeklyStandupDay] = useState<number>(currentMembership.weekly_standup_day);

  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  // Workspace mutations
  const [updateMembershipMutation, { isError, isLoading, error }]: [any, any] = useMutation(
    remoteApi.updateMembership,
    {
      onMutate: () => {
        queryCache.invalidateQueries(queryKey);
        setIsFormSaved(false);
      },
      onSuccess: () => {
        setIsFormSaved(true);
        setIsFormDirty(false);
      },
    },
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      weekly_standup_day,
      standup_frequency,
      standup_hour,
    };
    updateMembershipMutation({
      membershipId: currentMembership.id,
      membership: params,
    });
  };

  // Display loader if fetching
  if (!currentMembership) {
    return (
      <Fragment>
        <ChromeSideNav>
          <MembershipSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <Loader />
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'standup_frequency':
        return 'The standup frequency';
      case 'standup_day':
        return 'The standup day';
      case 'standup_hour':
        return 'The standup hour';
      default:
        return '';
    }
  };

  const days = getLocaleDaysOfWeek('EEEE', i18n);

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspaceMembershipNotifications.title')} | Tability
        </title>
      </Helmet>
      <Prompt
        message={t('shared.confirmLeave') ?? 'Your changes are not saved. Are you sure you want to leave?'}
        when={isFormDirty}
      />
      <ChromeSideNav>
        <MembershipSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceMembershipStandups.title')}</h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <FormLine>
                <KoalaAvatar user={currentUser} />
                {currentUser.name}
              </FormLine>
            </FormField>
            <h3>{t('workspaceMembershipStandups.standupsSettingsHeader')}</h3>
            <FormField>
              <label>{t('workspaceSettingsWorkflow.defaultStandupFrequencyHeader')}</label>
              <SelectLine>
                <select
                  value={standup_frequency}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setStandupFrequency(e.currentTarget.value)}
                >
                  <option value="daily">{t('workspaceSettingsWorkflow.daily')}</option>
                  <option value="weekly">{t('workspaceSettingsWorkflow.weekly')}</option>
                  <option value="none">{t('workspaceSettingsWorkflow.none')}</option>
                </select>
              </SelectLine>
            </FormField>
            {standup_frequency !== 'none' && (
              <>
                {standup_frequency === 'weekly' && (
                  <FormField>
                    <label>{t('workspaceSettingsWorkflow.defaultStandupDayHeader')}</label>
                    <select
                      value={weekly_standup_day}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setWeeklyStandupDay(parseInt(e.currentTarget.value))
                      }
                    >
                      <option value="0">{days[0]}</option>
                      <option value="1">{days[1]}</option>
                      <option value="2">{days[2]}</option>
                      <option value="3">{days[3]}</option>
                      <option value="4">{days[4]}</option>
                      <option value="5">{days[5]}</option>
                      <option value="6">{days[6]}</option>
                    </select>
                  </FormField>
                )}
                <FormField>
                  <label>{t('workspaceSettingsWorkflow.defaultStandupHourHeader')}</label>
                  <SelectLine>
                    <select
                      value={standup_hour}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setStandupHour(parseInt(e.currentTarget.value))
                      }
                    >
                      {Array(24)
                        .fill(0)
                        .map((_, i) => (
                          <option value={i} key={i}>
                            {i}
                          </option>
                        ))}
                    </select>
                    <div>:00</div>
                  </SelectLine>
                </FormField>
              </>
            )}
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading}>
                {t('shared.save')}
              </KoalaButton>
              {isFormSaved && <div>{t('shared.saved')}</div>}
            </Actions>
          </Form>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
