import styled from 'styled-components'; // Fixing the build
import theme from 'theme';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaButton from 'koala/components/Button';
import FormField from 'components/FormField';
import KoalaTextButton from 'koala/components/TextButton';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import * as routes from 'routes';
import queryKeys from 'config/queryKeys';

const Form = styled.form`
  .segment-input {
    margin-bottom: ${theme.spacing.x1};
  }
`;

function CreateDashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryCache = useQueryCache();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const { t } = useTranslation();

  const [title, setTitle] = useState('');

  const [createDashboard, { isLoading }] = useMutation(remoteApi.createDashboard, {
    onSuccess: (response: AxiosResponse) => {
      const createdDashboard = response.data;
      const route = routes.WORKSPACE_DASHBOARD_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
        ':dashboardId',
        createdDashboard.nano_slug,
      );
      queryCache.invalidateQueries([queryKeys.teams, workspaceSlug]);
      history.push(`${route}/edit`);
      dispatch(setGlobalModalContent(''));
    },
  });

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    const dashboard = {
      title,
    };
    const params = {
      workspaceSlug: workspaceSlug,
      dashboard,
    };
    createDashboard(params);
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t('modals.createDashboard.title')}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleSave}>
          <FormField>
            <label>{t('modals.createDashboard.name')}</label>
            <input
              placeholder={t('modals.createDashboard.name') ?? 'Dashboard name'}
              className="segment-input"
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSave} loading={isLoading}>
          {t('shared.create')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default CreateDashboard;
