import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useParams } from 'react-router-dom';

// API
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';

// Components
import Wordmark from 'components/Wordmark';
import { Helmet } from 'react-helmet';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';

const WordmarkWrapper = styled.div`
  margin-bottom: ${theme.spacing.x8};
`;

const Container = styled.div`
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    margin-bottom: ${theme.spacing.x4};
    a {
      text-decoration: underline;
    }
  }
`;

const MessageContainer = styled.div`
  min-height: 50%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const FormLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const Confirmation = styled.div`
  color: ${theme.colors.G90};
  background: ${theme.colors.G5};
  padding: ${theme.spacing.x1};
  margin-top: ${theme.spacing.x2};
  border-radius: 4px;
`;

const Error = styled.div`
  color: ${theme.colors.R90};
  background: ${theme.colors.R5};
  padding: ${theme.spacing.x1};
  margin-top: ${theme.spacing.x2};
  border-radius: 4px;
`;

function UnsubscribeFromEmail() {
  const queryCache = useQueryCache();
  // Get the unsubscribe token from the URL
  const { unsubscribe_token } = useParams<{ unsubscribe_token: string }>();

  const [email_weekly_reminders, setEmailWeeklyReminders] = useState(false);
  const [email_notifications, setEmailNotifications] = useState(false);
  const [email_weekly_reports, setEmailWeeklyReports] = useState(false);
  const [email_system, setEmailSystem] = useState(false);
  const [email_standup_reminders, setEmailStandupReminders] = useState(false);
  const [email_urgent_reminders, setEmailUrgentReminders] = useState(false);
  const [workspace, setWorkspace]: any = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  // First we need to fetch the existing notification settings using the unsubcribe token
  const queryKeyNotificationSettings = [queryKeys.memberships, unsubscribe_token];
  const staleTime = 0;

  const { isLoading, isError } = useQuery(queryKeyNotificationSettings, remoteApi.fetchUnsubscribeNotifications, {
    staleTime,
    onSuccess: (response) => {
      const { data } = response;
      setWorkspace(data.workspace);
      setEmailWeeklyReminders(data.email_weekly_reminders);
      setEmailNotifications(data.email_notifications);
      setEmailWeeklyReports(data.email_weekly_reports);
      setEmailSystem(data.email_system);
      setEmailStandupReminders(data.email_standup_reminders);
      setEmailUrgentReminders(data.email_urgent_reminders);
    },
  });

  // Workspace mutations
  const [updateUnsubscribeMutation, { isError: isUpdatingError, isLoading: isUpdating }]: [any, any] = useMutation(
    remoteApi.updateUnsubscribeNotifications,
    {
      onMutate: () => {
        setIsUpdated(false);
      },
      onSuccess: () => {
        setIsUpdated(true);
        queryCache.invalidateQueries(queryKeyNotificationSettings);
      },
    },
  );

  const handleSubmit = (e: MouseEvent) => {
    e.preventDefault();
    const params = {
      email_system,
      email_standup_reminders,
      email_urgent_reminders,
      email_weekly_reminders,
      email_notifications,
      email_weekly_reports,
    };
    updateUnsubscribeMutation({
      unsubscribeToken: unsubscribe_token,
      membership: params,
    });
  };

  const unsubscribeAll = (e: MouseEvent) => {
    e.preventDefault();
    const params = {
      email_system: false,
      email_standup_reminders: false,
      email_urgent_reminders: false,
      email_weekly_reminders: false,
      email_notifications: false,
      email_weekly_reports: false,
    };
    updateUnsubscribeMutation({
      unsubscribeToken: unsubscribe_token,
      membership: params,
    });
  };

  if (isError) {
    return (
      <Container>
        <Helmet>
          <title>Unsubscribe from emails | Tability</title>
        </Helmet>

        <WordmarkWrapper>
          <Wordmark type="dark" />
        </WordmarkWrapper>
        <MessageContainer>
          <h2>Invalid link</h2>
          <p>Sorry, we could not find any account corresponding to your unsubscribe link</p>
          <p>Please contact support.</p>
        </MessageContainer>
      </Container>
    );
  }
  return (
    <Container>
      <Helmet>
        <title>Unsubscribe from emails | Tability</title>
      </Helmet>

      <WordmarkWrapper>
        <Wordmark type="dark" />
      </WordmarkWrapper>
      <MessageContainer>
        {workspace && (
          <>
            <h2>
              Unsubscribe from email notifications for <a href={workspace.url}>{workspace.name}</a>
            </h2>
          </>
        )}

        <p>{isLoading && <KoalaLoader />}</p>
        {!isLoading && (
          <>
            <FormContainer>
              <FormLine>
                <input
                  type="checkbox"
                  id="email_weekly_reminders"
                  checked={email_weekly_reminders}
                  onChange={() => setEmailWeeklyReminders(!email_weekly_reminders)}
                />
                <label htmlFor="email_weekly_reminders">Receive check-ins reminders</label>
              </FormLine>
              <FormLine>
                <input
                  type="checkbox"
                  id="email_urgent_reminders"
                  checked={email_urgent_reminders}
                  onChange={() => setEmailUrgentReminders(!email_urgent_reminders)}
                />
                <label htmlFor="email_urgent_reminders">Receive daily urgent reminders</label>
              </FormLine>
              <FormLine>
                <input
                  type="checkbox"
                  id="email_standup_reminders"
                  checked={email_standup_reminders}
                  onChange={() => setEmailStandupReminders(!email_standup_reminders)}
                />
                <label htmlFor="email_standup_reminders">Receive standups reminders</label>
              </FormLine>
              <FormLine>
                <input
                  type="checkbox"
                  id="email_notifications"
                  checked={email_notifications}
                  onChange={() => setEmailNotifications(!email_notifications)}
                />
                <label htmlFor="email_notifications">Receive workspace notifications</label>
              </FormLine>
              <FormLine>
                <input
                  type="checkbox"
                  id="email_weekly_reports"
                  checked={email_weekly_reports}
                  onChange={() => setEmailWeeklyReports(!email_weekly_reports)}
                />
                <label htmlFor="email_weekly_reports">Receive weekly reports</label>
              </FormLine>
              <FormLine>
                <input
                  type="checkbox"
                  id="email_system"
                  checked={email_system}
                  onChange={() => setEmailSystem(!email_system)}
                />
                <label htmlFor="email_notifications">Receive system notifications</label>
              </FormLine>
              <FormLine></FormLine>
              <FormLine>
                <KoalaButton onClick={handleSubmit} loading={isUpdating}>
                  Update preferences
                </KoalaButton>
                <KoalaButton appearance="secondary" onClick={unsubscribeAll} loading={isUpdating}>
                  Unsubscribe from all notifications
                </KoalaButton>
              </FormLine>
              {isUpdated && <Confirmation>Your preferences have been updated</Confirmation>}
              {isUpdatingError && <Error>An error occurred while updating your preferences</Error>}
            </FormContainer>
          </>
        )}
      </MessageContainer>
    </Container>
  );
}

export default UnsubscribeFromEmail;
