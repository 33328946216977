import React from 'react';

export default () => (
  <svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g
      id="Icon-dump"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="user" transform="translate(1.000000, 1.000000)" stroke="#000000" strokeWidth="2">
        <path
          d="M16,18 L16,16 C16,13.790861 14.209139,12 12,12 L4,12 C1.790861,12 0,13.790861 0,16 L0,18"
          id="Shape"
        ></path>
        <circle id="Oval" cx="8" cy="4" r="4"></circle>
      </g>
    </g>
  </svg>
);
