import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './i18n';

// Below is the list of services that we need to configure in production
if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: ['Non-Error promise rejection captured with value: Object Not Found Matching Id:'],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.3,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
